import React, { useState, useEffect } from "react";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { Button, message } from "antd";
import {Modal} from '../../wrappers'

const ManageSourcing = ({ setManageSourcing }) => {
  const [sourcings, setSourcings] = useState([]);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));

  useEffect(() => {
    getSourcings();
  }, []);

  const getSourcings = async () => {
    const res = await fetch(`${url}/sourcing/find-all/${adminId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setSourcings(resJson.sourcing);
    } else message.error("no sourcing requests");
  };

  const updateSourcingStatus = async (sourcingId, status, index) => {
    const res = await fetch(`${url}/sourcing/edit-status/${adminId}/${sourcingId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        status: status,
      }),
    });
    if (res.status === 200) {
        let newSourcings = [...sourcings]
        newSourcings[index].status = status
        setSourcings([...newSourcings])
      message.success('Status updated !')
    } else message.error("error");
  }

  return (
    <Modal
      visible={true}
      onCancel={() => setManageSourcing(false)}
      footer={[
        <Button key="back" onClick={() => setManageSourcing(false)}>
          Cancel
        </Button>,
      ]}
    >
      {sourcings.map((sourcing, index) => {
        return (
          <div style={{ marginBottom: "20px" }} className="column">
            <p className="text-bold">User email :</p>
            <p className="text-300">{sourcing.user_email}</p>
            <p style={{marginTop: '6px'}} className="text-bold">status :</p>
            <p
              style={
                sourcing.status === "Processing"
                  ? { color: "#EF9B14" }
                  : { color: "#6FD979" }
              }
              className="text-bold"
            >
              {sourcing.status}
            </p>
            <p style={{marginTop: '6px'}} className="text-bold">Sourcing items describe :</p>
            <p className="text-300">{sourcing.item_describe}</p>
            {sourcing.other_informations && (
              <>
                <p style={{marginTop: '6px'}} className="text-bold">Other informations :</p>
                <p className="text-300">{sourcing.other_informations}</p>
              </>
            )}
            {sourcing.exemple_image && (
              <>
                <p style={{marginTop: '6px'}} className="text-bold">Image exemple :</p>
                <img
                  src={sourcing.exemple_image}
                  style={{ width: "150px", height: "auto", marginTop: '6px' }}
                />
              </>
            )}
            {sourcing.urls_product_exemple_1 && (
              <>
                <p style={{marginTop: '10px'}} className="text-bold">URL 1 :</p>
                <p className="text-300">{sourcing.urls_product_exemple_1}</p>
              </>
            )}
            {sourcing.urls_product_exemple_2 && (
              <>
                <p style={{marginTop: '10px'}} className="text-bold">URL 2 :</p>
                <p className="text-300">{sourcing.urls_product_exemple_2}</p>
              </>
            )}
            {sourcing.urls_product_exemple_3 && (
              <>
                <p style={{marginTop: '10px'}} className="text-bold">URL 3 :</p>
                <p className="text-300">{sourcing.urls_product_exemple_3}</p>
              </>
            )}
            <div style={{marginTop: '20px', marginBottom: '10px'}} className="row">
                <button style={{marginLeft: '0px'}} onClick={() => updateSourcingStatus(sourcing._id, 'Processing', index)} className={sourcing.status === "Processing" ? 'button-header-active' : 'button-header'}>
                    Processing
                </button>
                <button onClick={() => updateSourcingStatus(sourcing._id, 'Finished', index)} className={sourcing.status === "Finished" ? 'button-header-active' : 'button-header'}>
                    Finished
                </button>
            </div>
            <div className="divider-horizontal"/>
          </div>
        );
      })}
    </Modal>
  );
};

export default ManageSourcing;
