import React from "react";

import fantasyIcon from "../images/thematics/fantasy.svg";
import medievalIcon from "../images/thematics/medieval.svg";
import gothicIcon from "../images/thematics/gothic.svg";
import vikingIcon from "../images/thematics/viking.svg";
import zenIcon from "../images/thematics/zen.svg";
import esoterismIcon from "../images/thematics/esoterism.svg";
import kidsIcon from "../images/thematics/kids.svg";
import tribalIcon from "../images/thematics/tribal.svg";
import wildIcon from "../images/thematics/wild.svg";
import streetwearIcon from "../images/thematics/streetwear.svg";
import bohemeIcon from "../images/thematics/boheme.svg";
import petsIcon from "../images/thematics/pets.svg";
import usaIcon from "../images/thematics/usa.svg";
import ecologyIcon from "../images/thematics/ecology.svg";
import indiaIcon from "../images/thematics/india.svg";
import Checkbox from "antd/lib/checkbox/Checkbox";

const ThematicsSelection = ({ callbackSelection, selections }) => {
  return (
    <div className="row-wrap" style={{ width: "100%" }}>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Fantasy")}
          className={`thematic-option ${selections.includes("Fantasy") && "active"}`}
          src={fantasyIcon}
        />
        <Checkbox
        checked={selections.includes("Fantasy")}
          onChange={() => callbackSelection("Fantasy")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Medieval")}
          className={`thematic-option ${selections.includes("Medieval") && "active"}`}
          src={medievalIcon}
        />
        <Checkbox
        checked={selections.includes("Medieval")}
          onChange={() => callbackSelection("Medieval")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Gothic")}
          className={`thematic-option ${selections.includes("Gothic") && "active"}`}
          src={gothicIcon}
        />
        <Checkbox
        checked={selections.includes("Gothic")}
          onChange={() => callbackSelection("Gothic")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("viking")}
          className={`thematic-option ${selections.includes("viking") && "active"}`}
          src={vikingIcon}
        />
        <Checkbox
        checked={selections.includes("viking")}
          onChange={() => callbackSelection("viking")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Zen")}
          className={`thematic-option ${selections.includes("Zen") && "active"}`}
          src={zenIcon}
        />
        <Checkbox
        checked={selections.includes("Zen")}
          onChange={() => callbackSelection("Zen")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Esoterism")}
          className={`thematic-option ${selections.includes("Esoterism") && "active"}`}
          src={esoterismIcon}
        />
        <Checkbox
        checked={selections.includes("Esoterism")}
          onChange={() => callbackSelection("Esoterism")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Kids")}
          className={`thematic-option ${selections.includes("Kids") && "active"}`}
          src={kidsIcon}
        />
        <Checkbox
        checked={selections.includes("Kids")}
          onChange={() => callbackSelection("Kids")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Tribal")}
          className={`thematic-option ${selections.includes("Tribal") && "active"}`}
          src={tribalIcon}
        />
        <Checkbox
        checked={selections.includes("Tribal")}
          onChange={() => callbackSelection("Tribal")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Wild animals")}
          className={`thematic-option ${selections.includes("Wild animals") && "active"}`}
          src={wildIcon}
        />
        <Checkbox
        checked={selections.includes("Wild animals")}
          onChange={() => callbackSelection("Wild animals")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Streetwear")}
          className={`thematic-option ${selections.includes("Streetwear") && "active"}`}
          src={streetwearIcon}
        />
        <Checkbox
        checked={selections.includes("Streetwear")}
          onChange={() => callbackSelection("Streetwear")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Boheme")}
          className={`thematic-option ${selections.includes("Boheme") && "active"}`}
          src={bohemeIcon}
        />
        <Checkbox
        checked={selections.includes("Boheme")}
          onChange={() => callbackSelection("Boheme")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Pets")}
          className={`thematic-option ${selections.includes("Pets") && "active"}`}
          src={petsIcon}
        />
        <Checkbox
        checked={selections.includes("Pets")}
          onChange={() => callbackSelection("Pets")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("USA")}
          className={`thematic-option ${selections.includes("USA") && "active"}`}
          src={usaIcon}
        />
        <Checkbox
        checked={selections.includes("USA")}
          onChange={() => callbackSelection("USA")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("Ecology")}
          className={`thematic-option ${selections.includes("Ecology") && "active"}`}
          src={ecologyIcon}
        />
        <Checkbox
        checked={selections.includes("Ecology")}
          onChange={() => callbackSelection("Ecology")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <img
          onClick={() => callbackSelection("India")}
          className={`thematic-option ${selections.includes("India") && "active"}`}
          src={indiaIcon}
        />
        <Checkbox
        checked={selections.includes("India")}
          onChange={() => callbackSelection("India")}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
    </div>
  );
};

export default ThematicsSelection;
