import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

import MobileMenu from "./FirstMenu";
import Menu from "../../menu/FirstMenu";
import MobileHeader from "../../header/mobile/SimpleHeader";
import { routes } from "../../../routes";

const MobileSettingMenu = () => {
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [redirect, setRedirect] = useState(false);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (windowSize > 1150) {
      setRedirect(true);
    }
  }, [windowSize]);

  return (
    <div className="page">
      {redirect && <Redirect to={routes.yourInformations(i18n.language).path} />}
      <div className="page-start menu-setting container-setting-menu">
        {windowSize <= 1150 && <MobileMenu locationP="setting" />}
        <MobileHeader nameHeader="SETTINGS" />
        <Link to={typeAccount == "seller" ? "/your-shop-reseller" : "/your-shop-supplier"} className="row">
          <h4 className="title-menu-setting text-ellipsis">{t("components.menu.43")}</h4>
          <RightOutlined className="arrow-menu-setting" />
        </Link>
        {typeAccount == "creater" && (
          <Link style={{ marginTop: "35px" }} to={"/manage-shipping-methods"} className="row">
            <h4 className="title-menu-setting text-ellipsis">{t("shippingMethod")}</h4>
            <RightOutlined className="arrow-menu-setting" />
          </Link>
        )}
        <div style={{ marginTop: "35px" }} className="divider-horizontal" />
        <Link to="/your-informations" className="row">
          <h4 className="title-menu-setting text-ellipsis">{t("components.menu.44")}</h4>
          <RightOutlined className="arrow-menu-setting" />
        </Link>
        {typeAccount === "creater" && (
          <>
            <div style={{ marginTop: "35px" }} className="divider-horizontal" />
            <Link to="/payment-methods" className="row">
              <h4 className="title-menu-setting text-ellipsis">{t("components.menu.45")}</h4>
              <RightOutlined className="arrow-menu-setting" />
            </Link>

            <div style={{ marginTop: "35px" }} className="divider-horizontal" />
            <Link to={"/boost-your-business"} className="row">
              <h4 className="title-menu-setting text-ellipsis">{t("components.menu.46")}</h4>
              <RightOutlined className="arrow-menu-setting" />
            </Link>
          </>
        )}
        <div style={{ marginTop: "35px" }} className="divider-horizontal" />
        <Link
          to={typeAccount === "creater" ? routes.planSupplier(i18n.language).path : "/pricing"}
          className="row"
        >
          <h4 className="title-menu-setting text-ellipsis">Plans</h4>
          <RightOutlined className="arrow-menu-setting" />
        </Link>
        <div style={{ marginTop: "35px" }} className="divider-horizontal" />
        {typeAccount === "seller" && (
          <>
            <Link to={routes.pricingRulesSettings(i18n.language).path} className="row">
              <h4 className="title-menu-setting text-ellipsis">{t("pricingRules")}</h4>
              <RightOutlined className="arrow-menu-setting" />
            </Link>
            <div style={{ marginTop: "35px" }} className="divider-horizontal" />
          </>
        )}
        <Link
          to={typeAccount === "seller" ? "/reseller-documentation" : routes.supplierDoc(i18n.language).path}
          className="row"
        >
          <h4 className="title-menu-setting text-ellipsis">Documentation</h4>
          <RightOutlined className="arrow-menu-setting" />
        </Link>
        {typeAccount === "seller" && (
          <>
            <div style={{ marginTop: "35px" }} className="divider-horizontal" />
            <Link to={"/reseller-tools"} className="row">
              <h4 className="title-menu-setting text-ellipsis">{t("components.menu.47")}</h4>
              <RightOutlined className="arrow-menu-setting" />
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileSettingMenu;
