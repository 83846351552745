import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { articles } from "../../articles";
import NavBar from "../../../../components/menu/navbar/NavBar";
import Footer from "../../../../components/menu/Footer";
import TagMenu from "../../../../components/blog/TagMenu";
import ArticleHeaderNav from "../../../../components/blog/ArticleHeaderNav";
import CourseSponso from "../../../../components/blog/CourseSponso";
import Social from "../../../../components/blog/Social";
import ArticleFooter from "../../../../components/blog/ArticleFooter";
import Content from "./Content";

import "../../style.scss";
import SuggestionCard from "../../../../components/blog/SuggestionCard";

const Blog = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedTag, setSelectedTag] = useState("");

  const articleId = 14; // NEED CHANGE TO ALL NEW ARTICLE

  const article = articles.find((article) => parseInt(article.id) === articleId);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });
  const newArticles = articles
    .filter((article) => parseInt(article.id) !== articleId)
    .sort((a, b) => parseInt(a.id) - parseInt(b.id));
  const lastThreeArticles = [
    newArticles[newArticles.length - 1],
    newArticles[newArticles.length - 2],
    newArticles[newArticles.length - 3],
  ];

  return (
    <div style={{ background: "white" }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />
        <title>How much does product photography cost? | 01supply</title>
        <meta
          name="description"
          content="How much does product photography cost? Should you invest in equipment to do it yourself or should you pay a professional studio? Learn everything here."
        />
        <meta
          property="og:description"
          content="How much does product photography cost? Should you invest in equipment to do it yourself or should you pay a professional studio? Learn everything here."
        />
        <meta
          property="og:image"
          content="https://app.01supply.com/static/media/product-photography-cost.bccc97b4.jpg"
        />
        <meta property="og:title" content={`How much does product photography cost? | 01supply`} />
        <meta property="og:url" content="https://app.01supply.com/product-photography-cost" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://app.01supply.com/product-photography-cost" />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "How much does product photography cost?",
            "image": [
              "https://app.01supply.com/static/media/static/media/product-photography-cost.bccc97b4.jpg"
            ],
            "datePublished": "2022-09-26",
            "dateModified": "2022-09-26",
            "author": [
              {
                "@type": "Person",
                "name": "Diego Huberty",
                "jobTitle": "Editor in Chief"
              }
            ],
            "publisher": {
              "name": "01supply",
              "url": "https://app.01supply.com/"
            }
          }`}
        </script>
      </Helmet>

      <NavBar windowSize={windowSize} />

      <div style={{ paddingBottom: "150px", textAlign: "start" }} className="column center">
        <TagMenu setSelectedTag={setSelectedTag} selectedTag={selectedTag} />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            <ArticleHeaderNav article={article} />
            <CourseSponso
              imagePath="coursesImg/supplier/course1_untlited.svg"
              imageTitle="Photo mastery"
              price="99$"
              title="Learn how to take outstanding Product Photos"
              description="Even with your smartphone, you can enhance your shots by far, thanks to advanced settings, correct lighting 
              and good knowledge."
              coursePath="/academy/photo-mastery-course"
            />
            <Content />
            <Social article={article} />
            <ArticleFooter windowSize={windowSize} />
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article, index) => (
              <SuggestionCard key={`article-${articleId}-${index}`} article={article} />
            ))}
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Blog;
