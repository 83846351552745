import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { articles } from "../../articles";
import NavBar from "../../../../components/menu/navbar/NavBar";
import Footer from "../../../../components/menu/Footer";
import TagMenu from "../../../../components/blog/TagMenu";
import ArticleHeaderNav from "../../../../components/blog/ArticleHeaderNav";
import CourseSponso from "../../../../components/blog/CourseSponso";
import Social from "../../../../components/blog/Social";
import ArticleFooter from "../../../../components/blog/ArticleFooter";
import Content from './Content'
import SuggestionCard from "../../../../components/blog/SuggestionCard";

import "../../style.scss";

const Blog = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedTag, setSelectedTag] = useState("");

  const articleId = 20; // NEED CHANGE TO ALL NEW ARTICLE

  const article = articles.find((article) => parseInt(article.id) === articleId);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const newArticles = articles.filter((article) => parseInt(article.id) !== articleId);
  const lastThreeArticles = [
    newArticles[newArticles.length - 1],
    newArticles[newArticles.length - 2],
    newArticles[newArticles.length - 3],
  ];
  
  return (
    <div style={{ background: "white" }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://app.01supply.com/meta-title-meta-description-examples" />
        <title>What are Meta descriptions & titles? | 01supply</title>
        <meta name="description"
          content="Learn more about Meta Titles and Meta Descriptions to optimize SEO on your website. We give you examples and tutorials on how to add them to your website." />
        
        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://app.01supply.com/meta-title-meta-description-examples" />
        <meta property="og:title" content={`What are Meta descriptions & titles? | 01supply`} />
        <meta property="og:description"
          content="Learn more about Meta Titles and Meta Descriptions to optimize SEO on your website. We give you examples and tutorials on how to add them to your website." />
        <meta property="og:type" content="article" />
        <meta property="og:image"
          content="https://app.01supply.com/static/media/What-are-meta-descriptions-and-meta-tiles.78649701.jpg" />
        
        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "What are Meta descriptions & titles?",
            "image": [
              "https://app.01supply.com/static/media/static/media/What-are-meta-descriptions-and-meta-tiles.78649701.jpg"
            ],
            "datePublished": "2022-10-11",
            "dateModified": "2022-10-11",
            "author": [
              {
                "@type": "Person",
                "name": "Diego Huberty",
                "jobTitle": "Editor in Chief"
              }
            ],
            "publisher": {
              "name": "01supply",
              "url": "https://app.01supply.com/"
            }
          }`}
        </script>
      </Helmet>

      <NavBar windowSize={windowSize} />

      <div style={{ paddingBottom: "150px", textAlign: "start" }} className="column center">
        <TagMenu
          setSelectedTag={setSelectedTag}
          selectedTag={selectedTag}
        />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            <ArticleHeaderNav article={article} />
            <CourseSponso
              imagePath="coursesImg/supplier/seo-google.jpg"
              imageTitle="Google Algorithm"
              price="199$"
              title="Learn & Master Google Algorithm from SEO market leaders"
              description="SEO allows you to acquire visitors for free. It is basically free advertising offered by Google. 
              Search engines have some advanced algorythm you can trick to get more visibility and sales online."
              coursePath="/academy/google-seo-course"
            />
            <Content />
            <Social article={article} />
            <ArticleFooter windowSize={windowSize} />
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article) => (
              <SuggestionCard article={article} />
            ))}
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Blog;
