import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { Modal } from "../../../wrappers";
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";
import Load from "../../../utils/Load";
import ConnectShop from "../../../components/reseller/settings/store/connectShop/index";
import UpdateAlerts from "../../../components/reseller/settings/store/updateAlerts";

import wizishopIcon from "../../../images/illustrations/wizishop.svg";
import dropiziIcon from "../../../images/illustrations/dropizi.svg";
import TranslationFeature from "../../../components/reseller/settings/translationFeature";
import { installShopifyEvent } from "../../../utils/analyticEvents";

const YourShop = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [load, setLoad] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [deleteShop, setDeleteShop] = useState(false);
  const [connectShop, setConnectShop] = useState(false);
  const [removeShop, setRemoveShop] = useState(false);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  useEffect(() => {
    if (user && user.email) {
      const location = window.location.href;
      if (location.includes("connect-shopify")) {
        setLoad(true);
        pushShopify(location);
      }
    }
  }, [user]);

  const pushShopify = async (location) => {
    let tokenShopify = location.split("token=")[1];
    tokenShopify = tokenShopify.split("&shop")[0];
    let shop = location.split("shop=")[1].replace("?account", "");
    shop = shop.replace("&persoapp=true", "");
    const shopify = {
      token: tokenShopify,
      shop: shop,
    };
    const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        shopify: shopify,
      }),
    });
    if (res.status === 200) {
      installShopifyEvent({ user });

      const res = await fetch(`${url}/sendgrid/template-email`, {
        method: "POST",
        credentials: "include",
        headers: header,
        body: JSON.stringify({
          emailToSend: user.email,
          templateId: "d-7915b196ea43432aac1430aec97aa818",
          templateData: {
            shop: shopify.shop,
          },
        }),
      });
      window.location.href = window.location.href.split("&connect-shopify")[0];
    } else {
      message.error(t("messages.error"));
      setTimeout(() => {
        window.location.href = window.location.href.split("&connect-shopify")[0];
      }, 3000);
    }
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  const saveChange = async (userEdit) => {
    let newUser = userEdit ? userEdit : { ...user };
    const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        user: newUser,
        updateShop: true,
      }),
    });
    if (res.status === 200) {
      setUser(newUser);
      message.success(t("messages.successfullyUpdated"));
    }
  };

  return (
    <div className="page">
      {load && <Load />}
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        {removeShop && (
          <Modal
            style={{ padding: "30px" }}
            className="modal-account"
            visible={true}
            onCancel={() => {
              setRemoveShop(false);
            }}
            footer={[
              <button
                style={{ marginRight: "15px" }}
                className="button grey"
                onClick={() => setRemoveShop(false)}
              >
                {t("cancel")}
              </button>,
              <button
                style={{ marginLeft: "15px" }}
                className="button-red"
                onClick={() => {
                  const userEdit = { ...user, shop: { ...user.shop, url: "", cms: "" } };
                  saveChange(userEdit);
                  setRemoveShop(false);
                  message.success(t("messages.storeRemoved"));
                }}
              >
                {t("delete")}
              </button>,
            ]}
          >
            <p className="title-bold">{t("resellerStore.1")}</p>
          </Modal>
        )}
        {deleteShop && (
          <Modal
            style={{ padding: "30px" }}
            className="modal-account"
            visible={true}
            onCancel={() => {
              setDeleteShop(false);
            }}
            footer={[]}
          >
            <div className="column" style={{ alignItems: "center" }}>
              <p className="title-200">
                {t("resellerStore.2")}
                <br />
              </p>
              <p style={{ marginTop: "15px", marginBottom: "25px" }} className="title">
                {t("resellerStore.3")}
              </p>
              <a
                style={{ marginTop: "0px" }}
                className="button orange-back"
                href={`https://${user.shopify.shop}/admin/apps`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("resellerStore.4")}
              </a>
            </div>
          </Modal>
        )}
        {connectShop && (
          <ConnectShop
            isDisplayed={connectShop}
            onCancel={() => setConnectShop(false)}
            user={user}
            setUser={setUser}
          />
        )}

        <div
          style={
            windowSize > 1150
              ? { paddingTop: "0px" }
              : { padding: "25px 5px", paddingTop: windowSize > 1150 ? "110px" : "0" }
          }
          className="column-scrolling"
        >
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "50px",
              alignItems: "flex-start",
            }}
          >
            {windowSize > 1150 ? (
              <>
                <MenuSetting user={user} />
                <div className="header">
                  <div
                    className="row"
                    style={
                      windowSize > 1150
                        ? { width: "100%", marginBottom: "-30px", height: "30px" }
                        : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                    }
                  >
                    <h1 className="title-bold montserrat">{t("resellerStore.5")}</h1>
                  </div>
                  <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
                </div>
              </>
            ) : (
              <MobileHeader nameHeader={t("resellerStore.5")} location={"YOUR SHOP"} />
            )}

            {!(user.shopify?.shop || user?.wizishop?.user_id || user.shop?.url) && user && user.email ? (
              <p
                style={{
                  alignItems: "center",
                  display: "flex",
                  width: windowSize > 1150 ? "calc(100% - 350px)" : "100%",
                  fontSize: "18px",
                }}
                onClick={() => setConnectShop(true)}
                className="text-bold blue row card pointer"
              >
                {t("resellerStore.6")}
              </p>
            ) : user?.shopify?.shop || user?.wizishop?.user_id ? (
              <div
                className="column"
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: windowSize > 1150 && "30px",
                  width: windowSize > 1150 ? "calc(100% - 350px)" : "100%",
                  marginTop: windowSize > 1150 ? "0" : "30px",
                }}
              >
                <div className="row">
                  {user.wizishop?.cms && (
                    <img
                      src={user.wizishop.cms?.includes("dropizi") ? dropiziIcon : wizishopIcon}
                      style={{ width: "80px", marginRight: "20px" }}
                      alt="cms logo"
                    />
                  )}
                  <p className="title-200 row">{user?.shopify?.shop || user?.wizishop?.shop_name}</p>
                </div>
                {user?.shopify?.shop && (
                  <a
                    style={{ marginTop: "15px" }}
                    href="https://apps.shopify.com/01supply"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text blue underline pointer"
                  >
                    {t("removeShopifyStore")}
                  </a>
                )}
                <TranslationFeature user={user} setUser={setUser} />
                <UpdateAlerts user={user} setUser={setUser} />
              </div>
            ) : (
              user.shop?.url && (
                <div
                  style={{
                    display: "flex",
                    width: windowSize > 1150 ? "calc(100% - 350px)" : "100%",
                    fontSize: "18px",
                    marginTop: "0",
                  }}
                  className="column card"
                >
                  <p className="title-bold">{t("resellerStore.13")}</p>
                  <p
                    style={{ marginTop: "10px", marginBottom: "5px", fontSize: "15px" }}
                    className="text-bold"
                  >
                    {t("resellerStore.14")}
                  </p>
                  <p style={{ width: "80%" }} className="text-ellipsis">
                    {user.shop.url}
                  </p>
                  <p
                    style={
                      windowSize > 1150
                        ? { position: "absolute", top: "20px", right: "30px", fontSize: "13px" }
                        : { marginTop: "20px" }
                    }
                    onClick={() => setRemoveShop(true)}
                    className="text-red underline pointer"
                  >
                    {t("resellerStore.15")}
                  </p>
                  <p
                    style={
                      windowSize > 1150
                        ? { position: "absolute", bottom: "20px", right: "30px", fontSize: "16px" }
                        : { marginTop: "10px" }
                    }
                    onClick={() => setConnectShop(true)}
                    className="text-bold blue underline pointer"
                  >
                    {t("edit")}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourShop;
