import React from "react";
import EtsyLabel from "./EtsyLabel";

import productNotFound from "../../images/illustrations/product_not_found.svg";
import { useTranslation } from "react-i18next";

const NotFoundProduct = ({ product }) => {
  const { t } = useTranslation();
  
  return (
    <div className="column width-100" style={{ alignItems: "center", marginTop: "70px" }}>
      <img style={{ width: "300px", maxWidth: "90vw" }} alt="product not found" src={productNotFound} />
      <p style={{ maxWidth: "90vw", textAlign: "center" }} className="title center">
        {product?.isDelete ? t("etsyProductNotFound") : t("productNotFound")}
      </p>
      <div style={{ width: "250px", marginTop: "10px" }}>
        {product?.origin_url && <EtsyLabel productUrl={product.origin_url} />}
      </div>
    </div>
  );
};

export default NotFoundProduct;
