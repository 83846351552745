import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/resellerCoaching/Navbar";
import Footer from "../../components/menu/Footer";
import { routes } from "../../routes/index";

import starterButtonImg from "../../images/resellerCoaching/button1.svg";
import advancedButtonImg from "../../images/resellerCoaching/advanced_button.svg";
import proButtonImg from "../../images/resellerCoaching/pro_button.svg";
import checkImg from "../../images/resellerCoaching/check.svg";

import "./style.scss";

const CoursePayment = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <div className="coaching-page">
      <Navbar noSticky={true} />
      <h1 className="title-bold montserrat-800">
        {t("resellerCoaching.presentation.1")}
        <br />
        <span className="orange">{t("resellerCoaching.presentation.2")}</span>
      </h1>
      <div style={{ width: "85vw", alignItems: "flex-start" }} className="row-wrap">
        <div className="card margin-right">
          <img src={starterButtonImg} className="button-img" alt="starter coaching" />
          <p style={{ margin: "30px 0" }} className="text-bold montserrat">
            {t("resellerCoaching.presentation.card1.1")}
          </p>
          <div className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card1.2")}</p>
          </div>
          <div style={{ marginTop: "20px" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card1.3")}</p>
          </div>
          <div style={{ margin: "20px 0" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card1.5")}</p>
          </div>
          <p className="title">$69.99</p>
          <p className="text grey">{t("resellerCoaching.oneTimePayment")}</p>
          <Link
            to={routes.resellerCoachingStarter(i18n.language).path}
            style={{ marginTop: "20px" }}
            className="button big orange-back width-100"
          >
            {t("resellerCoaching.learnMore")}
          </Link>
        </div>
        <div className="card margin-left">
          <img src={advancedButtonImg} className="button-img" alt="starter coaching" />
          <p style={{ margin: "30px 0" }} className="text-bold montserrat">
            {t("resellerCoaching.presentation.card2.1")}
          </p>
          <div className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p style={{ letterSpacing: "0.6px" }} className="text montserrat">
              {t("resellerCoaching.presentation.card2.2")}
            </p>
          </div>
          <div style={{ marginTop: "20px" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card2.3")}</p>
          </div>
          <div style={{ marginTop: "20px" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card2.4")}</p>
          </div>
          <div style={{ margin: "20px 0" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card2.5")}</p>
          </div>
          <p className="title">
            $99.99
            <span className="text grey">/{t("months")}</span>
          </p>
          <p className="text grey">{t("resellerCoaching.recuringCharge")}</p>
          <Link
            to={routes.resellerCoachingAdvanced(i18n.language).path}
            style={{ marginTop: "20px" }}
            className="button big orange-back width-100"
          >
            {t("resellerCoaching.learnMore")}
          </Link>
        </div>
        <div className="card margin-right">
          <img src={proButtonImg} className="button-img" alt="starter coaching" />
          <p style={{ margin: "30px 0" }} className="text-bold montserrat">
            {t("resellerCoaching.presentation.card3.1")}
          </p>
          <div className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p style={{ letterSpacing: "0.6px" }} className="text montserrat">
              {t("resellerCoaching.presentation.card3.2")}
            </p>
          </div>
          <div style={{ marginTop: "20px" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card3.3")}</p>
          </div>
          <div style={{ marginTop: "20px" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card3.4")}</p>
          </div>
          <div style={{ marginTop: "20px" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p className="text montserrat">{t("resellerCoaching.presentation.card3.5")}</p>
          </div>
          <div style={{ margin: "20px 0" }} className="row start">
            <img src={checkImg} className="check" alt="check" />
            <p style={{ letterSpacing: "0.6px" }} className="text montserrat">
              {t("resellerCoaching.presentation.card3.6")}
            </p>
          </div>
          <p className="title">
            $499.99
            <span className="text grey">/{t("months")}</span>
          </p>
          <p className="text grey">{t("resellerCoaching.recuringCharge")}</p>
          <Link
            to={routes.resellerCoachingPro(i18n.language).path}
            style={{ marginTop: "20px" }}
            className="button big orange-back width-100"
          >
            {t("resellerCoaching.learnMore")}
          </Link>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
  );
};

export default CoursePayment;
