import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Popover } from "antd";
import { CloseOutlined, InfoCircleFilled, DeleteOutlined } from "@ant-design/icons";
import imgIllustration from "../../../images/img_illustration.png";
import Checkbox from "antd/lib/checkbox/Checkbox";

import { Modal } from "../../../wrappers";

const Variants = ({ setProduct, product }) => {
  const [selectImg, setSelectImg] = useState(undefined);
  const [isCreateOption, setIsCreateOption] = useState(false);
  const [editedOption, setEditedOption] = useState(undefined);
  const [newOptionName, setNewOptionName] = useState("");

  const { t } = useTranslation();

  const handleUpdateVariant = ({ key, index, newValue }) => {
    const productVariants = [...product.variants];
    productVariants[index][key] = newValue;
    setProduct({ ...product, variants: [...productVariants] });
  };

  const handleCreateEditOption = () => {
    const newOptions = [...product.optionsVariants];
    if (editedOption !== undefined) {
      newOptions[editedOption] = newOptionName;
    } else {
      newOptions.push(newOptionName);
    }
    setProduct({ ...product, optionsVariants: [...newOptions] });

    setEditedOption(undefined);
    setNewOptionName("");
    setIsCreateOption(false);
  };

  const handleRemoveOption = (event, index) => {
    let stockOptions = [...product.optionsVariants];
    stockOptions.splice(index, 1);
    setProduct({ ...product, optionsVariants: stockOptions });
    event.preventDefault();
    event.stopPropagation();
  };

  const addNewVariant = () => {
    const variantModel = {
      image: "",
      option1: "",
      option2: "",
      option3: "",
      price: "",
      comparedPrice: "",
      quantity: "",
    };
    setProduct({ ...product, variants: [...product.variants, variantModel], variantBool: true });

    if (product.optionsVariants.length < 1) setIsCreateOption(true);
  };

  return (
    <div
      id="variants"
      style={{ width: "100%", marginRight: "30px", overflow: "hidden", position: "relative" }}
      className="card"
    >
      <Popover
        content={t("components.supplier.createEditProduct.variants.1")}
        title={t("variantsConfiguration")}
      >
        <InfoCircleFilled
          style={{ position: "absolute", right: "15px", top: "15px", fontSize: "18px" }}
          type="primary"
          className="grey pointer"
        />
      </Popover>
      {(selectImg || selectImg === 0) && (
        <Modal
          className="modal-no-ok"
          title={t("components.supplier.createEditProduct.variants.8")}
          visible={true}
          onOk={() => setSelectImg(undefined)}
          onCancel={() => setSelectImg(undefined)}
        >
          {product.photos?.map((img, index) => {
            return (
              <img
                className="img-select-variant"
                src={img.base64}
                onClick={() => {
                  handleUpdateVariant({ key: "image", index: selectImg, newValue: index.toString() });
                  setSelectImg(undefined);
                }}
              />
            );
          })}
        </Modal>
      )}
      {(isCreateOption || editedOption) && (
        <Modal
          className="modal-no-ok"
          title={t("components.supplier.createEditProduct.variants.2")}
          visible={true}
          onOk={handleCreateEditOption}
          onCancel={() => {
            setNewOptionName("");
            setIsCreateOption(false);
            setEditedOption(undefined);
          }}
        >
          <Input
            maxLength={30}
            onPressEnter={handleCreateEditOption}
            placeholder={t("components.supplier.createEditProduct.variants.3")}
            onChange={(e) => setNewOptionName(e.target.value)}
            autoFocus
            defaultValue={product[editedOption] || ""}
          />
        </Modal>
      )}
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        Options
      </p>

      <div style={{ width: "100%", marginBottom: "20px" }} className="row">
        <Checkbox
          checked={product.variantBool}
          onChange={(e) => setProduct({ ...product, variantBool: e.target.checked })}
        >
          {t("components.supplier.createEditProduct.variants.4")}
        </Checkbox>
      </div>
      <div className="scroll-x" style={{ width: "100%" }}>
        <div
          style={{ width: "calc(100% - 52px)", marginBottom: "10px", marginTop: "20px", marginLeft: "52px" }}
          className="row"
        >
          {product.optionsVariants?.map((option, optionIndex) => (
            <p
              onClick={() => setEditedOption(`option${optionIndex}`)}
              style={{
                minWidth: "100px",
                marginRight: "20px",
                cursor: "pointer",
                height: "22px",
                maxHeight: "22px",
                minHeight: "22px",
              }}
              className="text-bold pointer row text-ellipsis"
            >
              {option}
              <CloseOutlined
                onClick={(e) => handleRemoveOption(e, optionIndex)}
                style={{ fontSize: "13px", marginLeft: "10px", color: "black", fontWeight: "bold" }}
              />
            </p>
          ))}
          <p
            style={{
              minWidth: "90px",
              width: "90px",
              marginRight: "20px",
            }}
            className="text-bold"
          >
            {t("price")}
          </p>
          <p
            style={{
              minWidth: "130px",
              width: "130px",
              marginRight: "20px",
            }}
            className="text-bold"
          >
            {t("components.supplier.createEditProduct.variants.5")}
          </p>
          <p
            style={{
              minWidth: "110px",
              width: "110px",
              marginRight: "20px",
            }}
            className="text-bold"
          >
            {t("quantity")}
          </p>
          {product.optionsVariants?.length < 3 && (
            <p
              style={{
                minWidth: "130px",
                width: "130px",
                fontSize: "12px",
              }}
              onClick={() => setIsCreateOption(true)}
              className="text-bold blue pointer underline"
            >
              {t("components.supplier.createEditProduct.variants.6")}
            </p>
          )}
        </div>

        {product.variants.map((variant, i) => {
          return (
            <div className="row" style={{ width: "100%", marginBottom: "15px" }}>
              <img
                src={product.photos?.[variant.image]?.base64 || imgIllustration}
                className="img-variant"
                onClick={(e) => setSelectImg(i)}
                alt="variant selected img"
              />
              {product.optionsVariants.map((option, index) => (
                <Input
                  maxLength={30}
                  style={{ minWidth: "100px", width: "100px", marginRight: "20px", height: "33px" }}
                  defaultValue={variant[`option${index + 1}`]}
                  onChange={(e) =>
                    handleUpdateVariant({ key: `option${index + 1}`, index: i, newValue: e.target.value })
                  }
                  placeholder={product.optionsVariants[index]}
                />
              ))}
              <Input
                max={29999}
                min={1}
                maxLength={8}
                style={{
                  minWidth: "90px",
                  width: "90px",
                  marginRight: "20px",
                  height: "33px",
                }}
                defaultValue={variant.price}
                onChange={(e) => handleUpdateVariant({ key: "price", index: i, newValue: e.target.value })}
                placeholder={t("price")}
                type="number"
                step="0.01"
                stringMode
              />
              <Input
                max={29999}
                min={1}
                maxLength={8}
                style={{
                  minWidth: "130px",
                  width: "130px",
                  marginRight: "20px",
                  height: "35px",
                  height: "33px",
                }}
                defaultValue={variant.comparedPrice}
                onChange={(e) =>
                  handleUpdateVariant({ key: "comparedPrice", index: i, newValue: e.target.value })
                }
                placeholder={t("components.supplier.createEditProduct.variants.5")}
                step="0.01"
                type="number"
              />
              <Input
                max={9999}
                min={1}
                maxLength={5}
                style={{ minWidth: "110px", width: "110px", marginRight: "20px", height: "33px" }}
                defaultValue={variant.stock}
                onChange={(e) => handleUpdateVariant({ key: "stock", index: i, newValue: e.target.value })}
                placeholder={t("quantity")}
                type="number"
              />
              <div
                onClick={() => {
                  let stockVar = [...product.variants];
                  stockVar.splice(i, 1);
                  setProduct({ ...product, variants: stockVar });
                }}
                className="button-red-400"
                style={{ height: "33px" }}
              >
                <DeleteOutlined style={{ marginRight: "10px" }} color="red" />
                {t("delete")}
              </div>
            </div>
          );
        })}
      </div>
      <p style={{ marginTop: "20px" }} onClick={addNewVariant} className="text-bold blue pointer underline">
        {t("components.supplier.createEditProduct.variants.7")}
      </p>
    </div>
  );
};

export default Variants;
