import React, { useState, useEffect } from "react";
import { url } from "../../api/url";
import { header } from "../../api/header";
import Product from '../../components/admin/manageThematics/Product'
import {category} from '../../data/Category'

const ManageThematicBySupplier = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [createrId, setCreaterId] = useState('')
  const [products, setProducts] = useState([])
  const [thematics, setThematics] = useState([])
  const [noProductsBool, setNoProductsBool] = useState(false)

  useEffect(() => {
    let createrId = window.location.href.split("?");
    if (createrId[1]) createrId = createrId[1];
    else createrId = "";

    setCreaterId(createrId)

    if (createrId) getSupplierProduct(createrId);

    findThematics()
  }, []);

  const getSupplierProduct = async (createrId) => {
    const res = await fetch(`${url}/product/find-by-creater/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      if(resJson.data.length)
      setProducts([...resJson.data].reverse());
      else setNoProductsBool(true)
    }
  };

  const findThematics = async (createrId) => {
    const res = await fetch(`${url}/thematic/find-all`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setThematics(resJson.thematics)
    }
  };

  return (
    <div
      style={{ padding: "50px", paddingLeft: "5vw", paddingRight: "5vw" }}
      className="page"
    >
        {products.length && !noProductsBool ? products.map(product => {
            return(
                <Product categorys={category} product={product} thematics={thematics}/>
            )
        })
      : noProductsBool && <p style={{fontSize: "30px"}}>No products</p> }
    </div>
  );
};

export default ManageThematicBySupplier;
