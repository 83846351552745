import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";
import '../../style.scss'

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article13/What-is-the-best-size-for-product-images.jpg";
import imageSize from "../../../../images/blog/global/article13/good-image-size-for-ecommerce.jpg";
import imageWeight from "../../../../images/blog/global/article13/lower-image-weight.jpg";
import jpgPng from "../../../../images/blog/global/article13/jpg-or-png.jpg";
import supply from "../../../../images/blog/global/article13/01supply-images-requirements.jpg";
import shopify from "../../../../images/blog/global/article13/shopify-image-requirements.jpg";
import woocommerce from "../../../../images/blog/global/article13/wordpress-image-requirements.jpg";
import etsy from "../../../../images/blog/global/article13/etsy-image-requirements.jpg";
import amazon from "../../../../images/blog/global/article13/amazon-image-requirements.jpg";
import squarespace from "../../../../images/blog/global/article13/squarespace-image-requiremetns.jpg";
import shopee from "../../../../images/blog/global/article13/shopee-image-requirements.jpg";
import wix from "../../../../images/blog/global/article13/wix-image-requirements.jpg";
import magento from "../../../../images/blog/global/article13/magento-image-size-requirements.jpg";








const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="start">
        What is the best size for product images?
      </h1>
      <p className="text-300">23 september 2022 - 7 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px" }} alt="What is the best size for product images?" />
      <p style={{ marginTop: "15px", marginBottom: '20px' }} className="start">
        It’s always a struggle to remember what image size you should choose for your products. Some software like Shopify will allow you to have 4472x4472px images while others will have way larger limits.</p><p style={{ marginBottom: '20px' }} className="start">
        Also, it is good to know that images can be optimized to <b>increase your store speed</b>, your conversions and <b>drive more sales</b>.</p>
      <p style={{ marginBottom: '20px' }} className="start">You can <b>use this article as a checklist</b> and a product image database to check at any time.{" "}
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#good-image-ecommerce" title="What is a good image size for e-commerce?" >
              <Anchor.Link href="#how-many-kb" title="How many Kb should an image be?" />
            </Anchor.Link>
            <Anchor.Link href="#reduce-image-size" title="How to reduce image size (in Kb)?" />
            <Anchor.Link href="#image-format" title="How to choose the image format?">
              <Anchor.Link href="#JPG-PNG-difference" title="The difference between JPG and PNG" />
            </Anchor.Link>
            <Anchor.Link href="#tools-compress-images" title="5 best tools to compress images (for free)" />
            <Anchor.Link href="#best-image-format-ecommerce" title="What is the best image format for ecommerces?" />
            <Anchor.Link href="#take-great-pictures-online-store" title="How to take great images for your e-commerce store?" />

          </Anchor>
        }
      />
      <p style={{ marginTop: "15px" }} className="start">
        <h2 id="good-image-ecommerce">✨ What is a good image size for e-commerce?</h2>
        <p style={{ marginBottom: '20px' }}>Images size impacts your website speed.</p>
        <u>Here are some basic recommendations we have for you</u>:
        <br /><br />
        <ul>
          <li>Quick load - Low quality: 640 x 640</li>
          <li>Medium load - Medium quality: 800 x 800</li>
          <li>Heavy load - High quality: 1000 x 1000</li>
        </ul>
        <img src={imageSize} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Best image size ecommerce" />
        <p style={{ marginBottom: '20px' }}>Note that on most websites, even with a 640 x 640px quality, the visitors won’t notice it as the product image doesn't take up more than 30% of the screen space.
          If you website design display large images, then you should of course make sure the quality is higher.</p>
        <h3 id="how-many-kb">How many Kb should a product image be?</h3>
        <p style={{ marginBottom: '20px' }}>A great rule to keep your website fast is to upload product images <b>below 70 kilobytes.</b></p>
        <p style={{ marginBottom: '20px' }}>Make sure that your image is never bigger than 1Mb. If your image is that heavy, it will impact your website performance for sure.
          Indeed, Image weight is often what makes websites and online stores slow. Remember that your website visitors don’t need to zoom (x1000),
          so keep your image size reasonable.</p>
        <p style={{ marginBottom: '20px' }}>An image size for e-commerce should <b>never exceed 2.000 x 2.000px.</b></p>
        <img src={imageWeight} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Lower image weight" />
        <h2 id="reduce-image-size">📉 How to reduce image size (in Kb)?</h2>
        <p style={{ marginBottom: '20px' }}>There are three ways of reducing the image weight:</p>
        <ol>
          <li>You can either <b>lower the size</b> of the image. For example, transforming a 1000 x 1000px into a 500 x 500px would cut the Kb in two.</li>
          <li>You can also <b>compress your images</b> thanks to online tools. We will talk about it later in this article.</li>
          <li>You can also <b>select another image format</b>. Choose a format that will save Kb. Therefore here is a guide on how to choose the best image format.</li>
        </ol>
        <h2 id="image-format">🔎 How to choose the image format?</h2>
        <h3>GIF</h3>
        <p style={{ marginBottom: '20px' }}>
          Gif is a format <b>for animated images</b>. You can transform a video into a gif to display several images in a row.
          You can make a GIF repeat so it creates a loop. GIFs are often used for creating fun and short videos mostly used on social media.
        </p>
        <p style={{ marginBottom: '20px' }}>Note that GIFs are not relevant for product images in e-commerce. You can still use GIFs in your product description to showcase the utility of a product.
        </p>
        <p style={{ marginBottom: '20px' }}>GIF weight really depends on its length and quality. As it has its own ability, GIF is not comparable to other image formats.
          Do not use GIFs if you are not planning to create a short “video”.
        </p>
        <h3>JPG/JPEG</h3>
        <p style={{ marginBottom: '20px' }}>JPG and JPEG are the same formats. They are just called differently because of the evolution of Windows formats.
        </p>
        <p style={{ marginBottom: '20px' }}><b>JPG is great for plain images</b> and also a good choice for product images. JPG is not suitable for transparent backgrounds.
          When you export an image into the PNG format, the background will be white by default.
        </p>
        <p style={{ marginBottom: '20px' }}>A great and optimized size for JPG should be between 50 and 100Kb.
        </p>
        <h3>PNG</h3>
        <p style={{ marginBottom: '20px' }}>PNG format is <b>used mainly for its transparency feature</b>. With PNG you can for example export a house image, without any background.
          This allows you to add this PNG image with a background from your own website.
        </p>
        <p style={{ marginBottom: '20px' }}>PNG images are heavier as they contain more information for transparency. Indeed, your computer has to calculate the image outlines, etc…
        </p>
        <p style={{ marginBottom: '20px' }}>A great and optimized <b>PNG image should not weight more than 200Kb</b>.
        </p>
        <p style={{ marginBottom: '20px' }}>PNG should only be used if you want your background to be transparent. If not, you should choose JPG,... or WEBP.
        </p>
        <h3>WEBP</h3>
        <p style={{ marginBottom: '20px' }}>WEBP is a <b>better compressed JPG image</b>. Note that WEBP is often not usable for edition, unlike JPG.
          For example, you can’t edit a WEBP image in Photoshop.
        </p>
        <p style={{ marginBottom: '20px' }}>WEBP is great for static images and therefore is a great choice for product images.
          Note that every software is not capable of handling WEBP as it is a fairly new format.</p>
        <p style={{ marginBottom: '20px' }}>If you know you won’t edit your image later and if you can export in WEBP, you can do so.
          Indeed, lighter images will allow your website to load faster.
        </p>
        <p style={{ marginBottom: '20px' }}>A great optimized <b>WEBP image should be between 30 and 70Kb</b>.
        </p>
        <h3>SVG</h3>
        <p style={{ marginBottom: '20px' }}>SVG means Scalable Vector Graphic. Unlike all the other formats, SVG does not contain pixel information,
          indeed SVG just contains code and maths.
        </p>
        <p style={{ marginBottom: '20px' }}>The specificity of SVG is that it is <b>endlessly scalable without losing image quality</b>.
          As the image is displayed thanks to maths, it allows you to expand it and the computer will just calculate how to render it.
        </p>
        <p style={{ marginBottom: '20px' }}>SVG does only work for simple images like logos, shapes, and easy-to-understand images.
          It does not work for detailed images or for product images.
        </p>
        <p style={{ marginBottom: '20px' }}>The strength of SVG is its weight. Indeed, SVG images can weigh as low as 1Kb.
          A good <b>SVG image should always weigh less than 10Kb</b>.
        </p>
        <img src={jpgPng} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Difference between JPG and PNG" />
        <h3 id="JPG-PNG-difference">🆚 The difference between JPG and PNG
        </h3>
        <p style={{ marginBottom: '20px' }}>What is better between these two formats? In fact, both are designed for unique usage.
        </p>
        <ul>
          <li>JPG is an image format for plain images, with a background. They are lighter than PNG.
          </li>
          <li>PNG images have the ability to showcase a transparent background. This is often used for logos and images that should still display the website background. Indeed, you don’t want your logo to have a white background as it won’t integrate nicely on your website that has a black background.
          </li>
        </ul>
        <h2 id="tools-compress-images">💾 5 best tools to compress images (for free)
        </h2>
        <p>Compressing images can save you up to 30% and make your website run faster.
          Here is a selection of 5 tools you should use if you want your images to be optimized:
        </p>
        <h3>Tiny PNG</h3>
        <p style={{ marginBottom: '20px' }}>Allowed formats:
        </p>
        <ul>
          <li>JPG</li>
          <li>PNG</li>
          <li>WEBP</li>
        </ul>
        <p style={{ marginBottom: '20px' }}><a className="blue" href="https://tinypng.com/" title="Tiny PNG" rel="nofollow" target="blank_"><u>TinyPNG</u></a> is an easy-to-use online tool that
          can save you up to 50% on some images.
          You can upload up to 20 images at a time, 5Mb each.
        </p>
        <h3>JPEG Optimizer</h3>
        <p style={{ marginBottom: '20px' }}>As you can imagine, <a className="blue" href="https://jpeg-optimizer.com/" title="Jpeg Optimizer" rel="nofollow" target="blank_"><u>JPEG Optimizer</u></a> is only for JPEG.
          You can choose the quality output you want. Indeed you can select a percentage for quality (for example 75% of the actual image).
          You can also set a maximum weight output (For example 3Mb max.).
        </p>
        <h3>Optimizilla</h3>
        <p style={{ marginBottom: '20px' }}>Allowed formats:
        </p>
        <ul>
          <li>JPG</li>
          <li>PNG</li>
          <li>GIF</li>
        </ul>
        <p style={{ marginBottom: '20px' }}><a className="blue" href="https://imagecompressor.com/" title="Optimizilla" rel="nofollow" target="blank_"><u>Optimizilla</u></a> is very similar to TinyPNG and allows you to queue up to 20 images in a row.
        </p>
        <h3>Compressor.io</h3>
        <p style={{ marginBottom: '20px' }}><a className="blue" href="https://imagecompressor.com/" rel="nofollow" title="Compressor.io" target="blank_"><u>Compressor.io</u></a> allows those formats:
        </p>
        <ul>
          <li>JPG</li>
          <li>PNG</li>
          <li>GIF</li>
          <li>SVG</li>
          <li>WEBP</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>You can send up to 10Mb at a time. Note that compressor.io allows you to independently select the optimization strength you want for
          each format. For example, you could say that you want to retain 80% quality for JPG images, and 50% quality for PNG images.
        </p>
        <h3>Kraken</h3>
        <p style={{ marginBottom: '20px' }}><a className="blue" href="https://kraken.io/web-interface" rel="nofollow" title="Kraken" target="blank_"><u>Kraken.io</u></a> can be installed on Magento and WordPress store at he has a direct integration.
        </p>
        <p style={{ marginBottom: '20px' }}>Kraken supports:</p>
        <ul>
          <li>JPG</li>
          <li>PNG</li>
          <li>GIF</li>
          <li>SVG</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>You can only upload 1Mb at a time.
        </p>
        <h2 id="best-image-format-ecommerce">🖼️ What is the best image format for ecommerces?
        </h2>
        <h3>01supply</h3>
        <img src={supply} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="01supply image formats" />
        <p style={{ marginBottom: '20px' }}>01supply supports:</p>
        <ul>
          <li>JPG</li>
          <li>JPEG</li>
          <li>PNG</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>To make sure your store is always loading fast, the image weight limit is set at 2Mb.
        </p>
        <h3>Shopify</h3>
        <img src={shopify} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Shopify image formats" />
        <p style={{ marginBottom: '20px' }}>Shopify supports the following image formats:
        </p>
        <ul>
          <li>JPEG</li>
          <li>PNG</li>
          <li>GIF</li>
          <li>HEIC</li>
          <li>WEBP</li>
          <li>AVIF</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>Shopify max image weight is set at 20Mb.
        </p>
        <h3>Woocommerce / Wordpress / Elementor
        </h3>
        <img src={woocommerce} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Wordpress image formats" />
        <p style={{ marginBottom: '20px' }}>Wordpress supports the following image formats:
        </p>
        <ul>
          <li>JPG/JPEG
          </li>
          <li>PNG</li>
          <li>GIF</li>
          <li>ICO (for favicons)</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>If you want to upload WebP or SVG images on WordPress, you must use plugins.
          Depending on your hosting, you can upload images of up to 150Mb on Wordpress.
        </p>
        <h3>Etsy</h3>
        <img src={etsy} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Etsy image formats" />
        <p style={{ marginBottom: '20px' }}>Etsy image formats are quite limited. Indeed Etsy only allows:
        </p>
        <ul>
          <li>JPG
          </li>
          <li>PNG</li>
          <li>GIF</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>Etsy also does not recommend images larger than 1Mb.
        </p>
        <h3>Amazon</h3>
        <img src={amazon} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Amazon image formats" />
        <p style={{ marginBottom: '20px' }}>Amazon accepts the following image formats:
        </p>
        <ul>
          <li>JPG
          </li>
          <li>TIFF</li>
          <li>GIF</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>Note that Amazon is the only website where images should be 1,600 pixels or larger on the longest side.
          This allows them to make the product image zoom work in any case while still having a great image quality.
        </p>
        <p style={{ marginBottom: '20px' }}>The image weight limit is set at 10Mb on Amazon.
        </p>
        <h3>Squarespace</h3>
        <img src={squarespace} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Squarespace image formats" />
        <p style={{ marginBottom: '20px' }}>Squarespace accepts the following formats:
        </p>
        <ul>
          <li>JPG
          </li>
          <li>PNG</li>
          <li>GIF</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>Squarespace limits image weight to 1.5Mb.
        </p>
        <h3>Shopee</h3>
        <img src={shopee} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Shopee image formats" />
        <p style={{ marginBottom: '20px' }}>Shopee only allows two formats:
        </p>
        <ul>
          <li>JPG
          </li>
          <li>PNG</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>Note that images can’t exceed 2Mb.
        </p>
        <h3>Wix</h3>
        <img src={wix} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Wix image formats" />
        <p style={{ marginBottom: '20px' }}>Wix supports a large range of formats:
        </p>
        <ul>
          <li>JPG/JPEG
          </li>
          <li>PNG</li>
          <li>GIF</li>
          <li>JPE</li>
          <li>JFIF</li>
          <li>BMP</li>
          <li>HEIC</li>
          <li>HEIF</li>
          <li>TIFF/TIF</li>
          <li>WEBP</li>
          <li>JPEG200</li>
          <li>RAW</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>Wix has a large image weight limit of 40Mb.
        </p>
        <h3>Magento</h3>
        <img src={magento} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Magento image formats" />
        <p style={{ marginBottom: '20px' }}>Magento allows 3 standard formats:
        </p>
        <ul>
          <li>JPG
          </li>
          <li>PNG</li>
          <li>GIF</li>
        </ul>
        <p style={{ marginBottom: '20px' }}>If you want to use WebP or other formats, you should use Magento extensions.
          Note that Magento limits to 5Mb for image weight, but you can increase it thanks to coding.
        </p>
        <h2 id="take-great-pictures-online-store">📸 How to take great images for your e-commerce store?
        </h2>
        <p style={{ marginBottom: '20px' }}><b>If you create your own products</b>, you may encounter some problems while taking product pictures.
          <b>Product photography is often expensive</b> if you want to hire someone. This is even more true if you have hundreds of products to shoot.
          And we are not even mentioning the fact that each product has to have multiple pictures for the user experience.
          <b>This easily raises the bill to thousands of dollars.</b>
        </p>
        <p style={{ marginBottom: '20px' }}>But with some easy steps, <b>you could do this job yourself at home</b>. And even if you don’t have an expensive camera,
          you can do great pictures with your smartphone if you understand some secret tips and tricks.
        </p>
        <p style={{ marginBottom: '20px' }}>Everything often relies on lighting, decor, and some settings.
          Afterward we will give you some equipment recommendations if you want to dive further into product photography.
        </p>
        <p style={{ marginBottom: '20px' }}>We have a course you can follow to master product photography, even if you only have a smartphone.
          This will help you save thousands of dollars in professional service fees.
        </p>
        <p style={{ marginBottom: '20px' }}><b>Check the course here: </b><a className="blue" href="/academy/photo-mastery-course" title="Product photography course" target="_blank"><u><b>Photo Mastery</b></u></a>
        </p>
        {" "}




      </p>
    </div>
  );
};

export default Content;
