import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article24/ecommerce-coaching.jpg";
import coachingOffers from "../../../../images/blog/global/article24/coaching-offers.jpg";
import ecommerceSkills from "../../../../images/blog/global/article24/ecommerce-skills.jpg";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Ecommerce coaching programs (Best mentor)
      </h1>
      <p className="text-300">14 november 2022 - 4 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="ecommerce coaching" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        You may be starting in e-commerce and you would like to have a <b>custom accompaniment</b> through your journey.
        <br/>Or you may have a running e-commerce store and you are struggling making more sales and more profit.
      </p>
      <p style={{ marginBottom: "20px" }}>
        In this article, we will discuss how you can <b>choose a great ecommerce coaching program</b> that suits your needs.
        Indeed, the main advantage of a coaching compared to an online course, is that the coaching will be tailor-made depending on your resources and your situation.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#prepare-for-ecommerce" title="How do I prepare for e-commerce?" />
            <Anchor.Link href="#what-learn-ecommerce" title="What do you learn in e-commerce?" />
            <Anchor.Link href="#manage-ecommerce-store" title="How do you manage an e-commerce store?" />
            <Anchor.Link href="#ecommerce-coaching-mentor" title="E-commerce coaching mentor" />
            <Anchor.Link href="#ecommerce-coaching-programs" title="E-commerce coaching programs" />
          </Anchor>
        }
      />
      <h2 id="prepare-for-ecommerce">How do I prepare for e-commerce?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Before starting, it is good to write down your goals and expectations if you are an e-commerce beginner.
        Based on this details, we will be able to determine how you want to work in e-commerce.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here are some pathes you can choose in e-commerce</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Classic e-commerce</b>: You purchase products in bulk, you stock them in your warehouse and you handle the shipping to the final customer.
        </li>
        <li>
          <b>Dropshipping</b>: You don’t own stock, you only order when you make sales and you ask the supplier to directly ship from the factory to the final customer.
        </li>
        <li>
          <b>Amazon FBA</b>: You purchase products in bulk and you stock them in an Amazon fulfillment center.
          Amazon will handle storage and shipping of your orders thanks to the stock you sent them.
        </li>
        <li>
          <b>Courses/info-products</b>: You can also sell virtual goods which removes the logistic part as no package has to be delivered to the customer.
        </li>
      </ul>
      <h2 id="what-learn-ecommerce">What do you learn in e-commerce?</h2>
      <img src={ecommerceSkills} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="ecommerce skills" />
      <p style={{ marginBottom: "20px" }}>
      Depending on the type of e-commerce you make, you will learn very different things. It also depends a lot on the software you use. 
      Indeed, there is a plethora of ecommerce software online like Shopify, Woocommerce, Klavyio, Mailchimp, Squarespace, etc… 
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is a quick list of what you could have to learn in e-commerce</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>Marketing strategies</li>
        <li>Online advertising</li>
        <li>Copywriting</li>
        <li>Product Photography</li>
        <li>Logistics</li>
        <li>Accounting</li>
        <li>Laws, customs, taxes</li>
        <li>Import-Export</li>
        <li>Pricings</li>
        <li>Analytics & Data</li>
      </ul>
      <h2 id="manage-ecommerce-store">How do you manage an e-commerce store?</h2>
      <p style={{ marginBottom: "20px" }}>
      <b>Everyday work of an e-commerce owner</b> varies a lot. If you manage your own stock, you may be spending a lot of your time <b>preparing orders</b>, 
      filling up and updating your inventory. If you create online courses, you may be spending a lot of time creating videos, podcasts, etc… 
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is the typical e-merchant routine</u>:
      </p>
      <ol style={{ marginBottom: "20px" }}>
      <li>Looking for audiences and markets to target</li>
      <li>Finding products for this audience</li>
      <li>Creating/Optimizing online store</li>
      <li>Making SEO</li>
      <li>Writing product descriptions</li>
      <li>Writing blog articles</li>
      <li>Fulfilling orders</li>
      <li>Managing ads on Facebook or Google</li>
      <li>Feed social networks with new content and offers</li>
      <li>Creating mail campaigns and flows</li>
      <li>Negociating prices and purchases with suppliers</li>
      <li>Taking care of customer service, returns and disputes</li>
      </ol>
      <h2 id="ecommerce-coaching-mentor">Ecommerce coaching mentor
</h2>
      <p style={{ marginBottom: "20px" }}>
      There are a lot of e-commerce guru’s out there. But most of them are making a living out of sales from online courses. 
      They don’t actually own an e-commerce store (anymore). That is why at 01supply we help you <b>connect with real e-commerce owners</b> that will act as mentor for you. 
      01supply is a platform that connects online e-merchants with local and handmade suppliers. 
      Therefore <b>we can find good mentors that are making sales</b> and that are OK to coach some beginners. 
      </p>
      <p style={{ marginBottom: "20px" }}>
      In the 01supply Academy portal, you can <b>find several coaching offers</b> and choose the one that is best for you. 
      You can also <b>book a free 5 minutes call</b> with a coach that will quickly establish a first contact and answer your questions.
      </p>
      <h3>01supply Academy</h3>
      <p style={{ marginBottom: "20px" }}>
      01supply.com founded the 01supply Academy to help e-merchants get more sales online and <b>eliminate the e-commerce logistic and marketing issues</b>. 
      </p>
      <p style={{ marginBottom: "20px" }}>
      Thanks to our e-commerce experience, the <b>01supply Academy coaches more than 450 online resellers</b> and helps them reach their goals <b>3 times faster than average</b>. 
      </p>
      <h2 id="ecommerce-coaching-programs">E-commerce coaching programs</h2>
      <a href="https://app.01supply.com/ecommerce-coaching-presentation" alt="ecommerce coaching offers" target="blank_">
      <img src={coachingOffers} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="ecommerce coaching programs offer" />
      </a>
      <p style={{ marginBottom: "20px" }}>
      There are three 01supply accompaniment programs:
      </p>
      <h3>Starter Package</h3>
      <p style={{ marginBottom: "20px" }}>
      The starter package is just an <b>introduction for very beginners</b>. We answer your questions and we help you define a clear vision regarding your e-commerce project. 
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Therefore, we provide two services</u>:
      </p>
      <ul>
        <li>
        <b>Website analysis</b>: We review your online store and give you complete feedback on improvements you can make.
        </li>
        <li><b>30 min. Q&A session</b>: We answer your questions during a 30 minutes call.
</li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
      Bonus: You receive three marketing crash courses.
      </p>
      <a href="https://app.01supply.com/e-commerce-starter-coaching" alt="Starter Coaching" className="blue text-300" target="blank_"><u>Learn more</u></a>
      <h3>Advanced Accompaniment</h3>
      <p style={{ marginBottom: "20px" }}>
      In the advanced accompaniment, we define your goals and your resources. 
      Based on that <b>we help you reach this goal as fast as possible</b> thanks to our tips and strategies. 
      It is a great option either for beginners or for more advanced e-commerce owners as we adapt our recommendations to your personal situation.
      </p>
      <p style={{ marginBottom: "20px" }}>
      <u>The advanced accompaniment contains two services</u>: 
      </p>
      <ul>
        <li>
        <b>One-hour coaching per month</b>: we follow your progress month after month and we establish strategies you can apply during the month.
        </li>
        <li>
        <b>Weekly strategies</b>: you receive weekly e-commerce strategies via email.
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
      Bonus: You receive five marketing crash courses.
      </p>
      <a href="https://app.01supply.com/e-commerce-advanced-coaching" alt="Advanced Coaching Ecommerce" className="blue text-300" target="blank_"><u>Learn more</u></a>
      <h3>Pro Accompaniment</h3>
      <p style={{ marginBottom: "20px" }}>
      The pro accompaniment is made for those who really want to get serious in business. 
      It is the perfect offer for e-commerce owners that already make sales. <b>We give you full tailor-made coaching month after month</b> to help you achieve your goals. 
      </p>
      <p style={{ marginBottom: "20px" }}>
      <u>Here are the three services we provide in the pro accompaniment</u>:
      </p>
      <ul>
        <li>
        <b>Three hours of coaching a month</b>: we follow your progress month after month and we establish strategies you can apply during the month.
        </li>
        <li>
          <b>Weekly strategies</b>: you receive weekly e-commerce strategies via email.</li>
        <li>
          <b>Full marketing course</b> on a specific topic from the 01supply Academy catalog.</li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
      Bonus: You receive eight marketing crash courses.
      </p>
      <a href="" alt="" className="blue text-300" target="blank_"><u>Learn more</u></a>



    </div>
  );
};

export default Content;
