import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Space } from "antd";

import {Modal} from '../../../wrappers'
import filterImg from "../../../images/icons/Filters.svg";

const InventorySellerSort = ({ getProducts, setLoad, windowSize }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          marginRight: windowSize > 1150 && "10px",
          marginLeft: windowSize <= 1150 && "10px",
          width: windowSize <= 1150 && "100%",
        }}
      >
        <button
          style={{ width: windowSize <= 1150 ? "100%" : "110px", marginTop: "0" }}
          onClick={() => setIsDisplayed(true)}
          className="button-grey row"
        >
          <img alt="sort icon" src={filterImg} style={{ marginRight: "10px", height: "15px" }} />
          <p className="text-bold">{t("sorting.sorting")}</p>
        </button>
      </div>
      <Modal footer={[]} onCancel={() => setIsDisplayed(false)} visible={isDisplayed}>
        <div className="column">
          <p style={{ marginBottom: "15px" }}>{t("sortBy")}</p>
          <Radio.Group
            direction="column"
            defaultValue="mostRecent"
            onChange={(e) => {
              setLoad(true);
              getProducts({ sortBy: e.target.value });
              setIsDisplayed(false);
            }}
          >
            <Space direction="vertical">
              <Radio value="mostRecent">{t("inventory.moreRecent")}</Radio>
              <Radio value="syncProblem">{t("inventory.syncProblem")}</Radio>
            </Space>
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
};

export default InventorySellerSort;
