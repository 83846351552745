import React from "react";
import {useTranslation} from 'react-i18next'

import { phoneCodes } from "./CountryCodeList";
import { Select } from "antd";

const { Option } = Select;

const CountryCodeSelector = ({ setValue, value }) => {
  const { t } = useTranslation()

  const onChange = (value) => {
    setValue(value);
  };

  return (
    <Select
      showSearch
      onChange={onChange}
      style={{width: '100%'}}
      className={!value && "border-red"}
      placeholder={t('search')}
      optionFilterProp="children"
      value={value}
      filterOption={(input, option) => option.children[3].toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => {
        optionA.children[3].toLowerCase().localeCompare(optionB.children[3].toLowerCase())}
      }
    >
      {phoneCodes.map((phoneCode) => {
        return (
          <Option value={phoneCode.code}>
            {phoneCode.flag}
            {"\u00a0"}
            {"\u00a0"}
            {phoneCode.name}
          </Option>
        );
      })}
    </Select>
  );
};

export default CountryCodeSelector;
