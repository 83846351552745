import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const NameDescribe = ({ setProduct, product }) => {
  const { t } = useTranslation();
  const [describeEditor, setDescribeEditor] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (toHtml(describeEditor) === product.describe) return; //added

    setDescribeEditor(
      EditorState.push(describeEditor, ContentState.createFromBlockArray(htmlToDraft(product.describe || "")))
    );
  }, [product]);

  function onEditorStateChange(es) {
    setDescribeEditor(es);
    const html = toHtml(es); //added
    if (product.describe !== html) {
      setProduct((prevState) => ({ ...prevState, describe: html }));
    }
  }

  function toHtml(es) {
    return draftToHtml(convertToRaw(es.getCurrentContent())); // added
  }

  return (
    <div id="name" style={{ width: "100%" }} className="card">
      <p style={{ marginBottom: "5px" }} className="text-bold">
        {t("name")}
      </p>
      <Input
        value={product.name}
        placeholder={t('productName')}
        onChange={(e) => setProduct({ ...product, name: e.target.value })}
        className="width-100"
      />
      <p style={{ marginTop: "20px", marginBottom: "5px" }} className="text-bold">
        Description
      </p>
      <Editor
        editorState={describeEditor}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};

export default NameDescribe;
