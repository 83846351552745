import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Input } from "antd";
import { Redirect } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./style.scss";

import StripePayment from "./StripePayment";
import PaypalPayment from "./PaypalPayment";
import CoursePaid from "./CoursePaid";

import { urlParams } from "../../utils/urlParams";
import { courseServices } from "../../services/course";
import { regex } from "../../regex/index";
import { routes } from "../../routes";

import supplerAcademyLogo from "../../images/icons/01supply_academy.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CoursePayment = () => {
  const [isBadUrl, setIsBadUrl] = useState(false);
  const [course, setCourse] = useState({});
  const [email, setEmail] = useState(localStorage.getItem("emailAccountCreatosell") || "");
  const [isCoursePaid, setIsCoursePaid] = useState(false);
  const [isBadEmail, setIsBadEmail] = useState(false);

  const paramsUrl = urlParams();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    courseServices
      .findOne({ id: paramsUrl.id })
      .then((res) => res.json())
      .then((res) => (res.data ? setCourse(res.data) : setIsBadUrl(true)));
  }, []);

  useEffect(() => {
    if (email && !regex.email(email)) {
      setIsBadEmail(true);
    } else {
      setIsBadEmail(false);
    }
  }, [email]);

  return (
    <>
      {isBadUrl && <Redirect to="/" />}
      <div className="payment-page">
        <div className="course-navbar">
          <img style={{ width: "180px" }} src={supplerAcademyLogo} alt="01supply academy" />
        </div>
        <div style={{ marginTop: "30px" }} className="row-wrap width-100">
          <img
            style={{ minWidth: "300px" }}
            className="img"
            alt="01supply academy course"
            src={`${process.env.REACT_APP_ORIGIN}${course.imagePath}`}
          />
          <div className="column content-course">
            <p className="title-bold">{course.name}</p>
            <p style={{ marginTop: "20px" }}>{course.description}</p>
          </div>
          <p className="title-300">1x ${course.price}</p>
        </div>
        <Divider />
        {isCoursePaid ? (
          <CoursePaid />
        ) : (
          <>
            <p className="title-bold width-100 end">Total ${course.price}</p>
            <div
              style={{ marginTop: "20px", justifyContent: "space-evenly" }}
              className="row-wrap start width-100"
            >
              <div className="column left-column">
                <p className="title-bold">{t('paymentPage.yourAccount')}</p>
                <Input
                  status={isBadEmail && "error"}
                  style={{ width: "300px", marginTop: "20px", height: "42px" }}
                  placeholder={t("yourEmailAddress")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <p style={{ fontSize: "13px", marginTop: "15px" }} className="text-300 grey">
                {t('paymentPage.emailInfos')} <br />
                {t('paymentPage.emailInfos2')}
                </p>
              </div>
              <div className="column right-column">
                <p className="title-bold">{t('paymentPage.paymentInfos')}</p>

                <Elements stripe={stripePromise}>
                  <StripePayment
                    isBadEmail={isBadEmail}
                    setIsBadEmail={setIsBadEmail}
                    setIsCoursePaid={setIsCoursePaid}
                    email={email}
                    courseId={paramsUrl.id}
                  />
                </Elements>
                <p className="title-400 width-100 center" style={{ marginTop: "20px", fontSize: "18px" }}>
                  - or -
                </p>
                <PaypalPayment
                  isBadEmail={isBadEmail}
                  setIsBadEmail={setIsBadEmail}
                  setIsCoursePaid={setIsCoursePaid}
                  email={email}
                  courseId={paramsUrl.id}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CoursePayment;
