import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { url } from "../api/url";
import { header } from "../api/header";
import { getImage } from "../utils/GetImage";
import { message, Popover, Button } from "antd";

import { Modal } from "../wrappers";

import PopupIcon from "../images/join-illustration3.svg";
import manualOrderIcon from "../images/icons_grey/manual_order.svg";
import countryListJson from "../utils/country_list.json";
import logoEtsy from "../images/icons/logo_etsy.svg";
import ModalShippingInformations from "./ShippingInformations";
import TextArea from "antd/lib/input/TextArea";
import ModalExtension from "./modals/InstallExtensionModal";

import shopifyIcon from "../images/icons/shopify-icon.png";
import wizishopIcon from "../images/illustrations/wizishop.svg";
import dropiziIcon from "../images/illustrations/dropizi.svg";
import ManualFulfillOrder from "./manualFulfillOrder/Index";

const OrderCard = ({
  order,
  typeAccount,
  setPassOrder,
  setImageSelect,
  product,
  setProductOrder,
  key,
  user,
  windowSize,
  setModalNeedShop,
  setDeleteOrder,
  orders,
  setOrders,
  setLocalProductPopup,
}) => {
  const [userId] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [image, setImage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [viewShippingBool, setViewShippingBool] = useState(false);
  const [localProduct, setLocalProduct] = useState({});
  const [variantSelect, setVariantSelect] = useState({});
  const [declineOrder, setDeclineOrder] = useState(false);
  const [manualFulfillment, setManualFulfillment] = useState({
    isModalOpen: false,
    carrier: "",
    trackingNumber: "",
  });
  const [declineReason, setDeclineReason] = useState("");
  const [extensionModalOn, setExtensionModalOn] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (product && product.photos && product.photos.length > 0) getImageF(product.photos[0]);

    let countryListRes = [];
    for (let region of countryListJson) {
      if (!region.children) countryListRes.push(region);
      else countryListRes = [...countryListRes, ...region.children];
    }
    setCountryList(countryListRes);
  }, []);

  useEffect(() => {
    const currentProduct = order.status === "paid" ? order.product : localProduct;
    if (currentProduct) {
      if (
        (!(variantSelect && variantSelect.name) &&
          currentProduct &&
          currentProduct.name &&
          (!currentProduct?.variantBool || !order.variant_id)) ||
        localProduct.type === "etsy"
      ) {
        setVariantSelect({ price: currentProduct.price, stock: currentProduct.stock });
      } else if (!(variantSelect && variantSelect?.name) && currentProduct?.variantBool && order.variant_id) {
        setVariantSelect(currentProduct.variants.find((variant) => variant._id === order.variant_id));
      }
    }
  }, [localProduct, order]);

  useEffect(() => {
    if (!localProduct.name && order.product._id && user?._id) {
      fetch(`${url}/product/find-to-seller-order/${order.product._id}/${user._id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((resJson) => {
          setLocalProduct(resJson.data);
        });
    }
  }, [order, user]);

  useEffect(() => {
    if (image && user && user.shopify && localProduct?.name) {
      if (window.location.href && window.location.href.includes("orderId=")) {
        const currentUrl = new URL(window.location.href);
        let manualOrderId = currentUrl.searchParams.get("orderId");

        if (manualOrderId === order._id) {
          if (!(user.shopify && user.shopify.shop && user.shopify.token) && order.shopify_order_id) {
            setModalNeedShop(true);
          } else if (
            user &&
            user.firstName &&
            user.lastName &&
            user.company &&
            user.company.name &&
            user.company.phone &&
            user.company.address &&
            user.company.postal_code &&
            user.company.city
          ) {
            setPassOrder(order);
            setLocalProductPopup(localProduct);
            setImageSelect(image);
          } else setWarningPopup(true);
        }
      }
    }
  }, [image, user, localProduct]);

  const getImageF = async (id) => {
    const res = await getImage(id);
    if (res) {
      setImage(res);
    }
  };

  const changeEtsyOrderStatus = async (status) => {
    const changeWizishopStatus = async () => {
      if (user.wizishop && user.wizishop.default_shop_id && order.wizishop_order_id) {
        const newStatus =
          status === "paid" ? "preparing" : status === "filled" ? "ship" : status === "close" && "returned";
        const url = `https://api.wizishop.com/v3/shops/${order.wizishop_shop_id}/orders/${order.wizishop_order_id}/status/${newStatus}`;
        const options = {
          method: "PUT",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            authorization: `Bearer ${user.wizishop.token}`,
          },
        };
        fetch(url, options).then((res) => console.log(res));
      }
    };
    const res = await fetch(`${url}/order/etsy/change-status/${userId}/${secretId}/${order._id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        newStatus: status,
        declineReason,
        carrier: manualFulfillment.carrier,
        trackingNumber: manualFulfillment.trackingNumber,
      }),
    });
    if (res.status === 200) {
      changeWizishopStatus();
      let newOrders = [...orders];
      newOrders.map((orderP, i) => {
        if (orderP._id === order._id) {
          newOrders[i].status = status;
          setOrders([...newOrders]);
          message.success(t("messages.orderMove"));
        }
      });
      setDeclineReason("");
    } else message.error(t("messages.error"));
  };

  const updateOrderShippingAddress = async (newCustomerAddress) => {
    if (newCustomerAddress === false) setViewShippingBool(false);
    else {
      const res = await fetch(`${url}/order/update/${userId}/${secretId}/${order._id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          newOrder: { ...order, customer: newCustomerAddress },
        }),
      });
      if (res.status === 200) {
        let newOrders = [...orders];
        newOrders.map((orderP, i) => {
          if (orderP._id === order._id) {
            newOrders[i].customer = newCustomerAddress;
            setOrders([...newOrders]);
            message.success(t("messages.orderShipUpdated"));
          }
        });
        setViewShippingBool(false);
      } else message.error(t("messages.error"));
    }
  };

  const usToEurDate = function (date) {
    const usDate = date.split("/");
    var eurDate = usDate[1] + "-" + usDate[0] + "-" + usDate[2];
    return eurDate;
  };

  const createEtsyAutoOrderUrl = () => {
    if (order.customer) {
      let productUrl = product.origin_url;
      if (!productUrl?.includes("?")) {
        productUrl += "?";
      }
      return (
        product.origin_url +
        `&ship_street=${order.customer?.address1 || ""}&ship_apartment=${
          order.customer.address2 || ""
        }&ship_country=${order.customer?.country || ""}&ship_full_name=${order.customer?.name}&ship_zip=${
          order.customer.zip
        }&ship_city=${order.customer?.city}&ship_state=${order.customer?.province}&variant1=${
          order.variant?.split("/")?.[0] || ""
        }&variant2=${order.variant?.split("/")?.[1] || ""}`
      );
    } else {
      return product.origin_url;
    }
  };

  const checkExtensionDisplayed = (e) => {
    if (localStorage.getItem("dontDisplayExtensionModal") !== "true") {
      e.preventDefault();
      e.stopPropagation();
      setExtensionModalOn(true);
    }
  };

  const orderIcon =
    order.wizishop_order_id && user.wizishop?.cms === "wizishop"
      ? wizishopIcon
      : order.wizishop_order_id
      ? dropiziIcon
      : order.shopify_order_id
      ? shopifyIcon
      : product.type === "etsy"
      ? logoEtsy
      : manualOrderIcon;

  const OrderButton = ({ user, order, product, windowSize }) =>
    order.status === "to pay" && typeAccount === "seller" && product.type !== "etsy" ? (
      <button
        onClick={(e) => {
          if (!(user.shopify && user.shopify.shop && user.shopify.token) && order.shopify_order_id) {
            setModalNeedShop(true);
          } else if (
            user &&
            user.firstName &&
            user.lastName &&
            user.company &&
            user.company.name &&
            user.company.phone &&
            user.company.address &&
            user.company.postal_code &&
            user.company.city &&
            user.company.country_code
          ) {
            setPassOrder(order);
            setProductOrder(product);
            setImageSelect(image);
            setLocalProductPopup(localProduct);
          } else setWarningPopup(true);

          e.preventDefault();
          e.stopPropagation();
        }}
        className="button order-card orange-back"
        style={{ height: "48px", minWidth: "250px" }}
      >
        {t("order").toUpperCase()}
      </button>
    ) : order.status === "to pay" && typeAccount === "seller" && product.type === "etsy" ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={createEtsyAutoOrderUrl()}
        className="button order-card orange-back"
        onClick={(e) => {
          checkExtensionDisplayed(e);
        }}
        style={{ height: "48px", minWidth: "250px" }}
      >
        {t("components.orderCard.7")}
      </a>
    ) : (
      <div
        style={windowSize > 1150 ? { position: "absolute", right: "0", top: "-10px" } : {}}
        className="column"
      >
        <Link
          style={{ position: "relative" }}
          to={`/fulfill-order?order=${order._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button order-card orange-back"
        >
          {typeAccount === "creater" && order.status === "paid" ? t("fulfill").toUpperCase() : "DETAILS"}
        </Link>
        {windowSize > 1150 &&
          order.status === "paid" &&
          typeAccount === "seller" &&
          !product.type === "etsy" && (
            <p style={{ marginTop: "10px" }} className="grey text-bold">
              {t("components.orderCard.8")}
              <span style={{ fontWeight: "400", marginLeft: "6px" }}>{product.processingTime} days</span>
            </p>
          )}
      </div>
    );

  return (
    <Link
      to={`/fulfill-order?order=${order._id}`}
      target="_blank"
      rel="noopener noreferrer"
      key={key}
      style={{ width: "95%", marginBottom: "30px", marginLeft: `${windowSize > 1150 ? "0" : "2.5%"}` }}
      className="order-card card over-effect"
    >
      {manualFulfillment.isModalOpen && (
        <ManualFulfillOrder
          setTrackingNumber={(value) => setManualFulfillment({ ...manualFulfillment, trackingNumber: value })}
          setCarrier={(value) => setManualFulfillment({ ...manualFulfillment, carrier: value })}
          setIsDisplayed={(value) => setManualFulfillment({ ...manualFulfillment, isModalOpen: value })}
          handleSend={() => {
            changeEtsyOrderStatus("filled");
            setManualFulfillment({ ...manualFulfillment, isModalOpen: false });
          }}
        />
      )}
      <ModalExtension
        orderUrl={createEtsyAutoOrderUrl()}
        setModalOn={setExtensionModalOn}
        modalOn={extensionModalOn}
        windowSize={windowSize}
      />
      {declineOrder && (
        <Modal
          title={
            t("components.orderCard.15") + " #" + typeAccount === "seller" && order.shopify_order_number
              ? order.shopify_order_number
              : typeAccount === "creater" && "#" + order.nb_order_creater
          }
          visible={true}
          footer={[
            <Button key="back" onClick={() => setDeleteOrder(false)}>
              {t("cancel").toUpperCase()}
            </Button>,
            <Button
              style={{ border: "1px solid red", fontWeight: "bold", color: "red" }}
              key="ok"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (declineReason) {
                  changeEtsyOrderStatus("close");
                  setDeleteOrder(false);
                } else message.error(t("messages.whyDeclineOrder"));
              }}
            >
              {t("components.orderCard.1")}
            </Button>,
          ]}
        >
          <p className="title-300">{t("components.orderCard.2")}</p>
          <p className="text-bold">{t("components.orderCard.3")}</p>
          <TextArea onChange={(e) => setDeclineReason(e.target.value)} />
        </Modal>
      )}

      {viewShippingBool && (
        <Modal
          className="modal-1000"
          visible={true}
          onCancel={() => setViewShippingBool(false)}
          footer={[
            <button
              className="button-red"
              key="submit"
              type="primary"
              onClick={() => setViewShippingBool(false)}
            >
              {t("cancel").toUpperCase()}
            </button>,
          ]}
        >
          <div className="row" style={{ justifyContent: "center", width: "100%" }}>
            <ModalShippingInformations
              notEditable={typeAccount === "seller" && order.status === "to pay" ? false : true}
              setShippingInformations={updateOrderShippingAddress}
              shippingInformations={order.customer}
              windowSize={windowSize}
              location={"order"}
            />
          </div>
        </Modal>
      )}

      {warningPopup && (
        <Modal
          className="modal-account"
          visible={true}
          onCancel={() => setWarningPopup(false)}
          footer={[
            <button className="button-red" key="submit" type="primary" onClick={() => setWarningPopup(false)}>
              {t("cancel").toUpperCase()}
            </button>,
          ]}
        >
          <div style={{ alignItems: "center" }} className="column">
            <img style={{ height: "100px", width: "auto" }} src={PopupIcon} className="user-icon-popup" />
            <p style={{ marginBottom: "20px", marginTop: "40px" }} className="title-300">
              {t("components.orderCard.4")}
            </p>
            <Link to="/your-informations" className="button orange-back">
              {t("components.orderCard.5")}
            </Link>
          </div>
        </Modal>
      )}

      {/* HEAD CARD */}

      <div
        style={
          windowSize > 1150
            ? { justifyContent: "center", width: "100%", paddingRight: "10px", paddingLeft: "10px" }
            : {
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
              }
        }
        className={windowSize > 1150 ? "row" : "column reverse"}
      >
        <div className={windowSize <= 1150 ? "column width-100" : "row width-100"}>
          <div
            className={windowSize > 1150 ? "row" : "row space-between"}
            style={windowSize > 1150 ? { width: "30%", marginRight: "5%" } : { marginBottom: "8px" }}
          >
            <div className="row">
              {typeAccount === "seller" ? (
                <img
                  src={orderIcon}
                  style={{ width: user.wizishop?.cms ? "35px" : "22px", height: "auto", marginRight: "8px" }}
                />
              ) : (
                <img src={manualOrderIcon} style={{ width: "22px", height: "auto", marginRight: "8px" }} />
              )}
              <p
                style={
                  windowSize > 1150 ? { textAlign: "start", marginRight: "20px", marginLeft: "10px" } : {}
                }
              >
                {typeAccount === "seller" && order.shopify_order_number
                  ? order.shopify_order_number
                  : typeAccount === "creater" && "#" + order.nb_order_creater}
              </p>
            </div>
            <p>
              {order.date.split(" ") && order.date.split(" ")[0] && usToEurDate(order.date.split(" ")[0])}
            </p>
          </div>

          <p
            style={windowSize > 1150 ? { width: "30%", justifyContent: "center" } : { width: "100%" }}
            className="text-bold row"
          >
            {t("customer")} :
            {order.customer && countryList.length && (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setViewShippingBool(true);
                }}
                style={{ marginLeft: "8px", maxWidth: "calc(100% - 90px)" }}
                className="text-300 underline pointer text-ellipsis"
              >
                {order.customer &&
                  countryList
                    .find((country) =>
                      country.title.toLowerCase().includes(order.customer.country.toLowerCase())
                    )
                    ?.title?.split(" ")[0]}{" "}
                {order.customer.name}
              </span>
            )}
          </p>
        </div>
        <div
          className="pointer row"
          style={
            windowSize > 1150
              ? { width: "30%", marginLeft: "5%", justifyContent: "flex-end" }
              : { marginBottom: "16px", width: "100%", justifyContent: "flex-end" }
          }
        >
          {order.status == "to pay" && (
            <p
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteOrder(order);
              }}
              className="text red underline pointer"
            >
              {t("components.orderCard.6")}
            </p>
          )}
        </div>

        {/*
            {order.status === "paid" && typeAccount === "creater" &&
            <p onClick={() => setDeclineOrder(true)} style={{width: "150px"}} className="text-red underline pointer">
              Decline order
            </p>}
*/}
      </div>

      <div className="divider-horizontal" style={{ width: "100%", marginLeft: "0" }} />

      <div
        style={
          windowSize > 1150
            ? { width: "100%", marginTop: "10px", alignItems: "center" }
            : { flexDirection: "column", width: "100%", marginTop: "10px", alignItems: "flex-start" }
        }
        className="row"
      >
        <div
          className={`initial-cursor ${
            order.status === "close"
              ? "button-back-red"
              : order.status === "to pay" || order.status === "payment confirmation"
              ? "button orange-back-2"
              : order.status === "paid"
              ? "button blue-back"
              : "button grey-back"
          }`}
          style={{
            padding: "4px 8px 4px 8px",
            boxShadow: "inherit",
            width: windowSize > 1150 ? "200px" : "100%",
            maxWidth: "450px",
            position: "relative",
          }}
        >
          {order.status === "close"
            ? t("components.orderCard.orderStatus.1")
            : order.status === "payment confirmation"
            ? t("components.orderCard.orderStatus.9")
            : order.status === "to pay"
            ? t("components.orderCard.orderStatus.2")
            : order.status === "paid"
            ? t("components.orderCard.orderStatus.3")
            : t("components.orderCard.orderStatus.4")}
        </div>

        <div style={windowSize <= 1150 ? { marginTop: "20px" } : { marginLeft: "20px" }} className="row">
          <Link
            to={order.creater && "/shop/" + order.creater?.name}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            {order.creater?.name}
          </Link>
        </div>

        {windowSize > 1150 && (
          <OrderButton user={user} order={order} product={product} windowSize={windowSize} />
        )}
      </div>

      <div
        style={{ width: "100%", justifyContent: `${windowSize > 1150 ? "flex-end" : "center"}` }}
        className="row-wrap"
      >
        {product.type === "etsy" && (order.status === "to pay" || order.status === "paid") && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (order.status === "paid") {
                setManualFulfillment({ ...manualFulfillment, isModalOpen: true });
              } else {
                changeEtsyOrderStatus(
                  order.status === "to pay" ? "paid" : order.status === "paid" ? "filled" : order.status
                );
              }
            }}
            style={{
              width: windowSize > 1150 ? "250px" : "100%",
              maxWidth: "450px",
              height: "40px",
              padding: "1px 10px",
              marginTop: "20px",
              marginRight: windowSize > 1150 && "10px",
            }}
            className="button-white"
          >
            {order.status == "to pay"
              ? t("components.orderCard.orderStatus.7")
              : t("components.orderCard.orderStatus.8")}
          </button>
        )}
      </div>

      <div
        className={windowSize > 1150 ? "row" : "column"}
        style={{
          borderRadius: "6px",
          padding: "20px",
          width: "100%",
          background: "#F6F6F6",
          alignItems: "flex-start",
          marginTop: "35px",
        }}
      >
        <Link
          onClick={() => {
            sessionStorage.setItem("lastNaviguationInventory", "true");
          }}
          className="container-img-inventory-product"
          style={{
            minWidth: "120px",
            width: "120px",
            minHeight: "120px",
            height: "120px",
            borderRadius: "6px",
            marginBottom: "0",
            marginLeft: "0",
          }}
          target={product.type === "etsy" && "_blank"}
          to={
            product.type === "etsy"
              ? { pathname: product.origin_url }
              : `/product-details?name=${product.name}&id=${product._id}`
          }
        >
          <img style={{ minHeight: "120px", height: "120px" }} src={image} className="image-product" />
        </Link>
        <div style={{ width: "100%", justifyContent: "flex-start" }} className="column">
          <Link
            to={`/product-details?name=${product.name}&id=${product._id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="title-300 text-ellipsis underline"
            style={
              windowSize > 1150
                ? { maxWidth: "60%", marginRight: "5%", fontSize: "17px" }
                : { fontSize: "17px", marginTop: "15px" }
            }
          >
            {product.name}
          </Link>
          {!variantSelect.option1 && order.variant ? (
            <div className="row">
              <p className="text-bold" style={{ color: "#B9B9B9", marginRight: "20px" }}>
                {order.variant}
              </p>
            </div>
          ) : (
            <div className="row">
              {variantSelect.option1 && (
                <p className="text-bold" style={{ color: "#B9B9B9", marginRight: "20px" }}>
                  {product.optionsVariants[0]} :
                  <span style={{ fontWeight: "400" }}>{" " + variantSelect.option1}</span>
                </p>
              )}
              {variantSelect.option2 && (
                <p className="text-bold" style={{ color: "#B9B9B9", marginRight: "20px" }}>
                  {product.optionsVariants[1]} :
                  <span style={{ fontWeight: "400" }}>{" " + variantSelect.option2}</span>
                </p>
              )}
              {variantSelect.option3 && (
                <p className="text-bold" style={{ color: "#B9B9B9" }}>
                  {product.optionsVariants[2]} :
                  <span style={{ fontWeight: "400" }}>{" " + variantSelect.option3}</span>
                </p>
              )}
            </div>
          )}
          {order.status === "filled" && (
            <div className="row" style={{ height: "100%", width: "80%", marginTop: "20px" }}>
              {order.sending?.date_sent && (
                <p className="text-bold grey" style={{ fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", fontWeight: "400" }}>{t("components.orderCard.11")} </span>
                  {order.sending && order.sending.date_sent}
                </p>
              )}
              {order.sending?.carrier && (
                <p className="text-bold grey" style={{ fontSize: "15px", marginLeft: "20px" }}>
                  <span style={{ fontSize: "15px", fontWeight: "400" }}>{t("components.orderCard.12")} </span>
                  {order.sending && order.sending.carrier}
                </p>
              )}
            </div>
          )}
          {order.status === "filled" && order.sending?.tracking_number && (
            <p className="text-bold grey" style={{ fontSize: "15px", marginTop: "10px" }}>
              <span style={{ fontSize: "15px", fontWeight: "400" }}>{t("components.orderCard.13")} </span>
              {order.sending && order.sending.tracking_number}
            </p>
          )}
        </div>
        <p
          style={
            windowSize > 1150
              ? { fontSize: "16px", position: "absolute", right: "30px", top: "calc(50% - 15px)" }
              : { fontSize: "14px", marginTop: "5px" }
          }
        >
          {order.quantity} x {variantSelect.price ? "$" + variantSelect.price : t("components.orderCard.14")}
        </p>
      </div>
      {windowSize <= 1150 && (
        <div className="row width-100 center" style={{ marginTop: "16px" }}>
          <OrderButton user={user} order={order} product={product} windowSize={windowSize} />
        </div>
      )}
    </Link>
  );
};

export default OrderCard;
