import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article18/How-to-shoot-360-product-photography.jpg";
import spinImagesmartphone from "../../../../images/blog/global/article18/360-photography-with-msartphone.jpg";
import Difference360and3d from "../../../../images/blog/global/article18/360-protography-difference-3d.jpg";
import camera360 from "../../../../images/blog/global/article18/camera-360-product-photography.jpg";
import tripodGear from "../../../../images/blog/global/article18/camera-tripod.jpg";
import ecommerce360Images from "../../../../images/blog/global/article18/ecommerce-360-images-animated.gif";
import lighting360Photography from "../../../../images/blog/global/article18/lighting-for-360-product-photography.jpg";
import turntable360Photography from "../../../../images/blog/global/article18/turntable-product-360-photography.jpg";


const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        How to shoot 360 product photography?
      </h1>
      <p className="text-300">05 october 2022 - 6 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px" }} alt="How to shoot 360 product photography?"/>
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        You are looking to <b>create more engaging photos for your e-commerce store</b>?
        360 degrees images are awesome to <b>increase your conversion rate</b> and get more sales from your visitors.
        It allows you to <b>stand out from your competitors</b> and create a unique experience on your online store.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#what-is-360-photography" title="What is 360 spin photography?" >
              <Anchor.Link href="#difference-360-3d-photography" title="What is the difference between 360-degree and 3D photography?"/>
              </Anchor.Link>
            <Anchor.Link href="#360-product-example" title="360 degrees products example" />
            <Anchor.Link href="#equipment-360-photography" title="What equipment do I need for 360 degrees in product photography?"/>
            <Anchor.Link href="#tutorial-360-product-photography" title="How do I make a 360 degrees product image? (Tutorial)"/>
            <Anchor.Link href="#360-degrees-product-ecommerce" title="360 product images for e-commerce"/>
            <Anchor.Link href="#360-images-software" title="Best 360 product photography software"/>
            <Anchor.Link href="#360-shots-with-smartphone" title="Capture 360 product images with your smartphone (DIY)"/>
          </Anchor>
        }
      />
      <h2 id="what-is-360-photography">What is 360 spin photography?
      </h2>
      <p style={{ marginBottom: "20px" }}>360 spin photography gives the user the impression of <b>rotating the subject</b>.
        This is done thanks to a series of photos arranged one after the other. This gives the same effect as a flipbook.
      </p>
      <p style={{ marginBottom: "20px" }}>You can <b>create 360 spin photography</b> with a gif or even better with interactive online software.
        This last technique would <b>allow your customer to directly interact</b> and spin the product himself.
      </p>
      <p style={{ marginBottom: "20px" }}>On average a spin image should have 24 images per row. <b>The more images you add, the smoother the animation can be</b>.
        Also remember that the more images you add, the slower your website will perform. Indeed, most of the bandwidth is for image loading.
      </p>
      <h3 id="difference-360-3d-photography">What is the difference between 360-degree and 3D photography?
      </h3>
      <img src={Difference360and3d} style={{ width: "100%", marginTop: "20px" }} alt="What is the difference between 360-degree and 3D photography?"/>
      <p style={{ marginBottom: "20px" }}><b>Spin photography is not the same as 3D photography</b>.
        3D photography would include every angle of the product, not just a horizontal rotation.
        It is also way harder to make 3D photography if you don’t have the right equipment.
      </p>
      <p style={{ marginBottom: "20px" }}>Indeed 3D product photography requires a moving camera that can capture every angle,
        while still keeping the same luminosity, stability, and settings as every other angle before.
      </p>
      <h2 id="360-product-example">360 degrees products example</h2>
      <p style={{ marginBottom: "20px" }}>Here are some <b>360 spin photography examples</b>. And you can interact with the image!
      </p>
      <p style={{ marginBottom: "20px" }}>Later in this article, we will discuss how to get the exact same result.
      </p>
      <p style={{ marginBottom: "20px" }}><u>Leather chair 360 example</u>:</p>
      <div class="Sirv" data-src="https://mpecogho.sirv.com/Spins/Egg-Chair/Egg%20Chair%20Anniversary%2036.spin"></div>
      <p style={{ marginBottom: "20px" }}><u>Sneakers 360 example</u>:</p>
      <div class="Sirv" data-src="https://mpecogho.sirv.com/Spins/Trainers/Trainers.spin"></div>
      <h2 id="equipment-360-photography">What equipment do I need for 360 degrees in product photography?
      </h2>
      <h3>Camera</h3>
      <img src={camera360} style={{ width: "100%", marginTop: "20px" }} alt="Camera for 360 Photography"/>
      <p style={{ marginBottom: "20px" }}>Most of the time you can shoot <b>with any camera you want</b>.
        Be aware that those cameras must have a manual mode.
        Otherwise, you would end up with irregular and inconsistent shots which would not look good on your website.
      </p>
      <p style={{ marginBottom: "20px" }}>As it is very easy to shoot in manual mode, <b>most smartphones are suitable</b> for this kind of 360 photography.
      </p>
      <h3>Tripod</h3>
      <img src={tripodGear} style={{ width: "100%", marginTop: "20px" }} alt="Tripod for 360 photography"/>
      <p style={{ marginBottom: "20px" }}><b>Tripod is a must-have</b> as you have to shoot every image from the same perspective.
        It also allows you to avoid any shaking or blurred images.
      </p>
      <h3>Lights</h3>
      <img src={lighting360Photography} style={{ width: "100%", marginTop: "20px" }} alt="Lights for 360 Photography"/>
      <p style={{ marginBottom: "20px" }}>
        We recommend you <b>add strobe light</b> if you can for 360 product photography. As the product will turn around, it is important to avoid any shadows.
        This is why strobe lights are important.
        Then, you can add a diffuser light to soften the image.
      </p>
      <h3>Turntable</h3>
      <img src={turntable360Photography} style={{ width: "100%", marginTop: "20px" }} alt="Turntable for 360 photography"/>
      <p style={{ marginBottom: "20px" }}><b>Electronic turntables are mandatory</b> for 360 product shooting. You could use a manual turntable but the shots are often inconsistent.
      </p>
      <p style={{ marginBottom: "20px" }}>If you can’t afford an electronic turntable, you can use a manual one and mark every degree on it.
        So that when you photograph the next angle, you can exactly rotate the turntable accordingly.
      </p>
      <p style={{ marginBottom: "20px" }}>It is important to know that there is some electronic turntable especially made for 360 photography.
      </p>
      <h3>Accessories</h3>
      <p style={{ marginBottom: "20px" }}>These accessories are optional but could be big time savers if you shoot a lot of 360 photography.
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Fixed view stand</b>: This allows you to get every shot at the same level for a long period of time. The stand will not move and always have the right height level.
        </li>
        <li>
          <b>Centering laser</b>: This allows you to place your product at the center of the turntable for a quicker installation.
        </li>
        <li><b>Lightbox</b> for consistent shots and easy setup.
        </li>
      </ul>
      <h2 id="tutorial-360-product-photography">How do I make a 360 degrees product image? (Tutorial)
      </h2>
      <h3>Setup</h3>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Place the turntable</b> in the center of the table.
        </li>
        <li><b>Add a white acrylic surface</b> on top of the turntable.
        </li>
        <li><b>Place the product</b> on the acrylic panel. The product should be perfectly centered with the turntable center. 
        Otherwise, it won’t turn on one axis and the spin effect won’t work.<br/> <u>Here is what happens if the product is not centered</u>: <br />
          <iframe className="start" src="https://iframe.packshot-creator.com/www/page_examples/Flower_not_centered/Flower_not_centered.html" data-opt-src="https://iframe.packshot-creator.com/www/page_examples/Flower_not_centered/Flower_not_centered.html" width="50%" height="300" frameborder="0" scrolling="no" data-opt-lazy-loaded="true"></iframe>
        </li>
        <li><b>Place a white backdrop</b> 1m to 2m away.
        </li>
        <li><b>Place your camera</b> in front of the product (less than 1m)</li>
        <li><b>Add two strobe lights</b> on each side of the camera.
        </li>
        <li><b>Add the softbox</b> above the product, a bit angled from the back.
        </li>
        <li><b>Add white reflectors</b> on each side of the product so it does not reflect other objects from the room.
        </li>
      </ul>
      <h3>Settings</h3>
      <p style={{ marginBottom: "20px" }}>Here are the recommended camera settings when you shoot on a white background:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Aperture</b>: between f/8 and f/11
        </li>
        <li>
          <b>ISO</b>: between 100 and 200
        </li>
        <li>
          <b>Shutter speed</b>: 1/30
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>Important: make sure your <b>camera setting</b> (including focus) is <b>on manual</b> so it does not change while taking every picture.
      </p>
      <p style={{ marginBottom: "20px" }}>You can play around with these settings to get the best result possible. If you plan to edit your images, make sure to shoot in RAW format.
        If you want to learn more about image formats for e-commerce, we recommend you look at our recent article: <a className="blue" href="https://app.01supply.com/best-size-product-images" target="blank_" alt="Best size for product images"><b><u>What is the best size for product images?</u></b></a>
      </p>
      <h3>Time to photograph
      </h3>
      <p style={{ marginBottom: "20px" }}>Now it is time to <b>photograph from all angles</b>. We recommend you <b>take shots every 15 degrees</b>. 
      This will give you an <b>end result of 24 images</b> for a full rotation.
      </p>
      <h3>Editing (optional)
      </h3>
      <p style={{ marginBottom: "20px" }}>As you have a series of images, you must apply exactly the same color grading settings on all images. Therefore, we recommend you use LUT presets.
      </p>
      <p style={{ marginBottom: "20px" }}>LUTs are used a lot in photo editing as they allow you to apply a selection of color settings at once on an image.
        This is why you should use LUTs for your 360 images.
        Here is a great <a className="blue" href="https://giggster.com/guide/retouching/luts/" rel="nofollow noreferrer" target="blank_" alt="guide for LUTs"><u>guide for LUTs</u></a> and how to create new ones.
      </p>
      <h3>Compile</h3>
      <p style={{ marginBottom: "20px" }}>Now that you have your images, it’s time to <b>assemble them</b>. Therefore, you can use a <b>free online tool</b>
        called <a href="https://sirv.com/" rel="nofollow noreferrer" alt="Software for 360 images" className="blue"><u>Sirv</u></a>.
        Upload your 24 images and run the spin tool. Sirv will do the job for you.
      </p>
      <h3>Publish</h3>
      <p style={{ marginBottom: "20px" }}>The nice part here is that you can <b>export the end result as a link or as code</b>.
        This allows you to publish it easily on your website and it does not impact performance as it runs on their server.
      </p>
      <h2 id="360-degrees-product-ecommerce">360 product images for e-commerce</h2>
      <img src={ecommerce360Images} style={{ width: "100%", marginTop: "20px" }} alt="360 images for ecommerce store"/>
      <p style={{ marginBottom: "20px" }}>Not all e-commerce niches are suitable for 360 photography.
        For example, you can’t do 360 spin photography for digital goods or for services.
        But it is very helpful for consumer goods, cars and models.
      </p>
      <p style={{ marginBottom: "20px" }}><b>360-degree product photography helps your customer visualize your product</b> from every angle.
        Which helps increase the conversion rate and brings <b>more revenue</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>Additionally, 360 images also <b>increase the average time spent on your page</b> which is a good indicator for Google ranking.
      </p>
      <h2 id="360-images-software">Best 360 product photography software
      </h2>
      <p style={{ marginBottom: "20px" }}><u>Here is a full list of 360 software you can use</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Sirv
        </li>
        <li>
          Arqspin
        </li>
        <li>
          ShutterStream 360
        </li>
        <li>
          Webrotate 360
        </li>
        <li>
          Spinzam
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>Depending on the software you choose, you may have access to more features.
        Also, note that some of them are directly <b>compatible with smartphone</b> apps like Webrotate 360 or Spinzam.
      </p>
      <p style={{ marginBottom: "20px" }}>Others like Sirv.com give you the ability to store your 360 images on their server.
        It means that you will have faster-loading images on your website as it won’t use your website server performance.
      </p>
      <h2 id="360-shots-with-smartphone">Capture 360 product images with your smartphone (DIY)
      </h2>
      <img src={spinImagesmartphone} style={{ width: "100%", marginTop: "20px" }} alt="Shoot 360 pictures with smartphone"/>
      <p style={{ marginBottom: "20px" }}>It is entirely possible to shoot 360 product images with your smartphone as described in this article.
        But you can find easier ways to shoot products for example 3dshot.io which has a mobile app.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>If you want to learn more about 360 photography, the right settings, correct lighting, and useful software,
          we recommend you look at our last photography course 
          here: <a href="https://app.01supply.com/academy/photo-mastery-course" rel="Photography course for e-commerce" target="blank_" className="blue"><u>Photo Mastery</u></a>.</b>
      </p>
      <p style={{ marginBottom: "20px" }}>This is created by 01supply Academy which helps thousands of product creators and e-commerce owners make a living out of their business, from start to finish.
      </p>




    </div>
  );
};

export default Content;
