import React from "react";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

import "./style.scss";

const DoubleSwitch = ({ state, setState, text1, text2 }) => {
  return (
    <div className="double-switch">
      <p className={!state && "text-bold"}>{text1}</p>
      <Switch className="ant-switch-blue" checked={state} onChange={(checked) => setState(checked)} />
      <p className={state && "text-bold"}>{text2}</p>
    </div>
  );
};

export default DoubleSwitch;
