import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iconCheck from "../../../images/icons/Check.svg";

const PricingCard3 = ({ windowSize, button, isYearly, user }) => {
  const { t } = useTranslation();

  return (
    <div className="pricing-card">
      <h2 style={{ marginBottom: "0" }} className="title-bold">
        {t("resellerPricing.27")}
      </h2>
      <p style={{ fontSize: "15px" }} className="title-300">
        {t("resellerPricing.26")}
      </p>
      {isYearly ? (
        <>
          <div className="row width-100 center">
            <p style={{ marginRight: "10px", fontSize: "30px" }} className="title ">
              <div style={{ width: "60px" }} className="dashed-2" />
              $99
            </p>
            <p style={{ fontSize: "35px", color: "##474747" }} className="title-bold">
              $59 <span className="title grey">/mo</span>
            </p>
          </div>
          <p>{t("annualPrice", { price: 59 * 12 })} </p>
        </>
      ) : (
        <p style={{ marginTop: "10px", fontSize: "35px" }} className="title-bold">
          $99
          <span style={{ marginLeft: "0px", fontSize: "19px" }} className="title grey">
            /mo
          </span>
        </p>
      )}
      {button}
      <div
        className="divider-horizontal"
        style={{ width: "100%", marginTop: "25px", marginLeft: "0", marginBottom: "25px" }}
      />
      <div style={{ width: "100%" }} className="column start">
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px", fontWeight: "bold" }}>
            {t("resellerPricing.25")}
          </p>
        </div>
        <Link
          target="_blank"
          to="/marketplace-winning-products"
          style={{ marginBottom: "10px" }}
          className="row"
        >
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px", fontWeight: "bold" }}>
            {t("resellerPricing.29")}
          </p>
        </Link>
        <Link
          target="_blank"
          to="/marketplace-products-analytics"
          style={{ marginBottom: "10px" }}
          className="row"
        >
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px", fontWeight: "bold" }}>
            {t("accessAnalytic")}
          </p>
        </Link>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px", fontWeight: "bold" }}>
            300 {t("resellerPricing.30")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.9")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.20")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.22")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.14")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.15")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingCard3;
