import React, { useEffect, useState } from "react";

import { header } from "../../api/header";
import { url } from "../../api/url";

import Chart from "./Chart";
import KeysWord from "./KeysWord";
import Informations from "./Informations";
import "./style.scss";

const ProductEtsyStats = ({ product, productImage }) => {
  const [completeProduct, setCompleteProduct] = useState(product);

  useEffect(() => {
    if (!product?.etsy_stats?.keys_word || !product?.etsy_stats?.trends) {
      // c'est que le produit est incomplet (vient d'algolia)
      fetch(`${url}/product/findOne?id=${product._id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
        },
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson && resJson.data && resJson.data.photos) {
            setCompleteProduct(resJson.data);
          }
        });
    }
  }, []);

  return (
    <div style={{ alignItems: "center" }} className="width-100 column" id="product-stats">
      <Informations product={completeProduct} productImage={productImage} />
      <Chart product={completeProduct} />
      {completeProduct.etsy_stats?.keys_word && <KeysWord keysWord={completeProduct.etsy_stats.keys_word} />}
    </div>
  );
};

export default ProductEtsyStats;
