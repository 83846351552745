import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { Input } from "antd";

import { header } from "../../api/header";
import { url } from "../../api/url";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import { updateUser } from "../../services/updateUser";
import ThematicsSelection from "../../components/ThematicsSelection";

import "react-phone-input-2/lib/material.css";

const { TextArea } = Input;

const Onboarding3 = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isNext, setIsNext] = useState(false);
  const [thematicSelections, setThematicSelections] = useState([]);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const nextCondition = thematicSelections[0];

  const totalSteps = typeAccount === "creater" ? 5 : 3;

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
  };

  const sendUpdateUser = () => {
    if (thematicSelections[0]) {
      updateUser({ user: { thematic: thematicSelections } }, idAccount, secretId, token, () =>
        setIsNext(true)
      );
    }
    setIsNext(true);
  };

  const handleSelectThematic = (thematic) => {
    setThematicSelections(
      thematicSelections.includes(thematic)
        ? thematicSelections.filter((value) => value !== thematic)
        : [...thematicSelections, thematic]
    );
  };

  return (
    <div className="page">
      {isNext && <Redirect to="/inventory" />}
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "0px",
                }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingBottom: "200px",
                }
          }
          className="column-scrolling"
        >
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              marginBottom: windowSize > 1150 ? "50px" : "0",
              height: "80px",
            }}
            className="header"
          >
            <h1 className="title-bold montserrat">
              {t("onboarding.1")} {totalSteps === 3 ? "(3/3)" : "(5/5)"}
            </h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "40px",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div
              id="content"
              style={{ marginLeft: "0", width: "90%", padding: "40px 5vw 30px 5vw", marginTop: "0px" }}
              className="column center card"
            >
              <p style={{ width: "100%" }} className="title-bold start">
                {t("onboarding.2")}
              </p>
              <p style={{ marginBottom: "40px", width: "100%" }} className="text-300 start">
                {t("onboarding.3")}
              </p>
              <ThematicsSelection callbackSelection={handleSelectThematic} selections={thematicSelections} />
              <div className="column" style={{ width: "100%", marginTop: "5px" }}>
                <p className="text-bold">{t("onboarding.4")}</p>
                <Input
                  style={{ width: "94%", marginTop: "5px" }}
                  onChange={(e) => setThematicSelections([e.target.value])}
                />
              </div>
              <div style={{ marginTop: "35px", marginBottom: "20px" }} className="row">
                <button
                  onClick={sendUpdateUser}
                  style={{ marginRight: "10px" }}
                  className="button big grey disable"
                >
                  {t("onboarding.5")}
                </button>
                {nextCondition && (
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={sendUpdateUser}
                    className="button big orange-back"
                  >
                    {t("onboarding.6")}
                  </button>
                )}
              </div>
              <Link
                to={`${
                  typeAccount === "creater" ? "/supplier-onboarding-step-4" : "/supplier-onboarding-step-2"
                }`}
                className="text blue underline pointer"
              >
                {t("onboarding.7")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding3;
