import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Modal } from "../../wrappers";
import ShopIllustration from "../../images/shop_illustration.jpg";

const ModalNeedShopify = ({ state, setState }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="modal-order"
      title={`Link your Shopify store`}
      onOk={() => {}}
      visible={state}
      onCancel={() => setState(false)}
      footer={[
        <Link to="/your-shop-reseller" className="button orange-back">
          {t("components.reseller.modalNeedShopify.1")}
        </Link>,
      ]}
    >
      <div className="column" style={{ alignItems: "center" }}>
        <img className="illutstration-top-absolute" src={ShopIllustration} />
        <p style={{ textAlign: "center" }} className="title-roboto">
          {t("components.reseller.modalNeedShopify.2")}
          <Link
            to="/your-shop-reseller"
            style={{ fontWeight: "bold", marginLeft: "8px", marginRight: "8px", textDecoration: "underline" }}
          >
            {t("components.reseller.modalNeedShopify.3")}
          </Link>
        </p>
      </div>
    </Modal>
  );
};

export default ModalNeedShopify;
