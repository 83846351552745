import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { articles } from "../../articles";
import NavBar from "../../../../components/menu/navbar/NavBar";
import Footer from "../../../../components/menu/Footer";
import TagMenu from "../../../../components/blog/TagMenu";
import ArticleHeaderNav from "../../../../components/blog/ArticleHeaderNav";
import CourseSponso from "../../../../components/blog/CourseSponso";
import Social from "../../../../components/blog/Social";
import ArticleFooter from "../../../../components/blog/ArticleFooter";
import Content from './Content'
import SuggestionCard from "../../../../components/blog/SuggestionCard";

import "../../style.scss";

const Blog = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedTag, setSelectedTag] = useState("");

  const articleId = 19; // NEED CHANGE TO ALL NEW ARTICLE

  const article = articles.find((article) => parseInt(article.id) === articleId);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const newArticles = articles.filter((article) => parseInt(article.id) !== articleId);
  const lastThreeArticles = [
    newArticles[newArticles.length - 1],
    newArticles[newArticles.length - 2],
    newArticles[newArticles.length - 3],
  ];
  
  return (
    <div style={{ background: "white" }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://app.01supply.com/optimize-seo-product-description" />
        <title>How to optimize an SEO product description? | 01supply</title>
        <meta name="description"
          content="Learn how you can Optimize your SEO on Product Descriptions now. SEO is essential for consistent and free traffic, ultimately bringing more profit." />
        
        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://app.01supply.com/optimize-seo-product-description" />
        <meta property="og:title" content={`How to optimize an SEO product description? | 01supply`} />
        <meta property="og:description"
          content="Learn how you can Optimize your SEO on Product Descriptions now. SEO is essential for consistent and free traffic, ultimately bringing more profit." />
        <meta property="og:type" content="article" />
        <meta property="og:image"
          content="https://app.01supply.com/static/media/How-to-optimize-an-SEO-product-description.ec522a24.jpg" />
        
        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "How to optimize an SEO product description?",
            "image": [
              "https://app.01supply.com/static/media/static/media/How-to-optimize-an-SEO-product-description.ec522a24.jpg"
            ],
            "datePublished": "2022-10-09",
            "dateModified": "2022-10-09",
            "author": [
              {
                "@type": "Person",
                "name": "Diego Huberty",
                "jobTitle": "Editor in Chief"
              }
            ],
            "publisher": {
              "name": "01supply",
              "url": "https://app.01supply.com/"
            }
          }`}
        </script>
      </Helmet>

      <NavBar windowSize={windowSize} />

      <div style={{ paddingBottom: "150px", textAlign: "start" }} className="column center">
        <TagMenu
          setSelectedTag={setSelectedTag}
          selectedTag={selectedTag}
        />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            <ArticleHeaderNav article={article} />
            <CourseSponso
              imagePath="coursesImg/supplier/course2-untitled.jpg"
              imageTitle="SEO Description"
              price="99$"
              title="With 10 specific SEO techniques, you can double your sales in the next weeks."
              description="SEO allows you to acquire visitors for free. It is basically free advertising offered by Google. Search engines have some advanced algorythm you can trick to get more visibility and sales online."
              coursePath="/academy/seo-product-description-course"
            />
            <Content />
            <Social article={article} />
            <ArticleFooter windowSize={windowSize} />
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article) => (
              <SuggestionCard article={article} />
            ))}
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Blog;
