import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { updateUser } from "../../../../services/updateUser";

import RetailPrice from "./RetailPrice";
import HandlePushToStoreSettings from "./HandlePushToStore";
import Load from "../../../../utils/Load";

const PriceRules = ({ user, setUser }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleUpdateSetting = ({ retailPrice }) => {
    setLoading(true);
    setUser({ ...user, retailPrice });
    updateUser({ user: { retailPrice } }).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        message.success(t("messages.successfullyUpdated"));
      } else {
        message.error(t("messages.error"));
      }
    });
  };

  return (
    <div className="column width-100">
      {loading && <Load />}
      {user?._id && (
        <>
          <RetailPrice handleUpdateSetting={handleUpdateSetting} user={user} />
          <HandlePushToStoreSettings handleUpdateSetting={handleUpdateSetting} user={user} />
        </>
      )}
    </div>
  );
};

export default PriceRules;
