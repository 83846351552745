import React, { useState, useEffect } from "react";
import { BarChartOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import icon_wallet from "../../images/icons/wallet.svg";
import icon_email from "../../images/icons/email.svg";

import logo from "../../images/logo.svg";
import MyAccount from "./MyAccount";

import CheckTokenAndRedirectLogin from "../../utils/RedirectLogin";
import CountryCurrencySelector from '../CountryCurrencySelector'
import { t } from "i18next";
import { routes } from "../../routes";

const FirstMenu = ({user}) => {
  const [location, setLocation] = useState(
    window.location.pathname.replace("/thematics", "").replace("/", "")
  );
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.location.pathname.includes("marketplace")) {
      setLocation("marketplace");
    } else {
      setLocation(window.location.pathname.replace("/", ""));
    }
  }, [window.location.pathname]);

  return (
    <>
      {token && <CheckTokenAndRedirectLogin />}
      {
        <div className="container-menu">
          <div style={{ padding: "30px 17px" }} className="board-left-menu montserrat">
            {windowSize > 1150 && (
              <Link
                style={{ width: "100%", maxHeight: "100%", marginBottom: "25px", marginTop: "15px" }}
                to={routes.affiliationLinks(i18n.language).path}
              >
                <img style={{ marginBottom: "0" }} src={logo} alt="supply.me logo" className="logo-menu" />
              </Link>
            )}
            {windowSize > 1150 && (
              <CountryCurrencySelector
                selectorStyle={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  maxWidth: "100%",
                }}
              />
            )}

            <Link
              to={routes.affiliationLinks(i18n.language).path}
              className="marketplace-menu montserrat orange-back"
            >
              {t("affiliateDashboard.menu.links")}
            </Link>

            <div
              className="divider-horizontal"
              style={{
                marginRight: "0",
                width: "100%",
                marginLeft: "0",
              }}
            />

            <Link
              to={routes.affiliationStatistics(i18n.language).path}
              className={location === "affiliation-statistics" ? "text-menu-active" : "text-menu"}
            >
              <BarChartOutlined style={{ fontSize: "19px", marginRight: "10px", color: "#666666" }} />
              {t("affiliateDashboard.menu.statistic")}
            </Link>
            <Link
              style={{ marginTop: "10px" }}
              to={routes.affiliationPaymentHistory(i18n.language).path}
              className={location === "payment-history" ? "text-menu-active" : "text-menu"}
            >
              <img src={icon_wallet} className="icon-menu" />
              {t("affiliateDashboard.menu.history")}
            </Link>
            <Link
              style={{ marginTop: "10px" }}
              to={routes.affiliationPaymentRequest(i18n.language).path}
              className={location === "payment-request" ? "text-menu-active" : "text-menu"}
            >
              <img src={icon_email} className="icon-menu" />
              {t("affiliateDashboard.menu.payment")}
            </Link>
          </div>
          <MyAccount user={user} location={"affiliate"} />
        </div>
      }
    </>
  );
};

export default FirstMenu;
