import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Skeleton, Pagination, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";

import { productServices } from "../../../services/products";
import { urlParams } from "../../../utils/urlParams";

import ProductCard from "../../ProductCard";
import PricingModal from "../../reseller/pricing/FirstPlans";
import PopupLogin from "../../modals/RegisterModal";
import Header from "./Header";
import { Modal } from "../../../wrappers";

import "./style.scss";
import DoubleSwitch from "../../global/doubleSwitch/DoubleSwitch";

const AnalyticsProduct = ({ user, setUser, setRegisterModalIsActive, windowSize }) => {
  const paramsUrl = urlParams();

  const [products, setProducts] = useState([]);
  const [isModalPricingDisplayed, setIsModalPricingDisplayed] = useState(false);
  const [currentPage, setCurrentPage] = useState(parseInt(paramsUrl.page) || 1);
  const [isLoadProduct, setIsLoadProducts] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isPopupLoginDisplayed, setIsPopupLoginDisplayed] = useState(false);
  const [isHistory, setIsFirstHistory] = useState(true);
  const [isPopularProducts, setIsPopularProducts] = useState(false);
  const { t } = useTranslation();

  const history = useHistory();

  const PAGE_SIZE = 50;

  const userCanAccess = !(!user.plan?.type || user.plan?.type === "free" || user.plan?.type === "basic");

  const blurCallbackFunction = () => {
    if (user?._id) {
      setIsModalPricingDisplayed(true);
    } else {
      setIsPopupLoginDisplayed(true);
    }
  };

  const getProducts = () => {
    productServices
      .find({
        isProductAnalytics: true,
        isPopularAnalyticsProducts: isPopularProducts,
        page: currentPage,
        limit: PAGE_SIZE,
        userId: localStorage.getItem("idAccountCreatosell") || "",
      })
      .then((res) => res.json())
      .then((resJson) => {
        const resProducts = resJson.data;
        setIsLoadProducts(false);
        setProducts(resProducts);
        setTotalProducts(resJson.totalProducts);

        if (isHistory) {
          history.replace({ search: `?page=${currentPage}` });
          setIsFirstHistory(false);
        } else {
          history.push({ search: `?page=${currentPage}` });
        }
      });
  };

  useEffect(() => {
    setIsLoadProducts(true);
    getProducts();
  }, [currentPage, isPopularProducts]);

  const scrollToTop = () => {
    const element = document.getElementsByClassName("column-scrolling")?.[0];
    if (element) {
      element.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page || 1);
    setIsLoadProducts(true);
    scrollToTop()
  };

  return (
    <div
      style={{
        boxShadow: "none",
        padding: windowSize <= 1150 && "0px",
        margin: windowSize < 1150 && "0px",
        marginBottom: "120px",
      }}
      className="width-100"
    >
      {isPopupLoginDisplayed && (
        <PopupLogin modalOn={isPopupLoginDisplayed} setModal={setIsPopupLoginDisplayed} />
      )}
      {isModalPricingDisplayed && (
        <Modal
          className="modal-full-screen"
          visible={true}
          footer={[]}
          onCancel={() => setIsModalPricingDisplayed(false)}
        >
          <div className="column width-100" style={{ alignItems: "center" }}>
            <PricingModal setRegisterModalIsActive={setRegisterModalIsActive} windowSize={windowSize} />
          </div>
        </Modal>
      )}

      <Header
        setIsPopupLoginDisplayed={setIsPopupLoginDisplayed}
        user={user}
        setIsModalPricingDisplayed={setIsModalPricingDisplayed}
        userCanAccess={userCanAccess}
      />

      {/* TRIE PAR PLUS POPULAIRE */}
      <Divider />
      <DoubleSwitch
        state={isPopularProducts}
        setState={setIsPopularProducts}
        text1="tous les produits"
        text2="Produits populaires"
      />
      <Divider />

      <div style={{ justifyContent: windowSize < 1150 && "center" }} className={`width-100 row-wrap`}>
        {products?.length > 0 && !isLoadProduct ? (
          <>
            {products.map((product, index) => {
              return (
                <ProductCard
                  isBlur={!userCanAccess && index > 0}
                  isAnalyticsProduct
                  setUser={setUser}
                  user={user}
                  key={product._id}
                  index={index}
                  location={"landing"}
                  product={product}
                  blurCallbackFunction={blurCallbackFunction}
                />
              );
            })}
            <div className="row width-100 center">
              <Pagination
                key={currentPage + products.length + totalProducts}
                style={{ marginBottom: "50px", marginTop: "30px" }}
                showSizeChanger={false}
                onChange={(page, pageSizeP) => {
                  handlePagination(page || 1);
                }}
                total={totalProducts}
                pageSize={PAGE_SIZE}
                defaultCurrent={currentPage || 1}
                disabled={!userCanAccess}
              />
            </div>
          </>
        ) : (
          <>
            <Skeleton
              style={{ marginTop: "20px" }}
              className="image-product width-100"
              loading={true}
              active
            />
            <Skeleton
              style={{ marginTop: "20px" }}
              className="image-product width-100"
              loading={true}
              active
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsProduct;
