import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../../components/menu/navbar/NavBar";
import { Helmet } from "react-helmet";

import img22 from "../../../images/landing/img22.svg";
import img23 from "../../../images/landing/img23.jpg";
import img25 from "../../../images/landing/img25.jpg";
import img26 from "../../../images/landing/img26.jpg";
import img27 from "../../../images/landing/img27.jpg";
import img28 from "../../../images/landing/img28.jpg";
import img29 from "../../../images/landing/img29.jpg";
import Footer from "../../../components/menu/Footer";

import { routes } from "../../../routes";
import { useTranslation } from "react-i18next";

const EbookArticle = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  return (
    <div className="column" style={{ width: "100vw", overflowX: "hidden", maxWidth: "100vw" }}>
      <div
        style={
          windowSize > 1150
            ? {
                paddingLeft: "15vw",
                paddingRight: "15vw",
                alignItems: "center",
                overflowX: "hidden",
                width: "100vw",
              }
            : { alignItems: "center", paddingBottom: "200px" }
        }
        className="page"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>How to make More Sales on Etsy - Increase your sales| 01supply</title>
          <link rel="canonical" href="https://app.01supply.com/how-to-get-more-sales-on-etsy" />
          <meta
            name="description"
            content="How to Get More SALES on Etsy ? Increase your sales on Etsy. This eBook helps you build your Etsy business and grow quickly thanks to 5 golden strategies."
          />
          <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "How to get more sales on Etsy?",
          "image": [
            "https://app.01supply.com/static/media/img23.9187d900.jpg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
        </Helmet>

        <NavBar windowSize={windowSize} />

        <div
          className="column"
          style={{ width: "70vw", justifyContent: "flex-start", width: "60%", marginTop: "80px" }}
        >
          <img
            alt="Increase your sales on Etsy"
            style={
              windowSize > 1150
                ? { width: "100%", height: "auto" }
                : { width: "100%", height: "auto", marginTop: "50px" }
            }
            src={img23}
          />
          <h1
            style={{
              marginTop: "85px",
              fontSize: "35px",
              width: "100%",
              textAlign: "center",
              marginBottom: "50px",
            }}
            className="title-roboto-bold"
          >
            How to Get More SALES on Etsy ?<br />
            Increase your sales on Etsy
          </h1>
          <div
            style={{ width: "100%", alignItems: "flex-start" }}
            className={windowSize > 1150 ? "row" : "column"}
          >
            <img
              alt="Get more sales on Etsy ebook PDF"
              src={img22}
              style={windowSize > 1150 ? { width: "40%" } : { width: "100%", marginBottom: "20px" }}
            />
            <div
              style={windowSize > 1150 ? { width: "55%", marginLeft: "5%" } : { width: "100%" }}
              className="column"
            >
              <h2 className="title-roboto">Grab your eBook (PDF) for free here</h2>
              <Link
                style={
                  windowSize > 1150
                    ? {
                        minWidth: "300px",
                        height: "40px",
                        fontSize: "25px",
                        marginTop: "30px",
                        marginBottom: "50px",
                      }
                    : {
                        width: "100%",
                        height: "40px",
                        fontSize: "25px",
                        marginTop: "30px",
                        marginBottom: "50px",
                      }
                }
                to="/download-ebook-how-to-get-more-sales-on-etsy"
                className="button orange-back"
              >
                Download ebook
              </Link>
              <Link to="/download-ebook-how-to-get-more-sales-on-etsy" className="text-roboto-300 underline">
                Click here to download your eBook
              </Link>
            </div>
          </div>
          <h2 style={{ marginBottom: "5px", marginTop: "15px" }} className="title-roboto">
            What is Etsy?
          </h2>
          <p className="text-roboto">
            <span style={{ marginRight: "5px" }} className="text-roboto-bold">
              Etsy is an online B2C marketplace
            </span>
            that allows sellers to sell their products to worldwide customers.
          </p>
          <p style={{ marginTop: "10px" }} className="text-roboto">
            Etsy is specialized into
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              hand-crafted
            </span>
            , vintage and arts items.
          </p>
          <h3 style={{ marginBottom: "5px", marginTop: "15px" }} className="title-roboto">
            How does Etsy work ?
          </h3>
          <p className="text-roboto">
            Every seller can publish products and collect sales. Etsy charges $0.20 per listing with a 5%
            transaction fee.
          </p>
          <h3 style={{ marginBottom: "5px", marginTop: "15px" }} className="title-roboto">
            How popular is Etsy ?
          </h3>
          <p className="text-roboto underline">Here are some statistics about Etsy:</p>
          <ul>
            <li>Etsy has over 3 millions sellers over the world</li>
            <li>In 2020 Etsy’s annual revenue was up to $ 1.7bn</li>
            <li>Etsy’s annual net income in 2020 was $ 349m</li>
            <li>Etsy has over 60 million products listed on his platform</li>
          </ul>
          <p className="text-roboto">Here is some infographics about Etsy’s statistics :</p>
          <p style={{ marginBottom: "5px", marginTop: "5px" }} className="text-roboto-bold">
            In 2020, 62% of Etsy’s purchases were on mobile.
          </p>
          <img
            alt="Etsy statistics on mobile"
            style={
              windowSize > 1150 ? { maxWidth: "700px", height: "auto" } : { width: "100%", height: "auto" }
            }
            src={img25}
          />
          <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto-bold">
            California has the most Etsy sellers
          </p>
          <img
            alt="Statistics about Etsy sellers"
            style={
              windowSize > 1150 ? { maxWidth: "700px", height: "auto" } : { width: "100%", height: "auto" }
            }
            src={img26}
          />
          <h3 style={{ marginBottom: "5px", marginTop: "25px" }} className="title-roboto">
            No sales on Etsy ?
          </h3>
          <p className="text-roboto-bold">
            If you are reading this article, you are probably wondering how you can make more sales on Etsy?
            Maybe you don’t know why you are getting visits but no sales ? How can you increase your sales and
            earn a living on Etsy ?
          </p>
          <p style={{ marginTop: "10px" }} className="text-roboto-300">
            In order to get more sales, you can download our eBook here :
            <Link
              style={{ marginLeft: "10px" }}
              to="/download-ebook-how-to-get-more-sales-on-etsy"
              className="text-roboto-300 underline"
            >
              DOWNLOAD “Get More SALES on Etsy” - PDF format
            </Link>
          </p>

          <h2 style={{ marginBottom: "5px", marginTop: "25px" }} className="title-roboto">
            How to be more visible on Etsy ?
          </h2>
          <p style={{ marginTop: "20px", marginBottom: "30px" }} className="text-roboto-300">
            First step in order to get more sales is being more visible on Etsy.
          </p>
          <p style={{ marginTop: "0px", marginBottom: "20px" }} className="text-roboto-300 underline">
            Here are two tips to be more visible on Etsy:
          </p>
          <ul>
            <li>More products = more views = more sales</li>
            <li>Improve your Etsy SEO</li>
          </ul>
          <h3 style={{ marginBottom: "5px", marginTop: "25px" }} className="title-roboto">
            How do you get noticed ?
          </h3>
          <p className="text-roboto-300">
            You can stand out by publishing high quality images. This does not only only include having high
            resolution images. But also having images that showcase the product nicely. <br />
            Avoid watermarks, branding and unnecessary stuff on your product image as it will confuse the
            visitor and lower your click rate.
          </p>
          <p style={{ marginTop: "20px", marginBottom: "20px" }} className="text-roboto-bold">
            You can look at the top items in your category to take inspiration from them.
          </p>
          <h2 style={{ marginBottom: "5px", marginTop: "0px" }} className="title-roboto">
            Etsy SEO
          </h2>
          <p style={{ marginTop: "20px", marginBottom: "20px" }} className="text-roboto-bold">
            SEO means Search Engine Optimization.
            <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-roboto-300">
              Working on your
            </span>
            Etsy SEO
            <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-roboto-300">
              will make your products more visible in the search results. Thus you will get
            </span>
            more clicks and more sales.
          </p>
          <h3 style={{ marginBottom: "5px", marginTop: "0px" }} className="title-roboto">
            How long does it take for Etsy SEO to work ?
          </h3>
          <p style={{ marginTop: "20px", marginBottom: "20px" }} className="text-roboto-300">
            It depends on the changes you made and the platform you want to rank on. <br />
            Indeed, what
            <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-roboto-bold">
              brings your views
            </span>
            is not only Etsy SEO. Because Etsy product pages are listed on Google and a lot of views are
            coming from Google.
          </p>
          <ul>
            <li>
              <span style={{ marginRight: "5px" }} className="text-roboto-bold">
                Etsy SEO:
              </span>
              One you have made a change, it might take a couple of days before you see results.
            </li>
            <li>
              <span style={{ marginRight: "5px" }} className="text-roboto-bold">
                Google SEO:
              </span>
              Google has a lot more websites to handle and to rank so it might take one month or two before
              you see any change in your Google ranking.
            </li>
          </ul>
          <p className="text-roboto-300">
            Also, changing your main title resets your SEO as the title of the page is different. So avoid
            changing your title unless the product is really underperforming.
          </p>
          <h3 style={{ marginBottom: "5px", marginTop: "0px" }} className="title-roboto">
            How do I know if my Etsy SEO is working ?
          </h3>
          <img
            alt="More views on Etsy with SEO"
            style={
              windowSize > 1150
                ? { maxWidth: "700px", height: "auto", marginBottom: "20px", marginTop: "10px" }
                : { width: "100%", height: "auto", marginBottom: "20px", marginTop: "10px" }
            }
            src={img27}
          />
          <p className="text-roboto-300">
            You will notice it
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              after a couple of days
            </span>
            on Etsy. You can see your views and if there are no significant changes after at least one week,
            you know that your SEO is not optimal.
            <br />
            <br />
            You should also always check your ranking in the search results to see if you see your products or
            not once you’ve typed a keyword.
          </p>
          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            How to rank on Etsy and get more views ?
          </h3>
          <p className="text-roboto-300">
            Etsy SEO is quite simple to understand. You can learn everything in our free eBook here: lien vers
            la page de vente.
          </p>
          <p style={{ marginTop: "20px", marginBottom: "20px" }} className="text-roboto-300 underline">
            Here are some tips to improve your Etsy SEO:
          </p>
          <ul>
            <li>Choose your Shop name wisely (must refer to what you sell)</li>
            <li>
              Look for
              <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
                Etsy’s keyword suggestions
              </span>
              while you are searching for something
            </li>
            <li>Gain backlinks</li>
            <li>Add more products in order to rank your overall shop</li>
            <li>Use 13 relevant tags</li>
            <li>Provide a great customer experience in order to get reviews</li>
            <li>
              One you
              <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
                publish a new listing,
              </span>
              Etsy boosts it during a couple of days in order to see the relevancy. So it is better to publish
              new listings rather than edit old ones.
            </li>
            <li>Offer free shipping</li>
            <li>Offer worldwide shipping</li>
          </ul>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Change your Etsy tags quite often
          </h3>
          <p className="text-roboto-300">
            Changing your tags depending on events will help you find the
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              best tags
            </span>
            and always be in the top search.
          </p>

          <h2 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Connect your shop with your social media accounts
          </h2>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Facebook and Etsy
          </h3>
          <p className="text-roboto-300">
            Connecting your Etsy shop to Facebook can help you
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              gain more followers.
            </span>
            It also builds trust and community which is great for returning customers.
          </p>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            How to use Pinterest for an Etsy shop ?
          </h3>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            After connecting your Etsy shop to Pinterest, you can start
            <span style={{ marginLeft: "5px" }} className="text-roboto-bold">
              promoting your products on the platform.
            </span>
          </p>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            Pinterest is about pins and boards. If you want to get visits from Pinterest, you need to publish
            a lot of content. By a lot of content, I mean
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              at least 10 images per day
            </span>
            on a daily basis.
          </p>
          <p className="text-roboto-300">
            Then, you can group performing pins in the same board in order to get subscribers to this board.
            You can also contact owners of big boards and ask them to add your pins to their boards. This is a
            great networking strategy.
          </p>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            One easy trick with coupon codes
          </h3>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            Be sure to share
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              free coupons with a deadline on your social media.
            </span>
            This gives your subscribers a reason to buy. It is important that you support the purchasing
            process on each step in order to increase your sales on Etsy.
          </p>

          <h2 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Etsy algorithm
          </h2>
          <p className="text-roboto-300">
            Etsy is a company that wants to make profit. <br />
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-300 underline">
              So here is what the
              <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold underline">
                algorithm
              </span>
              is about:
            </span>
          </p>
          <ul>
            <li>Promoting sellers that use ads.</li>
            <li>Promoting sellers with a lot of listings</li>
            <li>Promoting sellers that sell related and similar items in their shop</li>
            <li>Promoting sellers that can afford large amount of items to sell</li>
          </ul>

          <h2 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            How to be successful on Etsy ?
          </h2>
          <img
            alt="Revenue dashboard Etsy"
            style={
              windowSize > 1150
                ? { maxWidth: "700px", height: "auto", marginBottom: "20px", marginTop: "10px" }
                : { width: "100%", height: "auto", marginBottom: "20px", marginTop: "10px" }
            }
            src={img29}
          />
          <p className="text-roboto-300">
            <span style={{ marginRight: "5px" }} className="text-roboto-bold">
              Having more sales on Etsy is a long journey.
            </span>
            It’s all about perseverance and processes. Also, consistency is very important.
          </p>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Recover the abandoned Shopping carts thanks to Discounts
          </h3>
          <img
            alt="Abandonned cart"
            style={
              windowSize > 1150
                ? { maxWidth: "700px", height: "auto", marginBottom: "20px" }
                : { width: "100%", height: "auto", marginBottom: "20px" }
            }
            src={img28}
          />

          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            A customer that added a product to his cart is very likely to buy a product. So maybe he did not
            buy it because the item was too expensive.
          </p>
          <p className="text-roboto-300">
            So be sure to
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              set up abandoned cart coupon campaigns in order to increase your sales.
            </span>
            Indeed, this might be the point in order to claim the sale.
          </p>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Offer free shipping + worldwide shipping
          </h3>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            When a customer has to pay for shipping once he adds an item to his cart, it can really be
            discouraging for him. So be sure to include your shipping costs into your price so that you can
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              offer free shipping.
            </span>
            Free shipping is also good for SEO.
          </p>
          <p className="text-roboto-300">
            This is exactly the same with worldwide shipping. If you can’t ship your product to the buyer’s
            country, that is another sale missed.
          </p>

          <h2 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            What to sell on Etsy ?
          </h2>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            Selling what customers are searching for is very important. It is a common merchant mistake to
            sell items that nobody wants. So be sure to check if there are alread
            <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-roboto-bold">
              similar items that are getting sales.
            </span>
          </p>

          <h3 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            What is the most searched thing on Etsy ?
          </h3>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300 underline">
            Here are the top searched items on Etsy:
          </p>
          <ul>
            <li>Jewelry</li>
            <li>Wedding</li>
            <li>Accessories</li>
            <li>Party supplies</li>
            <li>Clothing</li>
            <li>Home & Living</li>
            <li>Arts</li>
          </ul>
          <h2 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            How to boost sales on Etsy ?
          </h2>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            In order to boost your sales, you can try connecting an app
            <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-roboto-bold underline">
              01supply
            </span>
            works with Etsy shops as you can one click import your Etsy items to the app.
          </p>
          <Link
            to={routes.becomeSupplier1(i18n.language).path}
            className="text-roboto-300 underline text-gradient"
          >
            Learn what is 01supply here
          </Link>
          <h2 style={{ marginBottom: "10px", marginTop: "20px" }} className="title-roboto">
            Can I make a living on Etsy ?
          </h2>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            <span style={{ marginRight: "5px" }} className="text-roboto-bold">
              Yes you can.
            </span>
            A lot of sellers are doing thousands of sales a year which is often enough to
            <span style={{ marginRight: "5px", marginLeft: "5px" }} className="text-roboto-bold">
              earn a living.
            </span>
          </p>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            It also depends on your margin. Lowering your price does not mean more sales. Just give it a try
            and you will find out by yourself.
          </p>
          <p style={{ marginBottom: "20px" }} className="text-roboto-bold">
            The top Etsy seller has made over 2.1 million since he opened his shop.
          </p>
          <p style={{ marginBottom: "20px" }} className="text-roboto-300">
            Don’t forget to download our Free eBook here:{" "}
            <Link
              to="/download-ebook-how-to-get-more-sales-on-etsy"
              className="text-roboto-300 underline text-gradient"
            >
              Download the Free Ebook
            </Link>
            <br />
            It won’t be free forever.
          </p>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
  );
};

export default EbookArticle;
