import Raact from "react";
import { useTranslation } from "react-i18next";
import { Radio, Switch, Space } from "antd";

const UpdateAlerts = ({ user, handleUpdateSetting }) => {
  const { t } = useTranslation();

  return (
    <div className="column">
      <p className="title-500 start">{t("updateAlerts.title-2")}</p>
      <Radio.Group
        style={{ marginBottom: "15px", marginTop: "15px" }}
        className="blue"
        defaultValue={user?.updateAlerts?.outOfStockProduct?.setQuantity ? "true" : ""}
        onChange={(e) =>
          handleUpdateSetting({
            updateAlerts: {
              ...user.updateAlerts,
              outOfStockProduct: { ...user?.updateAlerts?.outOfStockProduct, setQuantity: !!e.target.value },
            },
          })
        }
      >
        <Space direction="vertical">
          <Radio value="">{t("updateAlerts.doNothing")}</Radio>
          <Radio value="true">{t("updateAlerts.setQuantityToZero")}</Radio>
        </Space>
      </Radio.Group>

      <div className="row">
        <Switch
          defaultChecked={user?.updateAlerts?.outOfStockProduct?.notify}
          style={{ marginRight: "10px" }}
          onChange={(checked) => {
            handleUpdateSetting({
              updateAlerts: {
                ...user.updateAlerts,
                outOfStockProduct: {
                  ...user?.updateAlerts?.outOfStockProduct,
                  notify: !user?.updateAlerts?.outOfStockProduct?.notify,
                },
              },
            });
          }}
        />
        <p style={{ fontSize: "15px" }} className="text start montserrat">
          {t("updateAlerts.notifyMe")}
        </p>
      </div>
    </div>
  );
};

export default UpdateAlerts;
