import React, { useState } from "react";
import { Input, Form, message } from "antd";
import otherLogo from "../../../../../images/illustrations/store.jpg";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../../../../services/updateUser";

const OtherShop = ({ user, onCancel, setUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shopInformations, setShopInformations] = useState({
    url: "",
    cms: "",
  });
  const { t } = useTranslation();

  const onSubmit = async () => {
    updateUser({ user: { shop: { ...user.shop, ...shopInformations } } }).then(() => {
      message.success(t("messages.shopConnected"));
      setUser({ ...user, shop: { ...user.shop, ...shopInformations } });
      onCancel();
    });
  };

  return (
    <div onClick={() => setIsOpen(true)} className="card-connect-shop">
      <div className="row width-100">
        <img
          src={otherLogo}
          alt="shopify"
          style={{ height: "40px", filter: "grayscale(1)", marginRight: "70px" }}
        />
        <p className="title-bold grey">{t("other")}</p>
      </div>
      {isOpen && (
        <div style={{ marginTop: "20px" }} className="card-connect-shop-open">
          <Form
            name="store completion"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              name="url"
              rules={[{ required: true, message: t("pleaseCompleteField") }]}
              className="width-100"
              style={{ margin: "5px 0" }}
              onChange={(e) => setShopInformations({ ...shopInformations, url: e.target.value })}
            >
              <Input className="width-100" placeholder={`${t("yourStore")} URL`} />
            </Form.Item>
            <Form.Item
              name="cms"
              rules={[{ required: true, message: t("pleaseCompleteField") }]}
              className="width-100"
              style={{ margin: "10px 0 15px 0" }}
              onChange={(e) => setShopInformations({ ...shopInformations, cms: e.target.value })}
            >
              <Input className="width-100" placeholder="Your CMS (Woocommerce, Wix, Squarespace,...)" />
            </Form.Item>
            <Form.Item className="width-100">
              <button type="primary" htmlType="submit" className="button big orange-back width-100">
                {t("linkMyStore")}
              </button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default OtherShop;
