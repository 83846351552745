import React, { useState } from "react";
import { message, InputNumber, Select } from "antd";

import { adminServices } from "../../services/admin";

import { productCategoriesArray } from "../../constants/productCategories";

const { Option } = Select;

const inputStyle = {
  width: "90%",
  margin: "15px 5% 5px 5%",
};

const AdminButton = ({ product }) => {
  const [newViewCount, setNewViewCount] = useState(undefined);

  const updateProduct = ({ newProduct, e }) => {
    e?.preventDefault();
    e?.stopPropagation();
    adminServices.updateProduct({ productId: product._id, newProduct }).then((res) => {
      if (res.status === 200) {
        message.success("product update");
      } else {
        message.error("Error");
      }
    });
  };

  return localStorage.getItem("admin") === "true" ? (
    <div className="column width-100">
      <button
        style={{ background: "#E83535", width: "90%", marginBottom: "15px", marginLeft: "5%" }}
        onClick={(e) => updateProduct({ newProduct: { ban: true }, e })}
        className="button orange-back"
      >
        BAN
      </button>
      <button
        style={{ background: "rgb(34 34 34)", width: "90%", marginBottom: "15px", marginLeft: "5%" }}
        onClick={(e) =>
          updateProduct({
            newProduct: { isWinningProduct: product.isWinningProduct ? false : true },
            e,
          })
        }
        className="button orange-back"
      >
        {product.isWinningProduct && "NO"} WINNING
      </button>
      <button
        style={{ background: "rgb(34 34 34)", width: "90%", marginBottom: "15px", marginLeft: "5%" }}
        onClick={(e) =>
          updateProduct({
            newProduct: { isHighMarginProduct: product.isHighMarginProduct ? false : true },
            e,
          })
        }
        className="button orange-back"
      >
        {product.isHighMarginProduct && "NO"} HIGH MARGIN
      </button>
      <button
        style={{ background: "rgb(34 34 34)", width: "90%", marginBottom: "15px", marginLeft: "5%" }}
        onClick={(e) => updateProduct({ newProduct: { resellersLeft: Math.floor(Math.random() * 10) }, e })}
        className="button orange-back"
      >
        ADD reseller left
      </button>
      <InputNumber
        key={product.view_number}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={inputStyle}
        defaultValue={product.view_number}
        onChange={(value) => setNewViewCount(value)}
      />
      {newViewCount && (
        <button
          style={{ ...inputStyle, marginTop: "5px" }}
          onClick={(e) => {
            updateProduct({ newProduct: { view_number: newViewCount }, e });
            setNewViewCount(undefined);
          }}
          className="button orange-back"
        >
          SAVE
        </button>
      )}
      <Select
        key={product.category}
        style={inputStyle}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) =>
          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
        onChange={(categoryIndex) => {
          updateProduct({ newProduct: { category: productCategoriesArray[categoryIndex] } });
        }}
        defaultValue={product.category || "Choose category"}
      >
        {productCategoriesArray &&
          productCategoriesArray.map((category, index) => <Option value={index}>{category}</Option>)}
      </Select>
    </div>
  ) : (
    <></>
  );
};

export default AdminButton;
