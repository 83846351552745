import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Menu } from "antd";
import moment from "moment";

import { url } from "../../api/url";
import { header } from "../../api/header";

import RegistrationChart from "../../components/admin/statistics/RegistrationsChart";
import CurrentSubscriptionsChart from "../../components/admin/statistics/CurrentSubscriptionsChart";
import SubscriptionChart from "../../components/admin/statistics/SubscriptionChart";
import StepsRepartitionChart from "../../components/admin/statistics/StepsRepartitionChart";
import YearlySubscriptionsRepartitionChart from "../../components/admin/statistics/YearlySubscriptionsRepartitionChart";
import CurrentSubscriptionsRepartitionChart from "../../components/admin/statistics/CurrentSubscriptionsRepartitionChart";
import MoneyGainChart from "../../components/admin/statistics/MoneyGainChart";
import MedianSubscriptionChart from "../../components/admin/statistics/MedianSubscriptionChart";
import MedianRetentionChart from "../../components/admin/statistics/MedianRetentionChart";
import MedianEarningByVisitor from "../../components/admin/statistics/MedianEarningByVisitor";
import MedianEarningByRegistration from "../../components/admin/statistics/MedianEarningByRegistration";
import MedianEarningBySubscription from "../../components/admin/statistics/MedianEarningBySubscription";
import SupplierRegistrationChart from "../../components/admin/statistics/SupplierRegistrationChart";
import SupplierMedianProductCountChart from "../../components/admin/statistics/SupplierMedianProductCountChart";
import ProductChart from "../../components/admin/statistics/ProductChart";
import FirstStepEvolutionChart from "../../components/admin/statistics/FirstStepEvolutionChart";

const Charts = () => {
  const [statistics, setStatistics] = useState([]);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));
  const [isMonthly, setIsMonthly] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    getStatistics();
    if (window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, []);

  const parseDate = (date) => {
    return moment(date).utc().format("DD/MM HH", "Europe/Paris");
  };

  const getStatistics = async () => {
    const res = await fetch(`${url}/statistics/${adminId}?startDate=2023-05-01T22:00:00.000Z`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setStatistics(resJson.data);
    } else message.error(t("messages.error"));
  };

  return (
    <div style={{ paddingTop: "50px", paddingBottom: "50px" }} className="page">
      <Menu onClick={(e) => console.log(e)} mode="horizontal" defaultSelectedKeys={["global"]}>
        <Menu.Item key="global">Statistiques globales</Menu.Item>
        <Menu.Item key="monthly">Statistiques 30 derniers jours</Menu.Item>
      </Menu>
      <div style={{ padding: "0 5vw" }} className="row row-wrap width-100 space-between">
        <div className="column width-100 start">
          <p style={{ marginLeft: "2vw" }} className="title title-bold">
            IMPORTANTS KPI
          </p>
          <div className="row row-wrap width-100 space-between" style={{ alignItems: "flex-start" }}>
            <MedianRetentionChart intervals={statistics?.charts?.intervals} />
            <MedianEarningByVisitor intervals={statistics?.charts?.intervals} />
            <MedianEarningByRegistration intervals={statistics?.charts?.intervals} />
            <MedianEarningBySubscription intervals={statistics?.charts?.intervals} />
            <FirstStepEvolutionChart intervals={statistics?.charts?.intervals} />
          </div>
        </div>

        <div style={{ marginTop: "30px" }} className="column width-100 start">
          <p style={{ marginLeft: "2vw" }} className="title title-bold">
            ACQUISITION & ACTIVATION
          </p>
          <div className="row row-wrap width-100 space-between" style={{ alignItems: "flex-start" }}>
            <RegistrationChart intervals={statistics?.charts?.intervals} />
            <StepsRepartitionChart intervals={statistics?.charts?.intervals} />
          </div>
        </div>

        <div style={{ marginTop: "30px" }} className="column width-100 start">
          <p style={{ marginLeft: "2vw" }} className="title title-bold">
            KPI ABONNEMENTS
          </p>
          <div className="row row-wrap width-100 space-between" style={{ alignItems: "flex-start" }}>
            <SubscriptionChart intervals={statistics?.charts?.intervals} />
            <div className="column start">
              <CurrentSubscriptionsRepartitionChart intervals={statistics?.charts?.intervals} />
              <MedianSubscriptionChart intervals={statistics?.charts?.intervals} />
            </div>
            <CurrentSubscriptionsChart intervals={statistics?.charts?.intervals} />
            <YearlySubscriptionsRepartitionChart intervals={statistics?.charts?.intervals} />
          </div>
        </div>

        <div style={{ marginTop: "30px" }} className="column width-100 start">
          <p style={{ marginLeft: "2vw" }} className="title title-bold">
            CHIFFRE D'AFFAIRE
          </p>
          <div className="row row-wrap width-100 space-between" style={{ alignItems: "flex-start" }}>
            <MoneyGainChart intervals={statistics?.charts?.intervals} />
          </div>
        </div>

        <div style={{ marginTop: "30px" }} className="column width-100 start">
          <p style={{ marginLeft: "2vw" }} className="title title-bold">
            FOURNISSEURS
          </p>
          <div className="row row-wrap width-100 space-between" style={{ alignItems: "flex-start" }}>
            <SupplierRegistrationChart intervals={statistics?.charts?.intervals} />
            <SupplierMedianProductCountChart intervals={statistics?.charts?.intervals} />
            <ProductChart intervals={statistics?.charts?.intervals} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
