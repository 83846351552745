import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { url } from "../api/url";
import { header } from "../api/header";

const CheckTokenValidity = () => {
  const [redirect, setRedirect] = useState(false);

  const locationMustLogin = [
    "/messages",
    "/your-informations",
    "/my-orders",
    "/mobile-setting-menu",
    "/disconnect",
    "/connect",
    "/your-shop-supplier",
    "/supplier-service",
    "/create-product",
    "/plans-supplier",
    "/import-products-csv",
    "/supplier-documentation",
    "/setup-supplier",
    "/my-payments",
    "/manage-shipping-methods",
    "/sourcing-products",
    "/your-shop-reseller",
    "/import-list",
    "/reseller-documentation",
    "/setup-reseller",
    "/suppliers",
    "/affiliation/payouts",
    "/affiliation/referrals",
    "/inventory",
  ];

  useEffect(() => {
    if (localStorage.getItem("tokenCreatosell")) {
      checkToken();
    }
  }, [window.location.pathname]);

  const checkToken = async () => {
    const res = await fetch(`${url}/check-token`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        token: localStorage.getItem("tokenCreatosell"),
        email: localStorage.getItem("emailAccountCreatosell"),
      }),
    });
    if (res.status !== 200) {
      disconnect();
    }
  };

  const disconnect = () => {
    // set algolia analytics user id
    window.aa('setUserToken', "");
    localStorage.setItem("tokenCreatosell", "");
    localStorage.setItem("typeAccountCreatosell", "");
    localStorage.setItem("emailAccountCreatosell", "");
    localStorage.setItem("idAccountCreatosell", "");
    localStorage.setItem("nameAccountCreatosell", "");
    localStorage.setItem("secretAccountId01supply", "");

    if(locationMustLogin.find(loc => window.location.pathname.includes(loc)))
    setRedirect(true)

    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  return <React.Fragment>{redirect && <Redirect to="/join-us" />}</React.Fragment>;
};

export default CheckTokenValidity;
