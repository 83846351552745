import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Skeleton, Pagination, Checkbox } from "antd";
import { Helmet } from "react-helmet";
import Menu from "../components/menu/FirstMenu";
import MobileMenu from "../components/menu/mobile/FirstMenu";

import { url } from "../api/url";
import { header } from "../api/header";
import Product from "../components/ProductCard";
import { MarketMenu } from "../components/menu/MarketMenu";
import { PlusOutlined } from "@ant-design/icons";
import Header from "../components/menu/marketplaceNavbar";
import Invitation from "../components/Invitation";
import FilterLabels from "../components/marketplace/filterLabels/FilterLabels";
import PriceSorting from "../components/marketplace/sorting/PriceSort";
import SuggestionHeader from "../components/marketplace/productSuggestion/Header";

import { Modal } from "../wrappers";

import OgImg from "../images/og_image_2.png";

import RegisterModal from "../components/modals/RegisterModal";
import ResellerSetupProgress from "../components/reseller/SetupProgress";

const Marketplace = ({ key }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [disclamer, setDisclamer] = useState("false");
  const [products, setProducts] = useState([]);
  const [loadProducts, setLoadProducts] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);
  const [supplierMarket, setSupplierMarket] = useState(
    localStorage.getItem("typeAccountCreatosell") === "seller" &&
      !window.location.href.includes("marketplace")
      ? true
      : false
  );
  const [user, setUser] = useState({});
  const [activeInvitation, setActiveInvitation] = useState(false);
  const [thematicsList, setThematicsList] = useState([]);
  const [thematicFilter, setThematicFilter] = useState("");
  const [popupLogin, setPopupLogin] = useState("");

  const [thematics, setThematics] = useState(window.location.href.includes("thematics") ? true : false);

  const [noResult, setNoResult] = useState(false);

  const [categorySelect, setCategorySelect] = useState("all");
  const [searchWord, setSearchWord] = useState("");

  const [totalProducts, setTotalProducts] = useState(1000);

  const [isStickyNav, setIsStickyNav] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const [filterMenuOn, setFilterMenuOn] = useState(false);

  const [refreshProductsFunction, setRefreshProductsFunction] = useState();

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxprice] = useState(0);
  const [locationSelect, setLocationSelect] = useState({});
  const [deliverTo, setDeliverTo] = useState({});
  const [maxDeliveryTime, setMaxDeliveryTime] = useState(0);
  const [originRegion, setOriginRegion] = useState({});
  const [sortPrice, setSortPrice] = useState("");

  const { t } = useTranslation();

  const isSuggestion = window.location.pathname.includes("/suggestion");

  window.onscroll = function (e) {
    if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else setIsStickyNav(false);
  };

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, [window.tidioChatApi]);

  useEffect(() => {
    if (
      window.location.href &&
      window.location.href.split("/page") &&
      window.location.href.split("/page")[1]
    ) {
      const page = parseInt(window.location.href.split("/page")[1]);
      setCurrentPage(parseInt(page) || 1);
    }

    findThematics();

    if (idAccount && secretId && token) getUser();
  }, []);

  useEffect(() => {
    detectPagePath();
  }, [window.location.pathname]);

  const detectPagePath = () => {
    if (window.location.href.includes("suppliers")) {
      setSupplierMarket(true);
    } else setSupplierMarket(false);

    if (window.location.href.includes("thematics")) {
      if (window.location.href.includes("thematics/")) {
        setThematicFilter(window.location.href.split("thematics/")[1]);
        setThematics(false);
        setSupplierMarket(false);
      } else {
        setThematics(true);
        setThematicFilter("");
      }
    } else setThematics(false);

    localStorage.setItem(
      "searchMarketplace01supply",
      `${window.location.search ? window.location.search : ""}`
    );
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  const findThematics = async () => {
    const res = await fetch(`${url}/thematic/find-all-no-vedettes`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      const thematics = resJson.thematics;
      thematics.push(thematics.splice(thematics.indexOf(6), 1)[0]);
      setThematicsList(thematics);
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page || 1);
    setLoadProducts(true);
    setRefreshProductsFunction({ params: { page: page || 1 } });

    const element = document.getElementsByClassName("column-scrolling")?.[0];
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const lastPageNumber = Math.ceil(totalProducts / pageSize);

  return (
    <div key={key || ""} style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page">
      <style>
        {`
        .ant-pagination-item.ant-pagination-item-${lastPageNumber} {
          display: none;
        }
      `}
      </style>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marketplace | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/marketplace" />
        <meta name="description" content={t("marketplace.metas.description")} />
        <meta property="og:description" content={t("marketplace.metas.description")} />
        <meta property="og:url" content="https://app.01supply.com/marketplace" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Marketplace | 01supply`} />
        <meta property="og:image" content={OgImg} />
      </Helmet>
      <ResellerSetupProgress user={user} />
      <RegisterModal modalOn={popupLogin} setModal={setPopupLogin} />
      {activeInvitation && (
        <Invitation setActiveInvitation={setActiveInvitation} activeInvitation={activeInvitation} />
      )}
      {disclamer !== "false" &&
        typeAccount === "seller" &&
        localStorage.getItem("disableResellerDiclamer01supply") !== "true" && (
          <Modal
            className="modal-disclamer"
            visible={true}
            onCancel={() => setDisclamer("false")}
            footer={[]}
          >
            <div className="column" style={{ alignItems: "center", width: "90%" }}>
              <p style={{ fontSize: "35px", fontFamily: "Roboto" }} className="title">
                {t("disclaimer")}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginTop: "18px",
                  marginBottom: "18px",
                  textAlign: "center",
                  fontFamily: "Roboto",
                }}
                className="text-bold"
              >
                {t("marketplace.modalDisclaimer.texts.1")}
              </p>
              <p
                className="text"
                style={{
                  fontStyle: "italic",
                  fontSize: "16px",
                  textAlign: "start",
                  letterSpacing: "0.2px",
                  fontWeight: "400",
                  fontFamily: "Roboto",
                }}
              >
                {t("marketplace.modalDisclaimer.texts.2")}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  textAlign: "start",
                  marginTop: "12px",
                  marginBottom: "20px",
                  fontFamily: "Roboto",
                }}
                className="title-300"
              >
                {t("marketplace.modalDisclaimer.texts.3")}
                <br />
                <br />
                {t("marketplace.modalDisclaimer.texts.4")}
                <Link to="/sourcing-products" style={{ textDecoration: "underline", marginLeft: "6px" }}>
                  {t("here")}.
                </Link>
                <br />
                {t("marketplace.modalDisclaimer.texts.5")}
                <br />
                <br />
                <span style={{ fontSize: "12px" }}>{t("marketplace.modalDisclaimer.texts.6")}</span>
              </p>
              <Checkbox
                style={{ marginBottom: "35px" }}
                onChange={(e) => {
                  if (localStorage.getItem("disableResellerDiclamer01supply") === "false")
                    localStorage.setItem("disableResellerDiclamer01supply", "true");
                  else localStorage.setItem("disableResellerDiclamer01supply", "false");
                }}
              >
                <p style={{ fontSize: "16px" }} className="title-300">
                  {t("marketplace.modalDisclaimer.dontShowAgain")}
                </p>
              </Checkbox>
              <div style={{ width: "100%", position: "relative", marginBottom: "20px" }} className="row">
                <p
                  onClick={() => setActiveInvitation(true)}
                  style={{ textAlign: "start" }}
                  className="text-gradient"
                >
                  {t("marketplace.modalDisclaimer.inviteSupplier")}
                </p>
                <button
                  onClick={() => {
                    localStorage.setItem("disableResellerDiclamer01supply", "true");
                    setDisclamer("false");
                  }}
                  className="button orange-back"
                  style={{ position: "absolute", right: "0px" }}
                >
                  {t("marketplace.modalDisclaimer.agree")}
                </button>
              </div>
            </div>
          </Modal>
        )}

      <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page-start">
        {windowSize > 1150 ? (
          <Menu locationP={isSuggestion ? "suggestion" : "marketplace"} userProps={user} />
        ) : (
          window.location.href.includes("join-us") && <MobileMenu locationP="marketplace" />
        )}
        <div
          style={{ padding: "0", paddingBottom: windowSize > 1150 ? "100px" : "250px" }}
          className="column-scrolling"
        >
          {windowSize <= 1150 && (
            <Header
              setFilterMenuOn={setFilterMenuOn}
              setCategorySelect={setCategorySelect}
              isMarketMenu={true}
              setSearchWord={setSearchWord}
              searchWord={searchWord}
              windowSize={windowSize}
              categorySelect={categorySelect}
              user={user}
              setRefreshProductsFunction={setRefreshProductsFunction}
              isStickyNav={isStickyNav}
            />
          )}

          {!supplierMarket && (
            <div
              id="container-marketplace"
              className="column"
              style={
                windowSize > 1150
                  ? {
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      paddingBottom: "0px",
                      paddingLeft: "20px",
                      minHeight: "100vh",
                    }
                  : { alignItems: "center" }
              }
            >
              {windowSize > 1150 && (
                <>
                  <Header
                    setFilterMenuOn={setFilterMenuOn}
                    setCategorySelect={setCategorySelect}
                    setSearchWord={setSearchWord}
                    searchWord={searchWord}
                    windowSize={windowSize}
                    categorySelect={categorySelect}
                    user={user}
                    setRefreshProductsFunction={setRefreshProductsFunction}
                    filterMenuOn={filterMenuOn}
                    setCurrentPage={setCurrentPage}
                    setTotalProducts={setTotalProducts}
                    page={currentPage}
                    setLoadProducts={setLoadProducts}
                    setNoResult={setNoResult}
                    thematicsList={thematicsList}
                    products={products}
                    setProducts={setProducts}
                    refreshProductsFunction={refreshProductsFunction}
                    isMobile={windowSize < 1150}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxprice={setMaxprice}
                    locationSelect={locationSelect}
                    setLocationSelect={setLocationSelect}
                    deliverTo={deliverTo}
                    setDeliverTo={setDeliverTo}
                    maxDeliveryTime={maxDeliveryTime}
                    setMaxDeliveryTime={setMaxDeliveryTime}
                    originRegion={originRegion}
                    setOriginRegion={setOriginRegion}
                    sortPrice={sortPrice}
                    setSortPrice={setSortPrice}
                  />
                </>
              )}

              <div
                className="column"
                style={
                  windowSize > 1150
                    ? { marginTop: `${"180px"}`, width: "100%", alignItems: "center", marginLeft: "30px" }
                    : { width: "100%" }
                }
              >
                {/* FILTER MOBILE */}
                {windowSize <= 1150 && (
                  <MarketMenu
                    setFilterMenuOn={setFilterMenuOn}
                    filterMenuOn={filterMenuOn}
                    searchWord={searchWord}
                    setSearchWord={setSearchWord}
                    setCategorySelect={setCategorySelect}
                    setCurrentPage={setCurrentPage}
                    setTotalProducts={setTotalProducts}
                    page={currentPage}
                    setLoadProducts={setLoadProducts}
                    windowSize={windowSize}
                    categorySelect={categorySelect}
                    setNoResult={setNoResult}
                    thematicsList={thematicsList}
                    products={products}
                    setProducts={setProducts}
                    setRefreshProductsFunction={setRefreshProductsFunction}
                    refreshProductsFunction={refreshProductsFunction}
                    isMobile={windowSize < 1150}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxprice={setMaxprice}
                    locationSelect={locationSelect}
                    setLocationSelect={setLocationSelect}
                    deliverTo={deliverTo}
                    setDeliverTo={setDeliverTo}
                    maxDeliveryTime={maxDeliveryTime}
                    setMaxDeliveryTime={setMaxDeliveryTime}
                    originRegion={originRegion}
                    setOriginRegion={setOriginRegion}
                  />
                )}

                {isSuggestion && (
                  <div className="width-100" style={{ marginTop: "30px", marginBottom: "-15px" }}>
                    {" "}
                    <SuggestionHeader />{" "}
                  </div>
                )}

                {/* PRICE SORTING 
                <PriceSorting
                  setSortPrice={setSortPrice}
                  sortPrice={sortPrice}
                  setRefreshProductsFunction={setRefreshProductsFunction}
                />
                */}

                <FilterLabels
                  minPrice={minPrice}
                  setMinPrice={setMinPrice}
                  maxPrice={maxPrice}
                  setMaxprice={setMaxprice}
                  locationSelect={locationSelect}
                  setLocationSelect={setLocationSelect}
                  deliverTo={deliverTo}
                  setDeliverTo={setDeliverTo}
                  maxDeliveryTime={maxDeliveryTime}
                  setMaxDeliveryTime={setMaxDeliveryTime}
                  originRegion={originRegion}
                  setOriginRegion={setOriginRegion}
                  setRefreshProductsFunction={setRefreshProductsFunction}
                />

                {loadProducts && !noResult ? (
                  <div style={windowSize > 1150 ? { width: "90%" } : { width: "95vw", marginLeft: "2.5vw" }}>
                    <Skeleton
                      style={{ marginTop: "80px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                      className="image-product margin-top-landing"
                      loading={true}
                      active
                    />
                    <Skeleton
                      style={{ marginTop: "20px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                      className="image-product"
                      loading={true}
                      active
                    />
                  </div>
                ) : (
                  <div
                    key={products[0] ? products[0]._id + products.length : products.length}
                    id="marketplace-scrolling"
                    style={
                      windowSize > 1150
                        ? {
                            width: "95%",
                            height: "auto",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            flex: "0 0 auto",
                            marginTop: "30px",
                            marginLeft: "30px",
                          }
                        : {
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            flex: "0 0 auto",
                          }
                    }
                    className="row wrap"
                  >
                    {noResult || !products.length ? (
                      <div
                        className="column"
                        style={{
                          width: "100%",
                          alignItems: "center",
                          minHeight: "100vh",
                          marginTop: "30px",
                          marginLeft: windowSize <= 1150 ? "30px" : "",
                        }}
                      >
                        <p className="title-400" style={{ fontSize: "28px" }}>
                          {t("marketplace.noResults.texts.1")}
                          <br />
                          {t("marketplace.noResults.texts.2")}

                          <p style={{ marginTop: "30px", fontSize: "22px" }} className="title-400">
                            {t("marketplace.noResults.texts.3")}
                            <Link
                              onClick={(e) => {
                                if (!typeAccount || typeAccount !== "seller") {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setPopupLogin("seller");
                                }
                              }}
                              style={{ textDecoration: "underline", marginLeft: "6px" }}
                              to="/sourcing-products"
                            >
                              {t("marketplace.noResults.texts.4")}
                            </Link>
                          </p>
                        </p>
                      </div>
                    ) : (
                      products &&
                      products.length > 0 &&
                      products.map((product, index) => {
                        return (
                          product.is_publish &&
                          !product.ban && (
                            <>
                              <Product
                                setUser={setUser}
                                user={user}
                                key={product._id}
                                index={index}
                                location={"landing"}
                                product={product}
                              />
                            </>
                          )
                        );
                      })
                    )}
                  </div>
                )}
              </div>
              {noResult === false && (
                <Pagination
                  key={
                    currentPage +
                    loadProducts +
                    supplierMarket +
                    products +
                    thematics +
                    supplierMarket +
                    thematicFilter +
                    totalProducts
                  }
                  disabled={loadProducts}
                  style={{ marginBottom: "50px", marginTop: "30px" }}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  defaultCurrent={currentPage}
                  total={totalProducts}
                  pageSize={pageSize}
                />
              )}
            </div>
          )}
          {localStorage.getItem("typeAccountCreatosell") === "creater" && (
            <Link className="add-absolute" to="/create-product">
              <PlusOutlined />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
