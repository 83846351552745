import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "antd";

import {Modal} from '../../wrappers'

import subscribeIcon from "../../images/subscribe.jpg";

const ModalNeedSubsribe = ({ setModal }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="modal-account"
      onOk={() => {}}
      visible={true}
      onCancel={() => setModal(false)}
      footer={[
        <Button key="back" onClick={() => setModal(false)}>
          {t("cancel")}
        </Button>,
      ]}
    >
      <div style={{ alignItems: "center" }} className="column">
        <img
          style={{ width: "200px", height: "auto", marginTop: "-120px", borderRadius: "50%" }}
          src={subscribeIcon}
        />
        <p style={{ marginTop: "30px" }} className="title monteserrat">
          {t("components.modal.16")}
        </p>
        <p className="text-300 montserrat">{t("components.modal.17")}</p>
        <Link style={{ marginTop: "30px" }} to="/pricing" className="button big">
          {t("components.modal.18")}
        </Link>
      </div>
    </Modal>
  );
};

export default ModalNeedSubsribe;
