exports.productCategories = {
  "Jewels & Accessories": {
    JEWELS: [
      {
        id: 1,
        name: "Rings",
        productCategory: "Rings",
      },
      {
        id: 2,
        name: "Necklaces",
        productCategory: "Necklaces",
      },
      {
        id: 3,
        name: "Bracelets",
        productCategory: "Bracelets",
      },
      {
        id: 4,
        name: "Earrings",
        productCategory: "Earrings",
      },
      {
        id: 5,
        name: "Hair Jewels",
        productCategory: "Hair jewels",
      },
    ],
    ACCESSORIES: [
      {
        id: 6,
        name: "Handbags",
        productCategory: "Handbags", // à scrapper
      },
      {
        id: 7,
        name: "Backpacks",
        productCategory: "Backpacks", // à scrapper
      },
      /*
      {
        id: 8,
        name: "Totebags",
        productCategory: "Totebags", // à scrapper
      },
      */
      {
        id: 9,
        name: "Hats & Caps",
        productCategory: "Hats & Caps", // à scrapper
      },
      /*
      {
        id: 10,
        name: "Sunglasses",
        productCategory: "Sunglasses", // à scrapper
      },
      */
      {
        id: 11,
        name: "Belts",
        productCategory: "Belts", // à scrapper
      },
      {
        id: 12,
        name: "Gloves",
        productCategory: "Gloves", // à scrapper
      },
    ],
  },
  "Clothes & Shoes": {
    "MEN’S": {
      CLOTHING: [
        /*
        {
          id: 13,
          name: "Shirts",
          productCategory: "Men’s Shirts", // à scrapper
        },
        {
          id: 14,
          name: "Sweaters & Hoodies",
          productCategory: "Men’s Sweaters & Hoodies", // à scrapper
        },
        {
          id: 15,
          name: "Costumes",
          productCategory: "Men’s Costumes", // à scrapper
        },
        */
        {
          id: 16,
          name: "Pyjamas",
          productCategory: "Men’s Pyjamas",
        },
        {
          id: 17,
          name: "Jacket & Coats",
          productCategory: "Men’s jackets & coats",
        },
        {
          id: 18,
          name: "Pants",
          productCategory: "Men’s Pants",
        },
      ],
      SHOES: [
        {
          id: 19,
          name: "Boots",
          productCategory: "Men’s Boots",
        },
        {
          id: 20,
          name: "Slippers",
          productCategory: "Men’s Slippers",
        },
        {
          id: 21,
          name: "Sneakers",
          productCategory: "Men’s Sneakers",
        },
      ],
    },
    "WOMEN’S": {
      CLOTHING: [
        /*
        {
          id: 22,
          name: "Tops & Shirts",
          productCategory: "Women’s Tops & Shirts", // à scrapper
        },
        */
        {
          id: 23,
          name: "Dresses & Skirts",
          productCategory: "Women’s dresses & skirts",
        },
        /*
        {
          id: 24,
          name: "Sweaters & Hoodies",
          productCategory: "Women’s Sweaters & Hoodies", // à scrapper
        },
        */
        {
          id: 25,
          name: "Jacket & Coats",
          productCategory: "Women’s jackets & coats",
        },
        {
          id: 26,
          name: "Pants",
          productCategory: "Women’s pants",
        },
        {
          id: 27,
          name: "Pyjamas & Robes",
          productCategory: "Women’s pajamas",
        },
      ],
      SHOES: [
        {
          id: 28,
          name: "Boots",
          productCategory: "Women’s boots",
        },
        {
          id: 29,
          name: "Slippers",
          productCategory: "Women’s Slippers",
        },
        {
          id: 30,
          name: "Sneakers",
          productCategory: "Women’s sneakers",
        },
      ],
    },
    KIDS: {
      GIRLS: [
        /*
        {
          id: 31,
          name: "Tops & Shirts",
          productCategory: "Kids - Tops & Shirts", // à scrapper
        },
        */
        {
          id: 32,
          name: "Dresses & Skirts",
          productCategory: "Kids - Girls Dresses",
        },
        /*
        {
          id: 33,
          name: "Sweaters & Hoodies",
          productCategory: "Kids - Sweaters & Hoodies", // à scrapper
        },
        */
        {
          id: 34,
          name: "Jacket & Coats",
          productCategory: "Kids - Girls jackets & coats",
        },
        {
          id: 35,
          name: "Pants",
          productCategory: "Kids - Girls pants",
        },
        {
          id: 36,
          name: "Pyjamas & Robes",
          productCategory: "Kids - Girls Pyjamas",
        },
        {
          id: 37,
          name: "Boots",
          productCategory: "Kids - Girls boots",
        },
        {
          id: 38,
          name: "Slippers",
          productCategory: "Kids - Girls slippers",
        },
        {
          id: 39,
          name: "Sneakers",
          productCategory: "Kids - Girls sneakers",
        },
      ],
      BOYS: [
        /*
        {
          id: 40,
          name: "Shirts",
          productCategory: "Kids - Shirts", // à scrapper
        },
        */
        {
          id: 41,
          name: "Costumes",
          productCategory: "Kids - Boys' costumes",
        },
        /*
        {
          id: 42,
          name: "Sweaters & Hoodies",
          productCategory: "Kids - Boys’ Shirts", // à scrapper
        },
        */
        {
          id: 43,
          name: "Jacket & Coats",
          productCategory: "Kids - Boys’ jackets & coats",
        },
        {
          id: 44,
          name: "Pants",
          productCategory: "Kids - Boys’ pants",
        },
        {
          id: 45,
          name: "Pyjamas",
          productCategory: "Kids - Boys’ pyjamas",
        },
        {
          id: 46,
          name: "Boots",
          productCategory: "Kids - Boys’ boots",
        },
        {
          id: 47,
          name: "Slippers",
          productCategory: "Kids - Boys’ slippers",
        },
        {
          id: 48,
          name: "Sneakers",
          productCategory: "Kids - Boys’ sneakers",
        },
      ],
    },
    BABIES: {
      GIRLS: [
        {
          id: 49,
          name: "Clothing",
          productCategory: "Babies - Girls clothing",
        },
        {
          id: 50,
          name: "Shoes",
          productCategory: "Babies - Girls shoes",
        },
      ],
      BOYS: [
        {
          id: 51,
          name: "Clothing",
          productCategory: "Babies - Boys clothing",
        },
        {
          id: 52,
          name: "Shoes",
          productCategory: "Babies - Boys shoes",
        },
      ],
    },
  },
  "House & Decoration": {
    FURNITURE: [
      {
        id: 53,
        name: "Bedroom",
        productCategory: "Bedroom furniture",
      },
      {
        id: 54,
        name: "Dining room",
        productCategory: "Dining room furniture",
      },
      {
        id: 55,
        name: "Living room",
        productCategory: "Living room furniture",
      },
      {
        id: 56,
        name: "Kid’s furniture",
        productCategory: "Kids Furniture",
      },
      {
        id: 57,
        name: "Bathroom",
        productCategory: "Bathroom furniture",
      },
      {
        id: 58,
        name: "Office",
        productCategory: "Office furniture",
      },
      {
        id: 59,
        name: "Lighting",
        productCategory: "Lighting",
      },
      {
        id: 60,
        name: "Storage & Organization",
        productCategory: "Storage & Organization",
      },
    ],
    DECORATION: [
      {
        id: 61,
        name: "Wall decor",
        productCategory: "Wall decor",
      },
      {
        id: 62,
        name: "Clocks",
        productCategory: "Clocks",
      },
      {
        id: 63,
        name: "Vases",
        productCategory: "Vases",
      },
      {
        id: 64,
        name: "Candles",
        productCategory: "Candles",
      },
      {
        id: 65,
        name: "Sculpture",
        productCategory: "Sculptures",
      },
      {
        id: 66,
        name: "Glass art",
        productCategory: "Glass Art",
      },
    ],
    KITCHEN: [
      {
        id: 67,
        name: "Cookware",
        productCategory: "Cookware",
      },
      {
        id: 68,
        name: "Drinking",
        productCategory: "Drinkware",
      },
      {
        id: 69,
        name: "Serveware",
        productCategory: "Serveware",
      },
    ],
  },
  "Pet Supplies": {
    CATS: [
      {
        id: 70,
        name: "Furniture",
        productCategory: "Cat furniture",
      },
      {
        id: 72,
        name: "Collars & Leashes",
        productCategory: "Cat collars & leashes",
      },
      {
        id: 73,
        name: "Clothing",
        productCategory: "Cat clothing",
      },
      {
        id: 74,
        name: "Bedding",
        productCategory: "Cat Bedding",
      },
    ],
    DOGS: [
      {
        id: 70,
        name: "Furniture",
        productCategory: "Dog furniture",
      },
      {
        id: 72,
        name: "Collars & Leashes",
        productCategory: "Dog collars & leashes",
      },
      {
        id: 73,
        name: "Clothing",
        productCategory: "Dog clothing",
      },
      {
        id: 74,
        name: "Bedding",
        productCategory: "Dog Bedding",
      },
    ],
  },
  "Tools": {
    KITCHEN: [
      {
        id: 75,
        name: "Soap",
        productCategory: "Soap",
      },
      {
        id: 76,
        name: "Brush",
        productCategory: "Brush",
      },
      {
        id: 77,
        name: "Sponges",
        productCategory: "Sponges",
      },
      {
        id: 78,
        name: "Towels",
        productCategory: "Towels",
      },
      {
        id: 79,
        name: "Straw",
        productCategory: "Straw",
      },
      {
        id: 80,
        name: "Knife",
        productCategory: "Knife",
      },
    ],
    HYGIENE: [
      {
        id: 81,
        name: "Cotton rounds",
        productCategory: "Cotton rounds",
      },
      {
        id: 82,
        name: "Cotton swabs",
        productCategory: "Cotton swabs",
      },
      {
        id: 83,
        name: "Storage bags",
        productCategory: "Storage bags",
      },
    ],
    OUTDOORS: [
      {
        id: 84,
        name: "Bird feeder",
        productCategory: "Bird feeder",
      },
      {
        id: 85,
        name: "Axes",
        productCategory: "Axes",
      },
      {
        id: 86,
        name: "Shovels",
        productCategory: "Shovels",
      },
      {
        id: 87,
        name: "Compost",
        productCategory: "Compost",
      },
    ],
  },
};


function extractProductCategories(obj) {
  let productCategories = [];

  function traverseObject(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (item.productCategory) {
            productCategories.push(item.productCategory);
          }
        });
      } else if (typeof obj[key] === "object") {
        traverseObject(obj[key]);
      }
    }
  }

  traverseObject(obj);

  return productCategories;
}

const productCategoriesObj = exports.productCategories;
exports.productCategoriesArray = extractProductCategories(productCategoriesObj);