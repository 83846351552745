import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import { Input, message } from "antd";
import PhoneInput from "react-phone-input-2";

import { header } from "../../api/header";
import { url } from "../../api/url";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import { updateUser } from "../../services/updateUser";
import SelectCountryCode from "../../utils/CountryCodeSelector";

import "react-phone-input-2/lib/material.css";

const Onboarding2 = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isNext, setIsNext] = useState(false);

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const totalSteps = typeAccount === "creater" ? 5 : 3

  const nextCondition =
    user.company?.name &&
    user.company?.phone &&
    user.company?.address &&
    user.company?.postal_code &&
    user.company?.city &&
    user.company?.country_code;

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
  };

  const sendUpdateUser = () => {
    updateUser({user}, idAccount, secretId, token, () => setIsNext(true));
  };

  return (
    <div className="page">
      {isNext && (
        <Redirect to={`${typeAccount === "creater" ? "supplier" : "reseller"}-onboarding-step-3`} />
      )}
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "0px",
                }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingBottom: "200px",
                }
          }
          className="column-scrolling your-information"
        >
          <div
            style={{ paddingTop: "20px", paddingBottom: "20px", marginBottom: "50px", height: "80px" }}
            className="header"
          >
            <h1 className="title-bold montserrat">{t('onboarding.1')} (2/{totalSteps})</h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "40px",
              alignItems: "flex-start",
            }}
          >
            <div
              id="demo-step-5"
              className="column"
              style={
                windowSize > 1320
                  ? { width: "100%", marginLeft: "30px" }
                  : { width: "100%", marginTop: "30px", alignItems: "center" }
              }
            >
              {/* DETAILS */}
              <div
                id="content"
                style={{ marginLeft: "0", width: "90%", padding: "40px 25px 30px 25px", marginTop: "0px" }}
                className="column center card"
              >
                <div style={{ marginBottom: "25px", width: "100%" }} className="row-wrap">
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%", marginLeft: "2.5%" }
                        : { width: "100%", marginTop: "10px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t('legalNameOfCompany')}
                    </p>
                    <Input
                      maxLength={100}
                      value={user && user.company && user.company.name}
                      style={{ width: "100%" }}
                      placeholder={t('companyName')}
                      onChange={(e) => {
                        setUser({ ...user, company: { ...user.company, name: e.target.value } });
                      }}
                      className="border-red"
                    />
                  </div>
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "30%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('onboarding.8')}
                    </p>
                    <PhoneInput
                      style={!user.company?.phone ? { borderBottom: "2px solid red" } : {}}
                      className="border-red"
                      placeholder={t('onboarding.placeholders.1')}
                      value={user?.company?.phone}
                      onChange={(value) => {
                        setUser({ ...user, company: { ...user.company, phone: value } });
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{ width: "100%", marginLeft: "0", marginTop: "30px", marginBottom: "30px" }}
                  className="divider-horizontal"
                />

                {/* ADDRESS */}

                <h4
                  style={{ fontSize: "22px", color: "#606060", width: "95%", marginBottom: "20px" }}
                  className="text-bold start"
                >
                  {t('onboarding.9')}
                </h4>

                <div
                  style={{ marginBottom: "25px", width: "100%", justifyContent: "center" }}
                  className="row-wrap"
                >
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('onboarding.10')}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="border-red"
                      onChange={(e) => {
                        setUser({ ...user, company: { ...user.company, address: e.target.value } });
                      }}
                      placeholder={t('onboarding.placeholders.2')}
                      value={user.company?.address}
                    />
                  </div>
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('onboarding.11')}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="border-red"
                      onChange={(e) => {
                        setUser({ ...user, company: { ...user.company, postal_code: e.target.value } });
                      }}
                      placeholder={t('onboarding.placeholders.3')}
                      value={user && user.company && user.company.postal_code}
                    />
                  </div>
                </div>

                <div
                  style={{ marginBottom: "25px", width: "100%", justifyContent: "center" }}
                  className="row-wrap"
                >
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('onboarding.12')}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="border-red"
                      onChange={(e) => {
                        setUser({ ...user, company: { ...user.company, city: e.target.value } });
                      }}
                      placeholder={t('onboarding.placeholders.4')}
                      value={user && user.company && user.company.city}
                    />
                  </div>
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('onboarding.13')}
                    </p>
                    <SelectCountryCode
                      setValue={(value) => {
                        setUser({ ...user, company: { ...user.company, country_code: value } });
                      }}
                      value={user && user.company?.country_code}
                    />
                  </div>
                </div>

                <div style={{ marginTop: "35px", marginBottom: "20px" }} className="row">
                  <button
                    onClick={sendUpdateUser}
                    style={{ marginRight: "10px" }}
                    className="button big grey disable"
                  >
                    {t('onboarding.5')}
                  </button>
                  {nextCondition && (
                    <button
                      style={{ marginLeft: "10px" }}
                      onClick={sendUpdateUser}
                      className="button big orange-back"
                    >
                    {t('onboarding.6')}
                    </button>
                  )}
                </div>
                <Link
                  to={`/${typeAccount === "creater" ? "supplier" : "reseller"}-onboarding-step-1`}
                  className="text blue underline pointer"
                >
                {t('onboarding.7')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding2;
