export const resellerPlans = [
  {
    type: "free",
    maxProducts: 9,
    priceNegociationAnabled: false,
    feesPercentage: 5,
  },
  {
    type: "basic",
    maxProducts: 25,
    priceNegociationAnabled: false,
    feesPercentage: 5,
  },
  {
    type: "advanced",
    maxProducts: 250,
    priceNegociationAnabled: true,
    feesPercentage: 2,
  },
  {
    type: "premium",
    maxProducts: 10000000,
    priceNegociationAnabled: true,
    feesPercentage: 0.5,
  },
];
