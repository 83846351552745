import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'
import Load from '../utils/Load'

const ApiConnect = () => {
    const [redirectSetup, setRedirectSetup] = useState(false)

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        
        localStorage.setItem("tokenCreatosell", params.token);
        localStorage.setItem('typeAccountCreatosell', params.type)
        localStorage.setItem('emailAccountCreatosell', params.email)
        localStorage.setItem('idAccountCreatosell', params.id)
        localStorage.setItem('secretAccountId01supply', params.secret_id)
        localStorage.setItem('viewPlan01supply', 'false')
        sessionStorage.setItem('shopifyShop', params.shop)
        localStorage.setItem('admin', '')

        setTimeout(() => {
            setRedirectSetup(true)
            window.location.reload()
        }, 200)
    }, [])

    return(
        <div>
            {redirectSetup && <Redirect to="/your-informations"/> }
            <Load/>
        </div>
    )
}

export default ApiConnect