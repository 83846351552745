import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, Button, Tree } from "antd";

import { Modal } from "../../../wrappers";

const SelectShipping = ({
  setProduct,
  product,
  userShippings,
  countryList,
  user,
  getUser,
  setLoad,
  windowSize,
}) => {
  const [isSelectShipping, setIsSelectShipping] = useState(false);
  const [selectedShippingKeys, setSelectedShippingKeys] = useState([]);
  const [showShippingDestinations, setShowShippingDestinations] = useState({});
  const [defaultShippingKeyAreLoad, setDefaultShippingKeyAreLoad] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // SET DEFAULT SELECTED SHIPPING KEYS
    if (user?._id && product?._id && !selectedShippingKeys.length && !defaultShippingKeyAreLoad) {
      setDefaultShippingKeyAreLoad(true);
      const selections = user.shipping
        ?.map(
          (shipping, index) =>
            product.shipping?.some(
              (productShipping) => productShipping && productShipping.shipping_id === shipping._id
            ) && index
        )
        .filter((res) => res !== false);
      if (selections) {
        setSelectedShippingKeys(selections);
      }
    }
  }, [user, product]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: "Destinations",
      dataIndex: "destinations",
      render: (text) => (
        <p
          className="text blue underline pointer"
          onClick={() => setShowShippingDestinations(user.shipping[parseInt(text)])}
        >
          {t("view")}
        </p>
      ),
    },
    {
      title: t("price"),
      dataIndex: "price",
      render: (text) => <p>${text}</p>,
    },
    {
      title: t("deliveryTime"),
      dataIndex: "deliveryTime",
      render: (text) => <p>{text} days</p>,
    },
  ];

  const handleSelectShipping = (selectedRowKeys) => {
    setSelectedShippingKeys(selectedRowKeys);

    const shippingSelections = selectedRowKeys.map((shippingIndex) => userShippings[shippingIndex]);
    const newProductShippings = shippingSelections.map((shipping) => ({
      name: shipping.name,
      origin: user.shipping[parseInt(shipping.destinations)].origin,
      price: shipping.price,
      time: shipping.deliveryTime,
      shipping_id: shipping._id,
    }));

    setProduct({ ...product, shipping: newProductShippings });
  };

  const rowSelection = {
    selectedRowKeys: selectedShippingKeys,
    onChange: handleSelectShipping,
  };

  return (
    <>
      <p style={{ marginBottom: "3px", marginTop: "15px" }} className="text-bold">
        {t("components.supplier.createEditProduct.selectShipping.1")}
      </p>
      <p
        style={{ marginBottom: "10px" }}
        onClick={() => setIsSelectShipping(true)}
        className="text-bold blue underline pointer"
      >
        {t("edit")}
      </p>
      <div className="row-wrap">
        {product.shipping?.map(
          (shipping, index) =>
            shipping?.name && (
              <p className="text">
                {index > 0 && product.shipping.length > 1 && ", "}
                {shipping.name}
              </p>
            )
        )}
      </div>
      <Modal
        name="DELETE ORDER"
        visible={isSelectShipping}
        onCancel={() => setIsSelectShipping(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setIsSelectShipping(false)}>
            {t("cancel").toUpperCase()}
          </Button>,
        ]}
      >
        {showShippingDestinations._id && (
          <Modal
            title={`Availables destinations`}
            visible={true}
            onCancel={() => setShowShippingDestinations({})}
            footer={[]}
          >
            <p style={{ fontSize: "20px", marginBottom: "20px" }} className="title">
              {t("components.supplier.createEditProduct.selectShipping.2")} {showShippingDestinations.name}
            </p>
            <Tree
              defaultExpandedKeys={showShippingDestinations.origin.split(",")}
              defaultCheckedKeys={showShippingDestinations.origin.split(",")}
              checkedKeys={showShippingDestinations.origin.split(",")}
              treeData={countryList.filter(
                (country) =>
                  (country.children &&
                    country.children.some((child) =>
                      showShippingDestinations.origin.split(",").includes(child.key)
                    )) ||
                  (country.key && showShippingDestinations.origin.split(",").includes(country.key))
              )}
            />
          </Modal>
        )}
        {product && user?._id && (
          <div style={{ alignItems: "center", width: "100%" }} className="column">
            <Table
              scroll={{ x: 435 }}
              pagination={false}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={userShippings}
              width="100%"
              responsive
            />
          </div>
        )}
        {!user.shipping?.length ? (
          <>
            <div style={{ marginTop: "10px", marginBottom: "10px" }} className="row center">
              <button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setLoad(true);
                  getUser();
                }}
                className="button-orange"
              >
                {t("components.supplier.createEditProduct.selectShipping.3")}
              </button>
              <Link
                target="_blank"
                style={{ marginLeft: "10px" }}
                to="/manage-shipping-methods"
                className="button orange-back"
              >
                {t("components.supplier.createEditProduct.selectShipping.4")}
              </Link>
            </div>
            <p className="text-300 center grey">
              {t("components.supplier.createEditProduct.selectShipping.5")}
            </p>
          </>
        ) : (
          <div style={{ marginBottom: "10px", width: "100%", alignItems: "center" }} className="column">
            <button
              style={{
                marginRight: "10px",
                height: windowSize > 1150 && "38px",
                marginTop: "20px",
                width: "100%",
                fontSize: "18px",
              }}
              onClick={() => setIsSelectShipping(false)}
              className="button orange-back"
            >
              {t("components.supplier.createEditProduct.selectShipping.6")}
            </button>
            <div style={{ marginTop: "50px" }} className="row center">
              <button
                style={{ marginRight: "10px", fontSize: "13px" }}
                onClick={() => {
                  setLoad(true);
                  getUser();
                }}
                className="button-orange"
              >
                {t("components.supplier.createEditProduct.selectShipping.3")}
              </button>
              <Link
                target="_blank"
                style={{ marginLeft: "10px" }}
                to="/manage-shipping-methods"
                className="button-orange"
              >
                {t("components.supplier.createEditProduct.selectShipping.7")}
              </Link>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default SelectShipping;
