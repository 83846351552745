import React, { useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import {Modal} from '../../wrappers'
import { generateQrCode } from "../../utils/generateIbanQrCode";
import { orderServices } from "../../services/order";

const NorthMyth = ({ price, orderReference, setPassOrder, orders, order, setOrders, cost }) => {
  const [isModalConfirmDisplayed, setIsModalConfirmDisplayed] = useState(false);
  const { t } = useTranslation();

  const informations = {
    name: "Artem Hulenko",
    iban: "LT413250007630882437",
    amount: parseFloat(price),
    structuredReference: orderReference,
    information: orderReference,
  };

  const handlePlaceOrder = async () => {
    const resPayment = await orderServices.paymentConfirmation({
      orderId: order._id,
      sellerPaid: cost.total,
      shippingPaid: cost.shipping,
      productPaid: cost.products,
      bankTransferRef: orderReference
    });
    if (resPayment.status === 200) {
      const resJson = await resPayment.json();
      setPassOrder({});
      let newOrders = orders;
      let newOrder = order;
      orders.forEach((orderP, index) => {
        if (order._id === orderP._id) {
          newOrder.status = "payment confirmation";
          newOrder = {
            ...newOrder,
            paid: { ...order.paid, ...resJson.updatedPaid },
          };
          newOrders[index] = newOrder;
          setOrders([...newOrders]);
        }
      });
      message.success(t("messages.orderPlaced"));
    } else {
      message.error(t("messages.paymentError"));
    }
  };

  return (
    <div className="column">
      {isModalConfirmDisplayed && (
        <Modal
          style={{ padding: "30px" }}
          className="modal-account"
          visible={true}
          onCancel={() => {
            setIsModalConfirmDisplayed(false);
          }}
          footer={[]}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }} className="column width-100">
            <p className="title">{t("ibanPayment.5")}</p>
            <div style={{ marginTop: "20px" }} className="row">
              <button
                style={{ margin: "10px" }}
                onClick={() => setIsModalConfirmDisplayed(false)}
                className="button-red"
              >
                {t("no")}
              </button>
              <button style={{ margin: "10px" }} onClick={handlePlaceOrder} className="button orange-back">
                {t("yes")}
              </button>
            </div>
          </div>
        </Modal>
      )}
      <p className="title-bold montserrat grey">{t("ibanPayment.1")}</p>
      <p className="title grey start">{t("ibanPayment.2", { country: "Ukrainian" })}</p>
      <div style={{ padding: "20px", background: "#F6F6F6", marginTop: "20px" }} className="row-wrap">
        <div style={{ margin: "10px" }} className="column">
          <p className="title grey start">{t("ibanPayment.3")}</p>

          <p
            style={{ fontSIze: "20px", marginTop: "20px", marginBottom: "10px" }}
            className="text-bold montserrat grey"
          >
            IBAN : <span style={{ fontWeight: "400" }}>LT41 3250 0076 3088 2437</span>
          </p>
          <p style={{ fontSIze: "20px" }} className="text-bold montserrat grey">
            {t("name").toUpperCase()} : <span style={{ fontWeight: "400" }}>{informations.name}</span>
          </p>
          <p style={{ fontSIze: "20px" }} className="text-bold montserrat grey">
            BIC : <span style={{ fontWeight: "400" }}>Revolt21</span>
          </p>
          <p style={{ fontSIze: "20px" }} className="text-bold montserrat grey">
            REFERENCE : <span style={{ fontWeight: "400" }}>{orderReference}</span>
          </p>
        </div>
        <QRCode
          size={250}
          style={{ height: "auto", maxWidth: "100%", width: "250px", margin: "10px" }}
          value={generateQrCode(informations)}
          viewBox={`0 0 250 250`}
        />
      </div>
      <button
        onClick={() => setIsModalConfirmDisplayed(true)}
        style={{ marginTop: "15px" }}
        className="button big orange-back width-100"
      >
        {t("ibanPayment.button")}
      </button>
    </div>
  );
};

export default NorthMyth;
