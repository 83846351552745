import React from "react";
import SHA256 from "crypto-js/sha256";

export const subscriptionEvent = ({ value, subscriptionName, user }) => {
  // TikTok Ads
  window.ttq.identify({
    external_id: user._id ? SHA256(user._id).toString() : "",
    email: user.email ? SHA256(user.email).toString() : "",
    phone_number: user?.company?.phone ? SHA256(user?.company?.phone).toString() : "",
  });
  window.ttq.track("Subscribe", {
    value: value,
    currency: "USD",
    content_id: subscriptionName,
    status: "complete",
    external_id: user._id ? SHA256(user._id).toString() : "",
    email: user.email ? SHA256(user.email).toString() : "",
    phone_number: user?.company?.phone ? SHA256(user?.company?.phone).toString() : "",
  });

  // Google Analytics
  window.gtag("event", "Subscribe_shopify", {
    send_to: "G-L3M8KM38ME",
    value: value,
    price: value ? parseFloat(value).toFixed(2) : undefined,
    quantity: 1,
    currency: "USD",
    item_id: subscriptionName,
    user_id: user._id,
  });

  // Facebook Ads
  window.fbq("track", "Subscribe", {
    value: value,
    currency: "USD",
    content_name: subscriptionName,
    content_id: user._id,
  });
};

export const installShopifyEvent = ({ user }) => {
  // TikTok Ads
  window.ttq.identify({
    external_id: user._id ? SHA256(user._id).toString() : "",
    email: user.email ? SHA256(user.email).toString() : "",
  });

  window.ttq.track("Download", {
    content_type: "app",
    content_id: "shopifyApp",
    status: "complete",
    external_id: user._id ? SHA256(user._id).toString() : "",
    email: user.email ? SHA256(user.email).toString() : "",
  });

  // Google Analytics
  window.gtag("event", "shopify_app_installation", {
    send_to: "G-L3M8KM38ME",
    user_id: user._id,
    event_category: "engagement",
    event_action: "install",
    event_label: "Shopify App",
  });

  // Facebook Ads
  window.fbq("track", "Lead", {
    content_name: "Shopify App Installation",
    content_id: user._id,
  });
};

export const registrationEvent = ({ user }) => {
  // TikTok Ads
  window.ttq.identify({
    external_id: user._id ? SHA256(user._id).toString() : "",
    email: user.email ? SHA256(user.email).toString() : "",
  });
  window.ttq.track("CompleteRegistration", {
    content_type: "registration",
    content_id: "registration",
    status: "complete",
    external_id: user._id ? SHA256(user._id).toString() : "",
    email: user.email ? SHA256(user.email).toString() : "",
  });

  // Google Analytics
  window.gtag("event", "CompleteRegistration", {
    send_to: "G-L3M8KM38ME",
    user_id: user._id,
    email: user.email,
  });

  // Facebook Ads
  window.fbq("track", "CompleteRegistration", {
    content_name: "registration",
    content_id: user._id,
  });
};
