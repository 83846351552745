import React, { useState, useEffect } from "react";
import { Input, Tabs } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { updateUser } from "../../../services/updateUser";

const { TabPane } = Tabs;

const SearchProducts = ({
  getProduct,
  setLoad,
  inputSearch,
  setInputSearch,
  user,
  selectedSearchTerm,
  setSelectedSearchTerm,
  setUser,
  lastInputSearch,
  setLastInputSearch,
}) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (selectedSearchTerm?._id) {
      setActiveKey(
        user?.suggestions?.searchTerms?.findIndex((searchTerm) => searchTerm._id === selectedSearchTerm._id)
      );
    }
  }, [selectedSearchTerm]);

  const handleValid = () => {
    setSelectedSearchTerm(undefined);
    getProduct({ inputFind: inputSearch });
    setLoad(true);
    setActiveKey(null);
  };

  const handleClickSearchTerm = (selectedTermIndex) => {
    setActiveKey(selectedTermIndex);
    const searchTerm = user?.suggestions?.searchTerms[selectedTermIndex];
    setLoad(true);
    const isSameSearchTerm = selectedSearchTerm && selectedSearchTerm.searchTerm === searchTerm.searchTerm;
    if (isSameSearchTerm) {
      setSelectedSearchTerm(undefined);
      getProduct({});
      setInputSearch("");
    } else {
      setSelectedSearchTerm(searchTerm);
      getProduct({ searchTerm });
    }
  };

  const handleRemoveSearchTerm = ({ searchTerm, e }) => {
    e.stopPropagation();
    e.preventDefault();
    let newUserSuggest = user.suggestions;
    newUserSuggest.searchTerms = newUserSuggest.searchTerms.filter(
      (userSearchTerm) => userSearchTerm._id !== searchTerm._id
    );
    setSelectedSearchTerm(undefined);
    setUser({ ...user, suggestions: newUserSuggest });
    updateUser({
      user: { suggestions: newUserSuggest },
    });
  };

  return (
    <div id="search-products" className="column width-100">
      <Input
        value={inputSearch}
        maxLength={100}
        className="input marketplace"
        id="search-product-input-header"
        placeholder={t("marketplace.suggestion.6")}
        suffix={
          <button onClick={handleValid} className="button orange-back">
            <SearchOutlined style={{ fontSize: "20px", color: "white", marginRight: "10px" }} />{" "}
            {t("search").toUpperCase()}
          </button>
        }
        onChange={(e) => setInputSearch(e.target.value)}
        onPressEnter={handleValid}
      />
      <h2 className="title-bold center">
        {t("marketplace.suggestion.4")}
        <br />
        <span style={{ fontWeight: "400", fontSize: "15px" }} className="title-400">
          {t("marketplace.suggestion.5")}
        </span>
      </h2>
      {user?.suggestions?.searchTerms?.length > 0 && (
        <Tabs
          className="width-100"
          onChange={handleClickSearchTerm}
          type="card"
          activeKey={activeKey?.toString() || null}
        >
          {user?.suggestions?.searchTerms?.map((searchTerm, index) => {
            const isSelectedTerm =
              selectedSearchTerm && selectedSearchTerm.searchTerm === searchTerm.searchTerm;
            if (searchTerm.searchTerm) {
              return (
                <TabPane
                  tab={
                    <div className="row">
                      {searchTerm.searchTerm}
                      {!isSelectedTerm && (
                        <CloseOutlined
                          onClick={(e) => handleRemoveSearchTerm({ searchTerm, e })}
                          style={{ fontSize: "13px", marginLeft: "16px" }}
                        />
                      )}
                    </div>
                  }
                  key={index?.toString()}
                />
              );
            } else return <></>;
          })}
        </Tabs>
      )}
    </div>
  );
};

export default SearchProducts;
