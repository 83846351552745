import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import Contact from "../../../components/ContactForm";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";

const Documentation = () => {
  const [contact, setContact] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <div className="page">
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={windowSize > 1150 ? { paddingTop: "0px" } : { paddingTop: "30px", paddingBottom: '150px' }}
          className="column-scrolling documentation"
        >
          <div className="row-wrap" style={{ width: "100%", paddingTop: "110px", alignItems: "flex-start" }}>
            {contact && <Contact setContact={setContact} />}
            {windowSize > 1150 ? (
              <>
                <MenuSetting user={user} />
                <div className="header">
                  <div
                    className="row"
                    style={
                      windowSize > 1150
                        ? { width: "100%", marginBottom: "-30px", height: "30px" }
                        : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                    }
                  >
                    <h1 className="title-bold montserrat">DOCUMENTATION</h1>
                  </div>
                  <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
                </div>
              </>
            ) : (
              <MobileHeader nameHeader={"DOCUMENTATION"} location="DOCUMENTATION" />
            )}
            <div
              id="content"
              style={
                windowSize > 1150
                  ? { width: "calc(100% - 350px)", marginLeft: "30px", padding: "15px 20px 30px 20px", marginTop: '0' }
                  : { width: "100%", marginTop: "30px", padding: "15px 20px 30px 20px" }
              }
              className="column card"
            >
              <h4 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                {t('supplierDocumentation.1')}
              </h4>
              <ul>
                <a href="https://www.01supply.com/help/what-is-01supply-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.2')}</li>
                </a>
                <a href="https://www.01supply.com/help/marketplace-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.3')}</li>
                </a>
                <a href="https://www.01supply.com/help/your-account-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.4')}</li>
                </a>
                <a href="https://www.01supply.com/help/shipping-methods-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.5')}</li>
                </a>
                <a href="https://www.01supply.com/help/getting-paid-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.6')}</li>
                </a>
              </ul>

              <h4 style={{ fontSize: "22px", color: "#606060", marginTop: "10px" }} className="text-bold">
              {t('supplierDocumentation.7')}
              </h4>
              <ul>
                <a href="https://www.01supply.com/help/how-to-sell-on-01supply">
                  <li className="text-bold blue">{t('supplierDocumentation.8')}</li>
                </a>
                <a href="https://www.01supply.com/help/how-to-create-a-product">
                  <li className="text-bold blue">{t('supplierDocumentation.9')}</li>
                </a>
                <a href="https://www.01supply.com/help/how-to-import-a-csv-file-to-01supply-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.10')}</li>
                </a>
                <a href="https://www.01supply.com/help/transaction-fees-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.11')}s</li>
                </a>
                <a href="https://www.01supply.com/help/how-to-fulfill-an-order-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.12')}</li>
                </a>
              </ul>

              <h4 style={{ fontSize: "22px", color: "#606060", marginTop: "10px" }} className="text-bold">
                Dropshipping
              </h4>
              <ul>
                <a href="https://www.01supply.com/help/what-is-dropshipping-supplier">
                  <li className="text-bold blue">{t('supplierDocumentation.13')}</li>
                </a>
                <a href="https://www.01supply.com/help/dropshipping-resellers">
                  <li className="text-bold blue">{t('supplierDocumentation.14')}</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
