import React, { useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next'
import {url} from '../../../api/url'
import {header} from '../../../api/header'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import loadGif from '../../../images/load.gif'
import {Modal, message} from 'antd'

const CheckoutForm = ({data, setGoPayment, setPassOrder, setOrders, orders, stripeAccountId, user,
    windowSize,
    load,
    setLoad
}) => {
    const [error, setError] = useState('')
    const [token] = useState(localStorage.getItem('tokenCreatosell'))

    const { t } = useTranslation()

    const stripe = useStripe();
    const elements = useElements();

    const CARD_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
        style: {
          base: {
            iconColor: "rgb(63, 63, 63)",
            width: "100%",
            color: "rgb(63, 63, 63)",
            fontWeight: 600,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "rgb(63, 63, 63)" },
            "::placeholder": { color: "rgb(63, 63, 63)" },
          },
          invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee",
          },
        },
    };

    async function createPayment() {
        fetch(`${url}/order/paid`, {
            method: "PUT",
            credentials: "include",
            headers: {
              ...header,
              authorization: token,
            },
            body: JSON.stringify({
              orderId : data.orderId,
              shipping : data.shipping,
              order : data.order,
              message: data.message,
              product : data.product,
              local_variant: data.local_variant,
              variant : data.variant,
              isVariant: data.isVariant
            }),
          })
            .then((response) => {
                return response.json();
            })
            // If the card is declined, display an error to the user.
            .then(async (result) => {
                if (result.error || !result.orderUpdate) {
                    setLoad(false)
                    // The card had an error when trying to attach it to a customer.
                    if(result.status && result.status == "error payment stripe"){
                        message.error(t('messages.errorSupplieStripe'), 10)
                        setError(t('messages.errorSupplieStripe'))
                    } else {
                        setError(t('messages.error'))
                        message.error(t('messages.paymentError'))
                    }
                    throw result;
                }
                const clientSecret = result.client_secret;
                paymenthod(clientSecret, result.orderUpdate, result.newStock, result.orderId, result.productId, result.newStockVariant, result.variantBuy, result.createrEmail, result.order, result.chargeUrl, result.paymentIntentId)
            })
    }

    const paymenthod = async (clientSecret, orderUpdate, newStock, orderId, productId, newStockVariant, variantBuy, createrEmail, order, chargeUrl, paymentIntentId) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.

        let cardConfig = elements.getElement(CardElement)
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardConfig,
            }
        });
        if (result.error) {
            setError(t('messages.error'))
            message.error(t('messages.error'))
            setLoad(false)
        } else if (result) {
            let newOrder = orderUpdate
            if(!newOrder.seller || (newOrder.seller &&
            (!newOrder.seller.id || !newOrder.seller.name || !newOrder.seller.address || !newOrder.seller.company_name || !newOrder.seller.company_email || !newOrder.seller.company_phone))){
                newOrder.seller = {
                    id: user._id,
                    name: `${user.firstName} ${user.lastName}`,
                    vat: user.company.vat,
                    address: user.company.address,
                    company_name: user.company.name,
                    company_email: user.company.email ? user.company.email : user.email,
                    company_phone: user.company.phone
                }
            }
            const respaid = await fetch(`${url}/order/paid/valide`, {
                method: "PUT",
                credentials: "include",
                headers: {
                  ...header,
                  authorization: token,
                },
                body: JSON.stringify({
                  orderUpdate: newOrder,
                  newStock,
                  orderId,
                  productId,
                  newStockVariant,
                  variantBuy,
                  createrEmail,
                  order,
                  chargeUrl,
                  paymentIntent: paymentIntentId,
                  stripeAccountId
                }),
              })
              if(respaid.status === 200){
                setPassOrder({})
                let newOrders = orders
                let newOrder = order
                orders.map((orderP, index) => {
                    if(order._id === orderP._id){
                        newOrder.status = "paid"
                        newOrder = {...newOrder, paid: orderUpdate.paid}
                        newOrders[index] = newOrder
                        setOrders([...newOrders])
                    }
                })
                message.success(t('messages.orderPlaced'))

              }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setError('')
        }, 15000)
    }, [error])


    return (
        <div className={windowSize > 1150 ? "column" : "row"} style={windowSize > 1150 ? {alignItems: 'center'} : {flexDirection: 'column', alignItems: 'center'}}>
            {error && <p style={{fontSize: '15px', color: 'red', maxWidth: '90%', marginBottom: '30px'}} className="errorPay">{error}</p>}

            {load && <img style={{ width: "50%" }} src={loadGif} />}
            <div className="input-pricing-course-payment">
                <CardElement options={CARD_OPTIONS} />
            </div>
            {!load &&
            <button style={{width: '180px', height: '45px', fontSize: '20px', marginTop: '20px'}} className="button orange-back" onClick={() => {
                if(!load){
                    setLoad(true)
                    createPayment()
                }
            }} type="submit">
                {t('ordering').toUpperCase()}
            </button>}
        </div>
    )
}

export default CheckoutForm