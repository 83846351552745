import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article17/How-to-light-for-product-photography.jpg";
import backlight from "../../../../images/blog/global/article17/backlight.jpg";
import broadLight from "../../../../images/blog/global/article17/broad-light.jpg";
import butterFly from "../../../../images/blog/global/article17/butterfly-light.jpg";
import continuousStrobe from "../../../../images/blog/global/article17/continuous-light-or-strobe-light.jpg";
import flatLight from "../../../../images/blog/global/article17/flat-light.jpg";
import homeLighting from "../../../../images/blog/global/article17/home-setup-photography-light.jpg";
import lightboxProduct from "../../../../images/blog/global/article17/lightbox-for-product-photography.jpg";
import loopLight from "../../../../images/blog/global/article17/loop-light.jpg";
import proSetup from "../../../../images/blog/global/article17/pro-setup-lighting.jpg";
import removeShadow from "../../../../images/blog/global/article17/remove-shadow-on-backdrop-photography.jpg";
import rimLight from "../../../../images/blog/global/article17/rim-light.jpg";
import ringLight from "../../../../images/blog/global/article17/ringlight-good-or-bad.jpg";
import shortLight from "../../../../images/blog/global/article17/short-light.jpg";
import splitLight from "../../../../images/blog/global/article17/split-light.jpg";



const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        How to light for product photography?
      </h1>
      <p className="text-300">05 october 2022 - 10 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="How to light for product photography?"/>
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Lighting your scene is very important when it comes to <b>product photography</b>. Good lighting can <b>enhance the image quality</b> and make your product stand out.
      </p>
      <p style={{ marginBottom: "20px" }}>
        If you are starting with product photography, you may be asking, <b>what lights you should choose</b>.
        Maybe you don’t know how to place them and what light settings you should configure.
      </p>
      <p style={{ marginBottom: "20px" }}>
        In this article, we will review the top techniques, tips, and rules you need to know to correctly light your product.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#types-lighting-photography" title="8 types of lighting in product photography" />
            <Anchor.Link href="#lighting-product-photography" title="What lighting should I use for product photography?" >
              <Anchor.Link href="#difference-continuous-strobe" title="Differences between Continuous and Strobe Lights" />
              <Anchor.Link href="#led-product-photography" title="Can I use LED lights for product photography?" />
              <Anchor.Link href="#lumens-product-photography" title="How many lumens do I need for product photography?" />
              <Anchor.Link href="#ring-light-product-photography" title="Is a ring light good for product photography?" />
            </Anchor.Link>
            <Anchor.Link href="#setup-lighting" title="How do you set up lighting for product photography?" />
            <Anchor.Link href="#techniques-lighting-photography" title="What Are The Best Lighting Techniques In Product Photography?" />
            <Anchor.Link href="#use-natural-light" title="How do you photograph products with natural light?" />
            <Anchor.Link href="#fake-natural-light" title="How do you make fake light look natural?" />
            <Anchor.Link href="#get-rid-shadows" title="How do you get rid of shadows in product photography?" />
            <Anchor.Link href="#softbox-tutorial-photography" title="How do you use softbox lighting for product photography?" />
            <Anchor.Link href="#lightbox-product-lighting" title="Are light boxes suitable for product photography?" />
            <Anchor.Link href="#improve-lighting-course" title="How can I improve my lighting skills?" />
          </Anchor>
        }
      />
      <h2 id="types-lighting-photography">8 types of lighting in product photography
      </h2>
      <h3>Flat light
      </h3>
      <img src={flatLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Flat Light"/>
      <p style={{ marginBottom: "20px" }}>As the name suggests, flat light will flatten your image. With flat lighting, you place your light <b>in front of the subject</b>.
        This allows you to remove any shadows. As shadows create the 3d effect, that is why flat light flattens your image.
      </p>
      <h3>Broad light
      </h3>
      <img src={broadLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Broad Light"/>
      <p style={{ marginBottom: "20px" }}>In portrait photography, we talk about broad light when the side of the face of the subject <b>closest to the camera
        is lit more brightly.</b>
      </p>
      <h3>Short light
      </h3>
      <img src={shortLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Short Light"/>
      <p style={{ marginBottom: "20px" }}>Short light is the opposite of broad light.
        Short light means that the side of the subject’s face <b>opposite to the camera is lit more brightly</b>.
      </p>
      <h3>Split light
      </h3>
      <img src={splitLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Split Light"/>
      <p style={{ marginBottom: "20px" }}>Split light is a photography technique that <b>only lights one side of the subject</b>.
        This results in placing only one light on one side.
      </p>
      <h3>Backlight</h3>
      <img src={backlight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Backlight"/>
      <p style={{ marginBottom: "20px" }}>As the name suggests, the backlight is a light that is placed on the back of the subject.
        Note that the light is often from an angle so we can’t see it in the picture.
      </p>
      <p style={{ marginBottom: "20px" }}>Backlighting helps <b>detour the subject to make him stand out</b>. This is often used as additional lighting.
      </p>
      <h3>Rim light
      </h3>
      <img src={rimLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Rim light"/>
      <p style={{ marginBottom: "20px" }}>The rim light is a sort of backlighting. In that case, we will only place one light just behind the subject.
        This will <b>create a mysterious/halo look</b> so only the contours of the subject are lit.
      </p>
      <h3>Butterfly light
      </h3>
      <img src={butterFly} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Butterfly light"/>
      <p style={{ marginBottom: "20px" }}>The butterfly lighting consists in placing the light above and in front of your subject to create some shadows on his face.
        This allows you to <b>highlight certain parts and shapes</b>.
      </p>
      <h3>Loop light
      </h3>
      <img src={loopLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Loop light"/>
      <p style={{ marginBottom: "20px" }}>You can achieve loop light by placing the light 45 degrees to the side of the subject, just above eye level.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Loop light is a bit less flattering than flat light. It still <b>creates some shadow but looks less dramatic</b>.
      </p>
      <h2 id="lighting-product-photography">What lighting should I use for product photography?
      </h2>
      <p style={{ marginBottom: "20px" }}>You can use every type of lighting for product photography,
        but it is recommended to go for <b>continuous lighting</b> as it will help you visualize clearly the end result.
      </p>
      <h3 id="difference-continuous-strobe">Differences between Continuous and Strobe Lights
      </h3>
      <img src={continuousStrobe} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Differences between continuous and strobe lights"/>
      <p style={{ marginBottom: "20px" }}>Continuous lighting is lights that are constantly on. This gives you more control as you can move the lights to get the best end result.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Some continuous lights example</u>:
      </p>
      <ul>
        <li>
          LED panels
        </li>
        <li>
          Fluorescent tubes
        </li>
        <li>
          Plasma lights
        </li>
        <li>
          Tungsten lights
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        <b>Strobe lights</b> are more commonly known as flashes. They emit way more light than continuous lights allowing you to get better results in certain conditions.
        This light only triggers once you pull the shutter button.
      </p>
      <p style={{ marginBottom: "20px" }}>They are not very beginner friendly as you can’t visualize what the shot will look like.
      </p>
      <h3 id="led-product-photography">Can I use LED lights for product photography?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        You can use LED lights for product photography. It is the most commonly used light for beginners. They offer a lot of control, flexibility, and can be colorful.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Note that LED lights are not recommended for moving shots or animated shots.
        For example, LEDs are not the best choice if you want to capture a splash in the water.
      </p>
      <p style={{ marginBottom: "20px" }}>
        It is also good to know that LED lights are often criticized because they do not offer the same light quality as tungsten lights for example.
      </p>
      <h3 id="lumens-product-photography">How many lumens do I need for product photography?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        The lumen intensity should be <b>over 1.000 lumens to mimic the natural light</b> as much as possible.
      </p>
      <p style={{ marginBottom: "20px" }}>Note that lumens are not the only metric for product photography. Depending on the picture you want to take you can adjust the settings accordingly.
        In the end, all that matters is what you capture on camera.
      </p>
      <h3 id="ring-light-product-photography">Is a ring light good for product photography?
      </h3>
      <img src={ringLight} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Ringlight good for photography?"/>
      <p style={{ marginBottom: "20px" }}><b>Ring lights emit a lot of light</b> which can be great in some cases. But always remember that you shouldn’t have one single light source in product photography.
      </p>
      <p style={{ marginBottom: "20px" }}><u>Pro of ring lights</u>: </p>
      <ul>
        <li>
          Very bright
        </li>
        <li>
          Easy to setup and use
        </li>
        <li>
          Transportable
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        <u>Cons of ring lights</u>:
      </p>
      <ul>
        <li>
          The light may look too harsh
        </li>
        <li>
          Don’t use it for reflective objects
        </li>
      </ul>
      <h2 id="setup-lighting">How do you set up lighting for product photography?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        If you have an e-commerce store and you want to <b>photograph your product at home</b>, you need to start with a good photography setup.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Depending on your budget, the setup might be different. Here are 3 options you can choose from:
      </p>
      <h3>On budget setup
      </h3>
      <img src={homeLighting} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="on budget light setup"/>
      <p style={{ marginBottom: "20px" }}>First of all, remember that the best light is the one out of the sun. <b>Sunlight is bright and diffuse</b>.
        The problem is that you don’t have control over it. Light intensity is changing and this can be frustrating.
      </p>
      <p style={{ marginBottom: "20px" }}>Also, note that you should always <b>avoid direct sunlight</b>.
        If the sunbeams are directly hitting your product, you must <b>place a diffuser in between</b> (a large white sheet that lets the light shine through).
      </p>
      <p style={{ marginBottom: "20px" }}>Now that you have your light source, you must make sure the subject is lighted from both sides.
        Therefore, you can <b>place a reflector on the other side</b>. A reflector is a white plank/board that will reflect the light coming from the sun.
      </p>
      <p style={{ marginBottom: "20px" }}>Now that you have your main lighting, you can also <b>add a backlight</b> with a lamp from your home.
        This lamp can be colored to add some branding to your product image.
      </p>
      <p style={{ marginBottom: "20px" }}>If you have a bit more budget, we recommend you invest in a photography box as it will give you a nice environment
        to photograph your products.
      </p>
      <h3>Amateur setup
      </h3>
      <img src={proSetup} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Pro lighting setup"/>
      <p style={{ marginBottom: "20px" }}>
        For the amateur setup, you will need two lights placed on both sides. We recommend you choose two diffusers.
        The fewer shadows you want, the larger the lights must be.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Now that you have two lights, you can place an optional colored backlight from the background angled at 45 degrees so you don’t see it on your shot.
      </p>
      <h3>Professional setup
      </h3>
      <p style={{ marginBottom: "20px" }}>The professional setup is the same as the amateur setup, but you can add a large upper light separated by a large diffuser.
        This will help reduce the unwanted shadows. Note that the diffuser should be as close as possible to the product.
      </p>
      <p style={{ marginBottom: "20px" }}>Of course, depending on each setup, you can choose better lights, better diffusers, better reflectors, etc…
      </p>
      <h3>Is it better to light from above or below?
      </h3>
      <p style={{ marginBottom: "20px" }}>Lighting from above always looks more natural as we are used to it.
        Lighting from the bottom often looks terrific and unusual.
        For product photography, we would always recommend you light from the sides, top, and back.
      </p>
      <h2 id="techniques-lighting-photography">What Are The Best Lighting Techniques In Product Photography?
      </h2>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Use natural lighting</b> whenever you can.
        </li>
        <li>
          <b>Make use of reflectors</b> to bounce lights and create a range of light intensity. This also helps reduce shadows.
        </li>
        <li>
          You can play with <b>different light setups</b>, especially in the dark.
        </li>
        <li>
          Regarding the two-sided lights, <b>make sure one has a lower light intensity</b>. The main light is called “Key light”, the second one is called “Fill light”.
        </li>
        <li>
          The size of <b>the light is what determines the softness</b>. The larger the light, the softer the image. The smaller the light, the more harsh the shadows are.
        </li>
      </ul>
      <h2 id="use-natural-light">How do you photograph products with natural light?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        When using natural light, you should always have a reflector with you.
        This reflector will be placed on the opposite side as the natural light source to reduce unwanted shadows.
      </p>
      <p style={{ marginBottom: "20px" }}>
        In most cases, you should always use a diffusing material placed in between the light source and the product.
        Always avoid direct sunlight as it doesn’t look professional.
        Remember that the best light placement is the one where we don’t notice where the light is coming from.
      </p>
      <h2 id="fake-natural-light">How do you make fake light look natural?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        The main issue with <b>natural light is how changing it is</b>. That is why a lot of photography studios are looking to replicate natural light to control it.
      </p>
      <p style={{ marginBottom: "20px" }}>To mimic natural light you will need to have a very large diffuser (at least 2x2m) with a very bright light source behind it.
        In that case, we would recommend using a strobe and not a continuous light.
      </p>
      <p style={{ marginBottom: "20px" }}> If you can, make sure the light source hits a reflector before hitting the diffuser.
      </p>
      <h2 id="get-rid-shadows">How do you get rid of shadows in product photography?
      </h2>
      <img src={removeShadow} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Remove shadows backdrop photography"/>
      <p style={{ marginBottom: "20px" }}>First of all, to avoid bottom shadows, we recommend you <b>use an acrylic surface to place your product on</b>.
        This will reflect more light and should reduce the shadows a lot.
      </p>
      <p style={{ marginBottom: "20px" }}>If you still have shadows, you should <b>make sure your reflector is white enough</b> to bounce enough light.
      </p>
      <p style={{ marginBottom: "20px" }}>If the shadows persist, <b>bring the light source closer</b>, and make sure they are large enough.
        The larger the light source, the fewer shadows you will have.
      </p>
      <h3>How do you prevent shadows on backdrops?
      </h3>
      <p style={{ marginBottom: "20px" }}>To prevent shadows on the backdrop, you must <b>place it far away from the product</b>.
        This means that it should at least be placed 2 to 3m away.
      </p>
      <p style={{ marginBottom: "20px" }}>If you have a small setup with bent white paper as a background,
        make sure to have a <b>large light from above</b> so you don’t get shadows on the background.
      </p>
      <h2 id="softbox-tutorial-photography">How do you use softbox lighting for product photography?
      </h2>
      <p style={{ marginBottom: "20px" }}>Softboxes are great for photography as they are easy to set up and use.
        You can also easily <b>configure the light intensity and kelvin</b> to create different moods.
      </p>
      <p style={{ marginBottom: "20px" }}>Place them <b>on each side of the camera pointing at 45 degrees</b> to the product.
        Raise them just above the middle of the product, pointing a bit down.
        The good part here is that you can adjust this very easily to get the desired result.
      </p>
      <h2 id="lightbox-product-lighting">Are light boxes suitable for product photography?
      </h2>
      <img src={lightboxProduct} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Lightbox for product photography"/>
      <p style={{ marginBottom: "20px" }}>Lightboxes are perfect for product photography as it gives you a <b>uniform white background</b>.
        It is also very helpful for shiny products as <b>all it will reflect is white</b>.
        This is a great option as it helps you keep <b>consistent shots</b> for your e-commerce store.
      </p>
      <p style={{ marginBottom: "20px" }}>Thanks to the translucent sides, you can make the light shine through and <b>you don’t even need a diffuser</b> as the box does it for you.
      </p>
      <p style={{ marginBottom: "20px" }}>Lightboxes are also really <b>easy to transport</b>. This is especially helpful if you plan to shoot your products in multiple locations or abroad.
      </p>
      <p style={{ marginBottom: "20px" }}>There are still some disadvantages to lightboxes. Indeed, the <b>space is limited</b> and you can’t shoot large products.
        This is mostly great for small objects like jewels, handcrafted goods, shoes, etc…
      </p>
      <h3>How do you use a lightbox for product photography?
      </h3>
      <p style={{ marginBottom: "20px" }}>Place your light box on a table. If you can <b>use natural light from the sun</b> as it has a perfect luminosity and intensity.
        The walls of the lightbox will help diffuse the light and make the scene evenly bright.
      </p>
      <p style={{ marginBottom: "20px" }}>You should place a reflector on the other side of the box to have lighting from both sides.
        If the reflector doesn’t output enough light, you can add a secondary light.
        Make sure that this light has the same kelvin level (around 7.000k) as the sun (not a colored light source as it would look weird).
      </p>
      <h2 id="improve-lighting-course">How can I improve my lighting skills?
      </h2>
      <p style={{ marginBottom: "20px" }}>Lighting is the most important part of photography. Depending on how you light your scene, your subject might look completely different.
        This is <b>especially true for product photography</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>Indeed, the bad lighting for product photography makes your product less attractive and less likely to get sales.
        Additionally, it <b>is important to take good photos from the beginning</b> as it is a hassle to replace product images later.
      </p>
      <p style={{ marginBottom: "20px" }}>With the right knowledge and global photography skills, you can take awesome product photos.
        And you can learn this within one day.
        If you have good lighting for your scene, <b>you can also shoot with a smartphone</b> and it would look as good as a professional shooting.
      </p>
      <p style={{ marginBottom: "20px" }}>01supply Academy helps small creators and businesses take awesome product pictures based on our experience with thousands of business owners.
      </p>
      <p style={{ marginBottom: "20px" }}><b>This is why we created a full photography course. 
        You can learn more about it here: <a className="blue" href="https://app.01supply.com/academy/photo-mastery-course" target="blank_" alt="Photography course 01supply"><u>Photo Mastery</u></a></b>
      </p>



    </div>
  );
};

export default Content;
