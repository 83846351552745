import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Button, Input } from "antd";
import {Modal} from '../wrappers'
import { url } from "../api/url";
import { header } from "../api/header";

const Contact = ({ setContact }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [messageP, setMessageP] = useState("");
  const [user, setUser] = useState({});
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t } = useTranslation();

  useEffect(() => {
    getUser();
  }, []);

  const sendContact = async () => {
    const resConnect = await fetch(`${url}/contact-us`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        email: user.email,
        message: messageP,
      }),
    });
    if (resConnect.status === 200) {
      message.success(t('messages.invitationSend'));
      setContact(false);
    }
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <Modal
      className="modal-account"
      onOk={() => {}}
      visible={true}
      onCancel={() => setContact(false)}
      footer={[
        <Button key="back" onClick={() => setContact(false)}>
          {t("cancel")}
        </Button>,
      ]}
    >
      <div className="column" style={{ alignItems: "center" }}>
        <p
          className="title-300"
          style={{
            color: "rgb(110, 110, 110)",
            fontSize: "16px",
            width: "80%",
            marginTop: "20px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          {t("yourEmailAddress")} :
        </p>
        <Input value={user.email} />
        <p
          className="title-300"
          style={{
            color: "rgb(110, 110, 110)",
            fontSize: "16px",
            width: "80%",
            marginTop: "20px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Message
        </p>
        <Input.TextArea
          maxLength={450}
          defaultValue={user && user.shop && user.shop.describe}
          style={{ width: "90%", maxWidth: "90%", minWidth: "90%" }}
          onChange={(e) => setMessageP(e.target.value)}
          placeholder="Report message"
          className="describe-input"
        />
        <Button className="button orange-back" style={{ marginTop: "20px" }} onClick={sendContact}>
          {t("sendMessage")}
        </Button>
      </div>
    </Modal>
  );
};

export default Contact;
