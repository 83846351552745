import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../wrappers";

const VideoModal = ({ url, text, style }) => {
  const [activeModal, setActiveModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      {activeModal && (
        <Modal
          className="modal-order"
          visible={true}
          title={""}
          footer={null}
          onCancel={() => setActiveModal(false)}
        >
          <iframe
            style={{ width: "90%", height: "30vw" }}
            src={url}
            title={t("components.modal.1")}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </Modal>
      )}
      <p className="title underline blue pointer" style={style} onClick={() => setActiveModal(true)}>
        {text}
      </p>
    </>
  );
};

export default VideoModal;
