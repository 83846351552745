import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";

import supplerAcademyLogo from "../../images/icons/01supply_academy.svg";
import { routes } from "../../routes/index";

import buttonStarter from "../../images/resellerCoaching/starter_button_2.svg";
import buttonAdvanced from "../../images/resellerCoaching/advanced_button_2.svg";
import buttonPro from "../../images/resellerCoaching/pro_button_2.svg";

import "./style.scss";

const Navbar = ({ noSticky }) => {
  const { t, i18n } = useTranslation();
  const [isStickyNav, setIsStickyNav] = useState(false);

  window.onscroll = function (e) {
    if (!noSticky) {
      if (window.scrollY > 145) {
        setIsStickyNav(true);
      } else setIsStickyNav(false);
    }
  };

  const currentCourse = () => {
    const path = window.location.pathname;
    if (path?.includes("starter")) {
      return "starter";
    } else if (path?.includes("advanced")) {
      return "advanced";
    } else {
      return "pro";
    }
  };

  const dropMenu = (
    <Menu
      items={[
        {
          key: "1",
          label:
            currentCourse() !== "starter" ? (
              <Link to="/e-commerce-starter-coaching">
                <img src={buttonStarter} alt="starter button" className="button-navbar" />
              </Link>
            ) : (
              <Link to="/e-commerce-advanced-coaching">
                <img src={buttonAdvanced} alt="advanced button" className="button-navbar" />
              </Link>
            ),
        },
        {
          key: "2",
          label:
            currentCourse() !== "pro" ? (
              <Link to="/e-commerce-pro-coaching">
                <img src={buttonPro} alt="pro button" className="button-navbar" />
              </Link>
            ) : (
              currentCourse() !== "advanced" && (
                <Link to="/e-commerce-advanced-coaching">
                  <img src={buttonAdvanced} alt="advanced button" className="button-navbar" />
                </Link>
              )
            ),
        },
      ]}
    />
  );

  return (
    <div className={isStickyNav ? "reseller-coaching-navbar navbar sticky" : "reseller-coaching-navbar"}>
      <Link to="/">
        <img
          style={{ width: "150px", marginRight: "30vw" }}
          src={supplerAcademyLogo}
          alt="01supply academy"
          className="logo-navbar"
        />
      </Link>
      <Dropdown overlay={dropMenu}>
        <div onClick={(e) => e.preventDefault()} className="row">
          {currentCourse() === "starter" ? (
            <img src={buttonStarter} alt="starter button" className="button-navbar" />
          ) : currentCourse() === "advanced" ? (
            <img src={buttonAdvanced} alt="advanced button" className="button-navbar" />
          ) : (
            <img src={buttonPro} alt="pro button" className="button-navbar" />
          )}
          <CaretDownOutlined className="down-navbar-button" />
        </div>
      </Dropdown>
    </div>
  );
};

export default Navbar;
