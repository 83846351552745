import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
import { Select, InputNumber, message, Input, List } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {Modal} from '../../wrappers'
import { url } from "../../api/url";
import { header } from "../../api/header";
import OfferCard from "./OfferCard";
import { sendGridEmail } from "../../utils/SendGridEmail";
import ModalNeedSubsribe from "../modals/NeedSubscribe";
import { getImage } from "../../utils/GetImage";
import { productServices } from "../../services/products";

const { Option } = Select;

const ManageOffer = ({
  contact,
  user,
  offers,
  setOffers,
  setLoad,
  windowSize,
  isModalManageOffersDisplayed,
  setIsModalManageOffersDisplayed,
}) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [products, setProducts] = useState([]);
  const [productSelect, setProductSelect] = useState({});
  const [variantSelect, setVariantselect] = useState({});
  const [modalNeedSubOn, setModalNeedSubOn] = useState(false);
  const [searchProductWord, setSearchProductWord] = useState("");
  const [process, setProcess] = useState(false);
  const [newOffer, setNewOffer] = useState({});

  const { t } = useTranslation()

  const createrShop = user.type === "creater" ? user.shop?.name : contact.shop?.name;
  const createrId = user.type === "creater" ? user._id : contact._id;

  useEffect(() => {
    if (window.location.href.includes("offer=") && !productSelect.name) {
      setLoad(true);
      getOfferParamsProduct();
    }
    if (contact._id && !newOffer.seller_id) {
      // INIT OFFER
      setNewOffer({
        seller_id: localStorage.getItem("typeAccountCreatosell") === "seller" ? user._id : contact._id,
        creater_id: localStorage.getItem("typeAccountCreatosell") === "creater" ? user._id : contact._id,
        price: {
          product_id: "",
          product_image: "",
          product_name: "",
          variant_id: "",
          variant_name: "",
          custom_price: "",
          initial_price: "",
          status: localStorage.getItem("typeAccountCreatosell") === "creater" ? "accepted" : "pending",
        },
      });
    }
  }, [contact]);

  const getOfferParamsProduct = async () => {
    const href = window.location.href;
    const currentUrl = new URL(href);
    const offerParams = currentUrl.searchParams.get("offer");
    if (offerParams) {
      const res = await fetch(`${url}/product/find-with-image`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          productsId: offerParams,
        }),
      });
      if (res.status === 200) {
        const resJson = await res.json();
        setProductSelect(resJson.products[0]);
        setIsModalManageOffersDisplayed(true);
        setLoad(false);
      } else setLoad(false);
    }
  };

  const searchProduct = async () => {
    setProcess(true);
    setProductSelect({});
    productServices
      .find({ search: searchProductWord, createrId })
      .then((res) => res.json())
      .then((res) => {
        if (!res.data || res.data?.length < 1) {
          message.error(t('messages.noProducts'));
          setProcess(false);
        } else {
          Promise.all(
            res.data.map(async (product) => {
              const img = await getImage(product.photos[0]).then((img) => img);
              if (img) {
                return { ...product, image: img };
              } else if (img === undefined) {
                return product;
              }
            })
          ).then((products) => {
            setProducts(products);
            setProcess(false);
          });
        }
      });
  };

  const createOffer = async (offer) => {
    if (!offer.price) {
      message.error(t('message.pleaseInputPrice'));
      setLoad(false);
    } else {
      const res = await fetch(`${url}/custom-price/create/${user._id}/${user.secret_id}`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          customPrice: offer,
        }),
      });
      if (res.status === 200) {
        message.success('messages.offerSend');
        let newOffers = [...offers.prices, offer.price];
        setOffers({ ...offers, prices: newOffers });
        setProductSelect({});
        setVariantselect({});
        setLoad(false);
        setNewOffer({
          seller_id: localStorage.getItem("typeAccountCreatosell") === "seller" ? user._id : contact._id,
          creater_id: localStorage.getItem("typeAccountCreatosell") === "creater" ? user._id : contact._id,
          price: {
            product_id: "",
            product_image: "",
            product_name: "",
            variant_id: "",
            variant_name: "",
            custom_price: "",
            initial_price: "",
            status: localStorage.getItem("typeAccountCreatosell") === "creater" ? "accepted" : "pending",
          },
        });

        sendGridEmail(
          `${
            typeAccount === "seller"
              ? "d-e03cbc937085491a80b5cd25bf74a260"
              : "d-fce05f8ae4ab4c77a9e3bc195ef79848"
          }`,
          contact.email,
          {
            userName: user.shop.name
              ? user.shop.name
              : user.firstName
              ? user.firstName + " " + user.lastName
              : user.email,
            contactUrl: `https://app.01supply.com/messages&contact=${contact._id}`,
          },
          token
        );
      } else if (res.status === 201) {
        setModalNeedSubOn(true);
        setLoad(false);
      } else {
        message.error(t('messages.error'));
        setLoad(false);
      }
    }
  };

  return (
    <>
      {modalNeedSubOn && <ModalNeedSubsribe setModal={setModalNeedSubOn} />}
      {isModalManageOffersDisplayed && (
        <Modal
          className="modal-1000"
          title={"Manage offers"}
          visible={true}
          onCancel={() => {
            setIsModalManageOffersDisplayed(false);
            setProductSelect({});
            setVariantselect({});
          }}
          footer={[]}
        >
          <div className="column" style={{ width: "100%" }}>
            <p style={{ fontSize: "30px" }} className="title-roboto-bold">
              {t("components.messages.10")}
            </p>
            <div
              style={{ marginLeft: "0", marginTop: "0", marginBottom: "30px" }}
              className="divider-horizontal"
            />

            <div
              style={
                windowSize > 1150
                  ? { alignItems: "flex-start", width: "100%" }
                  : { alignItems: "flex-start", width: "100%", flexDirection: "column" }
              }
              className="row"
            >
              <div className="column" style={windowSize > 1150 ? { width: "50%" } : { width: "100%" }}>
                <div className="row">
                  <Input
                    style={windowSize > 1150 ? { width: "330px" } : { width: "75vw" }}
                    onChange={(e) => setSearchProductWord(e.target.value)}
                    placeholder={t("components.messages.11")}
                    onPressEnter={() => {
                      if (!process) {
                        searchProduct();
                        setProcess(true);
                      }
                    }}
                  />
                  <button
                    onClick={() => {
                      if (!process) {
                        searchProduct();
                        setProcess(true);
                      }
                    }}
                    style={{
                      marginRight: "-10px",
                      borderRadius: "7px",
                      height: "35px",
                      position: "absolute",
                      right: "10px",
                      zIndex: "100",
                      fontSize: "15px",
                    }}
                    className="button big orange-back"
                  >
                    {process ? <LoadingOutlined style={{ color: "white" }} /> : t("components.messages.12")}
                  </button>
                </div>
                {!productSelect.name && searchProductWord && products.length > 0 && !process && (
                  <List
                    style={{
                      maxWidth: "330px",
                      width: "100%",
                      maxHeight: "400px",
                      marginTop: "10px",
                      overflow: "scroll",
                    }}
                    header={<div>{t("components.messages.13")}</div>}
                    bordered
                    dataSource={products}
                    renderItem={(product) => (
                      <List.Item style={{ width: "100%" }}>
                        <div className="column start width-100">
                          <p className="text-ellipsis start" style={{ width: "90%" }}>
                            {product.name}
                          </p>
                          <div
                            style={{
                              alignItems: "flex-start",
                              marginTop: "10px",
                              justifyContent: "space-between",
                            }}
                            className="row width-100"
                          >
                            <img style={{ width: "50px" }} src={product.image} />
                            <button onClick={() => setProductSelect(product)} className="button orange-back">
                              {t("components.messages.14")}
                            </button>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                )}
                <Link target="_blank" to={`/shop/${createrShop}`} className="text blue underline pointer">
                  {user.type === "creater" ? t("components.messages.15") : t("components.messages.16")}
                </Link>

                {productSelect._id && (
                  <div style={{ padding: "20px", width: "100%" }} className="row">
                    <img
                      src={productSelect.image}
                      style={{
                        height: "50px",
                        width: "50px",
                        minWidth: "50px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        marginRight: "15px",
                      }}
                    />
                    <div style={{ width: "100%" }} className="column">
                      <p style={{ width: "100%" }} className="text-bold text-ellipsis">
                        {productSelect.name}
                      </p>
                      <p style={{ width: "100%" }} className="text-roboto-300 text-ellipsis">
                        ${productSelect.price}
                      </p>
                    </div>
                  </div>
                )}

                {productSelect && productSelect.variantBool && (
                  <Select
                    style={{ width: "300px", marginTop: "40px" }}
                    onChange={(value) => {
                      setVariantselect(productSelect.variants[parseInt(value)]);
                    }}
                    defaultValue={t("components.messages.17")}
                    className="select-manage-offer-2"
                  >
                    {productSelect.variants.map((variant, index) => {
                      return (
                        <Option value={index}>
                          <div style={{ padding: "20px" }} className="row">
                            <img
                              src={productSelect.image}
                              style={{
                                height: "50px",
                                width: "50px",
                                minWidth: "50px",
                                minHeight: "50px",
                                borderRadius: "10px",
                                marginRight: "15px",
                              }}
                            />
                            <div style={{ width: "100%" }} className="column">
                              <p style={{ width: "100%" }} className="text-roboto-300 text-ellipsis">
                                {t("components.messages.18")}
                              </p>
                              <p
                                style={{ width: "100%", marginTop: "-5px" }}
                                className="text-roboto-bold text-ellipsis"
                              >
                                {variant.option1} {variant.option2 && "-" + variant.option2}{" "}
                                {variant.option3 && "-" + variant.option3}
                              </p>
                              <p style={{ width: "100%" }} className="text-roboto-300 text-ellipsis">
                                ${variant.price}
                              </p>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </div>
              {productSelect.name && (!productSelect.variantBool || variantSelect._id) && (
                <div
                  style={
                    windowSize > 1150
                      ? { marginLeft: "30px", width: "45%" }
                      : { marginTop: "20px", width: "100%" }
                  }
                  className="column"
                >
                  <p
                    style={{ width: "100%", fontSize: "20px", textAlign: "start" }}
                    className="title-roboto-300 text-ellipsis"
                  >
                    {productSelect.name}
                  </p>
                  {variantSelect.option1 && (
                    <p style={{ width: "100%", textAlign: "start" }} className="text-roboto text-ellipsis">
                      Variant: {variantSelect.option1} {variantSelect.option2 && "-" + variantSelect.option2}{" "}
                      {variantSelect.option3 && "-" + variantSelect.option3}
                    </p>
                  )}
                  <p
                    style={{ fontSize: "20px", marginTop: "20px", marignBottom: "5px" }}
                    className="title-roboto-bold"
                  >
                    {t("components.messages.19")}
                  </p>
                  <p className="title-roboto-300">
                    ${variantSelect.option1 ? variantSelect.price : productSelect.price}
                  </p>
                  <p
                    style={{ fontSize: "20px", marginTop: "20px", marignBottom: "5px" }}
                    className="title-roboto-bold"
                  >
                    {t("components.messages.20")}
                  </p>
                  <InputNumber
                    placeholder={`${t('newPrice')} ($)`}
                    suffi="$"
                    style={{ width: "200px" }}
                    step="0.01"
                    min={0.5}
                    max={
                      variantSelect.option1
                        ? parseFloat(variantSelect.price)
                        : parseFloat(productSelect.price)
                    }
                    onChange={(value) => {
                      setNewOffer({
                        ...newOffer,
                        price: { ...newOffer.price, custom_price: parseFloat(value).toFixed(2) },
                      });
                    }}
                  />
                  <button
                    onClick={() => {
                      if (!newOffer.price?.custom_price) {
                        message.error(t('messages.needFill.priceOffer'));
                      } else {
                        setLoad(true);
                        createOffer({
                          ...newOffer,
                          price: {
                            product_id: productSelect._id,
                            product_name: productSelect.name,
                            product_image: productSelect.image,
                            variant_id: variantSelect ? variantSelect._id : "",
                            variant_name: variantSelect.option1
                              ? `${variantSelect.option1} ${
                                  variantSelect.option2 ? "-" + variantSelect.option2 : ""
                                } ${variantSelect.option3 ? "-" + variantSelect.option3 : ""}`
                              : "",
                            custom_price: newOffer.price.custom_price,
                            initial_price: variantSelect.price ? variantSelect.price : productSelect.price,
                            status: user.type === "creater" ? "accepted" : "pending",
                          },
                        });
                      }
                    }}
                    style={{ marginTop: "20px", width: "200px" }}
                    className="button orange-back"
                  >
                    {t("components.messages.21")}
                  </button>
                </div>
              )}
            </div>

            {offers.prices && (
              <>
                <p style={{ marginTop: "40px", fontSize: "30px" }} className="title-roboto-bold">
                  {t("components.messages.22")}{" "}
                  {contact.shop?.name ? contact.shop.name : `${contact.firstName} ${contact.lastName || ""}`}
                </p>
                <div
                  style={{ marginLeft: "0", marginTop: "0", marginBottom: "30px" }}
                  className="divider-horizontal"
                />
                {offers.prices &&
                  offers.prices
                    .slice()
                    .reverse()
                    .map((offer) => {
                      return (
                        <OfferCard
                          windowSize={windowSize}
                          setLoad={setLoad}
                          messages={offers}
                          setMessages={setOffers}
                          user={user}
                          contact={contact}
                          offer={offer}
                        />
                      );
                    })}
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageOffer;
