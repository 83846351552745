import countryList from "./country_list.json";
import { origin as originList } from "../data/Origin";

const normalize = (country) => {
  return country?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
};

export const getCountry = (countryFind) => {
  let productCountry = "";
  countryList.forEach(
    (list) =>
      list &&
      list.children &&
      list.children.forEach((country) => {
        const countryTitle = normalize(country?.title);
        if (countryFind) {
          let originTitle = normalize(countryFind);
          const isCountryOriginNumber = countryFind.includes("-");
          const isCountryCode = countryFind && countryFind.length < 3;
          if (isCountryCode) {
            originTitle = normalize(originList.find((origin) => origin.code === countryFind && origin)?.name);
          }
          if (countryFind === "0-0") {
            productCountry = "Rest of the world";
          } else if (isCountryOriginNumber && country.key === countryFind) {
            productCountry = country.title;
          } else if (countryTitle?.toLocaleLowerCase()?.includes(originTitle?.toLocaleLowerCase())) {
            productCountry = country.title;
          }
        } else return "";
      })
  );
  return productCountry;
};
