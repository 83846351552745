import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { url } from "../../api/url";
import { header } from "../../api/header";

import Menu from "../../components/menu/AffiliateMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import CheckToken from "../../utils/RedirectLogin";
import { userServices } from "../../services/user";
import PaypalEmailModal from "../../components/modals/PaypalEmailModal";
import IbanModal from "../../components/modals/IbanModal";

import paypalIcon from "../../images/icons/paypal.svg";
import bankIcon from "../../images/icons/bank.png";

const PaymentRequest = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [ibanModal, setIbanModal] = useState(false);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount, setIdAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId, setSecretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [user, setUser] = useState({});
  const [paypalEmailAccountModal, setPaypalEmailAccountModal] = useState(false);
  const [affiliations, setAffiliations] = useState([]);

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    userServices
      .me()
      .then((res) => res.json())
      .then((res) => setUser(res.data));
    userServices
      .findAffiliation()
      .then((res) => res.json())
      .then((res) => setAffiliations(res.affiliation));
  }, []);

  return (
    <div className="page affiliation">
      {paypalEmailAccountModal && (
        <PaypalEmailModal
          header={header}
          token={token}
          url={url}
          idAccount={idAccount}
          secretId={secretId}
          setPaypalEmailAccountModal={setPaypalEmailAccountModal}
          user={user}
          setUser={setUser}
        />
      )}
      {ibanModal && (
        <IbanModal
          header={header}
          token={token}
          url={url}
          idAccount={idAccount}
          secretId={secretId}
          setIbanModal={setIbanModal}
          user={user}
          setUser={setUser}
        />
      )}
      <CheckToken />
      <div className="page-start">
        {windowSize > 1150 ? <Menu user={user} /> : <MobileMenu user={user} locationP="affiliation" />}
        <div
          style={
            windowSize > 1150
              ? { padding: "50px", paddingLeft: "30px", paddingTop: "30px" }
              : { padding: "50px", paddingBottom: "100px", paddingLeft: "30px", paddingRight: "30px" }
          }
          className="column-scrolling start"
        >
          <h1
            style={{ fontSize: "28px", marginBottom: "30px" }}
            className="title montserrat-800 width-100 start"
          >
            {t("affiliateDashboard.paymentRequest.title")}
          </h1>
          <div style={{ width: "200px" }} className="card">
            <p className="text-bold">{t("affiliateDashboard.paymentRequest.1")}</p>
            <p className="title montserrat green">${affiliations?.total_available_payout || 0}</p>
          </div>
          <p style={{ marginTop: "30px", marginBottom: "10px" }} className="title montserrat-700 start">
            {t("affiliateDashboard.paymentRequest.2")}
          </p>
          <p className="text montserrat-700">{t("affiliateDashboard.paymentRequest.3")}</p>
          <p>
            01supply <br />  66 Avenue des Champs-Élysées, 75008, Paris, France <br />
            SIRET: 94910191900015
          </p>
          <p style={{ marginTop: "30px" }} className="text montserrat-700">
            {t("affiliateDashboard.paymentRequest.4")}
          </p>
          <p>01supply Partner Program Payout</p>
          <p style={{ marginTop: "30px", marginBottom: "10px" }} className="title montserrat-700 start">
            {t("affiliateDashboard.paymentRequest.5")}
          </p>
          <div className="row-wrap">
            <button style={{margin: '10px'}} className="settings-payments-button" onClick={() => setIbanModal(true)}>
              <img
                src={bankIcon}
                style={{
                  width: "40px",
                  height: "auto",
                }}
                alt="iban"
              />
              {t("affiliationPage.12")}
            </button>
            <p style={{ margin: "15px" }}>{t("or")} </p>
            <button style={{margin: '10px'}} className="settings-payments-button" onClick={() => setPaypalEmailAccountModal(true)}>
              <img
                src={paypalIcon}
                style={{
                  width: "40px",
                  height: "auto",
                }}
                alt="paypal"
              />
              {t("affiliationPage.10")}
            </button>
          </div>
          <p style={{ marginTop: "30px", marginBottom: "10px" }} className="title montserrat-700 start">
            {t("affiliateDashboard.paymentRequest.8")}
          </p>
          <a
            style={{ width: "fit-content" }}
            target="_blank"
            rel="nopenner noreferrer"
            href={`mailto:contact.01supply@gmail.com?subject=Affiliation payment request from ${user.email}`}
            className="button big orange-back"
          >
            {t("affiliateDashboard.paymentRequest.9")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentRequest;
