import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";

import { routes } from "../../../routes";

import tropheIcon from "../../../images/icons/trophe.svg";
import petIcon from "../../../images/icons/pet.svg";
import treeIcon from "../../../images/icons/tree.svg";
import babyIcon from "../../../images/icons/baby.svg";

const SecondaryHomeDropdown = () => {
  const { t, i18n } = useTranslation();
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              className="text montserrat row"
              style={{ height: "40px", padding: "15px 10px", alignItems: "center" }}
              href="https://01supply.com/winning-products"
            >
              <img src={tropheIcon} alt="trophée" style={{ width: "20px", marginRight: "10px" }} />
              {t("winningProducts")}
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              className="text montserrat row"
              style={{ height: "40px", padding: "15px 10px", alignItems: "center" }}
              href="https://01supply.com/eco-friendly-products"
            >
              <img src={treeIcon} alt="tree" style={{ width: "20px", marginRight: "10px" }} />
              {t("ecoProducts")}
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              className="text montserrat row"
              style={{ height: "40px", padding: "15px 10px", alignItems: "center" }}
              href="https://01supply.com/baby-products"
            >
              <img src={babyIcon} alt="baby" style={{ width: "20px", marginRight: "10px" }} />
              {t("babyProducts")}
            </a>
          ),
        },
        {
          key: "4",
          label: (
            <a
              className="text montserrat row"
              style={{ height: "40px", padding: "15px 10px", alignItems: "center" }}
              href="https://01supply.com/pet-supplies"
            >
              <img src={petIcon} alt="pet" style={{ width: "20px", marginRight: "10px" }} />
              {t("petSupplies")}
            </a>
          ),
        },
        {
          key: "5",
          label: (
            <Link
              className="text montserrat row"
              style={{ height: "40px", padding: "15px 10px", alignItems: "center" }}
              to={routes.marketplace(i18n.language).path}
            >
              {t("ortherProducts")}
            </Link>
          ),
        },
      ]}
    />
  );

  return (
    <div className="row center" style={{ minWidth: "130px" }}>
      <Dropdown overlay={menu}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <p className="title-300" style={{ fontSize: "16px" }}>
              {t("Products")}
            </p>
            <DownOutlined style={{ fontSize: "13px" }} />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default SecondaryHomeDropdown;
