import React, { useState, useEffect } from "react";
import NavBar from "../../../components/menu/navbar/NavBar";
import { Helmet } from "react-helmet";
import img23 from "../../../images/blog/import-etsy-product-to-shopify.jpg";
import img24 from "../../../images/blog/import-etsy.jpg";
import Footer from "../../../components/menu/Footer";
import Cta from "../../../components/Cta";

const ImportEtsyProductShopify = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [email, setEmail] = useState("");

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  return (
    <div
      style={
        windowSize > 1150
          ? { paddingLeft: "15vw", paddingRight: "15vw", alignItems: "center" }
          : { alignItems: "center", paddingBottom: "200px" }
      }
      className="blog-page"
    >
      <NavBar windowSize={windowSize} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>How to Import dropshipping Etsy products to Shopify ?| 01supply</title>
        <link rel="canonical" href="https://blog.01supply.com/how-to-import-etsy-products-to-shopify" />
        <meta
          name="description"
          content="Learn how to import Etsy products to your Shopify dropshipping store thanks to 01supply and its chrome extension. You can do this for free!"
        />
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "Import Etsy products to Shopify",
          "image": [
            "https://app.01supply.com/static/media/import-etsy-product-to-shopify.58226770.jpg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
      </Helmet>

      <div
        className="column"
        style={{ width: "70vw", justifyContent: "flex-start", width: "60%", paddingBottom: "5vw" }}
      >
        <img
          alt="Increase your sales on Etsy"
          style={
            windowSize > 1150
              ? { width: "100%", height: "auto" }
              : { width: "100%", height: "auto", marginTop: "30px" }
          }
          src={img23}
        />
        <h1 className="blog-h1">How to Import dropshipping Etsy products to Shopify ?</h1>
        <h2 className="blog-h2">Can I dropship products from Etsy to Shopify? </h2>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          You may ask yourself: <i>“Can I reseller items from Etsy ?”</i>. <br />
          <b>The answer is YES !</b> <br />
          You can of course sell Etsy products for your dropshipping store on Shopify.
        </p>
        <p style={{ marginTop: "10px" }} className="text-roboto">
          <b>It is legal</b> as long as you have the authorization from the Etsy seller.
        </p>
        <p style={{ marginBottom: "5px", marginTop: "10px" }} className="text-roboto">
          In order to do that, you can use the Etsy messaging feature. We also recommend you to ask for the
          supplier email as it will be important for you to contact him later if you have any problem.
        </p>
        <h2 className="blog-h2">
          📑 Import products from Etsy to Shopify <i>for dropshipping</i>
        </h2>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          It is a boring and time-consuming task to create all Etsy products manually on you Shopify store.
          You must also ask the supplier for the available quantity, the shipping method, the processing time,
          and a lot more…
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          The way to go should be to import the products data all at once. In order to do that, we developed a{" "}
          <b>chrome extension</b> that allows you to{" "}
          <b>import all the Etsy product data in one click to your Shopify store</b>.
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          You can already download it here:{" "}
          <a
            style={{ textDecoration: "underline" }}
            target="_blank"
            title="Extension Chrome 01supply"
            href="https://bit.ly/01supply-extension"
          >
            https://bit.ly/01supply-extension
          </a>
        </p>
        <hr/>
          <a
            class="button orange-back"
            target="_blank"
            style={
              windowSize > 1150
                ? { height: "35px", width: "300px" }
                : { height: "50px", marginBottom: "10px" }
            }
            href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
          >
            GET THE CHROME EXTENSION
          </a>
       
        <h2 className="blog-h2">Oberlo alternative for Etsy</h2>
        <img
          alt="Increase your sales on Etsy"
          style={
            windowSize > 1150
              ? { width: "100%", height: "auto" }
              : { width: "100%", height: "auto", marginTop: "30px" }
          }
          src={img24}
        />
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          <i>
            Can I dropship from different suppliers ? How can I keep track of my different suppliers ? What if
            the price is changing ? What if the item is not available anymore ?
          </i>{" "}
          These are all legitimate questions.
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          <b>With 01supply chrome extension, you don’t have to worry about that anymore.</b> We handle these
          automatically. 01supply allows you to work with hundreds, thousands of suppliers simultaneously
          without even having to open an excel file.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          First and foremost, let’s remember what Oberlo is. Oberlo is the most used dropshipping Shopify app
          that allows you to import products from Aliexpress to Shopify. Then once you have an order for this
          item, you can order it on the app, and all your customers information will be sent automatically to
          your supplier.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          01supply works to same way with Etsy. With our chrome extension, you can import products to your
          store. Then once you have an order on Shopify, you can order it from 01supply Shopify app.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          You must have the 01supply Shopify app + our chrome extension in order for this to work.
        </p>
        <ol style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          <li>
            <b>Add 01supply app to your Shopify store</b> :{" "}
            <a
              style={{ textDecoration: "underline" }}
              target="_blank"
              title="01supply Shopify App"
              href="https://apps.shopify.com/01supply"
            >
              https://apps.shopify.com/01supply
            </a>
          </li>
          <li>
            <b>Add 01supply chrome extension</b> :{" "}
            <a
              style={{ textDecoration: "underline" }}
              target="_blank"
              title="Extension Chrome 01supply"
              href="https://bit.ly/01supply-extension"
            >
              https://bit.ly/01supply-extension
            </a>
          </li>
        </ol>
        <h3 className="blog-h3">How does 01supply chrome extension work ?</h3>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/aGou-PIZS-s"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          First, make sure that you are logged in on the chrome extension. To do this, just click the
          extension icon and fill your account information to log in.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          When you are on an Etsy product page and you have 01supply chrome extension enabled, you can click
          on our import icon. <b>This will send the product to the 01supply Shopify app.</b> Import as many
          Etsy products as you want.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          Then, go in 01supply Shopify app and open your Import list. From there, you can see all products
          that you imported. You can either select the products you want to publish on your Shopify store or
          send them all. To send them all, just click the “Push to store” button at the bottom right of your
          screen.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          Great, now the products are live on your Shopify store and you can edit them from Shopify.{" "}
        </p>
        <h2 className="blog-h2">Etsy dropshipping suppliers </h2>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          It is not easy to find Etsy suppliers that agree to work with you. The only way to find them is to
          contact a lot of Etsy sellers. <br /> <u>Here is what you can write</u> :{" "}
        </p>
        <div style={{ width: "80%", textAlign: "center", backgroundColor: "#e6e6e6", padding: "20px" }}>
          <h4 className="blog-h4">Message template</h4>
          <p style={{ marginBottom: "5px", marginTop: "15px", textAlign: "left" }} className="text-roboto">
            <i>
              Hello, <br />
              My name is (Your name), and I am a (French) reseller working online. <br />
              I really like your (products), I think they are looking beautiful and unique!
              <br />
              Hence I would like to invite you to work with me. <br />
              If you are interested, please send me your email address or Whatsapp so we can talk about it.{" "}
              <br />
              Best regards, <br />
              (Your name)
            </i>
          </p>
        </div>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          Make sure that you add some variations in your messages as Etsy has a spam detector. If you send the
          same message multiple times or too fast, you will be restricted.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          Now send this kind of message to at least 50 suppliers. With that number in mind, you can expect 5
          to 10 agreements.{" "}
        </p>

        <h2 className="blog-h2">🕒 What if I don’t have time for this ? </h2>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          No worries ! 01supply Shopify app offers you a new feature. <b> We will contact them for you.</b> In
          fact, there is a marketplace on 01supply allowing you to find suppliers that already agree with
          dropshipping. You can import products from our marketplace to your Shopify store in one click. And
          you don’t have to ask for the authorization as the suppliers are already aware of dropshipping.
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          01supply is just starting. We have a long way to go before having enough suppliers in our
          marketplace. That is why we take your needs into consideration. Just send us a message with the
          suppliers you would like to see on our marketplace and we will start inviting them for you.{" "}
        </p>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          <b>
            Just send us a message at{" "}
            <a style={{ textDecoration: "underline" }} target="_blank" href="mailto:contact@01supply.com">
              contact@01supply.com
            </a>{" "}
            and explain what you are looking for.
          </b>{" "}
          <br />
          <br />
          <u>Here are some examples</u>:{" "}
        </p>

        <ul style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          <li>Your niche</li>
          <li>Location of the supplier (Europe, US, UK, …)</li>
          <li>Category of items</li>
          <li>Specific item</li>
          <li>Range of price</li>
          <li>...</li>
        </ul>
        <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-roboto">
          We hope to see you soon on board !
        </p>
      </div>


      <Footer location="reseller" windowSize={windowSize} />
    </div>
  );
};

export default ImportEtsyProductShopify;
