import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article20/What-are-meta-descriptions-and-meta-tiles.jpg";
import htmlMeta from "../../../../images/blog/global/article20/html-meta-title-and-description.jpg";
import shopifyMeta from "../../../../images/blog/global/article20/metadata-shopify-title.jpg";
import metaDescription from "../../../../images/blog/global/article20/meta-description-example.jpg";
import metaTitle from "../../../../images/blog/global/article20/meta-title-example.jpg";
import squarespaceMeta from "../../../../images/blog/global/article20/meta-title-squarespace.jpg";
import openGraph from "../../../../images/blog/global/article20/opengraph-protocol.jpg";
import schemaOrg from "../../../../images/blog/global/article20/schema-org-data-on-google.jpg";
import seoShopify from "../../../../images/blog/global/article20/search-engine-listing-shopify.jpg";
import googleSuggestions from "../../../../images/blog/global/article20/search-suggestions-google.jpg";
import wixSEO from "../../../../images/blog/global/article20/wix-seo.jpg";
import wordpressYoastseo from "../../../../images/blog/global/article20/yoast-seo-wordpress.jpg";



const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat start" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        What are meta descriptions and meta titles (definition and examples)
      </h1>
      <p className="text-300">11 october 2022 - 9 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="What are meta descriptions and meta titles"/>
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        You may be wondering <b>what Google metadata are</b> and how you should use them to optimize your website SEO.
        In this article, we will go through some of the most common questions about this topic.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#google-metadata" title="What is Google metadata?" />
            <Anchor.Link href="#meta-description" title="What is a meta description?" >
              <Anchor.Link href="#meta-description-use" title="What is a meta description used for?" />
              <Anchor.Link href="#lenght-meta-description" title="How many characters are in a meta description? (length limit)" />
              <Anchor.Link href="#meta-description-pages" title="Does every page need a meta description?" />
            </Anchor.Link>
            <Anchor.Link href="#meta-title-definition" title="What is a meta title? " >
            <Anchor.Link href="#meta-title-example" title="What should be in a meta title?" />
            <Anchor.Link href="#find-seo-title" title="Where do I find my SEO title?" />
            <Anchor.Link href="#lenght-meta-title" title="How long is a meta title?" />
            </Anchor.Link>
            <Anchor.Link href="#meta-title-description-difference" title="What is the difference between a title and a meta title?" />
            <Anchor.Link href="#add-metadata-seo" title="How do you write a meta description and a meta title? (how to add them to your website)" />
            <Anchor.Link href="#title-same-description" title="Can the meta description be the same as the title?" />
            <Anchor.Link href="#opengraph-meta" title="What are “OG:” meta tags?" />
            <Anchor.Link href="#schema-org-meta" title="Schema.org metadata" />
            <Anchor.Link href="#google-seo-algorithm" title="How to understand Google SEO and algorithm?" />
          </Anchor>
        }
      />
      <h2 id="google-metadata">What is Google metadata?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Metadata helps Google understand <b>what your page is intended to tell</b>, and how it should rank on the search result page.
      </p>
      <p style={{ marginBottom: "20px" }}>
        There are a lot of multiple different metadata that <b>influence your website ranking</b>. Some of them are more important than others.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>The two most important metadata are the meta title and the meta description.</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        <i>In this article, you will often hear about SERP. SERP stands for “Search Engine Result Page”,
          which is basically the results shown once you typed a query on Google Search.</i>
      </p>
      <h2 id="meta-description">What is a meta description?
      </h2>
      <img src={metaDescription} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="What is a meta description?"/>
      <p style={{ marginBottom: "20px" }}>
        A meta description is what you see appearing in the search results, below the title of the page.
      </p>
      <h3 id="meta-description-use">What is a meta description used for?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        The meta description is used for explaining <b>what the page is about</b> to the user. It should detail the content as shortly as possible.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>For user-friendly purposes</b>, make sure to add the meta title within the meta description as it will be highlighted by Google.
        As you can see in the image above, “01supply” is in bold in the meta description.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Note that <b>the meta description has no direct impact on SEO ranking</b>.
        It is mainly used to increase CTR (Click Through Rate) so that more people click on your website if your description is appealing enough.
      </p>
      <h3 id="lenght-meta-description">How many characters are in a meta description? (length limit)
      </h3>
      <p style={{ marginBottom: "20px" }}>
        There is no real length limit for meta descriptions. Indeed, you could write a meta description that is 5.000 characters long.
      </p>
      <p style={{ marginBottom: "20px" }}>
        But for optimization purposes (SEO), it is recommended to have a meta description length <b>ranging from 147 to 155 characters</b>.
        Indeed, this is the maximum number of characters that Google will display in the SERP on mobile.
        On the desktop, the limit is a bit higher (160), which is why we recommend you stick to the mobile limit.
      </p>
      <h3 id="meta-description-pages">Does every page need a meta description?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        If you don’t include any meta description on your page, Google will display the content it wants. Remember that <b>meta descriptions are optimizations</b>.
        If you don’t add, them, you won’t optimize your page for SEO.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Note that some pages don’t need to be optimized for ranking. For example the website policies, or the cart page if you are an e-commerce store owner.
      </p>
      <h2 id="meta-title-definition">What is a meta title?
      </h2>
      <img src={metaTitle} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="What is a meta title?"/>
      <p style={{ marginBottom: "20px" }}>
        A <b>meta title is very important</b> compared to a meta description. Indeed, the meta title is what gets you ranked on Google.
        If you don’t have a meta title, there is very little chance you will rank on Google.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Meta titles are the <b>titles of the results displayed on the SERP</b> when you type a query. This is what indicates to the user what your page is about.
      </p>
      <h3 id="meta-title-example">What should be in a meta title?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        <b>Your meta title should contain your exact target keyword</b>.
        Indeed, the closer your meta title is to the user query, the better your website will rank.
        For long tail keywords, this is even more true.
        You could rank first on a long tail query if you have the exact meta title and if there are no competitors on Google for this query.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Also, make sure that you add your website name at the end of each meta title.
        Here is the exact example of how to write it: “Target keyword title | My website name”
      </p>
      <h3 id="find-seo-title">Where do I find my SEO title?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Finding the SEO title is the same process as finding the target keyword for your page.
        For example, for this article, the target keyword is “What are meta descriptions and meta tiles?” because people are searching for it on Google.
        The meta title of the page is exactly the same.
      </p>
      <p style={{ marginBottom: "20px" }}>
        To find what people are looking for on Google, the easiest way to proceed is to <b>look for search suggestions</b>.
        Here is an example of what happens when you search for the topic “meta title”:
      </p>
      <img src={googleSuggestions} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Google Search Suggestions"/>
      <h3 id="lenght-meta-title">How long is a meta title?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Just like the meta description, the meta title could be as long as you want. But for optimization purposes, you should keep it <b>between 50 and 60 characters</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        After this max length, the title won’t be displayed and it will be shown with “...” which is not optimal for user experience.
      </p>
      <h2 id="meta-title-description-difference">What is the difference between a title and a meta title?
      </h2>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          The meta title is the title shown on the search result page on Google.
        </li>
        <li>
          The meta description is the short text shown after the meta title in the SERP.
        </li>
      </ul>
      <h2 id="add-metadata-seo">How do you write a meta description and a meta title? (how to add them to your website)
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Depending on the way you publish pages or articles online, the method to add Google metadata is different.
        Here is an overview of the main CMS and methods you can find for online content publishing.
      </p>
      <h3>HTML</h3>
      <img src={htmlMeta} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="HTML meta title description"/>
      <p style={{ marginBottom: "20px" }}>
        HTML is appropriate for those who <b>directly code their website</b>. It could also be helpful if your CMS does not handle metadata.
      </p>
      <p style={{ marginBottom: "20px" }}>First of all, make sure your metadata are in the {'<head>'} section of your file.
        <br />
        Add the following code (in bold is what you should edit):
      </p>
      <div style={{ backgroundColor: "#f4f4f4", padding: "20px", marginBottom: "20px", borderRadius: "20px" }}>
        <p style={{ marginBottom: "20px" }}><i>
          {'<head>'}
          <br /><br />
          {'<title>'}<b>your title is written here (50-60)</b>{'</title>'}
          <br />
          {'<meta'} name=”description” content=”<b>your description is written here (147-155)</b>”{'/>'}
          <br /><br />
          {'</head>'}
        </i>
        </p>
      </div>
      <h3>Shopify</h3>
      <p style={{ marginBottom: "20px" }}>
        Shopify is an <b>e-commerce CMS for product and collection pages</b>. But you can also create a blog section if you want.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is how to access your main page meta title and meta description</u>:
      </p>
      <p style={{ marginBottom: "20px" }}>
        Click on “Online store”, then click on “Preferences”.
        <br />
        You should now see this page:
      </p>
      <img src={shopifyMeta} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Shopify home metadata"/>
      <p style={{ marginBottom: "20px" }}>
        From there, just fill in the Homepage title and Homepage meta description. This is what will appear when people type your website name on Google.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is how to access the metadata of specific pages</u>:
      </p>
      <img src={seoShopify} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Shopify SEO pages"/>
      <p style={{ marginBottom: "20px" }}>
        Click on the page you want to edit. Scroll to the bottom of the page and you should see a bow called <b>“Search engine listing preview”</b>.
        From there, just <b>click on “Edit website SEO”</b> to edit your metadata. Note that you can also edit the URL on this part.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The process is the same for the product, collection, and blog pages. Note that you don’t need to add any application to your website for SEO on Shopify.
      </p>
      <h3>WordPress (Yoast SEO)
      </h3>
      <img src={wordpressYoastseo} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Add meta title description Wordpress Yoast"/>
      <p style={{ marginBottom: "20px" }}>
        WordPress SEO is a bit tricky.
        Indeed, as it is <b>open-source software</b>, most of the features are either made by other people (via plugins), or by yourself if you know how to code.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Therefore, we recommend you
        <b>use a plugin called <a href="https://wordpress.org/plugins/wordpress-seo/" rel="nofollow noreferrer" target="blank_" alt="Yoast SEO" className="blue"><u>Yoast SEO</u></a></b>.
        Once installed, the plugin will help you optimize your overall website SEO score.
        Then, you will need to manually add meta titles and meta descriptions on each page (just click on the page and you will see a new box for Yoast SEO).
      </p>
      <h3>Wix</h3>
      <p style={{ marginBottom: "20px" }}>
        As Wix is <b>very user-friendly</b>, you won’t find it difficult to add meta titles and meta descriptions.
      </p>
      <img src={wixSEO} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Wix SEO meta title and description"/>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is a short tutorial</u>:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <li>
          Click on the page icon
        </li>
        <li>
          Click on the more icon (...) next to the page you want to edit
        </li>
        <li>
          Click on “SEO Basics”
        </li>
        <li>
          From there, a popup will open where you can edit your meta title and meta description
        </li>
      </ol>
      <h3>Squarespace
      </h3>
      <img src={squarespaceMeta} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Squarespace metadata Google"/>
      <p style={{ marginBottom: "20px" }}>
        Click on the “Settings” icon next to the page you want to edit.
        <br />
        From there you will access a popup where you can fill in the page title (meta title) and the page description (meta description).
      </p>
      <h2 id="title-same-description">Can the meta description be the same as the title?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        <b>It is not relevant</b> to copy your meta title in the meta description.
        As explained before, the meta description is meant to increase your CTR.
        If Google understands your meta description is useless, he will display something else from your page content.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Furthermore, the meta description is way longer than the meta title
        which allows you to add more information and gives more opportunities to capture the prospect's attention.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>If you want to learn more about SEO techniques and theory, we have a full course for you. Check this here: <a href="/academy/google-seo-course" target="blank_" alt="Google SEO course" className="blue"><u>Google SEO Mastery</u></a></b>
      </p>
      <h2 id="opengraph-meta">What are “OG:” meta tags?
      </h2>
      <img src={openGraph} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="What are “OG:” meta tags?"/>
      <p style={{ marginBottom: "20px" }}>
        <b>“Og“ stands for “Opengraph”</b>. OpenGraph is a protocol <b>created by Facebook in 2010</b> to understand your content
        (just like Google understands your content with meta titles and meta descriptions).
      </p>
      <p style={{ marginBottom: "20px" }}>
        With Og tags, you can give more information to Facebook about your content.
        Here are the most common og tags, again, we the information you can edit is shown in bold:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          {'<meta'} property="og:title" content="<b>meta title</b>"{'/>'}
        </li>
        <li>
          {'<meta'} property="og:description" content="<b>meta description</b>"{'/>'}
        </li>
        <li>
          {'<meta'} property="og:type" content="<b>article</b>"{'/>'}
        </li>
        <li>
          {'<meta'} property="og:URL" content="<b>https://exemple.com/my-page</b>"{'/>'}
        </li>
        <li>
          {'<meta'} property="og:image" content="<b>http://exemple.com/image.jpg</b>"{'/>'}
        </li>
      </ul>
      <h3>OG types</h3>
      <p style={{ marginBottom: "20px" }}>
        Og:type determines the type of content you have on your page.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here are the three most common og:type you can find</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>content= “website“</b> : for a normal web page
        </li>
        <li>
          <b>content= ”article”</b> : for a blog post
        </li>
        <li>
          <b>content= “product”</b> : for a product page
        </li>
      </ul>
      <h3>OG image
      </h3>
      <p style={{ marginBottom: "20px" }}>
        The og:image is <b>the image that will be displayed on Facebook</b> when someone <b>shares a link</b> about your website.
        If the og:image is left empty, Facebook will either display your website logo or nothing.
      </p>
      <p style={{ marginBottom: "20px" }}>
        We recommend you add your article main image to the og:image.
      </p>
      <h3>OG URL</h3>
      <p style={{ marginBottom: "20px" }}>
        Og:URL is the URL of your page. This is will displayed on Facebook as <b>the preview link</b>. Note that the og:URL has no impact on the redirection.
        Your main URL will remain the same.
      </p>
      <p style={{ marginBottom: "20px" }}>
        We recommend you add the same og:URL as the main page URL.
      </p>
      <h2 id="schema-org-meta">Schema.org metadata
      </h2>
      <img src={schemaOrg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Schema.org metadata"/>
      <p style={{ marginBottom: "20px" }}>
        Schema.org metadata are often used for advanced purposes.
        <b>Schema.org is a protocol launched in 2011 by Bing, Google, and Yahoo</b> to better understand the content on the internet.
      </p>
      <p style={{ marginBottom: "20px" }}>
        It gives you access to more properties and data to add to your pages.
        These metadata can be placed anywhere on your website to indicate what each content block is about.
      </p>
      <p style={{ marginBottom: "20px" }}>
        For example, there are schema.org properties for FAQs, reviews, addresses, pricing plans, blog post author, published page date, breadcrumbs, and a lot more…
      </p>
      <p style={{ marginBottom: "20px" }}>
        The most common use and easiest way to use schema.org is <b>through JSON-LD</b> in the head section of your page.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is what it looks like</u>:
      </p>
      <div style={{ backgroundColor: "#f4f4f4", padding: "20px", marginBottom: "20px", borderRadius: "20px" }}>
        <p style={{ marginBottom: "20px" }}>
          <i>
            {'<head>'}<br /><br />
            {'<script type="application/ld+json">'}<br />
            {'{'}<br />
            "@context": "http://schema.org/",<br />
            "@type": "NewsArticle",<br />
            "headline": "What are meta titles and meta descriptions?",<br />
            "author":<br />
            {'{'}<br />
            "@type": "Person",<br />
            "name": "John Snow",<br />
            "birthDate": "1990-10-29"<br />
            {'},'}<br />
            "datePublished": "2022-10-12",<br />
            “dateModified”: “2022-10-29”,<br />
            {'}'}<br />
            {'</script>'}<br />
            <br /><br />
            {'</head>'}
          </i>
        </p>
      </div>
      <h2 id="google-seo-algorithm">How to understand Google SEO and algorithm?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Google is the most powerful tool today. <b>Billions of people use Google all around the world to look for businesses, information, products, and recommendations</b>.
        Meaning that SEO is the most important marketing skill to learn.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>If you are an e-commerce owner</b>, SEO could literally bring you hundreds of sales each month.
        Indeed, just imagine 5.000 people searching for your product online, and being first on the search result page literally brings hundreds of buyers.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>If you are a blogger</b>, and you monetize your blogs through Google Adsense or any other advertising company,
        getting visits from SEO could increase the clicks on the ads and generate more revenues.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>SEO is also very passive and long-term</b> as the search page does not change a lot. Meaning that you can stay in the first position for months and even years.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Therefore, the 01supply Academy created a full SEO course to understand Google algorithms and master SEO. You can find the course here: <a href="/academy/google-seo-course" target="blank_" alt="Google SEO course" className="blue"><u>Google SEO Mastery</u></a></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Note that SEO is also a high-demand skill in a lot of companies, especially digital ones.
      </p>






    </div>
  );
};

export default Content;
