import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ElementStripe from "./checkout_card/ElementStripe";
import Paypal from "./Paypal";
import Paypal01supply from "./01supplyPaypal";

import ScorpionMart from "../ibanQrPayment/ScorpionMart";
import NorthMyth from "../ibanQrPayment/NorthMyth";

const Pricing = ({
  data,
  setGoPayment,
  header,
  token,
  url,
  createrId,
  setPassOrder,
  setOrders,
  orders,
  order,
  sellerCost,
  user,
  variantSelect,
  shippingSelect,
  totalPrice,
  windowSize,
  imageSelect,
  messageSend,
  cost,
  isFeesPaid,
}) => {
  const [stripeAccountId, setStripeAccountId] = useState("");
  const [paypalAccountId, setPaypalAccountId] = useState("");
  const [iban, setIban] = useState("");
  const [paypalEmailAccount, setPaypalEmailAccount] = useState("");
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const resIban = await fetch(`${url}/user/find-iban/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (resIban.status === 200) {
      const resJson = await resIban.json();
      setIban(resJson.iban);
    }
    const resPaypalEmail = await fetch(`${url}/user/find-paypal-email-account/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (resPaypalEmail.status === 200) {
      const resJson = await resPaypalEmail.json();
      setPaypalEmailAccount(resJson.paypalEmailAccount);
    }
    const res = await fetch(`${url}/user/find-stripe/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setStripeAccountId(resJson.accountId || false);
    } else {
      setStripeAccountId(false);
    }
    const res2 = await fetch(`${url}/user/find-paypal/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res2.status === 200) {
      const resJson = await res2.json();
      if (resJson) setPaypalAccountId(resJson.accountId);
    }
  };

  return (
    <React.Fragment>
      <div
        style={
          windowSize > 1150
            ? { width: "100%", alignItems: "flex-start", marginBottom: "30px" }
            : {
                width: "100%",
                alignItems: "flex-end",
                flexDirection: "column",
                marginBottom: "30px",
              }
        }
        className="row"
      >
        <div
          className={windowSize > 1150 ? "column" : "row"}
          style={
            windowSize > 1150
              ? { width: "30%", marginLeft: "5%" }
              : {
                  width: "90%",
                  marginLeft: "5%",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }
          }
        >
          <img
            style={
              windowSize > 1150
                ? { marginBottom: "5px", width: "100%" }
                : { width: "100%", marginBottom: "20px" }
            }
            src={imageSelect}
            className="image-product-inventory"
          />
          {messageSend && (
            <p
              style={{
                textAlign: "start",
                marginBottom: "10px",
                marginTop: "5px",
              }}
              className="title"
            >
              {t("components.payment.1")}
              <span className="title-300">{" " + messageSend}</span>
            </p>
          )}
        </div>
        <div
          className="column paid text-end"
          style={{
            width: "50%",
            marginLeft: "15%",
            marginRight: "0%",
          }}
        >
          <p className="title" style={{ fontSize: "15px", textAlign: "end", maxWidth: "400px" }}>
            {order.quantity}x {order.product.name}
            {variantSelect?.option1 && " - " + variantSelect.option1}
            {variantSelect?.option2 && " - " + variantSelect.option2}
            {variantSelect?.option3 && " - " + variantSelect.option3} :
            <span
              key={variantSelect.price}
              style={{ fontSize: "16px", marginLeft: "5px", fontWeight: "400" }}
            >
              {order.status === "paid" && order.paid
                ? "$" + order.paid.product_paid
                : variantSelect.price
                ? "$" + cost.products
                : "??"}
            </span>
          </p>
          <p className="title-300" style={{ fontSize: "15px" }}>
            {t("shipping")} :
            <span style={{ fontSize: "16px", marginLeft: "5px", fontWeight: "400" }}>${cost.shipping}</span>
          </p>
          <div
            style={{ marginRight: "-10px", background: "rgb(230, 230, 230)" }}
            className="divider-horizontal"
          />
          <p className="title-300">
            {t("components.payment.2")}{" "}
            <span
              style={{
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "right",
              }}
            >
              {"$" + (isFeesPaid ? cost.productsAndShippingPrice : cost.total)}
            </span>
          </p>
        </div>
      </div>
      {cost.total &&
      (order.creater?.id === "60c20726f80fa80004560f64" ||
        order.creater.id === "6117f0bccb753e10b41443ba") ? (
        <ScorpionMart
          setPassOrder={setPassOrder}
          orders={orders}
          order={order}
          setOrders={setOrders}
          cost={cost}
          price={sellerCost}
          orderReference={`01supply${order.nb_order_creater}`}
        />
      ) : cost.total && order.creater?.id === "614b52dbbf04c80004c1a6c9" ? (
        <NorthMyth
          setPassOrder={setPassOrder}
          orders={orders}
          order={order}
          setOrders={setOrders}
          cost={cost}
          price={sellerCost}
          orderReference={`01supply${order.nb_order_creater}`}
        />
      ) : paypalAccountId && !load ? (
        <Paypal
          user={user}
          sellerCost={sellerCost}
          setPassOrder={setPassOrder}
          setOrders={setOrders}
          orders={orders}
          order={order}
          header={header}
          token={token}
          url={url}
          stripeAccountId={stripeAccountId}
          paypalAccountId={paypalAccountId}
          setGoPayment={setGoPayment}
          data={data}
          variantSelect={variantSelect}
          shippingSelect={shippingSelect}
          totalPrice={totalPrice}
          windowSize={windowSize}
          imageSelect={imageSelect}
          messageSend={messageSend}
        />
      ) : paypalEmailAccount && !iban && !paypalAccountId && !stripeAccountId && !load ? (
        <Paypal01supply
          user={user}
          sellerCost={sellerCost}
          setPassOrder={setPassOrder}
          setOrders={setOrders}
          orders={orders}
          order={order}
          header={header}
          token={token}
          url={url}
          setGoPayment={setGoPayment}
          data={data}
          variantSelect={variantSelect}
          shippingSelect={shippingSelect}
          totalPrice={totalPrice}
          windowSize={windowSize}
          imageSelect={imageSelect}
          messageSend={messageSend}
        />
      ) : (
        (stripeAccountId || stripeAccountId === false) && ( // check when user request is load to stripe connect account id
          <ElementStripe
            load={load}
            setLoad={setLoad}
            user={user}
            sellerCost={sellerCost}
            setPassOrder={setPassOrder}
            setOrders={setOrders}
            orders={orders}
            order={order}
            header={header}
            token={token}
            url={url}
            stripeAccountId={stripeAccountId}
            setGoPayment={setGoPayment}
            data={data}
            variantSelect={variantSelect}
            shippingSelect={shippingSelect}
            totalPrice={totalPrice}
            windowSize={windowSize}
            imageSelect={imageSelect}
            messageSend={messageSend}
          />
        )
      )}
    </React.Fragment>
  );
};

export default Pricing;
