import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CoachingForm from "../coachingForm/CoachingForm";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { Modal } from "../../wrappers";

const CoachingList = ({ isDisplayed, setIsDisplayed, users }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));
  const [coachingStatusFilter, setCoachingStatusFilter] = useState("in demand"); // in demand | accepted | activated

  const [coachings, setCoachings] = useState([]);

  useEffect(() => {
    fetch(`${url}/coaching/${adminId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    })
      .then((res) => (res.status === 200 ? res.json() : []))
      .then((res) => setCoachings(res.data));
  }, []);

  return (
    <Modal visible={isDisplayed} onCancel={() => setIsDisplayed(false)} footer={[]}>
      <div className="column">
        <button
          style={{ margin: "5px" }}
          className={coachingStatusFilter === "in demand" ? "button orange-back" : "button-white"}
          onClick={() => setCoachingStatusFilter("in demand")}
        >
          In demand
        </button>
        <button
          style={{ margin: "5px" }}
          className={coachingStatusFilter === "accepted" ? "button orange-back" : "button-white"}
          onClick={() => setCoachingStatusFilter("accepted")}
        >
          Accepted offer (validated by us)
        </button>
        <button
          style={{ margin: "5px" }}
          className={coachingStatusFilter === "activated" ? "button orange-back" : "button-white"}
          onClick={() => setCoachingStatusFilter("activated")}
        >
          Activated offer (is paid and not cancel)
        </button>
      </div>
      {users?.length &&
        coachings.map((coaching) => {
          if (coaching.status === coachingStatusFilter) {
            const user = users.find((user) => user?.user?._id === coaching.creater_id)?.user;
            return (
              <div style={{ marginTop: "20px" }} className="column">
                <div className="row">
                  <p style={{ marginRight: "8px" }}>email: {user?.email}</p>
                  <p style={{ marginRight: "8px" }}>shop: {user?.shop?.name}</p>
                  <Link
                    target="blank"
                    to={`/user-payments?userId=${coaching.creater_id}&userSecretId=${user?.secret_id}`}
                    className="text blue pointer underline"
                  >
                    View supplier
                  </Link>
                </div>
                <CoachingForm
                  user={user}
                  coaching={coaching}
                  idAccount={coaching.creater_id}
                  adminId={adminId}
                />
              </div>
            );
          }
        })}
    </Modal>
  );
};

export default CoachingList;
