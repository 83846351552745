import JewelIcon from '../images/icons_grey/Jewels _ Accessories.svg'
import ClotheIcon from '../images/icons_grey/Clothes _ Shoes.svg'
import HouseIcon from '../images/icons_grey/House _ Decoration.svg'
import ArtIcon from '../images/icons_grey/Arts _ Crafts.svg'
import SportIcon from '../images/icons_grey/Sports _ Outdoors.svg'
import ToysIcon from '../images/icons_grey/Toys _ Games.svg'
import ToolsIcon from '../images/icons_grey/Tools _ Gardening.svg'
import ElecIcon from '../images/icons_grey/Electronics _ Techs.svg'
import HealtIcon from '../images/icons_grey/Health _ Beauty.svg'
import Books from '../images/icons_grey/Books.svg'
import PetIcon from '../images/icons_grey/Pet supplies.svg'

import JewelIcon_white from '../images/icons_white/Jewels _ Accessories.svg'
import ClotheIcon_white from '../images/icons_white/Clothes _ Shoes.svg'
import HouseIcon_white from '../images/icons_white/House _ Decoration.svg'
import ArtIcon_white from '../images/icons_white/Arts _ Crafts.svg'
import SportIcon_white from '../images/icons_white/Sports _ Outdoors.svg'
import ToysIcon_white from '../images/icons_white/Toys _ Games.svg'
import ToolsIcon_white from '../images/icons_white/Tools _ Gardening.svg'
import ElecIcon_white from '../images/icons_white/Electronics _ Techs.svg'
import HealtIcon_white from '../images/icons_white/Health _ Beauty.svg'
import Books_white from '../images/icons_white/Books.svg'
import PetIcon_white from '../images/icons_white/Pet supplies.svg'


export const category = [
    {
        name : "Jewels & Accessories",
        image : JewelIcon,
        image_white : JewelIcon_white,
    },
    {
        name : "Clothes & Shoes",
        image : ClotheIcon,
        image_white : ClotheIcon_white
    },
    {
        name : "House & Decoration",
        image : HouseIcon,
        image_white : HouseIcon_white
    },
    {
        name : "Arts & Crafts",
        image : ArtIcon,
        image_white : ArtIcon_white
    },
    {
        name : "Sports & Outdoors",
        image : SportIcon,
        image_white : SportIcon_white
    },
    {
        name : "Toys & Games",
        image : ToysIcon,
        image_white : ToysIcon_white
    },
    {
        name : "Tools & Gardening",
        image : ToolsIcon,
        image_white : ToolsIcon_white
    },
    {
        name : "Electronics & Techs",
        image : ElecIcon,
        image_white : ElecIcon_white
    },
    {
        name : "Health & Beauty",
        image : HealtIcon,
        image_white : HealtIcon_white
    },
    {
        name : "Books",
        image : Books,
        image_white : Books_white
    },
    {
        name : "Pet supplies",
        image : PetIcon,
        image_white : PetIcon_white
    }
]