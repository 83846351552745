import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import UserIcon from "../../images/icons/User.png";

const MarketHeader = ({ setContactMenuOn, contactMenuOn, contact }) => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="row" style={{ width: "100%" }}>
        {contactMenuOn ? (
          !(contact && contact.email) ? (
            <CloseOutlined
              onClick={() => {
                setContactMenuOn(false);
              }}
              style={{
                fontSize: "40px",
                marginRight: "15px",
                cursor: "pointer",
              }}
            />
          ) : (
            <div
              style={{
                padding: "20px",
                border: "1px solid rgb(100, 100, 100)",
                borderTopLeftRadius: "9px",
                borderBottomLeftRadius: "9px",
                borderRight: "0px solid black",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
              }}
            >
              <CloseOutlined
                onClick={() => {
                  setContactMenuOn(false);
                }}
                style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              />
            </div>
          )
        ) : !(contact && contact.email) ? (
          <MenuOutlined
            onClick={() => setContactMenuOn(true)}
            style={{ fontSize: "40px", marginRight: "15px", cursor: "pointer" }}
          />
        ) : (
          <div
            style={{
              padding: "20px",
              border: "1px solid rgb(100, 100, 100)",
              borderTopLeftRadius: "9px",
              borderBottomLeftRadius: "9px",
              borderRight: "0px solid black",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90px",
            }}
          >
            <MenuOutlined
              onClick={() => setContactMenuOn(true)}
              style={{ fontSize: "40px", cursor: "pointer" }}
            />
          </div>
        )}
        {!(contact && contact.email) ? (
          <div className="column">
            <h4 className="little-title-header">{t("show")}</h4>
            <h4 style={{ fontSize: "25px" }} className="title-header">
              CONTACTS
            </h4>
          </div>
        ) : (
          <div
            style={{
              border: "1px solid rgb(100, 100, 100)",
              borderBottomRightRadius: "9px",
              borderTopRightRadius: "9px",
              height: "100px",
              paddingTop: "30px",
              width: "300px",
            }}
            className="row"
          >
            <img
              src={contact.profile_picture?.includes('http') ? contact.profile_picture : UserIcon}
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                marginRight: "20px",
                marginLeft: "15px",
              }}
            />
            <div className="column">
              {contact.shop.name ? (
                <Link
                  target="_blank"
                  to={contact.shop.name ? `/shop/${contact.shop.name}` : ""}
                  style={{ height: "25px", fontSize: "20px", color: "rgb(50, 50, 50)", width: "80%" }}
                  className="title-roboto text-ellipsis"
                >
                  {contact.shop && contact.shop.name
                    ? contact.shop.name
                    : contact.firstName && `${contact.firstName} ${contact.lastName}`}
                </Link>
              ) : (
                <p
                  style={{ height: "25px", fontSize: "20px", color: "rgb(50, 50, 50)", width: "80%" }}
                  className="title-roboto text-ellipsis"
                >
                  {contact.shop && contact.shop.name
                    ? contact.shop.name
                    : contact.firstName && `${contact.firstName || ""} ${contact.lastName || ""}`}
                </p>
              )}

              <p style={{ width: "85%" }} className="text-roboto-300 text-ellipsis">
                {contact.company && contact.company.email ? contact.company.email : contact.email}
              </p>
            </div>
            <p
              style={{
                display: "flex",
                borderBottomRightRadius: "9px",
                maxHeight: "25px",
                width: "180px",
                position: "absolute",
                top: "0px",
                left: "0px",
                padding: "5px",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              className="text-bold white orange-back-gradient"
            >
              {t("components.header.18")}
            </p>
          </div>
        )}
      </div>
      <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
    </div>
  );
};

export default MarketHeader;
