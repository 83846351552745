import { message, Divider } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import {Modal} from '../../wrappers'
import { Link } from "react-router-dom";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { sendGridEmail } from "../../utils/SendGridEmail";

const OfferCard = ({ offer, user, contact, location, messages, setMessages, setLoad, windowSize, id }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [modalRemoveOn, setModalRemoveOn] = useState(false);

  const { t } = useTranslation();

  const sendSupplierOfferResponse = async (status) => {
    const res = await fetch(`${url}/custom-price/status/${user._id}/${user.secret_id}/${offer._id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        status,
      }),
    });
    if (res.status === 200) {
      setLoad(false);
      message.success(t("messages.priceOfferStatus"), { status });
      const newOffers = messages.prices.map((price) =>
        price._id === offer._id ? { ...price, status: status } : price
      );
      setMessages({ ...messages, prices: newOffers });

      sendGridEmail(
        "d-954c5b4bb50144a184ca33ed2956a1f8",
        contact.email,
        {
          status,
          contactUrl: `https://app.01supply.com/messages&contact=${contact._id}`,
          productName: offer.product_name,
        },
        token
      );
    }
  };

  const removePriceOffer = async (status) => {
    const res = await fetch(`${url}/custom-price/${user._id}/${user.secret_id}/${offer._id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      sendGridEmail(
        "d-c0173a4442ed4c07a3f0cc90db951a8c",
        contact.email,
        {
          productUrl: `https://app.01supply.com/product-details?name=${offer.product_name}&id=${offer.product_id}`,
          productName: offer.product_name,
          supplierName: user.shop.name
            ? user.shop.name
            : user.firstName
            ? user.firstName + " " + user.lastName
            : user.email,
          contactUrl: `https://app.01supply.com/messages&contact=${user._id}`,
        },
        token
      );
      setLoad(false);
      message.success(t("messages.priceOfferDeleted"));
      const newOffers = messages.prices.filter((price) => price._id !== offer._id);
      setMessages({ ...messages, prices: newOffers });
      setModalRemoveOn(false);
    } else {
      message.error(t("messages.error"));
      setLoad(false);
    }
  };

  return (
    <div
      key={offer._id}
      id={id}
      style={{
        width: `${location === "chat" && windowSize > 1150 ? "60%" : location === "chat" ? "80%" : "100%"}`,
        alignItems: "center",
        cursor: "default",
        marginLeft: `${location === "chat" && user.type === "seller" && windowSize > 1150 ? "40%" : "0"}`,
        padding: "0",
      }}
      className="card column orange-back-3"
    >
      <Modal
        className="modal-account"
        onOk={removePriceOffer}
        visible={modalRemoveOn}
        onCancel={() => setModalRemoveOn(false)}
      >
        <p className="title">{t("components.messages.1")}</p>
      </Modal>

      <div
        style={
          windowSize > 1150
            ? { padding: "20px", background: "white", width: "100%", justifyContent: "center" }
            : {
                padding: "10px",
                background: "white",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }
        }
        className="row"
      >
        <div className="column">
          <img
            style={{
              borderRadius: "9px",
              height: "130px",
              width: "auto",
              maxWidth: "150px",
              marginRight: "40px",
            }}
            src={offer.product_image}
          />
          {user.type === "creater" && offer._id && offer.status === "accepted" && (
            <button
              style={{ fontSize: "13px", marginTop: "8px" }}
              onClick={() => setModalRemoveOn(true)}
              className="button-red"
            >
              {t("components.messages.2")}
            </button>
          )}
        </div>
        <div
          style={{
            width: "70%",
            alignItems: "flex-start",
            marginBottom: windowSize <= 1150 ? "35px" : "15px",
          }}
          className="column"
        >
          <div style={{ width: "100%", marginTop: "10px" }} className="row">
            <div className="row">
              <p
                style={{
                  position: "relative",
                  fontSize: `${windowSize > 850 ? "35px" : "5vw"}`,
                  textAlign: "start",
                }}
                className="title-300"
              >
                <span className="dashed" />${offer.initial_price}
              </p>
            </div>
            <p
              style={{
                marginLeft: `${windowSize > 850 ? "30px" : "10px"}`,
                marginRight: `${windowSize > 850 ? "30px" : "10px"}`,
                fontSize: `${windowSize > 850 ? "35px" : "5vw"}`,
              }}
              className="title-300"
            >
              {">"}
            </p>
            <p style={{ fontSize: `${windowSize > 850 ? "35px" : `5vw`}` }} className="title-roboto-bold">
              ${offer.custom_price}
            </p>
          </div>
          <div style={{ position: "relative", width: "100%" }} className="column">
            <p style={{ width: "100%", textAlign: "start" }} className="title-300 text-ellipsis">
              {offer.product_name}
            </p>
            <div style={{ alignItems: "flex-end", height: "30px" }} className="row">
              {offer.variant_name && (
                <p
                  style={{ width: "100%", marginRight: "20px", fontSize: "18px" }}
                  className="text-bold text-ellipsis"
                >
                  <span className="text-roboto" style={{ marginRight: "5px", fontSize: "18px" }}>
                    Variant:
                  </span>{" "}
                  {offer.variant_name}
                </p>
              )}
              {windowSize > 1150 && (
                <Link
                  style={{ marginBottom: "2px" }}
                  className="text-gradient"
                  to={`/product-details?name=${offer.product_name}&id=${offer.product_id}`}
                >
                  Details
                </Link>
              )}
            </div>
            {windowSize <= 1150 && (
              <Link
                style={{ marginBottom: "2px" }}
                className="text-gradient"
                to={`/product-details?name=${offer.product_name}&id=${offer.product_id}`}
              >
                Details
              </Link>
            )}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            background: `${
              offer.status === "pending"
                ? "linear-gradient(47deg, #fdca83 0%, #ffbb5b 100%)"
                : offer.status === "declined"
                ? "rgb(255 141 141)"
                : "rgb(131 190 255)"
            }`,
            borderTopLeftRadius: "6px",
            padding: "3px",
            paddingRight: "12px",
            paddingLeft: "12px",
          }}
          className="text-roboto white"
        >
          {offer.status === "pending"
            ? t("components.messages.3")
            : `${t("components.messages.4")} ${t(offer.status)} !`}
        </div>
      </div>
      {user.type === "creater" && offer.status === "pending" && (
        <div style={{ width: "100%", alignItems: "center" }} className="column">
          <Divider style={{ marginTop: "0" }} />
          {offer.status === "pending" && (
            <div
              style={
                windowSize > 1150
                  ? { width: "100%", justifyContent: "center", marginTop: "10px" }
                  : {
                      width: "90%",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                      flexDirection: "column",
                    }
              }
              className="row"
            >
              <button
                onClick={() => {
                  setLoad(true);
                  sendSupplierOfferResponse("declined");
                }}
                style={windowSize > 1150 ? { marginRight: "20px" } : { marginBottom: "10px" }}
                className="button-white-red"
              >
                {t("components.messages.5")}
              </button>
              <button
                onClick={() => {
                  setLoad(true);
                  sendSupplierOfferResponse("accepted");
                }}
                style={windowSize > 1150 ? { marginLeft: "20px" } : {}}
                className="button-white-gradient"
              >
                {t("components.messages.6")}
              </button>
            </div>
          )}
          <p style={{ marginTop: "10px", marginBottom: "10px" }} className="text-300">
            {t("components.messages.7")} {contact.firstName} {contact.lastName || ""}
          </p>
        </div>
      )}
    </div>
  );
};

export default OfferCard;
