import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article14/product-photography-cost.jpg";
import cameraGear from "../../../../images/blog/global/article14/camera-gear-cost.jpg";
import independant from "../../../../images/blog/global/article14/independant-photographer.jpg"
import studioRental from "../../../../images/blog/global/article14/photo-studio-rental.jpg";
import typesPhotography from "../../../../images/blog/global/article14/product-photography-types.jpg";
import lightsEquipement from "../../../../images/blog/global/article14/photography-lights-cost.jpg";
import studio from "../../../../images/blog/global/article14/studio-photography.jpg";
import truckRental from "../../../../images/blog/global/article14/truck-rental-photography.jpg";
import ecommercePhotography from "../../../../images/blog/global/article14/ecommerce-photography.jpg";
import photographyContract from "../../../../images/blog/global/article14/product-photography-included.jpg";
import smartphonePhoto from "../../../../images/blog/global/article14/smartphone-product-photography.jpg";



const Content = () => {

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  return (
    <div className="column width-100">
      <h1 className="title montserrat start" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        How much does product photography cost?
      </h1>
      <p className="text-300">26 september 2022 - 5 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px" }} alt="How much does product photography cost?"/>
      <p style={{ marginTop: "15px" }} className="start">
        When you create your own products, you must <b>take your own product pictures</b> to showcase them online.
        Product photography is crucial for customers as it represents your product quality. {" "}
      </p>
      <p style={{ marginTop: "15px" }}><b>Bad photos can impact your sales very badly.</b>
      </p>
      <p style={{ marginTop: "15px" }}>In this article, you will learn how to work with a professional studio, <b>how much it cost</b>,
        and <b>what to do if you can’t afford</b> to invest that much.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#how-many-photo-per-product-ecommerce" title="How many photos should a product have?" />
            <Anchor.Link href="#product-photography-pricing" title={`Product photography pricing in ${currentYear}`}  />
            <Anchor.Link href="#photographer-expensive-reason" title="Why are photographer so expensive?" />
            <Anchor.Link href="#why-variation-rates" title="Why ies there so much variantion in rates?" />
            <Anchor.Link href="#ecommerce-photography" title="Ecommerce photography" />
            <Anchor.Link href="#pictures-in-1-hour-shoot" title="How many pictures in a 1 hour shoot?" />
            <Anchor.Link href="#what-does-product-photography-include" title="What does product photography include?" />
            <Anchor.Link href="#product-photography-expectations" title="What should I expect from a product photographer?" >
            <Anchor.Link href="#how-to-hire-good-product-photographer" title="How do I hire a good product photographer?" />
              </Anchor.Link>
            <Anchor.Link href="#smartphone-product-photography" title="Can you do product photography with a smartphone?" />


          </Anchor>
        }
      />
      <h2 id="how-many-photo-per-product-ecommerce">🖼️ How many photos should a product have?</h2>
      <p style={{ marginTop: "15px", marginBottom: "20px" }}>
        A product should <u>at least have 3 photos</u>:
      </p>
      <ol >
        <li>
          One <b>global picture</b> to showcase the product clearly.<br />
          <span style={{ color: "#A6A6A6" }}>For example: A shoe on a white background.</span>
        </li>
        <li>
          One <b>closeup to showcase details</b> or a specific feature.<br />
          <span style={{ color: "#A6A6A6" }}>For example: The sole quality.</span>
        </li>
        <li>
          And one image to illustrate the <b>product in its environment.</b><br />
          <span style={{ color: "#A6A6A6" }}>For example: Someone wearing the shoes.</span>
        </li>
      </ol>
      <img src={typesPhotography} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="3 types of product photography" />
      <p style={{ marginBottom: "20px" }}>
        The more picture you have, the better it is for customers. Indeed, product pictures should remove any objections the customer might have.
      </p>
      <p style={{ marginBottom: "20px" }}>
        For example: If the customer is worried about the shoe quality, then showcase a closeup picture.
      </p>
      <p style={{ marginBottom: "20px" }}>Be careful not to add too many useless images as it might also upset the customer.
        A shoe does not need 15 global pictures from every angle. So make sure each image has its purpose and <b>don’t go over 9 images</b> unless your product is very special.
      </p>
      <h2 id="product-photography-pricing">
        💵 Product photography pricing in {currentYear}
      </h2>
      <h3>Pricing per hour
      </h3>
      <ul>
        <li>
          <b>Low</b>: $100 - $150
        </li>
        <li>
          <b>Average</b>: $150 - $400
        </li>
        <li>
          <b>High</b>: $400+
        </li>
      </ul>
      <h3>Pricing per day
      </h3>
      <ul>
        <li>
          <b>Low</b>: $300 - $500
        </li>
        <li>
          <b>Average</b>: $500 - $2.000
        </li>
        <li>
          <b>High</b>: $2.000 - $5.000+
        </li>
      </ul>
      <h3>Pricing per products
      </h3>
      <ul>
        <li><b>Low</b>: $10 - $20
        </li>
        <li>
          <b>Average</b>: $20 - $100
        </li>
        <li>
          <b>High</b>: $100 - $300+
        </li>
      </ul>
      <h3>Pricing per image
      </h3>
      <ul>
        <li>
          <b>Low</b>: $5 - $15
        </li>
        <li>
          <b>Average</b>: $15 - $70
        </li>
        <li>
          <b>High</b>: $70 - $200+
        </li>
      </ul>
      <h2 id="photographer-expensive-reason">📈 Why are photographers so expensive?
      </h2>
      <h3>Photography gear
      </h3>
      <img src={cameraGear} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Camera gear cost" />
      <p style={{ marginBottom: "20px" }}>First of all, photography gear is very expensive. Cameras cost at least $1.500 and that is not even including the lens which is at least $500.
        Note that photographers must have multiple lenses to capture different scenes.
        A good photographer won’t use the same lens for a global shot and for closeup details.
      </p>
      <p style={{ marginBottom: "20px" }}>Some expensive cameras can go as high as $30.000 and some lenses can cost $25.000.
      </p>
      <p style={{ marginBottom: "20px" }}>Overall the average price for photography gear is <b>$5.000 (camera + lenses)</b>.
        This is reason number one why some photography studios are very expensive.
      </p>
      <h3>Lights & equipment
      </h3>
      <img src={lightsEquipement} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Lights and equipment cost" />
      <p style={{ marginBottom: "20px" }}>To get good results, a photographer will need softboxes, light stands, strobe, backlights, tripods, background paper, etc…
      </p>
      <p style={{ marginBottom: "20px" }}>Ingredient number one is the <b>lighting which can cost on average $5.000</b>.
      </p>
      <h3>Studio rental
      </h3>
      <img src={studioRental} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Studio rental cost" />
      <p style={{ marginBottom: "20px" }}>Some photographers <b>work in a studio</b>, where their setup is ready for shooting. This is also a heavy cost for them.
      </p>
      <h3>Transportation cost
      </h3>
      <img src={truckRental} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Truck rental cost" />
      <p>If you want your shooting in a specific place, the photographer needs to <b>transport his equipment</b> which also includes a truck rental as photography
        equipment is heavy and takes up a lot of space.
      </p>
      <h2 id="why-variation-rates">❓ Why is there so much variation in rates?
      </h2>
      <p style={{ marginBottom: "20px" }}>As you probably understand, prices can vary a lot <b>depending on the equipment the photographer uses</b>.
        It’s also very different if you work with an independent photographer or a photography studio.
      </p>
      <p>It varies also a lot depending on the <b>photographer's experience</b>.
      </p>
      <h3>Independent photographer
      </h3>
      <img src={independant} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Independant photographer pros and cons" />
      <p style={{ marginBottom: "20px" }}>Independent photographers often work in shared studios with other freelancers.
        With solo photographers, you can also take advantage of their flexibility. For example, they can shoot wherever you want. This could save you some costs.
      </p>
      <p style={{ marginBottom: "20px" }}>You should also always make sure to select someone in your area as this will save a lot of transportation costs.
      </p>
      <p style={{ marginBottom: "20px" }}>There are still some downsides to independent photographers:
      </p>
      <ol>
        <li>The demand is very high and you may have to <b>wait for weeks</b> to get your photo shoot.
        </li>
        <li>Some solo photographers may have <b>less equipment</b> than photography studios and won’t always be ready to shoot e-commerce products.
        </li>
        <li>Some photographers are experienced in wedding photography or portrait photography.
          So you should always make sure he has <b>already shot for e-commerce</b> brands before working with him.
        </li>
      </ol>
      <h3>Photography studio
      </h3>
      <img src={studio} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Photography studio pros and cons" />
      <p style={{ marginBottom: "20px" }}>Photography studios are way <b>more expensive</b> than solo photographers. But some studios are cheaper than others.
        You should always look for specialized studios as they are often more affordable than large studios.
      </p>
      <p style={{ marginBottom: "20px" }}>They will charge more but you will often get <b>larger services</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>For example, some specialized e-commerce shooting photography studios may offer a warehouse and shipping service.
        They will store your products in their studio and ship them back to you when everything is shot.
      </p>
      <p style={{ marginBottom: "20px" }}>Also, a photography studio will often deliver your work faster and shoot more photos per hour.
      </p>
      <h2 id="ecommerce-photography">🛒 Ecommerce photography
      </h2>
      <img src={ecommercePhotography} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Ecommerce photography requirements" />
      <p style={{ marginBottom: "20px" }}><b>E-commerce photography is very specific</b>. It has nothing to do with a wedding, an anniversary, or party photography.
      </p>
      <p style={{ marginBottom: "20px" }}>Indeed, e-commerce shooting needs specific equipment like <b>diverse backgrounds</b>.
        If you sell home decoration products, the photographer should have suitable decor.
        You may have to rent a house for a day (on Airbnb for example) to have access to multiple decors.
      </p>
      <p style={{ marginBottom: "20px" }}>Next up you also need to <b>shoot with models</b> to showcase in-context product images. This is often true for clothes and accessories.
      </p>
      <p style={{ marginBottom: "20px" }}>For e-commerce photography, you also often need banners and collection images.
        This includes large decor, editing, and <b>adaptation for each event</b> (banners for Christmas, Halloween, Black Friday,...).
      </p>
      <h2 id="pictures-in-1-hour-shoot">📸 How many pictures should be expected from a 1-hour shoot?
      </h2>
      <p style={{ marginBottom: "20px" }}>First of all, be aware that photographers don’t have a 100% success rate with photos.
        This means that some photos can be bad (wrong focus, bad settings, wrong lighting,...).
      </p>
      <p style={{ marginBottom: "20px" }}><b>On average you could expect 30-50 pictures from a 1-hour shoot.</b>
      </p>
      <p style={{ marginBottom: "20px" }}>Don’t forget to add the preparation time. Preparation includes setting up decor, lights, fixing settings, changing lenses, etc…
      </p>
      <h2 id="what-does-product-photography-include">📋 What does product photography include?
      </h2>
      <img src={photographyContract} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="What is included in product photography?" />
      <p style={{ marginBottom: "20px" }}>When hiring a photographer, you must write down all your requirements.
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li><b>Editing and post-production</b>: Do you want the photographer to do the editing? Indeed some images need editing before publication.
          This is also important for the photographer as the setup could be completely different if you don’t want your images to be edited.
        </li>
        <li><b>Model and photo-release</b>: Consider having a model release if you photograph a model.
          This will allow you to use his image on your website, ads, etc… You must write all your conditions before shooting.
        </li>
        <li><b>Equipment and decor</b>: Photographer often brings their own equipment, but sometimes they don’t have a specific decor for e-commerce.
          Make sure to talk about this before starting the project.
        </li>
        <li><b>File format</b>: Depending on the image process you want, you have to ask for different file formats.
          For example, if you want to edit your images, you will prefer RAW format. If you want ready-to-use images, you may prefer the JPG format.
        </li>
      </ul>
      <h2 id="product-photography-expectations">📚 What should I expect from a product photographer?
      </h2>
      <p style={{ marginBottom: "20px" }}>Before working with a product photographer, there is a lot to talk about with him.
        As discussed above, many requirements must be taken into account. Therefore let’s discuss how to hire a good photographer.
      </p>
      <h3 id="how-to-hire-good-product-photographer">How do I hire a good photographer?</h3>
      <p style={{ marginBottom: "20px" }}>First of all, to hire a good photographer, you must be a good client.
        <br />
        What does it mean?
      </p>
      <p style={{ marginBottom: "20px" }}>Know your stuff! If you don’t know what your requirements are, or what you are going to do with the pictures, the photographer may do something you didn’t want.
        So <b>write down all the requirements</b>, the context, and the usage of your pictures, and talk with him about this. </p>
      <p style={{ marginBottom: "20px" }}><u>A good photographer should</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>Listen and ask for details about your business
        </li>
        <li>Understand your requirements
        </li>
        <li>Guide you for technical parts like file formats and pricing
        </li>
        <li>Offer flexibility depending on your situation
        </li>
      </ul>
      <h2 id="smartphone-product-photography">📱 Can you do product photography with a smartphone?
      </h2>
      <img src={smartphonePhoto} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Smartphone photography" />
      <p style={{ marginBottom: "20px" }}>Yes, you can, and in some cases, you should. <br />
        Here is why:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li><b>Smartphone pictures are lighter</b>: which means images will load faster on your website
        </li>
        <li>Product photography does not need $20.000 cameras. A smartphone with good settings and proper light will <b>often do the job</b>. Especially recent ones.
        </li>
        <li>E-commerce implies <b>frequent collection updates</b> and new product arrivals. A smartphone makes it easier to shoot a new collection, rather than paying for a new shooting in a photographer's studio.
        </li>
        <li>A smartphone is a <b>cheap and easy</b> option for every independent product creator that can’t invest thousands of dollars in photography.
        </li>
      </ul>
      <h3>Why do people hire photographers if smartphone works well?
      </h3>
      <p style={{ marginBottom: "20px" }}>A lot of people don’t know how to properly set up their smartphone to take great pictures.
        They think that they have to buy expensive cameras and lights while in fact, it is not the case.
      </p>
      <p style={{ marginBottom: "20px" }}>In photography, the best lighting is the natural light from the sun, as long as you know how to benefit from it.
        So for the most part you can get nearly the same result as a professional shooting with your smartphone and $0 equipment.
      </p>
      <p style={{ marginBottom: "20px" }}>To help you shoot your products, even if you know nothing about photography, we created a full course on how to shoot product pictures for your e-commerce brand.
      </p>
      <p style={{ marginBottom: "20px" }}>Check the course here: <a className="blue" href="/academy/photo-mastery-course" title="product photography course" target="blank_"><u>01supply.com/academy/photo-mastery-course</u></a>
      </p>
      <p style={{ marginBottom: "20px" }}>You can shoot your first pictures starting today based on our experience with thousands of other product creators we helped.
      </p>



    </div>
  );
};

export default Content;
