import React, { useState } from "react";
import { Input, message, InputNumber } from "antd";
import { t } from "i18next";

import { Modal } from "../../wrappers";
import { updateUser } from "../../services/updateUser";

import SelectShipping from "../supplier/SelectShipping";

const CreateEditShipping = ({
  shipping,
  setEditedShipping,
  user,
  setUser,
  setIsCreateShipping,
  shippingFactorize,
  isCard,
}) => {
  const [newShipping, setNewShipping] = useState(
    shipping || {
      name: "",
      origin: "",
      time: "",
      price: "",
      additionalProductPrice: "",
    }
  );
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isSelectDestinations, setIsSelectDestinations] = useState(false);

  const isEdit = !!shipping?._id;

  const isValid = () => {
    return !(
      !newShipping.name ||
      !newShipping.origin ||
      !((startTime && endTime) || newShipping.time) ||
      !newShipping.price ||
      !newShipping.additionalProductPrice
    );
  };

  const deliveryTimeValue = ({ index }) => {
    const existingValue = shipping?.time || "";
    let start = "";
    let end = "";
    if (existingValue) {
      start = existingValue.split(" to ")?.[0];
      end = existingValue.split(" to ")?.[1];
    }
    if (index === 0) {
      return start;
    } else if (index === 1) {
      return end;
    }
  };

  const handleEdit = () => {
    if (!isValid()) {
      message.error(t("elementsMissing"));
    } else {
      const newUserShipping = user.shipping.map((userShipping) =>
        userShipping._id === shipping._id
          ? {
              ...newShipping,
              time: `${startTime || deliveryTimeValue({ index: 0 })} to ${
                endTime || deliveryTimeValue({ index: 1 })
              }`,
            }
          : userShipping
      );
      setEditedShipping({});
      updateUser({ user: { shipping: newUserShipping } });
      setUser({ ...user, shipping: newUserShipping });
      shippingFactorize(newUserShipping);
    }
  };

  const handleCreate = () => {
    if (!isValid()) {
      message.error(t("elementsMissing"));
    } else {
      const newId = new Date().getTime();
      const newUserShipping = [
        ...user.shipping,
        {
          ...newShipping,
          temporaryId: newId,
          time: `${startTime || deliveryTimeValue({ index: 0 })} to ${
            endTime || deliveryTimeValue({ index: 1 })
          }`,
        },
      ];
      updateUser({
        user: { shipping: newUserShipping },
        newShipping: {
          ...newShipping,
          time: `${startTime || deliveryTimeValue({ index: 0 })} to ${
            endTime || deliveryTimeValue({ index: 1 })
          }`,
        },
      });
      setIsCreateShipping(false);
      if (setEditedShipping) {
        setEditedShipping({});
      }
      setUser({ ...user, shipping: newUserShipping });
      shippingFactorize(newUserShipping);
    }
  };

  return (
    <Modal
      className="modal-1000"
      title={isEdit ? t("components.shipping.editShipping") : t("components.shipping.createShipping")}
      visible={true}
      onCancel={() => {
        if (setEditedShipping) {
          setEditedShipping({});
        }
        setIsCreateShipping(false);
      }}
      footer={[]}
    >
      {isSelectDestinations && (
        <SelectShipping
          shipping={{ origin: newShipping.origin }}
          setSelectShipping={setIsSelectDestinations}
          editShippingOrigin={(value) => setNewShipping({ ...newShipping, origin: value })}
        />
      )}
      <div style={{ alignItems: "center" }} className="column width-100 shipping">
        <div style={{ alignItems: "center" }} className="column width-100 shipping create-edit">
          <div className="row width-100">
            <p className="section-1">{t("components.shipping.2")}</p>
            {!isEdit && !isCard && <p className="section-2">{t("components.shipping.6")}</p>}
            <p className="section-3">{t("components.shipping.3")}</p>
            <p className="section-4">{t("components.shipping.4")}</p>
            <p className="section-4">{t("components.shipping.5")}</p>
          </div>
          <div className="row width-100 shipping">
            <Input
              value={newShipping.name}
              className="section-1"
              placeholder={t("components.shipping.placeholder.1")}
              onChange={(e) => setNewShipping({ ...newShipping, name: e.target.value })}
            />

            {!isEdit && !isCard && (
              <p
                onClick={() => setIsSelectDestinations(true)}
                className="text blue underline pointer section-2"
              >
                {t("components.shipping.placeholder.2")}
              </p>
            )}

            <div className="row section-3">
              <InputNumber
                min={1}
                max={100}
                type="number"
                defaultValue={deliveryTimeValue({ index: 0 })}
                onChange={(value) => setStartTime(value)}
                style={{ width: "40%" }}
              />
              <p style={{ margin: "0 5px" }}>{t("to")}</p>
              <InputNumber
                min={1}
                max={100}
                type="number"
                defaultValue={deliveryTimeValue({ index: 1 })}
                onChange={(value) => setEndTime(value)}
                style={{ width: "40%" }}
              />
            </div>

            <Input
              value={newShipping.price}
              suffix={"$"}
              className="section-4"
              placeholder={t("components.shipping.placeholder.4")}
              onChange={(e) => setNewShipping({ ...newShipping, price: e.target.value })}
            />

            <Input
              value={newShipping.additionalProductPrice}
              suffix={"$"}
              className="section-4"
              placeholder={t("components.shipping.placeholder.5")}
              onChange={(e) => setNewShipping({ ...newShipping, additionalProductPrice: e.target.value })}
            />
          </div>
        </div>
        <button
          style={{ width: "300px", marginTop: "30px" }}
          onClick={() => (isEdit ? handleEdit() : handleCreate())}
          className="button big orange-back"
        >
          {t("confirm")}
        </button>
      </div>
    </Modal>
  );
};

export default CreateEditShipping;
