import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import {Modal} from '../../wrappers'
import Load from "../../utils/Load";

import "./style.scss";
import { productServices } from "../../services/products";

const CostEvolution = ({ product, setIsDisplayed, getProducts, getUser }) => {
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async () => {
    setLoad(true);
    productServices.updateShopifyProductCost({ productId: product._id }).then(async (res) => {
      if (res.status === 200) {
        await getProducts();
        await getUser();
        message.success(t("costEvolution.successfulUpdate"));
      } else {
        message.error(t("messages.error"));
      }
      setLoad(false);
      setIsDisplayed(false);
    });
  };

  const clcPriceDiff = (costEvolution) =>
    parseFloat(parseFloat(costEvolution.shopifyCost - costEvolution.realCost).toFixed(2));

  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000"
      visible={true}
      onCancel={() => {
        setIsDisplayed(false);
      }}
      footer={[
        <div className="row width-100 end">
          <button
            style={{ width: "150px" }}
            onClick={() => setIsDisplayed(false)}
            className="button big grey-back-2"
          >
            {t("cancel")}
          </button>
          <button
            style={{ width: "auto", marginLeft: "35px" }}
            onClick={handleSubmit}
            className="button big orange-back"
          >
            {t("updateShopifyCostEvolution")}
          </button>
        </div>,
      ]}
    >
      {load && <Load />}
      <div className="column start width-100">
        <p style={{ marginBottom: "35px" }} className="title montserrat-800">
          {t("costEvolution.title")}
        </p>
        <p style={{ marginBottom: "35px" }} className="text">
          {t("costEvolution.description")}
        </p>
        <div className="row space-between cost-evolution">
          <div className="column">
            <p className="title">{t("costEvolution.column1")}</p>
            {product.monitoring.costEvolutions.map((costEvolution) => (
              <p className="text">{costEvolution.shopifyVariantName}</p>
            ))}
          </div>
          <div className="column">
            <p className="title">{t("costEvolution.column2")}</p>
            {product.monitoring.costEvolutions.map((costEvolution) => (
              <p className="text">
                {costEvolution.shopifyCost ? "$" + costEvolution.shopifyCost : "undefined"}
              </p>
            ))}
          </div>
          <div className="column">
            <p className="title">{t("costEvolution.column3")}</p>
            {product.monitoring.costEvolutions.map((costEvolution) => (
              <p className="text">{costEvolution.realCost ? "$" + costEvolution.realCost : "undefined"}</p>
            ))}
          </div>
          <div className="column">
            <p className="title">{t("costEvolution.column4")}</p>
            {product.monitoring.costEvolutions.map((costEvolution) => (
              <p
                className={
                  clcPriceDiff(costEvolution) > 0
                    ? "text green"
                    : clcPriceDiff(costEvolution) < 0
                    ? "text red"
                    : "text"
                }
              >
                {clcPriceDiff(costEvolution) > 0 ? "-" : clcPriceDiff(costEvolution) < 0 && "+"} $
                {Math.abs(clcPriceDiff(costEvolution))}
              </p>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CostEvolution;
