import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article32/presentation-dropizi.jpg";
import step1 from "../../../../images/blog/global/article32/step1.JPG";
import step2 from "../../../../images/blog/global/article32/step2.JPG";
import step3 from "../../../../images/blog/global/article32/step3.JPG";
import step4 from "../../../../images/blog/global/article32/step4.JPG";
import step5 from "../../../../images/blog/global/article32/step5.JPG";
import step6 from "../../../../images/blog/global/article32/step6.JPG";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Créez votre boutique en dropshipping avec Dropizi
      </h1>
      <p className="text-300">3 december 2022 - 5 minutes read // 5min = 1k mots</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="creer boutique dropizi" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Si vous souhaitez débuter en dropshipping, <b>Dropizi est la référence française pour créer sa boutique en ligne en 2023</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Cette solution en ligne vous accompagne de A à Z via son interface faite pour les e-commerçants qui ne souhaitent pas gérer de stock, ni s’occuper de la logistique.
        Ils proposent même des formations tout au long de votre parcours afin de vous aider à générer vos premières ventes.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#tuto-dropshipping" title="Comment faire du dropshipping?" />
            <Anchor.Link href="#utiliser-dropizi" title="Comment utiliser Dropizi?">
              <Anchor.Link href="#creer-compte" title="Création de compte" />
              <Anchor.Link href="#interface" title="Interface" />
              <Anchor.Link href="#niche" title="Trouver une niche" />
              <Anchor.Link href="#accueil" title="Page d’accueil" />
              <Anchor.Link href="#apparence" title="Optimiser l’apparence de son site" />
            </Anchor.Link>
            <Anchor.Link href="#vendre-avec-dropizi" title="Comment vendre sur Dropizi?" >
            <Anchor.Link href="#parametrage" title="Paramétrage" />
            </Anchor.Link>
          </Anchor>
        }
      />
      <h2 id="tuto-dropshipping">Comment faire du dropshipping?</h2>
      <p style={{ marginBottom: "20px" }}>
        Le dropshipping consiste à <b>vendre un produit que l’on ne possède pas en stock</b>.
        En effet, c’est le fournisseur qui se chargera directement d’envoyer la commande pour vous, à votre client final.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Il s’agit donc de e-commerce classique, sans la partie logistique.
        Cela vous permet de ne pas faire de pertes sur les invendus, mais aussi de déléguer la charge de travail d’expédition des commandes à votre fournisseur.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Pour faire du dropshipping, les étapes sont assez simples</u>:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <li><a href="https://dropizi.fr/?afmc=atvrbj" target="blank_" class="blue" alt="créer boutique dropizi"><u>Créez une boutique avec Dropizi</u></a></li>
        <li>Ajoutez des produits grâce au catalogue 01supply</li>
        <li>Faites de la publicité ou du référencement naturel pour attirer vos premiers visiteurs</li>
        <li>Réalisez vos premières ventes et continuez d’améliorer vos publicités ainsi que votre boutique.</li>
        <li>Scaler en augmentant les budgets publicitaires ou en publiant plus de contenu pour votre référencement naturel. </li>
      </ol>
      <h2 id="utiliser-dropizi">Comment utiliser Dropizi?</h2>
      <p style={{ marginBottom: "20px" }}>
        Pour commencer à utiliser Dropizi, nous vous avons écrit un petit guide rapide et simple afin d’aller droit au but pour créer votre boutique en moins d’une journée.
      </p>
      <h3 id="creer-compte">Création de compte</h3>
      <a href="https://dropizi.fr/?afmc=atvrbj" target="blank_" alt="créer compte dropizi">
        <img src={step1} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="créer compte dropizi" />
      </a>
      <p style={{ marginBottom: "20px" }}>
        Tout d’abord, commencez par créer votre compte Dropizi. L’inscription est très rapide et se fait en une étape.
        En plus de cela, vous pouvez commencer sans carte de crédit.
      </p>
      <h3 id="interface">Interface</h3>
      <p style={{ marginBottom: "20px" }}>
        Une fois votre compté créé, vous arriverez sur cette page d’interface.
      </p>
      <img src={step2} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="interface dropizi" />
      <p style={{ marginBottom: "20px" }}>
        <u>Tout d’abord attardons nous sur le menu principal</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li><b>L’onglet “Produits”</b> vous permet de <b>gérer vos produits</b> et d’en <b>ajouter de nouveaux</b>.
          Vous pourrez même choisir d’importer des produits 01supply dans votre catalogue Dropizi grace à notre partenariat.</li>
        <li><b>L’onglet “Commandes”</b> vous permet de gérer et de <b>traiter vos commandes</b>.
          Mais aussi d’accéder à vos <b>statistiques</b> (ventes, visites, …) en liant votre compte Google Analytics directement à Dropizi.</li>
        <li><b>L’onglet “Marketing”</b> vous donne accès à plein de fonctionnalités très utiles pour <b>augmenter votre taux de conversion</b>.
          Vous pourrez en effet mettre en place des campagnes de mail, des barres d’urgence, des upsells, etc…</li>
        <li><b>L’onglet “IA”</b> est dédié aux outils d’intelligence artificielle.
          Dropizi propose en effet une <b>IA capable d’écrire vos fiches produits</b> pour vous, d’écrire des articles et même de vous donner des idées de contenu.</li>
        <li><b>L’onglet “SEO”</b> est obligatoire pour toute personne souhaitant <b>faire du référencement naturel</b>.
          Dropizi vous accompagne de A à Z pour optimiser votre boutique à 100%. </li>
        <li>Enfin, <b>l’onglet “Contenu”</b> vous permet d’accéder aux pages nécessitant du contenu.
          Comme par exemple la page d’accueil, le <b>blog</b> et d’autres pages annexes.</li>
      </ul>
      <h3 id="niche">Trouver une niche</h3>
      <img src={step3} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="trouver un niche dropshipping" />
      <p style={{ marginBottom: "20px" }}>
        Avant de commencer à mettre en place votre boutique, il est primordial de savoir dans quelle niche vous souhaitez vendre.
        <b>La niche correspond à un marché, à des acheteurs qui ont des habitudes d’achat</b> et que vous allez pouvoir cibler en ayant une niche précise.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour cela, encore une fois Dropizi fait fort grâce à son intelligence artificielle qui vous propose des idées de niche.
        Et le plus fort, c’est que c’est gratuit!
      </p>
      <h3 id="accueil">Page d’accueil</h3>
      <img src={step4} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="page d'accueil boutique dropizi" />
      <p style={{ marginBottom: "20px" }}>
        Maintenant que vous avez votre niche, il est temps de passer à la page d’accueil.
        Pour ce faire, il vous faudra tout d’abord une <b>bannière engageante</b>, qui présente l’offre de votre site et qui donne par la même occasion une idée claire de ce que vous vendez.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ensuite, pensez à rapidement <b>afficher vos premiers produits</b> afin d’engager le client vers un processus d’achat.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour finir, vous pouvez améliorer le tout en ajoutant des éléments de rassurance, des témoignages, des bons plans, etc…
      </p>
      <h3 id="apparence">Optimiser l’apparence de son site</h3>
      <img src={step5} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="apparence site dropizi" />
      <p style={{ marginBottom: "20px" }}>
        En parlant d’amélioration, vous pouvez aussi opter pour un autre design de site.
        Pour ce faire, il suffit de cliquer sur l’icone du crayon et de la règle à droite des menus.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous pourrez alors choisir un <b>design plus adapté</b> à votre audience et à votre niche.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Par la même occasion, c’est via cette page que vous pourrez ajouter un Logo à votre site.
        Si vous ne savez pas faire de logos, nous vous recommandons simplement de créer un logo “texte”.
        Sinon, vous pouvez faire appel à un graphiste sur un site comme Fiverr ou Comeup par exemple.
      </p>
      <h2 id="vendre-avec-dropizi">Comment vendre sur Dropizi?</h2>
      <p style={{ marginBottom: "20px" }}>
        Une fois votre site créé, il est temps d’y ajouter des produits.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour ce faire, nous vous recommandons de lier votre boutique Dropizi via votre <a href="https://app.01supply.com/fr/join-us?reseller" target="blank_" class="blue" alt="créer compte 01supply"><u>compte 01supply</u></a>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La marketplace 01supply référence <b>plus de 250.000 produits de créateurs locaux</b>, européens et américains prêts à être importés sur votre boutique Dropizi.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Le tout fonctionne à 100% en dropshipping et vos commandes Dropizi seront synchronisées sur 01supply et envoyées à vos fournisseurs.
      </p>
      <h3 id="parametrage">Paramétrage</h3>
      <p style={{ marginBottom: "20px" }}>
        Une fois vos produits publiés, il est temps de paramétrer votre boutique.
        Pour ce faire, rendez-vous dans les paramètres Dropizi en cliquant sur l’icone d’engrenage à droite du menu.
      </p>
      <img src={step6} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="paramétrer boutique dropizi" />
      <p style={{ marginBottom: "20px" }}>
        Remplissez toutes les informations une à une.
        Notamment les <b>modes de paiement</b> qui sont très importants si vous voulez recevoir l’argent de vos commandes.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Félicitations, votre boutique Dropizi est à présent prête à faire des ventes.
        Une question? Les Business Coachs de Dropizi sont disponibles pour vous accompagner et vous aider.
        Pensez à aussi jeter un œil à leurs vidéos de formations !
      </p>
    </div>
  );
};

export default Content;
