import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Input } from "antd";

import {Modal} from '../../../wrappers'
import EcoLabelIcon from "../../../images/icons/eco.svg";
import { sendGridEmail } from "../../../utils/SendGridEmail";
import { routes } from "../../../routes";

const EcoLabelForm = ({ user, setUser, updateUser, windowSize }) => {
  const [activeModal, setActiveModal] = useState(false);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [ecoLabel, setEcoLabel] = useState({
    verified: false,
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
  });
  const [newEdit, setNewEdit] = useState(false);

  const { t, i18n } = useTranslation();

  const editLabelContent = (questionNb, value) => {
    setEcoLabel({ ...ecoLabel, [questionNb]: value });
  };

  useEffect(() => {
    if (user && user.about && user.about.eco_label) {
      setEcoLabel({
        verified: false,
        question1: user.about.eco_label.question1,
        question2: user.about.eco_label.question2,
        question3: user.about.eco_label.question3,
        question4: user.about.eco_label.question4,
        question5: user.about.eco_label.question5,
      });
    }
  }, []);

  return (
    <div>
      <div onClick={() => setActiveModal(true)} className="row">
        <img src={EcoLabelIcon} style={{ marginRight: "10px", width: "35px", height: "auto" }} />
        <p
          className="pointer text-bold"
          style={{
            padding: "10px",
            borderRadius: "8px",
            color: "white",
            backgroundColor: user.about?.eco_label?.verified ? "rgb(211 211 211)" : "#7CB55A",
          }}
        >
          {user.about?.eco_label?.verified ? t("edit") : t("components.supplier.32")}{" "}
          {t("components.supplier.ecoLabel.1")}
        </p>
      </div>
      {activeModal && (
        <Modal
          className="modal-order background-landing"
          title={t("components.supplier.ecoLabel.2")}
          onOk={() => {}}
          visible={true}
          onCancel={() => setActiveModal(false)}
          footer={[
            <Button key="back" onClick={() => setActiveModal(false)}>
              {t("cancel")}
            </Button>,
          ]}
        >
          <div className="column">
            {user && user.about && user.about.eco_label && user.about.eco_label.verified === false && (
              <p
                style={
                  windowSize > 1150
                    ? { marginBottom: "30px", width: "60%", marginLeft: "20%", textAlign: "center" }
                    : { marginBottom: "30px", width: "90%", marginLeft: "5%", textAlign: "center" }
                }
                className="text-roboto-red"
              >
                {t("components.supplier.ecoLabel.3")}
              </p>
            )}

            {!newEdit &&
              user &&
              user.about &&
              user.about.eco_label &&
              user.about.eco_label.verified === true && (
                <>
                  <p
                    style={
                      windowSize > 1150
                        ? {
                            marginBottom: "30px",
                            width: "80%",
                            marginLeft: "10%",
                            textAlign: "center",
                            color: "#7CB55A",
                          }
                        : {
                            marginBottom: "30px",
                            width: "90%",
                            marginLeft: "5%",
                            textAlign: "center",
                            color: "#7CB55A",
                          }
                    }
                    className="title-roboto"
                  >
                    {t("components.supplier.ecoLabel.4")}
                    <br />
                    {t("components.supplier.ecoLabel.5")}
                    <Link
                      style={{ textDecoration: "underline", marginLeft: "5px", color: "#2c77e8" }}
                      target="_blank"
                      to={`${routes.supplierCertifHandmade(i18n.language).path}/${user.shop.name}`}
                    >
                      {t("components.supplier.ecoLabel.6")}
                    </Link>
                  </p>
                  <button
                    style={
                      windowSize > 1150
                        ? {
                            marginBottom: "10px",
                            marginTop: "20px",
                            width: "60%",
                            marginLeft: "20%",
                            textAlign: "center",
                            background: "#7CB55A",
                          }
                        : {
                            marginBottom: "10px",
                            marginTop: "20px",
                            width: "90%",
                            marginLeft: "5%",
                            textAlign: "center",
                            background: "#7CB55A",
                          }
                    }
                    onClick={() => setNewEdit(true)}
                    className="button orange-back"
                  >
                    {t("components.supplier.ecoLabel.7")}
                  </button>
                  <p
                    style={
                      windowSize > 1150
                        ? { marginBottom: "30px", width: "60%", marginLeft: "20%", textAlign: "center" }
                        : { marginBottom: "30px", width: "90%", marginLeft: "5%", textAlign: "center" }
                    }
                    className="text-roboto-red"
                  >
                    {t("components.supplier.ecoLabel.8")}
                  </p>
                </>
              )}

            {(newEdit ||
              !(user && user.about && user.about.eco_label && user.about.eco_label.verified === true)) && (
              <>
                <p className="title bold">{t("components.supplier.ecoLabel.9")}</p>
                <p className="text-300">{t("components.supplier.ecoLabel.10")}</p>
                <p className="text-300">{t("components.supplier.ecoLabel.11")}</p>
                <Input.TextArea
                  className="modal-textarea"
                  onChange={(e) => editLabelContent("question1", e.target.value)}
                  placeholder={t("components.supplier.25")}
                  defaultValue={user && user.about && user.about.eco_label && user.about.eco_label.question1}
                  style={{ marginTop: "10px" }}
                />

                <p style={{ marginTop: "30px" }} className="title bold">
                  {t("components.supplier.ecoLabel.12")} *
                </p>
                <p className="text-300">{t("components.supplier.ecoLabel.13")}</p>
                <Input.TextArea
                  className="modal-textarea"
                  onChange={(e) => editLabelContent("question3", e.target.value)}
                  placeholder={t("components.supplier.25")}
                  defaultValue={user && user.about && user.about.eco_label && user.about.eco_label.question3}
                  style={{ marginTop: "10px" }}
                />

                <p style={{ marginTop: "30px" }} className="title bold">
                  {t("components.supplier.ecoLabel.14")}
                </p>
                <p className="text-300">{t("components.supplier.ecoLabel.15")}</p>
                <Input.TextArea
                  className="modal-textarea"
                  onChange={(e) => editLabelContent("question2", e.target.value)}
                  placeholder={t("components.supplier.25")}
                  defaultValue={user && user.about && user.about.eco_label && user.about.eco_label.question2}
                  style={{ marginTop: "10px" }}
                />

                <p style={{ marginTop: "30px" }} className="title bold">
                  {t("components.supplier.ecoLabel.16")}
                </p>
                <p className="text-300">{t("components.supplier.ecoLabel.17")}</p>
                <Input.TextArea
                  className="modal-textarea"
                  onChange={(e) => editLabelContent("question4", e.target.value)}
                  placeholder={t("components.supplier.25")}
                  defaultValue={user && user.about && user.about.eco_label && user.about.eco_label.question4}
                  style={{ marginTop: "10px" }}
                />

                <p style={{ marginTop: "30px" }} className="title bold">
                  {t("components.supplier.ecoLabel.18")}
                </p>
                <p className="text-300">{t("components.supplier.ecoLabel.19")}</p>
                <Input.TextArea
                  className="modal-textarea"
                  onChange={(e) => editLabelContent("question5", e.target.value)}
                  placeholder={t("components.supplier.25")}
                  defaultValue={user && user.about && user.about.eco_label && user.about.eco_label.question5}
                  style={{ marginTop: "10px" }}
                />

                {((user && user.about && user.about.eco_label && user.about.eco_label.verified !== false) ||
                  !(user && user.about && user.about.eco_label)) && (
                  <>
                    <button
                      style={
                        windowSize > 1150
                          ? { marginTop: "40px", width: "60%", marginLeft: "20%", background: "#7CB55A" }
                          : { marginTop: "40px", width: "90%", marginLeft: "5%", background: "#7CB55A" }
                      }
                      className="button big"
                      onClick={() => {
                        updateUser({ user: { ...user, about: { ...user.about, eco_label: ecoLabel } } });
                        setUser({ ...user, about: { ...user.about, eco_label: ecoLabel } });
                        setActiveModal(false);
                        if (!ecoLabel.verified)
                          sendGridEmail(
                            "d-c8d2f4d2796f480aa87e0d06bc133510",
                            "contact.01supply@gmail.com",
                            {
                              subject: "Nouvelle demande de vérification eco label",
                              userEmail: user.email,
                            },
                            token
                          );
                      }}
                    >
                      {t("components.supplier.ecoLabel.20")}
                    </button>
                    <p
                      style={
                        windowSize > 1150
                          ? { marginTop: "10px", width: "60%", marginLeft: "20%", textAlign: "center" }
                          : { marginTop: "10px", width: "90%", marginLeft: "5%", textAlign: "center" }
                      }
                      className="text-roboto-red"
                    >
                      {t("components.supplier.ecoLabel.21")}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EcoLabelForm;
