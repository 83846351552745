import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";


const CourseSponso = ({ imagePath, imageTitle, price, title, description, coursePath }) => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ alignItems: "flex-start" }} className="row-wrap course-sponso-container">
      <div className="course-sponso-image-container">
        <img alt={imageTitle} className="course-sponso-image" src={imagePath} />
        <p className="course-sponso-image-title">{imageTitle}</p>
        <p className="course-sponso-image-price">{price}</p>
      </div>
      <div className="column course-sponso-content">
        <p style={{ fontSize: "15px" }} className="title-bold start">
          {title}
        </p>
        <p style={{ marginTop: "5px", marginBottom: "10px", fontSize: "14px" }} className="text-300 start">
          {description}
        </p>
        <Link to={coursePath} className="text-300 blue">
        {t("blog.3")}
        </Link>
      </div>
    </div>
  );
};

export default CourseSponso;
