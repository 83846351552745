import React from "react";
import {useTranslation} from 'react-i18next'
import { Radio, Space } from "antd";

const IsPublish = ({ setProduct, product }) => {

  const { t } = useTranslation()
  return (
    <div style={{ width: "100%" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        Status
      </p>
      <Radio.Group
        value={product.is_publish ? "Publish" : "Unpublish"}
        onChange={(e) => setProduct({ ...product, is_publish: e.target.value === "Publish" })}
      >
        <Space direction="vertical">
          <Radio value={"Publish"}>{t('publish')}</Radio>
          <Radio value={"Unpublish"}>{t('unpublish')}</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default IsPublish;
