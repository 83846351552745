import { message, Radio, Space, Input } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

import {Modal} from '../../wrappers'
import GenerateCsv from "./GenerateCsv";
import loadGif from "../../images/load2.gif";
import { productServices } from "../../services/products";
import Load from "../../utils/Load";

const ModalGenerateCsvProducts = ({
  setProducts,
  productsPage,
  productsSelect,
  windowSize,
  user,
  isExportDisplayed,
  setIsExportDisplayed
}) => {
  const [modalStep, setModalStep] = useState(
    window.location.search?.includes("csv-export") || isExportDisplayed ? "select products" : ""
  );
  const [allProducts, setAllProducts] = useState([]);
  const [productsToExport, setProductsToExport] = useState([]);
  const [cmsSelect, setCmsSelect] = useState("");
  const [productsGroupSelect, setProductsGroupSelect] = useState("all");
  const [csvGenerate, setCsvGenerate] = useState(false);
  const [shopName, setShopName] = useState("");
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (modalStep && !allProducts.length) {
      setLoad(true);
      productServices
        .findBySeller({ all: "true" })
        .then((res) => res.json())
        .then((userProducts) => {
          setAllProducts(userProducts.data);
          setProductsToExport(userProducts.data);
          setLoad(false);
        });
    }
  }, [modalStep]);

  useEffect(() => {
    if (isExportDisplayed) {
      setModalStep("select products");
    }
  }, [isExportDisplayed]);

  return (
    <div>
      {load && modalStep && <Load />}

      <Modal
        visible={modalStep}
        onCancel={() => {
          setModalStep("");
          setProductsToExport([]);
          setProductsGroupSelect("");
          setCmsSelect("");
          setIsExportDisplayed(false)
        }}
        footer={[]}
      >
        {!allProducts.length && !csvGenerate ? (
          <img src={loadGif} style={{ width: "100%", height: "auto" }} />
        ) : modalStep === "select products" ? (
          <div className="column">
            <p style={{ marginBottom: "20px" }} className="title">
              {t("components.generateCsv.2")}
            </p>
            <Radio.Group
              value={productsGroupSelect}
              onChange={(e) => {
                const value = e.target.value;

                if (!(value === "selected" && !productsSelect.length)) setProductsGroupSelect(value);

                if (value === "selected" && !productsSelect.length)
                  message.error(t("messages.selectProductToExport"));
                else if (value === "all") setProductsToExport(allProducts);
                else if (value === "page") setProductsToExport(productsPage);
                else if (value === "selected")
                  setProductsToExport(allProducts.filter((product) => productsSelect.includes(product._id)));
              }}
            >
              <Space direction="vertical">
                <Radio value={"all"}>
                  {t("components.generateCsv.3")} ({allProducts.length})
                </Radio>
                {window.location.href.includes("inventory") && (
                  <Radio value={"page"}>
                    {t("components.generateCsv.4")} ({productsPage.length})
                  </Radio>
                )}
                <Radio value={"selected"}>
                  {t("components.generateCsv.5")} ({productsSelect.length})
                </Radio>
              </Space>
            </Radio.Group>
            <button
              style={{ width: "150px", marginTop: "20px", fontSize: "18px" }}
              className="button big orange-back"
              onClick={() => {
                if (!productsToExport.length) message.error(t("messages.selectProductToExport"));
                else setModalStep("select cms");
              }}
            >
              {t("components.generateCsv.6")}
            </button>
          </div>
        ) : modalStep === "select cms" ? (
          <div style={{ marginBottom: "20px" }} className="column">
            <p style={{ marginBottom: "20px" }} className="title">
              {t("components.generateCsv.7")}
            </p>
            <Radio.Group
              value={cmsSelect}
              onChange={(e) => {
                const value = e.target.value;
                setCmsSelect(value);
              }}
            >
              <Space direction="vertical">
                <div style={{ alignItems: "flex-start" }} className="row">
                  <Radio value={"squarespace"}>SquareSpace</Radio>
                  {cmsSelect === "squarespace" && (
                    <div className="column">
                      <Input
                        onChange={(e) => setShopName(e.target.value.replaceAll("/", ""))}
                        placeholder="SquareSpace slugs URL"
                      />
                      <a
                        style={{ fontSize: "14px" }}
                        className="text-roboto blue underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.squarespace.com/hc/fr-fr/articles/205814578"
                      >
                        {t("components.generateCsv.8")}
                      </a>
                    </div>
                  )}
                </div>
                <Radio value={"wix"}>Wix</Radio>
                <Radio value={"prestashop"}>PrestaShop</Radio>
                <Radio value={"other"}>{t("other")}...</Radio>
              </Space>
            </Radio.Group>
            <button
              onClick={() => {
                if (!cmsSelect) message.error(t("messages.needFill.cms"));
                else if (cmsSelect === "squarespace" && !shopName) message.error(t("messages.slugUrl"));
                else setModalStep("generate");
              }}
              style={{ width: "150px", marginTop: "20px", fontSize: "18px" }}
              className="button big orange-back"
            >
              {t("components.generateCsv.6")}
            </button>
          </div>
        ) : (
          modalStep === "generate" && (
            <GenerateCsv
              shopName={shopName}
              allProducts={allProducts}
              setCsvGenerate={setCsvGenerate}
              setProducts={setProducts}
              user={user}
              windowSize={windowSize}
              type={cmsSelect}
              products={productsToExport}
            />
          )
        )}
      </Modal>
    </div>
  );
};

export default ModalGenerateCsvProducts;
