import React from "react";

import { Modal } from "../../../../../wrappers";

import Shopify from "./Shopify";
import WizishopDropizi from "./WizishopDropizi";
import OtherShop from "./Other";

import "./style.scss";
import { useTranslation } from "react-i18next";

const ConnectShop = ({ isDisplayed, onCancel, user, setUser }) => {
  const { t } = useTranslation();
  return (
    <Modal className="connect-shop-modal" footer={[]} title="" visible={isDisplayed} onCancel={onCancel}>
      <div style={{ alignItems: "center", justifyContent: "center" }} className="row-wrap center width-100">
        <p style={{ marginBottom: "15px" }} className="title-bold">
          {t("components.reseller.modalNeedShop.12")}
        </p>
        <Shopify />
        <WizishopDropizi cancelModal={onCancel} setUser={setUser} cms="WiziShop" />
        <WizishopDropizi cancelModal={onCancel} setUser={setUser} cms="DropIzi" />
        <OtherShop onCancel={onCancel} setUser={setUser} user={user} />
      </div>
    </Modal>
  );
};

export default ConnectShop;
