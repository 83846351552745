import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { Link, Redirect } from "react-router-dom";
import { Input, message } from "antd";
import { header } from "../../api/header";
import { url } from "../../api/url";
import { getImage } from "../../utils/GetImage";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import UploadImage from "../../components/UploadSimpleImage";
import { updateUser } from "../../services/updateUser";

const Onboarding1 = () => {
  const [user, setUser] = useState({});
  const [isChange, setIsChange] = useState(false);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [profilePicture, setProfilePicture] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [isNext, setIsNext] = useState(false);

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const nextCondition = user.firstName && user.lastName;

  const totalSteps = typeAccount === "creater" ? 5 : 3

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
    setProfilePicture(resJson.data?.profile_picture);
  };

  useEffect(() => {
    updateProfilePicture();
  }, [imageUpload]);

  const updateProfilePicture = async () => {
    if (imageUpload.base64) {
      const res = await fetch(`${url}/user/picture/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          picture: imageUpload,
          lastImageId: user.profile_picture,
          imageType: "profile_picture",
        }),
      });
      if (res.status === 200) {
        message.success(t('messages.profilePictureUpdated'));
        setProfilePicture(imageUpload.base64);
        setImageUpload({});
      }
    }
  };

  const sendUpdateUser = () => {
    updateUser({user:{ ...user }}, idAccount, secretId, token, () => setIsNext(true));
  };

  return (
    <div className="page">
      {isNext && (
        <Redirect to={`/${typeAccount === "creater" ? "supplier" : "reseller"}-onboarding-step-2`} />
      )}
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "0px",
                }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingBottom: "200px",
                }
          }
          className="column-scrolling your-information"
        >
          <div
            style={{ paddingTop: "20px", paddingBottom: "20px", marginBottom: "50px", height: "80px" }}
            className="header"
          >
            <h1 className="title-bold montserrat">{t('onboarding.1')} (1/{totalSteps})</h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "40px",
              alignItems: "flex-start",
            }}
          >
            <div
              id="demo-step-5"
              className="column"
              style={
                windowSize > 1320
                  ? { width: "100%", marginLeft: "30px" }
                  : { width: "100%", marginTop: "30px" }
              }
            >
              {/* DETAILS */}
              <div
                id="content"
                style={{ marginLeft: "0", width: "95%", padding: "15px 20px 30px 20px", marginTop: "0px" }}
                className="column center card"
              >
                <div
                  style={{ alignItems: "center", alignItems: "center", marginBottom: "30px" }}
                  className="column"
                >
                  <div
                    key={profilePicture}
                    style={{ display: profilePicture ? "flex" : "none", marginBottom: "20px" }}
                  >
                    <UploadImage image={profilePicture} setImage={setImageUpload} />
                  </div>
                  {!profilePicture &&
                  <button
                    style={{ width: "150px", marginLeft: profilePicture ? "20px" : "0" }}
                    className="button-orange"
                    onClick={() => document.getElementById("upload-profile-image").click()}
                  >
                    {t('upload')} photo
                  </button>}
                </div>
                <div
                  style={{
                    width: windowSize > 1150 ? "42.5%" : "100%",
                  }}
                  className="column"
                >
                  <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('firstName')}
                  </p>
                  <Input
                    style={{ width: "100%" }}
                    className="little-input border-red"
                    value={user.firstName}
                    placeholder={t('firstName')}
                    onChange={(e) => {
                      setUser({ ...user, firstName: e.target.value });
                      if (!isChange) setIsChange(true);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: windowSize > 1150 ? "42.5%" : "100%",
                    marginTop: "20px",
                  }}
                  className="column"
                >
                  <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t('lastName')}
                  </p>
                  <Input
                    className="little-input border-red"
                    maxLength={40}
                    value={user && user.lastName}
                    style={{ width: "100%" }}
                    placeholder={t('name')}
                    onChange={(e) => {
                      setUser({ ...user, lastName: e.target.value });
                      if (!isChange) setIsChange(true);
                    }}
                  />
                </div>

                <div style={{ marginTop: "35px" }} className="row">
                  <button
                    onClick={sendUpdateUser}
                    style={{ marginRight: "10px" }}
                    className="button big grey disable"
                  >
                    {t('onboarding.5')}
                  </button>
                  {nextCondition && (
                    <button
                      style={{ marginLeft: "10px" }}
                      onClick={sendUpdateUser}
                      className="button big orange-back"
                    >
                    {t('onboarding.6')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding1;
