import React, { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next'
import { url } from "../../api/url";
import { header } from "../../api/header";
import Menu from "../../components/menu/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../utils/RedirectLogin";
import TabPayments from "../../components/supplier/payments/Tab";

const MyPayments = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [paypalPayments, setPaypalPayments] = useState();

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    findPayments();
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  const findPayments = async () => {
      try{
        const res = await fetch(`${url}/paypal/paypal-payments/${idAccount}`, {
          method: "GET",
          credentials: "include",
          headers: {
            ...header,
            authorization: token,
          },
        });
        if (res.status === 200) {
          const resJson = await res.json();
          setPaypalPayments(resJson.paypalPayments);
        }
      } catch {

      }
  };

  return (
    <div className="page">
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? (
          <Menu location={t('myPayments.1')} />
        ) : (
          <MobileMenu load={false} />
        )}
        <div style={windowSize < 1150 ? {paddingLeft: '5vw', paddingRight: '5vw'} : {}} className="column-scrolling">
          {windowSize > 1150 ? (
            <Header location={t('myPayments.1')} />
          ) : (
            <MobileHeader
              load={false}
              location={t('myPayments.1')}
              nameHeader={t('myPayments.1')}
            />
          )}

          <div className="red-container">
            $
            {paypalPayments && paypalPayments.net_due
              ? parseFloat(paypalPayments.net_due).toFixed(2)
              : "0"}
            <p
              style={{ color: "grey", fontSize: "16px" }}
              className="title-300"
            >
              {t('myPayments.2')}
            </p>
          </div>

          <p
            style={{ fontSize: "14px", textAlign: "start", width: '70vw', marginBottom: "30px", marginTop: '20px' }}
          >
            {t('myPayments.3')}
            <span style={{marginLeft: '5px'}} className="blue underline pointer" onClick={() => window.tidioChatApi.open()}>
              {t('here')}
            </span>
          </p>

          <TabPayments paypalPayments={paypalPayments} />
        </div>
      </div>
    </div>
  );
};

export default MyPayments;
