import { url } from "../api/url";

export const getImage = async (id, maxSize) => {
  if (!id) {
    return "";
  }

  if (id?.includes("http")) {
    // is image url, not id
    return id;
  }

  const res = await fetch(`${url}/product/image?id=${id}&max_size=${maxSize}`);
  const resJson = await res.json();
  if(typeof resJson === "string"){
    return resJson
  } else if (resJson && resJson.data && resJson.data.base64) {
    if (
      (!resJson.data.base64.includes("data:image") && !resJson.data.base64.includes("http")) ||
      resJson.data.base64.split("").length > 1000
    ) {
      if (!resJson.data.name_file) {
        return `${resJson.data.base64}`;
      } else if (resJson.data.name_file && resJson.data.name_file.includes("svg"))
        return `data:image/svg+xml;base64,${resJson.data.base64}`;
      else if (resJson.data.name_file.includes("jpeg"))
        return `data:image/jpeg;base64,${resJson.data.base64}`;
      else if (resJson.data.name_file.includes("jpg")) return `data:image/jpg;base64,${resJson.data.base64}`;

      return `data:image/png;base64,${resJson.data.base64}`;
    } else {
      return `${resJson.data.base64}`;
    }
  } else return resJson.data || "";
};
