import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Banner from "../../../components/courses/Banner";
import Product from "../../../components/courses/Product";
import Items from "../../../components/courses/Items";
import BuyBanner from "../../../components/courses/BuyBanner";
import Faq from "../../../components/courses/Faq";
import Footer from "../../../components/menu/Footer";
import { coursesId } from "../../../constants/coursesId";

import bannerImg from "../../../images/courses/supplier/course2/banner.svg";
import productImg from "../../../images/courses/supplier/course2/course2.png";
import item1Img from "../../../images/courses/supplier/course2/icon1.svg";
import item2Img from "../../../images/courses/supplier/course2/icon2.svg";
import item3Img from "../../../images/courses/supplier/course2/icon3.svg";
import item4Img from "../../../images/courses/supplier/course2/icon4.svg";
import item5Img from "../../../images/courses/supplier/course2/icon5.svg";
import item6Img from "../../../images/courses/supplier/course2/icon6.svg";
import pcImg from "../../../images/courses/supplier/course2/pc.svg";

import supplerAcademyLogo from "../../../images/icons/01supply_academy.svg";

const Course2 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="course-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Optimize SEO product descriptions for sales | 01supply Academy</title>
        <meta
          name="description"
          content="Learn how to optimize your product descriptions thanks to 10 secret SEO techniques. 
          Description templates are included, for those who don't like to write."
        />
      </Helmet>
      <div className="course-navbar">
        <img style={{ width: "180px" }} src={supplerAcademyLogo} alt="01supply academy" />
      </div>
      <Banner
        title={
          <h1 style={{ fontSize: "45px", textShadow: "#3C3C3C 1px 0 5px" }} className="white title-600 start">
            10 Techniques
            <br /> of a Powerful
            <br />
            <span style={{ color: "#FBA95E" }}>SEO Description</span>
            <br />
            that will X2 your sales
          </h1>
        }
        text={
          <p style={{ fontSize: "16px", textShadow: "#1c1c1c 2px 0 5px"}} className="orange text-300">
            With templates for those who don’t like to write
          </p>
        }
        img={bannerImg}
        alt="how to write optimized seo product descriptions"
      />
      <Product
        text1={"With 10 specific SEO techniques, you can double your sales in the next weeks. "}
        text2={`SEO allows you to acquire visitors for free. It is basically free advertising offered by Google. Search engines have some advanced algorythm you can trick to get more visibility and sales online.`}
        difficult={"easy"}
        img={productImg}
        price={"$99"}
        alt="write converting seo descriptions"
        courseId={coursesId.supplier.course2}
      />
      <h2 className="course-black-banner">
      Turn you product descriptions into free acquisition machines
      </h2>
      <Items
        img1={item1Img}
        title1={`A great SEO description increases sales and visits.`}
        text1={`SEO is optimizing for user experience which is a underrated virtuous circle.`}
        img2={item2Img}
        title2={`SEO is the N°1 acquisition channel with the least competition.`}
        text2={`A lot of e-commerce owners do Facebook advertising. But most of the customers search via Google.`}
        img3={item3Img}
        title3={`SEO Mastery`}
        text3={`Learn everything about SEO theory to acquire a new online marketing skill.`}
        price1={"$39"}
        img4={item4Img}
        title4={`Multiple Templates`}
        text4={`Each template will help you write your product descriptions if you don’t to write.`}
        price2={"$19"}
        img5={item5Img}
        title5={`The Art of Copywriting`}
        text5={`Copywriting is the art of turning simple visitors into buyers with words.`}
        price3={"$49"}
        img6={item6Img}
        title6={`10 secret SEO techniques`}
        text6={`10 SEO techniques that no one uses and can will make your products rank first.`}
        price4={"$79"}
      />
      <BuyBanner
        title={
          <h2
            style={{ fontWeight: "900", fontSize: "30px" }}
            className="title-bold montserrat start width-100 white"
          >
            Everything <br />
            in one course
          </h2>
        }
        img={pcImg}
        alt="seo product
        description 
        course"
        list={
          <ul>
            <li className="text-bold">
            SEO Mastery:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $39
              </span>
            </li>
            <li className="text-bold">
            Multiple Templates:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $19
              </span>
            </li>
            <li className="text-bold">
            The Art of Copywriting{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $49
              </span>
            </li>
            <li className="text-bold">
            10 secret SEO techniques:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $79
              </span>
            </li>
          </ul>
        }
        price={"$99"}
        previousPrice={"$186"}
        courseId={coursesId.supplier.course2}
      />
      <Faq />
      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Course2;
