import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RollbackOutlined, LeftOutlined } from "@ant-design/icons";
import VideoModal from "../../modals/VideoModal";

const SimpleHeader = ({ nameHeader, location, product, sectionHeader, setSectionHeader }) => {
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));

  return (
    <div className="header">
      <div style={{ width: "100%", justifyContent: "center" }} className="row">
        <h4 className="title-header text-ellipsis">{nameHeader}</h4>
      </div>
      {location === "SOURCING" && (
        <VideoModal
          text={"Learn how it works"}
          url={"https://www.youtube.com/embed/DNmF8eeroBo"}
          style={{ marginTop: "15px" }}
        />
      )}
      <div style={{ marginTop: "35px" }} className="divider-horizontal" />
      <div style={{ marginBottom: "15px" }} className="row-wrap">
        {location === "product-detail" &&
          product.tag.map((tag) => {
            if (tag)
              return (
                <Link
                  to={`/marketplace?tag=${tag}`}
                  style={{
                    padding: "3px 5px 3px 5px",
                    cursor: "pointer",
                    marginRight: "5px",
                    marginLeft: "5px",
                    margin: "5px",
                    width: "auto",
                    maxWidth: "auto",
                  }}
                  className="button tag"
                >
                  {tag}
                </Link>
              );
          })}
      </div>
      {(location === "YOUR SHOP" ||
        location === "PLANS" ||
        location === "DOCUMENTATION" ||
        location === "YOUR INFORMATIONS" ||
        location === "BOOST" ||
        location === "TOOLS" ||
        location === "SETTINGS" ||
        location === "PRICING SETTINGS") && (
        <div style={{ alignItems: "center", justifyContent: "center", width: "100%" }} className="column">
          <div style={{ alignItems: "center", justifyContent: "center" }} className="row header-setting">
            <Link to="/mobile-setting-menu">
              <RollbackOutlined style={{ fontSize: "25px", marginRight: "8px" }} />
            </Link>
            <Link to="/mobile-setting-menu">
              <p style={{fontSize: "16px"}} className="title-200">SETTINGS</p>
            </Link>
            <LeftOutlined style={{ marginLeft: "5px", marginRight: "5px" }} />
            <p
              style={location === "YOUR INFORMATIONS" ? { fontSize: "14px" } : { fontSize: "16px" }}
              className="title"
            >
              {location}
            </p>
          </div>
          <div style={{ marginTop: "15px" }} className="divider-horizontal" />
        </div>
      )}
    </div>
  );
};

export default SimpleHeader;
