import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { Input, Button, message } from "antd";

import { Modal } from "../../wrappers";
import AffiliationStatsCard from "../affiliation/StatsCard";

const ManageAffiliation = ({ users }) => {
  const [affiliations, setAffiliations] = useState([]);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));
  const [userAffiliations, setUserAffiliations] = useState([]);
  const [modalOn, setModalOn] = useState(false);
  const [modalPaidPayout, setModalPaidPayout] = useState("");
  const [paidAffiliation, setPaidAffiliation] = useState({
    amount: "",
    destination: "",
    code: "",
    date: new Date().toLocaleDateString(),
  });

  useEffect(() => {
    getAffiliations();
  }, []);

  const getAffiliations = async () => {
    const res = await fetch(`${url}/admin/find-affiliations/${adminId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setAffiliations(resJson.affiliations.sort((a, b) => (a.payout_pending ? a : b)));
    } else message.error("no sourcing requests");
  };

  const paidAffiliationF = async (affiliationId, index) => {
    const res = await fetch(`${url}/admin/paid-affiliation/${adminId}/${affiliationId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        payout: paidAffiliation,
      }),
    });
    if (res.status === 200) {
      setPaidAffiliation({
        amount: "",
        destination: "",
        code: "",
        date: new Date().toLocaleDateString(),
      });
      setModalPaidPayout("");
      const newAffiliations = affiliations.map((affi) => {
        if (affi._id === affiliationId) {
          return { ...affi, payout_pending: false, total_available_payout: "0" };
        } else return affi;
      });
      setAffiliations([...newAffiliations]);
      message.success("Status updated !");
    } else message.error("error");
  };

  return (
    <>
      <button className="button orange-back" onClick={() => setModalOn(true)}>
        MANAGE AFFILIATIONS
      </button>

      {modalPaidPayout && (
        <Modal
          visible={true}
          onCancel={() => {
            setPaidAffiliation({
              amount: "",
              destination: "",
              code: "",
              date: new Date().toLocaleDateString(),
            });
            setModalPaidPayout("");
          }}
          footer={[]}
        >
          <div className="column">
            <Input
              suffix="$"
              placeholder="Amount"
              onChange={(e) => setPaidAffiliation({ ...paidAffiliation, amount: e.target.value })}
            />
            <Input
              style={{ marginTop: "10px", marginBottom: "10px" }}
              placeholder="Destination"
              onChange={(e) => setPaidAffiliation({ ...paidAffiliation, destination: e.target.value })}
            />
            <Input
              placeholder="Identification"
              onChange={(e) => setPaidAffiliation({ ...paidAffiliation, code: e.target.value })}
            />
            <button
              style={{ marginTop: "10px" }}
              className="button orange-back"
              onClick={() => paidAffiliationF(modalPaidPayout)}
            >
              Send payout
            </button>
          </div>
        </Modal>
      )}

      {modalOn && (
        <Modal
          className="modal-1000"
          visible={true}
          onCancel={() => setModalOn(false)}
          footer={[
            <Button key="back" onClick={() => setModalOn(false)}>
              Cancel
            </Button>,
          ]}
        >
          {users.length > 0 &&
            affiliations.map((affiliation, index) => {
              const user =
                users && users.length
                  ? users.find((user) => user.user?._id === affiliations[index].user_id)?.user || {}
                  : {};
              return (
                <div
                  className="column"
                  style={{
                    marginBottom: "20px",
                    border: "1px solid grey",
                    borderRadius: "6px",
                    padding: "10px",
                  }}
                >
                  {affiliation.payout_pending && (
                    <p style={{ fontSize: "18px" }} className="title-red">
                      Payout pending !
                    </p>
                  )}
                  <p className="title">Email: {user.email}</p>
                  <p className="title">Total earned: ${affiliation.total_earned || 0}</p>
                  <p className="title green">
                    Total payout available: ${affiliation.total_available_payout || 0}
                  </p>
                  <AffiliationStatsCard affiliations={affiliation} />
                  {users && users.length && (
                    <Link
                      style={{ marginTop: "10px" }}
                      className="button orange-back"
                      to={`/connect?token=${localStorage.getItem("tokenCreatosell")}&id=${
                        affiliation.user_id
                      }&type=${user.type}&email=${user.email}&secret_id=${user.secret_id}`}
                    >
                      Login to account
                    </Link>
                  )}
                  {affiliation.total_available_payout && (
                    <button
                      style={{ marginTop: "10px" }}
                      onClick={() => setModalPaidPayout(affiliation._id, index)}
                      className="button orange-back"
                    >
                      Send payout
                    </button>
                  )}
                </div>
              );
            })}
        </Modal>
      )}
    </>
  );
};

export default ManageAffiliation;
