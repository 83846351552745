import React from "react";
import { useTranslation } from "react-i18next";

const DownloadCard = ({ windowSize }) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      <div className={windowSize > 1330 ? "row width-100 space-between" : "width-100 column"}>
        <div className="column start" style={{ width: windowSize > 1330 ? "calc(100% - 580px)" : "100%" }}>
          <h1 style={{ fontSize: "24px" }} className="title montserrat-800 start">
            {t("extension.title.1")} <span className="purple">{t("extension.title.2")}</span>{" "}
            {t("extension.title.3")}
          </h1>
          <p>{t("extension.description")}</p>
          <p>{t("extension.description2")}</p>
          <a
            style={{ marginTop: "35px" }}
            href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
            target="_blank"
          >
            <button className="button big orange-back">{t("extension.button")}</button>
          </a>
        </div>
        <div style={windowSize <= 1330 ? { marginTop: "35px" } : {}}>
          <iframe
            className="video-iframe"
            src={"https://www.youtube.com/embed/aGou-PIZS-s"}
            title={"How does 01supply work"}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default DownloadCard;
