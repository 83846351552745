import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
import { Input, message } from "antd";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import { header } from "../../api/header";
import { url } from "../../api/url";

const SupplierService = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [choiceSelect, setChoiceSelect] = useState("");
  const [disbleInput, setDisableInput] = useState(false);
  const [shop, setShop] = useState("");
  const [email, setEmail] = useState("");
  const [token] = useState(localStorage.getItem("tokenCreatosell"));

  const { t } = useTranslation()

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const sendRequest = async () => {
    if (!email) {
      message.error(t('messages.needFill.email'));
    } else {
      const res = await fetch(`${url}/sendgrid/template-email`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          emailToSend: "contact.01supply@gmail.com",
          templateId: "d-ad3f3fe9ed1f451291cf62c835a7809c",
          templateData: {
            shop_url: shop,
            email: email,
          },
        }),
      });
      if (res.status === 200) {
        message.success(
          t('messages.successfullyContacted'),
          10
        );
        setDisableInput(true);
      }
    }
  };

  return (
    <div className="page">
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div className="column-scrolling">
          {windowSize > 1150 ? (
            <Header location={"WE DO IT FOR YOU"} />
          ) : (
            <MobileHeader
              nameHeader={"WE DO IT FOR YOU"}
              location="WE DO IT FOR YOU"
            />
          )}
          <div className="column" style={windowSize > 1150 ? { width: "100%", paddingBottom: '100px' } : {width: '90vw', marginLeft: '5vw', paddingBottom: '100px'}}>
            <h2 style={{ fontSize: "30px" }} className="title">
              We will list your products for you !
            </h2>
            <p
              style={{ textAlign: "start", marginBottom: "20px" }}
              className="text-300 grey"
            >
              We know it is a lot of work to list products online. This is why
              we can do it for you for free.
              <br />
              <span className="text-bold">Just fill the following form :</span>
            </p>
            <p style={{ marginBottom: "10px" }} className="title-300 grey">
              Are you products on an Etsy store ?
            </p>
            <div
              style={{ borderRadius: "6px", border: "1px solid grey" }}
              className="row"
            >
              <div
                onClick={() => {
                  setChoiceSelect("yes");
                  setDisableInput(false);
                }}
                style={{
                  borderRight: "1px solid grey",
                  width: "70px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                className="title-300 column center"
              >
                Yes
              </div>
              <div
                onClick={() => {
                  setChoiceSelect("no");
                  setDisableInput(false);
                }}
                style={{ width: "70px", padding: "10px", cursor: "pointer" }}
                className="title-300 column center"
              >
                No
              </div>
            </div>
            {choiceSelect === "yes" && (
              <div style={{ marginTop: "20px" }} className="column">
                <p style={{ marginBottom: "10px" }} className="title-300 grey">
                  Awesome !
                </p>
                <p
                  style={{ textAlign: "start", marginBottom: "20px" }}
                  className="text-300"
                >
                  Just
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.etsy.com/hc/en-us/articles/360000343508-How-to-Download-Your-Listing-Information?segment=selling"
                    style={{
                      marginLeft: "6px",
                      marginRight: "6px",
                      textDecoration: "underline",
                    }}
                    className="text-300"
                  >
                    download your Etsy CSV
                  </a>
                  file and upload it thanks to this button :
                </p>
                <Link to="/import-products-csv" className="button orange-back">
                  {t('upload').toUpperCase()} CSV
                </Link>
              </div>
            )}
            {choiceSelect === "no" && (
              <div style={{ marginTop: "20px" }} className="column">
                <p style={{ marginBottom: "10px" }} className="title-300 grey">
                  Send us more information about you
                </p>
                <p
                  style={{
                    textAlign: "start",
                    marginBottom: "5px",
                    fontSize: "20px",
                  }}
                  className="title-bold"
                >
                  Your shop URL
                </p>
                <Input
                    disabled={disbleInput}
                  onPressEnter={sendRequest}
                  style={{ width: "90vw", maxWidth: "400px" }}
                  placeholder="https://www.my-shop.com"
                  onChange={(e) => setShop(e.target.value)}
                />
                <p
                  style={{
                    textAlign: "start",
                    marginBottom: "5px",
                    marginTop: "20px",
                    fontSize: "20px",
                  }}
                  className="title-bold"
                >
                  Your email *
                </p>
                <Input
                    disabled={disbleInput}
                  onPressEnter={sendRequest}
                  style={{
                    marginBottom: "30px",
                    width: "90vw",
                    maxWidth: "400px",
                  }}
                  placeholder="myemail@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!disbleInput &&
                <button onClick={() => sendRequest()} className="button orange-back">
                  REQUEST SUPPORT
                </button>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierService;
