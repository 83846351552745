import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const checkStock = ({ product }) => {
  let isProductInStock = true;
  if (product.variants?.length && product.variantBool) {
    product.variants.forEach((variant) => {
      if (!variant.stock || variant.stock === "0") {
        isProductInStock = false;
      }
    });
  } else if (!product.stock) {
    isProductInStock = false;
  }

  return isProductInStock;
};

const MonitoringCard = ({
  product,
  setIsProductLinkDisplayed,
  setIsCostEvolutionDisplayed,
  setIsStockEvolutionDisplayed,
}) => {
  const [monitoring, setMonitoring] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setMonitoring({
      isExist: !product.isDelete,
      isInStock: checkStock({ product }),
      isVariantSync: !product.monitoring || product.monitoring?.variantSync,
      isOrderSync: !product.monitoring || product.monitoring?.orderSync,
      isCostSync: !product.monitoring || product.monitoring?.costSync,
      isVariantSyncDisplay: !product.monitoring || "variantSync" in product.monitoring,
      costEvolutions: product.monitoring?.costEvolutions || [],
    });
  }, [product]);

  return (
    <div className="card monitoring">
      {monitoring.isExist ? (
        <div className="row">
          <div style={{ marginRight: "10px" }} className="green-puce" />
          <p>{t("monitoring.exist.true")}</p>
        </div>
      ) : (
        <div onClick={() => setIsProductLinkDisplayed(true)} className="row pointer underline">
          <div style={{ marginRight: "10px" }} className="red-puce" />
          <p>{t("monitoring.exist.false")}</p>
        </div>
      )}
      {monitoring.isInStock ? (
        <div style={{ marginTop: "10px" }} className="row">
          <div style={{ marginRight: "10px" }} className="green-puce" />
          <p>{t("monitoring.inStock.true")}</p>
        </div>
      ) : (
        <div
          onClick={() => setIsStockEvolutionDisplayed(true)}
          style={{ marginTop: "10px" }}
          className="row pointer underline"
        >
          <div style={{ marginRight: "10px" }} className="red-puce" />
          <p>{t("monitoring.inStock.false")}</p>
        </div>
      )}
      {monitoring.isVariantSync && monitoring.isVariantSyncDisplay ? (
        <div style={{ marginTop: "10px" }} className="row">
          <div style={{ marginRight: "10px" }} className="green-puce" />
          <p>{t("monitoring.variantSync.true")}</p>
        </div>
      ) : (
        monitoring.isVariantSyncDisplay && (
          <div
            onClick={() => setIsProductLinkDisplayed(true)}
            style={{ marginTop: "10px" }}
            className="row pointer underline"
          >
            <div style={{ marginRight: "10px" }} className="red-puce" />
            <p>{t("monitoring.variantSync.false")}</p>
          </div>
        )
      )}
      {monitoring.isOrderSync ? (
        <div style={{ marginTop: "10px" }} className="row">
          <div style={{ marginRight: "10px" }} className="green-puce" />
          <p>{t("monitoring.orderSync.true")}</p>
        </div>
      ) : (
        <div
          onClick={() => setIsProductLinkDisplayed(true)}
          style={{ marginTop: "10px" }}
          className="row pointer underline"
        >
          <div style={{ marginRight: "10px" }} className="red-puce" />
          <p>{t("monitoring.orderSync.false")}</p>
        </div>
      )}
      {monitoring.isCostSync ? (
        <div style={{ marginTop: "10px" }} className="row">
          <div style={{ marginRight: "10px" }} className="green-puce" />
          <p>{t("monitoring.costSync.true")}</p>
        </div>
      ) : (
        monitoring.isCostSync !== undefined &&
        monitoring.isVariantSync && (
          <div
            onClick={() => setIsCostEvolutionDisplayed(true)}
            style={{ marginTop: "10px" }}
            className="row pointer underline"
          >
            <div style={{ marginRight: "10px" }} className="red-puce" />
            <p>{t("monitoring.costSync.false")}</p>
          </div>
        )
      )}
    </div>
  );
};

export default MonitoringCard;
