import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { url } from "../api/url";
import { header } from "../api/header";
import { Link } from "react-router-dom";
import Header from "../components/menu/marketplaceNavbar";
import { Helmet } from "react-helmet";
import Footer from "../components/menu/Footer";
import Load from "../utils/Load";

import RegisterModal from "../components/modals/RegisterModal";
import ProductCart from "../components/ProductCart";
import ShippingInformations from "../components/ShippingInformations";
import cartImg from "../images/cart.svg";
import logo from "../images/logo.svg";
import ResellerSetupProgress from '../components/reseller/SetupProgress'

const Cart = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [popupLogin, setPopupLogin] = useState("");
  const [cart, setCart] = useState([]);
  const [removeProductIds, setRemoveProductIds] = useState([]);
  const [isStickyNav, setIsStickyNav] = useState(false);
  const [shippingInformations, setShippingInformations] = useState(
    localStorage.getItem("shippingInformations")
      ? JSON.parse(localStorage.getItem("shippingInformations"))
      : {}
  );
  const [load, setLoad] = useState(false);
  const [needShippingInfos, setNeedShippingInfos] = useState(false);

  const { t } = useTranslation();

  window.onscroll = function (e) {
    if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else setIsStickyNav(false);
  };

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (idAccount && secretId && token) getUser();
  }, []);

  useEffect(() => {
    if (user.cart && user.cart.length) {
      setCart(user.cart);
    } else if (JSON.parse(localStorage.getItem("cart"))) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }
  }, [user]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, [window.tidioChatApi]);

  return (
    <div className="page">
      {load && <Load />}
      <RegisterModal modalOn={popupLogin} setModal={setPopupLogin} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("cartPage.metas.title")}</title>
        <link rel="canonical" href="https://app.01supply.com/cart" />
        <meta name="description" content={t("cartPage.metas.description")} />
      </Helmet>
        <ResellerSetupProgress user={user} />
      <div className="page-start">
        {windowSize <= 1150 && <Header windowSize={windowSize} location="cart" user={user} />}

        <div
          className="column"
          style={
            windowSize > 1150
              ? {
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  paddingBottom: "0px",
                  width: "100vw",
                  minHeight: "100vh",
                }
              : {}
          }
        >
          {windowSize > 1150 && <Header windowSize={windowSize} location="cart" user={user} />}

          {cart.length > 0 ? (
            <div
              className="column"
              style={{
                width: "95vw",
                marginLeft: `${windowSize > 1150 ? "7vw" : "2.5vw"}`,
                alignItems: "flex-start",
                marginTop: `${windowSize > 1150 ? "230px" : ""}`,
              }}
            >
              <ShippingInformations
                shippingInformations={shippingInformations}
                windowSize={windowSize}
                setShippingInformations={setShippingInformations}
                needShippingInfos={needShippingInfos}
                setNeedShippingInfos={setNeedShippingInfos}
              />
              {cart.map((cartItem, index) => {
                if (!removeProductIds.includes(cartItem?.product_id))
                  return (
                    <ProductCart
                      setNeedShippingInfos={setNeedShippingInfos}
                      setLoad={setLoad}
                      shippingInformations={shippingInformations}
                      popupLogin={popupLogin}
                      setPopupLogin={setPopupLogin}
                      setRemoveProductIds={setRemoveProductIds}
                      removeProductIds={removeProductIds}
                      windowSize={windowSize}
                      index={index}
                      cartItem={cartItem}
                      user={user}
                    />
                  );
              })}
            </div>
          ) : (
            <div
              className="column"
              style={{
                width: "95vw",
                marginLeft: `${windowSize > 1150 ? "7vw" : "2.5vw"}`,
                alignItems: "flex-start",
                marginTop: `${windowSize > 1150 ? "230px" : ""}`,
              }}
            >
              <div
                className="card"
                style={{
                  width: `${windowSize > 1150 ? "91.5vw" : "100%"}`,
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: `${windowSize < 1150 && "0"}`,
                }}
              >
                <p className="title">{t("cartPage.1")}</p>
                <img
                  src={cartImg}
                  style={{ height: "90px", width: "auto", marginTop: "25px", marginBottom: "40px" }}
                />
                <Link style={{ width: "230px" }} to="/marketplace" className="button big orange-back">
                  {t("cartPage.2")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer location="marketplace" windowSize={windowSize} />
    </div>
  );
};

export default Cart;
