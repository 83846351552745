import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { url } from "../../api/url";

import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Error from "../../utils/Error";

import HandmadeIcon from "../../images/icons/handmade.svg";
import ExterneLinkIcon from "../../images/icons/External-link.svg";
import SignatureImg from "../../images/signature.png";

import { routes } from "../../routes";

const CertifHandmade = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [supplierInformations, setSupplierInformations] = useState({});

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getSupplierShop();
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  const getSupplierShop = async () => {
    let supplierShop = window.location.href.split("handmade-certification/");
    if (supplierShop[1]) {
      supplierShop = supplierShop[1];
      const res = await fetch(`${url}/user/shop/no-product/${supplierShop}`);
      if (res.status === 200) {
        const resJson = await res.json();

        setSupplierInformations(resJson.data);
      }
    }
  };

  return (
    <div className="page">
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="about-supplier" />}
        <div
          style={
            windowSize > 1150
              ? { alignItems: "center", display: "flex" }
              : { alignItems: "center", display: "flex", height: "auto", paddingTop: "150px" }
          }
          className="column-scrolling"
        >
          {supplierInformations.shop &&
          supplierInformations.about &&
          supplierInformations.about.handmade_label &&
          supplierInformations.about.handmade_label.verified === true ? (
            <div
              className="column shadow2"
              style={
                windowSize > 1150
                  ? {
                      alignItems: "center",
                      width: "90%",
                      border: "1px solid grey",
                      padding: "50px",
                      maxWidth: "800px",
                      background: "white",
                    }
                  : {
                      alignItems: "center",
                      width: "95%",
                      border: "1px solid grey",
                      padding: "30px",
                      maxWidth: "800px",
                      background: "white",
                    }
              }
            >
              <img
                src={HandmadeIcon}
                style={
                  windowSize > 1150
                    ? { marginTop: "-100px", height: "80px", width: "auto" }
                    : { marginTop: "-70px", height: "80px", width: "auto" }
                }
              />

              <h1 style={{ fontSize: "40px", marginTop: "25px" }} className="title-roboto">
                {t("labelPage.19")}
              </h1>

              <div className={windowSize > 1150 ? "row" : "column"} style={{ width: "100%" }}>
                <img
                  style={{ height: "110px" }}
                  src={supplierInformations.shop.image}
                  className="shop-image-header"
                />
                <div
                  style={
                    windowSize > 1150
                      ? { width: "65%", maxWidth: "1130px" }
                      : { width: "100%", maxWidth: "1130px" }
                  }
                  className="column"
                >
                  <h2
                    style={{ fontSize: "25px", marginBottom: "0", marginBottom: "10px" }}
                    className="title-roboto-300"
                  >
                    {supplierInformations.shop.name}
                  </h2>
                  <h3 style={{ color: "#AF9BD9" }} className="text-roboto-bold">
                    {t("labelPage.20")}
                  </h3>
                  <Link
                    target="_blank"
                    className="text-gradient"
                    to={routes.whatHandmadeLabel(i18n.language).path}
                  >
                    {t("labelPage.21")}
                    <img
                      style={{ marginLeft: "7px", height: "12px", marginBottom: "2px" }}
                      src={ExterneLinkIcon}
                    />
                  </Link>
                </div>
              </div>

              <div className="column" style={{ width: "100%", paddingBottom: "200px" }}>
                <h4 style={{ marginTop: "40px", marginBottom: "10px" }} className="title-roboto">
                  {t("labelPage.22")}
                </h4>
                <p className="text-roboto">{supplierInformations.about.handmade_label.question1}</p>
                <h4 style={{ marginTop: "40px", marginBottom: "10px" }} className="title-roboto">
                  {t("labelPage.23")}
                </h4>
                <p className="text-roboto">{supplierInformations.about.handmade_label.question4}</p>
                <h4 style={{ marginTop: "40px", marginBottom: "10px" }} className="title-roboto">
                  {t("labelPage.24")}
                </h4>
                <p className="text-roboto">{supplierInformations.about.handmade_label.question5}</p>
                <h4 style={{ marginTop: "40px", marginBottom: "10px" }} className="title-roboto">
                  {t("labelPage.25")}
                </h4>
                <p className="text-roboto">{supplierInformations.about.handmade_label.question2}</p>
                <h4 style={{ marginTop: "40px", marginBottom: "10px" }} className="title-roboto">
                  {t("labelPage.26")}
                </h4>
                <p className="text-roboto">{supplierInformations.about.handmade_label.question3}</p>

                <p
                  className="text-roboto-300"
                  style={{ position: "absolute", bottom: "0px", fontSize: "13px" }}
                >
                  © HANDMADE CERTIFICATION - 01supply
                </p>
                <img
                  src={SignatureImg}
                  style={
                    windowSize > 1150
                      ? { position: "absolute", bottom: "0px", right: "0", width: "180px" }
                      : { position: "absolute", bottom: "50px", right: "0", width: "120px" }
                  }
                />
              </div>
            </div>
          ) : (
            supplierInformations.shop && (
              <Error
                text={"Page not found, this supplier does not exist or does not have the handmade label"}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default CertifHandmade;
