import React, { useState, useEffect } from 'react'
import NavBar from "../../../components/menu/navbar/NavBar";
import { Helmet } from "react-helmet";

import img23 from '../../../images/blog/dropshipping-viking-suppliers.jpg'
import img24 from '../../../images/blog/how-does-dropshipping-works.jpg'

import Footer from '../../../components/menu/Footer';
import Cta from '../../../components/Cta';



const VikingSuppliers = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [email, setEmail] = useState('')

    window.addEventListener("resize", function (event) {
        setWindowSize(window.innerWidth);
    });

    useEffect(() => {
        if (window.tidioChatApi)
            window?.tidioChatApi?.hide();
    }, [])

    return (
        <div style={windowSize > 1150 ? { paddingLeft: '15vw', paddingRight: '15vw', alignItems: 'center', overflow: 'hidden' }
            : { alignItems: 'center', paddingBottom: '0px' }} className="blog-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dropshipping Viking Suppliers - List of suppliers| 01supply</title>
                <link rel="canonical" href="https://app.01supply.com/dropshipping-viking-suppliers" />
                <meta name="description" content="Here you can find local, handmade and eco-friendly Dropshipping Viking Suppliers. Providing home decoration, axes, ... Everything in the viking niche" />
                <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "Viking Dropshipping Suppliers List",
          "image": [
            "https://app.01supply.com/static/media/dropshipping-viking-suppliers.684daa80.jpg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
            </Helmet>

            <NavBar windowSize={windowSize} />


            <div className="column" style={windowSize > 1150 ? { width: '70vw', justifyContent: 'flex-start', width: '60%', paddingBottom: '5vw' }
                : { width: '80vw', justifyContent: 'flex-start', paddingBottom: '5vw' }}>
                <img alt="Get Dropshipping Viking products" style={windowSize > 1150 ? { width: '100%', height: 'auto' } : { width: '100%', height: 'auto', marginTop: '30px' }} src={img23} />
                <h1 className="blog-h1">
                    How to Find Dropshipping Viking Suppliers ?
                </h1>
                <p style={{ marginTop: '10px' }} className="text-roboto">(and work with them)</p>
                <h2 className="blog-h2">
                    What is the Viking niche ?                </h2>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    The viking niche focuses on norse and pagan products. Those products can be about mythology, gods, history, famous people, and so on…
                </p>
                <p style={{ marginTop: '10px' }} className="text-roboto">
                    Here are some examples of norse mythology topics:
                </p>
                <ul>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">Odin</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">Freyja</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">Ragnar Lothbrok</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">Fenrir Wolf</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">Jörmungandr</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">Ragnarök</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto">...</p></li>
                </ul>
                <h2 className="blog-h2">
                    📋 List of Dropshipping Viking Suppliers
                </h2>
                <h3 className="blog-h3">🪑 Decoration and furniture</h3>
                <ol style={{ marginTop: '10px' }} className="text-roboto">
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Forged%20In%20Wood" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Forged%20In%20Wood</a> (Wall wooden decoration)</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/NorthMyth" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/NorthMyth</a> (Wooden statues)</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/MasterOak" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/MasterOak</a> (Wall wooden decoration)</p></li>
                </ol>
                <h3 className="blog-h3">🔪 Knives</h3>
                <ol style={{ marginTop: '10px' }} className="text-roboto">
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Scorpion%20Mart" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Scorpion%20Mart</a> (Damascus knives)</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Soul_of_wood" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Soul_of_wood</a> (Handmade knives)</p></li>
                </ol>
                <h3 className="blog-h3">🪓 Axes</h3>
                <ol style={{ marginTop: '10px' }} className="text-roboto">
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Scorpion%20Mart" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Scorpion%20Mart</a> (Damascus axes)</p></li>
                </ol>
                <h3 className="blog-h3">🛡️ Shields</h3>
                <ol style={{ marginTop: '10px' }} className="text-roboto">
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Nautical%20Cart%20Store" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Nautical%20Cart%20Store</a> (Viking Shields)</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/WerkstattLuzern" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/WerkstattLuzern</a> (Medieval Shields)</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Armsgard" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Armsgard</a> (Viking Shields)</p></li>
                </ol>
                <h3 className="blog-h3">👚 Clothes (Medieval, reenactment, costumes,...)</h3>
                <ol style={{ marginTop: '10px' }} className="text-roboto">
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/remparts" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/remparts</a> (Leather viking shoes, belts,...)</p></li>
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/bycalvina" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/bycalvina</a> (Viking Clothes)</p></li>
                </ol>
                <h3 className="blog-h3">⚔️ Armors</h3>
                <ol style={{ marginTop: '10px' }} className="text-roboto">
                    <li><p style={{ marginTop: '10px' }} className="text-roboto"><a href="https://app.01supply.com/shop/Nautical%20Cart%20Store" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/shop/Nautical%20Cart%20Store</a> (Medieval armors and Helmets)</p></li>
                </ol>
                <p style={{ marginBottom: '5px', marginTop: '15px', maxWidth: '80vw' }} className="text-roboto">
                    If you want to find more viking suppliers, you can check it on <a href="https://app.01supply.com" target="_blank">01supply.com</a>, here:
                    <a className="text-ellipsis" href="https://app.01supply.com/marketplace/thematics/Viking" target="_blank" style={{ textDecoration: 'underline' }}>https://app.01supply.com/marketplace/thematics/Viking</a>
                </p>
                <h2 className="blog-h2">
                    What is Dropshipping ?
                </h2>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <b><i>Dropshipping is a system that allows retailers and suppliers to work in tight flow. </i></b>
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <u>What does that mean ? </u><br />
                    It means that when a customer places an order in the reseller store, <b>the supplier will directly ship the goods to the customer address.</b> <br />
                    So the reseller does not have to worry about logistics and inventory management.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <u>Here is a quick schema to showcase this system</u>:
                </p>
                <img alt="What is Dropshipping ?" style={windowSize > 1150 ? { width: '100%', height: 'auto' } : { width: '100%', height: 'auto', marginTop: '30px' }} src={img24} />

                <h3 className="blog-h3">
                    How to work with Viking Dropshipping Suppliers ?
                </h3>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    In order to work with dropshipping suppliers, you must first find them.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    On <a href="https://app.01supply.com/marketplace" target="_blank"><u>01supply</u></a>, you can find thousands of products from local, handmade and eco suppliers. We focus on quality items made by craftsmen.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <b> Thanks to 01supply you can easily import their products to your Shopify store.</b> <br />
                    Once you get an order, you can also directly order from your supplier on 01supply. So he can ship to your customer.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <u>Here is what is automated as a reseller</u>: </p>
                <ul>
                    <li style={{ marginTop: '10px' }} className="text-roboto">Your customer delivery information is automatically sent to the supplier</li>
                    <li style={{ marginTop: '10px' }} className="text-roboto">The package tracking is automatically linked to your Shopify order</li>
                    <li style={{ marginTop: '10px' }} className="text-roboto">The product information is automatically sent to your Shopify store once imported</li>
                    <li style={{ marginTop: '10px' }} className="text-roboto">Item quantity and shortages are synchronized with your Shopify store</li>
                </ul>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <u><a href="https://apps.shopify.com/01supply" target="_blank">Install 01supply on your Shopify store now</a></u>, and start selling high quality products to your customers.
                </p>
                <h2 className="blog-h2">
                    How to import viking products to a Shopify store?
                </h2>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    First, make sure that you installed 01supply app into your Shopify store: <u><a href="https://apps.shopify.com/01supply" target="_blank">https://apps.shopify.com/01supply</a></u>
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    Once you are on the marketplace, just click “Add to import list” on the product you want to import to your Shopify store. You can also click on the product and then, in the product page, click “Import”.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    Then, your imported products will be in your import list. There you can edit and manage them. Just select those you want to <b>push to your Shopify store</b> and click “Push to Shopify”.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    Awesome!                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    Now your selected products are on your Shopify store. Description, price, quantity,... are also published in Shopify. You can find those imported products in the “Inventory” tab on 01supply.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    <b>Dropshipping Viking Suppliers: </b>
                </p>
                <u>
                    <a style={{ maxWidth: '80vw', textDecoration: 'underline' }} className="text-roboto text-ellipsis" href="https://app.01supply.com/marketplace/thematics/Viking" target="_blank">
                        https://app.01supply.com/marketplace/thematics/Viking
                    </a>
                </u>
                <h2 className="blog-h2">
                    🔎 What if I don’t find items that fits my needs ?                </h2>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    No worries ! 01supply Shopify app offers you a new feature. <b> We will bring new suppliers for you.</b> In fact, you can send us a sourcing request with the item you are looking for. Just log in into your account and go in Suppliers - Sourcing. From there, you can send us information about the product you are looking for.
                </p>
                <p style={{ marginBottom: '5px', marginTop: '15px' }} className="text-roboto">
                    We hope to see you soon on board !
                </p>

            </div>

            <Cta windowSize={windowSize} />

            <Footer windowSize={windowSize} />


        </div>
    )
}

export default VikingSuppliers