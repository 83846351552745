import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Banner from "../../../components/courses/Banner";
import Product from "../../../components/courses/Product";
import Items from "../../../components/courses/Items";
import BuyBanner from "../../../components/courses/BuyBanner";
import Faq from "../../../components/courses/Faq";
import Footer from "../../../components/menu/Footer";
import { coursesId } from "../../../constants/coursesId";

import bannerImg from "../../../images/courses/supplier/course1/banner.svg";
import productImg from "../../../images/courses/supplier/course1/product.png";
import item1Img from "../../../images/courses/supplier/course1/item1.svg";
import item2Img from "../../../images/courses/supplier/course1/item2.svg";
import item3Img from "../../../images/courses/supplier/course1/item3.svg";
import item4Img from "../../../images/courses/supplier/course1/item4.svg";
import item5Img from "../../../images/courses/supplier/course1/item5.svg";
import item6Img from "../../../images/courses/supplier/course1/item6.svg";
import pcImg from "../../../images/courses/supplier/course1/pc.svg";
import supplerAcademyLogo from "../../../images/icons/01supply_academy.svg";

const Course1 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="course-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Photo Mastery Course | 01supply Academy</title>
        <meta
          name="description"
          content="Learn how to take stunning pictures for your products in this photography course. And make your e-commerce store 10 times more appealing and outstanding."
        />
      </Helmet>
      <div className="course-navbar">
        <img style={{ width: "180px" }} src={supplerAcademyLogo} alt="01supply academy" />
      </div>
      <Banner
        title={
          <h1 style={{ fontSize: "45px", textShadow: "#3C3C3C 1px 0 5px" }} className="white title-600 start">
            Learn how to take
            <br /> outstanding
            <br />
            <span style={{ color: "#FBA95E" }}>Product Photos</span>
            <br />
            within 1 day
          </h1>
        }
        text={
          <p style={{ fontSize: "16px", textShadow: "#3C3C3C 1px 0 5px"}} className="orange text-300">
            Even with your smartphone
          </p>
        }
        img={bannerImg}
        alt="product photography course"
      />
      <Product
        text1={"Take photos with outstanding results that makes your products 10 times more attractive."}
        text2={`Even with your smartphone, you can enhance your shots by far, thanks to advanced settings, correct lighting and good knowledge.`}
        difficult={"easy"}
        img={productImg}
        price={"$99"}
        alt="product mastery"
        courseId={coursesId.supplier.course1}
      />
      <h2 className="course-black-banner">
        Product picture is the first thing a cusomer sees on your e-commerce store
      </h2>
      <Items
        img1={item1Img}
        title1={`If the image is not appealing, he won’t even click on it.`}
        text1={`Even more when your product is in a marketplace with thousands of other ones.`}
        img2={item2Img}
        title2={`A great picture can also make your product more desirable.`}
        text2={`If the photo quality is great, perceived value will increase and your pricing can grow too.`}
        img3={item3Img}
        title3={`Lighting course`}
        text3={`How to arrange and prepare your lights for the best shots of you life.`}
        price1={"$69"}
        img4={item4Img}
        title4={`Settings`}
        text4={`Prepare your device to capture your product at its best.`}
        price2={"$44"}
        img5={item5Img}
        title5={`Decor improvement`}
        text5={`Create a stunning decor that will make your product even more attractive.`}
        price3={"$29"}
        img6={item6Img}
        title6={`Equipment checklist`}
        text6={`You will receive equipment recommendation, even if you only have a smartphone.`}
        price4={"$37"}
      />
      <BuyBanner
        title={
          <h2
            style={{ fontWeight: "900", fontSize: "30px" }}
            className="title-bold montserrat start width-100 white"
          >
            Everything <br />
            in one course
          </h2>
        }
        img={pcImg}
        alt="product shooting course"
        list={
          <ul>
            <li className="text-bold">
              Lighting course:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $69
              </span>
            </li>
            <li className="text-bold">
              Settings:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $44
              </span>
            </li>
            <li className="text-bold">
              Decor improvement{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $29
              </span>
            </li>
            <li className="text-bold">
              Equipment checklist:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $37
              </span>
            </li>
          </ul>
        }
        price={"$99"}
        previousPrice={"$179"}
        courseId={coursesId.supplier.course1}
      />
      <Faq />
      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Course1;
