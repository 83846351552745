import React from "react";
import { useTranslation } from "react-i18next";

import illustration from "../../../images/illustrations/ia.svg";

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="card width-100" id="market-sub-category">
      <img src={illustration} alt="ia illustration" />
      <div className="column">
        <h1 className="title-bold">{t("marketplace.suggestion.title")}</h1>
        <p style={{ marginBottom: "5px" }}>{t("marketplace.suggestion.1")}</p>
        <p style={{ marginBottom: "5px" }}>{t("marketplace.suggestion.2")}</p>
        <p>{t("marketplace.suggestion.3")}</p>
      </div>
    </div>
  );
};

export default Header;
