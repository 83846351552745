import { url } from "../api/url";
import { header } from "../api/header";

export const userServices = {
  resetPassword: async ({ email, resetCode, passwordReset, accountType }) => {
    return await fetch(`${url}/user/reset-password`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        email: email.toLowerCase(),
        resetCode,
        passwordReset,
        accountType: accountType,
      }),
    });
  },
  login: async ({ email, password }) => {
    return await fetch(`${url}/user/connect`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        email: email.toLowerCase(),
        password,
      }),
    });
  },
  createShipping: async (
    { newShipping, newShipId },
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        newShipping,
        newShipId,
      }),
    });
  },
  me: async (
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  myResellers: async (
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/user/my-resellers/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  findAffiliation: async (
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/user/find-affiliation/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  productLink: async (
    { productId, cmsProductId, linkId, variantSkus },
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/user/product-link/${idAccount}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        productId,
        cmsProductId,
        linkId,
        variantSkus,
      }),
    });
  },
};
