import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Select } from "antd";
import { Modal } from "../wrappers";

import updIcon from "../images/icons/upArrowYellow.svg";
import { useTranslation } from "react-i18next";

const NeedUpgradePlanReseller = ({ setIsModalDisplayed, currentPlan, nextPlans }) => {
  const { t } = useTranslation();
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState(nextPlans[0].type);
  const [redirect, setRedirect] = useState(false);

  const handleSelect = ({ typeName }) => {
    setCurrentSelectedPlan(typeName);
  };

  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000 upgrade-plan-modal"
      visible={true}
      onCancel={() => {
        setIsModalDisplayed(false);
      }}
      footer={[]}
    >
      {redirect && <Redirect to={`/pricing?type=${currentSelectedPlan}`} />}
      <div className="column" style={{ alignItems: "center", justifyContent: "center" }}>
        <img src={updIcon} alt="upgrade icon" style={{ height: "60px", marginBottom: "30px" }} />
        <p className="title-bold">{t("modalNeedUpgradePlan.1")}</p>
        <p style={{ marginTop: "20px" }} className="text-bold red">
          {t("modalNeedUpgradePlan.2")}{" "}
          <span style={{ fontWeight: "400" }}>
            {t("modalNeedUpgradePlan.3", { count: currentPlan.maxProducts })}
          </span>
        </p>
        <div className="divider-horizontal width-100" style={{ margin: "25px 0" }} />
        <div style={{ justifyContent: "space-evenly" }} className="row-wrap width-100">
          <div className="column">
            <p style={{ marginBottom: "10px" }} className="title-bold">
              {t("modalNeedUpgradePlan.4")}
            </p>
            <Select
              style={{ width: "300px", height: "80px" }}
              defaultValue={nextPlans[0].type}
              onChange={handleSelect}
            >
              {nextPlans.map((plan) => {
                return (
                  <Select.Option style={{ height: "60px" }} value={plan.type}>
                    <div style={{ padding: "10px" }} className="row start space-between">
                      <div style={{ marginRight: "20px" }} className="column">
                        <p style={{ fontSize: "16px" }} className="title">
                          {plan.type.toUpperCase()} plan
                        </p>
                        <p className="grey" style={{ fontSize: "13px", marginTop: "-6px" }}>
                          {plan.type === "basic"
                            ? `10 - 25 ${t("products")}`
                            : plan.type === "advanced"
                            ? `26 - 250 ${t("products")}`
                            : plan.type === "premium" && t("modalNeedUpgradePlan.6")}
                        </p>
                      </div>
                      <p style={{ fontSize: "16px" }} className="title-bold grey">
                        {plan.type === "basic"
                          ? `$19`
                          : plan.type === "advanced"
                          ? `$49`
                          : plan.type === "premium" && "$99"}{" "}
                        <span style={{ fontWeight: "400" }}>/mo</span>
                      </p>
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <button
            onClick={() => setRedirect(true)}
            style={{ marginTop: "20px", height: "60px", width: "230px" }}
            className="button big orange-back"
          >
            {t("modalNeedUpgradePlan.5")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NeedUpgradePlanReseller;
