import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article31/comment-trouver-un-fournisseur-en-dropshipping.jpg";
import avisClients from "../../../../images/blog/global/article31/avis-clients.jpg";
import boucheOreille from "../../../../images/blog/global/article31/bouche-a-oreille.jpg";
import CMS from "../../../../images/blog/global/article31/cms-boutique.jpg";
import commandes from "../../../../images/blog/global/article31/commandes.jpg";
import schema from "../../../../images/blog/global/article31/dropshipping-shema.jpg";
import livraison from "../../../../images/blog/global/article31/livraison-fournisseur-dropshipping.jpg";
import pres from "../../../../images/blog/global/article31/pres.jpg";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Comment trouver des fournisseurs en dropshipping ?
      </h1>
      <p className="text-300">03 mars 2022 - 15 minutes de lecture</p>
      <img
        src={coverImg}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Comment trouver des fournisseurs en dropshipping ?"
      />
      <p style={{ marginTop: "15px", marginBottom: "20px" }}>
        Le dropshipping consiste à <b>revendre les produits d’un fournisseur auprès de vos clients</b> sans
        avoir de stock de produits. Le fournisseur sera tenu de livrer directement l’acheteur par ses propres
        moyens. Cette activité permet de faire du e-commerce à moindre frais, sans engager d’investissement
        dans du stock par exemple.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Cependant, il faut savoir trouver les fournisseurs pour débuter votre activité. Ce guide complet vous
        permettra de <b>trouver des fournisseurs en dropshipping dès aujourd’hui</b>.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#definition" title="Définition du dropshipping" />
            <Anchor.Link
              href="#comment-travailler-avec-fournisseurs"
              title="Comment travailler en dropshipping avec des fournisseurs ?"
            >
              <Anchor.Link href="#creer-boutique" title="Comment créer sa boutique en ligne?" />
              <Anchor.Link
                href="#enregistrer-activite-legal"
                title="Comment enregistrer son activité (en France par exemple)"
              />
              <Anchor.Link
                href="#commander-produits"
                title="Comment commander des produits pour sa boutique en dropshipping ?"
              />
              <Anchor.Link
                href="#strategie-marketing"
                title="Comment trouver la bonne stratégie de marketing digital ?"
              />
            </Anchor.Link>
            <Anchor.Link
              href="#ou-trouver-fournisseurs"
              title="Où trouver des fournisseurs en dropshipping ?"
            />
            <Anchor.Link
              href="#comment-trouver-bon-fournisseur"
              title="Comment trouver un bon fournisseur en dropshipping ?"
            >
              <Anchor.Link
                href="#fournisseur-fiable"
                title="Comment vérifier la fiabilité du fournisseur ?"
              />
              <Anchor.Link
                href="#livraison-fournisseur"
                title="Vérifier sa capacité à assurer la livraison"
              />
              <Anchor.Link
                href="#qualite-produits"
                title="S’assurer que le fournisseur dispose de bons produits"
              />
            </Anchor.Link>
            <Anchor.Link
              href="#types-de-fournisseurs"
              title="Quels sont les différents types de fournisseurs ?"
            >
              <Anchor.Link href="#detaillant" title="Les détaillants" />
              <Anchor.Link href="#grossiste" title="Les grossistes" />
              <Anchor.Link href="#centrale-achat" title="Les centrales d’achats" />
              <Anchor.Link href="#prestataires" title="Les prestataires de services" />
              <Anchor.Link href="#createurs" title="Les créateurs" />
            </Anchor.Link>
            <Anchor.Link
              href="#comment-travailler-fournisseur-dropshipping"
              title="Comment travailler avec des fournisseurs en dropshipping ?"
            />
            <Anchor.Link
              href="#contacter-fournisseurs"
              title="Comment contacter des fournisseurs en dropshipping ?"
            />
            <Anchor.Link
              href="#negocier-prix"
              title="Comment négocier les prix des produits des fournisseurs en dropshipping ?"
            />
            <Anchor.Link href="#conclusion" title="En résumé" />
          </Anchor>
        }
      />
      <h2 id="definition">Définition du dropshipping</h2>
      <p style={{ marginBottom: "20px" }}>
        C’est un concept assez simple. Il est adapté pour ceux qui veulent se lancer dans l’e-commerce, mais
        qui n’ont pas forcément un stock de marchandises. Vous agirez en tant que revendeur. Pour ce faire, il
        suffit d’ouvrir une boutique en ligne avec des plateformes telles que Shopify ou Wix par exemple.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Votre site en ligne vous servira de vitrine. C’est-à-dire que vous allez exposer des produits qui
        proviennent de fournisseurs. Les prix ainsi que les caractéristiques du produit seront affichés.
        Toutefois, vous appliquez une tarification plus élevée en ajoutant bien entendu votre marge aux prix
        fournisseurs.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>
          Lorsqu’un client effectue une commande sur votre boutique en ligne, vous transférez la commande à
          votre fournisseur
        </b>
        . Ce dernier sera chargé de livrer directement votre client. De votre côté, vous faites des bénéfices
        grâce aux marges appliquées sur les produits.
      </p>
      <img
        src={schema}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Définition dropshipping"
      />
      <p style={{ marginBottom: "20px" }}>
        <b>Faire du dropshipping est totalement légal</b>. La constitution d'un stock de marchandises n’est
        pas obligatoire. Tant que vous respectez toutes les obligations légales et fiscales de votre pays,
        vous pouvez pratiquer cette activité aussi longtemps que vous le souhaitez.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Le dropshipping donne la possibilité au revendeur de générer des revenus avec peu de risque et{" "}
        <b>depuis n’importe où dans le monde</b>. Le fournisseur, quant à lui, bénéficie d’une accélération de
        son activité et de ses ventes. Cependant, il est nécessaire de trouver le bon partenaire pour réussir
        dans cette activité, c’est ce que nous allons voir maintenant.
      </p>
      <h2 id="comment-travailler-avec-fournisseurs">
        Comment travailler en dropshipping avec des fournisseurs ?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Pour travailler en dropshipping avec des fournisseurs, il faut commencer par mettre en place votre
        boutique en ligne. Il sera par la suite nécessaire de travailler votre stratégie de marketing digital
        pour attirer de nouveaux clients.
      </p>
      <h3 id="creer-boutique">Comment créer sa boutique en ligne ?</h3>
      <p style={{ marginBottom: "20px" }}>
        Avant de pouvoir pratiquer le dropshipping, vous devez commencer par mettre en place votre boutique en
        ligne. Il s’agit de <b>créer un site internet qui servira de vitrine</b> pour que vos clients puissent
        effectuer leurs achats. La technologie actuelle vous donne plusieurs possibilités. Vous pouvez engager
        un webmaster ou utiliser une plateforme de création de sites web no-code.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Si vous optez pour la création de sites internet avec un développeur web</b>, vous allez devoir
        payer ses honoraires. Cette méthode vous donne la possibilité d’obtenir un site e-commerce bien
        fonctionnel. Attention cependant, bien que cette technique soit attirante afin de créer un site
        totalement sur-mesure, elle n’est pas sans risques. Tout d’abord cela vous coutera beaucoup plus cher
        qu’un abonnement à une service comme Shopify, Wix, Woocommerce ou autre, mais surtout, cela implique
        qu’à chaque changement, vous devrez faire appel à un développeur.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Toutefois, il est possible de se passer du webmaster en optant pour une création de sites web{" "}
        <b>avec une plateforme spécialisée dans le e-commerce</b>. Vous pouvez opter pour Shopify, Prestashop
        ou encore Wix par exemple. Ces applications en ligne disposent déjà de templates modifiables. De plus,
        il est possible de profiter d’une offre gratuite pour vos débuts. Cependant, il faudra opter pour une
        formule payante pour plus de fonctionnalités.
      </p>
      <img
        src={CMS}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Logiciels pour créer boutique en ligne"
      />
      <p style={{ marginBottom: "20px" }}>
        Notez que pour toute activité, il est important de savoir investir. Généralement les abonnements
        auprès de ces plateformes sont remboursées en 2-3 ventes.
      </p>
      <h3 id="enregistrer-activite-legal">Comment enregistrer son activité en France ?</h3>
      <p style={{ marginBottom: "20px" }}>
        Comme il s’agit d’une activité commerciale, vous devez enregistrer votre société. En France, le
        dropshipping est légal, mais il est nécessaire de <b>déclarer son activité</b> et de payer ses taxes.
        Pour ce faire, nous vous recommandons de vous faire accompagner par un expert-comptable, qui pourra
        vous conseiller sur la meilleure forme juridique adaptée à votre situation.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Il faudra également penser à remplir la partie : "mentions légales" de votre site web pour être en
        règle avec les lois de votre pays. Vous devez aussi indiquer votre raison sociale ainsi que d’autres
        informations obligatoires.
      </p>
      <h3 id="commander-produits">Comment commander des produits pour sa boutique en dropshipping ?</h3>
      <p style={{ marginBottom: "20px" }}>
        Pour commander pour sa boutique de dropshipping, il est d'abord nécessaire de trouver le bon
        fournisseur. Vous allez faire le tour des acteurs de ce domaine. Cependant, il faut que vous soyez
        prêt à respecter vos engagements tels que le paiement du fournisseur, l’acquisition de clients et bien
        d’autres encore.
      </p>
      <img
        src={commandes}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Passer commande fournisseurs dropshipping sur 01supply"
      />
      <p style={{ marginBottom: "20px" }}>
        Afin de vous simplifier la vie,{" "}
        <b>
          <a alt="01supply" target="blank_" class="blue" href="/">
            <u>01supply</u>
          </a>{" "}
          est une plateforme qui vous permet de trouver des milliers de fournisseurs et artisans locaux
        </b>
        . Ceux-ci fabriquent des produits à la main, en France, Europe et même aux États-Unis et Canada.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour passer commande, il vous suffit d’avoir importé les produits de vos fournisseurs sur votre
        boutique en ligne. Ensuite, une fois que vous recevez une commande, elle sera automatiquement envoyée
        à votre fournisseur. Il vous suffira de cliquer sur « payer » dans votre onglet commandes sur
        01supply.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour commencer :{" "}
        <a
          class="blue"
          alt="créer un compte 01supply"
          target="blank_"
          href="https://app.01supply.com/fr/join-us?reseller"
        >
          <u>Créer un compte revendeur sur 01supply</u>
        </a>
      </p>
      <h3 id="strategie-marketing">Comment trouver la bonne stratégie de marketing digital ?</h3>
      <p style={{ marginBottom: "20px" }}>
        Dans l’e-commerce, il est nécessaire de posséder une bonne stratégie de marketing digitale. Elle vous
        permettra d’acquérir des leads qui seront ensuite transformés en prospects puis en clients. Il existe
        plusieurs méthodes d’y arriver, mais en général, vous devez disposer d’une bonne présence sur
        internet.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Mettez à profit les réseaux sociaux ainsi que les sites de communications. Parlez à vos proches et
        effectuez des publicités à échelle réduite. Quand votre chiffre d’affaires décolle, vous pouvez penser
        à des stratégies plus élaborées. Vous avez même la possibilité de contacter des influenceurs pour
        accroître la notoriété de votre boutique.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici quelques idées pour effectuer de l’acquisition de visiteurs sur votre boutique en ligne</u>:
      </p>
      <ul>
        <li>Facebook Ads</li>
        <li>Instagram Ads</li>
        <li>Google Ads</li>
        <li>SEO (référencement naturel)</li>
        <li>Backlinks (liens sur des sites partenaires)</li>
        <li>Influenceurs</li>
        <li>Affiliation</li>
      </ul>
      <h2 id="ou-trouver-fournisseurs">Où trouver des fournisseurs en dropshipping ?</h2>
      <p style={{ marginBottom: "20px" }}>
        Trouver des fournisseurs dans ce domaine n’est pas facile. Il faut connaître chaque site et chaque
        partenaire pour les convaincre de collaborer avec vous. Votre fournisseur peut être un groupe qui
        dispose d’une grande notoriété ou un voisin qui travaille en tant qu’artisan.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez trouver tout ce que vous voulez sur le web. Il suffit d’entrer dans une barre de recherche
        les mots-clés adéquats pour voir apparaître plusieurs résultats. Toutefois,{" "}
        <b>il est important de bien choisir votre fournisseur</b>. Il sera votre collaborateur principal dans
        vos activités. De plus, il assurera la livraison des articles commandés par votre clientèle.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Sur 01supply, vous trouverez des fournisseurs de différentes sortes. <br />
        <u>Vous pouvez y contacter des</u>:
      </p>
      <ul>
        <li>Fournisseurs de bijoux</li>
        <li>Experts en métalurgie</li>
        <li>Fabriquants de vêtements</li>
        <li>Créateurs indépendants d'oeuvres d'arts</li>
        <li>Artisans d'articles de décoration d'intérieur</li>
        <li>Et bien d'autres encore...</li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Il est aussi possible d’opter pour les grandes marques. Elles peuvent vous procurer une collaboration
        sur le long terme. Toutefois, vous aurez du mal à les convaincre. En effet, elles disposent déjà de
        leur propre réseau. Elles n’ont presque pas besoin de nouveaux partenariats pour écouler leurs
        produits.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La recherche de fournisseurs exige de vous une bonne capacité de communication. Vous devez savoir
        parler avec eux afin de tirer un maximum de profit avec la collaboration. De plus, quand vous faites
        le premier pas, il sera plus facile de convaincre les fournisseurs de vos intentions.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Attention cependant à ne pas travailler avec n'importe quel service</b>. De nombreuses plateformes
        proposent parfois des produits à prix très attirants, ce qui se répercute souvent sur une mauvaise
        qualité produit et des plaintes de vos clients. Pour être sûr de trouver le bon fournisseur, il est
        préférable de collaborer avec des professionnels comme ceux présents sur 01supply. Ce type de
        partenariat vous offre une garantie sur la fiabilité du fournisseur.
      </p>
      <h2 id="comment-trouver-bon-fournisseur">Comment trouver un bon fournisseur en dropshipping ?</h2>
      <p style={{ marginBottom: "20px" }}>
        Comme nous venons de le voir, les fournisseurs sont très nombreux. Toutefois, il est nécessaire de
        trouver le bon partenaire pour avoir une boutique pérenne. Vous devez vérifier certains points pour
        vous assurer que vous avez trouvé la bonne personne.
        <br />
        <u>Il sera nécessaire de s’assurer</u>:
      </p>
      <ul>
        <li>
          De la <b>fiabilité du fournisseur</b>
        </li>
        <li>De sa capacité à assurer la livraison</li>
        <li>De la qualité des produits proposés</li>
        <li>De la marge que vous pouvez réaliser</li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Cette liste de critères n’est pas exhaustive, libre à vous d’établir plus de critères si vous le
        souhaitez.
      </p>
      <h3 id="fournisseur-fiable">Comment vérifier la fiabilité du fournisseur ?</h3>
      <img
        src={avisClients}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Fiabilité fournisseur dropshipping"
      />
      <p style={{ marginBottom: "20px" }}>
        Pour vérifier la fiabilité du fournisseur, vous pouvez faire un tour sur son site internet. Cette
        démarche est importante, car elle définit si la collaboration sera rentable. Vous pouvez aussi
        utiliser des sites de critiques comme Trustpilot afin de{" "}
        <b>vous assurer que votre partenaire dispose de bon avis sur internet</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez également vérifier les commentaires des consommateurs de votre distributeur. Si la
        majorité des retours sont positifs, vous pouvez collaborer avec lui sans crainte. Si ce n’est pas le
        cas, écartez-le immédiatement. Il est aussi possible de demander à votre réseau s’ils ont déjà
        effectué des achats auprès du vendeur. Vous aurez ainsi un avis complet sur le sujet.
      </p>
      <h3 id="livraison-fournisseur">Vérifier sa capacité à assurer la livraison</h3>
      <img
        src={livraison}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Livraison fournisseur dropshipping"
      />
      <p style={{ marginBottom: "20px" }}>
        Si le fournisseur n’est pas en mesure d’assurer la livraison à vos clients, il vaut mieux ne pas
        collaborer avec lui. Vous devez également prendre en compte la distance à parcourir pour que la
        livraison s’effectue. Elle définit le temps d’attente du client avant de recevoir sa commande. En
        effet, un fournisseur basé aux États-Unis aura des temps de livraison bien plus élevés si vos clients
        sont basés en France.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Cependant, cela ne veut pas dire qu’il ne faut pas collaborer avec des distributeurs étrangers. Bien
        au contraire, ils peuvent parfois vous donner une grande satisfaction.
      </p>
      <h3 id="qualite-produits">S’assurer que le fournisseur dispose de bons produits</h3>
      <img
        src={boucheOreille}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Bouche à oreille qualité produit"
      />
      <p style={{ marginBottom: "20px" }}>
        La qualité des produits est primordiale si vous souhaitez maintenir votre activité à flots. En effet,
        une mauvaise qualité de produits vous entrainera non seulement des plaintes clients, mais aussi des
        retours, remboursements et mauvais avis.
      </p>
      <p style={{ marginBottom: "20px" }}>
        À l’inverse en proposant des produits de bonne qualité,{" "}
        <b>vos clients reviendront acheter chez vous par eux même</b>, et vous bénéficierez de{" "}
        <b>bouche à oreille</b> rapidement. Ce bouche à oreille est littéralement de la publicité gratuite
        pour vous, alors profitez-en. En plus de cela, une bonne qualité produite vous évite des
        remboursements et des clients insatisfaits. C’est du service client en moins et donc des économies in
        fine.
      </p>
      <h2 id="types-de-fournisseurs">Quels sont les différents types de fournisseurs ?</h2>
      <p style={{ marginBottom: "20px" }}>
        En général, il existe cinq sortes de fournisseurs :{" "}
        <b>
          les détaillants, les grossistes, les centrales d’achats, les prestataires de services et enfin les
          créateurs
        </b>
        . Chacun d’entre eux dispose de ses propres particularités. Ce sont surtout le fonctionnement et le
        nombre d’articles qui différencient ces types de fournisseurs.
      </p>
      <h3 id="detaillant">Les détaillants</h3>
      <p style={{ marginBottom: "20px" }}>
        <b>Ce sont des professionnels qui collaborent avec plusieurs fournisseurs</b>. Ils vendent différents
        produits à des professionnels ou des particuliers. Ils offrent un grand avantage, car leurs
        marchandises sont très variées. Toutefois, ils appliquent des prix bien plus élevés que ceux des
        grossistes.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Tout comme le revendeur, ils achètent des articles auprès d’un grossiste, un importateur ou encore à
        des agriculteurs. Ensuite, ils revendent les articles dans une boutique ou sur internet pour obtenir
        des bénéfices. D’où l’écart de prix avec les autres types de fournisseurs.
      </p>
      <h3 id="grossiste">Les grossistes</h3>
      <p style={{ marginBottom: "20px" }}>
        Ces fournisseurs, quant à eux, appliquent des prix plus attractifs. Ils vous donnent la possibilité de
        profiter d’un tarif plus avantageux. En revanche,{" "}
        <b>il faut acheter en quantité pour bénéficier des réductions</b>. De plus, les produits sont assez
        limités, surtout si le grossiste se spécialise dans un domaine bien précis.
      </p>
      <h3 id="centrale-achat">Les centrales d’achats</h3>
      <p style={{ marginBottom: "20px" }}>
        Les centrales d’achats sont des entités qui gèrent les acquisitions de ses membres. Leurs
        consommateurs peuvent être des détaillants ou des grossistes. Contrairement aux autres sortes de
        fournisseurs, les centrales d’achats investissent en temps et en argent pour proposer une
        collaboration durable avec leurs clients. Le partenariat avec ces professionnels est{" "}
        <b>régi par un contrat</b>.
      </p>
      <h3 id="prestataires">Les prestataires de services</h3>
      <p style={{ marginBottom: "20px" }}>
        Ce type de fournisseurs <b>sous-traite le service d’un grossiste ou d’un détaillant</b>. Le
        prestataire de services offre son expertise dans la logistique des produits ainsi que dans la
        livraison. Au lieu de vendre un produit, il propose ses compétences. Ses qualités peuvent être utiles
        pour les entreprises en manque de personnel.
      </p>
      <h3 id="createurs">Les créateurs</h3>
      <p style={{ marginBottom: "20px" }}>
        Les créateurs de produits sont à l’origine de la chaine de production.{" "}
        <b>Ce sont eux qui créent les produits</b> et qui par la suite écoulent leurs stocks. En négociant
        directement avec eux, vous vous assurez d’obtenir les prix les plus bas mais aussi le meilleur
        service. En effet, ils seront par exemple capables de modifier le produit si besoin, ou même de le
        personnaliser si votre coopération se passe bien.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>01supply référence plus de 250.000 produits de petits créateurs locaux et éco-responsables</b>. En
        utilisant 01supply, vous bénéficiez d’une automatisation de vos commandes, ainsi que d’une
        synchronisation de votre inventaire. Cela vous permet par exemple de proposer 5.000 produis de 800
        artisans différents, le tout facilement et sans vous soucier des produits hors stock ou des numéros de
        suivi. Tout est automatisé et synchronisé par 01supply.
      </p>
      <a
        class="button orange-back"
        alt="fournisseurs dropshipping artisans"
        href="https://app.01supply.com/marketplace"
        target="blank_"
      >
        Voir les produits des créateurs 01supply
      </a>
      <br />
      <h2 id="comment-travailler-fournisseur-dropshipping">
        Comment travailler avec des fournisseurs en dropshipping ?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Pour travailler avec des fournisseurs, il faut mettre en place une bonne stratégie. Quand vous
        collaborez avec plusieurs distributeurs, les livraisons peuvent vite devenir un grand problème. Pour
        faire face à ce problème, il est préférable de travailler avec une plateforme telle que 01supply.
      </p>
      <img
        src={pres}
        style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        alt="Trouver des artisans dropshipping fournisseur"
      />
      <p style={{ marginBottom: "20px" }}>
        Travailler individuellement avec des fournisseurs exige une grande implication de votre part.
        <b>
          Vous allez devoir établir un suivi sur chaque collaboration et vérifier à chaque fois si la commande
          est bien en cours de traitement ou non
        </b>
        . Vous devez aussi vous assurer que le fournisseur livrera à temps et en bonne condition.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La livraison est très importante, sa gestion peut vite tourner au cauchemar, surtout si vous
        collaborez avec plusieurs fournisseurs en même temps. En effet, certains grossistes appliquent des
        frais plus élevés en raison de la distance qu’ils doivent parcourir. D’autres ne peuvent pas livrer
        dans les temps, car ils n’ont pas les moyens.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Pour éviter ces soucis, il est préférable de collaborer avec une plateforme comme 01supply</b>.
        Elle vous donne la possibilité de trouver rapidement un fournisseur qui répond à vos exigences. De
        plus, il est possible de créer un compte revendeur sur cette plateforme. Ce compte professionnel vous
        donnera plusieurs avantages.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Il vous permet d’accéder à plusieurs informations qui concernent les fournisseurs. Ce compte vous
        donne également la possibilité d’avoir un accès exclusif à la liste des fournisseurs qui travaillent
        avec la plateforme. Ils ont été triés sur le volet pour vous permettre de démarrer sereinement vos
        activités. En cas de difficultés, vous pouvez demander l’aide de 01supply.
      </p>
      <h2 id="contacter-fournisseurs">Comment contacter des fournisseurs en dropshipping ?</h2>
      <p style={{ marginBottom: "20px" }}>
        01supply vous donne déjà la possibilité de contacter les fournisseurs qui sont membres de la
        plateforme. Il suffit de suivre le lien du profil de votre futur collaborateur et de lui envoyer un
        message. Vous obtiendrez ainsi leur contact personnel et vous pourrez commencer à négocier sur les
        conditions de partenariat.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Si vous ne choisissez pas de collaborer avec 01supply, vous possédez plusieurs possibilités pour
        contacter les fournisseurs. La première consiste à envoyer un message via le formulaire de contact du
        fournisseur.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez aussi envoyer un e-mail ou contacter la société avec son numéro de téléphone. Toutefois,
        assurez-vous que l’adresse ou le numéro de téléphone que vous contactez correspond bien au département
        avec lequel vous souhaitez parler. Généralement, les sites fournisseurs contiennent déjà un onglet
        dédié au partenariat avec les revendeurs.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Si le fournisseur ne possède pas de site internet, vous pouvez consulter les annuaires en ligne. Vous
        pouvez également effectuer une visite auprès du professionnel concerné. Elle est obligatoire pour les
        artisans ou les acteurs qui viennent de se lancer. Ces derniers ne possèdent ni de présence en ligne
        ni de contact sur le web.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez être plus efficace dans le contact des fournisseurs en ciblant les dirigeants de
        l’entreprise. Pour les trouver, il faut mettre à profit les réseaux sociaux. C’est-à-dire que vous
        allez devoir retrouver sur ces plateformes les contacts de ces personnalités. Nous vous recommandons
        d’utiliser LinkedIn, car les postes sont affichés sur ce réseau social.
      </p>
      <h2 id="negocier-prix">Comment négocier les prix des produits des fournisseurs en dropshipping ?</h2>
      <p style={{ marginBottom: "20px" }}>
        La négociation des prix produits est une étape importante au cours de la vie de votre entreprise. Elle
        permet non seulement de <b>réduire vos couts, et surtout d’augmenter vos bénéfices</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Cependant, en début d’activité, nous vous recommandons de ne pas y passer trop de temps. En effet, les
        fournisseurs en dropshipping reçoivent des dizaines de demandes tous les jours. Si vous n’avez pas
        d’argument à leur proposer parce que vous venez de vous lancer, vous risquez simplement de perdre
        votre temps.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Commencez donc simplement en vendant vos produits un peu plus cher, ensuite lorsque vous commencerez à
        avoir des ventes récurrentes, vous aurez un argument afin de négocier auprès de votre fournisseur.
        Celui-ci sera bien plus enclin à vous écouter et à négocier avec vous car il sait qu’il peut vous
        faire confiance.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous vous démarquerez par la même occasion de tous les revendeurs amateurs car vous serez le seul à
        avoir des arguments convaincants.
      </p>
      <h2 id="conclusion">En résumé</h2>
      <p style={{ marginBottom: "20px" }}>
        Voici un rapide résumé de ce guide "Comment trouver des fournisseurs en dropshipping ?""
      </p>
      <ol>
        <li>
          <b>
            Trouvez des fournisseurs sur la{" "}
            <a
              class="blue"
              target="blank_"
              alt="marketplace 01supply"
              href="https://app.01supply.com/fr/marketplace-home"
            >
              <u>marketplace 01supply</u>
            </a>
          </b>
        </li>
        <li>Importez leurs produits sur votre boutique en ligne</li>
        <li>Payez vos commandes automatiquement via l’onglet "commandes"</li>
        <li>Lorsque vous avez suffisamment de ventes, négociez les prix afin d’augmenter vos bénéfices</li>
        <li>
          Lorsque vous avez suffisamment de ventes, proposez un packaging et de la personnalisation de
          produits à votre fournisseur afin de créer une expérience client unique à votre boutique
        </li>
      </ol>
      <p style={{ marginBottom: "20px" }}>
        Nous vous souhaitons bon courage dans votre nouvelle activité de dropshipping !
      </p>
      <a
        class="button orange-back"
        alt="trouver fournisseurs dropshipping"
        href="https://app.01supply.com/marketplace"
        target="blank_"
      >
        Voir les fournisseurs en dropshipping
      </a>
    </div>
  );
};

export default Content;
