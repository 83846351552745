import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

import { routes } from "../../routes";

import ProductCard from "../../components/ProductCard";
import { productServices } from "../../services/products";

const HighMarginProducts = ({ setUser, user, windowSize, setIsModalPricingDisplayed, setRegisterModalIsActive }) => {
  const [products, setProducts] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    productServices
      .find({
        isHighMarginProducts: true,
        page: 1,
        limit: 3,
        userId: localStorage.getItem("idAccountCreatosell") || "",
      })
      .then((res) => res.json())
      .then((resJson) => {
        const resProducts = resJson.data;
        setProducts(resProducts);
      });
  };

  return (
    <div style={{ marginTop: "30px", marginBottom: "40px", padding: windowSize < 1150 && "0" }} className="card width-100">
      <div style={{ marginBottom: "20px" }} className="row row-wrap space-between width-100">
        <p style={{ margin: "10px" }} className="title-bold">
          {t("marketplaceHome.highMargin.1")}
        </p>
      </div>

      <div style={{ justifyContent: "space-evenly" }} className="width-100 row-wrap center product-container-marketplace-home">
        {products.length > 0 ? (
          products.map((product, index) => {
            return (
              <ProductCard
                setUser={setUser}
                user={user}
                key={product._id}
                index={index}
                location={"landing"}
                product={product}
              />
            );
          })
        ) : (
          <div style={{ width: "90%" }}>
            <Skeleton
              style={{ marginTop: "80px", width: "100%" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
            <Skeleton
              style={{ marginTop: "80px", width: "100%" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
          </div>
        )}
      </div>

      <div
        style={{ alignItems: "flex-start", marginBottom: "20px" }}
        className="row-wrap space-between width-100"
      >
        <div style={{ width: "45%", minWidth: "300px", margin: "10px" }} className="column">
          <p className="title-bold">{t("marketplaceHome.highMargin.2")}</p>{" "}
          <p style={{ marginTop: "15px", marginBottom: "10px" }}>
            {t("marketplaceHome.highMargin.3")} <b>{t("marketplaceHome.highMargin.4")}</b>{" "}
            {t("marketplaceHome.highMargin.5")}
          </p>
          <p>
            {t("marketplaceHome.highMargin.6")} <b>{t("marketplaceHome.highMargin.7")}</b>{" "}
            {t("marketplaceHome.highMargin.8")}
          </p>
        </div>
        <div style={{ width: "45%", minWidth: "300px", margin: "10px" }} className="column">
          <p className="title-bold">{t("marketplaceHome.highMargin.9")}</p>
          <p style={{ marginTop: "15px", marginBottom: "10px" }}>{t("marketplaceHome.highMargin.10")}</p>
          <p>
            {t("marketplaceHome.highMargin.11")} <b>{t("marketplaceHome.highMargin.12")}</b>{" "}
            {t("marketplaceHome.highMargin.13")}
          </p>
        </div>
      </div>

      <div className="width-100 row center">
        <Link to={routes.marketplaceHighMargin(i18n.language).path} className="button-grey">
          {t("marketplaceHome.viewAll")}
        </Link>
      </div>
    </div>
  );
};

export default HighMarginProducts;
