import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Menu from "../../components/menu/AffiliateMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import CheckToken from "../../utils/RedirectLogin";
import { userServices } from "../../services/user";

import LinkCard from "../../components/affiliationDashboard/LinkCard";
import Load from "../../utils/Load";

const AffiliateLinks = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    userServices
      .me()
      .then((res) => res.json())
      .then((res) => setUser(res.data));
  }, []);

  return (
    <div className="page affiliation">
      <CheckToken />
      <div className="page-start">
        {windowSize > 1150 ? <Menu user={user} /> : <MobileMenu user={user} locationP="affiliation" />}
        {user?._id ? (
          <div
            style={
              windowSize > 1150
                ? { padding: "50px", paddingLeft: "30px", paddingTop: "30px" }
                : { padding: "50px", paddingBottom: "100px", paddingLeft: "15px", paddingRight: "15px" }
            }
            className="column-scrolling"
          >
            <h1
              style={{ fontSize: "28px", marginBottom: "30px" }}
              className="title montserrat-800 width-100 start"
            >
              {t("affiliateDashboard.links.title")}
            </h1>
            <LinkCard
              user={user}
              title={t("affiliateDashboard.links.card2.title")}
              thematics={t("affiliateDashboard.links.card2.thematics")}
              link={`https://app.01supply.com?invitation=${user._id}`}
              monetization={
                <div className="column">
                  <p>
                    {t("affiliateDashboard.links.subscription")}{" "}
                    <span className="text-bold" style={{ color: "#2AAB37" }}>
                      19$ {t("to")} 99$
                    </span>
                  </p>
                </div>
              }
            />
            <LinkCard
              user={user}
              title={t("affiliateDashboard.links.card1.title")}
              thematics={t("affiliateDashboard.links.card1.thematics")}
              link={`https://app.01supply.com/ecommerce-coaching-presentation?invitation=${user._id}`}
              index={0}
              monetization={
                <div className="column">
                  <p>
                    {t("affiliateDashboard.links.subscription")}{" "}
                    <span className="text-bold" style={{ color: "#2AAB37" }}>
                      99$ {t("to")} 500$ {t("affiliateDashboard.links.byMonth")}{" "}
                    </span>
                  </p>
                  <p>
                    {t("affiliateDashboard.links.oneTimePayment")}{" "}
                    <span className="text-bold" style={{ color: "#2AAB37" }}>
                      60$ {t("to")} 2000${" "}
                    </span>
                  </p>
                </div>
              }
            />
            <LinkCard
              user={user}
              title={t("affiliateDashboard.links.card3.title")}
              thematics={t("affiliateDashboard.links.card3.thematics")}
              link={`https://app.01supply.com?invitation=${user._id}`}
              monetization={
                <div className="column">
                  <p>
                    {t("affiliateDashboard.links.subscription")}{" "}
                    <span className="text-bold" style={{ color: "#2AAB37" }}>
                      6% {t("to")} 10%
                    </span>
                  </p>
                  <p>
                    {t("affiliateDashboard.links.oneTimePayment")}{" "}
                    <span className="text-bold" style={{ color: "#2AAB37" }}>
                      100$ {t("to")} 200${" "}
                    </span>
                  </p>
                </div>
              }
            />
          </div>
        ) : (
          <Load />
        )}
      </div>
    </div>
  );
};

export default AffiliateLinks;
