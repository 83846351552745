import React from "react";
import { useTranslation } from "react-i18next";

const ProductEtsyStats = ({ product, productImage }) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "50px" }} className="width-100 column">
      <div className="row width-100 start">
        <img
          src={productImage}
          alt={product.name}
          style={{ width: "90px", marginRight: "20px", borderRadius: "8px" }}
        />
        <p className="text-bold" style={{ maxWidth: "450px" }}>
          {product.name}
        </p>
      </div>
    </div>
  );
};

export default ProductEtsyStats;
