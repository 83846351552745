import React from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from "recharts";
import { useTranslation } from "react-i18next";

import upArrowIcon from "../../images/icons/up_arrow_green.svg";

const Chart = ({ product }) => {
  const { t } = useTranslation();
  const salesData = product.etsy_stats.trends?.sales?.map((stat) => ({
    date: stat.date,
    sales: parseInt(stat.value),
  }));

  function getWeekNumber(date) {
    let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  }
  const alreadyWeekPush = [];
  const priceAndFavoritesData = product.etsy_stats.trends?.favorites
    ?.map((stat) => {
      let weekNumber = getWeekNumber(new Date(stat.date));
      if (!alreadyWeekPush.includes(weekNumber)) {
        alreadyWeekPush.push(weekNumber);
        return {
          date: stat.date,
          favorites: parseInt(stat.value),
          price: product.etsy_stats.trends?.price.find((price) => price.date === stat.date)?.value,
        };
      } else return undefined;
    })
    .filter((data) => !!data);

  return (
    <div className="width-100 row-wrap space-between" style={{ alignItems: "flex-start" }}>
      <div style={{ width: window.innerWidth > 1150 ? "45%" : "100%" }} className="column start">
        <div style={{ marginBottom: "40px" }} className="row start">
          <div style={{ minWidth: "150px" }} className="column">
            <p style={{ fontSize: "16px" }} className="title grey">
              {t("marketplaceHome.analytic.modal.1")}
            </p>
            <p className="title-bold">{product.etsy_stats?.total_sales}</p>
          </div>
        </div>
        <ResponsiveContainer minHeight="450px" minWidth="300px" width="100%" height="100%">
          <AreaChart margin={{ top: 0, right: 0, bottom: 6, left: 10 }} barCategoryGap={1} data={salesData}>
            <defs>
              <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ffbc6d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ffbc38" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke="none" strokeDasharray="2 2" />
            <YAxis />
            <XAxis axisLine={false} tick={false} hide dataKey="date" />
            <Tooltip />
            <Area type="monotone" dataKey="sales" stroke="#ffbc6d" fillOpacity={1} fill="url(#total)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div style={{ width: window.innerWidth > 1150 ? "45%" : "100%" }} className="column start">
        {product.etsy_stats?.percent_evolution > 0 && (
          <div style={{ marginBottom: "40px" }} className="row start">
            <div className="column back-gey">
              <p className="grey" style={{ fontSize: "14px" }}>
                {t("marketplaceHome.analytic.modal.2")}
              </p>
              <p style={{ fontSize: "18px", color: "#8FBC5D" }} className="start">
                {" "}
                +{product.etsy_stats?.percent_evolution}%
                <img src={upArrowIcon} alt="up popularity" style={{ height: "16px", marginLeft: "10px" }} />
              </p>
            </div>
          </div>
        )}
        <div
          style={{ marginBottom: "40px", marginTop: window.innerWidth <= 1150 && "30px" }}
          className="column"
        >
          <p style={{ fontSize: "16px" }} className="title grey">
            {t("marketplaceHome.analytic.modal.3")}
          </p>
          <p className="title-bold">
            {product.etsy_stats?.trends?.favorites[product.etsy_stats?.trends?.favorites?.length - 1]?.value}
          </p>
        </div>
        <ResponsiveContainer minHeight="450px" minWidth="300px" width="100%" height="100%">
          <AreaChart
            margin={{ top: 0, right: 0, bottom: 6, left: 10 }}
            barCategoryGap={1}
            data={priceAndFavoritesData}
          >
            <defs>
              <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ffbc6d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ffbc38" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke="none" strokeDasharray="2 2" />
            <YAxis />
            <XAxis axisLine={false} tick={false} hide dataKey="date" />
            <Tooltip />
            <Area type="monotone" dataKey="favorites" stroke="#ffbc6d" fillOpacity={1} fill="url(#total)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Chart;
