import React from "react";
import { useTranslation } from "react-i18next";
import { Select, InputNumber } from "antd";
import SelectShipping from "./SelectShipping";

const { Option } = Select;

const Shipping = ({
  setProduct,
  product,
  countryList,
  userShippings,
  user,
  getUser,
  setLoad,
  windowSize,
}) => {
  const { t } = useTranslation();
  return (
    <div id="shipping" style={{ width: "100%" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        {t("shipping")}
      </p>
      <p style={{ marginBottom: "5px" }} className="text-bold">
        {t("origin")}
      </p>
      <Select
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) =>
          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
        value={product.origin || t('productOrigin')}
        defaultValue={t('productOrigin')}
        style={{ width: "100%" }}
        onChange={(value) => setProduct({ ...product, origin: value })}
      >
        {countryList.map((country) => {
          if (country.title !== "Rest of world")
            return <Option value={country.title}>{country.title}</Option>;
        })}
      </Select>
      <p style={{ marginBottom: "5px", marginTop: "15px" }} className="text-bold">
        {t("processingTime")}
      </p>
      <div id="demo-step-16" className="row">
        <InputNumber
          min={0}
          style={{ width: "calc(50% - 15px)", marginRight: "6px" }}
          value={product.processingTime?.toString().split(" ")[0]}
          placeholder={t("inDays")}
          type="number"
          onChange={(value) => {
            let newTime = "";
            if (product.processingTime) newTime = product.processingTime.toString().split("to ");
            if (newTime[1]) setProduct({ ...product, processingTime: value + " to " + newTime[1] });
            else setProduct({ ...product, processingTime: value });
          }}
        />
        {t("to")}
        <InputNumber
          min={parseInt(product.processingTime?.toString().split(" ")[0])}
          style={{ width: "calc(50% - 15px)", marginLeft: "6px" }}
          value={product.processingTime?.toString().split("to ")[1]}
          placeholder={t("inDays")}
          type="number"
          onChange={(value) => {
            let newTime = "0";
            if (product.processingTime) newTime = product.processingTime.toString().split("to ")[0];
            setProduct({ ...product, processingTime: newTime + " to " + value });
          }}
        />
      </div>
      <SelectShipping
        getUser={getUser}
        user={user}
        setProduct={setProduct}
        product={product}
        countryList={countryList}
        userShippings={userShippings}
        setLoad={setLoad}
        windowSize={windowSize}
      />
    </div>
  );
};

export default Shipping;
