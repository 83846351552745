import React from "react";
import { Link } from "react-router-dom";
import { Table, Select, message } from "antd";

import { thematics } from "../../../constants/thematics";
import { adminServices } from "../../../services/admin";
import { parseDate } from "../../../utils/parseDate";

const UserNoThematic = ({ users, isNoThematic }) => {
  const handleSelectThematic = ({ thematic, userId }) => {
    adminServices
      .assignThematics({ thematic, userId })
      .then((res) =>
        res.status === 200 ? message.success("thematic assign") : message.error("error assign thematic")
      );
  };

  const getStore = (user) => {
    let store = "";
    if (user.type === "seller") {
      store = user.shopify?.shop ? user.shopify?.shop : user.shop?.url ? user.shop?.url : "";
      if (store && !store.includes("http")) {
        store = "https://" + store;
      }
    } else {
      store = `${process.env.REACT_APP_ORIGIN}/shop/${user.shop?.name}`;
    }
    return store;
  };

  const columns = [
    {
      title: "type",
      dataIndex: "type",
      width: 70,
    },
    {
      title: "Country",
      dataIndex: "type",
      width: 70,
      render: (text, line) => <p>{line.shopifyContacts?.country || line.company.country_code}</p>,
      sorter: (a, b) =>
        (a.shopifyContacts?.country || a.company.country_code || "").localeCompare(
          b.shopifyContacts?.country || b.company.country_code || ""
        ),
    },
    {
      title: "store",
      dataIndex: "shopify",
      width: 150,
      render: (text, line) => {
        if (line.type === "seller") {
          let store = getStore(line);
          return (
            <a href={store} target="_blank" rel="noopener noreferrer">
              {store}
            </a>
          );
        } else {
          return (
            <Link target="_blank" to={`/shop/${line.shop?.name}`}>
              {line.shop?.name}
            </Link>
          );
        }
      },
      sorter: (a, b) => getStore(a).localeCompare(getStore(b)),
    },
    {
      title: "nb produits 01supply",
      dataIndex: "products_id",
      width: 100,
      render: (text, line) => <p>{line.products_id?.length || 0}</p>,
      sorter: (a, b) => (a.products_id?.length || 0) - (b.products_id?.length || 0),
    },
    {
      title: "nb ventes sur 01supply (createur)",
      dataIndex: "orderCount",
      width: 100,
      sorter: (a, b) => (a?.orderCount || 0) - (b?.orderCount || 0),
    },
    {
      title: "nb ventes sur Shopify (revendeur)",
      dataIndex: "shopifySales",
      width: 100,
      sorter: (a, b) => (a?.shopifySales || 0) - (b?.shopifySales || 0),
    },
    {
      title: "Contacts",
      dataIndex: "shopifySales",
      width: 300,
      render: (text, line) =>
        line.shopifyContacts ? (
          <div className="column">
            <p>{line.shopifyContacts.email} </p>
            <p>{line.shopifyContacts.phone} </p>
          </div>
        ) : (
          <div className="column">
            <p>{line.email} </p>
            <p>{line.company?.phone} </p>
          </div>
        ),
    },
    {
      title: "nb ventes sur Shopify (revendeur)",
      dataIndex: "shopifySales",
      width: 350,
      render: (text, line) => (
        <div className="column">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Thématic"
            defaultValue={line.thematic}
            onChange={(value) => handleSelectThematic({ thematic: value, userId: line._id })}
          >
            {thematics.map((theme) => (
              <Select.Option key={theme}>{theme}</Select.Option>
            ))}
          </Select>
          <Link
            style={{ marginTop: "10px" }}
            rel="noopener noreferrer"
            to={`/connect?token=${localStorage.getItem("tokenCreatosell")}&id=${line?._id}&type=${
              line?.type
            }&email=${line?.email}&secret_id=${line?.secret_id}`}
            className="text blue underline"
          >
            Login to this account
          </Link>
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "name",
      width: 100,
      render: (text, line) => <p>{parseDate(line?.create_at)}</p>,
      sorter: {
        compare: (a, b) =>
          parseInt(new Date(a?.create_at || 0).getTime()) - parseInt(new Date(b?.create_at || 0).getTime()),
      },
    },
  ];

  return (
    <Table
      style={{ width: "100%" }}
      pagination={false}
      columns={columns}
      scroll={{ x: 1650, y: 2000 }}
      dataSource={isNoThematic ? users.filter((user) => !user.thematic?.length) : users}
    />
  );
};

export default UserNoThematic;
