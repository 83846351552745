import React, { useState, useEffect } from "react";
import { Modal, Skeleton, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";

import { productServices } from "../../../services/products";

import ProductCard from "../../ProductCard";
import Header from './Header'

const HighMarginProducts = ({ user, setUser, setRegisterModalIsActive, windowSize }) => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadProduct, setIsLoadProducts] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const { t } = useTranslation();

  const PAGE_SIZE = 50

  const getProducts = () => {
    productServices
      .find({
        isHighMarginProducts: true,
        page: currentPage,
        limit: PAGE_SIZE,
        userId: localStorage.getItem("idAccountCreatosell") || "",
      })
      .then((res) => res.json())
      .then((resJson) => {
        const resProducts = resJson.data;
        setIsLoadProducts(false);
        setProducts(resProducts);
        setTotalProducts(resJson.totalProducts);
      });
  };

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  return (
    <div
      style={{
        boxShadow: "none",
        padding: windowSize <= 1150 && "0px",
        margin: windowSize <= 1150 && "0px",
        marginBottom: "120px",
      }}
      className="width-100"
    >
      <Header/>
      <div
        style={{ justifyContent: windowSize < 1150 && "center" }
        }
        className={`width-100 row-wrap`}
      >
        {products?.length > 0 && !isLoadProduct ? (
          <>
            {products.map((product, index) => {
              return (
                <ProductCard
                  setUser={setUser}
                  user={user}
                  key={product._id}
                  index={index}
                  location={"landing"}
                  product={product}
                />
              );
            })}
            <div className="row width-100 center">
              <Pagination
                  key={currentPage + products.length + totalProducts}
                style={{ marginBottom: "50px", marginTop: "30px" }}
                showSizeChanger={false}
                onChange={(page, pageSizeP) => {
                  setCurrentPage(page || 1);
                  setIsLoadProducts(true);

                  scroll.scrollToTop();
                }}
                defaultCurrent={parseInt(currentPage) || 1}
                total={totalProducts}
                pageSize={PAGE_SIZE}
              />
            </div>
          </>
        ) : (
          <>
            <Skeleton
              style={{ marginTop: "20px" }}
              className="image-product width-100"
              loading={true}
              active
            />
            <Skeleton
              style={{ marginTop: "20px" }}
              className="image-product width-100"
              loading={true}
              active
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HighMarginProducts;
