import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Switch, Space } from "antd";

import UpdateNumberModal from "./UpdateNumberModal";

import arrowRightIcon from "../../../../images/icons/arrow_right.png";

const defaultUpdateNumberValue = {
  defaultValue: null,
  title: null,
  handleSubmit: () => {},
};

const HandlePushToStoreSettings = ({ isImportPage, user, handleUpdateSetting }) => {
  const [updateNumberModal, setUpdateNumberModal] = useState(defaultUpdateNumberValue);
  const { t } = useTranslation();

  const handleRetailPriceRadioChange = (e) => {
    const value = e.target.value;
    if (value === "default") {
      handleUpdateSetting({
        retailPrice: {
          ...(user.retailPrice || {}),
          handlePush: {
            ...(user.retailPrice?.handlePush || {}),
            recommandedRetailPrice: false,
            customRetailPrice: false,
          },
        },
      });
    } else if (value === "recommandedRetailPrice") {
      handleUpdateSetting({
        retailPrice: {
          ...(user.retailPrice || {}),
          handlePush: {
            ...(user.retailPrice?.handlePush || {}),
            recommandedRetailPrice: true,
            customRetailPrice: false,
          },
        },
      });
    } else if (value === "customRetailPrice") {
      handleUpdateSetting({
        retailPrice: {
          ...(user.retailPrice || {}),
          handlePush: {
            ...(user.retailPrice?.handlePush || {}),
            recommandedRetailPrice: false,
            customRetailPrice: true,
          },
        },
      });
    }
  };

  return (
    <div style={{ marginLeft: "0", marginTop: "15px" }} className="card width-100">
      {updateNumberModal.title && (
        <UpdateNumberModal
          setIsDisplayed={() => setUpdateNumberModal(defaultUpdateNumberValue)}
          handleSubmit={updateNumberModal.handleSubmit}
          defaultValue={updateNumberModal.defaultValue}
          title={updateNumberModal.title}
        />
      )}
      {!isImportPage && (
        <p style={{ fontSize: "27px" }} className="title montserrat-800 grey start">
          {t("priceRules.handlePush.title")}
        </p>
      )}
      <p style={{ marginTop: "25px" }} className="title-500 start">
        {t("priceRules.handlePush.part1.title")}
      </p>
      <Radio.Group
        style={{ marginBottom: "15px", marginTop: "15px" }}
        className="blue"
        defaultValue={
          user?.retailPrice?.handlePush?.recommandedRetailPrice
            ? "recommandedRetailPrice"
            : user?.retailPrice?.handlePush?.customRetailPrice
            ? "customRetailPrice"
            : "default"
        }
        onChange={handleRetailPriceRadioChange}
      >
        <Space direction="vertical">
          <Radio value="default">{t("priceRules.handlePush.part1.option1")}</Radio>
          <Radio value="recommandedRetailPrice">{t("priceRules.handlePush.part1.option2")}</Radio>
          <Radio value="customRetailPrice">
            <p>{t("priceRules.handlePush.part1.option3")}</p>
            <div style={{ marginTop: "5px", fontSize: "13px" }} className="row width-100">
              <img src={arrowRightIcon} alt="arrow" style={{ width: "17px", marginRight: "10px" }} />
              {t("priceRules.handlePush.part1.multiplier")}{" "}
              <span
                onClick={() =>
                  setUpdateNumberModal({
                    defaultValue: user.retailPrice?.handlePush?.multiplier || 2.5,
                    title: t("priceRules.retailPrice.modal.title-1"),
                    handleSubmit: (value) => {
                      handleUpdateSetting({
                        retailPrice: {
                          ...user.retailPrice,
                          handlePush: {
                            ...user.retailPrice?.handlePush,
                            multiplier: value,
                          },
                        },
                      });
                      setUpdateNumberModal(defaultUpdateNumberValue);
                    },
                  })
                }
                className="text blue underline pointer"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                x{user.retailPrice?.handlePush?.multiplier || 2.5}
              </span>
            </div>
            <div style={{ marginTop: "5px", fontSize: "13px" }} className="row">
              <img src={arrowRightIcon} alt="arrow" style={{ width: "17px", marginRight: "10px" }} />
              {t("priceRules.handlePush.part1.setCents")}{" "}
              <span
                onClick={() =>
                  setUpdateNumberModal({
                    defaultValue: user.retailPrice?.handlePush?.cents || 99,
                    title: t("priceRules.retailPrice.modal.title-2"),
                    handleSubmit: (value) => {
                      handleUpdateSetting({
                        retailPrice: {
                          ...user.retailPrice,
                          handlePush: {
                            ...user.retailPrice?.handlePush,
                            cents: value,
                          },
                        },
                      });
                      setUpdateNumberModal(defaultUpdateNumberValue);
                    },
                  })
                }
                className="text blue underline pointer"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                {user.retailPrice?.handlePush?.cents || 99}
              </span>
            </div>
          </Radio>
        </Space>
      </Radio.Group>

      {/* DEFAULT STATUS */}
      <p style={{ marginTop: "25px" }} className="title-500">
        {t("priceRules.handlePush.part2.title")}
      </p>
      <Radio.Group
        style={{ marginBottom: "15px", marginTop: "15px" }}
        className="blue"
        defaultValue={user?.retailPrice?.handlePush?.defaultStatus?.draft ? "draft" : "publish"}
        onChange={(e) => {
          handleUpdateSetting({
            retailPrice: {
              ...user.retailPrice,
              handlePush: {
                ...user.retailPrice?.handlePush,
                defaultStatus: {
                  ...user.retailPrice?.handlePush?.defaultStatus,
                  draft: !!(e.target.value === "draft"),
                },
              },
            },
          });
        }}
      >
        <Space direction="vertical">
          <Radio value="publish">{t("priceRules.handlePush.part2.option1")}</Radio>
          <Radio value="draft">{t("priceRules.handlePush.part2.option2")}</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default HandlePushToStoreSettings;
