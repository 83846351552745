import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Progress } from "antd";

import "./style.scss";
import { useTranslation } from "react-i18next";

const SetupProgress = ({ user }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (user?._id && !user.products_id?.length) {
      if (user.import_list?.length > 8) {
        setCurrentStep(2);
      } else {
        setCurrentStep(1);
      }
    }
  }, [user]);

  if (user?.type === "seller" && currentStep !== 0) {
    return (
      <div className="setup-progress">
        <p style={{ fontSize: "15px" }} className="title-bold">
          {t("resellerSetupProgress.1")}
        </p>

        <Progress
          showInfo={false}
          percent={user.import_list?.length > 8 ? 90 : user.import_list?.length * 10}
          status="active"
          strokeColor={{ from: "#FFE27D", to: "#FFB950" }}
          style={{ marginBottom: "7px" }}
        />
        {currentStep === 1 ? (
          <>
            <p style={{ fontSize: "14px" }} className="title">
              {t("resellerSetupProgress.2")}
            </p>
            {!window.location.pathname?.includes("marketplace") && (
              <Link style={{ marginTop: "10px" }} to="/marketplace" className="button orange-back">
                {t("resellerSetupProgress.4")}
              </Link>
            )}
          </>
        ) : (
          currentStep === 2 && (
            <Link to="/import-list" className="button orange-back">
              {t("resellerSetupProgress.3")}
            </Link>
          )
        )}
      </div>
    );
  } else return <></>;
};

export default SetupProgress;
