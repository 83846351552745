import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import supplyImg from "../../images/icons/01supply_academy.svg";
import starsImg from "../../images/icons/stars.svg";

const { Panel } = Collapse;

const Faq = () => {
  const { t } = useTranslation();
  return (
    <div style={{ alignItems: "center" }} className="step-container column">
      <img alt="01supply academy" src={supplyImg} style={{ width: "250px" }} />
      <img style={{ marginTop: "30px", marginBottom: "10px" }} src={starsImg} />
      <p style={{ maxWidth: "700px" }} className="text-bold width-100 center">
        {t("components.course.7")}
        <br />
        <br />
        <span className="text-400">{t("components.course.8")}</span>
      </p>

      <div
        style={{ marginTop: "40px", justifyContent: "flex-start", alignItems: "flex-start" }}
        className="step-container"
      >
        <div className="course-left-container">
          <p className="title-bold montserrat grey" style={{ fontWeight: "800", fontSize: "33px" }}>
            {t("components.course.9")}
          </p>
        </div>
        <div className="course-right-container">
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
            defaultActiveKey={[]}
          >
            <Panel className="montserrat-bold" header="What kind of guarantees do you offer?" key="1">
              <p className="text-roboto montserrat">{t("components.course.10")}</p>
            </Panel>
            <Panel className="montserrat-bold" header="Where will I receive my course?" key="2">
              <p className="text-roboto montserrat">{t("components.course.11")}</p>
            </Panel>
            <Panel className="montserrat-bold" header="How do I register?" key="3">
              <p className="text-roboto montserrat">
                {t("components.course.12")}{" "}
                <a href="https://app.01supply.com/join-us" className="blue underline">
                  https://app.01supply.com/join-us
                </a>
              </p>
            </Panel>
            <Panel className="montserrat-bold" header="How can I contact you ?" key="4">
              <p className="text-roboto montserrat">
                {t("components.course.13")}{" "}
                <span onClick={() => window.tidioChatApi?.open()} className="text blue underline pointer">
                  {t("here")}
                </span>
                . {t("components.course.14")} support@01supply.com
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Faq;
