import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import IconCheckGreen from "../../../images/icons/check-green.svg";
import IconExternalLInk from "../../../images/icons/External-link.svg";
import IconClick from "../../../images/icons_white/Click.svg";
import IconNetwork from "../../../images/icons_white/Network.svg";
import IconParams from "../../../images/icons_white/Params.svg";

import { routes } from "../../../routes";

const SupplierMore = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="page">
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={windowSize > 1150 ? { paddingLeft: "0px", paddingTop: "0" } : {}}
          className="column-scrolling"
        >
          <div className="column back-gradient">
            <p className="title-white">Our resellers will</p>
            <h2 className="title-white-bold">automatically bring you sales every month</h2>
            <h2 className="text-white">
              So you don’t have to worry about marketing and competition anymore.
            </h2>
            <Link to={routes.joinUs(i18n.language).path + "?supplier"} className="button-white-2">
              Register
            </Link>
          </div>
          <div className="column selling-page">
            <div
              className="column"
              style={windowSize > 1150 ? { alignItems: "flex-start", width: "600px" } : { width: "90%" }}
            >
              <h1 className="title-roboto-bold">
                Here is why 94% of the merchants fail{" "}
                <span style={{ marginLeft: "5px" }} className="title-roboto">
                  to sell online :
                </span>
              </h1>
              <ul>
                <li style={{ marginTop: "25px" }}>
                  <p className="text-roboto-300">
                    Every year, more and more businesses are selling online.
                    <br />
                  </p>
                  <p className="text-roboto-bold">
                    It is becoming hard to get consistent sales with
                    <span style={{ marginLeft: "5px" }} className="text-roboto-bold-red">
                      increasing competition.
                    </span>
                  </p>
                </li>
                <li style={{ marginTop: "25px" }}>
                  <p className="text-roboto-300">Being an entrepreneur brings a lot of challenges and</p>
                  <p className="text-roboto-bold">you never know what will happen tomorrow.</p>
                </li>
                <li style={{ marginTop: "25px" }}>
                  <p className="text-roboto-300">
                    Everything could collapse if your website shuts down or if
                    <span style={{ marginLeft: "5px" }} className="text-roboto-bold">
                      new e-commerce
                    </span>
                    <span style={{ marginLeft: "5px" }} className="text-roboto-bold-red">
                      laws
                    </span>
                    <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                      make it harder for you to keep up.
                    </span>
                  </p>
                </li>
                <li style={{ marginTop: "25px" }}>
                  <p className="text-roboto-300">
                    <span style={{ marginLeft: "0px" }} className="text-roboto-bold-red">
                      Multinational corporations
                    </span>
                    <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-roboto-bold">
                      like Amazon, eBay and Alibaba are getting stronger each year.
                    </span>
                    And they have huge economic interests in taking new market shares.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="column selling-page back-black">
            <div
              className="column"
              style={windowSize > 1150 ? { alignItems: "flex-start", width: "600px" } : { width: "90%" }}
            >
              <p className="title-green">But we found a way to solve these problems</p>
              <div style={{ alignItems: "flex-start", marginTop: "30px" }} className="row">
                <img className="green-check-icon-selling-page" src={IconCheckGreen} />
                <p className="text-white-200">
                  To grow your business
                  <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-white-bold">
                    we must pull ourselves together
                  </span>
                  to successfully face the competition.
                </p>
              </div>
              <div style={{ alignItems: "flex-start", marginTop: "20px" }} className="row">
                <img className="green-check-icon-selling-page" src={IconCheckGreen} />
                <p className="text-white-200">
                  There are a lot of
                  <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-white-bold">
                    hidden & skilled marketing experts working online as resellers.
                  </span>
                  And they are looking to bring you sales.
                </p>
              </div>
            </div>
            <Link to={routes.joinUs(i18n.language).path + "?supplier"} className="button-white-2">
              Join us now
            </Link>
          </div>
          <div className="column selling-page">
            <div
              className="row"
              style={
                windowSize > 1150 ? { alignItems: "flex-start", width: "600px" } : { flexDirection: "column" }
              }
            >
              <div
                className="column"
                style={windowSize > 1150 ? { width: "30%", marginRight: "5%" } : { width: "100%" }}
              >
                <h3 className="title-roboto-bold">So what is 01supply for ?</h3>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/documentation/supplier"
                  className="text-gradient"
                >
                  Documentation <img style={{ height: "10px", width: "auto" }} src={IconExternalLInk} />
                </Link>
              </div>
              <div
                className="column"
                style={windowSize > 1150 ? { width: "65%" } : { width: "100%", marginTop: "30px" }}
              >
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-start", height: "120px" }
                      : { height: "150px", alignItems: "flex-start" }
                  }
                  className="row"
                >
                  <div className="img-back-gradient">
                    <img style={{ marginTop: "6px" }} src={IconClick} className="icon-supplier-more" />
                  </div>
                  <div className="column">
                    <p className="title-roboto">One click connect</p>
                    <p className="title-roboto-300">
                      Resellers can easily find you through the app and connect in one click.
                    </p>
                  </div>
                </div>
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-start", height: "120px" }
                      : { height: "150px", alignItems: "flex-start" }
                  }
                  className="row"
                >
                  <div className="img-back-gradient">
                    <img src={IconParams} className="icon-supplier-more" />
                  </div>
                  <div className="column">
                    <p className="title-roboto">Same business model</p>
                    <p className="title-roboto-300">If you already sell online, nothing changes for you.</p>
                  </div>
                </div>
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-start", height: "120px" }
                      : { height: "150px", alignItems: "flex-start" }
                  }
                  className="row"
                >
                  <div className="img-back-gradient">
                    <img src={IconNetwork} className="icon-supplier-more" />
                  </div>
                  <div className="column">
                    <p className="title-roboto">Reseller network</p>
                    <p className="title-roboto-300">
                      You can let 1.000 resellers work for you simultaneously if you want it to.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ justifyContent: "center" }} className="row selling-page back-gradient">
            <div
              style={
                windowSize > 1150
                  ? { alignItems: "flex-start", width: "600px", alignItems: "flex-end" }
                  : { width: "90%", flexDirection: "column" }
              }
              className="row"
            >
              <div className="column">
                <p
                  style={
                    windowSize > 1150
                      ? { width: "200px", marginBottom: "20px", marginLeft: "20px" }
                      : { width: "90%", marginBottom: "20px" }
                  }
                  className="title-white-bold"
                >
                  Register now for free
                </p>
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-end" }
                      : { flexDirection: "column", alignItems: "flex-start", width: "100%" }
                  }
                  className="row"
                >
                  <ul
                    style={
                      windowSize > 1150
                        ? { marginBottom: "0", marginRight: "150px" }
                        : { marginBottom: "20px" }
                    }
                  >
                    <li className="text-white-200">No credit card required</li>
                    <li className="text-white-200">No paid subscription</li>
                    <li className="text-white-200">Without commitment</li>
                  </ul>
                </div>
              </div>
              <Link
                style={{ marginTop: "0" }}
                to={routes.joinUs(i18n.language).path + "?supplier"}
                className="button-white-2"
              >
                Register now
              </Link>
            </div>
          </div>
          <div className="column selling-page">
            <div
              className="column"
              style={windowSize > 1150 ? { width: "600px" } : { width: "90%", marginLeft: "5%" }}
            >
              <p style={{ marginTop: "30px" }} className="title-roboto-bold">
                Frequently Asked Questions :
              </p>
              <p style={{ fontSize: "18px", marginTop: "30px" }} className="text-roboto-bold">
                Do I have to provide more work ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                No, you don’t. If you are already selling online, working with resellers only means more
                sales.
                <br />
                If you are not selling online yet, the process is the same.
                <span style={{ fontStyle: "italic", marginLeft: "5px" }}>
                  You will get orders with delivery information and you just have to ship to the right
                  address.
                </span>
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                Why would resellers work for me for free ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                Resellers do want to expand their product catalog. This is how they get customers.
                <br />
                Selling your products means more opportunities for them to get sales.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                How do I get orders from resellers ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                Once your account is created, you will find your orders in the “Order” section on your
                01supply.com account.
                <br />
                You can either list your products on the marketplace and wait for resellers to sell it for you
                or invite your own reseller on the app.
                <br />
                <br />
                Once you get an order, you will be instantly notified by email.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                Where should I ship my orders ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                Once you get an order, the delivery information will be displayed. Just ship it to the
                customer’s address based on the delivery methods you set up.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                When do I get paid ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                You get paid once the order is placed. The money directly goes on your Stripe account.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                Do I have to pay for fees ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                You only have to pay for fees once you get a sale. You can see our pricing on
                <Link style={{ textDecoration: "underline", marginLeft: "5px" }} to="/supplier-pricing">
                  this page.
                </Link>
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                I don’t have time to list my products on the app, what can I do ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                No worries, we implemented a bulk import feature. If you already have a product csv / excel
                file, you can import all of your products in one click.
                <br />
                <br />
                Once registered you can also send us a message and we will be happy to help you set up your
                products for you.
              </p>
            </div>
          </div>
          <div
            style={{ marginTop: "45px", justifyContent: "center", width: "100%", marginBottom: "50px" }}
            className="row"
          >
            <Link
              style={{ marginRight: "25px" }}
              className="button orange-back"
              to={routes.joinUs(i18n.language).path + "?supplier"}
            >
              REGISTER NOW
            </Link>
            <Link style={{ marginLeft: "25px" }} className="button-gradient" to="/pricing">
              See pricing
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierMore;
