import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Pagination, Skeleton } from "antd";

import { url } from "../../api/url";
import { header } from "../../api/header";
import { productServices } from "../../services/products";

import Product from "../../components/ProductCard";
import CheckToken from "../../components/check-token/CheckToken";
import { Link, Redirect } from "react-router-dom";
import Menu from "../../components/menu/FirstMenu";
import { PlusOutlined } from "@ant-design/icons";
import PopupIcon from "../../images/join-illustration3.svg";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import RedirectLogin from "../../utils/RedirectLogin";
import Load from "../../utils/Load";
import { sendGridEmail } from "../../utils/SendGridEmail";
import { pushProductsToInventory } from "../../services/pushPorductsToInventory";
import { resellerHaveShop } from "../../utils/resellerHaveShop";
import ConnectShop from "../../components/reseller/settings/store/connectShop/index";
import ImportListHeaderContent from "../../components/importList/HeaderContent";
import WizishopShopSelector from "../../components/wizishop/WizishopShopSelection";
import FixedImportButton from "../../components/importList/FixedImportButton";
import ImportedProcessHeader from "../../components/inventory/ImportedProcessHeader";

import { resellerPlans } from "../../constants/resellerPlans";
import { klaviyoServices } from "../../services/klaviyo";
import { wizishopServices } from "../../services/wizishop";
import { urlParams } from "../../utils/urlParams";

import { Modal } from "../../wrappers";

import ModalNeedUpgradePlan from "../../components/needUpgradePlan";
import EmptyList from "../../components/importList/EmptyList";

const ImportList = () => {
  const paramsUrl = urlParams();

  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [products, setProducts] = useState([]);
  const [tokenCheck, setTokenCheck] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [load, setLoad] = useState(false);
  const [popupWarning, setPopupWarning] = useState(false);
  const [productSelection, setProductSelection] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [alreadyProductsIdPush, setAlreadyProductsIdPush] = useState([]);
  const [isModalReconnectWizishopDisplayed, setIsModalReconnectWizishopDisplayed] = useState(false);
  const [selectedWizishopShopId, setSelectedWizishopShopId] = useState("");
  const [warningQuota, setWarningQuota] = useState({});
  // const [productPushSettingsProps, setProductPushSettingsProps] = useState({});
  const [modalPlanDisplayed, setIsModalPlanDisplayed] = useState({
    isModalDisplayed: false,
    currentPlan: {},
    nextPlans: [],
  });
  const [needShopModal, setNeedShopModal] = useState({
    isDisplayed: false,
    callback: () => true,
  });

  const [page, setPage] = useState(paramsUrl.page || 1);
  const [totalProducts, setTotalProducts] = useState(0);

  const { t } = useTranslation();
  const history = useHistory();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUser({ isRefresh: false });
    if (window.tidioChatApi) {
      window?.tidioChatApi?.hide();
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (user && user.import_list) {
      getProducts();
    }
  }, [page]);

  const getUser = async ({ isRefresh }) => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      const userRes = resJson.data;
      setUser(userRes);
      if (resJson.needToReconnectWizishop) {
        setIsModalReconnectWizishopDisplayed(true);
      }
      if (userRes.import_list !== totalProducts && isRefresh) {
        getProducts();
      }
    }
  };

  const getProducts = async () => {
    const res = await productServices.findImportList({ page });
    const resJson = await res.json();
    if (!resJson?.data?.length) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      setProducts(resJson.data);
      setTotalProducts(resJson.totalItems);
    }
  };

  const isCheckImportPlan = ({ newProductsPush }) => {
    const currentPlan = user.plan
      ? resellerPlans.find((plan) => plan.type === user.plan.type)
      : resellerPlans[0];

    const currentProductsNumber = parseInt(user.import_list?.length) + parseInt(user.products_id?.length);
    const nextPlans =
      currentProductsNumber < 25
        ? [resellerPlans[1], resellerPlans[2], resellerPlans[3]]
        : currentProductsNumber < 249
        ? [resellerPlans[2], resellerPlans[3]]
        : currentProductsNumber < 1000000
        ? [resellerPlans[3]]
        : undefined;
    if (nextPlans) {
      if (currentPlan.maxProducts < newProductsPush.length + user.products_id.length) {
        setIsModalPlanDisplayed({
          isModalDisplayed: true,
          currentPlan,
          nextPlans,
        });
        setLoad(false);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const redirectToInventory = () => {
    setLoad(true);
    setTimeout(() => {
      if (!user.shopify?.token && !user.products_id?.length && !user.wizishop?.token) {
        // if is first import product by no shopify store
        setRedirect("/inventory?csv-export=true");
      } else {
        setRedirect("/inventory?shopifyPush=true");
      }
    }, 2000);
  };

  const handleProductsPush = () => {
    // wait prodict push to user
    setTimeout(() => {
      klaviyoServices.checkStatus();
      message.success(t("messages.pushToStore"));
      redirectToInventory();

      if (!user.products_id.length)
        sendGridEmail("d-03ce3dea48ca4d1bb6da9675d770f121", user.email, {}, token);
    }, 900);
  };

  const handleProductsPushSelection = () => {
    klaviyoServices.checkStatus();
    let newProducts = products.filter((product) => !productSelection.includes(product._id));
    setProducts([...newProducts]);
    if (newProducts.length < 1) {
      redirectToInventory();
    } else {
      setUser({
        ...user,
        import_list: user.import_list.filter((id) => !productSelection.includes(id)),
        products_id: [...productSelection, ...user.products_id],
      });
      setLoad(false);
    }
    setProductSelection([]);
    message.success(t("messages.pushToStore"));

    if (!user.products_id.length) sendGridEmail("d-03ce3dea48ca4d1bb6da9675d770f121", user.email, {}, token);
  };

  const pushToCMS = async ({ isSelectionPush, isShopify, isWizishop }) => {
    const importIds = isSelectionPush
      ? productSelection
      : user.import_list.filter((id) => !alreadyProductsIdPush.includes(id));
    const shopifyRequest = async () => {
      return await fetch(`${url}/product/push-products-shopify/${idAccount}/${secretId}`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          importList: importIds,
        }),
      });
    };
    const wizishopRequest = async () => {
      return await wizishopServices.importProduct({
        productIds: importIds,
        shopId: selectedWizishopShopId,
      });
    };
    if (isCheckImportPlan({ newProductsPush: productSelection.length ? productSelection : products })) {
      if (isSelectionPush) {
        setAlreadyProductsIdPush([...alreadyProductsIdPush, ...productSelection]);
        handleProductsPushSelection();
      } else {
        handleProductsPush();
      }
      const res = isShopify ? await shopifyRequest() : await wizishopRequest();
      if (res.status === 203) {
        setLoad(false);
        message.error(t("messages.importLoading"));
      } else {
        setLoad(false);
        // message.error(t("messages.error"));
      }
    }
  };

  const deleteSelection = async () => {
    let newImportList = user.import_list;
    newImportList = newImportList.filter((idImport) => {
      return !productSelection.includes(idImport);
    });

    setProductSelection([]);
    setProducts(products.filter((products) => !productSelection.includes(products._id)));
    message.success(t("messages.productDeleted"));

    setUser({ ...user, import_list: newImportList });
    const res = await fetch(`${url}/user/delete-import-products/${user._id}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        importList: newImportList,
      }),
    });
    if (res.status === 200) {
    } else {
      setLoad(false);
      message.error(t("messages.error"));
    }
  };

  const handleClickPush = () => {
    // setProductPushSettingsProps({});
    const isCmsConnectedShop = user.shopify?.shop || user.wizishop?.user_id;
    const push = () => {
      setLoad(true);
      if (!load) {
        if (isCmsConnectedShop) {
          pushToCMS({
            isSelectionPush: productSelection.length ? true : false,
            isShopify: user.shopify?.shop ? true : false,
            isWizishop: user.wizishop?.user_id ? true : false,
          });
        } else {
          if (isCheckImportPlan({ newProductsPush: productSelection.length ? productSelection : products })) {
            if (productSelection.length) {
              pushProductsToInventory(
                products.filter((product) => productSelection.includes(product._id)),
                handleProductsPushSelection
              );
            } else {
              pushProductsToInventory(products, handleProductsPush);
            }
          }
        }
      }
    };
    if (resellerHaveShop(user)) {
      push();
    } else {
      setNeedShopModal({ isDisplayed: true, callback: push });
    }
  };

  useEffect(() => {
    // set wizishop shop id selected when we connect wizishop
    if (!selectedWizishopShopId && user.wizishop?.shop_list?.length) {
      setSelectedWizishopShopId(user.wizishop?.shop_list[0].id);
    }
  }, [user]);

  return (
    <div className="page">
      {load && <Load />}
      <CheckToken setTokenCheck={setTokenCheck} />
      <RedirectLogin />

      {/* SETTINGS PUSH 

      {productPushSettingsProps?.user && (
        <ProductPushSettings
          callback={productPushSettingsProps.callback}
          setUser={setUser}
          user={user}
          handleClose={() => setProductPushSettingsProps({})}
        />
      )}

      */}

      {modalPlanDisplayed.isModalDisplayed && (
        <ModalNeedUpgradePlan
          setIsModalDisplayed={setIsModalPlanDisplayed}
          currentPlan={modalPlanDisplayed.currentPlan}
          nextPlans={modalPlanDisplayed.nextPlans}
        />
      )}
      {needShopModal.isDisplayed && (
        <ConnectShop
          isDisplayed={needShopModal.isDisplayed}
          onCancel={() => setNeedShopModal({ isDisplayed: false, callback: () => false })}
          user={user}
          setUser={setUser}
        />
      )}
      <FixedImportButton
        productSelection={productSelection}
        windowSize={windowSize}
        handleClickPush={handleClickPush}
      />
      {redirect && <Redirect to={redirect} />}
      {tokenCheck && (
        <div className="page-start">
          {windowSize > 1150 ? (
            <Menu isModalReconnectWizishopDisplayedP={isModalReconnectWizishopDisplayed} userProps={user} />
          ) : (
            <MobileMenu
              isModalReconnectWizishopDisplayedP={isModalReconnectWizishopDisplayed}
              datas={products}
              setDatas={setProducts}
              attribut="name"
            />
          )}
          <div
            className="column-scrolling"
            style={
              windowSize > 1150
                ? {
                    display: "flex",
                    alignItems: "center",
                    paddingTop: windowSize > 1150 ? "120px" : "0px",
                    paddingBottom: "100px",
                  }
                : {
                    alignItems: "center",
                    paddingBottom: "100px",
                    paddingTop: "0px",
                    height: "auto",
                  }
            }
          >
            <div style={windowSize > 1150 ? { marginLeft: "30px" } : {}} className="header">
              <div
                className="row"
                style={
                  windowSize > 1150
                    ? { width: "95%", marginBottom: "-30px" }
                    : { width: "100%", marginBottom: "-30px" }
                }
              >
                <h1 className="title-bold montserrat width-100">{t("importListPage.1")}</h1>
                {windowSize > 1150 && (
                  <p style={{ position: "absolute", right: "150px" }}>{user.shopify?.shop}</p>
                )}
              </div>
              <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
            </div>

            <ImportedProcessHeader
              getUser={() => getUser({ isRefresh: true })}
              inProgressRequestCount={user.in_progress_requests?.products_import?.length}
            />

            {!load && user.wizishop?.shop_list && products?.length > 0 && (
              <WizishopShopSelector
                setSelectedWizishopShopId={setSelectedWizishopShopId}
                selectedWizishopShopId={selectedWizishopShopId}
                user={user}
                setUser={setUser}
                title={t("selectYourStore")}
              />
            )}

            {products?.length > 0 && (
              <ImportListHeaderContent
                productSelection={productSelection}
                handleClickPush={handleClickPush}
                windowSize={windowSize}
              />
            )}

            {!load && (
              <div
                style={windowSize > 1150 ? { marginTop: "10px" } : { marginTop: "30px" }}
                className="row width-100 start"
              >
                {productSelection.length > 0 && (
                  <button
                    style={{
                      marginTop: `${windowSize > 1150 ? "0" : "15px"}`,
                      height: "46px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      deleteSelection();
                    }}
                    type="primary"
                    htmlType="submit"
                    className="button-red"
                  >
                    <div className="row">
                      <React.Fragment>
                        {" "}
                        {t("delete").toUpperCase()} ({productSelection.length})
                      </React.Fragment>
                    </div>
                  </button>
                )}
              </div>
            )}

            <div key={page} className="row-wrap width-100">
              {isEmpty ? (
                <EmptyList windowSize={windowSize} />
              ) : products.length > 0 ? (
                products.map((product) => {
                  return (
                    <Product
                      key={product._id}
                      setProductSelection={setProductSelection}
                      productSelection={productSelection}
                      location="import-list"
                      product={product}
                    />
                  );
                })
              ) : (
                <>
                  <Skeleton
                    style={{ marginTop: "100px" }}
                    className="image-product width-100"
                    loading={true}
                    active
                  />
                  <Skeleton
                    style={{ marginTop: "20px" }}
                    className="image-product width-100"
                    loading={true}
                    active
                  />
                </>
              )}
            </div>

            <div className="width-100 center">
              {products.length > 0 && !isEmpty && (
                <Pagination
                  key={page + products.length + totalProducts}
                  showSizeChanger={false}
                  style={{ marginBottom: "50px", marginTop: "30px" }}
                  onChange={(page) => {
                    setProducts([]);
                    setPage(page);
                    history.push(`/import-list?page=${page}`);
                  }}
                  defaultCurrent={page || 1}
                  total={totalProducts}
                  pageSize={30}
                  defaultPageSize={30}
                />
              )}
            </div>
          </div>
          {localStorage.getItem("typeAccountCreatosell") === "creater" && (
            <Link className="add-absolute" to="/create-product">
              <PlusOutlined />
            </Link>
          )}
        </div>
      )}

      {tokenCheck && popupWarning && (
        <Modal
          className="modal-account"
          visible={true}
          onCancel={() => setPopupWarning(false)}
          footer={[
            <button className="button-red" key="submit" type="primary" onClick={() => setPopupWarning(false)}>
              {t("cancel".toUpperCase())}
            </button>,
          ]}
        >
          <div style={{ alignItems: "center" }} className="column">
            <img style={{ height: "100px", width: "auto" }} src={PopupIcon} className="user-icon-popup" />
            <p style={{ marginBottom: "20px", marginTop: "40px" }} className="title-300">
              {t("importListPage.7")}
            </p>
            <Link to="/your-shop-reseller" className="button orange-back">
              {t("importListPage.8")}
            </Link>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ImportList;
