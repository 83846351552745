import React from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

import { Modal } from "../../wrappers";

const ManualFulfillOrder = ({ setTrackingNumber, setCarrier, setIsDisplayed, handleSend }) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-account"
      visible={true}
      onCancel={() => {
        setTrackingNumber("");
        setCarrier("");
        setIsDisplayed(false);
      }}
      footer={[]}
    >
      <div className="column width-100">
        <p className="title-bold">{t("fulfillment.title")}</p>
        <p style={{ marginTop: "15px", marginBottom: "5px" }} className="text width-100">
          {t("fulfillment.1")}
        </p>
        <Input
          className="width-100"
          onChange={(e) => setCarrier(e.target.value)}
          placeholder={t("fulfillment.1")}
        />
        <p style={{ marginTop: "15px", marginBottom: "5px" }} className="text width-100">
          {t("fulfillment.2")}
        </p>
        <Input
          className="width-100"
          onChange={(e) => setTrackingNumber(e.target.value)}
          placeholder={t("fulfillment.2")}
        />

        <button style={{ marginTop: "35px" }} onClick={handleSend} className="button orange-back width-100">
          {t("fulfillment.button")}
        </button>
      </div>
    </Modal>
  );
};

export default ManualFulfillOrder;
