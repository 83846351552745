import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";

import { Input } from "antd";
import "./style.scss";

import {routes} from '../../routes'

const GetStarted = ({ redirectPath }) => {
  const [redirect, setRedirect] = useState(false);

  const { t, i18n } = useTranslation();
  return (
    <div className="get-started-container">
      {redirect && <Redirect to={redirectPath} />}
      <Input
        onPressEnter={() => {
          setRedirect(true);
        }}
        placeholder={t("yourEmailAddress")}
        onChange={(e) => {
          sessionStorage.setItem("registerDefaultEmail01supply", e.target.value);
        }}
        className="get-started-input"
      />
      <Link
        to={routes.joinUs(i18n.language).path+"?reseller"}
        style={{
          boxShadow: "0px 2px 5px rgb(200, 200, 200)",
          right: "-5px",
          fontSize: "16px",
          width: "120px",
        }}
        className="button-in-input a-hover button big orange-back"
      >
        {t("getStarted")}
      </Link>
    </div>
  );
};

export default GetStarted;
