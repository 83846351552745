import React from "react";
import { useTranslation } from "react-i18next";
import { PayPalButton } from "react-paypal-button-v2";
import { message } from "antd";

import { courseServices } from "../../services/course";

import paypalButtonImg from "../../images/illustrations/paypal_button.png";

const PaypalPayment = ({ email, courseId, setIsCoursePaid, isBadEmail, setIsBadEmail }) => {
  const { t } = useTranslation();
  let newPaymentId = "";

  const paypalDivElement = document.getElementsByClassName("paypal-button-course")[0];
  if (paypalDivElement) {
    const childrenElement = paypalDivElement.children[0];
    if (childrenElement) {
      childrenElement.style.width = "100%";
    }
  }

  const handlePaymentSend = (details) => {
    courseServices
      .validPayment({ paypalPaymentId: details.id, paymentId: newPaymentId })
      .then(async (res) => {
        if (res.status === 200) {
          setIsCoursePaid(true);
          message.success(t("message.orderPlaced"));
        } else {
          message.error(t("messages.error"));
        }
      });
  };

  const handleClickButton = (e) => {
    setIsBadEmail(true);
    message.error(t("message.needFill.price"));
  };

  return (
    <>
      {process.env.REACT_APP_PAYPAL_PUBLIC_KEY && (
        <div className="paypal-button-course">
          {isBadEmail || !email ? (
            <img
              style={{ width: "100%" }}
              className="pointer"
              onClick={handleClickButton}
              src={paypalButtonImg}
            />
          ) : (
            <PayPalButton
              className="payment-button"
              style={{
                layout: "horizontal",
                tagline: "false",
                width: "50vw",
                maxWidth: "500px",
                minWidth: "500px",
              }}
              shippingPreference="NO_SHIPPING"
              options={{
                clientId: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
                currency: "USD",
              }}
              createOrder={async (dataP, actions) => {
                const createdCourse = await courseServices.createPayment({ courseId, email });
                if (createdCourse.status === 200) {
                  const resJson = await createdCourse.json();
                  const { amount, paymentId } = resJson;
                  newPaymentId = paymentId;
                  const amountNumber = parseFloat(amount);
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          currency_code: "USD",
                          value: amountNumber,
                        },
                        invoice_id: paymentId,
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
                    },
                  });
                } else {
                  message.error(t("messages.error"));
                }
              }}
              onApprove={(data, actions) => {
                // Capture the funds from the transaction
                return actions.order.capture().then(function (details) {
                  // Show a success message to your buyer

                  // OPTIONAL: Call your server to save the transaction

                  handlePaymentSend(details);
                });
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PaypalPayment;
