import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { url } from "../../api/url";
import { header } from "../../api/header";
import shopBannerImg from "../../images/illustrations/shop_banner.svg";
import defaultProfilImgInput from "../../images/icons/add_profil.png";
import defaultProfilImg from "../../images/illustrations/supplier.png";
import { getCountry } from "../../utils/getCountry";
import UploadImage from "../UploadSimpleImage";
import ContactButton from "../ContactButton";

import "./style.css";

const HeaderShop = ({ supplier, user, windowSize }) => {
  const [profilePicture, setProfilePicture] = useState("");
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [imageUpload, setImageUpload] = useState({});
  const [bannerUpload, setBannerUpload] = useState("");

  const { t } = useTranslation();

  const getProfilePicture = async () => {
    if (supplier.profile_picture) {
      setProfilePicture(supplier.profile_picture);
    }

    if (supplier.shop?.banner) {
      setBannerUpload(supplier.shop?.banner);
    }
  };

  useEffect(() => {
    if (supplier.shop) {
      getProfilePicture();
    }
  }, [supplier]);

  const handleChangeBanner = () => {
    function getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        updateProfilePicture("shop_banner", { base64: reader.result });
        setBannerUpload(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
    const file = document.getElementById("banner-upload").files[0];
    getBase64(file);
  };

  useEffect(() => {
    if (imageUpload.base64) {
      updateProfilePicture("profile_picture", imageUpload);
    }
  }, [imageUpload]);

  const updateProfilePicture = async (imageType, image) => {
    if (image.base64) {
      const res = await fetch(`${url}/user/picture/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          picture: image,
          lastImageId: supplier.profile_picture,
          imageType: imageType,
        }),
      });
      if (res.status === 200) {
        message.success(t("messages.imageUpdated"));
        setImageUpload({});
      }
    }
  };

  return (
    <div style={{ position: "relative" }} className="card">
      <img
        src={bannerUpload || shopBannerImg}
        style={{
          width: "calc(100% + 40px)",
          marginTop: "-20px",
          marginLeft: "-20px",
          objectFit: "cover",
          maxHeight: "300px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
        className="image-cover"
        alt={`cover ${supplier.shop?.name}`}
      />
      {user.shop && supplier.shop && user.shop?.name === supplier.shop?.name && (
        <button
          className="button orange-back"
          style={{ position: "absolute", top: "30px", right: "30px", zIndex: "10", maxWidth: "120px" }}
          onClick={() => document.getElementById("banner-upload")?.click()}
        >
          {t("components.supplierShop.3")}
        </button>
      )}
      <input
        style={{ display: "none" }}
        type="file"
        onChange={(file) => handleChangeBanner(file)}
        id="banner-upload"
        accept=".png, .jpg, .jpeg"
      />
      <div
        style={windowSize < 1150 ? { display: "none" } : {}}
        key={profilePicture}
        className="container-profile-picture"
      >
        {!user.shop || !supplier.shop || user.shop?.name !== supplier.shop?.name ? (
          <img
            style={{ height: "160px" }}
            alt={`profile ${supplier.shop?.name}`}
            src={profilePicture?.includes("https") ? profilePicture : defaultProfilImg}
          />
        ) : (
          <UploadImage
            image={profilePicture?.includes("https") ? profilePicture : defaultProfilImgInput}
            setImage={setImageUpload}
          />
        )}
      </div>
      <div
        style={{
          marginLeft: windowSize > 1150 && "230px",
          marginTop: windowSize > 1150 ? "30px" : "20px",
          width: windowSize > 1150 ? "calc(100% - 230px)" : "100%",
          posiiton: "relative",
          minHeight: windowSize > 1150 && "200px",
        }}
        className={windowSize > 1150 ? "row" : "column"}
      >
        <div style={{ width: windowSize > 1150 && "calc(100% - 180px)" }} className="column">
          <h1 style={{ fontSize: "30px", marginBottom: "0" }} className="title-bold text-ellipsis">
            {supplier.shop?.name}
          </h1>
          <h3 className="text-300 width-100">{supplier.shop?.describe}</h3>
          <p style={{ marginTop: "10px" }} className="text grey">
            {supplier?.company?.country_code ? getCountry(supplier?.company?.country_code) : ""}
          </p>
        </div>
        {supplier._id && (
          <div
            style={
              windowSize > 1150
                ? { position: "absolute", right: "30px", bottom: "60px" }
                : { marginTop: "20px" }
            }
            className="column"
          >
            <p>{supplier.firstname}</p>
            <ContactButton
              css={{ width: "130px", height: "50px", fontSize: "18px" }}
              isGrey={true}
              contactId={supplier._id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderShop;
