import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../constants/languages";

const initialState = {
  currency: localStorage.getItem("userCurrency") || "USD",
  country: localStorage.getItem("userCountry") || "🇺🇸 United States",
  deliverTo: localStorage.getItem("deliverTo") || "🇺🇸 United States",
  lang: "en",
};

export const LocalStatesContext = React.createContext(initialState);

LocalStatesContext.displayName = "User";

const LocalStatesProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [localStates, setLocalStates] = useState(initialState);

  useEffect(() => {
    let initLocalStates = initialState;
    if (
      !localStorage.getItem("userCurrency") &&
      (i18n.language.includes("fr") ||
        i18n.language.includes("de") ||
        i18n.language.includes("pt") ||
        i18n.language.includes("es"))
    ) {
      initLocalStates = { ...initLocalStates, currency: "EUR" };
      localStorage.setItem("userCurrency", "EUR");
    }

    // FR COUNTRY
    if (!localStorage.getItem("userCountry") && i18n.language.includes("fr")) {
      initLocalStates = { ...initLocalStates, country: "🇫🇷 France" };
      localStorage.setItem("userCountry", "🇫🇷 France");
    }

    // DE COUNTRY
    if (!localStorage.getItem("userCountry") && i18n.language.includes("de")) {
      initLocalStates = { ...initLocalStates, country: "🇩🇪 Germany" };
      localStorage.setItem("userCountry", "🇩🇪 Germany");
    }

    // PT COUNTRY
    if (!localStorage.getItem("userCountry") && i18n.language.includes("pt")) {
      initLocalStates = { ...initLocalStates, country: "🇵🇹 Portugal" };
      localStorage.setItem("userCountry", "🇵🇹 Portugal");
    }

    // HI COUNTRY
    if (!localStorage.getItem("userCountry") && i18n.language.includes("hi")) {
      initLocalStates = { ...initLocalStates, country: "🇮🇳 India" };
      localStorage.setItem("userCountry", "🇮🇳 India");
    }

    // ES COUNTRY
    if (!localStorage.getItem("userCountry") && i18n.language.includes("es")) {
      initLocalStates = { ...initLocalStates, country: "🇮🇪 Spain" };
      localStorage.setItem("userCountry", "🇮🇪 Spain");
    }

    if (!localStorage.getItem("userCountry")) {
    }

    initLocalStates = {
      ...initLocalStates,
      lang: i18n.language && languages.includes(i18n.language) ? i18n.language : "en",
    };

    setLocalStates(initLocalStates);
  }, []);

  const context = {
    localStates,
    setLocalStates,
  };

  return <LocalStatesContext.Provider value={context}>{children}</LocalStatesContext.Provider>;
};

export default LocalStatesProvider;
