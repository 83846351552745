import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { url } from "../../api/url";
import { header } from "../../api/header";

import Menu from "../../components/menu/AffiliateMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import CheckToken from "../../utils/RedirectLogin";
import { userServices } from "../../services/user";
import StatsCard from "../../components/affiliation/StatsCard";
import Load from "../../utils/Load";

import "./style.scss";

const Statistics = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount, setIdAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId, setSecretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [user, setUser] = useState({});
  const [affiliations, setAffiliations] = useState([]);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    userServices
      .me()
      .then((res) => res.json())
      .then((res) => setUser(res.data));
    userServices
      .findAffiliation()
      .then((res) => res.json())
      .then((res) => setAffiliations(res.affiliation));
  }, []);

  return (
    <div className="page affiliation">
      <CheckToken />
      {!affiliations._id && <Load />}
      <div className="page-start">
        {windowSize > 1150 ? <Menu user={user} /> : <MobileMenu user={user} locationP="affiliation" />}
        <div
          style={
            windowSize > 1150
              ? { padding: "50px", paddingLeft: "30px", paddingTop: "30px" }
              : { padding: "50px", paddingBottom: "100px", paddingLeft: "30px", paddingRight: "30px" }
          }
          className="column-scrolling start"
        >
          <h1
            style={{ fontSize: "28px", marginBottom: "30px" }}
            className="title montserrat-800 width-100 start"
          >
            {t("affiliateDashboard.statistics.h1")}
          </h1>

          <StatsCard user={user} affiliations={affiliations} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
