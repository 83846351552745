import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import {Modal} from '../../wrappers'

import ValidIcon from "../../images/icons/valid.svg";
import ChromeIcon from "../../images/icons/Chrome.svg";

const ExtensionModal = ({ setModalOn, windowSize, productUrl }) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000"
      visible={true}
      onCancel={() => {
        setModalOn(false);
      }}
      footer={[
        <div className="row width-100 end">
          <Button onClick={() => setModalOn(false)}>{t("cancel")}</Button>
          {productUrl && (
            <a style={{ marginLeft: "20px" }} target="_blank" href={productUrl} rel="noopener noreferrer">
              <Button type="primary">{t("goEtsyProduct")}</Button>
            </a>
          )}
        </div>,
      ]}
    >
      <div style={{ paddingBottom: "0px", alignItems: "center" }} className="column">
        <img src={ValidIcon} style={{ height: "90px", marginBottom: "20px" }} />
        <p className="title-bold center">{t("components.modal.26")}</p>
        <div
          className="divider-horizontal"
          style={{ width: "90%", marginLeft: "0", marginTop: "30px", marginBottom: "30px" }}
        />
        <div
          style={windowSize > 1150 ? {} : { flexDirection: "column", alignItems: "center" }}
          className="row space-between align-end"
        >
          <div style={{ width: windowSize > 1150 ? "60%" : "100%" }} className="column">
            <p style={{ marginBottom: "10px" }} className="title-bold center">
              {t("components.modal.27")}
            </p>
            <div className="row">
              {windowSize > 1150 && (
                <img
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
                  src={ChromeIcon}
                  style={{ height: "40px", marginRight: "30px" }}
                />
              )}
              <p className="title-300 start">{t("components.modal.28")}</p>
            </div>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
            style={windowSize > 1150 ? {} : { marginTop: "20px" }}
            className="button big orange-back"
          >
            {t("components.modal.29")}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ExtensionModal;
