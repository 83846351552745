import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Input, message, Button, Radio, Space, Form } from "antd";
import { Helmet } from "react-helmet";

import { header } from "../api/header";
import { url } from "../api/url";
import { urlParams } from "../utils/urlParams";
import { registrationEvent } from "../utils/analyticEvents";

import RedirectLogin from "../utils/RedirectLogin";
import CreatePasswordNewAccount from "../components/joinUs/CreatePasswordNewAccount";
import ConnectShopify from "../components/reseller/ConnectShopify";
import GoogleLogin from "../components/joinUs/GoogleLogin";
import { Modal } from "../wrappers";

import Load from "../utils/Load";
import logo from "../images/logo.svg";
import OgImg from "../images/og_image.png";
import { klaviyoServices } from "../services/klaviyo";

const ModalRegister = ({
  setLoad,
  setEmail,
  loginBool,
  type,
  regexEmail,
  email,
  checkEmailExist,
  register,
  connect,
  setLoginBool,
  password,
  setPassword,
  setType,
  isRegister,
  setStepResetPassword,
}) => {
  const { t } = useTranslation();
  const registerF = async () => {
    if (!loginBool) {
      if (!type) message.error(t("messages.pleaseSelectAccountType"));
      else if (regexEmail(email)) {
        if (await checkEmailExist()) {
          setLoad(true);
          register();
        }
      } else message.error(t("messages.pleaseCorrectEmail"));
    } else {
      setLoad(true);
      connect();
    }
  };

  useEffect(() => {
    if (!loginBool && !isRegister) setLoginBool(true);
  }, []);

  return (
    <div style={{ alignItems: "center", width: "100%" }} className="column">
      <img src={logo} style={{ width: "80%", height: "auto" }} />
      <div style={{ marginTop: "30px", marginBottom: "25px" }} className="row">
        <p
          onClick={() => setLoginBool(false)}
          className="title-200 pointer"
          style={
            !loginBool
              ? { fontWeight: "bold", borderBottom: "2px solid black", marginRight: "10px" }
              : { marginRight: "10px" }
          }
        >
          {t("register").toUpperCase()}
        </p>
        <p
          onClick={() => setLoginBool(true)}
          className="title-200 pointer"
          style={
            loginBool
              ? { fontWeight: "bold", borderBottom: "2px solid black", marginLeft: "10px" }
              : { marginLeft: "10px" }
          }
        >
          {t("login").toUpperCase()}
        </p>
      </div>
      <Input
        style={{ width: "90%" }}
        id="email-login-input"
        defaultValue={email}
        maxLength={150}
        type="email"
        onPressEnter={registerF}
        placeholder={t("yourEmailAddress")}
        onChange={(e) => {
          setEmail(e.target.value);
          setTimeout(() => {
            document.getElementById("email-login-input").focus();
          }, 30);
        }}
        className="input"
      />

      <Input.Password
        id="password-login-input"
        style={{ marginTop: "15px", marginBottom: "10px", width: "90%" }}
        maxLength={60}
        type="password"
        onPressEnter={registerF}
        placeholder={t("joinUsPage.yourPassword")}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setTimeout(() => {
            document.getElementById("password-login-input").focus();
          }, 30);
        }}
        className="input"
      />
      {loginBool && (
        <div style={{ width: "90%" }} className="start">
          <p
            style={{ width: "170px" }}
            onClick={() => setStepResetPassword(1)}
            className="text blue underline pointer"
          >
            {t("joinUsPage.forgotPassword")}
          </p>
        </div>
      )}

      {!loginBool && (
        <Radio.Group
          className="column"
          style={{ width: "200px", alignItems: "flex-start", marginTop: "20px", width: "90%" }}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          {type === "affiliate" ? (
            <Radio value={"affiliate"}>{t("affiliate")}</Radio>
          ) : (
            <Space direction="vertical">
              <Radio value={"seller"}>{t("IAMReseller")}</Radio>
              <Radio value={"creater"}>{t("iWantSell")}</Radio>
            </Space>
          )}
        </Radio.Group>
      )}
      <button
        style={{ fontSize: "18px", marginTop: "30px", width: "90%" }}
        onClick={registerF}
        className="button orange-back"
      >
        {loginBool ? t("login").toUpperCase() : t("createAccount")}
      </button>
      <GoogleLogin type={type} />
    </div>
  );
};

const JoinUs = ({ redirectTo, setModal, isRegister, isSupplier }) => {
  const paramsUrl = urlParams();
  const isSupplierJoinUs = window.location.href.includes("?supplier");
  const [email, setEmail] = useState(paramsUrl.email || localStorage.getItem("emailAccountCreatosell") || "");
  const [type, setType] = useState(isSupplierJoinUs ? "creater" : "seller");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [confirmPasswordReset, setConfirmPasswordReset] = useState("");
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState("");
  const [loginBool, setLoginBool] = useState(
    !isRegister && window.location.href.includes("login") ? true : false
  );
  const [location] = useState(window.location.search);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [stepResetPassword, setStepResetPassword] = useState(0);
  const [resetCode, setResetCode] = useState("");
  const [emailShopifyAccount, setEmailShopifyAccount] = useState("");
  const [codeShopifyAccount, setCodeShopifyAccount] = useState("");
  const [buttonResetPassword, setButtonResetPassword] = useState(false);
  const [redirectMarket, setRedirectMarket] = useState(false);
  const [redirectSetup, setRedirectSetup] = useState(false);
  const [load, setLoad] = useState(false);
  const [shopName, setShopName] = useState();
  const [redirectAdmin, setRedirectAdmin] = useState(false);

  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const redirectToUrlPaths = ["/boost"];

  //listen login/register url search
  useEffect(() => {
    const href = window.location.href;
    if (href.includes("login")) {
      setLoginBool(true);
    } else if (href.includes("register")) {
      setLoginBool(false);
    }
  }, [window.location.search]);

  useEffect(() => {
    form.setFieldsValue({
      email,
    });
  }, [form, email]);

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();

    if (sessionStorage.getItem("registerDefaultEmail01supply")) {
      setEmail(sessionStorage.getItem("registerDefaultEmail01supply"));
      sessionStorage.setItem("registerDefaultEmail01supply", "");
    }

    if (window.location.href.includes("login=")) {
      const email = window.location.href.split("login=")[1];
      setEmail(email);
      setLoginBool(true);
      if (localStorage.getItem("idAccountCreatosell")) {
        setLoad(true);
        setRedirectMarket(true);
      }
    }

    if (window.location.href.includes("?affiliate")) {
      setType("affiliate");
    }

    // GET AFFILIATION ID
    let urlParams = new URL(window.location.href);
    let affiliationId = urlParams.searchParams.get("invitation");
    if (affiliationId) {
      localStorage.setItem("affiliationId", affiliationId);
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("tokenCreatosell") &&
      localStorage.getItem("typeAccountCreatosell") &&
      localStorage.getItem("idAccountCreatosell") &&
      localStorage.getItem("secretAccountId01supply") &&
      !(window.location.href.includes("&shop=") || window.location.href.includes("&timestamp"))
    ) {
      setRedirect("/setup-supplier");
    }
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  // SHOPIFY CONNECT

  useEffect(() => {
    if (window.location.href.includes("shop=") && window.location.href.includes("connect-shopify")) {
      let shop = window.location.href.split("shop=")[1].replace("?account", "");
      shop = shop.replace("&persoapp=true", "");
      shop = shop.replace("&new-account=true", "");
      setShopName(shop);
    }

    if (window.location.href.includes("&new-account=true")) {
      setStep(3);
    } else if (window.location.href.includes("connect-shopify")) {
      setStep(3);
      getShopifyEmail();
    }
  }, []);

  const regexEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const getShopifyEmail = async () => {
    if (!email) {
      const location = window.location.href;
      let tokenShopify = location.split("token=")[1];
      if (tokenShopify) {
        tokenShopify = tokenShopify.split("&shop")[0];
        let shop = location.split("shop=")[1].replace("?account", "");
        shop = shop.replace("&persoapp=true", "");
        shop = shop.replace("&new-account=true", "");
        const shopify = {
          token: tokenShopify,
          shop: shop,
        };
        const res = await fetch(`${url}/user/shopify/get-email`, {
          method: "POST",
          credentials: "include",
          headers: {
            ...header,
          },
          body: JSON.stringify({
            shopify: shopify,
          }),
        });
        if (res.status === 200) {
          const resJson = await res.json();
          if (resJson && resJson.shopifyEmail) {
            setEmailShopifyAccount(resJson.shopifyEmail);
            setEmail(resJson.shopifyEmail);
          }
        }
      }
    }
  };

  const register = async () => {
    setLoad(true);
    let affiliationId = localStorage.getItem("affiliationId");

    if (!regexEmail(email)) {
      message.error(t("components.messages.23"));
      setLoad(false);
    } else if (password !== confirmPassword && window.location.href.includes("join-us")) {
      message.error(t("components.messages.24"));
      setLoad(false);
    } else if (password.split("").length < 8) {
      message.error(t("components.messages.25"));
      setLoad(false);
    } else if (!type) {
      message.error(t("components.messages.26"));
      setLoad(false);
    } else if ((await checkEmailExist()) === true) {
      const resSub = await fetch(`${url}/user/subscription`, {
        method: "POST",
        credentials: "include",
        headers: header,
        body: JSON.stringify({
          email: email.toLowerCase(),
          password,
          type: type,
          affiliationId,
        }),
      });
      const resJson = await resSub.json();
      if (resSub.status === 200) {
        registrationEvent({ user: resJson.data });
        setLoad(false);
        let templateId = "d-822632533b3b42b4a0822b16eddc6561";
        if (type === "seller") templateId = "d-698968b92c5c409da636387e02f47a06";
        const res = await fetch(`${url}/sendgrid/template-email`, {
          method: "POST",
          credentials: "include",
          headers: header,
          body: JSON.stringify({
            emailToSend: email.toLowerCase(),
            templateId: templateId,
            templateData: {},
          }),
        });
        connect("", true);
      } else if (resSub.status === 405) {
        setLoad(false);
        setError(resJson.message);
      } else {
        setError("Oops error please refresh page and retry");
        setLoad(false);
      }
    }
  };

  const connect = async (shopify, isRegistration) => {
    setLoad(true);
    const resConnect = await fetch(`${url}/user/connect`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        email: email.toLowerCase(),
        password,
      }),
    });
    if (resConnect) {
      setLoad(false);
    }
    if (resConnect.status === 200) {
      const resConnectJson = await resConnect.json();

      klaviyoServices.checkStatus(resConnectJson.id, resConnectJson.token, resConnectJson.secretId);

      localStorage.setItem("tokenCreatosell", resConnectJson.token);
      localStorage.setItem("typeAccountCreatosell", resConnectJson.type);
      localStorage.setItem("emailAccountCreatosell", resConnectJson.email);
      localStorage.setItem("idAccountCreatosell", resConnectJson.id);
      localStorage.setItem("secretAccountId01supply", resConnectJson.secretId);
      localStorage.setItem("viewPlan01supply", "false");

      if (resConnectJson.type === "creater") {
        if (resConnectJson.demo_done.market === false) localStorage.setItem("demoMarketActivate", true);
        if (resConnectJson.demo_done.shop === false) localStorage.setItem("demoShopActivate", true);
        if (resConnectJson.demo_done.informations === false)
          localStorage.setItem("demoInformationsActivate", true);
        if (resConnectJson.demo_done.plans === false) localStorage.setItem("demoPlansActivate", true);
        if (resConnectJson.demo_done.inventory1 === false)
          localStorage.setItem("demoInventory1Activate", true);
        if (resConnectJson.demo_done.inventory2 === false)
          localStorage.setItem("demoInventory2Activate", true);
        if (resConnectJson.demo_done.createProduct === false)
          localStorage.setItem("demoCreateProductActivate", true);
      }

      const cart = JSON.parse(localStorage.getItem("cart"));
      if (cart && cart.length) {
        const resConnect = await fetch(
          `${url}/user/update-cart/${resConnectJson.id}/${resConnectJson.secretId}`,
          {
            method: "PUT",
            credentials: "include",
            headers: {
              ...header,
              authorization: resConnectJson.token,
            },
            body: JSON.stringify({
              cart,
            }),
          }
        );
      }

      if (resConnectJson.admin === true) localStorage.setItem("admin", "true");
      else localStorage.setItem("admin", "false");

      if (resConnectJson.admin === true) {
        setTimeout(() => {
          setRedirectAdmin(true);
          window.location.reload();
        }, 400);
      }

      setTimeout(() => {
        if (resConnectJson.admin !== true) {
          // set algolia analytics user id
          window.aa("setUserToken", resConnectJson.id);

          if (localStorage.getItem("loginRedirect")) {
            setRedirect(localStorage.getItem("loginRedirect"));
            localStorage.setItem("loginRedirect", "");
          } else if (paramsUrl.reload === "true") {
            window.location.reload();
          } else if (resConnectJson.type === "affiliate") {
            setRedirect("/affiliation-links");
            window.location.reload();
          } else if (redirectToUrlPaths.includes(window.location.pathname)) {
            setRedirect(redirectTo);
          } else if (isRegistration && resConnectJson.type === "creater") {
            setRedirect("/supplier-onboarding-step-1");
          } else if (isRegistration && resConnectJson.type === "seller") {
            if (window.location.href.includes("/marketplace-home")) {
              window.location.reload();
            } else {
              setRedirect("/marketplace-home");
            }
          } else if (redirectTo) {
            if (redirectTo === "reload") {
              window.location.reload();
            } else {
              window.open(redirectTo);
              if (setModal) setModal("");
            }
          } else if (window.location.href.includes("redirect="))
            setRedirect("/" + window.location.href.split("redirect=")[1]);
          else if (resConnectJson.type === "seller") setRedirect("/inventory");
          else setRedirect("/setup-supplier");
        }
        if (setModal) {
          setModal("");
        }
      }, 400);
    } else if (shopify) {
      changeShopifyPassword();
    } else message.error(t("messages.badLogin"));
  };

  const sendResetCode = async () => {
    setLoad(true);
    const res = await fetch(`${url}/user/create-reset-code`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        email: email.toLowerCase(),
      }),
    });
    if (res.status === 200) {
      setStepResetPassword(2);
      message.success(t("messages.resetCodeSent"));
      setLoad(false);
    } else if (res.status === 404) {
      message.error(t("messages.noAccountWithThisEmail"));
      setLoad(false);
    } else {
      message.error(t("messages.error"));
      setLoad(false);
    }
  };

  const changePassword = async () => {
    if (passwordReset !== confirmPasswordReset && window.location.href.includes("join-us")) {
      setLoad(false);
      message.error(t("messages.badRepeatPassword"));
    } else if (passwordReset.split("").length < 8) {
      message.error(t("messages.passwordLength"));
      setLoad(false);
    } else {
      const res = await fetch(`${url}/user/reset-password`, {
        method: "POST",
        credentials: "include",
        headers: header,
        body: JSON.stringify({
          email: email.toLowerCase(),
          resetCode,
          passwordReset,
        }),
      });
      if (res.status === 200) {
        setStepResetPassword(0);
        connect();
        message.success(t("messages.passwordUpdated"));
        setLoad(false);
      } else if (res.status === 405) {
        message.error(t("messages.badReset"));
        setLoad(false);
      } else {
        message.error(t("messages.error"));
        setLoad(false);
      }
    }
  };

  const changeShopifyPassword = async () => {
    if (password.split("").length < 8) message.error(t("messages.passwordLength"));
    else {
      const res = await fetch(`${url}/user/reset-password`, {
        method: "POST",
        credentials: "include",
        headers: header,
        body: JSON.stringify({
          email: emailShopifyAccount.toLowerCase(),
          resetCode: codeShopifyAccount,
          passwordReset: password,
        }),
      });
      if (res.status === 200) {
        connect();
        message.success(t("messages.accountCreated"));
      } else if (res.status === 405) {
        message.error(t("messages.emailAldreadyExist"));
        setButtonResetPassword(true);
      } else {
        message.error(t("messages.error"));
      }
    }
  };

  const checkEmailExist = async (shopify) => {
    const res = await fetch(`${url}/user/check-email/${email}/${shopify || "false"}`, {
      method: "GET",
      credentials: "include",
      headers: header,
    });
    if (res.status === 200) {
      return true;
    } else {
      message.error(t("messages.emailAldreadyExist"));
      setLoad(false);
      return false;
    }
  };

  return (
    <>
      {stepResetPassword !== 0 && (
        <Modal
          className="modal-no-ok"
          title={t("resetPassword")}
          visible={true}
          onCancel={() => setStepResetPassword(0)}
          footer={[
            <Button key="back" onClick={() => setStepResetPassword(0)}>
              {t("cancel")}
            </Button>,
          ]}
        >
          <div style={{ alignItems: "center" }} className="column">
            {stepResetPassword === 1 && (
              <React.Fragment>
                <p style={{ marginBottom: "10px" }} className="title">
                  {t("joinUsPage.enterEmailAccount")}
                </p>
                <Input
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("yourEmailAddress")}
                />
                <button style={{ marginTop: "20px" }} onClick={sendResetCode} className="button orange-back">
                  {t("joinUsPage.sendResetCode")}
                </button>
              </React.Fragment>
            )}
            {stepResetPassword === 2 && (
              <React.Fragment>
                <p className="title">{t("joinUsPage.enterResetCode")}</p>
                <Input
                  style={{ marginTop: "20px" }}
                  onChange={(e) => setResetCode(e.target.value)}
                  placeholder={t("joinUsPage.yourResetCode")}
                />
                <Input.Password
                  style={{ marginTop: "10px" }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordReset(e.target.value);
                  }}
                  placeholder={t("joinUsPage.newPassword")}
                />
                <Input.Password
                  style={{ marginTop: "10px" }}
                  onChange={(e) => setConfirmPasswordReset(e.target.value)}
                  placeholder={t("joinUsPage.confirmNewPassword")}
                />
                <button style={{ marginTop: "20px" }} onClick={changePassword} className="button orange-back">
                  {t("joinUsPage.resetPassword")}
                </button>
              </React.Fragment>
            )}
          </div>
        </Modal>
      )}
      {!window.location.href.includes("join-us") ? (
        <>
          {redirectMarket && <RedirectLogin redirectMarket={true} />}
          {redirectSetup && <Redirect to="/setup-reseller" />}
          {redirectAdmin && <Redirect to="/" />}
          {redirect && <Redirect to={redirect} />}
          {load && <Load />}
          <ModalRegister
            isRegister={isRegister}
            setEmail={setEmail}
            loginBool={loginBool}
            type={type}
            regexEmail={regexEmail}
            email={email}
            checkEmailExist={checkEmailExist}
            register={register}
            connect={connect}
            setLoginBool={setLoginBool}
            password={password}
            setPassword={setPassword}
            setType={setType}
            setLoad={setLoad}
            setStepResetPassword={setStepResetPassword}
          />
        </>
      ) : (
        <div style={{ position: "relative" }} className={window.location.href.includes("join-us") && "page"}>
          <CreatePasswordNewAccount />
          {load && <Load />}
          {redirectMarket && <RedirectLogin redirectMarket={true} />}
          {redirectSetup && <Redirect to="/setup-reseller" />}
          {redirectAdmin && <Redirect to="/" />}
          {redirect && <Redirect to={redirect} />}
          {location === "?supplier" ? (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{t("joinUsPage.metas.titleSupplier")}</title>
              <link rel="canonical" href="https://app.01supply.com/join-us?supplier" />
              <meta name="description" content={t("joinUsPage.metas.descriptionSupplier")} />
              <meta property="og:description" content={t("joinUsPage.metas.descriptionSupplier")} />
              <meta property="og:url" content="https://app.01supply.com/join-us?supplier" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={t("joinUsPage.metas.titleSupplier")} />
              <meta property="og:image" content={OgImg} />
            </Helmet>
          ) : location === "?reseller" ? (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{t("joinUsPage.metas.titleReseller")}</title>
              <link rel="canonical" href="https://app.01supply.com/join-us?reseller" />
              <meta name="description" content={t("joinUsPage.metas.descriptionReseller")} />
              <meta property="og:description" content={t("joinUsPage.metas.descriptionReseller")} />
              <meta property="og:url" content="https://app.01supply.com/join-us?reseller" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={t("joinUsPage.metas.titleReseller")} />
              <meta property="og:image" content={OgImg} />
            </Helmet>
          ) : (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{t("joinUsPage.metas.title")}</title>
              <link rel="canonical" href="https://app.01supply.com/join-us" />
              <meta name="description" content={t("joinUsPage.metas.description")} />
              <meta property="og:description" content={t("joinUsPage.metas.description")} />
              <meta property="og:url" content="https://app.01supply.com/join-us" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={t("joinUsPage.metas.title")} />
              <meta property="og:image" content={OgImg} />
            </Helmet>
          )}
          <div className="page-start">
            {step === 0 ? (
              <div
                style={
                  windowSize > 1150 && window.location.href.includes("join-us")
                    ? { paddingLeft: "250px", paddingTop: "60px", width: "100%" }
                    : window.location.href.includes("join-us")
                    ? { width: "100vw" }
                    : { width: "100%", padding: "0" }
                }
                className="column-scrolling join-us back-join-us"
              >
                <img src={logo} alt="01supply logo" style={{ width: "150px", marginBottom: "50px" }} />
                <div style={windowSize > 1150 ? {} : {}} className="row">
                  {!loginBool ? (
                    <div className="row">
                      <div style={{ zIndex: "10", maxWidth: "400px" }} className="column">
                        <h1
                          style={{ fontWeight: "800", fontSize: "30px", marginBottom: "5px" }}
                          className="big-title montserrat start"
                        >
                          {type === "affiliate" || window.location.search?.includes("affiliate")
                            ? t("joinUsPage.becomeAffiliate")
                            : t("joinUsPage.createNewAccount")}
                        </h1>
                        <p className="text-300 row">
                          {t("joinUsPage.alreadyMember")}
                          <p
                            style={{ marginLeft: "5px" }}
                            onClick={() => setLoginBool(true)}
                            className="text blue pointer underline"
                          >
                            {t("joinUsPage.login")}
                          </p>
                        </p>
                        <Form form={form} name="login" initialValues={{ email: "test" }} onFinish={register}>
                          <Form.Item
                            rules={[
                              { required: true, message: t("components.messages.23") },
                              { type: "email", message: t("components.messages.23") },
                            ]}
                            name="email"
                            style={{ marginTop: "30px" }}
                          >
                            <Input
                              maxLength={150}
                              type="email"
                              placeholder={t("joinUsPage.yourEmailAddress")}
                              style={windowSize > 1150 ? { width: "100%" } : { width: "100%" }}
                              onChange={(e) => setEmail(e.target.value)}
                              className="input"
                            />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            rules={[{ required: true, message: t("components.messages.25") }]}
                            style={{ marginTop: "15px", marginBottom: "10px" }}
                          >
                            <Input.Password
                              maxLength={60}
                              type="password"
                              placeholder={t("joinUsPage.yourPassword")}
                              style={windowSize > 1150 ? { width: "100%" } : { width: "100%" }}
                              onChange={(e) => setPassword(e.target.value)}
                              className="input"
                            />
                          </Form.Item>
                          <Form.Item
                            name="password repeat"
                            rules={[
                              {
                                required: true,
                                message: t("components.messages.24"),
                              },

                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error(t("components.messages.24")));
                                },
                              }),
                            ]}
                            style={{ marginBottom: "5px" }}
                          >
                            <Input.Password
                              maxLength={60}
                              type="password"
                              placeholder={t("joinUsPage.confirmPassword")}
                              style={windowSize > 1150 ? { width: "400px" } : { width: "100%" }}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              className="input"
                            />
                          </Form.Item>
                          {type !== "affiliate" && isSupplierJoinUs && (
                            <Form.Item
                              name="account-type"
                              rules={[
                                {
                                  required: password !== confirmPassword,
                                  message: t("components.messages.26"),
                                },
                              ]}
                            >
                              <Radio.Group
                                className="column"
                                style={{
                                  width: "200px",
                                  alignItems: "flex-start",
                                  marginTop: "20px",
                                  width: "90%",
                                }}
                                defaultValue={type}
                                onChange={(e) => {
                                  setType(e.target.value);
                                }}
                              >
                                <Space direction="vertical">
                                  <Radio value={"seller"}>{t("IAMReseller")}</Radio>
                                  <Radio value={"creater"}>{t("iWantSell")}</Radio>
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          )}
                          <button
                            type="primary"
                            htmlType="submit"
                            className="button orange-back"
                            style={{ width: windowSize > 1150 ? "400px" : "100%", marginTop: "20px" }}
                          >
                            {t("createAccount").toUpperCase()}
                          </button>

                          <GoogleLogin type={type} />
                        </Form>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div style={{ zIndex: "10" }} className="column">
                        <h1
                          style={{ fontWeight: "800", fontSize: "30px", marginBottom: "5px" }}
                          className="big-title montserrat"
                        >
                          {t("joinUsPage.signInYourAccount")}
                        </h1>
                        <p className="text-300 row">
                          {t("joinUsPage.noAccount")}
                          <p
                            style={{ marginLeft: "5px" }}
                            onClick={() => setLoginBool(false)}
                            className="text blue pointer underline"
                          >
                            {t("joinUsPage.registerHere")}
                          </p>
                        </p>
                        <Form
                          name="login"
                          initialValues={{ remember: true }}
                          onFinish={connect}
                          autoComplete="off"
                        >
                          <Form.Item style={{ marginBottom: "15px" }}>
                            <Input
                              defaultValue={email}
                              maxLength={150}
                              type="email"
                              onPressEnter={connect}
                              placeholder={t("yourEmailAddress")}
                              style={
                                windowSize > 1150
                                  ? { width: "400px", marginTop: "30px" }
                                  : { width: "80vw", marginTop: "30px" }
                              }
                              onChange={(e) => setEmail(e.target.value)}
                              className="input"
                            />
                          </Form.Item>
                          <Form.Item style={{ marginBottom: "5px" }}>
                            <Input.Password
                              maxLength={60}
                              type="password"
                              onPressEnter={() => connect()}
                              placeholder={t("joinUsPage.yourPassword")}
                              style={windowSize > 1150 ? { width: "400px" } : { width: "80vw" }}
                              onChange={(e) => setPassword(e.target.value)}
                              className="input"
                            />
                          </Form.Item>
                        </Form>

                        <p
                          style={{ marginBottom: "15px", marginTop: "5px", fontSize: "14px" }}
                          onClick={() => setStepResetPassword(1)}
                          className="text-300 grey pointer width-100 end"
                        >
                          {t("joinUsPage.forgotPassword")}
                        </p>

                        <button
                          onClick={() => connect()}
                          className="button orange-back"
                          style={{ width: "100%", marginTop: "20px" }}
                        >
                          {t("login").toUpperCase()}
                        </button>
                        <GoogleLogin type={type} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              step === 3 && (
                <ConnectShopify
                  windowSize={windowSize}
                  emailShopifyAccount={emailShopifyAccount}
                  email={email}
                  shopName={shopName}
                  setLoad={setLoad}
                  password={password}
                  setRedirectSetup={setRedirectSetup}
                  setEmail={setEmail}
                  setPassword={setPassword}
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default JoinUs;
