import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getImage } from "../../utils/GetImage";

import UserIcon from "../../images/icons/User.png";
import UserLittleIcon from "../../images/icons/user.svg";
import BagIcon from "../../images/icons/bag.svg";
import BoxIcon from "../../images/icons/box.svg";
import WalletIcon from "../../images/icons/wallet.svg";
import DocIcon from "../../images/icons/doc.svg";
import CardIcon from "../../images/icons/card.svg";
import PriceIcon from "../../images/icons/price.svg";
import ToolsIcon from "../../images/icons_grey/Tools.svg";
import growthIcon from "../../images/icons_grey/growth.png";

import { routes } from "../../routes";

const iconStyle = {
  width: "19px",
  marginRight: "12px",
};

const Menu = ({ user }) => {
  const [location] = useState(window.location.pathname.replace("/", ""));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [profilePicture, setProfilePicture] = useState("");
  const [isScrollToContent, setIsScrollToContent] = useState(false);

  const { t, i18n } = useTranslation();

  const scrollToContent = () => {
    document.getElementById("content")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setProfilePicture(user.profile_picture);
    if (user?._id && !isScrollToContent) {
      setIsScrollToContent(true);
      scrollToContent();
    }
  }, [user]);

  const linkStyle = {
    borderBottom: "1px solid #ebebeb",
    width: "100%",
    marginLeft: "0",
    margin: "0",
  };

  return (
    <div className="menu-setting">
      <div style={{ padding: "12px" }} className="row">
        <img src={profilePicture?.includes("http") ? profilePicture : UserIcon} className="user-icon-menu" />
        <div className="column">
          <p
            style={{ width: "170px", textAlign: "start", maxHeight: "15px", minHeight: "22px" }}
            className="text-bold text-ellipsis"
          >
            {typeAccount === "creater" && user && user.shop ? user.shop.name : user && user.name && user.name}
          </p>
          <p
            className="title-300 text-ellipsis"
            style={{ fontSize: "14px", width: "170px", textAlign: "start" }}
          >
            {user && user.email}
          </p>
        </div>
      </div>
      <Link
        style={linkStyle}
        id="demo-step-4"
        to="/your-informations"
        className={location === "your-informations" ? "text-menu-setting-active" : "text-menu-setting"}
      >
        <img src={UserLittleIcon} style={iconStyle} />
        {t("components.menu.2")}
      </Link>
      <Link
        style={linkStyle}
        to={typeAccount == "seller" ? "/your-shop-reseller" : "/your-shop-supplier"}
        className={
          location === "your-shop-supplier" ||
          location === "your-shop-reseller" ||
          location === "your-shop-reseller/"
            ? "text-menu-setting-active"
            : "text-menu-setting"
        }
      >
        <img src={BagIcon} style={iconStyle} />
        {typeAccount === "seller" ? t("components.menu.3") : t("yourStore")}
      </Link>
      {typeAccount === "seller" && user?.shopify?.shop && (
        <Link
          style={linkStyle}
          to={"/settings/pricing-rules"}
          className={location.includes("pricing-rules") ? "text-menu-setting-active" : "text-menu-setting"}
        >
          <img src={PriceIcon} style={iconStyle} />
          {t("pricingRules")}
        </Link>
      )}
      {typeAccount === "creater" && (
        <Link
          style={linkStyle}
          to={"/manage-shipping-methods"}
          className={
            location === "manage-shipping-methods" ? "text-menu-setting-active" : "text-menu-setting"
          }
        >
          <img src={BoxIcon} style={iconStyle} />
          {t("shippingMethod")}
        </Link>
      )}
      <Link
        style={linkStyle}
        id="demo-step-7"
        target="_blank"
        to={typeAccount === "creater" ? routes.planSupplier(i18n.language).path : "/pricing"}
        className={location.includes("plans") ? "text-menu-setting-active" : "text-menu-setting"}
      >
        <img src={WalletIcon} style={iconStyle} />
        {t("plans")}
      </Link>
      <Link
        style={linkStyle}
        to={typeAccount === "seller" ? "/reseller-documentation" : routes.supplierDoc(i18n.language).path}
        className={location.includes("documentation") ? "text-menu-setting-active" : "text-menu-setting"}
      >
        <img src={DocIcon} style={iconStyle} />
        Documentation
      </Link>
      {typeAccount === "creater" && (
        <>
          <Link
            style={linkStyle}
            to={"/payment-methods"}
            className={location === "payment-methods" ? "text-menu-setting-active" : "text-menu-setting"}
          >
            <img src={CardIcon} style={iconStyle} />
            {t("components.menu.4")}
          </Link>
          <Link
            style={linkStyle}
            to={"/boost-your-business"}
            className={location === "boost-your-business" ? "text-menu-setting-active" : "text-menu-setting"}
          >
            <img src={growthIcon} style={iconStyle} />
            {t("components.menu.5")}
          </Link>
        </>
      )}
      {typeAccount === "seller" && (
        <Link
          style={linkStyle}
          to="/reseller-tools"
          className={location === "reseller-tools" ? "text-menu-setting-active" : "text-menu-setting"}
        >
          <img src={ToolsIcon} style={iconStyle} />
          {t("components.menu.6")}
        </Link>
      )}
    </div>
  );
};

export default Menu;
