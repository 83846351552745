import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import { urlParams } from "../../../utils/urlParams";

import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";
import CoachingForm from "../../../components/coachingForm/CoachingForm";
import CoachingCard from "../../../components/coachingForm/CoachingCard";
import CoachingInformations from "../../../components/coachingForm/InformationsModal";
import Load from "../../../utils/Load";

import secureImg from "../../../images/icons/secure.svg";

const GrowthBusiness = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [load, setLoad] = useState(false);
  const [isDisplayInformations, setIsDisplayInformations] = useState(false);
  const [isConfigureCoaching, setIsConfigureCoaching] = useState(false);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (isConfigureCoaching) {
      document.getElementById("coaching-form")?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      document.getElementById("boost-cards")?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [user, isConfigureCoaching]);

  // CHECK SUBSCRIPTION
  useEffect(() => {
    const checkoutSessionId = urlParams()?.checkoutSessionId;
    if (checkoutSessionId) {
      setLoad(true);
      if (checkoutSessionId === "failed") {
        message.error(t("messages.error"));
        setLoad(false);
      } else {
        fetch(`${url}/coaching/subscribe/valid/${idAccount}/${checkoutSessionId}`, {
          method: "GET",
          credentials: "include",
          headers: {
            ...header,
            authorization: token,
          },
        }).then((result) => {
          window.location.href = `/boost-your-business`;
          setLoad(false);
        });
      }
    }
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      const userRes = resJson.data;
      if (!userRes.coaching?._id) {
        setIsConfigureCoaching(true);
      } else {
        setIsConfigureCoaching(false);
      }
      setUser(userRes);
    }
  };

  const isCurrentCoachingActive = () => {
    const currentCoachingStatus = user.coaching?.status;
    const activeStatus = ["in demand", "accepted", "activated"];
    return activeStatus.includes(currentCoachingStatus);
  };

  return (
    <div className="page">
      <RedirectLogin />
      {load && <Load />}
      {isDisplayInformations && (
        <CoachingInformations
          setIsConfigureCoaching={setIsConfigureCoaching}
          setIsDisplayed={setIsDisplayInformations}
          isDisplayed={isDisplayInformations}
        />
      )}
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="boost" />}
        <div
          style={windowSize > 1150 ? { paddingTop: "0px" } : { paddingTop: "30px", paddingBottom: "150px" }}
          className="column-scrolling"
        >
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "30px",
              alignItems: "flex-start",
              justifyContent: windowSize <= 1150 && "center",
            }}
          >
            {windowSize > 1150 ? (
              <>
                <MenuSetting user={user} />
                <div className="header">
                  <div
                    className="row"
                    style={
                      windowSize > 1150
                        ? { width: "100%", marginBottom: "-30px", height: "30px" }
                        : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                    }
                  >
                    <h1 className="title-bold montserrat">{t("supplierGrowth.10")}</h1>
                  </div>
                  <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
                </div>
              </>
            ) : (
              <MobileHeader nameHeader={t("supplierGrowth.10")} location={t("supplierGrowth.10")} />
            )}
            {user._id && isConfigureCoaching ? (
              <div
                className="column"
                style={{
                  width: windowSize > 1150 ? "calc(100vw - 700px)" : "90%",
                  marginTop: windowSize < 1150 && "30px",
                  alignItems: windowSize < 1150 ? "center" : "start",
                }}
              >
                <div
                  style={{ width: "100%", marginLeft: windowSize > 1150 && "30px" }}
                  className="column width-100"
                >
                  <div style={{ marginBottom: "20px" }} className="row-wrap width-100">
                    <Link
                      to="/boost"
                      target="_blank"
                      style={{ fontSize: "18px", marginRight: "10px", minWidth: "220px", margin: "10px" }}
                      className="button grey big"
                    >
                      {t("supplierGrowth.8")}
                    </Link>
                    <button
                      style={{ fontSize: "18px", minWidth: "260px", margin: "10px" }}
                      onClick={() => setIsConfigureCoaching(false)}
                      className="button big orange-back"
                    >
                      {t("supplierGrowth.1")}
                    </button>
                  </div>
                  <h1 className="title-bold montserrat start" style={{ fontWeight: "900", fontSize: "24px" }}>
                    {t("supplierGrowth.2")}
                  </h1>
                  <p className="title montserrat start" style={{ fontSize: "16px", marginTop: "5px" }}>
                    {t("supplierGrowth.3")}
                    <br />
                    <br />
                    {t("supplierGrowth.4")}
                    <br />
                    {t("supplierGrowth.5")}
                    <br />
                    {t("supplierGrowth.6")}
                    <br />
                  </p>
                </div>
                {!user.coaching?._id && (
                  <CoachingForm
                    refreshUser={getUser}
                    idAccount={idAccount}
                    coaching={user.coaching || {}}
                    setUser={setUser}
                    user={user}
                    isFirstBoost={true}
                    containerStyle={{
                      padding: "30px",
                      width: windowSize > 1150 ? "calc(100vw - 650px)" : "100%",
                      maxWidth: "800px",
                      paddingTop: "15px",
                      marginLeft: windowSize < 1150 ? "0px" : "30px",
                      marginRight: "0",
                      marginTop: windowSize < 1150 ? "20px" : "30px",
                    }}
                  />
                )}
                <CoachingForm
                  refreshUser={getUser}
                  idAccount={idAccount}
                  coaching={user.coaching || {}}
                  setUser={setUser}
                  user={user}
                  isFirstBoost={false}
                  containerStyle={{
                    padding: "30px",
                    width: windowSize > 1150 ? "calc(100vw - 650px)" : "100%",
                    maxWidth: "800px",
                    paddingTop: "15px",
                    marginLeft: windowSize < 1150 ? "0px" : "30px",
                    marginRight: "0",
                    marginTop: windowSize < 1150 ? "20px" : "30px",
                  }}
                />
              </div>
            ) : (
              user._id && (
                <div
                  style={{
                    width:
                      !isConfigureCoaching && windowSize > 1150
                        ? ""
                        : windowSize > 1150
                        ? "calc(100% - 380px)"
                        : "90%",
                    marginLeft: !isConfigureCoaching && windowSize < 1365 ? "0" : windowSize > 1150 && "30px",
                    marginTop: !isConfigureCoaching && windowSize < 1365 ? "30px" : "0",
                  }}
                  id="boost-cards"
                  className="column"
                >
                  <div
                    style={{ width: "100%", justifyContent: "flex-end", marginBottom: "20px" }}
                    className="row"
                  >
                    <Link
                      to="/boost"
                      target="_blank"
                      style={{ marginRight: "20px", fontSize: "18px" }}
                      className="button grey big"
                    >
                      {t("supplierGrowth.8")}
                    </Link>
                    {isCurrentCoachingActive() === false && (
                      <button
                        style={{ fontSize: "18px" }}
                        onClick={() => setIsConfigureCoaching(true)}
                        className="button orange-back"
                      >
                        {t("supplierGrowth.9")}
                      </button>
                    )}
                  </div>
                  {user.coaching ? (
                    <>
                      {user.coaching?.status !== "configuration" && user.coaching?.start_date && (
                        <CoachingCard
                          windowSize={windowSize}
                          index={t("currentBoost")}
                          coaching={user.coaching}
                        />
                      )}
                      {user?.coaching?.history?.map(
                        (coaching, index) =>
                          coaching.start_date && (
                            <CoachingCard
                              windowSize={windowSize}
                              index={`BOOST ${index + 1}`}
                              coaching={coaching}
                            />
                          )
                      )}
                    </>
                  ) : (
                    <p className="title-bold montserrat" style={{ marginTop: "30px" }}>
                      {t("supplierGrowth.11")}{" "}
                      <span
                        onClick={() => setIsConfigureCoaching(true)}
                        className="text blue underline pointer"
                      >
                        {t("supplierGrowth.12")}
                      </span>
                    </p>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthBusiness;
