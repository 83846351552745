import React, { useEffect, useState } from "react";
import ImageInput from "../../ImageInput";
import { getImage } from "../../../utils/GetImage";

const Images = ({ setProduct, product }) => {
  const [defaultImagesAreLoad, setDefaultImagesAreLoad] = useState(false);
  const [defaultImagesAreRequested, setDefaultImagesAreRequested] = useState(false);

  const isEditProduct = window.location.pathname !== "/create-product";

  useEffect(() => {
    // if product have _id we edit the product and we must get default images with product.photos id
    if (!defaultImagesAreLoad && !defaultImagesAreRequested && product._id) {
      setDefaultImagesAreRequested(true);
      Promise.all(
        product.photos?.map(async (imgId, index) => {
          return await getImage(imgId).then((img) => {
            return {
              base64: img,
              uid: `product_${product._id}_img${index}`,
            };
          });
        })
      ).then((defaultImgs) => {
        setProduct({ ...product, photos: [...defaultImgs] });
        setDefaultImagesAreLoad(true);
      });
    }
  }, [product]);

  return (
    <div id="images" style={{ width: "100%", marginRight: "30px" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        Images
      </p>
      {(isEditProduct === false || defaultImagesAreLoad) && (
        <ImageInput
          multiple={true}
          photos={product.photos || []}
          setPhotos={(images) => setProduct({ ...product, photos: images.fileList })}
        />
      )}
    </div>
  );
};

export default Images;
