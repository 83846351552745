import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { url } from "../../api/url";
import { Image, Carousel } from "antd";
import { Link } from "react-router-dom";
import { getImage } from "../../utils/GetImage";

import EcoLabelIcon from "../../images/icons/eco.svg";
import HandmadeLabelIcon from "../../images/icons/handmade.svg";
import FacebookIcon from "../../images/icons/facebook.png";
import InstagramIcon from "../../images/icons/instagram.png";

import { routes } from "../../routes";

const AboutSupplier = ({
  aboutSuppplier,
  shopInformations,
  windowSize,
  supplierShopName,
  setSupplierImage,
}) => {
  const [images, setImages] = useState([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (aboutSuppplier.images) getImageF();
  }, [aboutSuppplier]);

  const getImageF = async () => {
    let imagesStock = [];
    for (let i = 0; i < aboutSuppplier.images.length; i++) {
      const res = await getImage(aboutSuppplier.images[i]);
      if (res) {
        imagesStock.push(res);
        if (i === aboutSuppplier.images.length - 1) {
          setImages(imagesStock);
        }
      }
    }
  };

  function getYoutubeVideoId(url) {
    if (url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    } else return null;
  }

  return (
    <div style={windowSize > 1150 ? { paddingLeft: "20px", marginTop: "30px" } : {}} className="card">
      <div
        style={
          windowSize > 1150
            ? { marginTop: "20px", marginBottom: "5px", alignItems: "flex-start", width: "100%" }
            : {
                marginTop: "20px",
                marginBottom: "5px",
                alignItems: "flex-start",
                width: "90%",
                marginLeft: "5%",
              }
        }
        className={windowSize > 1150 ? "row" : "column"}
      >
        <div
          style={windowSize > 1150 ? { width: "55%", marginRight: "5%" } : { width: "100%" }}
          className="column"
        >
          {aboutSuppplier.information && (
            <>
              <p style={{ marginBottom: "10px", fontWeight: "bold" }} className="title-roboto">
                {t("components.supplierShop.4")}
              </p>
              <h3 style={{ textAlign: "start", marginBottom: "30px" }} className="text-300">
                {aboutSuppplier.information}
              </h3>
            </>
          )}
          {((images && Array.isArray(images) && images.length) || aboutSuppplier.video1) && (
            <div style={{ width: "400px", maxWidth: "100%" }}>
              <Carousel>
                {images &&
                  Array.isArray(images) &&
                  images.map((img, index) => {
                    if (img) {
                      return (
                        <Image
                          alt={`${shopInformations.name} image ${index + 1}`}
                          style={{ maxWidth: "80%", height: "auto", margin: "15px" }}
                          src={img}
                        />
                      );
                    }
                  })}
                {getYoutubeVideoId(aboutSuppplier.video1) && (
                  <iframe
                    height="360px"
                    style={
                      windowSize > 1150
                        ? { width: "560px", height: "315px", marginTop: "50px" }
                        : { marginTop: "30px", width: "90vw", height: "43vw" }
                    }
                    src={`//www.youtube.com/embed/${getYoutubeVideoId(aboutSuppplier.video1)}`}
                    title={`${shopInformations.name} video 1`}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                )}
                {getYoutubeVideoId(aboutSuppplier.video2) && (
                  <iframe
                    height="360px"
                    style={
                      windowSize > 1150
                        ? { width: "100%", height: "auto", marginTop: "50px" }
                        : { marginTop: "30px", width: "90vw", height: "43vw" }
                    }
                    src={`//www.youtube.com/embed/${getYoutubeVideoId(aboutSuppplier.video2)}`}
                    title={`${shopInformations.name} video 2`}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                )}
                {getYoutubeVideoId(aboutSuppplier.video3) && (
                  <iframe
                    height="360px"
                    style={
                      windowSize > 1150
                        ? { width: "560px", height: "315px", marginTop: "50px" }
                        : { marginTop: "30px", width: "90vw", height: "43vw" }
                    }
                    src={`//www.youtube.com/embed/${getYoutubeVideoId(aboutSuppplier.video3)}`}
                    title={`${shopInformations.name} video 3`}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                )}
              </Carousel>
            </div>
          )}
        </div>

        {windowSize > 1150 && (
          <div
            style={{ height: "300px", marginRight: "30px", opacity: "0.4" }}
            className="divider-vertical"
          />
        )}

        <div style={windowSize > 1150 ? { width: "40%" } : { width: "100%" }} className="column">
          {((aboutSuppplier.handmade_label && aboutSuppplier.handmade_label.verified === true) ||
            (aboutSuppplier.eco_label && aboutSuppplier.eco_label.verified === true)) && (
            <p style={windowSize > 1150 ? {} : { marginTop: "50px" }} className="title-roboto">
              Certifications
            </p>
          )}

          {aboutSuppplier.handmade_label && aboutSuppplier.handmade_label.verified === true && (
            <>
              <div style={{ marginTop: "15px" }} className="row">
                <img src={HandmadeLabelIcon} style={{ height: "25px", width: "auto", marginRight: "10px" }} />
                <p style={{ fontSize: "18px" }} className="text-bold">
                  {t("components.supplierShop.5")}
                </p>
              </div>
              <p style={{ fontSize: "13px", marginTop: "3px", marginBottom: "4px" }} className="text-300">
                {t("components.supplierShop.6")}
              </p>
              <Link
                to={`${routes.supplierCertifHandmade(i18n.language).path}/${supplierShopName}`}
                className="text-gradient"
              >
                {t("components.supplierShop.7")}
              </Link>
            </>
          )}

          {aboutSuppplier.eco_label && aboutSuppplier.eco_label.verified === true && (
            <>
              <div style={{ marginTop: "30px" }} className="row">
                <img src={EcoLabelIcon} style={{ height: "25px", width: "auto", marginRight: "10px" }} />
                <p style={{ fontSize: "18px" }} className="text-bold">
                  {t("components.supplierShop.8")}
                </p>
              </div>
              <p style={{ fontSize: "13px", marginTop: "3px", marginBottom: "4px" }} className="text-300">
                {t("components.supplierShop.9")}
              </p>
              <Link
                to={`${routes.supplierCertifEco(i18n.language).path}/${supplierShopName}`}
                className="text-gradient"
              >
                {t("components.supplierShop.7")}
              </Link>
            </>
          )}

          {(aboutSuppplier.facebook || aboutSuppplier.instagram) && (
            <>
              <p
                style={
                  windowSize > 1150 ? { marginTop: "50px", marginBottom: "20px" } : { marginBottom: "20px" }
                }
                className="title-roboto"
              >
                {t("components.supplierShop.10")}
              </p>
              <div className="row">
                {aboutSuppplier.facebook && (
                  <a href={aboutSuppplier.facebook} target="_blank" rel="noopener noreferrer">
                    <img src={FacebookIcon} style={{ height: "30px", width: "auto" }} />
                  </a>
                )}
                {aboutSuppplier.instagram && (
                  <a
                    style={{ marginLeft: "20px" }}
                    href={aboutSuppplier.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={InstagramIcon} style={{ height: "30px", width: "auto" }} />
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutSupplier;
