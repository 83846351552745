import { url } from "../api/url";
import { header } from "../api/header";

export const productServices = {
  find: async ({
    page,
    thematicId,
    category,
    thematicName,
    origin,
    originKey,
    deliverTo,
    destinationName,
    maxPrice,
    minPrice,
    search,
    createrId,
    createrName,
    isWinningProducts,
    isHighMarginProducts,
    limit,
    userId,
    isRecommendation,
    bannedIds,
    isProductAnalytics,
    regionOrigin,
    isPopularAnalyticsProducts,
    queryParams
  }) => {
    const query = queryParams || `?page=${page || ""}&limit=${limit || ""}&thematicId=${thematicId || ""}&category=${
      category || ""
    }&thematicName=${thematicName || ""}&origin=${origin || ""}&originKey=${originKey || ""}&deliverTo=${
      deliverTo || ""
    }&destinationName=${destinationName || ""}&maxPrice=${maxPrice || ""}&minPrice=${minPrice || ""}&search=${
      search || ""
    }&createrId=${createrId || ""}&createrName=${createrName || ""}&isWinningProducts=${
      isWinningProducts || ""
    }&isHighMarginProducts=${isHighMarginProducts || ""}&isProductAnalytics=${
      isProductAnalytics || ""
    }&isPopularAnalyticsProducts=${isPopularAnalyticsProducts}&userId=${userId || ""}&isRecommendation=${isRecommendation || false}&bannedIds=${
      bannedIds || ""
    }&regionOrigin=${regionOrigin}`;

    // save algolia queryID
    const res = await fetch(`${url}/product/find${query}`, {
      method: "GET",
      credentials: "include",
    });
    if (res.status === 200) {
      const clonedRes = res.clone();
      const resJson = await clonedRes.json();
      if (resJson.queryID) {
        localStorage.setItem("algolia_last_query_id", resJson.queryID);
      }
    }

    return res;

  },
  findBySeller: async (
    { page, search, all, sortBy },
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(
      `${url}/product/find-by-seller/${idAccount}/${secretId}?page=${page || "1"}&search=${
        search || ""
      }&all=${all}&sortBy=${sortBy}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      }
    );
  },
  findByCreater: async (
    { page, search },
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell")
  ) => {
    return await fetch(
      `${url}/product/find-by-creater/${idAccount}?page=${page || "1"}&search=${search || ""}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      }
    );
  },
  findByIds: async ({ productsId }) => {
    return await fetch(`${url}/product/find-with-image`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        productsId,
      }),
    });
  },
  findImportList: async (
    { page },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell")
  ) => {
    return await fetch(`${url}/product/find-import-list/user/${userId}?page=${page}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  findShopifyProduct: async (
    { productId },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/product/shopify/find/${userId}/${secretId}/${productId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  pushShopifyVariants: async (
    { shopifyProductId, productId },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/product/shopify/variants/${userId}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        shopifyProductId,
        productId,
      }),
    });
  },
  checkMonitoring: async (
    { productId },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/product/monitoring/${userId}/${secretId}/${productId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({}),
    });
  },
  updateShopifyProductCost: async (
    { productId },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/product/monitoring/cost/${userId}/${secretId}/${productId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({}),
    });
  },
};
