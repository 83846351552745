import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import UserNoThematicTable from "../../components/admin/collabMatching/UserList";
import UserCard from "../../components/admin/collabMatching/UserCard";

import { adminServices } from "../../services/admin";
import Load from "../../utils/Load";
import { thematics } from "../../constants/thematics";
import "./style.scss";

const CollabMatching = () => {
  const { isLoading, data: users } = useQuery(
    ["adminCollab"],
    () =>
      adminServices
        .findCollabMatching()
        .then((res) => res.json())
        .then((res) => {
          return res.data?.users;
        }),
    {
      staleTime: 1000000,
    }
  );

  return (
    <div className="page collab">
      {isLoading && <Load />}
      {users && (
        <>
          {thematics.map((thematic) => {
            return (
              <div className="card content-scroll">
                <p className="title-bold">Thématic: {thematic}</p>
                <div className="card content-scroll">
                  <p className="title-bold">Créateurs</p>
                  {users
                    .filter((user) => user.type === "creater" && user.thematic?.includes(thematic))
                    .map((user) => (
                      <UserCard user={user} users={users} />
                    ))}
                </div>
                <div className="card content-scroll">
                  <p className="title-bold">Revendeurs</p>
                  {users
                    .filter((user) => user.type === "seller" && user.thematic?.includes(thematic))
                    .map((user) => (
                      <UserCard user={user} users={users} />
                    ))}
                </div>
              </div>
            );
          })}

          <div style={{ width: "90vw" }} className="card">
            <p className="title-bold">Sans thématics</p>
            <UserNoThematicTable isNoThematic={true} users={users} />
          </div>
        </>
      )}
    </div>
  );
};

export default CollabMatching;
