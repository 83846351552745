import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ExcelIcon from "../../images/icons/excel-sheet-icon.jpeg";
import EtsyIcon from "../../images/icons/etsy-icon.png";
import ShopifyIcon from "../../images/icons/shopify-icon.png";
import WixIcon from "../../images/icons/wix-icon.png";

import CreateProductIllustration from "../../images/illustrations/create_product.svg";

const EmptyInventory = ({ windowSize }) => {
  const { t } = useTranslation();
  return (
    <div className="column" style={{ width: "100%", alignItems: "center", marginTop: "30px" }}>
      <p
        style={{
          fontSize: "30px",
          fontWeight: "900",
          fontFamily: "Montserrat",
          lineHeight: "45px",
          textAlign: "start",
          color: "#fba95e",
          marginBottom: "40px",
          textAlign: "center",
        }}
        className="title-bold montserrat"
      >
        {t("components.supplier.6")}
      </p>
      <div
        className="row-wrap"
        style={{ width: "100%", alignItems: "flex-start", paddingBottom: "150px", justifyContent: "center" }}
      >
        <div
          style={{
            alignItems: "center",
            margin: "20px",
            width: windowSize > 1150 ? "40%" : "100%",
            marginRight: windowSize > 1150 && "2%",
            marginTop: "0",
            marginBottom: "40px",
          }}
          className="column card"
        >
          <p className="montserrat title-600">{t("components.supplier.7")}</p>
          <Link
            style={{ marginTop: "20px", width: "90%", height: "45px" }}
            to="/import-products-csv"
            className="button big orange-back"
          >
            {t("components.supplier.8")}
          </Link>
          <p
            style={{
              fontSize: "18px",
              marginTop: "40px",
              marginBottom: "20px",
              width: "90%",
              textAlign: "center",
            }}
            className="text"
          >
            {t("components.supplier.9")} <span style={{ fontWeight: "bold" }}>.csv</span>{" "}
            {t("components.supplier.10")}
          </p>
          <a
            href="https://support.microsoft.com/en-us/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba#:~:text=Go%20to%20File%20%3E%20Save%20As,formats%20support%20different%20feature%20sets."
            target="_blank"
            rel="noopener noreferrer"
            className="card-tuto-import border-grey"
          >
            <p style={{ width: "70%", textAlign: "start" }}>Excel | Google Sheet</p>
            <img src={ExcelIcon} className="icon-in-case" />
          </a>
          <a
            href="https://help.etsy.com/hc/en-gb/articles/360000343508-How-to-Download-Your-Listing-Information?segment=selling"
            target="_blank"
            rel="noopener noreferrer"
            className="card-tuto-import border-grey"
          >
            <p style={{ width: "70%", textAlign: "start" }}>Etsy</p>
            <img src={EtsyIcon} className="icon-in-case" />
          </a>
          <a
            href="https://help.shopify.com/en/manual/products/import-export/export-products#export-your-products"
            target="_blank"
            rel="noopener noreferrer"
            className="card-tuto-import border-grey"
          >
            <p style={{ width: "70%", textAlign: "start" }}>Shopify</p>
            <img src={ShopifyIcon} className="icon-in-case" />
          </a>
          <a
            style={{ marginBottom: "30px" }}
            href="https://support.wix.com/en/article/wix-stores-exporting-products"
            target="_blank"
            rel="noopener noreferrer"
            className="card-tuto-import border-grey"
          >
            <p style={{ width: "70%", textAlign: "start" }}>Wix</p>
            <img src={WixIcon} className="icon-in-case" />
          </a>
        </div>
        <div
          style={{
            alignItems: "center",
            margin: "20px",
            width: windowSize > 1150 ? "40%" : "100%",
            marginRight: windowSize > 1150 && "2%",
            marginTop: "0",
          }}
          className="column card"
        >
          <p className="montserrat title-600">{t("components.supplier.11")}</p>
          <Link
            style={{ marginTop: "20px", width: "90%", height: "45px" }}
            to="/create-product"
            className="button big orange-back"
          >
            {t("components.supplier.12")}
          </Link>
          <img src={CreateProductIllustration} style={{ width: "90%", height: "auto", marginTop: "50px" }} />
        </div>
      </div>
    </div>
  );
};

export default EmptyInventory;
