import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { checkAccountCompleted } from "../../../utils/CheckAccountCompleted";

const SupplierSetupBanner = ({ user }) => {
  const { t } = useTranslation();
  const incompleteOnboardingStep = !(user.firstName && user.lastName)
    ? 1
    : !(user.company?.name && user.company?.address && user.company?.phone)
    ? 2
    : !user.shop?.name
    ? 3
    : !user.paymentEnable
    ? 4
    : 5;

  return (
    user && (
      <Link to="/supplier-onboarding-step-1" style={{ height: "60px" }} className="bottom-save">
        <p
          style={{
            fontSize: "22px",
            position: "absolute",
            left: "0px",
            height: "60px",
            alignItems: "center",
            background: "rgb(255 201 152)",
            padding: "20px",
          }}
          className="title-bold white row montserrat"
        >
          {user.orders_count > 0 ? t("components.banner.1") : t("components.banner.2")}
        </p>

        {checkAccountCompleted(user) === false && (
          <Link
            to={`/supplier-onboarding-step-${incompleteOnboardingStep}`}
            className="button orange-back montserrat"
          >
            {t("components.banner.3")}
          </Link>
        )}

        {user.orders_count > 0 && (
          <Link style={{ marginLeft: "20px" }} to="/my-orders" className="button-orange montserrat">
            {t("components.banner.4")}
          </Link>
        )}
      </Link>
    )
  );
};

export default SupplierSetupBanner;
