import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Categories = ({ handleChangeCategory, setMobileMenuOn }) => {
  const rootSubmenuKeys = ["menu1", "sub1", "menu2", "menu3", "menu4"];
  const [openKeys, setOpenKeys] = useState([]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const { t } = useTranslation();

  const handleMenuChange = (category) => {
    handleChangeCategory(category);
    setMobileMenuOn(false);
  };

  const items = [
    getItem(<p onClick={() => handleMenuChange("")}>{t("components.header.26")}</p>, "1", null),
    getItem(capitalizeFirstLetter(t("components.header.27")), "menu1", null, [
      getItem(<p onClick={() => handleMenuChange("Jewels")}>{t("all")}</p>, "2"),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.JEWELS"))}`, "sub1", null, [
        getItem(<p onClick={() => handleMenuChange("Jewels_JEWELS")}>{t("all")}</p>, "3"),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Rings")}>
            {capitalizeFirstLetter(t("components.header.subCategories.1"))}
          </p>,
          "5"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Necklaces")}>
            {capitalizeFirstLetter(t("components.header.subCategories.2"))}
          </p>,
          "6"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Bracelets")}>
            {capitalizeFirstLetter(t("components.header.subCategories.3"))}
          </p>,
          "7"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Earrings")}>
            {capitalizeFirstLetter(t("components.header.subCategories.4"))}
          </p>,
          "8"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Hair jewels")}>
            {capitalizeFirstLetter(t("components.header.subCategories.5"))}
          </p>,
          "9"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.ACCESSORIES"))}`, "sub2", null, [
        getItem(<p onClick={() => handleMenuChange("Jewels_ACCESSORIES")}>{t("all")}</p>, "4"),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Handbags")}>
            {capitalizeFirstLetter(t("components.header.subCategories.6"))}
          </p>,
          "10"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Backpacks")}>
            {capitalizeFirstLetter(t("components.header.subCategories.7"))}
          </p>,
          "11"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Hats & Caps")}>
            {capitalizeFirstLetter(t("components.header.subCategories.9"))}
          </p>,
          "13"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Belts")}>
            {capitalizeFirstLetter(t("components.header.subCategories.11"))}
          </p>,
          "14"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Jewels_ACCESSORIES_Gloves")}>
            {capitalizeFirstLetter(t("components.header.subCategories.12"))}
          </p>,
          "15"
        ),
      ]),
    ]),
    getItem(capitalizeFirstLetter(t("components.header.28")), "menu2", null, [
      getItem(<p onClick={() => handleMenuChange("Clothes")}>{t("all")}</p>, "15"),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.MEN’S"))}`, "sub3", null, [
        getItem(<p onClick={() => handleMenuChange("Clothes_MEN’S")}>{t("all")}</p>, "16"),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_MEN’S_CLOTHING_Men’s Pyjamas")}>
            {capitalizeFirstLetter(t("components.header.subCategories.16"))}
          </p>,
          "17"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_MEN’S_CLOTHING_Men’s jackets & coats")}>
            {capitalizeFirstLetter(t("components.header.subCategories.17"))}
          </p>,
          "18"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_MEN’S_CLOTHING_Men’s Pants")}>
            {capitalizeFirstLetter(t("components.header.subCategories.18"))}
          </p>,
          "19"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_MEN’S_SHOES_Men’s Boots")}>
            {capitalizeFirstLetter(t("components.header.subCategories.19"))}
          </p>,
          "20"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_MEN’S_SHOES_Men’s Slippers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.20"))}
          </p>,
          "21"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_MEN’S_SHOES_Men’s Sneakers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.21"))}
          </p>,
          "22"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.WOMEN’S"))}`, "sub4", null, [
        getItem(<p onClick={() => handleMenuChange("Clothes_WOMEN’S")}>{t("all")}</p>, "23"),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_CLOTHING_Women’s dresses & skirts")}>
            {capitalizeFirstLetter(t("components.header.subCategories.23"))}
          </p>,
          "24"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_CLOTHING_Women’s jackets & coats")}>
            {capitalizeFirstLetter(t("components.header.subCategories.25"))}
          </p>,
          "26"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_CLOTHING_Women’s pants")}>
            {capitalizeFirstLetter(t("components.header.subCategories.26"))}
          </p>,
          "27"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_CLOTHING_Women’s pajamas")}>
            {capitalizeFirstLetter(t("components.header.subCategories.27"))}
          </p>,
          "28"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_SHOES_Women’s boots")}>
            {capitalizeFirstLetter(t("components.header.subCategories.28"))}
          </p>,
          "29"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_SHOES_Women’s Slippers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.29"))}
          </p>,
          "30"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_WOMEN’S_SHOES_Women’s sneakers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.30"))}
          </p>,
          "31"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.GIRLS"))}`, "sub5", null, [
        getItem(<p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS")}>{t("all")}</p>, "32"),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls Dresses")}>
            {capitalizeFirstLetter(t("components.header.subCategories.32"))}
          </p>,
          "33"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls jackets & coats")}>
            {capitalizeFirstLetter(t("components.header.subCategories.34"))}
          </p>,
          "34"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls pants")}>
            {capitalizeFirstLetter(t("components.header.subCategories.35"))}
          </p>,
          "35"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls Pyjamas")}>
            {capitalizeFirstLetter(t("components.header.subCategories.36"))}
          </p>,
          "36"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls boots")}>
            {capitalizeFirstLetter(t("components.header.subCategories.37"))}
          </p>,
          "37"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls slippers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.38"))}
          </p>,
          "38"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_GIRLS_Kids - Girls sneakers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.39"))}
          </p>,
          "39"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.BOYS"))}`, "sub6", null, [
        getItem(<p onClick={() => handleMenuChange("Clothes_KIDS_BOYS")}>{t("all")}</p>, "32"),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys' costumes")}>
            {capitalizeFirstLetter(t("components.header.subCategories.41"))}
          </p>,
          "40"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys’ jackets & coats")}>
            {capitalizeFirstLetter(t("components.header.subCategories.43"))}
          </p>,
          "42"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys’ pants")}>
            {capitalizeFirstLetter(t("components.header.subCategories.44"))}
          </p>,
          "43"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys’ pyjamas")}>
            {capitalizeFirstLetter(t("components.header.subCategories.45"))}
          </p>,
          "44"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys’ boots")}>
            {capitalizeFirstLetter(t("components.header.subCategories.46"))}
          </p>,
          "45"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys’ slippers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.47"))}
          </p>,
          "46"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_KIDS_BOYS_Kids - Boys’ sneakers")}>
            {capitalizeFirstLetter(t("components.header.subCategories.48"))}
          </p>,
          "47"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.BABIES"))}`, "sub7", null, [
        getItem(<p onClick={() => handleMenuChange("Clothes_BABIES")}>{t("all")}</p>, "48"),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_BABIES_BOYS_Babies - Boys clothing")}>
            {capitalizeFirstLetter(t("components.header.subCategories.49"))}{" "}
            {capitalizeFirstLetter(t("components.header.categories.BOYS"))}
          </p>,
          "49"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_BABIES_BOYS_Babies - Boys shoes")}>
            {capitalizeFirstLetter(t("components.header.subCategories.50"))}{" "}
            {capitalizeFirstLetter(t("components.header.categories.BOYS"))}
          </p>,
          "50"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_BABIES_GIRLS_Babies - Girls clothing")}>
            {capitalizeFirstLetter(t("components.header.subCategories.51"))}{" "}
            {capitalizeFirstLetter(t("components.header.categories.GIRLS"))}
          </p>,
          "51"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Clothes_BABIES_GIRLS_Babies - Girls shoes")}>
            {capitalizeFirstLetter(t("components.header.subCategories.52"))}{" "}
            {capitalizeFirstLetter(t("components.header.categories.GIRLS"))}
          </p>,
          "52"
        ),
      ]),
    ]),
    getItem(capitalizeFirstLetter(t("components.header.29")), "menu3", null, [
      getItem(<p onClick={() => handleMenuChange("House")}>{t("all")}</p>, "53"),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.FURNITURE"))}`, "sub8", null, [
        getItem(<p onClick={() => handleMenuChange("House_FURNITURE")}>{t("all")}</p>, "54"),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Bedroom furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.53"))}
          </p>,
          "55"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Dining room furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.54"))}
          </p>,
          "56"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Living room furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.55"))}
          </p>,
          "57"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Kids Furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.56"))}
          </p>,
          "58"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Bathroom furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.57"))}
          </p>,
          "59"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Office furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.58"))}
          </p>,
          "60"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Lighting")}>
            {capitalizeFirstLetter(t("components.header.subCategories.59"))}
          </p>,
          "61"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_FURNITURE_Storage & Organization")}>
            {capitalizeFirstLetter(t("components.header.subCategories.60"))}
          </p>,
          "62"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.DECORATION"))}`, "sub9", null, [
        getItem(<p onClick={() => handleMenuChange("House_DECORATION")}>{t("all")}</p>, "63"),
        getItem(
          <p onClick={() => handleMenuChange("House_DECORATION_Wall decor")}>
            {capitalizeFirstLetter(t("components.header.subCategories.61"))}
          </p>,
          "64"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_DECORATION_Clocks")}>
            {capitalizeFirstLetter(t("components.header.subCategories.62"))}
          </p>,
          "65"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_DECORATION_Vases")}>
            {capitalizeFirstLetter(t("components.header.subCategories.63"))}
          </p>,
          "66"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_DECORATION_Candles")}>
            {capitalizeFirstLetter(t("components.header.subCategories.64"))}
          </p>,
          "67"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_DECORATION_Sculptures")}>
            {capitalizeFirstLetter(t("components.header.subCategories.65"))}
          </p>,
          "68"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_DECORATION_Glass Art")}>
            {capitalizeFirstLetter(t("components.header.subCategories.66"))}
          </p>,
          "69"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.KITCHEN"))}`, "sub10", null, [
        getItem(<p onClick={() => handleMenuChange("House_KITCHEN")}>{t("all")}</p>, "70"),
        getItem(
          <p onClick={() => handleMenuChange("House_KITCHEN_Cookware")}>
            {capitalizeFirstLetter(t("components.header.subCategories.67"))}
          </p>,
          "71"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_KITCHEN_Drinkware")}>
            {capitalizeFirstLetter(t("components.header.subCategories.68"))}
          </p>,
          "72"
        ),
        getItem(
          <p onClick={() => handleMenuChange("House_KITCHEN_Serveware")}>
            {capitalizeFirstLetter(t("components.header.subCategories.69"))}
          </p>,
          "73"
        ),
      ]),
    ]),
    getItem(t("components.header.animals"), "menu4", null, [
      getItem(`${capitalizeFirstLetter(t("components.header.categories.CATS"))}`, "sub11", null, [
        getItem(<p onClick={() => handleMenuChange("Pet_CATS")}>{t("all")}</p>, "74"),
        getItem(
          <p onClick={() => handleMenuChange("Pet_CATS_Cat furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.70"))}
          </p>,
          "75"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Pet_CATS_Cat collars & leashes")}>
            {capitalizeFirstLetter(t("components.header.subCategories.72"))}
          </p>,
          "76"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Pet_CATS_Cat clothing")}>
            {capitalizeFirstLetter(t("components.header.subCategories.73"))}
          </p>,
          "77"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Pet_CATS_Cat Bedding")}>
            {capitalizeFirstLetter(t("components.header.subCategories.74"))}
          </p>,
          "78"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.DOGS"))}`, "sub12", null, [
        getItem(<p onClick={() => handleMenuChange("Pet_DOGS")}>{t("all")}</p>, "79"),
        getItem(
          <p onClick={() => handleMenuChange("Pet_DOGS_Dog furniture")}>
            {capitalizeFirstLetter(t("components.header.subCategories.70"))}
          </p>,
          "80"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Pet_DOGS_Dog collars & leashes")}>
            {capitalizeFirstLetter(t("components.header.subCategories.72"))}
          </p>,
          "81"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Pet_DOGS_Dog clothing")}>
            {capitalizeFirstLetter(t("components.header.subCategories.73"))}
          </p>,
          "82"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Pet_DOGS_Dog Bedding")}>
            {capitalizeFirstLetter(t("components.header.subCategories.74"))}
          </p>,
          "83"
        ),
      ]),
    ]),
    getItem(t("components.header.30"), "menu5", null, [
      getItem(`${capitalizeFirstLetter(t("components.header.categories.KITCHEN"))}`, "sub12", null, [
        getItem(<p onClick={() => handleMenuChange("Tools_KITCHEN")}>{t("all")}</p>, "80"),
        getItem(
          <p onClick={() => handleMenuChange("Tools_KITCHEN_Soap")}>
            {capitalizeFirstLetter(t("components.header.subCategories.75"))}
          </p>,
          "84"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_KITCHEN_Brush")}>
            {capitalizeFirstLetter(t("components.header.subCategories.76"))}
          </p>,
          "85"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_KITCHEN_Sponges")}>
            {capitalizeFirstLetter(t("components.header.subCategories.77"))}
          </p>,
          "86"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_KITCHEN_Towels")}>
            {capitalizeFirstLetter(t("components.header.subCategories.78"))}
          </p>,
          "87"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_KITCHEN_Straw")}>
            {capitalizeFirstLetter(t("components.header.subCategories.79"))}
          </p>,
          "88"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_KITCHEN_Knife")}>
            {capitalizeFirstLetter(t("components.header.subCategories.80"))}
          </p>,
          "89"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.HYGIENE"))}`, "sub13", null, [
        getItem(<p onClick={() => handleMenuChange("Tools_HYGIENE")}>{t("all")}</p>, "81"),
        getItem(
          <p onClick={() => handleMenuChange("Tools_HYGIENE_Cotton rounds")}>
            {capitalizeFirstLetter(t("components.header.subCategories.81"))}
          </p>,
          "90"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_HYGIENE_Cotton swabs")}>
            {capitalizeFirstLetter(t("components.header.subCategories.82"))}
          </p>,
          "91"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_HYGIENE_Storage bags")}>
            {capitalizeFirstLetter(t("components.header.subCategories.83"))}
          </p>,
          "92"
        ),
      ]),
      getItem(`${capitalizeFirstLetter(t("components.header.categories.OUTDOORS"))}`, "sub14", null, [
        getItem(<p onClick={() => handleMenuChange("Tools_OUTDOORS")}>{t("all")}</p>, "8"),
        getItem(
          <p onClick={() => handleMenuChange("Tools_OUTDOORS_Bird feeder")}>
            {capitalizeFirstLetter(t("components.header.subCategories.84"))}
          </p>,
          "93"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_OUTDOORS_Axes")}>
            {capitalizeFirstLetter(t("components.header.subCategories.85"))}
          </p>,
          "94"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_OUTDOORS_Shovels")}>
            {capitalizeFirstLetter(t("components.header.subCategories.86"))}
          </p>,
          "95"
        ),
        getItem(
          <p onClick={() => handleMenuChange("Tools_OUTDOORS_Compost")}>
            {capitalizeFirstLetter(t("components.header.subCategories.87"))}
          </p>,
          "96"
        ),
      ]),
    ]),
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys(
        keys.filter(
          (key) =>
            (!key.includes("menu") && key !== latestOpenKey) ||
            key === latestOpenKey ||
            latestOpenKey.includes("sub")
        )
      );
    } else {
      setOpenKeys(keys || []);
    }
  };

  return (
    <>
      <p
        style={{ fontSize: "22px", marginLeft: "-5px", marginBottom: "10px" }}
        className="title-bold montserrat-800"
      >
        {t("categories")}
      </p>
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{
          width: 256,
        }}
        items={items}
      />
    </>
  );
};

export default Categories;
