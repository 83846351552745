import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "antd";

import { Modal } from "../../wrappers";

import CompleteAccountImg from "../../images/illustrations/complete_account.svg";

const UnverifiedSupplierAccount = ({ setModalOn, modalOn, user }) => {
  const isOrdersPage = window.location.href.includes("orders");

  const { t } = useTranslation();

  const incompleteOnboardingStep = !(user.firstName && user.lastName)
    ? 1
    : !(user.company?.name && user.company?.address && user.company?.phone)
    ? 2
    : !user.shop?.name
    ? 3
    : !user.paymentEnable
    ? 4
    : 5;

  return (
    <Modal
      className="modal-account invitation"
      onOk={() => {}}
      visible={modalOn}
      onCancel={() => setModalOn(false)}
      footer={[
        <Button key="back" onClick={() => setModalOn(false)}>
          {t("cancel")}
        </Button>,
      ]}
    >
      <div style={{ alignItems: "center" }} className="column">
        <p style={{ fontWeight: "700", fontSize: "22px" }} className="title-montserrat big">
          {t("please")}
          <span style={{ marginLeft: "6px", marginRight: "6px", fontWeight: "900" }}>
            {t("components.modal.2")}
          </span>
          {isOrdersPage ? t("toStartReceivePayment") : t("beforeSendingOrder")}
        </p>
        <img
          src={CompleteAccountImg}
          style={{ width: "80%", height: "auto", marginTop: "0px", marginBottom: "20px" }}
        />
        <Link
          to={`/supplier-onboarding-step-${incompleteOnboardingStep}`}
          className="button big orange-back montserrat"
        >
          {t("components.modal.3")}
        </Link>
      </div>
    </Modal>
  );
};

export default UnverifiedSupplierAccount;
