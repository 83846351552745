import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import {Modal} from '../../wrappers'
import { orderServices } from "../../services/order";
import { url } from "../../api/url";
import { header } from "../../api/header";

const ScorpionMart = ({ price, orderReference, setPassOrder, orders, order, setOrders, cost }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [isModalConfirmDisplayed, setIsModalConfirmDisplayed] = useState(false);
  const [iban, setIban] = useState("");
  const { t } = useTranslation();

  const getIban = async () => {
    const resIban = await fetch(`${url}/user/find-iban/${order?.creater?.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (resIban.status === 200) {
      const resJson = await resIban.json();
      setIban(resJson.iban);
    }
  };

  useEffect(() => {
    if (order?.creater?.id) {
      getIban();
    }
  }, [order]);

  const informations = {
    name: "SIGMA RULE LIMITED",
    iban: "BE56967537809388",
    amount: parseFloat(price),
    structuredReference: orderReference,
    information: orderReference,
  };

  const handlePlaceOrder = async () => {
    const resPayment = await orderServices.paymentConfirmation({
      orderId: order._id,
      sellerPaid: cost.total,
      shippingPaid: cost.shipping,
      productPaid: cost.products,
      bankTransferRef: orderReference,
    });
    if (resPayment.status === 200) {
      const resJson = await resPayment.json();
      setPassOrder({});
      let newOrders = orders;
      let newOrder = order;
      orders.forEach((orderP, index) => {
        if (order._id === orderP._id) {
          newOrder.status = "payment confirmation";
          newOrder = {
            ...newOrder,
            paid: { ...order.paid, ...resJson.updatedPaid },
          };
          newOrders[index] = newOrder;
          setOrders([...newOrders]);
        }
      });
      message.success(t("messages.orderPlaced"));
    } else {
      message.error(t("messages.paymentError"));
    }
  };

  return (
    <div className="column">
      {isModalConfirmDisplayed && (
        <Modal
          style={{ padding: "30px" }}
          className="modal-account"
          visible={true}
          onCancel={() => {
            setIsModalConfirmDisplayed(false);
          }}
          footer={[]}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }} className="column width-100">
            <p className="title">{t("ibanPayment.5")}</p>
            <div style={{ marginTop: "20px" }} className="row">
              <button
                style={{ margin: "10px" }}
                onClick={() => setIsModalConfirmDisplayed(false)}
                className="button-red"
              >
                {t("no")}
              </button>
              <button style={{ margin: "10px" }} onClick={handlePlaceOrder} className="button orange-back">
                {t("yes")}
              </button>
            </div>
          </div>
        </Modal>
      )}
      <p className="title-bold montserrat grey">{t("ibanPayment.1")}</p>
      <p className="title grey start">{t("ibanPayment.2", { country: "Pakistan" })}</p>
      <div style={{ padding: "20px", background: "#F6F6F6", marginTop: "20px" }} className="row-wrap">
        <div style={{ margin: "10px" }} className="column">
          <p className="title grey start"> {iban}</p>
        </div>
      </div>
      <button
        onClick={() => setIsModalConfirmDisplayed(true)}
        style={{ marginTop: "15px" }}
        className="button big orange-back width-100"
      >
        {t("ibanPayment.button")}
      </button>
    </div>
  );
};

export default ScorpionMart;
