import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { LocalStatesContext } from "../../../providers/LocalStates";
import countryListJson from "../../../utils/country_list.json";
import { urlParams } from "../../../utils/urlParams";

import "./style.scss";

const FilterLabels = ({
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxprice,
  locationSelect,
  setLocationSelect,
  deliverTo,
  setDeliverTo,
  maxDeliveryTime,
  setMaxDeliveryTime,
  originRegion,
  setOriginRegion,
  setRefreshProductsFunction,
}) => {
  const { localStates } = useContext(LocalStatesContext);
  const { t } = useTranslation();
  const paramsUrl = urlParams();

  const isPriceFilterDisplayed =
    (paramsUrl.minPrice && paramsUrl.minPrice.toString() !== "0") ||
    (paramsUrl.maxPrice && paramsUrl.maxPrice.toString() !== "0");

  return (
    <div style={{ marginTop: "20px" }} className="row width-100 row-wrap">
      {isPriceFilterDisplayed ? (
        <div className="filter-label">
          {t("filterLabels.price", {
            filterValue: minPrice || t("filterLabels.undefined"),
            filterValue2: maxPrice || t("filterLabels.undefined"),
            currency: localStates.currency,
          })}{" "}
          <CloseOutlined
            onClick={() => {
              setMinPrice("");
              setMaxprice("");
              setRefreshProductsFunction({ params: { minPrice: "", maxPrice: "", page: 1 } });
            }}
            style={{ color: "white", fontSize: "16px", marginLeft: "15px" }}
          />
        </div>
      ) : (
        <></>
      )}
      {originRegion && (
        <div className="filter-label">
          {t("filterLabels.location", {
            filterValue: countryListJson.find((region) => region.key === originRegion)?.title,
          })}{" "}
          <CloseOutlined
            onClick={() => {
              setOriginRegion("");
              setRefreshProductsFunction({ params: { originRegion: "", minPrice, maxPrice, page: 1 } });
            }}
            style={{ color: "white", fontSize: "16px", marginLeft: "15px" }}
          />
        </div>
      )}
      {locationSelect?.title && (
        <div className="filter-label">
          {t("filterLabels.location", {
            filterValue: locationSelect.title,
          })}{" "}
          <CloseOutlined
            onClick={() => {
              setLocationSelect({ key: "", title: "" });
              setRefreshProductsFunction({
                params: { locationSelect: { key: "", title: "" }, minPrice, maxPrice, page: 1 },
              });
            }}
            style={{ color: "white", fontSize: "16px", marginLeft: "15px" }}
          />
        </div>
      )}
      {deliverTo?.title && (
        <div className="filter-label">
          {t("filterLabels.deliverTo", {
            filterValue: deliverTo.title,
          })}{" "}
          <CloseOutlined
            onClick={() => {
              setDeliverTo({ key: "", title: "" });
              setMaxDeliveryTime(0);
              setRefreshProductsFunction({
                params: { deliverTo: { key: "", title: "" }, maxDeliveryTime: "", minPrice, maxPrice, page: 1 },
              });
            }}
            style={{ color: "white", fontSize: "16px", marginLeft: "15px" }}
          />
        </div>
      )}
      {maxDeliveryTime > 0 && (
        <div className="filter-label">
          {t("filterLabels.maxDeliveryTime", {
            filterValue: maxDeliveryTime,
          })}{" "}
          <CloseOutlined
            onClick={() => {
              setMaxDeliveryTime(0);
              setRefreshProductsFunction({
                params: { maxDeliveryTime: "", minPrice, maxPrice, page: 1 },
              });
            }}
            style={{ color: "white", fontSize: "16px", marginLeft: "15px" }}
          />
        </div>
      )}
    </div>
  );
};

export default FilterLabels;
