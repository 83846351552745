import React, { useEffect, useState } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from "recharts";
import moment from "moment";

const RegistrationsChart = ({ intervals }) => {
  const [datas, setDatas] = useState([]);

  const parseDate = (date) => {
    return moment(date).utc().format("DD/MM YYYY", "Europe/Paris");
  };

  useEffect(() => {
    if (intervals?.length) {
      setDatas(
        intervals.map((interval) => ({
          total_reseller_subscription: interval.total_reseller_subscription,
          subscription_percentage_per_visitor: interval.subscription_percentage_per_visitor,
          subscription_percentage_per_registration: interval.subscription_percentage_per_registration,
          unsubscribe_reseller: interval.unsubscribe_reseller,
          date: parseDate(interval.date),
        }))
      );
    }
  }, [intervals]);

  if (!datas.length) {
    return <></>;
  }

  return (
    <div
      className="card"
      style={{
        width: "40vw",
        margin: "2vw",
        boxShadow: "none",
      }}
    >
      <div style={{ marginBottom: "20px" }} className="row row-wrap width-100 space-between">
        <p style={{ margin: "10px", fontSize: "18px" }} className="title-bold start">
          Abonnements par visiteur : <br />{" "}
          <span style={{ fontSize: "24px" }} className="title-bold">
            {" "}
            {datas[datas.length - 1].subscription_percentage_per_visitor}%
          </span>
        </p>
        <p style={{ margin: "10px", fontSize: "18px" }} className="title-bold start">
          Abonnements par inscription : <br />{" "}
          <span style={{ fontSize: "24px" }} className="title-bold">
            {" "}
            {datas[datas.length - 1].subscription_percentage_per_registration}%
          </span>
        </p>
        <p style={{ margin: "10px", fontSize: "18px" }} className="title-bold start">
          Abonnements Totaux : <br />{" "}
          <span style={{ fontSize: "24px" }} className="title-bold">
            {" "}
            {datas[datas.length - 1].total_reseller_subscription}
          </span>
        </p>
      </div>
      <ResponsiveContainer minHeight="250px" minWidth="300px" width="100%" height="100%">
        <AreaChart margin={{ top: 0, right: 0, bottom: 6, left: 10 }} barCategoryGap={1} data={datas}>
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4A90E2" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#4A90E2" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="none" strokeDasharray="2 2" />
          <YAxis yAxisId="left" orientation="left" width={70} />
          <YAxis yAxisId="right" orientation="right" width={70} />
          <XAxis axisLine={false} tick dataKey="date" />
          <Tooltip />
          <Area
            yAxisId="left"
            type="monotone"
            dataKey="total_reseller_subscription"
            stroke="#4A90E2"
            fillOpacity={1}
            fill="url(#total)"
          />
          <Area
            yAxisId="right"
            type="monotone"
            dataKey="subscription_percentage_per_visitor"
            stroke="#2873c9"
            fillOpacity={1}
            fill="url(#total)"
          />
          <Area
            yAxisId="right"
            type="monotone"
            dataKey="subscription_percentage_per_registration"
            stroke="#0b54a8"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
      <div style={{ marginBottom: "20px" }} className="row row-wrap width-100 space-between">
        <p style={{ margin: "10px", fontSize: "18px" }} className="title-bold start">
          Désabonnement Totaux : <br />{" "}
          <span style={{ fontSize: "24px" }} className="title-bold">
            {" "}
            {datas[datas.length - 1].unsubscribe_reseller}
          </span>
        </p>
      </div>
      <ResponsiveContainer minHeight="250px" minWidth="300px" width="100%" height="100%">
        <AreaChart margin={{ top: 0, right: 0, bottom: 6, left: 10 }} barCategoryGap={1} data={datas}>
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4A90E2" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#4A90E2" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="none" strokeDasharray="2 2" />
          <YAxis yAxisId="left" orientation="left" width={70} />
          <YAxis yAxisId="right" orientation="right" width={70} />
          <XAxis axisLine={false} tick dataKey="date" />
          <Tooltip />
          <Area
            yAxisId="left"
            type="monotone"
            dataKey="unsubscribe_reseller"
            stroke="#4A90E2"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RegistrationsChart;
