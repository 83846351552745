import React, { useState, useEffect } from "react";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { Button, message } from "antd";

import { Modal } from "../../wrappers";

const ManageResearch = () => {
  const [statistics, setStatistics] = useState([]);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));
  const [modalOn, setModalOn] = useState(false);

  useEffect(() => {
    getStatistics();
  }, []);

  const getStatistics = async () => {
    const res = await fetch(`${url}/statistics/${adminId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setStatistics(resJson.data);
    } else message.error("Error occured");
  };

  return (
    <>
      <button style={{ marginBottom: "20px" }} className="button orange-back" onClick={() => setModalOn(true)}>
        MANAGE RECHERCHE MARKETPLACE
      </button>

      {modalOn && (
        <Modal
          visible={true}
          onCancel={() => setModalOn(false)}
          footer={[
            <Button key="back" onClick={() => setModalOn(false)}>
              Cancel
            </Button>,
          ]}
        >
          {statistics.marketplace_research?.reverse()?.map((research, index) => {
            return (
              <div style={{ marginBottom: "20px" }} className="card">
                <p className="text-bold">
                  <span className="text-300" style={{ marginRight: "10px" }}>
                    Recherche:
                  </span>
                  {research.word}
                </p>
                <p className="text-bold">
                  <span className="text-300" style={{ marginRight: "10px" }}>
                    Nombre de fois recherché:
                  </span>
                  {research.count}
                </p>
                <div className="table-container">
                  {research.user_emails?.map((email) => {
                    if (email) return <div className="row-table">{email}</div>;
                  })}
                </div>
              </div>
            );
          })}
        </Modal>
      )}
    </>
  );
};

export default ManageResearch;
