import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

const ArticleHeaderNav = ({ article }) => {
  const { i18n } = useTranslation();
  return (
    <div style={{ height: "30px", marginTop: "30px" }} className="width-100">
      <div className="row width-100">
        <a
          style={{ color: "#B9B9B9", fontSize: "13px" }}
          className="text-300 underline pointer"
          href="https://01supply.com"
        >
          Home
        </a>
        <CaretRightOutlined className="grey" style={{ marginLeft: "10px", marginRight: "10px" }} />
        <Link
          style={{ color: "#B9B9B9", fontSize: "13px" }}
          className="text-300 underline pointer"
          to={routes.blog(i18n.language).path}
        >
          Blog
        </Link>
        <CaretRightOutlined className="grey" style={{ marginLeft: "10px", marginRight: "10px" }} />
        <p style={{ minHeight: "auto" }} className="text-bold grey text-ellipsis">
          {article.title}
        </p>
      </div>
      <Divider style={{ marginTop: "10px" }} />
    </div>
  );
};

export default ArticleHeaderNav;
