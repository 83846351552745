export const auth = {
  saveToken: (user) => {
    localStorage.setItem("tokenCreatosell", user.token);
    localStorage.setItem("typeAccountCreatosell", user.type);
    localStorage.setItem("emailAccountCreatosell", user.email);
    localStorage.setItem("idAccountCreatosell", user.id);
    localStorage.setItem("secretAccountId01supply", user.secretId);
    localStorage.setItem("viewPlan01supply", "false");
  },
};
