import React, { useState, useEffect } from "react";
import { message, Select, Divider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { wizishopServices } from "../../services/wizishop";

import wizishopIllustration from "../../images/illustrations/wizishop.svg";
import dropiziIllustration from "../../images/illustrations/dropizi.svg";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const WizishopShopSelector = ({
  setSelectedWizishopShopId,
  selectedWizishopShopId,
  user,
  setUser,
  title,
}) => {
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (user?.wizishop?.shop_list) {
      const storageSelectedWizishopShopId = localStorage.getItem("selectedWizishopShopId");
      setSelectedWizishopShopId(
        user.wizishop.shop_list.find((shop) => shop.id?.toString() === storageSelectedWizishopShopId)?.id ||
          user.wizishop.default_shop_id
      );
    }
  }, []);

  const refreshWiziShops = (e) => {
    setIsRefreshLoading(true);
    e.stopPropagation();
    e.preventDefault();
    wizishopServices.refreshShops().then(async (res) => {
      if (res.status === 200) {
        setIsRefreshDisabled(true);
        const resJson = await res.json();
        setUser({ ...user, wizishop: { ...user.wizishop, shop_list: resJson.shop_list } });
        message.success({
          content: t("messages.storesCorrectlyRefresh", { cms: user.wizishop.cms }),
          duration: 3,
        });
      } else {
        message.error("messages.error");
      }
    });
  };
  if (!user?.wizishop?.cms || user?.wizishop?.shop_list?.length < 2) {
    return <></>;
  }
  return (
    <div style={{ boxShadow: "none", minHeight: "150px" }} className="card width-100">
      <img
        src={user.wizishop.cms.includes("dropizi") ? dropiziIllustration : wizishopIllustration}
        alt="wizishop illustration"
        style={{ width: "120px", marginBottom: "12px" }}
      />
      <p style={{ marginBottom: "8px", fontSize: "16px" }} className="text montserrat">
        {title}
      </p>
      <Select
        style={{ width: "280px" }}
        onChange={(value) => {
          setSelectedWizishopShopId(value);
          localStorage.setItem("selectedWizishopShopId", value);
        }}
        value={parseInt(selectedWizishopShopId)}
        defaultValue={t("selectYourShop")}
        dropdownRender={(menu) => (
          <>
            {menu}
            {!isRefreshDisabled && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <button
                  style={{ margin: "9px", width: "90%", marginLeft: "5%", height: "35px" }}
                  onClick={refreshWiziShops}
                  className="button orange-back"
                >
                  {isRefreshLoading ? (
                    <LoadingOutlined style={{ color: "white", fontSize: "20px" }} />
                  ) : (
                    t("refreshMyStores")
                  )}
                </button>
              </>
            )}
          </>
        )}
      >
        {user?.wizishop?.shop_list.map((shop) => (
          <Option value={shop.id}>{shop.name}</Option>
        ))}
      </Select>
    </div>
  );
};

export default WizishopShopSelector;
