import { url } from "../api/url";
import { header } from "../api/header";

export const klaviyoServices = {
  checkStatus: async (
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/klaviyo/check-status/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  updateProfil: async (
    { properties },
    idAccount = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/klaviyo/contact/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({ properties }),
    });
  },
};
