import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import Menu from "../../components/menu/AffiliateMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import CheckToken from "../../utils/RedirectLogin";
import { userServices } from "../../services/user";

const PaymentHistory = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [affiliations, setAffiliations] = useState([]);
  const [user, setUser] = useState({});

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    userServices
      .findAffiliation()
      .then((res) => res.json())
      .then((res) => {
        setAffiliations(res.affiliation);
      });
    userServices
      .me()
      .then((res) => res.json())
      .then((res) => setUser(res.data));
  }, []);

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, data) => `$${text}`,
    },
    {
      title: "Destination",
      dataIndex: "destination",
    },
    {
      title: "Identification",
      dataIndex: "code",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
  ];

  return (
    <div className="page affiliation">
      <CheckToken />
      <div className="page-start">
        {windowSize > 1150 ? <Menu user={user} /> : <MobileMenu user={user} locationP="affiliation" />}
        <div
          style={
            windowSize > 1150
              ? { padding: "50px", paddingLeft: "30px", paddingTop: "30px" }
              : { padding: "50px", paddingBottom: "100px", paddingLeft: "30px", paddingRight: "30px" }
          }
          className="column-scrolling start"
        >
          <h1
            style={{ fontSize: "28px", marginBottom: "30px" }}
            className="title montserrat-800 width-100 start"
          >
            {t("affiliateDashboard.paymentHistory.title")}
          </h1>

          <Table
            style={{ width: "100%" }}
            pagination={false}
            columns={columns}
            scroll={{ x: 1650, y: 2000 }}
            dataSource={affiliations?.payout_history || []}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
