import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { routes } from "../../routes";

const Menu = () => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ borderRadius: "6px", border: "1px solid grey", width: "max-content" }} className="row">
      <Link
        to="/documentation/supplier"
        style={{ borderRight: "1px solid grey" }}
        className={
          window.location.href.includes("supplier")
            ? "title-300 column center twoChoice-on"
            : "title-300 column center twoChoice"
        }
      >
        {t("components.menu.33")}
      </Link>
      <a
        href="https://www.01supply.com/help"
        style={{ width: "70px", padding: "10px", cursor: "pointer" }}
        className={
          window.location.href.includes("reseller")
            ? "title-300 column center twoChoice-on"
            : "title-300 column center twoChoice"
        }
      >
        {t("components.menu.34")}
      </a>
    </div>
  );
};

export default Menu;
