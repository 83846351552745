import React from "react";

import { Modal } from "../../wrappers";
import JoinUs from "../../pages/JoinUs";

const RegisterModal = ({ setModal, modalOn, isRegister, isSupplier }) => {
  return (
    <Modal className="modal-small" visible={modalOn} onCancel={() => setModal(false)} footer={[]}>
      <JoinUs isRegister={isRegister} isSupplier={isSupplier} setModal={setModal} redirectTo={modalOn} />
    </Modal>
  );
};

export default RegisterModal;
