import React from "react";

const MessageCard = ({ message, user, id }) => {
  return (
    <div id={id} className={message.sender_id === user._id ? "message-card sender" : "message-card receiver"}>
      <p style={{ fontSize: "15px", overflowWrap: "anywhere" }} className="title-bold white">
        {message.text}
      </p>
      <p
        className="text-300 white"
        style={{ position: "absolute", bottom: "5px", right: "9px", fontSize: "13px" }}
      >
        {message.date}
      </p>
    </div>
  );
};

export default MessageCard;
