import React, {useState} from 'react'
import { WarningOutlined } from '@ant-design/icons';
import DocMenu from '../../components/menu/DocMenu'
import Menu from '../../components/menu/FirstMenu'
import MobileMenu from '../../components/menu/mobile/FirstMenu'
import {Helmet} from "react-helmet";


const HowItWork = () => {
    const [user, setUser] = useState({})
    const [idAccount] = useState(localStorage.getItem('idAccountCreatosell'))
    const [token] = useState(localStorage.getItem('tokenCreatosell'))
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    window.addEventListener("resize", function (event) {
        setWindowSize(window.innerWidth);
      });
      
    return(
        <div className="page">
          <Helmet>
              <meta charSet="utf-8" />
              <title>Supplier Documentation | 01supply</title>
              <link rel="canonical" href="https://app.01supply.com/documentation/supplier" />
              <meta name="description" content="How does 01supply work? How to add products and get sales on 01supply? How to manage orders? Learn tips and hacks through our Supplier Documentation."/>
          </Helmet>
            <div className="page-start">
                {windowSize > 1150 ?
                <Menu/>
                : <MobileMenu locationP="setting"/>}
                <div style={windowSize > 1150 ? {alignItems: 'center', paddingTop: '70px', display: 'flex'}
                : {alignItems: 'center', paddingTop: '30px'}} className="column-scrolling">
                    <DocMenu/>
                    <div id="supplier-doc" className="column" style={windowSize > 1150 ? {width: '60%', marginLeft: "0%", marginTop: '100px'}
                    : {width: '80%', marginLeft: "10%", marginTop: '30px'}}>
                        <h3 style={{fontSize: '40px'}} className="title-300">
                          How it works ?
                        </h3>
                        <h2 style={{fontWeight: '300', fontSize: '20px'}}>
                          01supply helps you
                          <span className="text-bold">
                            {" "}delegate your marketing{" "}
                          </span>
                          and your online presence by letting resellers work for you.
                        </h2>
                        <ul style={{fontSize: '16px'}}>
                          <li>You can either invite your own reseller or just post your products to the marketplace.</li>
                          <li style={{fontWeight: 'bold'}}>Resellers can easily find your products and list them on their online store. </li>
                          <li>Once you get an order from a reseller, we make it easy for you to get paid.</li>
                        </ul>
                        <p style={{fontWeight: '300', fontSize: '16px'}}>
                          <span className="text-bold">
                            Listing is unlimited{" "}
                          </span>
                          and everything can be done for free. You create, they sell.
                        </p>

                        <h3 id="product-creation" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                          Product creation
                        </h3>

                        <p style={{fontWeight: '300', fontSize: '16px'}}>
                          You are ready to get started ? Awesome ! So it is time for your first product.<br/><br/>
                          You can either create a product by clicking the “+” button on the bottom right of the marketplace or click “Add a new product” on the top of the inventory page.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Image : {" "}
                          </span>
                          Upload up to 5 images (jpg, png & max 2Mb). Pay attention to ensure that these images display clear vision of the product. We recommend uploading at least one image on white background. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Name : {" "}
                          </span>
                          Be descriptive to help resellers find your product on the marketplace. Enter keywords like what is your product for, how it looks like, what are the materials, etc. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Price : {" "}
                          </span>
                          This is the price that resellers will pay you once they get an order from their customers. Cheap prices make your product more likely to be sold.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Compare at price : {" "}
                          </span>
                          Let’s say you want to offer a discount on a certain product. Compared price lets you choose the amount that will be crossed (must be bigger than the price).
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Quantity : {" "}
                          </span>
                          One the quantity hits 0, resellers can’t buy it anymore. Make sure the quantity is frequently updated to ensure good relation with resellers.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Description : {" "}
                          </span>
                          Description must be as complete as possible. Tell people what your product is about, what are the manufacturing materials, what are the features (size, weight, color,...). 
                          Also, be sure to include your warranties and terms and conditions (warn resellers about possible custom fees or refund policy). 
                          You can also share information about packaging and other benefits you offer. For example custom packaging or product customization. The more you write, the easier it is for resellers to find you on the marketplace.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Origin : {" "}
                          </span>
                          The origin country is the country from where you are shipping goods. This must be exact as we will conduct audits. Lying about origin may lead to warning on your account, suspension or even ban.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Processing time : {" "}
                          </span>
                          This is the time you need to ship the product. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Shipping methods : {" "}
                          </span>
                          You can add shipping methods in your settings. Once resellers get an order for your product, they will be able to choose a shipping method from those you added to the product.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Variants : {" "}
                          </span>
                          Let’s say your product has different colors. You can add variants to it by turning the switch on. Options are the title of your variant (example: Size, Color,...). Once you “add a new variant”, you can name these options for each variant.
                          Be sure to fill all the information to publish a variant (picture, option names, price, compare at price and quantity for each variant).
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Selling limit : {" "}
                          </span>
                          You can enable the selling limit to set a maximum number of resellers or orders. This can be useful if you can’t afford unlimited product output. 
                          Let’s say you want to work with one reseller only. Just set the “Max. number of resellers” to “1”. Then only one reseller will be able to list your product in his shop. You can also set a maximum order per month. This is only as an indication purpose for resellers.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Sorting : {" "}
                          </span>
                          Listing your product to the right place makes it easier for resellers to find you. You can add your product to one category and add a maximum of 3 different tags. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        Good job ! Your product is now completed. You can click on “Save” to publish it to the marketplace. Then you can find your product at any time in your Inventory.
                        </p>

                        <h3 id="marketplace" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                          Marketplace
                        </h3>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '0px'}}>
                          On the marketplace, you can find every product listed by other suppliers. You can search for a product using the search bar or even filter the results. 
                          <br/>On the reseller side, they are able to search for a product and import it on their store.
                          To stand out on the marketplace, we recommend you to be as complete as possible while writing your product description. The more descriptive you are, the easier it is for resellers to find you and to list your product on their store.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Product page : {" "}
                          </span>
                          You can click on a product to access the product page. Product page displays all the information entered during the product creation process.
                        </p>

                        <h3 id="inventory" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Inventory
                        </h3>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '0px'}}>
                        In your inventory, you can find all your listed products. You can either edit them or delete them. Note that resellers will be notified if you edit a product that they have listed on their store. 
                        Avoid deleting products that are best selling. This can result in resellers dissatisfaction as they won’t be able to get supply for this product.
                        </p>

                        <h3 id="orders" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Orders
                        </h3>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '0px'}}>
                        <WarningOutlined style={{color: 'red'}} /> Orders must be shipped to the “Delivery Address”. Don’t ship to the reseller address <WarningOutlined style={{color: 'red'}} /><br/><br/>

                        Once resellers get an order from their customer, they will place an order to you. Your orders will be displayed in the “Orders” section. 
                        You need to fulfill orders as fast as possible to improve your ranking in the marketplace. 
                        </p>
                        <ul style={{fontSize: '16px', marginTop: '20px'}}>
                          <li>Send the product ordered to the delivery address. You must use the same shipping method as requested by the reseller. You can find all shipping information by clicking on “Details”. </li>
                          <li style={{fontWeight: 'bold'}}>Once the order is shipped, click on fulfill to enter the tracking information (Carrier, tracking number & date sent) and mark the order as fulfilled.</li>
                          <li>Awesome, the job is done !</li>
                        </ul>

                        <h3 style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Settings
                        </h3>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '0px'}}>
                        Make sure to complete your settings as it is a good signal for us to know that you are engaged in 01supply. Also it makes the support process much easier and faster as we can solve your problem within 24h.
                        </p>
                        <h3 style={{fontSize: '25px', marginTop: '30px'}} className="title-300">
                        Your shop
                        </h3>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Shop branding : {" "}
                          </span>
                          Your branding helps resellers know more about you. Are you a craftsman, an artist or any other company ? This helps build trust and can lead to more sales.
                          Add an image that represents what you sell or the identity of your business. Choose a good looking name and complete your shop description.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Shipping : {" "}
                          </span>
                          Click on “Add a new shipping method” to create a new one. Shipping name is the name of the carrier (DHL, FEDEX, UPS,...). Shipping zone is the destination country. Delivery time is the time needed for the order to reach the final customer. Price is the amount charged to the reseller if he chooses this shipping method. Offering free shipping increases your sales. 
                          Add as many shipping methods as you can. Then when you will create a product, you will choose which shipping methods apply to this product.
                        </p>
                        <p style={{fontSize: '25px', marginTop: '30px'}} className="title-300">
                        Plans
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        Supplier plans are 100% free. You can lower your fees by importing more products to the marketplace. Take in consideration that spam may lead to a warning on your account. We will always promote quality and great product pages.
                        </p>
                        <h3 style={{fontSize: '25px', marginTop: '30px'}} className="title-300">
                        Your informations
                        </h3>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Contact : {" "}
                          </span>
                          You can enter two different emails for contact. The first one will be used by resellers and other users to contact you. The second one allows us to contact you. You will also receive orders notifications on this email.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Legal : {" "}
                          </span>
                          Fill your information carefully as it is mandatory for us to know our customer. Also, this will be useful for invoicing and billing purposes. VAT number is optional. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Payouts : {" "}
                          </span>
                          Connect your Stripe account to 01supply. This is the account where you will be receiving payments from resellers. You must connect it to start selling on our marketplace.
                        </p>
                        </div>


                </div>
            </div>
        </div>
    )
}

export default HowItWork