import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import Contact from "../../components/ContactForm";
import ShopifyLogo from "../../images/shopifylogo.png";
import EtsyLogo from "../../images/etsylogo.png";
import PaypalLogo from "../../images/Paypal-logo.png";
import StripeLogo from "../../images/Stripe-logo.png";
import OgImg from "../../images/og_image.png";
import iconCheck from "../../images/icons/Check.svg";
import StarImg from "../../images/star.svg";

import NavBar from "../../components/menu/navbar/NavBar";
import Footer from "../../components/menu/Footer";
import RegisterModal from "../../components/modals/RegisterModal";

import { routes } from "../../routes";

const { Panel } = Collapse;

const PricingSupplier = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [contactUs, setContactUs] = useState(false);
  const [registerModalIsActive, setRegisterModalIsActive] = useState("");

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  return (
    <div className="page background-landing">
      {registerModalIsActive && (
        <RegisterModal
          isRegister={true}
          isSupplier={true}
          modalOn={registerModalIsActive}
          setModal={setRegisterModalIsActive}
        />
      )}
      {contactUs && <Contact setContact={setContactUs} />}
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://app.01supply.com/supplier-pricing" />
        <title>Supplier Pricing | 01supply</title>
        <meta name="description"
          content="01supply helps product creators make a living out of their passion. Our resellers will sell for you, for free. Check our pricing and our features here." />

        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://app.01supply.com/supplier-pricing" />
        <meta property="og:title" content={`Supplier Pricing | 01supply`} />
        <meta property="og:description"
          content="01supply helps product creators make a living out of their passion. Our resellers will sell for you, for free. Check our pricing and our features here." />
        <meta property="og:type" content="website" />
        <meta property="og:image"
          content="https://app.01supply.com/static/media/logo.55832fc9.svg" />

        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org",
          "headline": "Supplier Pricing",
          "image": [
          "https://app.01supply.com/static/media/logo.55832fc9.svg"
          ],
          "publisher": {
          "name": "01supply",
          "url": "https://app.01supply.com/"
          }
          }`}
        </script>

      </Helmet>

      <NavBar windowSize={windowSize} />

      {/* FULL DIV 2*/}
      <div
        className="column center pricing"
        style={{ width: "100%", marginTop: "0px", paddingLeft: "4vw", paddingRight: "4vw" }}
      >
        <div style={{ marginBottom: "40px" }} className="row">
          <div className="double-button left active">{t("pricingSupplier.1")}</div>
          <Link to={routes.resellerPricing(i18n.language).path} className="double-button right">
            {t("pricingSupplier.2")}
          </Link>
        </div>
        <div
          className="row"
          style={
            windowSize > 1150
              ? { width: "100%", justifyContent: "center" }
              : { flexDirection: "column", width: "100%" }
          }
        >
          {/* PAGE DIV 2*/}
          <div
            style={
              windowSize > 1150
                ? { display: "flex", width: "100%" }
                : { display: "flex", flexDirection: "column" }
            }
          >
            {/* landing form */}
            <div
              className="column"
              style={
                windowSize > 1150
                  ? {
                    flex: "1",
                    float: "left",
                    width: "100%",
                    justifyContent: "flex-start",
                    marginTop: "20px",
                  }
                  : { width: "100%", textAlign: "center" }
              }
            >
              {/* PAGE DIV 2*/}
              <div
                style={
                  windowSize > 1150
                    ? { display: "flex", width: "100%", justifyContent: "center" }
                    : { display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }
                }
              >
                {/* landing form */}
                <div style={{ float: "left", zIndex: "999", marginRight: windowSize > 1150 ? "50px" : "" }}>
                  <div
                    style={
                      windowSize > 1150
                        ? { marginBottom: "100px", position: "relative", width: "300px" }
                        : { marginBottom: "20px" }
                    }
                  >
                    <h1
                      style={
                        windowSize > 1150
                          ? {
                            marginBottom: "0",
                            fontSize: "40px",
                            fontWeight: "900",
                            fontFamily: "Montserrat",
                            lineHeight: "45px",
                            textAlign: "start",
                          }
                          : {
                            marginBottom: "0",
                            fontSize: "30px",
                            fontWeight: "900",
                            fontFamily: "Montserrat",
                            lineHeight: "40px",
                            textAlign: "center",
                          }
                      }
                    >
                      <span style={{ color: "#6865E8" }}>{t("pricingSupplier.3")}</span>
                      <br />
                      {t("pricingSupplier.4")}
                    </h1>
                    <p></p>
                    <br />
                    <p
                      style={
                        windowSize > 1150
                          ? { fontSize: "17px", textAlign: "left", color: "#585858" }
                          : { textAlign: "center" }
                      }
                    >
                      {t("pricingSupplier.5")} <br />
                      {t("pricingSupplier.6")}
                    </p>
                  </div>
                </div>

                {/* PRICING CARD */}

                <div style={{ alignItems: "flex-end" }} className="column">
                  <div
                    style={
                      windowSize > 1150
                        ? { overflow: "hidden", height: "430px" }
                        : { justifyContent: "center" }
                    }
                    className="row-wrap"
                  >
                    <div
                      style={{
                        marginRight: `${windowSize > 1150 ? "5%" : "0"}`,
                        width: "250px",
                        padding: "30px",
                        borderRadius: "20px",
                        margin: windowSize > 1150 ? "10px" : "0 0 20px 0",
                      }}
                      className="card"
                    >
                      <h2 className="title-bold">{t("pricingSupplier.plans.1.1")}</h2>
                      <p className="title-300">{t("pricingSupplier.plans.1.2")}</p>
                      <p style={{ marginTop: "10px" }} className="title-bold">
                        $0
                        <span style={{ marginLeft: "10px" }} className="title-300">
                          /mo
                        </span>
                      </p>
                      <div
                        className="divider-horizontal"
                        style={{ width: "100%", marginTop: "10px", marginLeft: "0" }}
                      />
                      <div style={{ width: "100%" }} className="column">
                        <div style={{ marginBottom: "10px" }} className="row">
                          <img
                            src={iconCheck}
                            style={{ height: "15px", width: "auto", marginRight: "15px" }}
                          />
                          <p className="title-300" style={{ fontSize: "13px" }}>
                            {t("pricingSupplier.plans.1.3")}
                          </p>
                        </div>
                        <div style={{ marginBottom: "10px" }} className="row">
                          <img
                            src={iconCheck}
                            style={{ height: "15px", width: "auto", marginRight: "15px" }}
                          />
                          <p className="title-300" style={{ fontSize: "13px" }}>
                            {t("pricingSupplier.plans.1.4")}
                          </p>
                        </div>
                        <p style={{ fontSize: "18px", marginTop: "20px" }} className="title-bold">
                          1 - 49 {t("products")}
                        </p>
                        <p className="text-200">10% {t("pricingSupplier.transacFees")} </p>
                      </div>
                      <button
                        onClick={() => setRegisterModalIsActive("/inventory")}
                        style={{ lineHeight: "30px", marginTop: "20px", marginBottom: "5px", width: "100%" }}
                        className="button-orange"
                      >
                        {t("getStarted")}
                      </button>
                    </div>

                    <div
                      style={{
                        marginRight: `${windowSize > 1150 ? "5%" : "0"}`,
                        width: "250px",
                        padding: "30px",
                        borderRadius: "20px",
                        margin: windowSize > 1150 ? "10px" : "0 0 20px 0",
                      }}
                    >
                      <p style={{ fontSize: "18px", marginTop: "160px" }} className="title-bold">
                        50 - 149 {t("products")}
                      </p>
                      <p className="text-200">8% {t("pricingSupplier.transacFees")}</p>
                    </div>

                    <div
                      style={{
                        marginRight: `${windowSize > 1150 ? "5%" : "0"}`,
                        width: "250px",
                        padding: "30px",
                        borderRadius: "20px",
                        margin: windowSize > 1150 ? "10px" : "0 0 20px 0",
                      }}
                    >
                      <p style={{ fontSize: "18px", marginTop: "160px" }} className="title-bold">
                        150+ {t("products")}
                      </p>
                      <p className="text-200">6% {t("pricingSupplier.transacFees")}</p>
                    </div>
                  </div>

                  <Link
                    to={routes.resellerPricing(i18n.language).path}
                    style={{ marginRight: "50px" }}
                    className="text-bold blue underline"
                  >
                    {t("pricingSupplier.7")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FULL DIV 4*/}
      <div className="column center" style={{ width: "100%", zIndex: "0", paddingBottom: "0" }}>
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          {/* PAGE DIV 4*/}
          <div style={{ zIndex: "2", width: "80%" }}>
            {/* text */}
            <h2
              style={
                windowSize > 1150
                  ? {
                    fontSize: "30px",
                    textAlign: "left",
                    width: "100%",
                    fontWeight: "800",
                    fontFamily: "Roboto",
                    color: "#585858",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    marginBottom: "0",
                  }
                  : {
                    marginLeft: "0px",
                    fontSize: "25px",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    color: "#585858",
                  }
              }
            >
              {t("pricingSupplier.8")}
            </h2>
          </div>
        </div>
      </div>

      {/* FULL DIV 5*/}

      <div className="column center" style={{ width: "100%" }}>
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          {/* PAGE DIV 5*/}
          <div
            style={
              windowSize > 1150
                ? {
                  display: "flex",
                  zIndex: "2",
                  width: "80%",
                  backgroundColor: "#F6F6F6",
                  textAlign: "center",
                  padding: "20px",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }
                : {
                  width: "80%",
                  backgroundColor: "#F6F6F6",
                  textAlign: "center",
                  padding: "20px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }
            }
          >
            {/* logo 1 */}
            <div class="darken" style={{ flex: "1" }}>
              <a href="https://apps.shopify.com/01supply" target="_blank">
                <img
                  alt="shopify"
                  style={
                    windowSize > 1150
                      ? { position: "relative", width: "8vw" }
                      : { width: "30%", margin: "20px" }
                  }
                  src={ShopifyLogo}
                />
              </a>
            </div>
            {/* logo 2 */}
            <div class="darken" style={{ flex: "1" }}>
              <img
                alt="Etsy"
                style={
                  windowSize > 1150
                    ? { position: "relative", width: "5vw" }
                    : { width: "30%", margin: "20px" }
                }
                src={EtsyLogo}
              />
            </div>
            {/* logo 3 */}
            <div class="darken" style={{ flex: "1", height: "-webkit-fill-available" }}>
              <img
                alt="Paypal"
                style={
                  windowSize > 1150
                    ? { position: "relative", width: "8vw" }
                    : { width: "30%", margin: "20px" }
                }
                src={PaypalLogo}
              />
            </div>
            {/* logo 4 */}
            <div class="darken" style={{ flex: "1" }}>
              <img
                alt="Stripe"
                style={
                  windowSize > 1150
                    ? { position: "relative", width: "5vw" }
                    : { width: "30%", margin: "20px" }
                }
                src={StripeLogo}
              />
            </div>
          </div>
        </div>

        <div
          className="row"
          style={
            windowSize > 1150
              ? { marginTop: "60px", alignItems: "flex-start" }
              : { flexWrap: "wrap", width: "100%", justifyContent: "center" }
          }
        >
          <div
            className="card"
            style={{
              margin: "20px",
              width: "280px",
              minWidth: "280px",
              height: "310px",
              justifyContent: "flex-start",
            }}
          >
            <p className="title-bold">Bilal</p>
            <img src={StarImg} style={{ width: "150px", marginBottom: "15px", marginTop: "10px" }} />
            <p className="text-300">{t("pricingSupplier.advices.1")}</p>
          </div>
          <div
            className="card"
            style={{
              margin: "20px",
              width: "280px",
              minWidth: "280px",
              height: "310px",
              justifyContent: "flex-start",
            }}
          >
            <p className="title-bold">Franck</p>
            <img src={StarImg} style={{ width: "150px", marginBottom: "15px", marginTop: "10px" }} />
            <p className="text-300">{t("pricingSupplier.advices.2")}</p>
          </div>
          <div
            className="card"
            style={{
              margin: "20px",
              width: "280px",
              minWidth: "280px",
              height: "310px",
              justifyContent: "flex-start",
            }}
          >
            <p className="title-bold">Dina</p>
            <img src={StarImg} style={{ width: "150px", marginBottom: "15px", marginTop: "10px" }} />
            <p className="text-300">{t("pricingSupplier.advices.3")}</p>
          </div>
        </div>
      </div>

      {/* FULL DIV 4*/}
      <div className="column center" style={{ width: "100%", zIndex: "0", paddingBottom: "0" }}>
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          {/* PAGE DIV 4*/}
          <div style={{ zIndex: "2", width: "80%" }}>
            {/* text */}
            <h2
              style={
                windowSize > 1150
                  ? {
                    fontSize: "30px",
                    textAlign: "left",
                    width: "100%",
                    fontWeight: "800",
                    fontFamily: "Roboto",
                    color: "#585858",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    marginBottom: "0",
                  }
                  : {
                    marginLeft: "0px",
                    fontSize: "25px",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    color: "#585858",
                  }
              }
            >
              {t("pricingSupplier.9")}
            </h2>
          </div>
        </div>
        <button
          style={{ marginTop: "20px" }}
          onClick={() => {
            window.tidioChatApi.show();
            window.tidioChatApi.open();
          }}
          className="button big orange-back"
        >
          {t("contactUs")}
        </button>
      </div>

      <div
        className="column center"
        style={
          windowSize > 1150
            ? { width: "100%", marginTop: "60px" }
            : { paddingTop: "0", width: "100%", marginTop: "40px" }
        }
      >
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          <div
            style={
              windowSize > 1150 ? { display: "flex", width: "85%", marginLeft: "5%" } : { width: "100%" }
            }
          >
            <div
              style={
                windowSize > 1150
                  ? { flex: "1", maxWidth: "350px", minWidth: "350px" }
                  : { width: "100%", display: "flex", justifyContent: "center" }
              }
            >
              <h2
                style={
                  windowSize > 1150
                    ? {
                      fontSize: "30px",
                      textAlign: "left",
                      width: "100%",
                      fontWeight: "800",
                      color: "#585858",
                      fontFamily: "Montserrat",
                      lineHeight: "2.5vw",
                    }
                    : {
                      marginLeft: "0px",
                      fontSize: "25px",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      color: "#585858",
                      width: "90%",
                    }
                }
              >
                <span style={windowSize > 1150 ? {} : { width: "100%", textAlign: "start" }}>
                  {t("pricingSupplier.faq.title.1")}
                  <br /> {t("pricingSupplier.faq.title.2")}
                </span>
              </h2>
            </div>

            {/* text */}
            <div
              style={
                windowSize > 1150
                  ? {
                    flex: "1",
                    float: "right",
                    zIndex: "3",
                    maxInlineSize: "fit-content",
                    minWidth: "65%",
                    marginLeft: "40px",
                  }
                  : { width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }
              }
            >
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
                defaultActiveKey={[]}
              >
                <Panel className="montserrat-bold" header={t("pricingSupplier.faq.1")} key="1">
                  <p className="text-roboto montserrat">{t("pricingSupplier.faq.2")}</p>
                </Panel>
                <Panel className="montserrat-bold" header={t("pricingSupplier.faq.3")} key="2">
                  <p className="text-roboto montserrat">{t("pricingSupplier.faq.4")}</p>
                </Panel>
                <Panel className="montserrat-bold" header={t("pricingSupplier.faq.11")} key="3">
                  <p className="text-roboto montserrat">
                    {t("pricingSupplier.faq.5")}
                    <br />
                    {t("pricingSupplier.faq.6")}
                  </p>
                </Panel>
                <Panel className="montserrat-bold" header={t("pricingSupplier.faq.7")} key="4">
                  <p className="text-roboto montserrat">{t("pricingSupplier.faq.8")}</p>
                </Panel>
                <Panel className="montserrat-bold" header={t("pricingSupplier.faq.9")} key="5">
                  <p className="text-roboto montserrat">
                    {t("pricingSupplier.faq.10")}
                    <a href="mailto:contact@01supply.com">{t("here")}</a>
                  </p>
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} location="supplier" />
    </div>
  );
};

export default PricingSupplier;
