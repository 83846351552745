import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import {Modal} from '../../wrappers'

const ConfirmationModal = ({ modalOn, setModalOn, CancelButton, text, title }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="modal-account invitation"
      onOk={() => {}}
      visible={modalOn}
      title={title || ""}
      onCancel={() => setModalOn(false)}
      footer={[
        <Button key="back" onClick={() => setModalOn(false)}>
          {t("cancel")}
        </Button>,
        <CancelButton />,
      ]}
    >
      <div style={{ alignItems: "center" }} className="column">
        <p className="title">{text}</p>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
