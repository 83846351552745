import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { articles } from "../../articles";
import NavBar from "../../../../components/menu/navbar/NavBar";
import Footer from "../../../../components/menu/Footer";
import TagMenu from "../../../../components/blog/TagMenu";
import ArticleHeaderNav from "../../../../components/blog/ArticleHeaderNav";
import CourseSponso from "../../../../components/blog/CourseSponso";
import Social from "../../../../components/blog/Social";
import ArticleFooter from "../../../../components/blog/ArticleFooter";
import Content from './Content'
import SuggestionCard from "../../../../components/blog/SuggestionCard";

import "../../style.scss";

const Blog = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedTag, setSelectedTag] = useState("");

  const articleId = 30; // NEED CHANGE TO ALL NEW ARTICLE

  const article = articles.find((article) => parseInt(article.id) === articleId);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const newArticles = articles.filter((article) => parseInt(article.id) !== articleId);
  const lastThreeArticles = [
    newArticles[newArticles.length - 1],
    newArticles[newArticles.length - 2],
    newArticles[newArticles.length - 3],
  ];
  
  return (
    <div style={{ background: "white" }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://app.01supply.com/ou-trouver-fournisseur-dropshipping" />
        <title>Où trouver des fournisseurs en dropshipping ? | 01supply</title>
        <meta name="description"
          content="Découvrez où trouver des fournisseurs dropshipping pour votre boutique en ligne. Des fournisseurs proposant des produits uniques et une livraison rapide." />
        
        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://app.01supply.com/ou-trouver-fournisseur-dropshipping" />
        <meta property="og:title" content={`Où trouver des fournisseurs en dropshipping ? | 01supply`} />
        <meta property="og:description"
          content="Découvrez où trouver des fournisseurs dropshipping pour votre boutique en ligne. Des fournisseurs proposant des produits uniques et une livraison rapide." />
        <meta property="og:type" content="article" />
        <meta property="og:image"
          content="https://app.01supply.com/static/media/ou-trouver-fournisseur-dropshipping.c8e214de.jpg" />
        
        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "Où trouver des fournisseurs en dropshipping ?",
            "image": [
              "https://app.01supply.com/static/media/static/media/ou-trouver-fournisseur-dropshipping.c8e214de.jpg"
            ],
            "datePublished": "2023-02-28",
            "dateModified": "2023-02-28",
            "author": [
              {
                "@type": "Person",
                "name": "Diego Huberty",
                "jobTitle": "Editor in Chief"
              }
            ],
            "publisher": {
              "name": "01supply",
              "url": "https://app.01supply.com/"
            }
          }`}
        </script>
      </Helmet>

      <NavBar windowSize={windowSize} />

      <div style={{ paddingBottom: "150px", textAlign: "start" }} className="column center">
        <TagMenu
          setSelectedTag={setSelectedTag}
          selectedTag={selectedTag}
        />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            <ArticleHeaderNav article={article} />
            <CourseSponso
              imagePath="coursesImg/14jours/fournisseurdrop.jpg"
              imageTitle="Fournisseurs dropshipping"
              price="0€ jusqu'à 9 produits"
              title="Importez des produits gratuitement."
              description="Vous cherchez à ajouter des produits de fournisseurs locaux en dropshipping à votre boutique en ligne? Importer autant de produits que vous souhaitez sur votre boutique en ligne grâce à 01supply."
              coursePath="/fr/pricing"
            />
            <Content />
            <Social article={article} />
            <ArticleFooter windowSize={windowSize} />
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article) => (
              <SuggestionCard article={article} />
            ))}
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Blog;
