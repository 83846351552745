import { url } from "../api/url";
import { header } from "../api/header";

export const wizishopServices = {
  connect: async (
    { email, password, cms },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/wizishop/connect/${userId}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        email,
        password,
        cms
      }),
    });
  },
  importProduct: async (
    { productIds, shopId },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/wizishop/product/${userId}/${secretId}/${shopId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        productIds,
      }),
    });
  },
  refreshShops: async (
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/wizishop/refresh-shops/${userId}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
};
