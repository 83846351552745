import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputNumber, message, Button, Switch } from "antd";

import { Modal } from "../../../wrappers";

import SelectShipping from "../../../components/supplier/SelectShipping";
import ProductCard from "../../../components/ProductCard";
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import { getCountry } from "../../../utils/getCountry";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";
import LoadComponent from "../../../utils/Load";
import ShippingCard from "../../../components/shippingCard";
import CreateEditShipping from "../../../components/shippingCard/CreateEditShipping";

const ManageShipping = () => {
  const [activeModalApplyAllShipping, setActiveModalApplyAllShipping] = useState(false);
  const [selectShipping, setSelectShipping] = useState(false);
  const [productWithShipping, setProductWithShipping] = useState([]);
  const [shippingCards, setShippingCards] = useState([]);
  const [shippingDeleteId, setShippingDeleteId] = useState("");
  const [editShipping, setEditShipping] = useState(undefined);
  const [createShippingMobile, setCreateShippingMobile] = useState(false);
  const [newShipping, setNewShipping] = useState({
    name: "",
    origin: "",
    time: "",
    price: "",
  });
  const [saveIndex, setSaveIndex] = useState([]);
  const [applyShippingToAll, setApplyShippingToAll] = useState(false);
  const [addShipping, setAddShipping] = useState(false);
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [update, setUpdate] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isCreateShipping, setIsCreateShipping] = useState(false);
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();

  const shippingFactorize = (shippings) => {
    let shipCards = [];
    shippings.forEach((shipping, index) => {
      const shippingOriginString = shipping.origin.toString();
      const existingCard = shipCards.find((shipCard) => shipCard.name === shippingOriginString);
      if (!existingCard) {
        const countrys = shippingOriginString
          ?.split(",")
          ?.map((origin) => getCountry(origin))
          ?.filter((country) => !!country);
        shipCards.push({
          name: shippingOriginString,
          index: shipCards.length,
          shippings: [shipping],
          countrys,
        });
      } else {
        shipCards[existingCard.index].shippings.push(shipping);
      }
    });
    setShippingCards(shipCards);
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
      if (resJson.data?.shipping?.length) {
        shippingFactorize(resJson.data.shipping);
      }
    }
  };

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [idAccount, secretId, token]);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const getProductWithShippingMethod = async (shippingId) => {
    const res = await fetch(`${url}/product/find-by-shipping-method/${user._id}/${shippingId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res) {
      const resJson = await res.json();
      if (resJson) {
        setProductWithShipping(resJson.data);
        setLoad(false);
      } else {
        setLoad(false);
      }
      setShippingDeleteId(shippingId);
    }
  };

  const createShippingMethod = async (applyToAll) => {
    if (
      saveIndex.length < 1 &&
      (!newShipping.name || !newShipping.origin || !newShipping.time || !newShipping.price)
    ) {
      message.error(t("messages.shippingInfosCompleted"));
      setLoad(false);
    } else {
      let newUser = user;
      if (newShipping && newShipping.name) newUser.shipping.push(newShipping);
      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
          saveIndex,
          newShipping,
          applyShippingToAll: applyToAll ? true : false,
        }),
      });
      if (res.status === 200 || res.status === 405 || res.status === 400) {
        await setNewShipping({
          name: "",
          origin: "",
          time: "",
          price: "",
        });
        await setUser({ ...newUser });
        await setAddShipping(false);
        await setSaveIndex([]);
        setCreateShippingMobile(false);
        setEditShipping(undefined);
        setLoad(false);
        setApplyShippingToAll(false);
        message.success(t("message.shippingcreated"));
      }
    }
  };

  const deleteShipping = async () => {
    let newShipping = [...user.shipping];
    newShipping = await newShipping.filter((shipping, i) => shipping._id !== shippingDeleteId);

    let newUser = JSON.parse(JSON.stringify({ ...user, shipping: newShipping }));
    const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        user: newUser,
        deleteShipping: shippingDeleteId,
      }),
    });
    if (res) {
      setUser({ ...user, shipping: newShipping });
      setUpdate(!update);
      setCreateShippingMobile(false);
      setEditShipping(undefined);
      setShippingDeleteId("");
      setLoad(false);

      setProductWithShipping([]);
    }
  };

  const simpleUpdateUser = async (newUser) => {
    const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        user: newUser,
      }),
    });
    if (res.status === 200) {
      message.success(t("messages.successfullyUpdated"));
    } else {
      message.error(t("messages.error"));
    }
  };

  const applyAllShipping = async () => {
    const res = await fetch(`${url}/product/apply-all-shipping/${idAccount}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        user,
      }),
    });
    if (res.status === 200) {
      message.success(t("messages.appliedToAll"));
      setLoad(false);
    } else {
      message.error(t("messages.error"));
      setLoad(false);
    }
  };

  return (
    <div className="page">
      {load && <LoadComponent />}
      <RedirectLogin />

      {isCreateShipping && (
        <CreateEditShipping
          setUser={setUser}
          shippingFactorize={shippingFactorize}
          setIsCreateShipping={setIsCreateShipping}
          user={user}
        />
      )}
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}

        {user && user.email && (
          <div
            style={
              windowSize > 1150
                ? {
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    paddingTop: "70px",
                    paddingLeft: "40px",
                  }
                : {
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "20px",
                    paddingTop: "30px",
                  }
            }
            className="column-scrolling shop-supplier"
          >
            <div className="row-wrap width-100 start">
              {windowSize > 1150 ? (
                <MenuSetting user={user} location={"your-shop-supplier"} />
              ) : (
                <MobileHeader nameHeader={"SETTINGS"} location="YOUR SHOP" />
              )}

              {shippingDeleteId && (
                <Modal
                  className="modal-order"
                  title={`Delete shipping method`}
                  onOk={() => {}}
                  visible={true}
                  onCancel={() => {
                    setShippingDeleteId("");
                    setProductWithShipping([]);
                  }}
                  footer={[
                    <Button
                      key="back"
                      onClick={() => {
                        setShippingDeleteId("");
                        setProductWithShipping([]);
                      }}
                    >
                      {t("cancel")}
                    </Button>,
                  ]}
                >
                  <div className="column warning-delete-shipping" style={{ alignItems: "center" }}>
                    <p className="title-300">{t("manageShipping.1")}</p>
                    <button
                      style={{ marginBottom: "22px", marginTop: "15px" }}
                      className="button orange-back"
                      onClick={() => {
                        setLoad(true);
                        deleteShipping();
                      }}
                    >
                      {t("manageShipping.2")}
                    </button>
                    {productWithShipping.length > 0 && (
                      <React.Fragment>
                        <p style={{ marginBottom: "15px" }} className="title">
                          {t("manageShipping.3")}
                        </p>
                        {productWithShipping.map((product) => {
                          return <ProductCard product={product} key={product._id} />;
                        })}
                      </React.Fragment>
                    )}
                  </div>
                </Modal>
              )}
              {activeModalApplyAllShipping && (
                <Modal
                  className="modal-order"
                  title=""
                  visible={true}
                  onCancel={() => {
                    setActiveModalApplyAllShipping(false);
                  }}
                  footer={[
                    <Button
                      key="back"
                      onClick={() => {
                        setActiveModalApplyAllShipping(false);
                      }}
                    >
                      {t("no")}
                    </Button>,
                  ]}
                >
                  <div className="column warning-delete-shipping" style={{ alignItems: "center" }}>
                    <p className="title-300">{t("manageShipping.4")}</p>
                    <button
                      style={{ marginBottom: "22px", marginTop: "15px" }}
                      className="button orange-back"
                      onClick={() => {
                        if (user.products_id?.length) {
                          applyAllShipping();
                        }
                        setActiveModalApplyAllShipping(false);
                      }}
                    >
                      {t("manageShipping.5")}
                    </button>
                  </div>
                </Modal>
              )}
              {applyShippingToAll && (
                <Modal
                  className="modal-order"
                  title=""
                  visible={true}
                  onCancel={() => {
                    setApplyShippingToAll(false);
                    setLoad(true);
                    createShippingMethod();
                  }}
                  footer={[
                    <Button
                      key="back"
                      onClick={() => {
                        setApplyShippingToAll(false);
                        setLoad(true);
                        createShippingMethod();
                      }}
                    >
                      {t("no")}
                    </Button>,
                  ]}
                >
                  <div className="column warning-delete-shipping" style={{ alignItems: "center" }}>
                    <p className="title-300">{t("manageShipping.6")}</p>
                    <button
                      style={{ marginBottom: "22px", marginTop: "15px" }}
                      className="button orange-back"
                      onClick={() => {
                        setLoad(true);
                        createShippingMethod(true);
                      }}
                    >
                      {t("manageShipping.7")}
                    </button>
                  </div>
                </Modal>
              )}

              {(editShipping || editShipping === 0 || createShippingMobile) && (
                <Modal
                  className="modal-account"
                  visible={true}
                  onCancel={() => {
                    setCreateShippingMobile(false);
                    setEditShipping(undefined);
                  }}
                  footer={[
                    <button
                      className="button-red"
                      key="submit"
                      type="primary"
                      onClick={() => {
                        setCreateShippingMobile(false);
                        setEditShipping(undefined);
                      }}
                    >
                      {t("cancel").toUpperCase()}
                    </button>,
                  ]}
                >
                  <div
                    key={user.shipping[editShipping] && user.shipping[editShipping].name}
                    style={{ width: "100%", marginBottom: "13px", alignItems: "center" }}
                    className="column"
                  >
                    <Input
                      placeholder={t("manageShipping.placeholders.1")}
                      maxLength={40}
                      defaultValue={user.shipping[editShipping] && user.shipping[editShipping].name}
                      style={{ fontSize: "18px", width: "90%", marginTop: "50px" }}
                      onChange={(e) => {
                        if (!createShippingMobile) {
                          let stockNewShipping = user.shipping[editShipping];
                          stockNewShipping.name = e.target.value;
                          let stockUser = user;
                          stockUser.shipping[editShipping] = stockNewShipping;
                          if (!saveIndex.includes(editShipping)) setSaveIndex([...saveIndex, editShipping]);
                          setUser(stockUser);
                        } else {
                          let stockNewShipping = newShipping;
                          stockNewShipping.name = e.target.value;
                          setNewShipping(stockNewShipping);
                        }
                      }}
                    />

                    <button
                      style={{ fontSize: "18px", marginTop: "15px", width: "90%", cursor: "pointer" }}
                      onClick={() => setSelectShipping(newShipping ? newShipping : editShipping)}
                      className="button-header-active"
                    >
                      {!(user.shipping[editShipping] && user.shipping[editShipping].origin) ||
                      (user.shipping[editShipping] && user.shipping[editShipping].origin.includes("-"))
                        ? t("selectCountry")
                        : user.shipping[editShipping] && user.shipping[editShipping].origin}
                    </button>

                    {selectShipping && (
                      <SelectShipping
                        editShippingOrigin={(value) => {
                          let stockNewShipping = newShipping;
                          stockNewShipping.origin = value;
                          setNewShipping(stockNewShipping);
                        }}
                        setSelectShipping={setSelectShipping}
                        shipping={newShipping}
                      />
                    )}

                    <InputNumber
                      placeholder={t("manageShipping.placeholders.2")}
                      max={100}
                      min={1}
                      maxLength={3}
                      defaultValue={user.shipping[editShipping] && user.shipping[editShipping].time}
                      style={{ fontSize: "18px", width: "90%", marginTop: "20px" }}
                      type="number"
                      onChange={(value) => {
                        if (!createShippingMobile) {
                          let stockNewShipping = user.shipping[editShipping];
                          stockNewShipping.time = value;
                          let stockUser = user;
                          stockUser.shipping[editShipping] = stockNewShipping;
                          if (!saveIndex.includes(editShipping)) setSaveIndex([...saveIndex, editShipping]);
                          setUser(stockUser);
                        } else {
                          let stockNewShipping = newShipping;
                          stockNewShipping.time = value;
                          setNewShipping(stockNewShipping);
                        }
                      }}
                    />
                    <InputNumber
                      max={299}
                      min={0}
                      maxLength={5}
                      defaultValue={user.shipping[editShipping] && user.shipping[editShipping].price}
                      style={{ fontSize: "15px", width: "90%", marginTop: "20px" }}
                      type="number"
                      placeholder="price"
                      step="0.01"
                      onChange={(value) => {
                        if (!createShippingMobile) {
                          let stockNewShipping = user.shipping[editShipping];
                          stockNewShipping.price = parseFloat(value).toFixed(2);
                          let stockUser = user;
                          stockUser.shipping[editShipping] = stockNewShipping;
                          if (!saveIndex.includes(editShipping)) setSaveIndex([...saveIndex, editShipping]);
                          setUser(stockUser);
                        } else {
                          let stockNewShipping = newShipping;
                          stockNewShipping.price = value;
                          setNewShipping(stockNewShipping);
                        }
                      }}
                    />
                    {(saveIndex.includes(editShipping) || createShippingMobile) && (
                      <button
                        style={{ fontSize: "18px", width: "90%", marginTop: "20px" }}
                        onClick={() => {
                          setLoad(true);
                          createShippingMethod();
                        }}
                        className="button orange-back"
                      >
                        {t("save")}
                      </button>
                    )}
                    {!createShippingMobile &&
                      user.shipping[editShipping] &&
                      user.shipping[editShipping]._id && (
                        <button
                          style={{ fontSize: "18px", width: "90%", marginTop: "20px", marginBottom: "30px" }}
                          onClick={() => {
                            setLoad(true);
                            getProductWithShippingMethod(user.shipping[editShipping]._id);
                          }}
                          className="button-red"
                        >
                          {t("delete")}
                        </button>
                      )}
                  </div>
                </Modal>
              )}

              <div
                style={{
                  marginTop: windowSize > 1375 ? "0" : "20px",
                  minWidth: windowSize > 1150 ? "750px" : "auto",
                }}
                className="settings-right shipping"
              >
                <div className="column width-100 card">
                  <div style={{ alignItems: "center", marginBottom: "30px" }} className="row strat">
                    <Switch
                      checked={user?.auto_apply_shipping}
                      onChange={() => {
                        simpleUpdateUser({
                          ...user,
                          auto_apply_shipping: user.auto_apply_shipping ? false : true,
                        });
                        setUser({ ...user, auto_apply_shipping: user.auto_apply_shipping ? false : true });
                        if (user && !user.auto_apply_shipping && user.products_id?.length) {
                          setActiveModalApplyAllShipping(true);
                        }
                      }}
                    />
                    <p
                      style={{ marginLeft: "10px", fontSize: "18px", marginTop: "0" }}
                      className="title start"
                    >
                      {t("manageShipping.8")}
                    </p>
                  </div>
                  <div style={{ alignItems: "center" }} className="row strat">
                    <Switch
                      checked={user?.stop_selling_out_stock}
                      onChange={() => {
                        simpleUpdateUser({
                          ...user,
                          stop_selling_out_stock: user.stop_selling_out_stock ? false : true,
                        });
                        setUser({
                          ...user,
                          stop_selling_out_stock: user.stop_selling_out_stock ? false : true,
                        });
                      }}
                    />
                    <p style={{ marginLeft: "10px", fontSize: "18px" }} className="title start">
                      {t("manageShipping.16")}
                    </p>
                  </div>
                </div>

                <h1
                  id="content"
                  style={{ textAlign: "start", marginTop: "30px", marginBottom: "10px", fontSize: "25px" }}
                  className="title-300"
                >
                  {t("shipping")}
                </h1>
                {shippingCards.map((shippingCard) => (
                  <ShippingCard
                    user={user}
                    setUser={setUser}
                    shippingFactorize={shippingFactorize}
                    shippingCard={shippingCard}
                    shippingCards={shippingCards}
                    setShippingCards={setShippingCards}
                  />
                ))}
                <div className="card shipping width-100">
                  <p
                    onClick={() => setIsCreateShipping(true)}
                    className="width-100 center text-bold blue underline pointer"
                  >
                    {t("components.shipping.createShippingCard")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageShipping;
