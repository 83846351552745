import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Checkbox, Skeleton, message } from "antd";
import { Modal } from "../wrappers";

import { url } from "../api/url";
import { header } from "../api/header";
import { getImage } from "../utils/GetImage";
import PopupLogin from "./modals/RegisterModal";
import { getCountry } from "../utils/getCountry";
import { klaviyoServices } from "../services/klaviyo";
import { priceConverter } from "../utils/priceConverter";

import { LocalStatesContext } from "../providers/index";

import ProductDetail from "../pages/ProductDetail";
import ProductEtsyStats from "./productEtsyStats/index";
import BlockBackButton from "../utils/BlockBackButton";
import AdminButtons from "./productDetails/AdminButtons";
import ReviewsLabel from "./global/reviewsLabel";

import resellersLeftLabel from "../images/productResellerLeftLabel.svg";
import upArrowIcon from "../images/icons/up_arrow.png";
import graphIcon from "../images/icons/graph.png";
import AnalyticButton from "./productCard/analyticButton";

const buttonStyle = {
  width: "100%",
  marginTop: "0px",
  marginBottom: "8px",
  minHeight: "35px",
  borderRadius: "5px",
  paddingTop: "7px",
  paddingBottom: "7px",
};

const ProductCard = ({
  product,
  key,
  location,
  productSelection,
  setProductSelection,
  index,
  user,
  setUser,
  isAnalyticsProduct,
  isBlur,
  blurCallbackFunction,
}) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [popupLogin, setPopupLogin] = useState("");
  const [image, setImage] = useState("");
  const [originFlag, setOriginFlag] = useState("");
  const [isAddToImportList, setIsAddToImportList] = useState(false);
  const [isNoImages, setIsNoImage] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [isProductDetailDisplayed, setIsProductDetailDisplayed] = useState(false);
  const [isSalesStatsDisplayed, setIsSalesStatsDisplayed] = useState(false);

  const { localStates } = useContext(LocalStatesContext);

  const isImportListLocation = !!(location === "import-list");

  const { t, i18n } = useTranslation();

  const isImportButtonDisplayed =
    localStorage.getItem("typeAccountCreatosell") !== "creater" && !isImportListLocation;

  useEffect(() => {
    if (product && product.photos && product.photos.length > 0) {
      setOriginFlag(getCountry(product?.origin)?.split(" ")[0]);

      getImage(product.photos?.[0]).then((img) => {
        try {
          if (img) {
            setImage(img);
          } else {
            setIsNoImage(true);
          }
        } catch {}
      });
    }
  }, []);

  const addProductToImportList = async () => {
    if (setUser) {
      setUser({ ...user, import_list: [...user.import_list, product._id] });
    }
    setIsAddToImportList(true);
    setTimeout(() => {
      setIsAddToImportList(false);
    }, 3000);

    message.success({
      content: t("messages.productSuccessfullyImported"),
      duration: 5,
      onClick: () => setRedirect("/import-list"),
    });

    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    const userRes = await resJson.data;
    if (userRes) {
      let newList = [product._id];
      if (userRes && userRes.import_list) {
        userRes.import_list.forEach((id) => !newList.includes(id) && newList.push(id));
        user.import_list.forEach((id) => !newList.includes(id) && newList.push(id));
      }
      let newUser = { ...userRes, import_list: newList };
      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
          importList: product._id,
        }),
      });
      if (res.status === 200) {
        // Algolia analytic
        window.aa("convertedObjectIDs", {
          userToken: user?._id || "",
          index: "products",
          eventName: "product_import",
          objectIDs: [product._id],
          queryID: localStorage.getItem("algolia_last_query_id") || "",
        });

        if (user.products_id?.length < 11) {
          klaviyoServices.checkStatus();
        }
      } else if (res.status === 405) {
        message.error(t("messages.alreadyInImportList"));
      } else if (res.status === 406) {
        message.error(t("messages.alreadyInInventory"));
      } else message.error(t("messages.error"));
    }
  };

  const handleImportClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (token) {
      addProductToImportList();
    } else {
      setPopupLogin("reload");
    }
  };

  const handleProductClick = () => {
    if (!isBlur) {
      setIsProductDetailDisplayed(true);
      // algolia analytic
      window.aa("clickedObjectIDs", {
        userToken: user?._id || "",
        index: "products",
        eventName: "product_click",
        objectIDs: [product._id],
      });
    }
  };

  return (
    !isNoImages &&
    image && (
      <>
        {isProductDetailDisplayed && (
          <BlockBackButton onBackButton={() => setIsProductDetailDisplayed(false)} />
        )}
        {isSalesStatsDisplayed && (
          <Modal
            footer={[]}
            className="modal-80-vw"
            visible={true}
            onCancel={() => setIsSalesStatsDisplayed(false)}
          >
            <ProductEtsyStats productImage={image} product={product} />
          </Modal>
        )}
        {isProductDetailDisplayed && (
          <Modal
            title={
              <Link
                style={{ fontWeight: "400", display: "block" }}
                target="_blank"
                to={`/product-details?name=${product.name}&id=${product._id}`}
                className="text blue underline pointer width-100 center see-product-page-link"
              >
                {t("seeProductPage")}
              </Link>
            }
            footer={[
              <Link
                style={{ fontWeight: "400", width: "100%", fontSize: "16px", display: "block" }}
                target="_blank"
                to={`/product-details?name=${product.name}&id=${product._id}`}
                className="text blue underline pointer center see-product-page-link"
              >
                {t("seeProductPage")}
              </Link>,
            ]}
            className="modal-80-vw"
            onOk={() => {}}
            visible={true}
            onCancel={() => setIsProductDetailDisplayed(false)}
          >
            <ProductDetail userP={user} setUserP={setUser} isModal={true} productProps={product} />
          </Modal>
        )}
        {popupLogin && <PopupLogin modalOn={popupLogin} setModal={setPopupLogin} />}
        {redirect && <Redirect to={redirect} />}
        <div
          key={key}
          onClick={handleProductClick}
          className={`container-card-product link-product-card ${isBlur && "disabled"}`}
          style={localStorage.getItem("admin") === "true" ? { height: "auto" } : {}}
        >
          {isBlur && <div onClick={blurCallbackFunction} className="blur" />}
          {isAddToImportList && (
            <Link to="/import-list" className="view-import-list">
              <p className="text-bold blue underline">{t("components.productCard.1")}</p>
            </Link>
          )}

          {/* REVIEW */}
          {!isImportListLocation && (
            <ReviewsLabel
              score={product.reviews?.score}
              total={product.reviews?.total}
              style={{ position: "absolute", top: "8px", left: "5px" }}
            />
          )}

          <div style={{ minHeight: "270px", maxHeight: "270px" }} className="container-image-card">
            {location === "import-list" && (
              <Checkbox
                key={productSelection && productSelection.length}
                checked={productSelection && productSelection.includes(product._id)}
                className="checkbox-on-product"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={() => {
                  let stockSelection = [...productSelection];
                  if (productSelection.includes(product._id)) {
                    productSelection.map((selection, index) => {
                      if (selection === product._id) {
                        stockSelection.splice(index, 1);
                      }
                    });
                  } else stockSelection.push(product._id);
                  setProductSelection([...stockSelection]);
                }}
              />
            )}

            {image ? (
              <img src={image} alt={product.name} height={"100%"} className="image-product" />
            ) : (
              <Skeleton className="image-product" loading={true} active />
            )}
          </div>

          {/* PC IMPORT BUTTON */}
          <div className="bottom-card-product">
            {isImportButtonDisplayed && (
              <button
                className={
                  user && (user.import_list?.includes(product._id) || user.products_id?.includes(product._id))
                    ? "button-disable-orange"
                    : "button orange-back"
                }
                id="import-product-button"
                style={buttonStyle}
                onClick={handleImportClick}
              >
                {t("components.productCard.2")}
              </button>
            )}

            {/* MOBILE IMPORT BUTTON */}
            {isImportButtonDisplayed && (
              <button
                className={
                  user && (user.import_list?.includes(product._id) || user.products_id?.includes(product._id))
                    ? "button-disable-orange only-mobile"
                    : "button orange-back only-mobile"
                }
                id="import-product-button"
                style={buttonStyle}
                onClick={handleImportClick}
              >
                + {t("components.productCard.4")}
              </button>
            )}

            <p
              style={{
                fontSize: "13px",
                height: "38px",
                marginTop: "10px",
                lineHeight: "18px",
                width: "95%",
                textAlign: "start",
              }}
              className="text-ellipsis-2 text montserrat"
            >
              {product.name}
            </p>
            <div
              style={{
                width: "100%",
                alignItems: "flex-end",
                fontSize: "15px",
                color: "#3f3f3f",
              }}
              className="row-wrap space-between"
            >
              <p
                style={{ color: "#3f3f3f", lineHeight: "22px" }}
                className="start text-bold text-ellipsis montserrat product-price"
              >
                <span style={{ fontSize: "12px", fontWeight: "400", color: "#9a9a9a" }}>
                  {t("productPrice")}
                </span>{" "}
                <br /> {priceConverter({ price: product.price, currency: localStates.currency })}
              </p>
              {product.retailPrice && user?.retailPrice?.anable !== false && (
                <p
                  style={{ color: "rgb(90 90 90)", lineHeight: "22px", fontSize: "14px", maxWidth: "160px" }}
                  className="text text-ellipsis montserrat end recommended-retail"
                >
                  <span style={{ fontSize: "12px", color: "#9a9a9a" }}>{t("retailPrice")}</span> <br />
                  {priceConverter({
                    price: product.retailPrice,
                    currency: localStates.currency,
                    type: "retailPrice",
                    originalPrice: product.price,
                    user,
                  })}
                </p>
              )}
            </div>

            {/* RESELLER LEFT 
            {product.resellersLeft > 0 && !isAnalyticsProduct && (
              <div
                style={{ marginTop: "3px", marginBottom: "5px" }}
                className="row width-100 center reseller-left"
              >
                <p className="text-product-card-label">
                  {" "}
                  {t("resellersLeft", { count: product.resellersLeft })}{" "}
                </p>
                <img
                  alt={`${product.resellersLeft} resellers left`}
                  src={resellersLeftLabel}
                  className="label-product-card"
                />
              </div>
            )}
            */}

            {product.creater?.name ? (
              <div
                style={{
                  width: "90%",
                  fontSize: "14px",
                  marginTop: "3px",
                  alignItems: "center",
                  display: "flex",
                }}
                className="text-300 text-ellipsis row"
              >
                {t("from")}
                {originFlag && <div style={{ marginLeft: "5px", marginTop: "5px" }}>{originFlag}</div>}
                <Link
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  to={`/shop/${product.creater.name}`}
                  style={{ marginLeft: "5px", textDecoration: "underline" }}
                >
                  {product.creater.name}
                </Link>
              </div>
            ) : (
              <div
                style={{
                  width: "90%",
                  fontSize: "14px",
                  marginTop: "5px",
                  alignItems: "center",
                  display: "flex",
                }}
                className="text-300 text-ellipsis row"
              ></div>
            )}
          </div>

          {isImportListLocation && (
            <Link
              style={{ marginTop: "-5px", marginBottom: "10px" }}
              to={`/product-details?name=${product.name}&id=${product._id}`}
              className="text blue width-100 center"
            >
              {t("components.productCard.3").toUpperCase()}
            </Link>
          )}

          {product.etsy_stats?.total_sales > 25 && (
            <>
              <div
                style={{ marginTop: "-5px", alignItems: "center", justifyContent: "center" }}
                className="column width-100 product-card-analytic-button-container"
              >
                <div
                  className="divider"
                  style={{ width: "90%", marginLeft: "5%", marginBottom: "20px", marginTop: "5px" }}
                />
                {isAnalyticsProduct && product.etsy_stats?.percent_evolution > 0 && (
                  <div style={{ marginLeft: "15px", marginBottom: "15px" }} className="row width-100">
                    <img
                      className="mobile-display-none"
                      alt="growth arrow"
                      src={upArrowIcon}
                      style={{ width: "18px", marginRight: "8px" }}
                    />
                    <p className="product-card-sales-evolution">
                      {t("productCardStats", {
                        sales: product.etsy_stats?.total_sales,
                        percent: "+" + product.etsy_stats?.percent_evolution,
                      })}{" "}
                    </p>
                  </div>
                )}
                <AnalyticButton setState={setIsSalesStatsDisplayed} />
              </div>
            </>
          )}

          <AdminButtons product={product} />
        </div>
      </>
    )
  );
};

export default ProductCard;
