import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Slider, Divider, Input, message } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Load from "../../utils/Load";
import ValidationModal from "./ValidRequestModal";

import { url } from "../../api/url";
import { header } from "../../api/header";
import InformationsModal from "./InformationsModal";

import breakLineIcon from "../../images/icons/break_line.svg";
import shildImg from "../../images/icons/secure.svg";

const CoachingForm = ({
  refreshUser,
  idAccount,
  adminId,
  setIsModalDispalyed,
  coaching,
  setUser,
  user,
  containerStyle,
  isFirstBoost,
}) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [coachingStatus, setCoachingStatus] = useState("configuration"); // configuration | in demand | accepted | activated
  const [sellersPerMonth, setSellersPerMonth] = useState(coaching?.sellers_per_month || 1);
  const [sellersPopularity, setSellersPopularity] = useState(coaching?.sellers_popularity || "large");
  const [price, setPrice] = useState(coaching?.price || 0);
  const [estimateSales, setEstimateSales] = useState(coaching?.estimated_sales || 0);
  const [stripePriceId, setStripePriceId] = useState("");
  const [load, setLoad] = useState(false);
  const [isInformationsModalDisplayed, setIsInformationsModalDisplayed] = useState(false);
  const [isValidationModalDisplayed, setIsValidationModalDisplayed] = useState(false);

  const { t } = useTranslation();

  const [defaultEstimateSales, setDefaultEstimateSales] = useState(0);
  const [defaultPrice, setDefaultPrice] = useState(0);

  const isBuyable = isFirstBoost || !!user?.coaching?._id;
  const firstBoostPrice = 499;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  let stripe = "";
  stripePromise.then((resStripe) => (stripe = resStripe));

  useEffect(() => {
    if (coaching?._id) {
      if (coaching?.is_activate) {
        setCoachingStatus("activated");
      } else if (coaching?.is_validate) {
        setCoachingStatus("accepted");
      } else if (coaching?.status === "configuration") {
        setCoachingStatus("configuration");
      } else {
        setCoachingStatus("in demand");
      }

      setSellersPerMonth(coaching.sellers_per_month);
      setSellersPopularity(coaching.sellers_popularity);
      setEstimateSales(coaching.estimated_sales);
      setStripePriceId(coaching.stripe_price_id);
      if (isFirstBoost) {
        setPrice(firstBoostPrice);
      } else {
        setPrice(coaching.price);
      }
    }
  }, [coaching]);

  useEffect(() => {
    const defaultSellerPrice = 150;
    const milesViewsGain = 12;
    const popularityCoefs = {
      small: {
        cost: 4,
        view: 5,
      },
      medium: {
        cost: 5,
        view: 20,
      },
      large: {
        cost: 6,
        view: 75,
      },
    };
    Object.keys(popularityCoefs).map((key) => {
      if (key === sellersPopularity) {
        const clcPrice = parseFloat(popularityCoefs[key].cost * defaultSellerPrice * sellersPerMonth);
        const clcEstimateSaled = parseFloat(
          ((popularityCoefs[key].view * sellersPerMonth * milesViewsGain) / 1000) * 3
        );
        setDefaultPrice(!defaultPrice && coaching?._id ? coaching.price : clcPrice);
        setDefaultEstimateSales(
          !defaultEstimateSales && coaching?._id ? coaching.estimated_sales : clcEstimateSaled
        );
        if (!adminId && (coaching?.status === "configuration" || !coaching?._id)) {
          // admin input the price in text
          if (isFirstBoost) {
            setPrice(firstBoostPrice);
          } else {
            setPrice(!price && coaching?._id ? coaching.price : clcPrice);
          }
          setEstimateSales(!estimateSales && coaching?._id ? coaching.estimated_sales : clcEstimateSaled);
        }
      }
    });
  }, [sellersPerMonth, sellersPopularity]);

  const create = async () => {
    const fieldsAreValid = () => {
      return (
        idAccount &&
        sellersPerMonth &&
        sellersPopularity &&
        price &&
        estimateSales &&
        (stripePriceId || coaching?.stripe_price_id || !adminId)
      );
    };
    if (!fieldsAreValid()) {
      message.error(t("messages.missingElements"));
    } else {
      setLoad(true);
      const res = await fetch(`${url}/coaching`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          creater_id: idAccount,
          sellers_per_month: sellersPerMonth,
          sellers_popularity: sellersPopularity,
          stripe_price_id: stripePriceId,
          price,
          estimated_sales: estimateSales,
          userEmail: user?.email,
        }),
      });
      if (res.status === 200) {
        if (refreshUser) {
          refreshUser();
        }
        setLoad(false);
        if (!adminId) {
          setUser({ ...user, coaching: { ...user?.coaching, id: "created" } });
          message.success(t("messages.boostSend"));
        } else {
          message.success("save");
          if (adminId) {
            validPrice();
          }
        }
      } else message.error("error");
    }
  };

  const validPrice = async () => {
    setLoad(true);
    const res = await fetch(`${url}/coaching/${idAccount}/${adminId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        creater_id: idAccount,
        sellers_per_month: sellersPerMonth,
        sellers_popularity: sellersPopularity,
        stripe_price_id: stripePriceId,
        price,
        estimated_sales: estimateSales,
        is_activate: false,
        start_date: new Date().toISOString(),
        is_validate: true,
        status: "accepted",
      }),
    });
    if (res.status === 200) {
      message.success("save");
      setLoad(false);
    } else message.error("error");
  };

  const archive = async () => {
    setLoad(true);
    fetch(`${url}/coaching/archive/${idAccount}/${adminId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    }).then((result) => {
      if (result.status === 200) {
        message.success("Boost archivé, go supprimer le prix sur Stripe");
        setLoad(false);
        if (setIsModalDispalyed) {
          setIsModalDispalyed(false);
        }
      } else {
        message.error("error");
        setLoad(false);
      }
    });
  };

  const subscribe = async () => {
    setLoad(true);
    fetch(`${url}/coaching/subscribe/${idAccount}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          message.error(t("messages.error"));
          setLoad(false);
          window.tidioChatApi?.open();
          window.tidioChatApi.messageFromVisitor("Hello, I just had a problem paying my Boost");
          return {};
        }
      })
      .then(({ sessionId }) => {
        if (sessionId) {
          stripe.redirectToCheckout({ sessionId });
        }
      });
  };

  const isChangeBLocked = () => {
    return coachingStatus !== "configuration" && !adminId;
  };

  return (
    <Elements stripe={stripePromise}>
      {isValidationModalDisplayed && (
        <ValidationModal setIsVisible={setIsValidationModalDisplayed} handleValid={create} />
      )}
      {load && <Load />}
      {isInformationsModalDisplayed && (
        <InformationsModal
          isDisplayed={isInformationsModalDisplayed}
          setIsDisplayed={setIsInformationsModalDisplayed}
        />
      )}
      <div
        id="coaching-form"
        className={isFirstBoost ? "card orange-border" : "card"}
        style={containerStyle || {}}
      >
        <p
          style={{
            fontWeight: "900",
            fontSize: "25px",
            marginBottom: "20px",
            color: isFirstBoost ? "#FBA95E" : "rgb(189 189 189)",
          }}
          className="title-bold montserrat bold start width-100"
        >
          {isFirstBoost
            ? t("supplierGrowth.18")
            : coachingStatus === "accepted" || coachingStatus === "in demand"
            ? t("components.coachingForm.23")
            : coachingStatus === "activated"
            ? t("components.coachingForm.24")
            : t("components.coachingForm.25")}
        </p>
        <div className="row-wrap width-100 space-between start">
          <div style={{ margin: "15px", minWidth: "250px", width: "45%" }} className="back-grey">
            <div style={{ marginBottom: "3px" }} className="row">
              <CheckCircleFilled style={{ fontSize: "20px", marginLeft: "-7px" }} className="marine" />
              <p className="text-bold" style={{ marginLeft: "10px" }}>
                {t("components.coachingForm.26")}
              </p>
            </div>
            <div style={{ marginTop: "10px" }} className="row">
              <img alt="break-line" src={breakLineIcon} />
              <p
                className="text-bold marine"
                style={{ marginLeft: "10px", opacity: "0.7", fontWeight: "500" }}
              >
                {t("components.coachingForm.27")}
              </p>
            </div>
            <div style={{ marginTop: "10px" }} className="row">
              <img alt="break-line" src={breakLineIcon} />
              <p
                className="text-bold marine"
                style={{ marginLeft: "10px", opacity: "0.7", fontWeight: "500" }}
              >
                {t("components.coachingForm.28")}
              </p>
            </div>
            <div style={{ marginTop: "10px" }} className="row">
              <img alt="break-line" src={breakLineIcon} />
              <p
                className="text-bold marine"
                style={{ marginLeft: "10px", opacity: "0.7", fontWeight: "500" }}
              >
                {t("components.coachingForm.29")}
              </p>
            </div>
          </div>

          {isFirstBoost && (
            <div style={{ margin: "15px", minWidth: "250px", width: "45%" }} className="back-grey">
              <div style={{ marginBottom: "15px", marginLeft: "-4px" }} className="row">
                <CheckCircleFilled style={{ fontSize: "20px" }} className="marine" />
                <p className="text-bold" style={{ marginLeft: "10px", fontSize: "18px" }}>
                  {t("supplierGrowth.19")}
                </p>
              </div>
              <p className="text grey">{t("supplierGrowth.13")}</p>
            </div>
          )}
          {!isChangeBLocked() && !isFirstBoost && (
            <div style={{ margin: "15px", minWidth: "250px", width: "45%" }} className="back-grey">
              <div style={{ marginBottom: "65px", marginLeft: "-4px" }} className="row">
                <CheckCircleFilled style={{ fontSize: "20px" }} className="marine" />
                <p className="text-bold" style={{ marginLeft: "10px", fontSize: "18px" }}>
                  {t("components.coachingForm.30")}
                </p>
              </div>
              <Slider
                getTooltipPopupContainer={() => document.querySelector(".ant-slider-step")}
                value={sellersPerMonth}
                onChange={(value) => !isChangeBLocked() && setSellersPerMonth(value)}
                max={6}
                min={1}
                style={{ width: "100%" }}
                defaultValue={2}
                tipFormatter={(number) => `${number} ${t("to").toLowerCase()} ${number + 1}`}
                tooltipVisible
              />
            </div>
          )}
          {isChangeBLocked() && (
            <div style={{ margin: "15px", minWidth: "250px", width: "45%" }} className="back-grey width-100">
              <div style={{ marginBottom: "3px" }} className="row">
                <CheckCircleFilled style={{ fontSize: "20px", marginLeft: "-7px" }} className="marine" />
                <p className="text-bold" style={{ marginLeft: "10px" }}>
                  {t("components.coachingForm.35")}
                </p>
              </div>
              <div style={{ marginTop: "10px" }} className="row">
                <img src={breakLineIcon} />
                <p className="marine" style={{ marginLeft: "10px", opacity: "0.7", fontWeight: "500" }}>
                  +{sellersPerMonth}-{sellersPerMonth + 1} {t("components.coachingForm.36")}{" "}
                </p>
              </div>
              <div style={{ marginTop: "10px" }} className="row">
                <img src={breakLineIcon} />
                <p className="marine" style={{ marginLeft: "10px", opacity: "0.7", fontWeight: "500" }}>
                  {sellersPopularity} {t("components.coachingForm.37")}
                </p>
              </div>
            </div>
          )}
        </div>
        <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
        <div style={{ justifyContent: "space-between" }} className="row-wrap width-100 start space-between">
          <div style={{ width: "45%", minWidth: "250px", margin: "10px" }} className="column">
            <div className="row space-between">
              <p style={{ fontSize: "16px" }} className="text-bold grey">
                {t("price")}
              </p>
              {adminId ? (
                <div>
                  <Input
                    placeholder={defaultPrice}
                    style={{ width: "80px", marginRight: "10px" }}
                    prefix="$"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              ) : (
                <p className="montserrat bold">
                  <span style={{ fontSize: "16px" }} className="text-bold marine">
                    {price}$
                    {isFirstBoost && (
                      <span
                        style={{ textDecoration: "line-through", marginLeft: "10px" }}
                        className="text-bold red"
                      >
                        900$
                      </span>
                    )}
                  </span>{" "}
                </p>
              )}
            </div>
            {!isBuyable && (
              <p style={{ marginTop: "20px" }} className="text red">
                {t("supplierGrowth.17")}{" "}
              </p>
            )}
            {adminId ? (
              <button
                onClick={create}
                style={{ marginTop: "20px" }}
                className="button big orange-back width-100"
              >
                {t("save")}
              </button>
            ) : coachingStatus === "accepted" ? (
              <button
                id="buy-coaching"
                onClick={subscribe}
                style={{ marginTop: "20px" }}
                className="button big orange-back width-100"
              >
                {t("components.coachingForm.43")}
              </button>
            ) : coachingStatus === "in demand" ? (
              <button
                style={{ marginTop: "20px", width: "100%", fontSize: "22px", height: "45px" }}
                className="button-disable-grey"
              >
                {t("components.coachingForm.44")}
              </button>
            ) : (
              isBuyable &&
              (!coaching?._id || coachingStatus === "configuration") && (
                <button
                  id="ask-coaching"
                  onClick={() => setIsValidationModalDisplayed(true)}
                  style={{ marginTop: "20px" }}
                  className="button big orange-back width-100"
                >
                  {t("components.coachingForm.45")}
                </button>
              )
            )}
            {adminId && coachingStatus === "configuration" && coaching && (
              <button
                onClick={() => {
                  if (!stripePriceId && !coaching?.stripe_price_id) {
                    message.error("Entrer le stripe price id");
                  } else validPrice();
                }}
                style={{ marginTop: "20px" }}
                className="button big orange-back width-100"
              >
                {t("components.coachingForm.46")}
              </button>
            )}
            {adminId && (
              <button
                onClick={archive}
                style={{ marginTop: "20px" }}
                className="button big orange-back width-100"
              >
                {t("components.coachingForm.47")}
              </button>
            )}
            {coaching?.is_activate && (
              <div className="column width-100" style={{ alignItems: "center", marginTop: "15px" }}>
                <p
                  onClick={() => {
                    window.tidioChatApi.open();
                    window.tidioChatApi.messageFromVisitor("Hello, I want to stop my Boost");
                  }}
                  className="text-bold red underline pointer start"
                >
                  {t("components.coachingForm.48")}
                </p>
                <p className="text-300 grey" style={{ fontSize: "12px", marginTop: "3px" }}>
                  {t("components.coachingForm.49")}
                </p>
              </div>
            )}
            {coachingStatus === "configuration" && isBuyable && (
              <p style={{ fontSize: "13px", marginTop: "25px" }} className="text-300 grey width-100 start">
                {t("components.coachingForm.50")}
              </p>
            )}
          </div>
          <div style={{ width: "45%", minWidth: "250px", margin: "10px" }} className="column">
            <p style={{ fontSize: "14px" }} className="text-300 grey">
              {t("supplierGrowth.14")}
            </p>
            <p style={{ marginTop: "20px" }}>
              <img style={{ marginRight: "10px" }} src={shildImg} alt="shield" />
              {t("supplierGrowth.15")}
            </p>
          </div>
        </div>
        {adminId && (
          <Input
            placeholder="Stripe price id"
            style={{ width: "90%", marginTop: "20px" }}
            suffix="$"
            defaultValue={coaching?.stripe_price_id}
            onChange={(e) => setStripePriceId(e.target.value)}
          />
        )}
      </div>
    </Elements>
  );
};

export default CoachingForm;
