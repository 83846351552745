import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { message } from "antd";
import Load from "../../utils/Load";
import "./Stripe.scss";

const Stripe = ({ user, stripeStatus }) => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);

  const connect = async () => {
    const token = localStorage.getItem("tokenCreatosell");
    const resConnect = await fetch(`${url}/stripe/onboard-user`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        userId: user._id,
        user,
        stripe: user.stripe ? user.stripe : "none",
      }),
    });
    if (resConnect.status === 200) {
      const resJson = await resConnect.json();
      if (await resJson) {
        let win = window.open(resJson.url, "_blank");
        if (win) win.focus();
        else window.location.href = resJson.url;

        setLoad(false);
      }
    } else {
      message.error(t("messages.error"));
      setLoad(false);
    }
  };

  return (
    <React.Fragment>
      {load && <Load />}
      {stripeStatus === "Active" ? (
        <a
          style={{ marginTop: "10px", marginBottom: "10px" }}
          href="https://dashboard.stripe.com"
          target="_blank"
          rel="nopooner noreferrer"
          className="button big orange-back"
        >
          {t("stripe.goStripeDashboard")}
        </a>
      ) : (
        <button
          style={{ marginTop: "10px", marginBottom: "10px" }}
          onClick={() => {
            setLoad(true);
            connect();
          }}
          className="button big orange-back"
        >
          {stripeStatus === "Missing documents"
            ? t("stripe.submitDocuments")
            : user.stripe?.account_id
            ? t("stripe.configure")
            : t("stripe.connect")}
        </button>
      )}
    </React.Fragment>
  );
};

export default Stripe;
