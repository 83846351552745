import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { PayPalButton } from "react-paypal-button-v2";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { message } from "antd";

const Paypal = ({
  data,
  paypalAccountId,
  setPassOrder,
  setOrders,
  orders,
  order,
  user,
  stripeAccountId,
}) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));

  const { t } = useTranslation()

  let orderPaypal = {};

  const payer = {
    name: {
      given_name: "PayPal",
      surname: "Customer",
    },
    address: {
      address_line_1: order.customer.address1,
      address_line_2: "",
      admin_area_2: "",
      admin_area_1: "",
      postal_code: order.customer.zip,
      country_code:
        order.total_client_cost === "manual order"
          ? order.customer.country_code
          : "",
    },
    email_address: "customer@domain.com",
    phone: {
      phone_type: "MOBILE",
      phone_number: {
        national_number: user.company?.phone,
      },
    },
  };

  return (
    <>
      {process.env.REACT_APP_PAYPAL_PUBLIC_KEY && paypalAccountId && (
        <PayPalButton
          options={
            stripeAccountId
              ? {
                  clientId: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
                  merchantId: paypalAccountId,
                  currency: "USD",
                  disableFunding: "credit,card",
                }
              : {
                  clientId: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
                  merchantId: paypalAccountId,
                  currency: "USD",
                }
          }
          createOrder={async (dataP, actions) => {
            const response = await fetch(`${url}/paypal/create-order`, {
              method: "POST",
              credentials: "include",
              headers: {
                ...header,
                authorization: token,
              },
              body: JSON.stringify({
                orderId: data.orderId,
                shipping: data.shipping,
                order: data.order,
                message: data.message,
                product: data.product,
                local_variant: data.local_variant,
                variant: data.variant,
                isVariant: data.isVariant,
              }),
            });
            if (response.status == 200) {
              const resJson = await response.json();
              orderPaypal = resJson;

              if (resJson.orderUpdate && resJson.orderUpdate.paid) {
                let amount = parseFloat(
                  resJson.orderUpdate.paid.seller_paid
                ).toFixed(2);
                let createrFees = parseFloat(
                  resJson.orderUpdate.paid.creater_fees
                ).toFixed(2);
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: "USD",
                        value: amount,
                      },
                      payment_instruction: {
                        disbursement_mode: "INSTANT",
                        platform_fees: [
                          {
                            amount: {
                              currency_code: "USD",
                              value: createrFees,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  // application_context: {
                  //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                  // }
                })
              } else
                message.error(
                  t('messages.error')
                );
            } else {
              message.error(
                t('messages.error')
              );
            }
          }}
          onApprove={(data, actions) => {
            // Capture the funds from the transaction
            return actions.order.capture().then(function (details) {
              // Show a success message to your buyer

              // OPTIONAL: Call your server to save the transaction

              let newOrder = orderPaypal.orderUpdate;
              if (
                !newOrder.seller ||
                (newOrder.seller &&
                  (!newOrder.seller.id ||
                    !newOrder.seller.name ||
                    !newOrder.seller.address ||
                    !newOrder.seller.company_name ||
                    !newOrder.seller.company_email ||
                    !newOrder.seller.company_phone))
              ) {
                newOrder.seller = {
                  id: user._id,
                  name: `${user.firstName} ${user.lastName}`,
                  vat: user.company.vat,
                  address: user.company.address,
                  company_name: user.company.name,
                  company_email:  user.company.email ? user.company.email : user.email,
                  company_phone: user.company.phone,
                };
              }

              return fetch(`${url}/paypal/valid-order`, {
                method: "POST",
                credentials: "include",
                headers: {
                  ...header,
                  authorization: token,
                },
                body: JSON.stringify({
                  ...orderPaypal,
                  paypalOrderId: data.orderID,
                  paymentId: orderPaypal.paymentId
                }),
              })
                .then((res) => res.json())
                .then((resPaidJson) => {
                  if (resPaidJson) {
                    setPassOrder({});
                    let newOrders = orders;
                    let newOrder = order;
                    orders.map((orderP, index) => {
                      if (order._id === orderP._id) {
                        newOrder.status = "paid";
                        newOrder = {
                          ...newOrder,
                          paid: orderPaypal.orderUpdate.paid,
                        };
                        newOrders[index] = newOrder;
                        setOrders([...newOrders]);
                      }
                    });
                    message.success(t('messages.orderPlaced'));
                  } else {
                    message.error(t('messages.error'));
                  }
                });
            });
          }}
        />
      )}
    </>
  );
};

export default Paypal;
