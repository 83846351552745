import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { Link, useHistory } from "react-router-dom";
import { Select } from "antd";
import { Helmet } from "react-helmet";

import Contact from "../components/ContactForm";

import OgImg from "../images/og_image.png";
import HeroIllustration from "../images/illustrations/hero.svg";
import SupplierIllustration from "../images/illustrations/supplier.svg";

import NavBar from "../components/menu/navbar/NavBar";
import Footer from "../components/menu/Footer";
import ResellerSetupProgress from '../components/reseller/SetupProgress'

const Option = { Select };

const Documentation = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [contactUs, setContactUs] = useState(false);

  const { t } = useTranslation()

  const history = useHistory();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  return (
    <div className="page background-landing">
      {contactUs && <Contact setContact={setContactUs} />}
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://app.01supply.com/documentation" />
        <title>Reseller & Supplier Documentation | 01supply</title>
        <meta name="description"
          content="If you are a 01supply reseller or supplier, here is the full documentation to learn everything you need to know about our platform. Orders, marketplace,..." />

        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://app.01supply.com/documentation" />
        <meta property="og:title" content={`Reseller & Supplier Documentation | 01supply`} />
        <meta property="og:description"
          content="If you are a 01supply reseller or supplier, here is the full documentation to learn everything you need to know about our platform. Orders, marketplace,..." />
        <meta property="og:type" content="website" />
        <meta property="og:image"
          content="https://app.01supply.com/static/media/logo.55832fc9.svg" />

        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
    "@context": "https://schema.org",
    "headline": "01supply Reseller & Supplier Documentation",
    "image": [
    "https://app.01supply.com/static/media/logo.55832fc9.svg"
    ],
    "publisher": {
     "name": "01supply",
    "url": "https://app.01supply.com/"
    }
    }`}
        </script>
      </Helmet>

      <NavBar windowSize={windowSize} />

      <div className="column center" style={{ width: "100%", marginTop: "0px" }}>
        <h1 className="title-bold montserrat" style={{ fontWeight: "900" }}>
          {t("documentationPage.1")}
        </h1>
        <Select
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          placeholder={t("documentationPage.2")}
          style={
            windowSize > 1150
              ? { marginRight: "30px", width: "47.5%" }
              : { width: "90%", marginBottom: "10px" }
          }
          className="border-red"
          onChange={(value) => history.push(value)}
        >
          <Option value={"/what-is-01supply"}>{t("documentationPage.3")}</Option>
          <Option value={"/marketplace-documentation"}>{t("documentationPage.4")}</Option>
          <Option value={"/your-account-reseller-documentation"}>{t("documentationPage.5")}</Option>
          <Option value={"/how-to-place-an-order"}>{t("documentationPage.6")}</Option>
          <Option value={"/how-to-track-an-order"}>{t("documentationPage.7")}</Option>

          <Option value={"/shopify-setup"}>{t("documentationPage.8")}</Option>

          <Option value={"/supplier-location-documentation"}>{t("documentationPage.9")}</Option>
          <Option value={"/supplier-shipping-methods-documentation"}>{t("documentationPage.10")}</Option>
          <Option value={"/supplier-product-quantities-documentation"}>{t("documentationPage.11")}</Option>

          <Option value={"/what-is-dropshipping"}>{t("documentationPage.12")}</Option>
          <Option value={"/dropshipping-supplier"}>{t("documentationPage.13")}</Option>
          <Option value={"/what-is-01supply-for-supplier"}>{t("documentationPage.14")}</Option>
          <Option value={"/marketplace-documentation-for-supplier"}>{t("documentationPage.15")}</Option>
          <Option value={"/your-supplier-account-documentation"}>{t("documentationPage.16")}</Option>
          <Option value={"/shipping-methods-documentation"}>{t("documentationPage.17")}</Option>
          <Option value={"/getting-paid-documentation"}>{t("documentationPage.18")}</Option>

          <Option value={"/how-sell-on-01supply-supplier-documentation"}>{t("documentationPage.19")}</Option>
          <Option value={"/how-create-product-supplier-documentation"}>{t("documentationPage.20")}</Option>
          <Option value={"/how-import-csv-products-supplier-documentation"}>
            {t("documentationPage.21")}
          </Option>
          <Option value={"/transactions-fees-supplier-documentation"}>{t("documentationPage.22")}</Option>
          <Option value={"/fulfill-my-order-supplier-documentation"}>{t("documentationPage.23")}</Option>

          <Option value={"/what-is-dropshipping?supplier=true"}>{t("documentationPage.24")}</Option>
          <Option value={"/dropshipping-reseller"}>{t("documentationPage.25")}</Option>
        </Select>

        <div
          className={windowSize > 1150 ? "row" : "column"}
          style={{ width: "100%", marginTop: "50px", justifyContent: "center", alignItems: "center" }}
        >
          <div style={{ width: windowSize > 1150 ? "45%" : "100%", margin: "30px" }} className="card">
            <h2 className="title-bold orange montserrat" style={{ fontWeight: "900" }}>
              {t("documentationPage.card1.title")}
            </h2>
            <div className="row">
              <p className="text-400 start montserrat">
                {t("documentationPage.card1.texts.1")}
                <span className="text-bold" style={{ marginLeft: "6px", marginRight: "6px" }}>
                  {t("documentationPage.card1.texts.2")}
                </span>
                {t("documentationPage.card1.texts.3")}
                <br />
                <br />
                {t("documentationPage.card1.texts.4")}
              </p>
              {windowSize > 1150 && (
                <img src={HeroIllustration} style={{ width: "100px", marginLeft: "30px" }} />
              )}
            </div>
            <Link
              to="/what-is-01supply"
              style={{ width: "90%", marginTop: "20px" }}
              className="button big orange-back"
            >
              {t("documentationPage.card1.button")}
            </Link>
          </div>
          <div style={{ width: windowSize > 1150 ? "45%" : "100%", margin: "30px" }} className="card">
            <h2 className="title-bold purple montserrat" style={{ fontWeight: "900" }}>
              {t("documentationPage.card2.title")}
            </h2>
            <div className="row">
              <p className="text-400 start montserrat">
                {t("documentationPage.card2.texts.1")}
                <span className="text-bold" style={{ marginLeft: "6px", marginRight: "6px" }}>
                  {t("documentationPage.card2.texts.2")}
                </span>
                {t("documentationPage.card2.texts.3")}
                <br />
                <br />
                {t("documentationPage.card2.texts.4")}
              </p>
              {windowSize > 1150 && (
                <img src={SupplierIllustration} style={{ width: "100px", marginLeft: "30px" }} />
              )}
            </div>
            <Link
              to="/what-is-01supply-for-supplier"
              style={{ width: "90%", marginTop: "20px" }}
              className="button big purple-back"
            >
              {t("documentationPage.card2.button")}
            </Link>
          </div>
        </div>
        <h3 className="title-bold montserrat" style={{ fontWeight: "900", marginTop: "50px" }}>
          {t("documentationPage.26")}
        </h3>
        <ul>
          <a href="https://www.youtube.com/channel/UCeep3moyj-fAcENu-z884VA" target="_blank">
            <li className="text-bold blue start">{t("documentationPage.27")}</li>
          </a>
          <a href="https://www.facebook.com/01supply4suppliers" target="_blank">
            <li className="text-bold blue start">{t("documentationPage.28")}</li>
          </a>
        </ul>
        <h3 className="title-bold montserrat" style={{ fontWeight: "900", marginTop: "50px" }}>
          {t("documentationPage.29")}
        </h3>
        <button
          style={{ marginBottom: "150px" }}
          className="button big orange-back"
          onClick={() => window.tidioChatApi.open()}
        >
          {t("documentationPage.30")}
        </button>
      </div>

      <Footer windowSize={windowSize} location="reseller" />
    </div>
  );
};

export default Documentation;
