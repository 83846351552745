import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import { Helmet } from "react-helmet";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";

import { url } from "../../api/url";
import { header } from "../../api/header";
import { PlusOutlined } from "@ant-design/icons";
import Header from "../../components/menu/marketplaceNavbar";
import OgImg from "../../images/og_image_2.png";

import RegisterModal from "../../components/modals/RegisterModal";
import WinningProducts from "../../components/marketplace/winningProducts/WinningProducts";

const WinningProductsMarketplace = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [loadProducts, setLoadProducts] = useState(true);
  const [user, setUser] = useState({});
  const [popupLogin, setPopupLogin] = useState("");

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [window.tidioChatApi]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marketplace | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/marketplace" />
        <meta name="description" content={t("marketplace.metas.description")} />
        <meta property="og:description" content={t("marketplace.metas.description")} />
        <meta property="og:url" content="https://app.01supply.com/marketplace" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Marketplace | 01supply`} />
        <meta property="og:image" content={OgImg} />
      </Helmet>
      <RegisterModal modalOn={popupLogin} setModal={setPopupLogin} />

      <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page-start">
        {windowSize > 1150 ? (
          <Menu locationP="winningProducts" userProps={user} />
        ) : (
            <MobileMenu locationP="winningProducts" />
        )}
        <div style={{ padding: "0", paddingBottom: windowSize <= 1150 && "250px" }} className="column-scrolling">

          <div
            id="container-marketplace"
            className="column"
            style={
              windowSize > 1150
                ? {
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    paddingBottom: "0px",
                    minHeight: "100vh",
                  }
                : { alignItems: "center" }
            }
          >

            <div
              className="column"
              style={
                windowSize > 1150
                  ? { marginTop: `${"10px"}`, width: "100%", alignItems: "center" }
                  : { width: "100%" }
              }
            >
              {loadProducts && false ? (
                <div style={windowSize > 1150 ? { width: "90%" } : { width: "95vw", marginLeft: "2.5vw" }}>
                  <Skeleton
                    style={{ marginTop: "80px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                    className="image-product margin-top-landing"
                    loading={true}
                    active
                  />
                  <Skeleton
                    style={{ marginTop: "20px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                    className="image-product"
                    loading={true}
                    active
                  />
                </div>
              ) : (
                <div
                  id="marketplace-scrolling"
                  style={
                    windowSize > 1150
                      ? {
                          width: "95%",
                          height: "auto",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flex: "0 0 auto",
                          marginTop: "30px",
                        }
                      : {
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flex: "0 0 auto",
                        }
                  }
                  className="row wrap"
                >
                  <WinningProducts
                    user={user}
                    setUser={setUser}
                    windowSize={windowSize}
                    setRegisterModalIsActive={setPopupLogin}
                  />
                </div>
              )}
            </div>
          </div>
          {localStorage.getItem("typeAccountCreatosell") === "creater" && (
            <Link className="add-absolute" to="/create-product">
              <PlusOutlined />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default WinningProductsMarketplace;
