import React, {useState} from 'react'
import DocMenu from '../../components/menu/DocMenu'
import Menu from '../../components/menu/FirstMenu'
import MobileMenu from '../../components/menu/mobile/FirstMenu'
import {Helmet} from "react-helmet";

const HowItWork = () => {
    const [user, setUser] = useState({})
    const [idAccount] = useState(localStorage.getItem('idAccountCreatosell'))
    const [token] = useState(localStorage.getItem('tokenCreatosell'))
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    window.addEventListener("resize", function (event) {
        setWindowSize(window.innerWidth);
      });
      
    return(
        <div className="page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reseller Documentation | 01supply</title>
                <link rel="canonical" href="https://app.01supply.com/documentation/reseller" />
                <meta name="description" content="How does 01supply work? How to import products to our shop? How to place orders to the supplier? Learn tips and hacks through our Reseller Documentation."/>
            </Helmet>
            <div className="page-start">
                {windowSize > 1150 ?
                <Menu/>
                : <MobileMenu locationP="setting"/>}
                <div style={windowSize > 1150 ? {alignItems: 'center', paddingTop: '70px', display: 'flex'}
                : {alignItems: 'center', paddingTop: '30px'}} className="column-scrolling">
                    <DocMenu/>
                    <div id="supplier-doc" className="column" style={windowSize > 1150 ? {width: '60%', marginLeft: "0%", marginTop: '100px'}
                    : {width: '80%', marginLeft: "10%", marginTop: '30px'}}>
                        
                        <p style={{fontSize: '40px'}} className="title-300">
                        How it works ?
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        01supply helps you find unique dropshipping suppliers like jewels suppliers, clothes suppliers, decoration suppliers, craftsmen,...<br/>
                        Today, you want to get connected with suppliers that provide local, sustainable and quality products that meet this new demand. <br/>
                        You can either invite your own supplier or choose one in our marketplace. And once you have an order on your store, we make it easy for you to pay your supplier and track the shipping.<br/>
                        In addition to that, we provide quality label, origin verification, one-click import of products to your Shopify store and strong warranty. Everything you need to make a confident supplier selection.<br/>
                        </p>
                        
                        <p id="marketplace" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Marketplace
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        On the marketplace, you can find every product listed by our suppliers. You can search for a product using the search bar or even filter the results. 
                        Once you have found the product that you want to sell in your shop, you can click on it and import it through the “import” button. 
                        You can also contact the supplier if you need any information about the product.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Product page : {" "}
                          </span>
                          You can click on a product to access the product page. Product page displays all the information you need to write a great description on your own store. Click on variants to display their price. You can also see the remaining quantity. 
                          Suppliers can also choose to add limits to their product. That is why you will see products with limited resellers or orders per month allowed. Be sure to check the shipping methods allowed with this product to see if it matches your customer’s country.
                        </p>
                        
                        <p id="import-list" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Import list
                        </p>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        When you import a product, it appears in your import list. There you can edit it before pushing it to your Shopify store. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Splitting a product : {" "}
                          </span>
                          You can also choose to split it. For example if a product has multiple variants but you don’t want these variants on the same product in your store. You can choose to manually or automatically split it in multiple products. Then you just need to edit these newly splitted products one by one and push them to your store.
                        </p>

                        <p id="inventory" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Inventory
                        </p>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        Once pushed to your store, the products are listed in your inventory. There, you can easily find them, see the remaining quantity and their cost. Now let’s say that a product you listed is not available anymore. You can either choose to delete it or to override it. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Override : {" "}
                          </span>
                          Click on override to replace the product in your inventory with a newly selected product from the 01supply marketplace. The product page on your store won’t be affected if you unselect the option “Apply changes to Shopify product”.
                        </p>

                        <p id="orders" style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Orders
                        </p>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        You got an order in your store ? Awesome ! <br/>
                        Then it is time for you to ask your supplier to ship your product to your customer. <br/>
                        With 01supply, it is automated. You can find your orders in the “Orders” section. Click on the “order” to place an order to the supplier. You just need to select the shipping method and click on buy to place the order. <br/>
                        Once the order is placed, your supplier will get a notification and he will ship the order as soon as possible to your customer. <br/>
                        When it is shipped by your supplier, you will find your order is the “Shipped” section. You will also find the tracking number in the details of the order.
                        </p>

                        <p style={{fontSize: '40px', marginTop: '40px'}} className="title-300">
                        Settings
                        </p>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        Make sure to complete your settings as it is a good signal for us to know that you are engaged in 01supply. Also it makes the support process much easier and faster as we can solve your problem within 24h.
                        </p>

                        <p style={{fontSize: '25px', marginTop: '30px'}} className="title-300">
                        Your shop
                        </p>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Connect your shop : {" "}
                          </span>
                          The whole purpose of 01supply is to automate the link between your store and our app. So be sure to link your store by entering your ".myshopify” url.
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        If you don’t have Shopify, you can 
                            <a href='https://www.shopify.fr' target="_blank" rel="noopener noreferrer" className="text-gradient">{" "}start a free trial here.</a>
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        Once connected, you can update your preferences. You can choose what 01supply should do when a product or a variant is not available anymore. You can also choose to be notified by email.
                        </p>

                        <p style={{fontSize: '25px', marginTop: '30px'}} className="title-300">
                        Plans
                        </p>

                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                        For now, upgrading plans allows you to import more products to your online store. You also get access to more features like winning products and product analytics.
                        </p>

                        <p style={{fontSize: '25px', marginTop: '30px'}} className="title-300">
                        Your informations
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Contact : {" "}
                          </span>
                          You can enter two different emails for contact. The first one will be used by resellers and other users to contact you. The second one allows us to contact you. This is the email we will also be using to notify you if a product is not available anymore. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Legal : {" "}
                          </span>
                          Fill your information carefully as it is mandatory for us to know our customer. Also, this will be useful for invoicing and billing purposes. VAT number is optional. 
                        </p>
                        <p style={{fontWeight: '300', fontSize: '16px', marginTop: '15px'}}>
                          <span className="text-bold">
                          Fast payment : {" "}
                          </span>
                          Connect your Stripe account to 01supply. This will allow you to easily pay suppliers.
                        </p>

                        </div>

                </div>
            </div>
        </div>
    )
}

export default HowItWork