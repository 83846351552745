import React from "react";
import { useTranslation } from "react-i18next";

const HistoryItem = ({ sourcing }) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "20px", maxHeight: "100px" }} className="row width-100">
      {sourcing.exemple_image ? (
        <img
          alt="sourcing"
          src={sourcing.exemple_image}
          style={{ borderRadius: "6px", width: "75px", marginRight: "15px" }}
        />
      ) : (
        <div style={{ height: "75px", width: "75px", marginRight: "15px" }} />
      )}
      <div className="column">
        <p style={{ maxWidth: "200px" }} className="text text-ellipsis">
          {sourcing.item_describe}
        </p>
        {sourcing.status === "Finished" ? (
          <div className="sourcing-label-finished">{t("finished")}</div>
        ) : (
          <div className="sourcing-label-processing">{t("processing")}</div>
        )}
      </div>
    </div>
  );
};

export default HistoryItem;
