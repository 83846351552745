import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article22/meilleurs-outils-SEO.jpg";
import unfr from "../../../../images/blog/global/article22/1fr-outil-seo.jpg";
import ahrefs from "../../../../images/blog/global/article22/ahrefs-outil-seo.jpg";
import altTxttester from "../../../../images/blog/global/article22/alt-text-tester-outil-seo.jpg";
import answerthePublic from "../../../../images/blog/global/article22/answer-the-public-seo.jpg";
import googleAnalytics from "../../../../images/blog/global/article22/google-analytics-outil-seo.jpg";
import searchConsole from "../../../../images/blog/global/article22/google-search-console.jpg";
import gtMetrix from "../../../../images/blog/global/article22/gtmetrix-outil-seo.jpg";
import keywordEverywhere from "../../../../images/blog/global/article22/keyword-everywhere.jpg";
import keywordSheeter from "../../../../images/blog/global/article22/keyword-sheeter-outil-seo.jpg";
import semrush from "../../../../images/blog/global/article22/semrush-outil-seo.jpg";


const Content = () => {

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Quels sont les meilleurs outils SEO? (Gratuits et Payants)
      </h1>
      <p className="text-300">29 octobre 2022 - 14 minutes de lecture</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Quels sont les meilleurs outils SEO?" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Si vous faites du SEO, vous vous demandez certainement comment passer <b>moins de temps dans votre recherche de mot-clés</b>,
        comment <b>analyser les sites de vos concurrents</b> ou encore comment s’assurer que son site est parfaitement <b>optimisé pour le référencement naturel</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour ce faire, il existe de très nombreux <b>outils sur internet</b>.
        Tous ne se valent pas, c’est pourquoi nous vous avons préparé un article complet faisant office de comparatif pour trouver l’outil qui vous convient.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#top-10-outils-seo" title="Top 10 des meilleurs outils SEO" />
            <Anchor.Link href="#meilleur-outil-seo" title="Quel est le meilleur outil SEO?" />
            <Anchor.Link href="#semrush" title="SEMrush" />
            <Anchor.Link href="#google-search-console" title="Google Search Console" />
            <Anchor.Link href="#google-analytics" title="Google Analytics" />
            <Anchor.Link href="#answer-the-public" title="AnswerThePublic" />
            <Anchor.Link href="#ahrefs" title="Ahrefs" />
            <Anchor.Link href="#keywords-everywhere" title="Keywords Everywhere" />
            <Anchor.Link href="#gtmetrix" title="GTmetrix" />
            <Anchor.Link href="#1fr" title="1.FR" />
            <Anchor.Link href="#keyword-sheeter" title="Keyword Sheeter" />
            <Anchor.Link href="#alt-txt-tester" title="Alt text tester" />
            <Anchor.Link href="#comment-faire-du-seo" title="Comment faire du SEO de nos jours?" />
          </Anchor>
        }
      />
      <h2 id="top-10-outils-seo">Top 10 des meilleurs outils SEO
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Il existe de très nombreux outils sur le marché, certains permettent de trouver des mots-clés.
        D’autres se spécialisent dans l’espionnage de vos concurrents, d’autres encore font tout à la fois.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici donc notre top 10 des meilleurs outils pour le SEO</u>:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <li>
          SEMrush
        </li>
        <li>
          Google Search Console
        </li>
        <li>
          Google Analytics
        </li>
        <li>
          AnswerThePublic
        </li>
        <li>
          Ahref
        </li>
        <li>
          Keyword Everywhere
        </li>
        <li>
          GTmetrix
        </li>
        <li>
          1.FR
        </li>
        <li>
          Keyword Sheeter
        </li>
        <li>
          Alt text tester
        </li>
      </ol>
      <h2 id="meilleur-outil-seo">Quel est le meilleur outil SEO?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        <b>Le meilleur outil SEO est sans aucun doute SEMrush</b>. Il est le plus complet, le plus fiable et le plus puissant de tous les outils.
        Bien que son abonnement ne soit pas toujours abordable pour les petits indépendants, il est un outil indispensable en entreprise.
      </p>
      <h2 id="semrush">1. SEMrush
      </h2>
      <img src={semrush} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="SEMrush meilleur outil SEO" />
      <h3>C’est quoi SEMrush?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        SEMrush est un outil en ligne SEO qui <b>existe depuis 2008</b>. SEMrush vous permet de savoir quel mot-clé a plus de recherches qu’un autre,
        il vous permet aussi de vous positionner par rapport à vos concurrents et de <b>découvrir toutes les opportunités pour votre site internet</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Aujourd’hui, SEMrush est l’outil le plus complet en SEO car il comprend de très nombreuses foncitonnalités qui couvrent la mojorité des besoins SEO.
      </p>
      <h3>
        Pourquoi choisir SEMrush?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        SEMrush a la réputation d’avoir <b>les données les plus précises du marché</b>.
        En effet, ces logiciels d’analyse de mot-clés ou de sites font souvent des approximations,
        et il s’avère que SEMrush est toujours le plus proche.
      </p>
      <p style={{ marginBottom: "20px" }}>
        SEMrush répond aussi au besoin de tous les entrepreneurs qui veuelnt faire du SEO car il comporte <b>de très nombreuses fonctionnalités</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici une rapide liste des principales fonctionnalités</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Analyse de domaine (url d’un site)
        </li>
        <li>
          Analyse de trafic
        </li>
        <li>
          Analyse des concurrents
        </li>
        <li>
          Comparaison de mots-clés entre plusieurs sites
        </li>
        <li>
          Analyse des backlinks
        </li>
        <li>
          Générateur de mots-clés
        </li>
        <li>
          Suivi de position
        </li>
        <li>
          Audit de backlinks
        </li>
        <li>
          Audit de maillage interne
        </li>
        <li>
          Audit SEO général
        </li>
        <li>
          Analyse SEO d’une page précise
        </li>
        <li>
          Et bien d’autres…
        </li>
      </ul>
      <h3>Comment se servir de SEMrush?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        SEMrush peut paraître impressionnant au premier abord. Cependant, dans 80% des cas,
        le seul outil dont vous aurez besoin est <b>la barre de recherche SEMrush</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        À partir de celle-ci, vous pourrez aussi bien entrer une URL qu’un mot-clé,
        et SEMrush vous affichera une analyse complète de toutes les données qu’il possède au sujet de votre recherche.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici les informations qu’on peut retrouver lorsqu’on tape l’<b>URL</b> d'un site</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          L’authority score
        </li>
        <li>
          Le nombre de visites mensuelles organiques
        </li>
        <li>
          Le nombre de visites via le SEA (publicité Google)
        </li>
        <li>
          Nombre de backlinks
        </li>
        <li>
          Évolution du nombre de visites que le site reçoit depuis sa création
        </li>
        <li>
          Évolution de nombre de mots-clés présents sur le site depuis sa création
        </li>
        <li>
          Répartition du trafic par pays
        </li>
        <li>
          Les meilleurs mots-clés du site
        </li>
        <li>
          Le positionnement moyen du site dans la SERP
        </li>
        <li>
          La répartition des intentions de recherche
        </li>
        <li>
          Les compétiteurs organiques principaux
        </li>
        <li>
          Les publicités Google que fait le site actuellement
        </li>
        <li>
          Les backlinks du site
        </li>
        <li>
          Les domaines référents
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici les information qu’on peut retrouver lorsqu’on tape un <b>mot-clé</b> dans la barre de recherche SEMrush</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Le volume de recherche mensuel par pays
        </li>
        <li>
          La difficulté du mot-clé
        </li>
        <li>
          Le nombre de résultats Google pour ce mot-clé
        </li>
        <li>
          Les mots-clés similaires, les questions et les variations
        </li>
        <li>
          L’analyse des résultats dans la SERP pour ce mot-clé
        </li>
        <li>
          Les publicités actives pour ce mot-clé
        </li>
      </ul>
      <h3>Combien coûte SEMrush?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        SEMrush possède trois offres différentes.
        Celles-ci donnent accès à plus ou moins de fonctionnalités ainsi qu’un volume d’utilisation plus ou moins élevé.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici les trois offres disponibles</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          $119.95/mois: le plan pro
        </li>
        <li>
          $229.95/mois; le plan guru
        </li>
        <li>
          $499.95/mois: le plan business
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Ensuite, vous pouvez aussi ajouter d’autres extensions d'offres pour des cas bien précis.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous retrouverez toutes <a href="https://www.semrush.com/prices/" alt="prix semrush" target="blank_" rel="noreferrer nofollow" className="blue"><u>les offres SEMrush ici</u></a>.
      </p>
      <h3>
        Comment avoir SEMrush gratuit?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Il existe deux méthodes pour utiliser SEMrush gratuitement.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La première est de profiter de l’<b>essai gratuit de 7 jours</b> qui vous est proposé lors de votre inscription.
        Vous aurez alors accès à toutes les fonctionnalités de l’offre Pro gratuitement.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La deuxième méthode consiste à se créer un compte SEMrush en ignorant l’offre d’essai de 7 jours.
        Ensuite, vous pourrez <b>analyser jusqu’à 10 domaines et/ou mots-clés par jour</b>.
        Idéal donc pour les petits indépendants qui n’ont pas besoin d’utiliser toutes les offres de SEMrush et qui ont une petite utilisation.
      </p>
      <h2 id="google-search-console">
        2. Google Search Console
      </h2>
      <img src={searchConsole} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Google Search Console" />
      <h3>
        La Google Search Console, c’est quoi?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        La Google Search Console est le repaire de tous les spécialistes du SEO.
        Cet outil est fourni par Google pour assurer le suivi et la gestion la plus efficace possible de son site.
        Il est <b>indispensable d’utiliser la Google Search Console lorsque vous faites du SEO</b>, ne serait-ce que pour envoyer votre sitemap à Google.
      </p>
      <h3>
        Comment accéder à la Google Search Console?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Pour accéder à la Search console, vous devez dans un premier temps posséder un compte Google.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Ensuite, les étapes sont assez simples à suivre</u>:
      </p>
      <ol>
        <li>
          Rendez-vous sur le site de
          la Search Console: <a href="https://search.google.com/search-console" target="blank_" alt="Search Console" rel="noreferrer nofollow" className="blue"><u>https://search.google.com/search-console</u></a>
        </li>
        <li>
          Cliquez sur “Commencer maintenant”
        </li>
        <li>
          Connectez-vous au compte Google de votre site web
        </li>
        <li>
          Entrez l’url principale de votre site. Il est préférable de choisir l’option “Domaine” et d’entrer l’adresse de votre site web sans le “https://www.”
        </li>
        <li>
          Ensuite, cliquez sur continuer
        </li>
        <li>
          À partir de cette étape, il vous faut prouver que vous êtes le propriétaire du site. Suivez donc les instructions de vérification indiquées par Google.
        </li>
        <li>
          Cliquez sur “Valider” et patientez quelques heures car la validation peut prendre du temps.
          Si la validation échoue, patientez une ou deux heures et réessayez la manipulation.
        </li>
        <li>
          Félicitations, votre site est désormais enregistré dans la Google Search Console!
        </li>
      </ol>
      <h3>
        Comment fonctionne la Search Console pour le SEO?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        La Google Search Console vous permet tout d’abord d’<b>envoyer votre sitemap à Google</b>.
        Celui-ci permettra à Google d’avoir un aperçu de toutes les pages de votre site afin de les indexer directement.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La Search Console vous permet aussi d’<b>accéder à des données sur votre site</b>.
        Par exemple, le nombre d’impression que votre site a réalisé dans les résultats de recherche Google,
        le <b>nombre de clic sur vos liens</b> (ainsi que le taux de clic), et enfin le positionnement moyen de votre site.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ces analytics sont disponibles aussi bien pour votre site en global, mais aussi pour chacune de vos pages individuellement.
        Ainsi, via la barre de recherche de la Search Console, vous pouvez accéder à des données précises sur chacune de vos pages.
        Vous pourrez alors <b>voir s’il y a des erreurs SEO</b> ou si Google n’a pas réussi à indexer certaines pages.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La Search Console vous permet aussi de <b>savoir si des actions manuelles ont été menées contre votre site</b>.
        Par exemple, en cas de sanction Google si vous êtes allé à l’encontre de leurs pratiques de référencement.
      </p>
      <h3>Comment indexer un site web sur Google?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Pour indexer un site sur Google, vous devez l’enregistrer sur la Google Search Console comme expliqué plus haut.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ensuite, il vous suffit d’<b>envoyer le sitemap de votre site à Google</b> dans l’onglet “Sitemaps” de la Google Search Console.
        Notez que Google peut prendre un certain temps avant d’indexer votre site, surtout si celui-ci est nouveau (de 2 semaines à 1 mois).
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour trouver votre sitemap, il vous suffit la plupart du temps d’<b>entrer votre URL suivi de /sitemap.xml</b>.
        Si vous créez votre site à partir d’un logiciel particulier ou que vous le codez vous même,
        il est possible que la procédure pour créer ou accéder à votre sitemap soit différente.
      </p>
      <h3>Quelles sont les différences entre la Google Search Console et Google Analytics?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        La <b>Google Search Console</b> est un outil qui vous donne <b>les informations de référencement de votre site</b>.
        Elle vous permet de gérer l’indexation des pages et de découvrir des opportunités de mots-clés.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Google Analytics</b> se concentre principalement sur l’<b>acquisition et le comportement de vos utilisateurs</b> sur votre site.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Avec Google Analytics, vous pourrez configurer de nombreux outils pour obtenir des données précises sur un tunnel de conversion en particulier.
        Par exemple, Google Analytics vous permet de savoir combien de personnes sont arrivées sur la page B depuis la page A.
        Google Analytics vous permet aussi de savoir que 60% des utilisateurs ont passé en moyenne 3 minutes sur la page B.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Notez que la Search Console et Google Analytics sont <b>des outils très compatibles</b> entre eux et sont très puissants quand on sait les utiliser.
      </p>
      <h2 id="google-analytics">3. Google Analytics
      </h2>
      <img src={googleAnalytics} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Google Analytics pour le SEO" />
      <h3>Pourquoi utiliser Google Analytics?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Google Analytics vous permet de <b>comprendre comment vos utilisateurs trouvent votre site et comment ils interagissent avec</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Grâce à Google Analytics, vous pourrez facilement comprendre quelle page n’est pas performante,
        <b>comment vos utilisateurs naviguent sur votre site</b> (leur cheminement de page en page)
        ainsi que le <b>type d’utilisateur</b> utilisant votre site (par exemple 60% d’hommes de 30 à 45 ans).
      </p>
      <p style={{ marginBottom: "20px" }}>
        Google Analytics vous permet aussi de <b>paramétrer des suivis pour des tunnels de conversion</b>.
        Par exemple, sur une boutique e-commerce, vous pouvez calculer combien de personnes passent de la page produit,
        à la page de panier, à la page de confirmation de commande.
      </p>
      <h3>Est-ce que Google Analytics est gratuit?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Google Analytics est un <b>outil 100% gratuit</b> proposé par Google.
        La seule condition d’accès à cet outil est d’avoir un compte Google ainsi que de bien paramétrer l’enregistrement de son site sur l’outil.
      </p>
      <h2 id="answer-the-public">4. AnswerThePublic
      </h2>
      <img src={answerthePublic} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Answer The Public SEO" />
      <h3>C’est quoi Answer The Public?</h3>
      <p style={{ marginBottom: "20px" }}>
        Answer The Public est un <b>créateur d'idées de contenu SEO</b>.
        En effet, cet outil vous permet de trouver les questions et les demandes des utilisateurs concernant un sujet précis.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Par exemple, en tapant le mot-clé “lapin” dans Answers The Public,
        vous aurez la possibilité de voir toutes les questions que les utilisateurs posent à propos des lapins sur Google.
      </p>
      <h3>Pourquoi utiliser Answer The Public?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        En SEO, le plus important c’est le contenu.
        C’est pourquoi, il est très important d’<b>écrire des articles et de créer des pages qui répondent aux demandes et aux requêtes des utilisateurs</b> Google.
        Par exemple, à partir d’une question trouvée sur Answers The Public,
        vous pourrez rédiger un article qui y répondra parfaitement et ainsi vous positionner dans les résultats Google pour cette requête en particulier.
      </p>
      <h3>Combien coûte Answer The Public?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Answer The Public est gratuit sous un certain nombre de requêtes.
        Ensuite, il vous faudra souscrire à un abonnement pour obtenir des requêtes journalières illimités (de la même manière que SEMrush).
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici les offres proposées par Answers The Public</u>:
      </p>
      <ul>
        <li>
          $79/mois (avec engagement annuel)
        </li>
        <li>
          $99/mois
        </li>
        <li>
          $199/mois
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez retrouver tous les détails
        des <a href="https://answerthepublic.com/pages/pro" alt="prix answerthepublic" target="blank_" rel="noreferrer nofollow" className="blue"><u>offres Answer The Public ici</u></a>.
      </p>
      <h2 id="ahrefs">Ahrefs</h2>
      <img src={ahrefs} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="aHrefs trouver des backlinks" />
      <h3>Qu’est-ce que Ahrefs?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Ahrefs est un outil SEO vous permettant de <b>trouver des mots-clés, suivre vos positions</b> par rapport à vos concurrents et <b>analyser le SEO</b> de votre site.
      </p>
      <h3>Comment utiliser Ahrefs?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Ahrefs est principalement connu pour son célèbre <b>analyseur de backlinks</b>.
        En effet, celui-ci vous permet gratuitement de voir les backlinks de vos concurrents.
        Contrairement à SEMrush, vous aurez accès à plus de données gratuites sur Ahrefs.
        De plus, cet outil vous permet de trier les backlinks par ordre d’autorité et par importance du domaine référent.
      </p>
      <h3>Comment trouver et voir les backlinks des sites?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Pour accéder à l’outil d’analyse de backlinks d’Ahrefs, vous pouvez vous rendre sur cette
        page: <a href="https://ahrefs.com/backlink-checker" alt="Backlinks ahrefs" rel="nofollow noreferrer" target="blank_" className="blue"><u>https://ahrefs.com/backlink-checker</u></a>
      </p>
      <p style={{ marginBottom: "20px" }}>
        La puissance du backlink Checker d’Ahrefs est qu’il vous permet d’<b>analyser les backlinks d’une page précise</b>.
        Par exemple, si un concurrent est meilleur que vous sur un mot-clé en particulier,
        vous pourrez analyser sa page et en retirer des informations capitales pour votre positionnement stratégique.
      </p>
      <h3>Combien coûte Ahref?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Tout comme SEMrush, Ahrefs propose un <b>essai gratuit</b>, cette fois de 14 jours, contre 7 pour SEmrush.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Une fois ce délai écoulé, voici les offres proposées par Ahrefs</u>:
      </p>
      <ul>
        <li>
          €89/mois
        </li>
        <li>
          €179/mois
        </li>
        <li>
          €369/mois
        </li>
        <li>
          €899/mois
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Chacune de ces offres vous donne accès à plus ou moins de fonctionnalités.
      </p>
      <h2 id="keywords-everywhere">Keywords Everywhere
      </h2>
      <img src={keywordEverywhere} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Keyword Everywhere" />
      <h3>Qu’est-ce que Keywords everywhere?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Keywords Everywhere est une extension Chrome vous permettant d’<b>afficher des recommandations de mots-clés</b> lors de vos recherches Google.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En effet, une fois une requête entrée sur le moteur de recherche de Google,
        Keywords Everywhere vous affichera tous les mots-clés existant pour cette requête.
        Cela vous permet en un coup d'œil de <b>détecter des opportunités de mots-clés</b> et de contenu pour votre site.
      </p>
      <h3>Comment utiliser Keywords Everywhere sur Chrome?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Pour installer Keywords Everywhere, vous pouvez vous rendre sur
        la <a href="https://chrome.google.com/webstore/detail/keywords-everywhere-keywo/hbapdpeemoojbophdfndmlgdhppljgmp?hl=en" alt="KW everywhere" target="blank_" rel="noreferrer nofollow" className="blue"><u>page de l’extension ici</u></a>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Grâce au plan payant de l’extension, vous aurez aussi directement accès au <b>volume de recherche mensuel</b> pour la requête que vous avez entré.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Notez que l’extension Chrome vous permet aussi d’analyser les mots-clés Youtube et Amazon.
      </p>
      <h3>Comment ne plus afficher Keywords Everywhere?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Pour ne plus afficher les recommandations de mots clés qui prennent parfois de la place, vous pouvez faire un clic droit sur l’extension,
        en haut à droite de votre navigateur, ensuite, cliquez sur “Gérer l’extension” et passer le switch en mode “off”.
        Faites la manoeuvre inverse pour activer l’extension à nouveau
      </p>
      <h2 id="gtmetrix">GTmetrix</h2>
      <img src={gtMetrix} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="GTmetrix test vitesse site internet" />
      <h3>Gtmetrix, c’est quoi?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        <a href="https://gtmetrix.com/" alt="GTmetrix" rel="nofollow noreferrer" target="blank_" className="blue"><u>GTmetrix</u></a>
        est un outil vous permettant de <b>mesurer la performance de votre site</b>.
        Il vous suffit d’entrer votre URL pour obtenir tout un tas d’informations très utiles pour améliorer votre site.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En effet, la performance et <b>la vitesse de votre site</b> sont un critère de référencement Google.
        Un site trop lent sera moins bien référencé car Google considérera que les utilisateurs ne seront pas satisfaits par l’expérience en arrivant dessus.
      </p>
      <h3>Tester la vitesse de son site
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Une fois l’analyse de vitesse lancée, GTmetrix va analyser votre site et vous fournir toutes les analyses de performance.
        En plus de cela, GTmetrix vous <b>explique chaque point indépendamment</b>.
        Cela vous permet d’améliorer le tout de votre côté pour ensuite repasser les tests et voir votre score de performance s’améliorer.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En cliquant sur l’onglet “Waterfall”, vous pourrez constater quels éléments de votre page ralentissent votre site.
        Si vous n’êtes pas développeur, pensez à faire part de cet outil à la personne chargée du développement car les informations fournies sont très précieuses.
      </p>
      <h3>Alternative à GTmetrix
      </h3>
      <p style={{ marginBottom: "20px" }}>
        GTmetrix est un excellent outil pour analyser les performances de son site, mais il existe des alternatives.
        La plus connue est certainement <b>Google PageSpeed Insights</b>, qui est un outil de test de vitesse produit par Google lui-même.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour utiliser Google PageSpeed Insights, vous pouvez vous rendre sur cette
        page: <a href="https://pagespeed.web.dev/" alt="Google Pagespeed" target="blank_" className="blue" rel="noreferrer nofollow"><u>https://pagespeed.web.dev/</u></a>.
        Ensuite, procédez comme avec GTmetrix pour analyser votre site.
      </p>
      <h2 id="1fr">1.FR</h2>
      <img src={unfr} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="1.fr optimisation SEO" />
      <h3>C’est quoi 1.FR?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        1.fr est un outil français d’<b>optimisation de texte SEO</b>.
        Il vous permet d’écrire des articles et de recevoir des suggestions de la part de leur outil.
        Par exemple, 1.fr vous recommandera d’ajouter tels ou tels mots pour améliorer le référencement de votre texte.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Attention cependant à bien utiliser cet outil. <b>Le SEO n’est pas du bourrage de mots-clés</b>.
        Prenez plutôt les suggestions de 1.fr comme des indications.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Par exemple, si pour un article sur les lapins, 1.fr vous suggère d’utiliser le mot “clapier”,
        cela veut surtout dire que généralement ce mot est utilisé par vos concurrents.
        Ainsi, il vous faudra en parler par exemple en intégrant une question relative à ce mot, “Comment choisir un clapier pour mon lapin”.
        Il ne s’agit en aucun cas de simplement placer le mot au milieu de rien, sans contexte ni pertinence.
      </p>
      <h3>Combien coûte 1.FR?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        1.fr offre une <b>analyse de texte gratuite</b>. Cependant pour accéder à leur outil d’optimisation de texte,
        ainsi qu’à leur extension chrome, il vous faudra débourser <b>60€ par mois</b>.
      </p>
      <h3>Comment utiliser 1.FR gratuitement?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez utiliser 1.fr gratuitement uniquement pour l’analyse de texte.
        Cette analyse vous donnera un score qui vous permettra de situer votre optimisation par rapport au mot clé ciblé.
      </p>
      <h2 id="keyword-sheeter">Keyword Sheeter
      </h2>
      <img src={keywordSheeter} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Keyword Sheeter" />
      <p style={{ marginBottom: "20px" }}>
        Keyword Sheeter est un outil peu connu mais assez puissant. Surtout pour les personnes possédant une boutique e-commerce.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En effet, il vous permet de générer tous les mots-clés sur base d’un mot de départ.
        Par exemple, disons que vous avez une collection de robes sur votre boutique et que vous souhaitez connaître toutes les demandes des utilisateurs par rapport à ce produit.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Entrez simplement le mot “robe” dans keyword sheeter et cliquez sur “Sheet Keywords” et
        l’outil vous proposera plein de suggestions de mots-clés pour cette collection.
      </p>
      <h2 id="alt-txt-tester">Alt text tester
      </h2>
      <img src={altTxttester} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Tester les Alt-textes des images extension chrome" />
      <p style={{ marginBottom: "20px" }}>
        Alt Text Tester est une extension Google Chrome qui vous permet de <b>visuellement afficher les textes alternatifs de vos images</b>.
        Les images qui n’ont pas de textes alternatifs seront entourées d’un cadre rouge. Idéal pour faire un checkup de vos articles avant de les publier.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous pouvez ajouter l’extension <a href="https://chrome.google.com/webstore/detail/alt-text-tester/koldhcllpbdfcdpfpbldbicbgddglodk" alt="alt text tester" rel="noreferrer nofollow" className="blue" target="blank_"><u></u></a>Alt Text Tester ici.
      </p>
      <h2 id="comment-faire-du-seo">Comment faire du SEO en {currentYear}?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Avoir les bons outils c’est génial pour améliorer sa productivité et la rapidité d’exécution de certaines tâches. Cependant, <b>cela ne remplace des compétences en SEO</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Le SEO, c’est avant tout savoir répondre à la demande de l’utilisateur pour ensuite optimiser le contenu dans l’optique de passer devant vos concurrents.
      </p>
      <p style={{ marginBottom: "20px" }}>
        La 01supply Academy vous propose un cours complet sur le SEO afin de vous former dans le référencement naturel.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Cliquez ici pour commander votre cours sur le référencement naturel: <a href="/academy/google-seo-course" alt="Formation au seo" target="blank_" className="blue"><u>SEO Mastery</u></a>.</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Le SEO est une compétence clé utilisée par toutes les entreprises de nos jours.
        Être visible sur Google relève de la crédibilité de l’entreprise.
        C’est pourquoi cette compétence a beaucoup de valeur et est cruciale pour tous les sites en ligne.
      </p>
    </div>
  );
};

export default Content;
