import { url } from "../api/url";
import { header } from "../api/header";

export const courseServices = {
  findOne: async ({ id, token = localStorage.getItem("tokenCreatosell") }) => {
    return await fetch(`${url}/course/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
      },
    });
  },
  createPayment: async ({ courseId, email, isStripePayment }) => {
    return await fetch(`${url}/course/payment`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        courseId,
        email,
        isStripePayment,
      }),
    });
  },
  validPayment: async (
    { paymentId, paypalPaymentId, stripePaymentId },
    affiliationId = localStorage.getItem("affiliationId")
  ) => {
    return await fetch(`${url}/course/payment/valid`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        paymentId,
        paypalPaymentId,
        stripePaymentId,
        affiliationId,
      }),
    });
  },
};
