import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function useNavigationHistory() {
  const history = useHistory();
  
  useEffect(() => {
    // Enregistrer la navigation dans l'historique
    const unlisten = history.listen((location) => {
    });
    
    // Retirer l'écouteur lorsque le composant se démonte
    return () => {
      unlisten();
    }
  }, [history]);
}

export default useNavigationHistory