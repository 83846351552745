import { url } from "../api/url";
import { header } from "../api/header";

export const resellerCoachingPayment = {
  createCustomer: async ({ coaching, email }) => {
    return await fetch(`${url}/reseller-coaching/customer/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        coaching,
        email,
      }),
    });
  },
  validPayment: async (
    { paymentMethodId, customerId, priceId, email, coaching },
    affiliationId = localStorage.getItem("affiliationId")
  ) => {
    return await fetch(`${url}/reseller-coaching/payment/valid`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        paymentMethodId,
        customerId,
        priceId,
        email,
        coaching,
        affiliationId,
      }),
    });
  },
  validOneTimePayment: async ({ stripePayment, email, coaching }) => {
    return await fetch(`${url}/reseller-coaching/payment/one-time-payment/valid`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        stripePayment,
        email,
        coaching,
      }),
    });
  },
};
