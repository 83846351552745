import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { Drawer, Input } from "antd";
import { useTranslation } from "react-i18next";
import { routes } from "../../../routes/index";

import Categories from "./Categories";
import RegisterModal from "../../modals/RegisterModal";
import { MarketMenu } from "../MarketMenu";

import filterIcon from "../../../images/icons/filter.svg";

const MarketMobileMenu = ({
  handleChangeCategory,
  windowSize,
  handleSearch,
  localSearch,
  setLocalSearch,
  setFilterMenuOn,
}) => {
  const [isStickyNav, setIsStickyNav] = useState(false);
  const [mobileMenuOn, setMobileMenuOn] = useState(false);
  const [popupLogin, setPopupLogin] = useState("");
  const { t, i18n } = useTranslation();

  const columnScrollingElement = document.getElementsByClassName("column-scrolling")?.[0];

  useEffect(() => {
    if (columnScrollingElement && !window.location.href.includes("suggestion")) {
      columnScrollingElement.onscroll = function (e) {
        if (columnScrollingElement?.scrollTop > 290) {
          setIsStickyNav(true);
        } else {
          setIsStickyNav(false);
        }
      };
    }
  }, [columnScrollingElement]);

  const selectAccountAction = (e, path) => {
    if (!localStorage.getItem("typeAccountCreatosell") && e) {
      e.stopPropagation();
      e.preventDefault();
      setPopupLogin(path);
    }
  };

  return (
    <div
      style={{ justifyContent: "space-between", padding: "0 20px" }}
      className={
        isStickyNav ? "menu-mobile-container sticky space-between" : "menu-mobile-container space-between"
      }
    >
      {popupLogin && <RegisterModal modalOn={popupLogin} setModal={setPopupLogin} />}
      <MenuOutlined style={{ fontSize: "40px", width: "auto" }} onClick={() => setMobileMenuOn(true)} />
      <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={() => setMobileMenuOn(false)}
        visible={mobileMenuOn}
      >
        <div
          className="column start mobile-categories"
          style={{
            width: "100%",
            height: "100vh",
            padding: "32px",
            paddingTop: "0",
          }}
        >
          <Categories handleChangeCategory={handleChangeCategory} setMobileMenuOn={setMobileMenuOn} />
          <p
            style={{ fontSize: "22px", marginLeft: "-5px", marginTop: "10px" }}
            className="title-bold montserrat-800"
          >
            {t("components.menu.2")}
          </p>
          <Link
            onClick={(e) => selectAccountAction(e, "/inventory")}
            to={"/inventory"}
            style={{ marginTop: "20px", width: "100%", cursor: "pointer" }}
            className="column"
          >
            <p style={{ fontSize: "16px" }} className="text">
              {t("dashboard")}
            </p>
          </Link>
          <Link
            onClick={(e) => selectAccountAction(e, "/messages")}
            to={routes.messages(i18n.language).path}
            style={{ marginTop: "20px", width: "100%", cursor: "pointer" }}
            className="column"
          >
            <p style={{ fontSize: "16px", marginTop: "2px" }} className="text">
              Messages
            </p>
          </Link>
          <Link
            onClick={(e) => selectAccountAction(e, "/my-orders")}
            to={"/my-orders"}
            style={{ marginTop: "20px", width: "100%", cursor: "pointer" }}
            className="column"
          >
            <p style={{ fontSize: "16px", marginTop: "2px" }} className="text">
              {t("components.header.24")}
            </p>
          </Link>
        </div>
      </Drawer>
      <Input
        value={localSearch}
        maxLength={100}
        style={{ width: "calc(100% - 150px)", height: "40px" }}
        className="input"
        id="search-product-input-header"
        placeholder={"Search for a product"}
        suffix={<SearchOutlined onClick={handleSearch} style={{ color: "rgb(129, 129, 129)" }} />}
        onChange={(e) => {
          setLocalSearch(e.target.value);
        }}
        onPressEnter={handleSearch}
      />
      <img
        onClick={() => setFilterMenuOn(true)}
        style={{ height: "30px" }}
        src={filterIcon}
        alt="filter icon"
      />
    </div>
  );
};

export default MarketMobileMenu;
