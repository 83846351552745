import { url } from "../api/url";
import { header } from "../api/header";
import { message } from "antd";

const productVariantsError = (product) => {
  let good = true;
  let load = false;
  if (product.variants?.length) {
    product.variants?.map((variant, i) => {
      let goodOptions = true;
      product.optionsVariants.map((option, index) => {
        if (!variant[`option${index + 1}`]) goodOptions = false;
      });
      if (!variant.image) {
        good = "image missing";
      } else if (!(variant.image && goodOptions && variant.price && variant.stock)) {
        good = "error";
      }
      if (i === product.variants.length - 1) load = true;
    });
    if (load) return good;
  } else return "";
};

const productError = (product, setLoad, t) => {
  const variantsError = productVariantsError(product);
  if (!product.name) {
    message.error(t('messages.needFill.name'));
    setLoad(false);
    return "name";
  } else if (!product.describe) {
    message.error(t('messages.needFill.description'));
    setLoad(false);
    return "name";
  } else if (variantsError === "error") {
    message.error(t('messages.needFill.badVariant'));
    setLoad(false);
    return "variants";
  } else if (variantsError === "image missing") {
    message.error(t('messages.needFill.variantsImage'));
    setLoad(false);
    return "variants";
  } else if (!product.variants.length && product.variantBool) {
    message.error(t('messages.needFill.variants'));
    setLoad(false);
    return "variants";
  } else if (!product.photos?.length) {
    message.error(t('messages.needFill.image'));
    setLoad(false);
    return "images";
  } else if (!product.origin) {
    message.error(t('messages.needFill.origin'));
    setLoad(false);
    return "shipping";
  } else if (product.shipping.length < 1) {
    message.error(t('messages.needFill.shippingMethod'));
    setLoad(false);
    return "shipping";
  } else if (!product.variants.length && !product.price) {
    message.error(t('messages.needFill.price'));
    setLoad(false);
    return "price";
  } else if (!product.variants.length && !product.stock) {
    message.error(t('messages.needFill.quantity'));
    setLoad(false);
    return "inventory";
  } else if (!product.variants.length && !product.processingTime) {
    message.error(t('messages.needFill.processingTime'));
    setLoad(false);
    return "shipping";
  }
};

export const createProduct = async (product, callback, setLoad, t) => {
  const idAccount = localStorage.getItem("idAccountCreatosell");
  const secretId = localStorage.getItem("secretAccountId01supply");
  const token = localStorage.getItem("tokenCreatosell");

  const error = productError(product, setLoad, t);
  if (error) {
    document.getElementById(error)?.scrollIntoView({
      behavior: "smooth",
    });
  } else {
    const res = await fetch(`${url}/product/create/${idAccount}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        product,
      }),
    });
    if (res) {
      callback(res);
    }
  }
};

export const updateProduct = async (product, callback, setLoad, t) => {
  const idAccount = localStorage.getItem("idAccountCreatosell");
  const secretId = localStorage.getItem("secretAccountId01supply");
  const token = localStorage.getItem("tokenCreatosell");

  const error = productError(product, setLoad, t);
  if (error) {
    document.getElementById(error)?.scrollIntoView({
      behavior: "smooth",
    });
  } else {
    const res = await fetch(`${url}/product/update/${product._id}/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        product,
      }),
    });
    if (res) {
      callback(res);
    }
  }
};