import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";

import PriceRules from "../../../components/reseller/settings/priceRules";

const ResellerTools = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <div className="page">
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={windowSize > 1150 ? { paddingTop: "0px" } : { paddingTop: "30px", paddingBottom: "150px" }}
          className="column-scrolling"
        >
          <div
            className="row-wrap"
            style={{ width: "100%", paddingTop: windowSize > 1150 && "110px", alignItems: "flex-start" }}
          >
            {windowSize > 1150 ? (
              <>
                <MenuSetting user={user} />
                <div className="header">
                  <div
                    className="row"
                    style={
                      windowSize > 1150
                        ? { width: "100%", marginBottom: "-30px", height: "30px" }
                        : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                    }
                  >
                    <h1 className="title-bold montserrat">{t("pricingRules")}</h1>
                  </div>
                  <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
                </div>
              </>
            ) : (
              <MobileHeader nameHeader={t("pricingRules")} location={"PRICING SETTINGS"} />
            )}
            <div
              style={{
                width: windowSize > 1150 ? "calc(100% - 350px)" : "100%",
                marginLeft: windowSize > 1150 && "30px",
              }}
              className="column"
            >
              <PriceRules user={user} setUser={setUser} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellerTools;
