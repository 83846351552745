import React, { useEffect, useState } from "react";
import { message, Pagination, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { url } from "../api/url";
import { header } from "../api/header";
import Menu from "../components/menu/FirstMenu";
import OrderCard from "../components/OrderCard";
import PopupOrder from "../components/reseller/PopupOrder";
import MobileMenu from "../components/menu/mobile/FirstMenu";
import MobileHeader from "../components/header/mobile/SimpleHeader";
import RedirectLogin from "../utils/RedirectLogin";
import ModalNeedShop from "../components/reseller/ModalNeedShopify";
import DeleteOrder from "../components/modals/CancelOrder";
import ModalFilter from "../components/modals/FilterOrders";
import UnverifiedSupplierAccountModal from "../components/modals/UnverifiedSupplierAccountModal";
import Load from "../utils/Load";
import { checkAccountCompleted } from "../utils/CheckAccountCompleted";
import ResellerSetupProgress from "../components/reseller/SetupProgress";

const Orders = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [orders, setOrders] = useState([]);
  const [stockOrders, setStockOrders] = useState([]);
  const [passOrder, setPassOrder] = useState({});
  const [imageSelect, setImageSelect] = useState("");
  const [productOrder, setProductOrder] = useState({});
  const [sectionHeader, setSectionHeader] = useState(
    localStorage.getItem("typeAccountCreatosell") === "seller" ? "to pay" : "paid"
  );
  const [user, setUser] = useState({});
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [modalNeedShop, setModalNeedShop] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState({});
  const [localProduct, setLocalProduct] = useState({});
  const [ordersPage, setOrdersPage] = useState(1);
  const [ordersFilter, setOrdersFilter] = useState("all");
  const [noOrders, setNoOrders] = useState(false);
  const [load, setLoad] = useState(false);
  const [feesPaid, setFeesPaid] = useState(false);

  const [unverifiedSupplier, setUnverifiedSupplier] = useState(false);

  const { t } = useTranslation();

  const ordersPerPage = 20;

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getOrders();
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();

    if (window.location.href.includes("fees=") && sessionStorage.getItem("orderToPay01supply")) setLoad(true);
  }, []);

  useEffect(() => {
    if (orders && orders.length > 0) {
      if (
        window.location.href.includes("fees") &&
        sessionStorage.getItem("orderToPay01supply") &&
        !feesPaid
      ) {
        getShopifyFees();
      }
    }
  }, [orders]);

  useEffect(() => {
    if (ordersFilter && stockOrders.length) {
      const ordersGet = stockOrders.filter(
        (order) => order.status === ordersFilter || ordersFilter === "all"
      );
      if (!ordersGet.length) {
        setNoOrders(true);
      } else setOrders(ordersGet);

      if (orders && orders.length > 0) {
        if (
          window.location.href.includes("fees") &&
          sessionStorage.getItem("orderToPay01supply") &&
          !feesPaid
        ) {
          getShopifyFees();
        }
      }
    } else setOrders(stockOrders);
  }, [ordersFilter, stockOrders]);

  const getShopifyFees = async () => {
    let sessionData = sessionStorage.getItem("orderToPay01supply");
    sessionData = JSON.parse(sessionData);
    const href = window.location.href;
    var currentUrl = new URL(href);
    const feesId = currentUrl.searchParams.get("fees");
    const secretFeesId = currentUrl.searchParams.get("secret");

    let variantSelect = {};
    let shippingSelect = {};
    if (sessionData) {
      if (sessionData && sessionData.variantSelect) variantSelect = sessionData.variantSelect;
      if (sessionData && sessionData.shippingSelect) shippingSelect = sessionData.shippingSelect;
    }

    if (feesId && secretFeesId) {
      if (sessionData.order) {
        const res = await fetch(
          `${url}/order/shopify-fees/${sessionData.order._id}/${feesId}/${secretFeesId}`,
          {
            method: "PUT",
            credentials: "include",
            headers: {
              ...header,
              authorization: token,
            },
            body: JSON.stringify({
              shippingSelect,
              variantSelect,
            }),
          }
        );
        if (res.status === 200) {
          setLoad(false);

          let newOrder = { ...sessionData.order };
          setImageSelect(sessionData.image);

          newOrder.paid = {
            ...newOrder.paid,
            shopify_secret_fees_id: secretFeesId,
            variant_select: variantSelect,
            shipping_select: shippingSelect,
          };

          let newOrders = [...orders];
          orders.map((order, i) => {
            if (order._id === newOrder._id) {
              newOrders[i] = newOrder;
              setOrders([...newOrders]);
            }
          });

          setPassOrder({ ...newOrder });
          setFeesPaid(true);
        } else setLoad(false);
      } else {
        sessionStorage.setItem("orderToPay01supply", "");

        setLoad(false);
      }
    } else {
      message.error(t("messages.feesNotPaid"));

      setLoad(false);
    }
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson.data?.type === "creater" && checkAccountCompleted(resJson.data) === false) {
        setUnverifiedSupplier(true);
      }
      setUser(resJson.data);
    }
  };

  const sortByDates = (orders) => {
    return orders.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.date) - new Date(a.date);
    });
  };

  const getOrders = async () => {
    if (typeAccount === "seller") {
      const res = await fetch(`${url}/order/find-by-seller/${idAccount}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      const resJson = await res.json();
      if (resJson.orders && resJson.orders.length) {
        let ordersRes = resJson.orders;

        setStockOrders(sortByDates(ordersRes));
      } else setNoOrders(true);
    } else if (typeAccount === "creater") {
      const res = await fetch(`${url}/order/find-by-creater/${idAccount}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      const resJson = await res.json();
      if (resJson.orders && resJson.orders.length) {
        let ordersRes = resJson.orders;
        ordersRes = sortByDates(ordersRes);
        setStockOrders(ordersRes);
      } else setNoOrders(true);
    }
  };

  return (
    <div className="page">
      <ResellerSetupProgress user={user} />
      <UnverifiedSupplierAccountModal
        user={user}
        setModalOn={setUnverifiedSupplier}
        modalOn={unverifiedSupplier}
      />
      {load && <Load />}
      <ModalNeedShop state={modalNeedShop} setState={setModalNeedShop} />
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? (
          <Menu location={"orders"} />
        ) : (
          <MobileMenu
            load={orders && orders.length > 0 ? true : false}
            datas={orders}
            setDatas={setOrders}
            attribut="shopify_order_number"
          />
        )}
        <div
          style={windowSize > 1150 ? { paddingTop: "120px" } : { paddingTop: "0px", paddingBottom: "300px" }}
          className="column-scrolling"
        >
          {true ? (
            <div className="header">
              <div
                className="row"
                style={
                  windowSize > 1150
                    ? { width: "100%", marginBottom: "-30px" }
                    : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                }
              >
                <h1 className="title-bold montserrat">{t("orders").toUpperCase()} </h1>
                <ModalFilter
                  style={{ position: "absolute", right: "10vw", bottom: "15px" }}
                  ordersFilter={ordersFilter}
                  setOrdersFilter={setOrdersFilter}
                />
              </div>
              <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
            </div>
          ) : (
            <MobileHeader
              load={orders && orders.length > 0 ? true : false}
              location={"orders"}
              setSectionHeader={setSectionHeader}
              sectionHeader={sectionHeader}
              nameHeader="ORDERS"
            />
          )}
          {orders && orders.length > 0 ? (
            orders.map((order, index) => {
              return (
                index < ordersPage * ordersPerPage &&
                (index > ordersPage * ordersPerPage) - ordersPerPage && (
                  <OrderCard
                    setLocalProductPopup={setLocalProduct}
                    setDeleteOrder={setDeleteOrder}
                    setModalNeedShop={setModalNeedShop}
                    windowSize={windowSize}
                    key={order._id + order.name + order.product._id + sectionHeader}
                    setProductOrder={setProductOrder}
                    product={order.product}
                    setImageSelect={setImageSelect}
                    setPassOrder={setPassOrder}
                    typeAccount={typeAccount}
                    order={order}
                    user={user}
                    orders={orders}
                    setOrders={setOrders}
                  />
                )
              );
            })
          ) : noOrders ? (
            <p style={{ width: "100%", textAlign: "center", marginTop: "30px" }} className="title-roboto">
              {t("ordersPage.noOrders")}
            </p>
          ) : (
            <React.Fragment>
              <Skeleton
                style={{ marginTop: "80px" }}
                className="image-product margin-top-landing"
                loading={true}
                active
              />
              <Skeleton className="image-product" loading={true} active />
            </React.Fragment>
          )}

          <div className="row" style={{ width: "95%", justifyContent: "center" }}>
            {orders.length > ordersPerPage && (
              <Pagination
                showSizeChanger={false}
                style={{ marginBottom: "50px", marginTop: "0px" }}
                onChange={(page) => {
                  setOrdersPage(page);
                }}
                defaultCurrent={1}
                total={stockOrders.length}
                pageSize={ordersPerPage}
              />
            )}
          </div>

          {deleteOrder && deleteOrder.product && (
            <DeleteOrder
              order={deleteOrder}
              user={user}
              token={token}
              url={url}
              header={header}
              setDeleteOrder={setDeleteOrder}
              setOrders={setOrders}
              orders={orders}
            />
          )}
          {passOrder && passOrder.customer && typeAccount === "seller" && localProduct.name && (
            <PopupOrder
              localProduct={localProduct}
              sectionHeader={sectionHeader}
              windowSize={windowSize}
              user={user}
              orders={orders}
              setOrders={setOrders}
              url={url}
              header={header}
              token={token}
              product={productOrder}
              imageSelect={imageSelect}
              setPassOrder={setPassOrder}
              order={passOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
