import React, {useState, useEffect} from 'react'
import { Table } from 'antd';

const Tab = ({affiliation, type, windowSize}) => {
    const [datas, setDatas] = useState([])

    const columns = [
      {
        title: 'User',
        dataIndex: 'user',
      },
      {
        title: 'Paid date',
        dataIndex: 'date',
      },
      {
        title: 'Commission',
        dataIndex: 'commission'
      },
      {
        title: 'Earned',
        dataIndex: 'earned'
      },
    ];

    useEffect(() => {
        if(affiliation.commission_history){
            let index = 0
            const newData = affiliation.commission_history.map((com, i) => {
                if(com.type_account === type){
                    index+=1
                    return({
                        key: (index+1).toString(),
                        user: com.user_name,
                        date: com.date,
                        commission: com.commission_percent+"%",
                        earned: "$"+com.earned
                    })
                }
            })
            setDatas(newData.filter(data => data && data.key))
        }
    }, [])

    return(
        <Table  scroll={windowSize > 1150 ? { y: '300px', x: '85%' } : { y: '500px', x: '800px' }} pagination={false}
        style={windowSize > 1150 ? {width: '86%', marginTop: '30px', height: '400px'} : {width: '100%', marginTop: '30px', height: '500px'}} columns={columns} dataSource={datas} />
    )
}

export default Tab