export const articles = [
  {
    id: "1",
    title: "What is 01supply? (for Suppliers)",
    description:
      "You are an independant product creator, crafing jewels, decoration or clothes? Discover 01supply, the marketplace to make a living out of your passion.",
    imagePath: "articlesImg/article1.png",
    pathname: "/what-is-01supply-for-supplier",
    tags: ["MARKETPLACE"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "2",
    title: "What is 01supply? (for Resellers)",
    description:
      "Discover 01supply, the B2B marketplace to find and work with dropshipping suppliers selling handmade, quality and high ticket products.",
    imagePath: "articlesImg/article12.png",
    pathname: "/what-is-01supply",
    tags: ["MARKETPLACE"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "3",
    title: "How to Get More Sales on Etsy ?",
    description:
      "How to Get More SALES on Etsy ? Increase your sales on Etsy. This eBook helps you build your Etsy business and grow quickly thanks to 5 golden strategies.",
    imagePath: "articlesImg/article11.png",
    pathname: "/how-to-get-more-sales-on-etsy",
    tags: ["MARKETPLACE", "SEO"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "4",
    title: "How does 01supply work?",
    description:
      "Learn how to use 01supply by referencing your products on our marketplace for thousands of resellers around the world. Start your journey now!",
    imagePath: "articlesImg/article10.png",
    pathname: "/how-work-01supply",
    tags: ["MARKETPLACE"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "5",
    title: "The Great Story of Alex",
    description:
      "Alex is a 01supply supplier. She is crafting her own jewels and want to make a living out of it. Learn how she went from struggling with bills to company CEO.",
    imagePath: "articlesImg/article9.png",
    pathname: "/become-supplier",
    tags: ["MARKETPLACE"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "6",
    title: "How to set up my 01supply account ?",
    description:
      "Find out how to become a 01 supply supplier to work with resellers and boost sales. And loearn how to setup your 01supply account within 10 minutes.",
    imagePath: "articlesImg/article8.png",
    pathname: "/how-setup-01supply-account",
    tags: ["MARKETPLACE"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "7",
    title: "Import dropshipping Etsy products to Shopify",
    description:
      "Learn how to import Etsy products to your Shopify dropshipping store thanks to 01supply and its chrome extension. You can do this for free!",
    imagePath: "articlesImg/article7.png",
    pathname: "/how-to-import-etsy-products-to-shopify",
    tags: ["DROPSHIPPING"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "8",
    title: "How to Find Dropshipping Viking Suppliers?",
    description:
      "Here you can find local, handmade and eco-friendly Dropshipping Viking Suppliers. Providing home decoration, axes, ... Everything in the viking niche",
    imagePath: "articlesImg/article6.png",
    pathname: "/dropshipping-viking-suppliers",
    tags: ["PARTNERSHIP", "DROPSHIPPING"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "9",
    title: "Where to sell handmade items besides Etsy?",
    description:
      "Learn how to sell your handmade items besides Etsy with 3 Etsy Alternatives. Suppliers who sell on two marketplaces earn 195% more revenue, research says.",
    imagePath: "articlesImg/article5.png",
    pathname: "/supplier/sell-handmade-items-besides-etsy",
    tags: ["MARKETPLACE"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "10",
    title: "Find jewelry suppliers for dropshipping",
    description:
      "Find jewelry suppliers for your dropshipping business and easily import their products to your online store (Shopify, Woocomerce or any other platform)",
    imagePath: "articlesImg/article4.png",
    pathname: "/reseller/jewelry-supplier-dropshipping",
    tags: ["PARTNERSHIP", "DROPSHIPPING"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "11",
    title: "Find European suppliers for dropshipping",
    description:
      "Find European Suppliers for your Dropshipping online store. We teach you how to work with them and automate the process especially if you are on Shopify.",
    imagePath: "articlesImg/article3.png",
    pathname: "/reseller/find-european-supplier-dropshipping",
    tags: ["PARTNERSHIP", "DROPSHIPPING"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "12",
    title: "Dropshipping suppliers - Email template",
    description:
      "Learn how to Contact Dropshipping Suppliers. What email template should you send and what information do you need to provide.",
    imagePath: "articlesImg/email_template_supplier.jpg",
    pathname: "/reseller/dropshipping-supplier-email",
    tags: ["PARTNERSHIP", "DROPSHIPPING"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "13",
    title: "What is the best Size for Product images?",
    description:
      "What are the best sizes for your product images? Learn the different image formats and recommended practices to optimize effectively for your online store.",
    imagePath: "articlesImg/What-is-the-best-size-for-product-images.jpg",
    pathname: "/best-size-product-images",
    tags: ["PHOTOGRAPHY", "BRANDING"],
    date: "23 september 2022",
    lang: "en",
  },
  {
    id: "14",
    title: "How much does product Photography Cost?",
    description:
      "How much does product photography cost? Should you invest in equipment to do it yourself or should you pay a professional studio? Learn everything here.",
    imagePath: "articlesImg/product-photography-cost.jpg",
    pathname: "/product-photography-cost",
    tags: ["PHOTOGRAPHY"],
    date: "26 september 2022",
    lang: "en",
  },
  {
    id: "15",
    title: "How to shoot Clothing product Photography?",
    description:
      "Learn How to Shoot clothing product photography for your e-commerce store or your social media page like Instagram. How to make it look good and clean.",
    imagePath: "articlesImg/clothing-photography.jpg",
    pathname: "/clothing-product-photography",
    tags: ["PHOTOGRAPHY"],
    date: "29 september 2022",
    lang: "en",
  },
  {
    id: "16",
    title: "How to shoot product Videos at home?",
    description:
      "Do you want to film your product at home on a small budget? Learn now how to shoot product videos at home in this article. It even works with a smartphone.",
    imagePath: "articlesImg/How-to-shoot-product-videos-at-home.jpg",
    pathname: "/product-video-home",
    tags: ["PHOTOGRAPHY"],
    date: "01 october 2022",
    lang: "en",
  },
  {
    id: "17",
    title: "How to Light for product Photography?",
    description:
      "Lighting is crucial when it comes to product photography. Bad lighting can make a product look cheap. In this article learn lighting techniques like a pro.",
    imagePath: "articlesImg/How-to-light-for-product-photography.jpg",
    pathname: "/lighting-product-photography",
    tags: ["PHOTOGRAPHY"],
    date: "05 october 2022",
    lang: "en",
  },
  {
    id: "18",
    title: "How to shoot 360 product Photography?",
    description:
      "360 product photography is new and customers love it. With spin photography, users interact with your products and you stand out from your competitors.",
    imagePath: "articlesImg/How-to-shoot-360-product-photography.jpg",
    pathname: "/360-product-photography",
    tags: ["PHOTOGRAPHY"],
    date: "05 october 2022",
    lang: "en",
  },
  {
    id: "19",
    title: "How to optimize an SEO Product Description?",
    description:
      "Learn how you can Optimize your SEO on Product Descriptions now. SEO is essential for consistent and free traffic, ultimately bringing more profit.",
    imagePath: "articlesImg/How-to-optimize-an-SEO-product-description.jpg",
    pathname: "/optimize-seo-product-description",
    tags: ["SEO"],
    date: "09 october 2022",
    lang: "en",
  },
  {
    id: "20",
    title: "What are Meta descriptions & titles?",
    description:
      "Learn more about Meta Titles and Meta Descriptions to optimize SEO on your website. We give you examples and tutorials on how to add them to your website.",
    imagePath: "articlesImg/What-are-meta-descriptions-and-meta-tiles.jpg",
    pathname: "/meta-title-meta-description-examples",
    tags: ["SEO"],
    date: "11 october 2022",
    lang: "en",
  },
  {
    id: "21",
    title: "Pourquoi faire du SEO sur votre site?",
    description:
      "Vous vous demandez peut-être comment améliorer la visibilité de votre site web? Dans cet article nous allons voir Pourquoi vous devriez Faire du SEO.",
    imagePath: "articlesImg/pourquoi-faire-du-seo.jpg",
    pathname: "/pourquoi-faire-seo",
    tags: ["SEO"],
    date: "21 october 2022",
    lang: "fr",
  },
  {
    id: "22",
    title: "Quels sont les meilleurs outils SEO?",
    description:
      "Pour avoir un bon référencement naturel, il est important de s'équiper de bons Outils SEO. Dans cet article nous verrons les 10 meilleurs à utiliser.",
    imagePath: "articlesImg/meilleurs-outils-SEO.jpg",
    pathname: "/outils-seo",
    tags: ["SEO"],
    date: "29 october 2022",
    lang: "fr",
  },
  {
    id: "23",
    title: "Dropshipping coaching programs",
    description:
      "Dropshipping coaching programs are very important for beginners as it helps them avoid most common mistakes and save thousands of dollars in testing.",
    imagePath: "articlesImg/dropshipping-coaching.jpg",
    pathname: "/dropshipping-coaching",
    tags: ["Dropshipping"],
    date: "08 november 2022",
    lang: "en",
  },
  {
    id: "24",
    title: "Ecommerce coaching programs",
    description:
      "Learn how to find a great e-commerce coach that gives you a full mentorship experience from the start to your first sales and to achieve your goals.",
    imagePath: "articlesImg/ecommerce-coaching.jpg",
    pathname: "/e-commerce-coaching",
    tags: ["Dropshipping"],
    date: "14 november 2022",
    lang: "en",
  },
  {
    id: "25",
    title: "Comment importer des produits Etsy sur Shopify?",
    description:
      "Découvrez comment importer des produits depuis Etsy sur votre boutique Shopify pour faire du dropshipping avec des fournisseurs Etsy de qualité et locaux.",
    imagePath: "articlesImg/importer-des-produits-etsy-sur-shopify-dropshipping.jpg",
    pathname: "/importation-etsy-shopify",
    tags: ["Dropshipping", "Partnership"],
    date: "30 november 2022",
    lang: "fr",
  },
  {
    id: "26",
    title: "Faire du dropshipping avec des produits Etsy",
    description:
      "Découvrez comment faire du dropshipping avec des produits Etsy. Etsy est une excellent alternative à Aliexpress pour des produits de qualité et locaux.",
    imagePath: "articlesImg/dropshipping-avec-des-produits-etsy.jpg",
    pathname: "/dropshipping-produits-etsy",
    tags: ["Dropshipping", "Partnership"],
    date: "30 november 2022",
    lang: "fr",
  },
  {
    id: "27",
    title: "Trouver des fournisseurs en dropshipping français",
    description:
      "Vous faites du dropshipping? Découvrez comment travailler avec des fournisseurs français afin de proposer des produits de qualité et une livraison rapide.",
    imagePath: "articlesImg/fournisseur-francais-dropshipping.jpg",
    pathname: "/fournisseur-francais-dropshipping",
    tags: ["Dropshipping", "Partnership"],
    date: "16 december 2022",
    lang: "fr",
  },
  {
    id: "28",
    title: "Trouver des fournisseurs dropshipping en Europe",
    description:
      "Découvrez comment trouver et travailler avec des fournisseurs européens en dropshipping pour proposer des produits locaux et de qualité sur votre boutique.",
    imagePath: "articlesImg/fournisseur-europe-dropshipping.jpg",
    pathname: "/fournisseur-europe-dropshipping",
    tags: ["Dropshipping", "Partnership"],
    date: "29 january 2023",
    lang: "fr",
  },
  {
    id: "29",
    title: "Liste fournisseurs dropshipping vêtements",
    description:
      "Trouvez des fournisseurs en dropshipping pour des vêtements, pour femmes, hommes et enfants. Importez les en un clic sur votre boutique en ligne avec 01supply.",
    imagePath: "articlesImg/fournisseur-vetement-dropshipping.jpg",
    pathname: "/fournisseurs-dropshipping-vetements",
    tags: ["Dropshipping", "Partnership"],
    date: "17 february 2023",
    lang: "fr",
  },
  {
    id: "30",
    title: "Où trouver des fournisseurs en dropshipping?",
    description:
      "Découvrez où trouver des fournisseurs dropshipping pour votre boutique en ligne. Des fournisseurs proposant des produits uniques et une livraison rapide.",
    imagePath: "articlesImg/ou-trouver-fournisseur-dropshipping.jpg",
    pathname: "/ou-trouver-fournisseur-dropshipping",
    tags: ["Dropshipping", "Partnership"],
    date: "28 february 2023",
    lang: "fr",
  },
  {
    id: "31",
    title: "Comment trouver des fournisseurs en dropshipping ?",
    description:
      "Découvrez comment trouver des fournisseurs en dropshipping, où les trouver, comment travailler avec eux, négocier les prix et bien d'autres astuces.",
    imagePath: "articlesImg/comment-trouver-un-fournisseur-en-dropshipping.jpg",
    pathname: "/comment-trouver-fournisseur-dropshipping",
    tags: ["Dropshipping", "Partnership"],
    date: "12 march 2023",
    lang: "fr",
  },
  {
    id: "32",
    title: "Créez votre boutique en dropshipping avec Dropizi",
    description:
      "Découvrez comment créer votre boutique en ligne de dropshipping avec Dropizi. Le premier CMS de création de site e-commerce en France pour le dropshipping.",
    imagePath: "articlesImg/presentation-dropizi.jpg",
    pathname: "/creer-boutique-dropizi",
    tags: ["Dropshipping"],
    date: "18 april 2023",
    lang: "fr",
  },
  {
    id: "33",
    title: "Liste des fournisseurs de dropshipping en France",
    description:
      "Découvrez notre sélection des meilleurs fournisseurs de dropshipping en France. Gagnez des dizaines d'heures grace à notre liste de fournisseurs de confiance.",
    imagePath: "articlesImg/liste-fournisseurs-dropshipping-france.jpg",
    pathname: "/fournisseurs-france-liste",
    tags: ["Dropshipping", "Partnership"],
    date: "26 april 2023",
    lang: "fr",
  },
];

export const tagList = [
  "Branding",
  "Advertising",
  "Photography",
  "SEO",
  "Fulfillment and Shipping",
  "Social Media Marketing",
  "Partnership",
  "Dropshipping",
  "Marketplace",
];
