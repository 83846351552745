import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MenuAffiliation = ({ payouts, setPayouts }) => {
  const [location] = useState(window.location.pathname.replace("/", ""));

  const { t } = useTranslation();

  return (
    <div style={{ borderRadius: "6px", border: "1px solid grey", width: "max-content" }} className="row">
      <Link
        onClick={() => setPayouts(false)}
        to="/affiliation/referrals"
        style={{ borderRight: "1px solid grey" }}
        className={!payouts ? "title-300 column center twoChoice-on" : "title-300 column center twoChoice"}
      >
        {t("components.menu.36")}
      </Link>
      <Link
        onClick={() => setPayouts(true)}
        id="demo-step-7"
        to="/affiliation/payouts"
        style={{ width: "70px", padding: "10px", cursor: "pointer" }}
        className={payouts ? "title-300 column center twoChoice-on" : "title-300 column center twoChoice"}
      >
        {t("components.menu.37")}
      </Link>
    </div>
  );
};

export default MenuAffiliation;
