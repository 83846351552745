import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { url } from "../../../../api/url";
import { header } from "../../../../api/header";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { message } from "antd";

import { Modal } from "../../../../wrappers";

import Plan2Resume from "../paidResume/plan2";
import Plan3Resume from "../paidResume/plan3";
import Plan4Resume from "../paidResume/plan4";

import Load from "../../../../utils/Load";

import { klaviyoServices } from "../../../../services/klaviyo";

const CheckoutForm = ({
  user,
  typePlan,
  setTypePlan,
  setUser,
  setModal,
  setCancelSub,
  isYearly,
  setIsYearly,
}) => {
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [email] = useState(user.email);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(63, 63, 63)",
        width: "100%",
        color: "rgb(63, 63, 63)",
        fontWeight: 600,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "rgb(63, 63, 63)" },
        "::placeholder": { color: "rgb(63, 63, 63)" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  function validateEmail(email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  let priceId = "";
  if (typePlan === "basic") {
    if (isYearly) {
      priceId = process.env.REACT_APP_STRIPE_BASIC_PRODUCT_PRICE_YEARLY;
    } else {
      priceId = process.env.REACT_APP_STRIPE_BASIC_PRODUCT_PRICE;
    }
  } else if (typePlan === "advanced") {
    if (isYearly) {
      priceId = process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_PRICE_YEARLY;
    } else {
      priceId = process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_PRICE;
    }
  } else if (typePlan === "premium") {
    if (isYearly) {
      priceId = process.env.REACT_APP_STRIPE_PREMIUM_PRODUCT_PRICE_YEARLY;
    } else {
      priceId = process.env.REACT_APP_STRIPE_PREMIUM_PRODUCT_PRICE;
    }
  }

  const resumePlan =
    typePlan === "basic" ? (
      <Plan2Resume isYearly={isYearly} setIsYearly={setIsYearly} />
    ) : typePlan === "advanced" ? (
      <Plan3Resume isYearly={isYearly} setIsYearly={setIsYearly} />
    ) : (
      <Plan4Resume isYearly={isYearly} setIsYearly={setIsYearly} />
    );

  const subscription = async (event) => {
    if (!validateEmail(email)) {
      setError("Please enter a correct email address");
      setLoad(false);
    } else {
      setLoad(true);
      fetch(`${url}/user/stripe/create-customer`, {
        method: "post",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((response) => {
          if (!response) {
            setError("error payment, retry please");
            setLoad(false);
          } else return response.json();
        })
        .then((result) => {
          // result.customer.id is used to map back to the customer object
          // result.setupIntent.client_secret is used to create the payment method
          if (result && priceId)
            createPaymentMethod(elements.getElement(CardElement), result.customer.id, priceId);
          else {
            message.error(t("messages.paymentError"));
            setLoad(false);
          }
        });
    }
  };

  function createPaymentMethod(cardElement, customerId, priceId) {
    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          setError("error payment, retry please");
          setLoad(false);
        } else {
          createSubscription({
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            priceId: priceId,
          });
        }
      });
  }

  async function createSubscription({ customerId, paymentMethodId, priceId }) {
    const resSub = await fetch(`${url}/user/stripe/create-subscription/${user._id}/${secretId}`, {
      method: "post",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        typePlan,
        user,
        isYearly,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          setError("error payment, retry please");
          setLoad(false);
          throw result;
        } else if (result.requires_action) {
          const { client_secret } = result.payment_intent;
          const confirmedPayment = await stripe.confirmCardPayment(client_secret);

          if (confirmedPayment.error) {
            setError("error payment, retry please");
            setLoad(false);
            throw confirmedPayment.error;
          } else if (confirmedPayment.paymentIntent.status === "succeeded") {
            // The payment has been successfully processed
            // Continue processing the subscription as usual...
            const res = await fetch(`${url}/sendgrid/template-email`, {
              method: "POST",
              credentials: "include",
              headers: header,
              body: JSON.stringify({
                emailToSend: user.email.toLowerCase(),
                templateId: "d-647a78a4b8da4dc7b7f2d84e2a3a637d",
                templateData: {
                  previousPlan: user.plan.type,
                  newPlan: typePlan,
                },
              }),
            });
            klaviyoServices.checkStatus();
            message.success(t("messages.subscribeDone", { typePlan }));
            setUser({ ...user, plan: { ...user.plan, type: typePlan, isYearly } });
            setTypePlan("");
            setModal(false);
            setCancelSub("");
            window.location.reload();
          }
        } else {
          const res = await fetch(`${url}/sendgrid/template-email`, {
            method: "POST",
            credentials: "include",
            headers: header,
            body: JSON.stringify({
              emailToSend: user.email.toLowerCase(),
              templateId: "d-647a78a4b8da4dc7b7f2d84e2a3a637d",
              templateData: {
                previousPlan: user.plan.type,
                newPlan: typePlan,
              },
            }),
          });
          klaviyoServices.checkStatus();
          message.success(t("messages.subscribeDone", { typePlan }));
          setUser({ ...user, plan: { ...user.plan, type: typePlan, isYearly } });
          setTypePlan("");
          setModal(false);
          setCancelSub("");
          window.location.reload();
        }
      })
      .catch((error) => {
        setError("error payment, retry please");
      });
  }

  return (
    <Modal
      className="modal-1000"
      title={`Payment`}
      visible={true}
      onCancel={() => setModal(false)}
      footer={[]}
    >
      <div className="column" style={{ alignItems: "center" }}>
        {resumePlan}
        {load && <Load />}

        {error && !load && (
          <p style={{ fontSize: "15px", color: "red", maxWidth: "90%" }} className="errorPay">
            {error}
          </p>
        )}
        <div className="input-pricing-course-payment">
          <CardElement options={CARD_OPTIONS} />
        </div>
        <button
          className="button big orange-back"
          style={{ marginLeft: "0", marginTop: "20px", maxWidth: "500px", width: "100%" }}
          onClick={() => {
            if (!load) {
              setLoad(true);
              subscription();
            }
          }}
          type="submit"
        >
          {t("subscribe")}
        </button>
      </div>
    </Modal>
  );
};

export default CheckoutForm;
