import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Progress, Switch, message, Select } from "antd";

import { limitedFeatureUsageServices } from "../../../../services/limitedFeatureUsage";
import { RESELLER_TRANSLATION_QUOTA } from "../../../../constants";
import { updateUser } from "../../../../services/updateUser";
import { DEEPL_COUNTRIES } from "../../../../data/Deepl_countries";

import paidFeatureImg from "../../../../images/icons/paid_feature.svg";
import { routes } from "../../../../routes";

import "./style.scss";

function firstDayOfNextMonth() {
  // Obtenez la date actuelle
  let date = new Date();

  // Réglez la date sur le mois prochain
  date.setMonth(date.getMonth() + 1);

  // Réglez la date sur le premier jour du mois
  date.setDate(1);

  // Obtenez le jour et le mois sous la forme "jj/mm"
  let jour = ("0" + date.getDate()).slice(-2);
  let mois = ("0" + (date.getMonth() + 1)).slice(-2);

  return jour + "/" + mois;
}

const TranslationFeature = ({ user, setUser, isImportPage }) => {
  const [userLimitedFeatureUsage, setUserLimitedFeatureUsage] = useState(undefined);
  const [userQuota, setUserQuota] = useState(0);
  const [userMonthlyUsage, setUserMonthlyUsage] = useState(0);
  const [isQuotaSet, setIsQuotaSet] = useState(false);
  const [isAccessibleFeature, setIsAccessibleFeature] = useState(false);
  const [isSwitchTranslateAnabled, setIsSwitchTranslateAnabled] = useState(
    user.retailPrice?.handlePush?.translateLang || false
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setIsAccessibleFeature(user.plan?.type === "advanced" || user.plan?.type === "premium");
    if (parseInt(userMonthlyUsage) === parseInt(userQuota)) {
      setIsSwitchTranslateAnabled(false);
    }
  }, [user]);

  const handleUpdateSetting = ({ retailPrice }) => {
    setUser({ ...user, retailPrice });
    if (!isImportPage) {
      updateUser({ user: { retailPrice } }).then((res) => {
        if (res.status === 200) {
          message.success(t("messages.successfullyUpdated"));
        } else {
          message.error(t("messages.error"));
        }
      });
    }
  };

  useEffect(() => {
    limitedFeatureUsageServices
      .get()
      .then((res) => (res.status === 200 ? res.json() : undefined))
      .then((res) => setUserLimitedFeatureUsage(res?.data || {}));
  }, []);

  useEffect(() => {
    if (userLimitedFeatureUsage && user._id && !isQuotaSet) {
      setIsQuotaSet(true);
      findUserLimit();
      findUserMonthlyUsage();
    }
  }, [userLimitedFeatureUsage, user]);

  const findUserLimit = () => {
    const userSubscription = user?.plan?.type;
    const quota = RESELLER_TRANSLATION_QUOTA[userSubscription];
    setUserQuota(quota || 0);
  };

  const findUserMonthlyUsage = () => {
    if (userLimitedFeatureUsage?.translations?.length) {
      // Obtenez le mois et l'année actuels
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth(); // Note: getMonth() renvoie un indice de mois basé sur 0, où 0 est janvier.

      for (let i = 0; i < userLimitedFeatureUsage.translations.length; i++) {
        const entryDate = new Date(userLimitedFeatureUsage.translations[i].monthlyDate);
        if (entryDate.getFullYear() === currentYear && entryDate.getMonth() === currentMonth) {
          setUserMonthlyUsage(userLimitedFeatureUsage.translations[i]?.usageCount);
        }
      }
    }
  };

  return (
    <div style={{ marginLeft: "0", marginTop: "40px", position: "relative" }} className="card width-100">
      {!isAccessibleFeature && (
        <Link className="fixed-paid-feature" to={routes.resellerPricing(i18n.language).path}>
          <img style={{ height: "30px" }} src={paidFeatureImg} alt="paid feature" />
        </Link>
      )}
      {!isImportPage && (
        <p style={{ fontSize: "27px" }} className="title montserrat-800 grey start">
          {t("translationFeature.title")}
        </p>
      )}
      <div style={{ marginTop: !isImportPage && "25px", marginBottom: "25px" }} className="column width-100">
        <p className="text-bold">{t("translationFeature.subtitle")}</p>
        <Select
          style={{ width: "100%", maxWidth: "400px", margin: "10px 0" }}
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          onChange={(value) => {
            handleUpdateSetting({
              retailPrice: {
                ...user.retailPrice,
                handlePush: {
                  ...user.retailPrice?.handlePush,
                  translateLang: value,
                },
              },
            });
          }}
          value={user.retailPrice?.handlePush?.translateLang}
          defaultValue="Choose language"
        >
          {DEEPL_COUNTRIES.map((country) => (
            <Select.Option value={country.countryCode}>{country.country}</Select.Option>
          ))}
        </Select>
        <p style={{ fontSize: "13px" }} className="text grey">
          {t("translationFeature.subtitle-2")}
        </p>
      </div>
      {isAccessibleFeature && (
        <>
          <p className="text-bold">
            {t("translationFeature.usage")} {userMonthlyUsage}/{userQuota}
            <span style={{ fontSize: "12px", marginLeft: "10px", fontWeight: "400" }} className="text">
              ({t("translationFeature.renewal")} : {firstDayOfNextMonth()})
            </span>
          </p>
          <Progress
            status={parseInt(userMonthlyUsage) === parseInt(userQuota) && "exception"}
            className="big-select"
            style={{ width: "90%" }}
            percent={((userMonthlyUsage / userQuota) * 100).toFixed(2) || 0}
          />

          <div style={{ marginTop: "25px" }} className="row">
            <Switch
              defaultChecked={user?.retailPrice?.handlePush?.translate}
              checked={isSwitchTranslateAnabled}
              style={{ marginRight: "10px" }}
              disabled={userQuota === 0 || parseInt(userMonthlyUsage) === parseInt(userQuota)}
              onChange={(checked, e) => {
                setIsSwitchTranslateAnabled(checked);
                if (checked && !user.retailPrice?.handlePush?.translateLang) {
                  message.error("Please select a target language");
                  setIsSwitchTranslateAnabled(false);
                } else {
                  handleUpdateSetting({
                    retailPrice: {
                      ...user.retailPrice,
                      handlePush: {
                        ...user.retailPrice?.handlePush,
                        translate: checked,
                      },
                    },
                  });
                }
              }}
            />
            <p>
              {user?.retailPrice?.handlePush?.translate
                ? t("translationFeature.enabled")
                : t("translationFeature.disabled")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default TranslationFeature;
