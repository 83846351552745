import React from "react";
import { Rate } from "antd";
import { useTranslation } from "react-i18next";

import "./style.scss";

const ReviewsLabel = ({ score, total, style }) => {
  const { t } = useTranslation();

  if (!score || !total) {
    return <></>;
  }

  return (
    <div style={style || {}} className="review-label">
      
      <p className="text total">
        {total} {t("reviews")}
      </p>
      <div className="row center text score">
        <Rate
          allowHalf
          style={{ marginRight: "8px", fontSize: "12px", marginBottom: "3px", color: "white" }}
          disabled
          defaultValue={parseFloat(Math.round(score * 2) / 2, 2)}
        />
        {parseFloat(score.toFixed(2), 1)}
      </div>
    </div>
  );
};

export default ReviewsLabel;
