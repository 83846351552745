import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import Footer from "../../../components/menu/Footer";
import Navbar from "../../../components/resellerCoaching/Navbar";

import buttonImg from "../../../images/resellerCoaching/button1.svg";
import illustration1 from "../../../images/resellerCoaching/100pmhIllustration.png";
import arrowImg from "../../../images/resellerCoaching/arrow.svg";
import illustration2 from "../../../images/resellerCoaching/pc_smartphone_exemple.svg";
import illustration3 from "../../../images/resellerCoaching/coaching_girl.png";
import checkImg from "../../../images/resellerCoaching/check.svg";
import brandingIcon from "../../../images/resellerCoaching/branding_icon.svg";
import facebookIcon from "../../../images/resellerCoaching/facebook_icon.svg";
import tiktokIcon from "../../../images/resellerCoaching/tiktok_icon.svg";
import shieldIcon from "../../../images/resellerCoaching/shield.svg";
import illustration8 from "../../../images/illustrations/boostPresentation/roadmap.svg";
import illustration9 from "../../../images/resellerCoaching/reviews.svg";
import illustration10 from "../../../images/illustrations/boostPresentation/review_5_stars.svg";
import illustration12 from "../../../images/illustrations/boostPresentation/stars_4.svg";
import supplyImg from "../../../images/icons/01supply_academy.svg";
import starsImg from "../../../images/icons/stars.svg";
import arrow1Img from "../../../images/illustrations/boostPresentation/arrow1.svg";
import arrow2Img from "../../../images/illustrations/boostPresentation/arrow2.svg";
import { t } from "i18next";

import "./style.scss";

const { Panel } = Collapse;

const Coaching1 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.tidioChatApi.show();
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="reseller-coaching">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Starter Package | 01supply Academy</title>
        <meta
          name="description"
          content="We help you start your e-commerce business to get sales. The Starter Package contains a store analysis and a 30 minutes Questions and Answers session."
        />
      </Helmet>
      <Navbar />
      <div className="banner-1">
        <div className="left-column">
          <p style={{ fontSize: "55px", marginTop: "30px" }} className="title montserrat-800 orange">
            {t("resellerCoaching.sales")}
            <br />
          </p>
          <p style={{ fontSize: "35px", marginTop: "-15px" }} className="title montserrat-800">
            {t("resellerCoaching.104")}
          </p>
          <p className="text-400">{t("resellerCoaching.1")}</p>

          <Link
            to="ecommerce-coaching-payment?coaching=starter"
            style={{ marginTop: "15px", minWidth: "230px" }}
            className="button big orange-back"
          >
            {t("resellerCoaching.buyNow")}
          </Link>
        </div>
        <img
          alt="boost your business"
          src={illustration1}
          className="right-column"
          style={{ width: "25%", marginBottom: "-30px" }}
        />
      </div>
      <div className="black-banner">
        <img src={arrowImg} alt="arrow" className="arrow-1" />
        <h1 style={{ fontSize: "25px" }} className="title start white montserrat-800">
          {t("resellerCoaching.2")}
          <br />
          {t("resellerCoaching.3")}
          <br />
          <span className="orange">{t("resellerCoaching.4")}</span> {t("resellerCoaching.5")}
          <br />
          {t("resellerCoaching.6")} <span className="orange">{t("resellerCoaching.7")}</span>
        </h1>
      </div>
      <div className="banner-3">
        <div className="card">
          <img src={illustration2} className="illustration-2" alt="e-commerce boost exemple" />
          <div className="right-column">
            <p className="title montserrat-800 orange">{t("resellerCoaching.8")}</p>
            <p>
              {t("resellerCoaching.9")}
              <br />
              {t("resellerCoaching.10")}
            </p>
            <p style={{ marginTop: "20px" }} className="title montserrat-800 orange">
              {t("resellerCoaching.11")}
            </p>
            <p>
              {t("resellerCoaching.12")}
              <br />
              {t("resellerCoaching.13")}
            </p>
          </div>
        </div>
        <p
          style={{ marginTop: "40px", marginBottom: "40px", fontSize: "28px", textAlign: "center" }}
          className="title grey montserrat-800"
        >
          {t("resellerCoaching.14")}
        </p>
        <div style={{ alignItems: "flex-start" }} className="row width-100 center banner-4">
          <img
            style={{ width: "260px" }}
            src={illustration3}
            alt="coaching girl 01supply"
            className="left-column"
          />
          <div className="right-column">
            <div className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-bold orange">
                {t("resellerCoaching.15")}{" "}
                <span style={{ fontWeight: "400" }} className="black">
                  {t("resellerCoaching.16")}
                </span>
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-400">
                {t("resellerCoaching.17")} <span className="text-bold black">{t("resellerCoaching.18")}</span>{" "}
                {t("resellerCoaching.19")}
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-400">
                <span className="text-bold black">{t("resellerCoaching.20")}</span> {t("resellerCoaching.21")}
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-400">
                {t("resellerCoaching.22")} <span className="text-bold black">{t("resellerCoaching.23")}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="banner-5">
          <div className="card">
            <div className="column width-100" style={{ alignItems: "center" }}>
              <p style={{ fontSize: "25px" }} className="title montserrat-800 orange">
                {t("resellerCoaching.24")}
              </p>

              <div style={{ justifyContent: "center", marginTop: "20px" }} className="row start width-100">
                <div className="left-column">
                  <p style={{ fontSize: "20px", marginBottom: "15px" }} className="title montserrat-800">
                    {t("resellerCoaching.25")}
                  </p>
                  <p>
                    {t("resellerCoaching.26")}
                    <br />
                    <br />
                    {t("resellerCoaching.27")}
                  </p>
                </div>
                <div className="right-column">
                  <p style={{ fontSize: "20px", marginBottom: "15px" }} className="title montserrat-800">
                    {t("resellerCoaching.28")}
                  </p>
                  <p>
                    {t("resellerCoaching.29")}
                    <br />
                    <br />
                    {t("resellerCoaching.30")}
                  </p>
                </div>
              </div>
              <p
                style={{ marginTop: "35px", marginBottom: "20px", fontSize: "16px" }}
                className="title montserrat-800 grey"
              >
                {t("resellerCoaching.31")}
              </p>
              <div className="row width-100 center">
                <div style={{ alignItems: "center", marginRight: "20px" }} className="column">
                  <img src={brandingIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">{t("resellerCoaching.32")}</p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px" }} className="column">
                  <img src={facebookIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">Facebook ads</p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px" }} className="column">
                  <img src={tiktokIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">Tiktok ads</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-6">
          <img alt="starter coaching" src={buttonImg} style={{ width: "180px", marginBottom: "50px" }} />
          <div className="row center width-100">
            <div className="left-column">
              <p className="text-bold start">{t("resellerCoaching.33")}</p>
              <p style={{ margin: "20px 0" }} className="text start">
                {t("resellerCoaching.34")}
              </p>
              <p className="text start">{t("resellerCoaching.35")}</p>
            </div>
            <div className="right-column">
              <p style={{ fontSize: "28px" }} className="title">
                $69.99
              </p>
              <p className="text grey">{t("resellerCoaching.oneTimePayment")}</p>
              <Link
                to="ecommerce-coaching-payment?coaching=starter"
                style={{ marginTop: "10px", marginBottom: "20px" }}
                className="button big orange-back width-100"
              >
                {t("resellerCoaching.buyNow")}
              </Link>
              <div className="row">
                <img alt="secure payment" src={shieldIcon} style={{ marginRight: "10px", width: "20px" }} />
                <p style={{ fontSize: "11px" }} className="text-bold green start">
                  {t("resellerCoaching.paymentSecurity")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="black-banner roadmap">
          <p style={{ fontSize: "28px" }} className="title montserrat-800 white">
            {t("resellerCoaching.36")}
          </p>
          {windowSize > 1150 ? (
            <>
              <div className="row roadmap1">
                <div style={{ width: "150px", marginRight: "240px" }} className="column">
                  <p className="text montserrat-800 white">{t("resellerCoaching.37")}</p>
                  <p className="text white">{t("resellerCoaching.38")}</p>
                </div>
                <div style={{ width: "200px" }} className="column">
                  <p className="text montserrat-800 white">{t("resellerCoaching.41")}</p>
                  <p className="text white">{t("resellerCoaching.42")}</p>
                </div>
              </div>

              <img alt="01supply boost roadmap" src={illustration8} className="illustration8" />

              <div className="row roadmap2">
                <div style={{ width: "170px" }} className="column roadmap-3">
                  <p className="text montserrat-800 white">{t("resellerCoaching.39")}</p>
                  <p className="text white">{t("resellerCoaching.40")}</p>
                </div>
                <div style={{ width: "170px" }} className="column roadmap-4">
                  <p className="text montserrat-800 white">{t("resellerCoaching.43")}</p>
                  <p className="text white">{t("resellerCoaching.44")}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="column">
              <div style={{ width: "150px" }} className="column roadmap-3">
                <p className="text montserrat-800 white">{t("resellerCoaching.37")}</p>
                <p className="text white">{t("resellerCoaching.38")}</p>
              </div>
              <div style={{ width: "170px" }} className="column roadmap-3">
                <p className="text montserrat-800 white">{t("resellerCoaching.39")}</p>
                <p className="text white">{t("resellerCoaching.40")}</p>
              </div>
              <div style={{ width: "200px" }} className="column roadmap-3">
                <p className="text montserrat-800 white">{t("resellerCoaching.41")}</p>
                <p className="text white">{t("resellerCoaching.42")}</p>
              </div>
              <div style={{ width: "170px" }} className="column roadmap-4">
                <p className="text montserrat-800 white">{t("resellerCoaching.43")}</p>
                <p className="text white">{t("resellerCoaching.44")}</p>
              </div>
            </div>
          )}
        </div>

        {/* FAQ */}

        <div
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: "50px",
            maxWidth: "830px",
          }}
          className="row-wrap reviews"
        >
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.1.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.1.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.1.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.1.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.1.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.1.questions.3")} key="3">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.1.responses.3")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.1.questions.4")} key="4">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.1.responses.4")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.1.questions.5")} key="5">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.1.responses.5")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.1.questions.6")} key="6">
                <p className="text-roboto montserrat">
                  {t("resellerCoaching.faq.1.responses.6")}
                  <a
                    href="https://chatting.page/rwhlsppllnpriozhtsqysjubupzuunlk"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text blue"
                  >
                    {" "}
                    {t("resellerCoaching.faq.1.responses.6-1")}{" "}
                  </a>
                  {t("resellerCoaching.faq.1.responses.6-2")}
                </p>
              </Panel>
            </Collapse>
          </div>
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.2.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.2.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.2.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.2.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.2.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.2.questions.3")} key="3">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.2.responses.3")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.2.questions.4")} key="4">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.2.responses.4")}</p>
              </Panel>
            </Collapse>
          </div>
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.3.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.3")} key="3">
                <p className="text-roboto montserrat">
                  <p className="text-roboto montserrat">
                    {t("resellerCoaching.faq.3.responses.3")}
                    <a
                      href="https://chatting.page/rwhlsppllnpriozhtsqysjubupzuunlk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text blue"
                    >
                      {" "}
                      {t("resellerCoaching.faq.3.responses.3-1")}{" "}
                    </a>
                    {t("resellerCoaching.faq.3.responses.3-2")}
                  </p>
                </p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.4")} key="4">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.4")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.5")} key="5">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.5")}</p>
              </Panel>
            </Collapse>
          </div>
        </div>

        {/* REVIEWS */}

        <div style={{ marginTop: "100px" }} className="row start review">
          <p style={{ fontSize: "26px" }} className="title montserrat-800">
            {t("boostPresentation.61")}
          </p>
          <img src={illustration9} alt="01supply boost supplier reviews" className="illustration9" />
        </div>
        <div style={{ marginTop: "30px" }} className="row-wrap content start reviews">
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("resellerCoaching.reviews.1")}</p>
            <p className="text-bold start">François H.</p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("resellerCoaching.reviews.2")}</p>
            <p className="text-bold start">William J.</p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("resellerCoaching.reviews.3")}</p>
            <p className="text-bold start">Lucas O.</p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("resellerCoaching.reviews.4")}</p>
            <p className="text-bold start">Isaac Y.</p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration12}
              alt="review 4 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("resellerCoaching.reviews.5")}</p>
            <p className="text-bold start">Henry H.</p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("resellerCoaching.reviews.6")}</p>
            <p className="text-bold start">Marie C.</p>
          </div>
        </div>
      </div>
      <div className="column width-100 academy" style={{ alignItems: "center", margin: "50px 0" }}>
        <img alt="01supply academy" src={supplyImg} style={{ width: "250px" }} />
        <img style={{ marginTop: "30px", marginBottom: "10px" }} src={starsImg} />
        <p style={{ maxWidth: "700px" }} className="text-bold width-100 center">
          <span className="text-400">{t("resellerCoaching.45")}</span>
        </p>
      </div>

      <div className="back-6">
        <img src={arrow1Img} className="arrow-1-2" />
        <Link to="ecommerce-coaching-payment?coaching=starter" className="button big orange-back">
          {t("resellerCoaching.buyStarter")}
        </Link>
        <img src={arrow2Img} className="arrow-2-2" />
      </div>
      <Footer type="reseller" windowSize={windowSize} />
    </div>
  );
};

export default Coaching1;
