import { hydrate, render } from "react-dom";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import reportWebVitals from "./reportWebVitals";
import CheckTokenValidity from "./utils/CheckTokenValidity";
import App from "./App";
import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalStatesProvider } from "./providers";

const queryClient = new QueryClient();

const RootComponent = (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<></>}>
        <LocalStatesProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
        </LocalStatesProvider>
      </Suspense>
    </QueryClientProvider>
    <CheckTokenValidity />
  </BrowserRouter>
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(RootComponent, rootElement);
} else {
  render(RootComponent, rootElement);
}

reportWebVitals();