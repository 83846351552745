import { url } from "../api/url";
import { header } from "../api/header";
import { message } from "antd";

export const updateUser = async (
  {user, newShipping},
  idAccount = localStorage.getItem("idAccountCreatosell"),
  secretId = localStorage.getItem("secretAccountId01supply"),
  token = localStorage.getItem("tokenCreatosell"),
  callback,
  handleError
) => {
  const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      ...header,
      authorization: token,
    },
  });
  if (res.status === 200) {
    const resJson = await res.json();
    const userRes = await resJson.data;
    if (userRes) {
      let newUser = { ...userRes, ...user };
      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
          newShipping: newShipping || undefined
        }),
      });
      if (res.status === 200) {
        if (callback) {
          callback(newUser);
        } else {
          return res;
        }
      } else if (handleError) handleError(res);
    } else message.error("An error has occured, please try again or contact us");
  }
};
