import { url } from "../api/url";
import { header } from "../api/header";

export const currencyRateServices = {
  get: async () => {
    return await fetch(`${url}/currency-rate`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
      },
    });
  },
};
