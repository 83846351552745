import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { articles } from "../../pages/blog/articles";
import { useTranslation } from "react-i18next";

import "./style.scss";

const ArticleCard = ({ article, setDisplayedArticles, setSelectedTag }) => {
  const [redirect, setRedirect] = useState("");

  const handleClickTag = (tag, e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedTag(tag);
    setRedirect(tag ? `/blog?tag=${tag}` : "/blog");
    if (setDisplayedArticles) {
      setDisplayedArticles(articles.filter((article) => article.tags.includes(tag) || !tag));
    }
  };
  
  const { t } = useTranslation();
  
  return (
    <Link to={article.pathname} className="column blog-article-card">
      {redirect && <Redirect to={redirect} />}
      <img
        className="blog-article-card-img"
        src={`${process.env.REACT_APP_ORIGIN}/${article.imagePath}`}
        alt={article.title}
      />
      <h2 style={{ marginTop: "10px", marginBottom: "5px" }} className="title-bold montserrat start">
        {article.title}
      </h2>
      <p className="start" style={{ marginBottom: "10px" }}>
        {article.description}
      </p>
      <Link style={{ fontSize: "18px" }} className="text-300 blue" to={article.pathname}>
      {t("readArticle")}
      </Link>
      <div className="row-wrap width-100">
        {article.tags.map((tag) => (
          <p onClick={(e) => handleClickTag(tag.toUpperCase(), e)} className="blog-tag">
            {tag}
          </p>
        ))}
      </div>
    </Link>
  );
};

export default ArticleCard;
