export const checkAccountCompleted = (user) => {
  if (!(user.paypal_email_account || user.iban || user.stripe?.account_completed)) {
    return false;
  }
  if (
    !(
      user.firstName &&
      user.lastName &&
      user.shop &&
      user.shop.name &&
      user.company &&
      user.company.name &&
      user.company.phone &&
      user.company.address
    )
  ) {
    return false;
  }

  return true;
};
