import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import icon_marketplace from "../../images/icons_grey/Marketplace.svg";
import icon_join_us from "../../images/icons_white/Join_us.svg";
import icon_login from "../../images/icons_grey/Import_list.svg";
import icon_documentation from "../../images/icons_grey/Documentation.svg";
import icon_pricing from "../../images/icons_grey/pricing2.svg";

import logo from "../../images/logo.svg";
import { BulbFilled } from "@ant-design/icons";

import CheckTokenAndRedirectLogin from "../../utils/RedirectLogin";
import CountryCurrencySelector from '../CountryCurrencySelector'
import { t } from "i18next";
import { routes } from "../../routes";

const NotConnectedMenu = ({ windowSize }) => {
  const [location, setLocation] = useState(
    window.location.pathname.replace("/thematics", "").replace("/", "")
  );
  const [token] = useState(localStorage.getItem("tokenCreatosell"));

  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.location.pathname.includes("marketplace") && !window.location.pathname.includes("winning")) {
      setLocation("marketplace");
    } else {
      setLocation(window.location.pathname.replace("/", ""));
    }
  }, [window.location.pathname]);

  return (
    <>
      {token && <CheckTokenAndRedirectLogin />}
      {
        <div className="container-menu">
          <div className="board-left-menu montserrat">
            {windowSize > 1150 && (
              <a
                style={{ width: "100%", maxHeight: "100%", marginBottom: "25px", marginTop: "15px" }}
                href="https://01supply.com"
              >
                <img style={{ marginBottom: "0" }} src={logo} alt="supply.me logo" className="logo-menu" />
              </a>
            )}
            {windowSize > 1150 && (
              <CountryCurrencySelector
                selectorStyle={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  maxWidth: "100%",
                }}
              />
            )}

            <Link
              style={{ width: "190px", fontSize: "13px", height: "40px", marginTop: "30px" }}
              className="button big orange-back button-join-us-menu montserrat"
              to={"/join-us?register"}
            >
              <img
                className="icon-join-us-menu"
                src={icon_join_us}
                style={{ height: "19px", marginRight: "7px" }}
              />
              {t("components.menu.49")}
            </Link>
            <Link
              style={{ marginTop: "35px", marginBottom: "5px" }}
              to={"/join-us?login"}
              className={
                location === "join-us" && !window.location.href.includes("register")
                  ? "text-menu-active"
                  : "text-menu"
              }
            >
              <img src={icon_login} className="icon-menu" />
              {t("components.menu.50")}
            </Link>
            <div
              className="divider-horizontal"
              style={{ marginRight: "0", marginTop: "10px", width: "100%", marginLeft: "0" }}
            />
            <Link
              style={{ marginBottom: "8px" }}
              to={routes.marketplaceHome(i18n.language).path}
              className={location === "marketplace" ? "text-menu-active" : "text-menu"}
            >
              <img src={icon_marketplace} className="icon-menu" />
              Marketplace
            </Link>
            <Link
              style={{ marginBottom: "8px" }}
              to="/pricing"
              className={location === "pricing" ? "text-menu-active" : "text-menu"}
            >
              <img style={{ height: "20px" }} src={icon_pricing} className="icon-menu" />
              {t("pricing")}
            </Link>

            <a
              style={{ marginBottom: "8px" }}
              href="https://www.01supply.com/help"
              className={location === "documentation" ? "text-menu-active" : "text-menu"}
            >
              <img src={icon_documentation} className="icon-menu" />
              Documentation
            </a>
            <a
              href="https://blog.01supply.com"
              className={location === "blog" ? "text-menu-active" : "text-menu"}
            >
              <BulbFilled className="icon-menu" style={{ color: "grey" }} />
              Blog
            </a>
          </div>
        </div>
      }
    </>
  );
};

export default NotConnectedMenu;
