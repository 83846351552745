import React from "react";
import { useTranslation } from "react-i18next";

import illustration from "../../../images/illustrations/winning_product.svg";

const Header = ({ needAccessCallbackFunction, userCanAccess }) => {
  const { t } = useTranslation();
  return (
    <div className="card width-100" id="market-sub-category">
      <img src={illustration} alt="winning product illustration" />
      <div className="column">
        <h1 className="title-bold">{t("marketplace.winning.title")}</h1>
        <p style={{ marginBottom: "5px" }}>{t("marketplace.winning.1")}</p>
        <p style={{ marginBottom: "5px" }}>{t("marketplace.winning.2")}</p>
        <p>{t("marketplace.winning.3")}</p>
        {!userCanAccess && (
          <button
            style={{
              boxShadow: "rgb(202 202 202) 1px 1px 4px",
              fontSize: "24px",
              padding: "8px 40px",
              marginTop: "25px",
              marginBottom: "10px"
            }}
            onClick={needAccessCallbackFunction}
            className="button big orange-back"
          >
            {t("marketplace.winning.button")}
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
