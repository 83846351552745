import React from "react";
import { useTranslation } from "react-i18next";

import iconCheck from "../../../../images/icons/Check.svg";

const Plan2Resume = ({ isYearly, setIsYearly }) => {
  const { t } = useTranslation();

  const price = isYearly ? 59 : 99;

  return (
    <div style={{ alignItems: "flex-start" }} className="row-wrap resume-plan space-between width-100">
      <div style={{ margin: "20px" }} className="column">
        <div
          style={{ border: isYearly ? "orange 2px solid" : "" }}
          onClick={() => setIsYearly(true)}
          className="card-border"
        >
          <div className="row space-between">
            <p style={{ fontSize: "22px" }} className="start tilte montserrat-800">
              {t("yearlyPayment")}
            </p>
            <p className="title percentage">- 40%</p>
          </div>
          <div className="row">
            <p
              style={{ textDecoration: "line-through", marginRight: "20px", fontSize: "22px" }}
              className="title"
            >
              $99
            </p>
            <p style={{ fontSize: "26px", color: "##474747" }} className="title-bold montserrat-800">
              $59 <span className="text-400 grey">/mo</span>
            </p>
          </div>
          <p>{t("annualPrice", { price: 59 * 12 })} </p>
        </div>
        <div
          style={{ border: !isYearly ? "orange 2px solid" : "", marginTop: "20px" }}
          onClick={() => setIsYearly(false)}
          className="card-border"
        >
          <div className="row space-between">
            <p style={{ fontSize: "22px" }} className="start tilte montserrat-800">
              {t("mensualPayment")}
            </p>
          </div>
          <div className="row">
            <p style={{ fontSize: "26px", color: "##474747" }} className="title-bold montserrat-800">
              $99 <span className="text-400 grey">/mo</span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ minWidth: "240px", margin: "20px" }}>
        <h2 className="title-bold">{t("resellerPricing.27")}</h2>
        <p style={{ fontSize: "15px" }} className="title-300">
          {t("resellerPricing.26")}
        </p>
        {!isYearly ? (
          <p style={{ marginTop: "10px" }} className="title-bold start width-100">
            ${price}
            <span style={{ marginLeft: "10px" }} className="title-300">
              /mo
            </span>
          </p>
        ) : (
          <div className="row">
            <p
              style={{ textDecoration: "line-through", marginRight: "10px", fontSize: "22px" }}
              className="title"
            >
              ${99 * 12}
            </p>
            <p className="title-bold start width-100">${price * 12}</p>
          </div>
        )}
        <div className="divider-horizontal" style={{ width: "100%", marginTop: "10px", marginLeft: "0" }} />
        <div style={{ width: "100%", height: "245px" }} className="column">
          <div style={{ marginBottom: "10px" }} className="row">
            <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
            <p className="title-300" style={{ fontSize: "13px", fontWeight: "bold" }}>
              {t("resellerPricing.25")}
            </p>
          </div>
          <div style={{ marginBottom: "10px" }} className="row">
            <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
            <p className="title-300" style={{ fontSize: "13px" }}>
              {t("resellerPricing.9")}
            </p>
          </div>
          <div style={{ marginBottom: "10px" }} className="row">
            <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
            <p className="title-300" style={{ fontSize: "13px" }}>
              {t("resellerPricing.20")}
            </p>
          </div>
          <div style={{ marginBottom: "10px" }} className="row">
            <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
            <p className="title-300" style={{ fontSize: "13px" }}>
              {t("resellerPricing.22")}
            </p>
          </div>
          <div style={{ marginBottom: "10px" }} className="row">
            <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
            <p className="title-300" style={{ fontSize: "13px" }}>
              {t("resellerPricing.14")}
            </p>
          </div>
          <div style={{ marginBottom: "10px" }} className="row">
            <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
            <p className="title-300" style={{ fontSize: "13px" }}>
              {t("resellerPricing.15")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan2Resume;
