import React, { useState, useEffect, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { DownloadOutlined } from "@ant-design/icons";
import { Spin, Select, message } from "antd";

import { url } from "../../../api/url";
import { header } from "../../../api/header";
import Menu from "../../../components/menu/FirstMenu";
import Header from "../../../components/header/SimpleHeader";
import MenuMobile from "../../../components/menu/mobile/FirstMenu";
import HeaderMobile from "../../../components/header/mobile/SimpleHeader";
import ModalImportProductCsv from "./ModalImportProductCsv";
import Load from "../../../utils/Load";

import ExcelIcon from "../../../images/icons/excel-sheet-icon.jpeg";
import EtsyIcon from "../../../images/icons/etsy-icon.png";
import ShopifyIcon from "../../../images/icons/shopify-icon.png";
import WixIcon from "../../../images/icons/wix-icon.png";
import "./uploadStyle.scss";

const csv = require("csvtojson");

const ImportCSV = ({}) => {
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);
  const [products, setProducts] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [csvName, setCsvName] = useState("");
  const [headersColumns, setHeaderColumns] = useState([]);
  const [indexColumn, setIndexColumn] = useState({
    createrProductId: undefined,
    name: undefined,
    price: undefined,
    image1: undefined,
    image2: undefined,
    image3: undefined,
    image4: undefined,
    image5: undefined,
    image6: undefined,
    image7: undefined,
    image8: undefined,
    image9: undefined,
    image10: undefined,
    quantity: undefined,
    describe: undefined,
    origin: undefined,
    processing_time: undefined,
    tag: undefined,
    variants1: undefined,
    variants2: undefined,
    variants3: undefined,
    variantType1: undefined,
    variantType2: undefined,
    variantType3: undefined,
    currency: undefined,
  });
  const [indexSelect, setIndexSelect] = useState([]);
  const [badColumnSelection, setBadColumnSelection] = useState([]);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [csvData, setCsvData] = useState([]);
  const [disabledModalApplyOriginAll, setDisabledModalApplyOriginAll] = useState(false);
  const [disabledModalApplyShippingAll, setDisabledModalApplyShippingAll] = useState(false);
  const [disabledModalApplyProcessingTimeAll, setDisabledModalApplyProcessingTimeAll] = useState(false);
  const [processingTimeToAll, setProcessingTimeToAll] = useState("");
  const [imageWix, setImageWix] = useState(false);
  const [labelSelectedPreview, setLabelSelectedPreview] = useState({});

  const [noImportList, setNoImportList] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const [deviceRate, setDeviceRate] = useState();

  const { t } = useTranslation();

  const importCsvRef = useRef(null);

  const productModel = {
    userId: idAccount,
    creater: {
      id: idAccount,
      name: user && user.shop ? user.shop.name : "",
    },
    name: "",
    describe: "",
    price: "",
    stock: "",
    photos: [],
    origin: "",
    processingTime: "",
    shipping: [],
    tag: [],

    variants: [],
    comparedPrice: "",
    orders_max: "",
    reseller_max: "",
    category: "",
    maxResellerBool: false,
    maxOrderBool: false,
    optionsVariants: [],
    variantBool: false,
  };

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (secretId && idAccount) {
      getUser();
    }
  }, [idAccount, secretId, importCsvRef]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    if (resJson) setUser(resJson.data);
  };

  const saveIndexColumnToLocalStorage = () => {
    const stringsIndexColumn = Object.keys(indexColumn).reduce((acc, key) => {
      const index = indexColumn[key];
      if (typeof index === "number" && headersColumns[index]) {
        acc[key] = headersColumns[index];
      } else {
        acc[key] = index;
      }
      return acc;
    }, {});

    localStorage.setItem("indexColumn", JSON.stringify(stringsIndexColumn));
  };

  const loadIndexColumnFromLocalStorage = () => {
    const storedIndexColumn = localStorage.getItem("indexColumn");
    if (storedIndexColumn) {
      const parsedStringsIndexColumn = JSON.parse(storedIndexColumn);
      const updatedIndexColumn = { ...indexColumn };

      Object.keys(parsedStringsIndexColumn).forEach((key) => {
        const index = headersColumns.indexOf(parsedStringsIndexColumn[key]);
        if (index !== -1) {
          updatedIndexColumn[key] = index;
        }
      });

      setIndexColumn(updatedIndexColumn);
    }
  };

  useEffect(() => {
    if (headersColumns.length > 0) {
      loadIndexColumnFromLocalStorage();
    }
  }, [headersColumns]);

  const onDroping = (acceptedFiles) => {
    setLoad(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: "json",
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            let newHeadersColumns = [];
            for (let header in csvRows[0]) {
              newHeadersColumns.push(decodeURIComponent(escape(csvRows[0][header])));
            }
            const datas = csvRows.filter((value, index) => index !== 0);
            if (datas?.length) {
              setCsvData(datas);
              setCsvName(file.name);
              setHeaderColumns(newHeadersColumns);
              // CHECK IF IMAGES FIELD WIX
              if (
                newHeadersColumns[4] === "productImageUrl" &&
                datas.some((data) => data.field5.includes(";"))
              )
                setImageWix(true);
            } else {
              message.error(t("messages.badCsv"));
            }
            setLoad(false);
          });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.readAsBinaryString(file);
    });
  };

  const selectLabel = (value, label) => {
    setIndexColumn({ ...indexColumn, [label]: value });
    const newBadSelection = badColumnSelection.filter((column) => column !== label);
    setBadColumnSelection(newBadSelection);

    let newIndexSelect = indexSelect.filter((valueF) => valueF !== indexColumn[label]);
    let newSelectedLabelPreview = labelSelectedPreview;
    newSelectedLabelPreview[label] = csvData[0][`field${value + 1}`];
    setLabelSelectedPreview(newSelectedLabelPreview);
    if (!indexColumn[label] && indexColumn[label] !== 0) {
      setIndexSelect([...indexSelect, value]);
    } else if (value !== "manually") {
      newIndexSelect.push(value);
      setIndexSelect(newIndexSelect);
    }
  };

  const verifLabelSelection = () => {
    let badSelection = [];
    for (let label in indexColumn) {
      if (
        indexColumn[label] === undefined &&
        (!label.includes("image") || (label === "image1" && !imageWix)) &&
        // optional attributs
        label !== "variants1" &&
        label !== "processing_time" &&
        label !== "variants2" &&
        label !== "variantType1" &&
        label !== "variantType2" &&
        label !== "variants3" &&
        label !== "variantType3" &&
        label !== "tag" &&
        label !== "origin"
      )
        badSelection.push(label);
    }
    setBadColumnSelection(badSelection);
    if (badSelection.length > 0) {
      message.error(t("messages.selectedMissingColumn"));
      return false;
    } else {
      return true;
    }
  };

  const columnSelectionDone = async () => {
    if (verifLabelSelection() === true) {
      let convertCurrency = deviceRate;
      if (convertCurrency) {
        const productsCsv = csvData.map((dataCsv, index) => {
          try {
            let product = { ...productModel };
            if (dataCsv[Object.keys(dataCsv)[indexColumn.name]]) {
              product.optionsVariants = [];
              product.variants = [];
              product.variantBool = false;
              product.name = decodeURIComponent(escape(dataCsv[Object.keys(dataCsv)[indexColumn.name]]));

              // Creater product ID
              if (indexColumn.createrProductId !== undefined && indexColumn.createrProductId !== "manually")
                product.createrProductId = dataCsv[Object.keys(dataCsv)[indexColumn.createrProductId]];

              product.price = (
                dataCsv[Object.keys(dataCsv)[indexColumn.price]]
                  .replaceAll(",", ".")
                  .replace(/[^\d.+-]|\.(?=.*\.)/g, "") * convertCurrency
              ).toFixed(2);
              if (indexColumn.describe !== undefined && indexColumn.describe !== "manually")
                product.describe = dataCsv[Object.keys(dataCsv)[indexColumn.describe]];
              product.describe = decodeURIComponent(
                escape(dataCsv[Object.keys(dataCsv)[indexColumn.describe]].replace(/(?:\r\n|\r|\n)/g, "<br>"))
              );
              if (product.describe) {
                product.describe = product.describe.replaceAll("etsy", "01supply");
                product.describe = product.describe.replaceAll("Etsy", "01supply");
              }
              if (indexColumn.quantity !== undefined && indexColumn.quantity !== "manually")
                product.stock = parseFloat(dataCsv[Object.keys(dataCsv)[indexColumn.quantity]]);
              if (indexColumn.origin !== undefined && indexColumn.origin !== "manually")
                product.origin = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.origin]])
                );
              if (indexColumn.processing_time !== undefined && indexColumn.processing_time !== "manually")
                product.processingTime = parseFloat(
                  dataCsv[Object.keys(dataCsv)[indexColumn.processing_time]]
                );
              if (indexColumn.tag !== undefined && indexColumn.tag !== "manually") {
                product.tag = decodeURIComponent(escape(dataCsv[Object.keys(dataCsv)[indexColumn.tag]]))
                  .replaceAll(" ", "")
                  .split(",");
                product.tag.length = 3;
              }
              let variantType1 = "";
              if (indexColumn.variantType1 !== undefined && indexColumn.variantType1 !== "manually") {
                let variantType = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.variantType1]])
                );
                if (variantType) {
                  product.optionsVariants.push(variantType);
                  variantType1 = variantType;
                }
              }
              if (
                indexColumn.variants1 !== undefined &&
                indexColumn.variants1 !== "manually" &&
                variantType1
              ) {
                let variants = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.variants1]])
                )
                  .replaceAll(" ", "")
                  .split(",");
                for (let i in variants) {
                  if (variants[i]) {
                    let newVariant = {
                      image: "0",
                      price: product.price,
                      stock: product.stock,
                      option1: variants[i],
                    };
                    product.variants.push(newVariant);
                    product.variantBool = true;
                  }
                }
              }
              let variantType2 = "";
              if (indexColumn.variantType2 !== undefined && indexColumn.variantType2 !== "manually") {
                let variantType = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.variantType2]])
                );
                if (variantType) {
                  product.optionsVariants.push(variantType);
                  variantType2 = variantType;
                }
              }
              if (
                indexColumn.variants2 !== undefined &&
                indexColumn.variants2 !== "manually" &&
                variantType2
              ) {
                let variants = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.variants2]])
                )
                  .replaceAll(" ", "")
                  .split(",");
                if (variants.length) {
                  let newVariants = [];
                  for (let i in variants) {
                    for (let v in product.variants) {
                      if (variants[i]) {
                        let newVariant = { ...product.variants[v], option2: variants[i] };
                        newVariants.push(newVariant);
                      }
                    }
                  }
                  product.variants = newVariants;
                }
              }
              let variantType3 = "";
              if (indexColumn.variantType3 !== undefined && indexColumn.variantType3 !== "manually") {
                let variantType = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.variantType3]])
                );
                if (variantType) {
                  product.optionsVariants.push(variantType);
                  variantType3 = variantType;
                }
              }
              if (
                indexColumn.variants3 !== undefined &&
                indexColumn.variants3 !== "manually" &&
                variantType3
              ) {
                let variants = decodeURIComponent(
                  escape(dataCsv[Object.keys(dataCsv)[indexColumn.variants3]])
                )
                  .replaceAll(" ", "")
                  .split(",");
                if (variants.length) {
                  let newVariants = [];
                  for (let i in variants) {
                    for (let v in product.variants) {
                      if (variants[i]) {
                        let newVariant = { ...product.variants[v], option3: variants[i] };
                        newVariants.push(newVariant);
                      }
                    }
                  }
                  product.variants = newVariants;
                }
              }
              // SHOPIFY VARIANTS LIMIT
              if (product.variants.length > 100) {
                product.variants.length = 100;
              }

              product.photos = [];
              const isQuoteImageUrl = dataCsv.field5?.includes(",");
              if (imageWix) {
                const images = dataCsv.field5.split(";").map((url) => {
                  return {
                    base64: "https://static.wixstatic.com/media/" + url,
                    name: url,
                  };
                });
                product.photos = images;
              } else if (isQuoteImageUrl) {
                const images = dataCsv.field5.split(",").map((url) => {
                  return {
                    base64: url,
                    name: url,
                  };
                });
                product.photos = images;
              } else {
                if (indexColumn.image1 !== undefined && indexColumn.image1 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image1]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image1]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image2 !== undefined && indexColumn.image2 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image2]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image2]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image3 !== undefined && indexColumn.image3 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image3]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image3]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image4 !== undefined && indexColumn.image4 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image4]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image4]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image5 !== undefined && indexColumn.image5 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image5]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image5]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image6 !== undefined && indexColumn.image6 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image6]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image6]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image7 !== undefined && indexColumn.image7 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image7]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image7]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image8 !== undefined && indexColumn.image8 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image8]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image8]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image9 !== undefined && indexColumn.image9 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image9]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image9]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
                if (indexColumn.image10 !== undefined && indexColumn.image10 !== "manually") {
                  product.photos.push({
                    base64: dataCsv[Object.keys(dataCsv)[indexColumn.image10]],
                    name: dataCsv[Object.keys(dataCsv)[indexColumn.image10]]
                      .replace("data:image/", "")
                      .split(";")[0],
                  });
                }
              }
              return product;
            }
          } catch (err) {
            console.log(err);
            alert("error please retry or contact us");
          }
        });

        setProducts(productsCsv.filter((product) => product !== undefined));
        const loading = setInterval(function () {
          const productListRender = document.getElementsByClassName("ant-modal-content");
          if (productListRender) {
            saveIndexColumnToLocalStorage();
            setLoad(false);
            clearInterval(loading);
          }
        }, 300);
      }
    } else setLoad(false);
  };

  const verifProductInformations = (product, index) => {
    if (product.photos.length < 1) {
      message.error(t("messages.needFill.image"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (!product.origin) {
      message.error(t("messages.needFill.origin"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (product.shipping.length < 1) {
      message.error(t("messages.needFill.shippingMethod"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (!product.price) {
      message.error(t("messages.needFill.price"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (!product.stock) {
      message.error(t("messages.needFill.quantity"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (!product.name) {
      message.error(t("messages.needFill.name"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (!product.describe) {
      message.error(t("messages.needFill.description"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else if (!product.photos.some((img) => img.base64)) {
      message.error(t("messages.needFill.image"));
      setLoad(false);
      scrollToProduct(`import-product-${index}`);
    } else return true;
  };

  const scrollToProduct = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  const addStockToVariants = ({ product }) => {
    if (product.variants?.length) {
      const variantsWithStock = product.variants.map((variant) => ({
        ...variant,
        stock: variant.stock || product.stock,
      }));
      return { ...product, variants: variantsWithStock };
    }
    return product;
  };

  const importProducts = () => {
    const finish = () => {
      message.success(t("messages.productImported"));
      setRedirect(true);
      setLoad(false);
    };

    let stopExe = false;
    for (let index in products) {
      const product = addStockToVariants({ product: products[index] });
      if (!noImportList.includes(index)) {
        if (!stopExe) {
          if (verifProductInformations(product, index)) {
            fetch(`${url}/product/create/${idAccount}/${secretId}`, {
              method: "POST",
              credentials: "include",
              headers: {
                ...header,
                authorization: token,
              },
              body: JSON.stringify({
                product,
              }),
            }).then((res) => {
              if (res.status === 200) {
                if (index == products.length - 1) finish();
              } else if (index == products.length - 1) finish();
            });
          } else {
            stopExe = true;
            return false;
          }
        }
      }
    }
  };

  return (
    <div className="page">
      {load && <Load />}
      {redirect && <Redirect to="/inventory" />}
      <div className="page-start">
        {windowSize > 1150 && <Menu />}
        {windowSize <= 1150 && <MenuMobile attribut="name" />}
        <div
          style={
            windowSize > 1150
              ? { paddingLeft: "5vw" }
              : { alignItems: "center", justifyContent: "flex-start", display: "flex" }
          }
          className="column-scrolling"
        >
          {windowSize > 1150 ? (
            <Header location={"import-csv"} />
          ) : (
            <HeaderMobile nameHeader={t("importProductCsv.1")} />
          )}

          {products.length > 0 && (
            <ModalImportProductCsv
              windowSize={windowSize}
              products={products}
              setProducts={setProducts}
              user={user}
              noImportList={noImportList}
              setNoImportList={setNoImportList}
              getUser={getUser}
              disabledModalApplyShippingAll={disabledModalApplyShippingAll}
              setDisabledModalApplyShippingAll={setDisabledModalApplyShippingAll}
              disabledModalApplyProcessingTimeAll={disabledModalApplyProcessingTimeAll}
              setDisabledModalApplyProcessingTimeAll={setDisabledModalApplyProcessingTimeAll}
              setProcessingTimeToAll={setProcessingTimeToAll}
              processingTimeToAll={processingTimeToAll}
              disabledModalApplyOriginAll={disabledModalApplyOriginAll}
              setDisabledModalApplyOriginAll={setDisabledModalApplyOriginAll}
              setLoad={setLoad}
              importProducts={importProducts}
            />
          )}
          {!csvName && (
            <div
              className="column step-1-import-csv"
              style={
                windowSize > 1150
                  ? { alignItems: "center", width: "80%" }
                  : { alignItems: "center", width: "100%" }
              }
            >
              <Dropzone
                ref={importCsvRef}
                onDrop={onDroping}
                multiple={false}
                maxSize={8000000}
                style={{ height: "150px", width: "300px", border: "black 1px solid", cursor: "pointer" }}
                accept=".csv"
              >
                {({ getRootProps, getInputProps }) => (
                  <div id="dropzone" className="upload-btn-wrapper" {...getRootProps()}>
                    <button
                      style={{ width: "280px", height: "60px", fontSize: "25px" }}
                      className="button big orange-back shadow"
                    >
                      {t("importProductCsv.2")}{" "}
                      {!load ? (
                        <DownloadOutlined style={{ marginLeft: "20px", fontSize: "30px" }} />
                      ) : (
                        <Spin style={{ marginLeft: "10px", color: "white" }} />
                      )}
                    </button>
                    <input
                      className="inputGetFile"
                      {...getInputProps()}
                      style={{ height: "50px", width: "250px" }}
                      id="importCsvInputId"
                    />
                  </div>
                )}
              </Dropzone>
              <p style={{ marginTop: "60px", textAlign: "center" }} className="title">
                {t("importProductCsv.3")}
                <Link
                  style={{ margin: "0 7px" }}
                  to={"/how-import-csv-products-supplier-documentation"}
                  className="title blue underline"
                >
                  {t("importProductCsv.4")}
                </Link>
                {t("or")}
                <span
                  style={{ margin: "0 7px" }}
                  onClick={() => window.tidioChatApi?.open()}
                  className="title blue underline pointer"
                >
                  {t("importProductCsv.5")}
                </span>
              </p>
              <p
                style={{
                  fontSize: "30px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  width: "90%",
                  textAlign: "center",
                }}
                className="title-300"
              >
                {t("importProductCsv.6")} <span style={{ fontWeight: "bold" }}>.csv</span>{" "}
                {t("importProductCsv.7")}
              </p>
              <a
                href="https://support.microsoft.com/en-us/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba#:~:text=Go%20to%20File%20%3E%20Save%20As,formats%20support%20different%20feature%20sets."
                target="_blank"
                rel="noopener noreferrer"
                className="card-tuto-import"
              >
                <p style={{ width: "70%", textAlign: "start" }}>Excel | Google Sheet</p>
                <img src={ExcelIcon} className="icon-in-case" />
              </a>
              <a
                href="https://help.etsy.com/hc/en-gb/articles/360000343508-How-to-Download-Your-Listing-Information?segment=selling"
                target="_blank"
                rel="noopener noreferrer"
                className="card-tuto-import"
              >
                <p style={{ width: "70%", textAlign: "start" }}>Etsy</p>
                <img src={EtsyIcon} className="icon-in-case" />
              </a>
              <a
                href="https://help.shopify.com/en/manual/products/import-export/export-products#export-your-products"
                target="_blank"
                rel="noopener noreferrer"
                className="card-tuto-import"
              >
                <p style={{ width: "70%", textAlign: "start" }}>Shopify</p>
                <img src={ShopifyIcon} className="icon-in-case" />
              </a>
              <a
                style={{ marginBottom: "30px" }}
                href="https://support.wix.com/en/article/wix-stores-exporting-products"
                target="_blank"
                rel="noopener noreferrer"
                className="card-tuto-import"
              >
                <p style={{ width: "70%", textAlign: "start" }}>Wix</p>
                <img src={WixIcon} className="icon-in-case" />
              </a>
            </div>
          )}
          {headersColumns.length > 0 && (
            <div
              style={
                windowSize > 1150
                  ? {
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      paddingBottom: "50px",
                    }
                  : { width: "80%", alignItems: "center", justifyContent: "center", paddingBottom: "50px" }
              }
              className="column"
            >
              {csvName && <p className="title-300">{csvName}</p>}
              <p style={{ fontSize: "35px", marginTop: "0px", marginBottom: "30px" }} className="title">
                {t("importProductCsv.8")}
              </p>
              <div
                style={
                  windowSize > 1150
                    ? { flexWrap: "wrap", width: "100%", alignItems: "flex-end" }
                    : { flexWrap: "wrap", width: "100%", justifyContent: "center" }
                }
                className="row"
              >
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.21")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("name") && "red-select"}
                    value={
                      indexColumn.createrProductId !== undefined
                        ? headersColumns[indexColumn.createrProductId]
                        : t("importProductCsv.21")
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "createrProductId");
                    }}
                    defaultValue={t("importProductCsv.21")}
                  >
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["createrProductId"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.9")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("name") && "red-select"}
                    value={
                      indexColumn.name !== undefined ? headersColumns[indexColumn.name] : t("productName")
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "name");
                    }}
                    defaultValue={t("productName")}
                  >
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["name"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.10")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("price") && "red-select"}
                    value={
                      indexColumn.price !== undefined ? headersColumns[indexColumn.price] : "Product price"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "price");
                    }}
                    defaultValue={t("importProductCsv.11")}
                  >
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["price"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.12")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("currency") && "red-select"}
                    value={indexColumn.currency ? indexColumn.currency : "Currency"}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      setIndexColumn({ ...indexColumn, currency: value });
                      if (value === "USD") setDeviceRate(1);
                      else if (value === "EUR") {
                        let deviceConverter = localStorage.getItem("deviceConverter") || "{}";

                        if (deviceConverter) {
                          deviceConverter = JSON.parse(deviceConverter);
                        }
                        setDeviceRate(parseFloat(deviceConverter?.USDtoEUR || 1).toFixed(2));
                      }
                    }}
                    defaultValue={"Currency"}
                  >
                    <Select.Option value={"USD"}>USD</Select.Option>
                    <Select.Option value={"EUR"}>EUR</Select.Option>
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["currency"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.13")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("describe") && "red-select"}
                    value={
                      indexColumn.describe === "manually"
                        ? "Fill in manually"
                        : indexColumn.describe !== undefined
                        ? headersColumns[indexColumn.describe]
                        : "Description"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "describe");
                    }}
                    defaultValue={"Description"}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["describe"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.14")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("quantity") && "red-select"}
                    value={
                      indexColumn.quantity === "manually"
                        ? "Fill in manually"
                        : indexColumn.quantity !== undefined
                        ? headersColumns[indexColumn.quantity]
                        : "Quantity"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "quantity");
                    }}
                    defaultValue={"Quantity"}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["quantity"]}
                  </p>
                </div>
                {!imageWix && (
                  <>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 1
                      </p>
                      <Select
                        className={badColumnSelection.includes("image1") && "red-select"}
                        value={
                          indexColumn.image1 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image1 !== undefined
                            ? headersColumns[indexColumn.image1]
                            : "Image url 1"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image1");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image1"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 2
                      </p>
                      <Select
                        className={badColumnSelection.includes("image2") && "red-select"}
                        value={
                          indexColumn.image2 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image2 !== undefined
                            ? headersColumns[indexColumn.image2]
                            : "Images url 2"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image2");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image2"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 3
                      </p>
                      <Select
                        className={badColumnSelection.includes("image3") && "red-select"}
                        value={
                          indexColumn.image3 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image3 !== undefined
                            ? headersColumns[indexColumn.image3]
                            : "Images url 3"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image3");
                        }}
                        defaultValue={"Images url 3"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image3"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 4
                      </p>
                      <Select
                        className={badColumnSelection.includes("image4") && "red-select"}
                        value={
                          indexColumn.image4 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image4 !== undefined
                            ? headersColumns[indexColumn.image4]
                            : "Images url 4"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image4");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image4"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 5
                      </p>
                      <Select
                        className={badColumnSelection.includes("image5") && "red-select"}
                        value={
                          indexColumn.image5 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image5 !== undefined
                            ? headersColumns[indexColumn.image5]
                            : "Images url 5"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image5");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image5"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 6
                      </p>
                      <Select
                        className={badColumnSelection.includes("image6") && "red-select"}
                        value={
                          indexColumn.image6 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image6 !== undefined
                            ? headersColumns[indexColumn.image6]
                            : "Images url 6"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image6");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image6"]}
                      </p>
                    </div>
                    <div
                      style={{ height: "100px", marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 7
                      </p>
                      <Select
                        className={badColumnSelection.includes("image7") && "red-select"}
                        value={
                          indexColumn.image7 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image7 !== undefined
                            ? headersColumns[indexColumn.image7]
                            : "Images url 7"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image7");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image7"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 8
                      </p>
                      <Select
                        className={badColumnSelection.includes("image8") && "red-select"}
                        value={
                          indexColumn.image8 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image8 !== undefined
                            ? headersColumns[indexColumn.image8]
                            : "Images url 8"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image8");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image8"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 9
                      </p>
                      <Select
                        className={badColumnSelection.includes("image9") && "red-select"}
                        value={
                          indexColumn.image9 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image9 !== undefined
                            ? headersColumns[indexColumn.image9]
                            : "Images url 9"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image9");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image9"]}
                      </p>
                    </div>
                    <div
                      style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                        Images url 10
                      </p>
                      <Select
                        className={badColumnSelection.includes("image10") && "red-select"}
                        value={
                          indexColumn.image10 === "manually"
                            ? "Fill in manually"
                            : indexColumn.image10 !== undefined
                            ? headersColumns[indexColumn.image10]
                            : "Images url 10"
                        }
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          selectLabel(value, "image10");
                        }}
                        defaultValue={"Images url"}
                      >
                        <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                          {t("importProductCsv.fillManually")}
                        </Select.Option>
                        {headersColumns.map((header, index) => {
                          if (!indexSelect.includes(index))
                            return <Select.Option value={index}>{header}</Select.Option>;
                        })}
                      </Select>
                      <p
                        className="text-ellipsis grey"
                        style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                      >
                        {labelSelectedPreview["image10"]}
                      </p>
                    </div>
                  </>
                )}
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.15")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("processing_time") && "red-select"}
                    value={
                      indexColumn.processing_time === "manually"
                        ? "Fill in manually"
                        : indexColumn.processing_time !== undefined
                        ? headersColumns[indexColumn.processing_time]
                        : "Processing time"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "processing_time");
                    }}
                    defaultValue={"Processing time"}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["processing_time"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.16")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("variantType1") && "red-select"}
                    value={
                      indexColumn.variantType1 === "manually"
                        ? "Fill in manually"
                        : indexColumn.variantType1 !== undefined
                        ? headersColumns[indexColumn.variantType1]
                        : "Variants 1 type"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "variantType1");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["variantType1"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.17")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("variants1") && "red-select"}
                    value={
                      indexColumn.variant === "manually"
                        ? "Fill in manually"
                        : indexColumn.variants1 !== undefined
                        ? headersColumns[indexColumn.variants1]
                        : "Variants 1 values"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "variants1");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["variants1"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.18")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("variantType2") && "red-select"}
                    value={
                      indexColumn.variantType2 === "manually"
                        ? "Fill in manually"
                        : indexColumn.variantType2 !== undefined
                        ? headersColumns[indexColumn.variantType2]
                        : "Variants 2 type"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "variantType2");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["variantType2"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.19")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("variants2") && "red-select"}
                    value={
                      indexColumn.variant === "manually"
                        ? "Fill in manually"
                        : indexColumn.variants2 !== undefined
                        ? headersColumns[indexColumn.variants2]
                        : "Variants 2 value"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "variants2");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>

                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["variants2"]}
                  </p>
                </div>

                {/* VARIANT TYPE 3 */}
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.22")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("variantType3") && "red-select"}
                    value={
                      indexColumn.variantType3 === "manually"
                        ? "Fill in manually"
                        : indexColumn.variantType3 !== undefined
                        ? headersColumns[indexColumn.variantType3]
                        : "Variants 3 type"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "variantType3");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["variantType3"]}
                  </p>
                </div>

                {/* VARIANT 3 */}

                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    {t("importProductCsv.23")}
                  </p>
                  <Select
                    className={badColumnSelection.includes("variants3") && "red-select"}
                    value={
                      indexColumn.variant === "manually"
                        ? "Fill in manually"
                        : indexColumn.variants3 !== undefined
                        ? headersColumns[indexColumn.variants3]
                        : "Variants 2 value"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "variants3");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["variants3"]}
                  </p>
                </div>
                <div style={{ marginRight: "20px", marginLeft: "0px", width: "300px" }} className="column">
                  <p style={{ marginBottom: "5px", fontSize: "22px" }} className="title">
                    Tags
                  </p>
                  <Select
                    className={badColumnSelection.includes("tag") && "red-select"}
                    value={
                      indexColumn.tag === "manually"
                        ? "Fill in manually"
                        : indexColumn.tag !== undefined
                        ? headersColumns[indexColumn.tag]
                        : "Tags"
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      selectLabel(value, "tag");
                    }}
                  >
                    <Select.Option style={{ fontWeight: "bold" }} value={"manually"}>
                      {t("importProductCsv.fillManually")}
                    </Select.Option>
                    {headersColumns.map((header, index) => {
                      if (!indexSelect.includes(index))
                        return <Select.Option value={index}>{header}</Select.Option>;
                    })}
                  </Select>
                  <p
                    className="text-ellipsis grey"
                    style={{ width: "200px", fontSize: "13px", marginTop: "3px" }}
                  >
                    {labelSelectedPreview["tag"]}
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  setLoad(true);
                  setTimeout(() => {
                    columnSelectionDone();
                  }, 300);
                }}
                className="button big orange-back"
                style={{ width: "300px", minHeight: "50px" }}
              >
                {t("importProductCsv.20")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportCSV;
