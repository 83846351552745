import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../wrappers";

import "./style.scss";

const StockEvolution = ({ product, setIsDisplayed }) => {
  const { t } = useTranslation();

  const isVariant = product.variants?.length && product.variantBool;

  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000"
      visible={true}
      onCancel={() => {
        setIsDisplayed(false);
      }}
      footer={[]}
    >
      <div className="column start width-100">
        <div className="row cost-evolution">
          <div style={{ marginRight: "40px" }} className="column">
            <p style={{ fontSize: "16px" }} className="title">
              {t("variantName")}
            </p>
            {isVariant ? (
              product.variants.map((variant) => (
                <p className="text">{`${variant.option1} ${variant.option2 && " / " + variant.option2}${
                  variant.option3 && " / " + variant.option3
                }`}</p>
              ))
            ) : (
              <p className="text">{product.name}</p>
            )}
          </div>
          <div className="column">
            <p style={{ fontSize: "16px" }} className="title">
              {t("stock")}
            </p>

            {isVariant ? (
              product.variants.map((variant) => (
                <p className={variant.stock < 1 ? "text-bold red" : "text"}>{variant.stock}</p>
              ))
            ) : (
              <p className="text">{product.stock}</p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StockEvolution;
