import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

import CountryCurrencySelector from "../../components/CountryCurrencySelector";

import facebookIcon from "../../images/icons_white/facebook.png";
import instagramIcon from "../../images/icons_white/instagram.png";
import linkedinIcon from "../../images/icons_white/linkedin.png";
import youtubeIcon from "../../images/icons_white/youtube.png";
import frenchIcon from "../../images/flag/french.png";
import englishIcon from "../../images/flag/english.png";

const divStyle = {
  flex: "1",
  float: "left",
  width: "13vw",
  minWidth: "150px",
  marginLeft: "4vw",
  marginRight: "4vw",
};

const Footer = ({ windowSize, location }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container-footer">
      {/* FULL DIV 9*/}
      {!localStorage.getItem("typeAccountCreatosell") && (
        <div className="column center" style={{ width: "100%", backgroundColor: "#F6F6F6" }}>
          <div className="row" style={{ width: "100%", justifyContent: "center" }}>
            {/* PAGE DIV 9*/}
            <div
              className="row"
              style={
                windowSize > 1150
                  ? { display: "flex", width: "80%" }
                  : { width: "100%", flexDirection: "column" }
              }
            >
              {/* text */}
              <div style={{ flex: "1", float: "left" }}>
                <h2
                  style={
                    windowSize > 1150
                      ? {
                          fontSize: "30px",
                          textAlign: "left",
                          width: "100%",
                          fontWeight: "700",
                          color: "#585858",
                          fontFamily: "Montserrat",
                          lineHeight: "2.5vw",
                        }
                      : {
                          marginLeft: "0px",
                          fontSize: "25px",
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          color: "#585858",
                          marginBottom: "20px",
                        }
                  }
                >
                  {t("components.menu.12")} <br />
                  {t("components.menu.13")}
                </h2>
              </div>
              {/* buttons */}
              <div style={{ flex: "1", float: "right", textAlign: "-webkit-right" }}>
                <div>
                  <Link
                    className="button orange-back"
                    style={
                      windowSize > 1150
                        ? { height: "35px", width: "230px" }
                        : { height: "50px", marginBottom: "10px" }
                    }
                    to={
                      location === "reseller"
                        ? routes.joinUs(i18n.language).path + "?reseller"
                        : location === "supplier"
                        ? routes.joinUs(i18n.language).path + "?supplier"
                        : "/"
                    }
                  >
                    {t("components.menu.14")}{" "}
                    {location === "reseller" ? t("reseller").toUpperCase() : t("supplier").toUpperCase()}
                  </Link>
                </div>
                <div>
                  {location === "reseller" ? (
                    <p className="grey" style={{ fontSize: "16px" }}>
                      {t("or")} -{" "}
                      <a className="text blue" href="https://01supply.com/sell-on-01supply">
                        {t("components.menu.15")}
                      </a>
                    </p>
                  ) : (
                    <p className="grey" style={{ fontSize: "16px" }}>
                      {t("or")} -{" "}
                      <a className="text blue" href="https://01supply.com">
                        {t("components.menu.16")}
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* FULL DIV 10*/}
      <div
        className="column center back-footer"
        style={
          windowSize > 1150 ? { width: "100vw", paddingTop: "10vw" } : { width: "100%", paddingTop: "200px" }
        }
      >
        <div className="row" style={{ width: "100%", height: "auto", justifyContent: "center" }}>
          {/* PAGE DIV 10*/}
          <div
            className="row"
            style={
              windowSize > 1150
                ? { display: "flex", width: "100%", alignItems: "flex-start" }
                : { flexDirection: "column", width: "100%", alignItems: "flex-start" }
            }
          >
            {/* footer 1 */}
            <div style={divStyle}>
              <h3
                className="white start"
                style={
                  windowSize > 1150
                    ? {
                        fontSize: "20px",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Roboto",
                        color: "white",
                      }
                    : { marginLeft: "0px", fontSize: "20px" }
                }
              >
                RESSOURCES
              </h3>
              <nav className="column">
                <a style={{ color: "white" }} href="https://blog.01supply.com">
                  Blog
                </a>
                <Link style={{ color: "white" }} to={routes.resellerPricing(i18n.language).path}>
                  {t("pricing")}
                </Link>
                <a style={{ color: "white" }} href="https://www.01supply.com/help">
                  Documentation
                </a>
              </nav>
            </div>
            {/* footer 2 */}
            <div className="column" style={divStyle}>
              <h3
                className="white"
                style={
                  windowSize > 1150
                    ? {
                        fontSize: "20px",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Roboto",
                        color: "white",
                      }
                    : { marginLeft: "0px", fontSize: "20px", marginTop: "30px" }
                }
              >
                {t("joinUs").toUpperCase()}
              </h3>
              <nav className="column start">
                <Link style={{ color: "white" }} to={routes.joinUs(i18n.language).path + "?reseller"}>
                  {t("components.menu.17")}
                </Link>
                <a href="https://www.01supply.com/sell-on-01supply" style={{ color: "white" }}>
                  {t("components.menu.18")}
                </a>
                <a style={{ color: "white" }} href="https://01supply.com/affiliation">
                  {t("affiliationProgram")}
                </a>
              </nav>
            </div>
            {/* footer 3 */}
            <div style={divStyle}>
              <h3
                className="white start"
                style={
                  windowSize > 1150
                    ? {
                        fontSize: "20px",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Roboto",
                        color: "white",
                      }
                    : { marginLeft: "0px", fontSize: "20px", marginTop: "30px" }
                }
              >
                INTEGRATIONS
              </h3>
              <nav className="column start">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                  href="https://apps.shopify.com/01supply"
                >
                  Shopify
                </a>
                <a
                  style={{ color: "white" }}
                  href="https:///blog.01supply.com/how-to-import-etsy-products-to-shopify"
                >
                  Etsy
                </a>
                <a style={{ color: "white" }} href="https://blog.01supply.com/creer-boutique-dropizi">
                  Dropizi
                </a>
              </nav>
            </div>
            {/* footer 3 */}
            <div style={divStyle}>
              <h3
                className="white start"
                style={
                  windowSize > 1150
                    ? {
                        fontSize: "20px",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Roboto",
                        color: "white",
                      }
                    : { marginLeft: "0px", fontSize: "20px", marginTop: "30px" }
                }
              >
                LEGAL
              </h3>
              <nav className="column start">
                <a style={{ color: "white" }} href="https://01supply.com/privacy-policy">
                  {t("components.menu.19")}
                </a>
                <a style={{ color: "white" }} href="https://01supply.com/legal-notice">
                  {t("components.menu.20")}
                </a>
                <a style={{ color: "white" }} href="https://01supply.com/terms-of-use">
                  {t("components.menu.21")}
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px", marginLeft: "7vw" }} className="row width-100">
          <a href="https://www.facebook.com/01supply" target="_blank" rel="noreferrer">
            <img
              style={{ width: "25px", marginRight: "12px" }}
              src={facebookIcon}
              alt="01supply facebook icon"
            />
          </a>
          <a href="https://www.instagram.com/01supply_/" target="_blank" rel="noreferrer">
            <img
              style={{ width: "25px", marginRight: "12px" }}
              src={instagramIcon}
              alt="01supply facebook icon"
            />
          </a>
          <a href="https://www.linkedin.com/company/69418994" target="_blank" rel="noreferrer">
            <img
              style={{ width: "25px", marginRight: "12px" }}
              src={linkedinIcon}
              alt="01supply facebook icon"
            />
          </a>
          <a href="https://www.youtube.com/channel/UCeep3moyj-fAcENu-z884VA" target="_blank" rel="noreferrer">
            <img
              style={{ width: "25px", marginRight: "12px" }}
              src={youtubeIcon}
              alt="01supply facebook icon"
            />
          </a>
        </div>

        <CountryCurrencySelector selectorStyle={{ color: "white", marginTop: "20px" }} />
        <div style={{ marginTop: "30px" }}>
          <p style={{ color: "white" }}>© 2022 - 01supply.com</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
