import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Tree } from "antd";

import { Modal } from "../../wrappers";
import countryList from "../../utils/country_list.json";

const SelectShipping = ({ setSelectShipping, editShippingOrigin, index, shipping }) => {
  const [shippingSelect, setShippingSelect] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [dontSaveModal, setDontSaveModal] = useState(false);

  const { t } = useTranslation();

  const onSelect = (selectedKeys, info) => {};

  const onCheck = (checkedKeys, info) => {
    setShippingSelect(checkedKeys.toString());
    setIsChange(true);
  };

  const handleValid = () => {
    if (shippingSelect) {
      editShippingOrigin(shippingSelect);
    }
    setSelectShipping("");
  };

  useEffect(() => {
    if (shipping && shipping.origin)
      setShippingSelect(Array.isArray(shipping.origin) ? shipping.origin : shipping.origin.split(","));
  }, [shipping]);

  return (
    <Modal
      className="modal-order"
      title={t("selectAvailableShipping")}
      visible={true}
      onCancel={() => {
        if (isChange) setDontSaveModal(true);
        else setSelectShipping("");
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            if (isChange) setDontSaveModal(true);
            else setSelectShipping("");
          }}
        >
          {t("cancel")}
        </Button>,
      ]}
    >
      {dontSaveModal && (
        <Modal
          className="modal-order"
          title={t("components.supplier.1")}
          visible={true}
          onCancel={() => {
            setSelectShipping("");
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setSelectShipping("");
              }}
            >
              {t("cancel")}
            </Button>,
          ]}
        >
          <div className="column-center">
            <p className="title">{t("components.supplier.2")}</p>
            <button
              style={{ marginTop: "20px", marginBottom: "15px" }}
              onClick={() => {
                setSelectShipping("");
              }}
              className="button-red"
            >
              {t("components.supplier.3")}
            </button>
            <button onClick={handleValid} className="button orange-back">
              {t("components.supplier.4")}
            </button>
          </div>
        </Modal>
      )}
      <div className="column">
        <button
          style={{ marginBottom: "20px", width: "100%", height: "40px", fontSize: "18px" }}
          className="button orange-back"
          onClick={handleValid}
        >
          {t("components.supplier.5")}
        </button>
        <Tree
          checkable
          defaultExpandedKeys={
            shipping && Array.isArray(shipping.origin)
              ? shipping.origin
              : shipping && shipping.origin
              ? shipping.origin.split(",")
              : []
          }
          defaultCheckedKeys={
            shipping && Array.isArray(shipping.origin)
              ? shipping.origin
              : shipping && shipping.origin
              ? shipping.origin.split(",")
              : []
          }
          onSelect={onSelect}
          onCheck={onCheck}
          treeData={countryList}
        />
      </div>
    </Modal>
  );
};

export default SelectShipping;
