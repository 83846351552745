import React, { useState, useEffect } from "react";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import { userServices } from "../../services/user";

import courseImg from '../../images/courses/not_available.png'

const Setup = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [user, setUser] = useState({});
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  useEffect(() => {
    userServices
      .me()
      .then((res) => res.json())
      .then((res) => setUser(res.data));
  }, [idAccount, secretId]);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="page">
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150 ? { paddingLeft: "30px", paddingTop: "150px" } : { paddingBottom: "100px" }
          }
          className="column-scrolling"
        >
          {windowSize > 1150 ? (
            <Header location="ACADEMY" attribut="ACADEMY" />
          ) : (
            <MobileHeader nameHeader={"ACADEMY"} location="ACADEMY" />
          )}

          <div
            className="column"
            style={windowSize > 1150 ? { width: "80%" } : { width: "90%", marginLeft: "5%" }}
          >
            {user.courses?.length > 0 && <img style={{ width: "300px" }} src={courseImg} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;
