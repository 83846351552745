import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const FixedImportButton = ({ windowSize, handleClickPush, productSelection }) => {
  const [isSticky, setIsSticky] = useState(false);

  const { t } = useTranslation();

  const columnScrollingElement = document.getElementsByClassName("column-scrolling")?.[0];

  useEffect(() => {
    if (columnScrollingElement && !window.location.href.includes("suggestion")) {
      columnScrollingElement.onscroll = function (e) {
        if (columnScrollingElement?.scrollTop > 290) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
    }
  }, [columnScrollingElement]);

  if (!isSticky) {
    return <></>;
  }

  return (
    <button onClick={handleClickPush} className="button orange-back fixed-import-button">
      {productSelection.length ? t("importListPage.13") : t("importListPage.9")}
    </button>
  );
};

export default FixedImportButton;
