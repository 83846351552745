import React, { useState } from "react";
import { Checkbox, Dropdown, Space, Button, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {Modal} from '../../../wrappers'

import "./style.scss"

const SelectorActions = ({
  productSelection,
  setProductSelection,
  setIsExportDisplayed,
  deleteProductF,
  setLoad,
  products,
}) => {
  const { t } = useTranslation();
  const [isDeleteModelDisplayed, setIsDeleteModelDisplayed] = useState(false);

  const menu = (
    <Menu className="inventory-dropdown">
      <Menu.Item
        style={{ fontSize: "14px" }}
        className="text-300"
        key="0"
        onClick={() => setIsExportDisplayed(true)}
      >
        {t("components.generateCsv.1")}
      </Menu.Item>
      {productSelection.length > 0 && (
        <Menu.Item
          style={{ fontSize: "14px" }}
          className="red text-300"
          key="1"
          onClick={() => setIsDeleteModelDisplayed(true)}
        >
          {t("delete")}
        </Menu.Item>
      )}
    </Menu>
  );

  const handleDeleteProducts = () => {
    setLoad(true);
    Promise.all(productSelection.map(async (productId) => await deleteProductF({ productId }))).then(
      (res) => {
        setLoad(false);
        setIsDeleteModelDisplayed(false);
      }
    );
  };

  const handleSelect = () => {
    if (productSelection.length) {
      setProductSelection([]);
    } else {
      setProductSelection(products.map((product) => product._id));
    }
  };

  return (
    <div className="inventory selector">
      {isDeleteModelDisplayed && (
        <Modal
          className="modal-no-ok"
          title={t("deleteProduct")}
          onOk={handleDeleteProducts}
          visible={true}
          onCancel={() => setIsDeleteModelDisplayed(false)}
          footer={[
            <Button key="back" onClick={() => setIsDeleteModelDisplayed(false)}>
              {t("no")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleDeleteProducts}>
              {t("yes")}
            </Button>,
          ]}
        >
          <p className="title">{t("sureToDeleteProducts", { count: productSelection.length })}</p>
        </Modal>
      )}
      <div className="row row-1">
        <Checkbox onClick={handleSelect} checked={productSelection.length > 0} />
        <p style={{ marginLeft: "10px" }}>
          {productSelection.length} {t("selected")}
        </p>
      </div>
      <div className="row row-2">
        <Dropdown overlay={menu} trigger={["click"]}>
          <p onClick={(e) => e.preventDefault()}>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </p>
        </Dropdown>
      </div>
    </div>
  );
};

export default SelectorActions;
