export const resellerCoaching = {
  starter: {
    price_id:
      process.env.REACT_APP_MODE_ENV === "true"
        ? "price_1LybUmKleZ50Ivn6q1b2uUoJ"
        : "price_1LzQeiKleZ50Ivn6YPulBIvX",
    price: "69.99",
    name: "starter",
    description: "description",
    imagePath: "/resellerCoachingImg/starter_button.png",
  },
  advanced: {
    price_id:
      process.env.REACT_APP_MODE_ENV === "true"
        ? "price_1LybVgKleZ50Ivn6sewbYYP6"
        : "price_1LzQfwKleZ50Ivn6HDacBoSf",
    price: "99.99",
    name: "advanced",
    description: "description",
    imagePath: "/resellerCoachingImg/advanced_button.png",
  },
  pro: {
    price_id:
      process.env.REACT_APP_MODE_ENV === "true"
        ? "price_1Lz1PIKleZ50Ivn6AZOmsMH7"
        : "price_1LzQhTKleZ50Ivn6QT8S4HLE",
    price: "499.99",
    name: "pro",
    description: "description",
    imagePath: "/resellerCoachingImg/pro_button.png",
  },
};
