import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { url } from "../api/url";
import { header } from "../api/header";
import { routes } from "../routes";

const RedirectLogin = ({ redirectMarket }) => {
  const [redirect, setRedirect] = useState(false);
  const [redirectToMarket, setRedirectToMarket] = useState(false);
  const { i18n } = useTranslation();

  function getPathAndParams(url) {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname + parsedUrl.search;
    } catch (error) {
      console.error('URL invalide', error);
      return null;
    }
  }

  useEffect(() => {
    if (
      !localStorage.getItem("tokenCreatosell") ||
      !localStorage.getItem("typeAccountCreatosell") ||
      !localStorage.getItem("emailAccountCreatosell") ||
      !localStorage.getItem("idAccountCreatosell") ||
      !localStorage.getItem("secretAccountId01supply") ||
      localStorage.getItem("secretAccountId01supply") == "undefined" ||
      (window.location.href && window.location.href.includes("disconnect"))
    ) {
      localStorage.setItem("loginRedirect", getPathAndParams(window.location.href))
      disconnect();
    } else {
      checkToken();
    }
  }, []);

  const checkToken = async () => {
    const res = await fetch(`${url}/check-token`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        token: localStorage.getItem("tokenCreatosell"),
        email: localStorage.getItem("emailAccountCreatosell"),
      }),
    });
    if (res.status !== 200) {
      disconnect();
    } else if (redirectMarket && redirectMarket === true) {
      setRedirectToMarket(true);
    }
  };

  const disconnect = () => {
    // set algolia analytics user id
    window.aa("setUserToken", "");
    localStorage.setItem("tokenCreatosell", "");
    localStorage.setItem("typeAccountCreatosell", "");
    localStorage.setItem("emailAccountCreatosell", "");
    localStorage.setItem("idAccountCreatosell", "");
    localStorage.setItem("nameAccountCreatosell", "");
    localStorage.setItem("secretAccountId01supply", "");
    setRedirect(true);

    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to={routes.joinUs(i18n.language).path} />}
      {redirectToMarket && <Redirect to={routes.marketplace(i18n.language).path} />}
    </React.Fragment>
  );
};

export default RedirectLogin;
