import React from "react";
import { useTranslation } from "react-i18next";
import iconCheck from "../../../images/icons/Check.svg";

const PricingCard2 = ({ windowSize, button }) => {
  const { t } = useTranslation();

  return (
    <div className="pricing-card">
      <h2 style={{ marginBottom: "0" }} className="title-bold">
        {t("resellerPricing.6")}
      </h2>
      <p style={{ fontSize: "16px" }} className="title-300">
        {t("resellerPricing.7")}
      </p>
      <p style={{ marginTop: "10px", fontSize: "35px" }} className="title-bold">
        $0
        <span style={{ marginLeft: "10px", fontSize: "19px" }} className="title-300">
          /mo
        </span>
      </p>
      {button}
      <div
        className="divider-horizontal"
        style={{ width: "100%", marginTop: "25px", marginLeft: "0", marginBottom: "25px" }}
      />
      <div style={{ width: "100%" }} className="column start">
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px", fontWeight: "bold" }}>
            {t("resellerPricing.8")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.9")}
          </p>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
          <p className="title-300" style={{ fontSize: "13px" }}>
            {t("resellerPricing.20")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingCard2;
