import React, { useState } from "react";
import { Pagination } from "antd";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../wrappers";

import CardProduct from "./CardProduct";

const ModalImportProductCsv = ({
  windowSize,
  products,
  setProducts,
  setLoad,
  user,
  noImportList,
  setNoImportList,
  getUser,
  disabledModalApplyShippingAll,
  setDisabledModalApplyShippingAll,
  disabledModalApplyProcessingTimeAll,
  setDisabledModalApplyProcessingTimeAll,
  setProcessingTimeToAll,
  processingTimeToAll,
  disabledModalApplyOriginAll,
  setDisabledModalApplyOriginAll,
  importProducts,
}) => {
  const PAGE_SIZE = 15;
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const isProductVisible = (index) => index >= page * PAGE_SIZE - PAGE_SIZE && index < page * PAGE_SIZE;
  return (
    <Modal
      id="products-list"
      style={{ width: "90%" }}
      className="modal-import-csv"
      visible={true}
      onCancel={() => setProducts([])}
      footer={[]}
    >
      <div className="column width-100" style={{ position: "relative", alignItems: "center" }}>
        {products.map((product, index) => {
          if (product?.photos) {
            return (
              <CardProduct
                isVisible={isProductVisible(index)}
                windowSize={windowSize}
                product={product}
                index={index}
                products={products}
                setProducts={setProducts}
                user={user}
                noImportList={noImportList}
                setNoImportList={setNoImportList}
                getUser={getUser}
                disabledModalApplyShippingAll={disabledModalApplyShippingAll}
                setDisabledModalApplyShippingAll={setDisabledModalApplyShippingAll}
                disabledModalApplyProcessingTimeAll={disabledModalApplyProcessingTimeAll}
                setDisabledModalApplyProcessingTimeAll={setDisabledModalApplyProcessingTimeAll}
                setProcessingTimeToAll={setProcessingTimeToAll}
                processingTimeToAll={processingTimeToAll}
                disabledModalApplyOriginAll={disabledModalApplyOriginAll}
                setDisabledModalApplyOriginAll={setDisabledModalApplyOriginAll}
              />
            );
          }
        })}
        <div className="import-csv-modal-footer">
          <button
            style={{ height: "60px" }}
            className="button big orange-back"
            onClick={() => {
              setLoad(true);
              importProducts();
            }}
          >
            {t("importProductCsv.1")}
          </button>
          <Pagination
            key={products.length}
            showSizeChanger={false}
            onChange={(page, pageSizeP) => {
              setPage(page || 1);

              scroll.scrollToTop();
            }}
            total={products.length}
            pageSize={PAGE_SIZE}
            defaultCurrent={page || 1}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalImportProductCsv;
