import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Select, Dropdown, Input, Drawer, InputNumber, Popover } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import countryListJson from "../../utils/country_list.json";
import { LocalStatesContext } from "../../providers/index";
import { productServices } from "../../services/products";
import { url } from "../../api/url";

const { Option } = Select;

const CountrySelector = ({
  setState,
  state,
  attribut,
  setCountrySearch,
  countrySearch,
  countryList,
  findProducts,
  t,
  setOriginRegion,
  originRegion,
  isOriginFrom,
  setLocationSelect,
  setLocalStates,
  localStates,
}) => {
  const handleChange = (value) => {
    const isRegion = typeof value === "string" && value?.includes("0-");

    if (isRegion) {
      setOriginRegion(value);
      setLocationSelect({});
      findProducts({
        params: {
          regionOrigin: value,
          locationSelect: { title: "", key: "" },
        },
      });
    } else {
      const country = countryList[parseInt(value)];
      setOriginRegion("");
      setCountrySearch({ ...countrySearch, [attribut]: "" });
      setState(country);
      findProducts({
        params: {
          [attribut]: country,
          regionOrigin: "",
        },
      });
      if (attribut === "deliverTo" && setLocalStates) {
        localStorage.setItem("deliverTo", country.title);
        setLocalStates({ ...localStates, deliverTo: country.title });
      }
    }
  };
  return (
    <Select
      suffixIcon={<SearchOutlined />}
      showSearch
      open={!!countrySearch[attribut]}
      filterOption={(input, option) => {
        if (!countrySearch[attribut] || !input) {
          setCountrySearch({ ...countrySearch, [attribut]: input });
        }
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      filterSort={(optionA, optionB) =>
        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
      }
      style={{ width: "90%", marginLeft: "0%" }}
      onChange={handleChange}
      value={isOriginFrom && originRegion === "0-2" ? "🇪🇺 Europe" : state.title || t("components.menu.7")}
    >
      {isOriginFrom && <Option value={"0-2"}>🇪🇺 Europe</Option>}
      {countryList.map((country, index) => {
        if (country.title !== "Rest of world") return <Option value={index}>{country.title}</Option>;
      })}
    </Select>
  );
};

const ContentMenu = ({
  searchWord,
  setSearchWord,
  setCategorySelect,
  setCurrentPage,
  setTotalProducts,
  page,
  setProducts,
  thematicsList,
  categorySelect,
  setNoResult,
  windowSize,
  setLoadProducts,
  setMobileMenuOn,
  mobileMenuOn,
  refreshProductsFunction,
  sorting,
  setSorting,

  minPrice,
  setMinPrice,
  maxPrice,
  setMaxprice,
  locationSelect,
  setLocationSelect,
  deliverTo,
  setDeliverTo,
  maxDeliveryTime,
  setMaxDeliveryTime,
  originRegion,
  setOriginRegion,
  sortPrice,
  setSortPrice,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [menuFixed, setMenuFixed] = useState(false);
  const [lastQuery, setLastQuery] = useState("");
  const [countrySearch, setCountrySearch] = useState({});

  // FILTER SAVE
  const [thematicSelect, setThematicSelect] = useState({});
  const [isHighMarginProducts, setIsHighMarginProducts] = useState(false);
  const [isWinningProducts, setIsWinningProducts] = useState(false);
  const [isHistory, setIsFirstHistory] = useState(true);
  const [isPriceRangeVisible, setIsPriceRangeVisible] = useState(false);
  const [isShipOriginDisplay, setIsShipOriginDisplay] = useState(false);
  const [localMaxDeliveryTime, setLocalMaxDeliveryTime] = useState(maxDeliveryTime || 0);

  const [isInit, setIsInit] = useState(false);

  const isFiltersAnable =
    setLocationSelect && setDeliverTo && setMaxDeliveryTime && setMinPrice && setMaxprice;

  const { localStates, setLocalStates } = useContext(LocalStatesContext);

  const { t } = useTranslation();

  const history = useHistory();

  const isSuggestion = window.location.pathname.includes("/suggestion");

  // GET CURRENT DEFAULT URL PARAMS TO QUERY PRODUCTS
  const getDefaultQueryParams = async (search) => {
    const query = Object.fromEntries(new URLSearchParams(search)) || {};
    if (query && !isInit) {
      const maxPrice = query.maxPrice || 0;
      const minPrice = query.minPrice || 0;
      const locationSelect =
        query.origin && query.origin !== "undefined" ? { title: query.origin, key: query.originKey } : {};
      const deliverTo =
        query.deliverTo && query.deliverTo !== "undefined"
          ? { title: query.destinationName, key: query.deliverTo }
          : {};
      const thematicSelect =
        query.thematicName && query.thematicName !== "undefined" ? { name: query.thematicName } : "";
      const page = query.page || 1;
      const categorySelect = query.category && query.category !== "undefined" ? query.category : "";
      const searchWord = query.search && query.search !== "undefined" ? query.search : "";

      const isHighMargin = query.isHighMarginProducts === "true" ? "true" : "";
      const isWinning = query.isWinningProducts === "true" ? "true" : "";

      const sort = query.sort && query.sort !== "undefined" ? query.sort : "";

      const regionOrigin = query.regionOrigin && query.regionOrigin !== "undefined" ? query.regionOrigin : "";

      const maxDeliveryTimeQuery =
        query.maxDeliveryTime && query.maxDeliveryTime !== "undefined" ? query.maxDeliveryTime : "";

      await setOriginRegion(regionOrigin);

      await setSorting(sort);

      if (setSortPrice) {
        await setSortPrice(sortPrice);
      }

      await setIsHighMarginProducts(isHighMargin);
      await setIsWinningProducts(isWinning);

      if (isFiltersAnable) {
        await setMaxprice(maxPrice);
        await setMinPrice(minPrice);
        await setLocationSelect(locationSelect);
        await setDeliverTo(deliverTo);
        await setMaxDeliveryTime(parseInt(maxDeliveryTimeQuery) || 0);
        await setLocalMaxDeliveryTime(maxDeliveryTimeQuery || 0);
      }

      await setThematicSelect(thematicSelect);
      await setCurrentPage(page);
      await setCategorySelect(categorySelect);
      await setSearchWord(searchWord);

      setTimeout(() => {
        findProducts({
          params: {
            maxPrice,
            minPrice,
            locationSelect,
            deliverTo,
            maxDeliveryTime: maxDeliveryTimeQuery,
            thematicSelect,
            categorySelect,
            searchWord,
            page,
            isHighMarginProducts: isHighMargin,
            isWinningProducts: isWinning,
            sort,
            regionOrigin,
          },
        });
      }, 500);
    }

    // GET COUNTRY LIST

    let countryListRes = [];
    for (let region of countryListJson) {
      if (!region.children) countryListRes.push(region);
      else countryListRes = [...countryListRes, ...region.children];
    }
    await setCountryList(countryListRes);
  };

  useEffect(() => {
    getDefaultQueryParams(window.location?.search?.substring(1) || "");
    window.addEventListener("popstate", (event) => {
      getDefaultQueryParams(window.location?.search?.substring(1) || "");
    });
  }, []);

  useEffect(() => {
    window.document.getElementById("root").onclick = () => setCountrySearch({});
  }, []);

  const handleSearchUpdated = async (params) => {
    // await are importants
    await setMaxprice(0);
    await setMinPrice(0);
    await setLocationSelect({});
    await setDeliverTo({});
    await setMaxDeliveryTime(0);
    await setThematicSelect(() => ({ name: "", _id: "" }));

    setCurrentPage(1);
    findProducts({
      params: {
        maxPrice: 0,
        minPrice: 0,
        locationSelect: {},
        deliverTo: {},
        maxDeliveryTime: 0,
        thematicSelect: { name: "", _id: "" },
        categorySelect: params?.categorySelect || categorySelect,
        searchWord: params?.searchWord || searchWord,
        page: 1,
        sort: params.sort,
        isWinningProducts: params?.isWinningProducts || isWinningProducts,
        isHighMarginProducts: params?.isHighMarginProducts || isHighMarginProducts,
      },
    });
  };

  useEffect(() => {
    if (refreshProductsFunction?.params?.page) {
      findProducts({ params: refreshProductsFunction?.params });
    } else if (refreshProductsFunction?.params) {
      handleSearchUpdated(refreshProductsFunction?.params);
    }
  }, [refreshProductsFunction]);

  const findProducts = async ({ params, isCategory }) => {
    if (params?.thematicSelect?.name) {
      setThematicSelect({ name: "", _id: "" });
    }
    setCurrentPage(params?.page || page);
    setLoadProducts(true);
    const query = `?page=${params?.page || page}&category=${
      params?.categorySelect !== undefined ? params?.categorySelect : categorySelect || ""
    }&thematicName=${
      params?.thematicSelect !== undefined ? params?.thematicSelect?.name : thematicSelect.name || ""
    }&origin=${
      params?.locationSelect?.title !== undefined
        ? params?.locationSelect?.title
        : locationSelect?.title || ""
    }&originKey=${
      params?.locationSelect?.key !== undefined ? params?.locationSelect?.key : locationSelect?.key || ""
    }&deliverTo=${
      params?.deliverTo?.key !== undefined ? params?.deliverTo?.key : deliverTo?.key || ""
    }&maxDeliveryTime=${
      params?.maxDeliveryTime !== undefined ? params?.maxDeliveryTime : maxDeliveryTime || ""
    }&destinationName=${
      params?.deliverTo?.title !== undefined ? params?.deliverTo?.title : deliverTo?.title || ""
    }&maxPrice=${params?.maxPrice !== undefined ? params?.maxPrice : maxPrice}&minPrice=${
      params?.minPrice !== undefined ? params?.minPrice : minPrice
    }&search=${
      params?.searchWord !== undefined ? params?.searchWord : searchWord || ""
    }&isHighMarginProducts=${
      params?.isHighMarginProducts !== undefined || isHighMarginProducts
        ? params?.isHighMarginProducts || isHighMarginProducts
        : ""
    }&isWinningProducts=${
      params?.isWinningProducts !== undefined || isWinningProducts
        ? params?.isWinningProducts || isWinningProducts
        : ""
    }&sort=${params?.sort !== undefined || sorting ? params?.sort || sorting : ""}&userId=${
      localStorage.getItem("idAccountCreatosell") || ""
    }&regionOrigin=${params.regionOrigin !== undefined ? params.regionOrigin : originRegion}&currency=${
      localStates.currency
    }&isRecommendation=${isSuggestion || false}&sortPrice=${sortPrice || ""}`;

    if (query !== lastQuery) {
      if (isHistory) {
        history.replace({ search: query });
        setIsFirstHistory(false);
      } else {
        history.push({ search: query });
      }
    }
    setLastQuery(query);
    const res = await productServices.find({ queryParams: query });
    if (res.status === 200) {
      const resJson = await res.json();
      setProducts(resJson.data);
      setTotalProducts(resJson.totalProducts);
      setNoResult(false);
    } else setNoResult(true);

    setIsInit(true);
    setLoadProducts(false);

    setIsPriceRangeVisible(false);
    setIsShipOriginDisplay(false);
  };

  window.addEventListener("scroll", (e) => {
    const x = parseFloat(window.scrollY);
    if (x >= 800 && windowSize > 1150) {
      setMenuFixed(true);
    } else if (menuFixed) setMenuFixed(false);
  });

  return mobileMenuOn && isFiltersAnable ? (
    <div
      id={menuFixed && "menu-filter-marketplace"}
      style={{ paddingTop: "0" }}
      className={windowSize > 1150 ? "row filter-navbar" : "column"}
    >
      <Dropdown
        trigger={["click"]}
        visible={isShipOriginDisplay}
        onVisibleChange={setIsShipOriginDisplay}
        onClick={(e) => e?.stopPropagation()}
        overlay={
          <div className="card">
            <p style={{ marginTop: "15px", marginBottom: "5px" }} className="text-bold">
              {t("components.menu.10")}
            </p>

            <CountrySelector
              attribut="locationSelect"
              state={locationSelect}
              setState={setLocationSelect}
              setCountrySearch={setCountrySearch}
              countrySearch={countrySearch}
              countryList={countryList}
              findProducts={findProducts}
              t={t}
              setOriginRegion={setOriginRegion}
              originRegion={originRegion}
              isOriginFrom={true}
              setLocationSelect={setLocationSelect}
            />
            <p style={{ marginTop: "15px", marginBottom: "5px" }} className="text-bold">
              {t("components.menu.11")}
            </p>
            <CountrySelector
              attribut="deliverTo"
              state={deliverTo}
              setState={setDeliverTo}
              setCountrySearch={setCountrySearch}
              countrySearch={countrySearch}
              countryList={countryList}
              findProducts={findProducts}
              t={t}
              setOriginRegion={setOriginRegion}
              originRegion={originRegion}
              setLocationSelect={setLocationSelect}
              localStates={localStates}
              setLocalStates={setLocalStates}
            />

            <p style={{ marginTop: "15px" }} className="text-bold">
              {t("components.menu.57")}
            </p>
            {!deliverTo?.key && (
              <p style={{ fontSize: "12px", maxWidth: "230px" }} className="text-400 grey">
                ({t("components.menu.58")})
              </p>
            )}
            <InputNumber
              min={0}
              id="max-delivery-time"
              value={!deliverTo?.key ? "" : localMaxDeliveryTime || ""}
              type="number"
              style={{ width: "90%", marginTop: "5px" }}
              disabled={!deliverTo?.key}
              onChange={(value) => {
                setLocalMaxDeliveryTime(parseFloat(value) || 0);
              }}
            />
            {localMaxDeliveryTime !== maxDeliveryTime && (
              <button
                style={{ width: "173px", marginTop: "10px", height: "30px" }}
                onClick={() => {
                  findProducts({ params: { page: 1, maxDeliveryTime: localMaxDeliveryTime } });
                  setMaxDeliveryTime(localMaxDeliveryTime || 0);
                  setCurrentPage(1);
                }}
                className={"button orange-back"}
              >
                {t("apply")}
              </button>
            )}
          </div>
        }
      >
        <div style={{ margin: "10px" }} className="row center">
          <p style={{ marginRight: "8px" }} className="text underline pointer">
            {t("shipFromAnywhere")}
          </p>
          <DownOutlined style={{ fontSize: "11px" }} />
        </div>
      </Dropdown>

      <Dropdown
        visible={isPriceRangeVisible}
        onVisibleChange={setIsPriceRangeVisible}
        overlay={
          <div onClick={(e) => e?.stopPropagation()} className="card">
            <p style={{ marginBottom: "10px" }} className="text-bold">
              {t("price")}
            </p>
            <div style={{ width: "180px" }} className="row">
              <Input
                id="min-price-input"
                value={minPrice}
                style={{ width: "43%", marginRight: "7px" }}
                type="number"
                onChange={(e) => {
                  setMinPrice(parseFloat(e.target.value));
                }}
                placeholder="Min"
              />
              -
              <Input
                id="max-price-input"
                value={maxPrice}
                style={{ width: "43%", marginLeft: "7px" }}
                type="number"
                onChange={(e) => {
                  setMaxprice(parseFloat(e.target.value));
                }}
                placeholder="Max"
              />
            </div>
            <button
              style={{ width: "173px", marginTop: "10px", height: "30px" }}
              onClick={() => {
                setMobileMenuOn(false);
                findProducts({ params: { page: 1 } });
                setCurrentPage(1);
              }}
              className={maxPrice || minPrice ? "button orange-back" : "button-disable-grey"}
            >
              {t("apply")}
            </button>
          </div>
        }
        trigger={["click"]}
      >
        <div style={{ margin: "10px", marginRight: "30px" }} className="row center">
          <p style={{ marginRight: "8px" }} className="text underline pointer">
            {t("priceRange")}
          </p>
          <DownOutlined style={{ fontSize: "11px" }} />
        </div>
      </Dropdown>
    </div>
  ) : (
    <div />
  );
};

export const MarketMenu = (props) => {
  const [mobileMenuOn, setMobileMenuOn] = useState(false);
  const [sorting, setSorting] = useState("default");

  const { t } = useTranslation();

  return (
    <>
      <div>
        {/*
        <Sorting
          setRefreshProductsFunction={props.setRefreshProductsFunction}
          sorting={sorting}
          setSorting={setSorting}
        />*/}
      </div>

      {props.windowSize > 1150 ? (
        <ContentMenu
          {...props}
          sorting={sorting}
          setSorting={setSorting}
          mobileMenuOn={true}
          setMobileMenuOn={props.setFilterMenuOn}
          windowSize={props.windowSize}
        />
      ) : (
        <Drawer
          title="Sort products"
          placement={"right"}
          closable={true}
          onClose={() => {
            setMobileMenuOn(false);
            props.setFilterMenuOn(false);
          }}
          visible={true}
          style={
            !(mobileMenuOn || props.filterMenuOn)
              ? {
                  display: "none",
                  overflow: "hidden",
                  height: "0px",
                  width: "0px",
                  maxHeight: "0px",
                  minHeight: "0px",
                }
              : {}
          }
        >
          <ContentMenu
            {...props}
            sorting={sorting}
            setSorting={setSorting}
            mobileMenuOn={props.filterMenuOn}
            setMobileMenuOn={props.setFilterMenuOn}
            windowSize={props.windowSize}
          />
        </Drawer>
      )}
    </>
  );
};
