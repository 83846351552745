import React, { useState, useEffect } from "react";
import NavBar from "../../../../components/menu/navbar/NavBar";
import Footer from "../../../../components/menu/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Anchor } from "antd";

import img11 from "../../../../images/landing/img11.svg";
import img12 from "../../../../images/landing/img12.svg";
import img13 from "../../../../images/landing/img13.svg";
import img14 from "../../../../images/landing/img14.svg";
import img15 from "../../../../images/landing/img15.svg";
import img16 from "../../../../images/landing/img16.png";
import img17 from "../../../../images/landing/img17.svg";
import img18 from "../../../../images/landing/img18.svg";
import img19 from "../../../../images/landing/img19.svg";
import img20 from "../../../../images/landing/img20.svg";
import img21 from "../../../../images/landing/img20.svg";

import { routes } from "../../../../routes";
import { useTranslation } from "react-i18next";

const BecomeSupplier3 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { i18n } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={
          windowSize > 1150
            ? { paddingLeft: "15vw", paddingRight: "15vw", alignItems: "center", overflowX: "hidden" }
            : { alignItems: "center" }
        }
        className="page"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>How to setup my 01supply supplier account? | 01supply</title>
          <link rel="canonical" href="https://app.01supply.com/how-setup-01supply-account" />
          <meta
            name="description"
            content="Find out how to become a 01 supply supplier to work with resellers and boost sales"
          />
          <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "How to setup my 01supply supplier account?",
          "image": [
            "https://app.01supply.com/static/media/img11.e00ed2be.svg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
        </Helmet>

        <NavBar windowSize={windowSize} />

        <div
          style={windowSize > 1150 ? { position: "absolute", left: "30px", top: "30px" } : { width: "90%" }}
          className="column"
        >
          <p style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }} className="title-roboto">
            Summary
          </p>
          <Anchor affix={windowSize > 1150 ? true : false}>
            <Anchor.Link title="1. Registration" href="#become-supplier-anchor1" className="text-ref" />
            <Anchor.Link title="2. Shop branding" href="#become-supplier-anchor2" className="text-ref" />
            <Anchor.Link title="3. Shipping methods" href="#become-supplier-anchor3" className="text-ref" />
            <Anchor.Link
              title="4. Business information"
              href="#become-supplier-anchor4"
              className="text-ref"
            />
            <Anchor.Link title="5. Payouts" href="#become-supplier-anchor5" className="text-ref" />
            <Anchor.Link
              title="6. Publishing a product"
              href="#become-supplier-anchor6"
              className="text-ref"
            />
            <Anchor.Link title="7. Inviting resellers" href="#become-supplier-anchor7" className="text-ref" />
            <Anchor.Link title="8. Managing orders" href="#become-supplier-anchor8" className="text-ref" />
          </Anchor>
        </div>

        <div className="column" style={windowSize > 1150 ? { width: "60vw" } : { width: "90vw" }}>
          <h1 style={{ fontSize: "40px", marginTop: "50px" }} className="title-roboto">
            How to set up my 01supply account ?
          </h1>

          <h3
            id="become-supplier-anchor1"
            style={{ marginTop: "0px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 1 : Registration
          </h3>
          <p
            style={{ marginTop: "0px", fontSize: "18px", marginBottom: "10px" }}
            className="title-roboto-300"
          >
            Register on
            <Link
              className="title-roboto-300"
              style={{
                textDecoration: "underline",
                marginLeft: "7px",
                marginRight: "7px",
                fontWeight: "300",
              }}
              to={routes.joinUs(i18n.language).path + "?supplier"}
            >
              this page
            </Link>
            with an email and a password.
          </p>
          <img
            className="card-box"
            alt="01supply register page"
            src={img11}
            style={{ width: "100%", height: "auto" }}
          />
          <img
            className="card-box"
            alt="01supply register page password step"
            src={img12}
            style={{ width: "100%", height: "auto", marginTop: "15px" }}
          />

          <h3
            id="become-supplier-anchor2"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 2 : Shop branding
          </h3>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            Fill in your shop branding.
          </p>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Settings {">"} Your shop
          </p>
          <ul>
            <li className="text-roboto-bold">Shop name</li>
            <li className="text-roboto-bold">
              Shop description :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Tell us more about your business
              </span>
            </li>
            <li className="text-roboto-bold">Shop image</li>
          </ul>
          <img
            className="card-box"
            alt="01supply shop branding"
            src={img13}
            style={{ width: "100%", height: "auto" }}
          />

          <h3
            id="become-supplier-anchor3"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 3 : Shipping methods
          </h3>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Settings {">"} Your shop
          </p>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            Set up your shipping methods by clicking “Add a new shipping method”.
          </p>
          <ul>
            <li className="text-roboto-bold">
              Shipping name :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                It is recommended to write your carrier name here. This will help the reseller understand how
                you ship the goods.
              </span>
            </li>
            <li className="text-roboto-bold">
              Shipping zone :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                The country of destination.You can select ”Rest of the world” to ship worldwide (except for
                the countries already mentionned in other shipping methods).
              </span>
            </li>
            <li className="text-roboto-bold">
              Delivery time :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                How many days for the package to reach his destination.
              </span>
            </li>
            <li className="text-roboto-bold">
              Price :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                The amount you charge for this shipping method. It is recommended to offer free shipping.
              </span>
            </li>
          </ul>
          <img
            className="card-box"
            alt="01supply create shipping methods"
            src={img14}
            style={{ width: "100%", height: "auto" }}
          />

          <h3
            id="become-supplier-anchor4"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 4 : Business information
          </h3>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            Fill in your business information.
          </p>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Settings {">"} Your informations
          </p>
          <ul>
            <li className="text-roboto-bold">
              Two email adresses :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                One for resellers to contact you. The other for us to contact you.
              </span>
            </li>
            <li className="text-roboto-bold">
              Legal :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                For legal purpose, we are required to request these informations. VAT is optional.
              </span>
            </li>
          </ul>
          <img
            className="card-box"
            alt="01supply configure my information"
            src={img15}
            style={{ width: "100%", height: "auto" }}
          />

          <h3
            id="become-supplier-anchor5"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 5 : Payouts
          </h3>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Settings {">"} Your informations
          </p>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            In order to receive payments, you must connect your Stripe account to 01supply. For that, click on
            “Connect Stripe”. Then, you can link an existing Stripe account or create a new one.
            <br />
            <br />
            You won’t be able to publish your products if your Stripe account is not verified.
          </p>

          <h3
            id="become-supplier-anchor6"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 6 : Publishing a product
          </h3>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Settings {">"} Inventory
          </p>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="title-roboto-300">
            There are two ways of publishing product.
          </p>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            A. Manual add :
          </p>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Settings {">"} Add a new product
          </p>
          <ul>
            <li className="text-roboto-bold">
              Images :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Import images of your product. Avoid watermarks and branding. These images will be used by
                resellers so be sure to clearly represent your product in order to avoid confusion.
              </span>
            </li>
          </ul>
          <img
            alt="the good product image"
            src={img16}
            style={{ width: "100%", height: "auto", marginBottom: "15px" }}
          />
          <ul>
            <li className="text-roboto-bold">
              Product name :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                This will help resellers find your product in the marketplace.
              </span>
            </li>
            <li className="text-roboto-bold">
              Price and compare at price :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                The price is what resellers will pay once they order from you. Compare at price allows you to
                make special offers. Compare at price must be higher than the price.
              </span>
            </li>
            <li className="text-roboto-bold">
              Quantity :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                How many items you have in stock. Resellers won’t be able to order more than that.
              </span>
            </li>
            <li className="text-roboto-bold">
              Description :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Tell us more about your product (specifications, materials, special instructions, sizing,
                ...). Be sure to include your warranties in the description.
              </span>
            </li>
            <li className="text-roboto-bold">
              Origin :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                The country from where the product is shipped.
              </span>
            </li>
            <li className="text-roboto-bold">
              Processing time :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Maximum time (in days) to ship an order.
              </span>
            </li>
            <li className="text-roboto-bold">
              Shipping methods :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Select the shipping methods associated to this product.
              </span>
            </li>
          </ul>
          <img
            className="card-box"
            alt="01supply create a product"
            src={img17}
            style={{ width: "100%", height: "auto", marginBottom: "20px" }}
          />
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="title-roboto-300">
            -- More options --
          </p>
          <ul>
            <li className="text-roboto-bold">
              Variants :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                They are useful for a product with different sizes and colors. First click on “Add a new
                option” in order to define the variant category (e.g. size, color, ...)
                <br />
                .Then click on “Add a new variant” and fill the variants information. Don’t forget to turn ON
                the “Variant” switch in order to make these visible.
              </span>
            </li>
            <li className="text-roboto-bold">
              Selling limit :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Many suppliers can’t affort selling too much items. So you can set a maximum amount of
                resellers able to sell your product. You can also define a maximum of order per month for this
                specific product.
              </span>
            </li>
            <li className="text-roboto-bold">
              Sorting :
              <span style={{ marginLeft: "5px" }} className="text-roboto-300">
                Choose a category and tags relevant to this product.
              </span>
            </li>
          </ul>
          <img
            className="card-box"
            alt="01supply more options create a product"
            src={img18}
            style={{ width: "100%", height: "auto", marginBottom: "20px" }}
          />
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="title-roboto-300">
            Once finished, click on “Save”.
          </p>

          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            B. Bulk CSV Import :
          </p>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="text-roboto-bold italic">
            Inventory {">"} Import products CSV
          </p>
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="title-roboto-300">
            If you are already selling on other platforms, you can import these products in one click thanks
            to the CSV import feature.
          </p>
          <ul>
            <li className="text-roboto-300">First download your CSV file from the other platform.</li>
            <li className="text-roboto-300">
              Once you have it, you can upload it by clicking “Import CSV” and selecting your file.
            </li>
          </ul>
          <img
            className="card-box"
            alt="import products csv"
            src={img19}
            style={{ width: "100%", height: "auto", marginBottom: "15px" }}
          />
          <ul>
            <li className="text-roboto-300">
              Then select the corresponding columns in order to synchronize the CSV file with the app.
              <br />
              If there is no corresponding columns, select “Fill in manually”.
            </li>
            <li className="text-roboto-300">
              Once filled, click “Import my products”. It might take some time to process.
            </li>
          </ul>
          <img
            className="card-box"
            alt="select csv field product"
            src={img20}
            style={{ width: "100%", height: "auto", marginBottom: "15px" }}
          />
          <ul>
            <li className="text-roboto-300">
              Once the products are imported, fill in the missing fields and verify that everything is
              correct. Then click “Import products”.
            </li>
          </ul>
          <img
            className="card-box"
            alt="import products on 01supply"
            src={img21}
            style={{ width: "100%", height: "auto", marginBottom: "15px" }}
          />
          <p style={{ fontSize: "18px", marginBottom: "20px" }} className="title-roboto-bold italic">
            Congrats ! Your products are now on the marketplace and resellers can start selling them.
          </p>

          <h3
            id="become-supplier-anchor7"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 7 : Inviting resellers
          </h3>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            You can either let resellers come to you or invite them on the app.
          </p>
          <ul>
            <li className="text-roboto-300">
              To invite a reseller click “Invite a reseller” at the top left of your screen.
            </li>
            <li className="text-roboto-300">
              You can either send an invitation email or copy a sharable link.
            </li>
          </ul>

          <h3
            id="become-supplier-anchor8"
            style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }}
            className="title-roboto"
          >
            Step 8 : Managing orders
          </h3>
          <p style={{ fontSize: "18px" }} className="title-roboto-300">
            Your orders will appear in “Orders” section and you will be notified by email once you receive
            one.
            <br />
            <br />
            The more product you add, the more chance you have of getting new orders. Having more products in
            your inventory also decreases the transaction fees you pay.
          </p>

          <div className="column" style={{ alignItems: "center", width: "100%" }}>
            <Link
              style={{ marginBottom: "30px", marginTop: "50px" }}
              className="button big"
              to={routes.joinUs(i18n.language).path + "?supplier"}
            >
              Join us now
            </Link>
            <Link to="/documentation/supplier" className="text-gradient">
              see documentation
            </Link>
            <Link to="/supplier-pricing" className="text-gradient">
              see pricing
            </Link>
            <Link
              style={{ marginTop: "20px", marginBottom: "30px" }}
              to={routes.becomeSupplier2(i18n.language).path}
              className="title-roboto-300"
            >
              {"<"} Back
            </Link>
          </div>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </>
  );
};

export default BecomeSupplier3;
