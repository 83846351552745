import { url } from "../api/url";
import { header } from "../api/header";

export const limitedFeatureUsageServices = {
  get: async (
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/limited-feature-usage/${userId}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      }
    });
  }
};
