/*global chrome*/
import React, { useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { message, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";

import { url } from "../../api/url";
import { header } from "../../api/header";
import { urlParams } from "../../utils/urlParams";

import { klaviyoServices } from "../../services/klaviyo";

import Load from "../../utils/Load";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Remplacez ceci par votre Google Client ID

const GoogleLoginComponent = ({ type, redirectTo, shopify }) => {
  const [redirect, setRedirect] = useState("");
  const [load, setLoad] = useState(false);

  const paramsUrl = urlParams();
  const { t } = useTranslation();

  const isExtensionLogin = new URLSearchParams(window.location.search).get("extensionLogin") === "true";

  const updateCart = async ({ userId, secretId, token }) => {
    const cart = JSON.parse(localStorage.getItem("cart"));
    if (cart && cart.length) {
      fetch(`${url}/user/update-cart/${userId}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          cart,
        }),
      });
    }
  };

  const connect = async (response) => {
    const resConnectJson = await response.json();
    const { user } = resConnectJson;
    const { token, type, email, _id, secret_id } = user;

    updateCart({ userId: _id, secretId: secret_id, token: token });

    localStorage.setItem("tokenCreatosell", token);
    localStorage.setItem("typeAccountCreatosell", type);
    localStorage.setItem("emailAccountCreatosell", email);
    localStorage.setItem("idAccountCreatosell", _id);
    localStorage.setItem("secretAccountId01supply", secret_id);
    localStorage.setItem("viewPlan01supply", "false");
    localStorage.setItem("admin", "false");

    klaviyoServices.checkStatus(_id, token, secret_id);

    setTimeout(() => {
      window.aa("setUserToken", _id);

      if (paramsUrl.reload === "true") {
        window.location.reload();
      } else if (type === "affiliate") {
        setRedirect("/affiliation-links");
        window.location.reload();
      } else if (type === "creater") {
        setRedirect("/supplier-onboarding-step-1");
      } else if (type === "seller") {
        if (window.location.href.includes("/marketplace-home")) {
          window.location.reload();
        } else {
          setRedirect("/marketplace-home");
        }
      } else if (redirectTo) {
        if (redirectTo === "reload") {
          window.location.reload();
        } else {
          window.open(redirectTo);
        }
      } else if (window.location.href.includes("redirect=")) {
        setRedirect("/" + window.location.href.split("redirect=")[1]);
      }
    }, 400);
    setLoad(false);
  };

  const onSuccess = async (response) => {
    setLoad(true);
    const header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${response.accessToken}`,
    };

    let affiliationId = localStorage.getItem("affiliationId");

    try {
      const res = await fetch(`${url}/user/google-login`, {
        method: "POST",
        credentials: "include",
        headers: header,
        body: JSON.stringify({
          token: response.credential,
          type: type,
          affiliationId,
          shopify
        }),
      });

      if (res.status === 200) {
        connect(res);
      } else if (res.status === 403) {
        message.error(t("messages.googleLoginEmailAlreadyExist"));
        setLoad(false);
      } else if (res.status === 401) {
        message.error(t("messages.accountAlreadyExistWithThisShopifyShop"));
        setLoad(false);
      } else {
        message.error(t("messages.error"));
        setLoad(false);
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription avec Google :", error);
      message.error(t("error"));
      setLoad(false);
    }
  };

  const onFailure = (error) => {
    console.log("Échec de la connexion :", error);
    setLoad(false);
    // Votre logique d'échec de connexion ici
  };

  const onSuccessRef = useRef();
  onSuccessRef.current = onSuccess;

  const onFailureRef = useRef();
  onFailureRef.current = onFailure;

  if (isExtensionLogin) {
    return <></>;
  }

  return (
    <div id="01supply-chrome-auth" style={{ width: "100%" }}>
      {redirect && <Redirect to={redirect} />}
      {load && <Load />}
      <Divider />
      <div style={{ width: "100%", minWidth: "100%", maxWidth: "100%" }}>
        <GoogleLogin
          clientId={clientId}
          onSuccess={onSuccessRef.current} // Changement ici
          onFailure={onFailureRef.current} // Changement ici
          cookiePolicy={"single_host_origin"}
          text="continue_with"
          width="100%"
        >
          <button>Login with Google</button>
        </GoogleLogin>
      </div>
    </div>
  );
};

export default GoogleLoginComponent;
