import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { header } from "../../api/header";
import { url } from "../../api/url";
import { message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { checkAccountCompleted } from "../../utils/CheckAccountCompleted";
import { userServices } from "../../services/user";

import icon_import_list from "../../images/icons_grey/Import_list.svg";
import icon_inventory from "../../images/icons_grey/Inventory.svg";
import icon_orders from "../../images/icons_grey/Orders.svg";
import icon_payouts from "../../images/icons_grey/Payouts.svg";
import icon_message from "../../images/icons_grey/message.svg";
import icon_sourcing from "../../images/icons_grey/Sourcing.svg";
import icon_suppliers from "../../images/icons_grey/Suppliers.svg";
import icon_boost from "../../images/icons_grey/growth.png";
import icon_academy from "../../images/icons/academy.png";
import icon_coaching from "../../images/icons/coaching.png";
import AlertIcon from "../../images/icons/alert.png";

import logo from "../../images/logo.svg";
import MyAccount from "./MyAccount";
import { ShopFilled } from "@ant-design/icons";

import ModalStripeInformations from "../modals/ModalStripeInformations";
import CheckPaypal from "../paypal/CheckPaypal";
import SupplierSetupBanner from "./banner/SupplierSetupBanner";
import CheckTokenAndRedirectLogin from "../../utils/RedirectLogin";
import NotConnectedMenu from "./NotConnectedMenu";

import ReconnectWizishopModal from "../modals/ReconnectWizishop";

import CountryCurrencySelector from "../CountryCurrencySelector";
import { t } from "i18next";
import { routes } from "../../routes";

const FirstMenu = ({
  state,
  setState,
  setEditProduct,
  userProps,
  locationP,
  isModalReconnectWizishopDisplayedP,
}) => {
  const [user, setUser] = useState(userProps || {});
  const [location, setLocation] = useState(
    window.location.pathname.replace("/thematics", "").replace("/", "")
  );
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [bannerUpdateStripe, setBannerUpdateStripe] = useState(false);
  const [stripeAccount, setStripeAccount] = useState({});
  const [ordersCount, setOrdersCount] = useState(undefined);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isModalReconnectWizishopDisplayed, setIsModalReconnectWizishopDisplayed] = useState(false);

  const { i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (isModalReconnectWizishopDisplayedP) {
      setIsModalReconnectWizishopDisplayed(true);
    }
  }, [isModalReconnectWizishopDisplayedP]);

  useEffect(() => {
    if (secretId && idAccount && !userProps && !user?._id) {
      getUser();
    } else if (userProps) {
      setUser(userProps);
    }
  }, [userProps]);

  useEffect(() => {
    if (window.location.pathname.includes("marketplace")) {
      setLocation("marketplace");
    } else {
      setLocation(window.location.pathname.replace("/", ""));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (idAccount && typeAccount) {
      fetch(`${url}/user/get-orders-count/${idAccount}/${typeAccount}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((res) => setOrdersCount(res.count));
    }
  }, [idAccount, typeAccount]);

  const getUser = async () => {
    const res = await userServices.me();
    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson) {
        const userGet = resJson.data;
        setUser(userGet);

        if (userGet.type === "creater" && userGet.stripe && userGet.stripe.account_id)
          getStripeInfo(userGet.stripe.account_id, userGet);

        if (
          userGet.type === "creater" &&
          userGet.stripe &&
          !userGet.stripe.account_completed &&
          !userGet.paypal_email_account &&
          !userGet.iban
        ) {
          setBannerUpdateStripe(true);
        }

        if (resJson.needToReconnectWizishop) {
          setIsModalReconnectWizishopDisplayed(true);
        }
      }
    }
  };

  const getStripeInfo = async (accountId, user) => {
    const res = await fetch(`${url}/stripe/account/${accountId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();

      if (resJson) {
        setStripeAccount(resJson.account);
        if (user.stripe && !user.stripe.account_completed && resJson.account.payouts_enabled === true) {
          updateStripeVerification(user);
        }
      }
    }
  };

  const updateStripeVerification = async (user) => {
    const res = await fetch(`${url}/stripe/update/verification/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        user: user,
      }),
    });
    if (res.status === 200) {
      message.success(t("messages.stripeConfigured"));
      setBannerUpdateStripe(false);
    }
  };

  const handleNav = () => {
    if (setEditProduct) setEditProduct({});
    if (state && state.name && setState) {
      setState({});
    }
  };

  const supplierSetupBannerIsDisplay = () => {
    const bannedUrl = [
      "/create-product",
      "/payment-methods",
      "/edit-product",
      "/supplier-onboarding-step-1",
      "/supplier-onboarding-step-2",
      "/supplier-onboarding-step-3",
      "/supplier-onboarding-step-4",
      "/supplier-onboarding-step-5",
    ];
    return (
      user._id &&
      typeAccount === "creater" &&
      windowSize > 1150 &&
      !bannerUpdateStripe &&
      !bannedUrl.includes(window.location.pathname) &&
      checkAccountCompleted(user) === false
    );
  };

  return (
    <>
      {supplierSetupBannerIsDisplay() && <SupplierSetupBanner user={user} />}
      {token && <CheckTokenAndRedirectLogin />}
      {user && user.type === "creater" && <CheckPaypal url={url} user={user} token={token} header={header} />}
      {isModalReconnectWizishopDisplayed && user?._id && (
        <ReconnectWizishopModal
          setModal={setIsModalReconnectWizishopDisplayed}
          modalOn={isModalReconnectWizishopDisplayed}
          user={user}
        />
      )}
      {typeAccount ? (
        <div className="container-menu">
          <div className="board-left-menu montserrat">
            {bannerUpdateStripe && (
              <ModalStripeInformations
                setBannerUpdateStripe={setBannerUpdateStripe}
                user={user}
                stripeAccount={stripeAccount}
              />
            )}
            {windowSize > 1150 && (
              <a
                style={{ width: "100%", maxHeight: "100%", marginBottom: "25px", marginTop: "15px" }}
                onClick={handleNav}
                href="https://01supply.com"
              >
                <img style={{ marginBottom: "0" }} src={logo} alt="supply.me logo" className="logo-menu" />
              </a>
            )}
            {windowSize > 1150 && (
              <CountryCurrencySelector
                selectorStyle={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  maxWidth: "100%",
                }}
              />
            )}

            {typeAccount !== "creater" && (
              <div className="column width-100">
                <div style={{ position: "relative" }}>
                  <Link
                    style={{ fontSize: "14px", zIndex: "2", position: "relative" }}
                    onClick={handleNav}
                    to={routes.marketplaceHome(i18n.language).path}
                    className="title-bold montserrat"
                  >
                    <SearchOutlined style={{ fontSize: "15px", marginRight: "7px" }} />
                    {t("components.menu.51")}
                  </Link>
                  {window.location.href.includes("marketplace") && <div className="menu-orange-back" />}
                </div>
                <Link
                  className={
                    locationP === "marketplace" ? "text-menu-active-2 title montserrat" : "title montserrat"
                  }
                  style={{ fontSize: "13px", marginLeft: "23px", marginTop: "13px" }}
                  onClick={handleNav}
                  to={routes.marketplace(i18n.language).path}
                >
                  {t("components.menu.52")}
                </Link>
                <Link
                  className={
                    locationP === "winningProducts"
                      ? "text-menu-active-2 title montserrat row"
                      : "title montserrat row"
                  }
                  style={{ fontSize: "13px", marginLeft: "23px", marginTop: "6px" }}
                  onClick={handleNav}
                  to={routes.marketplaceWinningProducts(i18n.language).path}
                >
                  {t("components.menu.54")}
                </Link>

                <Link
                  className={
                    locationP === "extension" ? "text-menu-active-2 title montserrat" : "title montserrat"
                  }
                  style={{ fontSize: "13px", marginLeft: "23px", marginTop: "6px" }}
                  onClick={handleNav}
                  to={routes.extensionPromotion(i18n.language).path}
                >
                  {t("components.menu.59")}
                </Link>

                <Link
                  className={
                    locationP === "analyticProducts"
                      ? "text-menu-active-2 title montserrat row"
                      : "title montserrat row"
                  }
                  style={{ fontSize: "13px", marginLeft: "23px", marginTop: "6px" }}
                  onClick={handleNav}
                  to={routes.marketplaceAnalyticProducts(i18n.language).path}
                >
                  {t("components.menu.56")}
                  <div className="new-label">NEW</div>
                </Link>

                {/* LAMBDA MARKETPLACE SUGGESTION 
                <Link
                  className={
                    locationP === "suggestion" ? "text-menu-active-2 title montserrat" : "title montserrat"
                  }
                  style={{ fontSize: "13px", marginLeft: "23px", marginTop: "6px" }}
                  onClick={handleNav}
                  to={routes.marketplaceLambdaSuggestion(i18n.language).path+"?page=1"}
                >
                  {t("components.menu.53")}
                </Link>
                */}
                <Link
                  className={
                    locationP === "highMargin" ? "text-menu-active-2 title montserrat" : "title montserrat"
                  }
                  style={{ fontSize: "13px", marginLeft: "23px", marginTop: "6px" }}
                  onClick={handleNav}
                  to={routes.marketplaceHighMargin(i18n.language).path}
                >
                  {t("components.menu.55")}
                </Link>
              </div>
            )}

            <div
              className="divider-horizontal"
              style={{
                margin: "13px 0",
                marginRight: "0",
                width: "100%",
                marginLeft: "0",
              }}
            />

            {typeAccount === "seller" && (
              <Link
                onClick={handleNav}
                to="/import-list"
                className={location === "import-list" ? "text-menu-active" : "text-menu"}
                style={{ position: "relative" }}
              >
                <p
                  className={
                    !user.import_list?.length && location === "import-list"
                      ? "count-circle-grey active"
                      : !user.import_list?.length
                      ? "count-circle-grey"
                      : location === "import-list"
                      ? "count-circle-red active"
                      : "count-circle-red"
                  }
                >
                  {user.import_list?.length || 0}
                </p>
                <img src={icon_import_list} className="icon-menu" />
                {t("components.menu.25")}
              </Link>
            )}

            {idAccount && (
              <Link
                id="demo-step-9"
                onClick={handleNav}
                to="/inventory"
                className={
                  location === "inventory" && !(state && state.name) ? "text-menu-active" : "text-menu"
                }
                style={{ position: "relative" }}
              >
                <p className={location === "inventory" ? "count-circle-grey active" : "count-circle-grey"}>
                  {user.products_id?.length || 0}
                </p>
                <img src={icon_inventory} className="icon-menu" />
                {t("components.menu.26")}
              </Link>
            )}

            {idAccount && (
              <Link
                onClick={handleNav}
                style={{ position: "relative" }}
                to={"/my-orders"}
                className={
                  location === "my-orders" || location === "orders-supplier"
                    ? "text-menu-active"
                    : "text-menu"
                }
              >
                <img style={{ height: "20px" }} src={icon_orders} className="icon-menu" />
                {t("components.menu.27")}
                {ordersCount > 0 && (
                  <p
                    className={
                      location === "my-orders" || location === "orders-supplier"
                        ? "count-circle-orange active"
                        : "count-circle-orange"
                    }
                  >
                    {ordersCount || 0}
                  </p>
                )}
              </Link>
            )}

            {typeAccount === "creater" &&
              user._id &&
              checkAccountCompleted(user) &&
              !user.stripe?.account_url && (
                <Link
                  onClick={handleNav}
                  style={{ position: "relative" }}
                  to={"/my-payments"}
                  className={location === "my-payments" ? "text-menu-active" : "text-menu"}
                >
                  <img style={{ height: "20px" }} src={icon_payouts} className="icon-menu" />
                  {t("components.menu.28")}
                </Link>
              )}

            <div
              className="divider-horizontal"
              style={{ margin: "13px 0", marginRight: "0", width: "100%", marginLeft: "0" }}
            />

            {/* MY SUPPLIERS 
            {typeAccount === "seller" && (
              <Link
                onClick={handleNav}
                to={routes.supplierList(i18n.language).path}
                className={
                  location === "suppliers" && !(state && state.name) ? "text-menu-active" : "text-menu"
                }
              >
                <img src={icon_suppliers} className="icon-menu" />
                {t("mySuppliers")}
              </Link>
            )}
              */}

            {idAccount && (
              <Link
                onClick={handleNav}
                style={{ position: "relative" }}
                to={routes.messages(i18n.language).path}
                className={location === "messages" ? "text-menu-active" : "text-menu"}
              >
                <img src={icon_message} className="icon-menu" />
                Messages
                {user.messages_count?.total > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      right: location === "messages" ? "35px" : "0px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img style={{ height: "20px", width: "auto" }} src={AlertIcon} />
                      <p style={{ top: "5px", left: "9px" }} className="count-circle">
                        {user.messages_count?.total > 9 ? "+9" : user.messages_count?.total || 0}
                      </p>
                    </div>
                  </div>
                )}
              </Link>
            )}

            {typeAccount === "seller" && (
              <Link
                onClick={handleNav}
                to="/sourcing-products"
                className={
                  location === "sourcing-products" && !(state && state.name)
                    ? "text-menu-active"
                    : "text-menu"
                }
              >
                <img src={icon_sourcing} className="icon-menu" />
                {t("components.menu.29")}
              </Link>
            )}

            {typeAccount === "creater" && (
              <Link
                onClick={handleNav}
                to="/my-resellers"
                className={
                  location === "my-resellers" && !(state && state.name) ? "text-menu-active" : "text-menu"
                }
              >
                <img src={icon_suppliers} className="icon-menu" />
                {t("components.menu.30")}
              </Link>
            )}

            {idAccount && (
              <>
                {typeAccount === "creater" && (
                  <Link
                    onClick={handleNav}
                    style={{ position: "relative" }}
                    to="/boost-your-business"
                    className={location === "boost-your-business" ? "text-menu-active" : "text-menu"}
                  >
                    <img src={icon_boost} className="icon-menu" />
                    Boost
                  </Link>
                )}
                {typeAccount === "creater" && user?.shop?.name && (
                  <Link
                    onClick={handleNav}
                    style={{ position: "relative" }}
                    to={`/shop/${user.shop.name}`}
                    className={
                      location.includes("shop") && !window.location.href.includes("your-shop-supplier")
                        ? "text-menu-active"
                        : "text-menu"
                    }
                  >
                    <ShopFilled style={{ color: "#585858" }} className="icon-menu" />
                    {t("components.menu.31")}
                  </Link>
                )}

                {user.courses?.length > 0 && (
                  <Link
                    onClick={handleNav}
                    to="/academy/dashboard"
                    className={location === "academy/dashboard" ? "text-menu-active" : "text-menu"}
                  >
                    <img src={icon_academy} className="icon-menu" />
                    {t("components.menu.32")}
                  </Link>
                )}
              </>
            )}
          </div>
          <MyAccount user={user} setUser={setUser} location={location} />
        </div>
      ) : (
        <NotConnectedMenu windowSize={windowSize} />
      )}
    </>
  );
};

export default FirstMenu;
