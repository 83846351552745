import React, {useState, useEffect} from 'react'
import { Table } from 'antd';

const TabPayouts = ({payouts, windowSize}) => {
    const [datas, setDatas] = useState([])

    const columns = [
      {
        title: 'Payout Date',
        dataIndex: 'date',
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
      },
      {
        title: 'Amount',
        dataIndex: 'amount'
      },
      {
        title: 'Identification',
        dataIndex: 'identification'
      },
    ];

    useEffect(() => {
        if(payouts && payouts.length){
            let index = 0
            const newData = payouts.map((payout, i) => {
                index+=1
                return({
                    key: (index+1).toString(),
                    date: payout.date,
                    destination: payout.destination,
                    amount: "$"+payout.amount,
                    identification: payout.code,
                })
            })
            setDatas(newData.filter(data => data && data.key))
        }
    }, [payouts])

    return(
        <Table  scroll={windowSize > 1150 ? { y: '400px', x: '85%' } : { y: '500px', x: '800px' }} pagination={false}
        style={windowSize > 1150 ? {width: '86%', marginTop: '30px', height: '400px'} : {width: '100%', marginTop: '30px', height: '500px'}} columns={columns} dataSource={datas} />
    )
}

export default TabPayouts