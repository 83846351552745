import React from "react";
import { useTranslation } from "react-i18next";
import { InputNumber, message } from "antd";

const Inventory = ({ setProduct, product }) => {
  const { t } = useTranslation();
  const handleDisabledVariantBool = () => {
    if (product.variantBool) {
      setProduct({ ...product, variantBool: false });
      message.warning(t('messages.variantsDisabled'));
    }
  };
  return (
    <div id="inventory" style={{ width: "100%", marginRight: "30px" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        {t("inventoryWord")}
      </p>
      <div style={{ width: "45%" }} className="column">
        <p style={{ marginBottom: "5px" }} className="text-bold">
          {t("quantity")}
        </p>
        <div style={{ width: "100%" }} onClick={handleDisabledVariantBool}>
          <InputNumber
            min={0}
            max={9999}
            style={{ width: "100%" }}
            disabled={product.variantBool}
            onChange={(value) => setProduct({ ...product, stock: value })}
            value={product.stock}
          />
        </div>
      </div>
    </div>
  );
};

export default Inventory;
