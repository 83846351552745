import React, { useState } from "react";
import { Input, Form, message } from "antd";
import { useTranslation } from "react-i18next";

import Load from "../../../../../utils/Load";

import { wizishopServices } from "../../../../../services/wizishop";
import { userServices } from "../../../../../services/user";

import wizishopLogo from "../../../../../images/illustrations/wizishop.svg";
import dropiziLogo from "../../../../../images/illustrations/dropizi.svg";

const WizishopDropizi = ({ cms, setUser, cancelModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
    cms: cms.toLowerCase().includes('wizishop') ? "wizishop" : "dropizi"
  });

  const { t } = useTranslation();

  const handleConnectShop = () => {
    setLoad(true);
    wizishopServices
      .connect(login)
      .then((res) => {
        setLoad(false);
        if (res.status === 200) {
          return res.json();
        } else {
          message.error(t("messages.badCmsLogs", { cms }));
        }
      })
      .then((resJson) => {
        if (resJson) {
          userServices
            .me()
            .then((res) => res.json())
            .then((res) => {
              message.success(t("messages.shopConnected"));
              setUser(res.data);
              cancelModal();
            });
        }
      });
  };
  return (
    <div onClick={() => setIsOpen(true)} className="card-connect-shop">
      {load && <Load />}
      <img src={cms === "WiziShop" ? wizishopLogo : dropiziLogo} alt="shopify" style={{ height: "40px" }} />
      {isOpen && (
        <div className="card-connect-shop-open">
          <Form
            onFinish={handleConnectShop}
            className="column width-100"
            style={{ alignItems: "center", marginTop: "30px" }}
            name="store completion"
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              name="url"
              className="width-100"
              rules={[{ required: true, message: t("pleaseCompleteField") }]}
              style={{ marginTop: "0px", marginBottom: "10px" }}
              onChange={(e) => setLogin({ ...login, email: e.target.value })}
            >
              <Input className="width-100" placeholder={t("components.joinUs.9", { cms })} />
            </Form.Item>
            <Form.Item
              name={`${cms} password`}
              className="width-100"
              autoComplete="new-state"
              rules={[{ required: true, message: t("pleaseCompleteField") }]}
              style={{ marginTop: "0px", marginBottom: "15px" }}
              onChange={(e) => setLogin({ ...login, password: e.target.value })}
            >
              <Input.Password
                autoComplete="off"
                className="width-100"
                placeholder={t("components.joinUs.10", { cms })}
              />
            </Form.Item>

            <Form.Item className="width-100">
              <button type="primary" htmlType="submit" className="button big orange-back width-100">
                {t("components.joinUs.8", { cms })}
              </button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default WizishopDropizi;
