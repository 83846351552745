import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import EcoLabelIcon from "../../images/icons/eco.svg";
import { Helmet } from "react-helmet";
import iconCheck from "../../images/icons/Check.svg";

import {routes} from '../../routes'

const EcoLabelWhat = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t, i18n } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("labelPage.metas.title2")}</title>
        <link rel="canonical" href={routes.whatEcoLabel(i18n.language).path} />
        <meta name="description" content={t("labelPage.metas.description2")} />
      </Helmet>
      <div className="page-start">
        <div
          style={
            windowSize > 1150
              ? { alignItems: "center", paddingTop: "70px", display: "flex" }
              : { alignItems: "center", paddingTop: "30px", paddingBottom: "90px", width: "100%" }
          }
          className="column-scrolling"
        >
          <div
            id="supplier-doc"
            className="column"
            style={
              windowSize > 1150
                ? { width: "60%", marginLeft: "0%", marginTop: "100px" }
                : { width: "80%", marginLeft: "10%", marginTop: "30px" }
            }
          >
            <div className={windowSize > 1150 ? "row" : "column"}>
              <img
                style={{ marginRight: "30px", height: "80px", width: "auto" }}
                src={EcoLabelIcon}
                alt="eco label icon svg"
              />
              <div className="column">
                <h2
                  style={
                    windowSize > 1150
                      ? { marginBottom: "0", height: "35px" }
                      : { marginBottom: "0", height: "35px", marginTop: "15px" }
                  }
                  className="row"
                >
                  <span
                    className="title"
                    style={{ fontWeight: "bold", fontSize: "30px", marginRight: "8px", color: "#7CB55A" }}
                  >
                    ECO
                  </span>
                  <span className="title-300" style={{ fontSize: "30px", color: "#7CB55A" }}>
                    label
                  </span>
                </h2>
                <p className="text-300">{t("by")} 01supply</p>
              </div>
            </div>
            <h1 style={{ fontSize: "20px", marginTop: "30px", marginBottom: "60px" }} className="text-bold">
              {t("labelPage.11")}
            </h1>

            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.12")}
            </p>
            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.13")}
            </p>
            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.14")}
            </p>
            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.15")}
            </p>
            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.16")}
            </p>
            <p className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.17")}
            </p>
            <p style={{ marginTop: "40px", fontSize: "16px" }} className="text italic">
              {t("labelPage.18")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcoLabelWhat;
