import React from "react";
import { useTranslation } from "react-i18next";

const ShippingDetails = ({ setSeeShipping }) => {
  const { t } = useTranslation();

  return (
    <div className="column width-100">
      <p style={{ marginBottom: "15px" }} className="title montserrat-800">
        {t("productDetail.shipping.title")}
      </p>
      <p style={{ marginBottom: "10px" }} className="text-bold">
        {t("productDetail.shipping.1")}
      </p>
      <p style={{ marginBottom: "25px" }} className="text">
        {t("productDetail.shipping.2")}
      </p>
      <p style={{ marginBottom: "10px" }} className="text-bold">
        {t("productDetail.shipping.3")}
      </p>
      <p style={{ marginBottom: "15px" }} className="text">
        {t("productDetail.shipping.4")}
      </p>
      <p onClick={() => setSeeShipping(true)} className="text blue underline pointer">
        {t("productDetail.shipping.5")}
      </p>
      <p className="text">{t("productDetail.shipping.6")}</p>
      <p className="text">{t("productDetail.shipping.7")}</p>
      <p className="text">{t("productDetail.shipping.8")}</p>
      <p style={{ marginTop: "25px", marginBottom: "10px" }} className="text-bold">
        {t("productDetail.shipping.9")}
      </p>
      <p className="text">{t("productDetail.shipping.10")}</p>
      <p className="text">{t("productDetail.shipping.11")}</p>
    </div>
  );
};

export default ShippingDetails;
