import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { url } from "../api/url";
import { header } from "../api/header";
import SupplierIllustration from "../images/illustrations/supplier.png";

const SupplierCard = ({ supplier, windowSize, numberOfProducts, isResellerSupplierList }) => {
  const [showProducts, setShowPoducts] = useState(false);
  const [products, setProducts] = useState([]);

  const { t } = useTranslation()

  /* GET SUPPLIER 10 PRODUCTS

  useEffect(() => {
    if (supplier._id && !products.length && isResellerSupplierList) {
      getProducts();
    }
  }, [supplier]);

  const getProducts = async () => {
    const tenFirstSupplierProducts = supplier.products_id
      .slice()
      .splice(Math.floor(Math.random() * supplier.products_id.length), numberOfProducts);
    const res = await fetch(`${url}/product/find-with-image`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        productsId: tenFirstSupplierProducts,
      }),
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setProducts(resJson.products);
    }
  };*/

  return (
    <div key={supplier._id} style={{ width: "100%", marginLeft: "0" }} className="card">
      {supplier ? (
        <div style={{ width: "100%" }} className="column">
          <div style={{ width: "100%" }} className={windowSize > 1150 ? "row start" : "column"}>
            <div
              style={{
                height: "130px",
                width: "130px",
                marginRight: "40px",
                borderRadius: "6px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: windowSize <= 1150 && "15px",
              }}
              className="container-image-card border-grey"
            >
              <Link to={`/shop/${supplier.shop?.name}`}>
                <img
                  alt={supplier.shop.name}
                  style={{ height: "100%" }}
                  src={supplier.profile_picture || SupplierIllustration}
                  className="image-product pointer"
                />
              </Link>
            </div>
            <div
              style={
                windowSize > 1150
                  ? {
                      width: "calc(90% - 330px)",
                      marginRight: "10%",
                      marginBottom: windowSize <= 1150 && "15px",
                    }
                  : { width: "90%", marginBottom: "10px" }
              }
              className="column"
            >
              <p className="title-bold montserrat">{supplier.shop.name}</p>
              <p className="text">{supplier.shop.describe}</p>
            </div>
            <div style={{ width: "160px" }} className="column">
              <p className="text grey">
                <span style={{ marginRight: "7px" }} className="text-bold grey">
                  {t('products')}:
                </span>
                {supplier.products_id.length}
              </p>
              {supplier.thematic?.[0] && (
                <p className="text grey">
                  <span style={{ marginRight: "7px" }} className="text-bold grey">
                    {t('thematic')}:
                  </span>
                  {supplier.thematic[0]}
                </p>
              )}
              <Link
                to={`/shop/${supplier.shop?.name}`}
                style={{ width: "100%", marginTop: "20px" }}
                className="button big orange-back"
              >
                {t('components.supplierCard.1')}
              </Link>
            </div>
          </div>
          {!showProducts && products.length ? (
            <p
              className="text blue pointer center"
              style={{ width: "100%", marginTop: "15px" }}
              onClick={() => setShowPoducts(true)}
            >
              {t('components.supplierCard.2')} <DownOutlined style={{ color: "#70c4f3", marginLeft: "4px", fontSize: "12px" }} />
            </p>
          ) : (
            products.length > 0 && (
              <>
                <p style={{ marginTop: "25px", marginBottom: "10px" }} className="text-bold">
                  {t('products')}
                </p>
                <div className="row" style={{ width: "100%" }}>
                  <div style={{ maxWidth: "calc(100% - 90px)", overflow: "hidden" }} className="row">
                    {products.map((product) => {
                      return (
                        <div
                          style={{
                            height: "70px",
                            width: "70px",
                            marginRight: "20px",
                            borderRadius: "6px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="container-image-card border-grey"
                        >
                          <Link to={`/product-details?name=${product.name}&id=${product._id}`}>
                            <img
                              alt={product.name}
                              style={{ height: "100%" }}
                              src={product.image}
                              className="image-product pointer"
                            />
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <Link
                    to={`/shop/${supplier.shop?.name}`}
                    className="column text-bold"
                    style={{
                      border: "2px solid #FBA95E",
                      borderRadius: "4px",
                      background: "#FDFDFD",
                      color: "#FBA95E",
                      height: "70px",
                      width: "80px",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                   {t('components.supplierCard.3')} <br /> {t('products').toLowerCase()}
                  </Link>
                </div>
                <p
                  className="text blue pointer center"
                  style={{ width: "100%", marginTop: "15px" }}
                  onClick={() => setShowPoducts(false)}
                >
                  {t('components.supplierCard.4')}{" "}
                  <UpOutlined style={{ color: "#70c4f3", marginLeft: "4px", fontSize: "12px" }} />
                </p>
              </>
            )
          )}
        </div>
      ) : (
        <Skeleton className="image-product" loading={true} active />
      )}
    </div>
  );
};

export default SupplierCard;
