import React from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { url } from "../api/url";
import { header } from "../api/header";

const check = async () => {
  return fetch(`${url}/check-token`, {
    method: "POST",
    credentials: "include",
    headers: header,
    body: JSON.stringify({
      token: localStorage.getItem("tokenCreatosell"),
      email: localStorage.getItem("emailAccountCreatosell"),
    }),
  });
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
    return false;
  }
  return true;
}

class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.image = props.image;
  }
  state = {
    loading: false,
  };

  componentDidMount() {
    if (this.props.image) {
      this.setState({
        imageUrl: this.props.image,
        loading: false,
      });
    }
  }

  handleChange = (info) => {
    // Get this url from response in real world.
    if (beforeUpload(info.file)) {
      try {
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.setState({
            imageUrl,
            loading: false,
          });
          this.props.setImage({ base64: imageUrl, name: info.file.name });
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    const { loading, imageUrl } = this.state;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>upload</div>
      </div>
    );
    return (
      <Upload
        accept=".png,.jpg.jpeg"
        id="upload-profile-image"
        key={imageUrl}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={check}
        onChange={this.handleChange}
        style={this.props.style ? this.props.style : {}}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "100%" }} /> : uploadButton}
        {imageUrl && this.props.icon && this.props.icon}
      </Upload>
    );
  }
}

export default UploadImage;
