import React from "react";
import shopifyLogo from '../../../../../images/illustrations/shopify.svg'

const Shopify = () => {
  return (
    <a
      href="https://apps.shopify.com/01supply"
      target="_blank"
      rel="noopener noreferrer"
      className="card-connect-shop"
    >
        <img src={shopifyLogo} alt="shopify" style={{height: "40px"}}/>
    </a>
  );
};

export default Shopify