import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { url } from "../api/url";
import { header } from "../api/header";
import CheckToken from "../components/check-token/CheckToken";
import SubMenu from "../components/menu/AffiliationMenuSetting";
import Menu from "../components/menu/FirstMenu";
import { message, Input } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import MobileMenu from "../components/menu/mobile/FirstMenu";
import RedirectLogin from "../utils/RedirectLogin";
import Load from "../utils/Load";
import Tab from "../components/affiliation/Tab";
import TabPayouts from "../components/affiliation/TabPayouts";
import IbanModal from "../components/modals/IbanModal";
import PaypalEmailModal from "../components/modals/PaypalEmailModal";
import { sendGridEmail } from "../utils/SendGridEmail";

import bankIcon from "../images/icons/bank.png";
import paypalIcon from "../images/icons/paypal.svg";

const Affiliation = () => {
  const [tokenCheck, setTokenCheck] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount, setIdAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);
  const [secretId, setSecretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [redirectPayouts, setRedirectPayouts] = useState(false);
  const [payouts, setPayouts] = useState(
    window.location.href && window.location.href.includes("payouts") ? true : false
  );
  const [affiliation, setAffiliation] = useState({});
  const [ibanModal, setIbanModal] = useState(false);
  const [paypalEmailAccountModal, setPaypalEmailAccountModal] = useState(false);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    const href = window.location.href;
    let newUrl = new URL(href);
    if (href.includes("userid")) {
      setIdAccount(newUrl.searchParams.get("userid"));
      setSecretId(newUrl.searchParams.get("secretid"));
    }
  }, []);

  useEffect(() => {
    getUser();
    getAffiliation();
    if (window.tidioChatApi) window?.tidioChatApi?.show();

    if (window.location.href && window.location.href.includes("payouts")) {
      setPayouts(true);
      setRedirectPayouts(true);
    }
  }, [idAccount, secretId]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
  };

  const getAffiliation = async () => {
    const res = await fetch(`${url}/user/find-affiliation/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status == 200) {
      const resJson = await res.json();
      setAffiliation(resJson.affiliation);
    }
  };

  const copyLink = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        message.success(t("messages.invitationLinkCopied"));
      },
      function (err) {
        message.error(t("messages.error"));
      }
    );
  };

  const earningRequest = async () => {
    try {
      const res = await fetch(`${url}/affi/update-payout-status/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          status: true,
        }),
      });
      if (res.status == 200) {
        message.success(t("messages.transferRequestSend"), 10);
        setAffiliation({ ...affiliation, payout_pending: true });
        sendGridEmail(
          "d-c8d2f4d2796f480aa87e0d06bc133510",
          "contact.01supply@gmail.com",
          {
            subject: "Nouvelle demande de transfert - Gains Affiliation",
            userEmail: user.email,
          },
          token
        );
      } else message.error(t("messages.error"));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="page">
      {redirectPayouts && <Redirect to="/affiliation/payouts" />}
      <RedirectLogin />
      <CheckToken setTokenCheck={setTokenCheck} />
      <div key={payouts} className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}

        {!payouts && user ? (
          <div
            style={
              windowSize > 1150
                ? { paddingTop: "80px", paddingLeft: "50px" }
                : { width: "100%", alignItems: "center", padding: "20px" }
            }
            className="column-scrolling"
          >
            {/* REFERRALS */}

            <SubMenu setPayouts={setPayouts} payouts={false} />
            {load && <Load />}
            <div style={{ alignItems: "flex-start", marginTop: "50px" }} className="row">
              <div style={{ marginRight: "100px" }} className="container-gain-affiliation">
                <p style={{ fontSize: "32px" }} className="title-roboto">
                  ${affiliation.total_earned ? affiliation.total_earned : "0"}
                </p>
                <p className="text-roboto-300">{t("affiliationPage.1")}</p>
              </div>
            </div>

            <p
              style={{ color: "rgb(90, 90, 90)", fontSize: "25px", marginTop: "30px", marginBottom: "0px" }}
              className="title-roboto-300"
            >
              {t("affiliationPage.2")}
            </p>
            <div style={{ marginTop: "13px", width: "400px", maxWidth: "80vw" }} className="row">
              <Input
                value={`${window.location.href.split("/affiliation")[0]}/join-us?&invitation=${user._id}`}
                style={{ width: "350px" }}
                className="input"
                onPressEnter={() =>
                  copyLink(`${window.location.href.split("/affiliation")[0]}/join-us?&invitation=${user._id}`)
                }
              />
              <button
                style={{ width: "80px", zIndex: "100" }}
                onClick={() =>
                  copyLink(`${window.location.href.split("/affiliation")[0]}/join-us?&invitation=${user._id}`)
                }
                className="button-in-input orange-back"
              >
                {t("copy").toUpperCase()}
              </button>
            </div>
            <p style={{ marginTop: "10px", width: "350px", maxWidth: "80vw" }} className="text-roboto">
              {t("affiliationPage.3")}
              <span className="green" style={{ marginLeft: "4px", marginRight: "4px", fontWeight: "bold" }}>
                {user.affiliate?.my_commission_percentage || 20}% commission
              </span>
              {t("affiliationPage.4")}
            </p>

            <p
              style={{ color: "rgb(90, 90, 90)", fontSize: "25px", marginTop: "30px", marginBottom: "0px" }}
              className="title-roboto-300"
            >
              {t("affiliationPage.5")}
            </p>
            <div style={{ marginTop: "13px", width: "400px", maxWidth: "80vw" }} className="row">
              <Input
                value={`${window.location.href.split("/affiliation")[0]}/join-us?reseller&invitation=${
                  user._id
                }`}
                style={{ width: "350px" }}
                className="input"
                onPressEnter={() =>
                  copyLink(
                    `${window.location.href.split("/affiliation")[0]}/join-us?reseller&invitation=${user._id}`
                  )
                }
              />
              <button
                style={{ width: "80px", zIndex: "100" }}
                onClick={() =>
                  copyLink(
                    `${window.location.href.split("/affiliation")[0]}/join-us?reseller&invitation=${user._id}`
                  )
                }
                className="button-in-input orange-back"
              >
                {t("copy").toUpperCase()}
              </button>
            </div>
            <p style={{ marginTop: "10px", width: "400px", maxWidth: "80vw" }} className="text-roboto">
              {t("affiliationPage.6")}
            </p>
            {affiliation.commission_history && (
              <Tab windowSize={windowSize} affiliation={affiliation} type={"seller"} />
            )}

            <p
              style={{ color: "rgb(90, 90, 90)", fontSize: "25px", marginTop: "30px", marginBottom: "0px" }}
              className="title-roboto-300"
            >
              {t("affiliationPage.7")}
            </p>
            <div style={{ marginTop: "13px", width: "400px", maxWidth: "80vw" }} className="row">
              <Input
                value={`${window.location.href.split("/affiliation")[0]}/join-us?supplier&invitation=${
                  user._id
                }`}
                style={{ width: "350px" }}
                className="input"
                onPressEnter={() =>
                  copyLink(
                    `${window.location.href.split("/affiliation")[0]}/join-us?supplier&invitation=${user._id}`
                  )
                }
              />
              <button
                style={{ width: "80px", zIndex: "100" }}
                onClick={() =>
                  copyLink(
                    `${window.location.href.split("/affiliation")[0]}/join-us?supplier&invitation=${user._id}`
                  )
                }
                className="button-in-input orange-back"
              >
                {t("copy").toUpperCase()}
              </button>
            </div>
            <p style={{ marginTop: "10px", width: "400px", maxWidth: "80vw" }} className="text-roboto">
              {t("affiliationPage.8")}
            </p>
            {affiliation.commission_history && (
              <Tab windowSize={windowSize} affiliation={affiliation} type={"creater"} />
            )}
          </div>
        ) : user && (
          <div
            style={
              windowSize > 1150
                ? { paddingTop: "80px", paddingLeft: "50px" }
                : { width: "100%", alignItems: "center", padding: "20px" }
            }
            className="column-scrolling"
          >
            {/* PAYOUTS */}

            <SubMenu setPayouts={setPayouts} payouts={true} />
            {load && <Load />}
            <div
              style={
                windowSize > 1150
                  ? { marginTop: "50px", alignItems: "flex-start" }
                  : { flexDirection: "column", alignItems: "flex-start", paddingTop: "30px" }
              }
              className="row"
            >
              <div className="column">
                <div className="container-gain-affiliation">
                  <p style={{ fontSize: "32px", color: "green" }} className="title-roboto">
                    ${affiliation.total_available_payout ? affiliation.total_available_payout : "0"}
                  </p>
                  <p className="text-roboto-bold">{t("affiliationPage.9")}</p>
                </div>
                <button
                  className={affiliation.user_id && !affiliation.payout_pending ? "button" : "button-red"}
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    if (!(user.paypal_email_account || user.iban)) {
                      message.error(t("messages.pleaseEnterBank"));
                    } else if (
                      !affiliation.total_available_payout ||
                      (affiliation.total_available_payout &&
                        parseFloat(affiliation.total_available_payout) < 4)
                    ) {
                      message.error(t("messages.minEarning"));
                    } else if (affiliation.user_id && !affiliation.payout_pending) {
                      earningRequest();
                    }
                  }}
                >
                  {affiliation.user_id && !affiliation.payout_pending
                    ? t("affiliation.1")
                    : t("affiliation.2")}
                </button>
              </div>
              <div
                style={
                  windowSize > 1150
                    ? { width: "50%", marginLeft: "5%" }
                    : { marginTop: "20px", marginBottom: "20px" }
                }
                className="column"
              >
                <p className="text-roboto-300">
                  {t("affiliationPage.coord.1")} <br />
                  <span className="text-bold">
                    contact@01supply.com
                    <br />
                    <br />
                    {t("affiliationPage.coord.2")}
                    <br />
                  </span>
                  01supply
                  <br />
                  66 Avenue des Champs-Élysées, 75008, Paris, France
                  <br />
                  SIRET: 94910191900015
                  <br />
                  <br />
                  <span className="text-bold">
                    {t("affiliationPage.coord.3")}
                    <br />
                  </span>
                  {t("affiliationPage.coord.4")}
                </p>
                <h2
                  style={{
                    textAlign: "start",
                    fontSize: "18px",
                    marginTop: "30px",
                  }}
                  className="title row"
                >
                  {t("affiliationPage.10")}
                  {user?.paypal_email_account && (
                    <CheckCircleFilled
                      style={{
                        marginBottom: "0px",
                        marginLeft: "8px",
                        fontSize: "22px",
                        color: "rgb(155, 218, 155)",
                      }}
                    />
                  )}
                  {user.paypal_email_account && (
                    <span
                      onClick={() => setPaypalEmailAccountModal(true)}
                      style={{ marginLeft: "10px" }}
                      className="text-gradient"
                    >
                      {t("edit")}
                    </span>
                  )}
                </h2>
                {user.paypal_email_account ? (
                  <p className="title-300">{user.paypal_email_account}</p>
                ) : (
                  <button
                    className="settings-payments-button"
                    onClick={() => setPaypalEmailAccountModal(true)}
                  >
                    <img
                      src={paypalIcon}
                      style={{
                        width: "40px",
                        height: "auto",
                        marginRight: "15px",
                      }}
                    />
                    {t("affiliationPage.10")}
                  </button>
                )}
                {paypalEmailAccountModal && (
                  <PaypalEmailModal
                    header={header}
                    token={token}
                    url={url}
                    idAccount={idAccount}
                    secretId={secretId}
                    setPaypalEmailAccountModal={setPaypalEmailAccountModal}
                    user={user}
                    setUser={setUser}
                  />
                )}
                <h2
                  style={{
                    textAlign: "start",
                    fontSize: "18px",
                    marginTop: "30px",
                  }}
                  className="title row"
                >
                  {t("affiliationPage.11")}
                  {user.iban && (
                    <CheckCircleFilled
                      style={{
                        marginBottom: "0px",
                        marginLeft: "8px",
                        fontSize: "22px",
                        color: "rgb(155, 218, 155)",
                      }}
                    />
                  )}
                  {user.iban && (
                    <span
                      onClick={() => setIbanModal(true)}
                      style={{ marginLeft: "10px" }}
                      className="text-gradient"
                    >
                      {t("edit")}
                    </span>
                  )}
                </h2>
                {user.iban ? (
                  <p className="title-300">{user.iban}</p>
                ) : (
                  <button className="settings-payments-button" onClick={() => setIbanModal(true)}>
                    <img
                      src={bankIcon}
                      style={{
                        width: "40px",
                        height: "auto",
                        marginRight: "15px",
                      }}
                    />
                    {t("affiliationPage.12")}
                  </button>
                )}
                {ibanModal && (
                  <IbanModal
                    header={header}
                    token={token}
                    url={url}
                    idAccount={idAccount}
                    secretId={secretId}
                    setIbanModal={setIbanModal}
                    user={user}
                    setUser={setUser}
                  />
                )}
              </div>
            </div>

            <p className="title-roboto">{t("affiliationPage.13")}</p>
            <div style={{ marginLeft: "0" }} className="divider-horizontal" />
            <TabPayouts payouts={affiliation.payout_history} windowSize={windowSize} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Affiliation;
