import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { url } from "../api/url";
import { header } from "../api/header";
import PageHeader from "../components/menu/marketplaceNavbar";
import HeaderShop from "../components/supplierShop/HeaderShop";
import { Helmet } from "react-helmet";
import AboutSupplier from "../components/supplierShop/AboutSupplier";
import logo from "../images/logo.svg";
import Products from "../components/supplierShop/Products";
import MobileMenu from "../components/menu/mobile/FirstMenu";
import Menu from "../components/menu/FirstMenu";

const SupplierShop = ({ locationP, supplierProps, isProductDetailPage }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isStickyNav, setIsStickyNav] = useState(false);
  const [products, setProducts] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [shopInformations, setShopInformations] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [aboutSuppplier, setAboutSupplier] = useState({});
  const [supplierShopName, setSupplierShopName] = useState(supplierProps ? supplierProps : "");
  const [user, setUser] = useState({});
  const [supplierImage, setSupplierImage] = useState("");
  const [supplier, setSupplier] = useState({});

  const { t } = useTranslation();

  window.onscroll = function (e) {
    if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else setIsStickyNav(false);
  };

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getSupplierShop();
    getUser();
  }, []);

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [window.tidioChatApi]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  const getSupplierShop = async () => {
    let supplierShop = window.location.href.split("shop/");
    if (supplierShop) supplierShop = supplierShop[1];

    if (supplierProps) supplierShop = supplierProps;

    if (supplierShop) {
      setSupplierShopName(supplierShop);
      const res = await fetch(`${url}/user/shop/${supplierShop}`);
      if (res.status === 200) {
        const resJson = await res.json();
        if (!resJson.data?.products?.length) {
          setNotFound(true);
        } else {
          setProducts(resJson.data.products);
        }

        setShopInformations(resJson.data.shop);
        setAboutSupplier(resJson.data.about);
        setSupplier(resJson.data);
      } else {
        setNotFound(true);
      }
    } else setNotFound(true);
  };

  return (
    <div className="page">
      {window.location.href.includes("product-details") && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${t("handmadeSupplier")} : ${shopInformations.name}`}</title>
          <link rel="canonical" href={`https://app.01supply.com/shop${shopInformations.name}`} />
          <meta name="description" content={aboutSuppplier.information} />
          <meta property="og:description" content={aboutSuppplier.information} />
          <meta property="og:url" content={`https://app.01supply.com/shop${shopInformations.name}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={shopInformations.name} />
          <meta property="og:image" content={supplierImage} />
        </Helmet>
      )}

      <div className="page-start">
        {windowSize > 1150 && !isProductDetailPage ? <Menu userProps={user} /> : !isProductDetailPage && <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? { alignItems: "flex-start", justifyContent: "flex-start", paddingTop: !isProductDetailPage && "200px" }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0",
                  paddingTop: "30px",
                }
          }
          className={!isProductDetailPage && "column-scrolling shop-supplier"}
        >
          {windowSize > 1150 && !isProductDetailPage && <PageHeader user={user} windowSize={windowSize} location="product-detail" />}

          <div style={{ width: "100%" }}>
            <HeaderShop windowSize={windowSize} user={user} supplier={supplier} />
            {supplier.products?.length > 0 && (
              <Products
                windowSize={windowSize}
                setNotFound={setNotFound}
                user={user}
                supplier={supplier}
                notFound={notFound}
                setUser={setUser}
              />
            )}

            {(aboutSuppplier.facebook ||
              aboutSuppplier.instagram ||
              (aboutSuppplier.images && aboutSuppplier.images.length) ||
              aboutSuppplier.video1 ||
              aboutSuppplier.eco_label?.question1 ||
              aboutSuppplier.handmade_label?.question1) && (
              <AboutSupplier
                setSupplierImage={setSupplierImage}
                supplierShopName={supplierShopName}
                windowSize={windowSize}
                shopInformations={shopInformations}
                aboutSuppplier={aboutSuppplier}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierShop;
