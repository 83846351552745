import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../wrappers";
import coachingPricingImg from "../../images/illustrations/coaching_pricing.svg";

const InformationsModal = ({ setIsDisplayed, isDisplayed, setIsConfigureCoaching }) => {
  const { t } = useTranslation();
  const goBoost = () => {
    setIsDisplayed(false);
    if (setIsConfigureCoaching) {
      setIsConfigureCoaching(true);
    }
    document.getElementById("coaching-form")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Modal onCancel={() => setIsDisplayed(false)} className="modal-1000" visible={isDisplayed} footer={[]}>
      <div style={{ alignItems: "center", paddingBottom: "180px" }} className="width-100 column">
        <p style={{ fontWeight: "800" }} className="title-bold montserrat">
          {t("components.coachingForm.1")}
        </p>
        <p style={{ marginTop: "10px" }} className="text-300">
          {t("components.coachingForm.2")}
        </p>
        <button style={{ marginTop: "20px" }} onClick={goBoost} className="button big orange-back">
          {t("components.coachingForm.3")}
        </button>
        <p
          style={{ marginTop: "40px", marginBottom: "10px", fontWeight: "800" }}
          className="title-bold montserrat"
        >
          {t("components.coachingForm.4")}
        </p>
        <p className="text-300">{t("components.coachingForm.5")}</p>
        <p
          style={{ fontSize: "16px", marginTop: "30px", marginBottom: "10px" }}
          className="title-bold montserrat"
        >
          {t("components.coachingForm.6")}
        </p>
        <p className="text-300">
          {t("components.coachingForm.7")}
          <br />
          {t("components.coachingForm.8")}
        </p>
        <p
          style={{ fontSize: "16px", marginTop: "30px", marginBottom: "10px" }}
          className="title-bold montserrat"
        >
          {t("components.coachingForm.9")}
        </p>
        <p className="text-300 center">
          {t("components.coachingForm.10")}
          <br />
          {t("components.coachingForm.11")}
        </p>
        <img
          alt="01supply boost pricing"
          src={coachingPricingImg}
          style={{ width: "330px", marginTop: "40px" }}
        />

        <p
          style={{ fontSize: "16px", marginTop: "30px", marginBottom: "10px" }}
          className="title-bold montserrat"
        >
          {t("components.coachingForm.12")}
        </p>
        <p className="text-300 center">
          {t("components.coachingForm.13")}
          <br />
          <span onClick={goBoost} className="text-bold blue underline pointer">
            {t("components.coachingForm.14")}
          </span>{" "}
          {t("components.coachingForm.15")}
        </p>
        <p
          style={{ fontSize: "16px", marginTop: "30px", marginBottom: "10px" }}
          className="title-bold montserrat"
        >
          {t("components.coachingForm.20")}
        </p>
        <p className="text-300 center">
          {t("components.coachingForm.21")}
          <br />
          {t("components.coachingForm.22")}
        </p>
      </div>
    </Modal>
  );
};

export default InformationsModal;
