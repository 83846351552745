import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Drawer, Input } from "antd";
import ContentMenu from "../FirstMenu";
import { MenuOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import AffiliationMenu from "../../../components/menu/AffiliateMenu";
import CountryCurrencySelector from "../../CountryCurrencySelector";
import { routes } from "../../../routes";

import logo from "../../../images/logo.svg";
import chatImg from "../../../images/icons/chat.png";

const FirstMenu = ({
  load,
  setNoResult,
  datas,
  setDatas,
  attribut,
  locationP,
  setAllProducts,
  skip,
  limit,
  thematics,
  setThematics,
  thematicFilter,
  filterProducts,
  allThematicProducts,
  setSearch,
  isSearchDisplay,
}) => {
  const [stockFilterDatas, setStockFilterDatas] = useState([]);
  const [menuOn, setMenuOn] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const [stockDatas, setStockDatas] = useState([]);
  const [wordSearch, setWordSearch] = useState("");
  const [isStickyNav, setIsStickyNav] = useState(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const columnScrollingElement = document.getElementsByClassName("column-scrolling")?.[0];

  useEffect(() => {
    if (columnScrollingElement) {
      columnScrollingElement.onscroll = function (e) {
        if (columnScrollingElement?.scrollTop > 90 && !isStickyNav) {
          setIsStickyNav(true);
        } else setIsStickyNav(false);
      };
    }
  }, [columnScrollingElement]);

  window.onscroll = function (e) {
    if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else setIsStickyNav(false);
  };

  useEffect(() => {
    if (stockDatas) {
      if (stockDatas.length < 1) setStockDatas(datas);
      if (stockFilterDatas && stockFilterDatas.length < 1) setStockFilterDatas(filterProducts);
    }

    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [datas]);

  const handleSearch = () => {
    history.push(`/marketplace?page=1&search=${wordSearch}`);
  };

  return (
    <div className={isStickyNav ? "menu-mobile-container sticky" : "menu-mobile-container"}>
      {!searchOn ? (
        <React.Fragment>
          <div onClick={() => setMenuOn(true)} className="burger-menu">
            <MenuOutlined className="icon-burger-menu" />
          </div>
          <a
            style={{ maxHeight: "100%", justifyContent: "center", display: "flex" }}
            className="logo-menu"
            href="https://01supply.com"
          >
            <img style={{ height: "40px" }} src={logo} alt="supply.me logo" />
          </a>

          <SearchOutlined
            onClick={() => {
              setSearchOn(true);

              if (setThematics) {
                setThematics(false);
              }
            }}
            style={{ fontSize: "30px" }}
          />
        </React.Fragment>
      ) : (
        <div style={{ width: "90%", alignContent: "center" }} className="row">
          <CloseOutlined
            onClick={() => {
              setSearchOn(false);
              handleSearch();
            }}
            className="button orange-back"
            style={{ fontSize: "25px", marginRight: "10px", padding: "5px", borderRadius: "50%" }}
          />
          <Input
            onPressEnter={handleSearch}
            maxLength={100}
            style={{ width: "90%", maxWidth: "650px" }}
            className="input"
            placeholder={t("components.menu.48")}
            suffix={<SearchOutlined style={{ color: "rgb(129, 129, 129)" }} />}
            onChange={(e) => setWordSearch(e.target.value)}
            autoFocus={!thematics}
          />
        </div>
      )}

      {menuOn && (
        <Drawer
          title={
            <div className="column end width-100">
              <CountryCurrencySelector
                selectorStyle={{
                  fontSize: "12px",
                  maxWidth: "210px",
                  marginLeft: "10px",
                  maxHeight: "20px",
                  minHeight: "20px"
                }}
              />
              <a
                style={{ fontSize: "13px", marginTop: "5px" }}
                href="https://chatting.page/rwhlsppllnpriozhtsqysjubupzuunlk"
                className="underline pointer"
              >
                Contact us
                <img alt="chat icon" src={chatImg} style={{ width: "16px", marginLeft: "5px" }} />
              </a>
            </div>
          }
          placement="left"
          closable={true}
          onClose={() => setMenuOn(false)}
          visible={menuOn}
        >
          {locationP === "affiliation" ? <AffiliationMenu /> : <ContentMenu />}
        </Drawer>
      )}
    </div>
  );
};

export default FirstMenu;
