import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { Modal } from "../../wrappers";
import { url } from "../../api/url";
import { header } from "../../api/header";
import OgImg from "../../images/og_image_2.png";

import RegisterModal from "../../components/modals/RegisterModal";
import PricingModal from "../../components/reseller/pricing/FirstPlans";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/menu/marketplaceNavbar";

import WinningProducts from "./Winning";
import HighMarginProducts from "./HighMargin";
import AnalyticsProducts from "./Analytics";
import Categories from "./Categories";
import ProductSuggestions from "./ProductSuggestions";

import "./style.scss";

const HightMarginMarketplace = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [user, setUser] = useState({});
  const [registerModalIsActive, setRegisterModalIsActive] = useState("");
  const [isModalPricingDisplayed, setIsModalPricingDisplayed] = useState(false);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [window.tidioChatApi]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page marketplace-home">
      <RegisterModal modalOn={registerModalIsActive} setModal={setRegisterModalIsActive} />
      {isModalPricingDisplayed && (
        <Modal
          className="modal-full-screen"
          visible={true}
          footer={[]}
          onCancel={() => setIsModalPricingDisplayed(false)}
        >
          <div className="column width-100" style={{ alignItems: "center" }}>
            <PricingModal setRegisterModalIsActive={setRegisterModalIsActive} windowSize={windowSize} />
          </div>
        </Modal>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marketplace | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/marketplace" />
        <meta name="description" content={t("marketplace.metas.description")} />
        <meta property="og:description" content={t("marketplace.metas.description")} />
        <meta property="og:url" content="https://app.01supply.com/marketplace" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Marketplace | 01supply`} />
        <meta property="og:image" content={OgImg} />
      </Helmet>

      <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page-start">
        {windowSize > 1150 ? (
          <Menu locationP="marketplace-home" userProps={user} />
        ) : (
          <MobileMenu locationP="marketplace-home" />
        )}
        <div style={{ padding: "0", paddingTop: windowSize > 1150 && "200px" }} className="column-scrolling">
          {windowSize > 1150 && (
            <Header isMarketMenu={true} windowSize={windowSize} user={user} isNotSticky={true} />
          )}
          <div
            id="container-marketplace"
            className="column"
            style={
              windowSize > 1150
                ? {
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    paddingBottom: "0px",
                    paddingLeft: "20px",
                    minHeight: "100vh",
                  }
                : { alignItems: "center" }
            }
          >
            <div
              className="column"
              style={
                windowSize > 1150
                  ? { marginTop: `${"10px"}`, width: "100%", alignItems: "center" }
                  : { width: "100%" }
              }
            >
              {idAccount && <ProductSuggestions setUser={setUser} user={user} windowSize={windowSize} />}
              <WinningProducts
                setUser={setUser}
                windowSize={windowSize}
                user={user}
                setIsModalPricingDisplayed={setIsModalPricingDisplayed}
                setRegisterModalIsActive={setRegisterModalIsActive}
              />
              <Categories />
              <AnalyticsProducts
                setUser={setUser}
                user={user}
                windowSize={windowSize}
                setIsModalPricingDisplayed={setIsModalPricingDisplayed}
                setRegisterModalIsActive={setRegisterModalIsActive}
              />
              <HighMarginProducts
                setUser={setUser}
                user={user}
                windowSize={windowSize}
                setIsModalPricingDisplayed={setIsModalPricingDisplayed}
                setRegisterModalIsActive={setRegisterModalIsActive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HightMarginMarketplace;
