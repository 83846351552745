import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { urlParams } from "../../utils/urlParams";

const CheckToken = ({ setTokenCheck }) => {

  const paramsUrl = urlParams();

  useEffect(() => {
    check();
  }, []);

  const check = async () => {
    const resConnect = await fetch(`${url}/check-token`, {
      method: "POST",
      credentials: "include",
      headers: header,
      body: JSON.stringify({
        token: localStorage.getItem("tokenCreatosell"),
        email: localStorage.getItem("emailAccountCreatosell"),
      }),
    });
    if (resConnect.status == 200) setTokenCheck(true);
    else {
      setTokenCheck(false);
    }
  };

  return <div></div>;
};

export default CheckToken;
