import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Switch, Space } from "antd";

import UpdateNumberModal from "./UpdateNumberModal";

import arrowRightIcon from "../../../../images/icons/arrow_right.png";

const defaultUpdateNumberValue = {
  defaultValue: null,
  title: null,
  handleSubmit: () => {},
};

const RetailPrice = ({ user, handleUpdateSetting }) => {
  const [updateNumberModal, setUpdateNumberModal] = useState(defaultUpdateNumberValue);
  const { t } = useTranslation();

  return (
    <div style={{ marginLeft: "0", marginTop: "0" }} className="card width-100">
      {updateNumberModal.title && (
        <UpdateNumberModal
          setIsDisplayed={() => setUpdateNumberModal(defaultUpdateNumberValue)}
          handleSubmit={updateNumberModal.handleSubmit}
          defaultValue={updateNumberModal.defaultValue}
          title={updateNumberModal.title}
        />
      )}
      <p style={{ fontSize: "27px" }} className="title montserrat-800 grey">
        {t("priceRules.retailPrice.title")}
      </p>
      <p style={{ marginTop: "25px" }} className="title-500 start">
        {t("priceRules.retailPrice.subtitle")}
      </p>
      <Radio.Group
        style={{ marginBottom: "15px", marginTop: "15px" }}
        className="blue"
        defaultValue={user?.retailPrice?.custom ? "true" : ""}
        onChange={(e) =>
          handleUpdateSetting({
            retailPrice: {
              ...(user.retailPrice || {}),
              custom: !!e.target.value,
              multiplier: user.retailPrice?.multiplier || 2.5,
              cents: user.retailPrice?.cents || 99,
            },
          })
        }
      >
        <Space direction="vertical">
          <Radio value="">{t("priceRules.retailPrice.choice1")}</Radio>
          <Radio value="true">
            <p>{t("priceRules.retailPrice.choice2")}</p>
            <div style={{ marginTop: "5px", fontSize: "13px" }} className="row">
              <img src={arrowRightIcon} alt="arrow" style={{ width: "17px", marginRight: "10px" }} />
              {t("priceRules.retailPrice.multiplier")}{" "}
              <span
                onClick={() =>
                  setUpdateNumberModal({
                    defaultValue: user.retailPrice?.multiplier || 2.5,
                    title: t("priceRules.retailPrice.modal.title-1"),
                    handleSubmit: (value) => {
                      handleUpdateSetting({
                        retailPrice: {
                          ...(user.retailPrice || {}),
                          multiplier: value,
                        },
                      });
                      setUpdateNumberModal(defaultUpdateNumberValue);
                    },
                  })
                }
                className="text blue underline pointer"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                x{user.retailPrice?.multiplier || 2.5}
              </span>
            </div>
            <div style={{ marginTop: "5px", fontSize: "13px" }} className="row">
              <img src={arrowRightIcon} alt="arrow" style={{ width: "17px", marginRight: "10px" }} />
              {t("priceRules.retailPrice.setCents")}{" "}
              <span
                onClick={() =>
                  setUpdateNumberModal({
                    defaultValue: user.retailPrice?.cents || 99,
                    title: t("priceRules.retailPrice.modal.title-2"),
                    handleSubmit: (value) => {
                      handleUpdateSetting({
                        retailPrice: {
                          ...user.retailPrice,
                          cents: value,
                        },
                      });
                      setUpdateNumberModal(defaultUpdateNumberValue);
                    },
                  })
                }
                className="text blue underline pointer"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                {user.retailPrice?.cents || 99}
              </span>
            </div>
          </Radio>
        </Space>
      </Radio.Group>

      <div style={{ marginTop: "10px" }} className="row">
        <Switch
          defaultChecked={user?.retailPrice?.anable}
          style={{ marginRight: "10px" }}
          onChange={(checked) => {
            handleUpdateSetting({
              retailPrice: {
                ...user.retailPrice,
                anable: checked,
              },
            });
          }}
        />
        {user.retailPrice?.anable ? (
          <p style={{ fontSize: "15px" }} className="text montserrat">
            {t("hide")} / <b>{t("show")}</b>
          </p>
        ) : (
          <p style={{ fontSize: "15px" }} className="text montserrat">
            <b> {t("hide")} </b>/ {t("show")}
          </p>
        )}
      </div>
    </div>
  );
};

export default RetailPrice;
