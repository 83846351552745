import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { parseDate } from "../../utils/parseDate";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { url } from "../../api/url";
import { header } from "../../api/header";
import Load from "../../utils/Load";

import shieldImg from "../../images/icons/secure.svg";

const CoachingCard = ({ coaching, index, windowSize }) => {
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [load, setLoad] = useState(false);
  const [coachingStatus, setCoachingStatus] = useState("configuration"); // configuration | in demand | accepted | activated | cancel | finish

  const { t } = useTranslation();

  const isCurrentCoaching = coaching?.history !== undefined;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  let stripe = "";
  stripePromise.then((resStripe) => (stripe = resStripe));

  const estimateSalesErrorMarge = 0.8;

  const subscribe = async () => {
    setLoad(true);
    fetch(`${url}/coaching/subscribe/${idAccount}/${secretId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          message.error(t('messages.error'));
          setLoad(false);
          window.tidioChatApi?.open();
          window.tidioChatApi.messageFromVisitor("Hello, I just had a problem paying my Boost");
          return {};
        }
      })
      .then(({ sessionId }) => {
        if (sessionId) {
          stripe.redirectToCheckout({ sessionId });
        }
      });
  };

  useEffect(() => {
    if (coaching?._id) {
      if (coaching.status) {
        setCoachingStatus(coaching.status);
      } else if (coaching?.is_activate) {
        setCoachingStatus("activated");
      } else if (coaching?.is_validate) {
        setCoachingStatus("accepted");
      } else if (coaching?.status === "configuration") {
        setCoachingStatus("configuration");
      } else {
        setCoachingStatus("in demand");
      }
    }
  }, [coaching]);

  return (
    <Elements stripe={stripePromise}>
      {load && <Load />}
      <div
        key={index}
        style={{
          width: "100%",
          marginBottom: "30px",
          marginLeft: `${windowSize > 1150 ? "0" : "2.5%"}`,
          minWidth: `${windowSize > 1150 ? "770px" : ""}`,
        }}
        className="order-card card over-effect"
      >
        {/* HEAD CARD */}
        <div
          style={
            windowSize > 1150
              ? { width: "100%", paddingRight: "10px", paddingLeft: "10px" }
              : {
                  width: "100%",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  flexDirection: "column",
                }
          }
          className="row"
        >
          <div className="row" style={windowSize > 1150 ? { width: "60%", marginRight: "5%" } : {}}>
            <p style={{ fontSize: "18px", marginRight: "20px" }} className="text-bold">
              {index}
            </p>
            <p className="text-300">{parseDate(coaching.start_date)}</p>
          </div>

          {isCurrentCoaching && (
            <div
              className="pointer row"
              style={
                windowSize > 1150
                  ? { width: "30%", marginLeft: "5%", justifyContent: "flex-end" }
                  : { position: "absolute", top: "0", right: "0" }
              }
            >
              <p
                onClick={() => {
                  window.tidioChatApi.open();
                  window.tidioChatApi.messageFromVisitor("Hello, I want to stop my Boost");
                }}
                className="text red underline pointer"
              >
                {t("components.coachingForm.64")}
              </p>
            </div>
          )}
        </div>

        <div className="divider-horizontal" style={{ width: "100%", marginLeft: "0" }} />

        <div
          style={
            windowSize > 1150
              ? { width: "100%", marginTop: "10px", alignItems: "center" }
              : { flexDirection: "column", width: "100%", marginTop: "10px", alignItems: "flex-start" }
          }
          className="row"
        >
          <div
            className={`initial-cursor ${
              coachingStatus === "cancel"
                ? "button-back-red"
                : coachingStatus === "in demand"
                ? "button orange-back-2"
                : coachingStatus === "accepted"
                ? "button blue-back"
                : coachingStatus === "activated"
                ? "button green-back"
                : coachingStatus === "finish" && "button grey-back"
            }`}
            style={{ padding: "4px 8px 4px 8px", boxShadow: "inherit", width: "230px" }}
          >
            {coachingStatus === "cancel"
              ? t("components.coachingForm.52")
              : coachingStatus === "in demand"
              ? t("components.coachingForm.53")
              : coachingStatus === "accepted"
              ? t("components.coachingForm.54")
              : coachingStatus === "activated"
              ? t("components.coachingForm.55")
              : coachingStatus === "finish" && "Finished"}
          </div>

          {coachingStatus === "activated" && (
            <p
              style={windowSize > 1150 ? { marginLeft: "15px" } : { marginTop: "15px", marginBottom: "15px" }}
              className="text-bold"
            >
              {t("components.coachingForm.56")}<br/>
              <span style={{ fontWeight: "400" }}>{t("components.coachingForm.57")}</span>
            </p>
          )}

          {coachingStatus === "in demande" ||
            (coachingStatus === "activated" && (
              <button
                onClick={() => {
                  window.tidioChatApi.open();
                  window.tidioChatApi.messageFromVisitor("Hello, I will contact you regarding the boosts");
                }}
                className="button order-card orange-back"
              >
                {t("components.coachingForm.58")}
              </button>
            ))}
          {coachingStatus === "accepted" && (
            <button onClick={subscribe} className="button order-card orange-back">
              {t("components.coachingForm.59")}
            </button>
          )}
        </div>

        <div
          className={windowSize > 1150 ? "row" : "column"}
          style={{
            borderRadius: "6px",
            padding: "20px",
            width: "100%",
            background: "#F6F6F6",
            alignItems: "flex-start",
            marginTop: "35px",
          }}
        >
          <div
            style={
              windowSize > 1150
                ? { width: "30%", marginRight: "5%" }
                : { width: "100%", marginBottom: "10px" }
            }
            className="column"
          >
            <p style={{ marginBottom: "7px" }} className="text-bold">
              {t("components.coachingForm.61")}
            </p>
            <p style={{ marginBottom: "5px" }} className="text-bold">
              +{coaching.sellers_per_month}-{coaching.sellers_per_month + 1} {t("resellers")}{" "}
            </p>
          </div>
          <div
            style={windowSize > 1150 ? { width: "30%" } : { width: "100%", marginBottom: "10px" }}
            className="column"
          >
            <p style={{ marginBottom: "5px", marginTop: "30px" }} className="text-bold">
              <span style={{ fontSize: "14px" }} className="text-300">
                {t("price").toUpperCase()}:{" "}
              </span>{" "}
              ${(coaching.price).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default CoachingCard;
