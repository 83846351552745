import React, { useState } from "react";
import {message, Select} from 'antd'
import {url} from '../../../api/url'
import {header} from '../../../api/header'

const SelectThematics = ({ thematics, product, categorys }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));

  const selectThematic = async (newProduct) => {
    const res = await fetch(`${url}/admin/update-product/${adminId}/${product._id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        product: newProduct
      }),
    });
    if (res.status === 200) {
      message.success('Thematic updated !')
    } else message.error("error");
  };

  const Option = {Select}

  return (
    <div style={{ width: "30%", marginLeft: '5%' }} className="row">
      <Select
      showSearch
      filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
      }
        style={{ width: "45%", marginLeft: '5%' }}
        onChange={(value) => selectThematic({...product, thematic: {
          id: thematics[parseInt(value)]._id,
          name: thematics[parseInt(value)].name.replace(/^\s+|\s+$/g, "")
        }})}
        defaultValue={product.thematic?.name || "select thematic..."}
      >
        {thematics.map((them, index) => {
          return <Option value={index}>{them.name}</Option>;
        })}
      </Select>
      <Select
      showSearch
      filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
      }
        style={{ width: "45%", marginLeft: '5%' }}
        onChange={(value) => selectThematic({...product, category: categorys[parseInt(value)].name})}
        defaultValue={product.category || "select category..."}
      >
        {categorys.map((category, index) => {
          return <Option value={index}>{category.name}</Option>;
        })}
      </Select>

    </div>
  );
};

export default SelectThematics;
