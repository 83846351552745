import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { header } from "../../../api/header";
import { url } from "../../../api/url";

import { subscriptionEvent } from "../../../utils/analyticEvents";

import PricingCard1 from "./card1";
import PricingCard2 from "./card2";
import PricingCard3 from "./card3";
import PricingCard4 from "./card4";

import CancelSub from "./CancelSub";
import PlanPayment from "../paid_plan/Paid";

import Load from "../../../utils/Load";
import { urlParams } from "../../../utils/urlParams";
import { klaviyoServices } from "../../../services/klaviyo";

import "./style.scss";
import YearlySwitch from "./YearlySwitch";
import SecondPricing from "./secondPricing";

const buttonStyle = {
  lineHeight: "30px",
  marginTop: "20px",
  marginBottom: "5px",
  width: "100%",
};

const ResellerPlans = ({ windowSize, setRegisterModalIsActive, isSecondPricingDisplayed }) => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [typePlan, setTypePlan] = useState("");
  const [cancelSub, setCancelSub] = useState("");
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [redirect, setRedirect] = useState(false);
  const [load, setLoad] = useState(false);
  const [modalPaidPlan, setModalPaidPlan] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const { t } = useTranslation();

  const paramsUrl = urlParams();


  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();

    localStorage.setItem("viewPlan01supply", "true");
  }, []);

  const checkUrlParams = () => {
    if (paramsUrl.type && !user.shopify?.shop) {
      setTypePlan(paramsUrl.type);
      setModalPaidPlan(true);
    }
  };

  useEffect(() => {
    if (user && user.plan) {
      const href = window.location.href;
      if (href.includes("charge_id=") && href.includes("subscription") && href.includes("userId")) {
        const urlPath = new URL(href);
        const chargeId = urlPath.searchParams.get("charge_id");
        const subId = urlPath.searchParams.get("subscription");
        const userId = urlPath.searchParams.get("userId");
        const isYearly = urlPath.searchParams.get("isYearly");
        getShopifySubscription(chargeId, subId, userId, isYearly);
      }
    }
    if (user._id) {
      checkUrlParams();
    }
  }, [user]);

  const getShopifySubscription = async (chargeId, subId, userId, isYearly) => {
    const res = await fetch(`${url}/user/shopify/subscription/${chargeId}/${subId}/${userId}/${isYearly}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    if (resJson) {
      window.location.search = "";
      message.success(t("messages.subUpdated"));
      klaviyoServices.checkStatus();
      subscriptionEvent({ value: resJson.price, subscriptionName: resJson.plan, user });
      setTimeout(() => {
        setRedirect("/pricing");
      }, 300);
      setCancelSub("");
    }
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  const cancelStripeSub = async () => {
    const res = await fetch(`${url}/user/stripe/cancel-subscription`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        subscriptionId: user.plan.subscription_id,
        userId: user._id,
      }),
    });
    if (res.status === 200) {
      let newUser = user;
      newUser.plan.type = cancelSub;
      setUser(newUser);
      setCancelSub("");
      message.success(t("messages.subcriptionDeleted"));
    } else message.error(t("messages.error"));
  };

  const subscribeWithShopify = async (plan) => {
    const res = await fetch(`${url}/user/shopify/subscription`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        user,
        plan,
        isYearly,
      }),
    });
    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson.plan && resJson.plan === "free") {
        let newUser = { ...user };
        newUser.plan.type = "free";
        setUser({ ...newUser });
        setCancelSub("");
        message.success(t("messages.subcriptionDeleted"));
        setLoad(false);
      } else window.location.href = resJson.shopifySubUrl;
    } else {
      message.error(t("messages.error"));
    }
  };

  const isCurrentPlan = ({ planType }) => {
    return (
      ((!user?.plan?.type && planType === "free") || user?.plan?.type === planType) &&
      ((user.plan?.isYearly === true && isYearly) || (user.plan?.isYearly !== true && !isYearly))
    );
  };

  const handleClickPlan = ({ planType }) => {
    if (isCurrentPlan({ planType })) {
      setCancelSub(planType);
    } else if (!user?._id) {
      setRegisterModalIsActive("/pricing");
    } else if (planType === "free") {
      setRedirect("/inventory");
    } else if (user.shopify && user.shopify.shop && user.shopify.token) {
      setLoad(true);
      subscribeWithShopify(planType);
    } else {
      setTypePlan(planType);
      setModalPaidPlan(true);
    }
  };

  const PlanButton = ({ type, isSecondPlans }) => {
    return !(user.plan?.type === type && !isYearly && user.plan?.isYearly) || isYearly ? (
      <>
        {!isSecondPlans && isCurrentPlan({ planType: type }) && (
          <p style={{ marginTop: "10px", marginBottom: "-10px" }} className="title-bold montserrat-800">
            {t("currentPlan")}
          </p>
        )}
        <button
          onClick={() => handleClickPlan({ planType: type })}
          style={buttonStyle}
          className={isCurrentPlan({ planType: type }) ? "button-disable-grey pointer" : "button orange-back"}
        >
          {isCurrentPlan({ planType: type }) ? t("cancelPlan") : t("choosePlan")}
        </button>
      </>
    ) : (
      <p style={{ marginTop: "10px", marginBottom: "-10px" }} className="title-bold montserrat-800">
        {t("currentPlan")}
      </p>
    );
  };

  return (
    <div style={{ alignItems: "center" }} className="column">
      <YearlySwitch windowSize={windowSize} isYearly={isYearly} setIsYearly={setIsYearly} />
      <div style={{ justifyContent: "center" }} className="row-wrap start">
        {load && <Load />}
        {redirect && <Redirect to={redirect} />}
        {typePlan && modalPaidPlan && (
          <PlanPayment
            setUser={setUser}
            setTypePlan={setTypePlan}
            setModal={setModalPaidPlan}
            user={user}
            typePlan={typePlan}
            setCancelSub={setCancelSub}
            isYearly={isYearly}
            setIsYearly={setIsYearly}
          />
        )}
        {cancelSub && (
          <CancelSub
            cancelSub={CancelSub}
            setCancelSub={setCancelSub}
            user={user}
            setLoad={setLoad}
            subscribeWithShopify={subscribeWithShopify}
            cancelStripeSub={cancelStripeSub}
            setTypePlan={setTypePlan}
            setModalPaidPlan={setModalPaidPlan}
          />
        )}
        <div className={windowSize > 1150 ? "row row-wrap start" : "row row-wrap center width-100"}>
          <PricingCard1
            button={
              isCurrentPlan({ planType: "free" }) ? (
                <button style={buttonStyle} className={"button-disable-grey pointer"}>
                  {t("currentPlan")}
                </button>
              ) : (
                <div style={{ ...buttonStyle, height: "40px" }} />
              )
            }
            windowSize={windowSize}
          />
          <PricingCard2
            isYearly={isYearly}
            button={<PlanButton type={"basic"} />}
            windowSize={windowSize}
            user={user}
          />
        </div>
        <div className={windowSize > 1150 ? "row row-wrap start" : "row row-wrap center width-100"}>
          <PricingCard3
            isYearly={isYearly}
            button={<PlanButton type={"advanced"} />}
            windowSize={windowSize}
            user={user}
          />
          <PricingCard4
            isYearly={isYearly}
            button={<PlanButton type={"premium"} />}
            windowSize={windowSize}
            user={user}
          />
        </div>
      </div>

      {isSecondPricingDisplayed && <SecondPricing PlanButton={PlanButton} windowSize={windowSize} />}
    </div>
  );
};

export default ResellerPlans;
