import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import Footer from "../../../components/menu/Footer";
import Navbar from "../../../components/resellerCoaching/Navbar";

import buttonImg from "../../../images/resellerCoaching/pro_button.svg";
import illustration1 from "../../../images/resellerCoaching/500pmhIllustration.svg";
import arrowImg from "../../../images/resellerCoaching/arrow.svg";
import illustration2 from "../../../images/resellerCoaching/graphic.svg";
import illustration3 from "../../../images/resellerCoaching/coaching_girl.png";
import checkImg from "../../../images/resellerCoaching/check.svg";
import brandingIcon from "../../../images/resellerCoaching/branding_icon.svg";
import facebookIcon from "../../../images/resellerCoaching/facebook_icon.svg";
import tiktokIcon from "../../../images/resellerCoaching/tiktok_icon.svg";
import shieldIcon from "../../../images/resellerCoaching/shield.svg";
import copywright from "../../../images/resellerCoaching/copywright_icon.svg";
import searchIcon from "../../../images/resellerCoaching/search_icon.svg";
import adsIcon from "../../../images/resellerCoaching/ads_icon.svg";
import aamrrIcon from "../../../images/resellerCoaching/aamrr_icon.svg";
import maillingIcon from "../../../images/resellerCoaching/mailling_icon.svg";
import illustration8 from "../../../images/illustrations/boostPresentation/roadmap.svg";
import supplyImg from "../../../images/icons/01supply_academy.svg";
import starsImg from "../../../images/icons/stars.svg";
import arrow1Img from "../../../images/illustrations/boostPresentation/arrow1.svg";
import arrow2Img from "../../../images/illustrations/boostPresentation/arrow2.svg";
import googleMeetIllustration from "../../../images/resellerCoaching/google_meet.svg";
import messageIllustration from "../../../images/resellerCoaching/message.svg";
import callIllustration from "../../../images/resellerCoaching/call.svg";
import { t } from "i18next";

import "./style.scss";

const { Panel } = Collapse;

const Coaching1 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.tidioChatApi.show();
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="reseller-coaching pro">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pro Accompaniment | 01supply Academy</title>
        <meta
          name="description"
          content="With the Pro Accompaniment, we will help you create a brand. You have access to 3hours of monthly coaching, weekly strategies in your inbox & full courses."
        />
      </Helmet>
      <Navbar />
      <div className="black-banner">
        <div className="left-column">
          <p style={{ fontSize: "55px", marginTop: "30px" }} className="title montserrat-800 orange">
            5x<span style={{ fontWeight: "400", fontSize: "30px" }}> {t("resellerCoaching.faster")}</span>
          </p>
          <p style={{ fontSize: "35px", marginTop: "-15px" }} className="title montserrat-800 white">
            {t("resellerCoaching.success")}
          </p>
          <p className="text-400 white">{t("resellerCoaching.80")}</p>
          <Link
            style={{ width: "230px", marginTop: "15px", boxShadow: "none" }}
            to="/ecommerce-coaching-payment?coaching=pro"
            className="button big orange-back"
          >
            {t("resellerCoaching.subscribe")}
          </Link>
        </div>
        <img
          alt="boost your business"
          src={illustration1}
          className="right-column"
          style={{ width: "25%" }}
        />
      </div>
      <div className="black-banner">
        <img src={arrowImg} alt="arrow" className="arrow-1" />
        <h1 style={{ fontSize: "25px" }} className="title start white montserrat-800">
          {t("resellerCoaching.81")}
          <br />
          {t("resellerCoaching.82")} <span className="orange">{t("resellerCoaching.83")}</span>{" "}
          {t("resellerCoaching.84")}
          <br />
          {t("resellerCoaching.85")}
          <br />
          {t("resellerCoaching.86")} <span className="orange">{t("resellerCoaching.87")}</span>
        </h1>
      </div>
      <div className="banner-3">
        <div className="card">
          <img src={illustration2} className="illustration-2" alt="e-commerce boost exemple" />
          <div className="right-column">
            <div className="row">
              <p
                style={{ fontSize: "30px", marginRight: "20px", lineHeight: "33px" }}
                className="title montserrat-800 orange end"
              >
                80
                <br />%
              </p>
              <p>
                {t("resellerCoaching.54")}
                <br />
                {t("resellerCoaching.55")} <b>{t("resellerCoaching.56")}</b>
                <br />
                {t("resellerCoaching.57")}
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="row">
              <p
                style={{ fontSize: "30px", marginRight: "20px", lineHeight: "33px", color: "#FFC288" }}
                className="title montserrat-800 end"
              >
                20
                <br />%
              </p>
              <p>
                {t("resellerCoaching.58")}
                <br />
                {t("resellerCoaching.59")} <b>{t("resellerCoaching.60")}</b>
                <br />
                {t("resellerCoaching.61")}
              </p>
            </div>
          </div>
        </div>
        <p
          style={{ marginTop: "40px", marginBottom: "40px", fontSize: "28px", textAlign: "center" }}
          className="title grey montserrat-800"
        >
          {t("resellerCoaching.62")}
        </p>
        <div style={{ alignItems: "flex-start" }} className="row width-100 center banner-4">
          <img
            style={{ width: "260px" }}
            src={illustration3}
            alt="coaching girl 01supply"
            className="left-column"
          />
          <div className="right-column">
            <div className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-bold orange">
                {t("resellerCoaching.63")} <span className="text-400 black">{t("resellerCoaching.64")}</span>
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-400">
                {t("resellerCoaching.17")} <span className="text-bold black">{t("resellerCoaching.18")}</span>{" "}
                {t("resellerCoaching.19")}
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-400">
                <span className="text-bold black">{t("resellerCoaching.20")}</span> {t("resellerCoaching.21")}
              </p>
            </div>
            <div style={{ marginTop: "20px" }} className="width-100 row start">
              <img src={checkImg} alt="check" className="check-img" />
              <p style={{ fontSize: "16px" }} className="text-400">
                {t("resellerCoaching.22")} <span className="text-bold black">{t("resellerCoaching.23")}</span>
              </p>
            </div>
          </div>
        </div>
        <p
          style={{ marginTop: "80px", marginBottom: "40px", fontSize: "28px", textAlign: "center", maxWidth: '90vw' }}
          className="title grey montserrat-800"
        >
          {t("resellerCoaching.65")}
        </p>
        <div style={{ alignItems: "flex-start" }} className="row width-100 communicate center">
          <div className="column" style={{ width: "260px", margin: "30px", alignItems: "center" }}>
            <img
              alt="google meet conversation"
              src={googleMeetIllustration}
              style={{ height: "150px", maxWidth: "100%" }}
            />
            <p
              className="montserrat width-100 start"
              style={{ fontSize: "25px", fontWeight: "500", marginTop: "10px" }}
            >
              {t("resellerCoaching.66")}
            </p>
            <p className="montserrat orange width-100 start" style={{ fontSize: "25px", fontWeight: "500" }}>
              Google meet
            </p>
          </div>
          <div
            className="column"
            style={{
              width: "260px",
              margin: "30px",
              flexDirection: windowSize < 1150 ? "column-reverse" : "column",
              alignItems: windowSize < 1150 ? "flex-start" : "center",
            }}
          >
            <p className="montserrat width-100 start" style={{ fontSize: "25px", fontWeight: "500" }}>
              Conversations
            </p>
            <p
              className="montserrat orange width-100 start"
              style={{ fontSize: "25px", fontWeight: "500", marginBottom: windowSize > 1150 && "20px" }}
            >
              {t("resellerCoaching.67")}
            </p>
            <img
              alt="text message conversation"
              src={messageIllustration}
              style={{ height: "150px", maxWidth: "100%", marginBottom: windowSize < 1150 && "20px" }}
            />
          </div>
          <div className="column" style={{ width: "260px", margin: "30px" }}>
            <img
              alt="google meet conversation"
              src={callIllustration}
              style={{ height: "150px", maxWidth: "100%" }}
            />
            <p style={{ marginTop: "10px" }} className="text-300 width-100 start">
              {t("or")}
            </p>
            <p className="montserrat width-100 start" style={{ fontSize: "25px", fontWeight: "500" }}>
              Discussions
            </p>
            <p className="montserrat orange width-100 start" style={{ fontSize: "25px", fontWeight: "500" }}>
              {t("resellerCoaching.68")}
            </p>
          </div>
        </div>
        <p style={{ color: "#B9B9B9", maxWidth: '90vw' }} className="text grey">
          {t("resellerCoaching.69")}
        </p>
        <div className="banner-5">
          <div className="card">
            <div className="column width-100" style={{ alignItems: "center" }}>
              <p style={{ fontSize: "25px" }} className="title montserrat-800 orange">
                {t("resellerCoaching.24")}
              </p>

              <div style={{ justifyContent: "center", marginTop: "20px" }} className="row start width-100">
                <div className="left-column">
                  <p style={{ fontSize: "20px", marginBottom: "15px" }} className="title montserrat-800">
                    <span style={{ color: "#E83535" }}>3x</span> {t("resellerCoaching.70")}
                  </p>
                  <p>
                    {t("resellerCoaching.102")}
                    <br />
                    <br />
                    {t("resellerCoaching.72")}
                  </p>
                </div>
                <div className="right-column">
                  <p style={{ fontSize: "20px", marginBottom: "15px" }} className="title montserrat-800">
                    {t("resellerCoaching.73")}
                  </p>
                  <p>
                    {t("resellerCoaching.74")}
                    <br />
                    <br />
                    {t("resellerCoaching.75")}
                  </p>
                </div>
              </div>
              <div style={{ marginTop: "30px" }} className="width-100 start">
                <div className="right-column">
                  <p style={{ fontSize: "20px" }} className="title montserrat-800 start">
                    {t("resellerCoaching.88")}
                  </p>
                  <p style={{ marginBottom: "15px" }} className="text grey">
                    {t("resellerCoaching.89")}
                  </p>
                  <p>
                    {t("resellerCoaching.90")}
                    <br />
                    <br />
                    {t("resellerCoaching.91")}
                  </p>
                </div>
              </div>
              <p
                style={{ marginTop: "35px", marginBottom: "20px", fontSize: "16px" }}
                className="title montserrat-800 grey"
              >
                {t("resellerCoaching.31")}
              </p>
              <div style={{ marginBottom: "30px" }} className="row width-100 center">
                <div style={{ alignItems: "center", marginRight: "20px", width: "100px" }} className="column">
                  <img src={adsIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">
                    Google
                    <br />
                    ads
                  </p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px", width: "100px" }} className="column">
                  <img src={aamrrIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">{t("resellerCoaching.92")}</p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px", width: "100px" }} className="column">
                  <img src={maillingIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">{t("resellerCoaching.93")}</p>
                </div>
              </div>
              <div style={{ marginBottom: "30px" }} className="row width-100 center">
                <div style={{ alignItems: "center", marginRight: "20px", width: "110px" }} className="column">
                  <img src={copywright} alt="copywright icon" className="icon" />
                  <p className="text grey center">{t("resellerCoaching.76")}</p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px", width: "110px" }} className="column">
                  <img src={searchIcon} alt="google search icon" className="icon" />
                  <p className="text grey center">{t("resellerCoaching.77")}</p>
                </div>
              </div>
              <div className="row width-100 center">
                <div style={{ alignItems: "center", marginRight: "20px" }} className="column">
                  <img src={brandingIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">{t("resellerCoaching.32")}</p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px" }} className="column">
                  <img src={facebookIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">Facebook ads</p>
                </div>
                <div style={{ alignItems: "center", marginRight: "20px" }} className="column">
                  <img src={tiktokIcon} alt="branding icon" className="icon" />
                  <p className="text grey center">Tiktok ads</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-6">
          <img alt="starter coaching" src={buttonImg} style={{ width: "180px", marginBottom: "50px" }} />
          <div className="row center width-100">
            <div className="left-column">
              <p className="text-bold start">
                {t("resellerCoaching.96")}
                <br />
                {t("resellerCoaching.103")}
              </p>
              <p style={{ margin: "20px 0" }} className="text start">
                {t("resellerCoaching.97")}
              </p>
              <p className="text start">{t("resellerCoaching.98")}</p>
            </div>
            <div className="right-column">
              <p style={{ fontSize: "28px" }} className="title">
                $499.99
              </p>
              <p className="text grey">{t("resellerCoaching.recuringCharge")}</p>
              <Link
                to="ecommerce-coaching-payment?coaching=pro"
                style={{ marginTop: "10px", marginBottom: "20px" }}
                className="button big orange-back width-100"
              >
                {t("resellerCoaching.subscribe")}
              </Link>
              <div className="row">
                <img alt="secure payment" src={shieldIcon} style={{ marginRight: "10px", width: "20px" }} />
                <p style={{ fontSize: "11px" }} className="text-bold green start">
                  {t("resellerCoaching.paymentSecurity")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="black-banner roadmap">
          <p style={{ fontSize: "28px" }} className="title montserrat-800 white">
            {t("resellerCoaching.36")}
          </p>
          {windowSize > 1150 ? (
            <>
              <div className="row roadmap1">
                <div style={{ width: "150px", marginRight: "240px" }} className="column">
                  <p className="text montserrat-800 white">{t("resellerCoaching.37")}</p>
                  <p className="text white">{t("resellerCoaching.process.1")}</p>
                </div>
                <div style={{ width: "200px" }} className="column">
                  <p className="text montserrat-800 white">{t("resellerCoaching.process.4")}</p>
                  <p className="text white">{t("resellerCoaching.process.8")}</p>
                </div>
              </div>

              <img alt="01supply boost roadmap" src={illustration8} className="illustration8" />

              <div className="row roadmap2">
                <div style={{ width: "170px" }} className="column roadmap-3">
                  <p className="text montserrat-800 white">{t("resellerCoaching.process.2")}</p>
                  <p className="text white">{t("resellerCoaching.process.3")}</p>
                </div>
                <div style={{ width: "170px" }} className="column roadmap-4">
                  <p className="text montserrat-800 white">{t("resellerCoaching.process.6")}</p>
                  <p className="text white">{t("resellerCoaching.process.7")}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="column">
              <div style={{ width: "150px" }} className="column roadmap-3">
                <p className="text montserrat-800 white">{t("resellerCoaching.37")}</p>
                <p className="text white">{t("resellerCoaching.process.1")}</p>
              </div>
              <div style={{ width: "170px" }} className="column roadmap-3">
                <p className="text montserrat-800 white">{t("resellerCoaching.process.2")}</p>
                <p className="text white">{t("resellerCoaching.process.8")}</p>
              </div>
              <div style={{ width: "200px" }} className="column roadmap-3">
                <p className="text montserrat-800 white">{t("resellerCoaching.process.4")}</p>
                <p className="text white">{t("resellerCoaching.process.5")}</p>
              </div>
              <div style={{ width: "170px" }} className="column roadmap-4">
                <p className="text montserrat-800 white">{t("resellerCoaching.process.6")}</p>
                <p className="text white">{t("resellerCoaching.process.7")}</p>
              </div>
            </div>
          )}
          <p className="text montserrat-700 white">
            BONUS : <span style={{ fontWeight: "400" }}>{t("resellerCoaching.79")}</span>
          </p>
          <p style={{ marginTop: "25px", color: "#FFD7B1" }} className="text montserrat-700">
            {t("resellerCoaching.99")} <span style={{ fontWeight: "400" }}>{t("resellerCoaching.100")}</span>
          </p>
        </div>

        {/* FAQ */}

        <div
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: "50px",
            maxWidth: "830px",
          }}
          className="row-wrap reviews"
        >
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.4.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.4.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.4.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.3")} key="3">
                <p className="text-roboto montserrat">
                  <p className="text-roboto montserrat">
                    {t("resellerCoaching.faq.4.responses.3")}
                    <a
                      href="https://chatting.page/rwhlsppllnpriozhtsqysjubupzuunlk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text blue"
                    >
                      {" "}
                      {t("resellerCoaching.faq.4.responses.3-1")}{" "}
                    </a>
                  </p>
                </p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.4")} key="4">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.4.responses.4")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.5")} key="5">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.4.responses.5")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.6")} key="6">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.4.responses.6")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.4.questions.7")} key="7">
                <p className="text-roboto montserrat">
                  <p className="text-roboto montserrat">
                    {t("resellerCoaching.faq.4.responses.7")}
                    <a
                      href="https://chatting.page/rwhlsppllnpriozhtsqysjubupzuunlk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text blue"
                    >
                      {" "}
                      {t("resellerCoaching.faq.4.responses.7-1")}{" "}
                    </a>
                    {t("resellerCoaching.faq.4.responses.7-2")}
                  </p>
                </p>
              </Panel>
            </Collapse>
          </div>
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.3.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.3")} key="3">
                <p className="text-roboto montserrat">
                  <p className="text-roboto montserrat">
                    {t("resellerCoaching.faq.3.responses.3")}
                    <a
                      href="https://chatting.page/rwhlsppllnpriozhtsqysjubupzuunlk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text blue"
                    >
                      {" "}
                      {t("resellerCoaching.faq.3.responses.3-1")}{" "}
                    </a>
                    {t("resellerCoaching.faq.3.responses.3-2")}
                  </p>
                </p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.4")} key="4">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.8")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.3.questions.5")} key="5">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.3.responses.7")}</p>
              </Panel>
            </Collapse>
          </div>
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.5.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.5.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.5.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.5.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.5.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.5.questions.3")} key="3">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.5.responses.3")}</p>
              </Panel>
            </Collapse>
          </div>
          <div style={{ margin: "30px", width: "330px" }} className="column">
            <p
              style={{ width: "280px", marginRight: "50px", marginBottom: "15px" }}
              className="title start montserrat-800"
            >
              {t("resellerCoaching.faq.6.title")}
            </p>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse faq"
              defaultActiveKey={[]}
            >
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.6.questions.1")} key="1">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.6.responses.1")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.6.questions.2")} key="2">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.6.responses.2")}</p>
              </Panel>
              <Panel className="montserrat-bold" header={t("resellerCoaching.faq.6.questions.3")} key="3">
                <p className="text-roboto montserrat">{t("resellerCoaching.faq.6.responses.3")}</p>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
      <div className="column width-100 academy" style={{ alignItems: "center", margin: "50px 0" }}>
        <img alt="01supply academy" src={supplyImg} style={{ width: "250px" }} />
        <img style={{ marginTop: "30px", marginBottom: "10px" }} src={starsImg} />
        <p style={{ maxWidth: "700px" }} className="text-bold width-100 center">
          <span className="text-400">{t("resellerCoaching.45")}</span>
        </p>
      </div>

      <div className="back-6">
        <img src={arrow1Img} className="arrow-1-2" />
        <Link
          style={{ width: "230px", marginTop: "45px" }}
          to="ecommerce-coaching-payment?coaching=pro"
          className="button big orange-back"
        >
          {t("resellerCoaching.subscribe")}
        </Link>
        <p style={{ marginTop: "15px", maxWidth: '90vw' }} className="text-300 grey">
          <br />
          {t("resellerCoaching.101")}
        </p>
        <img src={arrow2Img} className="arrow-2-2" />
      </div>
      <Footer type="reseller" windowSize={windowSize} />
    </div>
  );
};

export default Coaching1;
