import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CheckoutForm from './CheckoutForm'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const ElementStripe = ({data, setGoPayment, stripeAccountId, setPassOrder, setOrders, orders, order, sellerCost, user,
    variantSelect,
    shippingSelect,
    totalPrice,
    windowSize,
    imageSelect,
    messageSend,
    load,
    setLoad
}) => {

    let stripePromise = ""
    if(stripeAccountId){
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
            stripeAccount: stripeAccountId
          });
    } else {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    }

    return (
        stripePromise ?
                <Elements stripe={stripePromise}>
                    <CheckoutForm load={load} setLoad={setLoad} user={user} sellerCost={sellerCost} setPassOrder={setPassOrder} setOrders={setOrders} orders={orders} order={order} setGoPayment={setGoPayment} data={data} stripeAccountId={stripeAccountId}
                    variantSelect={variantSelect}
                    shippingSelect={shippingSelect}
                    totalPrice={totalPrice}
                    windowSize={windowSize}
                    imageSelect={imageSelect}
                    messageSend={messageSend}
                    />
                </Elements>
        : <></>
    );
};

export default ElementStripe