import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import successImg from "../../images/illustrations/success.png";

const CoursePaid = () => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: 0, width: "100%" }} className="column width-100 center">
      <p className="title-bold">{t("paymentPage.isPaid.1")}</p>
      <img style={{ width: "80px", marginTop: "20px", marginBottom: "40px" }} src={successImg} />
      {localStorage.getItem("tokenCreatosell") ? (
        <>
          <p>{t("paymentPage.isPaid.2")}</p>
          <Link to="/academy/dashboard" style={{ marginTop: "15px" }} className="button orange-back">
            {t("paymentPage.isPaid.3")}
          </Link>
        </>
      ) : (
        <p>{t("paymentPage.isPaid.4")}</p>
      )}
    </div>
  );
};

export default CoursePaid;
