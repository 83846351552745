import React, { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next'
import { Input, message } from "antd";
import { SendOutlined } from "@ant-design/icons";

import { url } from "../../api/url";
import { header } from "../../api/header";

const NewMessage = ({ user, contact, messages, setMessages, setNoMessages, sendSocketMessage, windowSize }) => {
  const [newMessage, setNewMessage] = useState("");
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [lastSendDate, setLastSendingDate] = useState("")

  const { t } = useTranslation()

  const sendMessage = async () => {
    const currentDate = new Date()
    const differenceOfSecondsWithLastSend = !lastSendDate ? 5 : (currentDate.getTime() - lastSendDate.getTime()) / 1000;
    if(differenceOfSecondsWithLastSend > 2){
      if (newMessage) {
        const createrId = user.type === "creater" ? user._id : contact._id;
        const sellerId = user.type === "seller" ? user._id : contact._id;
        const res = await fetch(`${url}/channel/${user._id}/${user.secret_id}`, {
          method: "POST",
          credentials: "include",
          headers: {
            ...header,
            authorization: token,
          },
          body: JSON.stringify({
            text: newMessage,
            sellerId,
            createrId,
          }),
        });
        if (res.status === 200) {
          setNoMessages(false);
          sendSocketMessage({
            text: newMessage,
            date: new Date().toISOString(),
            sender_id: user._id,
          });
          setMessages([
            ...messages,
            {
              text: newMessage,
              date: new Date().toISOString(),
              sender_id: user._id,
            },
          ]);
          setNewMessage("");
          setLastSendingDate(new Date())
        } else {
          message.error(t('messages.error'));
        }
      }
    } else {
      message.warning(t('messages.wait'))
    }
  };

  useEffect(() => {}, []);

  return (
    <Input
      style={{ marginLeft: windowSize > 1150 && "30px", width: windowSize > 1150 ? "calc(100% - 300px)" : "90vw", height: "40px" }}
      onPressEnter={sendMessage}
      placeholder={t('components.messages.8')}
      onChange={(e) => setNewMessage(e.target.value)}
      value={newMessage}
      suffix={<SendOutlined style={{ color: "#ffc38b", fontSize: "22px" }} onClick={sendMessage} />}
    />
  );
};

export default NewMessage;
