import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Switch } from "antd";

const SearchUser = ({ datas, setDatas }) => {
  const [stockDatas] = useState(datas);

  useEffect(() => {
    if(stockDatas && stockDatas.length)
    filterUserType("creater")
  }, [stockDatas])

  const filterF = (e) => {
    let word = e.target.value;

    if (word.split("").length > 2) {
      let results = stockDatas.filter(function (data) {
        let res = false;
        let name = data.user.email.toLowerCase();
        if (name.indexOf(word.toLowerCase()) >= 0) res = true;
        return res;
      });

      if (results.length > 0) {
        setDatas([...results]);
      }
    } else if (!word) {
      let results = stockDatas;
      setDatas([...results]);
    }
  };

  const filterUserType = (type) => {
    setDatas(stockDatas.filter((data) => data.user?.type === type));
  };

  return (
    <div className="column">
      <Input
        maxLength={100}
        style={{ width: "30%", width: "300px", marginBottom: "20px", marginTop: "30px" }}
        className="input"
        placeholder={"Search user with email"}
        suffix={<SearchOutlined style={{ color: "rgb(129, 129, 129)" }} />}
        onChange={(e) => {
          filterF(e);
        }}
        autoFocus
      />
      <div className="row">
        <h4 className="title" style={{ marginBottom: "0" }}>
          Suppliers list
        </h4>
        <Switch
          className="pricing-switch"
          style={{ marginLeft: "15px", marginRight: "15px" }}
          onChange={(checked) => {
            if (checked) filterUserType("seller");
            else filterUserType("creater");
          }}
        />
        <h4 className="title" style={{ marginBottom: "0" }}>
          Reseller List
        </h4>
      </div>
      <p style={{marginBottom: '40px'}} className="text">
        {`(Nb user : ${datas.length})`}
      </p>
    </div>
  );
};

export default SearchUser;
