import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { message, Input, Radio, Space, Form } from "antd";

import {Modal} from '../../wrappers'
import { urlParams } from "../../utils/urlParams";
import { userServices } from "../../services/user";
import { auth } from "../../hooks/auth";

const CreatePasswordNewAccount = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [redirect, setRedirect] = useState("");
  const [accountType, setAccountType] = useState("");

  const { email, createAccountCode, redirectUrl } = urlParams();

  const { t } = useTranslation();

  const handleValid = () => {
    if (!newPassword || newPassword !== confirmNewPassword) {
      message.error(t('messages.badRepeatPassword'));
    } else if (newPassword.length < 8) {
      message.error(t('messages.passwordLength'));
    } else {
      userServices
        .resetPassword({
          email,
          resetCode: createAccountCode,
          passwordReset: newPassword,
          accountType,
        })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            message.error(t('messages.error'));
          }
        })
        .then((res) => {
          if (res) {
            userServices.login({ email, password: newPassword }).then(async (reslogin) => {
              if (reslogin.status === 200) {
                const user = await reslogin.json();
                if (user) {
                  auth.saveToken(user);
                  setRedirect("/academy/dashboard");
                }
              } else {
                message.error(t('messages.error'));
              }
            });
          }
        });
    }
  };

  return (
    <Modal visible={email && createAccountCode} footer={[]} maskClosable={true}>
      {redirect && <Redirect to={redirectUrl || redirect} />}
      <div style={{ alignItems: "center" }} className="column width-100">
        <p className="title">{t("components.joinUs.1")}</p>
        <p style={{ marginTop: "15px", marginBottom: "5px" }}>{email}</p>
        <Form
          name="create account"
          initialValues={{ remember: true }}
          onFinish={handleValid}
          autoComplete="off"
          className="column width-100"
          style={{ alignItems: "center", marginTop: "30px" }}
        >
          <Form.Item
            name="password"
            rules={[{ required: true, message: t("pleaseCompleteField") }]}
            style={{ marginTop: "0px", marginBottom: "15px" }}
            onChange={(e) => setNewPassword(e.target.value)}
          >
            <Input.Password style={{ width: "240px" }} placeholder={t("components.joinUs.2")} />
          </Form.Item>
          <Form.Item
            name="repeat password"
            rules={[{ required: true, message: t("pleaseCompleteField") }]}
            style={{ marginTop: "0px", marginBottom: "15px" }}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          >
            <Input.Password style={{ width: "240px" }} placeholder={t("components.joinUs.3")} />
          </Form.Item>
          <Form.Item
            name="Account type"
            rules={[{ required: true, message: t("components.joinUs.4") }]}
            style={{ marginTop: "0px", width: "240px" }}
            onChange={(e) => {
              setAccountType(e.target.value);
            }}
          >
            <Radio.Group style={{ alignItems: "flex-start" }} value={accountType}>
              <Space direction="vertical">
                <Radio value={"seller"}>{t("components.joinUs.5")}</Radio>
                <Radio value={"creater"}>{t("components.joinUs.6")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item style={{ marginTop: "20px" }}>
            <button type="primary" htmlType="submit" className="button orange-back">
              {t("components.joinUs.7")}
            </button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CreatePasswordNewAccount;
