import React, { useState } from "react";
import { Form, message, Input } from "antd";
import { useTranslation } from "react-i18next";

import { Modal } from "../../wrappers";
import { wizishopServices } from "../../services/wizishop";
import Load from "../../utils/Load";

import wizishopIcon from "../../images/illustrations/wizishop.svg";
import dropiziIcon from "../../images/illustrations/dropizi.svg";

const ReconnectWizishop = ({ setModal, modalOn, user }) => {
  const [load, setLoad] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
    cms: user?.wizishop?.cms,
  });

  const { t } = useTranslation();

  const isDropizi = user?.wizishop?.cms === "dropizi";
  const cms = user?.wizishop?.cms;

  const handleReconnect = () => {
    setLoad(true);
    wizishopServices.connect(login).then((res) => {
      setLoad(false);
      if (res.status === 200) {
        message.success(t("messages.shopConnected"));
        setModal(false);
      } else {
        message.error(t("messages.badCmsLogs", { cms: user?.wizishop?.cms }));
      }
    });
  };

  return (
    <Modal closable={false} className="modal-small" visible={true} footer={[]}>
      {load && <Load />}
      <div className="width-100 column" style={{ alignItems: "center" }}>
        <img
          src={isDropizi ? dropiziIcon : wizishopIcon}
          alt="wizishop dropizi icon"
          style={{ width: "220px", marginBottom: "30px" }}
        />
        <p className="title-bold center">{t("reconnectToCms", { cms: user.wizishop?.cms })}</p>
        <Form
          onFinish={handleReconnect}
          className="column width-100"
          style={{ alignItems: "center", marginTop: "30px" }}
          name="store completion"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            name="url"
            className="width-100"
            rules={[{ required: true, message: t("pleaseCompleteField") }]}
            style={{ marginTop: "0px", marginBottom: "10px" }}
            onChange={(e) => setLogin({ ...login, email: e.target.value })}
          >
            <Input className="width-100" placeholder={t("components.joinUs.9", { cms })} />
          </Form.Item>
          <Form.Item
            name={`${cms} password`}
            className="width-100"
            autoComplete="new-state"
            rules={[{ required: true, message: t("pleaseCompleteField") }]}
            style={{ marginTop: "0px", marginBottom: "15px" }}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
          >
            <Input.Password
              autoComplete="off"
              className="width-100"
              placeholder={t("components.joinUs.10", { cms })}
            />
          </Form.Item>

          <Form.Item className="width-100">
            <button type="primary" htmlType="submit" className="button big orange-back width-100">
              {t("validate")}
            </button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ReconnectWizishop;
