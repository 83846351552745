import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Skeleton, message } from "antd";

import { url } from "../../../api/url";
import { header } from "../../../api/header";
import { getImage } from "../../../utils/GetImage";
import PopupLogin from "../../modals/RegisterModal";
import { getCountry } from "../../../utils/getCountry";
import { klaviyoServices } from "../../../services/klaviyo";
import { priceConverter } from "../../../utils/priceConverter";

import { Modal } from "../../../wrappers";

import { LocalStatesContext } from "../../../providers/index";

import ProductDetail from "../../../pages/ProductDetail";

import orangeCross from "../../../images/icons/orange_cross.svg";

const buttonStyle = {
  width: "100%",
  marginTop: "0px",
  marginBottom: "8px",
  minHeight: "35px",
  borderRadius: "5px",
  paddingTop: "7px",
  paddingBottom: "7px",
};

const ProductCard = ({
  defaultProduct,
  user,
  setUser,
  getProduct,
  index,
  setSelectedSearchTerm,
  inputSearch,
  selectedSearchTerm,
}) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [popupLogin, setPopupLogin] = useState("");
  const [image, setImage] = useState("");
  const [originFlag, setOriginFlag] = useState("");
  const [isAddToImportList, setIsAddToImportList] = useState(false);
  const [isNoImages, setIsNoImage] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [isReplace, setIsReplace] = useState(false);
  const [product, setProduct] = useState({});
  const [lastInputSearch, setLastInputSearch] = useState("");
  const [isImportAnimation, setIsImportAnimation] = useState(false);
  const [isDeletedAnimationDisplay, setIsDeletedAnimationDisplay] = useState(false);
  const [isDisplayAnimationDisplay, setIsDisplayAnimationDisplay] = useState(false);
  const [isProductDetailDisplayed, setIsProductDetailDisplayed] = useState(false);

  const { localStates } = useContext(LocalStatesContext);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (!product?._id || lastInputSearch !== inputSearch) {
      setLastInputSearch(inputSearch);
      setProduct(defaultProduct);
    }
  }, [defaultProduct, inputSearch]);

  const { t } = useTranslation();

  const isImportButtonDisplayed =
    localStorage.getItem("typeAccountCreatosell") !== "creater" &&
    !window.location.href.includes("import-list");

  useEffect(() => {
    if (product && product.photos && product.photos.length > 0) {
      setOriginFlag(getCountry(product?.origin)?.split(" ")[0]);

      getImage(product.photos[0]).then((img) => {
        setIsReplace(false);
        if (img) {
          setImage(img);
        } else {
          setIsNoImage(true);
        }
      });
    }
  }, [product]);

  const autoSelectTerm = ({ user }) => {
    if (!selectedSearchTerm) {
      const newSelectedTerm = user?.suggestions?.searchTerms?.find(
        (searchTerm) => searchTerm.searchTerm === inputSearch
      );
      if (newSelectedTerm) {
        setSelectedSearchTerm(newSelectedTerm);
      }
    }
  };

  const addProductToImportList = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    const userRes = await resJson.data;
    if (userRes) {
      const newList = [...user.import_list, product._id];
      if (userRes && userRes.import_list) {
        userRes.import_list.forEach((id) => !newList.includes(id) && newList.push(id));
      }
      let newUser = { ...userRes, import_list: newList };
      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
          importList: product._id,
          searchTerm: inputSearch || "",
        }),
      });
      if (res.status === 200) {
        // Algolia analytic
        window.aa("convertedObjectIDs", {
          userToken: user?._id || "",
          index: "products",
          eventName: "product_import",
          objectIDs: [product._id],
          queryID: localStorage.getItem("algolia_last_query_id") || "",
        });

        if (user.products_id?.length + user.import_list?.length < 9) {
          klaviyoServices.checkStatus();
        }

        const resJson = await res.json();
        if (setUser && resJson) {
          setUser(resJson.user);
          autoSelectTerm({ user: resJson.user });
        }

        message.success({
          content: t("messages.productSuccessfullyImported"),
          duration: 5,
          onClick: () => setRedirect("/import-list"),
        });
      } else if (res.status === 405) {
        message.error(t("messages.alreadyInImportList"));
      } else if (res.status === 406) {
        message.error(t("messages.alreadyInInventory"));
      } else message.error(t("messages.error"));
    }
  };

  const deleteAnimation = () => {
    setIsDeletedAnimationDisplay(true);

    setTimeout(() => {
      setIsDeletedAnimationDisplay(false);
      setIsDisplayAnimationDisplay(true);
    }, 300);

    setTimeout(() => {
      setIsDisplayAnimationDisplay(false);
    }, 700);
  };

  const handleImportClick = async (e) => {
    deleteAnimation();
    e.stopPropagation();
    e.preventDefault();
    setIsReplace(true);
    if (token) {
      const newProduct = await getProduct({ changeProductIndex: index });
      if (newProduct) {
        setProduct(newProduct);
      }
      addProductToImportList();
    } else {
      setPopupLogin("reload");
    }
  };

  const handleClickRemove = async (e) => {
    deleteAnimation();
    e.stopPropagation();
    e.preventDefault();
    setIsReplace(true);
    const newProduct = await getProduct({ changeProductIndex: index, bannedProduct: product });
    if (newProduct) {
      setProduct(newProduct);
    }
  };

  return (
    <>
      {isProductDetailDisplayed && (
        <Modal
          title={
            <Link
              style={{ fontWeight: "400", display: "block" }}
              target="_blank"
              to={`/product-details?name=${product.name}&id=${product._id}`}
              className="text blue underline pointer width-100 center see-product-page-link"
            >
              {t("seeProductPage")}
            </Link>
          }
          footer={[
            <Link
              style={{ fontWeight: "400", width: "100%", fontSize: "16px", display: "block" }}
              target="_blank"
              to={`/product-details?name=${product.name}&id=${product._id}`}
              className="text blue underline pointer center see-product-page-link"
            >
              {t("seeProductPage")}
            </Link>,
          ]}
          className="modal-80-vw"
          onOk={() => {}}
          visible={true}
          onCancel={() => setIsProductDetailDisplayed(false)}
        >
          <ProductDetail userP={user} setUserP={setUser} isModal={true} productProps={product} />
        </Modal>
      )}
      {popupLogin && <PopupLogin modalOn={popupLogin} setModal={setPopupLogin} />}
      {redirect && <Redirect to={redirect} />}
      {isImportAnimation && <div className="import-success-animation" />}
      {product?._id && (
        <div
          onClick={() => setIsProductDetailDisplayed(true)}
          key={product._id}
          className={`container-card-product link-product-card product-suggestion-card`}
          id={
            isDeletedAnimationDisplay
              ? "deleted-suggestion-product-card"
              : isDisplayAnimationDisplay && "display-suggestion-product-card"
          }
          // style={{ display: displayedProductsIndex.includes(index) ? "flex" : "none" }}
        >
          {isAddToImportList && (
            <Link to="/import-list" className="view-import-list">
              <p className="text-bold blue underline">{t("components.productCard.1")}</p>
            </Link>
          )}

          <div style={{ minHeight: "270px", maxHeight: "270px" }} className="container-image-card">
            {image && !isReplace ? (
              <img src={image} alt={product.name} height={"100%"} className="image-product" />
            ) : (
              <Skeleton className="image-product" loading={true} active />
            )}
          </div>

          <div className="bottom-card-product">
            {isImportButtonDisplayed && (
              <div className="row width-100">
                <button onClick={handleClickRemove} id="cancel-product-card" style={buttonStyle}>
                  <img alt="cross" src={orangeCross} style={{ height: "20px" }} />
                </button>
                <button
                  className={
                    user &&
                    (user.import_list?.includes(product._id) || user.products_id?.includes(product._id))
                      ? "button-disable-orange"
                      : "button orange-back"
                  }
                  id="import-product-button-little"
                  style={buttonStyle}
                  onClick={handleImportClick}
                >
                  {t("import").toUpperCase()}
                </button>
              </div>
            )}

            <p
              style={{
                fontSize: "13px",
                height: "38px",
                marginTop: "10px",
                lineHeight: "18px",
                width: "95%",
                textAlign: "start",
              }}
              className="text-ellipsis-2 text montserrat"
            >
              {product.name}
            </p>
            <div
              style={{
                width: "100%",
                alignItems: "flex-end",
                fontSize: "15px",
                color: "#3f3f3f",
              }}
              className="row-wrap space-between"
            >
              <p
                style={{ color: "#3f3f3f", lineHeight: "22px" }}
                className="start text-bold text-ellipsis montserrat product-price"
              >
                <span style={{ fontSize: "12px", fontWeight: "400", color: "#9a9a9a" }}>
                  {t("productPrice")}
                </span>{" "}
                <br /> {priceConverter({ price: product.price, currency: localStates.currency })}
              </p>
              {product.retailPrice && user?.retailPrice?.anable !== false && (
                <p
                  style={{ color: "rgb(90 90 90)", lineHeight: "22px", fontSize: "14px" }}
                  className="text text-ellipsis montserrat end recommended-retail"
                >
                  <span style={{ fontSize: "12px", color: "#9a9a9a" }}>{t("retailPrice")}</span> <br />
                  {priceConverter({
                    price: product.retailPrice,
                    currency: localStates.currency,
                    type: "retailPrice",
                    originalPrice: product.price,
                    user,
                  })}
                </p>
              )}
            </div>

            {product.creater?.name ? (
              <div
                style={{
                  width: "90%",
                  fontSize: "14px",
                  marginTop: "3px",
                  alignItems: "center",
                  display: "flex",
                }}
                className="text-300 text-ellipsis row"
              >
                {t("from")}
                {originFlag && <div style={{ marginLeft: "5px", marginTop: "5px" }}>{originFlag}</div>}
                <Link
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  to={`/shop/${product.creater.name}`}
                  style={{ marginLeft: "5px", textDecoration: "underline" }}
                >
                  {product.creater.name}
                </Link>
              </div>
            ) : (
              <div
                style={{
                  width: "90%",
                  fontSize: "14px",
                  marginTop: "5px",
                  alignItems: "center",
                  display: "flex",
                }}
                className="text-300 text-ellipsis row"
              ></div>
            )}
          </div>

          <Link
            style={{ marginTop: "3px", marginBottom: "5px" }}
            to={`/product-details?name=${product.name}&id=${product._id}`}
            className="text blue width-100 center"
          >
            {t("components.productCard.3").toUpperCase()}
          </Link>
        </div>
      )}
    </>
  );
};

export default ProductCard;
