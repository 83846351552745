import React from "react";
import { useTranslation } from "react-i18next";

const Row = ({ payment }) => {
  const { t } = useTranslation();
  return payment ? (
    <div className="row-tab">
      <p className="tab-1 text-ellipsis">{payment.date}</p>
      <p className="tab-2 text-ellipsis">{payment.method}</p>
      <p className="tab-3 text-ellipsis">{payment.details}</p>
      <p className="tab-4 text-ellipsis">{payment.label}</p>
      <p className="tab-5 text-ellipsis">{payment.amount}</p>
      <p className="tab-6 text-ellipsis">{payment.fees_paypal}</p>
      <p className="tab-7 text-ellipsis">{payment.fees_01supply}</p>
      <p className="tab-8 text-ellipsis">
        <div className="green-box">{t("paid").toUpperCase()}</div>
      </p>
    </div>
  ) : (
    <></>
  );
};

export default Row;
