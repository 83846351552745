import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routes } from "../../routes";

import winningIcon from "../../images/icons/marketplaceHome/icon1.svg";
import highMarginIcon from "../../images/icons/marketplaceHome/icon2.svg";
import babyIcon from "../../images/icons/marketplaceHome/icon3.svg";
import jewelIcon from "../../images/icons/marketplaceHome/icon4.svg";
import ecoIcon from "../../images/icons/marketplaceHome/icon5.svg";
import petIcon from "../../images/icons/marketplaceHome/icon6.svg";
import homeIcon from "../../images/icons/marketplaceHome/icon7.svg";
import clotheIcon from "../../images/icons/marketplaceHome/icon8.svg";
import weddingIcon from "../../images/icons/marketplaceHome/icon9.svg";
import cookwareIcon from "../../images/icons/marketplaceHome/icon10.svg";
import europeIcon from "../../images/icons/marketplaceHome/icon11.svg";
import ukIcon from "../../images/icons/marketplaceHome/icon12.svg";
import usaIcon from "../../images/icons/marketplaceHome/icon13.svg";

const HighMarginProducts = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h1
        className="title-bold width-100 center"
        style={{ fontSize: "23px", marginBottom: "15px", marginTop: "40px", width: "90%", marginLeft: "5%" }}
      >
        {t("marketplaceHome.categories.1")}
      </h1>
      <div className="card width-100 row center">
        <div className="row-wrap width-100 row center">
          <Link to={routes.marketplaceWinningProducts(i18n.language).path} className="category">
            <img src={winningIcon} alt="winning products" />
            {t("marketplaceHome.categories.2")}
          </Link>
          <Link to={routes.marketplaceHighMargin(i18n.language).path} className="category">
            <img src={highMarginIcon} alt="high margin products" />
            {t("marketplaceHome.categories.3")}
          </Link>
          <Link to="/marketplace?category=Clothes_BABIES" className="category">
            <img src={babyIcon} alt="baby clothing" />
            {t("marketplaceHome.categories.4")}
          </Link>
          <Link to="/marketplace?page=1&category=Jewels" className="category">
            <img src={jewelIcon} alt="Jewels & Accessories" />
            {t("marketplaceHome.categories.5")}
          </Link>

          <Link to="/marketplace?page=1&search=eco-friendly" className="category">
            <img src={ecoIcon} alt="sunstaible" />
            {t("marketplaceHome.categories.6")}
          </Link>
          <Link to="/marketplace?page=1&category=Pet" className="category">
            <img src={petIcon} alt="pet" />
            {t("marketplaceHome.categories.7")}
          </Link>
          <Link to="/marketplace?page=1&category=House" className="category">
            <img src={homeIcon} alt="home decoration" />
            {t("marketplaceHome.categories.8")}
          </Link>
          <Link to="/marketplace?page=1&category=Clothes" className="category">
            <img src={clotheIcon} alt="Clothes" />
            {t("marketplaceHome.categories.9")}
          </Link>

          <Link to="/marketplace?page=1&search=wedding" className="category">
            <img src={weddingIcon} alt="wedding" />
            {t("marketplaceHome.categories.10")}
          </Link>

          <Link to="/marketplace?page=1&category=House_KITCHEN_Cookware" className="category">
            <img src={cookwareIcon} alt="cookware" />
            {t("marketplaceHome.categories.11")}
          </Link>
        </div>
        <div className="category-divider" />
        <div className="row-wrap width-100 row center">
          <Link to="/marketplace?page=1&regionOrigin=0-2" className="category">
            <img src={europeIcon} alt="Europe" />
            {t("marketplaceHome.categories.12")}
          </Link>
          <Link to="/marketplace?page=1&origin=🇬🇧%20United%20Kingdom" className="category">
            <img src={ukIcon} alt="UK" />
            {t("marketplaceHome.categories.13")}
          </Link>
          <Link to="/marketplace?page=1&origin=🇺🇸%20United%20States" className="category">
            <img src={usaIcon} alt="USA" />
            {t("marketplaceHome.categories.14")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default HighMarginProducts;
