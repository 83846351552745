import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Progress } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import refreshIcon from "../../images/icons/refresh_purple.svg";

import "./style.scss";

const ImportedProcessHeader = ({ inProgressRequestCount, getUser }) => {
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isRefreshLoading && !(inProgressRequestCount > 0)) {
      message.success(t("cardImportedProcess.successImported"));
    }
    setIsRefreshLoading(false);
  }, [inProgressRequestCount]);

  const handleRefresh = () => {
    setIsRefreshLoading(true);
    getUser();
    setTimeout(() => {
      setIsRefreshLoading(false);
    }, 5000);
  };

  if (inProgressRequestCount > 0) {
    return (
      <div className="card-imported-process">
        <div className="row width-100 space-between">
          <p style={{ fontSize: "16px" }} className="text">
            <b>{inProgressRequestCount}</b> {t("cardImportedProcess.1")}
          </p>
          {isRefreshLoading ? (
            <button className="refresh-button">
              <SyncOutlined style={{ color: "#a97af5", fontSize: "20px" }} spin />
            </button>
          ) : (
            <button onClick={handleRefresh} className="refresh-button">
              {t("refresh")}
              <img src={refreshIcon} alt="refresh" style={{ marginLeft: "7px", height: "16px" }} />
            </button>
          )}
        </div>
        <p style={{ marginBottom: "5px", marginTop: "-5px", fontSize: "14px" }} className="text-300 grey">
          {t("cardImportedProcess.2")}
        </p>
        <Progress percent={90} status="active" showInfo={false} />
      </div>
    );
  }

  return <></>;
};

export default ImportedProcessHeader;
