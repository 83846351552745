import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article21/pourquoi-faire-du-seo.jpg";
import seoDefinition from "../../../../images/blog/global/article21/seo-definition.jpg";
import premierGoogle from "../../../../images/blog/global/article21/comment-arriver-1er-sur-google.jpg";
import importanceSEO from "../../../../images/blog/global/article21/importance-seo.jpg";
import pasRecherches from "../../../../images/blog/global/article21/pas-assez-de-recherches.jpg";
import positionZero from "../../../../images/blog/global/article21/position-0.jpg";
import seoEntreprise from "../../../../images/blog/global/article21/seo-important-entreprise.jpg";
import competitionSEO from "../../../../images/blog/global/article21/trop-de-compétition.jpg";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Pourquoi faire du SEO sur votre site internet?
      </h1>
      <p className="text-300">21 octobre 2022 - 10 minutes de lecture</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Pourquoi faire du SEO?" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Vous vous demandez peut-être <b>comment améliorer la notoriété et la visibilité de votre site web</b>?
        Peut-être avez-vous déjà entendu parler du SEO mais vous ne savez pas ce que c’est concrètement.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Dans cet article, nous allons analyser point par point ce qu’est le SEO, pourquoi vous devriez l’utiliser
        dans votre stratégie d’acquisition et comment avoir un référencement qui fonctionne du tonnerre.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#seo-definition" title="Que veut dire SEO?" >
            <Anchor.Link href="#difference-seo-sea" title="La différence entre le SEO et le SEA" />
            </Anchor.Link>
            <Anchor.Link href="#importance-seo" title="Pourquoi le SEO est-il important?" >
            <Anchor.Link href="#besoin-seo" title="Qui a besoin de SEO?" />
            <Anchor.Link href="#seo-entreprise" title="Pourquoi le SEO est-il important pour les entreprises?" />
            </Anchor.Link>
            <Anchor.Link href="#avantages-seo" title="Les avantages du SEO"/>
              <Anchor.Link href="#commencer-seo-aujourdhui" title="Pourquoi se lancer dans le SEO dès aujourd’hui?" />
              <Anchor.Link href="#bon-referencement-seo" title="C’est quoi un bon référencement SEO?" />
              <Anchor.Link href="#comment-arriver-premier-google" title="Comment apparaitre en premier sur Google?" />
              <Anchor.Link href="#evolution-seo" title="L'évolution du SEO dans le temps" >
              <Anchor.Link href="#futur-de-google" title="Le futur de Google et du SEO" />
            </Anchor.Link>
            <Anchor.Link href="#formation-seo" title="Comment se former au SEO?" />
          </Anchor>
        }
      />
      <h2 id="seo-definition">Que veut dire SEO?</h2>
      <img src={seoDefinition} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Définition du SEO" />
      <p style={{ marginBottom: "20px" }}>
        SEO veut dire “Search Engine Optimization”, en français “<b>Optimisation pour les moteurs de recherche</b>”.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Par exemple, sur Google, le fait d’arriver premier dans les résultats lorsqu’on tape une requête vient souvent d’un bon SEO.
        Les sites se trouvant en 20-30e position dans les résultats de recherche ont souvent négligé leur SEO.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Comme nous allons le voir dans cet article, cette notion est à prendre avec des pincettes car
        le SEO est un domaine assez large avec de <b>très nombreux critères et facteurs de référencement</b>.
      </p>
      <h3 id="difference-seo-sea">La différence entre le SEO et le SEA</h3>
      <p style={{ marginBottom: "20px" }}>
        <b>Le SEO est le référencement organique</b>, celui qui se fait naturellement via la création de contenu et l’indexation de vos pages sur Google.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Le SEA</b> (Search Engine Advertising) <b>est la publicité</b> qui vous permet d’afficher votre site dans des emplacements sur Google.
        Vous payez un certain montant à chaque fois que quelqu’un clique sur votre lien (en moyenne entre 1€ et 2.5€ par clic dépendamment de la compétition.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En somme, le SEO est du SEA gratuit. Notez que si vous arrêtez votre campagne de SEA, vous ne serez plus premier sur Google du jour au lendemain,
        contrairement au SEO où cela durera facilement plusieurs mois voire des années.
      </p>
      <h2 id="importance-seo">Pourquoi le SEO est-il important?</h2>
      <img src={importanceSEO} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Importance du SEO" />
      <p style={{ marginBottom: "20px" }}>
        Le SEO est avant tout important pour obtenir de l’<b>acquisition passive</b> de nouveaux visiteurs sur votre site web ou sur votre boutique en ligne.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En effet, grâce au référencement naturel, votre site aura de la <b>visibilité dans les résultats des moteurs de recherche</b> (Bing, Google, Yahoo,...) et vous atteindrez
        des milliers voire des millions de personnes <b>gratuitement</b>.
        Un bon SEO peut totalement remplacer une campagne de publicité Google et vous faire économiser des milliers d’euros.
      </p>
      <h3 id="besoin-seo">Qui a besoin de SEO?</h3>
      <p style={{ marginBottom: "20px" }}>
        <b>Tous les sites qui souhaitent s’établir dans la durée</b> doivent considérer le SEO. En effet, le SEO est parfaitement adapté à une stratégie long terme.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Au contraire, si vous avez un site éphémère, qui ne dure pas plus de 2 ou 3 mois, le SEO ne sera pas réellement pertinent.
        Généralement une bonne stratégie SEO s’inscrit sur plusieurs mois pour devenir redoutable.
      </p>
      <p style={{ marginBottom: "20px" }}>
        De même, si vous avez peu ou pas de budget, le SEO vous permet d’établir une base de clients et de visiteurs réguliers sans dépenser un seul euro.
        Notez qu’en contrepartie, le SEO demandera du travail, du temps, de la patience et de la régularité.
      </p>
      <h3 id="seo-entreprise">Pourquoi le SEO est-il important pour les entreprises?
      </h3>
      <img src={seoEntreprise} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Importance du SEO en entreprise" />
      <p style={{ marginBottom: "20px" }}>
        Le SEO est capital pour les entreprises afin d’avoir une bonne visibilité de leur site web pour leurs clients ou prospects.
        Si vous ne ressortez pas premier dans les résultats lorsque l’on cherche votre entreprise sur Google,
        c’est très problématique pour vous et <b>cela peut même entacher votre crédibilité</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        De même, une bonne stratégie SEO peut vous permettre de <b>ressortir en premier sur des mots clés recherchés par votre audience</b>.
        Par exemple, si vous êtes un concessionnaire automobile à Paris, ressortir premier sur le mot clé “voiture neuve à Paris”
        peut vous rapporter de très nombreux clients et littéralement faire le job d’un commercial.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Au delà de ça, le SEO est primordial pour les entreprises car cela permet de toujours <b>comprendre les problématiques et les demandes de votre marché</b>.
        En effet, le SEO requiert de toujours savoir ce que votre marché recherche sur Google.
        Ainsi, faire du SEO vous ouvre des possibilités de nouvelles offres et de compréhension du marché, de leurs problèmes et de leurs besoins.
      </p>
      <h2 id="avantages-seo">Les avantages du SEO</h2>
      <p style={{ marginBottom: "20px" }}>
        Le SEO possède de très nombreux avantages. C’est la <b>première source de trafic sur internet</b> et aussi la plus pérenne.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici une liste des meilleurs avantages du SEO</u>:
      </p>
      <ul>
        <li>
          <b>Long terme</b>: dure pendant des années
        </li>
        <li>
          <b>Passif</b>: une fois le référencement établi, les visiteurs viennent d’eux-mêmes
        </li>
        <li>
          <b>Puissant</b>: dépendant de la thématique de votre site, certaines requêtes reçoivent des centaines de milliers de recherches mensuelles
        </li>
        <li>
          <b>Bon taux de conversion</b>: le SEO vous permet de vous adresser à des utilisateurs
          qui ont tapé eux même leur recherche et donc, cela augmente votre taux de conversion
        </li>
        <li>
          <b>Renforce votre crédibilité</b>: si votre site ressort dans les premiers résultats, les utilisateurs auront plus tendance à vous faire confiance
        </li>
      </ul>
      <h2 id="commencer-seo-aujourdhui">Pourquoi se lancer dans le SEO dès aujourd’hui?</h2>
      <p style={{ marginBottom: "20px" }}>
        <b>Le SEO prend du temps</b> à se mettre en place. C’est pourquoi commencer dès aujourd'hui peut permettre à votre entreprise de récolter les résultats plus vite.
        C’est un peu comme planter un pommier.
        Si vous voulez récolter des pommes, il vaut mieux le planter au plus vite car il faudra lui donner du temps avant qu’il produise.
      </p>
      <p style={{ marginBottom: "20px" }}>
        De plus, dans le SEO, <b>un critère important est l’ancienneté du site</b>. Plus votre site est ancien, plus Google vous considère comme stable et digne de confiance.
        Cela aura pour impact un boost dans votre référencement Google.
      </p>
      <p style={{ marginBottom: "20px" }}>
        De même, un site qui s’y prend à l’avance a plus de chances de <b>trouver des mots clés inexploités</b> et d’en tirer profit avant que la compétition ne soit trop importante.
      </p>
      <h2 id="bon-referencement-seo">C’est quoi un bon référencement SEO?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Un bon référencement, c’est avant tout <b>un référencement qui rapporte des visites</b> sur votre site.
        Vous pouvez être premier pour des requêtes que personne ne cherche, c’est facile et rapide.
        Cependant, cela ne vous rapportera aucune visite.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Au contraire, une bonne stratégie SEO vous permettra d’<b>arriver premier sur des requêtes avec plusieurs milliers de recherches par mois</b>.
        Si vous cumulez de nombreuses pages référencement sur de nombreuses requêtes, alors vous pouvez aisément avoir un site avec des milliers d’utilisateurs réguliers tous les mois.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Notez que dépendamment de la thématique de votre site, il peut être plus ou moins compliqué d’obtenir des visiteurs.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici deux cas de figure dans lesquels vous pourriez vous trouver</u>:
      </p>
      <h3>Thématique trop nichée</h3>
      <img src={pasRecherches} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="SEO pas de recherches" />
      <p style={{ marginBottom: "20px" }}>
        Dans le cas où vous vous trouvez dans une thématique trop nichée, il peut être complexe d’obtenir des visiteurs.
        En effet, si personne n’a de questions, d'intérêt ou de recherche concernant votre thématique, <b>ils ne chercheront rien à votre sujet sur Google</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        C’est un peu comme un journal qui parle d’actualité. Le journal du village recevra évidemment moins de lectures que le journal national.
        Simplement car le marché ciblé n’est pas de la même taille.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour savoir si votre thématique est trop nichée ou pas, <b>faites une liste des mots clés dans votre thématique</b>.
        Ensuite, comptabilisez les recherches mensuelles Google pour chaque mot clé.
        Vous pouvez utiliser l’outil en ligne <a href="https://semrush.com" alt="Semrush" target="blank_" className="blue"><u>SEMrush</u></a> pour connaître le volume de recherche mensuelle d’un mot clé.
        Par exemple, le mot clé “Optimisation SEO” reçoit 1.500 recherches par mois en France.
        Ensuite, <b>additionnez les recherches mensuelles de chaque mot clé</b> et cela vous donnera une idée du volume que vous pourriez atteindre.
      </p>
      <h3>Thématique trop concurrentielle</h3>
      <img src={competitionSEO} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Trop de competition SEO" />
      <p style={{ marginBottom: "20px" }}>
        Dans le cas contraire où vous seriez sur une thématique trop concurrentielle, il peut être très compliqué pour vous de ressortir dans les résultats de recherche.
        Par exemple, le mot clé “musculation” reçoit à lui seul 54.000 recherches par mois.
        Cependant la <b>concurrence est très rude car il existe des milliers d’autres sites</b> dans cette thématique.
        Ces autres sites essaieront donc tous d’être premiers sur les mots clés qui apportent des visites et il faudra être le meilleur obligatoirement.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour un bon référencement SEO, il  s’agit donc de pouvoir juger et doser entre volume et difficulté. Préférez-vous 1/1000 d’un grand gâteau ou la moitié d’un petit gâteau?
      </p>
      <h2 id="comment-arriver-premier-google">Comment appraitre en premier sur Google?</h2>
      <img src={premierGoogle} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Comment arriver 1er sur Google?" />
      <p style={{ marginBottom: "20px" }}>
        Pour apparaître en premier sur Google, il y a de nombreuses manières de procéder. En effet, le SEO pur et dur n’est pas la seule manière de faire.
        Parfois, et même souvent, un site avec peu de SEO mais énormément de valeur et de pertinence s’en sortira mieux qu’un site sur-optimisé mais vide.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici quelques critères de base d’une optimisation SEO classique</u>:
      </p>
      <ul>
        <li>
          Un bon <b>meta title</b> et une bonne <b>meta description</b>.
        </li>
        <li>
          Des textes alternatifs (<b>alt-text</b>) sur les images et les liens.
        </li>
        <li>
          Le <b>maillage interne</b> et externe (<b>backlinks</b>).
        </li>
        <li>
          L’optimisation de votre affichage pour mobile et ordinateur fixe (<b>site responsive</b>).
        </li>
        <li>
          La <b>rapidité</b> et la fluidité de votre site internet.
        </li>
        <li>
          Le comportement et la <b>rétention des utilisateurs</b>.
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Par SEO, on entend généralement optimisation, code, meta descriptions, etc…
        Mais <b>le SEO, c’est avant tout répondre à la demande de l’utilisateur</b> et être pertinent quant à la requête tapée par l’utilisateur.
        Vous ne serez par exemple jamais premier pour la requête "canne à pêche” si vous avez un article sur comment pêcher. Pourquoi?
      </p>
      <p style={{ marginBottom: "20px" }}>
        Simplement parce qu’un utilisateur qui tape canne à pêche veut très probablement en acheter une.
        C’est pourquoi les premiers résultats montrés par Google seront toujours des sites e-commerce pour ce type de requête.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Nous en venons donc aux <b>types de requêtes</b> (aussi appellé “<b>intention de recherche</b>”).
      </p>
      <h3>Informationnel
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Une requête informationnelle est tapée dans l’intention d’<b>obtenir une information</b>.
        Les résultats les plus mis en avant pour ce type de recherche seront généralement des <b>articles de blog</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Par exemple, pour la requête “Quand a été fondé google”, c’est un article Wikipedia qui ressort.
      </p>
      <h3>Navigationnel</h3>
      <p style={{ marginBottom: "20px" }}>
        Une requête navigationnelle est tapée dans l’intention de <b>trouver un site en particulier</b>.
        Par exemple, la requête “Amazon” est une requête navigationnelle car vous voulez vous rendre sur le site d’Amazon.
        Inutile donc d’espérer arriver 1er sur ce genre de résultat car Google montrera toujours Amazon en premier.
      </p>
      <h3>Commercial
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Un mot clé commercial est un mot clé qui est tapé dans l’intention de <b>se renseigner en vue d’un achat</b>.
        Plus concrètement, la requête “Quel est le meilleur smartphone en 2022” est à intention commerciale.
        Google montrera donc en premier des articles de <b>comparatifs</b> et des sites d’analyse de produits.
      </p>
      <h3>Transactionnel</h3>
      <p style={{ marginBottom: "20px" }}>
        La requête transactionnelle est la préférée des sites e-commerce.
        C’est un mot clé qui sera dans l’intention d’<b>acheter un produit ou un service</b>.
        Par exemple, le mot clé “planche de skateboard rouge” est une requête transactionnelle.
        Les premiers résultats seront tous des sites e-commerce.
      </p>
      <h2 id="evolution-seo">L’évolution du SEO dans le temps
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Chaque année, <b>Google améliore son algorithme de référencement</b>, ajoute de nouveaux critères, fait des ajustements ,
        le tout dans l’optique de répondre au mieux aux demandes des internautes.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ainsi, on a vu <b>de nombreuses mises à jour au fil du temps</b>.
        Ainsi, en 2005, le SEO consistait à placer plein de mots clés par page et de faire des liens de partout.
        Aujourd’hui de telles pratiques vous pénaliseraient au contraire.
        Google s’adapte chaque année pour donner une direction vers une meilleure expérience utilisateur.
      </p>
      <h3 id="futur-de-google">Le futur de Google et du SEO
      </h3>
      <p style={{ marginBottom: "20px" }}>
      Le moteur de recherche Google évolue non pas comme un moteur de recherche, mais comme <b>un moteur de réponse</b>. 
      En effet, l’objectif ultime de Google est d’avoir la réponse à n’importe quelle demande de l’utilisateur. 
      </p>
      <p style={{ marginBottom: "20px" }}>
      <u>Vous avez certainement déjà vu ceci</u>: 
      </p>
      <img src={positionZero} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Position 0 Google" />
      <p style={{ marginBottom: "20px" }}>
      <b>C’est ce qu’on appelle la position 0</b>. Quand Google décide que votre page a une bonne réponse pour une requête en particulier, Google va directement l’intégrer dans ses résultats. 
      C’est un exemple concret de sa volonté de devenir un moteur de réponse. 
      </p>
      <p style={{ marginBottom: "20px" }}>
      Par exemple, si vous recherchez “Taille mont Everest”, Google vous donnera aussi directement la réponse. PS: c’est 8,849 m. 
      </p>
      <h2 id="formation-seo">Comment se former au SEO?</h2>
      <p style={{ marginBottom: "20px" }}>
      Le SEO comprend de très nombreux autres critères et spécificités. C’est à la fois un domaine complet et très intéressant. 
      Contrairement à la publicité où vous placez votre site sous le nez de l’utilisateur, le SEO permet de se concentrer sur les demandes et d’y répondre. 
      Cela met l’expérience et la qualité de navigation internet au centre de tout. 
      </p>
      <p style={{ marginBottom: "20px" }}>
      <b>Pour en devenir un expert du SEO, nous vous recommandons notre cours complet à ce 
        sujet: <a href="/academy/google-seo-course" alt="Formation au seo" target="blank_" className="blue"><u>SEO Mastery</u></a></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
      Réalisé par la 01supply Academy qui accompagne des milliers de créateurs et e-commerçants, 
      vous apprendrez tout sur les techniques les plus avancées pour afficher votre site en première position sur Google.
      </p>
    </div>
  );
};

export default Content;
