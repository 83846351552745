import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Radio, Space } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import Navbar from "../../../components/menu/navbar/NavBar";

import { updateUser } from "../../../services/updateUser";

import loadGif from "../../../images/load.gif";
import illustration1 from "../../../images/welcomeForm/illustration1.svg";
import illustration2 from "../../../images/welcomeForm/illustration2.svg";
import illustration3 from "../../../images/welcomeForm/illustration3.svg";

import "./style.scss";

const WelcomeGuide = () => {
  const [currentStep, setCurrentStep] = useState(window.location.pathname.includes("step-2") ? 2 : 1);
  const [redirect, setRedirect] = useState("");
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isLoad, setIsLoad] = useState(false);
  const [advancedResellerStage, setAdvancedResellerStage] = useState({
    storeCreated: undefined,
    alreadySale: undefined,
    haveProduct: undefined,
  });
  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const handleValidForm = () => {
    const callback = () => {
      if (
        advancedResellerStage.storeCreated &&
        advancedResellerStage.alreadySale &&
        advancedResellerStage.haveProduct
      ) {
        setRedirect("/reseller-onboarding-step-1");
      } else {
        setIsLoad(false);
        setCurrentStep(2);
        setRedirect("/welcome-on-01supply-step-2");
      }
    };
    setIsLoad(true);
    if (localStorage.getItem("tokenCreatosell")) {
      updateUser({ user: { advancedResellerStage } }).then((res) => {
        callback();
      });
    } else {
      callback();
    }
  };

  return (
    <div className="page welcome-page">
      {redirect && <Redirect to={redirect} />}
      <Navbar isFixed={true} windowSize={windowSize} />
      <div className="back-black">
        <h1
          style={{ fontSize: "30px", width: windowSize > 1150 ? "800px" : "", position: "relative" }}
          className="title-bold montserrat-800 white"
        >
          {t("welcomePage.title")}
          <Link
            onClick={(e) => {
              if (currentStep === 1) {
                e.preventDefault();
                e.stopPropagation();
                setCurrentStep(2);
              }
            }}
            to={currentStep === 2 && "/reseller-onboarding-step-1"}
            className="skip"
          >
            <p className="title blue">Skip</p>
            <ArrowRightOutlined style={{ marginLeft: "10px", fontSize: "25px" }} className="blue" />
          </Link>
        </h1>
        {isLoad ? (
          <div className="form">
            <img alt="loading" src={loadGif} style={{ width: "100%" }} />
          </div>
        ) : currentStep === 1 ? (
          <div className="form">
            <p style={{ color: "#909090" }} className="title-bold width-100 center">
              {t("welcomePage.card1.title")}
            </p>
            <p className="text black normal-text">{t("welcomePage.card1.1")}</p>
            <Radio.Group
              onChange={(e) =>
                setAdvancedResellerStage({ ...advancedResellerStage, storeCreated: e.target.value })
              }
              value={advancedResellerStage.storeCreated}
            >
              <Space direction="vertical">
                <Radio value={true}>{t("yes")}</Radio>
                <Radio value={false}>{t("no")}</Radio>
              </Space>
            </Radio.Group>
            <p className="text black normal-text">{t("welcomePage.card1.2")}</p>
            <Radio.Group
              onChange={(e) =>
                setAdvancedResellerStage({ ...advancedResellerStage, alreadySale: e.target.value })
              }
              value={advancedResellerStage.alreadySale}
            >
              <Space direction="vertical">
                <Radio value={true}>{t("yes")}</Radio>
                <Radio value={false}>{t("no")}</Radio>
              </Space>
            </Radio.Group>
            <p className="text black normal-text">{t("welcomePage.card1.3")}</p>
            <Radio.Group
              onChange={(e) =>
                setAdvancedResellerStage({ ...advancedResellerStage, haveProduct: e.target.value })
              }
              value={advancedResellerStage.haveProduct}
            >
              <Space direction="vertical">
                <Radio value={true}>{t("yes")}</Radio>
                <Radio value={false}>{t("no")}</Radio>
              </Space>
            </Radio.Group>
            <div className="row width-100 center">
              <button
                onClick={handleValidForm}
                style={{ borderRadius: "4px", fontSize: "25px", marginTop: "35px" }}
                className="button big orange-back"
              >
                {t("welcomePage.card1.button")}
              </button>
            </div>
          </div>
        ) : (
          <div className="form">
            <p style={{ color: "#909090" }} className="title-bold">
              {t("welcomePage.card2.title")}
            </p>
            {(!advancedResellerStage.storeCreated || !advancedResellerStage.alreadySale) && (
              <div style={{ marginTop: "30px" }} className="row width-100 start">
                <img src={illustration1} alt="illustration1" className="illustration" />
                <div className="column">
                  <p style={{ marginBottom: "10px" }} className="title-bold grey">
                    {!advancedResellerStage.storeCreated
                      ? t("welcomePage.card2.1")
                      : t("welcomePage.card2.10")}
                  </p>
                  <p className="text grey">{t("welcomePage.card2.2")}</p>
                  <Link
                    style={{ marginTop: "15px" }}
                    to="/e-commerce-advanced-coaching"
                    className="button big orange-back"
                  >
                    {t("welcomePage.card2.3")}
                  </Link>
                </div>
              </div>
            )}
            {!advancedResellerStage.alreadySale && (
              <div style={{ marginTop: "45px" }} className="row width-100 start">
                <img src={illustration2} alt="illustration1" className="illustration" />
                <div className="column">
                  <p style={{ marginBottom: "10px" }} className="title-bold grey">
                    {t("welcomePage.card2.4")}
                  </p>
                  <p className="text grey">{t("welcomePage.card2.5")}</p>
                  <Link
                    style={{ marginTop: "15px" }}
                    to="/e-commerce-advanced-coaching"
                    className="button big orange-back"
                  >
                    {t("welcomePage.card2.6")}
                  </Link>
                </div>
              </div>
            )}

            {!advancedResellerStage.haveProduct && (
              <div style={{ marginTop: "45px" }} className="row width-100 start">
                <img src={illustration3} alt="illustration1" className="illustration" />
                <div className="column">
                  <p style={{ marginBottom: "10px" }} className="title-bold grey">
                    {t("welcomePage.card2.7")}
                  </p>
                  <p className="text grey">{t("welcomePage.card2.8")}</p>
                  <Link style={{ marginTop: "15px" }} to="/marketplace" className="button big orange-back">
                    {t("welcomePage.card2.9")}
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeGuide;
