import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Summary = ({ list }) => {
  const { t } = useTranslation();
  return (
    <div className="column course-sponso-container summary">
      <p style={{ marginBottom: "10px" }} className="title-bold montserrat">
        {t("summary")}
      </p>
      {list}
    </div>
  );
};

export default Summary;
