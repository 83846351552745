import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { url } from "../api/url";
import { header } from "../api/header";
import { getImage } from "../utils/GetImage";
import ShippingMethods from "./productDetails/ShippingMethods";
import { Skeleton, InputNumber, message } from "antd";
import { Modal } from "../wrappers";
import { updateCart } from "../utils/updateCart";
import { priceCalc } from "../utils/priceCalc";
import { routes } from "../routes";
import { userServices } from "../services/user";

const ProductCart = ({
  cartItem,
  index,
  windowSize,
  removeProductIds,
  setRemoveProductIds,
  setPopupLogin,
  popupLogin,
  shippingInformations,
  setLoad,
  setNeedShippingInfos,
  user,
}) => {
  const [product, setProduct] = useState({});
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [supplierShippings, setSupplierShippings] = useState([]);
  const [variantSelect, setVariantSelect] = useState({});
  const [shippingSelect, setShippingSelect] = useState({});
  const [newShippingSelect, setNewShippingSelect] = useState({});
  const [seeShipping, setSeeShipping] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [quantity, setQuantity] = useState(cartItem.quantity);
  const [productPrice, setProductPrice] = useState(0);
  const [orderAfterLogin, setOrderAfterLogin] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [availableShipping, setAvailableShipping] = useState([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (cartItem.product_id && !product.name) {
      getProduct(cartItem.product_id);
    }
  }, [cartItem]);

  useEffect(() => {
    if (newShippingSelect?._id) {
      setShippingSelect(newShippingSelect);
      updateCart({
        product,
        shippingSelect: newShippingSelect,
        variantSelect,
        quantity: cartItem.quantity,
        idAccount,
        secretId,
        url,
        header,
        token,
        t,
      });
    }
  }, [newShippingSelect]);

  useEffect(() => {
    if (!popupLogin && orderAfterLogin && localStorage.getItem("typeAccountCreatosell")) {
      createOrder();
      setOrderAfterLogin(false);
    }
  }, [popupLogin]);

  useEffect(() => {
    if (product.price) {
      if (!cartItem?.variant_id) setProductPrice(parseFloat(product.price));
      else {
        setProductPrice(parseFloat(variantSelect.price));
      }
    }
  }, [product]);

  const findVariant = (product) => {
    if (cartItem?.variant_id) {
      setVariantSelect(product.variants.find((variant) => variant?._id === cartItem.variant_id));
    }
  };

  const findShipping = (product) => {
    if (product.shipping.length) {
      const shipping = product.shipping.find((shipping) => shipping?._id === cartItem.shipping_id);
      if (shipping) {
        setShippingSelect(shipping);
      }
    }
  };

  const getSupplierShippings = async (createrId) => {
    const res = await fetch(`${url}/user/find-shipping/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setSupplierShippings(resJson.data);
    }
  };

  const getProduct = async (id) => {
    const res = await fetch(`${url}/product/findOne?id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: idAccount,
      },
    });
    const resJson = await res.json();
    if (resJson && resJson.data && resJson.data.photos) {
      findVariant(resJson.data);
      findShipping(resJson.data);
      const productRes = resJson.data;
      const productImage = await getImage(productRes.photos[0]);
      if (productRes.creater && productRes.creater.id) getSupplierShippings(productRes.creater.id);

      if (typeAccount === "seller" && productRes.creater?.id) {
        const res = await fetch(
          `${url}/custom-price/find-product-price/${productRes.creater.id}/${localStorage.getItem(
            "idAccountCreatosell"
          )}/${productRes?._id}/no-variant`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              ...header,
              authorization: localStorage.getItem("tokenCreatosell"),
            },
          }
        );
        if (res.status === 200) {
          const resJson2 = await res.json();
          setProduct({
            ...productRes,
            image: productImage,
            variants_price: "",
            price: resJson2.customPrice,
            comparedPrice: productRes.price,
          });
        } else {
          setProduct({ ...productRes, image: productImage, variants_price: "" });
        }
      } else setProduct({ ...productRes, image: productImage, variants_price: "" });
    }
  };

  const createOrder = async () => {
    if (
      !shippingInformations.name ||
      !shippingInformations.address1 ||
      !shippingInformations.city ||
      !shippingInformations.country
    ) {
      message.error(t("messages.needFill.shipping"));
      setLoad(false);
      setNeedShippingInfos(true);
    } else {
      const resOrder = await fetch(
        `${url}/order/create/manual/${localStorage.getItem("idAccountCreatosell")}/${localStorage.getItem(
          "secretAccountId01supply"
        )}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            ...header,
            authorization: localStorage.getItem("tokenCreatosell"),
          },
          body: JSON.stringify({
            product,
            shippingAddress: shippingInformations,
            shippingMethodId: shippingSelect?._id ? shippingSelect._id : "",
            variantId: variantSelect?._id ? variantSelect._id : "",
            quantity,
          }),
        }
      );
      if (resOrder.status === 200) {
        const resJson = await resOrder.json();
        const orderId = resJson.orderId;
        setRedirect("/my-orders?orderId=" + orderId);
        setLoad(false);
        updateCart({
          product,
          shippingSelect,
          variantSelect,
          quantity: cartItem.quantity,
          idAccount,
          secretId,
          url,
          header,
          token,
          deleteItemId: cartItem.product_id,
          t,
        });
        setRemoveProductIds([...removeProductIds, cartItem.product_id]);
      } else {
        setLoad(false);
        message.error(t("messages.error"));
      }
    }
  };

  const placeOrder = async () => {
    if (!typeAccount) {
      setPopupLogin(routes.cart(i18n.language).path);
      setOrderAfterLogin(true);
    } else {
      setLoad(true);
      createOrder();
    }
  };

  return (
    <div
      className="row"
      style={
        windowSize > 1150
          ? { width: "100%", alignItems: "flex-start" }
          : { flexDirection: "column", width: "100%", alignItems: "flex-start" }
      }
    >
      {redirect && <Redirect to={redirect} />}
      <ShippingMethods
        setAvailableShippingProps={setAvailableShipping}
        countryDestination={shippingInformations.country}
        modalOn={seeShipping}
        windowSize={windowSize}
        setShippingSelect={setNewShippingSelect}
        shippingSelect={shippingSelect}
        product={product}
        supplierShippings={supplierShippings}
        setModalOn={setSeeShipping}
      />

      {removeModal && (
        <Modal
          className="modal-order"
          title={`Remove a product from the cart`}
          onOk={() => {
            updateCart({
              product,
              shippingSelect,
              variantSelect,
              quantity: cartItem.quantity,
              idAccount,
              secretId,
              url,
              header,
              token,
              deleteItemId: cartItem.product_id,
              t,
            });
            setRemoveProductIds([...removeProductIds, cartItem.product_id]);
            setRemoveModal(false);
          }}
          visible={true}
          onCancel={() => setRemoveModal(false)}
        >
          <div className="column" style={{ alignItems: "center", width: "100%" }}>
            <p className="title" style={{ width: "80%" }}>
              {t("components.productCart.1")} {product.name} {t("components.productCart.2")}
            </p>
          </div>
        </Modal>
      )}
      <div
        style={{
          width: `${windowSize > 1150 ? "70%" : "100%"}`,
          marginRight: "20px",
          marginLeft: `${windowSize < 1150 && "0"}`,
        }}
        className="card"
      >
        <p className="title-roboto">
          {t("components.productCart.3")} {index + 1}
        </p>
        <div className="divider-horizontal" style={{ marginLeft: "0", width: "100%" }} />
        <p style={{ marginBottom: "20px" }} className="title-300">
          {t("supplier")}: {product.creater?.name}
        </p>
        <div
          style={
            windowSize > 1150
              ? { alignItems: "flex-start", width: "100%" }
              : { flexDirection: "column", width: "100%", alignItems: "flex-start" }
          }
          className="row"
        >
          <div
            style={{ height: "170px", width: "170px", marginRight: "30px", borderRadius: "6px" }}
            className="container-image-card"
          >
            {product.image ? (
              <Link to={`/product-details?name=${product.name}&id=${product._id}`}>
                <img style={{ height: "100%" }} src={product.image} className="image-product pointer" />
              </Link>
            ) : (
              <Skeleton className="image-product" loading={true} active />
            )}
          </div>
          <div
            style={
              windowSize > 1150 ? { width: "50%", marginRight: "11%" } : { width: "95%", marginTop: "15px" }
            }
            className="column"
          >
            <Link
              className="title-300 start text-ellipsis"
              to={`/product-details?name=${product.name}&id=${product._id}`}
            >
              <p style={{ width: "100%" }} className="title-300 start text-ellipsis">
                {product.name}
              </p>
            </Link>
            {variantSelect.option1 && product.optionsVariants && (
              <div className="row">
                <p style={{ marginRight: "15px" }} className="text-roboto grey">
                  {product.optionsVariants[0] + ": "}
                  <span style={{ marginLeft: "0px" }} className="text-roboto-bold">
                    {variantSelect.option1}
                  </span>
                </p>
                {variantSelect.option2 && (
                  <p style={{ marginRight: "15px" }} className="text-roboto grey">
                    {product.optionsVariants[1] + ": "}
                    <span style={{ marginLeft: "0px" }} className="text-roboto-bold">
                      {variantSelect.option2}
                    </span>
                  </p>
                )}
                {variantSelect.option3 && (
                  <p className="text-roboto grey">
                    {product.optionsVariants[2] + ": "}
                    <span style={{ marginLeft: "0px" }} className="text-roboto-bold">
                      {variantSelect.option3}
                    </span>
                  </p>
                )}
              </div>
            )}
            <div
              style={{
                width: "90%",
                height: "20px",
                marginTop: "30px",
                marginBottom: "10px",
                alignItems: "flex-end",
                fontSize: "18px",
                color: "#3f3f3f",
              }}
              className="row"
            >
              <p style={{ color: "#3f3f3f" }} className="text-bold text-ellipsis montserrat">
                ${product.price}
              </p>
              {product.comparedPrice && (
                <p
                  style={{
                    fontSize: "13px",
                    minHeight: "25px",
                    marginLeft: "15px",
                    textDecoration: "line-through",
                  }}
                  className="text-300 text-ellipsis montserrat"
                >
                  ${product.comparedPrice}
                </p>
              )}
            </div>
            {shippingSelect && shippingSelect.name && availableShipping.length > 0 ? (
              <p className="text text-ellipsis">
                <span
                  onClick={() => setSeeShipping(true)}
                  className="text-bold"
                  style={{ color: "#70C4F3", marginRight: "5px", cursor: "pointer" }}
                >
                  {parseFloat(shippingSelect.price) > 0
                    ? "Shipping: $" + shippingSelect.price
                    : t("freeShipping")}
                </span>
                via {shippingSelect.name} {t("components.productCart.4")} {shippingSelect.time} {t("days")}
              </p>
            ) : (
              <p
                onClick={() => setSeeShipping(true)}
                className="text-bold underline"
                style={{ color: "#70C4F3", marginRight: "5px", cursor: "pointer" }}
              >
                {t("components.productCart.5")}
              </p>
            )}
          </div>
          <div style={windowSize > 1150 ? { alignItems: "flex-end" } : {}} className="column">
            <p className="text-bold">{t("quantity")}</p>
            <InputNumber
              min={1}
              max={
                !product.stop_selling_out_stock
                  ? 1000
                  : !variantSelect.stock
                  ? product.stock
                  : variantSelect.stock
              }
              value={quantity}
              type="number"
              style={{ width: "80px", marginTop: "5px", marginBottom: "15px", marginTop: "5px" }}
              onChange={(value) => {
                setQuantity(value);
                updateCart({
                  product,
                  shippingSelect: newShippingSelect,
                  variantSelect,
                  quantity: value,
                  idAccount,
                  secretId,
                  url,
                  header,
                  token,
                  t,
                });
              }}
            />
            <p
              onClick={() => setRemoveModal(true)}
              style={{ fontSize: "14px", marginTop: "20px", color: "#ff5b5b" }}
              className="text-roboto underline pointer"
            >
              {t("delete")}
            </p>
          </div>
        </div>
      </div>

      <div
        style={windowSize < 1150 ? { width: "100%", marginLeft: "0", marginTop: "-20px" } : {}}
        className="card"
      >
        <p className="text-bold">
          {t("components.productCart.6")} {index + 1}
        </p>
        <p style={{ marginBottom: "0px" }} className="text-bold grey">
          {t("supplier")}:
          <span style={{ marginLeft: "5px", fontWeight: "400", color: "grey" }} className="text-roboto grey">
            {product.creater?.name}
          </span>
        </p>
        <div className="divider-horizontal" style={{ marginLeft: "0", width: "100%" }} />
        <p className="title-bold">
          Total: $
          {shippingSelect && shippingSelect.price
            ? priceCalc.sellerProductOrder({ shipping: shippingSelect, product, feesPercent: 0, quantity })
                .total
            : (productPrice * parseInt(quantity)).toFixed(2)}
        </p>
        {availableShipping.length > 0 ? (
          <button
            onClick={placeOrder}
            style={{ minWidth: "130px", width: "100%", fontSize: "22px", marginTop: "20px" }}
            className="button orange-back"
          >
            {t("components.productCart.7")}
          </button>
        ) : (
          <p style={{ maxWidth: "200px" }} className="text red">
            {t("components.productCart.8")}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductCart;
