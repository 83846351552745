import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { message } from "antd";

import { url } from "../../api/url";
import { header } from "../../api/header";
import { Redirect } from "react-router-dom";
import UserIcon from "../../images/icons/User.png";
import { Modal } from "../../wrappers";
import UploadImage from "../UploadSimpleImage";
import RedirectLogin from "../../utils/RedirectLogin";
import { getImage } from "../../utils/GetImage";
import icon_setting from "../../images/icons_grey/Settings.svg";
import icon_setup from "../../images/icons_grey/Setup.svg";
import { routes } from "../../routes";

const MyAccount = ({ location, user, setUser }) => {
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [popup, setPopup] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [uploadImage, setUploadImage] = useState(false);
  const [imageUpload, setImageUpload] = useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    updateProfilePicture();
  }, [imageUpload]);

  const updateProfilePicture = async () => {
    if (imageUpload.base64) {
      const res = await fetch(`${url}/user/picture/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          picture: imageUpload,
          lastImageId: user.profile_picture,
          imageType: "profile_picture",
        }),
      });
      if (res.status === 200) {
        message.success(t("messages.profilePictureUpdated"));
        setImageUpload({});
        setUploadImage(false);
        const resJson = await res.json();
        const img = await getImage(resJson.imageId);
        if (img) {
          setUser({ ...user, profile_picture: img });
        }
      }
    }
  };

  const logout = () => {
    localStorage.setItem("admin", "");
    localStorage.setItem("tokenCreatosell", "");
    localStorage.setItem("typeAccountCreatosell", "");
    localStorage.setItem("emailAccountCreatosell", "");
    localStorage.setItem("idAccountCreatosell", "");
    localStorage.setItem("nameAccountCreatosell", "");
    localStorage.setItem("secretAccountId01supply", "");

    localStorage.setItem("demoMarketActivate", false);
    localStorage.setItem("demoShopActivate", false);
    localStorage.setItem("demoInformationsActivate", false);
    localStorage.setItem("demoPlansActivate", false);
    localStorage.setItem("demoInventory1Activate", false);
    localStorage.setItem("demoInventory2Activate", false);
    localStorage.setItem("demoCreateProductActivate", false);
    setRedirect(true);

    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to={routes.marketplace(i18n.language).path} />}
      {popup && (
        <Modal
          className="modal-account"
          onOk={logout}
          visible={true}
          onCancel={() => setPopup(false)}
          footer={[
            <button className="button-red" key="submit" type="primary" onClick={logout}>
              {t("components.menu.1")}
            </button>,
          ]}
        >
          <div style={{ alignItems: "center" }} className="column">
            <RedirectLogin />
            <img
              onClick={() => setUploadImage(true)}
              src={user?.profile_picture?.includes("http") ? user.profile_picture : UserIcon}
              className="user-icon-popup"
            />
            <p style={{ marginTop: "30px" }} className="text-bold ">
              {typeAccount === "creater" && user && user.shop ? user.shop.name : user && user.name}
            </p>
            <p className="title-300" style={{ fontSize: "14px" }}>
              {user && user.email}
            </p>
          </div>
        </Modal>
      )}
      {uploadImage && (
        <Modal className="modal-account" visible={true} onCancel={() => setUploadImage(false)} footer={[]}>
          <div style={{ alignItems: "center" }} className="column">
            <UploadImage image={user?.profile_picture} setImage={setImageUpload} />
          </div>
        </Modal>
      )}

      {user && user.email && (
        <div
          onClick={() => {
            setPopup(true);
          }}
          className="container-bottom-menu"
        >
          {location !== "affiliate" && (
            <>
              <Link
                style={{ marginBottom: "5px", maxWidth: "calc(100% + 30px)" }}
                to={typeAccount === "creater" ? "/setup-supplier" : "/setup-reseller"}
                className={
                  location === "setup-supplier" || location === "setup-reseller"
                    ? "text-menu-active"
                    : "text-menu"
                }
              >
                <img src={icon_setup} className="icon-menu" />
                {t("setup")}
              </Link>
              <Link
                style={{ marginBottom: "0px", maxWidth: "calc(100% + 30px)" }}
                to={typeAccount === "creater" ? "/your-shop-supplier" : "/your-shop-reseller"}
                className={
                  location === "your-shop-supplier" ||
                  location === "your-shop-reseller" ||
                  location === "your-shop-reseller/" ||
                  location === "your-informations" ||
                  location === "plans-supplier" ||
                  location === "supplier-documentation" ||
                  location === "reseller-documentation"
                    ? "text-menu-active"
                    : "text-menu"
                }
              >
                <img src={icon_setting} className="icon-menu" />
                {t("settings")}
              </Link>
              <div
                style={{ marginTop: "10px", marginRight: "0", marginLeft: "0", marginBottom: "12px" }}
                className="divider-horizontal"
              />
            </>
          )}
          <div style={{ width: "calc(100% + 40px)", marginLeft: "-15px" }} className="row">
            <img
              src={user?.profile_picture?.includes("http") ? user?.profile_picture : UserIcon}
              className="user-icon-menu"
            />
            <div style={{ alignItems: "center" }} className="column">
              <p
                className="title-300 text-ellipsis center"
                style={{ fontSize: "13px", width: "140px", minHeight: "20px" }}
              >
                {user && user.email}
              </p>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyAccount;
