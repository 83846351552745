import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {routes} from '../routes'

const Cta = ({ windowSize }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="column center "
      style={
        windowSize > 1150
          ? { width: "100vw", paddingTop: "5vw", backgroundColor: "#f5f5f5" }
          : { width: "100vw", paddingTop: "100px", backgroundColor: "#f5f5f5" }
      }
    >
      <div className="row" style={{ width: "100%", justifyContent: "center" }}>
        {/* PAGE DIV 9*/}
        <div
          className="row"
          style={
            windowSize > 1150 ? { display: "flex", width: "80%" } : { width: "100%", flexDirection: "column" }
          }
        >
          {/* text */}
          <div style={{ flex: "1", float: "left" }}>
            <h2
              style={
                windowSize > 1150
                  ? {
                      fontSize: "30px",
                      textAlign: "left",
                      width: "100%",
                      fontWeight: "700",
                      color: "#585858",
                      fontFamily: "Montserrat",
                      lineHeight: "2.5vw",
                    }
                  : {
                      marginLeft: "0px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      color: "#585858",
                      marginBottom: "20px",
                    }
              }
            >
              {t("components.cta.1")} <br />
              {t("components.cta.2")}
            </h2>
          </div>
          {/* buttons */}
          <div style={{ flex: "1", float: "right", textAlign: "-webkit-right" }}>
            <div>
              <Link
                className="button orange-back"
                style={
                  windowSize > 1150
                    ? { height: "35px", width: "200px" }
                    : { height: "50px", marginBottom: "10px" }
                }
                href={routes.joinUs(i18n.language).path+"?reseller"}
              >
                {t("components.cta.3")}
              </Link>
            </div>
            <div>
              <p>
                {t("or")} -{" "}
                <Link className="text-gradient" to={routes.joinUs(i18n.language).path+"?supplier"}>
                  {t("components.cta.4")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
