import { message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { orderServices } from "../../services/order";

const ConfirmPayment = ({ order, setOrder }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    orderServices.paymentConfirmationCreater({ orderId: order._id }).then((res) => {
      if (res.status === 200) {
        setOrder({ ...order, status: "paid" });
        message.success(t("confirmPaymentMessage"));
      } else {
        message.error(t("messages.error"));
      }
    });
  };

  return (
    <div style={{ marginTop: "15px" }} className="width-100">
      <p className="title-bold">
        {t("paymentRef")} : <span style={{fontWeight: '400'}} className="title">#{order.paid?.bankTransferRef}</span>
      </p>
      <button style={{ marginTop: "15px" }} onClick={handleConfirm} className="button big orange-back">
        {t("confirmPayment")}
      </button>
    </div>
  );
};

export default ConfirmPayment;
