import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article27/fournisseur-francais-dropshipping.jpg";
import presentation01supply from "../../../../images/blog/global/article27/01supply-presentation-2.jpg";
import offres01supply from "../../../../images/blog/global/article27/plans 01supply.JPG";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Comment trouver des fournisseurs en dropshipping en France?
      </h1>
      <p className="text-300">16 december 2022 - 4 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="fournisseur francais dropshipping" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Si vous êtes dans le dropshipping, vous savez certainement à quel point la livraison peut être longue quand vous travaillez avec des fournisseurs à l’autre bout de la terre
        (en Chine par exemple). Ainsi, afin de <b>réduire vos délais de livraison</b> et afin de proposer du <b>Made In France</b> sur votre boutique en ligne,
        vous avez décidé de vous penchez sur des <b>fournisseurs français</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Dans cet article, nous allons vous donner la solution pour trouver des centaines de fournisseurs et artisans français qui acceptent le dropshipping,
        et seulement en quelques clics.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#dropshipping-definition" title="Qu’est-ce que le dropshipping?" />
            <Anchor.Link href="#trouver-fournisseurs-francais" title="Trouver des fournisseurs français dans un catalogue en ligne" />
            <Anchor.Link href="#avantages-fournisseurs-francais" title="Les avantages de travailler avec des fournisseurs français en dropshipping" />
            <Anchor.Link href="#liste-fournisseurs-francais" title="Liste des fournisseurs en France">
              <Anchor.Link href="#01supply" title="01supply" />
              <Anchor.Link href="#cdiscount-pro" title="Cdiscount Pro" />
              <Anchor.Link href="#bigbuy" title="BigBuy" />
            </Anchor.Link>
            <Anchor.Link href="#negocier-prix" title="Négocier les prix" />
          </Anchor>
        }
      />
      <h2 id="dropshipping-definition">Qu’est-ce que le dropshipping?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Le dropshipping est un modèle d’e-commerce où <b>le revendeur a une boutique en ligne mais ne fait pas de stock</b>.
        C’est son fournisseur qui va directement expédier la commande à son client.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ainsi, le revendeur, souvent débutant, n’a pas besoin d’investir dans un large stock de produit, ni de s’occuper du stockage, de la logistique ou encore des invendus.
        C’est donc une optimisation de temps, d’argent et d’efficacité in-fine pour tout le monde.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Le système du dropshipping est très largement utilisé par de nombreux actueurs du marché comme Amazon, Ebay, Cdiscount, et bien d’autres…
      </p>
      <h2 id="trouver-fournisseurs-francais">Trouver des fournisseurs français dans un catalogue en ligne
      </h2>
      <img src={presentation01supply} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="01supply fournisseur dropshipping" />
      <p style={{ marginBottom: "20px" }}>
        <a href="https://app.01supply.com" target="blank_" className="blue" alt="01supply"><u>01supply</u></a> est
        une plateforme qui référence <b>plus de 250.000 produits d’artisans dont une bonne partie sont des français</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Sur notre marketplace vous pourrez naviguer à travers de nombreuses catégories et thématiques différentes pour trouver les articles français pour votre boutique en ligne.
      </p>
      <p style={{ marginBottom: "20px" }}>
        En vous <a href="https://app.01supply.com/fr/join-us?reseller" target="blank_" className="blue" alt="Créer compte 01supply"><u>créant un compte revendeur</u></a> sur notre plateforme,
        vous aurez la aussi possibilité d’<b>importer des produits de fournisseurs français</b> en quelques clics sur votre boutique Shopify, Woocommerce, Prestashop ou autre.
      </p>
      <p style={{ marginBottom: "20px" }}>
        01supply vous permet par la même occasion de synchroniser les stocks de votre inventaire
        ainsi que de <b>synchroniser les commandes</b> afin de les passer en un clic à votre fournisseur.
      </p>
      <h2 id="avantages-fournisseurs-francais">Les avantages de travailler avec des fournisseurs français en dropshipping
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Les avantages à travailler avec des <b>fournisseurs locaux</b> sont nombreux. Voici les principaux bénéfices:
      </p>
      <ul>
        <li>
          Livraison rapide à vos clients français et Européens (3-4 jours)
        </li>
        <li>
          Fabrication Made in France (vos clients vont adorer)
        </li>
        <li>
          Qualité des produits et normes de sécurité
        </li>
        <li>
          Produits high-ticket* pour plus de marges et de profits
        </li>
        <li>
          Distance de livraison raccourcie et plus écologique
        </li>
        <li>
          Soutien à l’économie locale des territoires français
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        *<i>Le high-ticket consiste à vendre des produtis haut de gamme à prix élevé. Généralement, les marges sont plus importantes pour l’e-commerçant.
          Par exemple pour une robe de marié achetée 500€, elle peut être revendue 1000€, soit 500€ de marge brute.
        </i></p>
      <h2 id="liste-fournisseurs-francais">Liste des fournisseurs en France
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Il existe de nombreuses plateformes qui proposent une <b>livraison depuis la France</b> ou l’Europe, voici les plus importantes:
      </p>
      <h3 id="01supply">1. 01supply</h3>
      <p style={{ marginBottom: "20px" }}>
        01supply propose un large catalogue d’artisans locaux pour fournir votre boutique avec des produits uniques, faits main et <b>high-ticket</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        01supply n’a pas d’entrepots. 01supply vous aide à travailler directement avec des artisans et <b>fournisseurs français qui vont expédier pour vous</b>,
        depuis leur propre atelier ou leur propre entrepôt.
      </p>
      <a className="button big orange-back" href="https://app.01supply.com/marketplace?page=1&category=&thematicName=&origin=%F0%9F%87%AB%F0%9F%87%B7%20France&originKey=0-2-16&deliverTo=&destinationName=&maxPrice=&minPrice=&search=&isHighMarginProducts=&isWinningProducts=&sort=&userId=" target="blank_" alt="fournisseurs français dropshipping liste">Voir les produits de fournisseurs français</a>
      <br/>
      <p style={{ marginBottom: "20px" }}>
        Prix: Gratuit jusqu’à 9 produits, abonnements disponibles pour importer plus de produits. <a className="blue" target="blank_" href="https://app.01supply.com/fr/pricing" alt="Prix 01supply"><u>Voir les abonnements</u></a>.
      </p>
      <a target="blank_" href="https://app.01supply.com/fr/pricing" alt="Offres 01supply">
        <img src={offres01supply} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="offres 01supply" />
      </a>
      <h3 id="cdiscount-pro">Cdiscount Pro</h3>
      <p style={{ marginBottom: "20px" }}>
        Cdiscount propose un service dropshipping où ils expédient les produits pour vous. Vous pouvez choisir ceux-ci dans le catalogue.
        <br />
        Cdiscount ont des entrepots en Europe, cependant, les produits sont rarement fabriqués en France.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Prix: À partir de 49€/mois (Hors taxes)
      </p>
      <h3 id="bigbuy">BigBuy</h3>
      <p style={{ marginBottom: "20px" }}>
        BigBuy fonctionne de la même manière que Cdiscount Pro.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Prix: À partir de 69€/mois avec 90€ pour l’enregistrement
      </p>
      <h2 id="negocier-prix">Négocier les prix
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Lorsque vous commencez à avoir des commandes sur votre boutique, il est temps de <b>négocier les prix avec votre fournisseur</b>.
        En effet, étant donné que vous lui apportez des ventes, il est important pour vous de pouvoir mettre en avant cet argument.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Pour ce faire, tentez d’en <b>apprendre plus sur le fournisseur</b>.
        Posez lui des questions sur la fabrication de ses produits, son cout de revient, etc…
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ainsi, avec ces informations en votre possession vous pourrez <b>définir une négociation équitable et sensée</b>.
        Le fournisseur sera alors plus coopératif et à l’écoute de vos offres car vous avez aussi été à son écoute.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Il est déconseillé de négocier les prix si vous n’avez pas encore de ventes ou si le fournisseur ne vous connait pas encore.
        En effet, il est préférable de d’abord lui apporter de la valeur et des ventes avant de vouloir baisser ses prix.
      </p>
    </div>
  );
};

export default Content;
