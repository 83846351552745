import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Save = ({ callbackSave, discardUrl, callbackDiscard }) => {
  const { t } = useTranslation();
  return (
    <div className="bottom-save">
      {(discardUrl || callbackDiscard) && (
        <Link
          onClick={(e) => callbackDiscard?.(e)}
          style={{ padding: "8px 35px", fontSize: "22px", marginRIhgt: "15px", marginLeft: "15px" }}
          to={discardUrl}
          className="button-disable-grey"
        >
          {t("discard").toUpperCase()}
        </Link>
      )}
      <button
        style={{ padding: "8px 35px", fontSize: "22px", marginRIhgt: "15px", marginLeft: "15px" }}
        onClick={callbackSave}
        className="button big orange-back"
      >
        {t("save").toUpperCase()}
      </button>
    </div>
  );
};

export default Save;
