import React from "react";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Plan2 = ({ user, typePlan, setTypePlan, setUser, setModal, setCancelSub, isYearly, setIsYearly }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        setModal={setModal}
        setTypePlan={setTypePlan}
        typePlan={typePlan}
        user={user}
        setUser={setUser}
        setCancelSub={setCancelSub}
        isYearly={isYearly}
        setIsYearly={setIsYearly}
      />
    </Elements>
  );
};

export default Plan2;
