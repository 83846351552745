import { url } from "../api/url";
import { header } from "../api/header";

export const newResearch = ({ word, user }) => {
  // SEND RESEARCH STATISTICS
  fetch(`${url}/statistics/research`, {
    method: "PUT",
    credentials: "include",
    headers: {
      ...header,
    },
    body: JSON.stringify({
      user,
      word,
    }),
  });
};