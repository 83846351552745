import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, message } from "antd";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import Header from "../../../components/header/SimpleHeader";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import { header } from "../../../api/header";
import { url } from "../../../api/url";

import ImageInput from "../../../components/UploadSimpleImage";
import Load from "../../../utils/Load";

import HistoryItem from "./HistoryItem";
import { sendGridEmail } from "../../../utils/SendGridEmail";

import "./style.scss";

const { TextArea } = Input;

const Sourcing = () => {
  const [newSourcing, setNewSourcing] = useState({
    user_id: localStorage.getItem("idAccountCreatosell"),
    status: "Processing",
    item_describe: "",
    other_informations: "",
    exemple_image: "",
    urls_product_exemple_1: "",
    urls_product_exemple_2: "",
    urls_product_exemple_3: "",
  });
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [img, setImg] = useState("");
  const [sourcingHistory, setSourcingHistory] = useState([]);
  const [sendingBool, setSendingBool] = useState(false);
  const [removeImageBool, setRemoveImageBool] = useState(false);
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    findSourcing();
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const changeNewSourcing = (value, label) => {
    let sourcingStock = newSourcing;
    sourcingStock[label] = value;
    setNewSourcing({ ...sourcingStock });
  };

  const findSourcing = async () => {
    const res = await fetch(`${url}/sourcing/find-by-user/${idAccount}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status == 200) {
      const resJson = await res.json();

      setSourcingHistory(resJson.sourcing);
    }
  };
  const sendRequest = async () => {
    setLoad(true);
    if (!newSourcing.item_describe) {
      setLoad(false);
      message.error(t("messages.pleaseTelAboutSearch"));
    } else {
      const res = await fetch(`${url}/sourcing/create`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          sourcing: { ...newSourcing, user_email: localStorage.getItem("emailAccountCreatosell") },
        }),
      });
      if (res.status == 200) {
        setSourcingHistory([...sourcingHistory, newSourcing]);
        setNewSourcing({
          user_id: idAccount,
          user_email: localStorage.getItem("emailAccountCreatosell"),
          status: "Processing",
          item_describe: "",
          other_informations: "",
          exemple_image: "",
          urls_product_exemple_1: "",
          urls_product_exemple_2: "",
          urls_product_exemple_3: "",
        });
        setSendingBool(!sendingBool);
        message.success(t("messages.requestSent"));
        sendGridEmail(
          "d-c8d2f4d2796f480aa87e0d06bc133510",
          ["contact.01supply@gmail.com", "diegohuberty14@gmail.com"],
          {
            subject: "New reseller sourcing request !",
            userEmail: localStorage.getItem("emailAccountCreatosell"),
          },
          token
        );
      } else {
        message.error(t("messages.error"));
      }
      setLoad(false);
    }
  };

  useEffect(() => {
    if (img && img.base64) setNewSourcing({ ...newSourcing, exemple_image: img.base64 });
  }, [img]);

  return (
    <div className="page sourcing">
      {load && <Load />}
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="sourcing" />}
        <div className="column-scrolling">
          {windowSize > 1150 ? (
            <Header location={t("sourcing.1")} />
          ) : (
            <MobileHeader nameHeader={t("sourcing.1")} location={t("sourcing.1")} />
          )}
          <div style={{ width: "90%", alignItems: "flex-start" }} className="row-wrap space-between">
            <div
              className="column card"
              style={
                windowSize > 1150
                  ? { width: "60%", marginRight: "30px", minWidth: "330px" }
                  : { marginLeft: "5vw", paddingBottom: "100px", minWidth: "330px" }
              }
            >
              <h1 className="title montserrat-700" style={{ fontSize: "20px" }}>
                {t("sourcing.2")}
              </h1>
              <div style={{ width: "100%", marginTop: "10px" }} className="column">
                <p style={{ marginBottom: "8px" }} className="text-bold">
                  {t("sourcing.3")}
                </p>
                <TextArea
                  value={newSourcing.item_describe}
                  placeholder={t("sourcing.placeholders.1")}
                  className="column"
                  showCount
                  maxLength={450}
                  style={{ width: "100%", maxWidth: "400px" }}
                  onChange={(e) => changeNewSourcing(e.target.value, "item_describe")}
                />
              </div>
              <div style={{ width: "100%", marginTop: "30px" }} className="column">
                <p style={{ marginBottom: "8px" }} className="text-bold">
                  {t("sourcing.6")}
                </p>
                <TextArea
                  value={newSourcing.other_informations}
                  placeholder={t("sourcing.placeholders.2")}
                  className="column"
                  showCount
                  maxLength={450}
                  style={{ width: "100%", maxWidth: "400px" }}
                  onChange={(e) => changeNewSourcing(e.target.value, "other_informations")}
                />
              </div>
              <div style={{ width: "100%", marginTop: "30px" }} className="column">
                <p style={{ marginBottom: "8px" }} className="text-bold">
                  {t("sourcing.4")}
                </p>
                <div key={sendingBool + removeImageBool}>
                  <ImageInput image={""} setImage={setImg} />
                  {newSourcing.exemple_image && (
                    <p
                      onClick={() => {
                        setRemoveImageBool(!removeImageBool);
                        setNewSourcing({ ...newSourcing, exemple_image: "" });
                      }}
                      style={{ textDecoration: "underline" }}
                      className="text-red"
                    >
                      {t("sourcing.5")}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px" }} className="column">
                <p style={{ marginBottom: "8px" }} className="text-bold">
                  {t("sourcing.7")}
                </p>
                <Input
                  value={newSourcing.urls_product_exemple_1}
                  style={{ width: "250px", marginBottom: "15px" }}
                  placeholder="https://www.amazon.com/pink-dress"
                  maxLength={600}
                  onChange={(e) => changeNewSourcing(e.target.value, "urls_product_exemple_1")}
                />
                <Input
                  value={newSourcing.urls_product_exemple_2}
                  style={{ width: "250px", marginBottom: "15px" }}
                  placeholder="https://www.amazon.com/pink-dress"
                  maxLength={600}
                  onChange={(e) => changeNewSourcing(e.target.value, "urls_product_exemple_2")}
                />
                <Input
                  value={newSourcing.urls_product_exemple_3}
                  style={{ width: "250px" }}
                  placeholder="https://www.amazon.com/pink-dress"
                  maxLength={600}
                  onChange={(e) => changeNewSourcing(e.target.value, "urls_product_exemple_3")}
                />
              </div>
              <button
                style={{ fontSize: "18px" }}
                onClick={sendRequest}
                className="button big orange-back width-100"
              >
                {t("sourcing.8")}
              </button>
            </div>
            <div
              style={{ alignItems: "flex-start", width: "330px", maxWidth: "330px", minWidth: "330px" }}
              className="card"
            >
              <div className="column">
                <p style={{ fontSize: "20px", marginBottom: "30px" }} className="title montserrat-700">
                  {t("sourcing.9")}
                </p>
                {sourcingHistory.map((sourcing) => {
                  return <HistoryItem sourcing={sourcing} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sourcing;
