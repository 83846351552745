import React, { useState } from "react";
import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";

import {Modal} from '../../../../wrappers'

const UpdateNumberModal = ({ setIsDisplayed, handleSubmit, defaultValue, title }) => {
  const [newValue, setNewValue] = useState(defaultValue);
  const { t } = useTranslation();

  return (
    <Modal visible={true} onCancel={() => setIsDisplayed(false)} footer={[]}>
      <div className="column">
        <p style={{ marginBottom: "10px" }} className="title bold">
          {title}
        </p>
        <InputNumber
          style={{ width: "100%" }}
          defaultValue={parseFloat(defaultValue)}
          min={0}
          max={99}
          onChange={(value) => setNewValue(value)}
        />
        <button
          style={{ width: "100%", marginTop: "25px" }}
          onClick={() => handleSubmit(newValue)}
          className="button orange-back"
        >
          {t("validate")}
        </button>
      </div>
    </Modal>
  );
};

export default UpdateNumberModal;
