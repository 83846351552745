import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import emptyListImg from "../../images/illustrations/empty_list.svg";
import emptyListFRImg from "../../images/illustrations/empty_list_fr.svg";

const EmptyList = ({ windowSize }) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ alignItems: "center" }} className="column">
      <p style={{ maxWidth: "90vw" }} className="title-bold center">
        {t("importListPage.3")}
      </p>
      <img
        src={i18n.language.includes("fr") ? emptyListFRImg : emptyListImg}
        alt="empty import list"
        style={{ width: "90%", margin: "30px 0" }}
      />
      <Link to="/marketplace" style={{ fontSize: "22px", width: "280px" }} className="button big orange-back">
        {t("importListPage.12")}
      </Link>
    </div>
  );
};

export default EmptyList;
