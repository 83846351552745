import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { courseServices } from "../../services/course";

import Load from "../../utils/Load";

import securePaymentImg from "../../images/illustrations/secure_payment.svg";
import { message } from "antd";

const StripePayment = ({ email, courseId, setIsCoursePaid, isBadEmail, setIsBadEmail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation()
  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(63, 63, 63)",
        color: "rgb(63, 63, 63)",
        fontWeight: 600,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "rgb(63, 63, 63)" },
        "::placeholder": { color: "rgb(63, 63, 63)" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const createPayment = async () => {
    if (isBadEmail || !email) {
      message.error(t("messages.needFill.email"));
      setIsBadEmail(true);
    } else {
      setIsLoading(true);
      const createdCourse = await courseServices.createPayment({ courseId, email, isStripePayment: true });
      if (createdCourse.status === 200) {
        const resJson = await createdCourse.json();
        const { paymentId, stripePaymentIntent } = resJson;

        if (stripePaymentIntent) {
          let cardConfig = elements.getElement(CardElement);
          const result = await stripe.confirmCardPayment(stripePaymentIntent.client_secret, {
            payment_method: {
              card: cardConfig,
            },
          });
          if (result.error) {
            message.error(t('messages.paymentError'));
            setIsLoading(false);
          } else if (result) {
            courseServices
              .validPayment({ stripePaymentId: stripePaymentIntent.id, paymentId: paymentId })
              .then(async (res) => {
                if (res.status === 200) {
                  setIsCoursePaid(true);

                  message.success(t("messages.orderPlaced"));
                  setIsLoading(false);
                } else {
                  message.error(t('messages.error'));
                }
              });
          }
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Load />}
      <div className="input-pricing-course-payment">
        <CardElement options={CARD_OPTIONS} />
      </div>
      <img
        style={{ marginTop: "10px" }}
        alt="secure payment"
        src={securePaymentImg}
        className="payment-button"
      />
      <button onClick={createPayment} className="button orange-back payment-button pointer">
        Place Order
      </button>
    </>
  );
};

export default StripePayment;
