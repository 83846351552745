import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <div
      className="column home-container"
      style={{
        marginTop: "20px",
        alignItems: "center",
        marginBottom: "60px",
      }}
    >
      <p className="title montserrat-800">{t("resellerHome.faq.title")}</p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.tidio.com/talk/rwhlsppllnpriozhtsqysjubupzuunlk"
        style={{ fontSize: "18px", padding: "10px 35px", marginTop: "15px" }}
        className="button big orange-back montserrat"
      >
        {t("contactUs")}
      </a>

      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
        defaultActiveKey={[]}
      >
        <Panel className="montserrat-bold" header={t("resellerHome.faq.1")} key="1">
          <p className="text-roboto montserrat">{t("resellerHome.faq.2")}</p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.3")} key="2">
          <p className="text-roboto montserrat">{t("resellerHome.faq.4")}</p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.5")} key="3">
          <p className="text-roboto montserrat">
            {t("resellerHome.faq.6")}{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.shopify.com/01supply"
              className="text-300 blue underline"
            >
              {t("resellerHome.faq.7")}
            </a>
            {t("resellerHome.faq.8")}
          </p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.9")} key="4">
          <p className="text-roboto montserrat">{t("resellerHome.faq.10")}</p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.11")} key="5">
          <p className="text-roboto montserrat">{t("resellerHome.faq.12")}</p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.13")} key="6">
          <p className="text-roboto montserrat">{t("resellerHome.faq.14")}</p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.15")} key="7">
          <p className="text-roboto montserrat">{t("resellerHome.faq.16")}</p>
        </Panel>
        <Panel className="montserrat-bold" header={t("resellerHome.faq.17")} key="8">
          <p className="text-roboto montserrat">
            {t("resellerHome.faq.18")}{" "}
            <Link to="reseller-pricing" className="text-300 blue underline">
              {t("resellerHome.faq.19")}
            </Link>
          </p>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FAQ;
