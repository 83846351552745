import React from "react";
import { Modal } from "../../wrappers";
import { useTranslation } from "react-i18next";

const ValidRequestModal = ({ setIsVisible, handleValid }) => {
  const { t } = useTranslation();
  return (
    <Modal footer={[]} title={t("supplierGrowth.20")} visible={true} onCancel={() => setIsVisible(false)}>
      <div className="column start width-100">
        <p className="title-bold">{t("supplierGrowth.21")}</p>
        <ul style={{ marginBottom: "30px", marginTop: "20px" }}>
          <li>{t("supplierGrowth.22")}</li>
          <li>{t("supplierGrowth.23")}</li>
          <li>{t("supplierGrowth.24")}</li>
          <li>{t("supplierGrowth.25")}</li>
        </ul>
        <button style={{ width: "100%" }} onClick={handleValid} className="button big orange-back">
          {t("supplierGrowth.26")}
        </button>
      </div>
    </Modal>
  );
};

export default ValidRequestModal;
