import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";

import PopupLogin from "./modals/RegisterModal";
import {routes} from '../routes'

const ContactButton = ({ contactId, css, isGrey }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [popupLogin, setPopupLogin] = useState("");

  const { i18n, t } = useTranslation()

  return (
    <>
      {popupLogin && <PopupLogin modalOn={popupLogin} setModal={setPopupLogin} />}
      <Link
        onClick={(e) => {
          if (!token) {
            e.stopPropagation();
            e.preventDefault();
            setPopupLogin(`${routes.messages(i18n.language).path}?contact=${contactId}`);
          }
        }}
        className={isGrey ? "button-disable-grey" : "button orange-back"}
        style={css ? css : { margin: "15px", marginLeft: "0" }}
        to={token && `${routes.messages(i18n.language).path}?contact=${contactId}`}
      >
        {t('getContact')}
      </Link>
    </>
  );
};

export default ContactButton;
