import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { routes } from "../../routes";

import { productServices } from "../../services/products";

import ProductCard from "../../components/ProductCard";

const ProductSuggestions = ({ user, setUser, windowSize }) => {
  const [products, setProducts] = useState([]);
  const [isLoadProduct, setIsLoadProducts] = useState(false);
  const { t } = useTranslation();

  const getProduct = async ({ changeProductIndex, bannedProduct }) => {
    let isUpdatedBanProducts = !bannedProduct ? true : false;
    let newUser = user;
    //generateDisplayedProductsIndex({ changeProductIndex });

    if (newUser) {
      if (await isUpdatedBanProducts) {
        const currentProductsId = products?.length
          ? encodeURIComponent(JSON.stringify([products[0]?._id, products[1]?._id, products[2]?._id]))
          : "";
        const newProductRes = await productServices.find({
          page: 1,
          limit: 6,
          userId: localStorage.getItem("idAccountCreatosell") || "",
          bannedIds: currentProductsId,
          isRecommendation: true,
        });
        if (newProductRes) {
          const resJson = await newProductRes.json();
          const resProducts = resJson.data;
          if (changeProductIndex || changeProductIndex === 0) {
            let newProducts = products;
            newProducts[changeProductIndex] = resProducts[0];
            setProducts(newProducts);
            return resProducts[0];
          } else {
            setProducts(resProducts);
          }
          setIsLoadProducts(false);
        }
      }
    }
  };

  useEffect(() => {
    if ((user._id || !localStorage.getItem("idAccountCreatosell")) && !products.length) {
      setIsLoadProducts(true);
      getProduct({ userParams: user });
    }
  }, [user]);

  return (
    <div
      style={{ marginTop: "30px", marginBottom: "40px", padding: windowSize < 1150 && "0" }}
      className="card width-100"
    >
      <div style={{ marginBottom: "20px" }} className="row row-wrap space-between width-100">
        <p style={{ margin: "10px" }} className="title-bold">
          {t("marketplaceHome.recommandedProducts.1")}
        </p>
      </div>

      <div
        style={{ justifyContent: "space-evenly" }}
        className="width-100 row-wrap center product-container-marketplace-home"
      >
        {products.length > 0 && !isLoadProduct ? (
          products.map((product, index) => {
            return (
              <ProductCard
                setUser={setUser}
                user={user}
                key={product._id}
                index={index}
                location={"landing"}
                product={product}
              />
            );
          })
        ) : (
          <div style={{ width: "90%" }}>
            <Skeleton
              style={{ marginTop: "80px", width: "100%" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
            <Skeleton
              style={{ marginTop: "80px", width: "100%" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
          </div>
        )}
      </div>

      <div className="width-100 row center">
        <Link to={`/marketplace`} className="button-grey">
          {t("marketplaceHome.viewAll")}
        </Link>
      </div>
    </div>
  );
};

export default ProductSuggestions;
