import React from 'react'
import image from '../images/404.svg'

const Error = ({text}) => {
    return(
        <div style={{width: '100%'}} className="column">
        <p className="title-roboto title-illustration-error">{text}</p>
            <img src={image} className="image-status"/>
        </div>

    )
}

export default Error