import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import Contact from "../../components/ContactForm";
import ShopifyLogo from "../../images/shopifylogo.png";
import EtsyLogo from "../../images/etsylogo.png";
import PaypalLogo from "../../images/Paypal-logo.png";
import StripeLogo from "../../images/Stripe-logo.png";
import StarImg from "../../images/star.svg";

import NavBar from "../../components/menu/navbar/NavBar";
import Footer from "../../components/menu/Footer";
import RegisterModal from "../../components/modals/RegisterModal";
import SendPriceOfferModal from "../../components/modals/SendPriceOffer";
import Advices from "../home/Advices";

import ResellerPlans from "../../components/reseller/pricing/FirstPlans";

import { routes } from "../../routes";

const { Panel } = Collapse;

const PricingReseller = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [redirect, setRedirect] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const [registerModalIsActive, setRegisterModalIsActive] = useState("");
  const [sendPriceOfferModalIsOn, setSendPriceOfferModalIsOn] = useState(false);
  const { i18n } = useTranslation();

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  return (
    <div className="page background-landing">
      {registerModalIsActive && (
        <RegisterModal
          isRegister={true}
          isSupplier={true}
          modalOn={registerModalIsActive}
          setModal={setRegisterModalIsActive}
        />
      )}
      {sendPriceOfferModalIsOn && <SendPriceOfferModal setModalOn={setSendPriceOfferModalIsOn} />}
      {contactUs && <Contact setContact={setContactUs} />}
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://app.01supply.com/pricing" />
        <title>Reseller Pricing | 01supply</title>
        <meta
          name="description"
          content="01supply helps online resellers find quality and handmade suppliers for their online stores. Check out our pricing and features to automate partnerhsip."
        />

        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://app.01supply.com/pricing" />
        <meta property="og:title" content={`Reseller Pricing | 01supply`} />
        <meta
          property="og:description"
          content="01supply helps online resellers find quality and handmade suppliers for their online stores. Check out our pricing and features to automate partnerhsip."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://app.01supply.com/static/media/logo.55832fc9.svg" />

        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
                    "@context": "https://schema.org",
                    "headline": "Reseller Pricing",
                    "image": [
                    "https://app.01supply.com/static/media/logo.55832fc9.svg"
                    ],
                    "publisher": {
                    "name": "01supply",
                    "url": "https://app.01supply.com/"
                    }
                    }`}
        </script>
      </Helmet>
      {redirect && <Redirect to={routes.joinUs(i18n.language).path + "?reseller"} />}

      <NavBar windowSize={windowSize} />

      {/* FULL DIV 2*/}
      <div
        className="column center"
        style={{ width: "100%", marginTop: "0px", paddingLeft: "2vw", paddingRight: "2vw" }}
      >
        {/* SWITH SUPPLIER/RESELLER PRICING 
        <div style={{ marginBottom: "40px" }} className="row">
          <Link to={routes.supplierPricing(i18n.language).path} className="double-button left">
            {t("resellerPricing.1")}
          </Link>
          <div className="double-button right active">{t("resellerPricing.2")}</div>
        </div>*/}
        <div
          className="row"
          style={
            windowSize > 1150
              ? { width: "100%", justifyContent: "center" }
              : { flexDirection: "column", width: "100%" }
          }
        >
          {/* PAGE DIV 2*/}
          <div
            style={
              windowSize > 1150
                ? { display: "flex", width: "100%" }
                : { display: "flex", flexDirection: "column" }
            }
          >
            {/* landing form */}
            <div
              className="column"
              style={
                windowSize > 1150
                  ? {
                      flex: "1",
                      float: "left",
                      width: "95%",
                      justifyContent: "flex-start",
                      marginTop: "0px",
                    }
                  : { width: "100%", textAlign: "center" }
              }
            >
              {/* PAGE DIV 2*/}
              <div
                style={{ width: "100%", alignItems: "center", justifyContent: "center" }}
                className="column"
              >
                {/* landing form */}
                <div style={{ float: "left", zIndex: "999", marginBottom: "50px", marginTop: "50px" }}>
                  <div style={{ position: "relative", maxWidth: "80vw" }}>
                    <h1
                      className="center"
                      style={
                        windowSize > 1150
                          ? {
                              marginBottom: "0",
                              fontSize: "22px",
                              fontWeight: "900",
                              fontFamily: "Montserrat",
                              lineHeight: "35px",
                            }
                          : {
                              marginBottom: "0",
                              fontSize: "18px",
                              fontWeight: "900",
                              fontFamily: "Montserrat",
                              lineHeight: "30px",
                              textAlign: "center",
                            }
                      }
                    >
                      {t("resellerPricing.title-1")}
                      <br />
                      {t("resellerPricing.title-2")}
                      <br />
                      <span style={{ fontWeight: "400", fontSize: "14px" }}>
                        {t("resellerPricing.title-3")}
                      </span>
                    </h1>
                  </div>
                </div>

                {/* PRICING CARD */}

                <div className="column" style={{ alignItems: "flex-end" }}>
                  <ResellerPlans
                    windowSize={windowSize}
                    setRegisterModalIsActive={setRegisterModalIsActive}
                    isSecondPricingDisplayed={true}
                  />

                  <Link
                    to={routes.supplierPricing(i18n.language).path}
                    style={{ marginRight: "40px", marginTop: "20px" }}
                    className="text-bold blue underline"
                  >
                    {t("resellerPricing.16")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FULL DIV 4*/}
      <div className="column center" style={{ width: "100%", zIndex: "0", paddingBottom: "0" }}>
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          {/* PAGE DIV 4*/}
          <div style={{ zIndex: "2", width: "80%" }}>
            {/* text */}
            <h2
              style={
                windowSize > 1150
                  ? {
                      fontSize: "30px",
                      textAlign: "left",
                      width: "100%",
                      fontWeight: "800",
                      fontFamily: "Roboto",
                      color: "#585858",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      marginBottom: "0",
                    }
                  : {
                      marginLeft: "0px",
                      fontSize: "25px",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontWeight: "800",
                      color: "#585858",
                    }
              }
            >
              {t("resellerPricing.18")}
            </h2>
          </div>
        </div>
      </div>

      {/* FULL DIV 5*/}

      <div className="column center" style={{ width: "100%" }}>
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          {/* PAGE DIV 5*/}
          <div
            style={
              windowSize > 1150
                ? {
                    display: "flex",
                    zIndex: "2",
                    width: "80%",
                    backgroundColor: "#F6F6F6",
                    textAlign: "center",
                    padding: "20px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }
                : {
                    width: "80%",
                    backgroundColor: "#F6F6F6",
                    textAlign: "center",
                    padding: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }
            }
          >
            {/* logo 1 */}
            <div class="darken" style={{ flex: "1" }}>
              <a href="https://apps.shopify.com/01supply" target="_blank">
                <img
                  alt="shopify"
                  style={
                    windowSize > 1150
                      ? { position: "relative", width: "8vw" }
                      : { width: "30%", margin: "20px" }
                  }
                  src={ShopifyLogo}
                />
              </a>
            </div>
            {/* logo 2 */}
            <div class="darken" style={{ flex: "1" }}>
              <img
                alt="Etsy"
                style={
                  windowSize > 1150
                    ? { position: "relative", width: "5vw" }
                    : { width: "30%", margin: "20px" }
                }
                src={EtsyLogo}
              />
            </div>
            {/* logo 3 */}
            <div class="darken" style={{ flex: "1", height: "-webkit-fill-available" }}>
              <img
                alt="Paypal"
                style={
                  windowSize > 1150
                    ? { position: "relative", width: "8vw" }
                    : { width: "30%", margin: "20px" }
                }
                src={PaypalLogo}
              />
            </div>
            {/* logo 4 */}
            <div class="darken" style={{ flex: "1" }}>
              <img
                alt="Stripe"
                style={
                  windowSize > 1150
                    ? { position: "relative", width: "5vw" }
                    : { width: "30%", margin: "20px" }
                }
                src={StripeLogo}
              />
            </div>
          </div>
        </div>
      </div>
      <Advices />

      {/* FULL DIV 4*/}
      <div className="column center" style={{ width: "100%", zIndex: "0", paddingBottom: "0" }}>
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          {/* PAGE DIV 4*/}
          <div style={{ zIndex: "2", width: "80%" }}>
            {/* text */}
            <h2
              style={
                windowSize > 1150
                  ? {
                      fontSize: "30px",
                      textAlign: "left",
                      width: "100%",
                      fontWeight: "800",
                      fontFamily: "Roboto",
                      color: "#585858",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      marginBottom: "0",
                    }
                  : {
                      marginLeft: "0px",
                      fontSize: "25px",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontWeight: "800",
                      color: "#585858",
                    }
              }
            >
              {t("resellerPricing.19")}
            </h2>
          </div>
        </div>
        <button
          style={{ marginTop: "20px" }}
          onClick={() => {
            window.tidioChatApi.show();
            window.tidioChatApi.open();
          }}
          className="button big orange-back"
        >
          {t("contactUs")}
        </button>
      </div>

      <div
        className="column center"
        style={
          windowSize > 1150
            ? { width: "100%", marginTop: "60px" }
            : { paddingTop: "0", width: "100%", marginTop: "40px" }
        }
      >
        <div className="row" style={{ width: "100%", justifyContent: "center" }}>
          <div
            style={
              windowSize > 1150 ? { display: "flex", width: "85%", marginLeft: "5%" } : { width: "100%" }
            }
          >
            <div
              style={
                windowSize > 1150
                  ? { flex: "1", maxWidth: "350px", minWidth: "350px" }
                  : { width: "100%", display: "flex", justifyContent: "center" }
              }
            >
              <h2
                style={
                  windowSize > 1150
                    ? {
                        fontSize: "30px",
                        textAlign: "left",
                        width: "100%",
                        fontWeight: "800",
                        color: "#585858",
                        fontFamily: "Montserrat",
                        lineHeight: "2.5vw",
                      }
                    : {
                        marginLeft: "0px",
                        fontSize: "25px",
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        color: "#585858",
                        width: "90%",
                      }
                }
              >
                <span style={windowSize > 1150 ? {} : { width: "100%", textAlign: "start" }}>
                  {t("resellerPricing.faq.title.1")}
                  <br /> {t("resellerPricing.faq.title.2")}
                </span>
              </h2>
            </div>

            {/* text */}
            <div
              style={
                windowSize > 1150
                  ? {
                      flex: "1",
                      float: "right",
                      zIndex: "3",
                      maxInlineSize: "fit-content",
                      minWidth: "65%",
                      marginLeft: "40px",
                    }
                  : { width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }
              }
            >
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
                defaultActiveKey={[]}
              >
                <Panel className="montserrat-bold" header={t("resellerPricing.faq.1")} key="1">
                  <p className="text-roboto montserrat">{t("resellerPricing.faq.2")} </p>
                </Panel>
                <Panel className="montserrat-bold" header={t("resellerPricing.faq.3")} key="2">
                  <p className="text-roboto montserrat">{t("resellerPricing.faq.4")}</p>
                </Panel>
                <Panel className="montserrat-bold" header={t("resellerPricing.faq.5")} key="3">
                  <p className="text-roboto montserrat">{t("resellerPricing.faq.6")}</p>
                </Panel>
                <Panel className="montserrat-bold" header={t("resellerPricing.faq.7")} key="4">
                  <p className="text-roboto montserrat">{t("resellerPricing.faq.8")}</p>
                </Panel>
                <Panel className="montserrat-bold" header={t("resellerPricing.faq.9")} key="5">
                  <p className="text-roboto montserrat">{t("resellerPricing.faq.10")}</p>
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} location="reseller" />
    </div>
  );
};

export default PricingReseller;
