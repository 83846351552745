import React, { useEffect, useState } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Legend, Bar } from "recharts";
import moment from "moment";

import "./style.scss";

const StepsRepartitionChart = ({ intervals }) => {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (intervals?.length) {
      const interval = intervals[intervals.length - 1];
      setDatas([
        {
          reseller_with_zero_import_products: interval.reseller_with_zero_import_products,
          reseller_with_import_products_and_empty_inventory:
            interval.reseller_with_import_products_and_empty_inventory,
          reseller_with_at_free_limit: interval.reseller_with_at_free_limit,
          total_reseller_subscription: interval.total_reseller_subscription,
          reseller_registrations: interval.reseller_registrations,
        },
      ]);
    }
  }, [intervals]);

  if (!datas.length) {
    return <></>;
  }

  const CustomLabel = (props) => {
    const { x, y, width, height, value, title } = props;
    const percentage = (value / datas[0].reseller_registrations) * 100;

    return (
      <g>
        <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
          ({value})
        </text>
        <text x={x + width / 2} y={y + 25} fill="#666" textAnchor="middle" dy={-6}>
          {title}
        </text>
        <text x={x + width / 2} y={y + 42} fill="#666" textAnchor="middle" dy={-6}>
          {percentage.toFixed(2)}%
        </text>
      </g>
    );
  };

  return (
    <div
      className="card"
      style={{
        width: "40vw",
        margin: "2vw",
        boxShadow: "none",
      }}
    >
      <div style={{ marginBottom: "20px" }} className="row row-wrap width-100 space-between">
        <p style={{ margin: "10px", fontSize: "18px" }} className="title-bold start">
          Étapes : <br />
        </p>
      </div>
      <ResponsiveContainer minHeight="250px" minWidth="300px" width="100%" height="100%">
        <BarChart data={datas} margin={{ top: 20, right: 30, left: 30, bottom: 5 }} barCategoryGap={40}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="reseller_with_zero_import_products"
            fill="#badbff"
            label={<CustomLabel title="0 import" />}
            barCategoryGap="20%"
          />
          <Bar
            dataKey="reseller_with_import_products_and_empty_inventory"
            fill="#abd2fc"
            label={<CustomLabel title="0 inventory" />}
            barCategoryGap="20%"
          />
          <Bar
            dataKey="reseller_with_at_free_limit"
            fill="#99c9fc"
            label={<CustomLabel title="At free limit" />}
            barCategoryGap="20%"
          />
          <Bar
            dataKey="total_reseller_subscription"
            fill="#84c1ff"
            label={<CustomLabel title="Subscribe" />}
            barCategoryGap="20%"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StepsRepartitionChart;
