import { currencyRateServices } from "../services/currencyRate";

function adjustDecimalPart(originalPrice, decimalCount) {
  // Convertir les entrées en nombres à virgule flottante pour être sûr
  const originalPriceFloat = parseFloat(originalPrice);
  const decimalCountFloat = parseFloat(decimalCount);

  // Obtenir la partie entière du prix original
  const integerPart = Math.floor(originalPriceFloat);

  // Ajuster la partie décimale en fonction de decimalCount
  const adjustedPrice = integerPart + decimalCountFloat / 100;

  // Formater le prix ajusté en une chaîne avec deux chiffres après la virgule
  const formattedAdjustedPrice = adjustedPrice.toFixed(2);

  return parseFloat(formattedAdjustedPrice);
}

function hourDifference(date) {
  const currentDate = new Date();
  const diffInMs = currentDate - new Date(date);
  const diffInHours = diffInMs / 1000 / 60 / 60;
  return diffInHours;
}

export const saveDeviceConverter = async () => {
  let deviceConverter = localStorage.getItem("deviceConverter");
  if (deviceConverter) {
    deviceConverter = JSON.parse(deviceConverter);
  }
  const minimumHoursDiffToRefresh = 0.5;
  if (
    !deviceConverter?.refreshDate ||
    deviceConverter === "undefined" ||
    deviceConverter === "null" ||
    hourDifference(deviceConverter.refreshDate) > minimumHoursDiffToRefresh
  ) {
    currencyRateServices.get().then((res) => console.log(res));
    const res = await currencyRateServices.get();
    if (res?.status === 200) {
      const converterRes = await res.json();

      const USDtoEUR = converterRes.data.USD_TO_EUR;
      localStorage.setItem(
        "deviceConverter",
        JSON.stringify({
          USDtoEUR,
          refreshDate: new Date(),
        })
      );
    } else {
      localStorage.setItem(
        "deviceConverter",
        JSON.stringify({
          USDtoEUR: 1,
          refreshDate: new Date(),
        })
      );
    }
  }
};

const retailPrice = ({ user, type, originalPrice, deviceConverter, isFrenchLanguage }) => {
  if (type === "retailPrice" && user?.retailPrice?.custom === true) {
    const multiplier = parseFloat(user?.retailPrice?.multiplier) || 2.5;
    let customRetailPrice = parseFloat(originalPrice) * multiplier;

    if (!customRetailPrice) {
      return null;
    }

    const isEuroConverter = isFrenchLanguage && deviceConverter?.USDtoEUR;

    customRetailPrice = adjustDecimalPart(
      isEuroConverter
        ? parseFloat(customRetailPrice) * parseFloat(deviceConverter?.USDtoEUR)
        : customRetailPrice,
      user?.retailPrice?.cents || 99
    );

    if (isEuroConverter) {
      return `${customRetailPrice.toFixed(2)}€`;
    }

    return `$${customRetailPrice.toFixed(2)}`;
  }

  return null;
};

export const priceConverter = function ({ price, currency, user, type, originalPrice }) {
  const isFrenchLanguage = currency === "EUR";
  let deviceConverter = localStorage.getItem("deviceConverter");
  if (deviceConverter) {
    deviceConverter = JSON.parse(deviceConverter);
  }

  const personalizedRetailPrice = retailPrice({
    user,
    type,
    originalPrice,
    deviceConverter,
    isFrenchLanguage,
  });

  if (personalizedRetailPrice) {
    return personalizedRetailPrice;
  }

  if (!isFrenchLanguage || !deviceConverter?.USDtoEUR) {
    return `$${parseFloat(personalizedRetailPrice || price || 0).toFixed(2)}`;
  }

  return `${(parseFloat(personalizedRetailPrice || price) * parseFloat(deviceConverter?.USDtoEUR)).toFixed(
    2
  )}€`;
};
