import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import IconCheckGreen from "../../../images/icons/check-green.svg";
import IconExternalLInk from "../../../images/icons/External-link.svg";
import IconClick from "../../../images/icons_white/Click.svg";
import IconNetwork from "../../../images/icons_white/Network.svg";
import IconParams from "../../../images/icons_white/Params.svg";

import {routes} from '../../../routes'

const ResellerMore = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="page">
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={windowSize > 1150 ? { paddingLeft: "0px", paddingTop: "0" } : {}}
          className="column-scrolling"
        >
          <div className="column back-gradient">
            <p className="title-white">Crush your competition</p>
            <h1 className="title-white-bold">by selling unique quality products</h1>
            <h1 className="text-white">And one click connect to thousands of worldwide suppliers.</h1>
            <Link to={routes.joinUs(i18n.language).path + "?reseller"} className="button-white-2">
              Register
            </Link>
          </div>
          <div className="column selling-page">
            <div
              className="column"
              style={windowSize > 1150 ? { alignItems: "flex-start", width: "600px" } : { width: "90%" }}
            >
              <h2 className="title-roboto-bold">Are you tired of low quality products ?</h2>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                Low-quality products mean a lot of Paypal and Stripe disputes. It also means more customer
                after-sales service. <br />
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-bold">
                Refunds, emails, reports, bans, angry customers, ...
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                Nobody wants to build a long-term business based on these issues.
              </p>
              <h2 style={{ marginTop: "25px" }} className="title-roboto-bold">
                How long will your business last if you are the same as all your competitors ?
              </h2>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                Same products, same audience, same revenues, same everything...
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                This is what most of dropshipping business look like.
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-bold">
                And this is why less than 1% of them succeed
                <span style={{ marginLeft: "3px", marginRight: "3px" }} className="text-roboto-300">
                  in becoming a
                </span>
                valuable long-term brand.
              </p>
              <h2 style={{ marginTop: "25px" }} className="title-roboto-bold">
                How can you get your customers back if you can’t offer fast shipping and quality packaging ?
              </h2>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                Two weeks shipping ? It is already too much for a lot of customers. Of course, you could sell
                with poor shipping delays and poor packaging. But what in the long term?
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-bold">
                Amazon’s first goal is to offer worldwide shipping within 1 day.
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                What will you do when all of your products will be listed on Amazon, with good packaging, fast
                delivery, and very good guarantees ?
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                If you want to exist on the long term, you need to build a
                <span style={{ marginLeft: "3px", marginRight: "3px" }} className="text-roboto-bold">
                  trusted customer community
                </span>
                that loves buying from you.
              </p>
              <p style={{ marginTop: "25px" }} className="text-roboto-300">
                This is what made Amazon Worldwide e-Commerce Leader thanks to Amazon Prime introduced in
                2006.
              </p>
            </div>
          </div>
          <div className="column selling-page back-black">
            <div
              className="column"
              style={windowSize > 1150 ? { alignItems: "flex-start", width: "600px" } : { width: "90%" }}
            >
              <p className="title-green">Only one way to go :</p>
              <div style={{ alignItems: "flex-start", marginTop: "30px" }} className="row">
                <img className="green-check-icon-selling-page" src={IconCheckGreen} />
                <p className="text-white-200">
                  Sell
                  <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-white-bold">
                    high-quality products.
                  </span>
                </p>
              </div>
              <div style={{ alignItems: "flex-start", marginTop: "20px" }} className="row">
                <img className="green-check-icon-selling-page" src={IconCheckGreen} />
                <p className="text-white-200">
                  Offer
                  <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-white-bold">
                    ridiculously fast shipping times
                  </span>
                  thanks to local suppliers.
                </p>
              </div>
              <div style={{ alignItems: "flex-start", marginTop: "20px" }} className="row">
                <img className="green-check-icon-selling-page" src={IconCheckGreen} />
                <p className="text-white-200">
                  <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-white-bold">
                    Crush your competition
                  </span>
                  by selling what they can’t sell.
                </p>
              </div>
            </div>
            <Link to={routes.joinUs(i18n.language).path + "?reseller"} className="button-white-2">
              Join us now
            </Link>
          </div>
          <div className="column selling-page">
            <div
              className="row"
              style={
                windowSize > 1150 ? { alignItems: "flex-start", width: "600px" } : { flexDirection: "column" }
              }
            >
              <div
                className="column"
                style={windowSize > 1150 ? { width: "30%", marginRight: "5%" } : { width: "100%" }}
              >
                <h3 className="title-roboto-bold">So what is 01supply for ?</h3>
                <a href="https://www.01supply.com/help" className="text-gradient">
                  Documentation <img style={{ height: "10px", width: "auto" }} src={IconExternalLInk} />
                </a>
              </div>
              <div
                className="column"
                style={windowSize > 1150 ? { width: "65%" } : { width: "100%", marginTop: "30px" }}
              >
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-start", height: "120px" }
                      : { height: "150px", alignItems: "flex-start" }
                  }
                  className="row"
                >
                  <div className="img-back-gradient">
                    <img style={{ marginTop: "6px" }} src={IconClick} className="icon-supplier-more" />
                  </div>
                  <div className="column">
                    <p className="title-roboto">One click import</p>
                    <p className="title-roboto-300">Import supplier’s products to your store in one click.</p>
                  </div>
                </div>
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-start", height: "120px" }
                      : { height: "150px", alignItems: "flex-start" }
                  }
                  className="row"
                >
                  <div className="img-back-gradient">
                    <img src={IconParams} className="icon-supplier-more" />
                  </div>
                  <div className="column">
                    <p className="title-roboto">Automated process</p>
                    <p className="title-roboto-300">
                      Orders and tracking are automated so you will have to work less.
                    </p>
                  </div>
                </div>
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-start", height: "120px" }
                      : { height: "150px", alignItems: "flex-start" }
                  }
                  className="row"
                >
                  <div className="img-back-gradient">
                    <img src={IconNetwork} className="icon-supplier-more" />
                  </div>
                  <div className="column">
                    <p className="title-roboto">Supplier network</p>
                    <p className="title-roboto-300">
                      You can connect with more than 1.000 suppliers simultaneously if you want to.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ justifyContent: "center" }} className="row selling-page back-gradient">
            <div
              style={
                windowSize > 1150
                  ? { alignItems: "flex-start", width: "600px", alignItems: "flex-end" }
                  : { width: "90%", flexDirection: "column" }
              }
              className="row"
            >
              <div className="column">
                <p
                  style={
                    windowSize > 1150
                      ? { width: "200px", marginBottom: "20px", marginLeft: "20px" }
                      : { width: "90%", marginBottom: "20px" }
                  }
                  className="title-white-bold"
                >
                  Register now for free
                </p>
                <div
                  style={
                    windowSize > 1150
                      ? { alignItems: "flex-end" }
                      : { flexDirection: "column", alignItems: "flex-start", width: "100%" }
                  }
                  className="row"
                >
                  <ul
                    style={
                      windowSize > 1150
                        ? { marginBottom: "0", marginRight: "150px" }
                        : { marginBottom: "20px" }
                    }
                  >
                    <li className="text-white-200">No credit card required</li>
                    <li className="text-white-200">No paid subscription</li>
                    <li className="text-white-200">Without commitment</li>
                  </ul>
                </div>
              </div>
              <Link
                style={{ marginTop: "0" }}
                to={routes.joinUs(i18n.language).path + "?reseller"}
                className="button-white-2"
              >
                Register now
              </Link>
            </div>
          </div>
          <div className="column selling-page">
            <div
              className="column"
              style={windowSize > 1150 ? { width: "600px" } : { width: "90%", marginLeft: "5%" }}
            >
              <p style={{ marginTop: "30px" }} className="title-roboto-bold">
                Frequently Asked Questions :
              </p>
              <p style={{ fontSize: "18px", marginTop: "30px" }} className="text-roboto-bold">
                Can I customize my packaging ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                Yes !
                <br />
                <br />
                For this, you will need to contact your supplier first.
                <br />
                Then once you have discussed a customer’s packaging, you can remind him during the order
                process thanks to the order message.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                What are the shipping times ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                Shipping times depend on each supplier.
                <br />
                <br />
                Once you are on a product page, just click the “Shipping methods” button on the right. Then
                select the country you want to ship to and the shipping delays will be indicated.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                How do I place orders to suppliers ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                Once you get an order on your linked store, it will automatically appear on your 01supply
                Order page.
                <br />
                <br />
                Then, just click on the order button to place an order. Your customer information will be
                automatically sent to the supplier.
              </p>
              <p style={{ fontSize: "18px", marginTop: "35px" }} className="text-roboto-bold">
                Are my customers automatically notified when their order is sent ?
              </p>
              <p style={{ marginTop: "10px" }} className="text-roboto">
                This depends on your store settings.
                <br />
                <br />
                From our side, we will automatically send tracking information to your store once the order is
                shipped.
              </p>
            </div>
          </div>
          <div
            style={{ marginTop: "45px", justifyContent: "center", width: "100%", marginBottom: "50px" }}
            className="row"
          >
            <Link
              style={{ marginRight: "25px" }}
              className="button orange-back"
              to={routes.joinUs(i18n.language).path + "?reseller"}
            >
              REGISTER NOW
            </Link>
            <Link style={{ marginLeft: "25px" }} className="button-gradient" to="/pricing">
              See pricing
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellerMore;
