import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import { Input, message } from "antd";
import { header } from "../api/header";
import { url } from "../api/url";
import { getImage } from "../utils/GetImage";
import MenuSetting from "../components/menu/MenuSetting";
import Menu from "../components/menu/FirstMenu";
import PhoneInput from "react-phone-input-2";
import MobileMenu from "../components/menu/mobile/FirstMenu";
import MobileHeader from "../components/header/mobile/SimpleHeader";
import RedirectLogin from "../utils/RedirectLogin";
import SelectCountryCode from "../utils/CountryCodeSelector";
import UploadImage from "../components/UploadSimpleImage";

import { Modal } from "../wrappers";

import "react-phone-input-2/lib/material.css";

const YourShop = () => {
  const [user, setUser] = useState({});
  const [isChange, setIsChange] = useState(false);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [newPhone, setNewPhone] = useState("");
  const [editFirstEmail, setEditFirstEmail] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [profilePicture, setProfilePicture] = useState("");
  const [imageUpload, setImageUpload] = useState("");

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateVAT(vat) {
    const re = /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/;
    return re.test(String(vat).toLowerCase());
  }

  const updateUser = async () => {
    let phone = newPhone;
    let newUser = user;
    if (user.company) {
      newUser.company.phone = phone;
    }
    if (user.company && user.company.email && !validateEmail(user.company.email)) {
      message.error(t("messages.wrong.companyEmail"));
    } else if (!validateEmail(user.email)) {
      message.error(t("messages.wrong.personalEmail"));
    } else if (user.company && user.company.vat && !validateVAT(user.company.vat)) {
      message.error(t("messages.wrong.vat"));
    } else if (phone && phone.split("").length > 0 && phone.split("").length < 8) {
      message.error(t("messages.wrong.phone"));
    } else {
      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
        }),
      });
      if (res.status === 200) {
        message.success(t("messages.successfullyUpdated"));
        setIsChange(false);
      } else if (res.status === 405) {
        message.error(t("messages.emailAldreadyExist"));
      }
    }
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
    setProfilePicture(await getImage(resJson.data?.profile_picture));
    if (resJson.data.company) {
      setNewPhone(resJson.data.company.phone);
    }
  };

  useEffect(() => {
    updateProfilePicture();
  }, [imageUpload]);

  const updateProfilePicture = async () => {
    if (imageUpload.base64) {
      const res = await fetch(`${url}/user/picture/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          picture: imageUpload,
          lastImageId: user.profile_picture,
          imageType: "profile_picture",
        }),
      });
      if (res.status === 200) {
        message.success(t("messages.successfullyUpdated"));
        setImageUpload({});
        const resJson = await res.json();
        setProfilePicture(await getImage(resJson.imageId));
      }
    }
  };

  return (
    <div className="page">
      <Prompt
        when={isChange}
        message="You have not saved your changes, do you really want to leave the page?"
      />
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "0px",
                }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "10px",
                  padding: "20px",
                }
          }
          className="column-scrolling your-information"
        >
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "30px",
              alignItems: "flex-start",
            }}
          >
            {windowSize > 1150 ? (
              <>
                <MenuSetting user={user} />
                <div className="header">
                  <div
                    className="row"
                    style={
                      windowSize > 1150
                        ? { width: "100%", marginBottom: "-30px", height: "30px" }
                        : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                    }
                  >
                    <h4 className="title-bold montserrat">{t("yourStore")}</h4>
                  </div>
                  <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
                </div>
              </>
            ) : (
              <MobileHeader nameHeader={"SETTINGS"} location="YOUR INFORMATIONS" />
            )}
            {editFirstEmail && (
              <Modal
                className="modal-no-ok"
                title={t("editPersonalEmail")}
                visible={true}
                onOk={() => {
                  setEditFirstEmail(false);
                }}
                onCancel={() => {
                  setEditFirstEmail(false);
                }}
              >
                <Input
                  maxLength={150}
                  onPressEnter={() => {
                    setEditFirstEmail(false);
                  }}
                  defaultValue={user.email}
                  onChange={(e) => {
                    let stockUser = user;
                    stockUser.email = e.target.value;
                    setUser(stockUser);
                    if (!isChange) setIsChange(true);
                  }}
                  autoFocus
                />
              </Modal>
            )}
            <div
              id="demo-step-5"
              className="column"
              style={
                windowSize > 1320
                  ? { width: "calc(100% - 350px)", marginLeft: "30px" }
                  : { width: "100%", marginTop: "30px" }
              }
            >
              {/* DETAILS */}
              <div
                id="content"
                style={{ marginLeft: "0", width: "95%", padding: "15px 20px 30px 20px", marginTop: "0px" }}
                className="column card"
              >
                <h4 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                  Details
                </h4>
                <div style={{ alignItems: "flex-start", marginBottom: "30px" }} className="row-wrap">
                  <div key={profilePicture} style={{ display: profilePicture ? "flex" : "none" }}>
                    <UploadImage image={profilePicture} setImage={setImageUpload} />
                  </div>
                  {!profilePicture && !imageUpload && (
                    <button
                      style={{ width: "150px", marginLeft: profilePicture ? "20px" : "0" }}
                      className="button-orange"
                      onClick={() => document.getElementById("upload-profile-image").click()}
                    >
                      {t("upload")} photo
                    </button>
                  )}
                </div>
                <div
                  style={{ marginBottom: "25px", width: "100%", alignItems: "flex-start" }}
                  className="row-wrap"
                >
                  <div
                    style={{
                      width: windowSize > 1150 ? "42.5%" : "100%",
                      marginRight: windowSize > 1150 ? "5%" : "0",
                    }}
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("firstName")}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="little-input border-red"
                      value={user.firstName}
                      placeholder={t("firstName")}
                      onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                        if (!isChange) setIsChange(true);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: windowSize > 1150 ? "42.5%" : "100%",
                      marginTop: windowSize <= 1150 ? "10px" : "0",
                    }}
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("lastName")}
                    </p>
                    <Input
                      className="little-input border-red"
                      maxLength={40}
                      value={user && user.lastName}
                      style={{ width: "100%" }}
                      placeholder="Name"
                      onChange={(e) => {
                        setUser({ ...user, lastName: e.target.value });
                        if (!isChange) setIsChange(true);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* CONTACT */}
              <div
                style={{ marginLeft: "0", width: "95%", padding: "15px 20px 30px 20px", marginTop: "30px" }}
                className="column card"
              >
                <h4 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                  Contact
                </h4>
                <p style={{ marginBottom: "5px" }} className="text-bold">
                  {t("publicEmail")}
                </p>
                <Input
                  maxLength={150}
                  value={user && user.company && user.company.email ? user.company.email : user.email}
                  placeholder="Shop contact email"
                  onChange={(e) => {
                    setUser({ ...user, company: { ...user.company, email: e.target.value } });
                    if (!isChange) setIsChange(true);
                  }}
                  style={{ width: "90%" }}
                  className="border-red"
                />
                <p
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    marginBottom: "10px",
                    marginTop: "5px",
                  }}
                  className="title-300"
                >
                  {typeAccount === "seller" ? t("forSuppliersToContactYou") : t("forResellersToContactYou")}
                </p>
              </div>

              {/* LEGAL */}
              <div
                style={{ marginLeft: "0", width: "95%", padding: "15px 20px 30px 20px", marginTop: "30px" }}
                className="column card"
              >
                <h4 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                  Legal
                </h4>
                <div style={{ marginBottom: "25px", width: "100%" }} className="row-wrap">
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "10px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("legalNameOfCompany")}
                    </p>
                    <Input
                      maxLength={100}
                      value={user && user.company && user.company.name}
                      style={{ width: "100%" }}
                      placeholder={t("companyName")}
                      onChange={(e) => {
                        setUser({ ...user, company: { ...user.company, name: e.target.value } });
                        if (!isChange) setIsChange(true);
                      }}
                      className="border-red"
                    />
                  </div>
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("phone")}
                    </p>
                    <PhoneInput
                      style={!newPhone ? { borderBottom: "2px solid red" } : {}}
                      className="border-red"
                      placeholder={t("phoneNumber")}
                      value={newPhone || user?.company?.phone}
                      onChange={(value) => {
                        setNewPhone(value);
                        if (!isChange) setIsChange(true);
                      }}
                    />
                  </div>
                </div>
                <div style={{ marginBottom: "25px", width: "100%" }} className="row-wrap">
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "30%", marginRight: "30px" }
                        : { width: "100%", marginTop: "10px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("vatNumber")} ({t("optional")})
                    </p>
                    <Input
                      maxLength={100}
                      placeholder=""
                      onChange={(e) => {
                        setUser({ ...user, company: { ...user.company, vat: e.target.value } });
                        if (!isChange) setIsChange(true);
                      }}
                      value={user && user.company && user.company.vat}
                      style={{ width: "100%" }}
                    />
                  </div>
                  {user.type === "seller" ? (
                    <>
                      <div
                        style={
                          windowSize > 1150
                            ? { width: "30%", marginRight: "30px" }
                            : { width: "100%", marginTop: "10px" }
                        }
                        className="column"
                      >
                        <p style={{ marginBottom: "5px" }} className="text-bold">
                          {t("iossNumber")} ({t("optional")})
                        </p>
                        <Input
                          maxLength={100}
                          placeholder=""
                          onChange={(e) => {
                            setUser({ ...user, company: { ...user.company, IOSS: e.target.value } });
                            if (!isChange) setIsChange(true);
                          }}
                          value={user.company?.IOSS}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div
                        style={windowSize > 1150 ? { width: "30%" } : { width: "100%", marginTop: "10px" }}
                        className="column"
                      >
                        <p style={{ marginBottom: "5px", width: "100%" }} className="text-bold">
                          {t("eoriNumber")} ({t("optional")})
                        </p>
                        <Input
                          maxLength={100}
                          placeholder=""
                          onChange={(e) => {
                            setUser({ ...user, company: { ...user.company, EORI: e.target.value } });
                            if (!isChange) setIsChange(true);
                          }}
                          value={user.company?.EORI}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </>
                  ) : (
                    user.type === "creater" && (
                      <div
                        style={windowSize > 1150 ? { width: "45%" } : { width: "100%", marginTop: "10px" }}
                        className="column"
                      >
                        <p style={{ marginBottom: "5px" }} className="text-bold">
                          {t("companyRegistrationNumber")}
                        </p>
                        <Input
                          maxLength={100}
                          placeholder=""
                          onChange={(e) => {
                            setUser({
                              ...user,
                              company: { ...user.company, registration_number: e.target.value },
                            });
                            if (!isChange) setIsChange(true);
                          }}
                          value={user.company?.registration_number}
                          style={{ width: "100%" }}
                        />
                      </div>
                    )
                  )}
                </div>

                <div
                  style={{ width: "100%", marginLeft: "0", marginTop: "30px", marginBottom: "30px" }}
                  className="divider-horizontal"
                />

                {/* ADDRESS */}

                <h4 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                  {t("companyAddress")}
                </h4>

                <div style={{ marginBottom: "25px", width: "100%" }} className="row-wrap">
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("street")}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="border-red"
                      onChange={(e) => {
                        if (!isChange) setIsChange(true);
                        setUser({ ...user, company: { ...user.company, address: e.target.value } });
                      }}
                      placeholder={t("street")}
                      value={user.company?.address}
                    />
                  </div>
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("postalCode")}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="border-red"
                      onChange={(e) => {
                        if (!isChange) setIsChange(true);
                        setUser({ ...user, company: { ...user.company, postal_code: e.target.value } });
                      }}
                      placeholder={t("postalCode")}
                      value={user && user.company && user.company.postal_code}
                    />
                  </div>
                </div>

                <div style={{ marginBottom: "25px", width: "100%" }} className="row-wrap">
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("city")}
                    </p>
                    <Input
                      style={{ width: "100%" }}
                      className="border-red"
                      onChange={(e) => {
                        if (!isChange) setIsChange(true);
                        setUser({ ...user, company: { ...user.company, city: e.target.value } });
                      }}
                      placeholder={t("city")}
                      value={user && user.company && user.company.city}
                    />
                  </div>
                  <div
                    style={
                      windowSize > 1150
                        ? { width: "42.5%", marginRight: "5%" }
                        : { width: "100%", marginTop: "20px" }
                    }
                    className="column"
                  >
                    <p style={{ marginBottom: "5px" }} className="text-bold">
                      {t("country")}
                    </p>
                    <SelectCountryCode
                      setValue={(value) => {
                        if (!isChange) setIsChange(true);
                        setUser({ ...user, company: { ...user.company, country_code: value } });
                      }}
                      value={user && user.company?.country_code}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isChange && (
            <div className="button-absolute orange-back" onClick={updateUser}>
              {t("save")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default YourShop;
