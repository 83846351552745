import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { resellerCoachingPayment } from "../../services/resellerCoachingPayment";

import Load from "../../utils/Load";

import securePaymentImg from "../../images/illustrations/secure_payment.svg";
import { message } from "antd";
import Messages from "../chat/Messages";

const StripePayment = ({
  email,
  coaching,
  setIsCoachingPaid,
  isBadEmail,
  setIsBadEmail,
  shopUrl,
  setIsBadStoreUrl,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(63, 63, 63)",
        color: "rgb(63, 63, 63)",
        fontWeight: 600,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "rgb(63, 63, 63)" },
        "::placeholder": { color: "rgb(63, 63, 63)" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  function createPaymentMethod(cardElement, customerId, priceId) {
    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((resultCreatePayment) => {
        if (resultCreatePayment.error) {
          setIsLoading(false);
        } else {
          resellerCoachingPayment
            .validPayment({
              paymentMethodId: resultCreatePayment.paymentMethod.id,
              customerId,
              priceId,
              coaching,
              email,
            })
            .then((res) => (res.status === 200 || res.status === 202 ? res.json() : undefined))
            .then(async (res) => {
              if (res) {
                if (res.client_secret) {
                  let cardConfig = elements.getElement(CardElement);
                  const result = await stripe.confirmCardPayment(res.client_secret, {
                    payment_method: {
                      card: cardConfig,
                    },
                  });
                  if (!result.error) {
                    resellerCoachingPayment
                      .validOneTimePayment({
                        stripePayment: result.paymentIntent,
                        email,
                        coaching,
                      })
                      .then((res) => {
                        setIsLoading(false);
                        if (res.status === 200) {
                          setIsCoachingPaid(true);
                          message.success("Payment successfull");
                        } else {
                          message.error("Error with payment");
                        }
                      });
                  }
                } else {
                  setIsCoachingPaid(true);
                  message.success("Payment successfull");
                  setIsLoading(false);
                }
              }
            });
        }
      });
  }

  const createPayment = async () => {
    if (isBadEmail || !email) {
      message.error(t("messages.needFill.email"));
      setIsBadEmail(true);
    } else if (!shopUrl) {
      message.error(t("messages.needFill.storeUrl"));
      setIsBadStoreUrl(true);
    } else {
      setIsLoading(true);
      resellerCoachingPayment
        .createCustomer({ coaching, email })
        .then((res) => (res.status === 200 ? res.json() : undefined))
        .then((res) => {
          if (res) {
            createPaymentMethod(elements.getElement(CardElement), res.customer.id, coaching.price_id);
          }
        });
    }
  };

  return (
    <>
      {isLoading && <Load />}
      <div className="input-pricing-course-payment">
        <CardElement options={CARD_OPTIONS} />
      </div>
      <img
        style={{ marginTop: "10px" }}
        alt="secure payment"
        src={securePaymentImg}
        className="payment-button"
      />
      <button onClick={createPayment} className="button orange-back payment-button pointer">
        {t("placeOrder")}
      </button>
      <p className="grey" style={{ marginTop: "10px" }}>
        {coaching.name === "starter" ? t("paymentPage.1") : t("paymentPage.2")}
      </p>
    </>
  );
};

export default StripePayment;
