import { url } from "../api/url";
import { header } from "../api/header";

export const adminServices = {
  banUser: async (
    { userId, isBanned },
    token = localStorage.getItem("tokenCreatosell"),
    adminId = localStorage.getItem("idAccountCreatosell")
  ) => {
    return await fetch(`${url}/admin/ban-user/${adminId}/${userId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        isBanned,
      }),
    });
  },
  findCollabMatching: async (
    token = localStorage.getItem("tokenCreatosell"),
    adminId = localStorage.getItem("idAccountCreatosell")
  ) => {
    return await fetch(`${url}/admin/collab-matching/${adminId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  assignThematics: async (
    { userId, thematic },
    token = localStorage.getItem("tokenCreatosell"),
    adminId = localStorage.getItem("idAccountCreatosell")
  ) => {
    return await fetch(`${url}/admin/assign-thematic/${adminId}/${userId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        thematic,
      }),
    });
  },
  upRankProduct: async (
    { productId },
    token = localStorage.getItem("tokenCreatosell"),
    adminId = localStorage.getItem("idAccountCreatosell")
  ) => {
    return await fetch(`${url}/admin/up-rank-product/${adminId}/${productId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
  updateProduct: async (
    { productId, newProduct },
    token = localStorage.getItem("tokenCreatosell"),
    adminId = localStorage.getItem("idAccountCreatosell")
  ) => {
    return await fetch(`${url}/admin/update-product/${adminId}/${productId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        product: newProduct,
      }),
    });
  },
  exeMonitoring: async (
    token = localStorage.getItem("tokenCreatosell"),
    adminId = localStorage.getItem("idAccountCreatosell")
  ) => {
    return await fetch(`${url}/admin/exe-monitoring/${adminId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({}),
    });
  },
};
