import React, { useEffect } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

import backIcon from "../images/icons/orange_back.png";

const CustomModal = ({ visible, onCancel, ...props }) => {
  const history = useHistory();

  const windowSize = window.innerWidth;

  useEffect(() => {
    if (visible) {
      // Une méthode pour "bloquer" temporairement la navigation
      const unblock = history.block((location, action) => {
        if (action === "POP") {
          onCancel && onCancel();
          return false; // Bloque la navigation
        }
        return true; // Autorise les autres actions de navigation
      });

      // Quand la modal est fermée ou que le composant est démonté, nous "débloquons" la navigation
      return unblock;
    }
  }, [visible, onCancel, history]);

  const customCloseIcon = <img className="modal-close" src={backIcon} alt="back icon" />;

  return (
    <Modal
      closeIcon={windowSize <= 1150 && customCloseIcon}
      visible={visible}
      onCancel={onCancel}
      {...props}
    />
  );
};

export default CustomModal;
