import React from "react";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

const YearlySwitch = ({ isYearly, setIsYearly, windowSize }) => {
  const { t } = useTranslation();


  return (
    <div
      style={{ marginBottom: "30px", jusifyContent: "center", alignItems: "center" }}
      className={windowSize <= 1150 ? "column width-100" : "row width-100 center"}
    >
      <p className={!isYearly && "text-bold"}>{t("mensualPayment")}</p>
      <Switch
        className="ant-switch-blue"
        style={{ margin: windowSize <= 1150 ? "10px" : "0 15px" }}
        checked={isYearly}
        onChange={(checked) => setIsYearly(checked)}
      />
      <p className={isYearly && "text-bold"}>{t("yearlyPayment")}</p>
    </div>
  );
};

export default YearlySwitch;
