import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input, Button } from "antd";

import { Modal } from "../../wrappers";
import { sendGridEmail } from "../../utils/SendGridEmail";

import EcoLabelIcon from "../../images/icons/eco.svg";
import HandmadeIcon from "../../images/icons/handmade.svg";

const CheckLabel = ({ users, updateUser, token }) => {
  const [openModal, setOpenModal] = useState(false);
  const [indexValidate, setIndexValidate] = useState([]);

  return (
    <div>
      <button style={{ marginBottom: "20px" }} onClick={() => setOpenModal(true)} className="button orange-back">
        MANAGE LABEL VERIFICATIONS
      </button>

      {openModal && (
        <Modal
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          footer={[
            <Button key="back" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <div className="column" style={{ width: "100%", alignItems: "center" }}>
            {users &&
              users.map((user, index) => {
                if (user.user && user.user.about)
                  return (
                    <>
                      {user.user.about.eco_label &&
                        user.user.about.eco_label.verified === false &&
                        !indexValidate.includes(`eco${index}`) && (
                          <div
                            className="column"
                            style={{
                              width: "100%",
                              border: "1px solid rgb(120, 120, 120)",
                              borderRadius: "6px",
                              padding: "15px",
                              marginBottom: "30px",
                            }}
                          >
                            <div style={{ marginBottom: "30px" }} className="row">
                              <img
                                src={EcoLabelIcon}
                                style={{ height: "50px", width: "auto", marginRight: "25px" }}
                              />
                              <div className="column">
                                <p className="text-roboto">Email: {user.user.email}</p>
                                <Link
                                  to={`/shop/${user.user.shop && user.user.shop.name}`}
                                  className="text-roboto underline"
                                >
                                  Link to shop :{" "}
                                  {(user.user.shop && user.user.shop.name) ||
                                    "nom de la boutique non renseignée"}
                                </Link>
                              </div>
                            </div>

                            <p className="title bold start">
                              What is the raw material commonly used for your creations?
                            </p>
                            <p className="text-300">Where does it come from?</p>
                            <p className="text-300">Why is it ecological?</p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.eco_label.question1}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              How do you manage your post-design waste?
                            </p>
                            <p className="text-300">How is this management ecological and sustainable?</p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.eco_label.question2}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              How do you make your creations?
                            </p>
                            <p className="text-300">
                              Does your design method consume a lot of energy? (Why?)
                            </p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.eco_label.question3}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              What is the estimated lifespan for your creations?
                            </p>
                            <p className="text-300">
                              How long can you guarantee your creations? Are they repairable?
                            </p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.eco_label.question4}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              What type of packaging do you use when sending product?
                            </p>
                            <p className="text-300">Are these packaging eco-designed?</p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.eco_label.question5}
                              style={{ marginTop: "10px" }}
                            />

                            <div className="row">
                              <button
                                style={{ marginTop: "20px" }}
                                onClick={async () => {
                                  updateUser(
                                    {
                                      user: {
                                        about: {
                                          ...user.user.about,
                                          eco_label: { ...user.user.about.eco_label, verified: true },
                                        },
                                      },
                                    },
                                    user.user
                                  );
                                  sendGridEmail(
                                    "d-1671d536c97644e88062541c0b666a26",
                                    user.user.email,
                                    {},
                                    token
                                  );
                                  setIndexValidate([...indexValidate, `eco${index}`]);
                                }}
                                className="button orange-back"
                              >
                                Valid eco label !
                              </button>
                              <button
                                style={{ marginTop: "20px", marginLeft: "20px" }}
                                onClick={async () => {
                                  updateUser(
                                    {
                                      user: {
                                        about: {
                                          ...user.user.about,
                                          eco_label: { ...user.user.about.eco_label, verified: undefined },
                                        },
                                      },
                                    },
                                    user.user
                                  );
                                  sendGridEmail(
                                    "d-54fd1e1e4c924a158ced07927a9f307e",
                                    user.user.email,
                                    {},
                                    token
                                  );
                                  setIndexValidate([...indexValidate, `eco${index}`]);
                                }}
                                className="button orange-back"
                              >
                                Reject !
                              </button>
                            </div>
                          </div>
                        )}

                      {user.user.about.handmade_label &&
                        user.user.about.handmade_label.verified === false &&
                        !indexValidate.includes(`handmade${index}`) && (
                          <div
                            className="column"
                            style={{
                              width: "100%",
                              border: "1px solid rgb(120, 120, 120)",
                              borderRadius: "6px",
                              padding: "15px",
                              marginBottom: "30px",
                            }}
                          >
                            <div style={{ marginBottom: "30px" }} className="row">
                              <img
                                src={HandmadeIcon}
                                style={{ height: "50px", width: "auto", marginRight: "25px" }}
                              />
                              <div className="column">
                                <p className="text-roboto">Email: {user.user.email}</p>
                                <Link
                                  to={`/shop/${user.user.shop && user.user.shop.name}`}
                                  className="text-roboto underline"
                                >
                                  Link to shop :{" "}
                                  {(user.user.shop && user.user.shop.name) ||
                                    "nom de la boutique non renseignée"}
                                </Link>
                              </div>
                            </div>

                            <p className="title bold start">How do you design your products?</p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.handmade_label.question1}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              How do you get your creative ideas?
                            </p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.handmade_label.question2}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              How much time do you spend on each creation on average?
                            </p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.handmade_label.question3}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              Why is each of your creations unique?
                            </p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.handmade_label.question4}
                              style={{ marginTop: "10px" }}
                            />

                            <p style={{ marginTop: "30px" }} className="title bold start">
                              Why are your creations of quality and last over time?
                            </p>
                            <Input.TextArea
                              className="modal-textarea"
                              value={user.user.about.handmade_label.question5}
                              style={{ marginTop: "10px" }}
                            />

                            <div className="row">
                              <button
                                style={{ marginTop: "20px" }}
                                onClick={async () => {
                                  updateUser(
                                    {
                                      user: {
                                        about: {
                                          ...user.user.about,
                                          handmade_label: {
                                            ...user.user.about.handmade_label,
                                            verified: true,
                                          },
                                        },
                                      },
                                    },
                                    user.user
                                  );
                                  sendGridEmail(
                                    "d-70e77d773b2c4eadbf00dc09b01f0673",
                                    user.user.email,
                                    {},
                                    token
                                  );
                                  setIndexValidate([...indexValidate, `handmade${index}`]);
                                }}
                                className="button orange-back"
                              >
                                Valid handmade label !
                              </button>
                              <button
                                style={{ marginTop: "20px", marginLeft: "20px" }}
                                onClick={async () => {
                                  updateUser(
                                    {
                                      user: {
                                        about: {
                                          ...user.user.about,
                                          handmade_label: {
                                            ...user.user.about.eco_label,
                                            verified: undefined,
                                          },
                                        },
                                      },
                                    },
                                    user.user
                                  );
                                  sendGridEmail(
                                    "d-ccbf6e7884af4f8fa11c8e3801dc4759",
                                    user.user.email,
                                    {},
                                    token
                                  );
                                  setIndexValidate([...indexValidate, `handmade${index}`]);
                                }}
                                className="button orange-back"
                              >
                                Reject !
                              </button>
                            </div>
                          </div>
                        )}
                    </>
                  );
              })}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CheckLabel;
