import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { header } from "../../../api/header";
import { url } from "../../../api/url";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import iconCheck from "../../../images/icons/Check.svg";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";

const Plan = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [demoIndex, setDemoIndex] = useState(0);
  const [demo, setDemo] = useState([
    {
      target: "#demo-step-8",
      content: (
        <h2>
          Here you can see your current plan. The more diverse products you add, the less fees you have.{" "}
        </h2>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#demo-step-9",
      content: <h2>Go to inventory page.</h2>,
      disableBeacon: true,
      placement: "right",
      spotlightClicks: true,
    },
  ]);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  const updateUserDemo = async () => {
    localStorage.setItem("demoPlansActivate", false);
    const resConnect = await fetch(`${url}/user/demo/${idAccount}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        update: {
          "demo_done.plans": true,
        },
      }),
    });
  };

  return (
    <div className="page">
      <RedirectLogin />
      {user && user.email && (
        <div className="page-start">
          {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
          <div
            style={
              windowSize > 1150
                ? { alignItems: "center", paddingTop: "110px", paddingBottom: "150px" }
                : { alignItems: "center", paddingTop: "30px", paddingBottom: "150px" }
            }
            className="column-scrolling"
          >
            {windowSize > 1150 ? (
              <div className="header">
                <div
                  className="row"
                  style={
                    windowSize > 1150
                      ? { width: "100%", marginBottom: "-30px", height: "30px" }
                      : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                  }
                >
                  <h1 className="title-bold montserrat">{t('plans').toUpperCase()}</h1>
                </div>
                <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
              </div>
            ) : (
              <MobileHeader nameHeader={t('plans').toUpperCase()} location="YOUR SHOP" />
            )}

            <div
              className="row-wrap"
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              {windowSize > 1150 && <MenuSetting user={user} location={"your-shop-supplier"} />}

              <div
                className="column"
                style={
                  windowSize > 1535
                    ? { width: "calc(100% - 350px)", marginLeft: "30px" }
                    : { width: "100%", marginTop: "30px" }
                }
              >
                {user.products_id.length < 150 && (
                  <h2
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      fontSize: "25px",
                      marginTop: windowSize > 1535 ? "" : "30px",
                    }}
                    className="title-300 center"
                  >
                    {t("supplierPlan.1")}
                    <span className="text-bold">
                      {" "}
                      {user.products_id.length < 50
                        ? " " + 50 - user.products_id.length + " "
                        : " " + 150 - user.products_id.length + " "}
                    </span>
                    {t("supplierPlan.2")}
                  </h2>
                )}

                {/* NO FEES PORMOTION */}
                {user?.plan?.promotion?.expiration && (
                  <div
                    className="text-bold montserrat white center"
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      marginTop: "30px",
                      padding: "8px",
                      borderRadius: "8px",
                      background: "#88e888",
                    }}
                  >
                    {t("supplierPlan.3")} {moment(user?.plan?.promotion?.expiration).format("DD-MM-YYYY")}
                  </div>
                )}

                <div
                  id="content"
                  className="row"
                  style={{ marginTop: "50px", width: "100%", flexWrap: "wrap", justifyContent: "center" }}
                >
                  <div
                    style={{
                      marginRight: `${windowSize > 1150 ? "5%" : "0"}`,
                      width: "250px",
                      alignItems: "center",
                      padding: "30px",
                      borderRadius: "20px",
                      position: "relative",
                    }}
                    className="card"
                  >
                    <h2 className="title-bold title-bold">{t("supplierPlan.4")}</h2>
                    <p className="title-300 center">{t("supplierPlan.5")}</p>
                    <p style={{ marginTop: "20px" }} className="title-bold">
                      $0
                      <span style={{ marginLeft: "10px" }} className="title-300">
                        /mo
                      </span>
                    </p>
                    {user.products_id.length < 50 ? (
                      <button
                        style={{ lineHeight: "30px", marginTop: "20px", marginBottom: "25px", width: "80%" }}
                        className="button-disable-grey"
                      >
                        {t("supplierPlan.6")}
                      </button>
                    ) : (
                      <p
                        style={{
                          height: "50px",
                          marginTop: "20px",
                          marginBottom: "25px",
                          width: "80%",
                          textAlign: "center",
                        }}
                        className="text-bold grey"
                      >
                        {t("supplierPlan.7")}
                      </p>
                    )}
                    <div style={{ width: "100%" }} className="column">
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          1 - 49 {t("products")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.8")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.9")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.10")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: `${windowSize > 1150 ? "5%" : "0"}`,
                      width: "250px",
                      alignItems: "center",
                      padding: "30px",
                      borderRadius: "20px",
                      position: "relative",
                    }}
                    className="card"
                  >
                    {user.products_id.length < 50 && (
                      <p className="plan-top-text-fixed">
                        {50 - user.products_id.length} {t("supplierPlan.11")}
                      </p>
                    )}
                    <h2 className="title-bold center">{t("supplierPlan.12")}</h2>
                    <p className="title-300 center">{t("supplierPlan.13")}</p>
                    <p style={{ marginTop: "20px" }} className="title-bold">
                      $0
                      <span style={{ marginLeft: "10px" }} className="title-300">
                        /mo
                      </span>
                    </p>
                    {user.products_id.length >= 50 && user.products_id.length < 150 ? (
                      <button
                        style={{ lineHeight: "30px", marginTop: "20px", marginBottom: "25px", width: "80%" }}
                        className="button-disable-grey"
                      >
                        {t("supplierPlan.14")}
                      </button>
                    ) : (
                      <p
                        style={{
                          height: "50px",
                          marginTop: "20px",
                          marginBottom: "25px",
                          width: "80%",
                          textAlign: "center",
                        }}
                        className="text-bold grey"
                      >
                        {user.products_id.length < 50 ? t("supplierPlan.15") : t("supplierPlan.16")}
                      </p>
                    )}
                    <div style={{ width: "100%" }} className="column">
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          50 - 149 {t("products")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.17")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.18")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.19")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: `${windowSize > 1150 ? "3%" : "0"}`,
                      width: "250px",
                      alignItems: "center",
                      padding: "30px",
                      borderRadius: "20px",
                      position: "relative",
                    }}
                    className="card"
                  >
                    {user.products_id.length < 150 && (
                      <p className="plan-top-text-fixed">
                        {150 - user.products_id.length} {t("supplierPlan.11")}
                      </p>
                    )}
                    <h2 className="title-bold center">{t("supplierPlan.20")}</h2>
                    <p className="title-300 center">{t("supplierPlan.21")}</p>
                    <p style={{ marginTop: "20px" }} className="title-bold">
                      $0
                      <span style={{ marginLeft: "10px" }} className="title-300">
                        /mo
                      </span>
                    </p>
                    {user.products_id.length >= 150 ? (
                      <button
                        style={{ lineHeight: "30px", marginTop: "20px", marginBottom: "25px", width: "80%" }}
                        className="button-disable-grey"
                      >
                        {t("supplierPlan.6")}
                      </button>
                    ) : (
                      <p
                        style={{
                          height: "50px",
                          marginTop: "20px",
                          marginBottom: "25px",
                          width: "80%",
                          textAlign: "center",
                        }}
                        className="text-bold grey"
                      >
                        {t("supplierPlan.22")}
                      </p>
                    )}
                    <div style={{ width: "100%" }} className="column">
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          150+ {t("products")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.8")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.23")}
                        </p>
                      </div>
                      <div style={{ marginBottom: "10px" }} className="row">
                        <img src={iconCheck} style={{ height: "15px", width: "auto", marginRight: "15px" }} />
                        <p className="title-300" style={{ fontSize: "13px" }}>
                          {t("supplierPlan.24")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plan;
