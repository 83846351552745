import React, { useState } from "react";
import { Radio, Space } from "antd";
import { t } from "i18next";

import {Modal} from '../../wrappers'

import filterImg from "../../images/icons/Filters.svg";

const ShippingMethods = ({ ordersFilter, setOrdersFilter, style }) => {
  const [modalOn, setModalOn] = useState(false);
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));

  return (
    <>
      <div style={style ? style : {}} onClick={() => setModalOn(true)} className="row pointer">
        <img src={filterImg} style={{ width: "20px", height: "auto", marginRight: "10px" }} />
        <p className="title">{t("filters")}</p>
      </div>
      <Modal
        className="modal-1000"
        onOk={() => {}}
        visible={modalOn}
        onCancel={() => setModalOn(false)}
        footer={[]}
      >
        <div style={{ width: "100%" }} className="column">
          <Radio.Group
            value={ordersFilter ? ordersFilter : "all"}
            onChange={(e) => {
              setOrdersFilter(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={"all"}>{t("all")}</Radio>
              {typeAccount !== "creater" && <Radio value={"to pay"}>{t('components.modal.23')}</Radio>}
              <Radio value={"paid"}>{t('components.modal.24')}</Radio>
              <Radio value={"filled"}>{t('components.modal.25')}</Radio>
            </Space>
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
};

export default ShippingMethods;
