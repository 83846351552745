import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import { Button, message } from "antd";
import { header } from "../../api/header";
import { url } from "../../api/url";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import countryListJson from "../../utils/country_list.json";
import Save from "../../components/Save";
import { createProduct, updateProduct } from "../../services/createEditProduct";
import Load from "../../utils/Load";
import { urlParams } from "../../utils/urlParams";
import ModalDiscard from "../../components/modals/Confirmation";

import NameDescribe from "../../components/supplier/createEditProduct/NameDescribe";
import IsPublish from "../../components/supplier/createEditProduct/IsPublish";
import Shipping from "../../components/supplier/createEditProduct/Shipping";
import Sorting from "../../components/supplier/createEditProduct/Sorting";
import SellingLimit from "../../components/supplier/createEditProduct/SellingLimit";
import Pricing from "../../components/supplier/createEditProduct/Pricing";
import Images from "../../components/supplier/createEditProduct/Images";
import Inventory from "../../components/supplier/createEditProduct/Inventory";
import Variants from "../../components/supplier/createEditProduct/Variants";
import Thematic from "../../components/supplier/createEditProduct/Thematic";

import BackIcon from "../../images/icons/back_arrow.svg";

const CreateEditProduct = () => {
  const [user, setUser] = useState({});
  const [isChange, setIsChange] = useState(false);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [redirect, setRedirect] = useState("");
  const [load, setLoad] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [userShippings, setUserShippings] = useState([]);
  const [modalDiscardOn, setModalDiscardOn] = useState(false);

  const { t } = useTranslation()

  const [product, setProduct] = useState({
    name: "",
    describe: "",
    price: null,
    stock: null,
    photos: [],
    comparedPrice: null,
    origin: "",
    processingTime: "",
    variants: [],
    orders_max: null,
    reseller_max: null,
    maxResellerBool: false,
    maxOrderBool: false,
    shipping: [],
    category: "",
    tag: [],
    optionsVariants: [],
    creater: {
      id: idAccount,
      name: user.shop ? user.shop.name : "",
    },
    variantBool: false,
    is_publish: true,
  });

  const queryUrl = urlParams();

  const isEdit = queryUrl?.id ? true : false;

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (isChange) window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  });

  const factoUserShippings = (userResShippings) => {
    const shippings = userResShippings.map((shipping, index) => ({
      key: index,
      name: shipping.name,
      destinations: index,
      price: shipping.price,
      deliveryTime: shipping.time,
      _id: shipping._id,
    }));
    setUserShippings(shippings);
  };

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) window?.tidioChatApi?.show();

    let countryListRes = [];
    for (let region of countryListJson) {
      if (!region.children) countryListRes.push(region);
      else countryListRes = [...countryListRes, ...region.children];
    }
    setCountryList(countryListRes);

    // GET DEFAULT PRODUCT IF WE UPDATE PRODUCT
    if (queryUrl?.id) {
      fetch(`${url}/product/findOne?id=${queryUrl.id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: idAccount,
        },
      })
        .then((res) => (res.status === 200 ? res.json() : setRedirect("/inventory")))
        .then((resJson) => setProduct(resJson.data));
    }
  }, []);

  useEffect(() => {
    if (!isChange) setIsChange(true);
  }, [product]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
      factoUserShippings(resJson.data.shipping);
      setLoad(false);
    }
  };

  const handleCreateProduct = () => {
    createProduct(
      product,
      (res) => {
        if (res.status === 200) {
          message.success(t('messages.successfullyCreated'));
          setRedirect("/inventory");
        } else {
          message.error(t('message.error'));
          setLoad(false);
        }
      },
      setLoad,
      t
    );
  };

  const handleEditProduct = () => {
    updateProduct(
      product,
      (res) => {
        if (res.status === 200) {
          message.success(t('messages.successfullyUpdated'));
          setRedirect("/inventory");
        } else {
          message.error(t('messages.error'));
          setLoad(false);
        }
      },
      setLoad,
      t
    );
  };

  const discardFunction = (props) => (
    <Button onClick={(e) => setRedirect("/inventory")} style={{ backgroundColor: "#ff5858", color: "white" }}>
      Discard changes
    </Button>
  );

  return (
    <div className="page">
      {load && <Load />}
      {redirect && <Redirect to={redirect} />}
      <ModalDiscard
        text={t('createEditProduct.1')}
        modalOn={modalDiscardOn}
        setModalOn={setModalDiscardOn}
        CancelButton={discardFunction}
      />
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  paddingTop: "100px",
                }
              : {
                  padding: "20px",
                  paddingBottom: "200px",
                }
          }
          className="column-scrolling"
        >
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              marginBottom: windowSize > 1150 && "50px",
              height: "80px",
            }}
            className="header"
          >
            <h1 className="title-bold montserrat grey">
              <Link to="/inventory">
                <img src={BackIcon} style={{ height: "30px", marginRight: "25px" }} className="pointer" />
              </Link>
              {isEdit ?  t('edit').toUpperCase()+": " + product.name : t('createEditProduct.2')}
            </h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>

          <div className={windowSize > 1150 ? "row width-100 start" : "column"}>
            <div
              style={windowSize > 1150 ? { width: "60%", marginRight: "30px" } : { width: "100%" }}
              className="column"
            >
              {/* NAME & DESCRIBE */}
              <NameDescribe setProduct={setProduct} product={product} />

              {/* IMAGES */}
              <Images setProduct={setProduct} product={product} />

              {/* PRICING */}
              <Pricing setProduct={setProduct} product={product} />

              {/* INVENTORY */}
              <Inventory setProduct={setProduct} product={product} />

              {/* INVENTORY */}
              <Variants setProduct={setProduct} product={product} />

              {/* THEMATIC */}
              <Thematic setProduct={setProduct} product={product} />
            </div>

            <div style={{ width: windowSize > 1150 ? "25%" : "100%" }} className="column">
              {/* IS PUBLISH */}
              <IsPublish setProduct={setProduct} product={product} />

              {/* SHIPPING */}
              <Shipping
                userShippings={userShippings}
                countryList={countryList}
                setProduct={setProduct}
                product={product}
                user={user}
                getUser={getUser}
                setLoad={setLoad}
                windowSize={windowSize}
              />

              {/* SORTING */}
              <Sorting setProduct={setProduct} product={product} />

              {/* SELLING LIMIT */}
              <SellingLimit windowSize={windowSize} setProduct={setProduct} product={product} />
            </div>
          </div>
        </div>
      </div>
      {isChange && (
        <Save
          callbackSave={() => {
            setLoad(true);
            if (isEdit) {
              handleEditProduct();
            } else handleCreateProduct();
          }}
          callbackDiscard={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setModalDiscardOn(true);
          }}
          discardUrl=""
        />
      )}
    </div>
  );
};

export default CreateEditProduct;
