import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import MenuNavbar from "../mobile/FirstMenu";
import logo from "../../../images/logo.svg";
import { routes } from "../../../routes";

import SecondaryHomeDropdown from "./SecondaryHomeDropDown";

const NavBar = ({ windowSize, isFixed }) => {
  const [location] = useState(window.location.pathname.replace("/", ""));
  const [isStickyNav, setIsStickyNav] = useState(isFixed || false);

  const { t, i18n } = useTranslation();

  window.onscroll = function (e) {
    if (!isFixed) {
      if (window.scrollY > 145 && windowSize > 1150) {
        setIsStickyNav(true);
      } else if (window.scrollY > 145) {
        setIsStickyNav(true);
      } else setIsStickyNav(false);
    }
  };

  return windowSize > 1150 ? (
    <div
      className={isStickyNav ? "column center navbar sticky" : "column center navbar"}
      style={{ width: "100vw", justifyContent: "space-between" }}
    >
      <div className="row" style={{ width: "100%", justifyContent: "center" }}>
        {/* PAGE DIV 1*/}
        <div className="row" style={{ display: "flex", width: "100%", height: "58px", alignItems: "center" }}>
          {/* logo */}
          <div style={{ flex: "1", display: "flex", float: "left", height: "58px" }}>
            <a href="https://01supply.com">
              <img
                className="logo-menu"
                alt="logo 01supply"
                style={{
                  width: "200px",
                  height: "auto",
                  marginLeft: "0",
                  justifyContent: "left",
                }}
                src={logo}
              />
            </a>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              float: "right",
              height: "3vw",
              justifyContent: "flex-end",
              placeContent: "space-between",
              alignItems: "center",
            }}
          >
            <a
              style={{
                fontSize: "16px",
                minWidth: "100px",
                textAlign: "center",
              }}
              href="https://01supply.com"
              className={location === "" ? "title-600 black" : "title-300 black"}
            >
              {t("components.menu.38")}
            </a>
            <Link
              style={{
                fontSize: "16px",
                minWidth: "110px",
                textAlign: "center",
              }}
              to={
                location === routes.resellerPricing(i18n.language).path
                  ? routes.supplierPricing(i18n.language).path
                  : routes.resellerPricing(i18n.language).path
              }
              className={
                location === "supplier-pricing" || location === "reseller-pricing"
                  ? "title-600 black"
                  : "title-300 black"
              }
            >
              {t("pricing")}
            </Link>
            <SecondaryHomeDropdown />
            <Link
              style={{
                fontSize: "16px",
                minWidth: "100px",
                textAlign: "center",
                marginLeft: "10px",
              }}
              to={routes.marketplaceHome(i18n.language).path}
              className={location === "marketplace" ? "title-600 black" : "title-300 black"}
            >
              Marketplace
            </Link>
            <Link
              to={
                window.location.pathname.includes("affiliation")
                  ? routes.joinUs(i18n.language).path + "?affiliate=true"
                  : localStorage.getItem("typeAccountCreatosell") === "seller"
                  ? "/setup-reseller"
                  : localStorage.getItem("typeAccountCreatosell") === "creater"
                  ? "/setup-supplier"
                  : routes.joinUs(i18n.language).path
              }
              className="button back-purple montserrat-800 white"
              style={{
                marginLeft: "30px",
                minWidth: "170px",
                padding: "18px 18px",
                fontSize: "15px",
                maxHeight: "40px",
                letterSpacing: "1.3px",
              }}
            >
              {localStorage.getItem("typeAccountCreatosell")
                ? t("components.menu.40")
                : t("getStarted").toUpperCase()}
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MenuNavbar isSearchDisplay={false} location={location} windowSize={windowSize} />
  );
};

export default NavBar;
