import React from 'react'
import LoadImg from '../images/01supply-load.gif'

const Load = () => {
    return(
        <div className="back-loading">
            <img className="gif-load-01supply" src={LoadImg}/>
        </div>
    )
}

export default Load