import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

import { routes } from "../../routes";

import ProductCard from "../../components/ProductCard";
import { productServices } from "../../services/products";

const Analytics = ({ setUser, user, windowSize, setIsModalPricingDisplayed, setRegisterModalIsActive }) => {
  const [products, setProducts] = useState([]);
  const { t, i18n } = useTranslation();

  const userCanAccess = !(!user.plan?.type || user.plan?.type === "free" || user.plan?.type === "basic");

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    productServices
      .find({
        isProductAnalytics: true,
        page: 1,
        limit: 6,
        userId: localStorage.getItem("idAccountCreatosell") || "",
      })
      .then((res) => res.json())
      .then((resJson) => {
        const resProducts = resJson.data;
        setProducts(resProducts);
      });
  };

  const needAccessCallbackFunction = () => {
    const isUserAuthorized = user.plan?.type === "advanced" || user.plan?.type === "premium";
    if (!isUserAuthorized) {
      if (user?._id) {
        setIsModalPricingDisplayed(true);
      } else {
        setRegisterModalIsActive(true);
      }
    }
  };

  return (
    <div style={{ marginTop: "30px", padding: windowSize < 1150 && "0" }} className="card width-100">
      <div style={{ marginBottom: "20px" }} className="row row-wrap space-between width-100">
        <p style={{ margin: "10px" }} className="title-bold">
          {t("marketplaceHome.analytics.1")}
        </p>
        <div onClick={needAccessCallbackFunction} style={{ margin: "10px" }} className="advanced-label">
          {t("marketplaceHome.advancedFeature")}
        </div>
      </div>

      <div
        style={{ justifyContent: "space-evenly" }}
        className="width-100 row-wrap center product-container-marketplace-home"
      >
        {products?.length > 0 ? (
          products.map((product, index) => {
            return (
              <ProductCard
                isAnalyticsProduct={true}
                setUser={setUser}
                user={user}
                key={product._id}
                index={index}
                location={"landing"}
                product={product}
                isBlur={index > 0 && !userCanAccess}
                blurCallbackFunction={needAccessCallbackFunction}
              />
            );
          })
        ) : (
          <div style={{ width: "90%" }}>
            <Skeleton
              style={{ marginTop: "80px", width: "100%" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
            <Skeleton
              style={{ marginTop: "80px", width: "100%" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
          </div>
        )}
      </div>
      <div className="width-100 row center">
        <Link to={routes.marketplaceAnalyticProducts(i18n.language).path} className="button-grey">
          {t("marketplaceHome.viewAll")}
        </Link>
      </div>
    </div>
  );
};

export default Analytics;
