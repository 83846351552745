import i18n from "i18next";

const currentLang = i18n.language;

const defineLang = (lang) => {
  if (lang) {
    if (lang.includes("-")) {
      const splitingLang = lang.split("-")?.[0];
      if (splitingLang?.length === 2) {
        return "/"+splitingLang;
      }
    }
    if (lang?.length === 2) {
      return "/"+lang;
    }
  }

  return "";
};

export const routes = {
  home: (lang) => ({
    path: `${defineLang(lang)}/`,
    access: ["*"],
  }),
  cart: (lang) => ({
    path: `${defineLang(lang)}/cart`,
    access: ["*"],
  }),
  connect: (lang) => ({
    path: `${defineLang(lang)}/connect`,
    access: ["*"],
  }),
  marketplace: (lang) => ({
    path: `${defineLang(lang)}/marketplace`,
    access: ["*"],
  }),
  marketplaceLambdaSuggestion: (lang) => ({
    path: `${defineLang(lang)}/suggestion/marketplace`,
    access: ["*"],
  }),
  marketplaceHome: (lang) => ({
    path: `${defineLang(lang)}/marketplace-home`,
    access: ["*"],
  }),
  marketplaceSuggestions: (lang) => ({
    path: `${defineLang(lang)}/marketplace-suggestion`,
    access: ["*"],
  }),
  extensionPromotion: (lang) => ({
    path: `${defineLang(lang)}/extension`,
    access: ["*"],
  }),
  marketplaceWinningProducts: (lang) => ({
    path: `${defineLang(lang)}/marketplace-winning-products`,
    access: ["*"],
  }),
  marketplaceAnalyticProducts: (lang) => ({
    path: `${defineLang(lang)}/marketplace-products-analytics`,
    access: ["*"],
  }),
  marketplaceHighMargin: (lang) => ({
    path: `${defineLang(lang)}/marketplace-high-margin`,
    access: ["*"],
  }),
  supplierShop: (lang) => ({
    path: `${defineLang(lang)}/shop`,
    access: ["*"],
  }),
  affiliationLinks: (lang) => ({
    path: `${defineLang(lang)}/affiliation-links`,
    access: ["affiliate"],
  }),
  affiliationPaymentRequest: (lang) => ({
    path: `${defineLang(lang)}/payment-request`,
    access: ["affiliate"],
  }),
  affiliationStatistics: (lang) => ({
    path: `${defineLang(lang)}/affiliation-statistics`,
    access: ["affiliate"],
  }),
  affiliationPaymentHistory: (lang) => ({
    path: `${defineLang(lang)}/payment-history`,
    access: ["affiliate"],
  }),
  joinUs: (lang) => ({
    path: `${defineLang(lang)}/join-us`,
    access: ["*"],
  }),
  productDetails: (lang) => ({
    path: `${defineLang(lang)}/product-details`,
    access: ["*"],
  }),
  resellerCoachingStarter: (lang) => ({
    path: `${defineLang(lang)}/e-commerce-starter-coaching`,
    access: ["*"],
  }),
  resellerCoachingAdvanced: (lang) => ({
    path: `${defineLang(lang)}/e-commerce-advanced-coaching`,
    access: ["*"],
  }),
  resellerCoachingPro: (lang) => ({
    path: `${defineLang(lang)}/e-commerce-pro-coaching`,
    access: ["*"],
  }),
  resellerCoachingPayment: (lang) => ({
    path: `${defineLang(lang)}/ecommerce-coaching-payment`,
    access: ["*"],
  }),
  resellerCoaching: (lang) => ({
    path: `${defineLang(lang)}/ecommerce-coaching`,
    access: ["*"],
  }),
  resellerPresentation: (lang) => ({
    path: `${defineLang(lang)}/ecommerce-coaching-presentation`,
    access: ["*"],
  }),
  supplierMore: (lang) => ({
    path: `${defineLang(lang)}/supplier-more`,
    access: ["*"],
  }),
  resellerMore: (lang) => ({
    path: `${defineLang(lang)}/reseller-more`,
    access: ["*"],
  }),
  whatIs01supply: (lang) => ({
    path: `${defineLang(lang)}/what-is-01supply`,
    access: ["*"],
  }),
  marketplaceResellerDoc: (lang) => ({
    path: `${defineLang(lang)}/marketplace-documentation`,
    access: ["*"],
  }),
  yourAccountResellerDoc: (lang) => ({
    path: `${defineLang(lang)}/your-account-reseller-documentation`,
    access: ["*"],
  }),
  howPlaceOrder: (lang) => ({
    path: `${defineLang(lang)}/how-to-place-an-order`,
    access: ["*"],
  }),
  howTrackOrder: (lang) => ({
    path: `${defineLang(lang)}/how-to-track-an-order`,
    access: ["*"],
  }),
  shopifySetup: (lang) => ({
    path: `${defineLang(lang)}/shopify-setup`,
    access: ["*"],
  }),
  supplierLocation: (lang) => ({
    path: `${defineLang(lang)}/supplier-location-documentation`,
    access: ["*"],
  }),
  supplierShippingMethods: (lang) => ({
    path: `${defineLang(lang)}/supplier-shipping-methods-documentation`,
    access: ["*"],
  }),
  supplierQuantities: (lang) => ({
    path: `${defineLang(lang)}/supplier-product-quantities-documentation`,
    access: ["*"],
  }),
  whatIsDropshipping: (lang) => ({
    path: `${defineLang(lang)}/what-is-dropshipping`,
    access: ["*"],
  }),
  dropshippingSupplierDoc: (lang) => ({
    path: `${defineLang(lang)}/dropshipping-supplier`,
    access: ["*"],
  }),
  whatIs01supplyForSupplier: (lang) => ({
    path: `${defineLang(lang)}/what-is-01supply-for-supplier`,
    access: ["*"],
  }),
  marketplaceDocumentationForSupplier: (lang) => ({
    path: `${defineLang(lang)}/marketplace-documentation-for-supplier`,
    access: ["*"],
  }),
  yourSupplierAccountDocumentation: (lang) => ({
    path: `${defineLang(lang)}/your-supplier-account-documentation`,
    access: ["*"],
  }),
  shippingMethodsDocumentation: (lang) => ({
    path: `${defineLang(lang)}/shipping-methods-documentation`,
    access: ["*"],
  }),
  gettingPaidSupplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/getting-paid-documentation`,
    access: ["*"],
  }),
  howSellSupplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/how-sell-on-01supply-supplier-documentation`,
    access: ["*"],
  }),
  howCreateProductSupplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/how-create-product-supplier-documentation`,
    access: ["*"],
  }),
  howImportCsvSupplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/how-import-csv-products-supplier-documentation`,
    access: ["*"],
  }),
  transactionsFeesSupplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/transactions-fees-supplier-documentation`,
    access: ["*"],
  }),
  fulfillOrderSupplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/fulfill-my-order-supplier-documentation`,
    access: ["*"],
  }),
  dropshippingResellerDocumentation: (lang) => ({
    path: `${defineLang(lang)}/dropshipping-reseller`,
    access: ["*"],
  }),
  supplierCourse1: (lang) => ({
    path: `${defineLang(lang)}/academy/photo-mastery-course`,
    access: ["*"],
  }),
  coursePayment: (lang) => ({
    path: `${defineLang(lang)}/course-payment`,
    access: ["*"],
  }),
  blog: (lang) => ({
    path: `${defineLang(lang)}/blog`,
    access: ["*"],
  }),
  becomeSupplier1: (lang) => ({
    path: `${defineLang(lang)}/become-supplier`,
    access: ["*"],
  }),
  becomeSupplier2: (lang) => ({
    path: `${defineLang(lang)}/how-work-01supply`,
    access: ["*"],
  }),
  becomeSupplier3: (lang) => ({
    path: `${defineLang(lang)}/how-setup-01supply-account`,
    access: ["*"],
  }),
  downloadEbook: (lang) => ({
    path: `${defineLang(lang)}/download-ebook-how-to-get-more-sales-on-etsy`,
    access: ["*"],
  }),
  importEtsyProductShopify: (lang) => ({
    path: `${defineLang(lang)}/how-to-import-etsy-products-to-shopify`,
    access: ["*"],
  }),
  vikingSuppliers: (lang) => ({
    path: `${defineLang(lang)}/dropshipping-viking-suppliers`,
    access: ["*"],
  }),
  blogEmailTemplateSupplier: (lang) => ({
    path: `${defineLang(lang)}/reseller/dropshipping-supplier-email`,
    access: ["*"],
  }),
  blogFindDropshipSupplier: (lang) => ({
    path: `${defineLang(lang)}/reseller/find-european-supplier-dropshipping`,
    access: ["*"],
  }),
  blogJewelrySupplier: (lang) => ({
    path: `${defineLang(lang)}/reseller/jewelry-supplier-dropshipping`,
    access: ["*"],
  }),
  blogSellBesideEtsy: (lang) => ({
    path: `${defineLang(lang)}/supplier/sell-handmade-items-besides-etsy`,
    access: ["*"],
  }),
  article13: (lang) => ({
    path: `${defineLang(lang)}/best-size-product-images`,
    access: ["*"],
  }),
  article14: (lang) => ({
    path: `${defineLang(lang)}/product-photography-cost`,
    access: ["*"],
  }),
  article15: (lang) => ({
    path: `${defineLang(lang)}/clothing-product-photography`,
    access: ["*"],
  }),
  article16: (lang) => ({
    path: `${defineLang(lang)}/product-video-home`,
    access: ["*"],
  }),
  supplierDocumentation: (lang) => ({
    path: `${defineLang(lang)}/documentation/supplier`,
    access: ["*"],
  }),
  resellerDocumentation: (lang) => ({
    path: `${defineLang(lang)}/documentation/reseller`,
    access: ["*"],
  }),
  pricingRulesSettings: (lang) => ({
    path: `${defineLang(lang)}/settings/pricing-rules`,
    access: ["*"],
  }),
  whatEcoLabel: (lang) => ({
    path: `${defineLang(lang)}/what-is-eco-label`,
    access: ["*"],
  }),
  whatHandmadeLabel: (lang) => ({
    path: `${defineLang(lang)}/what-is-handmade-label`,
    access: ["*"],
  }),
  supplierCertifHandmade: (lang) => ({
    path: `${defineLang(lang)}/handmade-certification`,
    access: ["*"],
  }),
  supplierCertifEco: (lang) => ({
    path: `${defineLang(lang)}/eco-friendly-certification`,
    access: ["*"],
  }),
  supplierPricing: (lang) => ({
    path: `${defineLang(lang)}/supplier-pricing`,
    access: ["*"],
  }),
  resellerPricing: (lang) => ({
    path: `${defineLang(lang)}/pricing`,
    access: ["*"],
  }),
  documentation: (lang) => ({
    path: `${defineLang(lang)}/documentation`,
    access: ["*"],
  }),
  messages: (lang) => ({
    path: `${defineLang(lang)}/messages`,
    access: ["seller", "creater"],
  }),
  inventory: (lang) => ({
    path: `${defineLang(lang)}/inventory`,
    access: ["seller", "creater"],
  }),
  yourInformations: (lang) => ({
    path: `${defineLang(lang)}/your-informations`,
    access: ["seller", "creater"],
  }),
  orders: (lang) => ({
    path: `${defineLang(lang)}/my-orders`,
    access: ["seller", "creater"],
  }),
  mobileSettingMenu: (lang) => ({
    path: `${defineLang(lang)}/mobile-setting-menu`,
    access: ["seller", "creater"],
  }),
  disconnect: (lang) => ({
    path: `${defineLang(lang)}/disconnect`,
    access: ["seller", "creater"],
  }),
  affiliationPayouts: (lang) => ({
    path: `${defineLang(lang)}/affiliation/payouts`,
    access: ["seller", "creater"],
  }),
  affiliationReferrals: (lang) => ({
    path: `${defineLang(lang)}/affiliation/referrals`,
    access: ["seller", "creater"],
  }),
  academyDashboard: (lang) => ({
    path: `${defineLang(lang)}/academy/dashboard`,
    access: ["seller", "creater"],
  }),
  yourShopSupplier: (lang) => ({
    path: `${defineLang(lang)}/your-shop-supplier`,
    access: ["creater"],
  }),
  supplierService: (lang) => ({
    path: `${defineLang(lang)}/shop`,
    access: ["creater"],
  }),
  createProduct: (lang) => ({
    path: `${defineLang(lang)}/create-product`,
    access: ["creater"],
  }),
  editProduct: (lang) => ({
    path: `${defineLang(lang)}/edit-product`,
    access: ["creater"],
  }),
  planSupplier: (lang) => ({
    path: `${defineLang(lang)}/plans-supplier`,
    access: ["creater"],
  }),
  ImportProductsCsv: (lang) => ({
    path: `${defineLang(lang)}/import-products-csv`,
    access: ["creater"],
  }),
  supplierDoc: (lang) => ({
    path: `${defineLang(lang)}/supplier-documentation`,
    access: ["creater"],
  }),
  supplierSetup: (lang) => ({
    path: `${defineLang(lang)}/setup-supplier`,
    access: ["creater"],
  }),
  myPayments: (lang) => ({
    path: `${defineLang(lang)}/my-payments`,
    access: ["creater"],
  }),
  manageShippingMethods: (lang) => ({
    path: `${defineLang(lang)}/manage-shipping-methods`,
    access: ["creater"],
  }),
  fulfillOrder: (lang) => ({
    path: `${defineLang(lang)}/fulfill-order`,
    access: ["creater"],
  }),
  paymentMethods: (lang) => ({
    path: `${defineLang(lang)}/payment-methods`,
    access: ["creater"],
  }),
  supplierGrowthBusiness: (lang) => ({
    path: `${defineLang(lang)}/boost-your-business`,
    access: ["creater"],
  }),
  myResellers: (lang) => ({
    path: `${defineLang(lang)}/my-resellers`,
    access: ["creater"],
  }),
  onboarding1: (lang) => ({
    path: `${defineLang(lang)}/supplier-onboarding-step-1`,
    access: ["creater"],
  }),
  onboarding2: (lang) => ({
    path: `${defineLang(lang)}/supplier-onboarding-step-2`,
    access: ["creater"],
  }),
  onboarding3: (lang) => ({
    path: `${defineLang(lang)}/supplier-onboarding-step-3`,
    access: ["creater"],
  }),
  onboarding4: (lang) => ({
    path: `${defineLang(lang)}/supplier-onboarding-step-4`,
    access: ["creater"],
  }),
  onboarding5: (lang) => ({
    path: `${defineLang(lang)}/supplier-onboarding-step-5`,
    access: ["creater", "seller"],
  }),
  sourcing: (lang) => ({
    path: `${defineLang(lang)}/sourcing-products`,
    access: ["seller"],
  }),
  yourShopReseller: (lang) => ({
    path: `${defineLang(lang)}/your-shop-reseller`,
    access: ["seller"],
  }),
  importList: (lang) => ({
    path: `${defineLang(lang)}/import-list`,
    access: ["seller"],
  }),
  resellerDoc: (lang) => ({
    path: `${defineLang(lang)}/reseller-documentation`,
    access: ["seller"],
  }),
  resellerSetup: (lang) => ({
    path: `${defineLang(lang)}/setup-reseller`,
    access: ["seller"],
  }),
  resellerTools: (lang) => ({
    path: `${defineLang(lang)}/reseller-tools`,
    access: ["seller"],
  }),
  supplierList: (lang) => ({
    path: `${defineLang(lang)}/suppliers`,
    access: ["seller"],
  }),
  resellerOnboarding1: (lang) => ({
    path: `${defineLang(lang)}/reseller-onboarding-step-1`,
    access: ["seller"],
  }),
  resellerOnboarding2: (lang) => ({
    path: `${defineLang(lang)}/reseller-onboarding-step-2`,
    access: ["seller"],
  }),
  resellerOnboarding3: (lang) => ({
    path: `${defineLang(lang)}/reseller-onboarding-step-3`,
    access: ["seller"],
  }),
};
