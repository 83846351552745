import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Button, Input } from "antd";
import { Modal } from "../wrappers";
import { url } from "../api/url";
import { header } from "../api/header";

const Invitation = ({ activeInvitation, setActiveInvitation }) => {
  const [typeInvit, setTypeInvit] = useState(
    localStorage.getItem("typeAccountCreatosell") === "seller" ? "creater" : "seller"
  );
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [email, setEmail] = useState("");
  const [whoInvite, setWhoInvite] = useState("");
  const [sendInvitation, setSendInvitation] = useState(false);

  const { t } = useTranslation();

  const sendInvitationF = async () => {
    if (!whoInvite) message.error(t("messages.whoSending"));
    else {
      if (typeInvit === "seller") {
        const resConnect = await fetch(`${url}/sendgrid/template-email`, {
          method: "POST",
          credentials: "include",
          headers: {
            ...header,
            authorization: token,
          },
          body: JSON.stringify({
            emailToSend: email,
            templateId: "d-5b5ca9a90996445b8daf4e6ee888a056",
            templateData: {
              invitationLink: "https://app.01supply.com/join-us?reseller",
              whoInvite: whoInvite,
            },
          }),
        });
        if (resConnect.status === 200) {
          message.success("messages.invitationSend");
          setActiveInvitation(false);
        }
      } else {
        const resConnect = await fetch(`${url}/sendgrid/template-email`, {
          method: "POST",
          credentials: "include",
          headers: {
            ...header,
            authorization: token,
          },
          body: JSON.stringify({
            emailToSend: email,
            templateId: "d-7c8fb4936180468195755a91d9a29af9",
            templateData: {
              invitationLink: "https://app.01supply.com/join-us?supplier",
              whoInvite: whoInvite,
            },
          }),
        });
        if (resConnect.status === 200) {
          message.success(t("messages.invitationSend"));
          setActiveInvitation(false);
        }
      }
    }
  };

  const copyLink = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        message.success(t("messages.invitationLinkCopied"));
      },
      function (err) {
        message.error(t("messages.error"));
      }
    );
  };

  return (
    <Modal
      className="modal-account invitation"
      onOk={() => {}}
      visible={true}
      onCancel={() => setActiveInvitation(false)}
      footer={[
        <Button key="back" onClick={() => setActiveInvitation(false)}>
          {t("cancel")}
        </Button>,
      ]}
    >
      {sendInvitation && (
        <Modal
          className="modal-account invitation"
          onOk={() => {}}
          visible={true}
          onCancel={() => setSendInvitation(false)}
          footer={[
            <Button key="back" onClick={() => setSendInvitation(false)}>
              {t("cancel")}
            </Button>,
          ]}
        >
          <div className="column" style={{ alignItems: "center" }}>
            <p className="title-300">{t("components.invitation.1")}</p>
            <Input
              style={{ marginBottom: "30px", marginTop: "20px" }}
              placeholder="Who invite ?"
              onChange={(e) => setWhoInvite(e.target.value)}
            />
            <button className="button orange-back" onClick={sendInvitationF}>
              {t("components.invitation.2")}
            </button>
          </div>
        </Modal>
      )}

      <div className="column" style={{ alignItems: "center" }}>
        <div style={{ borderRadius: "6px", border: "1px solid grey", width: "max-content" }} className="row">
          <div
            onClick={() => {
              setTypeInvit("seller");
            }}
            style={{ borderRight: "1px solid grey" }}
            className={
              typeInvit === "seller"
                ? "title-300 column center twoChoice-on"
                : "title-300 column center twoChoice"
            }
          >
            {t("reseller")}
          </div>
          <div
            onClick={() => {
              setTypeInvit("creater");
            }}
            className={
              typeInvit === "creater"
                ? "title-300 column center twoChoice-on"
                : "title-300 column center twoChoice"
            }
          >
            {t("supplier")}
          </div>
        </div>
        <p
          style={{
            color: "rgb(110, 110, 110)",
            fontSize: "16px",
            width: "80%",
            marginTop: "30px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          {t("components.invitation.3")}
        </p>
        <div className="black-band">
          {t("components.invitation.4")}{" "}
          {typeInvit === "seller" ? t("reseller").toUpperCase() : t("supplier").toUpperCase()}
        </div>
        <p
          className="title-300"
          style={{ color: "rgb(110, 110, 110)", marginTop: "35px", width: "90%", textAlign: "start" }}
        >
          {t("components.invitation.5")} <span style={{ fontWeight: "bold" }}>email</span>
        </p>
        <div style={{ width: "90%", marginTop: "5px" }} className="row">
          <Input
            maxLength={100}
            value={email}
            onPressEnter={() => setSendInvitation(true)}
            placeholder={
              typeInvit === "seller" ? t("components.invitation.10") : t("components.invitation.11")
            }
            style={{ width: "70%" }}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          <button onClick={() => setSendInvitation(true)} className="button-in-input">
            {t("components.invitation.6")}
          </button>
        </div>
        <p style={{ marginTop: "15px", marginBottom: "10px" }} className="title-300">
          {t("or").toUpperCase()}
        </p>
        <p
          className="title-300"
          style={{ color: "rgb(110, 110, 110)", marginTop: "0px", width: "90%", textAlign: "start" }}
        >
          {t("components.invitation.7")}{" "}
          <span style={{ fontWeight: "bold" }}>{t("components.invitation.8")}</span>
        </p>
        <div style={{ minwidth: "250px", marginTop: "5px" }} className="row">
          <Input
            maxLength={100}
            value={
              typeInvit === "creater"
                ? `${window.location.origin}/join-us?supplier`
                : `${window.location.origin}/join-us?reseller`
            }
            style={{ width: "250px" }}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          <button
            onClick={() =>
              copyLink(
                typeInvit === "creater"
                  ? `${window.location.origin}/join-us?supplier`
                  : `${window.location.origin}/join-us?reseller`
              )
            }
            className="button-in-input"
          >
            {t("components.invitation.9")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Invitation;
