import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Tooltip } from "antd";

import { updateUser } from "../../services/updateUser";

import optionsIcon from "../../images/icons/options.svg";
import SelectShipping from "../supplier/SelectShipping";
import CreateEditShipping from "./CreateEditShipping";

import "./style.scss";

const ShippingCard = ({
  shippingCard,
  user,
  setUser,
  shippingFactorize,
  setShippingCards,
  shippingCards,
}) => {
  const [selectShipping, setSelectShipping] = useState(false);
  const [editedShipping, setEditedShipping] = useState({});
  const [isCreateShipping, setIsCreateShipping] = useState(false);
  const { t } = useTranslation();

  const editShippingOrigin = (value) => {
    const newShipping = user.shipping.map((userShipping) =>
      shippingCard.name === userShipping.origin ? { ...userShipping, origin: value } : userShipping
    );
    const newUser = { ...user, shipping: newShipping };
    updateUser({ user: { shipping: newShipping } });
    setUser(newUser);
    shippingFactorize(newShipping);
  };

  const removeCard = () => {
    const newShipping = user.shipping.filter((userShipping) => shippingCard.name !== userShipping.origin);
    const newUser = { ...user, shipping: newShipping };
    updateUser({ user: { shipping: newShipping } });
    setUser(newUser);
    shippingFactorize(newShipping);
  };

  const removeShipping = (id) => {
    const newShipping = user.shipping.filter((userShipping) =>
      userShipping._id ? id !== userShipping._id : id !== userShipping.temporaryId
    );
    const newUser = { ...user, shipping: newShipping };
    const newShippingCards = shippingCards.map((globalShippingCard) =>
      shippingCard.index === globalShippingCard.index
        ? {
            ...globalShippingCard,
            shippings: globalShippingCard.shippings.filter((shipping) =>
              shipping._id ? id !== shipping._id : id !== shipping.temporaryId
            ),
          }
        : globalShippingCard
    );
    setShippingCards(newShippingCards);
    updateUser({ user: { shipping: newShipping } });
    setUser(newUser);
    // shippingFactorize(newShipping);
  };

  return (
    <>
      {selectShipping && (
        <SelectShipping
          editShippingOrigin={editShippingOrigin}
          setSelectShipping={setSelectShipping}
          shipping={selectShipping}
        />
      )}
      {(editedShipping.origin || isCreateShipping) && (
        <CreateEditShipping
          isCard={true}
          setIsCreateShipping={setIsCreateShipping}
          shipping={editedShipping}
          setEditedShipping={setEditedShipping}
          user={user}
          setUser={setUser}
          shippingFactorize={shippingFactorize}
        />
      )}
      <div style={{ marginTop: "20px" }} className="width-100 card shipping">
        <p className="title montserrat-800">{t("components.shipping.1")}</p>
        <div className="row width-100 space-between">
          <p
            style={{ width: "60%", fontSize: "20px", marginTop: "10px", marginBottom: "15px" }}
            className="title montserrat-700 grey countrys text-ellipsis start pointer"
            onClick={() => setSelectShipping(shippingCard.shippings[0])}
          >
            {shippingCard.countrys.join(" ")}
          </p>
          <Tooltip
            className="shipping-tool"
            title={
              <div className="column tooltip">
                <p
                  onClick={() => setSelectShipping(shippingCard.shippings[0])}
                  style={{ fontSize: "16px" }}
                  className="title pointer"
                >
                  {t("edit")}
                </p>
                <Divider style={{ margin: "8px 0" }} />
                <p onClick={removeCard} style={{ fontSize: "16px" }} className="title red pointer">
                  {t("delete")}
                </p>
              </div>
            }
          >
            <div className="section-5 pointer" style={{ height: "40px" }}>
              <img src={optionsIcon} alt="options" />
            </div>
          </Tooltip>
        </div>
        <div className="row width-100">
          <p className="section-1 text-bold">{t("components.shipping.2")}</p>
          <p className="section-2 text-bold">{t("components.shipping.3")}</p>
          <p className="section-3 text-bold">{t("components.shipping.4")}</p>
          <p className="section-4 text-bold">{t("components.shipping.5")}</p>
          <p className="section-5 text-bold"></p>
        </div>
        <Divider className="width-100" />
        {shippingCard.shippings.map((shipping) => {
          return (
            <div className="row width-100">
              <p className="section-1 text">{shipping.name}</p>
              <p className="section-2 text">
                {t("deliveryTimeParse", {
                  start: shipping.time?.split(" to ")?.[0],
                  end: shipping.time?.split(" to ")?.[1],
                })}{" "}
                {t("days")}
              </p>
              <p className="section-3 text">${shipping.price}</p>
              <p className="section-4 text">${shipping.additionalProductPrice || shipping.price}</p>
              <Tooltip
                className="shipping-tool"
                title={
                  <div className="column tooltip">
                    <p
                      onClick={() => setEditedShipping(shipping)}
                      style={{ fontSize: "16px" }}
                      className="title pointer"
                    >
                      {t("edit")}
                    </p>
                    <Divider style={{ margin: "8px 0" }} />
                    <p
                      onClick={() => removeShipping(shipping._id || shipping.temporaryId)}
                      style={{ fontSize: "16px" }}
                      className="title red pointer"
                    >
                      {t("delete")}
                    </p>
                  </div>
                }
              >
                <div className="section-5 pointer start" style={{ height: "40px" }}>
                  <img src={optionsIcon} alt="options" style={{ width: "20px" }} />
                </div>
              </Tooltip>
            </div>
          );
        })}
        <p
          onClick={() => setEditedShipping({ origin: shippingCard.name })}
          className="text-bold blue underline pointer"
        >
          + {t("components.shipping.addShipping")}
        </p>
      </div>
    </>
  );
};

export default ShippingCard;
