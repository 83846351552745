import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImage } from "../../utils/GetImage";
import { CSVLink, CSVDownload } from "react-csv";
import downloadCsvIcon from "../../images/icons/download_csv.png";
import loadGif from "../../images/load_document.gif";

const GenerateCsv = ({
  products,
  type,
  windowSize,
  user,
  setProducts,
  setCsvGenerate,
  allProducts,
  shopName,
}) => {
  const [csvDatas, setCsvDatas] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    generateCsv();
  }, [products]);

  const generateCsv = async () => {
    if (products && products.length) {
      let csv = [];
      let head = [];

      if (type === "wix") {
        head = [
          "01supply update ID",
          "stock",
          "handleId",
          "fieldType",
          "name",
          "description",
          "productImageUrl",
          "sku",
          "price",
          "inventory",
          "productOptionName1",
          "productOptionDescription1",
          "productOptionName2",
          "productOptionDescription2",
          "productOptionName3",
          "productOptionDescription3",
          "productOptionType1",
          "productOptionType2",
          "productOptionType3",
        ];
      } else if (type === "other" || type === "prestashop") {
        head = [
          "01supply update ID",
          "stock",
          "Title",
          "Description",
          "Hosted Image URLs",
          "Price",
          "Stock",
          "Tags",
          "Option Name 1",
          "Option Value 1",
          "Option Name 2",
          "Option Value 2",
          "Option Name 3",
          "Option Value 3",
        ];
      } else {
        head = [
          "01supply update ID",
          "stock",
          "Variant ID [Non Editable]",
          "Product ID [Non Editable]",
          "Product Type [Non Editable]",
          "Product URL",
          "Product Page",
          "Title",
          "Description",
          "SKU",
          "Hosted Image URLs",
          "Price",
          "Sale Price",
          "Stock",
          "Tags",
          "Visible",
          "On Sale",
          "Weight",
          "Length",
          "Width",
          "Height",
          "Option Name 1",
          "Option Value 1",
          "Option Name 2",
          "Option Value 2",
          "Option Name 3",
          "Option Value 3",
        ];
      }

      csv.push(head);

      for (let product of products) {
        if (product.photos) {
          let imagesUrl = "";

          for (let img of product.photos) {
            imagesUrl += await getImage(img);
            if (img !== product.photos[product.photos.length - 1]) {
              imagesUrl += ",";
            }
          }

          let line = [];

          if (type === "wix") {
            line = [
              product.createrProductId || product._id,
              product.stock || undefined,
              product._id,
              type === "wix" ? "Product" : "simple",
              `"${product.name?.substring(0, 79)?.replaceAll('"', "")}"`,
              `"${product.describe?.replaceAll('"', "")}"`,
              `"${imagesUrl?.replaceAll('"', "")}"`,
              product._id,
              product.price,
              product.stock,
            ];
          } else if (type === "other" || type === "prestashop") {
            line = [
              product.createrProductId || product._id,
              product.stock || undefined,
              `"${product.name?.substring(0, 79)?.replaceAll('"', "")}"`,
              `"${product.describe?.replaceAll('"', "")}"`,
              `"${imagesUrl?.replaceAll('"', "")}"`,
              product.price,
              product.stock,
              product.tag?.length ? product.tag.join() : "",
            ];
          } else {
            line = [
              product.createrProductId || product._id,
              product.stock || undefined,
              type === "squarespace" ? "" : product._id,
              type === "squarespace" ? "" : product._id,
              "PHYSICAL",
              `"${product.name
                ?.substring(0, 79)
                ?.replaceAll('"', "")
                ?.replace(/[^\w\s]/gi, "")
                .replaceAll(" ", "-")}"`,
              `"${shopName?.replaceAll('"', "") || "store-1"}"`,
              `"${product.name
                ?.substring(0, 79)
                ?.replaceAll('"', "")
                ?.replace(/[^\w\s]/gi, "")
                .replaceAll(" ", "-")}"`,
              `"${product.describe?.replaceAll('"', "")}"`,
              product._id,
              `"${imagesUrl?.replaceAll('"', "")}"`,
              product.price,
              product.price,
              product.stock,
              product.tag?.length ? product.tag.join() : "",
              "Yes",
              "Yes",
              "",
              "",
              "",
              "",
            ];
          }

          let options1 = "";
          let options2 = "";
          let options3 = "";

          if (product.variants) {
            for (let variant of product.variants) {
              let separate = ",";
              if (variant._id !== product.variants[product.variants.length - 1]._id) {
              }
              if (!options1.includes(variant.option1)) options1 += variant.option1 += separate;
              if (product.optionsVariants?.[1] && !options2.includes(variant.option2))
                options2 += variant.option2 += separate;
              if (product.optionsVariants?.[2] && !options3.includes(variant.option3))
                options3 += variant.option3 += separate;
            }
          }

          line = [
            ...line,
            `"${product.optionsVariants?.[0]?.replaceAll('"', "") || ""}"`,
            `"${options1?.replaceAll('"', "") || ""}"`,
            `"${product.optionsVariants?.[1]?.replaceAll('"', "") || ""}"`,
            `"${options2?.replaceAll('"', "") || ""}"`,
            `"${product.optionsVariants?.[2]?.replaceAll('"', "") || ""}"`,
            `"${options3?.replaceAll('"', "") || ""}"`,
          ];

          if (type === "wix") {
            line = [...line, "DROP_DOWN", "DROP_DOWN", "DROP_DOWN"];
          }

          csv.push(line);
        }
      }
      setCsvDatas(csv);
    }
  };

  return csvDatas.length ? (
    <div className="column">
      <CSVLink
        separator={";"}
        enclosingCharacter={``}
        filename={"01supply_products.csv"}
        style={{ width: "90%" }}
        className={"button orange-back row"}
        onClick={(e) => {
          if (!products.length) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        data={csvDatas}
      >
        <div style={{ width: "100%", justifyContent: "center" }} className="row">
          <p className="title white">{t("components.generateCsv.9")}</p>
          {windowSize > 1150 && (
            <img
              src={downloadCsvIcon}
              style={{ position: "absolute", right: "15px", width: "35px", height: "auto" }}
            />
          )}
        </div>{" "}
      </CSVLink>
      <CSVDownload
        separator={";"}
        enclosingCharacter={``}
        filename={"01supply_products.csv"}
        data={csvDatas}
      ></CSVDownload>
    </div>
  ) : (
    <div style={{ alignItems: "center" }} className="column">
      <img src={loadGif} style={{ width: "100%", height: "auto" }} />
      <p className="title">{t("components.generateCsv.10")}</p>
    </div>
  );
};

export default GenerateCsv;
