import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import RegisterModal from "../../modals/RegisterModal";
import { newResearch } from "../../../services/updateStatistics";
import MobileMarketMenu from "../marketMobileMenu/index";
import { MarketMenu } from "../MarketMenu";
import CountryCurrencySelector from "../../CountryCurrencySelector";

import { routes } from "../../../routes";

import Jewels from "./dropdowns/Jewels";
import House from "./dropdowns/House";
import Pet from "./dropdowns/Pet";
import Clothes from "./dropdowns/Clothes";
import Tools from "./dropdowns/Tools";

import "./style.scss";
import { languages } from "../../../constants/languages";

const HeaderContent = ({
  windowSize,
  categorySelect,
  setCategorySelect,
  selectAccountAction,
  setMobileMenuOn,
  popupLogin,
  setPopupLogin,
  localSearch,
  setLocalSearch,
  cartCount,
  searchWord,
  handleChangeCategory,
  handleSearch,
  isDropDownDisplay,
  setFilterMenuOn,
  filterMenuOn,
  setCurrentPage,
  setTotalProducts,
  setLoadProducts,
  setNoResult,
  thematicsList,
  products,
  setProducts,
  refreshProductsFunction,
  setSearchWord,
  page,
  setRefreshProductsFunction,
  isNotSticky,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxprice,
  locationSelect,
  setLocationSelect,
  deliverTo,
  setDeliverTo,
  maxDeliveryTime,
  setMaxDeliveryTime,
  originRegion,
  setOriginRegion,
  sortPrice,
  setSortPrice
}) => {
  const [isStickyNav, setIsStickyNav] = useState(false);

  const { t, i18n } = useTranslation();

  const columnScrollingElement = document.getElementsByClassName("column-scrolling")?.[0];

  useEffect(() => {
    if (columnScrollingElement && !window.location.href.includes("suggestion") && !isNotSticky) {
      columnScrollingElement.onscroll = function (e) {
        if (columnScrollingElement?.scrollTop > 90 && !isStickyNav) {
          setIsStickyNav(true);
        } else setIsStickyNav(false);
      };
    }
  }, [columnScrollingElement]);

  useEffect(() => {
    setLocalSearch(searchWord);
  }, [searchWord]);

  const handleResetCategory = () => {
    if (setCategorySelect) {
      setCategorySelect("");
    }
    handleChangeCategory("");
  };

  const isMarketplaceAllProducts = [
    "/marketplace",
    "/suggestion/marketplace",
    ...languages.map((lang) => `/${lang}/marketplace`),
    ...languages.map((lang) => `/${lang}/suggestion/marketplace`),
  ].includes(window.location.pathname);

  return (
    <div
      style={
        windowSize > 1150
          ? {
              backgroundColor: "white",
              width: `"100vw`,
              borderBottom: "1px solid rgb(200, 200, 200)",
              top: "0",
              minHeight: "195px",
              height: "auto",
              padding: "15px",
              paddingLeft: "50px",
              paddingTop: "50px",
              left: "0",
            }
          : { width: "90%", paddingTop: "180px", paddingBottom: "90px", alignItems: "flex-start" }
      }
      className="header marketplace-navbar"
    >
      <div
        style={
          windowSize > 1150
            ? {
                width: "100%",
                backgroundColor: "rgb(240, 240, 240)",
                justifyContent: "flex-end",
                paddingRight: "5vw",
                position: "absolute",
                top: "0",
                left: "0",
                height: "35px",
              }
            : {
                width: "100%",
                backgroundColor: "rgb(240, 240, 240)",
                flexDirection: "column",
                padding: "10px",
                alignItems: "flex-start",
                justifyContent: "center",
                position: "absolute",
                top: "0",
                left: "0",
              }
        }
        className="row"
      >
        <a
          href="tel:33-6-75-55-83-34"
          style={windowSize > 1150 ? { position: "absolute", left: "50px", fontSize: "13px" } : {}}
        >
          {t("components.header.19")} : (+33) 06.75.55.83.34
        </a>
        <a
          className="text-roboto"
          style={windowSize > 1150 ? { marginRight: "30px", fontSize: "13px" } : { fontSize: "13px" }}
          target="_blank"
          href="https://01supply.com/sell-on-01supply"
        >
          <p>{t("components.header.20")}</p>
        </a>
        <a
          className="text-roboto"
          style={
            windowSize > 1150
              ? { marginRight: "40px", fontSize: "13px" }
              : { marginTop: "10px", fontSize: "13px" }
          }
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tidio.com/talk/rwhlsppllnpriozhtsqysjubupzuunlk"
        >
          <p>{t("components.header.21")}</p>
        </a>
        <CountryCurrencySelector
          selectorStyle={
            windowSize > 1150
              ? { fontSize: "14px", marginTop: "3px" }
              : { marginTop: "5px", fontSize: "14px" }
          }
        />
      </div>

      {popupLogin && <RegisterModal modalOn={popupLogin} setModal={setPopupLogin} />}

      <div
        className={windowSize > 1150 ? `row ${isStickyNav && "market-navbar sticky"}` : "column"}
        style={{ width: "100%", justifyContent: "flex-start", minHeight: "70px" }}
      >
        <Input
          value={localSearch}
          maxLength={100}
          className="input marketplace"
          id="search-product-input-header"
          placeholder={t("components.menu.48")}
          suffix={
            <button onClick={handleSearch} className="button orange-back">
              <SearchOutlined style={{ fontSize: "20px", color: "white", marginRight: "10px" }} />{" "}
              {t("search").toUpperCase()}
            </button>
          }
          onChange={(e) => {
            setLocalSearch(e.target.value);
          }}
          onPressEnter={handleSearch}
        />

        {isMarketplaceAllProducts && (
          <MarketMenu
            setFilterMenuOn={setFilterMenuOn}
            filterMenuOn={filterMenuOn}
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            setCategorySelect={setCategorySelect}
            setCurrentPage={setCurrentPage}
            setTotalProducts={setTotalProducts}
            page={page}
            setLoadProducts={setLoadProducts}
            windowSize={windowSize}
            categorySelect={categorySelect}
            setNoResult={setNoResult}
            thematicsList={thematicsList}
            products={products}
            setProducts={setProducts}
            setRefreshProductsFunction={setRefreshProductsFunction}
            refreshProductsFunction={refreshProductsFunction}
            isMobile={windowSize < 1150}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxprice={setMaxprice}
            locationSelect={locationSelect}
            setLocationSelect={setLocationSelect}
            deliverTo={deliverTo}
            setDeliverTo={setDeliverTo}
            maxDeliveryTime={maxDeliveryTime}
            setMaxDeliveryTime={setMaxDeliveryTime}
            originRegion={originRegion}
            setOriginRegion={setOriginRegion}
            sortPrice={sortPrice}
            setSortPrice={setSortPrice}
          />
        )}
      </div>

      <div
        style={
          windowSize > 1150
            ? { marginTop: "30px", marginLeft: "5px" }
            : { marginRight: "30px", marginTop: "40px" }
        }
        className={windowSize > 1150 ? `row ${isStickyNav && "market-navbar sticky categories"}` : "column"}
      >
        <div
          className={windowSize > 1150 ? `row width-100` : "column"}
          style={{ justifyContent: "space-between", maxWidth: "800px" }}
        >
          <Link
            to={routes.marketplaceHome(i18n.language).path}
            style={
              windowSize > 1150
                ? { cursor: "pointer", fontSize: "15px" }
                : { cursor: "pointer", fontSize: "15px", marginTop: "20px" }
            }
            onClick={window.location.href?.includes("marketplace") && handleResetCategory}
            className={
              window.location.href?.includes("marketplace-home") && !categorySelect
                ? "text-bold underline"
                : "title monteserrat"
            }
          >
            {t("components.header.26")}
          </Link>
          <Jewels
            handleChangeCategory={handleChangeCategory}
            categorySelect={categorySelect}
            t={t}
            windowSize={windowSize}
            isDropDownDisplay={isDropDownDisplay}
          />
          <Clothes
            handleChangeCategory={handleChangeCategory}
            categorySelect={categorySelect}
            t={t}
            windowSize={windowSize}
            isDropDownDisplay={isDropDownDisplay}
          />
          <House
            handleChangeCategory={handleChangeCategory}
            categorySelect={categorySelect}
            t={t}
            windowSize={windowSize}
            isDropDownDisplay={isDropDownDisplay}
          />
          <Pet
            handleChangeCategory={handleChangeCategory}
            categorySelect={categorySelect}
            t={t}
            windowSize={windowSize}
            isDropDownDisplay={isDropDownDisplay}
          />
          <Tools
            handleChangeCategory={handleChangeCategory}
            categorySelect={categorySelect}
            t={t}
            windowSize={windowSize}
            isDropDownDisplay={isDropDownDisplay}
          />
        </div>
      </div>
    </div>
  );
};

const MarketHeader = ({
  categorySelect,
  windowSize,
  user,
  setSearchWord,
  searchWord,
  setCategorySelect,
  setRefreshProductsFunction,
  isStickyNav,
  isNotSticky,
  setFilterMenuOn,

  filterMenuOn,
  setCurrentPage,
  setTotalProducts,
  setLoadProducts,
  setNoResult,
  thematicsList,
  products,
  setProducts,
  refreshProductsFunction,
  currentPage,

  minPrice,
  setMinPrice,
  maxPrice,
  setMaxprice,
  locationSelect,
  setLocationSelect,
  deliverTo,
  setDeliverTo,
  maxDeliveryTime,
  setMaxDeliveryTime,
  originRegion,
  setOriginRegion,
  sortPrice,
  setSortPrice
}) => {
  const [mobileMenuOn, setMobileMenuOn] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [popupLogin, setPopupLogin] = useState("");
  const [localSearch, setLocalSearch] = useState(searchWord || "");
  const [isDropDownDisplay, setIsDropDownDisplay] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setLocalSearch(searchWord);
  }, [searchWord]);

  const handleSearch = () => {
    newResearch({ word: localSearch, user });
    setMobileMenuOn(false);
    if (setSearchWord) {
      setSearchWord(localSearch);
      setCategorySelect("");

      setRefreshProductsFunction({ params: { searchWord: localSearch } });
    } else {
      // if we are not on marketplace
      history.push(`/marketplace?page=1&search=${localSearch}`);
    }
  };

  useEffect(() => {
    if (user.cart && user.cart.length) {
      setCartCount(user.cart.length);
    } else if (JSON.parse(localStorage.getItem("cart"))) {
      setCartCount(JSON.parse(localStorage.getItem("cart")).length);
    }
  }, [user]);

  useEffect(() => {
    if (searchParams) setSearchParams("");
  }, [searchParams]);

  const selectAccountAction = (e, path) => {
    if (!localStorage.getItem("typeAccountCreatosell") && e) {
      e.stopPropagation();
      e.preventDefault();
      setPopupLogin(path);
    }
  };

  const handleChangeCategory = (category) => {
    const weAreOnMarketplace = window.location.pathname === "/marketplace";
    if (weAreOnMarketplace) {
      setSearchWord("");
      setCategorySelect(category.replace("&", "é"));
      setRefreshProductsFunction({
        params: {
          categorySelect: category.replace("&", "é"),
          page: 1,
          isWinningProducts: "false",
          isHighMarginProducts: "false",
          thematicSelect: { name: "", _id: "" },
        },
      });
      setIsDropDownDisplay(false);
      setTimeout(() => setIsDropDownDisplay(true), 500);
    } else {
      history.push(`/marketplace?category=${category.replace("&", "é")}`);
    }
  };

  return windowSize > 1150 ? (
    <>
      {searchParams && <Redirect to={`/marketplace?search=${localSearch}`} target="_blank" />}
      <HeaderContent
        isNotSticky={isNotSticky}
        windowSize={windowSize}
        categorySelect={categorySelect}
        setCategorySelect={setCategorySelect}
        selectAccountAction={selectAccountAction}
        setMobileMenuOn={setMobileMenuOn}
        popupLogin={popupLogin}
        setPopupLogin={setPopupLogin}
        localSearch={localSearch}
        setLocalSearch={setLocalSearch}
        cartCount={cartCount}
        searchWord={searchWord}
        handleChangeCategory={handleChangeCategory}
        handleSearch={handleSearch}
        isDropDownDisplay={isDropDownDisplay}
        user={user}
        setFilterMenuOn={setFilterMenuOn}
        filterMenuOn={filterMenuOn}
        setCurrentPage={setCurrentPage}
        setTotalProducts={setTotalProducts}
        setLoadProducts={setLoadProducts}
        setNoResult={setNoResult}
        thematicsList={thematicsList}
        products={products}
        setProducts={setProducts}
        refreshProductsFunction={refreshProductsFunction}
        setSearchWord={setSearchWord}
        page={currentPage}
        setRefreshProductsFunction={setRefreshProductsFunction}
        isMobile={windowSize < 1150}
        minPrice={minPrice}
        setMinPrice={setMinPrice}
        maxPrice={maxPrice}
        setMaxprice={setMaxprice}
        locationSelect={locationSelect}
        setLocationSelect={setLocationSelect}
        deliverTo={deliverTo}
        setDeliverTo={setDeliverTo}
        maxDeliveryTime={maxDeliveryTime}
        setMaxDeliveryTime={setMaxDeliveryTime}
        originRegion={originRegion}
        setOriginRegion={setOriginRegion}
        sortPrice={sortPrice}
        setSortPrice={setSortPrice}
      />
    </>
  ) : (
    <>
      {searchParams && <Redirect to={`/marketplace?search=${localSearch}`} />}
      <MobileMarketMenu
        handleSearch={handleSearch}
        isStickyNav={isStickyNav}
        windowSize={windowSize}
        handleChangeCategory={handleChangeCategory}
        localSearch={localSearch}
        setLocalSearch={setLocalSearch}
        user={user}
        setFilterMenuOn={setFilterMenuOn}
      />
    </>
  );
};

export default MarketHeader;
