import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { Link, Redirect } from "react-router-dom";

import { header } from "../../../api/header";
import { url } from "../../../api/url";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import IbanModal from "../../../components/modals/IbanModal";
import PaypalEmailModal from "../../../components/modals/PaypalEmailModal";
import { updateUser } from "../../../services/updateUser";

import bankIcon from "../../../images/bank.svg";
import paypalIcon from "../../../images/paypal.svg";
import stripeIcon from "../../../images/stripe.svg";
import Stripe from "../../../components/stripe/Stripe";

import "react-phone-input-2/lib/material.css";

const SupplierOnboarding4 = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isNext, setIsNext] = useState(false);
  const [stripeEmail, setStripeEmail] = useState("");
  const [ibanModal, setIbanModal] = useState(false);
  const [paypalEmailAccountModal, setPaypalEmailAccountModal] = useState(false);
  const [stripeStatus, setStripeStatus] = useState("");

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const nextCondition = user.stripe?.account_completed || user.paypal_email_account || user.iban;

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
    if (resJson.data?.stripe?.account_id) getStripeInfo(resJson.data.stripe.account_id);
  };

  const getStripeInfo = async (stripeAccountId) => {
    const res = await fetch(`${url}/stripe/account/${stripeAccountId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    if (resJson.account) {
      setStripeEmail(resJson.account.email);
      const status = resJson.account.payouts_enabled
        ? "Active"
        : resJson.account?.requirements?.currently_due?.some((requirement) =>
            requirement.includes("document")
          )
        ? "Missing documents"
        : "Pending";
      setStripeStatus(status);
    }
  };

  const sendUpdateUser = () => {
    setIsNext(true);
  };

  return (
    <div className="page">
      {isNext && <Redirect to="/supplier-onboarding-step-5" />}
      {ibanModal && (
        <IbanModal
          user={user}
          setIbanModal={setIbanModal}
          url={url}
          idAccount={idAccount}
          secretId={secretId}
          token={token}
          header={header}
          setUser={setUser}
        />
      )}
      {paypalEmailAccountModal && (
        <PaypalEmailModal
          user={user}
          setPaypalEmailAccountModal={setPaypalEmailAccountModal}
          url={url}
          idAccount={idAccount}
          secretId={secretId}
          token={token}
          header={header}
          setUser={setUser}
        />
      )}
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "0px",
                }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingBottom: "200px",
                }
          }
          className="column-scrolling"
        >
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              marginBottom: windowSize > 1150 ? "50px" : "0",
              height: "80px",
            }}
            className="header"
          >
            <h1 className="title-bold montserrat">{t("onboarding.1")} (4/5)</h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "40px",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div
              id="content"
              style={{ marginLeft: "0", width: "95%", padding: "15px 20px 30px 20px", marginTop: "30px" }}
              className="column card"
            >
              <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                {t("onboarding.14")}
              </h1>

              <div style={{ width: "100%", marginTop: "20px" }} className="grey-card">
                <img src={stripeIcon} style={{ width: "120px" }} />

                <div style={{ justifyContent: "space-between", width: "100%" }} className="row-wrap">
                  <div className="column">
                    <p className="text-bold">{t("onboarding.15")}</p>
                    <p className="text">2%</p>
                  </div>
                  {stripeStatus && (
                    <>
                      {stripeEmail && (
                        <div className="column">
                          <p className="text-bold">{t("onboarding.16")}</p>
                          <p className="text">{stripeEmail}</p>
                        </div>
                      )}
                      <div className="column">
                        <p className="text-bold">Status</p>
                        <p
                          style={{ color: stripeStatus === "Active" ? "#6FD979 " : "#FBA95E" }}
                          className="text-bold"
                        >
                          {stripeStatus}
                        </p>
                      </div>
                    </>
                  )}
                  <Stripe stripeStatus={stripeStatus} stripeEmail={stripeEmail} user={user} />
                </div>
              </div>
              {user?.stripe?.account_url && !user?.stripe?.account_completed && stripeStatus === "Active" && (
                <p
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    background: "rgb(232, 53, 53, 0.5)",
                    padding: "5px 15px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                  className="text-bold white"
                >
                  {t("onboarding.17")}
                </p>
              )}

              <div
                style={{ marginTop: "45px", width: "100%", justifyContent: "center", marginBottom: "20px" }}
                className="row"
              >
                <button
                  onClick={sendUpdateUser}
                  style={{ marginRight: "10px" }}
                  className="button big grey disable"
                >
                  {t("onboarding.5")}
                </button>
                {nextCondition && (
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={sendUpdateUser}
                    className="button big orange-back"
                  >
                    {t("onboarding.6")}
                  </button>
                )}
              </div>
              <div className="row" style={{ width: "100%", justifyContent: "center" }}>
                <Link to="/supplier-onboarding-step-3" className="text blue underline pointer">
                  {t("onboarding.7")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierOnboarding4;
