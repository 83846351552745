import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";

import ChromeIcon from "../../../images/icons/Chrome.svg";
import ShopifyIcon from "../../../images/icons/shopify-icon.png";

const ResellerTools = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <div className="page">
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={windowSize > 1150 ? { paddingTop: "0px" } : { paddingTop: "30px", paddingBottom: "150px" }}
          className="column-scrolling documentation"
        >
          <div className="row-wrap" style={{ width: "100%", paddingTop:  windowSize > 1150 ? "110px" : "20px", alignItems: "flex-start" }}>
            {windowSize > 1150 ? (
              <>
                <MenuSetting user={user} />
                <div className="header">
                  <div
                    className="row"
                    style={
                      windowSize > 1150
                        ? { width: "100%", marginBottom: "-30px", height: "30px" }
                        : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                    }
                  >
                    <h1 className="title-bold montserrat">{t("resellerTools.1")}</h1>
                  </div>
                  <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
                </div>
              </>
            ) : (
              <MobileHeader nameHeader={t("resellerTools.1")} location={"TOOLS"} />
            )}
            <div style={{ width: windowSize > 1150 ? "calc(100% - 350px)" : "100%" }} className="column">
              <div
                id="content"
                style={
                  windowSize > 1150
                    ? {
                        width: "100%",
                        marginLeft: "30px",
                        padding: "15px 35px 30px 35px",
                        marginTop: "0",
                      }
                    : { width: "100%", marginTop: "30px", padding: "15px 20px 30px 20px" }
                }
                className="column card"
              >
                <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                  {t("resellerTools.2")}
                </h1>
                <div className="row space-between">
                  <div className="row">
                    <img
                      style={{ height: "35px", marginRight: windowSize > 1150 ? "30px" : "" }}
                      src={ChromeIcon}
                    />
                    <p
                      style={
                        windowSize > 1150
                          ? { width: "calc(45vw - 230px)" }
                          : { width: "100%", marginTop: "20px", marginBottom: "20px" }
                      }
                    >
                      {t("resellerTools.3")}
                    </p>
                  </div>
                  <a
                    target="_blank"
                    href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
                    className="button orange-back"
                  >
                    {t("resellerTools.4")}
                  </a>
                </div>
              </div>
              <div
                id="content"
                style={
                  windowSize > 1150
                    ? {
                        width: "100%",
                        marginLeft: "30px",
                        padding: "15px 35px 30px 35px",
                        marginTop: "0",
                      }
                    : { width: "100%", marginTop: "30px", padding: "15px 20px 30px 20px" }
                }
                className="column card"
              >
                <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                  {t('shopifyApp')}
                </h1>
                <div className="row space-between">
                  <div className="row">
                    <img
                      style={{ height: "35px", marginRight: windowSize > 1150 ? "30px" : "" }}
                      src={ShopifyIcon}
                    />
                    <p
                      style={
                        windowSize > 1150
                          ? { width: "calc(45vw - 230px)" }
                          : { width: "100%", marginTop: "20px", marginBottom: "20px" }
                      }
                    >
                      {t("resellerTools.5")}
                    </p>
                  </div>
                  <a target="_blank" href="https://apps.shopify.com/01supply" className="button orange-back">
                    {t("resellerTools.6")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellerTools;
