import React, { useState, useEffect } from "react";
import ThematicCard from "../../marketplace/ThematicCard";
import { Input, Button, message } from "antd";

import {Modal} from '../../../wrappers'
import { url } from "../../../api/url";
import { header } from "../../../api/header";

const Product = ({ thematics, setEditThematics, setThematics, windowSize }) => {
  const [newThemName, setNewThemName] = useState("");
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [adminId] = useState(localStorage.getItem("idAccountCreatosell"));
  const [editThematicVedette, setEditThematicVedette] = useState({});
  const [newVedetteId, setNewVedetteId] = useState("");
  const [reloadThematicCard, setReloadThematicCard] = useState('')

  useEffect(() => {}, []);

  const createThematic = async () => {
    if (!newThemName) message.error("Input Thematic name please");
    else {
      const res = await fetch(`${url}/thematic/create/${adminId}`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          name: newThemName,
        }),
      });
      if (res.status === 200) {
        const resJson = await res.json()
        setThematics([
          ...thematics,
          resJson.thematic,
        ]);
        setNewThemName("");
        message.success('Thematic created !')
      } else message.error("error");
    }
  };

  const editVedetteIdF = async () => {
    const res = await fetch(`${url}/thematic/edit-vedette/${adminId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        thematicId: editThematicVedette._id,
        index: editThematicVedette.index,
        productId: newVedetteId
      }),
    });
    if(res.status === 200){
      let newThematics = [...thematics]
      for(let i in thematics){
        if(thematics[i]._id === editThematicVedette._id){
          newThematics[i].vedettes[editThematicVedette.index] = newVedetteId
        }
      }
      setThematics([...newThematics])
        setReloadThematicCard(editThematicVedette._id)
        setEditThematicVedette({})
    } else message.error('error')

  }

  return (
    <Modal
      className={windowSize > 1150 && "large"}
      onOk={() => {}}
      visible={true}
      onCancel={() => setEditThematics(false)}
      footer={[
        <Button key="back" onClick={() => setEditThematics(false)}>
          Cancel
        </Button>,
      ]}
    >
      <div className="column" style={{ alignItems: "center" }}>
        {editThematicVedette._id && (
          <Modal
            onOk={() => {}}
            visible={true}
            onCancel={() => setEditThematicVedette({})}
            footer={[
              <Button key="back" onClick={() => setEditThematicVedette({})}>
                Cancel
              </Button>,
            ]}
          >
            <Input
            defaultValue={editThematicVedette.vedettes[editThematicVedette.index] && editThematicVedette.vedettes[editThematicVedette.index]._id ? editThematicVedette.vedettes[editThematicVedette.index]._id 
              : editThematicVedette.vedettes[editThematicVedette.index] && editThematicVedette.vedettes[editThematicVedette.index]}
              onPressEnter={editVedetteIdF}
              onChange={(e) => {
                setNewVedetteId(e.target.value);
              }}
              placeholder="Product ID"
            />
            <button
              className="button orange-back"
              onClick={editVedetteIdF}
              style={{ marginTop: "20px" }}
            >
              Valid ID
            </button>
          </Modal>
        )}
        <p className="title">Create new Thematic</p>
        <Input
        value={newThemName}
          onChange={(e) => setNewThemName(e.target.value)}
          placeholder="Thematic Name"
          style={{ marginTop: "10px", marginBottom: "12px" }}
        />
        <button className="button orange-back" onClick={createThematic}>
          Create Thematic
        </button>
        {thematics &&
          thematics.map((them) => {
            return (
              <ThematicCard
                setEditThematicVedette={setEditThematicVedette}
                thematic={them}
                admin={true}
                setReloadThematicCard={setReloadThematicCard}
                reloadThematicCard={reloadThematicCard}
              />
            );
          })}
      </div>
    </Modal>
  );
};

export default Product;
