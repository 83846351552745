import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import socketClient from "socket.io-client";

import { url } from "../../api/url";
import { header } from "../../api/header";
import RedirectLogin from "../../utils/RedirectLogin";
import Load from "../../utils/Load";
import { updateUser } from "../../services/updateUser";
// import {socket} from '../../services/socket.io'

import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/header/MessagesHeader";
import MenuContacts from "../../components/menu/ContactsListMenu";
import ManageOffers from "../../components/messages/ManageOffer";
import NewMessage from "../../components/messages/NewMessage";
import OffersList from "../../components/messages/OffersList";
import MessagesList from "../../components/messages/Messages";

const Messages = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [contactMenuOn, setContactMenuOn] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [load, setLoad] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [noContacts, setNoContacts] = useState(false);
  const [user, setUser] = useState({});
  const [contactSelect, setContactSelect] = useState({});
  const [isModalManageOffersDisplayed, setIsModalManageOffersDisplayed] = useState(false);
  const [isManageOffers, setIsManageOffers] = useState(false);
  const [offers, setOffers] = useState({});
  const [messages, setMessages] = useState([]);
  const [noMessages, setNoMessages] = useState(false);
  const [isSocketConfig, setIsSocketConfig] = useState(false);
  const [messageIsSent, setMessageIsSent] = useState(false);
  const [socket, setSocket] = useState(null);

  const { t } = useTranslation();

  const createrId = user.type === "creater" ? user._id : contactSelect._id;
  const sellerId = user.type === "seller" ? user._id : contactSelect._id;
  const channelId = `${createrId}${sellerId}`;

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) {
      window?.tidioChatApi?.hide();
    }
    getUser();
  }, []);

  useEffect(() => {
    if (user._id && contactSelect._id && isSocketConfig === false) {
      configureSocket();
    }
  }, [user, contactSelect]);

  // WEB SOCKET

  // consigure socket
  const configureSocket = () => {
    const socketConnected = socketClient.connect(process.env.REACT_APP_SERVER_URL, {
      reconnection: true, // whether to reconnect automatically
      reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
      reconnectionDelay: 10, // how long to initially wait before attempting a new reconnection
      reconnectionDelayMax: 10, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
      randomizationFactor: 0.5,
      transports: ["websocket"],
      upgrade: false,
      "force new connection": true,
    });
    setSocket(socketConnected);
    setIsSocketConfig(true);
  };

  useEffect(() => {
    if (socket && messageIsSent === false) {
      socket.on(channelId, (message) => {
        setMessages([...messages, message]);
      });
    } else if (messageIsSent) {
      setMessageIsSent(false);
    }
  }, [messages, socket]);

  const sendSocketMessage = (message) => {
    setMessageIsSent(true);
    socket.emit("send-message", { ...message, channelId });
  };

  const updateMessagesCount = () => {
    if (user.messages_count?.contacts?.find((contact) => contact.id === contactSelect._id)?.count > 0) {
      const newMessagesCount = user.messages_count;
      newMessagesCount.contacts.forEach((contact) => {
        if (contact.id === contactSelect._id) {
          newMessagesCount.total = newMessagesCount.total - contact.count;
          contact.count = 0;
        }
      });
      updateUser({ user: { ...user, messages_count: newMessagesCount } }, idAccount, secretId, token);
    }
  };

  useEffect(() => {
    if (contactSelect._id && user._id) {
      setMessages([]);
      setNoMessages(false);

      updateMessagesCount();
      getOffers();
      getMessages();
    }
  }, [contactSelect]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
      if (typeAccount === "seller") findSuppliers(resJson.data?._id);
      else if (typeAccount === "creater") findResellers(resJson.data?._id);

      if (window.location.href.includes("contact=")) {
        setLoad(true);
        autoSelectContactWithUrl();
      }
    }
  };

  const findResellers = async (user) => {
    const res = await fetch(`${url}/user/my-resellers/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson.data?.length < 1) setNoContacts(true);
      else {
        setContacts(resJson.data);
      }
    } else setNoContacts(true);
  };

  const findSuppliers = async () => {
    const res = await fetch(`${url}/user/my-suppliers/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson.suppliers && resJson.suppliers.length < 1) setNoContacts(true);
      else {
        setContacts(resJson.suppliers);
      }
    } else setNoContacts(true);
  };

  const getOffers = async () => {
    const res = await fetch(
      `${url}/custom-price/find/${typeAccount === "seller" ? contactSelect._id : user._id}/${
        typeAccount === "creater" ? contactSelect._id : user._id
      }`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      }
    );
    if (res.status === 200) {
      const resJson = await res.json();
      setOffers({ ...resJson.data, prices: resJson.data.prices });
    } else {
      setOffers({
        seller_id: user.type === "seller" ? user._id : contactSelect._id,
        creater_id: user.type === "creater" ? user._id : contactSelect._id,
        prices: [],
      });
    }
  };

  const getMessages = async () => {
    const createrId = user.type === "creater" ? user._id : contactSelect._id;
    const sellerId = user.type === "seller" ? user._id : contactSelect._id;
    const res = await fetch(`${url}/channel/${createrId}/${sellerId}/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setMessages(resJson.messages);
    } else {
      setNoMessages(true);
    }
  };

  const autoSelectContactWithUrl = async (contacts) => {
    const href = window.location.href;
    const currentUrl = new URL(href);
    const contactParams = currentUrl.searchParams.get("contact");

    if (contactParams) {
      const res = await fetch(`${url}/user/find-users`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          usersId: [contactParams],
        }),
      });
      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson.data) {
          setContactSelect(resJson.data[0]);
        }
        setLoad(false);
      } else setLoad(false);
    } else setLoad(false);
  };

  return (
    <div style={{ maxHeight: "100vh" }} className="page">
      <RedirectLogin />
      <ManageOffers
        windowSize={windowSize}
        setLoad={setLoad}
        offers={offers}
        setOffers={setOffers}
        user={user}
        contact={contactSelect}
        isModalManageOffersDisplayed={isModalManageOffersDisplayed}
        setIsModalManageOffersDisplayed={setIsModalManageOffersDisplayed}
      />
      <div style={{ height: "100vh", overflow: "hidden" }} className="page-start">
        {contactMenuOn ? (
          <MenuContacts
            user={user}
            contactSelect={contactSelect}
            setOffers={setOffers}
            setContactSelect={setContactSelect}
            setContactMenuOn={setContactMenuOn}
            windowSize={windowSize}
            noContacts={noContacts}
            contacts={contacts}
          />
        ) : windowSize > 1150 ? (
          <Menu />
        ) : (
          <MobileMenu locationP="about-supplier" />
        )}
        <div
          style={windowSize > 1150 ? { overflow: "hidden" } : { padding: "10px" }}
          className="column-scrolling"
        >
          {load && <Load />}
          <Header contact={contactSelect} contactMenuOn={contactMenuOn} setContactMenuOn={setContactMenuOn} />

          {!contactSelect.email ? (
            <p className="title-roboto-300">
              {t("chat.1")} <br />
              <span
                onClick={() => setContactMenuOn(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {t("chat.2")}
              </span>
            </p>
          ) : isManageOffers && offers.prices && !offers.prices.length ? (
            <p style={{ marginTop: "50px" }} className="title-roboto-300">
              {t("chat.3")}
              <br />
              {t("chat.4")}
            </p>
          ) : !isManageOffers && noMessages ? (
            <p style={{ marginTop: "50px" }} className="title-roboto-300">
              {t("chat.5")}
              <br />
              {t("chat.6")}
            </p>
          ) : (isManageOffers && !offers.prices) || (!isManageOffers && !messages.length) ? (
            <React.Fragment>
              <div style={{ marginTop: "50px" }} />
              <Skeleton className="image-product" loading={true} active />
              <Skeleton className="image-product" loading={true} active />
            </React.Fragment>
          ) : isManageOffers ? (
            <OffersList
              windowSize={windowSize}
              setLoad={setLoad}
              setMessages={setOffers}
              messages={offers}
              contact={contactSelect}
              user={user}
              isManageOffers={isManageOffers}
            />
          ) : (
            <MessagesList windowSize={windowSize} messages={messages} user={user} />
          )}

          {contactSelect && contactSelect.email && contactSelect.email && (
            <div
              className="row shadow"
              style={
                windowSize > 1150
                  ? {
                      width: "calc(100% - 225px)",
                      backgroundColor: "white",
                      height: "70px",
                      position: "fixed",
                      bottom: "0",
                      right: "0",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }
                  : {
                      width: "100%",
                      backgroundColor: "white",
                      height: "120px",
                      position: "fixed",
                      bottom: "0",
                      right: "0",
                      padding: "10px",
                      flexDirection: "column",
                    }
              }
            >
              {isManageOffers ? (
                <>
                  <button
                    style={{ fontSize: "20px", width: "200px", height: "40px" }}
                    onClick={() => setIsManageOffers(false)}
                    className="button-orange"
                  >
                    {t("chat.7")}
                  </button>
                  <button
                    onClick={() => setIsModalManageOffersDisplayed(true)}
                    className="button orange-back"
                    style={
                      windowSize > 1150
                        ? { width: "60%", marginLeft: "60px", height: "40px", fontSize: "16px" }
                        : { width: "90%", marginTop: "15px" }
                    }
                  >
                    {t("chat.8")}
                  </button>
                </>
              ) : (
                <>
                  <button
                    style={{
                      fontSize: "20px",
                      width: "200px",
                      height: "40px",
                      marginBottom: windowSize <= 1150 && "15px",
                    }}
                    onClick={() => setIsManageOffers(true)}
                    className="button-orange"
                  >
                    {t("chat.9")}
                  </button>
                  <NewMessage
                    setNoMessages={setNoMessages}
                    contact={contactSelect}
                    user={user}
                    messages={messages}
                    setMessages={setMessages}
                    sendSocketMessage={sendSocketMessage}
                    windowSize={windowSize}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
