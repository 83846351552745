import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article19/How-to-optimize-an-SEO-product-description.jpg";
import keywordPlacement from "../../../../images/blog/global/article19/keyword-placement.jpg";
import desciptionLenght from "../../../../images/blog/global/article19/how-long-product-description.jpg";
import googleAlgorithm from "../../../../images/blog/global/article19/google-algoryihm.jpg";
import dogBasket from "../../../../images/blog/global/article19/dog-basket.jpg";
import aida from "../../../../images/blog/global/article19/aida-model.jpg";
import awareness from "../../../../images/blog/global/article19/awareness.jpg";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        How to optimize an SEO product description? (Simple Guide)
      </h1>
      <p className="text-300">09 october 2022 - 10 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="How to optimize an SEO product description?"/>
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        You are an <b>e-commerce owner</b>, and you want to <b>improve your product descriptions</b> to grow visits from <b>search engines like Google</b>.
        In this article we will run through the most common questions SEO writers are asking.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Indeed, you may be asking yourself how you should choose product titles, what keyword to target and <b>how long your product description</b> must be.
        This is very important as it will define whether or not you will rank in Google search results.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#product-description-importance" title="Why is product description important?" />
            <Anchor.Link href="#seo-in-product-description" title="How is SEO used in a product description?" />
            <Anchor.Link href="#seo-description-best-practices" title="How to write SEO-friendly product descriptions? (best practices)">
              <Anchor.Link href="#google-algorithm" title="Understanding Google algorithm" />
              <Anchor.Link href="#element-list-seo-description" title="What should I write in my product description?" />
              <Anchor.Link href="#seo-description-lenght" title="How long should a product description be? (how many words)" />
              <Anchor.Link href="#best-practices-seo" title="List of best practices for SEO product descriptions" />
              <Anchor.Link href="#dont" title="Don’t do this" />
            </Anchor.Link>
            <Anchor.Link href="#improve-product-description" title="How can I improve my product description?" />
            <Anchor.Link href="#seo-description-examples" title="SEO description examples" />
          </Anchor>
        }
      />
      <h2 id="product-description-importance">Why is product description important?</h2>
      <p style={{ marginBottom: "20px" }}>
        The product description is very important for the buyer as he needs to know more information about the product.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Let’s imagine you don’t have any product descriptions. If the visitor has questions regarding your product he won’t find any solution.
        <b>A good product description should answer your customer's objections</b> to convert them into shoppers.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The product description is not only useful for the visitor.
        It is also very powerful when it comes to online marketing.
        Indeed, a good product description, optimized for SEO, can <b>drive thousands of visitors each month</b>.
        This is possible thanks to Google ranking.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Most internet users use Google as a search engine. If they look to buy something, they will search for it on Google.
        <b>Google ranking is like free advertising</b> for everyone.
      </p>
      <p style={{ marginBottom: "20px" }}>
        And in case you are wondering, yes you can beat Amazon, Etsy, and all big marketplaces.
        SEO is all about being relevant for Google. If your website is about dog baskets, you are more relevant than Amazon for dog-related keywords.
      </p>
      <h2 id="seo-in-product-description">How is SEO used in a product description?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Product descriptions can be optimized for SEO. But in order to do it in a relevant way, you must follow a specific path and <b>SEO strategy</b>.
      </p>
      <h3>Strategic keyword placement
      </h3>
      <p style={{ marginBottom: "20px" }}><u>There are multiple places where you can optimize your SEO</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Product Title
        </li>
        <li>
          Product URL
        </li>
        <li>
          Meta Title
        </li>
        <li>
          Meta description
        </li>
        <li>
          Images
        </li>
        <li>
          Description
        </li>
      </ul>
      <img src={keywordPlacement} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="SEO Elements of a product page"/>
      <p style={{ marginBottom: "20px" }}>
        Let’s run through all of them.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Product title</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Your product title should be your <b>focus keyword</b>. This is the keyword you want to rank on Google.
        Note that some keywords are more difficult to rank on than others. This is why in most cases, it is better to go specific.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Here is a quick example: The keyword “Dog basket” may have 100.000 searches a month but it will be very difficult to rank even on the first page for it.
        You may arrive on page 20 even with a well-optimized description and collect 20 visits a month.
        On the other hand, if you target the “Brown dog basket” keyword, this one may have 5.000 visits a month but you will rank higher easier and maybe collect 100 visits a month.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Product URL</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Your product URL must be short</b> and if possible write a variation for your keyword. For example “url.com/dog-bed” for your keyword “dog basket”.
        Note that the variation is not useful for larger keywords.
        They are great for very specific keywords as they will allow you to rank on larger search requests.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Meta Title</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Your meta title is the one the user will see on the search result page on Google.
        This <b>should be your exact target keyword</b> (same as the product title) + end with your website name. For example “Brown Dog Basket | My dog store”
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Meta Description</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        The meta description is NOT the description! It is the small description that is shown just under the Meta title on the Google search page.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The meta description is <b>useful to stand out on Google</b>.
        Make sure to write your target keyword in the description as it will be highlighted if the user types it on Google.
        Note that the meta description should be <b>between 147 and 155 letters</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Images</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        You may be wondering why images are relevant when it comes to keywords.
        In fact, it is very useful as you can <b>add alt-text to images</b>. Alt-text help Google understand what your image is about.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Make sure that your main product image has your keyword as the title.
        Then, if you have other product images, write variations or image descriptions (be concise).
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Description</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Descriptions are the most important factor for ranking (just after the product title of course).
        They help Google understand what your product page is about. Descriptions shouldn’t introduce new keywords.
        This means that <b>you should always be referring to your target keyword</b> (the product title).
        This makes sense but the most common mistake is keyword stuffing.
      </p>
      <p style={{ marginBottom: "20px" }}>Just make sure not to repeat your keyword 20 times.
      </p>
      <h2 id="seo-description-best-practices">How to write SEO-friendly product descriptions? (best practices)
      </h2>
      <h3 id="google-algorithm">Understanding Google algorithm
      </h3>
      <img src={googleAlgorithm} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="Google Algorithm SEO"/>
      <p style={{ marginBottom: "20px" }}>
        Google is a search engine that is looking to become an answer engine. This means that today you type your request on Google to find relevant results.
        Tomorrow, Google will directly give you the answer.
      </p>
      <p style={{ marginBottom: "20px" }}>
        This approach means that your product description should first of all be helpful and relevant to your target keyword.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>There are 4 types of requests on Google</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Informational</b>: the user is asking a question and wants information. Example: “How to write good product description”
        </li>
        <li>
          <b>Navigational</b>: the user is looking for a specific website. Example: “Amazon”
        </li>
        <li>
          <b>Commercial</b>: the user wants to learn more about a product. Example: “What is the best dog basket?”
        </li>
        <li>
          <b>Transactional</b>: the user wants to buy something. Example: “RGB led strip 5 meters”
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        When you understand that, you start understanding that <b>your page must have the correct purpose</b>.
        For example, you will never rank a product page on an informational request as Google will know that you are not relevant to the user intent.
      </p>
      <h3 id="element-list-seo-description">What should I write in my product description?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Depending on the type of product you sell, descriptions may be a bit different.
      </p>
      <p style={{ marginBottom: "20px" }}>
        First of all, <b>start with a paragraph that will relate to your prospect's problems</b> and situation.
        We will talk about an important marketing technique later in this article.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Nest, <b>make a list of benefits</b>. You can detail each benefit with technical explanations.
        Make sure that each technical element is proven with facts or numbers.
        For example, if you explain that your product helped a lot of people, make sure to demonstrate that with precise numbers and proofs (reviews, news articles,...).
      </p>
      <p style={{ marginBottom: "20px" }}>
        Lastly, <b>include your warranties</b> in the description to remind the prospect that you are trustworthy.
        Also, make sure to highlight your delivery time if it is fast enough (less than 4 days).
      </p>
      <p style={{ marginBottom: "20px" }}>
        All along the description, use vocabulary that relates to your product title to be SEO friendly.
        <b>Use subtitles (H2, H3)</b> that arrange your description and include important words in the titles.
      </p>
      <h3 id="seo-description-lenght">How long should a product description be? (how many words)</h3>
      <img src={desciptionLenght} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="How long should a product description be?"/>
      <p style={{ marginBottom: "20px" }}>
        <b>Between 200 and 400 words. </b><br />It will really depend on the keyword competition.
      </p>
      <p style={{ marginBottom: "20px" }}>
        If your main product keyword is “Brown dog basket”, you must look at your competitors to see how many words they write in their product descriptions.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Therefore, search your keyword on Google, click on the 5 first results and check how many words they have in their description.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Then, just write more words in yours. Note that you should still be very user-friendly and relevant. Don’t talk without saying anything useful.
      </p>
      <h3 id="best-practices-seo">List of best practices for SEO product descriptions
      </h3>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Anticipate</b> prospect objections
        </li>
        <li>
          Add relevant and <b>quality images</b> that stand out from the other images in Google image
        </li>
        <li>
          <b>Use sub-titles</b> (H2 and H3) to organize your description
        </li>
        <li>
          <b>Add images or GIFs</b> within your product description if your need to illustrate something you explained.
        </li>
        <li>
          <b>Use lists and bullet points</b> as they are useful to Google.
        </li>
        <li>
          Make sure to <b>add relevant tags</b> to your product. Each tag helps the user connect to similar products.
        </li>
        <li>
          <b>Use natural language</b>, and don’t go overcomplicated.
        </li>
      </ul>
      <h3 id="dont">Don’t do this
      </h3>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Don’t be commercial-only</b>. We often read that you should talk about benefits, not features, etc…
          But in fact, it is not that true. To rank on Google, and to convert visitors into buyers, it is important to be technical.
          Technical words will help Google understand what your product is about. It also really depends on your audience's awareness.
          If you are talking to an audience that knows nothing about your product, being technical is not relevant because they won’t understand.
        </li>
        <li>
          <b>Don’t copy-paste</b>! Indeed, you should never copy from a competitor as this is punished by Google.
          Meaning that if you get caught, Google will drop you far away in the search result page and your visits will drop drastically.
          This is called duplicate content. Note that this happens if you really go hard on copy-pasting from competitors.
          You shouldn’t be afraid of copying your warranties and some information on each of your product pages.
        </li>
      </ul>
      <h2 id="improve-product-description">How can I improve my product description?
      </h2>
      <p style={{ marginBottom: "20px" }}>SEO is an important digital marketing skill. It allows you to understand how to write content, how to create a website that will rank on Google,
        how to monetize free traffic, and how to build a very long-term e-commerce business.</p>
      <p style={{ marginBottom: "20px" }}>
        <b>You can learn a lot about SEO in our last 01supply Academy course: <a className="blue" href="/academy/seo-product-description-course" alt="SEO Product Description course" target="blank_"><u>SEO Description</u></a></b>
      </p>
      <h3>Understanding what your audience is looking for
      </h3>
      <p style={{ marginBottom: "20px" }}>
        <b>Each person has a certain level of awareness</b> and has his own idea in mind when looking for something online.
        Meaning that you should always take this into consideration.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here are 5 levels of audience awareness</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Unaware</b>: They have never heard of your product or solution. For SEO, this is not frequent as they already typed your product on Google.
          Unawareness is more common in advertising for example.
        </li>
        <li>
          <b>Problem aware</b>: If you sell an innovative and new kind of vacuum cleaner, your audience is aware of the house cleaning problem.
          But they don’t know about your new vacuum cleaner.
        </li>
        <li>
          <b>Solution aware</b>: Let’s say you sell shampoo, your audience knows this product, and in that case, your role is to prove you are better than the competitors.
        </li>
        <li>
          <b>Product aware</b>: these people know your product but they are not ready to buy yet. They need more arguments in favor of your product.
        </li>
        <li>
          <b>Most aware</b>: In that case, they know everything about your brand. They just need a good reason to buy now. For example a flash sale, or anything like that.
        </li>
      </ul>
      <img src={awareness} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="Customer awareness levels"/>
      <p style={{ marginBottom: "20px" }}>
        Based on these pieces of information, we can conclude that your description must be relevant to each profile. The more content and information you add, the better.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Now we can learn how to arrange everything in order to convert and get sales.
      </p>
      <h3>How do you write product descriptions to grow online sales?
      </h3>
      <p style={{ marginBottom: "20px" }}>
        The art of turning visitors into buyers with words is called copywriting. Here is a simple technique you can follow to reach the product description. It is called “AIDA”.
      </p>
      <p style={{ marginBottom: "20px" }}>
        AIDA refers to “Attention”, “Interest”, “Desire”, and “Action”.
      </p>
      <img src={aida} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="Aida model  SEO"/>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Attention</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Grab your visitor's attention. The easiest way to achieve this is by asking a question that relates to his situation.
        For example, if your customer is looking for a dog comb, start your description with this question: “Are you tired of your dog’s hair all over your apartment floor? ”
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Interest</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Introduce your <b>product benefits</b> that solve the consumer problem. For example “Our dog comb is designed to eliminate dead hairs from your dog in seconds”
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Desire</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Now that the customer is aware of your product, it’s time to convince him. Highlight important differentiation factors and cool features.
        For example: “Our dog comb is based on a new technology that reinforces your dog hairs allowing them to get stronger and last longer”
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Action</u>:</b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Now that your prospect is convinced, let’s close the deal. Offer a discount or a special deal to make him buy right now.
      </p>
      <p style={{ marginBottom: "20px" }}>
        We recommend you use this AIDA model as the first paragraph of your product description. Next, you can list benefits, features, warranties, etc…
      </p>
      <h2 id="seo-description-examples">SEO description examples
      </h2>
      <h3>Product Title</h3>
      <p style={{ marginBottom: "20px" }}>Brown dog basket
      </p>
      <h3>Product URL</h3>
      <p style={{ marginBottom: "20px" }}>01supply.com/product/brown-dog-bed
      </p>
      <h3>Meta Title</h3>
      <p style={{ marginBottom: "20px" }}>Brown dog basket | 01supply
      </p>
      <h3>Meta description</h3>
      <p style={{ marginBottom: "20px" }}>
        Discover our brand-new brown dog basket. Your dog will love it thanks to its new design improving softness and comfort. Made out of eco and local materials.
      </p>
      <h3>Images</h3>
      <img src={dogBasket} style={{ width: "100%", marginTop: "20px", marginBottom:"20px" }} alt="dog basket example seo"/>
      <p style={{ marginBottom: "20px" }}>
        Alt text: Brown dog basket
      </p>
      <h3>Description</h3>
      <div style={{backgroundColor:"#f4f4f4", padding:"20px", marginBottom: "20px", borderRadius:"20px"}}>
      <p style={{ marginBottom: "20px" }}>
        <b>
          Your dog needs a great basket!
        </b></p>
      <p style={{ marginBottom: "20px" }}>
        This dog basket is a great place to give your lovely pet a quiet place to escape from the hustle and bustle of the day.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Caring for your pet can be quite stressful at times, but with the brown dog basket, you can allow your pet the time it needs to relax and socialize.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The brown dog basket is made with soft eco material, filled with comfortable and soft materials for your pet's comfort and safety.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Get a Brown Dog Basket today!
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Specifications:
        </b></p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Soft and comfort
        </li>
        <li>
          Made out of heavy-duty nylon
        </li>
        <li>
          Size: 93cm x 183cm
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}><b>Warranties: </b></p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Shipping worldwide within 3 days
        </li>
        <li>
          Free returns
        </li>
        <li>
          24/7 support
        </li>
      </ul>
      </div>
      <p style={{ marginBottom: "20px" }}>
        <b>You can learn a lot about SEO in our last 01supply Academy course: <a className="blue" href="/academy/seo-product-description-course" alt="SEO Product Description course" target="blank_"><u>SEO Description</u></a></b>
      </p>

    </div>
  );
};

export default Content;
