import React, { useState, useEffect } from "react";
import { url } from "../../api/url";
import { header } from "../../api/header";
import Product from '../../components/admin/manageThematics/Product'
import {Pagination} from 'antd'
import {category} from '../../data/Category'

const AssignThematic = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [createrId, setCreaterId] = useState('')
  const [products, setProducts] = useState([])
  const [thematics, setThematics] = useState([])
  const [noProductsBool, setNoProductsBool] = useState(false)
  const [noThemCategoryBool, setNoThemCategoryBool] = useState(true)
  const [loadProducts, setLoadProducts] = useState(true)
  const [defaultPage, setDefaultPage] = useState(1)
  const [allProducts, setAllProducts] = useState([])
  const [stockProducts, setStockProducts] = useState([])
  const [pageSize, setPageSize] = useState(50)
  const [endList, setEndList] = useState(false)

  useEffect(() => {
    let createrId = window.location.href.split("?");
    if (createrId[1]) createrId = createrId[1];
    else createrId = "";

    setCreaterId(createrId)

    if (createrId) getSupplierProduct(createrId);

    findThematics()
    //getProducts(1, 50)
  }, []);

  useEffect(() => {
    changePage(1, 50)
  }, [allProducts])

  useEffect(() => {
    getAllProducts()

  }, [noThemCategoryBool])

  const changePage = (page, pageSize) => {


      let skip = (parseInt(page)*parseInt(pageSize))-pageSize
      let limit = parseInt(pageSize)+skip
      let newProducts = allProducts.filter((product, index) => {
          return (index >= skip && index < limit)
      })

      setProducts([...newProducts])
      setLoadProducts(false)
      setEndList(false)
      setStockProducts([...newProducts])

  }

  const getAllProducts = async () => {
    let res = {}
    if(noThemCategoryBool)
      res = await fetch(`${url}/product/find-with-no-thematic`);
    else
      res = await fetch(`${url}/product/find-with-thematic`);

      if(res.status === 200){
          const resJson = await res.json()
          if(resJson.data){
              setAllProducts(resJson.data)
          }
      }
  }

  const getSupplierProduct = async (createrId) => {
    const res = await fetch(`${url}/product/find-by-creater/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      if(resJson.data.length)
      setProducts([...resJson.data].reverse());
      else setNoProductsBool(true)
    }
  };

  const findThematics = async (createrId) => {
    const res = await fetch(`${url}/thematic/find-all`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setThematics(resJson.thematics)
    }
  };

  return (
    <div
      style={{ padding: "50px", paddingLeft: "5vw", paddingRight: "5vw" }}
      className="page"
    >
        <div style={{width: '100%', alignItems: 'center'}} className="column">
            <div style={{marginBottom: '30px', marginTop: '20px'}} className="row">
                <button onClick={() => setNoThemCategoryBool(true)} className={noThemCategoryBool ? 'button-header-active' : 'button-header'}>
                    No thematic assign
                </button>
                <button onClick={() => setNoThemCategoryBool(false)} className={!noThemCategoryBool ? 'button-header-active' : 'button-header'}>
                    Thematic already assign
                </button>
            </div>
            {products.length && !noProductsBool ? products.map(product => {
                if((noThemCategoryBool && !(product.thematic && product.thematic.name)) || (!noThemCategoryBool && (product.thematic && product.thematic.name)))
                return(
                    <Product key={product._id} categorys={category} product={product} thematics={thematics}/>
                )
            })
            : noProductsBool && <p style={{fontSize: "30px"}}>No products</p> }
            <Pagination
            key={products.length}
            disabled={loadProducts}
            style={{marginBottom: '50px', marginTop: '30px'}}
            showSizeChanger
            onChange={(page, pageSizeP) => {
                if(page === 0)
                page = 1

                setDefaultPage(page)
                setLoadProducts(true)
                setPageSize(pageSizeP)

                    changePage(page, pageSizeP)
                }}
            defaultCurrent={parseInt(defaultPage)}
            total={allProducts.length}
            pageSize={pageSize}
                />

        </div>
    </div>
  );
};

export default AssignThematic;
