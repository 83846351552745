import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Divider } from "antd";

import checkIcon from "../../images/icons_white/check.svg";
import { routes } from "../../routes";

const BuyBanner = ({ title, list, price, previousPrice, img, alt, courseId }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="course-buy-banner-container" style={{}}>
      <img alt={alt} className="course-buy-banner-img" src={img} />
      <div className="course-items-right-container">
        {title}
        <Link
          style={{ width: "180px", margin: "20px 0", background: "black" }}
          className="button orange-back"
          title="course payment"
          to={`${routes.coursePayment(i18n.language).path}?id=${courseId}`}
        >
          <span style={{ fontSize: `18px` }}>{t("components.course.15")}</span>
        </Link>
        {list}
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <p className="title-bold" style={{ fontSize: "30px" }}>
          <span
            style={{
              textDecoration: "line-through",
              fontWeight: "300",
            }}
          >
            {previousPrice}
          </span>{" "}
          {price}
        </p>
        <p style={{ fontSize: "13px", marginTop: "20px" }} className="text-bold white">
          <img src={checkIcon} style={{ height: "15px", marginRight: "10px" }} />
          {t("components.course.15")}
        </p>
      </div>
    </div>
  );
};

export default BuyBanner;
