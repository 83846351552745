import React from "react";

import { productCategories } from "../../../../constants/productCategories";

import arrowImg from "../../../../images/icons/right_arrow.svg";

const categoryName = "Tools";

const Tools = ({ handleChangeCategory, categorySelect, windowSize, t, isDropDownDisplay }) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const Overlay = () => (
    <div id="dropdown-marketplace" className="jewel-menu">
      <p
        style={{ fontSize: "14px" }}
        className="text-bold pointer"
        onClick={() => handleChangeCategory("Tools")}
      >
        {t("components.header.all2")} {t("components.header.30")}{" "}
        <img style={{ width: "15px", marginLeft: "5px" }} src={arrowImg} alt="all products" />
      </p>
      <div style={{ marginTop: "25px" }} className="row width-100 start">
        {Object.keys(productCategories[categoryName]).map((category) => {
          return (
            <div key={category} className="column">
              <p
                onClick={(e) => {
                  handleChangeCategory(`Tools_${category}`);
                  stopPropagation(e);
                }}
                style={{ marginBottom: "5px", fontSize: "18px" }}
                className="title-bold pointer"
              >
                {t("components.header.categories." + category)}
              </p>
              {productCategories[categoryName][category].map((subCategory) => (
                <p
                  style={{ lineHeight: "30px" }}
                  onClick={(e) => {
                    handleChangeCategory(`Tools_${category}_${subCategory.productCategory}`);
                    stopPropagation(e);
                  }}
                  className="title"
                >
                  {t("components.header.subCategories." + subCategory.id)}
                </p>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <p
        onClick={() => handleChangeCategory("Tools")}
        style={
          windowSize > 1150
            ? { cursor: "pointer", fontSize: "15px", width: "100px" }
            : { cursor: "pointer", fontSize: "15px", marginTop: "20px", width: "100px" }
        }
        className={
          categorySelect?.includes("Tools") ? `text-bold underline jewel-navbar center` : "text jewel-navbar center"
        }
      >
        {t("components.header.30")}

        {isDropDownDisplay && <Overlay />}
      </p>
    </>
  );
};

export default Tools;
