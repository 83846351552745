import { message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const LinkCard = ({ title, thematics, link, monetization, index, user }) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(
      function () {
        message.success(t("affiliateDashboard.links.linkIsCopy"));
      },
      function (err) {}
    );
  };

  return (
    <div style={{ marginBottom: "30px" }} className="card">
      <div className="row-wrap width-100 start">
        <div className="column left-column start">
          <p className="title montserrat-700">{title}</p>
          <div className="link-div">
            <div style={index === 0 ? { height: "85px" } : {}} className="link-left">
              <p>{link}</p>
            </div>
            <div style={index === 0 ? { height: "85px" } : {}} onClick={handleCopy} className="link-right">
              {t("affiliateDashboard.links.copyLink")}
            </div>
          </div>
          <p className="title montserrat-700 affiliation-percentage">
            {t("affiliateDashboard.links.affiliationPercentage")}{" "}
            <span style={{ color: "#2AAB37" }}>{user.affiliate?.my_commission_percentage || 20}%</span>
          </p>
        </div>
        <div className="column right-column">
          <p className="title montserrat-700">{t("affiliateDashboard.links.thematic")}</p>
          <p>{thematics}</p>
          <p style={{ marginTop: "20px" }} className="title montserrat-700">
            {t("affiliateDashboard.links.monetization")}
          </p>
          {monetization}
        </div>
      </div>
    </div>
  );
};

export default LinkCard;
