import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { Button } from "antd";

import { Modal } from "../../wrappers";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import { header } from "../../api/header";
import { url } from "../../api/url";
import CheckToken from "../../utils/RedirectLogin";

const Setup = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [user, setUser] = useState({});
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [viewVideo, setViewVideo] = useState("");

  const { t, i18n } = useTranslation();

  const [nbInstructions, setNbInstruction] = useState(0);
  const [steps, setSteps] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  });

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  useEffect(() => {
    getUser();
  }, [idAccount, secretId]);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    let newSteps = { ...steps };
    let nbStep = 0;
    if (
      (user.stripe && user.stripe.account_completed) ||
      (user.paypal && user.paypal.account_completed) ||
      user.paypal_email_account ||
      user.iban
    ) {
      newSteps.step1 = true;
      nbStep += 1;
    }

    if (
      user.firstName &&
      user.lastName &&
      user.company &&
      user.company.name &&
      user.company.phone &&
      user.company.address
    ) {
      newSteps.step2 = true;
      nbStep += 1;
    }

    if (user.shop && user.shop.name && user.shop.describe) {
      newSteps.step3 = true;
      nbStep += 1;
    }

    if (user.shipping && user.shipping.length > 0) {
      newSteps.step4 = true;
      nbStep += 1;
    }

    if (user.products_id && user.products_id.length > 0) {
      newSteps.step5 = true;
      nbStep += 1;
    }

    setSteps(newSteps);
    setNbInstruction(nbStep);
  }, [user]);

  return (
    <div className="page">
      <CheckToken />
      {typeAccount === "seller" && <Redirect to="/setup-reseller" />}
      <div className="page-start">
        {viewVideo && (
          <Modal
            className="modal-1000"
            onOk={() => {}}
            visible={true}
            onCancel={() => setViewVideo("")}
            footer={[
              <Button key="back" onClick={() => setViewVideo("")}>
                {t("cancel")}
              </Button>,
            ]}
            style={{ width: "80vw" }}
          >
            <div className="column-center">
              <iframe
                width={windowSize > 1150 ? "800px" : "280px"}
                height={windowSize > 1150 ? "400px" : "280px"}
                src={viewVideo}
                title={viewVideo}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Modal>
        )}
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150 ? { paddingLeft: "30px", paddingTop: "150px" } : { paddingBottom: "100px" }
          }
          className="column-scrolling"
        >
          {windowSize > 1150 ? (
            <Header location={t("setup").toUpperCase()} attribut={t("setup").toUpperCase()} />
          ) : (
            <MobileHeader nameHeader={t("setup").toUpperCase()} location={t("setup").toUpperCase()} />
          )}
          <div
            className="column"
            style={windowSize > 1150 ? { width: "85%" } : { width: "90%", marginLeft: "5%" }}
          >
            <div className="row" style={{ width: "100%" }}>
              <h1 className="title-roboto" style={{ width: "70%" }}>
                {t("supplierSetup.1")}
              </h1>
              <h2 style={{ width: "30%", textAlign: "end" }} className="title-roboto-bold">
                {nbInstructions}/5
              </h2>
            </div>
            <div className="row" style={{ width: "100%", marginBottom: "50px" }}>
              <p className="text-roboto-bold" style={{ width: "70%" }}>
                {t("supplierSetup.2")}
              </p>
            </div>
            <div
              className="row"
              style={
                windowSize > 1150
                  ? { width: "100%", marginBottom: "30px" }
                  : { width: "100%", flexWrap: "wrap" }
              }
            >
              <p className="title-roboto" style={windowSize > 1150 ? { width: "60%" } : { width: "90%" }}>
                <span style={{ marginRight: "5px" }} className="title-roboto-bold">
                  {t("step")} 1
                </span>
                : {t("supplierSetup.3")}
              </p>
              <div
                style={
                  windowSize > 1150
                    ? {
                        width: "40%",
                        textAlign: "end",
                        justifyContent: "flex-end",
                      }
                    : {
                        width: "auto",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        marginBottom: "40px",
                        marginTop: "10px",
                      }
                }
                className="title-roboto-bold row"
              >
                <p
                  style={{ marginRight: "10px" }}
                  onClick={() => setViewVideo("https://www.youtube.com/embed/CI08wPZG4jM")}
                  className="text-gradient"
                >
                  {t("supplierSetup.4")}
                </p>
                {steps.step1 === true ? (
                  <button className="button-disable-orange">Done</button>
                ) : (
                  <Link
                    style={{
                      fontSize: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    to="/payment-methods"
                    className="button orange-back"
                  >
                    {t("supplierSetup.5")}
                  </Link>
                )}
              </div>
            </div>
            <div
              className="row"
              style={
                windowSize > 1150
                  ? { width: "100%", marginBottom: "30px" }
                  : { width: "100%", flexWrap: "wrap" }
              }
            >
              <p className="title-roboto" style={windowSize > 1150 ? { width: "70%" } : { width: "90%" }}>
                <span style={{ marginRight: "5px" }} className="title-roboto-bold">
                  {t("step")} 2
                </span>
                : {t("supplierSetup.6")}
              </p>
              <div
                style={
                  windowSize > 1150
                    ? {
                        width: "30%",
                        textAlign: "end",
                        justifyContent: "flex-end",
                      }
                    : {
                        width: "auto",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        marginBottom: "40px",
                        marginTop: "10px",
                      }
                }
                className="title-roboto-bold row"
              >
                {steps.step2 === true ? (
                  <button className="button-disable-orange">Done</button>
                ) : (
                  <Link
                    style={{
                      fontSize: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    to="/your-informations"
                    className="button orange-back"
                  >
                    {t("supplierSetup.5")}
                  </Link>
                )}
              </div>
            </div>
            <div
              className="row"
              style={
                windowSize > 1150
                  ? { width: "100%", marginBottom: "30px" }
                  : { width: "100%", flexWrap: "wrap" }
              }
            >
              <p className="title-roboto" style={windowSize > 1150 ? { width: "60%" } : { width: "90%" }}>
                <span style={{ marginRight: "5px" }} className="title-roboto-bold">
                  {t("step")} 3
                </span>
                : {t("supplierSetup.7")}
              </p>
              <div
                style={
                  windowSize > 1150
                    ? {
                        width: "40%",
                        textAlign: "end",
                        justifyContent: "flex-end",
                      }
                    : {
                        width: "auto",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        marginBottom: "40px",
                        marginTop: "10px",
                      }
                }
                className="title-roboto-bold row"
              >
                {steps.step3 === true ? (
                  <button className="button-disable-orange">Done</button>
                ) : (
                  <Link
                    style={{
                      fontSize: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    to="/your-shop-supplier"
                    className="button orange-back"
                  >
                    {t("supplierSetup.8")}
                  </Link>
                )}
              </div>
            </div>
            <div
              className="row"
              style={
                windowSize > 1150
                  ? { width: "100%", marginBottom: "30px" }
                  : { width: "100%", flexWrap: "wrap" }
              }
            >
              <p className="title-roboto" style={windowSize > 1150 ? { width: "57%" } : { width: "90%" }}>
                <span style={{ marginRight: "5px" }} className="title-roboto-bold">
                  {t("step")} 4
                </span>
                : {t("supplierSetup.9")}
              </p>
              <div
                style={
                  windowSize > 1150
                    ? {
                        width: "43%",
                        textAlign: "end",
                        justifyContent: "flex-end",
                      }
                    : {
                        width: "auto",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        marginBottom: "40px",
                        marginTop: "10px",
                      }
                }
                className="title-roboto-bold row"
              >
                {steps.step4 === true ? (
                  <button className="button-disable-orange">Done</button>
                ) : (
                  <Link
                    style={{
                      fontSize: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    to="/manage-shipping-methods"
                    className="button orange-back"
                  >
                    {t("supplierSetup.10")}
                  </Link>
                )}
              </div>
            </div>
            <div
              className="row"
              style={
                windowSize > 1150
                  ? { width: "100%", marginBottom: "30px" }
                  : { width: "100%", flexWrap: "wrap" }
              }
            >
              <p className="title-roboto" style={windowSize > 1150 ? { width: "70%" } : { width: "90%" }}>
                <span style={{ marginRight: "5px" }} className="title-roboto-bold">
                  {t("step")} 5
                </span>
                : {t("supplierSetup.11")}
              </p>
              <div
                style={
                  windowSize > 1150
                    ? {
                        width: "30%",
                        textAlign: "end",
                        justifyContent: "flex-end",
                      }
                    : {
                        width: "auto",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        marginBottom: "40px",
                        marginTop: "10px",
                      }
                }
                className="title-roboto-bold row"
              >
                <p
                  style={{ marginRight: "10px" }}
                  onClick={() => setViewVideo("https://www.youtube.com/embed/DkfsXZ7ABT4?start=28")}
                  className="text-gradient"
                >
                  {t("supplierSetup.5")}
                </p>
                {steps.step5 === true ? (
                  <button className="button-disable-orange">Done</button>
                ) : (
                  <Link
                    style={{
                      fontSize: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    to="/inventory"
                    className="button orange-back"
                  >
                    {t("supplierSetup.12")}
                  </Link>
                )}
              </div>
            </div>
            <a
              style={{ width: "100%", textAlign: "center" }}
              href="https://www.01supply.com/help"
              className="text-gradient"
            >
              {t("supplierSetup.13")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;
