import Raact from "react";
import { useTranslation } from "react-i18next";
import { Radio, Switch, Space } from "antd";

const ChangedPrice = ({ user, handleUpdateSetting }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "30px" }} className="column">
      <p className="title-500 start">{t("updateAlerts.title-5")}</p>
      <Radio.Group
        style={{ marginBottom: "5px", marginTop: "15px" }}
        className="blue"
        defaultValue={
          user?.updateAlerts?.changedPrice?.setQuantity
            ? "setQuantity"
            : user?.updateAlerts?.changedPrice?.setPrice
            ? "setPrice"
            : "notSetQuantity"
        }
        onChange={(e) =>
          handleUpdateSetting({
            updateAlerts: {
              ...user.updateAlerts,
              changedPrice:
                e.target.value === "setPrice"
                  ? {
                      ...user?.updateAlerts?.changedPrice,
                      setPrice: true,
                      setQuantity: false,
                    }
                  : {
                      ...user?.updateAlerts?.changedPrice,
                      setQuantity: e.target.value === "notSetQuantity" ? false : true,
                      setDraft: false,
                    },
            },
          })
        }
      >
        <Space direction="vertical">
          <Radio value="notSetQuantity">{t("updateAlerts.doNothing")}</Radio>
          <Radio value="setQuantity">{t("updateAlerts.setQuantityToZero")}</Radio>
          <Radio value="setPrice">{t("updateAlerts.updatePrice")}</Radio>
        </Space>
      </Radio.Group>
      <p className="text grey">{t("updateAlerts.updatePrice-description")}</p>

      <div style={{ marginTop: "20px", marginBottom: "15px" }} className="row">
        <Switch
          defaultChecked={user?.updateAlerts?.changedPrice?.notify}
          style={{ marginRight: "10px" }}
          onChange={(checked) => {
            handleUpdateSetting({
              updateAlerts: {
                ...user.updateAlerts,
                changedPrice: {
                  ...user?.updateAlerts?.changedPrice,
                  synchronizeCost: !user?.updateAlerts?.changedPrice?.synchronizeCost,
                },
              },
            });
          }}
        />
        <p style={{ fontSize: "15px" }} className="text montserrat">
          {t("updateAlerts.synchronizeCost")}
        </p>
      </div>

      <div className="row">
        <Switch
          defaultChecked={user?.updateAlerts?.changedPrice?.notify}
          style={{ marginRight: "10px" }}
          onChange={(checked) => {
            handleUpdateSetting({
              updateAlerts: {
                ...user.updateAlerts,
                changedPrice: {
                  ...user?.updateAlerts?.changedPrice,
                  notify: !user?.updateAlerts?.changedPrice?.notify,
                },
              },
            });
          }}
        />
        <p style={{ fontSize: "15px" }} className="text montserrat">
          {t("updateAlerts.notifyMe")}
        </p>
      </div>
    </div>
  );
};

export default ChangedPrice;
