import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, message, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {Modal} from '../../wrappers'
import VariantMapping from "../variantMapping";
import Load from "../../utils/Load";
import { productServices } from "../../services/products";
import { userServices } from "../../services/user";

import arrowImg from "../../images/icons/arrow.svg";
import tooltipIcon from "../../images/icons/toolip.png";
import closeImg from "../../images/icons_white/Close.svg";
import shopifyLogoImg from "../../images/icons/shopify-icon.png";
import supplyLogoImg from "../../images/icons/logo_01supply.svg";

import "./style.scss";

const TooltipUrl = ({ text }) => (
  <Tooltip style={{ padding: "10px", height: "auto" }} title={<div>{text}</div>}>
    <img
      src={tooltipIcon}
      alt="url information"
      style={{ height: "20px", marginBottom: "10px", marginLeft: "10px" }}
    />
  </Tooltip>
);

const ProductLinkCMS = ({
  productProps,
  defaultShopifyProductId,
  setIsDisplayed,
  user,
  getProducts,
  getUser,
}) => {
  const [isSaveButtonDisplayed, setIsSaveButtonDisplayed] = useState(false);
  const [load, setLoad] = useState(false);
  const [shopifyProduct, setShopifyProduct] = useState();
  const [product, setProduct] = useState(productProps && !productProps.isDelete ? productProps : {});
  const [variantsMapping, setVariantsMapping] = useState([]);
  const [shopifyProductUrl, setShopifyProductUrl] = useState("");
  const [localProductUrl, setLocalProductUrl] = useState("");

  const isUpdateLink = !!productProps?._id;

  const { t } = useTranslation();

  const findShopifyProduct = async () => {
    const res = await productServices.findShopifyProduct({ productId: defaultShopifyProductId });
    const resJson = await res.json();
    if (resJson.data) {
      setShopifyProduct(resJson.data);
    }

    // Quand le produit Shopify n'a que 1 variant, celle par défaut
    const defaultShopifyVariantMapping = shopifyProduct?.variants?.map((shopifyVariant) => ({
      shopifyVariantId: shopifyVariant.id,
      sku: product._id,
    }));
    setVariantsMapping(defaultShopifyVariantMapping);

    return await true;
  };

  useEffect(() => {
    if ((defaultShopifyProductId || shopifyProduct) && !variantsMapping?.length) {
      findShopifyProduct();
    }
  }, [defaultShopifyProductId, shopifyProduct]);

  const parseProductUrlToId = (productUrl) => {
    let productId = productUrl;

    if (productUrl.includes("id=")) {
      productId = productId.split("id=")[1];
    } else if (productUrl.includes("/")) {
      productId = productId.split("/");
      productId = productId[productId.length - 1];
    }

    return productId;
  };

  const handleFind01supplyProduct = () => {
    setLoad(true);
    setIsSaveButtonDisplayed(true);
    let productId = parseProductUrlToId(localProductUrl);
    productServices
      .findByIds({ productsId: [productId] })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          message.error(t("01supplyProductNotFound"));
          setLoad(false);
        }
      })
      .then((res) => {
        setLoad(false);
        setProduct(res?.products?.[0] || {});
      });
  };

  const handleFindShopifyProduct = () => {
    setLoad(true);
    setIsSaveButtonDisplayed(true);
    let productId = parseProductUrlToId(shopifyProductUrl);
    productServices
      .findShopifyProduct({ productId })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          message.error(t("shopifyProductNotFound"));
          setLoad(false);
        }
      })
      .then((res) => {
        setLoad(false);
        setShopifyProduct(res?.data || {});
      });
  };

  const handleSubmit = async () => {
    setLoad(true);

    const linkId = productProps
      ? user.shopify_product.find((link) => link.local_id?.toString() === productProps._id?.toString())?._id
      : "";

    userServices
      .productLink({
        productId: product._id,
        cmsProductId: shopifyProduct.id,
        linkId,
        variantSkus: variantsMapping || [],
      })
      .then(async (res) => {
        const resJson = await res.json();
        if (res.status === 200) {
          productServices.checkMonitoring({ productId: product._id }).then(async (res) => {
            await getProducts();
            await getUser();
            message.success(t("messages.linkProduct.success"));
            setIsDisplayed(false);
            setLoad(false);
          });
        } else {
          setLoad(false);
          message.error(resJson.message);
        }
      });
  };

  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000"
      visible={true}
      onCancel={() => {
        setIsDisplayed(false);
      }}
      footer={[
        <div className="row width-100 end">
          <button
            style={{ width: "150px" }}
            onClick={() => setIsDisplayed(false)}
            className="button big grey-back-2"
          >
            {t("cancel")}
          </button>
          {isSaveButtonDisplayed && product?._id && shopifyProduct?.id && (
            <button
              style={{ width: "220px", marginLeft: "35px" }}
              onClick={handleSubmit}
              className="button big orange-back"
            >
              {t("validate")}
            </button>
          )}
        </div>,
      ]}
    >
      {load && <Load />}
      <div className="column start width-100">
        <p style={{ marginBottom: "20px" }} className="title montserrat-800">
          {t("productLinking")}
        </p>
        <p style={{ marginBottom: "30px" }} className="grey-back-3">
          {t('productLinkingDescription')}
        </p>

        <div className="product-link row row-wrap width-100 space-between">
          {product?._id ? (
            <div className="part">
              <p style={{ marginBottom: "15px" }} className="text-bold">
                <img
                  src={supplyLogoImg}
                  alt="01supply logo"
                  style={{ height: "23px", marginRight: "10px" }}
                />
                {t("01supplyProduct")}
              </p>
              <div className="product-card">
                <Link
                  className="product-img"
                  to={`/product-details?name=${product.name}&id=${product._id}`}
                  target="_blank"
                >
                  <img className="product-img" alt="product img" src={product.image || product.photos[0]} />
                </Link>
                <p className="product-title">{product.name}</p>
                <div onClick={() => setProduct({})} className="product-close">
                  <img src={closeImg} alt="close icon" style={{ height: "10px" }} />
                </div>
              </div>
            </div>
          ) : (
            <div className="part">
              <p style={{ marginBottom: "15px" }} className="text-bold">
                <img
                  src={supplyLogoImg}
                  alt="01supply logo"
                  style={{ height: "23px", marginRight: "10px" }}
                />
                {t("01supplyProduct")} URL
                <TooltipUrl text={t('01supplyProductTooltip')}/>
              </p>
              <div style={{ width: "100%" }} className="row">
                <Input
                  style={{ width: "100%" }}
                  onChange={(e) => setLocalProductUrl(e.target.value)}
                  placeholder={t("01supplyProductUrlPlaceholder")}
                />
                <button onClick={handleFind01supplyProduct} className="button-in-input orange">
                  <PlusOutlined style={{ color: "white", fontSize: "20px" }} />
                </button>
              </div>
            </div>
          )}
          <img alt="arrow" className="arrow" src={arrowImg} />
          {shopifyProduct?.id ? (
            <div className="part">
              <p style={{ marginBottom: "15px" }} className="text-bold">
                <img
                  src={shopifyLogoImg}
                  alt="shopify logo"
                  style={{ height: "25px", marginRight: "10px" }}
                />
                {t("shopifyProduct")}
              </p>
              <div className="product-card">
                <div className="product-img">
                  <img className="product-img" alt="product img" src={shopifyProduct?.images[0]?.src} />
                </div>
                <p className="product-title">{shopifyProduct.title}</p>
                <div onClick={() => setShopifyProduct({})} className="product-close">
                  <img src={closeImg} alt="close icon" style={{ height: "10px" }} />
                </div>
              </div>
            </div>
          ) : (
            <div className="part">
              <p style={{ marginBottom: "15px" }} className="text-bold">
                <img
                  src={shopifyLogoImg}
                  alt="shopify logo"
                  style={{ height: "25px", marginRight: "10px" }}
                />
                {t("shopifyProduct")} URL
                <TooltipUrl text={t('shopifyProductTooltip')}/>
              </p>
              <div style={{ width: "100%" }} className="row">
                <Input
                  style={{ width: "100%" }}
                  onChange={(e) => setShopifyProductUrl(e.target.value)}
                  placeholder={t("shopifyProductUrlPlaceholder")}
                />
                <button onClick={handleFindShopifyProduct} className="button-in-input orange">
                  <PlusOutlined style={{ color: "white", fontSize: "20px" }} />
                </button>
              </div>
            </div>
          )}
        </div>
        {product?._id &&
          shopifyProduct?.id &&
          (product?.variants?.length > 0 || shopifyProduct?.variants?.length > 1) && (
            <VariantMapping
              variantsMapping={variantsMapping}
              setVariantsMapping={setVariantsMapping}
              shopifyVariants={shopifyProduct?.variants || []}
              variants={product.variants}
              productId={product._id}
              shopifyProductId={shopifyProduct?.id}
              findShopifyProduct={findShopifyProduct}
              isUpdateLink={isUpdateLink}
              setIsSaveButtonDisplayed={setIsSaveButtonDisplayed}
              getUser={getUser}
              getProducts={getProducts}
              defaultShopifyProductId={defaultShopifyProductId}
              default01supplyProductId={productProps._id}
            />
          )}
      </div>
    </Modal>
  );
};

export default ProductLinkCMS;
