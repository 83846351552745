import React, { useEffect } from 'react';

const BlockBackButton = ({ onBackButton }) => {
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      onBackButton();
    };

    window.addEventListener('popstate', handlePopState);

    // Pour bloquer le retour en arrière, on ajoute une entrée dans l'historique
    window.history.pushState(null, null, null);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onBackButton]);

  return null;
};

export default BlockBackButton;