import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Banner from "../../../components/courses/Banner";
import Product from "../../../components/courses/Product";
import Items from "../../../components/courses/Items";
import BuyBanner from "../../../components/courses/BuyBanner";
import Faq from "../../../components/courses/Faq";
import Footer from "../../../components/menu/Footer";
import { coursesId } from "../../../constants/coursesId";

import bannerImg from "../../../images/courses/supplier/course3/banner.svg";
import productImg from "../../../images/courses/supplier/course3/seo.svg";
import item1Img from "../../../images/courses/supplier/course3/icon1.svg";
import item2Img from "../../../images/courses/supplier/course2/icon2.svg";
import item3Img from "../../../images/courses/supplier/course2/icon3.svg";
import item4Img from "../../../images/courses/supplier/course3/icon4.svg";
import item5Img from "../../../images/courses/supplier/course3/icon5.svg";
import item6Img from "../../../images/courses/supplier/course2/icon6.svg";
import pcImg from "../../../images/courses/supplier/course3/pc.svg";

import supplerAcademyLogo from "../../../images/icons/01supply_academy.svg";

const Course3 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="course-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Google SEO Algorithm course | 01supply Academy</title>
        <meta
          name="description"
          content="Learn and master Google algorithms from SEO market leaders. 
          Dive into the secrets of Google's algorithm to attract thousands of visits to your website."
        />
      </Helmet>
      <div className="course-navbar">
        <img style={{ width: "180px" }} src={supplerAcademyLogo} alt="01supply academy" />
      </div>
      <Banner
        title={
          <h1 style={{ fontSize: "45px", textShadow: "#3C3C3C 1px 0 5px" }} className="white title-600 start">
            Learn & Master
            <br /> <span style={{ color: "#FBA95E" }}>Google Algorithm</span>
            <br />
            from SEO
            <br />
            market leaders
          </h1>
        }
        text={
          <p style={{ fontSize: "16px", textShadow: "#1c1c1c 2px 0 5px"}} className="orange text-300">
            From SEO Basics to Advanced techniques
          </p>
        }
        img={bannerImg}
        alt="how to understand google algorithm for seo"
      />
      <Product
        text1={"Dive into the secrets of Google's algorithm to attract thousands of visits to your website."}
        text2={`SEO allows you to acquire visitors for free. It is basically free advertising offered by Google. Search engines have some advanced algorythm you can trick to get more visibility and sales online.`}
        difficult={"moderate"}
        img={productImg}
        price={"$199"}
        alt="Learn Google SEO Fundamentals"
        courseId={coursesId.supplier.course3}
      />
      <h2 className="course-black-banner">
      Transform your website into a free trafic acquisition machine
      </h2>
      <Items
        img1={item1Img}
        title1={`Google Algorithm is improving since 1998`}
        text1={`Google is the most used tool of the internet. It is here today, and it will be here in 10 years.`}
        img2={item2Img}
        title2={`SEO is the N°1 acquisition channel with the least competition.`}
        text2={`A lot of e-commerce owners do Facebook advertising. But most of the customers search via Google.`}
        img3={item3Img}
        title3={`SEO Mastery`}
        text3={`Learn everything about SEO theory to acquire a new online marketing skill.`}
        price1={"$149"}
        img4={item4Img}
        title4={`Google Coding`}
        text4={`Discover every optimization possible thanks to code snippets easy to copy-paste.`}
        price2={"$49"}
        img5={item5Img}
        title5={`Google Ecosystem`}
        text5={`Make your brand visible on Google using every services they offer.`}
        price3={"$49"}
        img6={item6Img}
        title6={`10 secret SEO techniques`}
        text6={`10 SEO techniques that no one uses and can will make your products rank first.`}
        price4={"$89"}
      />
      <BuyBanner
        title={
          <h2
            style={{ fontWeight: "900", fontSize: "30px" }}
            className="title-bold montserrat start width-100 white"
          >
            Everything <br />
            in one course
          </h2>
        }
        img={pcImg}
        alt="seo product
        description 
        course"
        list={
          <ul>
            <li className="text-bold">
            SEO Mastery:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $149
              </span>
            </li>
            <li className="text-bold">
            Google Coding:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $49
              </span>
            </li>
            <li className="text-bold">
            Google Ecosystem{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $49
              </span>
            </li>
            <li className="text-bold">
            10 secret SEO techniques:{" "}
              <span style={{ fontSize: "14px" }} className="text-400">
                $89
              </span>
            </li>
          </ul>
        }
        price={"$199"}
        previousPrice={"$336"}
        courseId={coursesId.supplier.course3}
      />
      <Faq />
      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Course3;
