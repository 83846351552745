import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article15/clothing-photography.jpg";
import interiorLayer from "../../../../images/blog/global/article15/add-interior-layer.jpg";
import adjustLayer from "../../../../images/blog/global/article15/adjust-the-back-layer.jpg";
import closeupPhoto from "../../../../images/blog/global/article15/closeup-photography-idea.jpg";
import hangerIdeas from "../../../../images/blog/global/article15/clothes-on-hanger-ideas.jpg";
import detourMannequin from "../../../../images/blog/global/article15/detour-mannequin.jpg";
import ecommerceClothing from "../../../../images/blog/global/article15/ecommerce-clothing-photography.jpg";
import ghostMannequin from "../../../../images/blog/global/article15/final-result.jpg";
import flatIdeas from "../../../../images/blog/global/article15/flat-laid-clothing-idea.jpg";
import foldedIdeas from "../../../../images/blog/global/article15/folded-clothes-photography-ideas.jpg";
import ghostIdeas from "../../../../images/blog/global/article15/ghost-mannequin-photography-idea.jpg";
import goodSettings from "../../../../images/blog/global/article15/good-bad.jpg";
import incontextIdeas from "../../../../images/blog/global/article15/in-context-photography-idea.jpg";
import mannequinIdeas from "../../../../images/blog/global/article15/mannequin-photography-ideas.jpg";
import mask from "../../../../images/blog/global/article15/mask-the-selection.jpg";
import interior from "../../../../images/blog/global/article15/photo-shoot-interior.jpg";
import setupMannequin from "../../../../images/blog/global/article15/setup-mannequin.jpg";
import goodMannequin from "../../../../images/blog/global/article15/take-good-mannequin-clothing-photo.jpg";
import typesClothing from "../../../../images/blog/global/article15/types-of-clothing-photography.jpg";
import hangerClothing from "../../../../images/blog/global/article15/clothing-on-a-hanger-photography.jpg";
import flatLaid from "../../../../images/blog/global/article15/flat-laying-clothes.jpg";




const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat start" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        How to shoot clothing product photography?
      </h1>
      <p className="text-300">29 september 2022 - 9 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px" }} alt="How to shoot clothing product photography?" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} className="start">
        <b>Clothing product photography is important for most e-commerce owners</b> as it often involves a large part of their catalog.
        In this article, you will learn how to shoot clothing photography even if you only have a smartphone.
      </p>
      <p style={{ marginBottom: "20px" }}>We will also talk about different types of clothing photography and full tutorial to get the best out of your shots.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#clothing-photography-needs" title="What do you need for clothing photography?" />
            <Anchor.Link href="#clothing-for-commerce" title="Clothing photography for e-commerce" />
            <Anchor.Link href="#clothing-photography-tips" title="Clothing product photography tips" />
            <Anchor.Link href="#clothing-photography-cost" title="Clothing product photography pricing" />
            <Anchor.Link href="#clothing-without a model" title="How to shoot clothes without a model?">
              <Anchor.Link href="#ghost-mannequin-effect-tutorial" title="How do you get the ghost mannequin effect?" />
              <Anchor.Link href="#clothes-on-hanger" title="How to photograph clothes on a hanger?" />
            </Anchor.Link>
            <Anchor.Link href="#flat-photography-setup" title="Flat lay clothing photography setup" />
            <Anchor.Link href="#fold-clothing-photography" title="How to fold clothes for product photography?" />
            <Anchor.Link href="#clothing-photography-ideas" title="Clothing product photography creative ideas (images)" />
            <Anchor.Link href="#clothing-photography-smartphone" title="How do you take clothes pictures with a smartphone (iPhone or Android)" />
          </Anchor>
        }
      />
      <h2 id="clothing-photography-needs">What do you need for clothing photography?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        It really depends on what final result you are looking for and it also depends on the purpose of the photo shooting.
        If you shoot for advertising billboards you will prefer professional equipment. But if you shoot for your online store, a smartphone and basic equipment are often enough.
      </p>
      <h3>Starter equipment list
      </h3>
      <ul>
        <li>
          Smartphone
        </li>
        <li>
          Sun lighting
        </li>
        <li>
          White sheet or white paper for the backdrop
        </li>
      </ul>
      <h3>Professional equipment list
      </h3>
      <ul>
        <li>
          Camera
        </li>
        <li>
          Tripod
        </li>
        <li>
          Lighting source
        </li>
        <li>
          Reflector
        </li>
        <li>
          Backdrop
        </li>
        <li>
          Model
        </li>
      </ul>
      <h2 id="clothing-for-commerce">Clothing photography for e-commerce
      </h2>
      <img src={ecommerceClothing} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="ecommerce Clothing Photography" />
      <p style={{ marginBottom: "20px" }}>It is very important to take your own product pictures for your e-commerce.
        Indeed, Google likes unique content and will rank your store higher if you have <b>unique apparel pictures</b>.
        A better ranking on Google means that your product will receive more visits and more sales.
      </p>
      <p style={{ marginBottom: "20px" }}>The product image is what your customer sees first on your website. Therefore, <b>the photo must be as professional as possible</b>.
        Image quality has a huge impact on the perceived value of the product. Indeed a poor product image quality will reflect a negative image to your visitors.
      </p>
      <p style={{ marginBottom: "20px" }}>Make sure to <b>showcase your clothes in context</b>. If you sell a t-shirt, it is important to have a model wear them because customers will identify with him.
        If you can’t afford to pay for a model, you should at least shoot your products on a plastic mannequin.
      </p>
      <p style={{ marginBottom: "20px" }}>In e-commerce, the goal is to convert visitors by overcoming any potential objections they may have.
      </p>
      <p style={{ marginBottom: "20px", textDecoration: "underline" }}>Here are three types of clothes photos you should at least have on a product page:    </p>
      <ol>
        <li><b>Global shot</b> with no decor and a white background to showcase the product
        </li>
        <li><b>In-context shot</b> with a model or a plastic mannequin
        </li>
        <li><b>Detailed shot</b> with a zoom on the product quality and finishes.
        </li>
      </ol>
      <img src={typesClothing} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="types of clothing photography" />
      <p style={{ marginBottom: "20px" }}>In short, working on your clothing shots for your e-commerce store will result in:
      </p>
      <ul>
        <li>
          Better conversion rate (meaning that you will get more sales)
        </li>
        <li>
          Increased CTR (Click through rate) on Google image
        </li>
        <li>
          Enhanced average order value
        </li>
        <li>
          More returning customers
        </li>
        <li>
          Average time spent on your store also increased
        </li>
      </ul>
      <h2 id="clothing-photography-tips">Clothing product photography tips
      </h2>
      <h3>Magic camera settings
      </h3>
      <p style={{ marginBottom: "20px" }}>When you shoot on a white background, make sure to select these camera settings (which also work with advanced smartphone settings):
      </p>
      <ul>
        <li><b>Aperture</b>: between f/8 and f/11
        </li>
        <li><b>ISO</b>: between 100 and 200
        </li>
        <li><b>Shutter speed</b>: 1/30
        </li>
      </ul>
      <img src={goodSettings} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="good settings for clothing photography" />
      <p style={{ marginBottom: "20px" }}>If you don’t apply these settings, your white background could look a bit yellow or blue depending on the lighting you use.
        It can be removed in post-processing but it takes a lot of effort which could have been avoided.
      </p>
      <h3>Iron clothes
      </h3>
      <p style={{ marginBottom: "20px" }}>Make sure to <b>iron your clothes</b> before you shoot to get the best result.
        Wrinkled clothes will decrease the perceived value of the product as it will sook used.
      </p>
      <h3>Don’t lay it down randomly
      </h3>
      <p style={{ marginBottom: "20px" }}>Either hire a model, use a plastic mannequin or hand your clothes on a hanger.
        These are the three styles you can use to showcase your clothes, especially shirts and jackets.
      </p>
      <p style={{ marginBottom: "20px" }}>Also, a good presentation will make your product more attractive to customers. </p>
      <h3>Good practices with plastic mannequins
      </h3>
      <img src={goodMannequin} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="nice setup for mannequin photo shoot" />
      <p style={{ marginBottom: "20px" }}>Don’t let the clothes get loose. Instead, always <b>make sure they are tights and take the full shape of the plastic mannequin</b>.
        If your clothing is too large, either choose a bigger mannequin or fill the gaps with tissue.
      </p>
      <p style={{ marginBottom: "20px" }}>If your plastic mannequin does not look good, you can remove it in post editing.
        This will create a ghost mannequin effect which we will talk about later.
      </p>
      <h2 id="clothing-photography-cost">Clothing product photography pricing
      </h2>
      <p style={{ marginBottom: "20px" }}>Should you hire or do the shooting yourself? This is an important question you must ask yourself depending on your budget.
      </p>
      <p style={{ marginBottom: "20px" }}>Remember that you must anticipate the photographer's cost, but also the model's cost as you shoot clothing.
        You can also ask the photographer if he has a plastic mannequin which could save a lot of money.
      </p>
      <p style={{ marginBottom: "20px" }}><u>Average costs</u>:
      </p>
      <ul>
        <li><b>Photographer</b>: $150 - $400 per hour
        </li>
        <li><b>Model</b>: $75 - $150 per hour
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>Note that these are average costs and they can vary a lot based on their experience, their equipment, etc…
      </p>
      <p style={{ marginBottom: "20px" }}>You can check your previous article to learn more about <a className="blue" target="blank_" href="https://app.01supply.com/product-photography-cost" title="product photography cost"><u>product photography pricing</u></a>.
      </p>
      <h2 id="clothing-without a model">How to shoot clothes without a model?
      </h2>
      <p style={{ marginBottom: "20px" }}>Models are important because it allows you to shoot in-context clothing images.
        For example, you can shoot someone wearing a jacket in the rain as it makes the customer imagine wearing the jacket.
      </p>
      <p style={{ marginBottom: "20px" }}>But models are not the only way to shoot in-context images. <b>You can shoot clothes on a hanger or with a plastic mannequin</b> as this will still give a nice result.
      </p>
      <p style={{ marginBottom: "20px" }}>Plastic mannequins are great as is will give the same shape as a human body. You can also edit it later to get a clear ghost mannequin effect.
      </p>
      <h3 id="ghost-mannequin-effect-tutorial">How do you get the ghost mannequin effect?
      </h3>
      <ol>
        <li>Install the shirt on the mannequin.
        </li>
        <img src={setupMannequin} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="mannequin preparation" />
        <li>Make sure that there is no direct sunlight on the mannequin (this would look weird afterward). Lighting must always be indirect (or with a diffuser).
        </li>
        <li>Shoot with the recommended settings shown above (use the RAW format to completely edit the image).
        </li>
        <li>Repeat the same process with the clothing laying down without a mannequin. This will allow you to shoot the interior.
        </li>
        <img src={interior} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="photograph interior clothing" />
        <li>Open the mannequin image in Photoshop.
        </li>
        <li>Detour the shirt with the magic wand or the manual selection tool. Don't include the mannequin in your selection.
        </li>
        <img src={detourMannequin} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="detour for ghost effect" />
        <li>Click on the fusion mask to only display your selection.
        </li>
        <img src={mask} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="mask the selection" />
        <li>Now you can import your interior picture to the project.
        </li>
        <li>Select the interior of the t-shirt neck from the second picture and delete the rest. (Ctrl+Shift+I then delete)
        </li>
        <img src={interiorLayer} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="add the interior layer" />
        <li>Place the interior layer below the first picture and place it in the right spot.
        </li>
        <li>If the interior does not fit perfectly with the mannequin image, you can use the wrap tool to adjust it.
        </li>
        <img src={adjustLayer} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="Adjust the layer to the photo" />
        <li>Now you can darken the interior image to create a realistic look (only if you think it is needed).
        </li>
        <img src={ghostMannequin} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="ghost mannequin tutorial" />
        <li>Congratulations, you now have your ghost mannequin effect created!
        </li>
      </ol>
      <h3 id="clothes-on-hanger">How to photograph clothes on a hanger?
      </h3>
      <p style={{ marginBottom: "20px" }}>Now that you know how to shoot a shirt on a mannequin, it is also good to know how to shoot it on a hanger.
      </p>
      <p style={{ marginBottom: "20px" }}>Indeed <b>hanger can give a clean and nice look to your image</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>If you want to highlight one specific clothing on your hanger, make sure to select less saturated clothes on the rest of your drawer.
        For example, if you shoot a green shirt, don’t add red and yellow shirts behind as it would be confusing.
      </p>
      <p style={{ marginBottom: "20px" }}><b>Don’t fill up your hanger completely</b>. You can add spacing and lower the number of clothes on the hanger. Too many clothes would make it messy.
        Spacing is your ally.
      </p>
      <img src={hangerClothing} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="clothes on hanger" />
      <p style={{ marginBottom: "20px" }}><u>So here is the step-by-step guide</u>: </p>
      <ul>
        <li>
          Iron your clothes
        </li>
        <li>
          Put your main shirt first and add 3-4 other clothes in the background
        </li>
        <li>
          Place your camera on a tripod and use the settings we talked bout earlier
        </li>
        <li>
          Make sure to aim for the top part of the hanger, this will give a better result
        </li>
        <li>
          Shoot using the RAW format if you want to edit your image later.
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>Tip: If you want to shoot a closeup image, <b>place your camera far away and zoom in as it will create a blur effect in the background</b>.
        Do not zoom if you are shooting with a smartphone as it would decrease the image quality.
      </p>
      <p style={{ marginBottom: "20px" }}>You can also shoot clothes on a hanger alone.
      </p>
      <h2 id="flat-photography-setup">Flat lay clothing photography setup
      </h2>
      <img src={flatLaid} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="flat lay clothing photography tutorial" />
      <p>Flat-laid photography is trending these times. Indeed it is great for Instagram and other social media posts when the image is nice looking.
      </p>
      <h3>Background</h3>
      <p style={{ marginBottom: "20px" }}>Even for flat-lay clothing photography, <b>you must choose a background</b>. This can be some colorful tissue or kraft paper.
        Don’t simply use your table or your floor unless the texture and pattern are very nice and interesting.
      </p>
      <h3>Tripod or your hands</h3>
      <p style={{ marginBottom: "20px" }}>Some tripods are capable of holding your camera looking straight down.
        This is the best option as it results in a cleaner shot without any shaking.
      </p>
      <p style={{ marginBottom: "20px" }}>If you hold your camera with your hands, make sure to stay on a stepladder or anything that could serve this purpose. </p>
      <h3>Lights</h3>
      <p style={{ marginBottom: "20px" }}>Lights shouldn’t be placed on your back as it would create shadows on your shot. The rule is exactly the same for sunlight. <b>No direct
        sunlight in product photography</b> and no direct shadow either.
      </p>
      <p style={{ marginBottom: "20px" }}>Make sure your <b>lighting is diffuse</b> and comes from every direction.
        If you only have one light source, you can use a reflector on the opposite side to nicely light your scene.
      </p>
      <h3>Decoration</h3>
      <p style={{ marginBottom: "20px" }}>The fun part about flat-lay clothing photography is that you can add a lot of items to the scene.
        For example, you can <b>add complementary items to the scene</b>. If you shoot pants, you can add shoes and socks as it makes the image more interesting.
      </p>
      <p style={{ marginBottom: "20px" }}>Make sure your complementary items are relevant. Also, <b>don’t overload your scene</b> with hundreds of items.
        5 items are the maximum you can add to this type of photography.
      </p>
      <h2 id="fold-clothing-photography">How to fold clothes for product photography?
      </h2>
      <p style={{ marginBottom: "20px" }}>Folding clothing for product photography is not often recommended unless you shoot a polo shirt that has some volume
        and texture compared to a t-shirt.
      </p>
      <p style={{ marginBottom: "20px" }}>Otherwise, folded clothing photography is great with a lot of products, for example, if you shoot a stack of
        colorful shirts to display your collection and different color variants.
      </p>
      <p style={{ marginBottom: "20px" }}><u>Here is a quick guide on how to fold clothes correctly</u>:
      </p>
      <iframe width="100%" height="400px" src="https://www.youtube.com/embed/icCL89J6w54" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <h2 id="clothing-photography-ideas">Clothing product photography creative ideas (images)
      </h2>
      <p>Now that you know a lot more about photography, here are <b>some ideas for your future clothing photography</b>:
      </p>
      <h3>Closeup</h3>
      <img src={closeupPhoto} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="closeup clothing photography ideas" />
      <h3>In-context shot</h3>
      <img src={incontextIdeas} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="in-context clothing photography ideas" />
      <h3>Plastic mannequin
      </h3>
      <img src={mannequinIdeas} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="plastic mannequin clothing photography ideas" />
      <h3>Ghost Mannequin
      </h3>
      <img src={ghostIdeas} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="ghost mannequin clothing photography ideas" />
      <h3>On a hanger
      </h3>
      <img src={hangerIdeas} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="hanger clothing photography ideas" />
      <h3>Flat lay clothing photography
      </h3>
      <img src={flatIdeas} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="flat lay clothing photography ideas" />
      <h3>Folded clothes
      </h3>
      <img src={foldedIdeas} style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} alt="folded clothing photography ideas" />
      <h2 id="clothing-photography-smartphone">How do you take clothes pictures with a smartphone (iPhone or Android)
      </h2>
      <p style={{ marginBottom: "20px" }}>Most people don’t have an expensive setup to shoot clothes.
        Indeed a nice camera can cost up to $2.000 which is very expensive if you are only shooting your creations.
      </p>
      <p style={{ marginBottom: "20px" }}>That is why <b>the smartphone is the most relevant choice for small creators</b>.
        The most recent ones have an insane photo quality which can compare to a lot of professional cameras.
      </p>
      <p style={{ marginBottom: "20px" }}>If you shoot <b>clothes for an online store</b>, it is even more true as very high-quality images are not needed.
        A smartphone will often do the job.
      </p>
      <p style={{ marginBottom: "20px" }}>The problem is that most people don’t know how to correctly set up their smartphones to shoot for product photography.
        Furthermore, lighting can make a huge difference in that case. Decoration and basic equipment are also likely to help you enhance your smartphone shots easily.
      </p>
      <p style={{ marginBottom: "20px" }}>To help small product creator shoot their creations,
        we created a full photography course that teaches you how you can turn your smartphone shots into a professional rendering.
      </p>
      <p style={{ marginBottom: "20px" }}><b>You can access the course and learn more information about
        it here: <a className="blue" href="https://app.01supply.com/academy/photo-mastery-course" target="blank_" title="Photo Course for Clothing"><u>Photo Mastery</u></a></b>
      </p>





    </div>

  );
};

export default Content;
