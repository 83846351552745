import React from "react";
import {useTranslation} from 'react-i18next'
import { InputNumber, Checkbox } from "antd";

const SellingLimit = ({ setProduct, product, windowSize }) => {

  const { t } = useTranslation()
  return (
    <div style={{ width: "100%" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        {t("components.supplier.createEditProduct.sellingLimit.1")}
      </p>
      <p style={{ marginBottom: "8px" }} className="text-bold">
        {t("components.supplier.createEditProduct.sellingLimit.2")}
        {product.maxResellerBool && windowSize > 1330 && (
          <span style={{ position: "absolute", right: "0", fontSize: "13px" }} className="text-300 green">
            {t("active")}
          </span>
        )}
      </p>
      <div style={{ width: "100%" }} className="row">
        <Checkbox
          checked={product.maxResellerBool}
          onChange={(e) => setProduct({ ...product, maxResellerBool: e.target.checked })}
        />
        <InputNumber
          min={1}
          value={product.reseller_max}
          style={{ marginLeft: "10px", width: "calc(90% - 26px)" }}
          onChange={(value) => setProduct({ ...product, reseller_max: value, maxResellerBool: true })}
        />
      </div>
      <p style={{ marginBottom: "8px", marginTop: "15px" }} className="text-bold">
        {t("components.supplier.createEditProduct.sellingLimit.3")}
        {product.maxOrderBool && windowSize > 1330 && (
          <span style={{ position: "absolute", right: "0", fontSize: "13px" }} className="text-300 green">
            {t("active")}
          </span>
        )}
      </p>
      <div style={{ width: "100%" }} className="row">
        <Checkbox
          checked={product.maxOrderBool}
          onChange={(e) => setProduct({ ...product, maxOrderBool: e.target.checked })}
        />
        <InputNumber
          min={1}
          value={product.orders_max}
          style={{ marginLeft: "10px", width: "calc(90% - 26px)" }}
          onChange={(value) => setProduct({ ...product, orders_max: value, maxOrderBool: true })}
        />
      </div>
    </div>
  );
};

export default SellingLimit;
