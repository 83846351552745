import React, { useState, useEffect, useContext } from "react";
import { Select, Radio, Space, Tree } from "antd";
import { t } from "i18next";

import { Modal } from "../../wrappers";
import { priceConverter } from "../../utils/priceConverter";
import { LocalStatesContext } from "../../providers/index";

import countryListJson from "../../utils/country_list.json";
import { getCountry } from "../../utils/getCountry";

const { Option } = Select;

const ShippingMethods = ({
  setModalOn,
  modalOn,
  product,
  setShippingSelect,
  shippingSelect,
  setShippingDestination,
  windowSize,
  countryDestination, // optional => for cart country selection
  setAvailableShippingProps, // optional => for cart country selection
}) => {
  const [shippingAvailable, setShippingAvailable] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [destinationIndexChoose, setDestinationIndexChoose] = useState("");
  const [showDestinations, setShowDestinations] = useState({});
  const [localShippingSelect, setLocalShippingSelect] = useState({});
  const [defaultShippingSelect, setDefaultShippingSelect] = useState(undefined);

  const { localStates } = useContext(LocalStatesContext);

  useEffect(() => {
    if (product && product.shipping && Array.isArray(product.shipping)) {
      let countryListRes = [];
      for (let region of countryListJson) {
        if (!region.children) {
          countryListRes.push(region);
        } else {
          countryListRes = [...countryListRes, ...region.children];
        }
      }
      const availableShippingCountries = countryListRes.filter((country) => {
        return product.shipping?.some((shipping) => {
          const countryName = getCountry(shipping.origin);

          return (
            shipping.origin.includes(country.key) ||
            countryName === country.title ||
            shipping.origin === "0-0" ||
            shipping.origin?.toLowerCase()?.includes("rest of the world")
          );
        });
      });
      setCountryList(availableShippingCountries);

      let userCountry = countryDestination || localStates.deliverTo;
      if (!userCountry) {
        userCountry = "🇺🇸 United States";
      }
      if (userCountry) {
        availableShippingCountries.map((country, index) => {
          if (country && country.title?.toLowerCase()?.includes(userCountry?.toLowerCase())) {
            setDestinationIndexChoose(index);
            if (setShippingDestination) setShippingDestination(country.title);
          }
        });
      }
    }
  }, [product, countryDestination || ""]);

  useEffect(() => {
    if (destinationIndexChoose !== "") {
      let availableShip = product.shipping.filter((ship) => {
        if (ship) {
          if (
            ship.origin === "Rest of the world" ||
            ship.origin === "Rest of world" ||
            ship.origin === "0-0"
          ) {
            return ship;
          } else {
            let shipArray = [];
            if (ship.origin?.includes(",") || ship.origin?.includes("-")) {
              shipArray = ship.origin.split(",");
            }
            if (shipArray?.length) {
              const isUE = shipArray.includes("0-2");
              if (
                shipArray.includes(countryList?.[destinationIndexChoose]?.key) ||
                shipArray.includes("0-0") ||
                (countryList?.[destinationIndexChoose]?.key?.includes("0-2") && isUE)
              )
                return ship;
            } else if (ship.origin && !ship.origin.includes("-")) {
              if (
                countryList[destinationIndexChoose]?.title
                  ?.toLowerCase()
                  .includes(getCountry(ship.origin)?.toLowerCase())
              )
                return ship;
            }
          }
        }
      });
      availableShip = availableShip.sort((a, b) => a.price.localeCompare(b.price));
      const filterDuplicateShipping = () => {
        let notDuplicateShippings = [];
        availableShip.forEach(
          (shipping) =>
            !notDuplicateShippings.some(
              (ship) =>
                ship.additionalProductPrice === shipping.additionalProductPrice &&
                ship.name === shipping.name &&
                ship.price === shipping.price &&
                ship.time === shipping.time
            ) && notDuplicateShippings.push(shipping)
        );
        return notDuplicateShippings;
      };
      availableShip = filterDuplicateShipping();
      setShippingAvailable(availableShip);
      if (setAvailableShippingProps) {
        setAvailableShippingProps(availableShip);
      }
      if (availableShip && availableShip[0]) {
        if (!shippingSelect.price) {
          setShippingSelect(availableShip?.[0] || {});
          setLocalShippingSelect(availableShip?.[0] || {});
        }
      }
    }
  }, [destinationIndexChoose]);

  useEffect(() => {
    if (shippingSelect?._id && shippingAvailable?.length) {
      for (let i = 0; i < shippingAvailable.length; i++) {
        if (shippingSelect._id === shippingAvailable[i]._id) setDefaultShippingSelect(i);
      }
    }
  }, [shippingSelect, shippingAvailable]);

  return (
    <Modal
      className="modal-1000"
      title={t("shippingMethod")}
      onOk={() => {}}
      visible={modalOn}
      onCancel={() => setModalOn(false)}
      footer={[]}
    >
      <div style={{ width: "100%" }} className="column">
        {showDestinations._id && (
          <Modal
            title={t("components.modal.5")}
            visible={true}
            onCancel={() => setShowDestinations({})}
            footer={[]}
          >
            <p style={{ fontSize: "20px", marginBottom: "20px" }} className="title">
              {t("components.modal.4")} {showDestinations.name}
            </p>
            <Tree
              defaultExpandedKeys={showDestinations.origin.split(",")}
              defaultCheckedKeys={showDestinations.origin.split(",")}
              checkedKeys={showDestinations.origin.split(",")}
              treeData={countryList.filter(
                (country) =>
                  showDestinations.origin === "0-0" ||
                  country?.title?.toLowerCase().includes(getCountry(showDestinations.origin)?.toLowerCase())
              )}
            />
          </Modal>
        )}

        <p className="text-bold" style={{ fontSize: "22px" }}>
          {t("shippingMethod")}
        </p>
        {destinationIndexChoose !== "" ? (
          <p>
            {t("to")}
            <span
              onClick={() => {
                setDestinationIndexChoose("");
                setLocalShippingSelect({});
              }}
              className="text-bold"
              style={{ marginLeft: "5px", color: "#38AFF1", textDecoration: "underline", cursor: "pointer" }}
            >
              {countryList[destinationIndexChoose] &&
              (countryList[destinationIndexChoose].title.toLowerCase() === "rest of world" ||
                countryList[destinationIndexChoose].title.toLowerCase() === "rest of the world")
                ? countryList[destinationIndexChoose]?.title
                : countryList[destinationIndexChoose]?.title.split(" ")[1] +
                  " " +
                  countryList[destinationIndexChoose]?.title.split(" ")[0]}
            </span>
          </p>
        ) : (
          <div className="column">
            <p style={{ marginTop: "5px" }} className="text-bold">
              {t("components.modal.6")}
            </p>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              onChange={(value) => {
                if (setShippingDestination) setShippingDestination(countryList[parseInt(value)].title);

                setDestinationIndexChoose(parseInt(value));
              }}
              value={
                destinationIndexChoose !== ""
                  ? countryList[destinationIndexChoose].title
                  : t("components.modal.12")
              }
              defaultValue={t("components.modal.13")}
              style={
                windowSize > 1150 ? { width: 400, marginTop: "5px" } : { width: "70vw", marginTop: "5px" }
              }
              autoFocus
              defaultOpen
            >
              {countryList &&
                countryList.map((country, index) => {
                  if (country.title !== "Rest of world")
                    return <Option value={index}>{country.title}</Option>;
                })}
            </Select>
          </div>
        )}

        {destinationIndexChoose !== "" && shippingAvailable?.length ? (
          <div style={{ width: "100%", marginTop: "30px" }} className="column">
            {windowSize > 1150 && (
              <div className="row-wrap" style={{ width: "100%" }}>
                <p className="text-bold" style={{ width: "340px", marginRight: "20px" }}>
                  {t("components.modal.9")}
                </p>
                <p className="text-bold" style={{ width: "110px", marginRight: "20px" }}>
                  {t("cost")}
                </p>
                <p className="text-bold" style={{ width: "150px", marginRight: "20px" }}>
                  {t("costByAdditionalProduct")}
                </p>
                <p className="text-bold" style={{ width: "200px" }}>
                  {t("components.modal.8")}
                </p>
              </div>
            )}
            <div className="divider-horizontal" style={{ marginLeft: "0" }} />
            <Radio.Group
              defaultValue={defaultShippingSelect}
              style={{ width: "100%" }}
              onChange={(e) => setLocalShippingSelect(shippingAvailable[parseInt(e.target.value)])}
            >
              <Space style={{ width: "100%" }} direction="vertical">
                {shippingAvailable.map((ship, index) => {
                  return (
                    <Radio value={index}>
                      <div
                        style={windowSize > 1150 ? {} : { marginTop: "20px", maxWidth: "80vw" }}
                        className="row-wrap"
                      >
                        <p
                          className={windowSize > 1150 && "text-ellipsis"}
                          style={{ width: "320px", fontSize: "14px", marginRight: "20px" }}
                        >
                          {t("components.modal.9")} {ship.time} {t("days")}
                        </p>
                        <p
                          className={windowSize > 1150 && "text-ellipsis"}
                          style={{ width: "110px", fontSize: "14px", marginRight: "20px" }}
                        >
                          {windowSize <= 1150 && t("cost") + ": "}
                          {parseInt(ship.price) !== 0
                            ? `${priceConverter({ price: ship.price, currency: localStates.currency })}`
                            : "Free Shipping"}
                        </p>
                        <p
                          className={windowSize > 1150 && "text-ellipsis"}
                          style={{ width: "150px", fontSize: "14px", marginRight: "20px" }}
                        >
                          {windowSize <= 1150 && t("costByAdditionalProduct") + ": "}
                          {`${priceConverter({
                            price: ship.additionalProductPrice || 0,
                            currency: localStates.currency,
                          })}`}
                        </p>
                        <p
                          className={windowSize > 1150 && "text-ellipsis"}
                          style={{ width: "200px", fontSize: "14px", marginRight: "20px" }}
                        >
                          {ship.name}
                        </p>
                        <p
                          onClick={() => setShowDestinations(ship)}
                          style={{
                            width: "190px",
                            color: "#38AFF1",
                            fontSize: "13px",
                            textDecoration: "underline",
                          }}
                        >
                          Destinations
                        </p>
                      </div>
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </div>
        ) : (
          destinationIndexChoose !== "" && (
            <p style={{ width: "100%", marginTop: "30px" }} className="title">
              {t("components.modal.11")} {countryList[destinationIndexChoose]?.title}
            </p>
          )
        )}

        {localShippingSelect._id && (
          <button
            onClick={() => {
              setShippingSelect(localShippingSelect);
              setModalOn(false);
            }}
            style={
              windowSize > 1150
                ? { width: "25%", marginLeft: "37.5%", marginTop: "30px" }
                : { minWidth: "150px", marginTop: "30px", width: "90%", marginLeft: "5%" }
            }
            className="button big orange-back"
          >
            {t("apply")}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ShippingMethods;
