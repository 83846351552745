import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { articles } from "./articles";
import NavBar from "../../components/menu/navbar/NavBar";
import Footer from "../../components/menu/Footer";
import Header from "../../components/blog/BlogHeader";
import TagMenu from "../../components/blog/TagMenu";

import "./style.scss";
import ArticleCard from "../../components/blog/ArticleCard";
import SuggestionCard from "../../components/blog/SuggestionCard";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const [displayedArticles, setDisplayedArticles] = useState(articles);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedTag, setSelectedTag] = useState("");

  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language) {
    }
  }, [i18n.language]);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });
  const sortArticles = articles.sort((a, b) => parseInt(a.id) - parseInt(b.id));
  const lastThreeArticles = [
    sortArticles[sortArticles.length - 1],
    sortArticles[sortArticles.length - 2],
    sortArticles[sortArticles.length - 3],
  ];

  return (
    <div style={{ background: "white" }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />
        <title>E-commerce and Branding Blog | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/blog" />
        <meta
          name="description"
          content="Learn how to manage your e-commerce business, create nice products, 
          good looking online store, and build partnerships with worldwide resellers & suppliers"
        />
      </Helmet>

      <NavBar windowSize={windowSize} />

      <div style={{ paddingBottom: "150px" }} className="column center">
        <Header />
        <TagMenu
          setSelectedTag={setSelectedTag}
          selectedTag={selectedTag}
          setDisplayedArticles={setDisplayedArticles}
        />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            {displayedArticles
              .sort((a, b) => b.id - a.id)
              .map((article) => (
                <ArticleCard
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                  setDisplayedArticles={setDisplayedArticles}
                  article={article}
                />
              ))}
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article) => (
              <SuggestionCard article={article} />
            ))}
          </div>
        </div>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default Blog;
