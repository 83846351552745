import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { url } from "../api/url";
import { header } from "../api/header";
import ProductCard from "../components/InventoryProductCard";
import CheckToken from "../components/check-token/CheckToken";
import { Link } from "react-router-dom";
import Menu from "../components/menu/FirstMenu";
import { CloseCircleFilled, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { message, Button, Input, Pagination, Skeleton } from "antd";
import Header from "../components/header/SimpleHeader";
import MobileMenu from "../components/menu/mobile/FirstMenu";
import MobileHeader from "../components/header/mobile/SimpleHeader";
import RedirectLogin from "../utils/RedirectLogin";
import Load from "../utils/Load";
import GenerateCsv from "../components/generateCsvProductsList/ModalGenerateCsv";
import EmptyInventory from "../components/supplier/EmptyInventory";
import ResellerSetupProgress from "../components/reseller/SetupProgress";
import ImportedProcessHeader from "../components/inventory/ImportedProcessHeader";
import SelectorActions from "../components/inventory/selectorActions/SelectorActions";
import ProductLinkCMS from "../components/productLinkCMS";

import { productServices } from "../services/products";
import { urlParams } from "../utils/urlParams";
import { t } from "i18next";
import { routes } from "../routes";
import InventorySellerSort from "../components/inventory/seller/Sort";

import { Modal } from "../wrappers";

const antIcon = <LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />;

const Inventory = () => {
  const paramsUrl = urlParams();

  const [products, setProducts] = useState([]);
  const [tokenCheck, setTokenCheck] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [deleteProduct, setDeleteProduct] = useState("");
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [deleteProductSeller, setDeleteProductSeller] = useState("");
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [pageStep, setPageStep] = useState(paramsUrl.page || 1);
  const [search, setSearch] = useState("");
  const [noResult, setNoResult] = useState(false);
  const [productsDelete, setProductsdelete] = useState([]);
  const [currency, setCurrency] = useState("");
  const [productsLoad, setProductsLoad] = useState(false);
  const [productSelection, setProductSelection] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const [isExportDisplayed, setIsExportDisplayed] = useState(false);
  const [isProductLinkDisplayed, setIsProductLinkDisplayed] = useState(false);
  const [isFirstHistory, setIsFirstHistory] = useState(true);
  const { i18n } = useTranslation();

  const history = useHistory();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUser();
    if (window.tidioChatApi) {
      window?.tidioChatApi?.show();
    }
  }, []);

  useEffect(() => {
    if (products?.length > 0) setNoResult(false);
  }, [products]);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);

    if (resJson.data && resJson.data.type === "seller" && resJson?.data?.shopify?.shop) {
      const res2 = await fetch(`${url}/user/shopify/find-shopify-currency`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          shopify: resJson.data.shopify,
        }),
      });
      const resJson2 = await res2.json();
      setCurrency(resJson2.currency);
    }

    return true;
  };

  const getProducts = async (
    { sortBy, searchParams } = { sortBy: paramsUrl.sort || "", searchParams: undefined }
  ) => {
    if (typeAccount === "creater") {
      history.push(`/inventory?page=${pageStep}`);
      return await productServices
        .findByCreater({ page: pageStep, search: searchParams !== undefined ? searchParams : search })
        .then((res) => res.json())
        .then((res) => {
          setIsLoad(true);
          setLoad(false);
          setProducts(res.data || []);
          if (res.data?.length) {
            setTotalProducts(res.totalItems);
          }
          if (!res.data?.length) {
            setNoResult(true);
          }
          return true;
        });
    } else {
      // set query params URL
      if (isFirstHistory) {
        history.replace({ search: `page=${pageStep}&sort=${sortBy || paramsUrl.sort || ""}` });
        setIsFirstHistory(false);
      } else {
        history.push({ search: `page=${pageStep}&sort=${sortBy || paramsUrl.sort || ""}` });
      }
      return await productServices
        .findBySeller({
          page: pageStep,
          search: searchParams !== undefined ? searchParams : search,
          sortBy: sortBy || paramsUrl.sort || "",
        })
        .then((res) => res.json())
        .then((res) => {
          setIsLoad(true);
          setLoad(false);
          setProducts(res.data || []);
          if (res.data?.length) {
            setTotalProducts(res.totalItems);
          }
          if (!res.data?.length) {
            setNoResult(true);
          }

          return true;
        });
    }
  };

  useEffect(() => {
    getProducts({});
  }, [pageStep]);

  const deleteProductF = async ({ productId }) => {
    const deletedProductId = productId || deleteProduct;
    const res = await fetch(`${url}/product/delete/${deletedProductId}/${idAccount}/${secretId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      let newProducts = [...products];
      newProducts = newProducts.filter((product) => product._id !== deletedProductId);
      setProductsdelete((prevState) => [...prevState, deletedProductId]);
      message.success(t("messages.productDeleted"));
      setDeleteProduct("");
      setProductSelection([]);
      if (newProducts.length < 1) setNoResult(true);

      //setProducts([...newProducts])
    }

    return true;
  };

  const deleteSellerProduct = async ({ productId }) => {
    const deletedProductId = productId || deleteProductSeller;
    const removeWizishopProduct = async ({ user }) => {
      if (user.wizishop && user.wizishop.default_shop_id) {
        const wizishopProduct = user.wizishop_product?.find(
          (product) => product.local_id === deletedProductId
        );
        if (wizishopProduct) {
          const url = `https://api.wizishop.com/v3/shops/${wizishopProduct.shop_id}/products/${wizishopProduct.wizishop_id}`;
          const options = {
            method: "DELETE",
            headers: {
              accept: "application/json",
              "content-type": "application/json",
              authorization: `Bearer ${user.wizishop.token}`,
            },
          };
          fetch(url, options).then((res) => console.log(res));
        }
      }
    };
    const resConnect = await fetch(
      `${url}/product/delete-seller-product/${deletedProductId}/${idAccount}/${secretId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      }
    );
    if (resConnect.status === 200) {
      removeWizishopProduct({ user });
      let newProducts = [...products];
      newProducts = newProducts.filter((product) => product._id !== deletedProductId);
      const newDeletedProducts = [...productsDelete, deletedProductId];
      setUser((prevState) => ({
        ...prevState,
        products_id: [...prevState.products_id.filter((id) => !newDeletedProducts.includes(id))],
      }));
      message.success(t("messages.productDeleted"));
      setProductsdelete((prevState) => [...prevState, deletedProductId]);
      setDeleteProductSeller("");
      setProductSelection([]);
      if (newProducts.length < 1) setNoResult(true);
    }
    return true;
  };

  return (
    <div className="page">
      {isProductLinkDisplayed && (
        <ProductLinkCMS
          setIsDisplayed={setIsProductLinkDisplayed}
          productProps={{}}
          defaultShopifyProductId={""}
          user={user}
          getProducts={getProducts}
          getUser={getUser}
        />
      )}
      {products.length > 0 && (
        <GenerateCsv
          setProducts={setProducts}
          productsPage={products}
          user={user}
          windowSize={windowSize}
          productsSelect={productSelection}
          isExportDisplayed={isExportDisplayed}
          setIsExportDisplayed={setIsExportDisplayed}
        />
      )}
      <ResellerSetupProgress user={user} />
      <RedirectLogin />
      <CheckToken setTokenCheck={setTokenCheck} />
      {deleteProduct && (
        <Modal
          className="modal-no-ok"
          title={t("deleteProduct")}
          onOk={() => deleteProductF()}
          visible={true}
          onCancel={() => setDeleteProduct("")}
          footer={[
            <Button key="back" onClick={() => setDeleteProduct("")}>
              {t("no")}
            </Button>,
            <Button key="submit" type="primary" onClick={() => deleteProductF()}>
              {t("yes")}
            </Button>,
          ]}
        >
          <p className="title">{t("sureToDelete")}</p>
        </Modal>
      )}
      {deleteProductSeller && (
        <Modal
          className="modal-no-ok"
          title={t("deleteProduct")}
          onOk={() => deleteSellerProduct({})}
          visible={true}
          onCancel={() => setDeleteProductSeller("")}
          footer={[
            <Button key="back" onClick={() => setDeleteProductSeller("")}>
              {t("no")}
            </Button>,
            <Button key="submit" type="primary" onClick={() => deleteSellerProduct({})}>
              {t("yes")}
            </Button>,
          ]}
        >
          <p className="title">{t("sureToDelete")}</p>
          {(user.shopify?.shop || user.wizishop?.default_shop_id) && (
            <p style={{ marginTop: "10px", color: "#ff6767" }} className="text-bold center">
              {t("sureToDelete2", { cms: user.shopify?.shop ? "Shopify" : user.wizishop?.cms })}
            </p>
          )}
        </Modal>
      )}
      <div className="page-start inventory">
        {windowSize > 1150 ? <Menu userProps={user} /> : <MobileMenu />}
        <div
          style={{
            paddingTop: `${windowSize <= 1150 ? "50px" : typeAccount === "seller" ? "100px" : "110px"}`,
            paddingBttom: windowSize <= 1150 && "200px" 
          }}
          className="column-scrolling"
        >
          {load && <Load />}
          {windowSize > 1150 ? (
            <Header location={typeAccount === "creater" ? "inventory creater" : "inventory"} />
          ) : (
            <MobileHeader load={products && products.length > 0 ? true : false} nameHeader={"INVENTORY"} />
          )}

          <ImportedProcessHeader
            getUser={getUser}
            inProgressRequestCount={user.in_progress_requests?.products_push?.length}
            
          />

          {typeAccount === "creater" && (
            <div style={{ marginBottom: "30px" }} className="row-wrap">
              <Link
                id="demo-step-10"
                to="/create-product"
                style={{
                  margin: "10px",
                  height: "45px",
                }}
                className="button big orange-back montserrat"
              >
                {t("inventory.addNewProduct")}
              </Link>
              <Link
                to="/import-products-csv"
                style={{ margin: "10px", height: "45px" }}
                className="button-orange big montserrat"
              >
                {t("inventory.importProductCsv")}
              </Link>
            </div>
          )}

          <div
            style={{
              width: windowSize <= 1150 ? "100%" : "90%",
              marginTop: typeAccount === "seller" && windowSize > 1150 && "30px",
            }}
            className="row row-wrap space-between"
          >
            {(products.length > 0 || search) && (
              <Input
                onPressEnter={() => {
                  setLoad(true);
                  getProducts({});
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (!e.target.value) {
                    // reset search products
                    setLoad(true);
                    getProducts({ searchParams: "" });
                  }
                }}
                placeholder={t("inventory.findByName")}
                className="input-search"
                value={search}
                style={{
                  marginLeft: "10px",
                  marginBottom: windowSize <= 1150 && "8px",
                  width: windowSize <= 1150 ? "100%" : "350px",
                }}
                suffix={
                    <CloseCircleFilled
                      style={{ color: "#949494", fontSize: "18px", display: !search && "none" }}
                      onClick={() => {
                        setLoad(true);
                        setSearch("");
                        getProducts({ searchParams: "" });
                      }}
                    />
                }
              />
            )}

            {/* MOBILE */}
            {typeAccount === "seller" && products.length > 0 && windowSize <= 1150 && (
              <InventorySellerSort windowSize={windowSize} setLoad={setLoad} getProducts={getProducts} />
            )}

            {/* PC WINDOW SIZE */}
            {user?.shopify?.token && windowSize > 1150 && (
              <button
                style={{ marginLeft: "10px", width: windowSize <= 1150 && "100%" }}
                onClick={() => setIsProductLinkDisplayed(true)}
                className="button big orange-back"
              >
                {t("linkShopifyProduct")}
              </button>
            )}
          </div>

          {products.length > 0 && (
            <div
              style={{
                marginBottom: "30px",
                width: windowSize <= 1150 ? "100%" : "90%",
                marginTop: windowSize <= 1150 ? "16px" : "30px",
              }}
              className="end row row-wrap space-between"
            >
              <div
                style={{ marginLeft: "10px" }}
                className={windowSize <= 1150 ? "row row-wrap width-100" : "row row-wrap end"}
              >
                <SelectorActions
                  productSelection={productSelection}
                  setProductSelection={setProductSelection}
                  setIsExportDisplayed={setIsExportDisplayed}
                  deleteProductF={typeAccount === "seller" ? deleteSellerProduct : deleteProductF}
                  setLoad={setLoad}
                  products={products}
                />
                <p
                  style={{
                    marginLeft: windowSize > 1150 && "30px",
                    width: windowSize <= 1150 && "100%",
                    marginTop: windowSize <= 1150 && "8px",
                  }}
                  onClick={() => setProductSelection(user.products_id.map((productId) => productId))}
                  className="text blue underline pointer"
                >
                  {t("selectAll")}
                </p>
              </div>

              {/* PC WINDOW SIZE | LINK SHOPIFY PRODUCT */}
              {user?.shopify?.token && windowSize <= 1150 && (
                <button
                  style={{
                    marginLeft: "10px",
                    width: windowSize <= 1150 && "100%",
                    marginTop: windowSize <= 1150 && "16px",
                  }}
                  onClick={() => setIsProductLinkDisplayed(true)}
                  className="button big orange-back"
                >
                  {t("linkShopifyProduct")}
                </button>
              )}

              {/* PC WINDOW SIZE */}
              {typeAccount === "seller" && products.length > 0 && windowSize > 1150 && (
                <InventorySellerSort windowSize={windowSize} setLoad={setLoad} getProducts={getProducts} />
              )}
            </div>
          )}

          {products?.length > 0 &&
            products.map((product, index) => {
              if (!productsDelete.includes(product._id))
                return (
                  <ProductCard
                    setProductSelection={setProductSelection}
                    productSelection={productSelection}
                    currency={currency}
                    user={user}
                    page={pageStep}
                    shopifyPrice={product.shopify_price}
                    index={index}
                    setDeleteProductSeller={setDeleteProductSeller}
                    typeAccount={typeAccount}
                    keyP={product._id + product.name + product.shopify_price}
                    setDeleteProduct={setDeleteProduct}
                    location={"landing"}
                    productP={product}
                    windowSize={windowSize}
                    getUser={getUser}
                    getProducts={getProducts}
                  />
                );
            })}

          <div className="column" style={{ alignItems: "center", width: "100%" }}>
            {products.length > 0 && totalProducts > 0 ? (
              <Pagination
                key={pageStep + products.length + totalProducts}
                showSizeChanger={false}
                style={{ marginBottom: "50px", marginTop: "30px" }}
                onChange={(page) => {
                  setProducts([]);
                  setIsLoad(false);
                  setPageStep(page);
                }}
                defaultCurrent={pageStep || 1}
                total={totalProducts}
                pageSize={30}
                defaultPageSize={30}
              />
            ) : !noResult && isLoad ? (
              <>
                <button
                  style={{ marginTop: "60px" }}
                  onClick={() => {
                    if (pageStep === 1) {
                      // useEffect don't referesh
                      getProducts();
                    }
                    setPageStep(1);
                    setSearch("");
                  }}
                  className="button orange-back"
                >
                  {t("inventory.returnToAll")}
                </button>
              </>
            ) : noResult && isLoad && search ? (
              <p style={{ marginTop: "50px" }} className="title-bold center">
                {t("inventory.noResultSearch")}
              </p>
            ) : noResult && typeAccount === "seller" ? (
              <div
                style={
                  windowSize > 1150
                    ? { position: "absolute", left: "-100px" }
                    : { width: "90vw", marginBottom: "50px" }
                }
                className="column center"
              >
                <p className="title-bold center">{t("inventory.emptyInventory")}</p>
                <span style={{ fontSize: "18px" }}>
                  <Link
                    style={{ marginRight: "10px" }}
                    className="underline title"
                    to={routes.marketplace(i18n.language).path}
                  >
                    {t("inventory.discoverProduct")}
                  </Link>
                  {t("or")}
                  {windowSize < 1150 && <br />}
                  <Link style={{ marginLeft: "10px" }} className="underline title" to="/import-list">
                    {t("inventory.pushFromImportList")}
                  </Link>
                </span>
              </div>
            ) : noResult && typeAccount === "creater" && isLoad && !search ? (
              <EmptyInventory windowSize={windowSize} />
            ) : (
              !isLoad && (
                <React.Fragment>
                  <Skeleton style={{ marginTop: "30px" }} className="image-product" loading={true} active />
                  <Skeleton className="image-product" loading={true} active />
                </React.Fragment>
              )
            )}

            {typeAccount === "seller" && !products.length && productsLoad && (
              <div style={{ width: "100%", marginLeft: "0px", marginTop: "50px" }} className="column">
                <p className="title">{t("inventory.noResult.title")}</p>
                <Link
                  style={{ fontSize: "20px", textAlign: "start" }}
                  to="/sourcing-products"
                  className="text-gradient"
                >
                  {t("inventory.noResult.text")}
                </Link>
                <iframe
                  style={{
                    marginTop: "30px",
                    width: "95%",
                    maxWidth: "600px",
                    height: "400px",
                    marginBottom: "20px",
                  }}
                  src={"https://www.youtube.com/embed/DNmF8eeroBo"}
                  title={t("inventory.addProductToImportList")}
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;
