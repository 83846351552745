export const thematics = [
  "Fantasy",
  "Medieval",
  "Gothic",
  "viking",
  "Zen",
  "Esoterism",
  "Kids",
  "Tribal",
  "Wild animals",
  "Streetwear",
  "Boheme",
  "Pets",
  "USA",
  "Ecology",
  "India",
  "Jewelry",
  "Clothes",
  "Decorative art",
  "Lingerie",
  "Undefined",
  "not serious"
];
