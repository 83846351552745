import { url } from "../api/url";
import { header } from "../api/header";

export const orderServices = {
  paymentConfirmation: async (
    { orderId, sellerPaid, shippingPaid, productPaid, bankTransferRef },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/order/payment-confirmation/${orderId}/${userId}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        sellerPaid,
        shippingPaid,
        productPaid,
        bankTransferRef
      }),
    });
  },
  paymentConfirmationCreater: async (
    { orderId },
    userId = localStorage.getItem("idAccountCreatosell"),
    token = localStorage.getItem("tokenCreatosell"),
    secretId = localStorage.getItem("secretAccountId01supply")
  ) => {
    return await fetch(`${url}/order/payment-confirmation-creater/${orderId}/${userId}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  },
};
