import React from "react";
import { useTranslation } from "react-i18next";
import { Select, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { category } from "../../../data/Category";
import { tag } from "../../../data/Tag";

const { Option } = Select;

const Sorting = ({ setProduct, product }) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        {t("components.supplier.createEditProduct.sorting.1")}
      </p>
      <p style={{ marginBottom: "8px" }} className="text-bold">
        {t("components.supplier.createEditProduct.sorting.2")}
      </p>
      <Select
        value={product.category || t("components.supplier.createEditProduct.sorting.3")}
        style={{ width: 200 }}
        onChange={(value) => {
          setProduct({ ...product, category: value });
        }}
      >
        {category.map((category, index) => {
          return <Option value={category.name}>{t(`categoryList.${index+1}`)}</Option>;
        })}
      </Select>

      <p style={{ marginBottom: "8px", marginTop: "15px" }} className="text-bold">
        Tags (3 max.)
      </p>

      <Select
        value={t("components.supplier.createEditProduct.sorting.4")}
        style={{ width: 200, marginBottom: "10px" }}
        disabled={product.tag?.length > 2}
        onChange={(value) => {
          if (product.tag?.length < 3) setProduct({ ...product, tag: [...product.tag, value] });
          else {
            message.error(t('messages.tagLimit'));
          }
        }}
      >
        {tag.map((tag, index) => {
          return !product.tag?.includes(tag) && <Option value={tag}>{t(`tagList.${index+1}`)}</Option>;
        })}
      </Select>
      <div style={{ width: "100%" }} className="row-wrap">
        {product.tag?.map((tag, index) => {
          return (
            <button className="transparent-gradient-button">
              {tag}
              <CloseOutlined
                onClick={() => {
                  let tagStock = product.tag;
                  tagStock.splice(index, 1);
                  setProduct({ ...product, tag: tagStock });
                }}
                style={{ fontSize: "14px", marginLeft: "5px" }}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Sorting;
