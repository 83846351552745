import React from "react";
import { useTranslation } from "react-i18next";

import GetStarted from "../getStarted/GetStarted";

import { routes } from "../../routes";

const ArticleFooter = () => {
  const { i18n, t } = useTranslation();
  return (
    <div style={{ marginTop: "50px" }} className="column header-container width-100">
      <p className="title-bold montserrat start" style={{ fontWeight: "800", fontSize: "28px" }}>
      {t("blog.0")} <br />
        {t("blog.1")}
      </p>
      <p style={{ marginBottom: "15px", fontSize: "14px" }} className="text-300 grey">
      {t("blog.2")}
      </p>
      <GetStarted redirectPath={routes.joinUs(i18n.language).path} />
    </div>
  );
};

export default ArticleFooter;
