import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { updateUser } from "../../services/updateUser";
import { message, Table } from "antd";

import SearchUser from "../../components/admin/SearchUser";
import ThematicsList from "../../components/admin/manageThematics/ThematicsList";
import ManageSourcing from "../../components/admin/ManageSourcing";
import CheckLabel from "../../components/admin/CheckLabel";
import ManageAffiliation from "../../components/admin/ManageAffiliation";
import ManageResearch from "../../components/admin/ManageResearch";
import CoachingList from "../../components/admin/CoachingList";
import StatsUserList from "../../components/admin/StatsUserList";
import { parseDate } from "../../utils/parseDate";
import { adminServices } from "../../services/admin";

const freeSubscriptionUserEmails = [
  "testseller2@gmail.com",
  "support@odins-hall.com",
  "sales463@gmail.com",
  "contact.invasionvikingshop@gmail.com",
  "sales462@gmail.com",
  "sales461@gmail.com",
  "sales460@gmail.com",
  "sales459@gmail.com",
  "sales458@gmail.com",
  "sales457@gmail.com",
  "sales456@gmail.com",
  "matenterprises2020@gmail.com",
];

const UserList = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [editThematics, setEditThematics] = useState(false);
  const [manageSourcing, setManageSourcing] = useState(false);
  const [thematics, setThematics] = useState([]);
  const [users, setUsers] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isCoachingListDisplayed, setIsCoachingLstDisplayed] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    getUsers();
    findThematics();

    fetch(`${url}/admin`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
  }, []);

  const getUsers = async () => {
    try {
      const sortByPaymentsDue = (a, b) => {
        if (!a.user.products_id?.length && !b.user.products_id?.length) return 0;
        if (a.user.products_id?.length && a.user.products_id?.length > b.user.products_id?.length) return -1;
        else return 1;
      };
      const res = await fetch(`${url}/admin/find-users/${idAccount}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson.users) {
          setAllUsers(resJson.users);
          let usersSort = resJson.users.sort(sortByPaymentsDue);
          setUsers(usersSort);
        }
      }
    } catch {}
  };

  const findThematics = async (createrId) => {
    const res = await fetch(`${url}/thematic/find-all`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setThematics(resJson.thematics);
    }
  };

  const disconnect = () => {
    // set algolia analytics user id
    window.aa("setUserToken", "");
    localStorage.setItem("tokenCreatosell", "");
    localStorage.setItem("typeAccountCreatosell", "");
    localStorage.setItem("emailAccountCreatosell", "");
    localStorage.setItem("idAccountCreatosell", "");
    localStorage.setItem("nameAccountCreatosell", "");
    localStorage.setItem("secretAccountId01supply", "");
    localStorage.setItem("admin", "");

    localStorage.setItem("demoMarketActivate", false);
    localStorage.setItem("demoShopActivate", false);
    localStorage.setItem("demoInformationsActivate", false);
    localStorage.setItem("demoPlansActivate", false);
    localStorage.setItem("demoInventory1Activate", false);
    localStorage.setItem("demoInventory2Activate", false);
    localStorage.setItem("demoCreateProductActivate", false);

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const handleUpdateUser = async (newValues, user) => {
    updateUser(
      { user: { ...newValues } },
      user._id,
      user.secret_id,
      token,
      message.success(t("messages.successfullyUpdated"))
    );
  };

  const handleBanUser = ({ user }) => {
    const isBanned = user?.banned ? false : true;
    adminServices.banUser({ userId: user?._id, isBanned });
    message.success("user ban = " + isBanned);
    setUsers(
      users.map((userP) =>
        user?._id === userP.user?._id ? { ...userP, user: { ...userP.user, banned: isBanned } } : userP
      )
    );
  };

  const getShopUrl = (line) => {
    if (line?.user?.shopify?.shop) {
      return line?.user?.shopify?.shop;
    } else if (line?.user?.shop?.url) {
      return line?.user?.shop?.url;
    } else if (line?.user?.wizishop?.shop_list?.length) {
      return `https://${line?.user?.wizishop?.shop_list[0].name}.mywizi.com`;
    }

    return "";
  };

  const columns = [
    {
      title: "Plan",
      dataIndex: "plan",
      render: (text, line) => (
        <p>
          {" "}
          {freeSubscriptionUserEmails.includes(line?.user?.email)
            ? `(test) ${line?.user?.plan?.type}`
            : line?.user?.plan?.type
            ? line?.user?.plan?.type
            : "free"}
        </p>
      ),
      sorter: (a, b) =>
        (a?.user?.plan?.type !== "free" && !freeSubscriptionUserEmails.includes(a?.user?.email)
          ? a?.user?.plan?.type
          : ""
        )?.localeCompare(
          b?.user?.plan?.type !== "free" && !freeSubscriptionUserEmails.includes(b?.user?.email)
            ? b?.user?.plan?.type
            : ""
        ),
    },
    {
      title: "Payment due",
      dataIndex: "name",
      render: (text, line) => (
        <p style={line?.payment?.net_due ? { color: "red" } : {}}>
          {line?.payment?.net_due ? line?.payment?.net_due + "$" : "0$"}
        </p>
      ),
      sorter: {
        compare: (a, b) => (a?.payment?.net_due || 0) - (b?.payment?.net_due || 0),
      },
    },
    {
      title: "Shop name",
      dataIndex: "name",
      render: (text, line) => <p>{line?.user?.shop?.name || "undefined"}</p>,
      sorter: (a, b) => (a?.user?.shop?.name || "").localeCompare(b?.user?.shop?.name || ""),
    },
    {
      title: "Shop URL",
      dataIndex: "name",
      render: (text, line) => <p>{getShopUrl(line)}</p>,
      sorter: (a, b) => getShopUrl(a).localeCompare(getShopUrl(b)),
    },
    {
      title: "Email",
      dataIndex: "name",
      render: (text, line) => <p>{line?.user?.email || "undefined"}</p>,
      sorter: (a, b) => (a?.user?.email || "").localeCompare(b?.user?.email || ""),
    },
    {
      title: "Inventaire",
      dataIndex: "name",
      render: (text, line) => <p>{line?.user?.products_id?.length || "0"}</p>,
      sorter: {
        compare: (a, b) => (a?.user?.products_id?.length || 0) - (b?.user?.products_id?.length || 0),
      },
    },
    {
      title: "Import list",
      dataIndex: "name",
      render: (text, line) => <p>{line?.user?.import_list?.length || "0"}</p>,
      sorter: {
        compare: (a, b) => (a?.user?.import_list?.length || 0) - (b?.user?.import_list?.length || 0),
      },
    },
    {
      title: "Orders count",
      dataIndex: "orderCount",
      render: (text, line) => <p>{line.user?.orderCount || 0}</p>,
      sorter: {
        compare: (a, b) => (a.user?.orderCount || 0) - (b.user?.orderCount || 0),
      },
    },
    {
      title: "",
      dataIndex: "name",
      render: (text, line, index) => (
        <div className="column">
          <Link
            style={{ marginTop: "10px" }}
            rel="noopener noreferrer"
            to={`/user-payments?userId=${line?.user?._id}&userSecretId=${line?.user?.secret_id}`}
            className="button orange-back"
          >
            Paiements
          </Link>
          <Link
            style={{ marginTop: "10px" }}
            rel="noopener noreferrer"
            to={`/connect?token=${localStorage.getItem("tokenCreatosell")}&id=${line.user?._id}&type=${
              line?.user?.type
            }&email=${line?.user?.email}&secret_id=${line?.user?.secret_id}`}
            className="button orange-back"
          >
            Login to this account
          </Link>
          <button
            onClick={() => handleBanUser({ user: line.user })}
            style={{ marginTop: "10px" }}
            className="button-red"
          >
            {line.user?.banned ? "Ne plus bannir" : "Bannir"}
          </button>
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "name",
      render: (text, line) => <p>{parseDate(line?.user?.create_at)}</p>,
      sorter: {
        compare: (a, b) =>
          parseInt(new Date(a.user?.create_at || 0).getTime()) -
          parseInt(new Date(b.user?.create_at || 0).getTime()),
      },
    },
  ];

  return (
    <div style={{ padding: "50px", paddingLeft: "5vw", paddingRight: "5vw" }} className="page">
      <button
        style={{ marginBottom: "15px" }}
        onClick={() => {
          adminServices.exeMonitoring();
          message.success("Monitoring executé");
        }}
      >
        EXECUTER MONITORING
      </button>
      {editThematics && (
        <ThematicsList
          windowSize={windowSize}
          thematics={thematics}
          setEditThematics={setEditThematics}
          setThematics={setThematics}
        />
      )}

      {manageSourcing && <ManageSourcing setManageSourcing={setManageSourcing} />}
      <button style={{ marginBottom: "20px" }} onClick={() => setEditThematics(true)} className="button orange-back">
        MANAGE THEMATICS
      </button>
      <Link target="_blank" to="/assign-thematic" style={{ marginBottom: "20px" }} className="button orange-back">
        ASSIGN THEMATICS
      </Link>
      <Link target="_blank" to="/kpi" style={{ marginBottom: "20px" }} className="button orange-back">
        STATISTICS
      </Link>
      <button style={{ marginBottom: "20px" }} onClick={() => setManageSourcing(true)} className="button orange-back">
        MANAGE SOURCING REQUESTS
      </button>
      <button
        style={{ marginBottom: "20px" }}
        onClick={() => setIsCoachingLstDisplayed(true)}
        className="button orange-back"
      >
        MANAGE COACHING
      </button>
      <Link target="_blank" to="/collab-matching" style={{ marginBottom: "20px" }} className="button orange-back">
        COLLAB MATCHING
      </Link>
      <ManageResearch />

      <CoachingList
        isDisplayed={isCoachingListDisplayed}
        setIsDisplayed={setIsCoachingLstDisplayed}
        users={users}
      />
      <CheckLabel token={token} updateUser={handleUpdateUser} users={users} />
      <ManageAffiliation users={allUsers} />
      {users?.length && <StatsUserList users={users} />}
      {users.length > 0 && <SearchUser setDatas={setUsers} datas={users} />}
      {users.length > 0 && (
        <Table scroll={{ x: 1100, y: 500 }} pagination={false} columns={columns} dataSource={users} />
      )}
      <button onClick={disconnect} className="button-absolute">
        Disconnect
      </button>
    </div>
  );
};

export default UserList;
