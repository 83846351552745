import React from "react";
import { useTranslation } from "react-i18next";

const Items = ({
  img1,
  title1,
  text1,
  img2,
  title2,
  text2,
  img3,
  title3,
  text3,
  img4,
  title4,
  text4,
  img5,
  title5,
  text5,
  img6,
  title6,
  text6,
  price1,
  price2,
  price3,
  price4,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ paddingTop: "50px" }} className="step-container">
        <div className="course-items-left-container">
          <div className="row">
            <img src={img1} className="item-img" />
            <h3 className="text-bold">
              {title1}
              <br />
              <br />
              <p style={{ fontSize: "14px" }} className="p">
                {text1}
              </p>
            </h3>
          </div>
        </div>
        <div className="course-items-right-container">
          <div className="row">
            <img src={img2} className="item-img" />
            <h3 className="text-bold">
              {title2}
              <br />
              <br />
              <p style={{ fontSize: "14px" }} className="p">
                {text2}
              </p>
            </h3>
          </div>
        </div>
      </div>

      <h2
        style={{ fontWeight: "900", marginTop: "30px", marginLeft: "25px", fontSize: "25px" }}
        className="title-bold montserrat start width-100"
      >
        {t("components.course.5")}
      </h2>
      <div style={{ paddingTop: "20px" }} className="step-container">
        <div className="course-items-left-container">
          <div className="row">
            <img src={img3} className="item-img" />
            <div className="column">
              <h3 className="text-bold">{title3}</h3>
              <p style={{ fontSize: "14px" }} className="p">
                {text3}
              </p>
            </div>
          </div>
          <h4 style={{ marginTop: "10px", color: "#B9B9B9" }} className="text-bold">
            {t("components.course.6")} {price1}
          </h4>
        </div>
        <div className="course-items-right-container">
          <div className="row">
            <img src={img4} className="item-img" />
            <div className="column">
              <h3 className="text-bold">{title4}</h3>
              <p style={{ fontSize: "14px" }} className="p">
                {text4}
              </p>
            </div>
          </div>
          <h4 style={{ marginTop: "10px", color: "#B9B9B9" }} className="text-bold">
            {t("components.course.6")} {price2}
          </h4>
        </div>
      </div>

      <div style={{ paddingTop: "20px" }} className="step-container">
        <div className="course-items-left-container">
          <div className="row">
            <img src={img5} className="item-img" />
            <div className="column">
              <h3 className="text-bold">{title5}</h3>
              <p style={{ fontSize: "14px" }} className="p">
                {text5}
              </p>
            </div>
          </div>
          <h4 style={{ marginTop: "10px", color: "#B9B9B9" }} className="text-bold">
            {t("components.course.6")} {price3}
          </h4>
        </div>
        <div className="course-items-right-container">
          <div className="row">
            <img src={img6} className="item-img" />
            <div className="column">
              <h3 className="text-bold">{title6}</h3>
              <p style={{ fontSize: "14px" }} className="p">
                {text6}
              </p>
            </div>
          </div>
          <h4 style={{ marginTop: "10px", color: "#B9B9B9" }} className="text-bold">
            {t("components.course.6")} {price4}
          </h4>
        </div>
      </div>
    </>
  );
};

export default Items;
