import { url } from "../api/url";
import { header } from "../api/header";

export const affiliationServices = {
  traffic: async ({ affiliateId = localStorage.getItem("affiliationId") }) => {
    return await fetch(`${url}/affi/traffic/${affiliateId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
      },
    });
  },
};
