import React from "react";
import { Upload, message } from "antd";
import {Modal} from '../wrappers'
import { PlusOutlined } from "@ant-design/icons";
import { header } from "../api/header";
import { url } from "../api/url";

function getBase64(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    fetch(file, {
      method: "GET",
      credentials: "include",
      headers: {
        Origin: "https://compassionate-ardinghelli-12d57b.netlify.app",
        "Access-Control-Request-Method": "GET",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Origin": "https://compassionate-ardinghelli-12d57b.netlify.app",
        "x-amz-expected-bucket-owner": "01supply",
      },
    })
      .then((file) => {
        return file.blob();
      })
      .then((file) => {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
  });
}

async function getS3Base64(file) {
  const res = await fetch(`${url}/product/image-base64`, {
    method: "POST",
    credentials: "include",
    headers: {
      ...header,
    },
    body: JSON.stringify({
      key: file,
    }),
  });
  if ((await res.status) === 200) {
    const resJson = await res.json();
    let base64 = await resJson.data;
    return await base64;
  }
}

function beforeUpload(file, lengthList) {
  if (lengthList > 1) {
    const isJpgOrPng = !file.type || file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M && file.size) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
  }
  return true;
}

class PicturesWall extends React.Component {
  constructor(props) {
    super(props);
    this.photos = props.photos;
  }
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  };

  componentDidMount() {
    if (this.props.photos && this.props.photos.length) {
      Promise.all(
        this.props.photos.map(async (photo, index) => {
          if (photo.base64) {
            if (photo.base64.includes("https://") && photo.base64.split("").length < 1500) {
              const base64 = await getS3Base64(photo.base64);
              if (base64) {
                return {
                  base64: "data:image/jpg;base64," + base64,
                  thumbUrl: "data:image/jpg;base64," + base64,
                  name: photo.name ? photo.name : "image.jpg",
                };
              }
            } else {
              return { base64: photo.base64, thumbUrl: photo.base64, name: photo.name };
            }
          } else {
            if (typeof photo === "string" && photo.includes("https://") && photo.split("").length < 1500) {
              const newPhoto = await getS3Base64(photo);
              if (newPhoto) {
                return {
                  base64: "data:image/jpg;base64," + newPhoto,
                  thumbUrl: "data:image/jpg;base64," + newPhoto,
                  name: "image.jpg",
                };
              }
            } else {
              return { base64: photo };
            }
          }
          return { base64: photo };
        })
      ).then((res) => {
        this.setState({ fileList: [...this.state.fileList, ...res] });
      });
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.base64);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") info.file.status = "done";

    let fileStock = {};
    let fileList = [...info.fileList];
    // Accept 8 files only
    fileList = fileList.slice(-10);
    fileList.forEach(function (file, index) {
      if (file.originFileObj) {
        fileStock = file;
        let reader = new FileReader();
        reader.onload = async (e) => {
          file.base64 = e.target.result;
        };
        reader.readAsDataURL(file.originFileObj);
      }
    });
    if (beforeUpload(fileStock, info.fileList.length)) {
      this.setState({ fileList });
      this.props.setPhotos({ fileList });
    }
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <React.Fragment>
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        <Upload
          multiple={this.props.multiple || false}
          accept=".jpg,.jpeg,.png"
          key={fileList.length}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length >= 10 ? null : uploadButton}
        </Upload>
      </React.Fragment>
    );
  }
}

export default PicturesWall;
