import {url} from '../api/url'
import {header} from '../api/header'

export const sendGridEmail = (templateId, email, templateData, token) => {
    fetch(`${url}/sendgrid/template-email`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
            emailToSend: email,
            templateId: templateId,
            templateData: templateData
        }),
      });
}