import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Link } from "react-router-dom";
import { message, Button, Input, Select, Popover, Skeleton } from "antd";

import { Modal } from "../../wrappers";
import Payment from "../paid_order/Paid";
import Load from "../../utils/Load";
import { priceCalc } from "../../utils/priceCalc";

import iconCheck from "../../images/icons/Check.svg";
import countryList from "../../utils/country_list.json";

const { Option } = Select;

const PopupOrder = ({
  order,
  setPassOrder,
  imageSelect,
  product,
  header,
  token,
  url,
  orders,
  setOrders,
  user,
  windowSize,
  sectionHeader,
  localProduct,
}) => {
  const [shippingSelect, setShippingSelect] = useState(
    order && order.shipping
      ? {
          price: order.shipping.price,
          time: order.shipping.time,
          name: order.shipping.name,
        }
      : {}
  );
  const [variantSelect, setVariantSelect] = useState({});
  const [paid, setPaid] = useState(false);
  const [messageSend, setMessage] = useState("");
  const [goPayment, setGoPayment] = useState(false);
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [createrId, setCreaterId] = useState("");
  const [stockProduct, setStockProduct] = useState("");
  const [totalPrice, setTotalPrice] = useState(undefined);
  const [load, setLoad] = useState(false);
  const [cost, setCost] = useState({});

  const { t } = useTranslation();

  const isStockAvailable = () => {
    return (
      parseInt(stockProduct) >= parseInt(order.quantity) ||
      (!product.stop_selling_out_stock && order.quantity < 1001)
    );
  };

  useEffect(() => {
    if (!stockProduct) setStockProduct(product.stock);
  }, [product]);

  useEffect(() => {
    setCost(
      priceCalc.sellerProductOrder({
        shipping: shippingSelect,
        quantity: order.quantity,
        product: variantSelect,
      })
    );
  }, [shippingSelect, order, variantSelect]);

  useEffect(() => {
    let sessionData = sessionStorage.getItem("orderToPay01supply");
    if (sessionData) {
      sessionData = JSON.parse(sessionData);
      if (sessionData && sessionData.variantSelect) setVariantSelect(sessionData.variantSelect);
      if (sessionData && sessionData.shippingSelect) setShippingSelect(sessionData.shippingSelect);

      sessionStorage.setItem("orderToPay01supply", "");
    }
  }, []);

  useEffect(() => {
    if (variantSelect?.price && shippingSelect?.price) {
      const price = parseFloat(
        (parseFloat(variantSelect.price) + (shippingSelect.price ? parseFloat(shippingSelect.price) : 0)) *
          order.quantity
      ).toFixed(2);
      setTotalPrice(price);
    }
  }, [variantSelect, shippingSelect]);

  useEffect(() => {
    if (localProduct._id && order._id && !shippingSelect.name && order.shipping_method_id) {
      const manualOrderShippingSelect = localProduct.shipping.find(
        (shipping) => shipping._id === order.shipping_method_id
      );
      if (manualOrderShippingSelect) setShippingSelect(manualOrderShippingSelect);
    }
    if (localProduct._id && order._id && !shippingSelect.name && order.variant_id) {
      const manualVariantSelect = localProduct.variants.find((variant) => variant._id === order.variant_id);
      if (manualVariantSelect)
        setVariantSelect({
          ...manualVariantSelect,
          name: `${manualVariantSelect.option1} ${
            manualVariantSelect.option2 ? "- " + manualVariantSelect.option2 : ""
          } ${manualVariantSelect.option3 ? "- " + manualVariantSelect.option3 : ""}`,
        });
    }
  }, [localProduct, order]);

  useEffect(() => {
    if (variantSelect.stock) setStockProduct(variantSelect.stock);
  }, [variantSelect]);

  useEffect(() => {
    if (!(variantSelect && variantSelect.name) && localProduct.name && !localProduct.variantBool)
      setVariantSelect({ price: localProduct.price, stock: localProduct.stock });
  }, [localProduct]);

  async function paidSellerFees() {
    if (
      !shippingSelect?.price ||
      (!variantSelect?.price && localProduct?.variants?.length && localProduct.variantBool)
    ) {
      message.error(t("messages.selectDeliveryTime"));
      setLoad(false);
    } else {
      fetch(`${url}/order/paid-shopify-fees`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          orderId: order._id,
          shipping: {
            name: shippingSelect.name,
            price: shippingSelect.price,
            time: shippingSelect.time,
            tracking_number: "",
            id: shippingSelect._id,
          },
          order: order,
          message: messageSend,
          product: localProduct,
          local_variant: variantSelect.name,
          variant: variantSelect,
          isVariant: variantSelect && variantSelect.name ? true : false,
        }),
      })
        .then((response) => {
          if (response.status !== 200) {
            message.error(t("messages.paymentError"));
          } else return response.json();
        })
        // If the card is declined, display an error to the user.
        .then(async (result) => {
          if (result && result.shopifyFeesUrl) {
            sessionStorage.setItem(
              "orderToPay01supply",
              JSON.stringify({
                order: order,
                shippingSelect: shippingSelect,
                variantSelect: variantSelect,
                image: imageSelect,
              })
            );
            setTimeout(() => {
              window.location.href = result.shopifyFeesUrl;
            }, 200);
          } else {
            setLoad(false);
            message.error(t("messages.paymentError"));
          }
        });
    }
  }

  const checkShippingAvailable = (shippingOrigin, customerCountry) => {
    if (shippingOrigin) {
      if (!(shippingOrigin && shippingOrigin.includes("-")) || !shippingOrigin) {
        if (
          shippingOrigin.includes(customerCountry) ||
          shippingOrigin === "rest of the world" ||
          shippingOrigin === "rest of world" ||
          !shippingOrigin
        ) {
          return true;
        } else return false;
      } else {
        const countrys = countryList;
        const originShipping = shippingOrigin.split(",");
        if (originShipping.some((origin) => origin === "0-0")) {
          return true;
        } else {
          for (let i in countrys) {
            let country = countrys[i];
            if (country.children) {
              for (let c in country.children) {
                const child = country.children[c];
                if (
                  child.key &&
                  originShipping.some((shipKey) => shipKey === child.key) &&
                  child.title.toLocaleLowerCase().includes(customerCountry.toLocaleLowerCase())
                ) {
                  return true;
                }
                if (i === countrys.length - 1 && c === country.children.length - 1) return false;
              }
            }
            if (i === countrys.length - 1) return false;
          }
        }
      }
    }
  };

  return (
    <Modal
      className="modal-order"
      title={`${t("order")} ${
        typeAccount === "seller" && order.shopify_order_number
          ? order.shopify_order_number
          : typeAccount === "seller"
          ? t("components.reseller.popupOrder.23")
          : order.nb_order_creater && "#" + order.nb_order_creater
      }`}
      onOk={() => {}}
      visible={order.status === "to pay"}
      onCancel={() => setPassOrder({})}
      footer={
        !paid
          ? [
              <Button key="back" onClick={() => setPassOrder({})}>
                {t("cancel")}
              </Button>,
            ]
          : [
              <Button key="back" onClick={() => setPaid(false)}>
                {t("back")}
              </Button>,
            ]
      }
    >
      {!localProduct.name && (
        <React.Fragment>
          <Skeleton className="image-product" loading={true} active />
          <Skeleton className="image-product" loading={true} active />
        </React.Fragment>
      )}
      {createrId && <Redirect target="_blank" to={`/messages?contact=${createrId}`} />}
      {load && <Load />}
      {!paid && localProduct.name ? (
        <div
          style={{ width: "100%", justifyContent: "center", alignItems: "flex-end" }}
          className="column popup-order"
        >
          <div
            style={windowSize > 1150 ? { width: "100%" } : { flexDirection: "column", width: "100%" }}
            className="row"
          >
            <div
              className="column"
              style={{ width: `${windowSize > 1150 ? "40%" : "100%"}`, marginLeft: "5%" }}
            >
              <p className="title" style={{ marginBottom: "5px", fontSize: "16px" }}>
                {t("components.reseller.popupOrder.1")}
              </p>
              <p className="title-300" style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("name")} :</span>
                {order.customer && order.customer.name}
              </p>
              {order.customer && order.customer.phone && (
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    {t("components.reseller.popupOrder.2")}
                  </span>
                  {order.customer.phone}
                </p>
              )}
              <p className="title-300" style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  {t("components.reseller.popupOrder.3")}
                </span>
                {order.customer && order.customer.address1}
              </p>
              {order.customer && order.customer.address2 && (
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    {t("components.reseller.popupOrder.4")}
                  </span>
                  {order.customer.address2}
                </p>
              )}
              {order.customer && order.customer.company && (
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("company")} :</span>
                  {order.customer.company}
                </p>
              )}
              <p className="title-300" style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  {t("components.reseller.popupOrder.5")}
                </span>
                {order.customer && `${order.customer.zip}, ${order.customer.city}`}
              </p>
              <p className="title-300" style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("country")} :</span>
                {order.customer && order.customer.country}
              </p>
            </div>
            <div
              className={windowSize > 1150 ? "column text-end" : "column"}
              style={{
                width: `${windowSize > 1150 ? "40%" : "100%"}`,
                marginLeft: "5%",
                marginRight: "0%",
                alignItems: "flex-end",
              }}
            >
              <p
                className="title text-end"
                style={
                  windowSize > 1150
                    ? { marginBottom: "5px", fontSize: "16px" }
                    : { marginBottom: "5px", fontSize: "16px", marginTop: "15px" }
                }
              >
                Details
              </p>
              <p className="text text-ellipsis text-end" style={{ fontSize: "14px", textAlign: "right" }}>
                {order.quantity}x{" "}
                {order.product && order.product.shopify_name
                  ? order.product.shopify_name
                  : order.product && order.product.name}
                <span className="text-roboto text-end">{order.variant && " - " + order.variant}</span>
              </p>
              <p className="title-300 text-end" style={{ fontSize: "13px", textAlign: "right" }}>
                {order.customer && order.customer.shipping_title
                  ? t("components.reseller.popupOrder.6") + order.customer.shipping_title
                  : t("components.reseller.popupOrder.7")}
              </p>
              <div style={{ marginTop: "10%" }} className="row">
                {windowSize > 1150 && (
                  <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "5px" }} />
                )}
                <p style={{ fontSize: "14px" }} className="title-300 text-end">
                  {t("components.reseller.popupOrder.8")}
                  <span
                    style={{ fontSize: "16px", marginLeft: "5px", fontWeight: "400", textAlign: "right" }}
                  >
                    {order.total_client_cost && order.total_client_cost !== "manual order"
                      ? order.total_client_cost
                      : order.total_client_cost &&
                        order.total_client_cost === "manual order" &&
                        t("components.reseller.popupOrder.9")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "30px", marginBottom: "30px" }} className="divider-horizontal" />
          <div
            style={{ width: "100%", alignItems: "flex-start" }}
            className={windowSize > 1150 ? "row" : "column"}
          >
            <div
              className="column"
              style={{
                width: `${windowSize > 1150 ? "30%" : "100%"}`,
                marginLeft: `${windowSize > 1150 ? "5%" : "0"}`,
              }}
            >
              <Link
                style={{ borderRadius: "0px" }}
                onClick={() => {
                  sessionStorage.setItem("lastNaviguationInventory", "true");
                }}
                className="container-img-inventory-product"
                to={`/product-details?name=${localProduct.name}&id=${localProduct._id}`}
              >
                <img style={{ marginBottom: "5px" }} src={imageSelect} className="image-product-inventory" />
              </Link>

              <div className="row">
                <Link
                  style={{
                    width: "200px",
                    marginRight: "20px",
                    fontSize: "16px",
                    color: "rgb(150, 150, 150)",
                    marginBottom: "10px",
                    textAlign: "start",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/shop/${order.creater?.name}`}
                  className="title-300"
                >
                  {t("from")} {order.creater?.name}
                </Link>
                {order.variant && (
                  <p
                    style={{
                      width: "200px",
                      fontSize: "14px",
                      color: "rgb(120, 120, 120)",
                      marginBottom: "10px",
                    }}
                    className="text-300"
                  >
                    {t("components.reseller.popupOrder.10")} <br />
                    {order.variant}
                  </p>
                )}
              </div>
              <div
                style={
                  windowSize <= 1150
                    ? { flexDirection: "column", alignItems: "flex-start", width: "100%" }
                    : {}
                }
                className="column start"
              >
                {sectionHeader === "to pay" && (
                  <Select
                    notFoundContent={t("components.reseller.popupOrder.11")}
                    onChange={(value) => {
                      let shipping = localProduct.shipping[value];
                      setShippingSelect(shipping);
                    }}
                    value={shippingSelect && shippingSelect.name && shippingSelect.name}
                    defaultValue="Shipping method"
                    style={windowSize > 1150 ? { width: 300 } : { width: "280px" }}
                  >
                    {localProduct.shipping &&
                      localProduct.shipping.length > 0 &&
                      localProduct.shipping.map((shipping, index) => {
                        if (
                          shipping &&
                          (!order.paid ||
                            (order.paid && !order.paid.shopify_secret_fees_id) ||
                            (order.paid &&
                              order.paid.shipping_select &&
                              order.paid.shipping_select._id === shipping._id))
                        )
                          return (
                            checkShippingAvailable(
                              shipping.origin && shipping.origin.toLowerCase(),
                              order.customer && order.customer.country.toLowerCase()
                            ) && (
                              <Option value={index}>
                                <p style={{ fontSize: "16px" }} className="text-bold">
                                  {shipping.name}
                                </p>
                                <div className="row">
                                  <p style={{ marginRight: "5px" }} className="text-bold">
                                    {t("components.reseller.popupOrder.12")}
                                  </p>
                                  <p>
                                    {shipping.time} {t("days")}
                                  </p>
                                </div>
                                {shipping.origin && !shipping.origin.includes("-") && (
                                  <div className="row">
                                    <p style={{ marginRight: "5px" }} className="text-bold">
                                      Destination:
                                    </p>
                                    <p>{shipping.origin}</p>
                                  </div>
                                )}
                                <div className="row">
                                  <p style={{ marginRight: "5px" }} className="text-bold">
                                    {t("cost")}:
                                  </p>
                                  <p>${shipping.price}</p>
                                </div>
                                <div className="row">
                                  <p style={{ marginRight: "5px" }} className="text-bold">
                                    {t("costByAdditionalProduct")}:
                                  </p>
                                  <p>${shipping.additionalProductPrice || shipping.price}</p>
                                </div>
                                <div
                                  style={{ width: "90%", marginLeft: "0" }}
                                  className="divider-horizontal"
                                />
                              </Option>
                            )
                          );
                      })}
                  </Select>
                )}

                {localProduct &&
                  localProduct.variants.length > 0 &&
                  localProduct.variantBool &&
                  sectionHeader === "to pay" && (
                    <Select
                      style={
                        windowSize > 1150
                          ? { width: 300, marginTop: "15px" }
                          : { width: "280px", marginTop: "15px" }
                      }
                      onChange={(value) => {
                        const index = parseInt(value.split("index=")[1].split("name=")[0]);
                        const name = value.split("name=")[1];
                        if (localProduct.variants[index] && localProduct.variants[index].stock)
                          setVariantSelect({ ...localProduct.variants[index], name: name });
                        else setVariantSelect({ stock: localProduct.stock, name: name });
                      }}
                      value={
                        variantSelect && variantSelect.name
                          ? variantSelect.name
                          : t("components.reseller.popupOrder.13")
                      }
                    >
                      {localProduct.variants.map((variant, index) => {
                        if (
                          !order.paid ||
                          (order.paid && !order.paid.shopify_secret_fees_id) ||
                          (order.paid &&
                            order.paid.shopify_secret_fees_id &&
                            order.paid.variant_select &&
                            parseFloat(order.paid.variant_select.price) === parseFloat(variant.price))
                        )
                          return (
                            <Option
                              value={`index=${index}name=${variant.option1} ${
                                variant.option2 ? "- " + variant.option2 : ""
                              } ${variant.option3 ? "- " + variant.option3 : ""}`}
                            >
                              <p style={{ fontSize: "16px" }} className="text-bold">
                                {variant.option1} {variant.option2 && "- " + variant.option2}{" "}
                                {variant.option3 && "- " + variant.option3}
                              </p>
                              <div style={{ width: "90%", marginLeft: "0" }} className="divider-horizontal" />
                            </Option>
                          );
                      })}
                    </Select>
                  )}
              </div>
            </div>
            <div
              className={windowSize > 1150 ? "column text-end" : "column"}
              style={{
                width: `${windowSize > 1150 ? "40%" : "100%"}`,
                marginLeft: `${windowSize > 1150 ? "15%" : "0"}`,
                marginRight: "0%",
                alignItems: "flex-end",
                marginTop: `${windowSize > 1150 ? "0" : "15px"}`,
              }}
            >
              <div className="row width-100 end" style={{ fontSize: "14px", textAlign: "end" }}>
                <p className="text-ellipsis" style={{ width: "80%", minHeight: "25px", maxHeight: "25px" }}>
                  {order.quantity}x {order.product.name} {order.variant && "- " + order.variant} :
                </p>
                <span
                  key={variantSelect.price}
                  style={{ fontSize: "16px", marginLeft: "5px", fontWeight: "400" }}
                >
                  {order.status !== "to pay" && order.paid
                    ? "$" + order.paid.product_paid
                    : variantSelect.price
                    ? "$" +
                      parseFloat(parseFloat(variantSelect.price) * parseFloat(order.quantity)).toFixed(2)
                    : "??"}
                </span>
              </div>
              <p className="title-300" style={{ fontSize: "15px" }}>
                {t("shipping")} :
                <span style={{ fontSize: "16px", marginLeft: "5px", fontWeight: "400" }}>
                  {shippingSelect && shippingSelect.price
                    ? "$" +
                      priceCalc.sellerProductOrder({
                        shipping: shippingSelect,
                        quantity: order.quantity,
                        product: variantSelect,
                      })?.shipping
                    : order.paid && order.paid.shipping_paid
                    ? "$" + order.paid.shipping_paid
                    : "??"}
                </span>
              </p>
              <div
                style={{ marginRight: "-10px", background: "rgb(230, 230, 230)" }}
                className="divider-horizontal"
              />
              <p className="title-300">
                {t("components.reseller.popupOrder.15")}{" "}
                <span style={{ fontSize: "20px", fontWeight: "400", textAlign: "right" }}>
                  {sectionHeader === "to pay" && totalPrice
                    ? "$" + (order.paid?.shopify_secret_fees_id ? cost.productsAndShippingPrice : cost.total)
                    : sectionHeader === "to pay"
                    ? "??"
                    : order.paid && order.paid.seller_paid
                    ? "$" +
                      parseFloat(
                        parseFloat(order.paid.shipping_paid) +
                          parseFloat(order.paid.product_paid) +
                          (parseFloat(order.paid.seller_fees) || 0)
                      ).toFixed(2)
                    : "??"}
                </span>
              </p>
              {sectionHeader === "to pay" && isStockAvailable() ? (
                <Button
                  onClick={() => {
                    if (!isStockAvailable()) message.error(t("messages.noStock"));
                    else if (!(shippingSelect && shippingSelect.price)) {
                      message.error(t("messages.selectShipping"));
                    } else if (
                      localProduct.variants.length > 0 &&
                      localProduct.variantBool &&
                      !(variantSelect && variantSelect.name)
                    ) {
                      message.error(t("messages.selectVariant"));
                    } else setPaid(true);
                  }}
                  style={
                    windowSize > 1150
                      ? { marginTop: "30px", width: "250px" }
                      : { marginTop: "10px", width: "100%", height: "48px" }
                  }
                  className="button orange-back"
                  key="submit"
                  type="primary"
                >
                  {t("components.reseller.popupOrder.17")}
                </Button>
              ) : (
                sectionHeader === "to pay" && (
                  <Popover
                    content={t("components.reseller.popupOrder.18")}
                    title={t("components.reseller.popupOrder.19")}
                  >
                    <button
                      style={
                        windowSize > 1150
                          ? { marginTop: "30px", width: "250px" }
                          : { marginTop: "10px", width: "110px" }
                      }
                      className="button-disable"
                    >
                      {t("components.reseller.popupOrder.17")}
                    </button>
                  </Popover>
                )
              )}

              <button
                onClick={() => setCreaterId(order?.creater?.id)}
                className="transparent-gradient-button"
                style={
                  windowSize > 1150
                    ? {
                        width: "250px",
                        marginTop: "15px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        fontSize: "13px",
                      }
                    : {
                        width: "100%",
                        marginTop: "15px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        fontSize: "13px",
                        height: "38px",
                      }
                }
              >
                {t("components.reseller.popupOrder.20")}
              </button>
            </div>
          </div>
        </div>
      ) : !goPayment && localProduct.name ? (
        <div style={{ width: "100%", justifyContent: "center", alignItems: "center" }} className="column">
          <Input.TextArea
            maxLength={200}
            style={windowSize > 1150 ? { width: "400px" } : { width: "60%", marginLeft: "20%" }}
            placeholder={t("components.reseller.popupOrder.22")}
            onChange={(e) => setMessage(e.target.value)}
          />
          {isStockAvailable() ? (
            <Button
              onClick={() => setGoPayment(true)}
              style={
                windowSize > 1150
                  ? { marginTop: "30px", width: "150px" }
                  : { marginTop: "30px", width: "60%", marginLeft: "20%", height: "48px" }
              }
              className="button big orange-back"
              key="submit"
              type="primary"
            >
              {t("buy").toUpperCase()}
            </Button>
          ) : (
            <Popover
              content={t("components.reseller.popupOrder.18")}
              title={t("components.reseller.popupOrder.19")}
            >
              <button
                style={
                  windowSize > 1150
                    ? { marginTop: "30px", width: "150px", height: "48px" }
                    : { marginTop: "30px", width: "60%", marginLeft: "20%", height: "48px" }
                }
                className="button-disable"
              >
                {t("buy").toUpperCase()}
              </button>
            </Popover>
          )}
        </div>
      ) : (
        localProduct.name && (
          <Payment
            setGoPayment={setGoPayment}
            createrId={order.creater.id}
            header={header}
            token={token}
            url={url}
            user={user}
            cost={cost}
            sellerCost={
              priceCalc.sellerProductOrder({
                shipping: shippingSelect,
                quantity: order.quantity,
                product: variantSelect,
              })?.total
            }
            variantSelect={variantSelect}
            shippingSelect={shippingSelect}
            totalPrice={totalPrice}
            windowSize={windowSize}
            imageSelect={imageSelect}
            messageSend={messageSend}
            setPassOrder={setPassOrder}
            setOrders={setOrders}
            orders={orders}
            order={order}
            data={{
              orderId: order._id,
              order,
              message: messageSend,
              product: localProduct,
              local_variant: variantSelect.name,
              variant: variantSelect,
              isVariant: variantSelect && variantSelect.name ? true : false,
              shipping: {
                name: shippingSelect.name,
                price: shippingSelect.price,
                time: shippingSelect.time,
                tracking_number: "",
                id: shippingSelect._id,
              },
            }}
          />
        )
      )}
    </Modal>
  );
};

export default PopupOrder;
