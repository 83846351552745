import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select, Button, message } from "antd";
import { Modal } from "../wrappers";
import PhoneInput from "react-phone-input-2";
import countryListJson from "../utils/country_list.json";

const { Option } = Select;

const ShippingInformations = ({
  setShippingInformations,
  windowSize,
  shippingInformations,
  location,
  notEditable,
  needShippingInfos,
  setNeedShippingInfos,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [informations, setInformations] = useState(
    shippingInformations.name
      ? shippingInformations
      : localStorage.getItem("shippingInformations")
      ? JSON.parse(localStorage.getItem("shippingInformations"))
      : {}
  );
  const [shippingInfoModal, setShippingInfoModal] = useState(location === "order" ? true : false);

  const { t } = useTranslation();

  useEffect(() => {
    let countryListRes = [];
    for (let region of countryListJson) {
      if (!region.children) countryListRes.push(region);
      else countryListRes = [...countryListRes, ...region.children];
    }
    setCountryList(countryListRes);
  }, []);

  const saveAddress = () => {
    if (
      !informations.address1 ||
      !informations.city ||
      !informations.zip ||
      !informations.country ||
      !informations.name
    )
      message.error(t("messages.incompleteInformations"));
    else {
      if (setNeedShippingInfos) setNeedShippingInfos(false);

      setShippingInformations(informations);
      setShippingInfoModal(false);
      localStorage.setItem("shippingInformations", JSON.stringify(informations));
    }
  };

  return (
    <>
      <div
        className={needShippingInfos ? "card border-red-fix" : "card"}
        style={{
          width: `${windowSize > 1150 ? "70%" : "100%"}`,
          alignItems: "flex-start",
          marginLeft: `${windowSize < 1150 && "0"}`,
        }}
      >
        <p className="title-bold">{t("components.shippingInformations.1")}</p>
        {!informations.address1 ? (
          <p
            onClick={() => setShippingInfoModal(true)}
            style={{ cursor: "pointer", marginTop: "30px", marginBottom: "20px" }}
            className="text-bold blue"
          >
            {t("components.shippingInformations.2")}
          </p>
        ) : (
          <div style={{ marginTop: "30px" }} className="column">
            <p className="text-bold">{informations.name}</p>
            <p>{informations.phone}</p>
            <p>{informations.address1}</p>
            {informations.address2 && <p>{informations.address2}</p>}
            <p>
              {informations.city}, {informations.zip}, {informations.province && informations.province + ", "}
              {informations.country}
            </p>

            <p
              onClick={() => setShippingInfoModal(true)}
              style={{ marginTop: "20px", fontSize: "16px" }}
              className="text-bold blue pointer underline"
            >
              {t("edit")}
            </p>
          </div>
        )}
      </div>
      {shippingInfoModal && (
        <Modal
          className="modal-1000"
          onOk={() => {}}
          visible={true}
          onCancel={() =>
            location === "order" ? setShippingInformations(false) : setShippingInfoModal(false)
          }
          footer={[
            <Button
              key="back"
              onClick={() =>
                location === "order" ? setShippingInformations(false) : setShippingInfoModal(false)
              }
            >
              {t("cancel")}
            </Button>,
          ]}
        >
          <div className="column" style={{ width: "100%" }}>
            {notEditable && (
              <div
                style={{ position: "absolute", zIndex: "10", height: "100%", width: "100%", opacity: "0" }}
              />
            )}
            <p className="title-bold">{t("components.shippingInformations.3")}</p>

            <p style={{ marginBottom: "6px", marginTop: "30px" }} className="text-bold start">
              Contact
            </p>
            <div style={{ width: "90%" }} className={windowSize > 1150 ? "row" : "column"}>
              <Input
                value={informations.name}
                id="address-input-1"
                style={
                  windowSize > 1150
                    ? { marginRight: "30px", width: "47.5%" }
                    : { width: "90%", marginBottom: "10px" }
                }
                placeholder={t("components.shippingInformations.placeholders.1")}
                onChange={(e) => {
                  setInformations({ ...informations, name: e.target.value });
                  setTimeout(() => {
                    document.getElementById("address-input-1").focus();
                  }, 30);
                }}
                className="border-red"
              />
              <PhoneInput
                enableSearch
                style={windowSize > 1150 ? { width: "47.5%" } : { width: "90%", marginBottom: "10px" }}
                className="input"
                placeholder={t("components.shippingInformations.placeholders.2")}
                value={informations.phone}
                onChange={(value) => {
                  setInformations({ ...informations, phone: value });
                }}
              />
            </div>

            <p style={{ marginTop: "30px", marginBottom: "6px" }} className="text-bold start">
              {t("address")}
            </p>
            <div style={{ width: "90%" }} className={windowSize > 1150 ? "row" : "column"}>
              <Input
                value={informations.address1}
                id="address-input-2"
                style={
                  windowSize > 1150
                    ? { marginRight: "30px", width: "47.5%" }
                    : { width: "90%", marginBottom: "10px" }
                }
                placeholder={t("components.shippingInformations.placeholders.3")}
                onChange={(e) => {
                  setInformations({ ...informations, address1: e.target.value });
                  setTimeout(() => {
                    document.getElementById("address-input-2").focus();
                  }, 30);
                }}
                className="border-red"
              />
              <Input
                value={informations.address2}
                id="address-input-3"
                style={windowSize > 1150 ? { width: "47.5%" } : { width: "90%", marginBottom: "10px" }}
                placeholder={t("components.shippingInformations.placeholders.4")}
                onChange={(e) => {
                  setInformations({ ...informations, address2: e.target.value });
                  setTimeout(() => {
                    document.getElementById("address-input-3").focus();
                  }, 30);
                }}
              />
            </div>
            <div style={{ width: "90%", marginTop: "20px" }} className={windowSize > 1150 ? "row" : "column"}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setInformations({
                    ...informations,
                    country: countryList[parseInt(value)].title,
                  });
                }}
                value={informations.country ? informations.country : "Select a country"}
                defaultValue={t("components.shippingInformations.placeholders.5")}
                style={
                  windowSize > 1150
                    ? { marginRight: "30px", width: "47.5%" }
                    : { width: "90%", marginBottom: "10px" }
                }
                className="border-red"
              >
                {countryList &&
                  countryList.map((country, index) => {
                    if (country.title !== "Rest of world") {
                      return <Option value={index}>{country.title}</Option>;
                    }
                  })}
              </Select>
              <Input
                value={informations.province}
                id="address-input-4"
                style={
                  windowSize > 1150
                    ? { marginRight: "30px", width: "47.5%" }
                    : { width: "90%", marginBottom: "10px" }
                }
                placeholder={t("components.shippingInformations.placeholders.6")}
                onChange={(e) => {
                  setInformations({ ...informations, province: e.target.value });
                  setTimeout(() => {
                    document.getElementById("address-input-4").focus();
                  }, 30);
                }}
              />
              <Input
                value={informations.city}
                id="address-input-5"
                style={
                  windowSize > 1150
                    ? { marginRight: "30px", width: "47.5%" }
                    : { width: "90%", marginBottom: "10px" }
                }
                placeholder={t("components.shippingInformations.placeholders.7")}
                onChange={(e) => {
                  setInformations({ ...informations, city: e.target.value });
                  setTimeout(() => {
                    document.getElementById("address-input-5").focus();
                  }, 30);
                }}
                className="border-red"
              />
              <Input
                value={informations.zip}
                id="address-input-6"
                style={windowSize > 1150 ? { width: "47.5%" } : { width: "90%", marginBottom: "10px" }}
                placeholder={t("components.shippingInformations.placeholders.8")}
                onChange={(e) => {
                  setInformations({ ...informations, zip: e.target.value });
                  setTimeout(() => {
                    document.getElementById("address-input-6").focus();
                  }, 30);
                }}
                className="border-red"
              />
            </div>
            {!notEditable && (
              <button
                onClick={saveAddress}
                style={
                  windowSize > 1150
                    ? {
                        minWidth: "130px",
                        width: "50%",
                        marginLeft: "25%",
                        marginTop: "50px",
                        fontSize: "18px",
                      }
                    : { width: "100%", marginTop: "30px" }
                }
                className="button orange-back"
              >
                {t("components.shippingInformations.4")}
              </button>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ShippingInformations;
