import React from "react";
import { useTranslation } from "react-i18next";

import illustration from "../../../images/illustrations/productsAnalytic/illustration1.svg";
import illustration2 from "../../../images/illustrations/productsAnalytic/illustration2.svg";
import illustration3 from "../../../images/illustrations/productsAnalytic/illustration3.svg";
import illustration4 from "../../../images/illustrations/productsAnalytic/illustration4.svg";

const SubButton = ({ setIsModalPricingDisplayed, text, user, setIsPopupLoginDisplayed }) => {
  const handleClick = () => {
    if (user?._id) {
      setIsModalPricingDisplayed(true);
    } else {
      setIsPopupLoginDisplayed(true);
    }
  };
  return (
    <button
      style={{
        boxShadow: "rgb(202 202 202) 1px 1px 4px",
        fontSize: "24px",
        padding: "8px 40px",
        marginTop: "25px",
        marginBottom: "5px",
      }}
      onClick={handleClick}
      className="button big orange-back"
    >
      {text}
    </button>
  );
};

const Header = ({ setIsModalPricingDisplayed, userCanAccess, user, setIsPopupLoginDisplayed }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="column width-100 header-product-analytic">
      <div className="card width-100">
        <div className="row-wrap width-100">
          <img className="illustration-1" src={illustration} alt="product analytics" />
          <div className="column start content-text-header" style={{ margin: "15px" }}>
            <h1 className="title-bold montserrat">{t("marketplaceHome.analytic.1")}</h1>
            <p style={{ marginBottom: "10px" }} className="text">
              {t("marketplaceHome.analytic.2")}
            </p>
            <p style={{ marginBottom: "10px" }} className="text">
              {t("marketplaceHome.analytic.3")}
            </p>
            <p className="text">{t("marketplaceHome.analytic.4")}</p>
            {!userCanAccess && (
              <SubButton
                text={t("marketplaceHome.analytic.5")}
                setIsModalPricingDisplayed={setIsModalPricingDisplayed}
                setIsPopupLoginDisplayed={setIsPopupLoginDisplayed}
                user={user}
                userCanAccess={userCanAccess}
              />
            )}
          </div>
        </div>
      </div>
        <div style={{ margin: "10px" }} className="row-wrap space-between width-100">
          <div style={{ alignItems: "center", padding: "30px 40px" }} className="card feature">
            <img src={illustration2} alt="Favorites trend" style={{ height: "170px" }} />
            <p style={{ marginTop: "20px", marginBottom: "10px", fontSize: "18px" }} className="text-bold">
              {t("marketplaceHome.analytic.6")}
            </p>
            <p style={{ maxWidth: "200px" }} className="text center">
              {t("marketplaceHome.analytic.7")}
            </p>
          </div>
          <div style={{ alignItems: "center", padding: "30px 40px" }} className="card feature">
            <img src={illustration3} alt="Favorites trend" style={{ height: "170px" }} />
            <p style={{ marginTop: "20px", marginBottom: "10px", fontSize: "18px" }} className="text-bold">
              {t("marketplaceHome.analytic.8")}
            </p>
            <p style={{ maxWidth: "200px" }} className="text center">
              {t("marketplaceHome.analytic.9")}
            </p>
          </div>
          <div style={{ alignItems: "center", padding: "30px 40px" }} className="card feature">
            <img src={illustration4} alt="Favorites trend" style={{ height: "170px" }} />
            <p style={{ marginTop: "20px", marginBottom: "10px", fontSize: "18px" }} className="text-bold">
              {t("marketplaceHome.analytic.10")}
            </p>
            <p style={{ maxWidth: "230px" }} className="text center">
              {t("marketplaceHome.analytic.11")}
            </p>
          </div>
        </div>
    </div>
  );
};

export default Header;
