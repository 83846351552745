import React, { useState } from "react";
import { Checkbox, message } from "antd";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../wrappers";

const CancelSub = ({ setCancelSub, user, setLoad, subscribeWithShopify, cancelStripeSub }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-account"
      visible={true}
      onCancel={() => setCancelSub("")}
      footer={[]}
    >
      <div style={{ alignItems: "flex-start" }} className="column">
        <p style={{ fontSize: "16px", marginBottom: "10px", marginTop: "15px" }} className="title start">
          {t("resellerPricing.cancel.1")}
        </p>
        <p className="text-bold start" style={{ marginTop: "5px", fontSize: "18px" }}>
          {t("resellerPricing.cancel.2", { count: user.products_id?.length - 9 })}
        </p>
        <Checkbox style={{ marginTop: "40px" }} onChange={() => setIsChecked(!isChecked)}>
          {t("resellerPricing.cancel.confirm")}
        </Checkbox>
        <div style={{ marginTop: "20px", width: "100%" }} className="row space-between">
          <button
            style={{ marginRight: "12px" }}
            className="button orange-back"
            onClick={() => setCancelSub("")}
          >
            {t("resellerPricing.cancel.no")}
          </button>
          <button
            style={{ marginLeft: "12px" }}
            className="button-orange"
            onClick={() => {
              if (!isChecked) {
                message.warning(t("resellerPricing.cancel.errorConfirm"));
              } else if (user.shopify && user.shopify.shop && user.shopify.token) {
                setLoad(true);
                subscribeWithShopify("free");
              } else {
                cancelStripeSub();
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }
            }}
          >
            {t("resellerPricing.cancel.yes")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSub;
