import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";

import { url } from "../api/url";
import { header } from "../api/header";
import SupplierCard from "../components/SupplierCard";
import Header from "../components/menu/marketplaceNavbar";
import logo from "../images/logo.svg";

import { routes } from "../routes";

const SuppliersList = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isSupplierLoad, setIsSupplierLoad] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [user, setUser] = useState({});
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isPaginationEnd, setIsPaginationEnd] = useState(false);
  const [page, setPage] = useState(0);
  const [isStickyNav, setIsStickyNav] = useState(false);
  const [resellerSupplierList, setResellerSupplierList] = useState([]);
  const [isResellerSupplierListEnd, setIsResellerSupplierListEnd] = useState(false);
  const [isResellerSupplierList, setIsResellerSupplierList] = useState(typeAccount === "seller");
  const [numberOfProducts, setNumberOfProducts] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  window.onscroll = function (e) {
    if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else setIsStickyNav(false);
  };

  useEffect(() => {
    const cardSize = parseInt(window.innerWidth) - ((parseInt(window.innerWidth) * 30) / 100 + 90);
    setNumberOfProducts(Math.floor(cardSize / 90));
  }, []);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (token) {
      getUser();
    }
    getPaginationSuppliers();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setPage(0);
    if (isResellerSupplierList && !isResellerSupplierListEnd) {
      getMySuppliers();
    } else {
      setIsLoading(false);
    }
  }, [isResellerSupplierList]);

  const getMySuppliers = async () => {
    const res = await fetch(`${url}/user/my-suppliers/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setIsLoading(false);
      if (resJson.suppliers?.length) {
        setResellerSupplierList(resJson.suppliers);
        setSuppliers(resJson.suppliers);
      }
      setIsResellerSupplierListEnd(true);
    } else {
      setIsResellerSupplierListEnd(true);
    }
  };

  const getPaginationSuppliers = async (newPage) => {
    const res = await fetch(`${url}/user?page=${newPage || page + 1}&type=creater`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setIsLoading(false);
      if (resJson.data?.length) {
        setSuppliers([...suppliers, ...resJson?.data]);
      } else {
        setIsPaginationEnd(true);
      }
      setIsSupplierLoad(true);
      setPage(newPage || page + 1);
    } else setIsPaginationEnd(true);
  };

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("supplierList")}</title>
        <link rel="canonical" href="https://app.01supply.com/suppliers" />
        <meta name="description" content="Find your 01supply suppliers list !" />
      </Helmet>
      <div className="page-start">
        {windowSize <= 1150 && (
            <Header windowSize={windowSize} location="cart" user={user} />
        )}

        <div
          className="column"
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            paddingBottom: "100px",
            width: "100vw",
            minHeight: "100vh",
            paddingTop: windowSize > 1150 ? "230px" : "50px",
          }}
        >
          {windowSize > 1150 && <Header windowSize={windowSize} location="cart" user={user} />}

          {user.type === "seller" && (
            <div style={{ marginBottom: "40px" }} className="row">
              <div
                onClick={() => !isResellerSupplierList && setIsResellerSupplierList(true)}
                className={`double-button left ${isResellerSupplierList && "active"}`}
              >
                {t("mySuppliers")}
              </div>
              <div
                onClick={() => isResellerSupplierList && setIsResellerSupplierList(false)}
                to={routes.resellerPricing(i18n.language).path}
                className={`double-button right pointer ${!isResellerSupplierList && "active"}`}
              >
                {t("allSuppliers")}
              </div>
            </div>
          )}

          {isSupplierLoad && suppliers.length > 0 ? (
            <InfiniteScroll
              key={isResellerSupplierList}
              style={{ width: "100%", overflowX: "hidden" }}
              dataLength={isResellerSupplierList ? resellerSupplierList.length : suppliers.length} //This is important field to render the next data
              next={!isLoading && getPaginationSuppliers}
              refreshFunction={!isLoading && getPaginationSuppliers}
              hasMore={
                (isResellerSupplierList && !isResellerSupplierListEnd) ||
                (!isResellerSupplierList && !isPaginationEnd)
              }
              loader={
                <Skeleton
                  style={{ marginTop: "20px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                  className="image-product"
                  loading={true}
                  active
                />
              }
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
            >
              {isResellerSupplierList
                ? resellerSupplierList.map(
                    (supplier, index) =>
                      supplier.shop?.name &&
                      supplier.products_id?.length > 0 && (
                        <SupplierCard
                          key={index}
                          supplier={supplier}
                          windowSize={windowSize}
                          numberOfProducts={numberOfProducts}
                          isResellerSupplierList={isResellerSupplierList}
                        />
                      )
                  )
                : suppliers.map(
                    (supplier, index) =>
                      supplier.shop?.name &&
                      supplier.products_id?.length > 0 && (
                        <SupplierCard
                          key={index}
                          supplier={supplier}
                          windowSize={windowSize}
                          numberOfProducts={numberOfProducts}
                          isResellerSupplierList={isResellerSupplierList}
                        />
                      )
                  )}
            </InfiniteScroll>
          ) : (
            <>
              <Skeleton
                style={{ marginTop: "20px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                className="image-product"
                loading={true}
                active
              />
              <Skeleton
                style={{ marginTop: "20px", width: `${windowSize > 1150 ? "60vw" : "100%"}` }}
                className="image-product"
                loading={true}
                active
              />
            </>
          )}
          {!resellerSupplierList.length && isResellerSupplierList && (
            <div className="column center">
              <p className="title montserrat bold">
                {t("youHaveNoSuppliers")},
                <span
                  onClick={() => setIsResellerSupplierList(false)}
                  className="underline pointer"
                  style={{ marginLeft: "5px" }}
                >
                  {t("findSuppliers")}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuppliersList;
