import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article23/dropshipping-coaching.jpg";
import dropshippingGurus from "../../../../images/blog/global/article23/dropshipping-gurus.jpg";
import coachingOffers from "../../../../images/blog/global/article23/coaching-offers.jpg";


const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat start" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Dropshipping coaching programs <br />(Best mentor & e-commerce courses)
      </h1>
      <p className="text-300">08 november 2022 - 4 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Dropshipping coaching" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        You may be wondering what is the best solution to start dropshipping and e-commerce. <b>If you are a beginner</b>, 
        there are a lot of courses available out there but they are not always relevant to your budget and your personal situation.
      </p>
      <p style={{ marginBottom: "20px" }}>
        In this article, we will go through the existing solutions and advise you on the <b>best way to learn e-commerce today</b>.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#what-is-dropshipping" title="What is dropshipping?" >
            <Anchor.Link href="#dropshipping-advantages" title="Dropshipping advantages" />
            </Anchor.Link>
            <Anchor.Link href="#learn-dropshipping" title="How do I learn dropshipping?" />
            <Anchor.Link href="#ecommerce-coach" title="Best e-commerce coach "/>
            <Anchor.Link href="#skill-needed-dropshipping" title="What skills do you need for dropshipping?"/>
            <Anchor.Link href="#best-ecommerce-course" title="Which course is best for dropshipping?"/>
          </Anchor>
        }
      />
      <h2 id="what-is-dropshipping">What is dropshipping?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Dropshipping is an <b>e-commerce logistic system</b> where the online retailer <b>doesn’t have any stock</b>.
        In that case, it is the supplier that is responsible for shipping the goods to the final consumer.
        The e-commerce owner is in charge of promoting and selling the products on his website.
      </p>
      <h3 id="dropshipping-advantages">Dropshipping advantages
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Dropshipping has <b>huge advantages for beginners</b>.
        Indeed, as you don’t have to buy a large stock of products for your online store,
        you save literally thousands of dollars that you can invest in advertising and store creation.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Additionally, you can <b>work with suppliers close to your customers</b> so delivery times are very short.
        For example, if you are a USA resident and you want to open a dropshipping store for the European market,
        you can easily work with European suppliers to achieve insane delivery times (max 3-4 days).
      </p>
      <h2 id="learn-dropshipping">How do I learn dropshipping?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        The Internet is a mess of information.
        You can literally find thousands of strategies and techniques to start an online store and to make a living online.
        Therefore, it is best to <b>stick to one strategy at a time</b> as they are not always compatible.
      </p>
      <p style={{ marginBottom: "20px" }}>
        That is even more true when you are a beginner.
        When you are just starting you do not master every marketing technique and advertising platform,
        that is why it is best to specialize in one strategy at a time and to become good at it.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>When learning online, you have two choices</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Dropshipping courses</b>: this will give you an overview of a specific topic and strategies will vary depending on the guru.
        </li>
        <li>
          <b>Dropshipping coaching programs</b>: going through custom coaching will allow you to receive a strategy that fits your situation and your needs.
          And you can directly ask your questions at the same time. The coaching sessions often last several months to help you grow successfully.
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        <b>If you are just starting it is better to choose a coaching program</b> as the coach will tell you what mistakes you can avoid.
        And this could literally save you thousands of dollars as beginners often lose a lot of money in advertising.
      </p>
      <p style={{ marginBottom: "20px" }}>
        When you start making sales, it can be interesting to buy a full course as you will gain more knowledge on a specific topic
        and this will allow you to improve your skills in Facebook Advertising for example.
      </p>
      <h2 id="ecommerce-coach">Best e-commerce coach
      </h2>
      <img src={dropshippingGurus} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Dropshipping Gurus" />
      <p style={{ marginBottom: "20px" }}>
        There is a <b>difference between e-commerce gurus and real coaches</b>. Gurus often make a living selling online courses.
      </p>
      <p style={{ marginBottom: "20px" }}>
        On the other hand, coaches often have an active e-commerce store and can really help you and guide you through your dropshipping journey.
      </p>
      <p style={{ marginBottom: "20px" }}>
        At 01supply, we work with more than 450 e-merchants and we help them succeed thanks to our <b>dropshipping expertise</b>.
        Indeed our core activity is growing with online resellers as it is also beneficial to us.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Therefore, we connected with <b>verified e-commerce owners</b> that act as 01supply coaches.
        They are available to give you a full mentorship to success.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here are our three coaching programs</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b><a href="https://app.01supply.com/e-commerce-starter-coaching" target="blank_" alt="Starter Package" className="blue"><u>Starter Package</u></a></b>:
          One store analysis and 30 minutes Q&A.
        </li>
        <li>
          <b><a href="https://app.01supply.com/e-commerce-advanced-coaching" target="blank_" alt="Advanced dropshipping accompaniment" className="blue"><u>Advanced Accompaniment</u></a></b>:
          One hour of phone coaching each month + weekly strategies via email + 5 dropshipping crash courses.
        </li>
        <li>
          <b><a href="https://app.01supply.com/e-commerce-pro-coaching" target="blank_" alt="Pro dropshipping accompaniment" className="blue"><u>Pro Accompaniment</u></a></b>:
          Three hours of phone coaching each month + weekly strategies via email
          + 8 dropshipping crash courses + one full course from the 01supply Academy catalog each month.
        </li>
      </ul>
      <a href="https://app.01supply.com/ecommerce-coaching-presentation" target="blank_" alt="ecommerce coaching offers"><img src={coachingOffers} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Dropshipping coaching"/></a>
      <p style={{ marginBottom: "20px" }}>
        Starting now, you can already <b>book a <u>free</u> 5 minutes call</b> with a 01supply coach.
        During this short call, you will be able to ask questions and the coach will answer you for free.
      </p>
      <p style={{ marginBottom: "20px" }}>
        To book your 5 minutes free coaching
        session, <a href="https://app.01supply.com/e-commerce-advanced-coaching" target="blank_" className="blue" alt="5 minutes coaching"><u>click here</u></a> and
        accept the chat request at the bottom right of your screen.
      </p>
      <h2 id="skill-needed-dropshipping">What skills do you need for dropshipping?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Dropshipping doesn’t require many skills and it is very beginner friendly.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is a list of some important skills you should master</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Understanding computers</b> and basic software
        </li>
        <li>
          Having good <b>spelling and grammar</b>
        </li>
        <li>
          Being able to create graphics and <b>edit photos</b>
        </li>
        <li>
          <b>Marketing</b> skills: knowing how to sell and how to create interesting promotions
        </li>
        <li>
          The <b>determination</b> as this will differentiate a successful store owner from one that gives up at the first challenge.
        </li>
      </ul>
      <h2 id="best-ecommerce-course">Which course is best for dropshipping?
      </h2>
      <p style={{ marginBottom: "20px" }}>
        There are a lot of online courses out there. Each of them relates to a specific topic.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here are the most common ones</u>:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <li>
          Facebook Advertising
        </li>
        <li>
          Google Advertising
        </li>
        <li>
          Creating a nice Shopify store
        </li>
        <li>
          Promoting on Instagram
        </li>
        <li>
          Tiktok Advertising
        </li>
        <li>
          Etc...
        </li>
      </ol>
      <p style={{ marginBottom: "20px" }}>
        Depending on your level, courses may not suit you best. Indeed, <b>Facebook is very popular amongst beginners</b>, but it is the most complex acquisition channel by far.
      </p>
      <p style={{ marginBottom: "20px" }}>
        That is why we always recommend you <b>start with coaching sessions</b>, at least to understand what you can and can’t do with your actual resources (time, money, skills).
      </p>
      <p style={{ marginBottom: "20px" }}>
        Note that 
        the <b><a href="https://app.01supply.com/ecommerce-coaching-presentation" alt="coaching programs dropshipping" target="blank_" className="blue"><u>01supply coaching programs</u></a> contain 
          crash courses</b> that are relevant to your situation.
        For example, we start by teaching you how to create a nice and converting Shopify/Wix/Woocommerce/… store.
        We won’t teach you the AAMRR strategy if you are just a beginner as it is way too advanced for you.
      </p>
    </div>
  );
};

export default Content;
