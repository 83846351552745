import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

const SupplierAnchor = ({ windowSize }) => {
  const { t } = useTranslation();
  return (
    <div
      style={windowSize > 1150 ? { position: "absolute", left: "50px", top: "110px" } : { width: "90%" }}
      className="column documentation-anchor"
    >
      <p style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }} className="title-roboto">
        Documentation
      </p>
      <Anchor affix={windowSize > 1150 ? true : false}>
        <Anchor.Link className="text-bold" title="Getting started">
          <Link style={{ fontWeight: "400" }} to="/what-is-01supply-for-supplier">
            <Anchor.Link href="#what-is-01supply" title={t("components.anchor.1")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/marketplace-documentation-for-supplier">
            <Anchor.Link href="#marketplace" title={t("components.anchor.2")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/your-supplier-account-documentation">
            <Anchor.Link href="#your-account" title={t("components.anchor.3")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/shipping-methods-documentation">
            <Anchor.Link href="#shipping-methods" title={t("components.anchor.4")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/getting-paid-documentation">
            <Anchor.Link href="#getting-paid" title={t("components.anchor.5")} className="text-ref" />
          </Link>
        </Anchor.Link>

        <Anchor.Link style={{ fontSize: "18px" }} className="text-bold" title="Selling">
          <Link style={{ fontWeight: "400" }} to="/how-sell-on-01supply-supplier-documentation">
            <Anchor.Link href="#how-sell" title={t("components.anchor.6")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/how-create-product-supplier-documentation">
            <Anchor.Link href="#create-product" title={t("components.anchor.7")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/how-import-csv-products-supplier-documentation">
            <Anchor.Link href="#import-csv" title={t("components.anchor.8")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/transactions-fees-supplier-documentation">
            <Anchor.Link href="#transaction-fees" title={t("components.anchor.9")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/fulfill-my-order-supplier-documentation">
            <Anchor.Link href="#fulfill-order" title={t("components.anchor.10")} className="text-ref" />
          </Link>
        </Anchor.Link>

        <Anchor.Link style={{ fontSize: "18px" }} className="text-bold" title="Integrations">
          <Link style={{ fontWeight: "400" }} to="/what-is-dropshipping?supplier=true">
            <Anchor.Link
              href="#what-is-dropshipping"
              title={t("components.anchor.11")}
              className="text-ref"
            />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/dropshipping-reseller">
            <Anchor.Link
              href="#dropshipping-reseller"
              title={t("components.anchor.12")}
              className="text-ref"
            />
          </Link>
        </Anchor.Link>
      </Anchor>
    </div>
  );
};

export default SupplierAnchor;
