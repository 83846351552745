import React, { useEffect, useState } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Legend, Bar } from "recharts";
import moment from "moment";

const SubscriptionsRepartitionChart = ({ intervals }) => {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (intervals?.length) {
      const interval = intervals[intervals.length - 1];
      setDatas([
        {
          reseller_with_basic_plan_yearly: interval.reseller_with_basic_plan_yearly,
          reseller_with_advanced_plan_yearly: interval.reseller_with_advanced_plan_yearly,
          reseller_with_premium_plan_yearly: interval.reseller_with_premium_plan_yearly,
        },
      ]);
    }
  }, [intervals]);

  if (!datas.length) {
    return <></>;
  }

  const CustomLabel = (props) => {
    const { x, y, width, height, value, title } = props;
    const current_yearly_reseller_subscription =
      datas[0].reseller_with_basic_plan_yearly +
      datas[0].reseller_with_advanced_plan_yearly +
      datas[0].reseller_with_premium_plan_yearly;
    const percentage = (value / current_yearly_reseller_subscription) * 100;

    return (
      <g>
        <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
          ({value}) {title}
        </text>
        <text x={x + width / 2} y={y + 30} fill="#666" textAnchor="middle" dy={-6}>
          {percentage.toFixed(2)}%
        </text>
      </g>
    );
  };

  return (
    <div
      className="card"
      style={{
        width: "40vw",
        margin: "2vw",
        boxShadow: "none",
      }}
    >
      <div style={{ marginBottom: "20px" }} className="row row-wrap width-100 space-between">
        <p style={{ margin: "10px", fontSize: "18px" }} className="title-bold start">
          Répartition abonnements (Annuel) : <br />{" "}
        </p>
      </div>
      <ResponsiveContainer minHeight="250px" minWidth="300px" width="100%" height="100%">
        <BarChart data={datas} margin={{ top: 20, right: 30, left: 30, bottom: 5 }} barCategoryGap={40}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="reseller_with_basic_plan_yearly"
            fill="#badbff"
            label={<CustomLabel title="Basic" />}
            barCategoryGap="30%"
          />
          <Bar
            dataKey="reseller_with_advanced_plan_yearly"
            fill="#abd2fc"
            label={<CustomLabel title="Avancé" />}
            barCategoryGap="30%"
          />
          <Bar
            dataKey="reseller_with_premium_plan_yearly"
            fill="#99c9fc"
            label={<CustomLabel title="Premium" />}
            barCategoryGap="30%"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SubscriptionsRepartitionChart;
