import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LogoImg from "../../../../images/logo.svg";
import { Link } from "react-router-dom";
import MobileMenu from "../../../../components/menu/mobile/FirstMenu";
import { Helmet } from "react-helmet";
import Footer from "../../../../components/menu/Footer";

import img7 from "../../../../images/landing/img7.svg";
import img8 from "../../../../images/landing/img8.svg";
import img9 from "../../../../images/landing/img9.svg";
import img10 from "../../../../images/landing/img10.svg";
import { routes } from "../../../../routes";

const BecomeSupplier2 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();

    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={
        windowSize > 1150
          ? { paddingLeft: "15vw", paddingRight: "15vw", alignItems: "center", overflowX: "hidden" }
          : { alignItems: "center" }
      }
      className="page"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>How does 01supply work? (Illustration) | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/how-work-01supply" />
        <meta
          name="description"
          content="Learn how to use 01supply by referencing your products for thousands of resellers around the world"
        />
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "How does 01supply work? (Illustration)",
          "image": [
            "https://app.01supply.com/static/media/img7.f430c7ef.svg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
      </Helmet>
      {windowSize < 1150 && <MobileMenu />}
      {windowSize > 1150 && (
        <div className="row" style={{ width: "100%", justifyContent: "center", margin: "2vw" }}>
          <div style={{ display: "flex", width: "80%", height: "3vw" }}>
            {/* logo */}
            <div style={{ flex: "1", display: "flex", float: "left", height: "3vw" }}>
              <a href="/">
                <img
                  alt="logo 01supply"
                  style={{ width: "200px", height: "auto", marginRight: "5vw", justifyContent: "left" }}
                  src={LogoImg}
                />
              </a>
            </div>

            {/* menu */}
            <div
              style={{
                flex: "1",
                display: "flex",
                float: "right",
                height: "3vw",
                justifyContent: "flex-end",
                placeContent: "space-between",
              }}
            >
              <a
                style={{ fontSize: "16px", fontWeight: "600", transform: "translateY(25%)" }}
                href="https://01supply.com"
                className="title-300 black"
              >
                Home
              </a>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to={routes.joinUs(i18n.language).path + "?supplier"}
                className="title-300 black"
              >
                {t("suppliers")}
              </Link>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to="/supplier-pricing"
                className="title-300 black"
              >
                Pricing
              </Link>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to={routes.marketplace(i18n.language).path}
                className="title-300 black"
              >
                Marketplace
              </Link>
              <Link
                to={routes.joinUs(i18n.language).path + "?login"}
                className="button orange-back"
                style={{ height: "2vw", transform: "translateY(20%)" }}
              >
                LOGIN
              </Link>
            </div>
          </div>
        </div>
      )}

      <h1
        style={{ fontSize: "40px", position: "absolute", left: "20vw", top: "150px" }}
        className="title-roboto"
      >
        How does 01supply work ?
      </h1>

      <div className="column center" style={windowSize > 1150 ? { width: "70vw" } : { width: "95vw" }}>
        <h4
          style={{
            marginTop: "150px",
            fontSize: "30px",
            marginBottom: "30px",
            opacity: "0.8",
            textAlign: "center",
          }}
          className="title-roboto"
        >
          This is 01supply :
        </h4>
        <img alt="01supply industry working" src={img7} style={{ width: "80%", height: "auto" }} />
        <h3
          style={{
            marginTop: "50px",
            fontSize: "30px",
            marginBottom: "30px",
            opacity: "0.8",
            textAlign: "center",
          }}
          className="title-roboto"
        >
          What’s inside the factory ?
        </h3>
        <img alt="selling internet partner" src={img8} style={{ width: "80%", height: "auto" }} />
        <h2
          style={{
            marginTop: "50px",
            fontSize: "30px",
            marginBottom: "30px",
            opacity: "0.8",
            textAlign: "center",
          }}
          className="title-roboto"
        >
          How do they bring me sales ?
        </h2>
        <img alt="cute supplier shop" src={img9} style={{ width: "80%", height: "auto" }} />
        <h2
          style={{
            marginTop: "50px",
            fontSize: "30px",
            marginBottom: "30px",
            opacity: "0.8",
            textAlign: "center",
          }}
          className="title-roboto"
        >
          And here is how it goes :
        </h2>
        <img alt="supplier and reseller sales process" src={img10} style={{ width: "80%", height: "auto" }} />

        <Link
          style={{ marginBottom: "30px", marginTop: "50px" }}
          className="button big"
          to={routes.joinUs(i18n.language).path + "?supplier"}
        >
          Join us now
        </Link>
        <Link to={routes.becomeSupplier3(i18n.language).path} className="text-gradient">
          (3/3) How to setup my 01supply account ?
        </Link>
        <Link
          style={{ marginTop: "20px" }}
          to={routes.becomeSupplier1(i18n.language).path}
          className="title-roboto-300"
        >
          {"<"} Back
        </Link>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default BecomeSupplier2;
