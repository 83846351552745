import React from "react";
import { useTranslation } from "react-i18next";

const StatsCard = ({ affiliations, user }) => {
  const { t } = useTranslation();

  const averageTraffic = () => {
    let average = 0;
    affiliations?.generated_traffic?.forEach((traffic) => (average += traffic.number));
    return parseInt(average / affiliations?.generated_traffic?.length || 0);
  };

  const totalTraffic = () => {
    let total = 0;
    affiliations?.generated_traffic?.forEach((traffic) => (total += traffic.number));
    return total;
  };

  const totalEarn = () => {
    let total = 0;
    affiliations?.payout_history?.forEach((payout) => (total += parseFloat(payout.amount)));
    return total;
  };

  return (
    <div className="row-wrap width-100 start">
      <div className="stats-box">
        <p style={{ marginBottom: "10px" }} className="title-bold montserrat-800">
          {t("affiliateDashboard.statistics.generatedTraffic")}
        </p>
        <>
          <p>
            {t("affiliateDashboard.statistics.today")}:{" "}
            <b>
              {affiliations?.generated_traffic?.[affiliations?.generated_traffic?.length - 1]?.number || 0}
            </b>{" "}
          </p>
          <p>
            {t("affiliateDashboard.statistics.averagePerDay")}: <b>{averageTraffic() || 0}</b>{" "}
          </p>
          <p>
            {t("affiliateDashboard.statistics.totalSinceStart")}: <b>{totalTraffic() || 0}</b>{" "}
          </p>
        </>
      </div>
      <div className="stats-box">
        <p style={{ marginBottom: "10px" }} className="title-bold montserrat-800">
          {t("affiliateDashboard.statistics.generatedRegistration")}
        </p>
        {affiliations?.affiliations && (
          <>
            <p>
              {t("affiliateDashboard.statistics.totalSinceStart")}:{" "}
              <b>{affiliations.affiliations.length || 0}</b>{" "}
            </p>
          </>
        )}
      </div>
      <div className="stats-box">
        <p style={{ marginBottom: "5px" }} className="title-bold montserrat-800">
          {t("affiliateDashboard.statistics.currentSubscription")}
        </p>
        <p className="green title-bold">
          {localStorage.getItem("admin") !== "true" && affiliations.monthlySubcription
            ? (
                (parseFloat(affiliations.monthlySubcription) *
                  (user?.affiliate?.my_commission_percentage || 20)) /
                100
              ).toFixed(2)
            : affiliations.monthlySubcription || 0}
          $ <span style={{ fontSize: "16px", color: "grey", fontWeight: "400" }}>/{t("months")}</span>
        </p>
        <p style={{ margin: "10px 0 5px 0" }} className="title-bold montserrat-800">
          {t("affiliateDashboard.statistics.gains")}
        </p>
        <p className="green title-bold">{affiliations?.monthlySubcription ? totalEarn() || 0 : 0}$</p>
      </div>
    </div>
  );
};

export default StatsCard;
