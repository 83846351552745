import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LogoImg from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import { Helmet } from "react-helmet";

import img22 from "../../../images/landing/img22.svg";
import downloadIcon from "../../../images/icons_white/Download.svg";
import { message, Input } from "antd";
import { routes } from "../../../routes";

const DownloadEbook = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [email, setEmail] = useState("");

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  const regexEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const subscribeEmail = async () => {
    if (regexEmail(email)) {
      message.success(t("messages.ebookSent"), 5);
    } else {
      message.error(t("messages.needFill.email"));
    }
  };

  return (
    <div
      style={
        windowSize > 1150
          ? { paddingLeft: "15vw", paddingRight: "15vw", alignItems: "center", paddingBottom: "200px" }
          : { alignItems: "center", paddingBottom: "200px" }
      }
      className="page"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Download Ebook : how to make more sales on Etsy | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/download-ebook-how-to-get-more-sales-on-etsy" />
        <meta
          name="description"
          content="This eBook helps you build your Etsy business and grow quickly thanks to 5 golden strategies."
        />
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "Download eBook, get more sales on Etsy",
          "image": [
            "https://app.01supply.com/static/media/img22.583be13e.svg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
      </Helmet>
      {windowSize < 1150 && <MobileMenu />}
      {windowSize > 1150 && (
        <div className="row" style={{ width: "100%", justifyContent: "center", margin: "2vw" }}>
          <div style={{ display: "flex", width: "80%", height: "3vw" }}>
            {/* logo */}
            <div style={{ flex: "1", display: "flex", float: "left", height: "3vw" }}>
              <a href="/">
                <img
                  alt="logo 01supply"
                  style={{ width: "200px", height: "auto", marginRight: "5vw", justifyContent: "left" }}
                  src={LogoImg}
                />
              </a>
            </div>

            {/* menu */}
            <div
              style={{
                flex: "1",
                display: "flex",
                float: "right",
                height: "3vw",
                justifyContent: "flex-end",
                placeContent: "space-between",
              }}
            >
              <a
                style={{ fontSize: "16px", fontWeight: "600", transform: "translateY(25%)" }}
                href="https://01supply.com"
                className="title-300 black"
              >
                Home
              </a>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to={routes.joinUs(i18n.language).path + "?supplier"}
                className="title-300 black"
              >
                {t("suppliers")}
              </Link>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to="/pricing"
                className="title-300 black"
              >
                Pricing
              </Link>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to={routes.marketplace(i18n.language).path}
                className="title-300 black"
              >
                Marketplace
              </Link>
              <Link
                to="/join-us?login"
                className="button orange-back"
                style={{ height: "2vw", transform: "translateY(20%)" }}
              >
                LOGIN
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="column" style={{ width: "80vw", justifyContent: "flex-start" }}>
        <button
          style={windowSize < 1150 ? { height: "auto", marginTop: "30px" } : {}}
          className="download-ebook-button"
        >
          FREE download for a limited period of time
        </button>
        <div
          style={{ width: "100%", marginTop: "30px", alignItems: "flex-start" }}
          className={windowSize > 1150 ? "row" : "column"}
        >
          <img
            alt="How to Get More SALES on Etsy"
            style={windowSize > 1150 ? { width: "35%" } : { width: "100%" }}
            src={img22}
          />
          <div
            style={windowSize > 1150 ? { marginLeft: "5%", width: "60%" } : { width: "100%" }}
            className="column"
          >
            <h1 style={{ fontSize: "35px" }} className="title-roboto-bold">
              Learn how to make more sales on Etsy
            </h1>
            <div className="row">
              <p style={{ marginRight: "10px" }} className="text-roboto-bold green">
                FREE
              </p>
              <div style={{ marginLeft: "5px" }} className="row">
                <div style={{ height: "1px" }} className="dashed" />
                4.99€
              </div>
            </div>
            <p style={{ marginTop: "30px", marginBottom: "20px" }} className="text-roboto-300">
              Format : PDF
            </p>
            <h2 className="text-roboto-bold">
              You are not getting any sales on Etsy ? You are having visits but no orders ?
            </h2>
            <h3 className="text-roboto-300">
              Should you advertise and pay or are there some secrets in order to be visible on the marketplace
              ?
            </h3>
            <h4 className="text-roboto-bold">
              This eBook helps you build your Etsy business and grow quickly thanks to 5 golden strategies.
            </h4>
            <Input
              value={email}
              style={
                windowSize > 1150
                  ? { width: "400px", marginBottom: "15px", height: "40px", marginTop: "20px" }
                  : { width: "100%", marginBottom: "15px", height: "40px", marginTop: "20px" }
              }
              placeholder="Your email"
              className="input"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={() => subscribeEmail()}
              style={
                windowSize > 1150
                  ? { width: "400px", display: "flex", alignItems: "center", flexDirection: "row" }
                  : {
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      fontSize: "20px",
                    }
              }
              className="button row title-roboto-bold white"
            >
              Get this eBook for free{" "}
              <img style={{ height: "30px", width: "auto", marginLeft: "10px" }} src={downloadIcon} />
            </button>
            <p style={{ marginTop: "5px" }} className="text-roboto-300">
              The eBook will be sent in PDF format to your email address.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadEbook;
