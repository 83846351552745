import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article25/importer-des-produits-etsy-sur-shopify-dropshipping.jpg";
import extension01supply from "../../../../images/blog/global/article25/chrome-extension-01supply.JPG";
import importerEtsy from "../../../../images/blog/global/article25/etsy-product-import.JPG";
import etsyShopify from "../../../../images/blog/global/article25/publier-produits-etsy-shopify.jpg";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Comment importer des produits Etsy sur Shopify? (5 étapes)
      </h1>
      <p className="text-300">30 novembre 2022 - 3 minutes de lecture</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Comment importer des produits Etsy sur Shopify?" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Si vous faites du dropshipping, vous vous êtes certainement déjà demandé comment <b>importer des produits Etsy sur votre boutique Shopify</b>.
        En effet, les produits Aliexpress sont vu et revus, et en [année], il y a beaucoup trop de concurrence pour ceux-ci.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Dans cet article, nous verrons comment importer des produits Etsy sur votre boutique Shopify,
        exactement comme vous le feriez en important des produits Aliexpress grâce à Oberlo ou Dsers par exemple.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#installer-extension-01supply" title="Étape 1: Installez l’extension 01supply sur Google Chrome" />
            <Anchor.Link href="#creer-compte-01supply" title="Étape 2: Créez un compte 01supply et liez votre boutique Shopify" />
            <Anchor.Link href="#trouver-produits-etsy" title="Étape 3: Trouvez des produits dans le catalogue Etsy à importer" />
            <Anchor.Link href="#importer-produit-etsy" title="Étape 4: Cliquez sur le bouton d’importation depuis la fiche produit Etsy" />
            <Anchor.Link href="#publier-depuis-01supply" title="Étape 5: Rendez-vous sur votre liste d’importation 01supply et publiez les produits sur votre boutique Shopify" />
            <Anchor.Link href="#artisans-dropshipping-etsy" title="Travailler avec des artisans Etsy en dropshipping" />
          </Anchor>
        }
      />
      <h2 id="installer-extension-01supply">Étape 1: Installez l’extension 01supply sur Google Chrome</h2>
      <hr/>
      <a
            class="button orange-back"
            target="_blank"
            href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
            alt="01supply chrome extension"
          >
            INSTALLER l'EXTENSION CHROME
          </a>
          <a
          target="_blank"
          alt="01supply chrome extension"
          href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg">
      <img src={extension01supply} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Extension Chrome 01supply Etsy vers Shopify" />
      </a>
      <p style={{ marginBottom: "20px" }}>
        Tout d’abord, il faut commencer par <b>installer
          l’<a href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg" target="blank_" className="blue"><u>extension Chrome de 01supply</u></a></b>
        qui va vous permettre d’automatiser l’importation en un clic.
      </p>
      <h2 id="creer-compte-01supply">Étape 2: Créez un compte 01supply et liez votre boutique Shopify
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Une fois l’extension ajoutée à Chrome, cliquez dessus et cliquez sur le bouton “Register” pour vous <b>créer un compte 01supply</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        À partir de là, il vous suffira de suivre les quelques étapes d’inscription afin de configurer votre compte.
        Ensuite, rendez-vous dans les paramètres, dans “Intégration de boutique”, et cliquez sur “Ajouter une boutique” pour ajouter votre boutique Shopify.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Vous devrez alors installer l’application 01supply sur votre boutique Shopify afin de terminer la mise en place du système de synchronisation et d’import de produits Etsy.
      </p>
      <h2 id="trouver-produits-etsy">Étape 3: Trouvez des produits dans le catalogue Etsy à importer
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Une fois tout cela terminé, vous pouvez à présent vous rendre sur Etsy et <b>lister les produits qui vous plaisent</b>.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Si vous souhaitez vendre les produits d’un vendeur Etsy sur votre boutique Shopify, pensez à lui <b>demander l’autorisation</b>.
        Envoyez lui simplement un message via la messagerie Etsy afin d’en parler avec lui.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Voici un modèle de message que vous pouvez réutiliser</u>:
      </p>
      <div style={{ width: '80%', textAlign: 'center', backgroundColor: '#e6e6e6', padding: '20px' }}>
        <p style={{ marginBottom: '5px', marginTop: '15px', textAlign: 'left' }} className="text-roboto"><i>
          Bonjour,
          <br /><br />
          Je m’appelle [prénom], je un revendeur en ligne français.<br />
          Vos produits m’ont beaucoup plu et c’est pourquoi j’aimerais avoir votre autorisation pour les vendre à mes clients sur ma boutique.<br />
          De mon côté, je viendrai commander chez vous lorsque je reçois des commandes de mes clients.<br /><br />
          Ai-je votre autorisation?
          <br /><br />
          Bien cordialement,<br />
          <br />
          NOM Prénom<br />
          (+33) Téléphone<br />
          (@) Email<br />
          Votre-site.com
        </i>
        </p>
      </div>
      <h2 id="importer-produit-etsy">Étape 4: Cliquez sur le bouton d’importation depuis la fiche produit Etsy
      </h2>
      <img src={importerEtsy} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Importer produit Etsy sur Shopify" />
      <p style={{ marginBottom: "20px" }}>
        Une fois que vous avez l’autorisation du propriétaire de la boutique Etsy, rendez-vous sur les fiches produits qui vous plaisent
        et <b>cliquez sur le bouton d’importation</b> 01supply.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Notez que si le produit a beaucoup de variantes (tailles, couleurs, …), l’importation peut prendre quelques temps.
        Ne cliquez pas plusieurs fois sur le bouton d’importation du même produit car cela ne fera que ralentir votre importation.
      </p>
      <h2 id="publier-depuis-01supply">Étape 5: Rendez-vous sur votre liste d’importation 01supply et publiez les produits sur votre boutique Shopify
      </h2>
      <img src={etsyShopify} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Alternative Oberlo Dsers pour Etsy" />
      <p style={{ marginBottom: "20px" }}>
        Une fois tous vos produits importés, rendez-vous sur votre liste d’improtation 01supply et sélectionnez les produits que vous souhaitez publier
        maintenant sur votre boutique Shopify.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Une fois ceux-ci sélectionnés, il vous suffit de <b>cliquer sur “Envoyer vers l’inventaire”</b>, et le tour est joué.
        Vos produits sont à présent bien sur votre boutique Shopify et vous pouvez commencer à les vendre.
      </p>
      <p style={{ marginBottom: "20px" }}>
        L’avantage de 01supply est que l’application va aussi <b>synchroniser vos commandes de produits Etsy</b>.
        Ainsi, lorsque vous recevrez des commandes pour un produit Etsy, vous pourrez directement la commander via l’onglet “commandes” dans votre interface 01supply.
      </p>
      <h2 id="artisans-dropshipping-etsy">Travailler avec des artisans Etsy en dropshipping
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Si vous souhaitez <b>créer une marque à partir de produits Etsy</b>, il est toujours possible de collaborer avec les créateurs
        Etsy pour leur proposer d’<b>afficher votre logo</b> sur leurs emballages ou leurs produits directement.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Ainsi, vous vous démarquerez largement de la concurrence et vos clients achèteront uniquement chez vous car ils seront amplement satisfaits de la qualité que vous proposez.
        Il est en effet <b>7x plus facile de demander à un ancien client d’acheter à nouveau</b>, plutôt que d’acquérir un nouveau client qui ne vous connait pas.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>Commencez dès à présent à vendre des produits de qualité en dropshipping et 
          installez l’<a href="https://apps.shopify.com/01supply?locale=fr" target="blank_" className="blue" rel="noreferrer nofollow"><u>application 01supply</u></a> sur votre boutique Shopify.</b>
      </p>
      <hr/>
      <a
            class="button orange-back"
            target="_blank"
            href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
            alt="01supply chrome extension"
          >
            INSTALLER l'EXTENSION CHROME 01SUPPLY
          </a>
    </div>
  );
};

export default Content;
