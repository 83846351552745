import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Redirect, Switch, useLocation } from "react-router-dom";
import { message } from "antd";

import { languages } from "./constants/languages";
import { updateUser } from "./services/updateUser";

import { affiliationServices } from "./services/affiliation";

import MarketPlace from "./pages/MarketPlace";
import JoinUs from "./pages/JoinUs";
import CreateEditProduct from "./pages/supplier/CreateEditProduct";
import Inventory from "./pages/Inventory";
import YourShopSupplier from "./pages/supplier/settings/YourShop";
import YourShopReseller from "./pages/reseller/settings/YourShop";
import ProductDetail from "./pages/ProductDetail";
import ImportList from "./pages/reseller/ImportList";
import Orders from "./pages/Orders";
import YourInformations from "./pages/YourInformations";
import PlanSupplier from "./pages/supplier/settings/Plans";
import DocumentationReseller from "./pages/documentation/ResellerDoc";
import DocumentationSupplier from "./pages/documentation/SupplierDoc";
import ResellerDoc from "./pages/reseller/settings/Documentation";
import SupplierDoc from "./pages/supplier/settings/Documentation";
import MobileSettingMenu from "./components/menu/mobile/SettingMenu";
import SupplierShop from "./pages/SupplierShop";
import ImportProductsCsv from "./pages/supplier/importProduct/ImportProductCsv";
import SupplierMore from "./pages/blog/supplier/SupplierMore";
import ResellerMore from "./pages/blog/reseller/ResellerMore";
import RedirectLogin from "./utils/RedirectLogin";
import ResellerSetup from "./pages/reseller/Setup";
import SupplierSetup from "./pages/supplier/Setup";
import Affiliation from "./pages/Affiliation";
import ApiConnect from "./pages/apiConnect";
import DownloadEbook from "./pages/blog/supplier/DownloadEbook";
import EbookArticle from "./pages/blog/supplier/GetMoreSalesOnEtsy";
import BecomeSupplier1 from "./pages/blog/supplier/becomeSupplier/BecomeSupplier1";
import BecomeSupplier2 from "./pages/blog/supplier/becomeSupplier/BecomeSupplier2";
import BecomeSupplier3 from "./pages/blog/supplier/becomeSupplier/BecomeSupplier3";
import MyPayments from "./pages/supplier/MyPayments";
import Home from "./pages/home/index";
import ImportEtsyProductShopify from "./pages/blog/supplier/ImportEtsytoShopify";
import SupplierService from "./pages/supplier/SupplierService";
import ManageShippingMethods from "./pages/supplier/settings/ManageShippings";
import SupplierCertifHandmade from "./pages/labels/CertifHandmade";
import SupplierCertifEco from "./pages/labels/CertifEco";
import VikingSuppliers from "./pages/blog/supplier/VikingSuppliers";
import FulfillOrder from "./pages/supplier/FulfillOrder";
import Blog from "./pages/blog/Blog";
import Messages from "./pages/chat/Messages";
import Cart from "./pages/Cart";
import PaymentMethods from "./pages/supplier/settings/PaymentMethods";
import Documentation from "./pages/Documentation";
import CoursePayment from "./pages/coursePayment";
import BoostPresentation from "./pages/boostPresentation";

// NOT CONNECTED
import SuppliersList from "./pages/SuppliersList";
import ResellerCoaching1 from "./pages/resellerCoaching/sellingPages/coaching1";
import ResellerCoaching2 from "./pages/resellerCoaching/sellingPages/coaching2";
import ResellerCoaching3 from "./pages/resellerCoaching/sellingPages/coaching3";
import ResellerCoachingPayment from "./pages/resellerCoachingPayment/index";
import EcommerceCoaching from "./pages/resellerCoaching/index";

import ProductsSuggestions from "./pages/marketplace/Suggestion";
import WinningProducts from "./pages/marketplace/WinningProducts";
import AnalyticProducts from "./pages/marketplace/AnalyticProducts";
import ProductsHighMargin from "./pages/marketplace/HighMargin";

import MarketplaceHome from "./pages/marketplaceHome/index";
import ExtensionPromotion from "./pages/extensionPromotion/index";

// SECONDARY HOMES

// ACADEMY
import AcademyDashboard from "./pages/academy/index";

// COACHING
import CoachingDashboard from "./pages/reseller/CoachingDashboard";

// RESELLER
import ResellerWelcomeForm from "./pages/reseller/welcomeForm";

// SUPPLIER
import Onboarding1 from "./pages/onboarding/Step1";
import Onboarding2 from "./pages/onboarding/Step2";
import SupplierOnboarding3 from "./pages/onboarding/supplier/Step3";
import SupplierOnboarding4 from "./pages/onboarding/supplier/Step4";
import Onboarding5 from "./pages/onboarding/Step5";
import SupplierGrowthBusiness from "./pages/supplier/settings/GrowthBusiness";
import MyResellers from "./pages/supplier/MyResellers";

// ADMIN
import UserList from "./pages/admin/UserList";
import UserPayments from "./pages/admin/UserPayments";
import ManageThematics from "./pages/admin/ManageThematicBySupplier";
import AssignThematic from "./pages/admin/AssignThematic";
import Charts from "./pages/admin/Charts";
import CollabMatching from "./pages/admin/CollabMatching";

import Sourcing from "./pages/reseller/sourcing/Sourcing";

import WhatEcoLabel from "./pages/labels/EcoLabelWhat";
import WhatHandmadeLabel from "./pages/labels/HandmadeLabelWhat";

// RESELLER DOC
import WhatIs01supply from "./pages/reseller/documentations/WhatIs01supply";
import MarketplaceResellerDoc from "./pages/reseller/documentations/Marketplace";
import YourAccountResellerDoc from "./pages/reseller/documentations/YourAccount";
import HowPlaceOrder from "./pages/reseller/documentations/HowPlaceOrder";
import HowTrackOrder from "./pages/reseller/documentations/HowTrackOrder";
import ShopifySetup from "./pages/reseller/documentations/ShopifySetup";
import SupplierLocation from "./pages/reseller/documentations/SupplierLocation";
import SupplierShippingMethods from "./pages/reseller/documentations/SupplierShippingMethod";
import SupplierQuantities from "./pages/reseller/documentations/SupplierQuantities";
import WhatIsDropshipping from "./pages/reseller/documentations/WhatIsDropshipping";
import DropshippingSupplierDoc from "./pages/reseller/documentations/DropshippingSupplier";

// SUPPLIER DOC
import WhatIs01supplyForSupplier from "./pages/supplier/documentations/WhatIs01supply";
import MarketplaceDocumentationForSupplier from "./pages/supplier/documentations/Marketplace";
import YourSupplierAccountDocumentation from "./pages/supplier/documentations/YourAccount";
import ShippingMethodsDocumentation from "./pages/supplier/documentations/ShippingMethods";
import GettingPaidSupplierDocumentation from "./pages/supplier/documentations/GettingPaid";
import HowSellSupplierDocumentation from "./pages/supplier/documentations/HowSell";
import HowCreateProductSupplierDocumentation from "./pages/supplier/documentations/CreateProduct";
import HowImportCsvSupplierDocumentation from "./pages/supplier/documentations/ImportCsv/ImportCsv";
import TransactionsFeesSupplierDocumentation from "./pages/supplier/documentations/Fees";
import FulfillOrderSupplierDocumentation from "./pages/supplier/documentations/FulfillOrder";
import DropshippingResellerDocumentation from "./pages/supplier/documentations/DropReseller";

// SUPPLIER COURSES
import SupplierCourse1 from "./pages/courses/supplier/course1";
import SupplierCourse2 from "./pages/courses/supplier/course2";
import SupplierCourse3 from "./pages/courses/supplier/course3";

// BLOG + ARTICLES
import BlogEmailTemplateSupplier from "./pages/blog/reseller/HowEmailTemplateDrop";
import BlogFindDropshipSupplier from "./pages/blog/reseller/findDropshipSupplier/FindDropshipSupplier";
import BlogJewelrySupplier from "./pages/blog/reseller/jewelrySupplier/JewelrySupplier";
import BlogSellBesideEtsy from "./pages/blog/supplier/SellBesideEtsy";
import Article13 from "./pages/blog/global/article13/Page";
import Article14 from "./pages/blog/global/article14/Page";
import Article15 from "./pages/blog/global/article15/Page";
import Article16 from "./pages/blog/global/article16/Page";
import Article17 from "./pages/blog/global/article17/Page";
import Article18 from "./pages/blog/global/article18/Page";
import Article19 from "./pages/blog/global/article19/Page";
import Article20 from "./pages/blog/global/article20/Page";
import Article21 from "./pages/blog/global/article21/Page";
import Article22 from "./pages/blog/global/article22/Page";
import Article23 from "./pages/blog/global/article23/Page";
import Article24 from "./pages/blog/global/article24/Page";
import Article25 from "./pages/blog/global/article25/Page";
import Article26 from "./pages/blog/global/article26/Page";
import Article27 from "./pages/blog/global/article27/Page";
import Article28 from "./pages/blog/global/article28/Page";
import Article29 from "./pages/blog/global/article29/Page";
import Article30 from "./pages/blog/global/article30/Page";
import Article31 from "./pages/blog/global/article31/Page";
import Article32 from "./pages/blog/global/article32/Page";
import Article33 from "./pages/blog/global/article33/Page";

// AFFILIATE
import AffiliationLink from "./pages/affiliateDashboard/AffiliateLinks";
import AffiliationPaymentRequest from "./pages/affiliateDashboard/PaymentRequest";
import AffiliationPaymentHistory from "./pages/affiliateDashboard/PaymentHistory";
import AffiliationStatistics from "./pages/affiliateDashboard/Statistics";

import PricingSupplier from "./pages/supplier/Pricing";
import PricingReseller from "./pages/reseller/Pricing";

import ResellerTools from "./pages/reseller/settings/Tools";
import ResellerPricingRules from "./pages/reseller/settings/PricingRules";

import ScrollToTop from "./utils/ScrollTopPage";
import useNavigationHistory from "./utils/useNavigationHistory";
import { saveDeviceConverter } from "./utils/priceConverter";

import "./style/style.scss";
import "./style/iconBurger.scss";
import "./style/tab.scss";
import "./style/blogstyle.scss";
import "./style/CountryCodeSelector.scss";
import "./pages/courses/style.scss";

import "./i18n";
import { klaviyoServices } from "./services/klaviyo";

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();

  useNavigationHistory();

  message.config({
    maxCount: 3,
  });

  useEffect(() => {
    const lastExecuted = localStorage.getItem("lastExecuted");
    const currentTime = new Date().getTime();
    const sixHoursInMilliseconds = 6 * 60 * 60 * 1000;

    // Si le code ne s'est jamais exécuté ou s'il s'est exécuté il y a plus de 6 heures
    if (!lastExecuted || currentTime - lastExecuted > sixHoursInMilliseconds) {
      if (i18n.language && localStorage.getItem("idAccountCreatosell")) {
        klaviyoServices.updateProfil({ properties: { lang: i18n.language } });
        updateUser({
          user: {
            language: i18n.language,
          },
        }).then((res) => res.status === 200 && localStorage.setItem("lastExecuted", currentTime)); // Mettre à jour le moment de la dernière exécution
      }
    }
  }, [i18n.language]);

  useEffect(() => {
    saveDeviceConverter();
    languages.forEach((lang) => window.location.pathname.includes(`/${lang}`) && i18n.changeLanguage(lang));

    // save affiliation invitation id
    let urlParams = new URL(window.location.href);
    let affiliationId = urlParams.searchParams.get("invitation");
    if (affiliationId) {
      localStorage.setItem("affiliationId", affiliationId);
    }

    if (
      localStorage.getItem("affiliationId") &&
      localStorage.getItem("affiliationTrafficAlreadySet") !== "true"
    ) {
      localStorage.setItem("affiliationTrafficAlreadySet", "true");
      affiliationServices.traffic({ affiliateId: localStorage.getItem("affiliationId") });
    }
  }, [location]);

  // algolia analytics
  window.aa("init", {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID,
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
  });

  const generateRoutes = (route) => {
    const langRoutes = languages.map((lang) => `/${lang}${route}`);
    return [route, ...langRoutes];
  };

  return localStorage.getItem("admin") === "true" ? (
    <Switch>
      <Route exact path="/" component={UserList} />
      <Route path="/assign-thematic" component={AssignThematic} />
      <Route path="/user-payments" component={UserPayments} />
      <Route path="/manage-thematics" component={ManageThematics} />
      <Route path="/product-details" component={ProductDetail} />
      <Route path="/shop" component={SupplierShop} />

      <Route path="/affiliation/payouts" component={Affiliation} />
      <Route path="/affiliation/referrals" component={Affiliation} />

      <Route path="/kpi" component={Charts} />
      <Route path="/collab-matching" component={CollabMatching} />

      <Route path="/connect" component={ApiConnect} />
      <Route path="/your-informations" component={YourInformations} />
      <Route path="/marketplace" component={MarketPlace} />
      <Route path={["/marketplace-home", "/fr/marketplace-home"]} component={MarketplaceHome} />
      <Route path={["/extension", "/fr/extension"]} component={ExtensionPromotion} />
      <Route
        path={["/marketplace-suggestion", "/fr/marketplace-suggestion"]}
        component={ProductsSuggestions}
      />
      <Route
        path={["/marketplace-high-margin", "/fr/marketplace-high-margin"]}
        component={ProductsHighMargin}
      />

      <Route
        path={["/marketplace-winning-products", "/fr/marketplace-winning-products"]}
        component={WinningProducts}
      />
      <Route
        path={["/marketplace-products-analytics", "/fr/marketplace-products-analytics"]}
        component={AnalyticProducts}
      />
    </Switch>
  ) : localStorage.getItem("typeAccountCreatosell") === "affiliate" ? (
    <Switch>
      <Route exact path={generateRoutes("/affiliation-links")} component={AffiliationLink} />
      <Route path={generateRoutes("/payment-request")} component={AffiliationPaymentRequest} />
      <Route path={generateRoutes("/payment-history")} component={AffiliationPaymentHistory} />
      <Route path={generateRoutes("/affiliation-statistics")} component={AffiliationStatistics} />

      <Route render={() => <Redirect to="/affiliation-links" />} />
    </Switch>
  ) : (
    <Router>
      <ScrollToTop />

      <Switch>
        {/* NO CONNECTED ACCESS PAGE */}
        <Route path={generateRoutes("/connect")} component={ApiConnect} />

        <Route
          path={generateRoutes("/marketplace")}
          render={(props) => <MarketPlace {...props} key="marketplace" />}
        />
        <Route
          path={generateRoutes("/suggestion/marketplace")}
          render={(props) => <MarketPlace {...props} key="suggestion" />}
        />

        <Route path={generateRoutes("/marketplace-home")} component={MarketplaceHome} />
        <Route path={generateRoutes("/marketplace-suggestion")} component={ProductsSuggestions} />
        <Route path={generateRoutes("/extension")} component={ExtensionPromotion} />
        <Route path={generateRoutes("/marketplace-high-margin")} component={ProductsHighMargin} />
        <Route path={generateRoutes("/marketplace-winning-products")} component={WinningProducts} />
        <Route path={generateRoutes("/marketplace-products-analytics")} component={AnalyticProducts} />
        <Route path={generateRoutes("/shop")} component={SupplierShop} />
        <Route path={generateRoutes("/cart")} component={Cart} />

        <Route path={generateRoutes("/join-us")} component={JoinUs} />
        <Route path={generateRoutes("/product-details")} component={ProductDetail} />
        <Route path={generateRoutes("/supplier-more")} component={SupplierMore} />
        <Route path={generateRoutes("/reseller-more")} component={ResellerMore} />
        <Route path={generateRoutes("/boost")} component={BoostPresentation} />

        <Route path={generateRoutes("/e-commerce-starter-coaching")} component={ResellerCoaching1} />
        <Route path={generateRoutes("/e-commerce-advanced-coaching")} component={ResellerCoaching2} />
        <Route path={generateRoutes("/e-commerce-pro-coaching")} component={ResellerCoaching3} />
        <Route path={generateRoutes("/ecommerce-coaching-payment")} component={ResellerCoachingPayment} />
        <Route path={generateRoutes("/ecommerce-coaching-presentation")} component={EcommerceCoaching} />

        {/* RESELLER DOCUMENTATION */}

        <Route path={generateRoutes("/what-is-01supply")} component={WhatIs01supply} />
        <Route path={generateRoutes("/marketplace-documentation")} component={MarketplaceResellerDoc} />
        <Route
          path={generateRoutes("/your-account-reseller-documentation")}
          component={YourAccountResellerDoc}
        />
        <Route path={generateRoutes("/how-to-place-an-order")} component={HowPlaceOrder} />
        <Route path={generateRoutes("/how-to-track-an-order")} component={HowTrackOrder} />
        <Route path={generateRoutes("/shopify-setup")} component={ShopifySetup} />
        <Route path={generateRoutes("/supplier-location-documentation")} component={SupplierLocation} />
        <Route
          path={generateRoutes("/supplier-shipping-methods-documentation")}
          component={SupplierShippingMethods}
        />
        <Route
          path={generateRoutes("/supplier-product-quantities-documentation")}
          component={SupplierQuantities}
        />
        <Route path={generateRoutes("/what-is-dropshipping")} component={WhatIsDropshipping} />
        <Route path={generateRoutes("/dropshipping-supplier")} component={DropshippingSupplierDoc} />

        {/* SUPPLIER DOCUMENTATION */}

        <Route
          path={generateRoutes("/what-is-01supply-for-supplier")}
          component={WhatIs01supplyForSupplier}
        />
        <Route
          path={generateRoutes("/marketplace-documentation-for-supplier")}
          component={MarketplaceDocumentationForSupplier}
        />
        <Route
          path={generateRoutes("/your-supplier-account-documentation")}
          component={YourSupplierAccountDocumentation}
        />
        <Route
          path={generateRoutes("/shipping-methods-documentation")}
          component={ShippingMethodsDocumentation}
        />
        <Route
          path={generateRoutes("/getting-paid-documentation")}
          component={GettingPaidSupplierDocumentation}
        />
        <Route
          path={generateRoutes("/how-sell-on-01supply-supplier-documentation")}
          component={HowSellSupplierDocumentation}
        />
        <Route
          path={generateRoutes("/how-create-product-supplier-documentation")}
          component={HowCreateProductSupplierDocumentation}
        />
        <Route
          path={generateRoutes("/how-import-csv-products-supplier-documentation")}
          component={HowImportCsvSupplierDocumentation}
        />
        <Route
          path={generateRoutes("/transactions-fees-supplier-documentation")}
          component={TransactionsFeesSupplierDocumentation}
        />
        <Route
          path={generateRoutes("/fulfill-my-order-supplier-documentation")}
          component={FulfillOrderSupplierDocumentation}
        />
        <Route
          path={generateRoutes("/dropshipping-reseller")}
          component={DropshippingResellerDocumentation}
        />

        {/* SUPPLIER/RESELLER PAGE */}
        <Route path={generateRoutes("/inventory")} component={Inventory} />
        <Route path={generateRoutes("/messages")} component={Messages} />
        <Route path={generateRoutes("/your-informations")} component={YourInformations} />
        <Route path={generateRoutes("/my-orders")} component={Orders} />
        <Route path={generateRoutes("/mobile-setting-menu")} component={MobileSettingMenu} />
        <Route path={generateRoutes("/disconnect")} component={RedirectLogin} />

        <Route path={generateRoutes("/pricing")} component={PricingReseller} />

        <Route path={generateRoutes("/affiliation/payouts")} component={Affiliation} />
        <Route path={generateRoutes("/affiliation/referrals")} component={Affiliation} />

        <Route path={generateRoutes("/academy/dashboard")} component={AcademyDashboard} />

        {/* SUPPLIER PAGE */}
        <Route path={generateRoutes("/your-shop-supplier")} component={YourShopSupplier} />
        <Route path={generateRoutes("/supplier-service")} component={SupplierService} />
        <Route path={generateRoutes("/create-product")} component={CreateEditProduct} />
        <Route path={generateRoutes("/edit-product")} component={CreateEditProduct} />
        <Route path={generateRoutes("/plans-supplier")} component={PlanSupplier} />
        <Route path={generateRoutes("/import-products-csv")} component={ImportProductsCsv} />
        <Route path={generateRoutes("/supplier-documentation")} component={SupplierDoc} />
        <Route path={generateRoutes("/setup-supplier")} component={SupplierSetup} />
        <Route path={generateRoutes("/my-payments")} component={MyPayments} />
        <Route path={generateRoutes("/manage-shipping-methods")} component={ManageShippingMethods} />
        <Route path={generateRoutes("/fulfill-order")} component={FulfillOrder} />
        <Route path={generateRoutes("/payment-methods")} component={PaymentMethods} />
        <Route path={generateRoutes("/boost-your-business")} component={SupplierGrowthBusiness} />
        <Route path={generateRoutes("/my-resellers")} component={MyResellers} />

        <Route path={generateRoutes("/supplier-onboarding-step-1")} component={Onboarding1} />
        <Route path={generateRoutes("/supplier-onboarding-step-2")} component={Onboarding2} />
        <Route path={generateRoutes("/supplier-onboarding-step-3")} component={SupplierOnboarding3} />
        <Route path={generateRoutes("/supplier-onboarding-step-4")} component={SupplierOnboarding4} />
        <Route path={generateRoutes("/supplier-onboarding-step-5")} component={Onboarding5} />

        {/* RESELLER PAGE */}
        <Route path={generateRoutes("/sourcing-products")} component={Sourcing} />
        <Route path={generateRoutes("/your-shop-reseller")} component={YourShopReseller} />
        <Route path={generateRoutes("/import-list")} component={ImportList} />
        <Route path={generateRoutes("/reseller-documentation")} component={ResellerDoc} />
        <Route path={generateRoutes("/setup-reseller")} component={ResellerSetup} />
        <Route path={generateRoutes("/reseller-tools")} component={ResellerTools} />
        <Route path={generateRoutes("/settings/pricing-rules")} component={ResellerPricingRules} />

        <Route path={generateRoutes("/suppliers")} component={SuppliersList} />

        <Route path={generateRoutes("/ecommerce-coaching")} component={CoachingDashboard} />

        <Route path={generateRoutes("/welcome-on-01supply")} component={ResellerWelcomeForm} />

        <Route path={generateRoutes("/reseller-onboarding-step-1")} component={Onboarding1} />
        <Route path={generateRoutes("/reseller-onboarding-step-2")} component={Onboarding2} />
        <Route path={generateRoutes("/reseller-onboarding-step-3")} component={Onboarding5} />

        {/* SUPPLIER COURSES */}
        <Route path={generateRoutes("/academy/photo-mastery-course")} component={SupplierCourse1} />
        <Route path="/academy/seo-product-description-course" component={SupplierCourse2} />
        <Route path="/academy/google-seo-course" component={SupplierCourse3} />

        {/* COURSES PAYMENT */}
        <Route path={generateRoutes("/course-payment")} component={CoursePayment} />

        {/* BLOG */}
        <Route path={generateRoutes("/blog")} component={Blog} />
        <Route path={generateRoutes("/become-supplier")} component={BecomeSupplier1} />
        <Route path={generateRoutes("/how-work-01supply")} component={BecomeSupplier2} />
        <Route path={generateRoutes("/how-setup-01supply-account")} component={BecomeSupplier3} />

        <Route
          path={generateRoutes("/download-ebook-how-to-get-more-sales-on-etsy")}
          component={DownloadEbook}
        />
        <Route path={generateRoutes("/how-to-get-more-sales-on-etsy")} component={EbookArticle} />
        <Route
          path={generateRoutes("/how-to-import-etsy-products-to-shopify")}
          component={ImportEtsyProductShopify}
        />
        <Route path={generateRoutes("/dropshipping-viking-suppliers")} component={VikingSuppliers} />
        <Route
          path={generateRoutes("/reseller/dropshipping-supplier-email")}
          component={BlogEmailTemplateSupplier}
        />
        <Route
          path={generateRoutes("/reseller/find-european-supplier-dropshipping")}
          component={BlogFindDropshipSupplier}
        />
        <Route
          path={generateRoutes("/reseller/jewelry-supplier-dropshipping")}
          component={BlogJewelrySupplier}
        />
        <Route
          path={generateRoutes("/supplier/sell-handmade-items-besides-etsy")}
          component={BlogSellBesideEtsy}
        />

        {/* ARTICLES*/}
        <Route path={generateRoutes("/best-size-product-images")} component={Article13} />
        <Route path={generateRoutes("/product-photography-cost")} component={Article14} />
        <Route path={generateRoutes("/clothing-product-photography")} component={Article15} />
        <Route path={generateRoutes("/product-video-home")} component={Article16} />

        <Route path="/lighting-product-photography" component={Article17} />
        <Route path="/360-product-photography" component={Article18} />
        <Route path="/optimize-seo-product-description" component={Article19} />
        <Route path="/meta-title-meta-description-examples" component={Article20} />
        <Route path="/pourquoi-faire-seo" component={Article21} />
        <Route path="/outils-seo" component={Article22} />
        <Route path="/dropshipping-coaching" component={Article23} />
        <Route path="/e-commerce-coaching" component={Article24} />
        <Route path="/importation-etsy-shopify" component={Article25} />
        <Route path="/dropshipping-produits-etsy" component={Article26} />
        <Route path="/fournisseur-francais-dropshipping" component={Article27} />
        <Route path="/fournisseur-europe-dropshipping" component={Article28} />
        <Route path="/fournisseurs-dropshipping-vetements" component={Article29} />
        <Route path="/ou-trouver-fournisseur-dropshipping" component={Article30} />
        <Route path="/comment-trouver-fournisseur-dropshipping" component={Article31} />
        <Route path="/creer-boutique-dropizi" component={Article32} />
        <Route path="/fournisseurs-france-liste" component={Article33} />

        <Route path={generateRoutes("/documentation/supplier")} component={DocumentationSupplier} />
        <Route path={generateRoutes("/documentation/reseller")} component={DocumentationReseller} />

        <Route path={generateRoutes("/what-is-eco-label")} component={WhatEcoLabel} />
        <Route path={generateRoutes("/what-is-handmade-label")} component={WhatHandmadeLabel} />
        <Route path={generateRoutes("/handmade-certification")} component={SupplierCertifHandmade} />
        <Route path={generateRoutes("/eco-friendly-certification")} component={SupplierCertifEco} />

        <Route path={generateRoutes("/supplier-pricing")} component={PricingSupplier} />

        <Route path={generateRoutes("/documentation")} component={Documentation} />

        {/* AFFILIATION */}
        <Route path={generateRoutes("/affiliation-links")} component={AffiliationLink} />

        <Route
          exact
          path="/"
          component={() => {
            window.location.href = "https://01supply.com";
            return null;
          }}
        />

        <Route path="*">
          <Redirect to={"/join-us"} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
