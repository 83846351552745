import React, { useState } from "react";
import { message, Input } from "antd";
import { header } from "../../api/header";
import { url } from "../../api/url";
import shopifyIllustration from "../../images/shopify_illustration.png";
import { useTranslation } from "react-i18next";

import editIcon from "../../images/icons/edit.png";
import GoogleLogin from "../../components/joinUs/GoogleLogin";
import { installShopifyEvent } from "../../utils/analyticEvents";

const ConnectShopify = ({
  windowSize,
  emailShopifyAccount,
  email,
  shopName,
  setLoad,
  password,
  setRedirectSetup,
  setEmail,
  setPassword,
  setShopifyState,
}) => {
  const [changeDefaultEmail, setChangeDefaultEmail] = useState(false);
  const { t } = useTranslation();

  const defaultEmail = localStorage.getItem("emailAccountCreatosell");

  const location = window.location.href;
  let tokenShopify = location.split("token=")[1].split("&shop")[0];
  let shop = location
    .split("shop=")[1]
    .replace("?account", "")
    .replace("&persoapp=true", "")
    .replace("&new-account=true", "");
  const shopify = {
    token: tokenShopify,
    shop: shop,
  };

  const pushShopify = async () => {
    const secretId = localStorage.getItem("secretAccountId01supply");
    const userId = localStorage.getItem("idAccountCreatosell");

    let affiliationId = localStorage.getItem("affiliationId");

    let emailAccount = email;

    const res = await fetch(`${url}/user/create-shopify`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
      },
      body: JSON.stringify({
        shopify: shopify,
        userId,
        secretId,
        persoApp: location.includes("&persoapp=true") ? true : false,
        newEmail: emailAccount,
        password,
        affiliationId,
      }),
    });
    const resConnectJson = await res.json();
    setLoad(false);
    if ((await res.status) === 200 && (await resConnectJson)) {
      localStorage.setItem("tokenCreatosell", resConnectJson.token);
      localStorage.setItem("typeAccountCreatosell", resConnectJson.type);
      localStorage.setItem("emailAccountCreatosell", resConnectJson.email);
      localStorage.setItem("idAccountCreatosell", resConnectJson.id);
      localStorage.setItem("secretAccountId01supply", resConnectJson.secretId);
      localStorage.setItem("viewPlan01supply", "false");

      installShopifyEvent({user: {
        _id: resConnectJson.id,
        email: resConnectJson.email
      }})

      message.success("Welcome to 01supply !");

      setTimeout(() => {
        setRedirectSetup(true);
      }, 200);
    } else if (res.status === 405) {
      message.error(t("messages.accountAlreadyExist"));
    } else if (res.status === 401) {
      setChangeDefaultEmail(true);
      message.error(t("messages.accountAlreadyExistWithThisShopifyShop"));
    } else {
      message.error(
        resConnectJson.message || "An error has occurred, please try to reopen the app or contact our support"
      );
    }
  };

  return (
    <div
      style={
        windowSize > 1150
          ? { alignItems: "center", paddingBottom: "100px" }
          : { width: "100%", padding: "0", paddingBottom: "100px" }
      }
      className="full-screen"
    >
      <div style={windowSize > 1150 ? { marginTop: "50px" } : { width: "100%" }} className="row">
        <div
          style={
            windowSize > 1150
              ? { marginRight: "50px", alignItems: "center", width: "80%" }
              : { alignItems: "center", width: "100%", padding: "5%" }
          }
          className="column"
        >
          <img
            src={shopifyIllustration}
            style={
              windowSize > 1150
                ? { width: "500px", height: "auto" }
                : { width: "80vw", maxWidth: "500px", height: "auto" }
            }
            alt="shopify illustration"
          />
          <p className="title-green-join-us">{shopName}</p>
          {defaultEmail && !changeDefaultEmail ? (
            <div onClick={() => setChangeDefaultEmail(true)} className="row pointer">
              <p style={{ fontSize: "16px" }}>{defaultEmail}</p>
              <img alt="edit email" src={editIcon} style={{ height: "30px", marginLeft: "10px" }} />
            </div>
          ) : (
            <Input
              key={emailShopifyAccount}
              defaultValue={defaultEmail || emailShopifyAccount}
              maxLength={150}
              type="email"
              onPressEnter={async () => {
                const emailReg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
                if (!emailReg.test(email)) message.error(t("messages.wrong.email"));
                else {
                  setLoad(true);
                  pushShopify();
                }
              }}
              placeholder={t("yourEmailAddress")}
              style={windowSize > 1150 ? { width: "400px" } : { width: "300px" }}
              onChange={(e) => setEmail(e.target.value)}
              className="input"
            />
          )}
          <Input.Password
            maxLength={150}
            type="password"
            onPressEnter={async () => {
              const emailReg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
              if (!emailReg.test(email)) message.error(t("messages.wrong.email"));
              else {
                setLoad(true);
                pushShopify();
              }
            }}
            placeholder={t("joinUsPage.yourPassword")}
            style={
              windowSize > 1150
                ? { width: "400px", marginBottom: "20px", marginTop: "15px" }
                : { marginBottom: "15px", marginTop: "15px", width: "300px" }
            }
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          <button
            style={{ marginTop: "20px", width: windowSize > 1150 ? "400px" : "300px" }}
            onClick={async () => {
              const emailReg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
              if (!emailReg.test(email)) message.error(t("messages.wrong.email"));
              else {
                setLoad(true);
                pushShopify();
              }
            }}
            className="button orange-back"
          >
            {t("joinUsPage.connectShopify")}
          </button>
          <GoogleLogin type="seller" redirectTo="/setup/reseller" shopify={shopify} />
        </div>
      </div>
    </div>
  );
};

export default ConnectShopify;
