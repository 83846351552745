import React, { useState, useEffect } from "react";
import { Select, message } from "antd";
import { useTranslation } from "react-i18next";

import { productServices } from "../../services/products";
import Load from "../../utils/Load";
import arrowImg from "../../images/icons/arrow.svg";
import "./style.scss";

const { Option } = Select;

const VariantMapping = ({
  shopifyVariants,
  shopifyProductId,
  variants,
  productId,
  variantsMapping,
  setVariantsMapping,
  findShopifyProduct,
  isUpdateLink,
  setIsSaveButtonDisplayed,
  isSaveButtonDisplayed,
  getProducts,
  getUser,
  defaultShopifyProductId,
  default01supplyProductId,
}) => {
  const [load, setLoad] = useState(false);
  const [availableVariants, setAvailableVariants] = useState(variants);
  const [isAdditional01supplyProductVariants, setIsAdditional01supplyProductVariants] = useState(false);
  const { t } = useTranslation();

  const handleSetAvailableVariants = ({ variantsMapping }) => {
    setAvailableVariants(
      variants.filter(
        (variant) => !variantsMapping.some((variantMapping) => variantMapping.sku.includes(variant._id))
      )
    );
  };

  useEffect(() => {
    // set default variantsMapping
    console.log("shopifyVariants", shopifyVariants, shopifyVariants.length)
    if (shopifyVariants?.length) {
      const setMapping =
        shopifyVariants?.map((shopifyVariant) => ({
          shopifyVariantId: shopifyVariant.id,
          sku: shopifyVariant.sku || "",
        })) || [];

      setVariantsMapping(setMapping);

      const additional01supplyVariants = !isUpdateLink
        ? false
        : variants.length !== shopifyVariants.length && shopifyVariants.length < 95;

      setIsAdditional01supplyProductVariants(additional01supplyVariants);

      handleSetAvailableVariants({ variantsMapping: setMapping });
    }
  }, [shopifyVariants]);

  useEffect(() => {
    if (
      defaultShopifyProductId?.toString() !== shopifyProductId?.toString() ||
      default01supplyProductId?.toString() !== productId?.toString()
    ) {
      setIsAdditional01supplyProductVariants(false);
    }
  }, [productId, shopifyProductId]);

  const findLocalVariantMatching = ({ shopifyVariant }) => {
    if (shopifyVariant && variants) {
      return variants.find((variant) => {
        const shopifyVariantSku = `${productId}-${variant._id}`;
        const firstShopifyVariantSku = `${variant._id}`;

        return shopifyVariant.sku === shopifyVariantSku || shopifyVariant.sku === firstShopifyVariantSku;
      });
    }
  };

  const handleUpdate = ({ shopifyVariant, shopifyVariantIndex, variantId }) => {
    if (!variantId) {
      // clear selection
      const newVariantsMapping = variantsMapping;
      newVariantsMapping[shopifyVariantIndex] = {
        shopifyVariantId: shopifyVariant.id,
        sku: "",
      };
      setVariantsMapping(newVariantsMapping);
      handleSetAvailableVariants({ variantsMapping: newVariantsMapping });
    } else {
      setIsSaveButtonDisplayed(true);
      const variantIndex = variants.findIndex((variant) => variant._id === variantId);
      const newVariantsMapping = variantsMapping;
      newVariantsMapping[shopifyVariantIndex] = {
        shopifyVariantId: shopifyVariant.id,
        sku: `${productId}-${variants[variantIndex]._id}`,
      };
      setVariantsMapping(newVariantsMapping);
      handleSetAvailableVariants({ variantsMapping: newVariantsMapping });
    }
  };

  const handlePushAdditional01supplyVariants = () => {
    setLoad(true);
    productServices.pushShopifyVariants({ shopifyProductId, productId }).then(async (res) => {
      if (res.status === 200) {
        await productServices.checkMonitoring({ productId });
        await getProducts();
        await getUser();
        findShopifyProduct().then(() => setLoad(false));
      } else {
        message.error(t("messages.error"));
        setLoad(false);
      }
    });
  };

  return (
    <div className="column width-100">
      {load && <Load />}
      <div style={{ marginTop: "10px" }} className="row row-wrap width-100 space-between">
        <div className="part">
          <p style={{ fontSize: "16px" }} className="text grey title-variant-mapping">
            {t("01supplyVariant")}
            {isAdditional01supplyProductVariants && !isSaveButtonDisplayed && (
              <div style={{ height: "140px", marginTop: "15px" }} className="column">
                <p className="text" style={{ fontSize: "13px" }}>
                  {t("01supplyProductHaveMoreVariant")}
                </p>
                <button
                  onClick={handlePushAdditional01supplyVariants}
                  style={{ marginTop: "10px", height: "35px" }}
                  className="button orange-back width-100"
                >
                  {t("syncVariants")}
                </button>
              </div>
            )}
          </p>
        </div>
        <div className="part">
          <p style={{ fontSize: "16px" }} className="text grey title-variant-mapping">
            {t("shopifyvariant")}
            {isAdditional01supplyProductVariants && (
              <div style={{ height: "120px", marginTop: "15px" }} className="column"></div>
            )}
          </p>
        </div>
      </div>
      {shopifyVariants &&
        shopifyVariants?.map((shopifyVariant, shopifyVariantIndex) => (
          <div style={{ width: "100%" }} className="column">
            <div className="mapping-variant row width-100 space-between">
              <div className="part">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  style={{ width: "100%" }}
                  onChange={(variantId) => {
                    handleUpdate({ shopifyVariant, shopifyVariantIndex, variantId });
                  }}
                  defaultValue={findLocalVariantMatching({ shopifyVariant })?._id || t("chooseVariant")}
                  className={!findLocalVariantMatching({ shopifyVariant })?._id && "border-red"}
                >
                  {availableVariants?.map((variant) => {
                    const variantName = `${variant.option1} ${variant.option2 ? "/" + variant.option2 : ""} ${
                      variant.option3 ? "/" + variant.option3 : ""
                    }`;
                    return (
                      <Option value={variant._id} key={variant._id}>
                        {variantName}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <img alt="arrow" className="arrow" src={arrowImg} />
              <div className="part">
                <div className="shopify-variant-card">
                  <p className="row">
                    <b>{shopifyVariant.option1}</b> {shopifyVariant.option2 && <p className="separator">/</p>}{" "}
                    {shopifyVariant.option2 && <b>{shopifyVariant.option2}</b>}{" "}
                    {shopifyVariant.option3 && <p className="separator">/</p>}{" "}
                    {shopifyVariant.option3 && <b>{shopifyVariant.option3}</b>}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default VariantMapping;
