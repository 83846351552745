import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Button, Input } from "antd";

import {Modal} from '../../wrappers'

import { updateUser } from "../../services/updateUser";

const PaypalEmailModal = ({
  user,
  setPaypalEmailAccountModal,
  url,
  idAccount,
  secretId,
  token,
  header,
  setUser,
}) => {
  const [email, setEmail] = useState(user.paypal_email_account || "");

  const { t } = useTranslation();

  const sendEmail = async () => {
    const emailReg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    if (!emailReg.test(email) && email) message.error(t("messages.pleaseCorrectEmail"));
    else {
      const updateCallback = () => {
        message.success(t("messages.successfullyUpdated"));
        setPaypalEmailAccountModal(false);
        setUser({ ...user, paypal_email_account: email });
      };
      updateUser({user:{ paypal_email_account: email }}, idAccount, secretId, token, updateCallback);
    }
  };

  return (
    <Modal
      className="modal-account invitation"
      onOk={() => {}}
      visible={true}
      onCancel={() => setPaypalEmailAccountModal(false)}
      footer={[
        <Button key="back" onClick={() => setPaypalEmailAccountModal(false)}>
          {t("cancel")}
        </Button>,
      ]}
    >
      <div style={{ alignItems: "center" }} className="column">
        <p className="title">{t("components.modal.14")}</p>
        <Input
          defaultValue={user.paypal_email_account}
          style={{ marginTop: "20px", marginBottom: "30px" }}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('paypalEmailAccount')}
        />
        <button onClick={sendEmail} className="button orange-back">
          {t("components.modal.15")}
        </button>
      </div>
    </Modal>
  );
};

export default PaypalEmailModal;
