import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, message, InputNumber } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment";

import { Modal } from "../../wrappers";
import { url } from "../../api/url";
import { header } from "../../api/header";
import TabPayments from "../../components/supplier/payments/Tab";
import { urlParams } from "../../utils/urlParams";

import CoachingForm from "../../components/coachingForm/CoachingForm";

const UserPayments = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [isCoachingModalOpen, setIsCoachingModalOpen] = useState(false);
  const [paypalPayments, setPaypalPayments] = useState({});
  const [paypalEmail, setPaypalEmail] = useState("");
  const [iban, setIban] = useState("");
  const [paymentSelection, setPaymentSelection] = useState([]);
  const [totalAmountSelect, setTotalAmountSelect] = useState(0);
  const [totalFeesSelect, setTotalFeesSelect] = useState(0);
  const [ratioCurrency, setRatioCurrency] = useState(1.14);
  const [totalPaypalFeesSelect, setTotalPaypalFeesSelect] = useState(0);
  const [user, setUser] = useState({});
  const [newPayment, setNewPayment] = useState({
    date: "",
    method: "",
    details: "",
    label: "",
    amount: "",
    fees_01supply: "",
    fees_paypal: "",
  });
  const [paypalFees, setPaypalFees] = useState([]);

  const { t } = useTranslation();

  const paramsUrl = urlParams();

  const userId = paramsUrl?.userId || "";
  const userSecretId = paramsUrl?.userSecretId || "";

  useEffect(() => {
    findPayments();
    findUserInfos();
    findUser();
  }, []);

  const findUserInfos = async () => {
    const resIban = await fetch(`${url}/user/find-iban/${userId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (resIban.status === 200) {
      const resJson = await resIban.json();
      setIban(resJson.iban);
    }
    const resPaypalEmail = await fetch(`${url}/user/find-paypal-email-account/${userId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (resPaypalEmail.status === 200) {
      const resJson = await resPaypalEmail.json();
      setPaypalEmail(resJson.paypalEmailAccount);
    }
  };

  const findPayments = async () => {
    if (userId) {
      const res = await fetch(`${url}/paypal/paypal-payments/${userId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        setPaypalPayments({
          ...resJson.paypalPayments,
          paypal_invoice: resJson.paypalPayments.paypal_invoice.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          ),
        });
      }
    }
  };

  const findUser = async () => {
    if (userId) {
      const res = await fetch(`${url}/user/find/${userId}/${userSecretId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        setUser(resJson.data);
      }
    }
  };

  const sendPayment = async () => {
    const newTotalDue = (parseFloat(paypalPayments.total_due) - parseFloat(newPayment.amount)).toFixed(2);
    const newFeesDue = (parseFloat(paypalPayments.fees_due) - parseFloat(newPayment.fees_01supply)).toFixed(
      2
    );
    const newNetDue = newTotalDue - newFeesDue;
    const res = await fetch(`${url}/admin/send-payment/${idAccount}/${paypalPayments._id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        newPayment: {
          ...newPayment,
          fees_01supply: "$" + newPayment.fees_01supply,
          fees_paypal: "$" + newPayment.fees_paypal,
          amount: "$" + newPayment.amount,
        },
        total_due: newTotalDue,
        fees_due: newFeesDue,
        net_due: newNetDue,
      }),
    });
    if (res.status === 200) {
      let newPaypalPayments = { ...paypalPayments };
      newPaypalPayments.total_due = newTotalDue;
      newPaypalPayments.fees_due = newFeesDue;
      newPaypalPayments.net_due = newNetDue;
      if (newPaypalPayments.payment_history) newPaypalPayments.payment_history.push(newPayment);
      else newPaypalPayments.payment_history = [newPayment];

      setPaypalPayments({ ...newPaypalPayments });
      setNewPayment({
        date: "",
        method: "",
        details: "",
        label: "",
        amount: "",
        fees_01supply: "",
        fees_paypal: "",
      });
      message.success(t("messages.paymentSend"));
    }
  };

  useEffect(() => {
    calcPaymentSelection();
  }, [paymentSelection]);

  const calcPaymentSelection = () => {
    if (paypalPayments.paypal_invoice) {
      let totalAmount = 0;
      let totalFees = 0;
      for (let i in paypalPayments.paypal_invoice) {
        if (paymentSelection.includes(parseInt(i))) {
          totalAmount += parseFloat(paypalPayments.paypal_invoice[parseInt(i)].amount);
          totalFees += parseFloat(paypalPayments.paypal_invoice[parseInt(i)].fees_01supply);
        }
      }
      setTotalAmountSelect(totalAmount.toFixed(2));
      setTotalFeesSelect(totalFees.toFixed(2));
    }
  };

  const updatePaymentStatus = async ({ paid }) => {
    const newInvoices = paypalPayments.paypal_invoice.map((invoice, index) =>
      paymentSelection.includes(parseInt(index)) ? { ...invoice, paid: paid } : invoice
    );
    const newPaypalPayments = { ...paypalPayments, paypal_invoice: [...newInvoices] };
    const res = await fetch(`${url}/admin/paypal-payment-due/${idAccount}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        paypal_payment_due: newPaypalPayments,
      }),
    });
    if (res.status === 200) {
      message.success(t("messages.paymentStatusChange"));
      setPaypalPayments(newPaypalPayments);
    }
  };

  const changePaypalPayment = (index, value) => {
    const calcNewTotalPaypalFees = (newFees) => {
      setPaypalFees(newFees);
      let totalValue = 0;
      newFees.forEach((fees) => (totalValue += fees.value));
      setTotalPaypalFeesSelect(totalValue.toFixed(2));
    };
    const currentFees = paypalFees.find((fees) => fees.index === index);
    if (currentFees) {
      const newPaypalFess = paypalFees.map((fees) =>
        fees.index === index ? { ...fees, value: parseFloat(value) } : fees
      );
      calcNewTotalPaypalFees(newPaypalFess);
    } else {
      const newPaypalFess = [...paypalFees, { index, value: parseFloat(value) }];
      calcNewTotalPaypalFees(newPaypalFess);
    }
  };

  const handleCheckPayment = (index) => {
    const stockSelection = [...paymentSelection];
    if (stockSelection.includes(index)) {
      stockSelection.splice(index, 1);
    } else stockSelection.push(index);
    setPaymentSelection([...stockSelection]);
  };

  return (
    <div style={{ padding: "50px", paddingLeft: "5vw", paddingRight: "5vw" }} className="page">
      <Modal onCancel={() => setIsCoachingModalOpen(false)} visible={isCoachingModalOpen}>
        <CoachingForm
          coaching={user?.coaching}
          setIsModalDispalyed={setIsCoachingModalOpen}
          idAccount={userId}
          adminId={idAccount}
        />
      </Modal>
      <Link style={{ marginBottom: "30px" }} className="button orange-back" to={`/manage-thematics?${userId}`}>
        Manage Thematics
      </Link>
      <button
        style={{ marginBottom: "30px" }}
        className="button orabge-back"
        onClick={() => setIsCoachingModalOpen(true)}
      >
        Manage Coaching
      </button>
      <div style={{ marginBottom: "30px", fontSize: "16px" }} className="red-container">
        Awaiting payment :
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          ${paypalPayments && paypalPayments.net_due ? parseFloat(paypalPayments.net_due).toFixed(2) : "0"}
        </span>
        {user.coaching?.remaining_fees_due && (
          <>
            Remaining coaching fees due :
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>${user.coaching?.remaining_fees_due}</span>
          </>
        )}
        Total due :
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          $
          {paypalPayments && paypalPayments.total_due ? parseFloat(paypalPayments.total_due).toFixed(2) : "0"}
        </span>
        Fees due :
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          ${paypalPayments && paypalPayments.fees_due ? parseFloat(paypalPayments.fees_due).toFixed(2) : "0"}
        </span>
      </div>

      <p className="title-300">UserId : {userId}</p>
      {paypalEmail && <p className="title-300">Paypal email : {paypalEmail}</p>}
      {iban && <p className="title-300">IBAN : {iban}</p>}

      <div className="row-wrap" style={{ width: "100%", marginBottom: "30px", marginTop: "15px" }}>
        <Input
          value={newPayment.date}
          style={{ margin: "5px" }}
          placeholder="Date"
          onChange={(e) => {
            setNewPayment({ ...newPayment, date: e.target.value });
          }}
        />
        <Input
          value={newPayment.method}
          style={{ margin: "5px" }}
          placeholder="Method"
          onChange={(e) => {
            setNewPayment({ ...newPayment, method: e.target.value });
          }}
        />
        <Input
          value={newPayment.details}
          style={{ margin: "5px" }}
          placeholder="Details"
          onChange={(e) => {
            setNewPayment({ ...newPayment, details: e.target.value });
          }}
        />
        <Input
          value={newPayment.label}
          style={{ margin: "5px" }}
          placeholder="Label"
          onChange={(e) => {
            setNewPayment({ ...newPayment, label: e.target.value });
          }}
        />
        <Input
          value={newPayment.amount}
          style={{ margin: "5px" }}
          placeholder="Amount"
          prefix={"$"}
          onChange={(e) => {
            setNewPayment({ ...newPayment, amount: e.target.value });
          }}
        />
        <Input
          value={newPayment.fees_01supply}
          style={{ margin: "5px" }}
          placeholder="01supply fees"
          prefix={"$"}
          onChange={(e) => {
            setNewPayment({ ...newPayment, fees_01supply: e.target.value });
          }}
        />
        <Input
          value={newPayment.fees_paypal}
          style={{ margin: "5px" }}
          placeholder="Paypal fees"
          prefix={"$"}
          onChange={(e) => {
            setNewPayment({ ...newPayment, fees_paypal: e.target.value });
          }}
        />
        <button style={{ marginLeft: "30px" }} className="button orange-back" onClick={sendPayment}>
          Send payment
        </button>
      </div>

      <TabPayments paypalPayments={paypalPayments} />
      <p className="title" style={{ marginTop: "30px", marginBottom: "10px" }}>
        Paypal invoice history
      </p>

      <div style={{ marginBottom: "40px" }} className="card">
        <p style={{ marginBottom: "7px" }}>
          Total amount select : {totalAmountSelect}$ soit {(totalAmountSelect / ratioCurrency).toFixed(2)}€
        </p>
        <p style={{ marginBottom: "7px" }}>
          Total 01supply fees : {totalFeesSelect}$ soit {(totalFeesSelect / ratioCurrency).toFixed(2)}€
        </p>
        <p style={{ marginBottom: "7px" }}>
          Total paypal/stripe fees : {totalPaypalFeesSelect}$ soit{" "}
          {(totalPaypalFeesSelect / ratioCurrency).toFixed(2)}€
        </p>
        <p style={{ marginBottom: "15px" }}>
          Total à payer :{" "}
          {(
            parseFloat(totalAmountSelect) -
            (parseFloat(totalFeesSelect) + parseFloat(totalPaypalFeesSelect))
          ).toFixed(2)}
          $ soit{" "}
          {(
            parseFloat(totalAmountSelect) -
            (parseFloat(totalFeesSelect) + parseFloat(totalPaypalFeesSelect)) / parseFloat(ratioCurrency)
          ).toFixed(2)}
          €
        </p>
        <div className="row">
          <p>Conversion $/€</p>
          <InputNumber
            min={0}
            style={{ width: "80px", marginLeft: "15px", height: "30px" }}
            value={ratioCurrency}
            onChange={(value) => setRatioCurrency(value ? parseFloat(value) : 0)}
          />
        </div>
      </div>

      {paymentSelection.length > 0 && (
        <div className="row-wrap">
          <button
            onClick={() => updatePaymentStatus({ paid: true })}
            style={{ marginRight: "20px" }}
            className="button-blue"
          >
            Move to paid
          </button>
          <button onClick={() => updatePaymentStatus({ paid: false })} className="button-red">
            Move to NOT paid
          </button>
        </div>
      )}

      <div className="row-wrap">
        {paypalPayments &&
          paypalPayments.paypal_invoice &&
          paypalPayments.paypal_invoice.map((invoice, index) => {
            return (
              <div
                style={{
                  position: "relative",
                  paddingTop: "50px",
                  background: `${invoice.paid ? "rgb(201 237 190)" : "rgb(255 192 192)"}`,
                }}
                className="card"
              >
                <Input
                  suffix="€"
                  style={{ position: "absolute", top: "7px", left: "50px", width: "130px", height: "30px" }}
                  onChange={(e) => changePaypalPayment(index, parseFloat(e.target.value))}
                  placeholder="paypal fees"
                />
                <Checkbox
                  checked={paymentSelection && paymentSelection.includes(index)}
                  className="checkbox-on-product"
                  onChange={() => handleCheckPayment(index)}
                />
                {invoice.paid && (
                  <p
                    className="title-bold white"
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                  >
                    PAID
                  </p>
                )}
                <p className="text ellipsis">
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "7px" }}>Date : </span>{" "}
                  {moment(invoice.date).format("D/MM/YYYY hh:mm:ss SSS")}
                </p>
                <p className="text ellipsis">
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "7px" }}>Order id : </span>{" "}
                  {invoice.order_id}
                </p>
                <p className="text ellipsis">
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "7px" }}>Amount : </span> $
                  {parseFloat(invoice.amount).toFixed(2)}
                </p>
                <p className="text ellipsis">
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "7px" }}>01supply fees: </span> $
                  {invoice.fees_01supply}
                </p>
                <p className="text ellipsis">
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "7px" }}>Paypal Invoice ID: </span>{" "}
                  {invoice.paypal_invoice_id}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserPayments;
