import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { message } from "antd";
import { url } from "../../api/url";
import { header } from "../../api/header";

const ModaLStripeInformations = ({ stripeAccount, user, setBannerUpdateStripe }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [load, setLoad] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation()

  const stripeConfiguration = async () => {
    const resConnect = await fetch(`${url}/stripe/onboard-user`, {
      method: "POST",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        userId: user._id,
        user,
        stripe: user.stripe ? user.stripe : "none",
      }),
    });
    if (resConnect.status === 200) {
      const resJson = await resConnect.json();
      if (await resJson) {
        let win = window.open(resJson.url, "_blank");
        if (win) win.focus();
        else window.location.href = resJson.url;

        setLoad(false);
      }
    } else message.error(t('messages.error'));
  };

  const checkRequirements = async () => {
    if (!stripeAccount || (stripeAccount && !stripeAccount.payouts_enabled)) stripeConfiguration();
    else {
      message.success(t('messages.stripeConfigured'));
      setBannerUpdateStripe(false);
    }
  };

  return localStorage.getItem("disabledStripeBanner") !== "true" && !disabled ? (
    <div className="banner-bottom">
      <div className="row">
        <p style={{ fontSize: "16px" }} className="text-bold marine">
          {t('components.modal.19')}
        </p>
        <button
          onClick={async () => {
            await checkRequirements();
          }}
          className="button-banner"
        >
          {t('components.modal.20')}
        </button>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ModaLStripeInformations;
