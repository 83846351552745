import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { url } from "../api/url";
import { header } from "../api/header";
import { getImage } from "../utils/GetImage";
import { Link } from "react-router-dom";
import { Checkbox, message } from "antd";

import { productServices } from "../services/products";
import { priceConverter } from "../utils/priceConverter";
import { LocalStatesContext } from "../providers/index";
import MonitoringCard from "./inventory/MonitoringCard";
import ProductLinkCMS from "./productLinkCMS";
import CostEvolution from "./inventory/CostEvolution";

import linkImg from "../images/icons/blue_link.svg";
import deleteImg from "../images/icons/red_delete.svg";
import StockEvolution from "./inventory/StockEvolution";

const ProductCard = ({
  productP,
  setDeleteProduct,
  setDeleteProductSeller,
  keyP,
  typeAccount,
  index,
  currency,
  user,
  setProductSelection,
  productSelection,
  windowSize,
  page,
  getProducts,
  getUser,
}) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [image, setImage] = useState("");
  const [realPrice, setRealPrice] = useState("");
  const [initalPrice, setInitialPrice] = useState("");
  const [published, setPublished] = useState(productP.is_publish);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [isProductLinkDisplayed, setIsProductLinkDisplayed] = useState(false);
  const [isCostEvolutionDisplayed, setIsCostEvolutionDisplayed] = useState(false);
  const [isStockEvolutionDisplayed, setIsStockEvolutionDisplayed] = useState(false)
  const [shopifyProductId, setShopifyProductId] = useState("");
  const [product, setProduct] = useState(productP);

  const { localStates } = useContext(LocalStatesContext);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (product && product.photos && product.photos.length > 0) getImageF(product.photos[0]);
  }, []);

  useEffect(() => {
    getImageF(productP.photos[0]);
    setProduct({ ...productP });
  }, [productP]);

  useEffect(() => {
    if (typeAccount === "seller" && user && user.shopify_product) {
      user.shopify_product.map((prod) => {
        if (prod.local_id === productP._id) {
          setShopifyProductId(prod.shopify_id);
          setTimeout(() => {
           // getShopifyPrice(prod.shopify_id);
          }, index * 600);
        }
      });
    }
  }, [user]);

  const isBlockedProduct = () => {
    if (typeAccount === "creater") {
      return false;
    }

    let userLimit = 9;
    if (user.plan?.type === "basic") {
      userLimit = 25;
    }
    if (user.plan?.type === "advanced") {
      userLimit = 250;
    }
    if (user.plan?.type === "premium") {
      userLimit = 1000000;
    }
    const currentPage = (page || 1) - 1;
    return index + parseInt(currentPage * 30) >= userLimit;
  };

  const getShopifyPrice = async (productId) => {
    const res = await productServices.findShopifyProduct({ productId });
    if (res.status === 200) {
      const resJson = await res.json();
      const variants = [...resJson.data.variants];
      let minPrice = 0;
      let maxPrice = 0;
      let finish = false;
      variants.map((variant, index) => {
        if (index === 0) minPrice = parseFloat(variant.price);
        else if (parseFloat(maxPrice) === 0 && parseFloat(variant.price) > minPrice)
          maxPrice = parseFloat(variant.price);
        else if (parseFloat(variant.price) < minPrice) minPrice = parseFloat(variant.price);
        else if (parseFloat(variant.price) > maxPrice) maxPrice = parseFloat(variant.price);
        if (index === variants.length - 1) finish = true;
      });
      if (finish) {
        let resultPrice = `${minPrice}`;
        if (maxPrice != minPrice && maxPrice !== 0) {
          resultPrice = `${minPrice} - ${maxPrice}`;
        }
        let newObject = { ...product };
        newObject.name = resJson.data.title;
        newObject.shopify_price = resultPrice;
        setProduct({ ...newObject });
      }
    }
  };

  useEffect(() => {
    calcRealPrice();
  }, [product]);

  const getImageF = async (id) => {
    const res = await getImage(id);
    if (res) {
      setImage(res);
    }
  };

  const calcRealPrice = async () => {
    let minPrice = 0;
    let maxPrice = 0;
    if (product.variants && product.variants.length > 0 && product.variantBool) {
      product.variants.map((variant, index) => {
        if (index === 0) minPrice = variant.price;
        else if (maxPrice === 0 && parseFloat(variant.price) > minPrice) maxPrice = variant.price;
        else if (parseFloat(variant.price) < minPrice) {
          maxPrice = minPrice;
          minPrice = parseFloat(variant.price);
        } else if (parseFloat(variant.price) > maxPrice) maxPrice = variant.price;
        if (index === product.variants.length - 1) {
          let resultPrice = `${parseFloat(minPrice).toFixed(2)}`;
          if (maxPrice != minPrice && maxPrice !== 0)
            resultPrice = `${parseFloat(minPrice).toFixed(2)} - $${parseFloat(maxPrice).toFixed(2)}`;
          if (!resultPrice) resultPrice = product.price;
          setRealPrice(resultPrice);
        }
      });
    } else {
      if (typeAccount === "seller" && product.creater?.id) {
        const res = await fetch(
          `${url}/custom-price/find-product-price/${product.creater.id}/${user._id}/${product._id}/no-variant`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              ...header,
              authorization: token,
            },
          }
        );
        if (res.status === 200) {
          const resJson = await res.json();
          setRealPrice(resJson.customPrice);
          setInitialPrice(product.price);
        } else {
          setRealPrice(product.price);
        }
      } else setRealPrice(product.price);
    }
  };

  const updatePublishProduct = async () => {
    const resConnect = await fetch(`${url}/product/update/${product._id}/${idAccount}/${secretId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        isPublish: !published,
        changePublish: true,
      }),
    });
    if (resConnect.status === 200) {
      message.success(
        t("messages.productPublish", { status: published ? t("unpublished") : t("published") })
      );
      setPublished(!published);
    } else {
      message.error(t("messages.error"));
    }
  };

  return (
    <div
      style={{ width: windowSize > 1150 && "90%" }}
      className={windowSize > 1150 ? "row" : "column width-100"}
    >
      {isProductLinkDisplayed && (
        <ProductLinkCMS
          setIsDisplayed={setIsProductLinkDisplayed}
          productProps={product}
          defaultShopifyProductId={shopifyProductId}
          user={user}
          getProducts={getProducts}
          getUser={getUser}
        />
      )}
      {isCostEvolutionDisplayed && (
        <CostEvolution
          setIsDisplayed={setIsCostEvolutionDisplayed}
          product={product}
          getProducts={getProducts}
          getUser={getUser}
        />
      )}
      {isStockEvolutionDisplayed && (
        <StockEvolution
          setIsDisplayed={setIsStockEvolutionDisplayed}
          product={product}
        />
      )}
      <div
        key={keyP + productP._id}
        style={{
          width: `${windowSize > 1150 ? "100%" : "100%"}`,
          flexDirection: `${windowSize > 1150 ? "row" : "column"}`,
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
        className={typeAccount === "seller" ? "card reseller row-inventory" : "card row-inventory"}
      >
        {isBlockedProduct() && (
          <div className="blocked-product center">
            {" "}
            <p style={{ maxWidth: "70%" }} className="white">
              {t("disableProduct.1")}{" "}
            </p>{" "}
            <p style={{ marginTop: "20px", maxWidth: "70%" }} className="white">
              {t("disableProduct.2")}{" "}
              <Link style={{ color: "white" }} to="/pricing" className="underline text montserrat">
                {t("disableProduct.3")}
              </Link>{" "}
              {t("disableProduct.4")}{" "}
            </p>
          </div>
        )}
        <div
          key={productP._id}
          className="row"
          style={{ width: "60%", alignItems: "flex-start", marginRight: "5%" }}
        >
          <Link
            onClick={() => {
              sessionStorage.setItem("lastNaviguationInventory", "true");
            }}
            className="container-img-inventory-product"
            target={product.type === "etsy" && "_blank"}
            rel="noopener noreferrer"
            to={`/product-details?name=${product.name}&id=${product._id}`}
            style={{ marginBottom: "0", width: "150px", minWidth: "150px" }}
          >
            <Checkbox
              key={productSelection && productSelection.length}
              checked={productSelection && productSelection.includes(product._id)}
              className="checkbox-on-product"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onChange={() => {
                let stockSelection = [...productSelection];
                if (productSelection.includes(product._id)) {
                  productSelection.map((selection, index) => {
                    if (selection === product._id) {
                      stockSelection.splice(index, 1);
                    }
                  });
                } else stockSelection.push(product._id);
                setProductSelection([...stockSelection]);
              }}
            />

            <img key={product._id} src={image} className="image-product" />
          </Link>
          <div className="column second" style={{ height: "100%", minWidth: "30%", marginLeft: "5%" }}>
            <Link
              to={`/product-details?name=${product.name}&id=${product._id}`}
              style={{ maxWidth: "100%" }}
              className="title text-ellipsis"
            >
              {product.name}
            </Link>
            {realPrice && (
              <p className="row" style={{ alignItems: "flex-end" }}>
                <p style={{ marginRight: "5px", fontSize: "16px" }} className="title-200">
                  {typeAccount === "creater" ? t("price") + ": " : t("cost") + ": "}
                </p>
                {initalPrice && (
                  <p
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      fontSize: "16px",
                    }}
                    className="title"
                  >
                    <span className="dashed" />
                    {priceConverter({ price: initalPrice, currency: localStates.currency })}
                  </p>
                )}
                <p style={{ fontSize: "16px" }} className="title">
                  {priceConverter({ price: realPrice, currency: localStates.currency })}
                </p>
              </p>
            )}
            {typeAccount === "seller" && product.shopify_price && (
              <p className="row-wrap start" style={{ alignItems: "flex-end", marginTop: "5px" }}>
                <p style={{ marginRight: "5px", fontSize: "14px" }} className="title-200">
                  {t("components.inventoryProductCard.1")}{" "}
                </p>
                <p style={{ fontSize: "14px" }} className="title">
                  {currency !== "EUR" && "$"}
                  {product.shopify_price}
                  {currency === "EUR" && "€"}
                </p>
              </p>
            )}
            <p
              className="title-200"
              style={
                typeAccount === "seller"
                  ? { fontSize: "12px", marginTop: "5%" }
                  : { fontSize: "12px", marginTop: "40px" }
              }
            >
              {t("components.inventoryProductCard.2")} {product.stock}
            </p>
          </div>
        </div>
        {typeAccount === "seller" ? (
          <div
            style={{
              width: "30%",
              alignItems: "flex-end",
              height: `${windowSize > 1150 ? "150px" : ""}`,
              marginTop: `${windowSize > 1150 ? "" : "10px"}`,
              justifyContent: "flex-end",
            }}
            className="column"
          >
            <p
              style={{ marginBottom: "10px" }}
              onClick={() => setDeleteProductSeller(product._id)}
              className="text red underline pointer end"
            >
              {t("delete")} <img src={deleteImg} alt="delete" style={{ height: "20px", marginLeft: "5px" }} />
            </p>
            {user.shopify?.shop && user.shopify?.token && shopifyProductId && (
              <p onClick={() => setIsProductLinkDisplayed(true)} className="text blue underline pointer">
                {t("linking")}{" "}
                <img src={linkImg} alt="linking" style={{ height: "20px", marginLeft: "5px" }} />
              </p>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "30%",
              alignItems: "flex-end",
              height: `${windowSize > 1150 ? "150px" : ""}`,
              marginTop: `${windowSize > 1150 ? "" : "10px"}`,
              justifyContent: "flex-end",
            }}
            className="column"
          >
            <Link
              to={`/edit-product?id=${product._id}`}
              style={{ width: "150px", marginBottom: "-10px" }}
              className="button orange-back"
            >
              {t("edit").toUpperCase()}
            </Link>
            <p
              onClick={() => setDeleteProduct(product._id)}
              style={
                windowSize > 1150 ? { position: "absolute", top: "0px", right: "0" } : { marginTop: "20px" }
              }
              className="text red underline pointer end"
            >
              {t("delete")}
            </p>
            <Checkbox
              style={{ marginTop: "25px" }}
              checked={published}
              onChange={(e) => updatePublishProduct()}
            >
              <p style={{ fontSize: "18px" }} className="title-300">
                {t("components.inventoryProductCard.3")}
              </p>
            </Checkbox>
          </div>
        )}
      </div>
      {user?.shopify?.token && (
        <MonitoringCard
          setIsCostEvolutionDisplayed={setIsCostEvolutionDisplayed}
          setIsProductLinkDisplayed={setIsProductLinkDisplayed}
          setIsStockEvolutionDisplayed={setIsStockEvolutionDisplayed}
          product={product}
        />
      )}
    </div>
  );
};

export default ProductCard;
