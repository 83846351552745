import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
import { Input } from "antd";
import { SearchOutlined, RollbackOutlined } from "@ant-design/icons";
import VideoModal from "../modals/VideoModal";

const SimpleHeader = ({
  setNoResult,
  pageStep,
  setSearch,
  location,
  setDatas,
  allDatas,
  attribut,
  setEditProduct,
}) => {
  const [searchWord, setSearchWord] = useState("");

  const { t } = useTranslation()

  const sortProductsPagination = (products) => {
    const page = pageStep || 1;
    let skip = parseInt(page) * parseInt(20) - 20;
    let limit = parseInt(20) + skip;

    return products.filter((product, index) => {
      return index >= skip && index <= limit;
    });
  };

  const filterF = ({ noWord }) => {
    const word = noWord ? "" : searchWord;

    if (word) {
      if (setSearch) setSearch(true);

      let results = allDatas.filter(function (data) {
        let res = false;
        if (data[attribut] && data[attribut].toLowerCase().indexOf(word.toLowerCase()) >= 0) res = true;
        return res;
      });
      if (!results.length) {
        if (setNoResult) setNoResult(true);
      }
      setDatas(sortProductsPagination(results));
    } else if (!word) {
      if (setSearch) setSearch(false);
      setDatas(sortProductsPagination(allDatas));
    }
  };

  return (
    <div className="header">
      <div className="row" style={{ width: "100%" }}>
        {(location === "edit-product" || location === "create-product") && (
          <Link
            to="/inventory"
            onClick={() => {
              if (location === "edit-product") setEditProduct(false);
            }}
          >
            <RollbackOutlined style={{ fontSize: "40px", marginRight: "30px" }} />
          </Link>
        )}
        <h1 style={{ marginBottom: "0px" }} className="title-bold montserrat">
          {location === "orders"
            ? t("components.header.6")
            : location === "inventory" || location === "inventory creater"
            ? t("components.header.7")
            : location === "import list"
            ? t("components.header.8")
            : location === "pricing"
            ? t("components.header.9")
            : location === "create-product"
            ? t("components.header.10")
            : location === "edit-product"
            ? t("components.header.11")
            : location === "import-csv"
            ? t("components.header.12")
            : location === "MY PAYMENTS"
            ? t("components.header.13")
            : location}
        </h1>

        {location === "SOURCING" && (
          <VideoModal
            text={t("components.header.14")}
            url={"https://www.youtube.com/embed/DNmF8eeroBo"}
            style={{ marginLeft: "20px" }}
          />
        )}
        {(location === "inventory" || location === "inventory creater") && allDatas?.length > 0 && (
          <Input
            onPressEnter={filterF}
            maxLength={80}
            className="simple-input input-header"
            placeholder={location === "orders" ? t("components.header.15") : t("components.header.16")}
            prefix={<SearchOutlined onClick={filterF} style={{ color: "rgb(129, 129, 129)" }} />}
            onChange={(e) => {
              setSearchWord(e.target.value);
              if (!e.target.value) {
                filterF({ noWord: true });
              }
            }}
          />
        )}
      </div>
      <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
    </div>
  );
};

export default SimpleHeader;
