import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, message } from "antd";

import { Modal } from "../../../wrappers";
import { updateUser } from "../../../services/updateUser";

import facebookIcon from "../../../images/icons/facebook.png";
import instagramIcon from "../../../images/icons/instagram.png";

const SocialMediaCustom = ({ user, setUser }) => {
  const [editUrl, setEditUrl] = useState("");
  const [newUrl, setNewUrl] = useState("");

  const { t } = useTranslation();

  return (
    <div className="row">
      <div style={{ cursor: "pointer" }} onClick={() => setEditUrl("instagram")} className="row">
        <img style={{ height: "25px", width: "auto", marginRight: "10px" }} src={instagramIcon} />
        <p className="text-gradient">
          {user && user.about && user.about.instagram ? t("edit") : "+ " + t("add")}
        </p>
      </div>

      <div
        style={{ marginLeft: "20px", cursor: "pointer" }}
        onClick={() => setEditUrl("facebook")}
        className="row"
      >
        <img style={{ height: "25px", width: "auto", marginRight: "10px" }} src={facebookIcon} />
        <p className="text-gradient">
          {user && user.about && user.about.facebook ? t("edit") : "+ " + t("add")}
        </p>
      </div>

      <Modal
        key={editUrl}
        className="modal-order background-landing"
        title={`${t("components.supplier.13")} ${editUrl} url`}
        visible={editUrl}
        onCancel={() => {
          setEditUrl("");
          setNewUrl("");
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setEditUrl("");
              setNewUrl("");
            }}
          >
            {t("cancel")}
          </Button>,
        ]}
      >
        <div className="column" style={{ width: "100%", alignItems: "center" }}>
          <Input
            key={editUrl}
            defaultValue={user && user.about && user.about[editUrl]}
            placeholder={`${editUrl} url`}
            onChange={(e) => {
              setNewUrl(e.target.value);
            }}
          />
          <button
            style={{ marginTop: "20px", width: "120px" }}
            onClick={() => {
              const send = () => {
                setUser({ ...user, about: { ...user.about, [editUrl]: newUrl } });
                updateUser({ user: { ...user, about: { ...user.about, [editUrl]: newUrl } } });
                setEditUrl("");
              };

              if (editUrl === "instagram") {
                let regex =
                  /(?:https?:\/\/)?(?:www\.)?(?:instagram|fb|m\.instagram)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i;
                let regexUrl =
                  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
                if (newUrl.match(regex) && newUrl.match(regexUrl)) {
                  send();
                } else message.error(t("messages.instagramUrlIncorrect"));
              } else {
                let regex =
                  /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/i;
                let regexUrl =
                  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
                if (newUrl.match(regex) && newUrl.match(regexUrl)) {
                  send();
                } else message.error(t("messages.facebookUrlIncorrect"));
              }
            }}
            className="button orange-back"
          >
            {t("save")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SocialMediaCustom;
