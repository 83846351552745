const shippingPrice = ({ shipping, quantity }) => {
  const shippingPrice = shipping.price;
  const additionalProductPrice = shipping.additionalProductPrice || shipping.price;
  return (parseFloat(shippingPrice) + parseFloat(additionalProductPrice) * (parseInt(quantity) - 1)).toFixed(
    2
  );
};

export const priceCalc = {
  sellerProductOrder: ({ shipping, quantity, product }) => {
    if (shipping?.price && quantity && product?.price) {
      const totalShippingPrice = shippingPrice({ shipping, quantity });
      const productsPrice = (parseFloat(product.price) * parseFloat(quantity)).toFixed(2);
      const totalPrice = (
        parseFloat(productsPrice) +
        parseFloat(totalShippingPrice)
      ).toFixed(2);

      const productsAndShippingPrice = (parseFloat(totalShippingPrice) + parseFloat(productsPrice)).toFixed(
        2
      );

      return {
        shipping: totalShippingPrice,
        products: productsPrice,
        total: totalPrice,
        productsAndShippingPrice,
      };
    } else {
      return 0;
    }
  },
};
