import React, { useEffect, useState } from "react";
import { getImage } from "../../utils/GetImage";
import { Link } from "react-router-dom";
import { url } from "../../api/url";
import { header } from "../../api/header";
import RedirectLogin from "../../utils/RedirectLogin";
import { message, DatePicker, Spin, Input, Popover } from "antd";
import { LoadingOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import Load from "../../utils/Load";
import moment from "moment";
import iconCheck from "../../images/icons/Check.svg";
import { usToEurDate } from "../../utils/usToEurDate";
import ModalUnverifiedSupplierAccount from "../../components/modals/UnverifiedSupplierAccountModal";
import ConfirmPayment from "../../components/fullfill/ConfirmPayment";
import { t } from "i18next";

const FulfillOrder = () => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [user, setUser] = useState({});
  const [order, setOrder] = useState({});
  const [product, setProduct] = useState({});
  const [image, setImage] = useState("");
  const [variantSelect, setVariantSelect] = useState({});
  const [load, setLoad] = useState(false);
  const [sellerCompany, setSellerCompany] = useState({});
  const [modalUnverifiedSupplierAccount, setModalUnverifiedSupplierAccount] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [fulfill, setFulfill] = useState({
    carrier: "",
    tracking_number: "",
    date_sent: "",
  });

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    let orderId = currentUrl.searchParams.get("order");
    findOrder(orderId);
    if (window.tidioChatApi) window?.tidioChatApi?.show();

    if (idAccount && typeAccount && secretId) {
      getUser();
    }
  }, []);

  const findSellerCompany = (sellerId) => {
    fetch(`${url}/user/company/${sellerId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    })
      .then((res) => res.json())
      .then(async (resJson) => {
        setSellerCompany(resJson.data);
      });
  };

  const findProduct = async (productId) => {
    fetch(`${url}/product/find-to-seller-order/${productId}/${idAccount}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    })
      .then((res) => res.json())
      .then(async (resJson) => {
        setProduct(resJson.data || {});
        if (resJson.data) {
        }
      });
  };

  const findOrder = async (orderId) => {
    try {
      const res = await fetch(`${url}/order/${orderId}/${idAccount}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson?.order) {
          setOrder(resJson.order);
          findSellerCompany(resJson.order?.seller?.id);

          setOrderStatus(
            resJson?.order?.status === "close"
              ? t("components.orderCard.orderStatus.1")
              : resJson?.order?.status === "payment confirmation"
              ? t("components.orderCard.orderStatus.9")
              : resJson?.order?.status === "to pay"
              ? t("components.orderCard.orderStatus.2")
              : resJson?.order?.status === "paid"
              ? t("components.orderCard.orderStatus.3")
              : t("components.orderCard.orderStatus.4")
          );

          setProduct(resJson.order.product);
          const productImage = await getImage(resJson.order.product?.photos?.[0]);
          if (productImage) setImage(productImage);
        }
      }
    } catch {}
  };

  useEffect(() => {
    if (product?._id) {
      if (
        (!(variantSelect && variantSelect.name) && product?.name && !product?.variantBool) ||
        product.type === "etsy"
      )
        setVariantSelect({ price: product.price, stock: product.stock });
      else if (!(variantSelect && variantSelect.name) && product.variants?.length && order.variant_id) {
        setVariantSelect(product.variants.find((variant) => variant._id === order.variant_id));
      }
    }
  }, [product]);

  const sendOrder = async () => {
    // CHECK IF SUPPLIER ACCOUNT IS COMPLETED
    if (!user.user_verified) {
      setModalUnverifiedSupplierAccount(true);
    } else {
      setLoad(true);
      if (!fulfill.carrier) {
        message.error(t("messages.needFill.carrier"));
        setLoad(false);
      } else if (!fulfill.tracking_number) {
        message.error(t("messages.needFill.trackingNumber"));
        setLoad(false);
      } else if (!fulfill.date_sent) {
        message.error(t("messages.needFill.sentDate"));
        setLoad(false);
      } else {
        const resConnect = await fetch(`${url}/order/sending/${order._id}/${idAccount}/${secretId}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            ...header,
            authorization: token,
          },
          body: JSON.stringify({
            sending: fulfill,
          }),
        });
        if (resConnect.status === 200) {
          setFulfill({
            carrier: "",
            tracking_number: "",
            date_sent: "",
          });
          setOrder({ ...order, status: "filled" });
          setLoad(false);
          message.success(t("messages.orderFilled"));
        } else {
          setLoad(false);
          message.error(t("messages.error"));
        }
      }
    }
  };

  return (
    <div className="page">
      {load && <Load />}
      <RedirectLogin />
      <ModalUnverifiedSupplierAccount
        user={user}
        modalOn={modalUnverifiedSupplierAccount}
        setModalOn={setModalUnverifiedSupplierAccount}
      />
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"MY PAYMENTS"} /> : <MobileMenu load={false} />}
        <div
          style={
            windowSize < 1150
              ? { paddingLeft: "5vw", paddingRight: "5vw", paddingBottom: "100px" }
              : { paddingTop: "120px" }
          }
          className="column-scrolling"
        >
          {windowSize > 1150 ? (
            <div className="header">
              <div
                className="row"
                style={
                  windowSize > 1150
                    ? { width: "100%", marginBottom: "-30px", height: "30px" }
                    : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                }
              >
                <h1 className="title-bold montserrat">{t("fulfillOrder.1")}</h1>
              </div>
              <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
            </div>
          ) : (
            <MobileHeader load={false} location={t("fulfillOrder.1")} nameHeader={t("fulfillOrder.1")} />
          )}
          <div className={windowSize > 1150 ? "row" : "row-wrap"}>
            <div
              className={`initial-cursor ${
                order.status === "close"
                  ? "button-back-red"
                  : order.status === "to pay" || order.status === "payment confirmation"
                  ? "button orange-back-2"
                  : order.status === "paid"
                  ? "button blue-back"
                  : "button grey-back"
              }`}
              style={{
                padding: "4px 8px 4px 8px",
                boxShadow: "inherit",
                width: "200px",
                margin: "0",
                marginLeft: "0",
                position: "relative",
              }}
            >
              {orderStatus}
            </div>
            <div style={{ margin: "15px" }} className="text-300">
              {order.date?.split(" ") &&
                order.date?.split(" ")?.[0] &&
                usToEurDate(order.date?.split(" ")?.[0])}
            </div>
            {product.type === "etsy" ? (
              <a
                href={product.origin_url}
                className="button orange-back"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("contact")} {t("supplier")}
              </a>
            ) : (
              <Link
                className="button orange-back"
                style={{ margin: "15px", marginLeft: "0" }}
                to={
                  typeAccount &&
                  `/messages?contact=${typeAccount === "creater" ? order.seller?.id : order.creater?.id}`
                }
              >
                {t("contact")} {typeAccount === "creater" ? t("reseller") : t("supplier")}
              </Link>
            )}
          </div>
          <div
            className="row-wrap"
            style={
              windowSize > 1150
                ? { width: "100%", alignItems: "flex-start" }
                : { flexDirection: "column", width: "100%", alignItems: "flex-start" }
            }
          >
            <div
              style={windowSize > 1150 ? { marginRight: "20px", width: "70%" } : { width: "100%" }}
              className="column"
            >
              <div
                style={{
                  width: `${windowSize > 1150 ? "100%" : "100%"}`,
                  minWidth: `${windowSize > 1150 ? "765px" : ""}`,
                  marginLeft: `0`,
                  marginTop: "20px",
                  marginRIght: "50px",
                }}
                className="card"
              >
                <p className="title-bold">{orderStatus}</p>
                {product && (
                  <div
                    className={windowSize > 1150 ? "row" : "column"}
                    style={{
                      borderRadius: "6px",
                      padding: "20px",
                      width: "100%",
                      background: "#F6F6F6",
                      alignItems: "flex-start",
                      marginTop: "15px",
                    }}
                  >
                    <Link
                      onClick={() => {
                        sessionStorage.setItem("lastNaviguationInventory", "true");
                      }}
                      className="container-img-inventory-product"
                      style={{
                        minWidth: "120px",
                        width: "120px",
                        minHeight: "120px",
                        height: "120px",
                        borderRadius: "6px",
                        marginBottom: "0",
                        marginLeft: "0",
                      }}
                      target={product.type === "etsy" && "_blank"}
                      to={
                        product.type === "etsy"
                          ? { pathname: product.origin_url }
                          : `/product-details?name=${product.name}&id=${product._id}`
                      }
                    >
                      <img
                        style={{ minHeight: "120px", height: "120px" }}
                        src={image}
                        className="image-product"
                      />
                    </Link>
                    <div
                      style={{
                        maxWidth: "calc(100% - 130px)",
                        width: "calc(100% - 130px)",
                        justifyContent: "flex-start",
                      }}
                      className="column"
                    >
                      <Link
                        to={`/product-details?name=${product.name}&id=${product._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="title-300 text-ellipsis underline"
                        style={
                          windowSize > 1150
                            ? { maxWidth: "60%", marginRight: "5%", fontSize: "17px" }
                            : { fontSize: "17px", marginTop: "15px" }
                        }
                      >
                        {product.name}
                      </Link>
                      {product.optionsVariants && (
                        <div className="row">
                          {variantSelect.option1 && (
                            <p className="text-bold" style={{ color: "#B9B9B9", marginRight: "20px" }}>
                              {product.optionsVariants[0]} :
                              <span style={{ fontWeight: "400" }}>{" " + variantSelect.option1}</span>
                            </p>
                          )}
                          {variantSelect.option3 && (
                            <p className="text-bold" style={{ color: "#B9B9B9", marginRight: "20px" }}>
                              {product.optionsVariants[1]} :
                              <span style={{ fontWeight: "400" }}>{" " + variantSelect.option2}</span>
                            </p>
                          )}
                          {variantSelect.option2 && (
                            <p className="text-bold" style={{ color: "#B9B9B9" }}>
                              {product.optionsVariants[2]} :
                              <span style={{ fontWeight: "400" }}>{" " + variantSelect.option3}</span>
                            </p>
                          )}
                        </div>
                      )}
                      <p
                        style={
                          windowSize > 1150
                            ? { fontSize: "16px", position: "absolute", right: "30px", top: "45px" }
                            : { fontSize: "14px", marginTop: "5px" }
                        }
                      >
                        {order.quantity} x
                        <span style={{ fontWeight: "bold", marginLeft: "5px", color: "rgb(60, 60, 60)" }}>
                          {variantSelect.price ? "$" + variantSelect.price : "select variant"}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                {order.status === "payment confirmation" && typeAccount === "creater" && (
                  <ConfirmPayment setOrder={setOrder} order={order} />
                )}
                {order.status === "paid" && typeAccount === "creater" && (
                  <div
                    style={{ width: "100%", alignItems: "flex-end", marginTop: "30px" }}
                    className="row order-supplier"
                  >
                    <div style={{ marginRight: "30px" }} className="column">
                      <p style={{ marginBottom: "5px", fontSize: "18px" }} className="title-300">
                        {t("fulfillOrder.3")}
                      </p>
                      <Input
                        maxLength={40}
                        style={{ width: "165px" }}
                        placeholder="DHL, FEDEX ..."
                        onChange={(e) => {
                          let stockFulfill = fulfill;
                          stockFulfill.carrier = e.target.value;
                          setFulfill(stockFulfill);
                        }}
                      />
                    </div>
                    <div
                      style={windowSize > 1150 ? { marginRight: "30px" } : { marginTop: "20px" }}
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "18px" }} className="title-300">
                        {t("fulfillOrder.4")}
                      </p>
                      <Input
                        maxLength={50}
                        style={{ width: "150px" }}
                        placeholder="02830438473"
                        onChange={(e) => {
                          let stockFulfill = fulfill;
                          stockFulfill.tracking_number = e.target.value;
                          setFulfill(stockFulfill);
                        }}
                      />
                    </div>
                    <div
                      style={
                        windowSize > 1150
                          ? { marginRight: "30px" }
                          : { marginTop: "20px", marginBottom: "30px" }
                      }
                      className="column"
                    >
                      <p style={{ marginBottom: "5px", fontSize: "18px" }} className="title-300">
                        {t("fulfillOrder.5")}
                      </p>
                      <DatePicker
                        disabledDate={(current) => {
                          return moment().add(-0, "days") <= current;
                        }}
                        style={{ width: "160px" }}
                        format="DD-MM-YYYY"
                        placeholder="22/06/2021"
                        onChange={(date, dateString) => {
                          let stockFulfill = fulfill;
                          stockFulfill.date_sent = dateString;
                          setFulfill(stockFulfill);
                        }}
                      />
                    </div>
                    <button
                      style={windowSize <= 1150 ? { marginBottom: "30px" } : { fontSize: "13px" }}
                      onClick={() => {
                        if (!load) sendOrder();
                      }}
                      className="button orange-back"
                    >
                      {!load ? (
                        t("fulfillOrder.6")
                      ) : (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />} />
                      )}
                    </button>
                  </div>
                )}
                {order.status === "filled" && order?.sending?.tracking_number && (
                  <div style={{ marginTop: "20px", width: "100%" }} className="row-wrap">
                    <p style={{ marginRight: "20px" }} className="text-bold grey">
                      {t("fulfillOrder.7")} {order?.sending?.tracking_number}
                    </p>
                    <p style={{ marginRight: "20px" }} className="text-bold grey">
                      {t("fulfillOrder.8")} {order?.sending?.carrier}
                    </p>
                    <p style={{ marginRight: "20px" }} className="text-bold grey">
                      {t("fulfillOrder.9")} {order?.sending?.date_sent}
                    </p>
                  </div>
                )}
              </div>
              {(order.status === "paid" || order.status === "filled") && product.type !== "etsy" ? (
                <div
                  style={{
                    width: `${windowSize > 1150 ? "100%" : "100%"}`,
                    minWidth: `${windowSize > 1150 ? "765px" : ""}`,
                    marginLeft: `0`,
                    marginTop: "20px",
                  }}
                  className="card"
                >
                  <div style={{ width: "100%" }} className="row">
                    <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
                    <p style={{ fontSize: "16px" }} className="title-bold">
                      {t("paid")}
                    </p>
                  </div>

                  <div
                    style={{ width: "95%", marginTop: "20px", marginBottom: "10px", marginLeft: "2%" }}
                    className="row"
                  >
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis start">
                      {t("subtotal")}
                    </p>
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis center">
                      {order.quantity} {t("item")}(s)
                    </p>
                    <p style={{ width: "30%" }} className="text-ellipsis end">
                      ${parseFloat(order?.paid?.product_paid).toFixed(2)}
                    </p>
                  </div>

                  <div style={{ width: "95%", marginBottom: "10px", marginLeft: "2%" }} className="row">
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis start">
                      {t("shipping")}
                    </p>
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis center">
                      {order?.shipping?.name}
                    </p>
                    <p style={{ width: "30%" }} className="text-ellipsis end">
                      ${parseFloat(order?.paid?.shipping_paid).toFixed(2)}
                    </p>
                  </div>

                  <div style={{ width: "95%", marginBottom: "10px", marginLeft: "2%" }} className="row">
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis start">
                      {t("fees")}
                    </p>
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis center"></p>
                    <p style={{ width: "30%" }} className="text-ellipsis end">
                      {typeAccount === "creater"
                        ? "- $" + parseFloat(order?.paid?.creater_fees).toFixed(2)
                        : "$" + parseFloat(order?.paid?.seller_fees).toFixed(2)}
                    </p>
                  </div>

                  <div style={{ width: "100%", marginLeft: "0px" }} className="divider-horizontal" />

                  {typeAccount === "creater" ? (
                    <div style={{ width: "95%", marginLeft: "2%" }} className="row">
                      <p style={{ width: "45%", marginRight: "5%" }} className="text-ellipsis start">
                        {t("fulfillOrder.10")}
                      </p>
                      <p style={{ width: "50%" }} className="text-bold text-ellipsis end">
                        $
                        {parseFloat(
                          parseFloat(order?.paid?.shipping_paid) +
                            parseFloat(order?.paid?.product_paid) -
                            parseFloat(order?.paid?.creater_fees)
                        ).toFixed(2)}
                      </p>
                    </div>
                  ) : (
                    <div style={{ width: "95%", marginLeft: "2%" }} className="row">
                      <p style={{ width: "45%", marginRight: "5%" }} className="text-ellipsis start">
                        {t("fulfillOrder.11")}
                      </p>
                      <p style={{ width: "50%" }} className="text-bold text-ellipsis end">
                        $
                        {parseFloat(
                          parseFloat(order?.paid?.shipping_paid) +
                            parseFloat(order?.paid?.product_paid) +
                            parseFloat(order?.paid?.seller_fees)
                        ).toFixed(2)}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: `${windowSize > 1150 ? "100%" : "100%"}`,
                    minWidth: `${windowSize > 1150 ? "765px" : ""}`,
                    marginLeft: `0`,
                    marginTop: "20px",
                  }}
                  className="card"
                >
                  <div style={{ width: "100%" }} className="row">
                    <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
                    <p style={{ fontSize: "16px" }} className="title-bold">
                      {t("fulfillOrder.12")}
                    </p>
                  </div>
                  <div
                    style={{ width: "95%", marginTop: "20px", marginBottom: "10px", marginLeft: "2%" }}
                    className="row"
                  >
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis start">
                      {t("fulfillOrder.13")}
                    </p>
                    <p style={{ width: "30%", marginRight: "5%" }} className="text-ellipsis center">
                      {order.quantity} {t("item")}(s)
                    </p>
                    <p style={{ width: "30%" }} className="text-ellipsis end">
                      ${(parseInt(order?.quantity) * parseFloat(order?.product?.price)).toFixed(2)}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div
              style={windowSize > 1150 ? {} : { width: "100%" }}
              className={windowSize < 1350 ? "row-wrap" : "column"}
            >
              <div
                style={
                  windowSize < 1350
                    ? { width: "100%", marginLeft: "0", marginTop: "20px" }
                    : { marginTop: "20px", width: "280px", marginLeft: "0" }
                }
                className="card"
              >
                <p className="title-bold">{t("customer")}</p>
                <p style={{ marginBottom: "7px", marginTop: "8px" }} className="text-bold grey">
                  {t("fulfillOrder.14")}
                </p>
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("name")} :</span>
                  {order.customer && order.customer.name}
                </p>
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("address")}1 :</span>
                  {order.customer && order.customer.address1}
                </p>
                {order.customer && order.customer.address2 && (
                  <p
                    className="title-300"
                    style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                  >
                    <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("fulfillOrder.15")}</span>
                    {order.customer.address2}
                  </p>
                )}
                {order.customer && order.customer.company && (
                  <p
                    className="title-300"
                    style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                  >
                    <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("company")} :</span>
                    {order.customer.company}
                  </p>
                )}
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("fulfillOrder.16")} :</span>
                  {order.customer && `${order.customer.zip}, ${order.customer.city}`}
                </p>
                {order.customer?.province && (
                  <p
                    className="title-300"
                    style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                  >
                    <span style={{ fontWeight: "bold", marginRight: "5px" }}>Province :</span>
                    {order.customer?.province}
                  </p>
                )}
                <p
                  className="title-300"
                  style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("country")} :</span>
                  {order.customer && order.customer.country}
                </p>

                {order.phone && order.customer.phone && (
                  <>
                    <p style={{ marginTop: "10px", marginBottom: "5px" }} className="text-bold">
                      {t("fulfillOrder.17")}
                    </p>
                    <p
                      className="title-300"
                      style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("phoneNumber")} :</span>
                      {order.customer.phone}
                    </p>
                  </>
                )}

                {(sellerCompany.IOSS || sellerCompany.EORI) && (
                  <>
                    <Popover
                      content={
                        <p>
                          {t("fulfillOrder.18")}
                          <br /> {t("fulfillOrder.19")}
                        </p>
                      }
                      title={t("fulfillOrder.20")}
                    >
                      <div
                        style={{ alignItems: "center", marginTop: "15px", marginBottom: "7px" }}
                        className="row"
                      >
                        <p
                          style={{ fontSize: "15px" }}
                          className={typeAccount === "creater" ? "text-bold red" : "text-bold"}
                        >
                          {t("fulfillOrder.20")}
                        </p>
                        {typeAccount === "creater" && (
                          <QuestionCircleTwoTone
                            style={{ fontSize: "20px", cursor: "pointer", marginLeft: "30px" }}
                          />
                        )}
                      </div>
                    </Popover>
                    {sellerCompany.IOSS && (
                      <p
                        className="title-300"
                        style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                      >
                        <span style={{ fontWeight: "bold", marginRight: "5px" }}>IOSS :</span>
                        {sellerCompany.IOSS}
                      </p>
                    )}
                    {sellerCompany.EORI && (
                      <p
                        className="title-300"
                        style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                      >
                        <span style={{ fontWeight: "bold", marginRight: "5px" }}>EORI :</span>
                        {sellerCompany.EORI}
                      </p>
                    )}
                  </>
                )}
              </div>
              {order.message && (
                <div
                  style={
                    windowSize < 1350
                      ? { width: "100%", marginLeft: "0", marginTop: "20px" }
                      : { marginTop: "20px", width: "280px", marginLeft: "0" }
                  }
                  className="card"
                >
                  <p style={{ marginBottom: "10px" }} className="title-bold">
                    Message
                  </p>
                  <p>{order.message}</p>
                </div>
              )}
              {order.seller && (
                <div
                  style={
                    windowSize < 1350
                      ? { width: "100%", marginLeft: "0", marginTop: "20px" }
                      : { marginTop: "20px", width: "280px", marginLeft: "0" }
                  }
                  className="card"
                >
                  <p style={{ marginBottom: "10px" }} className="title-bold">
                    {t("fulfillOrder.21")}
                  </p>
                  {order?.seller?.name && (
                    <p
                      className="title-300"
                      style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("name")} :</span>
                      {order?.seller?.name}
                    </p>
                  )}
                  {order?.seller?.company_name && (
                    <p
                      className="title-300"
                      style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("companyName")} :</span>
                      {order?.seller?.company_name}
                    </p>
                  )}
                  {order?.seller?.company_email && (
                    <p
                      className="title-300"
                      style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "5px" }}>email :</span>
                      {order?.seller?.company_email}
                    </p>
                  )}
                  {order?.seller?.company_phone && (
                    <p
                      className="title-300"
                      style={{ fontSize: "13px", textAlign: "start", marginBottom: "4px" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "5px" }}>{t("phoneNumber")} :</span>
                      {order?.seller?.company_phone}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FulfillOrder;
