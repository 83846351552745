import React, { useState, useEffect } from "react";
import { Skeleton, Input } from "antd";
import { useTranslation } from "react-i18next";

import { productServices } from "../../../services/products";
import { userServices } from "../../../services/user";
import { updateUser } from "../../../services/updateUser";

import ResellerSetupProgress from "../../reseller/SetupProgress";

import ProductCard from "./ProductCard";
import SearchProducts from "./SearchProducts";

import Header from "./Header";

import "../style.scss";

const RecommandedProducts = ({ user, setUser, windowSize, isHiddenHeader }) => {
  const [products, setProducts] = useState([]);
  const [searchKeys, setSearchKeys] = useState("");
  const [productsFindCount, setProductsFindCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [displayedProductsIndex, setDisplayedProductsIndex] = useState([0, 1, 2]);
  const [inputSearch, setInputSearch] = useState("");
  const [lastInputSearch, setLastInputSearch] = useState("");
  const [selectedSearchTerm, setSelectedSearchTerm] = useState(undefined);

  const getUser = async () => {
    const user = await userServices.me();
    const newUser = await user.json();
    if (newUser) {
      return newUser.data;
    }
  };

  const suggestWord = ({ inputFind, user, searchTerm }) => {
    let currentSelectedTerm = searchTerm || {};
    if (selectedSearchTerm?.searchTerm && !currentSelectedTerm?.searchTerm) {
      // récupérer le dernier terme de recherche si ce n'est pas un nouveau terme qui a été envoyé en paramètre
      currentSelectedTerm =
        user.suggestions.searchTerms.find(
          (searchTerm) => searchTerm.searchTerm === selectedSearchTerm.searchTerm
        ) || {};
    }
    if (inputFind || (lastInputSearch && !currentSelectedTerm?.searchTerm)) {
      return inputFind || lastInputSearch;
    }
    if (currentSelectedTerm.searchTerm) {
      return `${currentSelectedTerm.searchTerm}`;
    }
    return "";
  };

  const getProduct = async ({ userParams, changeProductIndex, bannedProduct, inputFind, searchTerm }) => {
    let isUpdatedBanProducts = !bannedProduct ? true : false;
    let newUser = userParams || (await getUser());
    //generateDisplayedProductsIndex({ changeProductIndex });

    if (newUser) {
      if (bannedProduct) {
        const newBanProducts = user.suggestions?.bannedProducts?.length
          ? [...user.suggestions?.bannedProducts, bannedProduct._id]
          : [bannedProduct._id];
        const newSuggestion = { ...user.suggestions, bannedProducts: newBanProducts };
        isUpdatedBanProducts = await updateUser({
          user: { suggestions: newSuggestion },
        });
        newUser.suggestions = newSuggestion;
        // update local user banned products
        setUser({ ...user, suggestions: newSuggestion });
      }
      if (await isUpdatedBanProducts) {
        const searchWords = suggestWord({ inputFind, user: newUser, searchTerm });
        setSearchKeys(searchWords);
        const currentProductsId = products?.length
          ? encodeURIComponent(JSON.stringify([products[0]?._id, products[1]?._id, products[2]?._id]))
          : "";
        const newProductRes = await productServices.find({
          page: 1,
          limit: !changeProductIndex || changeProductIndex === 0 ? 5 : 1,
          userId: localStorage.getItem("idAccountCreatosell") || "",
          bannedIds: currentProductsId,
          isRecommendation: true,
          search: selectedSearchTerm ? searchWords : "",
        });
        if (newProductRes) {
          const resJson = await newProductRes.json();
          setProductsFindCount(productsFindCount + 1);
          setLastInputSearch(inputSearch);
          const resProducts = resJson.data;
          if (changeProductIndex || changeProductIndex === 0) {
            let newProducts = products;
            newProducts[changeProductIndex] = resProducts[0];
            setProducts(newProducts);
            return resProducts[0];
          } else {
            setProducts(resProducts);
          }
          setLoad(false);
        }
      }
    }
  };

  useEffect(() => {
    if ((user._id || !localStorage.getItem("idAccountCreatosell")) && !products.length) {
      setLoad(true);
      getProduct({ userParams: user });
    }
  }, [user]);

  return (
    <div
      style={{
        boxShadow: "none",
        padding: windowSize <= 1150 && "0px",
        margin: windowSize < 1150 && "0px",
        marginBottom: "30px",
      }}
      className="width-100 start product-container-marketplace-home"
    >
      {!isHiddenHeader && <Header />}
      <div
        style={{
          justifyContent: windowSize < 1150 && "center",
          boxShadow: isHiddenHeader && "none",
          padding: isHiddenHeader && "0",
        }}
        className="card width-100"
      >
        <SearchProducts
          getProduct={getProduct}
          setLoad={setLoad}
          inputSearch={inputSearch}
          setInputSearch={setInputSearch}
          user={user}
          setUser={setUser}
          selectedSearchTerm={selectedSearchTerm}
          setSelectedSearchTerm={setSelectedSearchTerm}
          lastInputSearch={lastInputSearch}
          setLastInputSearch={setLastInputSearch}
        />
        <div
          style={{ alignItems: "flex-end", justifyContent: "center" }}
          className="row-wrap width-100 product-container-marketplace-home"
        >
          {!load && products?.length > 0 ? (
            <>
              <ProductCard
                inputSearch={lastInputSearch}
                getProduct={getProduct}
                setUser={setUser}
                user={user}
                index={0}
                defaultProduct={products[0]}
                displayedProductsIndex={displayedProductsIndex}
                setSelectedSearchTerm={setSelectedSearchTerm}
                selectedSearchTerm={selectedSearchTerm}
              />
              <ProductCard
                inputSearch={lastInputSearch}
                getProduct={getProduct}
                setUser={setUser}
                user={user}
                index={1}
                defaultProduct={products[1]}
                displayedProductsIndex={displayedProductsIndex}
                setSelectedSearchTerm={setSelectedSearchTerm}
                selectedSearchTerm={selectedSearchTerm}
              />
              <ProductCard
                inputSearch={lastInputSearch}
                getProduct={getProduct}
                setUser={setUser}
                user={user}
                index={2}
                defaultProduct={products[2]}
                displayedProductsIndex={displayedProductsIndex}
                setSelectedSearchTerm={setSelectedSearchTerm}
                selectedSearchTerm={selectedSearchTerm}
              />
            </>
          ) : (
            <>
              <Skeleton
                style={{ marginTop: "20px" }}
                className="image-product width-100"
                loading={true}
                active
              />
              <Skeleton
                style={{ marginTop: "20px" }}
                className="image-product width-100"
                loading={true}
                active
              />
            </>
          )}
        </div>
        <div className="width-100 row start">
          <ResellerSetupProgress user={user} />
        </div>
      </div>
    </div>
  );
};

export default RecommandedProducts;
