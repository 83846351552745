import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import keyWordIcon from "../../images/icons_white/keyword.png";
import googleIcon from "../../images/icons_white/google.png";
import searchVolumeIcon from "../../images/icons_white/stats.png";
import salesIcon from "../../images/icons_white/sales.png";
import keysWordIllustration from "../../images/illustrations/keysword.png";

const KeysWord = ({ keysWord }) => {
  const { t } = useTranslation();

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num;
    }
  }

  const columns = [
    {
      title: (
        <div className="row">
          <p>{t("keyWord")}</p>
          <img src={keyWordIcon} alt="keyword icon" style={{ height: "23px", marginLeft: "10px" }} />
        </div>
      ),
      dataIndex: "key_word",
      render: (text) => <b>{text}</b>,
    },
    {
      title: (
        <div className="row">
          <p>{t("googleCPC")}</p>
          <img src={googleIcon} alt="keyword icon" style={{ height: "20px", marginLeft: "10px" }} />
        </div>
      ),
      dataIndex: "google_cpc",
      render: (text) => <p>${text}</p>,
    },
    {
      title: (
        <div className="row">
          <p>{t("searchVolume")}</p>
          <img src={searchVolumeIcon} alt="keyword icon" style={{ height: "20px", marginLeft: "10px" }} />
        </div>
      ),
      dataIndex: "search_volume",
      render: (text) => <p>{formatNumber(text)}</p>,
    },
  ];

  return (
    <div style={{ marginTop: "30px" }} className="width-100 row-wrap">
      <div style={{ marginBottom: "20px" }} className="width-100 start">
        <p className="title-bold">{t("marketplaceHome.analytic.modal.4")}</p>
      </div>
      <Table
        scroll={{ y: "250px", x: "900px" }}
        pagination={false}
        style={{ width: "97%", marginTop: "30px", minWidth: "280px" }}
        columns={columns}
        dataSource={keysWord}
      />
    </div>
  );
};

export default KeysWord;
