import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article16/how-to-shoot-product-videos-at-home.jpg";
import budgetSetup from "../../../../images/blog/global/article16/budget-setup.jpg";
import closeup from "../../../../images/blog/global/article16/closeup.jpg";
import smartphoneFilm from "../../../../images/blog/global/article16/film-product-with-smartphone.jpg";
import fullShot from "../../../../images/blog/global/article16/full-shot.jpg";
import lightCorrectly from "../../../../images/blog/global/article16/light-correctly-product-video.jpg";
import mediumShot from "../../../../images/blog/global/article16/medium-shot.jpg";
import planVideo from "../../../../images/blog/global/article16/plan-your-video.jpg";
import premiereLook from "../../../../images/blog/global/article16/premiere-pro-look.jpg";
import homeVideosetup from "../../../../images/blog/global/article16/product-video-setup-at-home.jpg";
import studioSetup from "../../../../images/blog/global/article16/studio-setup.jpg";
import wideShot from "../../../../images/blog/global/article16/wide-shot.jpg";
import shotsTemplate from "../../../../images/blog/global/article16/shot-scenes-template.jpg";
import braceletVideography from "../../../../images/blog/global/article16/bracelet-product-videography.jpg";
import perfumePhotography from "../../../../images/blog/global/article16/perfume.jpg";
import watchPhotography from "../../../../images/blog/global/article16/watch-photography.jpg";



const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat start" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
      How to shoot product videos at home?
      </h1>
      <p className="text-300">01 october 2022 - 10 minutes read</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px" }} alt="How to shoot product videos at home?" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }}>
        You have <b>awesome products to film</b> and showcase in commercials but you don’t know how to do it on a budget?
      </p>
      <p style={{ marginBottom: "20px" }}>
        In this article, we will discuss how to film a commercial <b>at home</b>.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#equipment-video-filming" title="What equipment do you need to shoot a video?" />
            <Anchor.Link href="#correct-lighting" title="How to light your scene correctly?" >
            <Anchor.Link href="#translucent-material" title="Translucent material (bottle, perfume,...)"/>
            <Anchor.Link href="#matte-material" title="Matte material (bracelet, statue,...)"/>
            <Anchor.Link href="#shiny-material" title="Shiny material (watch, cooking tool,...)"/>
            </Anchor.Link>
            <Anchor.Link href="#steps-create-video-home" title="6 steps to create a product video at home">
            <Anchor.Link href="#define-target-audience" title="Define the target audience for this video"/>
            <Anchor.Link href="#image-result" title="Imagine the desired final result"/>
            <Anchor.Link href="#write-shot-list" title="Create the video shot list (download template)"/>
            <Anchor.Link href="#prepare-decors" title="Prepare the decors and the setup"/>
            <Anchor.Link href="#start-filming" title="Start filming all your scenes"/>
            <Anchor.Link href="#editing" title="It’s time for editing!"/>
            </Anchor.Link>
            <Anchor.Link href="#tips-look-professional" title="Tips to make your video look more professional" />
            <Anchor.Link href="#product-video-ideas" title="Product video ideas" />
            <Anchor.Link href="#product-video-smartphone" title="How do I shoot professional videos on my smartphone (iPhone or Android)?" />
          </Anchor>
        }
      />
      <h2 id="equipment-video-filming">What equipment do you need to shoot a video?</h2>
      <p style={{ marginBottom: "20px" }}>
        Equipment really varies if you are filming on a budget or if you can afford the expensive gear.
        Depending on that, you will get different results and different qualities.
      </p>
      <p style={{ marginBottom: "20px" }}>Note that <b>equipment is not the only secret to making a good product video</b>. Skills and knowledge are also really important.
      </p>
      <h3>On a budget setup
      </h3>
      <img src={budgetSetup} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Budget video setup" />
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Smartphone ($300)
        </li>
        <li>
          Tripod ($20)
        </li>
        <li>
          Decoration, if you have no interesting decoration at home, you can still rent an Airbnb for the day ($150/day)
        </li>
        <li>
          Sunlight (free)
        </li>
        <li>
          RGB Led tube ($50)
        </li>
        <li>
          Black perspex ($20)
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}><b>Total: $520</b></p>
      <h3>Home video studio setup
      </h3>
      <img src={studioSetup} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Studio home video setup" />
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Camera: Sony A7R4, Canon 5D mark4, Nikon D850 ($2.500)
        </li>
        <li>
          Normal lenses (50mm/85mm) ($500)
        </li>
        <li>
          Macro lenses ($400)
        </li>
        <li>
          Electronic lazy susan ($100)
        </li>
        <li>
          Soft lights ($80)
        </li>
        <li>
          RGB led tube ($50)
        </li>
        <li>
          Black, white, or blue perspex ($20)
        </li>
        <li>
          Backdrop ($20)
        </li>
        <li>
          Decoration ($150/day if you rent an Airbnb)
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}><b>Total: $3.820</b></p>
      <h2 id="correct-lighting">How to light your scene correctly?
      </h2>
      <img src={lightCorrectly} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Light setup for product video" />
      <p style={{ marginBottom: "20px" }}>Depending on the product you are filming, lighting is going to be very different. Here are 3 types of lighting for 3 different product materials.
      </p>
      <h3 id="translucent-material">Translucent material (bottle, perfume,...)
      </h3>
      <img src={perfumePhotography} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Perfume Photography" />
      <p style={{ marginBottom: "20px" }}>Don't:</p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Don’t point the light directly at it</b>, as it won’t look good.
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>Don't:</p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Place the light to create reflections</b>, therefore you should try to light from different angles to see what works best on camera.
        </li>
        <li>
          You should also have <b>reflectors on both sides</b>.
        </li>
        <li>
          You can use a <b>soft light hanging from the top back</b> to nicely contour the product. This soft light can diffuse a specific color that matches the branding of the product.
        </li>
      </ul>
      <h3 id="matte-material">Matte material (bracelet, statue,...)
      </h3>
      <img src={braceletVideography} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Bracelet Photography" />
      <p style={{ marginBottom: "20px" }}>Do:</p>
      <ul style={{ marginBottom: "20px" }}>
        <li>Use a <b>top diffuse light</b>. To get diffuse lighting, you should use a LED light pointing at a diffusion frame and place the frame as close as possible to the product.
        </li>
        <li>
          Place a <b>colored backlight</b> to contour the product and give a nice colored vibe to it.
        </li>
      </ul>
      <h3 id="shiny-material">Shiny material (watch, cooking tool,...)
      </h3>
      <img src={watchPhotography} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Watch Photography" />

      <p style={{ marginBottom: "20px" }}>Don't:</p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Don't face a flat reflecting surface</b> as we would see you on the product. Instead, take the photo from an angle.
        </li>
        <li>
          <b>Don’t use your flash</b> as it would mess up the entire shot.
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>Do:</p>
      <ul>
        <li>
          Always <b>defuse the light</b> so the product doesn’t reflect it too much.
        </li>
        <li>
          Place the product <b>in a white box</b> or surround it with white panels so it does not reflect the environment.
        </li>
        <li>
          If the material is too reflective, you can use <b>dulling spray</b> which cancels the reflectiveness of an object for some time.
        </li>
      </ul>
      <h2 id="steps-create-video-home">6 steps to create a product video at home
      </h2>
      <p style={{ marginBottom: "20px" }}>Now that you are ready to go, let’s break down 6 steps you need to go through to <b>create an amazing product video</b>, even if you know nothing about filmmaking.
      </p>
      <h3 id="define-target-audience">Define the target audience for this video
      </h3>
      <p style={{ marginBottom: "20px" }}>Knowing who your target is key before starting any video project.
      </p>
      <p style={{ marginBottom: "20px" }}><u>On a paper, describe your audience according to these criteria</u>: </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>What age group</b> does your audience belong to? The way you will pace the video will highly change depending on the age group.
        </li>
        <li>
          Is your audience mostly <b>male, female, or balanced</b>? This could help you define the mood and the way you should present your product.
        </li>
        <li>
          Does your audience belong to a specific <b>group, ethnicity, or religion</b>? This is an important factor as you should respect people's habits and traditions.
        </li>
        <li>
          <b>Where is your audience located?</b> If your audience is located in Saudi Arabia, maybe it is not the right strategy to film the commercial on a snowy mountain.
        </li>
        <li>
          What are your <b>audience's lifestyle</b> and habits?
        </li>
        <li>
          Why do they use your product?
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Based on these criteria, you will be able to angle your video differently. The more specific you are, the better you will convert the targeted people.
      </p>
      <p style={{ marginBottom: "20px" }}>
        If you aim for a broad audience, you can still make multiple product videos for each audience segment.
      </p>
      <p>
        Make sure to <b>write everything down</b> as you will need these criteria to take relevant choices during the whole process.
      </p>
      <h3 id="image-result">Imagine the desired final result
      </h3>
      <img src={planVideo} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Imagine your video shots" />
      <p style={{ marginBottom: "20px" }}>
        <b>Taking inspiration from various other product films</b> is definitely the way to go before you start imagining yours.
      </p>
      <p style={{ marginBottom: "20px" }}>
        If you are new to product video, simplicity is key. Don’t make it overcomplicated as you might not have the skills a professional film artist has.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Based on the other product video you took inspiration from, you can start to imagine the result you want to achieve.
      </p>
      <h3 id="write-shot-list">Create the video shot list (download template)
      </h3>
      <p style={{ marginBottom: "20px" }}>
        The shot list is important as it will define a <b>clear walkthrough</b> and help you stick to a precise process.
        It helps gain efficiency and saves a lot of time during the filming process.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Here is a template you can print to write down the shots and scenes you want to make:
      </p>
      <img src={shotsTemplate} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Template for video shots commercial" />
      <p style={{ marginBottom: "20px" }}>
        Now that you have this template, here is a breakdown of each column so you know how to use it.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Shot #</u></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Shot number 1 is the first shot of your commercial. You don’t need to fill anything in there.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Scene #</u></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        If you plan to use multiple setups and decors, you can number them accordingly as a reference for each shot.
        For example, if you want your 3rd and 6th shots to be on scene 1, you can write it down there.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Movement</u></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        What movement should you operate when you start filming this shot? Maybe you want a translation left to right, or zoom, or anything like that.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Angle</u></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        There are different types of angles and each one has its specific purpose. Here is a list that could be helpful for product video filming:
      </p>
      <ul>
        <li>
          <b>Low angle</b>: emphasize the power
        </li>
        <li>
          <b>High angle</b>: creates a feeling of inferiority
        </li>
        <li>
          <b>Dutch angle</b>: camera tilted to create disorientation
        </li>
        <li>
          <b>Overhead shot</b>: creates a sense of scale and movement
        </li>
        <li>
          <b>Eye level</b>: neutral perspective and most common height
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        Note that these descriptions are mostly true for cinematography and filmmaking but you can be creative when it comes to product videos.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b><u>Size</u></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        What do you want your spectator to see? And what do you want to transmit? This is when shot sizes are important.
        Here is a list of the different shot sizes and their purpose:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          <b>Wide Shot (WS)</b>: this is great to make the product feel small in a certain location. For example a perfume with a big waterfall.
        </li>
        <img src={wideShot} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Wide shot cinematography" />
        <li>
          <b>Full Shot (FS)</b>: Your product is the main subject but you can still see the scenery.
        </li>
        <img src={fullShot} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Full short cinematography" />
        <li>
          <b>Medium Shot (MS)</b>: This size frames start above the waist while still showing the environment.
        </li>
        <img src={mediumShot} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Medium short cinematography" />
        <li>
          <b>Close Up (CU)</b>: Ideal to showcase a specific part or feature of your product.
        </li>
        <img src={closeup} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Closeup short cinematography" />
        <li>
          <b>Establishing Shot</b>:  It’s important to introduce a new scene with this type of shot as it will help the spectator understand the environment.
          Note that for some product shots this is not necessary. It mostly is for films and stories.
        </li>
      </ul>
      <h3 id="prepare-decors">Prepare the decors and the setup
      </h3>
      <img src={homeVideosetup} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Home product video setup" />
      <p style={{ marginBottom: "20px" }}>
        <b>The more work you put upfront, the less editing your video will need</b>. It is up to you to choose which part you are the best at.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Now here is how to prepare for a dark scene setup. Depending on the mood you want your video to be, you can adapt it accordingly.
        Also, this setup does not take into account outdoor/moving commercials.
      </p>
      <p style={{ marginBottom: "20px" }}><b><u>Step by step process to start filming your commercial at home</u>:</b></p>
      <ol style={{ marginBottom: "20px" }}>
      <li>
        First of all, start by <b>placing a table</b> or any support on which you will film your product.
      </li>
      <li>
        Then, <b>add the black perspex</b> or any surface you want on top of it. <br />Also, make sure to have a <b>backdrop</b> placed at least 2 to 4m away from the table.
      </li>
      <li>
        To create some ambiance, you can <b>add some decor</b> between the table and the product. Make sure that this decor is out of focus when filming your product.
      </li>
      <li>
        Now that this is done, you can decide where your camera and your lights will be.
      </li>
      <li>
        <b>Place your camera</b> on a tripod in front of your product. Aim for a <b>distance between 20cm to 50cm</b>.
      </li>
      <li>
        When placing your lights, don’t put them in front of your product. Either light from the sides or from the top. Lighting from the front would flatten your image.
        <br />Also remember, <b>the less we see where the light is coming from, the better</b>.
      </li>
      <li>
        You can also add a <b>colored backlight</b> to add some branding and vibes to your shots.
      </li>
      </ol>
      <p style={{ marginBottom: "20px" }}>
        If you plan to make the product rotate, you can use the electronic lazy-suzan with the perspex on top of it.
      </p>
      <h3 id="start-filming">Start filming all your scenes
      </h3>
      <p style={{ marginBottom: "20px" }}>
        Now that your setup is ready, you can start filming. Just <b>refer to your video shot list</b>.
        You can film a shot multiple times to make sure you will have the right one once you start editing.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Remember that you can add some effects while filming. For example, a moving RGB-led tube above the product would create some interesting effects.
      </p>
      <h3 id="editing">It’s time for editing!
      </h3>
      <img src={premiereLook} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Premiere Pro look" />
      <p style={{ marginBottom: "20px" }}>
        Now that you have all your shots on your computer, it is time for editing.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is some software you can use</u>:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Adobe Premiere Pro
        </li>
        <li>
          Adobe After Effect (Advanced only)
        </li>
        <li>
          Filmora (beginner-friendly)
        </li>
        <li>
          Sony Vegas Pro
        </li>
      </ul>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is a quick tutorial on how to edit a nice product commercial</u>:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <li>
          Add your shots one after the other
        </li>
        <li>
          Add transitions between the shots. If you don’t master transitions, you can choose faded transitions as they will always work.
        </li>
        <li>
          Add sound effects relevant to your shots. Don’t go crazy on that, simplicity always wins.
        </li>
        <li>
          Now that your film has sound effects, add music that matches your video requirements as seen in the beginning.
        </li>
        <li>
          Export your product video in mp4 format and publish it on YouTube.
        </li>
        <li>
          Congratulations, your product video is finished!
        </li>
      </ol>
      <h2 id="tips-look-professional">Tips to make your video look more professional
      </h2>
      <p style={{ marginBottom: "20px" }}>
        <b>Place the product far away from any wall or backdrop</b> to avoid creating shadows with the lights.
        Additionally, it also prevents lighting the background which would look terrible if you are filming in a black mood.
      </p>
      <p style={{ marginBottom: "20px" }}>
        When filming a product video, you mostly want to <b>place it on a shiny surface</b> to reflect colors and lights. This is why we often recommend perspex for example.
        If you really want to use a specific surface with a specific pattern, you can still place a transparent acrylic plastic board above it.
      </p>
      <p style={{ marginBottom: "20px" }}>
        If you are shooting a commercial for a soft drink or something that requires <b>ice cubes, go for fake ones</b>.
        They are cheap, they don’t melt and they look better on camera.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>If you take a video of a reflective object, you can use a polarizing filter</b> as it will help remove any bad reflections.
      </p>
      <h2 id="product-video-ideas">Product video ideas
      </h2>
      <p style={{ marginBottom: "20px" }}>
        Now that you have some basics on product video filmmaking, you can start to get inspiration and ideas from professional creators and commercials.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <u>Here is a list of cool product videos that you can replicate at home</u>:
      </p>
      <h3>Creative idea with a TV</h3>
      <iframe width="800" height="400" src="https://www.youtube.com/embed/SKbHHcdLGKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br />
      <h3>Tricks for smooth and dynamic video</h3>
      <iframe width="800" height="400" src="https://www.youtube.com/embed/E0oI5_uDOUc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br />
      <h3>Learn advanced editing and effects</h3>
      <iframe width="800" height="400" src="https://www.youtube.com/embed/HjxnWLKAM10" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <h2 id="product-video-smartphone">How do I shoot professional videos on my smartphone (iPhone or Android)?
      </h2>
      <img src={smartphoneFilm} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Smartphone product filming" />
      <p style={{ marginBottom: "20px" }}>
        Filming <b>professional videos at home is possible with a smartphone</b> if you select the right settings and if you set up your lights correctly.
      </p>
      <p style={{ marginBottom: "20px" }}>
        Of course, the more advanced your smartphone is, the better it will look, especially for dark scenes.
        If you have an older smartphone, we would recommend you film in well-lighted scenes as it will result in better shots.
      </p>
      <p style={{ marginBottom: "20px" }}>
        To completely cover this topic, we created a full product photography course where you will learn how to correctly use your smartphone for photos and videos.
      </p>
      <p style={{ marginBottom: "20px" }}>
        <b>You can learn more about this course here: <a href="https://app.01supply.com/academy/photo-mastery-course" target="blank_" title="Product video course" className="blue"><u>Photo Mastery</u></a></b>
      </p>
      <p style={{ marginBottom: "20px" }}>
        01supply Academy helps thousands of small product creators make a living out of their passion by training them in online marketing and product creation.
      </p>



    </div>
  );
};

export default Content;
