import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrderAndPayment = ({ isEtsyProduct }) => {
  const { t } = useTranslation();

  return (
    <div className="column width-100">
      <p style={{ marginBottom: "15px" }} className="title montserrat-800">
        {t("productDetail.orderAndPayment.title")}
      </p>
      <p style={{ marginBottom: "10px" }} className="text-bold">
        {t("productDetail.orderAndPayment.1")}
      </p>
      <p style={{ marginBottom: "15px" }} className="text">
        {t("productDetail.orderAndPayment.2")}
      </p>
      <p className="text">{t("productDetail.orderAndPayment.3")}</p>
      <p className="text">{t("productDetail.orderAndPayment.4")}</p>
      <p className="text">{t("productDetail.orderAndPayment.5")}</p>
      <p style={{ marginBottom: "15px" }} className="text">
        {t("productDetail.orderAndPayment.6")}
      </p>
      <p style={{ marginBottom: "25px" }} className="text">
        {t("productDetail.orderAndPayment.7")}
      </p>
      {!isEtsyProduct && (
        <>
          <p style={{ marginBottom: "15px" }} className="text-bold">
            {t("productDetail.orderAndPayment.8")}
          </p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.9")}
          </p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.10")}
          </p>
          <p className="text">{t("productDetail.orderAndPayment.11")}</p>
          <p className="text">{t("productDetail.orderAndPayment.12")}</p>
          <p className="text">{t("productDetail.orderAndPayment.13")}</p>
          <p className="text">{t("productDetail.orderAndPayment.14")}</p>
          <p className="text">{t("productDetail.orderAndPayment.15")}</p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.16")}
          </p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.17")}
          </p>
          <p className="text">{t("productDetail.orderAndPayment.18")}</p>
          <p className="text">
            {t("productDetail.orderAndPayment.19")}{" "}
            <Link target="_blank" className="text blue underline" to="cart">
              {t("productDetail.orderAndPayment.20")}
            </Link>
          </p>
          <p className="text">{t("productDetail.orderAndPayment.21")}</p>
          <p className="text">{t("productDetail.orderAndPayment.22")}</p>
          <p className="text">{t("productDetail.orderAndPayment.23")}</p>
          <p className="text">{t("productDetail.orderAndPayment.24")}</p>
          <p className="text">{t("productDetail.orderAndPayment.25")}</p>
          <p className="text">{t("productDetail.orderAndPayment.26")}</p>
          <p className="text">{t("productDetail.orderAndPayment.27")}</p>
          <p style={{ marginBottom: "25px" }} className="text">
            {t("productDetail.orderAndPayment.28")}
          </p>
        </>
      )}
      <p style={{ marginBottom: "15px" }} className="text-bold">
        {t("productDetail.orderAndPayment.29")}
      </p>
      <p className="text">{t("productDetail.orderAndPayment.30")}</p>
      <p style={{ marginBottom: "25px" }} className="text">
        {t("productDetail.orderAndPayment.31")}
      </p>
      {isEtsyProduct && (
        <>
          <p style={{ marginBottom: "15px" }} className="text-bold">
            {t("productDetail.orderAndPayment.32")}
          </p>
          <p className="text">{t("productDetail.orderAndPayment.33")}</p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.34")}
          </p>
          <p className="text">{t("productDetail.orderAndPayment.35")}</p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.36")}{" "}
            <a
              className="text blue underline"
              href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("productDetail.orderAndPayment.37")}
            </a>
          </p>
          <p style={{ marginBottom: "15px" }} className="text">
            {t("productDetail.orderAndPayment.38")}
          </p>
          <p className="text">{t("productDetail.orderAndPayment.39")}</p>
          <p className="text">{t("productDetail.orderAndPayment.40")}</p>
          <p className="text">{t("productDetail.orderAndPayment.41")}</p>
          <p className="text">{t("productDetail.orderAndPayment.42")}</p>
          <p className="text">{t("productDetail.orderAndPayment.43")}</p>
          <p className="text">{t("productDetail.orderAndPayment.44")}</p>
          <p className="text">{t("productDetail.orderAndPayment.45")}</p>
          <p className="text">{t("productDetail.orderAndPayment.46")}</p>
        </>
      )}
    </div>
  );
};

export default OrderAndPayment;
