import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { Modal } from "../wrappers";
import { DownOutlined } from "@ant-design/icons";
import { LocalStatesContext } from "../providers/index";
import countryListJson from "../utils/country_list.json";

const { Option } = Select;

const CountryCurrencySelector = ({ selectorStyle }) => {
  const { t, i18n } = useTranslation();

  const { localStates, setLocalStates } = useContext(LocalStatesContext);

  const [isMoalDisplayed, setIsModalDisplayed] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(localStates.country);
  const [selectedLanguage, setSelectedLanguage] = useState(localStates.lang || "en");
  const [selectedCurrency, setSelectedCurrency] = useState(localStates.currency);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    let countryListRes = [];
    for (let region of countryListJson) {
      if (!region.children) countryListRes.push(region);
      else countryListRes = [...countryListRes, ...region.children];
    }
    setCountryList(countryListRes);
  }, []);

  const handleValid = () => {
    let newLocalStates = localStates;
    if (selectedCountry) {
      localStorage.setItem("userCountry", selectedCountry);
      newLocalStates = { ...newLocalStates, country: selectedCountry };
    }

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage, (err, t) => {});
      newLocalStates = { ...newLocalStates, lang: selectedLanguage };
    }

    if (selectedCurrency) {
      localStorage.setItem("userCurrency", selectedCurrency);
      newLocalStates = { ...newLocalStates, currency: selectedCurrency };
    }

    setLocalStates(newLocalStates);
    setIsModalDisplayed(false);
  };

  return (
    <>
      <p
        style={selectorStyle}
        className="text-roboto pointer text-ellipsis"
        onClick={() => {
          setIsModalDisplayed(true);
        }}
      >
        {localStates.country} / {localStates.lang} / {localStates.currency}
        <DownOutlined style={{ fontSize: "11px", marginLeft: "4px" }} />
      </p>

      <Modal
        className="modal-account invitation"
        onOk={() => {}}
        visible={isMoalDisplayed}
        onCancel={() => setIsModalDisplayed(false)}
        footer={[]}
      >
        <div className="column start width-100">
          <p style={{ marginBottom: "5px" }} className="text-bold">
            {t("country")}
          </p>
          <Select
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            onChange={(countryIndex) => {
              setSelectedCountry(countryList[countryIndex].title);
            }}
            value={selectedCountry || t("yourCountry")}
            defaultValue="Choose country"
          >
            {countryList &&
              countryList.map((country, index) => {
                if (country.title !== "Rest of world") return <Option value={index}>{country.title}</Option>;
              })}
          </Select>
          <p style={{ marginTop: "20px", marginBottom: "5px" }} className="text-bold">
            {t("language")}
          </p>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedLanguage(value);
            }}
            value={selectedLanguage}
            defaultValue="Choose language"
          >
            <Option value={"en"}>English</Option>
            <Option value={"fr"}>Français</Option>
            <Option value={"es"}>Spanish</Option>
            <Option value={"pt"}>Portuguese</Option>
            <Option value={"de"}>German</Option>
            <Option value={"hi"}>Hindi</Option>
          </Select>
          <p style={{ marginTop: "20px", marginBottom: "5px" }} className="text-bold">
            {t("currency")}
          </p>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedCurrency(value);
            }}
            value={selectedCurrency}
            defaultValue="Choose country"
          >
            <Option value={"EUR"}>EUR</Option>
            <Option value={"USD"}>USD</Option>
          </Select>

          <div style={{ marginTop: "30px" }} className="width-100 row center">
            <button style={{ width: "200px" }} onClick={handleValid} className="button big orange-back">
              {t("valid")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CountryCurrencySelector;
