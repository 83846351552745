import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { tagList, articles } from "../../pages/blog/articles";

import { urlParams } from "../../utils/urlParams";

import "./style.scss";

const TagMenu = ({ setDisplayedArticles, selectedTag, setSelectedTag }) => {
  const [redirect, setRedirect] = useState("");
  const { t, i18n } = useTranslation();

  const paramsUrl = urlParams();

  const handleFilter = (tag, isClicked) => {
    if (isClicked) {
      setRedirect(tag ? `/blog?tag=${tag}` : "/blog")
    }
    if (setDisplayedArticles) {
      setDisplayedArticles(
        articles.filter(
          (article) =>
            (article.tags.includes(tag) || !tag) &&
            ((article.lang === "fr" && i18n.language.includes("fr")) ||
              (article.lang === "en" && i18n.language.includes("en")))
        )
      );
      setSelectedTag(tag);
    }
  };

  useEffect(() => {
    handleFilter(paramsUrl.tag);
  }, [i18n.language]);

  return (
    <div className="blog-tag-menu">
      {redirect && <Redirect to={redirect} />}
      <p
        style={{ margin: "5px 20px 5px 0" }}
        className={`pointer ${!selectedTag && "text-bold"}`}
        onClick={() => handleFilter("", true)}
      >
        {t("all2")}
      </p>
      {tagList.map((tag, index) => {
        return (
          <p
            style={{ margin: "5px 20px 5px 0" }}
            className={`pointer ${selectedTag === tag.toUpperCase() && "text-bold"}`}
            onClick={() => handleFilter(tag.toUpperCase(), true)}
          >
            {t(`blog.tags.${index + 1}`)}
          </p>
        );
      })}
    </div>
  );
};
export default TagMenu;
