import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

import graphIcon from "../../../images/icons/graph.png";
import icon from "../../../images/icons/analytic.svg";

const AnalyticButton = ({ setState }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={(e) => {
        setState(true);
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ width: "80%", marginBottom: "15px" }}
      className="button-shadow analytics-button"
    >
      <img
        className="mobile-display-none"
        alt="growth arrow"
        src={graphIcon}
        style={{ width: "20px", position: "absolute", left: "10px" }}
      />
      <p style={{ fontWeight: "500", color: "#f3a648" }} className="center">
        {t("viewStatistics")}
      </p>
    </button>
  );
};

export default AnalyticButton;
