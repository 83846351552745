import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image, message, Button, Skeleton, Tabs, InputNumber, Tooltip } from "antd";

import Menu from "../components/menu/FirstMenu";
import MobileMenu from "../components/menu/mobile/FirstMenu";
import DOMPurify from "dompurify";

import { header } from "../api/header";
import { url } from "../api/url";
import { ShoppingCartOutlined, DownOutlined } from "@ant-design/icons";
import { origin } from "../data/Origin";
import { getImage } from "../utils/GetImage";
import ImportIcon from "../images/icons/import-icon-orange.svg";
import ImportIconWhite from "../images/icons_white/Import list.svg";
import Header from "../components/menu/marketplaceNavbar";
import { Helmet } from "react-helmet";
import ShippingMethods from "../components/productDetails/ShippingMethods";
import PopupLogin from "../components/modals/RegisterModal";
import SupplierPage from "./SupplierShop";
import ModalExtension from "../components/modals/ExtensionModal";
import { updateCart } from "../utils/updateCart";
import ShippingDetails from "../components/productDetails/tabs/Shipping";
import OrderAndPayment from "../components/productDetails/tabs/OrderAndPayment";
import EtsyLabel from "../components/productDetails/EtsyLabel";
import ReviewsLabel from "../components/global/reviewsLabel";

import { Modal } from "../wrappers";

import { priceConverter } from "../utils/priceConverter";
import { LocalStatesContext } from "../providers/LocalStates";

import ecoLabelIcon from "../images/icons/eco.svg";
import handmadeLabelIcon from "../images/icons/handmade.svg";
import tooltipIcon from "../images/icons/toolip.png";

import { routes } from "../routes";

import ResellerSetupProgress from "../components/reseller/SetupProgress";
import NotFoundProduct from "../components/productDetails/NotFound";
import AdminButton from "../components/productDetails/AdminButtons";
import ProductEtsyStats from "../components/productEtsyStats/index";

import resellersLeftLabel from "../images/productResellerLeftLabel.svg";
import etsyOrderInstructionFR from "../images/illustrations/etsy_order_instruction_fr.svg";
import etsyOrderInstructionEN from "../images/illustrations/etsy_order_instruction_en.svg";
import graphIcon from "../images/icons/graph.png";

const { TabPane } = Tabs;

const ProductDetail = ({ isModal, productProps, userP, setUserP }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [isStickyNav, setIsStickyNav] = useState(false);
  const [product, setProduct] = useState({});
  const [images, setImages] = useState([]);
  const [productOrigin, setProductOrigin] = useState({});
  const [user, setUser] = useState({});
  const [seeShipping, setSeeShipping] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [variantSelect, setVariantSelect] = useState({});
  const [optionsSelect, setOptionsSelect] = useState(["", "", ""]);
  const [update, setUpdate] = useState(false);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [availableOptions1, setAvailableOptions1] = useState([]);
  const [availableOptions2, setAvailableOptions2] = useState([]);
  const [availableOptions3, setAvailableOptions3] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [supplierShippings, setSupplierShippings] = useState([]);
  const [shippingSelect, setShippingSelect] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [popupLogin, setPopupLogin] = useState("");
  const [shippingDestination, setShippingDestination] = useState("");
  const [productAddToCart, setProductAddToCart] = useState(false);
  const [productIsImport, setProductIsImport] = useState(false);
  const [modalExtension, setModalExtension] = useState(false);
  const [supplier, setSupplier] = useState({});
  const [recommandedRetailPrice, setRecommendedRetailPrice] = useState(0);
  const [tabActiveKey, setTabActiveKey] = useState("1");

  const [isSalesStatsDisplayed, setIsSalesStatsDisplayed] = useState(false);

  const [isNotFoundProduct, setIsNotFoundProduct] = useState(false);

  const { localStates } = useContext(LocalStatesContext);
  const history = useHistory();

  const { t, i18n } = useTranslation();

  window.onscroll = function (e) {
    if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else if (window.scrollY > 90) {
      setIsStickyNav(true);
    } else setIsStickyNav(false);
  };

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    return history.listen((location) => {
      setImages([]);
      getProduct();
    });
  }, [history, window.location.search, window.location.pathname]);

  useEffect(() => {
    getProduct();
    if (!userP?._id) {
      getUser();
    } else {
      setUser(userP);
    }
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setUser(resJson.data);
    }
  };

  const getSupplier = async (createrId) => {
    const res = await fetch(`${url}/user/supplier/${createrId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      setSupplier(resJson.data);
    }
  };

  const getProduct = async () => {
    const productDataGestion = async ({ product }) => {
      if (product.retailPrice) {
        setRecommendedRetailPrice(
          priceConverter({
            price: parseFloat(product.retailPrice).toFixed(2),
            currency: localStates.currency,
            type: "retailPrice",
            originalPrice: product.price,
            user,
          })
        );
      }
      getImageF(product.photos);
      getOrigin(product.origin);
      setSupplierShippings(product.shipping);
      if (product?.creater?.id) {
        getSupplier(product.creater.id);
      }

      if (typeAccount === "seller" && product.creater?.id) {
        const res = await fetch(
          `${url}/custom-price/find-product-price/${product.creater.id}/${idAccount}/${product._id}/no-variant`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              ...header,
              authorization: token,
            },
          }
        );
        if (res.status === 200) {
          const resJson2 = await res.json();
          setProduct({
            ...product,
            variants_price: getProductVariants({
              variants: product.variants,
              variantBool: product.variantBool,
            }),
            price: resJson2.customPrice,
            comparedPrice: product.price,
          });
        } else {
          setProduct({
            ...product,
            variants_price: getProductVariants({
              variants: product.variants,
              variantBool: product.variantBool,
            }),
          });
        }
      } else
        setProduct({
          ...product,
          variants_price: getProductVariants({
            variants: product.variants,
            variantBool: product.variantBool,
          }),
        });
    };

    if (!productProps?.stock) {
      const productId = productProps ? productProps._id : window.location.href.split("id=")?.[1];
      const res = await fetch(`${url}/product/findOne?id=${productId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: idAccount,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson?.data?.isDelete) {
          setIsNotFoundProduct(true);
        }
        if (resJson?.data?.photos) {
          productDataGestion({ product: resJson.data });
        }
      } else {
        setIsNotFoundProduct(true);
      }
    } else {
      productDataGestion({ product: productProps });
    }
  };

  const getProductVariants = ({ variants, variantBool }) => {
    let minPrice = 0;
    let maxPrice = 0;
    let finish = false;
    let option1 = [];
    let option2 = [];
    let option3 = [];
    if (variants?.length) {
      variants.map((variant, index) => {
        if (variant.option1 && !option1.includes(variant.option1)) option1.push(variant.option1);

        if (variant.option2 && !option2.includes(variant.option2)) option2.push(variant.option2);

        if (variant.option3 && !option3.includes(variant.option3)) option3.push(variant.option3);
        if (index === 0) minPrice = parseFloat(variant.price);
        else if (maxPrice === 0 && parseInt(variant.price) > minPrice) maxPrice = parseFloat(variant.price);
        else if (parseFloat(variant.price) < minPrice) {
          maxPrice = minPrice;
          minPrice = parseFloat(variant.price);
        } else if (parseFloat(variant.price) > maxPrice) maxPrice = parseFloat(variant.price);

        if (index === variants.length - 1) finish = true;
      });
    }

    if (finish || !variants?.length || !variantBool) {
      let resultPrice = minPrice ? `${parseFloat(minPrice).toFixed(2)}` : 0;
      if (maxPrice != minPrice && maxPrice !== 0) {
        resultPrice = `${parseFloat(minPrice).toFixed(2)} - $${parseFloat(maxPrice).toFixed(2)}`;
      }

      setOptions1(option1);
      setOptions2(option2);
      setOptions3(option3);

      return resultPrice;
    }
  };

  const getOrigin = (originP) => {
    origin.map((org) => {
      if (org.name === originP) {
        setProductOrigin({ country: org.name, flag: org.flag });
      }
    });
  };

  const getImageF = async (images) => {
    if (images && images.length > 0) {
      let imagesStock = [];
      for (let i = 0; i < images.length; i++) {
        const res = await getImage(images[i]);
        if (res) {
          imagesStock.push(res);
          if (i === images.length - 1) {
            setImages(imagesStock);
          }
        } else if (i === images.length - 1) {
          setImages(imagesStock);
        }
      }
    }
  };

  const addProduct = async () => {
    setProductIsImport(true);
    setUser({ ...user, import_list: [...user?.import_list, product._id] });
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    const userRes = await resJson.data;
    if (userRes) {
      let newList = [];
      if (userRes && userRes.import_list) newList = [...userRes.import_list];
      newList = [product._id, ...newList];
      let newUser = { ...userRes, import_list: newList };
      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
          importList: product._id,
        }),
      });

      // Algolia analytic
      window.aa("convertedObjectIDs", {
        userToken: user?._id || "",
        index: "products",
        eventName: "product_import",
        objectIDs: [product._id],
        queryID: localStorage.getItem("algolia_last_query_id") || "",
      });
      message.success({
        content: t("messages.productSuccessfullyImported"),
        duration: 5,
        onClick: () => setRedirect("/import-list"),
      });
      setProductIsImport(true);
      setUser({ ...user, import_list: [...user.import_list, product._id] });
      if (setUserP) {
        setUserP({ ...user, import_list: [...user.import_list, product._id] });
      }
      if (res.status === 405) {
        setProductIsImport(true);
        message.error(t("messages.productAlreadyInImportList"));
      } else if (res.status === 406) {
        setProductIsImport(true);
        message.error(t("messages.productAlreadyInInventory"));
      } else if (res.status === 500) message.error(t("messages.error"));
    }
  };

  const deleteProductF = async () => {
    const resConnect = await fetch(`${url}/product/delete/${deleteProduct}/${idAccount}/${secretId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (resConnect.status === 200) {
      message.success(t("messages.productDeleted"));
      setDeleteProduct("");
      setRedirect(true);
    }
  };

  useEffect(() => {
    let result = false;
    let available1 = [];
    let available2 = [];
    let available3 = [];
    if (product && product.variants && product.variantBool && optionsSelect !== ["", "", ""]) {
      product.variants.map(async (variant, index) => {
        if (optionsSelect[0] && optionsSelect[0] === variant.option1) {
          available2.push(variant.option2);
          available3.push(variant.option3);
          available1.push(variant.option1);
        }
        if (
          variant.option1 === optionsSelect[0] &&
          (!variant.option2 || variant.option2 === optionsSelect[1]) &&
          (!variant.option3 || variant.option3 === optionsSelect[2])
        ) {
          if (product.retailPrice) {
            // update product recommended retail price
            const initRatio = parseFloat(product.retailPrice) / parseFloat(product.price);
            setRecommendedRetailPrice(
              priceConverter({
                price: (parseFloat(variant.price) * parseFloat(initRatio)).toFixed(2),
                currency: localStates.currency,
                type: "retailPrice",
                originalPrice: variant.price,
                user,
              })
            );
          }
          setVariantSelect(variant);
          result = true;
        }
      });
    }
    setAvailableOptions1(available1);
    setAvailableOptions2(available2);
    setAvailableOptions3(available3);
  }, [update]);

  const handleReadMoreEtsyPrice = () => {
    setTabActiveKey("4");
    const section = document.querySelector("#tabs-informations");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const productDecribe = DOMPurify.sanitize(
    product.describe?.includes("<p>") ? product.describe : product.describe?.replace(/\n/g, "<br>"),
    { USE_PROFILES: { html: true } }
  );

  const price =
    (!product.variants_price && !(variantSelect && variantSelect.price)) || !product.variantBool
      ? product.price
      : variantSelect && variantSelect.price
      ? variantSelect.price
      : product.variants_price;

  const shippings =
    !shippingSelect.name && product.shipping?.length > 0 ? (
      <>
        <p className="text-bold">{t("shipping")}</p>
        <p
          onClick={() => setSeeShipping(true)}
          className="text-gradient"
          style={{
            fontSize: "15px",
            marginTop: "0px",
            marginBottom: "5px",
            cursor: "pointer",
          }}
        >
          {t("productDetail.selectShippingMethod")}
        </p>
      </>
    ) : (
      product.shipping?.length > 0 && (
        <>
          <p className="text-bold">{t("shipping")}</p>
          <div onClick={() => setSeeShipping(true)} style={{ cursor: "pointer" }} className="column">
            <p
              style={{
                fontSize: "15px",
                marginTop: "5px",
                marginBottom: "0px",
                cursor: "pointer",
                color: "grey",
              }}
              className="text-300"
            >
              {t("to")}
              <span style={{ marginLeft: "5px", marginRight: "5px" }} className="text-bold underline">
                {shippingDestination}
              </span>
              {t("with")}
              <span style={{ marginLeft: "5px" }} className="text-bold underline">
                {shippingSelect.name}
              </span>
            </p>
            <p style={{ color: "grey", marginBottom: "4px" }} className="text-300">
              {t("productDetail.estimatedDeliveryTime")} : {shippingSelect.time} {t("days")}
            </p>
            <p className="link text-300">
              {t("productDetail.moreOption")} <DownOutlined style={{ fontSize: "12px" }} />
            </p>
          </div>
        </>
      )
    );

  return (
    <div style={{ overflowX: "hidden" }} className="page product-detail">
      {modalExtension && <ModalExtension setModalOn={setModalExtension} windowSize={windowSize} />}
      {popupLogin && <PopupLogin modalOn={popupLogin} setModal={setPopupLogin} />}
      {product.name && !isModal && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{product.name} | 01supply</title>
          <link rel="canonical" href={`https://app.01supply.com/product-details?name=${product.name}`} />
          <meta name="description" content={<div dangerouslySetInnerHTML={productDecribe} />} />
          <meta property="og:description" content={product.describe} />
          <meta property="og:url" content={`https://app.01supply.com/product-details?name=${product.name}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${product.name} | 01supply`} />
          <meta property="og:image" content={images[0]} />
        </Helmet>
      )}

      {isSalesStatsDisplayed && (
        <Modal
          footer={[]}
          className="modal-80-vw"
          visible={true}
          onCancel={() => setIsSalesStatsDisplayed(false)}
        >
          <ProductEtsyStats productImage={images[0]} product={product} />
        </Modal>
      )}

      {redirect && !isModal && <Redirect to={routes.marketplace(i18n.language).path} />}
      <div style={isModal ? { background: "white" } : {}} className="page-start">
        {windowSize <= 1150 && !isModal && (
          <Header windowSize={windowSize} location="product-detail" user={user} />
        )}

        {windowSize > 1150 && !isModal ? (
          <Menu userProps={user} />
        ) : (
          !isModal && window.location.href.includes("join-us") && <MobileMenu locationP="setting" />
        )}
        <div
          style={{ paddingLeft: "0", marginBottom: windowSize <= 1150 && "200px" }}
          className={isModal ? "width-100" : "column-scrolling"}
        >
          {isNotFoundProduct ? (
            <>
              {windowSize > 1150 && !isModal && (
                <Header windowSize={windowSize} location="product-detail" user={user} />
              )}
              <NotFoundProduct product={product} />
            </>
          ) : (
            <div
              className="product-detail"
              style={
                windowSize > 1150
                  ? {
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      marginTop: "0",
                      width: "100%",
                    }
                  : {}
              }
            >
              {windowSize > 1150 && !isModal && (
                <Header windowSize={windowSize} location="product-detail" user={user} />
              )}

              <div
                className="product-detail tab-product-details"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  paddingTop: isModal ? "" : "70px",
                  marginTop: isModal ? "" : "30px",
                  paddingRight: "20px",
                  marginBottom: "50px",
                  width: "100%",
                  padding: isModal ? "0px" : "5vw",
                }}
              >
                {deleteProduct && (
                  <Modal
                    className="modal-no-ok"
                    title={t("deleteProduct")}
                    onOk={deleteProductF}
                    visible={true}
                    onCancel={() => setDeleteProduct("")}
                    footer={[
                      <Button key="back" onClick={() => setDeleteProduct("")}>
                        {t("no")}
                      </Button>,
                      <Button key="submit" type="primary" onClick={deleteProductF}>
                        {t("yes")}
                      </Button>,
                    ]}
                  >
                    <p className="title">{t("productDetail.areYouWantDelete")}</p>
                  </Modal>
                )}
                <ShippingMethods
                  modalOn={seeShipping}
                  windowSize={windowSize}
                  setShippingDestination={setShippingDestination}
                  setShippingSelect={setShippingSelect}
                  shippingSelect={shippingSelect}
                  product={product}
                  supplierShippings={supplierShippings}
                  setModalOn={setSeeShipping}
                />
                {images && images.length > 0 ? (
                  <div
                    style={{
                      width: windowSize < 915 ? "100%" : isModal ? "calc(100% - 750px)" : "30%",
                      maxWidth: "400px",
                      minWidth: isModal ? "150px" : "300px",
                      marginRight: "35px",
                    }}
                    className="column"
                  >
                    <Image.PreviewGroup>
                      {/* REVIEW */}
                      <ReviewsLabel
                        score={product.reviews?.score}
                        total={product.reviews?.total}
                        style={{ position: "absolute", top: "8px", left: "5px" }}
                      />
                      <Image
                        filename={"01supply_" + product.name + "_1"}
                        alt={"01supply_" + product.name + "_1"}
                        className="big-img-product"
                        src={images.length > 0 && images[0]}
                      />
                      <div
                        style={{ width: "100%", justifyContent: "flex-start", marginTop: "20px" }}
                        className="row-wrap container-image-product-detail"
                      >
                        {images &&
                          images.length > 0 &&
                          images.map((img, index) => {
                            return (
                              index > 0 && (
                                <Image
                                  filename={"01supply_" + product.name + `_${index + 2}`}
                                  alt={"01supply_" + product.name + `_${index + 2}`}
                                  src={img}
                                  className="little-img-product"
                                />
                              )
                            );
                          })}
                      </div>
                    </Image.PreviewGroup>
                  </div>
                ) : (
                  <div style={{ width: "30%", minWidth: "300px" }} className="column">
                    <Skeleton className="skeleton-95" loading={true} active />
                  </div>
                )}
                <div style={{ width: "auto" }} className="row-wrap start">
                  <div
                    style={{
                      width: "300px",
                      marginRight: "35px",
                    }}
                    className="column"
                  >
                    <AdminButton product={product} />
                    <h1
                      style={{
                        fontSize: "20px",
                        marginBottom: "0px",
                        textAlign: "start",
                      }}
                      className="title-300 text-ellipsis-2"
                    >
                      {product.name}
                    </h1>
                    <div
                      style={{
                        width: "90%",
                        height: "20px",
                        alignItems: "flex-end",
                        fontSize: "22px",
                        color: "#3f3f3f",
                        marginTop: "40px",
                      }}
                      className="row"
                    >
                      {price && (
                        <p
                          style={{ color: "#3f3f3f" }}
                          className="text-bold text-ellipsis montserrat price-disclaimer"
                        >
                          {priceConverter({
                            price,
                            currency: localStates.currency,
                          })}
                          {product?.type === "etsy" && (
                            <Tooltip
                              style={{ padding: "10px", height: "auto" }}
                              title={
                                <div>
                                  {t("etsyProductPriceDiclaimer")
                                    .split(". ")
                                    .map((line) => (
                                      <p style={{ color: "white", fontSize: "12px" }}>{line}</p>
                                    ))}
                                  <p
                                    onClick={handleReadMoreEtsyPrice}
                                    className="white underline pointer bold"
                                    style={{ fontSize: "12px", marginTop: "10px", fontWeight: "bold" }}
                                  >
                                    {t("readMore")}
                                  </p>
                                </div>
                              }
                            >
                              <img
                                src={tooltipIcon}
                                alt="price information"
                                style={{ height: "20px", marginBottom: "10px", marginLeft: "10px" }}
                              />
                            </Tooltip>
                          )}
                        </p>
                      )}
                      {product?.comparedPrice > 0 && (
                        <p
                          style={{
                            fontSize: "14px",
                            minHeight: "25px",
                            marginLeft: "15px",
                            textDecoration: "line-through",
                          }}
                          className="text-300 text-ellipsis montserrat"
                        >
                          {priceConverter({
                            price:
                              variantSelect && variantSelect.comparedPrice
                                ? variantSelect.comparedPrice
                                : product.comparedPrice,
                            currency: localStates.currency,
                          })}
                        </p>
                      )}
                    </div>

                    {recommandedRetailPrice !== 0 &&
                      recommandedRetailPrice !== null &&
                      recommandedRetailPrice !== undefined &&
                      user?.retailPrice?.anable !== false && (
                        <p style={{ marginBottom: "10px" }}>
                          {t("retailPrice")}: {recommandedRetailPrice}
                        </p>
                      )}

                    <p style={{ fontSize: "16px", color: "#B9B9B9" }} className="title-300">
                      {t("productDetail.vatIncluded")}
                    </p>

                    {product.origin && (
                      <p style={{ fontSize: "16px", color: "#B9B9B9" }} className="title-300">
                        {t("from")}:
                        <span style={{ marginLeft: "5px", color: "#B9B9B9" }} className="text-bold">
                          {product.origin}
                        </span>
                      </p>
                    )}

                    {supplier?.shop?.name ? (
                      <p
                        style={{ fontSize: "16px", color: "#B9B9B9", alignItems: "center" }}
                        className="title-300 row"
                      >
                        {t("supplier")} :
                        <Link
                          to={`/shop/${supplier.shop.name}`}
                          style={{
                            marginLeft: "5px",
                            color: "#70C4F3",
                            maxWidth: "220px",
                            fontSize: "14px",
                            minHeight: "24px",
                          }}
                          className="text-ellipsis blue underline"
                        >
                          {supplier.shop.name}
                        </Link>
                      </p>
                    ) : (
                      product.creater?.name && (
                        <p
                          style={{ fontSize: "16px", color: "#B9B9B9", alignItems: "center" }}
                          className="title-300 row center"
                        >
                          {t("supplier")} :
                          <Link
                            to={`/shop/${product.creater?.name}`}
                            style={{
                              marginLeft: "5px",
                              color: "#70C4F3",
                              maxWidth: "220px",
                              fontSize: "14px",
                              minHeight: "24px",
                            }}
                            className="text-ellipsis blue underline"
                          >
                            {product.creater?.name}
                          </Link>
                        </p>
                      )
                    )}

                    {product.type !== "etsy" && typeAccount !== "creater" && (
                      <>
                        <Link
                          onClick={(e) => {
                            if (!typeAccount) {
                              e.stopPropagation();
                              e.preventDefault();
                              setPopupLogin(`/messages?contact=${product.creater.id}&offer=${product._id}`);
                            }
                          }}
                          to={typeAccount && `/messages?contact=${product.creater?.id}&offer=${product._id}`}
                          style={{
                            color: "#70C4F3",
                            fontSize: "14px",
                            marginTop: "15px",
                            textDecoration: "underline",
                            marginBottom: "5px",
                          }}
                        >
                          {t("productDetail.sendPriceOffer")}
                        </Link>
                        <Link
                          onClick={(e) => {
                            if (!typeAccount) {
                              e.stopPropagation();
                              e.preventDefault();
                              setPopupLogin(`/messages?contact=${product.creater.id}`);
                            }
                          }}
                          to={typeAccount && `/messages?contact=${product.creater?.id}`}
                          style={{
                            color: "#70C4F3",
                            fontSize: "14px",
                            textDecoration: "underline",
                            marginBottom: "5px",
                          }}
                        >
                          {t("productDetail.contactSupplier")}
                        </Link>
                      </>
                    )}
                    <div style={{ width: "90%", marginLeft: "0" }} className="divider-horizontal" />

                    {product.variantBool && product.variants.length > 0 && (
                      <React.Fragment>
                        {product.optionsVariants.length > 0 && (
                          <React.Fragment>
                            <p className="text-bold" style={{ marginBottom: "0px" }}>
                              {product.optionsVariants[0]}
                              {optionsSelect[0] && (
                                <span style={{ fontSize: "14px" }} className="text-300 montserrat">
                                  : {optionsSelect[0]}
                                </span>
                              )}
                            </p>
                            <div style={{ marginBottom: "20px" }} className="row-wrap">
                              {options1.map((option, index) => {
                                return (
                                  <button
                                    onClick={() => {
                                      let stock = optionsSelect;
                                      stock[0] = option;
                                      setOptionsSelect(stock);
                                      setUpdate(!update);
                                    }}
                                    style={{
                                      marginLeft: "0",
                                      marginRight: "7px",
                                      marginTop: "7px",
                                    }}
                                    className={
                                      optionsSelect[0] === option
                                        ? "button-variant on"
                                        : optionsSelect[0] && !availableOptions1.includes(option)
                                        ? "button-variant disable"
                                        : "button-variant"
                                    }
                                  >
                                    {option}
                                  </button>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                        {product.optionsVariants.length > 1 && (
                          <React.Fragment>
                            <p className="text-bold">
                              {product.optionsVariants[1]}
                              {optionsSelect[1] && (
                                <span style={{ fontSize: "14px" }} className="text-300 montserrat">
                                  : {optionsSelect[1]}
                                </span>
                              )}
                            </p>
                            <div style={{ marginBottom: "20px" }} className="row-wrap">
                              {options2.map((option, index) => {
                                return (
                                  <button
                                    onClick={() => {
                                      let stock = optionsSelect;
                                      stock[1] = option;
                                      setOptionsSelect(stock);
                                      setUpdate(!update);
                                    }}
                                    style={{
                                      marginLeft: "0",
                                      marginRight: "7px",
                                      marginTop: "7px",
                                    }}
                                    className={
                                      optionsSelect[1] === option
                                        ? "button-variant on"
                                        : optionsSelect[0] && !availableOptions2.includes(option)
                                        ? "button-variant disable"
                                        : "button-variant"
                                    }
                                  >
                                    {option}
                                  </button>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                        {product.optionsVariants.length > 2 && (
                          <React.Fragment>
                            <p className="text-bold" style={{ marginTop: "10px" }}>
                              {product.optionsVariants[2]}
                              {optionsSelect[2] && (
                                <span style={{ fontSize: "14px" }} className="text-300 montserrat">
                                  : {optionsSelect[2]}
                                </span>
                              )}
                            </p>
                            <div className="row-wrap">
                              {options3.map((option, index) => {
                                return (
                                  <button
                                    onClick={() => {
                                      let stock = optionsSelect;
                                      stock[2] = option;
                                      setOptionsSelect(stock);
                                      setUpdate(!update);
                                    }}
                                    style={{
                                      marginLeft: "0",
                                      marginRight: "7px",
                                      marginTop: "7px",
                                    }}
                                    className={
                                      optionsSelect[2] === option
                                        ? "button-variant on"
                                        : optionsSelect[0] && !availableOptions3.includes(option)
                                        ? "button-variant disable"
                                        : "button-variant"
                                    }
                                  >
                                    {option}
                                  </button>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}

                    {/* LABELS */}
                    {(supplier.about?.eco_label?.verified || supplier.about?.handmade_label?.verified) && (
                      <div
                        style={{ marginLeft: "0", marginTop: "30px", marginBottom: "30px" }}
                        className="divider-horizontal"
                      />
                    )}
                    {supplier.about?.eco_label?.verified && (
                      <div style={{ marginBottom: "20px" }} className="row">
                        <img src={ecoLabelIcon} style={{ height: "25px", marginRight: "10px" }} />
                        <p>
                          {t("productDetail.thisSupplierHasThe")}
                          <span className="text-bold" style={{ marginLeft: "5px" }}>
                            {t("productDetail.ecoCertif")}
                          </span>
                        </p>
                      </div>
                    )}
                    {supplier.about?.handmade_label?.verified && (
                      <div style={{ marginBottom: "20px" }} className="row">
                        <img src={handmadeLabelIcon} style={{ height: "25px", marginRight: "10px" }} />
                        <p>
                          {t("productDetail.thisSupplierHasThe")}
                          <span className="text-bold" style={{ marginLeft: "5px" }}>
                            {t("productDetail.handmadeCertif")}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  {product.type !== "etsy" && (
                    <div
                      style={{
                        width: "350px",
                      }}
                      className="column"
                    >
                      <React.Fragment>
                        <p className="text-bold">{t("quantity")}</p>
                        <div className="row">
                          <InputNumber
                            min={1}
                            max={
                              !product.stop_selling_out_stock
                                ? 1000
                                : !variantSelect.stock
                                ? product.stock
                                : variantSelect.stock
                            }
                            value={quantity}
                            type="number"
                            style={{ width: "80px", marginTop: "5px", marginBottom: "15px" }}
                            onChange={(value) => setQuantity(value)}
                          />
                          <p
                            style={{ fontSize: "16px", color: "#B9B9B9", marginLeft: "10px" }}
                            className="title-300"
                          >
                            {!variantSelect.stock ? product.stock : variantSelect.stock}{" "}
                            {t("productDetail.itemsLeft")}
                          </p>
                        </div>

                        <div style={{ alignItems: "center", marginTop: "0px" }} className="row">
                          <p className="text-bold" style={{ fontSize: "15px" }}>
                            {t("productDetail.maxResellers")}:
                          </p>
                          <p
                            style={{
                              marginLeft: "4px",
                              marginRight: "5px",
                            }}
                          >
                            {product.reseller_max && product.maxResellerBool
                              ? (product.reseller_max - product.seller.length || 0) + " "
                              : t("unlimited")}{" "}
                            {t("productDetail.resellersAllowedLeft")}
                          </p>
                        </div>
                        <div style={{ alignItems: "center", marginTop: "5px" }} className="row">
                          <p className="text-bold" style={{ fontSize: "15px" }}>
                            {t("productDetail.monthlyOrderLimit")}:
                          </p>
                          <p
                            style={{
                              marginLeft: "4px",
                              marginRight: "5px",
                            }}
                          >
                            {product.orders_max && product.maxOrderBool
                              ? product.orders_max + " "
                              : t("unlimited")}{" "}
                            {t("productDetail.ordersByMonth")}
                          </p>
                        </div>

                        <div className="divider-horizontal" style={{ marginLeft: "0" }} />

                        <p
                          style={{ fontSize: "14px", color: "#B9B9B9", marginBottom: "6px" }}
                          className="title text-bold"
                        >
                          {t("productDetail.processingTime")}:
                          <span style={{ marginLeft: "5px", color: "#B9B9B9" }} className="text-300">
                            {product.processingTime} {t("days")}
                          </span>
                        </p>

                        {shippings}

                        {product.resellersL > 0 && (
                          <div
                            style={{ marginTop: "10px", marginBottom: "5px", justifyContent: "flex-start" }}
                            className="row width-100 center"
                          >
                            <p
                              style={{ color: "white", position: "absolute", left: "40px", fontSize: "13px" }}
                            >
                              {" "}
                              {t("resellersLeft", { count: product.resellersLeft })}{" "}
                            </p>
                            <img
                              alt={`${product.resellersLeft} resellers left`}
                              src={resellersLeftLabel}
                              style={{ width: "250px" }}
                            />
                          </div>
                        )}
                        {typeAccount !== "creater" &&
                          !productIsImport &&
                          product.is_publish &&
                          product?._id &&
                          (!idAccount ||
                            (user?._id &&
                              !(
                                user.import_list?.includes(product._id) ||
                                user.products_id?.includes(product._id)
                              ))) && (
                            <button
                              onClick={() => {
                                if (!idAccount) {
                                  setPopupLogin(window.location.href);
                                } else {
                                  addProduct();
                                }
                              }}
                              className="button orange-back"
                              style={{
                                width: "300px",
                                maxWidth: "90vw",
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                                marginTop: "20px",
                              }}
                            >
                              <img
                                src={ImportIconWhite}
                                style={{
                                  height: "50%",
                                  width: "auto",
                                  position: "absolute",
                                  left: "20px",
                                }}
                              />
                              {t("components.productCard.2")}
                            </button>
                          )}

                        {typeAccount === "seller" && productIsImport && product.is_publish && (
                          <Link
                            to="/import-list"
                            className="button orange-back-outlined"
                            style={{
                              width: "300px",
                              maxWidth: "90vw",
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              position: "relative",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              src={ImportIcon}
                              style={{
                                height: "50%",
                                width: "auto",
                                position: "absolute",
                                left: "10px",
                              }}
                            />
                            {t("seeMyImportList")}
                          </Link>
                        )}

                        {product._id && !product.is_publish && (
                          <p className="text-red">{t("unpublishedProduct")}</p>
                        )}

                        {productAddToCart && (
                          <Link
                            to={routes.cart(i18n.language).path}
                            className="button orange-back"
                            style={{
                              width: "300px",
                              maxWidth: "90vw",
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              position: "relative",
                              marginTop: "10px",
                            }}
                          >
                            <ShoppingCartOutlined
                              style={{
                                fontSize: "22px",
                                position: "absolute",
                                left: "20px",
                              }}
                            />
                            {t("productDetail.viewMyCart")}
                          </Link>
                        )}

                        {product.seller &&
                          !productAddToCart &&
                          !(product.seller.length >= product.reseller_max && product.maxResellerBool) &&
                          product.is_publish &&
                          (!user._id || user._id !== product.creater?.id) && (
                            <button
                              onClick={() => {
                                updateCart({
                                  product,
                                  shippingSelect,
                                  variantSelect,
                                  quantity,
                                  idAccount,
                                  secretId,
                                  url,
                                  header,
                                  token,
                                  t,
                                });
                                setProductAddToCart(true);
                              }}
                              className="button orange-back"
                              style={{
                                width: "300px",
                                maxWidth: "90vw",
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                                marginTop: "10px",
                              }}
                            >
                              <ShoppingCartOutlined
                                style={{
                                  fontSize: "22px",
                                  position: "absolute",
                                  left: "10px",
                                }}
                              />
                              {t("productDetail.addToCart")}
                            </button>
                          )}
                      </React.Fragment>

                      {localStorage.getItem("typeAccountCreatosell") === "seller" &&
                        product.seller &&
                        product.seller.length >= product.reseller_max &&
                        product.maxResellerBool && (
                          <p className="text-red">{t("productDetail.maximumResellerReached")}</p>
                        )}

                      {user._id && user._id === product.creater?.id && (
                        <React.Fragment>
                          <Link
                            to={`/edit-product?id=${product._id}`}
                            className="button orange-back"
                            style={{
                              width: "200px",
                              marginBottom: "20px",
                              maxWidth: "90vw",
                              marginTop: "10px",
                            }}
                          >
                            {t("edit").toUpperCase()}
                          </Link>
                          <button
                            onClick={() => setDeleteProduct(product._id)}
                            className="button-red"
                            style={{ width: "200px", maxWidth: "90vw" }}
                          >
                            {t("delete").toUpperCase()}
                          </button>
                        </React.Fragment>
                      )}
                      <div style={{ width: "200px", marginLeft: "0" }} className="divider-horizontal" />
                    </div>
                  )}{" "}
                  {product.type === "etsy" && (
                    <div
                      style={{
                        width: "280px",
                        marginBottom: "20px",
                      }}
                      className="column"
                    >
                      <div
                        style={{ marginBottom: "10px", marginTop: windowSize < 1150 ? "20px" : "" }}
                        className="row end"
                      >
                        <p style={{ fontSize: "16px", color: "#B9B9B9" }} className="text-bold">
                          {t("quantity")}
                        </p>
                        <p
                          style={{ fontSize: "16px", color: "#B9B9B9", marginLeft: "10px" }}
                          className="title-300"
                        >
                          {!variantSelect.stock ? product.stock : variantSelect.stock}{" "}
                          {t("productDetail.itemsLeft")}
                        </p>
                      </div>

                      {product.resellersLeft > 0 && (
                        <div
                          style={{ marginBottom: "5px", justifyContent: "flex-start" }}
                          className="row width-100 center"
                        >
                          <p style={{ color: "white", position: "absolute", left: "40px", fontSize: "13px" }}>
                            {" "}
                            {t("resellersLeft", { count: product.resellersLeft })}{" "}
                          </p>
                          <img
                            alt={`${product.resellersLeft} resellers left`}
                            src={resellersLeftLabel}
                            style={{ width: "250px" }}
                          />
                        </div>
                      )}

                      {shippings}

                      <div style={{ width: "250px", marginLeft: "0" }} className="divider-horizontal" />
                      {typeAccount !== "creater" &&
                        !productIsImport &&
                        product.is_publish &&
                        product?._id &&
                        (!idAccount ||
                          (user?._id &&
                            !(
                              user.import_list?.includes(product._id) ||
                              user.products_id?.includes(product._id)
                            ))) && (
                          <button
                            onClick={(e) => {
                              if (!idAccount) {
                                setPopupLogin(window.location.href);
                              } else {
                                addProduct();
                              }
                            }}
                            className="button orange-back"
                            style={{
                              maxWidth: "90vw",
                              width: "300px",
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              src={ImportIconWhite}
                              style={{
                                height: "50%",
                                width: "auto",
                                position: "absolute",
                                left: "20px",
                              }}
                            />
                            {t("components.productCard.2")}
                          </button>
                        )}
                      {typeAccount === "seller" && productIsImport && (
                        <Link
                          to="/import-list"
                          className="button orange-back-outlined"
                          style={{
                            width: "300px",
                            maxWidth: "90vw",
                            position: "relative",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "20px",
                          }}
                        >
                          <img
                            src={ImportIcon}
                            style={{
                              height: "50%",
                              width: "auto",
                              position: "absolute",
                              left: "10px",
                            }}
                          />
                          {t("seeMyImportList")}
                        </Link>
                      )}
                      {product.origin_url && <EtsyLabel productUrl={product.origin_url} />}
                      {!user.products_id?.includes(product._id) && (
                        <img
                          alt="order instruction 01supply"
                          src={i18n.language.includes("fr") ? etsyOrderInstructionFR : etsyOrderInstructionEN}
                          className="width-100"
                          style={{ marginTop: "15px" }}
                        />
                      )}
                      {user.products_id?.includes(product._id) && product.origin_url && (
                        <a
                          href={product.origin_url}
                          className="button orange-back"
                          style={{
                            width: "300px",
                            maxWidth: "90vw",
                            position: "relative",
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                            marginTop: "15px",
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("productDetail.buyNowOnEtsy")}
                        </a>
                      )}

                      {/* ANALYTICS BUTTON */}
                      {product.etsy_stats?.total_sales > 25 && (
                        <div
                          style={{
                            width: "280px",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          <div
                            className="divider"
                            style={{ width: "90%", marginLeft: "5%", marginBottom: "20px", marginTop: "5px" }}
                          />
                          <button
                            onClick={(e) => {
                              setIsSalesStatsDisplayed(true);
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            style={{ width: "100%" }}
                            className="button-shadow"
                          >
                            <img
                              className="mobile-display-none"
                              alt="growth arrow"
                              src={graphIcon}
                              style={{ width: "20px", position: "absolute", left: "10px" }}
                            />
                            <p style={{ fontWeight: "500", color: "#f3a648" }} className="center">
                              {t("viewStatistics")}
                            </p>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Tabs
                onChange={(activeKey) => setTabActiveKey(activeKey)}
                style={{ paddingBottom: "100px" }}
                className="tab-product-details"
                defaultActiveKey="1"
                activeKey={tabActiveKey}
                id="tabs-informations"
              >
                <TabPane tab="Description" key="1">
                  <p
                    style={windowSize > 1150 ? { textAlign: "start" } : { textAlign: "start", width: "100%" }}
                    className="text-300"
                  >
                    <div dangerouslySetInnerHTML={{ __html: productDecribe }} />
                  </p>
                </TabPane>
                {product.creater?.name && (
                  <TabPane tab={t("supplier")?.charAt(0)?.toUpperCase() + t("supplier")?.slice(1)} key="2">
                    <SupplierPage
                      isProductDetailPage={true}
                      supplierProps={product.creater?.name}
                      locationP={"product-detail"}
                    />
                  </TabPane>
                )}
                <TabPane tab={t("shipping")} key="3">
                  <ShippingDetails setSeeShipping={setSeeShipping} />
                </TabPane>
                <TabPane tab={t("productDetail.orderAndPayment.tab")} key="4">
                  <OrderAndPayment isEtsyProduct={product?.type === "etsy"} />
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
