import React from "react";

const freeSubscriptionUserEmails = [
  "testseller2@gmail.com",
  "support@odins-hall.com",
  "sales463@gmail.com",
  "contact.invasionvikingshop@gmail.com",
  "sales462@gmail.com",
  "sales461@gmail.com",
  "sales460@gmail.com",
  "sales459@gmail.com",
  "sales458@gmail.com",
  "sales457@gmail.com",
  "sales456@gmail.com",
  "matenterprises2020@gmail.com",
];

const StatsUserList = ({ users }) => {
  const startStatsDate = new Date("2022-12-10");
  const filterUsers = users
    .map((user) => user.user)
    .filter((user) => !freeSubscriptionUserEmails.includes(user.email) && user.type === "seller");
  const nbResellers = filterUsers.filter((user) => new Date(user?.create_at) > startStatsDate).length;
  const nbResellersWithShopify = filterUsers.filter(
    (user) => user.shopify?.shop && new Date(user?.create_at) > startStatsDate
  ).length;
  const nbSubBasic = filterUsers.filter(
    (user) => user.plan?.type === "basic" && new Date(user?.plan?.subscription_date) > startStatsDate
  ).length;
  const nbSubAdvanced = filterUsers.filter(
    (user) => user.plan?.type === "advanced" && new Date(user?.plan?.subscription_date) > startStatsDate
  ).length;
  const nbSubPremium = filterUsers.filter(
    (user) => user.plan?.type === "premium" && new Date(user?.plan?.subscription_date) > startStatsDate
  ).length;
  const nbTotalSub = filterUsers.filter(
    (user) => user.plan?.type !== "free" && new Date(user?.plan?.subscription_date) > startStatsDate
  ).length;

  const subRevenus = nbSubBasic * 19 + nbSubAdvanced * 49 + nbSubPremium * 99;

  const calcPercent = ({ nb, totalReseller }) => {
    return parseFloat((nb / totalReseller) * 100).toFixed(2);
  };

  const revenuByUser = () => {
    return {
      withShopify: (subRevenus / nbResellersWithShopify).toFixed(2),
      withoutShopify: (subRevenus / nbResellers).toFixed(2),
    };
  };

  return (
    <div className="column">
      <p className="title" style={{ margin: "20px 0" }}>
        Stats depuis le 10/12/2022
      </p>
      <div className="row-wrap width-100">
        <div className="card" style={{ margin: "15px" }}>
          <p className="text">
            Revenus: <b>{subRevenus}$/mois</b>
          </p>
          <br />

          <p className="text">
            Total abonnements:{" "}
            <b>
              {nbTotalSub} ({calcPercent({ nb: nbTotalSub, totalReseller: nbResellers })}% d'utilisateur)
            </b>
          </p>
          <br />
          <p className="text">
            Abonnement moyen: <b>{(subRevenus / nbTotalSub).toFixed(2)}$</b>
          </p>
          <br />
          <p className="text">
            Gains par utilisateur: <br />
            <b>
              {revenuByUser().withShopify}$/mois (avec shopify) <br />
              {revenuByUser().withoutShopify}$/mois (sans shopify)
            </b>
          </p>
          <br />
          <p className="text">
            nombre d'incription revendeurs: <b>{nbResellers}</b>
          </p>
          <br />
          <p className="text">
            nombre d'incription revendeurs AVEC SHOPIFY: <b>{nbResellersWithShopify}</b>
          </p>
          <br />
          <p className="text">
            nb abonnement basic:{" "}
            <b>
              {nbSubBasic} ({calcPercent({ nb: nbSubBasic, totalReseller: nbResellers })}% sans Shopify) (
              {calcPercent({ nb: nbSubBasic, totalReseller: nbResellersWithShopify })}% avec Shopify){" "}
            </b>
          </p>
          <br />
          <p className="text">
            nb abonnement avancé:{" "}
            <b>
              {nbSubAdvanced} ({calcPercent({ nb: nbSubAdvanced, totalReseller: nbResellers })}% sans Shopify)
              ({calcPercent({ nb: nbSubAdvanced, totalReseller: nbResellersWithShopify })}% avec Shopify){" "}
            </b>
          </p>
          <br />
          <p className="text">
            nb abonnement premium:{" "}
            <b>
              {nbSubPremium} ({calcPercent({ nb: nbSubPremium, totalReseller: nbResellers })}% sans Shopify) (
              {calcPercent({ nb: nbSubPremium, totalReseller: nbResellersWithShopify })}% avec Shopify){" "}
            </b>
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default StatsUserList;
