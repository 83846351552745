import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import {Modal} from '../../wrappers'

import ValidIcon from "../../images/price_offer.gif";

const SendPriceOfferModal = ({ setModalOn, windowSize }) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000"
      visible={true}
      onCancel={() => {
        setModalOn(false);
      }}
      footer={[<Button onClick={() => setModalOn(false)}>{t("cancel")}</Button>]}
    >
      <div style={{ paddingBottom: "0px", alignItems: "center" }} className="column">
        <img style={{ width: "100%" }} src={ValidIcon} />
      </div>
    </Modal>
  );
};

export default SendPriceOfferModal;
