import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PayPalButton } from "react-paypal-button-v2";
import { url } from "../../api/url";
import { header } from "../../api/header";
import { message } from "antd";

const Paypal01supply = ({ data, setPassOrder, setOrders, orders, order, user }) => {
  const [token] = useState(localStorage.getItem("tokenCreatosell"));

  const { t } = useTranslation();

  let orderPaypal = {};

  const generatePaypalPaymentId = () => {
    if (data.product && data.product.creater?.id) {
      const createrId = data.product.creater.id;
      const newId = Math.random().toString(16).slice(2);

      return `${createrId}_${newId}`;
    } else return false;
  };

  let invoiceId = "";

  return (
    <>
      {process.env.REACT_APP_PAYPAL_PUBLIC_KEY && (
        <PayPalButton
          shippingPreference="NO_SHIPPING"
          options={{
            clientId: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
            currency: "USD",
          }}
          createOrder={async (dataP, actions) => {
            const response = await fetch(`${url}/paypal/create-order`, {
              method: "POST",
              credentials: "include",
              headers: {
                ...header,
                authorization: token,
              },
              body: JSON.stringify({
                orderId: data.orderId,
                shipping: data.shipping,
                order: data.order,
                message: data.message,
                product: data.product,
                local_variant: data.local_variant,
                variant: data.variant,
                isVariant: data.isVariant,
              }),
            });
            if (response.status == 200) {
              const resJson = await response.json();
              orderPaypal = resJson;

              if (resJson.orderUpdate && resJson.orderUpdate.paid) {
                let amount = parseFloat(resJson.orderUpdate.paid.seller_paid).toFixed(2);

                invoiceId = generatePaypalPaymentId();
                if (invoiceId === false) message.error(t("messages.error"));
                else {
                  return actions.order.create({
                    payer: {
                      name: {
                        surname: `${user.firstName} ${user.lastName}`,
                        given_name: `${user.company?.name}`,
                      },
                      address: {
                        address_line_1: `${user.company?.address}`,
                        address_line_2: "",
                        admin_area_2: `${user.company?.city ? user.company?.city : ""}`,
                        admin_area_1: "",
                        postal_code: `${user.company?.postal_code ? user.company?.postal_code : ""}`,
                        country_code: `${
                          user.company && user.company.country_code ? user.company.country_code : "US"
                        }`,
                      },
                      email_address: `${user.email}`,
                      phone: {
                        phone_type: "MOBILE",
                        phone_number: {
                          national_number: `${user.company?.phone}`,
                        },
                      },
                    },
                    purchase_units: [
                      {
                        amount: {
                          currency_code: "USD",
                          value: amount,
                        },
                        invoice_id: invoiceId,
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
                    },
                    // application_context: {
                    //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    // }
                  });
                }
              } else message.error(t("messages.error"));
            } else {
              message.error(t("messages.error"));
            }
          }}
          onApprove={(data, actions) => {
            // Capture the funds from the transaction
            return actions.order.capture().then(function (details) {
              // Show a success message to your buyer

              // OPTIONAL: Call your server to save the transaction

              let newOrder = orderPaypal.orderUpdate;
              if (
                !newOrder.seller ||
                (newOrder.seller &&
                  (!newOrder.seller.id ||
                    !newOrder.seller.name ||
                    !newOrder.seller.address ||
                    !newOrder.seller.company_name ||
                    !newOrder.seller.company_email ||
                    !newOrder.seller.company_phone))
              ) {
                newOrder.seller = {
                  id: user._id,
                  name: `${user.firstName} ${user.lastName}`,
                  vat: user.company.vat,
                  address: user.company.address,
                  company_name: user.company.name,
                  company_email: user.company.email ? user.company.email : user.email,
                  company_phone: user.company.phone,
                };
              }

              return fetch(`${url}/paypal/valid-order`, {
                method: "POST",
                credentials: "include",
                headers: {
                  ...header,
                  authorization: token,
                },
                body: JSON.stringify({
                  ...orderPaypal,
                  paypalOrderId: data.orderID,
                  simplePayment: true,
                  invoiceId,
                  paymentId: orderPaypal.paymentId,
                }),
              })
                .then((res) => res.json())
                .then((resPaidJson) => {
                  if (resPaidJson) {
                    setPassOrder({});
                    let newOrders = orders;
                    let newOrder = order;
                    orders.map((orderP, index) => {
                      if (order._id === orderP._id) {
                        newOrder.status = "paid";
                        newOrder = {
                          ...newOrder,
                          paid: orderPaypal.orderUpdate.paid,
                        };
                        newOrders[index] = newOrder;
                        setOrders([...newOrders]);
                      }
                    });
                    message.success(t("messages.orderPlaced"));
                  } else {
                    message.error(t("messages.error"));
                  }
                });
            });
          }}
        />
      )}
    </>
  );
};

export default Paypal01supply;
