import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import OutOfStock from "./OutOfStock";
import DeletedProduct from "./DeletedProduct";
import DeletedVariant from "./DeletedVariant";
import ChangedPrice from "./ChangedPrice";
import { updateUser } from "../../../../../services/updateUser";
import Tooltip from "../../../../global/Tooltip";
import Load from "../../../../../utils/Load";

const UpdateAlerts = ({ user, setUser }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleUpdateSetting = ({ updateAlerts }) => {
    setLoading(true);
    setUser({ ...user, updateAlerts });
    updateUser({ user: { updateAlerts } }).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        message.success(t("messages.successfullyUpdated"));
      } else {
        message.error(t("messages.error"));
      }
    });
  };

  return (
    <div style={{ marginLeft: "0", marginTop: "30px" }} className="card width-100">
      {loading && <Load />}
      <div className="row">
        <h2 style={{ fontSize: "27px", marginRight: "10px" }} className="title start montserrat-800 grey">
          {t("updateAlerts.title")}
        </h2>
        <Tooltip text={t("updateAlerts.tooltip")} />
      </div>
      {user?._id && (
        <>
          <OutOfStock handleUpdateSetting={handleUpdateSetting} user={user} />
          <DeletedProduct handleUpdateSetting={handleUpdateSetting} user={user} />
          <DeletedVariant handleUpdateSetting={handleUpdateSetting} user={user} />
          <ChangedPrice handleUpdateSetting={handleUpdateSetting} user={user} />
        </>
      )}
    </div>
  );
};

export default UpdateAlerts;
