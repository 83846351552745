import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";

import { Modal } from "../../wrappers";

import EtsyChromeExtensionIllustration from "../../images/illustrations/EtsyExtensionIllustration.png";
import ChromeIcon from "../../images/icons/Chrome.svg";

const ExtensionModal = ({ setModalOn, modalOn, windowSize, orderUrl }) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={{ padding: "30px" }}
      className="modal-1000"
      visible={modalOn}
      onCancel={() => {
        setModalOn(false);
      }}
      footer={[<Button onClick={() => setModalOn(false)}>{t("cancel")}</Button>]}
    >
      <div style={{ paddingBottom: "0px", alignItems: "center" }} className="column">
        <p className="title-bold center">{t("components.modal.37")}</p>
        <div
          className="divider-horizontal"
          style={{ width: "90%", marginLeft: "0", marginTop: "30px", marginBottom: "30px" }}
        />
        <div
          style={windowSize > 1150 ? {} : { flexDirection: "column", alignItems: "center" }}
          className="row space-between align-end"
        >
          <div style={{ width: windowSize > 1150 ? "60%" : "100%" }} className="column">
            <p style={{ marginBottom: "10px" }} className="title-bold center">
              {t("components.modal.38")}
            </p>
            <div className="row">
              {windowSize > 1150 && (
                <img
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
                  src={ChromeIcon}
                  style={{ height: "40px", marginRight: "30px" }}
                />
              )}
              <p className="title-300 start">{t("components.modal.39")}</p>
            </div>
          </div>
          <div className="column">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg"
              style={windowSize > 1150 ? {} : { marginTop: "20px" }}
              className="button big orange-back"
            >
              {t("components.modal.40")}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={orderUrl}
              style={{ marginTop: "10px", fontSize: "16px" }}
              className="button big orange-back"
            >
              {t("components.orderCard.7")}
            </a>
            <Checkbox
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (localStorage.getItem("dontDisplayExtensionModal")) {
                  localStorage.setItem("dontDisplayExtensionModal", "false");
                } else {
                  localStorage.setItem("dontDisplayExtensionModal", "true");
                }
              }}
            >
              {t("components.modal.41")}
            </Checkbox>
          </div>
        </div>
        <img
          src={EtsyChromeExtensionIllustration}
          style={{ width: "80%", marginTop: "30px", borderRadius: "8px" }}
        />
      </div>
    </Modal>
  );
};

export default ExtensionModal;
