import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";
import { Helmet } from "react-helmet";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";

import OgImg from "../../images/og_image_2.png";

import DownloadCard from "./DownloadCard";
import KeysValue from "./KeysValue";

import "./style.scss";

const ExtensionPromotion = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [window.tidioChatApi]);

  return (
    <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page marketplace-suggestion">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("extension.metas.title")}</title>
        <link rel="canonical" href="https://app.01supply.com/extension" />
        <meta name="description" content={t("extension.metas.description")} />
        <meta property="og:description" content={t("extension.metas.description")} />
        <meta property="og:url" content="https://app.01supply.com/extension" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t("extension.metas.title")} />
        <meta property="og:image" content={OgImg} />
      </Helmet>

      <div style={windowSize > 1150 ? { overflowY: "hidden" } : {}} className="page-start">
        {windowSize > 1150 ? <Menu locationP="extension" /> : <MobileMenu locationP="extension" />}
        <div style={{ padding: "0" }} className="column-scrolling">
          <div
            className="column"
            style={windowSize > 1150 ? { marginTop: `${"10px"}`, width: "100%" } : { width: "100%" }}
          >
            <div
              id="marketplace-scrolling"
              style={
                windowSize > 1150
                  ? {
                      width: "95%",
                      maxWidth: "1500px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      flex: "0 0 auto",
                      marginTop: "30px",
                      paddingBottom: "150px",
                      paddingLeft: "5vw",
                    }
                  : {
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      flex: "0 0 auto",
                      paddingBottom: "150px",
                    }
              }
              className="row wrap"
            >
              <h2
                style={{ margin: "10px", marginTop: windowSize <= 1150 && "30px", fontSize: "24px" }}
                className="title montserrat-800 start"
              >
                {t("extension.header")}
              </h2>
              <Divider style={{ marginTop: "5px" }} />
              <DownloadCard windowSize={windowSize} />
              <KeysValue windowSize={windowSize} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtensionPromotion;
