import React, { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
import { Table } from "antd";

import Menu from "../../components/menu/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../utils/RedirectLogin";

import { userServices } from "../../services/user";
import defaultProfileImg from "../../images/icons/User.png";

const MyResellers = () => {
  const [resellers, setResellers] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    userServices
      .myResellers()
      .then((res) => res.json())
      .then((res) => res.data && setResellers(res.data.sort((a, b) => b.salesCount - a.salesCount)));
  }, []);

  const columns = [
    {
      dataIndex: "profile_picture",
      width: 70,
      render: (img) => {
        return (
          <img
            src={img || defaultProfileImg}
            style={{ width: "40px", maxHeight: "40px", objectFit: "contain" }}
          />
        );
      },
    },
    {
      title: "Store",
      dataIndex: "user",
      render: (text, line) => {
        let shopUrl = line.shopify?.shop ? line.shopify?.shop : line?.shop?.url || "";
        if (!shopUrl.includes("http") && shopUrl) {
          shopUrl = "https://" + shopUrl;
        }
        return (
          <a className="text blue underline pointer" target="_blank" rel="noopener noreferrer" href={shopUrl}>
            {shopUrl}
          </a>
        );
      },
      sorter: (a, b) => (a?.shop?.url || "").localeCompare(b?.shop?.url || ""),
    },
    {
      title: t('myResellers.2'),
      dataIndex: "firstName",
      sorter: (a, b) => (a?.firstName || "").localeCompare(b?.firstName || ""),
    },
    {
      title: t('myResellers.3'),
      dataIndex: "importedProductsCount",
      sorter: (a, b) => (a?.importedProductsCount || 0) - (b?.importedProductsCount || 0),
    },
    {
      title: t('myResellers.4'),
      dataIndex: "salesCount",
      sorter: (a, b) => (a?.salesCount || 0) - (b?.salesCount || 0),
    },
    {
      dataIndex: "_id",
      width: 110,
      render: (id) => {
        return <Link className="text blue underline" to={`/messages?contact=${id}`}>Contacter</Link>;
      },
    },
  ];


  return (
    <div className="page">
      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={t('myResellers.5')} /> : <MobileMenu load={false} />}
        <div
          style={windowSize < 1150 ? { paddingLeft: "5vw", paddingRight: "5vw" } : {}}
          className="column-scrolling"
        >
          {windowSize > 1150 ? (
            <Header location={t('myResellers.5')} />
          ) : (
            <MobileHeader load={false} location={t('myResellers.5')} nameHeader={t('myResellers.5')} />
          )}

          <Table
            style={{ width: "95%" }}
            scroll={{ x: 1000, y: 500 }}
            pagination={false}
            columns={columns}
            dataSource={resellers}
          />
        </div>
      </div>
    </div>
  );
};

export default MyResellers;
