import React, { useState, useEffect } from "react";
import {useTranslation} from 'react-i18next'
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";
import LoadComponent from "../../../utils/Load";
import IbanModal from "../../../components/modals/IbanModal";
import PaypalEmailModal from "../../../components/modals/PaypalEmailModal";
import ConnectPaypal from "../../../components/paypal/ConnectPaypal";

import bankIcon from "../../../images/bank.svg";
import paypalIcon from "../../../images/paypal.svg";
import stripeIcon from "../../../images/stripe.svg";
import Stripe from "../../../components/stripe/Stripe";

const PaymentMethods = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [stripeEmail, setStripeEmail] = useState("");
  const [ibanModal, setIbanModal] = useState(false);
  const [paypalEmailAccountModal, setPaypalEmailAccountModal] = useState(false);
  const [stripeStatus, setStripeStatus] = useState("");

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [idAccount, secretId, token]);

  useEffect(() => {
    if (!user._id) getUser();
  }, []);

  const getUser = async () => {
    if (!user._id) {
      const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson) {
          setUser(resJson.data);
          if (resJson.data?.stripe?.account_id) getStripeInfo(resJson.data.stripe.account_id);
        }
      }
    }
  };

  const getStripeInfo = async (stripeAccountId) => {
    const res = await fetch(`${url}/stripe/account/${stripeAccountId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    if (resJson.account) {
      setStripeEmail(resJson.account.email);
      const status = resJson.account.payouts_enabled
        ? "Active"
        : resJson.account?.requirements?.currently_due?.some((requirement) =>
            requirement.includes("document")
          )
        ? "Missing documents"
        : "Pending";
      setStripeStatus(status);
    }
  };

  return (
    <div className="page">
      {ibanModal && (
        <IbanModal
          user={user}
          setIbanModal={setIbanModal}
          url={url}
          idAccount={idAccount}
          secretId={secretId}
          token={token}
          header={header}
          setUser={setUser}
        />
      )}
      {paypalEmailAccountModal && (
        <PaypalEmailModal
          user={user}
          setPaypalEmailAccountModal={setPaypalEmailAccountModal}
          url={url}
          idAccount={idAccount}
          secretId={secretId}
          token={token}
          header={header}
          setUser={setUser}
        />
      )}

      <RedirectLogin />
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? { alignItems: "flex-start", justifyContent: "flex-start", paddingTop: "110px" }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingTop: "30px",
                }
          }
          className="column-scrolling shop-supplier"
        >
          {windowSize > 1150 ? (
            <div className="header">
              <div
                className="row"
                style={
                  windowSize > 1150
                    ? { width: "100%", marginBottom: "-30px", height: "30px" }
                    : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                }
              >
                <h1 className="title-bold montserrat">{t('paymentMethod.1')}</h1>
              </div>
              <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
            </div>
          ) : (
            <MobileHeader nameHeader={"SETTINGS"} location="YOUR SHOP" />
          )}
          <div
            className="row-wrap"
            style={{
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            {windowSize > 1150 && <MenuSetting user={user} location={"your-shop-supplier"} />}
            <div
              id="demo-step-5"
              className="column"
              style={
                windowSize > 1400 ? { width: "calc(100% - 350px)", marginLeft: "30px" } : { width: "100%" }
              }
            >
              <div
                id="content"
                style={{
                  marginLeft: "0",
                  width: windowSize > 1150 ? "95%" : "100%",
                  padding: "15px 20px 30px 20px",
                  marginTop: windowSize > 1400 ? "0" : "30px",
                }}
                className="column card"
              >
                <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                {t('paymentMethod.2')}
                </h1>
                <img src={stripeIcon} style={{ width: "120px", marginTop: "20px" }} />

                <div style={{ justifyContent: "space-between", width: "100%" }} className="row-wrap">
                  <div className="column">
                    <p className="text-bold">{t('paymentMethod.3')}</p>
                    <p className="text">2%</p>
                  </div>
                  {stripeStatus && (
                    <>
                      {stripeEmail && (
                        <div className="column">
                          <p className="text-bold">{t('paymentMethod.4')}</p>
                          <p className="text">{stripeEmail}</p>
                        </div>
                      )}
                      <div className="column">
                        <p className="text-bold">Status</p>
                        <p
                          style={{ color: stripeStatus === "Active" ? "#6FD979 " : "#FBA95E" }}
                          className="text-bold"
                        >
                          {stripeStatus}
                        </p>
                      </div>
                    </>
                  )}
                  <Stripe stripeStatus={stripeStatus} stripeEmail={stripeEmail} user={user} />
                </div>

                {/*<ConnectPaypal user={user} />*/}
                {user?.stripe?.account_url && !user?.stripe?.account_completed && stripeStatus === "Active" && (
                  <p
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      background: "rgb(232, 53, 53, 0.5)",
                      padding: "5px 15px",
                      textAlign: "center",
                      marginTop: "30px",
                    }}
                    className="text-bold white"
                  >
                    {t('paymentMethod.5')}
                  </p>
                )}
              </div>
              {(user.paypal_email_account || user.iban) &&
                !user.stripe?.account_url && ( //temporary for past suppliers config
                  <div
                    style={{
                      marginLeft: "0",
                      width: windowSize > 1150 ? "95%" : "100%",
                      padding: "15px 20px 30px 20px",
                      marginTop: "30px",
                    }}
                    className="column card"
                  >
                    <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                    {t('paymentMethod.6')}
                    </h1>
                    <p
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        background: "rgb(232, 53, 53, 0.5)",
                        padding: "5px 15px",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                      className="text-bold white"
                    >
                      {t('paymentMethod.7')}
                    </p>

                    <img
                      src={paypalIcon}
                      style={{ width: "160px", marginTop: "40px", marginBottom: "10px" }}
                    />
                    <div style={{ justifyContent: "space-between", width: "100%" }} className="row-wrap">
                      <div className="column">
                        <p className="text-bold">{t('paymentMethod.8')}</p>
                        <p className="text">5% +</p>
                      </div>
                      {user?.paypal_email_account ? (
                        <>
                          <div className="column">
                            <p className="text-bold">Status</p>
                            <p style={{ color: "#6FD979" }} className="text-bold">
                              Active
                            </p>
                          </div>
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            onClick={() => setPaypalEmailAccountModal(true)}
                            className="button big orange-back"
                          >
                            {t('edit').toUpperCase()}
                          </div>
                        </>
                      ) : (
                        <div
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                          onClick={() => setPaypalEmailAccountModal(true)}
                          className="button big orange-back"
                        >
                          {t('paymentMethod.9')}
                        </div>
                      )}
                    </div>

                    <div
                      style={{ width: "100%", marginLeft: "0", marginTop: "40px", marginBottom: "30px" }}
                      className="divider-horizontal"
                    />

                    <div style={{ alignItems: "center", marginBottom: "10px" }} className="row">
                      <img src={bankIcon} style={{ width: "40px" }} />
                      <p style={{ color: "#585858", marginLeft: "15px" }} className="title-bold">
                        BANK
                      </p>
                    </div>
                    <div style={{ justifyContent: "space-between", width: "100%" }} className="row-wrap">
                      <div className="column">
                        <p className="text-bold">{t('paymentMethod.8')}</p>
                        <p className="text">4 - 7%</p>
                      </div>
                      {user?.iban ? (
                        <>
                          <div className="column">
                            <p className="text-bold">Status</p>
                            <p style={{ color: "#6FD979" }} className="text-bold">
                              Active
                            </p>
                          </div>
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            onClick={() => setIbanModal(true)}
                            className="button big orange-back"
                          >
                            {t('edit').toUpperCase()}
                          </div>
                        </>
                      ) : (
                        <div
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                          onClick={() => setIbanModal(true)}
                          className="button big orange-back"
                        >
                          {t('paymentMethod.10')}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
