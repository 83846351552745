import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./style.scss";

import { coursesId } from "../../constants/coursesId";

import easyDifficultImg from "../../images/courses/difficult_easy.svg";
import moderateDifficultImg from "../../images/courses/difficult_moderate.svg";

import { routes } from "../../routes";

const Product = ({ img, price, difficult, text1, text2, alt, courseId }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="step-container">
      <img className="product-img" alt={alt} src={img} />
      <div className="course-right-container">
        <p className="title start">{price}</p>
        <div
          style={{ marginTop: "10px", marginBottom: "20px", justifyContent: "space-between" }}
          className="row"
        >
          <Link
            style={{ width: "200px", height: "45px", marginRight: "30px", filter: "saturate(4)" }}
            className="button orange-back"
            title="course-payment"
            to={`${routes.coursePayment(i18n.language).path}?id=${courseId}`}
          >
            <span style={{ fontSize: `18px` }}>{t("components.course.1")}</span>
          </Link>
          <div className="column">
            <p className="text-bold orange">{t("components.course.2")}</p>
            {difficult === "easy" ? (
              <div className="row">
                <img style={{ width: "20px" }} alt="easy difficult" src={easyDifficultImg} />
                <p style={{ marginLeft: "7px" }} className="text-bold grey">
                  {t("components.course.3")}
                </p>
              </div>
            ) : (
              difficult === "moderate" && (
                <div className="row">
                  <img alt="moderate difficult" src={moderateDifficultImg} />
                  <p style={{ marginLeft: "7px" }} className="text-bold grey">
                    {t("components.course.4")}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
        <h2 className="text-bold text-300" style={{ fontFamily: `"montserrat", sans-serif` }}>
          {text1}
          <br />
          <br />
          <span className="text-300" style={{ fontFamily: `"montserrat", sans-serif` }}>
            {text2}
          </span>
        </h2>
      </div>
    </div>
  );
};

export default Product;
