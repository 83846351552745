export const tag = [
  "Travel",
  "Beach",
  "Summer",
  "Autumn",
  "Winter",
  "Spring",
  "Nature",
  "Hiking",
  "Camping",
  "Mountain",
  "Desert",
  "Sea",
  "Forest",
  "Mythology",
  "Esotericism",
  "Gods",
  "History",
  "Space",
  "Science",
  "Geography",
  "Music",
  "Love",
  "War",
  "Wood",
  "Metal",
  "3Dprinted",
  "Wool",
  "Leather",
  "Resin",
  "Dog",
  "Cat",
  "Horse",
  "Bird",
  "Fish",
  "Skull",
  "Hairstyle",
  "Ecological",
  "DIY",
  "Handcrafted",
  "Abstract",
  "Retro",
  "Vintage",
  "Organic",
  "Steel",
  "Iron",
  "Gold",
  "Silver",
  "Luxurioux",
  "Liquid",
  "Modern",
  "Stone",
  "Crystal",
  "Futuristic",
  "Medieval",
  "Fantasy",
  "Peace",
  "Wedding",
  "Plastic-Free",
  "Europe",
  "America",
  "Asia",
  "Africa",
  "Oceania",
  "Text",
  "Customizable",
  "Productivity",
  "Education",
  "Security",
  "Design",
];
