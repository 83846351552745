import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ExtensionModal from "../modals/ExtensionModal";

import etsyIcon from "../../images/icons/etsy.svg";

import "./style.scss";

const EtsyLabel = ({ productUrl }) => {
  const [isExtensionModalDisplayed, setIsExtensionModalDisplayed] = useState("none");
  const { t } = useTranslation();

  const handleClick = (e) => {
    if (isExtensionModalDisplayed === "none") {
      e.preventDefault();
      e.stopPropagation();
      setIsExtensionModalDisplayed(true);
    }
  };

  return (
    <>
      {isExtensionModalDisplayed === true && (
        <ExtensionModal
        productUrl={productUrl}
          windowSize={window.innerWidth}
          setModalOn={setIsExtensionModalDisplayed}
        />
      )}
      <a
        onClick={handleClick}
        target="_blank"
        href={productUrl}
        rel="noopener noreferrer"
        className="etsy-label"
      >
        <img src={etsyIcon} alt="etsy" />
        <p>
          {t("productDetail.fromEtsy2.1")} <span className="underline">{t("productDetail.fromEtsy2.2")}</span>
        </p>
      </a>
    </>
  );
};

export default EtsyLabel;
