import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import ThematicSelection from "../../ThematicsSelection";

const Thematic = ({ setProduct, product }) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", marginRight: "30px" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        {t("thematic")}
      </p>
      <div style={{ width: "100%" }} className="column">
        <p style={{ marginBottom: "15px" }} className="text-300">
          {t("components.supplier.createEditProduct.thematic.1")}
        </p>
        <ThematicSelection
          callbackSelection={(thematicName) => setProduct({ ...product, thematic: { name: thematicName } })}
          selections={[product.thematic?.name || ""]}
        />
        <div className="column" style={{ width: "100%", marginTop: "5px" }}>
          <p className="text-bold">{t("components.supplier.createEditProduct.thematic.2")}</p>
          <Input
            style={{ width: "94%", marginTop: "5px" }}
            onChange={(e) => setProduct({ ...product, thematic: { name: e.target.value } })}
          />
        </div>
      </div>
    </div>
  );
};

export default Thematic;
