import React, {useEffect, useState} from 'react'

const CheckPaypal = ({user, token, header, url}) => {

    useEffect(() => {
        if(user && user.paypal && user.type === "creater"){
            fetch(`${url}/paypal/check-status/${user._id}/${user.secret_id}`, {
                method: "POST",
                credentials: "include",
                headers: {
                  ...header,
                  authorization: token,
                },
                body: JSON.stringify({
                  paypal_merchant_id: user.paypal_merchant_id,
                  merchant_id: user.merchant_id
                }),
              })
              .then(res => {
                if(res.status == 200){
                  //message.success('Paypal infos updated !')
                }
              })
        }
    }, [user])

    return(
        <div>
        </div>
    )
}

export default CheckPaypal