import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import EcoLabelIcon from "../../images/icons/handmade.svg";
import { Helmet } from "react-helmet";
import iconCheck from "../../images/icons/Check.svg";

const HandmadeLabelWhat = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t } = useTranslation()

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("labelPage.metas.title")}</title>
        <link rel="canonical" href="https://app.01supply.com/what-is-handmade-label" />
        <meta name="description" content={t("labelPage.metas.description")} />
      </Helmet>
      <div className="page-start">
        <div
          style={
            windowSize > 1150
              ? { alignItems: "center", paddingTop: "70px", display: "flex", width: "100%" }
              : { alignItems: "center", paddingTop: "30px" }
          }
          className="column-scrolling"
        >
          <div
            id="supplier-doc"
            className="column"
            style={
              windowSize > 1150
                ? { width: "60%", marginLeft: "0%", marginTop: "100px" }
                : { width: "80%", marginLeft: "10%", marginTop: "30px", paddingBottom: "90px" }
            }
          >
            <div className={windowSize > 1150 ? "row" : "column"}>
              <img
                style={{ marginRight: "30px", height: "80px", width: "auto" }}
                src={EcoLabelIcon}
                alt="eco label icon svg"
              />
              <div className="column">
                <h2
                  style={
                    windowSize > 1150
                      ? { marginBottom: "0", height: "35px" }
                      : { marginBottom: "0", height: "35px", marginTop: "15px" }
                  }
                  className="row"
                >
                  <span
                    className="title"
                    style={{ fontWeight: "bold", fontSize: "30px", marginRight: "8px", color: "#AF9BD9" }}
                  >
                    {t("labelPage.1")}
                  </span>
                  <span className="title-300" style={{ fontSize: "30px", color: "#AF9BD9" }}>
                    label
                  </span>
                </h2>
                <p className="text-300">{t("by")} 01supply</p>
              </div>
            </div>
            <h1 style={{ fontSize: "20px", marginTop: "30px", marginBottom: "30px" }} className="text-bold">
              {t("labelPage.2")}
            </h1>

            <p className="title-300 grey underline">{t("labelPage.3")}</p>

            <h2 style={{ marginTop: "15px", fontSize: "18px", marginBottom: "35px" }} className="text grey">
              {t("labelPage.4")}
            </h2>

            <p className="title-300 grey underline">{t("labelPage.5")}</p>

            <h2 style={{ marginTop: "15px", fontSize: "18px", marginBottom: "20px" }} className="text grey">
              {t("labelPage.6")}
            </h2>

            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.7")}
            </p>
            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.8")}
            </p>
            <p style={{ marginBottom: "15px" }} className="text-300">
              <img src={iconCheck} style={{ height: "20px", width: "auto", marginRight: "20px" }} />
              {t("labelPage.9")}
            </p>
            <p style={{ marginTop: "40px", fontSize: "16px" }} className="text italic">
              {t("labelPage.10")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandmadeLabelWhat;
