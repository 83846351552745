import React, { useState, useEffect } from "react";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import { userServices } from "../../services/user";

import CoachingCard from "../../components/reseller/coachingDashboard/CoachingCard";

import { resellerCoaching } from "../../constants/resellerCoaching";
import { routes } from "../../routes/index";

import starterImg from "../../images/resellerCoaching/button1.svg";
import advancedImg from "../../images/resellerCoaching/advanced_button.svg";
import proImg from "../../images/resellerCoaching/pro_button.svg";
import { useTranslation } from "react-i18next";

const CoachingDashboard = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [user, setUser] = useState({});
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  useEffect(() => {
    userServices
      .me()
      .then((res) => res.json())
      .then((res) => setUser(res.data));
  }, [idAccount, secretId]);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  return (
    <div className="page coaching-dashboard">
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150 ? { paddingLeft: "30px", paddingTop: "150px" } : { paddingBottom: "100px" }
          }
          className="column-scrolling"
        >
          {windowSize > 1150 ? (
            <Header location="COACHING" attribut="COACHING" />
          ) : (
            <MobileHeader nameHeader={"COACHING"} location="COACHING" />
          )}

          <div
            className="column"
            style={windowSize > 1150 ? { width: "95%" } : { width: "90%", marginLeft: "5%" }}
          >
            <CoachingCard
              imgSrc={starterImg}
              description={t("resellerCoaching.cards.starter.description")}
              content={t("resellerCoaching.cards.starter.content")}
              isByMonth={false}
              price={resellerCoaching.starter.price}
              link={routes.resellerCoachingStarter(i18n.language).path}
              isAlreadyBuy={
                !!user.resellerCoaching?.find((coaching) => coaching.name === "starter" && coaching.isPaid)
              }
            />
            <CoachingCard
              imgSrc={advancedImg}
              description={t("resellerCoaching.cards.advanced.description")}
              content={t("resellerCoaching.cards.advanced.content")}
              isByMonth={true}
              price={resellerCoaching.advanced.price}
              link={routes.resellerCoachingAdvanced(i18n.language).path}
              isAlreadyBuy={
                !!user.resellerCoaching?.find((coaching) => coaching.name === "advanced" && coaching.isPaid)
              }
            />
            <CoachingCard
              imgSrc={proImg}
              description={t("resellerCoaching.cards.pro.description")}
              content={t("resellerCoaching.cards.pro.content")}
              isByMonth={true}
              price={resellerCoaching.pro.price}
              link={routes.resellerCoachingPro(i18n.language).path}
              isAlreadyBuy={
                !!user.resellerCoaching?.find((coaching) => coaching.name === "pro" && coaching.isPaid)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingDashboard;
