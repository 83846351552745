import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import OfferCard from "./OfferCard";

const OffersList = ({ messages, user, contact, setMessages, setLoad, windowSize }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (messages && messages.prices?.length) {
      document.getElementById(`message${messages.prices.length - 1}`).scrollIntoView();
    }
  }, [messages]);

  return (
    <div
      id="container-messages"
      className="column-scrolling"
      style={
        windowSize > 1150
          ? { width: "100%", marginTop: "50px", height: "calc(100vh - 270px)", padding: "2%" }
          : { width: "100%", marginTop: "10px", height: "50vh", paddingBottom: "50px" }
      }
    >
      {messages.prices &&
        messages.prices.map((offer, index) => {
          return (
            <div className="column" style={{ width: "100%" }}>
              <OfferCard
                id={`message${index}`}
                windowSize={windowSize}
                offers={messages.prices}
                messages={messages}
                setMessages={setMessages}
                location="chat"
                user={user}
                contact={contact}
                offer={offer}
                setLoad={setLoad}
              />
              {offer.status !== "pending" && (
                <div
                  style={{ marginTop: "0", marginBottom: "25px", marginTop: "10px", padding: "15px 20px" }}
                  className={user.type === "creater" ? "message-card sender" : "message-card receiver"}
                >
                  <p style={{ fontSize: "18px" }} className="title-bold white">
                    {offer.status === "accepted" ? t("components.modal.35") : t("components.modal.36")}
                  </p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default OffersList;
