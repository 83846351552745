import React from "react";
import { useTranslation } from "react-i18next";
import Row from "./RowTab";

const Tab = ({ paypalPayments }) => {
  const { t } = useTranslation();
  return (
    <div className="tab-container">
      <div className="tab-head">
        <p className="tab-1">{t("components.supplier.tabPayment.1")}</p>
        <p className="tab-2">{t("components.supplier.tabPayment.2")}</p>
        <p className="tab-3">{t("components.supplier.tabPayment.3")}</p>
        <p className="tab-4">Label</p>
        <p className="tab-5">{t("components.supplier.tabPayment.4")}</p>
        <p className="tab-6">{t("components.supplier.tabPayment.5")}</p>
        <p className="tab-7">{t("components.supplier.tabPayment.6")}</p>
        <p className="tab-8">{t("components.supplier.tabPayment.7")}</p>
      </div>
      {paypalPayments &&
        paypalPayments.payment_history &&
        paypalPayments.payment_history.map((payment) => {
          return <Row payment={payment} />;
        })}
    </div>
  );
};

export default Tab;
