import React, { useState, useEffect } from "react";
import { Skeleton, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import { productServices } from "../../../services/products";

import {Modal} from '../../../wrappers'
import ProductCard from "../../ProductCard";
import PricingModal from "../../reseller/pricing/FirstPlans";
import Header from "./Header";

const WinningProducts = ({ user, setUser, setRegisterModalIsActive, windowSize }) => {
  const [products, setProducts] = useState([]);
  const [isModalPricingDisplayed, setIsModalPricingDisplayed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadProduct, setIsLoadProducts] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const { t } = useTranslation();

  const PAGE_SIZE = 50;

  const userCanAccess = user.plan?.type === "advanced" || user.plan?.type === "premium"

  const needAccessCallbackFunction = () => {
    if (!userCanAccess) {
      if (user?._id) {
        setIsModalPricingDisplayed(true);
      } else {
        setRegisterModalIsActive(true);
      }
    }
  };

  const getProducts = () => {
    productServices
      .find({
        isWinningProducts: true,
        page: currentPage,
        limit: PAGE_SIZE,
        userId: localStorage.getItem("idAccountCreatosell") || "",
      })
      .then((res) => res.json())
      .then((resJson) => {
        const resProducts = resJson.data;
        setIsLoadProducts(false);
        setProducts(resProducts);
        setTotalProducts(resJson.totalProducts);
      });
  };

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  return (
    <div
      style={{
        boxShadow: "none",
        padding: windowSize <= 1150 && "0px",
        margin: windowSize < 1150 && "0px",
        marginBottom: "120px",
      }}
      className="width-100"
    >
      <Header userCanAccess={userCanAccess} needAccessCallbackFunction={needAccessCallbackFunction} />
      {isModalPricingDisplayed && (
        <Modal
          className="modal-full-screen"
          visible={true}
          footer={[]}
          onCancel={() => setIsModalPricingDisplayed(false)}
        >
          <div className="column width-100" style={{ alignItems: "center" }}>
            <PricingModal setRegisterModalIsActive={setRegisterModalIsActive} windowSize={windowSize} />
          </div>
        </Modal>
      )}
      <div style={{ justifyContent: windowSize < 1150 && "center" }} className={`width-100 row-wrap`}>
        {products?.length > 0 && !isLoadProduct ? (
          <>
         {!userCanAccess &&
            <div
              onClick={needAccessCallbackFunction}
              style={{ minHeight: products.length > 3 && "1110px" }}
              className="width-100 blur row center z-index-1000"
            >
              <button
                style={{ height: "50px", width: "350px", position: "absolute", top: "300px" }}
                className="button big orange-back"
              >
                {t("marketplace.winning.button")}
              </button>
            </div>}
            {products.map((product, index) => {
              return (
                <ProductCard
                  setUser={setUser}
                  user={user}
                  key={product._id}
                  index={index}
                  location={"landing"}
                  product={product}
                  isBlur={!userCanAccess}
                  blurCallbackFunction={needAccessCallbackFunction}
                />
              );
            })}
            <div className="row width-100 center">
              <Pagination
                key={currentPage + products.length + totalProducts}
                style={{ marginBottom: "50px", marginTop: "30px" }}
                showSizeChanger={false}
                onChange={(page, pageSizeP) => {
                  setCurrentPage(page || 1);
                  setIsLoadProducts(true);
                  scroll.scrollToTop();
                }}
                defaultCurrent={currentPage || 1}
                total={totalProducts}
                pageSize={PAGE_SIZE}
                disabled={!userCanAccess}
              />
            </div>
          </>
        ) : (
          <>
            <Skeleton
              style={{ marginTop: "20px" }}
              className="image-product width-100"
              loading={true}
              active
            />
            <Skeleton
              style={{ marginTop: "20px" }}
              className="image-product width-100"
              loading={true}
              active
            />
          </>
        )}
      </div>
    </div>
  );
};
export default WinningProducts;
