import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import { Input, Steps, Select, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { header } from "../../../api/header";
import { url } from "../../../api/url";
import { getImage } from "../../../utils/GetImage";
import MenuSetting from "../../../components/menu/MenuSetting";
import Menu from "../../../components/menu/FirstMenu";
import UploadImage from "../../../components/UploadSimpleImage";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import MobileHeader from "../../../components/header/mobile/SimpleHeader";
import RedirectLogin from "../../../utils/RedirectLogin";
import EcoLabelForm from "../../../components/supplier/your_shop/EcoLabelForm";
import HandmadeLabelForm from "../../../components/supplier/your_shop/HandmadeLabelForm";
import SocialMediaCustom from "../../../components/supplier/your_shop/SocialMediaCustom";
import MoreAboutYou from "../../../components/supplier/your_shop/MoreAboutYou";

const { TextArea } = Input;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />;

const YourShop = () => {
  const [image, setImage] = useState("");
  const [user, setUser] = useState({});
  const [isChange, setIsChange] = useState(false);
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [newImage, setNewImage] = useState(false);
  const [picture, setPicture] = useState("");
  const [update, setUpdate] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [newAboutImages, setNewAboutImages] = useState([]);
  const [initialAboutImagesList, setInitialAboutImagesList] = useState([]);
  const [fileListImages, setFileListImages] = useState({});
  const [loadSave, setLoadSave] = useState(false);

  const { t } = useTranslation();

  const checkYoutubeUrl = () => {
    if (user.about && user.about.video1) {
      for (let i = 0; i < 3; i++) {
        const url = user.about[`video${i + 1}`];
        var p = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
        if (url && !url.match(p)) {
          message.error(t("messages.badYoutubeVideo", { nb: i + 1 }), 10);
          return false;
        }
      }
      return true;
    } else return true;
  };

  const updateUser = async (userP) => {
    if (checkYoutubeUrl()) {
      let imgAboutToSave = false;
      if (fileListImages.fileList && fileListImages.fileList.length !== initialAboutImagesList.length) {
        imgAboutToSave = fileListImages.fileList.map((img) => {
          return img.base64;
        });
      }

      let newUser = user;
      if (userP) newUser = userP;

      const res = await fetch(`${url}/user/update/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          user: newUser,
          newImage,
          picture: image,
          imgAboutToSave,
        }),
      });
      if (res.status === 200) {
        setNewImage(false);
        setIsChange(false);
        setLoadSave(false);
        message.success(t("messages.successfullyUpdated"));
      } else if (res.status === 406) {
        setLoadSave(false);
        message.error(t("messages.successfullyUpdated"));
      } else {
        setLoadSave(false);
        message.error(t("messages.error"));
      }
    } else setLoadSave(false);
  };

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();
  }, [idAccount, secretId, token]);

  useEffect(() => {
    if (!user._id) getUser();
  }, []);

  useEffect(() => {
    if (!newImage && image) setNewImage(true);
  }, [image]);

  useEffect(() => {}, [picture]);

  useEffect(() => {}, [update]);

  const getUser = async () => {
    if (!user._id) {
      const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
      });
      if (res.status === 200) {
        const resJson = await res.json();
        if (resJson) {
          setUser(resJson.data);
          setPicture(resJson.data?.profile_picture);
        }
      }
    }
  };

  return (
    <div className="page">
      <Prompt when={isChange} message={t("yourShopSupplier.1")} />
      <RedirectLogin />
      {user && user.email && (
        <div className="page-start">
          {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
          <div
            style={
              windowSize > 1150
                ? { alignItems: "flex-start", justifyContent: "flex-start", paddingTop: "0px" }
                : {
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "20px",
                    paddingTop: "30px",
                  }
            }
            className={"column-scrolling shop-supplier"}
          >
            {windowSize > 1150 ? (
              <div className="header">
                <div
                  className="row"
                  style={
                    windowSize > 1150
                      ? { width: "100%", marginBottom: "-30px", height: "30px" }
                      : { width: "100%", marginBottom: "-30px", paddingLeft: "50px" }
                  }
                >
                  <h1 className="title-bold montserrat">{t("yourStore")}</h1>
                </div>
                <div style={{ marginLeft: "0px", marginTop: "35px" }} className="divider-horizontal" />
              </div>
            ) : (
              <MobileHeader nameHeader={"SETTINGS"} location={t("yourShopSupplier.2")} />
            )}

            <div
              className="row-wrap"
              style={{
                width: "100%",
                paddingTop: windowSize > 1150 ? "110px" : "40px",
                alignItems: "flex-start",
              }}
            >
              {windowSize > 1150 && <MenuSetting user={user} location={"your-shop-supplier"} />}

              <div
                className="column"
                style={
                  windowSize > 1320
                    ? { width: "calc(100% - 350px)", marginLeft: "30px" }
                    : { width: "100%", marginTop: "30px" }
                }
              >
                {/* SHOP INFORMATIONS */}

                <div
                  id="content"
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    width: "95%",
                    padding: "15px 20px 30px 20px",
                    marginTop: "0px",
                  }}
                  className="column card"
                >
                  <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                    {t("yourShopSupplier.3")}
                  </h1>
                  <div
                    id="demo-step-2"
                    style={{
                      alignItems: "flex-start",
                      marginTop: "10px",
                      width: "100%",
                      flexDirection: windowSize > 1150 ? "row" : "column",
                    }}
                    className="row"
                  >
                    <div className="column">
                      {user && user.email && user._id && picture && (
                        <UploadImage image={picture} setImage={setImage} />
                      )}
                      {user && user.email && user._id && !picture && (
                        <UploadImage image={""} setImage={setImage} />
                      )}
                      <button
                        style={{ width: "150px" }}
                        className="button-orange"
                        onClick={() => document.getElementById("upload-profile-image")?.click()}
                      >
                        {t("upload")} photo
                      </button>
                    </div>
                    <div
                      style={
                        windowSize > 1150
                          ? { marginLeft: "20px", width: "80%" }
                          : { width: "100%", marginTop: "20px" }
                      }
                      className="column"
                    >
                      <p style={{ marginBottom: "5px" }} className="text-bold">
                        {t("yourShopSupplier.4")}
                      </p>
                      <Input
                        className="border-red"
                        maxLength={100}
                        defaultValue={user && user.shop && user.shop.name}
                        style={{ marginBottom: "20px", width: "100%", minHeight: "40px" }}
                        placeholder={t("yourShopSupplier.5")}
                        onChange={(e) => {
                          if (user && user.shop) {
                            let stockUser = user;
                            stockUser.shop.name = e.target.value?.trim();
                            setUser(stockUser);
                            if (!isChange) setIsChange(true);
                          }
                        }}
                      />
                      <div style={{ height: "140px", width: "100%" }}>
                        <TextArea
                          maxLength={450}
                          defaultValue={user && user.shop && user.shop.describe}
                          style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}
                          onChange={(e) => {
                            if (user && user.shop) {
                              let stockUser = user;
                              stockUser.shop.describe = e.target.value;
                              setUser(stockUser);
                              if (!isChange) setIsChange(true);
                            }
                          }}
                          placeholder={t("yourShopSupplier.6")}
                          className="describe-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* SOCIAL MEDIA */}

                <div
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    width: "95%",
                    padding: "15px 20px 30px 20px",
                    marginTop: "30px",
                  }}
                  className="column card"
                >
                  <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                    {t("yourShopSupplier.7")}
                  </h1>

                  <SocialMediaCustom
                    setUser={setUser}
                    user={user}
                    windowSize={windowSize}
                    updateUser={updateUser}
                  />
                </div>

                {/* SOCIAL MEDIA */}

                <div
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    width: "95%",
                    padding: "15px 20px 30px 20px",
                    marginTop: "30px",
                  }}
                  className="column card"
                >
                  <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                    {t("yourShopSupplier.8")}
                  </h1>
                  <MoreAboutYou
                    setFileListImages={setFileListImages}
                    fileListImages={fileListImages}
                    setInitialAboutImagesList={setInitialAboutImagesList}
                    setNewAboutImages={setNewAboutImages}
                    isChange={isChange}
                    setIsChange={setIsChange}
                    setUser={setUser}
                    user={user}
                    windowSize={windowSize}
                    updateUser={updateUser}
                  />
                </div>

                {/* CERTIFICATION */}

                <div
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    width: "95%",
                    padding: "15px 20px 30px 20px",
                    marginTop: "30px",
                  }}
                  className="column card"
                >
                  <h1 style={{ fontSize: "22px", color: "#606060" }} className="text-bold">
                    {t("yourShopSupplier.9")}
                  </h1>
                  {/*
                                <DiscoverLabel/>
                                */}

                  <EcoLabelForm
                    setUser={setUser}
                    user={user}
                    windowSize={windowSize}
                    updateUser={updateUser}
                  />
                  <HandmadeLabelForm
                    setUser={setUser}
                    user={user}
                    windowSize={windowSize}
                    updateUser={updateUser}
                  />
                </div>
              </div>
            </div>
            {(isChange || newImage) && (
              <div
                className="button-absolute orange-back"
                onClick={() => {
                  if (!loadSave) {
                    setLoadSave(true);
                    updateUser();
                  }
                }}
              >
                {loadSave ? <Spin indicator={antIcon} /> : t("save")}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default YourShop;
