import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Divider, Tooltip } from "antd";

import linkImg from "../../images/icons/link.svg";
import facebookImg from "../../images/icons/facebook.svg";
import linkedinImg from "../../images/icons/linkedin.svg";
import whatsappImg from "../../images/icons/whatsapp.svg";
import validCopyImg from "../../images/icons/valid_copy.svg";

const Social = ({ article }) => {
  const [redirect, setRedirect] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  function copyLink() {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }

  return (
    <div style={{ height: "30px", marginTop: "30px" }} className="width-100">
      {redirect && <Redirect to={redirect} />}
      <div style={{ justifyContent: "space-between" }} className="row-wrap width-100">
        <div className="row">
          {article.tags.map((tag) => (
            <p onClick={(e) => setRedirect(`/blog?tag=${tag}`)} className="blog-tag">
              {tag}
            </p>
          ))}
        </div>
        <div style={{ margin: "5px" }} className="row">
          <Tooltip title="Copied!" open={isCopied}>
            <img
              className="pointer"
              alt="copy article url"
              onClick={copyLink}
              style={{ width: "35px", marginRight: "10px" }}
              src={isCopied ? validCopyImg : linkImg}
            />
          </Tooltip>

          <a
            className="pointer"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
          >
            <img style={{ width: "30px" }} src={facebookImg} alt="facebook share" />
          </a>
          <a
            className="pointer"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
            target="_blank"
            href={`http://www.linkedin.com/shareArticle?url=${window.location.href}`}
          >
            <img style={{ width: "30px" }} src={linkedinImg} alt="linkedin share" />
          </a>
          <a
            className="pointer"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
            target="_blank"
            href={`whatsapp://send?text=${window.location.href}`}
            data-action="share/whatsapp/share"
          >
            <img style={{ width: "30px" }} src={whatsappImg} alt="whatsapp share" />
          </a>
        </div>
      </div>
      <Divider style={{ marginTop: "10px" }} />
    </div>
  );
};

export default Social;
