import React from "react";

import orderIcon from "../../images/illustrations/order.svg";
import inventoryIcon from "../../images/illustrations/inventory.svg";
import { useTranslation } from "react-i18next";

const HeaderContent = ({ windowSize, handleClickPush, productSelection }) => {
  const { t } = useTranslation();

  return (
    <div className="card width-100" style={{ boxShadow: "none", padding: "15px 5vw 0 20px", minHeight: '230px' }}>
      <div className="row-wrap space-between width-100">
        <div
          style={{ maxWidth: windowSize > 1150 && "50vw", minWidth: "280px", marginRight: "20px" }}
          className="column"
        >
          <p style={{ marginBottom: "10px" }} className="title-bold">
            {t("importList.1")}
          </p>
          <ul>
            <li>{t("importList.2")}</li>
            <li>{t("importList.3")}</li>
            <li>{t("importList.4")}</li>
          </ul>
        </div>
        <div className="row">
          <img src={orderIcon} alt="order" style={{ height: "90px", marginRight: "15px" }} />
          <img src={inventoryIcon} alt="order" style={{ height: "90px" }} />
        </div>
      </div>
      <button
        onClick={handleClickPush}
        className="button big orange-back"
        style={{ margin: "5px 0 20px 0" }}
      >
        {productSelection.length ? t("importListPage.13") : t("importListPage.9")}
      </button>
    </div>
  );
};

export default HeaderContent;
