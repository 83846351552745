import React from "react";
import { Tooltip } from "antd";

import tooltipIcon from "../../images/icons/toolip.png";

const TooltipComponent = ({ text }) => {
  return (
    <Tooltip style={{ padding: "10px", height: "auto" }} title={<div>{text}</div>}>
      <img
        src={tooltipIcon}
        alt="url information"
        style={{ height: "20px", marginBottom: "10px", marginLeft: "10px" }}
      />
    </Tooltip>
  );
};

export default TooltipComponent;
