export const coursesId = {
  supplier: {
    course1:
      process.env.REACT_APP_MODE_ENV === "true" ? "632a0d7943dac91a2afaf6a7" : "632a0e4713ce6eb1eb7512a2",
    course2:
      process.env.REACT_APP_MODE_ENV === "true" ? "63430646886af189eccd1deb" : "6343028b7a7c407726b699fd",
      course3:
        process.env.REACT_APP_MODE_ENV === "true" ? "6345cbf2ef14ea80ceead39d" : "6345cbd0a20339c195b2787a",
  },
};
