import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LogoImg from "../../../../images/logo.svg";
import { Link } from "react-router-dom";
import MobileMenu from "../../../../components/menu/mobile/FirstMenu";
import { Helmet } from "react-helmet";
import Footer from "../../../../components/menu/Footer";

import img1 from "../../../../images/landing/img1.svg";
import img2 from "../../../../images/landing/img2.svg";
import img3 from "../../../../images/landing/img3.svg";
import img4 from "../../../../images/landing/img4.svg";
import img5 from "../../../../images/landing/img5.svg";
import img6 from "../../../../images/landing/img6.svg";
import { routes } from "../../../../routes";

const BecomeSupplier1 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { t, i18n } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.hide();

    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={
        windowSize > 1150
          ? { paddingLeft: "15vw", paddingRight: "15vw", alignItems: "center", overflowX: "hidden" }
          : { alignItems: "center" }
      }
      className="page"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Great Story of Alex, a 01supply supplier | 01supply</title>
        <link rel="canonical" href="https://app.01supply.com/become-supplier" />
        <meta
          name="description"
          content="Find out how to become a 01 supply supplier to work with resellers and boost sales"
        />
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "The Great Story of Alex, a 01supply supplier",
          "image": [
            "https://app.01supply.com/static/media/img2.1b652f32.svg"
           ],
          "datePublished": "2022-09-23",
          "dateModified": "2022-09-23",
          "author": [{
              "@type": "Person",
              "name": "Diego Huberty",
              "jobTitle": "Editor in Chief"
            }],
             "publisher":
             {
              "name": "01supply",
              "url": "https://app.01supply.com/"
             }
      }
    
            `}</script>
      </Helmet>
      {/* HEADER*/}
      {windowSize < 1150 && <MobileMenu />}
      {windowSize > 1150 && (
        <div className="row" style={{ width: "100%", justifyContent: "center", margin: "2vw" }}>
          <div style={{ display: "flex", width: "80%", height: "3vw" }}>
            {/* logo */}
            <div style={{ flex: "1", display: "flex", float: "left", height: "3vw" }}>
              <a href="/">
                <img
                  alt="logo 01supply"
                  style={{ width: "200px", height: "auto", marginRight: "5vw", justifyContent: "left" }}
                  src={LogoImg}
                />
              </a>
            </div>

            {/* menu */}
            <div
              style={{
                flex: "1",
                display: "flex",
                float: "right",
                height: "3vw",
                justifyContent: "flex-end",
                placeContent: "space-between",
              }}
            >
              <a
                style={{ fontSize: "16px", fontWeight: "600", transform: "translateY(25%)" }}
                href="https://01supply.com"
                className="title-300 black"
              >
                Home
              </a>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to={routes.joinUs(i18n.language).path + "?supplier"}
                className="title-300 black"
              >
                {t("suppliers")}
              </Link>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to="/supplier-pricing"
                className="title-300 black"
              >
                Pricing
              </Link>
              <Link
                style={{ fontSize: "16px", transform: "translateY(25%)" }}
                to={routes.marketplace(i18n.language).path}
                className="title-300 black"
              >
                Marketplace
              </Link>
              <Link
                to={routes.joinUs(i18n.language).path + "?login"}
                className="button orange-back"
                style={{ height: "2vw", transform: "translateY(20%)" }}
              >
                LOGIN
              </Link>
            </div>
          </div>
        </div>
      )}

      <div className="column center" style={windowSize > 1150 ? { width: "70vw" } : { width: "95vw" }}>
        <img alt="the great story of alex" src={img1} style={{ width: "80%", height: "auto" }} />
        <img alt="here is alex" src={img2} style={{ width: "100%", height: "auto", marginTop: "60px" }} />
        <h3
          style={{ fontSize: "20px", marginTop: "50px", marginBottom: "30px" }}
          className="title-roboto-300"
        >
          She is crafting her own jewels
        </h3>
        <h3 className="title-roboto-bold">
          And she wants to make
          <span
            style={{ marginLeft: "9px", color: "#6fd979", textDecoration: "underline" }}
            className="green"
          >
            More Sales
          </span>
        </h3>
        <img alt="think statistic" src={img3} style={{ width: "80%", height: "auto", marginTop: "60px" }} />
        <h2
          style={{ fontSize: "20px", marginTop: "50px", marginBottom: "30px" }}
          className="title-roboto-300"
        >
          {"->"} Will I be able to run my business on the long-term ?
          <br />
          <br />
          {"->"} How can I get more customer ?
          <br />
          <br />
          {"->"} When am I going to reach the next level for my business ?
        </h2>
        <p style={{ marginBottom: "30px", color: "#e83536" }} className="title-roboto-bold">
          So she tried something different :
        </p>
        <p style={{ marginBottom: "30px", textAlign: "center" }} className="title-roboto-bold">
          One year ago, she signed up on 01supply ...
        </p>
        <img
          alt="alt work illustartion"
          src={img4}
          style={{ width: "80%", height: "auto", marginTop: "60px" }}
        />
        <p
          style={{ marginBottom: "30px", textAlign: "center", fontSize: "20px", marginTop: "25px" }}
          className="title-roboto"
        >
          ... and she uploaded her jewels on the app
        </p>
        <p style={{ marginBottom: "30px", color: "#e83536" }} className="title-roboto-bold">
          This is when things started to change.
        </p>
        <h4 style={{ fontSize: "20px", textAlign: "center" }} className="title-roboto">
          Resellers around the world started
          <span style={{ marginLeft: "5px", fontSize: "20px" }} className="text-roboto-bold">
            to bring her
          </span>
          <span
            style={{ marginLeft: "5px", color: "#6fd979", textDecoration: "underline", fontSize: "20px" }}
            className="text-roboto-bold green"
          >
            sales !
          </span>
        </h4>
        <img
          alt="growth money supplier"
          src={img5}
          style={{ width: "80%", height: "auto", marginTop: "60px" }}
        />
        <h1 style={{ fontSize: "20px", textAlign: "center", marginTop: "50px" }} className="title-roboto">
          The more resellers started working with her, the more her business became Strong
          <span
            style={{
              marginLeft: "7px",
              color: "#6fd979",
              textDecoration: "underline",
              fontSize: "20px",
              marginRight: "7px",
            }}
            className="text-roboto-bold green"
          >
            Strong
          </span>
          and
          <span
            style={{ marginLeft: "7px", color: "#6fd979", textDecoration: "underline", fontSize: "20px" }}
            className="text-roboto-bold green"
          >
            Profitable
          </span>
          .
        </h1>
        <img
          alt="good home and family"
          src={img6}
          style={{ width: "80%", height: "auto", marginTop: "60px" }}
        />
        <p style={{ marginTop: "50px", textAlign: "center" }} className="title-roboto-bold">
          Don’t miss the opportunity to be the next Alex !
        </p>
        <Link
          style={{ marginBottom: "30px", marginTop: "30px" }}
          className="button big"
          to={routes.joinUs(i18n.language).path + "?supplier"}
        >
          Join us now
        </Link>
        <Link to={routes.becomeSupplier2(i18n.language).path} className="text-gradient">
          (2/3) How does 01supply work ?
        </Link>
      </div>

      <Footer windowSize={windowSize} />
    </div>
  );
};

export default BecomeSupplier1;
