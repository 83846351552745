import React from "react";
import { useTranslation } from "react-i18next";
import { InputNumber, message } from "antd";

const Pricing = ({ setProduct, product }) => {
  const { t } = useTranslation();
  const handleDisabledVariantBool = () => {
    if (product.variantBool) {
      setProduct({ ...product, variantBool: false });
      message.warning(t("messages.variantsDisabled"));
    }
  };
  return (
    <div id="price" style={{ width: "100%", marginRight: "30px" }} className="card">
      <p style={{ marginBottom: "15px" }} className="title-bold grey">
        {t("pricing")}
      </p>
      <div style={{ width: "100%" }} className="row">
        <div style={{ width: "45%", marginRight: "15px" }} className="column">
          <p style={{ marginBottom: "5px" }} className="text-bold">
            {t("price")}
          </p>
          <div style={{ width: "100%" }} onClick={handleDisabledVariantBool}>
            <InputNumber
              min={0.5}
              max={49000}
              style={{ width: "100%" }}
              disabled={product.variantBool}
              onChange={(value) => setProduct({ ...product, price: value })}
              value={product.price}
            />
          </div>
        </div>
        <div style={{ width: "45%", marginLeft: "15px" }} className="column">
          <p style={{ marginBottom: "5px" }} className="text-bold">
            {t("comparedPrice")}
          </p>
          <div style={{ width: "100%" }} onClick={handleDisabledVariantBool}>
            <InputNumber
              min={product.price}
              max={49000}
              style={{ width: "100%" }}
              disabled={product.variantBool}
              onChange={(value) => setProduct({ ...product, comparedPrice: value })}
              value={product.comparedPrice}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
