import React, { useState, useEffect } from "react";
import { message, Button } from 'antd';
import { t } from "i18next";

import {Modal} from '../../wrappers'

const DeleteOrder = ({
  order,
  user,
  token,
  url,
  header,
  setDeleteOrder,
  setOrders,
  orders
}) => {
    const [typeAccount] = useState(localStorage.getItem('typeAccountCreatosell'))

    const changeEtsyOrderStatus = async (status) => {

      const res = await fetch(`${url}/order/etsy/change-status/${user._id}/${user.secret_id}/${order._id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
            newStatus: status,
            declineReason: order.decline_reason || ''
        }),
      });
      if(res.status === 200){
        let newOrders = [...orders]
        newOrders.map((orderP, i) => {
          if(orderP._id === order._id){
            newOrders[i].status = status
            setOrders([...newOrders])
            message.success(t('messages.orderMove'))
          }
        })
        setDeleteOrder(false)
      } else message.error(t('messages.error'))

    }

  return (
      <Modal name="DELETE ORDER" className="modal-account"  visible={true} onCancel={() => setDeleteOrder({})}
      footer={[
          <Button  key="submit" type="primary" onClick={() => setDeleteOrder({})}>
              {t('cancel')}
          </Button>
          ]}>
          <div style={{alignItems: 'center'}} className="column">
              <p className="title">
                  {t('cancelOrder')} {`${typeAccount === "seller" && order.shopify_order_number ? order.shopify_order_number : t('components.modal.34')}`}
              </p>
              <p style={{marginBottom: '20px', marginTop: '40px'}} className="title-300">
                {order.quantity}x {order.product && order.product.shopify_name ? order.product.shopify_name : order.product && order.product.name}
                <span className="title">
                    {order.variant && '- '+order.variant}
                </span>
              </p>
              <button style={{width: '90%', height: '35px'}} onClick={() => changeEtsyOrderStatus('close')} className="button-red">
              {t('components.modal.33')}
              </button>
          </div>
      </Modal>
  );
};

export default DeleteOrder; 
