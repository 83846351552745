import Raact from "react";
import { useTranslation } from "react-i18next";
import { Radio, Switch, Space } from "antd";

const DeletedProduct = ({ user, handleUpdateSetting }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "30px" }} className="column">
      <p className="title-500 start">{t("updateAlerts.title-3")}</p>
      <Radio.Group
        style={{ marginBottom: "15px", marginTop: "15px" }}
        className="blue"
        defaultValue={
          user?.updateAlerts?.deletedProduct?.setQuantity
            ? "setQuantity"
            : user?.updateAlerts?.deletedProduct?.setDraft
            ? "unpublish"
            : "notSetQuantity"
        }
        onChange={(e) =>
          handleUpdateSetting({
            updateAlerts: {
              ...user.updateAlerts,
              deletedProduct:
                e.target.value === "unpublish"
                  ? {
                      ...user?.updateAlerts?.deletedProduct,
                      setDraft: true,
                      setQuantity: false,
                    }
                  : {
                      ...user?.updateAlerts?.deletedProduct,
                      setQuantity: e.target.value === "notSetQuantity" ? false : true,
                      setDraft: false,
                    },
            },
          })
        }
      >
        <Space direction="vertical">
          <Radio value="notSetQuantity">{t("updateAlerts.doNothing")}</Radio>
          <Radio value="setQuantity">{t("updateAlerts.setQuantityToZero")}</Radio>
          <Radio value="unpublish">{t("updateAlerts.unpublishProduct")}</Radio>
        </Space>
      </Radio.Group>

      <div className="row">
        <Switch
          defaultChecked={user?.updateAlerts?.deletedProduct?.notify}
          style={{ marginRight: "10px" }}
          onChange={(checked) => {
            handleUpdateSetting({
              updateAlerts: {
                ...user.updateAlerts,
                deletedProduct: {
                  ...user?.updateAlerts?.deletedProduct,
                  notify: !user?.updateAlerts?.deletedProduct?.notify,
                },
              },
            });
          }}
        />
        <p style={{ fontSize: "15px" }} className="text montserrat">
          {t("updateAlerts.notifyMe")}
        </p>
      </div>
    </div>
  );
};

export default DeletedProduct;
