import React from "react";
import { useTranslation } from "react-i18next";

import StarImg from "../../images/star.svg";

import "./style.scss"

const Advices = () => {
  const { t } = useTranslation();
  const advices = [
    {
      name: "Hugo",
      text: (
        <p>
          {t("resellerHome.advices.1")}
          <br />
          {t("resellerHome.advices.18")}
          <br />
          {t("resellerHome.advices.2")}
        </p>
      ),
    },
    {
      name: "Jenelle",
      text: (
        <p>
          {t("resellerHome.advices.2")}
          <br />
          {t("resellerHome.advices.3")}
          <br />
          <br />
          {t("resellerHome.advices.4")}
        </p>
      ),
    },
    {
      name: "Diego",
      text: (
        <p>
          {t("resellerHome.advices.6")}
          <br />
          {t("resellerHome.advices.7")}
          <br />
          {t("resellerHome.advices.8")}
        </p>
      ),
    },
    {
      name: "Music Awakening store",
      text: (
        <p>
          {t("resellerHome.advices.9")}
          <br />
          <br />
          {t("resellerHome.advices.10")}
          <br />
          {t("resellerHome.advices.11")}
        </p>
      ),
    },
    {
      name: "Hanelle",
      text: (
        <p>
          {t("resellerHome.advices.12")}
          <br />
          {t("resellerHome.advices.13")}
        </p>
      ),
    },
    {
      name: "Julien",
      text: (
        <p>
          {t("resellerHome.advices.14")}
          <br />
          <br />
          {t("resellerHome.advices.15")}
        </p>
      ),
    },
    {
      name: "Hephaistos Fabrique",
      text: (
        <p>
          {t("resellerHome.advices.16")}
          <br />
          <br />
          {t("resellerHome.advices.17")}
        </p>
      ),
    },
  ];

  return (
    <div
      className="column home-container"
      style={{
        marginTop: "80px",
        marginBottom: "60px",
        justifyContent: "center",
      }}
    >
      <p style={{ fontSize: "20px", marginBottom: "15px" }} className="text-bold width-100 center">
        {t("resellerHome.advices.title")}
      </p>
      <div className="advices-container">
        {advices.map((advice) => (
          <div className="card">
            <p style={{ color: "#585858" }} className="title montserrat-800">
              {advice.name}
            </p>
            <img src={StarImg} alt="rate" style={{ width: "160px", margin: "15px 0" }} />
            {advice.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advices;
