import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { Input, message } from "antd";

import { header } from "../../../api/header";
import { url } from "../../../api/url";
import Menu from "../../../components/menu/FirstMenu";
import MobileMenu from "../../../components/menu/mobile/FirstMenu";
import { updateUser } from "../../../services/updateUser";
import UploadImage from "../../../components/UploadSimpleImage";

import "react-phone-input-2/lib/material.css";

const { TextArea } = Input;

const SupplierOnboarding3 = () => {
  const [user, setUser] = useState({});
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));
  const [isNext, setIsNext] = useState(false);
  const [image, setImage] = useState("");

  const { t } = useTranslation();

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const nextCondition = user.shop?.name;

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
  };

  const sendUpdateUser = () => {
    const picture = image?.base64 ? image?.base64 : user.picture ? user.picture : "";
    updateUser(
      { user: { ...user, picture } },
      idAccount,
      secretId,
      token,
      () => setIsNext(true),
      (res) => res.status === 405 && message.error("This store name is not available")
    );
  };

  return (
    <div className="page">
      {isNext && <Redirect to="/supplier-onboarding-step-4" />}
      <div className="page-start">
        {windowSize > 1150 ? <Menu location={"your-informations"} /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "0px",
                }
              : {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingBottom: "200px",
                }
          }
          className="column-scrolling"
        >
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              marginBottom: windowSize > 1150 ? "50px" : "0",
              height: "80px",
            }}
            className="header"
          >
            <h1 className="title-bold montserrat">{t("onboarding.1")} (3/5)</h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>
          <div
            className="row-wrap"
            style={{
              width: "100%",
              paddingTop: windowSize > 1150 ? "110px" : "40px",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div
              id="content"
              style={{ marginLeft: "0", width: "90%", padding: "40px 25px 30px 25px", marginTop: "0px" }}
              className="column center card"
            >
              <div className="column" style={{ width: "90%" }}>
                <h1 style={{ fontSize: "22px", color: "#606060", marginBottom: "0" }} className="text-bold">
                  {t("yourStore")}
                </h1>
                <p style={{ marginBottom: "20px" }} className="title-300">
                  {t("onboarding.18")}
                </p>
              </div>
              <div
                id="demo-step-2"
                style={{
                  alignItems: "flex-start",
                  marginTop: "10px",
                  width: "90%",
                  flexDirection: windowSize > 1150 ? "row" : "column",
                }}
                className="row"
              >
                <div key={user.picture || ""} className="column">
                  <UploadImage image={user.picture || image} setImage={setImage} />
                  <button
                    style={{ width: "150px" }}
                    className="button-orange"
                    onClick={() => document.getElementById("upload-profile-image")?.click()}
                  >
                    {t("upload")} photo
                  </button>
                </div>
                <div
                  style={
                    windowSize > 1150
                      ? { marginLeft: "20px", width: "80%" }
                      : { width: "100%", marginTop: "20px" }
                  }
                  className="column"
                >
                  <p style={{ marginBottom: "5px" }} className="text-bold">
                    {t("onboarding.19")}
                  </p>
                  <Input
                    className="border-red"
                    maxLength={100}
                    value={user.shop?.name}
                    style={{ marginBottom: "20px", width: "100%", minHeight: "40px" }}
                    placeholder={t("onboarding.placeholders.5")}
                    onChange={(e) => {
                      setUser({ ...user, shop: { ...user.shop, name: e.target.value } });
                    }}
                  />
                  <div style={{ height: "140px", width: "100%" }}>
                    <TextArea
                      maxLength={450}
                      value={user.shop?.describe}
                      style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}
                      onChange={(e) => {
                        setUser({ ...user, shop: { ...user.shop, describe: e.target.value } });
                      }}
                      placeholder={t("onboarding.placeholders.6")}
                      className="describe-input"
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "35px", marginBottom: "20px" }} className="row">
                <button
                  onClick={sendUpdateUser}
                  style={{ marginRight: "10px" }}
                  className="button big grey disable"
                >
                  {t("onboarding.5")}
                </button>
                {nextCondition && (
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={sendUpdateUser}
                    className="button big orange-back"
                  >
                    {t("onboarding.6")}
                  </button>
                )}
              </div>
              <Link to="/supplier-onboarding-step-2" className="text blue underline pointer">
                {t("onboarding.7")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierOnboarding3;
