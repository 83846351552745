import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./style.scss";

const CoachingCard = ({ imgSrc, description, content, isByMonth, price, link, isAlreadyBuy }) => {
  const { t } = useTranslation();
  return (
    <div style={{ flexDirection: "row" }} className="card">
      <div style={{ width: "63%", minWidth: "300px" }} className="column">
        <img style={{ width: "150px", marginBottom: "20px" }} src={imgSrc} alt="coaching" />
        <p>{description}</p>
        <p style={{ marginTop: "10px" }} className="text grey">
          {content}
        </p>
      </div>
      <div style={{ width: "30%" }} className="column">
        <p className="title">
          ${price}
          {isByMonth && (
            <span className="grey" style={{ fontSize: "16px" }}>
              /{t("months")}
            </span>
          )}
        </p>
        <p style={{ marginBottom: "20px" }} className="grey">
          {isByMonth ? t("resellerCoaching.recuringCharge") : t("resellerCoaching.oneTimePayment")}
        </p>
        {isAlreadyBuy ? (
          <button className="button big button-disable-orange">{t("alreadyBuy")}</button>
        ) : (
          <Link to={link} className="button big orange-back">
            {t("learnMore")}
          </Link>
        )}
      </div>
    </div>
  );
};

export default CoachingCard;
