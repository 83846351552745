import React from "react";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

import Summary from "../../../../components/blog/Summary";

import coverImg from "../../../../images/blog/global/article26/dropshipping-avec-des-produits-etsy.jpg";
import importEtsyshopify from "../../../../images/blog/global/article26/importer-des-produits-etsy-sur-shopify-dropshipping.jpg";
import highticketEtsy from "../../../../images/blog/global/article26/highticket-etsy.jpg";

const Content = () => {
  return (
    <div className="column width-100">
      <h1 className="title montserrat" style={{ fontSize: "22px", fontWeight: "800", marginTop: "20px" }}>
        Faire du dropshipping avec des produits Etsy
      </h1>
      <p className="text-300">30 novembre 2022 - 4 minutes de lecture</p>
      <img src={coverImg} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Faire du dropshipping avec des produits Etsy" />
      <p style={{ marginTop: "15px", marginBottom: "20px" }} >
        Vous en avez <b>marre des produits Aliexpress</b> en dropshipping? Vous souhaitez vous démarquer de vos concurrents et proposer des produits uniques sur votre boutique en ligne?
        Si tel est votre cas, Etsy est la plateforme qu’il vous faut pour trouver vos <b>nouveaux fournisseurs en dropshipping</b>.
      </p>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#etsy-cest-quoi" title="Qu’est-ce que Etsy?" />
            <Anchor.Link href="#trouver-produits-dropshipping" title="Où trouver des produits pour son site en dropshipping?" />
            <Anchor.Link href="#importer-produits-etsy-shopify" title="Comment importer des produits Etsy sur ma boutique Shopify?" />
            <Anchor.Link href="#meilleurs-produits-dropshipping" title="Quels sont les produits qui se vendent le mieux? " />
            <Anchor.Link href="#produits-etsy-probleme" title="Le gros problème des produits Etsy">
              <Anchor.Link href="#vendre-highticket" title="Comment vendre en high-ticket?" />
            </Anchor.Link>
            <Anchor.Link href="#dropshipping-produits-etsy" title="Faire du dropshipping avec des produits Etsy" />
          </Anchor>
        }
      />
      <h2 id="etsy-cest-quoi">Qu’est-ce que Etsy?
      </h2>
      <p style={{ marginBottom: "20px" }} >
        Etsy est une marketplace pour les petits créateurs indépendants. Elle leur permet d’obtenir de la visibilité en ligne auprès de consommateurs à travers le monde.
        Etsy gère la gestion d’inventaire, des commandes et des paiements, ce qui en fait une solution pratique pour les créateurs débutants.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Cependant, savez-vous que <b>95% des vendeurs Etsy n’arrivent pas à vivre</b> de leurs créations?
        C’est là où vous intervenez en tant qu’e-commercant car vous pouvez les aider à obtenir la visibilité qu’ils n’ont pas.
        Et c’est à partir de là que peut commencer votre intérêt de les contacter en tant que revendeur en dropshipping.
        Pour eux rien ne change, si ce n’est qu’ils auront plus de ventes.
      </p>
      <h2 id="trouver-produits-dropshipping">Où trouver des produits pour son site en dropshipping?
      </h2>
      <p style={{ marginBottom: "20px" }} >
        Etsy est une superbe plateforme pour trouver vos futurs fournisseurs et vous démarquer de la concurrence.
        En effet, les <b>produits Etsy</b> ne sont pas trouvables sur Aliexpress car ce sont directement les vendeurs Etsy qui les créent.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Voici quelques <b>avantages à utiliser Etsy pour trouver des fournisseurs</b> en dropshipping:
      </p>
      <ul style={{ marginBottom: "20px" }}>
        <li>
          Produits uniques et de qualité
        </li>
        <li>
          Livraison rapide à votre client
        </li>
        <li>
          Made in France / Europe / USA / UK
        </li>
        <li>
          Facilité de communication
        </li>
        <li>
          Possibilité d’ajouter son logo et de négocier les prix
        </li>
      </ul>
      <h2 id="importer-produits-etsy-shopify">Comment importer des produits Etsy sur ma boutique Shopify?
      </h2>
      <img src={importEtsyshopify} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Importer des produits Etsy sur Shopify dropshipping" />
      <p style={{ marginBottom: "20px" }} >
        Une fois que vous avez trouvé des produits Etsy qui vous intéressent, il est temps de les <b>importer sur votre boutique</b> afin d’obtenir des ventes.
        Pour ce faire, nous vous avons préparé un <a href="/importation-etsy-shopify" className="blue" target="blank_"><u>tutoriel complet sur l’importation de produits Etsy vers Shopify</u></a>.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Pour vendre des produits qui ne vous appartiennent pas, pensez à bien contacter le fournisseur afin d’avoir son autorisation.
      </p>
      <h2 id="meilleurs-produits-dropshipping">Quels sont les produits qui se vendent le mieux?
      </h2>
      <p style={{ marginBottom: "20px" }} >
        Par ordre d’importance, voice les articles qui se vendent le mieux en ligne:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <b>
          <li>
            Mode-Habillement
          </li>
          <li>
            Produits culturels
          </li>
          <li>
            Hygiène-Beauté
          </li>
        </b>
        <li>
          Jeux-Jouets
        </li>
        <li>
          Chaussures
        </li>
        <li>
          Produits Électroniques
        </li>
        <li>
          Alimentation-Boisson
        </li>
        <li>
          Maison-Décoration
        </li>
        <li>
          Bricolage, jardinage
        </li>
      </ol>
      <h2 id="produits-etsy-probleme">Le gros problème des produits Etsy
      </h2>
      <p style={{ marginBottom: "20px" }} >
        Les produits Etsy ont un gros problème: ils sont chers.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Du moins c’est un faux problème auquel beaucoup de débutants font face.
        En effet, il a été prouvé qu’il est <b>2X plus simple de vendre un produit à 100€</b> plutôt que 100 produits à 1€.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Dans le premier cas, vous aurez juste à <b>convaincre une seule personne</b>, tandis que dans le deuxième, vous devrez convaincre 100 personnes.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Vendre un produit haut de gamme a un nom. Cela s’appelle de high-ticket.
      </p>
      <h3 id="vendre-highticket">Comment vendre en high-ticket?
      </h3>
      <img src={highticketEtsy} style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} alt="Highticket avec produits Etsy" />
      <p style={{ marginBottom: "20px" }} >
        Pour vendre en high-ticket, cela demande d’accorder une <b>importance accrue à la présentation du produit</b>.
        Bonne nouvelle: les produits Etsy sont généralement de bonne qualité ce qui va beaucoup vous aider.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Il est important de bien <b>détailler sa description produit</b> en répondant à toutes les questions que le client pourrait se poser.
        Cela peut inclure les matériaux de fabrication, l’origine du produit, ses caractéristiques techniques d’utilisation, les délais de livraison, etc…
        Vous devez être irrépochable sur le détail de ces points.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Enfin, vendre en high-ticket passe par un <b>site qui inspire confiance</b> et qui offre des possibilités de downsell si le client n’a pas les moyens de payer un produit high ticket.
        Par exemple, si vous vendez une statue de décoration d’intérieur à 500€ (high-ticket), pensez à proposer à côté des décorations accessibles à 50-60€.
      </p>
      <p style={{ marginBottom: "20px" }} >
        Ainsi, si le client est séduit par les statues à 60€, il sera plus enclin à venir acheter une statue à 500€.
      </p>
      <h2 id="dropshipping-produits-etsy">Faire du dropshipping avec des produits Etsy
      </h2>
      <p style={{ marginBottom: "20px" }} >
      En conclusion, il est tout à fait possible de sortir des problèmes récurrents du dropshipping (chinois, mauvaise qualité, longue livraison, retours produits,...) 
      en allant chercher des <b>fournisseurs locaux et de qualité sur 01supply ou Etsy</b>.
      </p>
      <p style={{ marginBottom: "20px" }} >
      Au lieu de se baser sur la quantité, vous préférez la qualité. 
      Ainsi, il vous sera possible de faire <b>une vente par semaine en high-ticket</b>, alors que celui qui vend des produits Aliexpress devra faire 20 ventes par semaine 
      pour arriver au même résultat que vous. 
      </p>
      <p style={{ marginBottom: "20px" }} >
        <b>
        Pour importer des produits Etsy, pensez à installer notre extension Chrome qui vous permet en quelques 
        clics d’<a href="https://chrome.google.com/webstore/detail/01supply-import-etsy-prod/iklgfokemnlkheikecfagjhejgdjffbg" target="blank_" className="blue"><u>importer les produits Etsy sur votre boutique Shopify</u></a>.
        </b>
      </p>
    </div>
  );
};

export default Content;
