import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { routes } from "../../routes";
import "./style.scss";

import RegisterModal from "../../components/modals/RegisterModal";

import illustration1 from "../../images/illustrations/boostPresentation/growth_woman.png";
import illustration4 from "../../images/illustrations/boostPresentation/illustration_4.png";
import illustration5 from "../../images/illustrations/boostPresentation/resellers_prospection.svg";
import illustration6 from "../../images/illustrations/boostPresentation/our_boost_agents.svg";
import illustration6Fr from "../../images/illustrations/boostPresentation/our_boost_agents_fr.svg";
import illustration7 from "../../images/illustrations/boostPresentation/handmade_supplier_profile.svg";
import illustration8 from "../../images/illustrations/boostPresentation/roadmap.svg";
import illustration9 from "../../images/illustrations/boostPresentation/reviews.svg";
import illustration10 from "../../images/illustrations/boostPresentation/review_5_stars.svg";
import illustration11 from "../../images/illustrations/boostPresentation/certification.svg";
import illustration12 from "../../images/illustrations/boostPresentation/stars_4.svg";
import logoImg from "../../images/logo.svg";
import boostImg from "../../images/illustrations/boostPresentation/boost.svg";
import arrow1Img from "../../images/illustrations/boostPresentation/arrow1.svg";
import arrow2Img from "../../images/illustrations/boostPresentation/arrow2.svg";

const { Panel } = Collapse;

const BoostPresentation = () => {
  const [isModalRegisterOn, setIsModalRegisterOn] = useState("");
  const [isRedirectToBoost, setIsRedirectToBoost] = useState(false);
  const { t, i18n } = useTranslation();

  const handleAction = () => {
    const isConnected = !!localStorage.getItem("tokenCreatosell");
    if (isConnected) {
      setIsRedirectToBoost(true);
    } else {
      setIsModalRegisterOn("/boost-your-business");
    }
  };

  return (
    <div className="boost">
      {isRedirectToBoost && <Redirect to={routes.supplierGrowthBusiness().path} />}
      {isModalRegisterOn && (
        <RegisterModal isSupplier={true} modalOn={isModalRegisterOn} setModal={setIsModalRegisterOn} />
      )}
      <div className="back-1">
        <div className="column">
          <img src={boostImg} alt="boost business" className="boost-icon" />
          <p style={{ fontSize: "25px" }} className="title montserrat-800">
            {t("boostPresentation.1")} <span className="orange">10x</span> {t("boostPresentation.2")}
          </p>
          <p style={{ marginBottom: "20px" }} className="title">
            {t("boostPresentation.3")} <span className="orange underline">{t("boostPresentation.4")}</span>
          </p>
          <div className="row start">
            <p className="title montserrat-800 percent">
              93 <br /> <span style={{ fontSize: "30px" }}>%</span>
            </p>
            <p style={{ fontSize: "16px", fontWeight: "400" }} className="text montserrat">
              {t("boostPresentation.5")}
              <br />
              {t("boostPresentation.6")}
              <br />
              {t("boostPresentation.7")}
            </p>
          </div>
          <div style={{ marginTop: "30px" }} className="row start">
            <p className="title montserrat-800 percent">
              22 <br /> <span style={{ fontSize: "30px" }}>$</span>
            </p>
            <p style={{ fontSize: "16px", fontWeight: "400" }} className="text montserrat">
              {t("boostPresentation.8")}
              <br />
              {t("boostPresentation.9")}
              <br />
              {t("boostPresentation.10")}
            </p>
          </div>
        </div>
        <img className="illustration-1" src={illustration1} alt="growth my creator sales" />
      </div>
      <div className="back-2">
        <img src={arrow1Img} className="arrow-1" />
        <p style={{ fontSize: "30px" }} className="title montserrat-800 white">
          {t("boostPresentation.11")}
          <br />
          <span className="orange"> 7% </span>
          {t("boostPresentation.12")}
          <br />
          <span className="orange"> {t("with")} $0 </span>
          {t("boostPresentation.13")}
          <br />
          {t("and")} {t("boostPresentation.14")}
        </p>
        <img src={arrow2Img} className="arrow-2" />
      </div>

      <div className="back-3">
        <div className="card-back-3">
          <p style={{ fontSize: "16px" }} className="title-300 italic montserrat center">
            "{t("boostPresentation.15")} <br /> {t("boostPresentation.16")} <br /> {t("boostPresentation.17")}
            <br /> <br />
            {t("boostPresentation.18")}
            <br />
            {t("boostPresentation.19")}
            <br />
            {t("boostPresentation.20")}
            <br />
            <br />
            {t("boostPresentation.21")}
            <br />
            {t("boostPresentation.22")}
            <br />
            {t("boostPresentation.23")}
            <br />
            {t("boostPresentation.24")}"
          </p>
        </div>
      </div>
      <div className="back-4">
        <p style={{ fontSize: "30px", marginBottom: "18px" }} className="title montserrat-800">
          {t("boostPresentation.29")}
        </p>
        <div className="row">
          <img src={illustration4} alt="working with online resellers" className="img4" />
          <div style={{ width: "300px" }} className="column">
            <p style={{ lineHeight: "30px", marginBottom: "20px" }} className="title start">
              {t("boostPresentation.25")}
              <br />
              <span className="orange">{t("boostPresentation.26")}</span>
            </p>
            <p>{t("boostPresentation.27")}</p>
            <p style={{ marginTop: "15px" }}>{t("boostPresentation.28")}</p>
          </div>
        </div>
        <p
          style={{ fontSize: "30px", marginBottom: "18px", marginTop: "50px" }}
          className="title montserrat-800"
        >
          {t("boostPresentation.30")}
        </p>
        <div className="card-back-4">
          <div style={{ width: "280px", marginRight: "20px" }} className="column">
            <p className="title montserrat-800 orange start">{t("boostPresentation.31")}</p>
            <img style={{ width: "180px", marginTop: "20px" }} alt="resellers prospect" src={illustration5} />
          </div>
          <div style={{ width: "280px" }} className="column">
            <p className="text montserrat-800">{t("boostPresentation.32")}</p>
            <p className="text">{t("boostPresentation.33")}</p>
            <p style={{ marginTop: "20px" }} className="text montserrat-800">
              {t("boostPresentation.34")}
            </p>
            <p className="text">{t("boostPresentation.35")}</p>
            <p className="text grey" style={{ marginTop: "20px" }}>
              {t("boostPresentation.36")}
            </p>
          </div>
        </div>
        <div style={{ position: "relative", marginTop: "50px" }}>
          <img
            alt="our boost agents"
            src={i18n.language?.includes("fr") ? illustration6Fr : illustration6}
            className="illustration6"
          />
          <button onClick={handleAction} className="button big orange-back agent-button">
            {t("boostPresentation.37")}
          </button>
        </div>
        <div className="back-5">
          <div style={{ width: "360px", marginRight: "120px" }} className="column start">
            <p style={{ fontSize: "22px" }} className="title montserrat-800 white start">
              <span className="orange">{t("boostPresentation.38")} </span> {t("boostPresentation.39")}
              <br />
              {t("boostPresentation.40")}
              <br />
              <span className="orange">{t("boostPresentation.41")} </span>
              {t("boostPresentation.42")}
              <br />
              {t("boostPresentation.43")}
            </p>
            <p style={{ marginTop: "30px" }} className="text white italic">
              {t("boostPresentation.44")}
              <br />
              <br />
              {t("boostPresentation.45")}
              <br />
              <br />
              {t("boostPresentation.46")}
            </p>
          </div>
          <div style={{ alignItems: "center" }} className="column">
            <img src={illustration7} alt="handmade supplier profile" className="illustration7" />
            <div className="tiret" />
            <p className="text italic white">{t("boostPresentation.47")}</p>
          </div>
        </div>
        <p
          style={{ fontSize: "30px", marginBottom: "18px", marginTop: "50px" }}
          className="title montserrat-800"
        >
          {t("boostPresentation.48")}
        </p>

        <p className="title-400">{t("boostPresentation.49")}</p>
        <p className="text grey">{t("boostPresentation.50")}</p>
        <div className="row roadmap1">
          <div style={{ width: "150px", marginRight: "240px" }} className="column">
            <p className="text montserrat-800">{t("boostPresentation.51")}</p>
            <p className="text">{t("boostPresentation.52")}</p>
          </div>
          <div style={{ width: "200px" }} className="column">
            <p className="text montserrat-800">{t("boostPresentation.53")}</p>
            <p className="text">{t("boostPresentation.54")}</p>
          </div>
        </div>

        <img alt="01supply boost roadmap" src={illustration8} className="illustration8" />

        <div className="row roadmap2">
          <div style={{ width: "170px" }} className="column roadmap-3">
            <p className="text montserrat-800">{t("boostPresentation.55")}</p>
            <p className="text">{t("boostPresentation.56")}</p>
          </div>
          <div style={{ width: "170px" }} className="column roadmap-4">
            <p className="text montserrat-800">{t("boostPresentation.57")}</p>
            <p className="text">{t("boostPresentation.58")}</p>
          </div>
        </div>
        <p
          style={{ fontSize: "30px", marginBottom: "18px", marginTop: "50px", marginBottom: "-10px" }}
          className="title montserrat-800"
        >
          {t("boostPresentation.59")}
        </p>
        <div className="back-6">
          <img src={arrow1Img} className="arrow-1-2" />
          <button onClick={handleAction} className="button big orange-back">
            {t("boostPresentation.60")}
          </button>
          <img src={arrow2Img} className="arrow-2-2" />
        </div>
        <div style={{ marginTop: "100px" }} className="row start review">
          <p style={{ fontSize: "26px" }} className="title montserrat-800">
            {t("boostPresentation.61")}
          </p>
          <img src={illustration9} alt="01supply boost supplier reviews" className="illustration9" />
        </div>
        <div style={{ marginTop: "30px" }} className="row-wrap content start">
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("boostPresentation.reviews.1")}</p>
            <p className="text-bold start">
              Ana K. <span className="text-400">{t("boostPresentation.reviews.2")}</span>
            </p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("boostPresentation.reviews.3")}</p>
            <p className="text-bold start">
              Fabrice J. <span className="text-400">{t("boostPresentation.reviews.4")}</span>
            </p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("boostPresentation.reviews.5")}</p>
            <p className="text-bold start">
              Daniel H. <span className="text-400">{t("boostPresentation.reviews.6")}</span>
            </p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("boostPresentation.reviews.7")}</p>
            <p className="text-bold start">
              Franck B. <span className="text-400">{t("boostPresentation.reviews.8")}</span>
            </p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration12}
              alt="review 4 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("boostPresentation.reviews.9")}</p>
            <p className="text-bold start">
              Ralph K. <span className="text-400">{t("boostPresentation.reviews.10")}</span>
            </p>
          </div>
          <div className="review-card column start">
            <img
              style={{ width: "100px", marginLeft: "110px", marginLeft: "75px" }}
              src={illustration10}
              alt="review 5 stars"
            />
            <p style={{ marginTop: "10px", marginBottom: "15px" }}>{t("boostPresentation.reviews.11")}</p>
            <p className="text-bold start">
              Leslie W. <span className="text-400">{t("boostPresentation.reviews.12")}</span>
            </p>
          </div>
        </div>
        <div style={{ marginTop: "50px" }} className="row start content faq">
          <p style={{ width: "280px", marginRight: "50px" }} className="title start montserrat-800">
            {t("boostPresentation.62")}
          </p>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse faq"
            defaultActiveKey={[]}
          >
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.1")} key="1">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.2")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.3")} key="2">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.4")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.5")} key="3">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.6")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.7")} key="4">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.8")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.9")} key="5">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.10")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.11")} key="6">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.12")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.13")} key="7">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.14")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.15")} key="8">
              <p className="text-roboto montserrat">{t("boostPresentation.faq.16")}</p>
            </Panel>
            <Panel className="montserrat-bold" header={t("boostPresentation.faq.17")} key="9">
              <p className="text-roboto montserrat">
                {t("boostPresentation.faq.18")}
                <ul style={{ marginTop: "20px" }}>
                  <li>{t("boostPresentation.faq.19")}</li>
                  <li>{t("boostPresentation.faq.20")}</li>
                  <li>{t("boostPresentation.faq.21")}</li>
                  <li>{t("boostPresentation.faq.22")}</li>
                  <li>{t("boostPresentation.faq.23")}</li>
                  <li>{t("boostPresentation.faq.24")}</li>
                  <li>{t("boostPresentation.faq.25")}</li>
                </ul>
              </p>
            </Panel>
          </Collapse>
        </div>
        <img
          src={logoImg}
          alt="01supply logo"
          style={{ width: "300px", marginTop: "100px", marginBottom: "30px" }}
        />
        <p className="title-300 center">
          {t("boostPresentation.63")}
          <br />
          {t("boostPresentation.64")}
        </p>
        <img
          style={{ width: "180px", marginTop: "30px" }}
          src={illustration11}
          alt="01supply certification"
        />
      </div>
    </div>
  );
};

export default BoostPresentation;
