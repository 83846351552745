import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import Menu from "../../components/menu/FirstMenu";
import MobileMenu from "../../components/menu/mobile/FirstMenu";
import Header from "../../components/header/SimpleHeader";
import MobileHeader from "../../components/header/mobile/SimpleHeader";
import { header } from "../../api/header";
import { url } from "../../api/url";
import VideoModal from "../../components/modals/VideoModal";
import { routes } from "../../routes";

import completedIcon from "../../images/icons/completed.svg";

const Setup = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [token] = useState(localStorage.getItem("tokenCreatosell"));
  const [idAccount] = useState(localStorage.getItem("idAccountCreatosell"));
  const [typeAccount] = useState(localStorage.getItem("typeAccountCreatosell"));
  const [user, setUser] = useState({});
  const [secretId] = useState(localStorage.getItem("secretAccountId01supply"));

  const { t, i18n } = useTranslation();

  const [nbInstructions, setNbInstruction] = useState(0);
  const [steps, setSteps] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  });

  const getUser = async () => {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    const resJson = await res.json();
    setUser(resJson.data);
  };

  useEffect(() => {
    if (window.tidioChatApi) window?.tidioChatApi?.show();
  }, []);

  useEffect(() => {
    getUser();
  }, [idAccount, secretId]);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    if (user) {
      let newSteps = { ...steps };
      let nbStep = 0;
      if (
        (user.shopify && user.shopify.token && user.shopify.shop) ||
        user?.shop?.url ||
        user?.wizishop?.shop
      ) {
        newSteps.step2 = true;
        nbStep += 1;
      }

      if (
        user.firstName &&
        user.lastName &&
        user.company &&
        user.company.name &&
        user.company.phone &&
        user.company.address
      ) {
        newSteps.step4 = true;
        nbStep += 1;
      }

      if (user.products_id && user.products_id.length > 0) {
        newSteps.step3 = true;
        nbStep += 1;
      }

      if ((user.import_list && user.import_list.length > 0) || newSteps.step3 === true) {
        newSteps.step1 = true;
        nbStep += 1;
      }

      if (user.plan && user.plan.type && user.plan.type !== "free") {
        newSteps.step5 = true;
        nbStep += 1;
      }

      setSteps(newSteps);
      setNbInstruction(nbStep);
    }
  }, [user]);

  return (
    <div className="page">
      {typeAccount === "creater" && <Redirect to="/setup-reseller" />}
      <div className="page-start">
        {windowSize > 1150 ? <Menu /> : <MobileMenu locationP="setting" />}
        <div
          style={
            windowSize > 1150 ? { paddingLeft: "30px", paddingTop: "150px" } : { paddingBottom: "100px" }
          }
          className="column-scrolling"
        >
          <div className="header">
            <h1 style={{ marginBottom: "0px" }} className="title-bold montserrat">
              SETUP ({nbInstructions}/5)
            </h1>
            <div style={{ marginLeft: "0px", marginTop: "15px" }} className="divider-horizontal" />
          </div>

          <div
            className="column"
            style={windowSize > 1150 ? { width: "80%" } : { width: "90%", marginLeft: "5%" }}
          >
            <div
              className={`card width-100 row-wrap`}
              style={{
                marginLeft: "0",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                position: "realtive",
              }}
            >
              {steps.step1 === true && <div className="grey-back" />}
              <div className="column" style={{ width: windowSize > 1150 ? "60%" : "100%" }}>
                <p className="title-roboto" style={{ fontSize: "20px" }}>
                  <span style={{ marginRight: "5px", fontSize: "20px" }} className="title-roboto-bold">
                    {t("step")} 1
                  </span>
                  : {t("setupReseller.6")}
                </p>
                <p style={{ marginTop: "20px" }} className="text">
                  {t("setupReseller.13")}
                </p>
              </div>
              {steps.step1 === true ? (
                <img
                  src={completedIcon}
                  alt="completed"
                  style={{ width: "150px", zIndex: 10, marginTop: windowSize > 1150 ? "" : "20px" }}
                />
              ) : (
                <Link
                  style={{
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    display: "inline-block",
                    marginTop: windowSize > 1150 ? "" : "20px",
                  }}
                  to={routes.marketplaceHome(i18n.language).path}
                  className="button orange-back"
                >
                  {t("setupReseller.7")}
                </Link>
              )}
            </div>
            <div
              className="card width-100 row-wrap"
              style={{
                marginLeft: "0",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                position: "realtive",
              }}
            >
              {steps.step2 === true && <div className="grey-back" />}
              <div className="column" style={{ width: windowSize > 1150 ? "60%" : "100%" }}>
                <p className="title-roboto" style={{ fontSize: "20px" }}>
                  <span style={{ marginRight: "5px", fontSize: "20px" }} className="title-roboto-bold">
                    {t("step")} 2
                  </span>
                  : {t("setupReseller.2")}
                </p>
                <p style={{ marginTop: "20px" }} className="text">
                  {t("setupReseller.14")}
                </p>
              </div>
              {steps.step2 === true ? (
                <img
                  src={completedIcon}
                  alt="completed"
                  style={{ width: "150px", zIndex: 10, marginTop: windowSize > 1150 ? "" : "20px" }}
                />
              ) : (
                <Link
                  style={{
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    display: "inline-block",
                    marginTop: windowSize > 1150 ? "" : "20px",
                  }}
                  to="/your-shop-reseller"
                  className="button orange-back"
                >
                  {t("setupReseller.3")}
                </Link>
              )}
            </div>
            <div
              className="card width-100 row-wrap"
              style={{
                marginLeft: "0",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                position: "realtive",
              }}
            >
              {steps.step3 === true && <div className="grey-back" />}
              <div className="column" style={{ width: windowSize > 1150 ? "60%" : "100%" }}>
                <p className="title-roboto" style={{ fontSize: "20px" }}>
                  <span style={{ marginRight: "5px", fontSize: "20px" }} className="title-roboto-bold">
                    {t("step")} 3
                  </span>
                  : {t("setupReseller.8")}
                </p>
                <p style={{ marginTop: "20px" }} className="text">
                  {t("setupReseller.15")}
                </p>
              </div>
              {steps.step3 === true ? (
                <img
                  src={completedIcon}
                  alt="completed"
                  style={{ width: "150px", zIndex: 10, marginTop: windowSize > 1150 ? "" : "20px" }}
                />
              ) : (
                <Link
                  style={{
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    display: "inline-block",
                    marginTop: windowSize > 1150 ? "" : "20px",
                  }}
                  to="/import-list"
                  className="button orange-back"
                >
                  {t("setupReseller.9")}
                </Link>
              )}
            </div>

            <div
              className="card width-100 row-wrap"
              style={{
                marginLeft: "0",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                position: "realtive",
              }}
            >
              {steps.step4 === true && <div className="grey-back" />}
              <div className="column" style={{ width: windowSize > 1150 ? "60%" : "100%" }}>
                <p className="title-roboto" style={{ fontSize: "20px" }}>
                  <span style={{ marginRight: "5px", fontSize: "20px" }} className="title-roboto-bold">
                    {t("step")} 4
                  </span>
                  : {t("setupReseller.4")}
                </p>
                <p style={{ marginTop: "20px" }} className="text">
                  {t("setupReseller.16")}
                </p>
              </div>
              {steps.step4 === true ? (
                <img
                  src={completedIcon}
                  alt="completed"
                  style={{ width: "150px", zIndex: 10, marginTop: windowSize > 1150 ? "" : "20px" }}
                />
              ) : (
                <Link
                  style={{
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    display: "inline-block",
                    marginTop: windowSize > 1150 ? "" : "20px",
                  }}
                  to="/your-informations"
                  className="button orange-back"
                >
                  {t("setupReseller.5")}
                </Link>
              )}
            </div>

            <div
              className="card width-100 row-wrap"
              style={{
                marginLeft: "0",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                position: "realtive",
              }}
            >
              {steps.step5 === true && <div className="grey-back" />}
              <div className="column" style={{ width: windowSize > 1150 ? "60%" : "100%" }}>
                <p className="title-roboto" style={{ fontSize: "20px" }}>
                  <span style={{ marginRight: "5px", fontSize: "20px" }} className="title-roboto-bold">
                    {t("step")} 5
                  </span>
                  : {t("setupReseller.10")}
                </p>
                <p style={{ marginTop: "20px" }} className="text">
                  {t("setupReseller.17")}
                </p>
              </div>
              {steps.step5 === true ? (
                <img
                  src={completedIcon}
                  alt="completed"
                  style={{ width: "150px", zIndex: 10, marginTop: windowSize > 1150 ? "" : "20px" }}
                />
              ) : (
                <Link
                  style={{
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    display: "inline-block",
                    marginTop: windowSize > 1150 ? "" : "20px",
                  }}
                  to="/pricing"
                  className="button orange-back"
                >
                  {t("setupReseller.11")}
                </Link>
              )}
            </div>

            <a
              style={{ width: "100%", textAlign: "center" }}
              href="https://www.01supply.com/help"
              className="text-gradient"
            >
              {t("setupReseller.12")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;
