import { url } from "../api/url";
import { header } from "../api/header";
import { updateUser } from "./updateUser";

export const pushProductsToInventory = async (products, callback) => {
  const idAccount = localStorage.getItem("idAccountCreatosell");
  const secretId = localStorage.getItem("secretAccountId01supply");
  const token = localStorage.getItem("tokenCreatosell");

  const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      ...header,
      authorization: token,
    },
  });
  if (res.status === 200) {
    const resJson = await res.json();
    let newUser = resJson.data;

    const newProductsId = products.map((product) => product._id);
    const importList = newUser.import_list.filter((id) => !products.some((product) => product._id === id));
    const userProductsId = [...newUser.products_id, ...newProductsId];
    updateUser({ user: { import_list: importList, products_id: userProductsId } });

    const res3 = await fetch(`${url}/product/push-seller-in-products`, {
      method: "PUT",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
      body: JSON.stringify({
        userId: newUser._id,
        productsId: newProductsId,
      }),
    });
    if (res3 && callback) {
      callback(newProductsId);
    }
  }
};
