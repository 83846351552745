import React from "react";
import { Link } from "react-router-dom";
import { Table, Select, message } from "antd";

import { thematics } from "../../../constants/thematics";
import { adminServices } from "../../../services/admin";

import UserList from "./UserList";

const UserNoThematic = ({ users, user }) => {
  const handleSelectThematic = ({ thematic, userId }) => {
    adminServices
      .assignThematics({ thematic, userId })
      .then((res) =>
        res.status === 200 ? message.success("thematic assign") : message.error("error assign thematic")
      );
  };

  let store = "";
  if (user.type === "seller") {
    store = user.shopify?.shop ? user.shopify?.shop : user.shop?.url ? user.shop?.url : "";
    if (store && !store.includes("http")) {
      store = "https://" + store;
    }
  } else {
    store = `${process.env.REACT_APP_ORIGIN}/shop/${user.shop?.name}`;
  }

  return (
    <div className="user-card">
      <div className="column">
        <div className="row">
          <div style={{ marginRight: "30px" }} className="column">
            <p className="text-bold">Boutique</p>
            <a href={store} target="_blank" rel="noopener noreferrer">
              {store}
            </a>
            <p className="text">
              {user.type === "creater" ? user.shop?.name : user.shopify?.shop || user.shop?.url || ""}
            </p>
          </div>
          <div style={{ marginRight: "30px" }} className="column">
            <p className="text-bold">nb produits 01supply</p>
            <p className="text">{user.products_id?.length}</p>
          </div>
          {user.type === "creater" && (
            <div style={{ marginRight: "30px" }} className="column">
              <p className="text-bold">nb ventes sur 01supply</p>
              <p className="text">{user.orderCount}</p>
            </div>
          )}
          {user.type === "seller" && (
            <div style={{ marginRight: "30px" }} className="column">
              <p className="text-bold">nb ventes sur Shopify</p>
              <p className="text">{user.shopifySales}</p>
            </div>
          )}
          <Select
            mode="multiple"
            allowClear
            style={{ width: "350px" }}
            placeholder="Thématic"
            defaultValue={user.thematic}
            onChange={(value) => handleSelectThematic({ thematic: value, userId: user._id })}
          >
            {thematics.map((theme) => (
              <Select.Option key={theme}>{theme}</Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ alignItems: "flex-end", marginTop: "20px" }} className="row">
          <div style={{ marginRight: "30px" }} className="column">
            <p className="text-bold">Contacts</p>
            {user.shopifyContacts ? (
              <div className="row">
                <p>{user.shopifyContacts.email} </p>
                <p>{user.shopifyContacts.phone} </p>
              </div>
            ) : (
              <div className="row">
                <p>{user.email} </p>
                <p>{user.company?.phone} </p>
              </div>
            )}
          </div>
          <Link
            style={{ marginTop: "10px" }}
            rel="noopener noreferrer"
            to={`/connect?token=${localStorage.getItem("tokenCreatosell")}&id=${user?._id}&type=${
              user?.type
            }&email=${user?.email}&secret_id=${user?.secret_id}`}
            className="text blue underline"
          >
            Login to this account
          </Link>
        </div>
        <p style={{ marginTop: "30px", marginBottom: "15px" }} className="title-bold blue">
          Collaborations actuelles
        </p>
        <UserList users={users.filter((userP) => user.currentCollab.includes(userP._id))} />
        <p style={{ marginTop: "30px", marginBottom: "15px", color: "#ff6767" }} className="title-bold">
          Collaborations potentielle
        </p>
        <UserList users={users.filter((userP) => user.potentialCollab.includes(userP._id))} />
      </div>
    </div>
  );
};

export default UserNoThematic;
