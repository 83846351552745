import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
import { url } from "../../api/url";
import greyArrow from "../../images/icons/grey-arrow.svg";

const ThematicCard = ({
  thematic,
  admin,
  setEditThematicVedette,
}) => {
  const [vedettes, setVedettes] = useState(thematic.vedettes);

  const { t } = useTranslation()


  const getImage = async (id) => {
    let image = "";
    try{
      const res = await fetch(`${url}/product/image?id=${id}`);
      if (res && res.status == 200) {
        const resJson = await res.json();
        if (
          resJson &&
          resJson.data &&
          resJson.data.base64 &&
          resJson.data.name_file
        ) {
          if (!resJson.data.base64.includes("data:image")) {
            if (
              resJson.data.base64.includes("http") &&
              resJson.data.base64.split("").length < 1500
            ) {
              image = resJson.data.base64;
            } else if (
              resJson.data.name_file &&
              resJson.data.name_file.includes("svg")
            )
              image = `data:image/svg+xml;base64,${resJson.data.base64}`;
            else if (resJson.data.name_file.includes("jpeg"))
              image = `data:image/jpeg;base64,${resJson.data.base64}`;
            else if (resJson.data.name_file.includes("jpg"))
              image = `data:image/jpg;base64,${resJson.data.base64}`;
            else if (resJson.data.name_file.includes("png"))
              image = `data:image/png;base64,${resJson.data.base64}`;
          } else {
            image = `${resJson.data.base64}`;
          }
        }
        return image;
      }
    } catch {
      return ""
    }
  };

  return (
    <div style={{ margin: "20px" }} className="column">
      <Link
        to={`/marketplace/thematics/${thematic.name}`}
        className="title row title-vedette"
        style={{ fontSize: "25px", fontWeight: "600", alignItems: "center" }}
      >
        {thematic.name}
        <img
          className="icon-vedette"
          src={greyArrow}
          style={{ width: "20px", height: "auto" }}
        />
      </Link>
      {vedettes && (
        <div style={{ alignItems: "flex-end" }} className="row-wrap">
          <a
            target="_blank"
            href={`/product-details?name=${
              vedettes[0] && vedettes[0].name
            }&id=${vedettes[0] && vedettes[0]._id}`}
          >
            <img
              onClick={(e) => {
                if (admin) {
                  setEditThematicVedette({ ...thematic, index: 0 });
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              className={"img-vedette-1"}
              src={
                vedettes.length && vedettes[0]
                  ? vedettes[0].image
                  : "http://club-entrepreneurs92.fr/img/icon/picture.png"
              }
            />
          </a>
          <div className="column">
            <a
              target="_blank"
              href={
                vedettes[1] &&
                `/product-details?name=${vedettes[1].name}&id=${vedettes[1]._id}`
              }
            >
              <img
                onClick={(e) => {
                  if (admin) {
                    setEditThematicVedette({ ...thematic, index: 1 });
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                className={"img-vedette"}
                src={
                  vedettes[1] && vedettes[1].image
                    ? vedettes[1].image
                    : "http://club-entrepreneurs92.fr/img/icon/picture.png"
                }
              />
            </a>
            <a
              target="_blank"
              href={
                vedettes[4] &&
                `/product-details?name=${vedettes[4].name}&id=${vedettes[4]._id}`
              }
            >
              <img
                onClick={(e) => {
                  if (admin) {
                    setEditThematicVedette({ ...thematic, index: 4 });
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                className={"img-vedette"}
                src={
                  vedettes[4] && vedettes[4].image
                    ? vedettes[4].image
                    : "http://club-entrepreneurs92.fr/img/icon/picture.png"
                }
              />
            </a>
          </div>
          <div className="column">
            <a
              target="_blank"
              href={
                vedettes[2] &&
                `/product-details?name=${vedettes[2].name}&id=${vedettes[2]._id}`
              }
            >
              <img
                onClick={(e) => {
                  if (admin) {
                    setEditThematicVedette({ ...thematic, index: 2 });
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                className={"img-vedette"}
                src={
                  vedettes[2] && vedettes[2].image
                    ? vedettes[2].image
                    : "http://club-entrepreneurs92.fr/img/icon/picture.png"
                }
              />
            </a>
            <a
              target="_blank"
              href={
                vedettes[5] &&
                `/product-details?name=${vedettes[5].name}&id=${vedettes[5]._id}`
              }
            >
              <img
                onClick={(e) => {
                  if (admin) {
                    setEditThematicVedette({ ...thematic, index: 5 });
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                className={"img-vedette"}
                src={
                  vedettes[5] && vedettes[5].image
                    ? vedettes[5].image
                    : "http://club-entrepreneurs92.fr/img/icon/picture.png"
                }
              />
            </a>
          </div>
          <div className="column">
            <a
              target="_blank"
              href={
                vedettes[3] &&
                `/product-details?name=${vedettes[3].name}&id=${vedettes[3]._id}`
              }
            >
              <img
                onClick={(e) => {
                  if (admin) {
                    setEditThematicVedette({ ...thematic, index: 3 });
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                className={"img-vedette"}
                src={
                  vedettes[3] && vedettes[3].image
                    ? vedettes[3].image
                    : "http://club-entrepreneurs92.fr/img/icon/picture.png"
                }
              />
            </a>
            <Link
              to={`/marketplace/thematics/${thematic.name}`}
              className="img-vedette"
              style={{ background: "rgb(50, 50, 50)", color: "white" }}
            >
              {t('components.supplierCard.3')}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThematicCard;
