import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Pagination } from "antd";

import Product from "../ProductCard";
import { productServices } from "../../services/products";

import "./style.css";

const Products = ({ user, notFound, setUser, supplier, setNotFound, windowSize }) => {
  const [products, setProducts] = useState([]);
  const [pageStep, setPageStep] = useState(1);
  const [isLoad, setIsLoad] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const { t } = useTranslation();
  const pageSize = 50;

  const scrollToFirstProduct = () => {
    document.getElementById("products-list-shop")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (supplier.shop?.name) {
      productServices
        .find({ page: pageStep, createrId: supplier._id, createrName: supplier.shop?.name })
        .then((res) => (res.status === 200 ? res.json() : false))
        .then((res) => {
          if (res) {
            setIsLoad(false);
            setProducts(res.data || []);
            setTotalItems(res.totalProducts);
          } else {
            setNotFound(true);
          }
        });
    }
  }, [pageStep, supplier]);

  return (
    <div
      id="products-list-shop"
      style={
        windowSize > 600 ? { position: "relative" } : { position: "relative", padding: 0, margin: "20px 0" }
      }
      className="card"
    >
      <div className="row-wrap" style={{ width: "100%", justifyContent: "center" }}>
        {products.length > 0 && (
          <p
            style={{ marginTop: "20px", marginBottom: "20px", marginLeft: "15px", fontWeight: "bold" }}
            className="title-roboto width-100"
          >
            {t("components.supplierShop.1")}
          </p>
        )}
        {products.length && !isLoad ? (
          products.map((product, index) => (
            <Product
              setUser={setUser}
              user={user}
              key={product._id + product.name}
              location={"landing"}
              product={product}
            />
          ))
        ) : notFound ? (
          <p
            style={{ width: "100%", textAlign: "center", fontSize: "30px", marginTop: "80px" }}
            className="title"
          >
            {t("components.supplierShop.2")}
          </p>
        ) : (
          <div className="column" style={{ alignItems: "center", width: "100%" }}>
            <Skeleton className="image-product" loading={true} active />
            <Skeleton className="image-product" loading={true} active />
          </div>
        )}

        {!notFound && (
          <div className="column" style={{ alignItems: "center", width: "100%" }}>
            {products && products.length > 0 ? (
              <Pagination
                showSizeChanger={false}
                style={{ marginBottom: "50px", marginTop: "30px" }}
                onChange={(page) => {
                  setPageStep(page);
                  scrollToFirstProduct();
                  setIsLoad(true);
                }}
                defaultCurrent={pageStep}
                total={totalItems}
                pageSize={pageSize}
                disabled={totalItems < pageSize}
              />
            ) : (
              <React.Fragment>
                <Skeleton className="image-product" loading={true} active />
                <Skeleton className="image-product" loading={true} active />
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
