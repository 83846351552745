import React from "react";
import { useTranslation } from "react-i18next";

import GetStarted from "../getStarted/GetStarted";
import coverImg from "../../images/blog/global/cover.svg";

import { routes } from "../../routes";

const BlogHeader = () => {
  const { i18n, t } = useTranslation();
  return (
    <div style={{ marginBottom: "50px" }} className="row-wrap-blog width-100">
      <div className="column header-container">
        <h1 className="title-bold montserrat start" style={{ fontWeight: "800", fontSize: "28px" }}>
        {t("blog.0")} <br />
          {t("blog.1")}
        </h1>
        <h2 style={{ marginBottom: "15px", fontSize: "14px" }} className="text-300 grey">
          {t("blog.2")}
        </h2>
        <GetStarted redirectPath={routes.joinUs(i18n.language).path} />
      </div>
      <img alt="01supply blog" src={coverImg} className="cover" />
    </div>
  );
};

export default BlogHeader;
