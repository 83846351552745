import React from "react";
import { useTranslation } from "react-i18next";

const cardStyle = {
    width: "240px",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

const KeysValue = ({ windowSize }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "50px", paddingLeft: windowSize <= 1150 && "5vw" }} className="column width-100">
      <h3 style={{ margin: "10px", fontSize: "20px" }} className="montserrat">
        {t("extension.keysValue.title")}
      </h3>
      <div
        style={{ alignItems: "flex-start" }}
        className={windowSize <= 1150 ? "row-wrap row center" : "row-wrap"}
      >
        <div
          style={cardStyle}
          className="card center"
        >
          <p style={{ fontSize: "50px" }} className="purpose-2 title montserrat-800 center">
            80M+
          </p>
          <p className="center">{t("extension.keysValue.1")}</p>
        </div>
        <div
          style={cardStyle}
          className="card center"
        >
          <p style={{ fontSize: "50px" }} className="purpose-3 title montserrat-800 center">
            234
          </p>
          <p className="center">{t("extension.keysValue.2")}</p>
        </div>
        <div
          style={cardStyle}
          className="card center"
        >
          <p style={{ fontSize: "50px" }} className="purpose title montserrat-800 center">
            7.6M
          </p>
          <p className="center">{t("extension.keysValue.3")}</p>
        </div>
        <div
          style={cardStyle}
          className="card center"
        >
          <p style={{ fontSize: "50px" }} className="purpose-2 title montserrat-800 center">
            4%
          </p>
          <p className="center">{t("extension.keysValue.4")}</p>
        </div>
      </div>
    </div>
  );
};

export default KeysValue;
