import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Input } from "antd";
import { Redirect } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./style.scss";

import StripePayment from "./StripePayment";
import IsCoachingPaid from "./IsCoachingPaid";

import { urlParams } from "../../utils/urlParams";
import { regex } from "../../regex/index";
import { routes } from "../../routes";

import { resellerCoaching } from "../../constants/resellerCoaching";

import supplerAcademyLogo from "../../images/icons/01supply_academy.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StarterDescription = ({ t }) => {
  return (
    <ul style={{ paddingLeft: "0" }}>
      <li> {t("resellerCoaching.descriptions.starter.1")} </li>
      <li> {t("resellerCoaching.descriptions.starter.2")} </li>
      <li> {t("resellerCoaching.descriptions.starter.3")} </li>
    </ul>
  );
};

const AdvancedDescription = ({ t }) => {
  return (
    <ul style={{ paddingLeft: "0" }}>
      <li> {t("resellerCoaching.descriptions.advanced.2")} </li>
      <li> {t("resellerCoaching.descriptions.advanced.3")} </li>
      <li> {t("resellerCoaching.descriptions.advanced.4")} </li>
    </ul>
  );
};

const ProDescription = ({ t }) => {
  return (
    <ul style={{ paddingLeft: "0" }}>
      <li> {t("resellerCoaching.descriptions.pro.2")} </li>
      <li> {t("resellerCoaching.descriptions.pro.3")} </li>
      <li> {t("resellerCoaching.descriptions.pro.4")} </li>
      <li> {t("resellerCoaching.descriptions.pro.5")} </li>
    </ul>
  );
};

const CoursePayment = () => {
  const [isBadUrl, setIsBadUrl] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("emailAccountCreatosell") || "");
  const [shopUrl, setShopUrl] = useState("");
  const [isCoachingPaid, setIsCoachingPaid] = useState(false);
  const [isBadEmail, setIsBadEmail] = useState(false);
  const [isBadStoreUrl, setIsBadStoreUrl] = useState(false);
  const [coaching, setCoaching] = useState({});

  const paramsUrl = urlParams();

  const { i18n, t } = useTranslation();

  useEffect(() => {

    const defaultCoaching = resellerCoaching[paramsUrl.coaching];
    if (!defaultCoaching) {
      setIsBadUrl(true);
    }
    setCoaching({ ...defaultCoaching, lang: i18n.language || "en" });
  }, []);

  useEffect(() => {
    if (email && !regex.email(email)) {
      setIsBadEmail(true);
    } else {
      setIsBadEmail(false);
    }
  }, [email]);

  return (
    <>
      {isBadUrl && <Redirect to={routes.resellerCoachingAdvanced(i18n.language).path} />}
      <div className="payment-page">
        <div className="course-navbar">
          <img style={{ width: "180px" }} src={supplerAcademyLogo} alt="01supply academy" />
        </div>
        <div style={{ marginTop: "30px" }} className="row-wrap width-100">
          <img
            style={{ height: "100px", marginBottom: "20px" }}
            className="img"
            alt="01supply academy course"
            src={`${process.env.REACT_APP_ORIGIN}${coaching.imagePath}`}
          />
          <div className="column content-course">
            {coaching.name === "starter" ? (
              <StarterDescription t={t} />
            ) : coaching.name === "advanced" ? (
              <AdvancedDescription t={t} />
            ) : (
              <ProDescription t={t} />
            )}
          </div>
          <p className="title-300">
            1x ${coaching.price} {coaching.name !== "starter" && "/" + t("months")}{" "}
          </p>
        </div>
        <Divider />
        {isCoachingPaid ? (
          <IsCoachingPaid />
        ) : (
          <>
            <p className="title-bold width-100 end">
              Total ${coaching.price} {coaching.name !== "starter" && "/" + t("months")}
            </p>
            <div
              style={{ marginTop: "20px", justifyContent: "space-evenly" }}
              className="row-wrap start width-100"
            >
              <div className="column left-column">
                <p className="title-bold">{t("paymentPage.yourAccount")}</p>
                <Input
                  status={isBadStoreUrl && "error"}
                  style={{ width: "300px", marginTop: "20px", height: "42px" }}
                  placeholder={t("yourStoreUrl")}
                  onChange={(e) => setShopUrl(e.target.value)}
                  value={shopUrl}
                />
                <Input
                  status={isBadEmail && "error"}
                  style={{ width: "300px", marginTop: "20px", height: "42px" }}
                  placeholder={t("yourEmailAddress")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <p style={{ fontSize: "13px", marginTop: "15px" }} className="text-300 grey">
                  {t("paymentPage.emailInfos3")} <br />
                  {t("paymentPage.emailInfos2")}
                </p>
              </div>
              <div className="column right-column">
                <p className="title-bold">{t("paymentPage.paymentInfos")}</p>

                <Elements stripe={stripePromise}>
                  <StripePayment
                    isBadEmail={isBadEmail}
                    setIsBadEmail={setIsBadEmail}
                    setIsCoachingPaid={setIsCoachingPaid}
                    email={email}
                    shopUrl={shopUrl}
                    setIsBadStoreUrl={setIsBadStoreUrl}
                    coaching={coaching}
                  />
                </Elements>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CoursePayment;
