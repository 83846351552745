import { message } from "antd";

export const updateCart = async ({
  product,
  shippingSelect,
  variantSelect,
  quantity,
  idAccount,
  secretId,
  url,
  header,
  token,
  deleteItemId,
  updateShipping,
  t,
}) => {
  const getNewCart = (actualCart) => {
    let cart = [];
    let productAlreadyInCart = false;
    if (!idAccount || !secretId) {
      let cartStorage = JSON.parse(localStorage.getItem("cart"));
      if (cartStorage) cart = cartStorage;
    }

    if (actualCart && actualCart.length) {
      cart = actualCart;
    }

    for (let productCart of cart) {
      if (productCart.product_id === product._id && variantSelect._id === productCart.variant_id) {
        productCart.quantity = parseInt(quantity);
        productCart.shipping_id = shippingSelect?._id ? shippingSelect._id : "";
        productAlreadyInCart = true;
      }
    }

    if (!productAlreadyInCart && !deleteItemId) {
      cart.push({
        product_id: product._id,
        variant_id: variantSelect._id,
        shipping_id: shippingSelect?._id ? shippingSelect._id : "",
        quantity: quantity,
      });
    }

    if (deleteItemId) {
      cart = cart.filter((item) => item.product_id !== deleteItemId);
    }

    if (updateShipping) {
      cart = cart.map((item) => {
        if (item.product_id === updateShipping.product_id) {
          return {
            ...item,
            shipping_id: updateShipping.newShippingId,
          };
        } else return item;
      });
    }

    return cart;
  };

  // NO CONNECT USER

  if (!idAccount || !secretId) {
    const newCart = getNewCart();

    localStorage.setItem("cart", JSON.stringify(newCart));
  }

  // CONNECT USER

  if (idAccount && secretId) {
    const res = await fetch(`${url}/user/find/${idAccount}/${secretId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...header,
        authorization: token,
      },
    });
    if (res.status === 200) {
      const resJson = await res.json();
      const userRes = resJson.data;

      const newCart = getNewCart(userRes.cart);

      const resConnect = await fetch(`${url}/user/update-cart/${idAccount}/${secretId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          ...header,
          authorization: token,
        },
        body: JSON.stringify({
          cart: newCart,
        }),
      });
      if (resConnect.status === 200) {
        localStorage.setItem("cart", JSON.stringify(newCart));
        message.success(t("messages.cartUpdated"));
      } else message.success(t("messages.cartUpdated"));
    }
  }
};
