import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { Modal } from "../../wrappers";
import { updateUser } from "../../services/updateUser";

const IbanModal = ({ user, setIbanModal, url, idAccount, secretId, token, header, setUser }) => {
  const [iban, setIban] = useState(user.iban || "");

  const { t } = useTranslation();

  const sendIban = async () => {
    const updateCallback = () => {
      message.success(t("messages.ibanSent"));
      setIbanModal(false);
      setUser({ ...user, iban: iban });
    };
    updateUser({ user: { iban } }, idAccount, secretId, token, updateCallback);
  };

  return (
    <Modal
      className="modal-account invitation"
      onOk={() => {}}
      visible={true}
      onCancel={() => setIbanModal(false)}
      footer={[
        <Button key="back" onClick={() => setIbanModal(false)}>
          {t("cancel")}
        </Button>,
      ]}
    >
      <div style={{ alignItems: "center" }} className="column">
        <p className="title">{t("components.modal.21")}</p>
        <TextArea
          defaultValue={user.iban}
          style={{ marginTop: "20px", marginBottom: "30px", width: "100%" }}
          onChange={(e) => setIban(e.target.value)}
          placeholder="IBAN"
        />
        <button onClick={sendIban} className="button orange-back">
          {t("components.modal.22")}
        </button>
      </div>
    </Modal>
  );
};

export default IbanModal;
