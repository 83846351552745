import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import ImageInput from "../../ImageInput";
import { getImage } from "../../../utils/GetImage";

const HandmadeLabelForm = ({
  user,
  setUser,
  updateUser,
  windowSize,
  isChange,
  setIsChange,
  setInitialAboutImagesList,
  setFileListImages,
  fileListImages,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!fileListImages.length && user.about && user.about.images && user.about.images.length)
      getAboutImages(user.about.images);
  }, []);

  const getAboutImages = async (ids) => {
    Promise.all(
      ids.map(async (id) => {
        const img = await getImage(id);
        return {
          base64: img,
          thumbUrl: img,
          name: id + "image.svg",
        };
      })
    ).then((res) => {
      setFileListImages({ fileList: res?.filter((img) => img) });
      setInitialAboutImagesList(res);
      setIsChange(false);
    });
  };

  useEffect(() => {
    if (!isChange && fileListImages.fileList && fileListImages.fileList.length) setIsChange(true);
  }, [fileListImages]);

  return (
    <div style={{ alignItems: "flex-start", width: "100%" }} className="column">
      <div style={{ marginRight: "50px", width: "100%" }} className="column">
        <p style={{ textAlign: "start", fontSize: "15px", marginBottom: "10px" }} className="text-bold">
          {t("components.supplier.14")}
        </p>
        <Input.TextArea
          defaultValue={user && user.about && user.about.information}
          placeholder={t("components.supplier.15")}
          onChange={(e) => {
            if (!isChange) setIsChange(true);

            setUser({ ...user, about: { ...user.about, information: e.target.value } });
          }}
          style={{ width: "90%" }}
        />
      </div>
      <div style={{ marginTop: "20px", width: "100%" }} className="column">
        <p style={{ textAlign: "start", fontSize: "15px", marginBottom: "10px" }} className="text-bold">
          Video(s)
        </p>
        <Input
          style={{ width: "90%" }}
          defaultValue={user && user.about && user.about.video1}
          placeholder="www.youtube.com/..."
          onChange={(e) => {
            if (!isChange) setIsChange(true);

            setUser({ ...user, about: { ...user.about, video1: e.target.value } });
          }}
        />
        {user && user.about && (user.about.video1 || user.about.video2) && (
          <Input
            style={{ marginTop: "15px", width: "90%" }}
            defaultValue={user && user.about && user.about.video2}
            placeholder="www.youtube.com/..."
            onChange={(e) => {
              if (!isChange) setIsChange(true);

              setUser({ ...user, about: { ...user.about, video2: e.target.value } });
            }}
          />
        )}
        {user && user.about && (user.about.video2 || user.about.video3) && (
          <Input
            style={{ marginTop: "15px", width: "90%" }}
            defaultValue={user && user.about && user.about.video3}
            placeholder="www.youtube.com/..."
            onChange={(e) => {
              if (!isChange) setIsChange(true);

              setUser({ ...user, about: { ...user.about, video3: e.target.value } });
            }}
          />
        )}
      </div>
      <div
        key={fileListImages.fileList ? true : false}
        style={{ width: "100%", marginTop: "20px" }}
        className="column"
      >
        <p style={{ textAlign: "start", fontSize: "15px", marginBottom: "10px" }} className="text-bold">
          Image(s)
        </p>
        <ImageInput
          photos={fileListImages.fileList ? fileListImages.fileList : fileListImages}
          setPhotos={setFileListImages}
        />
      </div>
    </div>
  );
};

export default HandmadeLabelForm;
