import React from "react";
import "./style.scss";

const Banner = ({ img, title, text, alt }) => {
  return (
    <div className="course-banner">
      <img
        className="course-banner-img"
        alt={alt}
        src={img}
      />
      <div className="course-banner-content">
        {title}
        {text}
      </div>
    </div>
  );
};

export default Banner;
