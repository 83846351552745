import React, { useEffect } from "react";
import MessageCard from "./MessageCard";

const OffersList = ({ messages, windowSize, user }) => {
  useEffect(() => {
    if (messages.length) {
      document.getElementById(`message${messages.length - 1}`)?.scrollIntoView();
    }
  }, [messages]);

  return (
    <div
      id="container-messages"
      className="column-scrolling"
      style={
        windowSize > 1150
          ? { width: "100%", marginTop: "50px", height: "calc(100vh - 270px)", padding: "2%" }
          : { width: "100%", marginTop: "10px", height: "50vh", paddingBottom: "50px" }
      }
    >
      {messages.map((message, index) => {
          return <MessageCard id={`message${index}`} message={message} user={user} />;
        })}
    </div>
  );
};

export default OffersList;
