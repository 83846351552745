import React, { useState, useEffect } from "react";
import SelectThematics from "./SelectThematics";
import { getImage } from "../../../utils/GetImage";
import { url } from "../../../api/url";

const Product = ({ product, thematics, categorys, key }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (product && product.photos && product.photos.length > 0) {
      getImageF(product.photos[0]);
    }
  }, []);

  const getImageF = async (id) => {
    const res = await getImage(id)
    if(res){
      setImage(res)
    }
  };

  return (
    <div key={key} style={{width: '100%' }} className="column">
      <div style={{ height: "120px", width: '100%' }} className="row">
        <a
          style={{ height: "90%", maxWidth: "120px", minHeight: "auto" }}
          className="container-image-card"
          href={`/product-details?name=${product.name}&id=${product._id}`}
        >
          <img
            style={{ height: "100%" }}
            src={image}
            className="image-product"
          />
        </a>
        <p
          style={{ marginLeft: "5%", width: "20%" }}
          className="title-300 text-ellipsis"
        >
          {product.name}
        </p>
        <p style={{ marginLeft: "5%", width: "20%" }} className="title-300">
          ${product.price}
        </p>
        <SelectThematics categorys={categorys} thematics={thematics} product={product} />
      </div>

      <div
        style={{ marginTop: "10px", marginBotto: "10px" }}
        className="divider-horizontal"
      />
    </div>
  );
};

export default Product;
