import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Skeleton, Drawer } from "antd";
import UserIcon from "../../images/icons/User.png";
import AlertIcon from "../../images/icons/alert.png";
import { routes } from "../../routes";

const Menu = ({
  contacts,
  noContacts,
  windowSize,
  setContactMenuOn,
  setContactSelect,
  setOffers,
  contactSelect,
  user,
}) => {
  const idAccount = localStorage.getItem("idAccountCreatosell");

  const { t, i18n } = useTranslation();

  const contactNotificationsCount = (contact) => {
    let count = user.messages_count?.contacts?.find((contactP) => contactP.id === contact._id)?.count;
    if (!count) return 0;
    else if (count > 9) return "+9";
    else return count;
  };

  const MenuContent = () => {
    return (
      <div
        style={{ paddingLeft: "20px", paddingRight: "20px", maxHeight: "95vh", minHeight: "95vh" }}
        key={contacts && contacts.length}
        className="column-scrolling-menu"
      >
        {windowSize > 1150 && <p className="title-roboto">CONTACTS</p>}
        {!contacts.length && !noContacts ? (
          <React.Fragment>
            <Skeleton
              style={{ marginTop: "80px" }}
              className="image-product margin-top-landing"
              loading={true}
              active
            />
            <Skeleton className="image-product" loading={true} active />
          </React.Fragment>
        ) : noContacts ? (
          <p style={{ fontSize: "20px", marginTop: "50px" }} className="title-roboto">
            {t("components.menu.35")}
          </p>
        ) : (
          contacts.map((contact) => {
            if (contact._id !== contactSelect._id && contact._id !== idAccount)
              return (
                <Link
                  to={`${routes.messages(i18n.language).path}?contact=${contact._id}`}
                  className="card shadow"
                  style={{
                    width: "100%",
                    height: "30px",
                    marginLeft: "0",
                    minWidth: "100%",
                    marginBottom: "20px",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "10px",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setContactSelect(contact);
                    setContactMenuOn(false);
                    setOffers({});
                  }}
                >
                  <div style={{ position: "absolute", top: "-7px", right: "-7px" }}>
                    <div style={{ position: "relative" }}>
                      <img style={{ height: "24px", width: "auto" }} src={AlertIcon} />
                      <p className="count-circle">{contactNotificationsCount(contact)}</p>
                    </div>
                  </div>
                  <img
                    src={contact.profile_picture?.includes('http') ? contact.profile_picture : UserIcon}
                    style={{ height: "25px", width: "25px", borderRadius: "50%", marginRight: "10px" }}
                  />
                  <p
                    style={{ fontSize: "16px", width: "calc(100%-45px)" }}
                    className="title-roboto-bold text-ellipsis"
                  >
                    {contact.shop && contact.shop.name
                      ? contact.shop.name
                      : contact.firstName || contact.lastName
                      ? `${contact.firstName} ${contact.lastName || ""}`
                      : contact.email}
                  </p>
                </Link>
              );
          })
        )}
      </div>
    );
  };

  return windowSize > 1150 ? (
    <div className="board-left-menu contact-list">
      <MenuContent />
    </div>
  ) : (
    <Drawer
      title="Contacts"
      placement="left"
      closable={true}
      onClose={() => setContactMenuOn(false)}
      visible={true}
    >
      <MenuContent />
    </Drawer>
  );
};

export default Menu;
