export const DEEPL_COUNTRIES = [
    {country: "Bulgarian", countryCode: "BG"},
    {country: "Czech", countryCode: "CS"},
    {country: "Danish", countryCode: "DA"},
    {country: "German", countryCode: "DE"},
    {country: "Greek", countryCode: "EL"},
    {country: "English", countryCode: "EN"},
    {country: "Spanish", countryCode: "ES"},
    {country: "Estonian", countryCode: "ET"},
    {country: "Finnish", countryCode: "FI"},
    {country: "French", countryCode: "FR"},
    {country: "Hungarian", countryCode: "HU"},
    {country: "Indonesian", countryCode: "ID"},
    {country: "Italian", countryCode: "IT"},
    {country: "Japanese", countryCode: "JA"},
    {country: "Korean", countryCode: "KO"},
    {country: "Lithuanian", countryCode: "LT"},
    {country: "Latvian", countryCode: "LV"},
    {country: "Norwegian (Bokmål)", countryCode: "NB"},
    {country: "Dutch", countryCode: "NL"},
    {country: "Polish", countryCode: "PL"},
    {country: "Portuguese (all Portuguese varieties mixed)", countryCode: "PT"},
    {country: "Romanian", countryCode: "RO"},
    {country: "Russian", countryCode: "RU"},
    {country: "Slovak", countryCode: "SK"},
    {country: "Slovenian", countryCode: "SL"},
    {country: "Swedish", countryCode: "SV"},
    {country: "Turkish", countryCode: "TR"},
    {country: "Ukrainian", countryCode: "UK"},
    {country: "Chinese", countryCode: "ZH"}
  ]