import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select, Spin, Button, Checkbox, message, Image } from "antd";
import { LoadingOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

import {Modal} from '../../../wrappers'
import { origin } from "../../../data/Origin";
import { tag } from "../../../data/Tag";
import UploadImage from "../../../components/UploadSimpleImage";
import Load from "../../../utils/Load";

import UploadIcon from "../../../images/img_illustration.png";

const { Option } = Select;

const CardProduct = ({
  isVisible,
  product,
  index,
  products,
  setProducts,
  user,
  noImportList,
  setNoImportList,
  windowSize,
  setDisabledModalApplyOriginAll,
  disabledModalApplyOriginAll,
  disabledModalApplyShippingAll,
  setDisabledModalApplyShippingAll,
  processingTimeToAll,
  setProcessingTimeToAll,
  getUser,
  disabledModalApplyProcessingTimeAll,
  setDisabledModalApplyProcessingTimeAll,
}) => {
  const [originToApplyAll, setOriginToApplyAll] = useState("");
  const [shippingToApplyAll, setShippingToApplyAll] = useState({});
  const [load, setLoad] = useState(false);
  const [editImage, setEditImage] = useState(undefined);
  const [indexVariantManageMobile, setIndexVariantManageMobile] = useState(undefined);
  const [selectImg, setSelectImg] = useState(undefined);
  const [modalProcessingOnClick, setModalProcessingOnClick] = useState(false);

  const { t } = useTranslation();

  const editProduct = (value, label) => {
    let newProduct = { ...product };
    newProduct[label] = value;
    let newProducts = [...products];
    newProducts[index] = newProduct;
    setProducts(newProducts);
    setEditImage(undefined);
    if (!disabledModalApplyOriginAll && label === "origin") setOriginToApplyAll(value);
    if (!disabledModalApplyProcessingTimeAll && !modalProcessingOnClick && label === "processingTime")
      setProcessingTimeToAll(value);
  };

  const applyOriginAll = () => {
    let newProducts = [...products];
    products.map((product, index) => {
      if (!product.origin) {
        newProducts[index].origin = originToApplyAll;
      }
      if (index === products.length - 1) {
        setLoad(false);
        setOriginToApplyAll("");
        setProducts(newProducts);
      }
    });
  };

  const applyProcessingTimeAll = () => {
    let newProducts = [...products];
    products.map((product, index) => {
      if (
        !product.processingTime ||
        (product.processingTime === processingTimeToAll.split(" to")[0] &&
          !product.processingTime.split(" to")[1])
      ) {
        newProducts[index].processingTime = processingTimeToAll;
      }
      if (index === products.length - 1) {
        setLoad(false);
        setProcessingTimeToAll("");
        setModalProcessingOnClick("");
        setProducts(newProducts);
      }
    });
  };

  const applyShippingAll = () => {
    let newProducts = [...products];
    if (!Array.isArray(shippingToApplyAll)) {
      products.map((product, index) => {
        if (!product.shipping.some((ship) => ship.shipping_id === shippingToApplyAll.shipping_id))
          newProducts[index].shipping = [...newProducts[index].shipping, shippingToApplyAll];
        if (index === products.length - 1) {
          setLoad(false);
          setShippingToApplyAll({});
          setProducts(newProducts);
        }
      });
    } else {
      products.map((product, index) => {
        shippingToApplyAll.map((shipping) => {
          if (!product.shipping.some((ship) => ship.shipping_id === shipping.shipping_id)) {
            newProducts[index].shipping = [
              ...newProducts[index].shipping,
              {
                name: shipping.name,
                origin: shipping.origin,
                price: shipping.price,
                time: shipping.time,
                shipping_id: shipping.shipping_id,
              },
            ];
          }
          if (index === products.length - 1) {
            setLoad(false);
            setShippingToApplyAll({});
            setProducts(newProducts);
          }
        });
      });
    }
  };

  const validEditVariant = (index) => {
    const stockProducts = [...products];
    stockProducts[index] = { ...product };
    setIndexVariantManageMobile(undefined);
    message.success(t("messages.successfullyUpdated"));
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <div
      onClick={(e) => {
        if (
          processingTimeToAll &&
          !modalProcessingOnClick &&
          processingTimeToAll &&
          !disabledModalApplyProcessingTimeAll
        ) {
          e.stopPropagation();
          setModalProcessingOnClick(true);
        }
      }}
      id={`import-product-${index}`}
      className="column"
      style={windowSize > 1150 ? { width: "95%" } : { width: "100%" }}
    >
      {load && <Load />}
      {/* EDIT VARIANT */}
      {(selectImg || selectImg === 0) && (
        <Modal
          footer={[]}
          className="modal-no-ok"
          title={"Select image of variant"}
          visible={true}
          onCancel={() => setSelectImg(undefined)}
        >
          {product.photos &&
            product.photos.map((img, index) => {
              return (
                <img
                  className="img-select-variant"
                  src={img.base64}
                  onClick={() => {
                    let stockVariants = product.variants;
                    stockVariants[selectImg].image = index.toString();
                    setSelectImg(undefined);
                  }}
                />
              );
            })}
        </Modal>
      )}
      {(indexVariantManageMobile || indexVariantManageMobile === 0) && (
        <Modal
          footer={[]}
          onOk={() => setIndexVariantManageMobile(undefined)}
          visible={true}
          className="modal-no-ok"
          title={"Create / Edit variant"}
          onCancel={() => {
            setIndexVariantManageMobile(undefined);
          }}
        >
          <div style={{ width: "95%", alignItems: "center" }} className="column">
            <Image
              style={{ width: "100%", height: "auto" }}
              src={
                product.photos && product.photos[product.variants[indexVariantManageMobile].image]
                  ? product.photos[product.variants[indexVariantManageMobile].image].base64
                  : ""
              }
              fallback={UploadIcon}
              className="img-variant"
              onClick={() => setSelectImg(indexVariantManageMobile)}
            />
            {product.optionsVariants[0] && (
              <Input
                maxLength={30}
                style={{ width: "95%", marginTop: "15px" }}
                defaultValue={
                  product.variants[indexVariantManageMobile] &&
                  product.variants[indexVariantManageMobile].option1
                }
                onChange={(e) => {
                  product.variants[indexVariantManageMobile].option1 = e.target.value;
                }}
                placeholder={product.optionsVariants[0]}
              />
            )}
            {product.optionsVariants[1] && (
              <Input
                maxLength={30}
                style={{ width: "95%", marginTop: "15px" }}
                defaultValue={
                  product.variants[indexVariantManageMobile] &&
                  product.variants[indexVariantManageMobile].option2
                }
                onChange={(e) => {
                  product.variants[indexVariantManageMobile].option2 = e.target.value;
                }}
                placeholder={product.optionsVariants[1]}
              />
            )}
            {product.optionsVariants[2] && (
              <Input
                maxLength={30}
                style={{ width: "95%", marginTop: "15px" }}
                defaultValue={
                  product.variants[indexVariantManageMobile] &&
                  product.variants[indexVariantManageMobile].option3
                }
                onChange={(e) => {
                  product.variants[indexVariantManageMobile].option3 = e.target.value;
                }}
                placeholder={product.optionsVariants[2]}
              />
            )}
            <Input
              suffix={"price"}
              max={29999}
              min={1}
              maxLength={8}
              style={{ width: "95%", marginTop: "15px" }}
              defaultValue={
                product.variants[indexVariantManageMobile] && product.variants[indexVariantManageMobile].price
              }
              onChange={(e) => {
                product.variants[indexVariantManageMobile].price = e.target.value;
              }}
              placeholder="Price"
              step="0.01"
              type="number"
              stringMode
            />
            <Input
              suffix={"stock"}
              max={9999}
              min={1}
              maxLength={5}
              style={{ width: "95%", marginTop: "15px" }}
              defaultValue={
                product.variants[indexVariantManageMobile] && product.variants[indexVariantManageMobile].stock
              }
              onChange={(e) => {
                product.variants[indexVariantManageMobile].stock = e.target.value;
              }}
              placeholder="Quantity"
              type="number"
            />
            <div
              style={{ width: "80%", marginTop: "30px" }}
              onClick={() => {
                validEditVariant();
              }}
              className="button orange-back"
            >
              {t("cardProduct.1")}
            </div>
            <div
              style={{ width: "80%", marginTop: "30px" }}
              onClick={() => {
                let stockVar = [...product.variants];
                stockVar.splice(indexVariantManageMobile, 1);
                product.variants = stockVar;
                let stockProducts = [...products];
                stockProducts[index] = product;
                setProducts(stockProducts);
                setIndexVariantManageMobile(undefined);
              }}
              className="button-red"
            >
              {t("delete").toUpperCase()}
            </div>
          </div>
        </Modal>
      )}
      {originToApplyAll && (
        <Modal
          className="modal-no-ok"
          title={t("cardProduct.6")}
          visible={true}
          onCancel={() => setOriginToApplyAll("")}
          footer={[
            <Button key="back" onClick={() => setOriginToApplyAll("")}>
              {t("cancel")}
            </Button>,
          ]}
        >
          <p className="title-300 column">
            <p style={{ textAlign: "start" }} className="title-300">
              {t("cardProduct.7")}
              <span style={{ fontWeight: "bold", marginLeft: "6px", marginRight: "6px" }}>
                {originToApplyAll}
              </span>
              {t("cardProduct.2")}
            </p>
            <Checkbox
              style={{ marginTop: "15px", marginBottom: "30px" }}
              onChange={(e) => setDisabledModalApplyOriginAll(!disabledModalApplyOriginAll)}
            >
              <p style={{ fontSize: "18px" }} className="title-300">
                {t("cardProduct.3")}
              </p>
            </Checkbox>
            <button
              onClick={() => {
                if (!load) setLoad(true);
                applyOriginAll();
              }}
              className="button orange-back"
            >
              {!load ? (
                `${t("cardProduct.4")} ${originToApplyAll} ${t("cardProduct.5")}`
              ) : (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />} />
              )}
            </button>
          </p>
        </Modal>
      )}
      {modalProcessingOnClick && (
        <Modal
          className="modal-no-ok"
          title={t("cardProduct.6")}
          visible={true}
          onCancel={() => {
            setModalProcessingOnClick(false);
            setProcessingTimeToAll("");
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setModalProcessingOnClick(false);
                setProcessingTimeToAll("");
              }}
            >
              {t("cancel")}
            </Button>,
          ]}
        >
          <p className="title-300 column">
            <p style={{ textAlign: "start" }} className="title-300">
              {t("cardProduct.8")}
              <span style={{ fontWeight: "bold", marginLeft: "6px", marginRight: "6px" }}>
                {processingTimeToAll}
              </span>
              {t("cardProduct.9")}
            </p>
            <Checkbox
              style={{ marginTop: "15px", marginBottom: "30px" }}
              onChange={(e) => setDisabledModalApplyProcessingTimeAll(!disabledModalApplyProcessingTimeAll)}
            >
              <p style={{ fontSize: "18px" }} className="title-300">
                {t("cardProduct.10")}
              </p>
            </Checkbox>
            <button
              onClick={() => {
                if (!load) setLoad(true);
                applyProcessingTimeAll();
              }}
              className="button orange-back"
            >
              {!load ? (
                `${t("cardProduct.8")} ${processingTimeToAll} ${t("cardProduct.11")}`
              ) : (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />} />
              )}
            </button>
          </p>
        </Modal>
      )}
      {((shippingToApplyAll && shippingToApplyAll.name) ||
        (Array.isArray(shippingToApplyAll) && shippingToApplyAll.length > 0)) && (
        <Modal
          className="modal-no-ok"
          title={`Apply ${shippingToApplyAll.name} shipping method to all products`}
          visible={true}
          onCancel={() => setShippingToApplyAll({})}
          footer={[
            <Button key="back" onClick={() => setShippingToApplyAll({})}>
              {t("cancel")}
            </Button>,
          ]}
        >
          <p className="title-300 column">
            <p style={{ textAlign: "start" }} className="title-300">
              {t("cardProduct.8")}
              <span style={{ fontWeight: "bold", marginLeft: "6px", marginRight: "6px" }}>
                {Array.isArray(shippingToApplyAll) ? "all" : shippingToApplyAll.name}
              </span>
              {t("cardProduct.12")}
            </p>
            <Checkbox
              style={{ marginTop: "15px", marginBottom: "30px" }}
              onChange={(e) => setDisabledModalApplyShippingAll(!disabledModalApplyShippingAll)}
            >
              <p style={{ fontSize: "18px" }} className="title-300">
                {t("cardProduct.13")}
              </p>
            </Checkbox>
            <button
              onClick={() => {
                if (!load) setLoad(true);
                applyShippingAll();
              }}
              className="button orange-back"
            >
              {!load ? (
                `${t("cardProduct.8")} ${
                  Array.isArray(shippingToApplyAll) ? "all" : shippingToApplyAll.name
                } ${t("cardProduct.12")}`
              ) : (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />} />
              )}
            </button>
          </p>
        </Modal>
      )}
      {(editImage || editImage === 0) && (
        <Modal
          className="modal-account edit-image-import"
          visible={true}
          onCancel={() => setEditImage(undefined)}
          footer={[]}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }} className="column">
            <UploadImage
              image={product.photos[editImage] ? product.photos[editImage].base64 : ""}
              icon={<EditOutlined className="edit-image-absolute" />}
              setImage={(image) => {
                let newImages = [...product.photos];
                newImages[editImage] = image;
                editProduct(newImages, "photos");
              }}
            />
          </div>
        </Modal>
      )}
      <div
        className="row"
        style={
          windowSize > 1150
            ? { width: "100%", alignItems: "flex-start", flexDirection: "row" }
            : { width: "100%", alignItems: "center", justifyContent: "flex-start", flexDirection: "column" }
        }
      >
        <div
          style={
            windowSize > 1150
              ? {
                  alignItems: "flex-start",
                  width: "20%",
                  justifyContent: "flex-start",
                  marginTop: "5px",
                  marginRight: "5%",
                }
              : {
                  alignItems: "flex-start",
                  width: "100%",
                  justifyContent: "flex-start",
                  marginTop: "5px",
                  marginRight: "0%",
                }
          }
          className="column"
        >
          <div
            style={
              windowSize > 1150
                ? { width: "90%", alignItems: "flex-start", height: "100px", minWidth: "auto" }
                : { width: "100%", alignItems: "flex-start", height: "200px" }
            }
            className="container-img-inventory-product"
          >
            <img
              onClick={() => setEditImage(0)}
              src={product.photos?.[0]?.base64 || UploadIcon}
              className="image-product"
            />
          </div>
          <div className="row-wrap" style={windowSize > 1150 ? { width: "90%" } : { width: "100%" }}>
            <div
              style={{
                width: "45%",
                alignItems: "flex-start",
                marginRight: "10%",
                height: "100px",
                minWidth: "auto",
              }}
              className="container-img-inventory-product import-csv"
            >
              <img
                style={!product.photos?.[1] ? { height: "100%", width: "auto" } : {}}
                onClick={() => setEditImage(1)}
                src={product.photos?.[1]?.base64 || UploadIcon}
                className="image-product"
              />
            </div>
            {product.photos[1] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[2] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(2)}
                  src={product.photos?.[2]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[2] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "10%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[3] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(3)}
                  src={product.photos?.[3]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[3] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[4] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(4)}
                  src={product.photos?.[4]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[4] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[5] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(5)}
                  src={product.photos?.[5]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[5] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[6] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(6)}
                  src={product.photos?.[6]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[6] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[7] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(7)}
                  src={product.photos?.[7]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[7] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[8] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(8)}
                  src={product.photos?.[8]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
            {product.photos[8] && (
              <div
                style={{
                  width: "45%",
                  alignItems: "flex-start",
                  marginRight: "0%",
                  height: "100px",
                  minWidth: "auto",
                }}
                className="container-img-inventory-product import-csv"
              >
                <img
                  style={!product.photos?.[9] ? { height: "100%", width: "auto" } : {}}
                  onClick={() => setEditImage(9)}
                  src={product.photos?.[9]?.base64 || UploadIcon}
                  className="image-product"
                />
              </div>
            )}
          </div>
          <Checkbox
            checked={noImportList.includes(index) ? false : true}
            style={{ marginTop: "15px" }}
            onChange={(e) => {
              if (!noImportList.includes(index)) {
                setNoImportList([...noImportList, index]);
              } else {
                setNoImportList(noImportList.filter((i) => i !== index));
              }
            }}
          >
            <p style={{ fontSize: "18px" }} className="title">
              {t("import")}
            </p>
          </Checkbox>
        </div>
        <div style={windowSize > 1150 ? { width: "25%" } : { width: "100%" }} className="column second">
          <p style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "0px" }}>{t("name")} : </p>
          <Input
            className={!product.name && "red-select"}
            onChange={(e) => editProduct(e.target.value, "name")}
            defaultValue={product.name}
            style={{ width: "100%" }}
          />

          <p style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "10px" }}>{t("price")} : </p>
          <Input
            className={!product.price && "red-select"}
            max={29999}
            min={1}
            maxLength={8}
            step="0.01"
            type="number"
            onChange={(e) => editProduct(e.target.value, "price")}
            defaultValue={product.price}
            style={{ width: "100%" }}
          />

          <p style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "10px" }}>Stock : </p>
          <Input
            className={!product.stock && "red-select"}
            max={9999}
            min={1}
            maxLength={5}
            type="number"
            onChange={(e) => editProduct(e.target.value, "stock")}
            defaultValue={product.stock}
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={
            windowSize > 1150 ? { width: "25%", marginLeft: "30px" } : { width: "100%", marginLeft: "0px" }
          }
          className="column second"
        >
          <p
            style={
              windowSize > 1150
                ? { fontWeight: "bold", marginBottom: "5px", marginTop: "0px" }
                : { fontWeight: "bold", marginBottom: "5px", marginTop: "5px" }
            }
          >
            Description :{" "}
          </p>
          <Input
            className={!product.describe && "red-select"}
            onChange={(e) => editProduct(e.target.value, "describe")}
            defaultValue={product.describe}
            style={{ width: "100%" }}
          />

          <p style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "10px" }}>{t("origin")} : </p>
          <Select
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            className={!product.origin && "red-select"}
            value={product.origin || t("cardProduct.14")}
            style={{ width: "100%" }}
            onChange={(value) => editProduct(value, "origin")}
          >
            {origin.map((country, index) => {
              if (country.name !== "Rest of the world")
                return <Option value={country.name}>{country.name}</Option>;
            })}
          </Select>

          <p style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "10px" }}>{t("cardProduct.15")} </p>

          <div className="row">
            <Input
              maxLength={5}
              style={{ width: "80px", marginRight: "6px" }}
              value={(product.processingTime && product.processingTime.toString().split(" ")[0]) || ""}
              placeholder={t("inDays")}
              type="text"
              id="demo-step-16"
              onChange={(e) => {
                let newTime = product.processingTime.toString().split("to ");
                if (newTime[1]) editProduct(e.target.value + " to " + newTime[1], "processingTime");
                else editProduct(e.target.value, "processingTime");
              }}
            />
            {t("to")}
            <Input
              maxLength={5}
              style={{ width: "80px", marginLeft: "6px" }}
              value={product.processingTime && product.processingTime.toString().split("to ")[1]}
              placeholder={t("inDays")}
              type="text"
              onChange={(e) => {
                let newTime = product.processingTime.toString().split("to ");
                editProduct(newTime[0] + " to " + e.target.value, "processingTime");
              }}
            />
          </div>
        </div>
        <div
          style={
            windowSize > 1150 ? { width: "25%", marginLeft: "30px" } : { width: "100%", marginLeft: "0px" }
          }
          className="column second"
        >
          <p
            style={
              windowSize > 1150
                ? { fontWeight: "bold", marginBottom: "5px", marginTop: "0px" }
                : { fontWeight: "bold", marginBottom: "5px", marginTop: "5px" }
            }
          >
            Tags (3 max.)
          </p>
          <Select
            value={"+ Add tag"}
            style={{ width: "100%", marginBottom: "5px" }}
            onChange={(value) => {
              if (product.tag.length < 3) editProduct([...product.tag, value], "tag");
              else {
                message.error(t("messages.tagLimit"));
              }
            }}
          >
            {tag.map((tag, index) => {
              return !product.tag.includes(tag) && <Option value={tag}>{tag}</Option>;
            })}
          </Select>
          <div
            style={
              windowSize > 1150
                ? { width: "95%", marginBottom: "10px" }
                : { width: "100%", marginBottom: "10px" }
            }
            className="row-wrap"
          >
            {product.tag.map((tag, index) => {
              return (
                <button className="transparent-gradient-button">
                  {tag}
                  <CloseOutlined
                    onClick={() => {
                      let tagStock = [...product.tag];
                      tagStock.splice(index, 1);
                      editProduct(tagStock, "tag");
                    }}
                    style={{ fontSize: "14px", marginLeft: "5px" }}
                  />
                </button>
              );
            })}
          </div>

          <p style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "0px" }}>{t("cardProduct.16")}</p>
          <Select
            className={product.shipping.length < 1 && "red-select"}
            onClick={getUser}
            id="demo-step-17"
            value="+ Shipping method"
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value === "all" && user.shipping) {
                let newShip = [];
                user.shipping.map((shipping, index) => {
                  newShip.push({
                    name: shipping.name,
                    origin: shipping.origin,
                    price: shipping.price,
                    time: shipping.time,
                    shipping_id: shipping._id,
                  });
                  if (index === user.shipping.length - 1) {
                    editProduct([...product.shipping, ...newShip], "shipping");
                    if (!disabledModalApplyShippingAll) {
                      setShippingToApplyAll(newShip);
                    }
                  }
                });
              } else if (value || value == 0) {
                const newShip = user.shipping[value];
                editProduct(
                  [
                    ...product.shipping,
                    {
                      name: newShip.name,
                      origin: newShip.origin,
                      time: newShip.time,
                      price: newShip.price,
                      shipping_id: newShip._id,
                    },
                  ],
                  "shipping"
                );
                if (!disabledModalApplyShippingAll) {
                  setShippingToApplyAll({
                    name: newShip.name,
                    origin: newShip.origin,
                    time: newShip.time,
                    price: newShip.price,
                    shipping_id: newShip._id,
                  });
                }
              }
            }}
          >
            {user && user.shipping && user.shipping.length > 0 && (
              <Option value="all" className="button orange-back">
                {t("cardProduct.17")}
              </Option>
            )}
            {user &&
              user.shipping &&
              user.shipping.map((method, index) => {
                if (!product.shipping.some((ship) => ship.shipping_id === method._id))
                  return (
                    <Option value={index}>
                      {method.name} {"->"} {method.price}$
                    </Option>
                  );
              })}
          </Select>
          {product.shipping.length > 0 &&
            product.shipping.map((method, index) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid rgb(230, 230, 230)",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "45px",
                    width: "100%",
                  }}
                  className="row"
                >
                  <p
                    style={{
                      marginRight: "25px",
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {method.name} {"->"} {method.price}$
                  </p>
                  <button
                    className="little-button-red"
                    onClick={(e) => {
                      let shipStock = [...product.shipping];
                      shipStock.splice(index, 1);
                      editProduct(shipStock, "shipping");
                    }}
                  >
                    {t("delete").toUpperCase()}
                  </button>
                </div>
              );
            })}
          {product.shipping?.length && product.shipping?.length < user.shipping?.length ? (
            <div
              style={{
                borderBottom: "1px solid rgb(230, 230, 230)",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "45px",
                width: "100%",
              }}
              className="row"
            >
              <p style={{ marginRight: "25px", width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {t("cardProduct.18")}
              </p>
            </div>
          ) : (
            ""
          )}
          <a
            style={{ marginTop: "20px" }}
            className="text-gradient"
            href="/manage-shipping-methods"
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit the shipping methods
          </a>
        </div>
      </div>
      {product.variants.length > 0 && (
        <div
          style={
            windowSize > 1150 ? { width: "45%", marginLeft: "25%" } : { width: "90%", marginTop: "25px" }
          }
          className="column"
        >
          <p className="title">Variants :</p>
          {product.variants.map((variant, i) => {
            return (
              <div style={{ width: "100%" }} className="row mobile-shipping edit-product">
                <img
                  alt={`img variant-${variant._id}`}
                  src={
                    product.photos && product.photos[product.variants[i].image]
                      ? product.photos[product.variants[i].image].base64
                      : ""
                  }
                  className="img-variant"
                  onClick={() => setSelectImg(i)}
                />
                <div style={{ marginLeft: "20px", marginright: "20px", width: "60%" }} className="row-wrap">
                  <p style={{ fontSize: "16px", marginRight: "15px" }} className="title-200">
                    {variant.option1 && variant.option1}
                    {variant.option2 && ` / ${variant.option2}`}
                    {variant.option3 && ` / ${variant.option3}`}
                  </p>
                  <p style={{ fontSize: "14px", marginRight: "15px" }} className="title-200 row">
                    ${variant.price}{" "}
                    {variant.comparedPrice && (
                      <div style={{ marginLeft: "5px" }} className="row">
                        <div className="dashed" />${variant.comparedPrice}
                      </div>
                    )}
                  </p>
                  <p style={{ fontSize: "14px", marginRight: "15px" }} className="title-200">
                    {t("quantity")}: {variant.stock}
                  </p>
                </div>
                <button
                  style={{ padding: "4px 7px 4px 7px" }}
                  className="button-white"
                  onClick={() => setIndexVariantManageMobile(i)}
                >
                  {t("edit").toUpperCase()}
                </button>
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{ marginLeft: "0px", marginBottom: "20px", width: "100%" }}
        className="divider-horizontal"
      />
    </div>
  );
};

export default CardProduct;
