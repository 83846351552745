import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Anchor } from "antd";

const ResellerAnchor = ({ windowSize }) => {
  const { t } = useTranslation();
  return (
    <div
      style={windowSize > 1150 ? { position: "absolute", left: "50px", top: "110px" } : { width: "90%" }}
      className="column documentation-anchor"
    >
      <p style={{ marginTop: "50px", fontSize: "20px", marginBottom: "10px" }} className="title-roboto">
        Documentation
      </p>
      <Anchor style={{ top: "80px" }} affix={windowSize > 1150 ? true : false}>
        <Anchor.Link className="text-bold" title="Getting started">
          <Link style={{ fontWeight: "400" }} to="/what-is-01supply">
            <Anchor.Link href="#what-is-01supply" title={t("components.anchor.13")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/marketplace-documentation">
            <Anchor.Link href="#marketplace" title={t("components.anchor.14")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/your-account-reseller-documentation">
            <Anchor.Link href="#your-account" title={t("components.anchor.15")} className="text-ref" />
          </Link>
        </Anchor.Link>

        <Anchor.Link style={{ fontSize: "18px" }} className="text-bold" title="Buying">
          <Link style={{ fontWeight: "400" }} to="/how-to-place-an-order">
            <Anchor.Link
              href="#how-to-place-an-order"
              title={t("components.anchor.16")}
              className="text-ref"
            />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/how-to-track-an-order">
            <Anchor.Link
              href="#how-to-track-an-order"
              title={t("components.anchor.17")}
              className="text-ref"
            />
          </Link>
        </Anchor.Link>

        <Anchor.Link style={{ fontSize: "18px" }} className="text-bold" title="Integrations">
          <Link style={{ fontWeight: "400" }} to="/shopify-setup">
            <Anchor.Link href="#shopify-setup" title="1. Shopify" className="text-ref" />
          </Link>
        </Anchor.Link>

        <Anchor.Link style={{ fontSize: "18px" }} className="text-bold" title="Suppliers">
          <Link style={{ fontWeight: "400" }} to="/supplier-location-documentation">
            <Anchor.Link href="#supplier-location" title={t("components.anchor.18")} className="text-ref" />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/supplier-shipping-methods-documentation">
            <Anchor.Link
              href="#supplier-shipping-methods"
              title={t("components.anchor.19")}
              className="text-ref"
            />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/supplier-product-quantities-documentation">
            <Anchor.Link href="#supplier-quantities" title={t("components.anchor.20")} className="text-ref" />
          </Link>
        </Anchor.Link>

        <Anchor.Link style={{ fontSize: "18px" }} className="text-bold" title="Dropshipping">
          <Link style={{ fontWeight: "400" }} to="/what-is-dropshipping">
            <Anchor.Link
              href="#what-is-dropshipping"
              title={t("components.anchor.21")}
              className="text-ref"
            />
          </Link>
          <Link style={{ fontWeight: "400" }} to="/dropshipping-supplier">
            <Anchor.Link
              href="#dropshipping-supplier"
              title={t("components.anchor.22")}
              className="text-ref"
            />
          </Link>
        </Anchor.Link>
      </Anchor>
    </div>
  );
};

export default ResellerAnchor;
