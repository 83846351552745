import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import "./style.scss";

import uncheckImg from "../../../../images/icons/uncheck.svg";
import checkImg from "../../../../images/icons/check_blue.svg";

const SecondPricing = ({ PlanButton, windowSize }) => {
  const { t } = useTranslation();
  const header = [
    {
      title: (
        <div className="column">
          <p style={{ fontSize: windowSize > 1150 ? "26px" : "16px" }} className="title montserrat-800 start">
            {t("resellerPricing.pricing2.features")}
          </p>
        </div>
      ),
      dataIndex: "features",
      key: "features",
      fixed: "left",
      width: windowSize < 800 ? 160 : undefined,
    },
    {
      title: (
        <div className="column">
          <p className="title montserrat-800">{t("resellerPricing.pricing2.basic")}</p>
          <PlanButton isSecondPlans={true} type={"basic"} />
        </div>
      ),
      dataIndex: "basic",
      key: "basic",
    },
    {
      title: (
        <div className="column">
          <p className="title montserrat-800">{t("resellerPricing.pricing2.advanced")}</p>
          <PlanButton isSecondPlans={true} type={"advanced"} />
        </div>
      ),
      dataIndex: "advanced",
      key: "advanced",
    },
    {
      title: (
        <div className="column">
          <p className="title montserrat-800">{t("resellerPricing.pricing2.premium")}</p>
          <PlanButton isSecondPlans={true} type={"premium"} />
        </div>
      ),
      dataIndex: "premium",
      key: "premium",
    },
  ];

  const content = [
    {
      key: "1",
      features: <p className="title grey width-100 start">{t("resellerPricing.pricing2.products")}</p>,
    },
    {
      key: "2",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.1")}</p>,
      basic: <p className="width-100 center">25</p>,
      advanced: <p className="text-bold width-100 center">250</p>,
      premium: <p className="width-100 center">Unlimited</p>,
    },
    {
      key: "3",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.2")}</p>,
      basic: <p className="width-100 center">25/mo</p>,
      advanced: <p className="text-bold width-100 center">250/mo</p>,
      premium: <p className="width-100 center">500/mo</p>,
    },
    /*
    {
      key: "4",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.3")}</p>,
      basic: <p className="width-100 center">10/mo</p>,
      advanced: <p className="text-bold width-100 center">125/mo</p>,
      premium: <p className="width-100 center">250/mo</p>,
    },
    */
    {
      key: "5",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.4")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "6",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.5")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "7",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.6")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="alert" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="alert" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="alert" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "8",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.7")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "10",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.8")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={uncheckImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "9",
      features: (
        <p style={{ marginTop: "30px" }} className="title grey width-100 start no-margin">
          {t("resellerPricing.pricing2.9")}
        </p>
      ),
    },
    {
      key: "11",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.10")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "12",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.11")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "13",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.12")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "14",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.13")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={uncheckImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "15",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.14")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={uncheckImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "16",
      features: (
        <p style={{ marginTop: "30px" }} className="title grey width-100 start no-margin">
          {t("resellerPricing.pricing2.15")}
        </p>
      ),
    },
    {
      key: "17",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.16")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "18",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.17")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "19",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.18")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "20",
      features: (
        <p style={{ marginTop: "30px" }} className="title grey width-100 start no-margin">
          {t("resellerPricing.pricing2.19")}
        </p>
      ),
    },
    {
      key: "21",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.20")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="check" className="icon" />{" "}
        </div>
      ),
    },
    {
      key: "22",
      features: <p className="text-bold width-100">{t("resellerPricing.pricing2.21")}</p>,
      basic: (
        <div className="width-100 center">
          <img src={uncheckImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      advanced: (
        <div className="width-100 center">
          <img src={checkImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
      premium: (
        <div className="width-100 center">
          <img src={checkImg} alt="uncheck" className="icon" />{" "}
        </div>
      ),
    },
  ];

  return (
    <div
      className="column width-100"
      style={{ alignItems: "center", marginTop: "60px", width: windowSize < 1150 && "100vw" }}
    >
      <p className="title  montserrat-800 center">{t("resellerPricing.pricing2.title")} </p>
      <div className="second-reseller-pricing-container">
        <Table scroll={{ y: "80vh", x: "850px" }} pagination={false} dataSource={content} columns={header} />
      </div>
    </div>
  );
};

export default SecondPricing;
